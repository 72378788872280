import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from './GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_USER_ACCESS_INFO,
    LOAD_USER_ACCESS_INFO_SUCCESS,
    LOAD_USER_ACCESS_INFO_FAILURE,
    INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
    // select
    SELECT_USER_ACCESS_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
    ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
    START_ADD_OR_EDIT_USER_ACCESS,
    ADD_OR_EDIT_USER_ACCESS_SUCCESS,
    ADD_OR_EDIT_USER_ACCESS_FAILURE,
    INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
    RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_USER_ACCESS_MODAL,
    DEL_USER_ACCESS_CHANGE_INPUT,
    START_DEL_USER_ACCESS,
    DEL_USER_ACCESS_SUCCESS,
    DEL_USER_ACCESS_FAILURE,
    INIT_DEL_USER_ACCESS_ACTION_STATUS,
    RESET_DEL_USER_ACCESS_MODAL,

    // del
    OPEN_COP_USER_ACCESS_MODAL,
    COP_USER_ACCESS_CHANGE_INPUT,
    START_COP_USER_ACCESS,
    COP_USER_ACCESS_SUCCESS,
    COP_USER_ACCESS_FAILURE,
    INIT_COP_USER_ACCESS_ACTION_STATUS,
    RESET_COP_USER_ACCESS_MODAL,
    OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    RESTRICTED_RULE_INPUT_CHANGE,

} from './UserAccessConstants';

import {
    // Load Security Tickers
    LOAD_SECURITY_TICKERS_SUCCESS,
  
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  
  } from '../../home/homeConstants';
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={'Active'}>{'Active'}</Option>);
statusOptions.push(<Option key={'Inactive'}>{'Inactive'}</Option>);

const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {

    userAccessUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.USER_ACCESS_UPLOAD_URL,
    userAccessDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.USER_ACCESS_DOWNLOAD_URL,
    userAccessGridColumns: GridColumnMap.userAccessGridColumns,

    statusOptions: statusOptions,
    fundFirmRelatedOptions: fundFirmRelatedOptions,
    ccyOptions: ccyOptions,

    userAccessQueryFields: {
        userName: '',
        status: ''
    },
    selectedUserAccessInfoArray: [],
    addOrEditUserAccessModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            category: '',
            firm: '',
            fundFirmRelated: '',
            invDate: '',
            invNo: '',
            ccy: '',
            amount: '',
            fx: '',
            amtUSD: '',
            payDate: '',
            nature: '',
            remarks: '',
            payMethod: '',
            comments: '',
        },
    },
    delUserAccessModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
        }
    },
    copUserAccessModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
            employee: null,
            comments: '',

        }
    },
    // Load User Account
      userAccountArray: [],
   //   userAccountOptions: [],
   restrictedRuleEditControlModal: {
    isOpened: false,
    respErrMsg: null,
    isAddMode: null,
    isFundBookOpened: false,
    fields:{

    },
    fundBookFields:{

    }
  }

};


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.userAccessQueryFields,
        [name]: value,
    };
    return {
        ...state,
        userAccessQueryFields: upatedFields,
    }
}
function startLoadUserAccessInfo(state) {
    return {
      ...state,
      loadUserAccessInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadUserAccessInfoSuccess(state, resp) {
    return {
        ...state,
        loadUserAccessInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadUserAccessInfoResp: resp,
    };
}
function loadUserAccessInfoFailure(state, err) {
    return {
        ...state,
        loadUserAccessInfoActionStatus: ACTION_STATUS.ERROR,
        loadUserAccessInfoResp: err,
    };
}
function initLoadUserAccessInfoActionStatus(state) {
    return {
        ...state,
        loadUserAccessInfoActionStatus: ACTION_STATUS.READY,
        selectedUserAccessInfoArray: [],
    };
}


// select
function selectUserAccessInfo(state, array) {
    return {
        ...state,
        selectedUserAccessInfoArray: array,
    }
}


// add or edit
function openAddOrEditUserAccessModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditUserAccessModal: {
                ...state.addOrEditUserAccessModal,
                isOpened: true,
                mode,
            },
        };
    } else {
        return {
            ...state,
            addOrEditUserAccessModal: {
                ...state.addOrEditUserAccessModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditUserAccessModal.fields,
                    id: data.id,
                    cpty: data.cpty,
                    functiontype: data.functiontype,
                    usertype: data.usertype,
                    fundandcompany: data.fundandcompany,
                    employee: data.employee,
                    status: data.status,
                   
                    remarks: data.remarks,
                }
            },
        };
    }
}
function addOrEditUserAccessChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditUserAccessModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditUserAccessModal: {
          ...state.addOrEditUserAccessModal,
          fields: updatedFields,
        },
    };
}
function startAddOrEditUserAccess(state) {
    return {
      ...state,
      addOrEditUserAccessStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditUserAccessSuccess(state, resp) {
    return {
        ...state,
        addOrEditUserAccessStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditUserAccessFailure(state, err) {
    return {
        ...state,
        addOrEditUserAccessStatus: ACTION_STATUS.ERROR,
        addOrEditUserAccessModal: {
            ...state.addOrEditUserAccessModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditUserAccessActionStatus(state) {
    return {
        ...state,
        addOrEditUserAccessStatus: ACTION_STATUS.READY,
    };
}
function resetAddOrEditUserAccessModal(state) {
    return {
        ...state,
        addOrEditUserAccessModal: initialState.addOrEditUserAccessModal,
    }
}


// refresh fx and amtUSD
function startRefreshFx(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.LOGINING,
    };
}
function refreshFxSuccess(state, resp) {
    const fx = (!!resp && !!resp.data && !!resp.data.last) ? resp.data.last : '';
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.SUCCESS,
        addOrEditUserAccessModal: {
            ...state.addOrEditUserAccessModal,
            fields: {
                ...state.addOrEditUserAccessModal.fields,
                fx: fx,
            },
        }
    }
}
function refreshFxFailure(state, err) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.ERROR,
        refreshFxResp: err,
        addOrEditUserAccessModal: {
            ...state.addOrEditUserAccessModal,
            fields: {
                ...state.addOrEditUserAccessModal.fields,
                fx: '',
            },
        }
    }
}
function initRefreshFxActionStatus(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.READY,
    };
}
function refreshAmtUSD(state, amtUSD) {
    return {
        ...state,
        addOrEditUserAccessModal: {
            ...state.addOrEditUserAccessModal,
            fields: {
                ...state.addOrEditUserAccessModal.fields,
                amtUSD: amtUSD,
            },
        }
    };
}


// del
function openDelUserAccessModal(state, {info,mode,idList}) {
    return {
        ...state,
        delUserAccessModal: {
            ...state.delUserAccessModal,
            isOpened: true,
            mode:mode,
            idList:idList,
            fields: {
                ...state.delUserAccessModal.fields,
                id: info.id,
                
            }
        }
    }
}
function delUserAccessChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.delUserAccessModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delUserAccessModal: {
          ...state.delUserAccessModal,
          fields: updatedFields,
        },
    };
}
function startDelUserAccess(state) {
    return {
      ...state,
      delUserAccessStatus: ACTION_STATUS.LOGINING,
    };
}
function delUserAccessSuccess(state, resp) {
    return {
        ...state,
        delUserAccessStatus: ACTION_STATUS.SUCCESS,
    };
}
function delUserAccessFailure(state, err) {
    return {
        ...state,
        delUserAccessStatus: ACTION_STATUS.ERROR,
        delUserAccessModal: {
            ...state.delUserAccessModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelUserAccessActionStatus(state) {
    return {
        ...state,
        delUserAccessStatus: ACTION_STATUS.READY,
    };
}
function resetDelUserAccessModal(state) {
    return {
        ...state,
        delUserAccessModal: initialState.delUserAccessModal,
    }
}



// cop
function openCopUserAccessModal(state, payload) {
    return {
        ...state,
        copUserAccessModal: {
            ...state.copUserAccessModal,
            isOpened: true,
            fields: {
                ...state.copUserAccessModal.fields,
                id: payload.id,
            }
        }
    }
}
function copUserAccessChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.copUserAccessModal.fields,
        [name]: value,
    };
    return {
        ...state,
        copUserAccessModal: {
          ...state.copUserAccessModal,
          fields: updatedFields,
        },
    };
}
function startCopUserAccess(state) {
    return {
      ...state,
      copUserAccessStatus: ACTION_STATUS.LOGINING,
    };
}
function copUserAccessSuccess(state, resp) {
    return {
        ...state,
        copUserAccessStatus: ACTION_STATUS.SUCCESS,
    };
}
function copUserAccessFailure(state, err) {
    return {
        ...state,
        copUserAccessStatus: ACTION_STATUS.ERROR,
        copUserAccessModal: {
            ...state.copUserAccessModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initCopUserAccessActionStatus(state) {
    return {
        ...state,
        copUserAccessStatus: ACTION_STATUS.READY,
    };
}
function resetCopUserAccessModal(state) {
    return {
        ...state,
        copUserAccessModal: initialState.copUserAccessModal,
    }
}



// Load User Account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
  
    return {
      ...state,
      userAccountArray,
      userAccountOptions,
    };
  }
  
  export function openRestrictedRuleFundBookDialog(state) {
    let updatedFields = {
      ...state.restrictedRuleEditControlModal,
      isFundBookOpened: true,
      fundBookFields:{},
    };
    return {
      ...state,
      restrictedRuleEditControlModal: updatedFields
    };
  }
  
  export function closeRestrictedRuleFundBookDialog(state) {
    let updatedFields = {
      ...state.restrictedRuleEditControlModal,
      isFundBookOpened: false,
      fundBookFields:{},
    };
    return {
      ...state,
      restrictedRuleEditControlModal: updatedFields
    };
  }



  function restrictedRuleEditChangeInput(state, { name, value }) {
    let updatedFields = {
      ...state.restrictedRuleEditControlModal,
      [name]: value,
    };
    return {
      ...state,
      restrictedRuleEditControlModal: updatedFields
    };
  }


export default createReducer(initialState, {
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_USER_ACCESS_INFO]: startLoadUserAccessInfo,
    [LOAD_USER_ACCESS_INFO_SUCCESS]: loadUserAccessInfoSuccess,
    [LOAD_USER_ACCESS_INFO_FAILURE]: loadUserAccessInfoFailure,
    [INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS]: initLoadUserAccessInfoActionStatus,
    // select
    [SELECT_USER_ACCESS_INFO]: selectUserAccessInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL]: openAddOrEditUserAccessModal,
    [ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT]: addOrEditUserAccessChangeInput,
    [START_ADD_OR_EDIT_USER_ACCESS]: startAddOrEditUserAccess,
    [ADD_OR_EDIT_USER_ACCESS_SUCCESS]: addOrEditUserAccessSuccess,
    [ADD_OR_EDIT_USER_ACCESS_FAILURE]: addOrEditUserAccessFailure,
    [INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS]: initAddOrEditUserAccessActionStatus,
    [RESET_ADD_OR_EDIT_USER_ACCESS_MODAL]: resetAddOrEditUserAccessModal,
    // refresh fx and amtUSD
    [START_REFRESH_FX]: startRefreshFx,
    [REFRESH_FX_SUCCESS]: refreshFxSuccess,
    [REFRESH_FX_FAILURE]: refreshFxFailure,
    [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
    [REFRESH_AMT_USD]: refreshAmtUSD,
    // del
    [OPEN_DEL_USER_ACCESS_MODAL]: openDelUserAccessModal,
    [DEL_USER_ACCESS_CHANGE_INPUT]: delUserAccessChangeInput,
    [START_DEL_USER_ACCESS]: startDelUserAccess,
    [DEL_USER_ACCESS_SUCCESS]: delUserAccessSuccess,
    [DEL_USER_ACCESS_FAILURE]: delUserAccessFailure,
    [INIT_DEL_USER_ACCESS_ACTION_STATUS]: initDelUserAccessActionStatus,
    [RESET_DEL_USER_ACCESS_MODAL]: resetDelUserAccessModal,


    // cop
    [OPEN_COP_USER_ACCESS_MODAL]: openCopUserAccessModal,
    [COP_USER_ACCESS_CHANGE_INPUT]: copUserAccessChangeInput,
    [START_COP_USER_ACCESS]: startCopUserAccess,
    [COP_USER_ACCESS_SUCCESS]: copUserAccessSuccess,
    [COP_USER_ACCESS_FAILURE]: copUserAccessFailure,
    [INIT_COP_USER_ACCESS_ACTION_STATUS]: initCopUserAccessActionStatus,
    [RESET_COP_USER_ACCESS_MODAL]: resetCopUserAccessModal,


    // Load User Account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

    [OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG]: openRestrictedRuleFundBookDialog,
    [CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG]: closeRestrictedRuleFundBookDialog,
    [RESTRICTED_RULE_INPUT_CHANGE]: restrictedRuleEditChangeInput,
});