import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import JsonTooltipComponent from '../../../../../utils/JsonTooltipComponent';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';
class ExpenseSummaryGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: props.expenseSummaryGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                // tagComponent: TagComponent
            },
        };
    }

    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let expenseSummaryInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectExpenseSummaryInfo(expenseSummaryInfoArray);
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'expense-summaryold-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };
    render() {

        const {
            // query
            loadExpenseSummaryInfoActionStatus,
            loadExpenseSummaryInfoResp,
        } = this.props;


        const isLoading = loadExpenseSummaryInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadExpenseSummaryInfoArray = [];
        if(!!loadExpenseSummaryInfoResp && !!loadExpenseSummaryInfoResp.data) {
            loadExpenseSummaryInfoArray = loadExpenseSummaryInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadExpenseSummaryInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}/////
                            resizable={true}
                            sideBar={true}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // event
                            onSelectionChanged={this.onDataSelectionChanged}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}

                        />
                    )}
            </div>
        );
    }
}

export default ExpenseSummaryGrid;
