import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, Divider, AutoComplete, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const FormItem = Form.Item;


class AddOrEditElementDialog extends Component {
    componentDidMount() {
    }


    componentDidUpdate() {
        const {
            addOrEditElementModal,
            addOrEditElementActionStatus,
        } = this.props;
        const {
            mode,
            fields,
            initEdit,
        } = addOrEditElementModal;
        const {
            elementTypeId,
        } = fields;
        if(initEdit) {
            this.props.resetInitEditOfAddOrEditElementModal();
            this.constructPropsFormItem(elementTypeId, false);
        }
        if(addOrEditElementActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getModalTitle(mode) + ' succeeded';
            message.success(msg);
            this.props.initSubmitAddOrEditElementActionStatus();
            this.props.resetAddOrEditElementModal();
            this.props.queryElement(this.props.elementQueryFields);
        }
        if(addOrEditElementActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitAddOrEditElementActionStatus();
        }
    }   


    // 构造表单中用于填写props的值的部分
    constructPropsFormItem = (elementTypeId, resetAllFields) => {
        const elementTypeList = this.props.elementTypeList;
        const currElementType = !!elementTypeId ? elementTypeList.filter(e => e.id == elementTypeId)[0] : undefined;
        const propsFormItemArray = [];
        if(!!currElementType) {
            const elementTypeFieldList = currElementType.elementTypeFieldList;
            for(let i in elementTypeFieldList) {
                if(i%2 !== 0) {
                    continue;
                }
                // 每两个属性构造一行
                const firstField = elementTypeFieldList[i];
                const plusOne = parseInt(i) + 1;
                const secondField = (i >= elementTypeFieldList.length-1) ? null : elementTypeFieldList[plusOne];
                const oneRow = this.constructOneRow(firstField, secondField, i, resetAllFields);
                propsFormItemArray.push(oneRow);
            }
        }
        this.props.appendPropsFormItemToState(propsFormItemArray);
    }

    // 构造一行
    constructOneRow = (firstField, secondField, idx, resetAllFields) => {
        let firstCol = undefined;
        if(firstField.isConstraint === 'Y' && (firstField.fieldName === 'from' || firstField.fieldName === 'to')) {
            firstCol = this.constructOneColOfFromTo(firstField, resetAllFields);
        } else {
            firstCol = this.constructOneColOfNonConstraint(firstField, resetAllFields);
        }
        let secondCol = <Col span={12}></Col>;
        if(!!secondField) {
            if(secondField.isConstraint === 'Y' && (secondField.fieldName === 'from' || secondField.fieldName === 'to')) {
                secondCol = this.constructOneColOfFromTo(secondField, resetAllFields);
            } else {
                secondCol = this.constructOneColOfNonConstraint(secondField, resetAllFields);
            }
        }
        const onwRow = <Row gutter={24} key={idx}>{firstCol}{secondCol}</Row>
        return onwRow;
    }

    // 构造from/to属性
    constructOneColOfFromTo = (elementTypeField, resetAllFields) => {
        const { getFieldDecorator } = this.props.form;
        const elementPropsList = this.props.addOrEditElementModal.fields.elementPropsList;
        const fieldName = elementTypeField.fieldName;
        const filedId = elementTypeField.id;
        let initialValue = undefined;
        if(!!elementPropsList && elementPropsList.length>0 && !resetAllFields) {
            const filteredArray = elementPropsList.filter(e => e.elementTypeField.isConstraint === 'Y' && e.elementTypeField.fieldName === elementTypeField.fieldName);
            if(!!filteredArray && filteredArray.length>0) {
                initialValue = filteredArray[0].value;
            }
        }

        const oneCol = (
            <Col span={12}>
                <FormItem {...formItemLayout} label={fieldName}>
                {getFieldDecorator(fieldName, {
                        initialValue: initialValue,
                        rules: [{
                            required: false,
                            message: '',
                        }],
                    })(
                            <Select 
                                allowClear
                                style={{ width: 250, marginLeft: 10 }}
                                placeholder={fieldName} 
                                onChange={(val) => {
                                    this.onElementPropsInputChange(filedId, val, elementTypeField)
                            }}>
                                {this.props.entitySelectOptions}
                            </Select>
                    )}
                </FormItem>
            </Col>
        );
        return oneCol;
    }

    // 构造普通isConstraint=N的属性
    constructOneColOfNonConstraint = (elementTypeField, resetAllFields) => {
        const { getFieldDecorator } = this.props.form;
        const elementPropsList = this.props.addOrEditElementModal.fields.elementPropsList;
        const fieldName = elementTypeField.fieldName;
        const filedId = elementTypeField.id;
        let initialValue = undefined;
        if(!!elementPropsList && elementPropsList.length>0 && !resetAllFields) {
            const filteredArray = elementPropsList.filter(e => e.elementTypeField.fieldName === elementTypeField.fieldName);
            if(!!filteredArray && filteredArray.length>0) {
                initialValue = filteredArray[0].value;
            }
        }
        const oneCol = (
            <Col span={12}>
                <FormItem {...formItemLayout} label={fieldName}>
                {getFieldDecorator(fieldName, {
                        initialValue: initialValue,
                        rules: [{
                            required: false,
                            message: '',
                        }],
                    })(
                            <Input placeholder={fieldName} 
                                    style={{ width: 250, marginLeft: 10 }}
                                    onChange={(e) => {
                                        this.onElementPropsInputChange(filedId, e.target.value, elementTypeField)
                                }}
                            />
                    )}
                </FormItem>
            </Col>
        );
        return oneCol;
    }



    onElementTypeInputChange = (elemenetTypeId) => {
        this.props.form.resetFields();
        this.props.resetElementProps();
        this.onElementDataInputChange({name: 'elementTypeId', value: elemenetTypeId});
        this.constructPropsFormItem(elemenetTypeId, true);
    }


    onElementDataInputChange = (param) => {
        this.props.onElementDataInputChange(param);
    }

    onElementPropsInputChange = (fieldId, value, elementTypeField) => {
        const elementId = this.props.addOrEditElementModal.fields.id;
        const currProps = {
            elementTypeField,
            elementId,
            fieldId,
            value,
        }
        this.props.onElementPropsInputChange(currProps);
    }


    getModalTitle = (mode) => {
        const action = mode === 'ADD' ? 'Add' : 'Edit';
        return action + ' Element and Props';
    }


    submitAddOrEditElement = () => {
        const {
            elementTypeList,
            addOrEditElementModal,
        } = this.props;
        const {
            mode,
            fields,
        } = addOrEditElementModal;
        const {
            id,
            elementTypeId,
            elementName,
            elementPropsList,
        } = fields;
        let elementTypeFieldList = [];
        if(!!elementTypeId) {
            const currElementType = elementTypeList.filter(e => e.id+'' === elementTypeId+'')[0];
            elementTypeFieldList = currElementType.elementTypeFieldList;
        }

        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // props优先来自于elementPropsList，但不一定完整，还要根据elementTypeFieldList补充
            const newPropsList = [];
            if(!!elementPropsList && elementPropsList.length > 0) {
                elementPropsList.forEach(e => newPropsList.push(e));
            }
            if(!!elementTypeFieldList && elementTypeFieldList.length > 0) {
                elementTypeFieldList.forEach(f => {
                    if(elementPropsList.filter(p => f.id+'' === p.fieldId+'').length === 0) {
                        const newProps = {
                            elementTypeField: f,
                            elementId: id,
                            fieldId: f.id,
                            value: null,
                        };
                        newPropsList.push(newProps);
                    }
                });
            }
            // isConstraint=Y的from/to，这两个属性不能是空
            let errMsg = null;
            for(let i in newPropsList) {
                const e = newPropsList[i];
                if(e.elementTypeField.isConstraint === 'Y' && (e.elementTypeField.fieldName === 'from' || e.elementTypeField.fieldName === 'to')) {
                    if(!e.value) {
                        errMsg = e.elementTypeField.fieldName + ' shall not be empty';
                        break;
                    }
                }
            }
            if(!!errMsg) {
                const err = {
                    respMessage: errMsg,
                }
                this.props.submitAddOrEditElementFailure(err);
                return;
            }
            this.props.submitAddOrEditElement({
                id,
                elementTypeId,
                elementName,
                elementPropsList: newPropsList,
                mode,
            });
            
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            addOrEditElementModal,
            elementTypeSelectOptions,
            propsFormItemArray,
            addOrEditElementActionStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
            initEdit,
        } = addOrEditElementModal;
        const {
            id,
            elementTypeId,
            elementName,
        } = fields;


        const modalTitle = this.getModalTitle(mode);
        const isSubmitLoading = addOrEditElementActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditElementModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetAddOrEditElementModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditElement() }>
                          Submit
                        </Button>,
                    ]}
                >

                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="elementType">
                                    {getFieldDecorator('elementTypeId', {
                                        initialValue: !!elementTypeId ? elementTypeId+'' : undefined,
                                        rules: [{
                                            required: true,
                                            message: 'Please select elementType',
                                        }],
                                        })(
                                            <Select 
                                                disabled={mode==='EDIT'}
                                                style={{ width: 250, marginLeft: 10 }}
                                                placeholder='elementType' 
                                                onChange={(val) => {
                                                    this.onElementTypeInputChange(val)
                                            }}>
                                                {elementTypeSelectOptions}
                                            </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="elementName">
                                    {getFieldDecorator('elementName', {
                                        initialValue: elementName,
                                        rules: [{
                                            required: true,
                                            message: 'Please input elementName',
                                        }],
                                        })(
                                            <Input placeholder='elementName' 
                                                    style={{ width: 250, marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        this.onElementDataInputChange({ name: 'elementName', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Divider>Input Props Values</Divider>

                        {propsFormItemArray}

                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditElementDialogForm = Form.create({ name: 'AddOrEditElementDialog' })(AddOrEditElementDialog);
export default AddOrEditElementDialogForm;

