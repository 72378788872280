import {connect} from 'react-redux';
import CustodianDashboard from '../components/CustodianDashboard';

import {
  // Load Custodian
  loadCustodian,
  initLoadCustodianActionStatus,

  // Load Custodian Account
  loadCustodianAccount,
  initLoadCustodianAccountActionStatus,
} from '../custodianActions';

const mapStateToProps = state => {
  return {
    custodianGridColumns: state.custodian.custodianGridColumns,
    custodianAccountGridColumns: state.custodian.custodianAccountGridColumns,

    // Custodian
    custodianArray: state.custodian.custodianArray,

    // Custodian Account
    custodianAccountArray: state.custodian.custodianAccountArray,

    // Load Custodian Action Status
    loadCustodianActionStatus: state.custodian.loadCustodianActionStatus,

    // Load Custodian Account Action Status
    loadCustodianAccountActionStatus: state.custodian.loadCustodianAccountActionStatus,

  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Custodian
    loadCustodian: () => dispatch(loadCustodian()),
    initLoadCustodianActionStatus: () => dispatch(initLoadCustodianActionStatus()),

    // Load Custodian Account
    loadCustodianAccount: () => dispatch(loadCustodianAccount()),
    initLoadCustodianAccountActionStatus: () => dispatch(initLoadCustodianAccountActionStatus()),
    
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianDashboard);