import React, { Component } from 'react';
import { Input, Modal, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../../utils/QuestionnaireEmailSendMode';

const TextArea = Input.TextArea;

class QuestionnaireEmailEditDialog extends Component {

  state = {
    modalTitle: 'Email',
    emailTitle: '',
    emailContent: '',
  };

  onInputChange = ({name, value}) => {
    this.setState({
      [name]: value,
    });
  }

  componentDidUpdate() {
    const { 
      // Accept Questionnaire Result
      acceptQuestionnaireResultActionStatus,

      // Reject Questionnaire Result
      rejectQuestionnaireResultActionStatus,

      // Send Remind Email
      sendRemindEmailActionStatus,
      // Select Answeree
      selectAnswereesActionStatus,
    } = this.props;

    // Accept Questionnaire Result 成功
    if (acceptQuestionnaireResultActionStatus === ACTION_STATUS.SUCCESS) {
      message.success(' answer are accepted successfully!');
      this.props.loadCptInfo({id: undefined , location: undefined, fundCompany: undefined});
      this.props.loadLicensingForm({type: 'Personal Info',status: 'Activated'});
      this.props.initAcceptQuestionnaireResultActionStatus();
      this.props.resetQuestionnaireEmailEditModalDialog();
    } 

    // Accept Questionnaire Result 失败
    if (acceptQuestionnaireResultActionStatus === ACTION_STATUS.ERROR) {
      this.props.initAcceptQuestionnaireResultActionStatus();
    }

    // Reject Questionnaire Result 成功
    if (rejectQuestionnaireResultActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Questionnaire answer are refused successfully!');
      this.props.loadQuestionnaireResults();
      this.props.initRejectQuestionnaireResultActionStatus();
      this.props.resetQuestionnaireEmailEditModalDialog();
    } 

    // Refuse Questionnaire Result 失败
    if (rejectQuestionnaireResultActionStatus === ACTION_STATUS.ERROR) {
      this.props.initRejectQuestionnaireResultActionStatus();
    }

    // Send Remind Email 成功
    if (sendRemindEmailActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Remind email are sent successfully!');
      this.props.loadQuestionnaireResults();
      this.props.initSendRemindEmailActionStatus();
      this.props.resetQuestionnaireEmailEditModalDialog();
    } 

    if (sendRemindEmailActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSendRemindEmailActionStatus();
    }

  }

  submitButtonOnClicked = () => {
    const {
      sendMode,
      emailPayload,
    } = this.props.questionnaireEmailEditControlModal;

    const {
      emailTitle,
      emailContent,
    } = this.state;

    // 选择答卷人
    if (sendMode === QUESTIONNAIRE_EMAIL_SEND_MODE.ANSWEREE_SELECT) {
      const {
        userAccountNameList,
        questionnaireGuid,
      } = emailPayload;

      this.props.selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent);
    }

    // 提醒邮件重发
    if (sendMode === QUESTIONNAIRE_EMAIL_SEND_MODE.EMAIL_RESEND) {
      const {
        questionnaireResultGuidList,
      } = emailPayload;

      this.props.sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent);
    }

    // 问卷接受
    if (sendMode === QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_ACCEPT) {
      const {
        questionnaireResultGuidList,
      } = emailPayload;

      this.props.acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent);
    }

    // 问卷拒绝
    if (sendMode === QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_REJECT) {
      const {
        questionnaireResultGuidList,
      } = emailPayload;

      this.props.rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent);
    }
  }

  // 设置 Modal Title
  getModalTitle = () => {
    let modalTitle;
    const {
      sendMode,
    } = this.props.questionnaireEmailEditControlModal;
  
    switch(sendMode) {
      case QUESTIONNAIRE_EMAIL_SEND_MODE.ANSWEREE_SELECT: 
        modalTitle = 'Select Answeree Email';
        break;
      case QUESTIONNAIRE_EMAIL_SEND_MODE.EMAIL_RESEND: 
        modalTitle = 'Remind Email';
        break;
      case QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_ACCEPT: 
        modalTitle = 'Accept Questionnaire Email';
        break;
      case QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_REJECT: 
        modalTitle = 'Reject Questionnaire Email';
        break;
      default:
        modalTitle = 'Email';
    }

    return modalTitle;
  }

  // Modal Closed
  modalClosedHandle = () => {
    this.setState({
      emailTitle: '',
      emailContent: '',
    });
  }

  render() {
    const {
      emailTitle,
      emailContent,
    } = this.state;

    const {
      questionnaireEmailEditControlModal,
      sendRemindEmailActionStatus,
      // Select Answeree
      selectAnswereesActionStatus,

      // Accept Questionnaire Result
      acceptQuestionnaireResultActionStatus,

      // Refuse Questionnaire Result
      rejectQuestionnaireResultActionStatus,
    } = this.props;

    const {
      isOpened,
      respErrMsg,
    } = questionnaireEmailEditControlModal;

    const modalTitle = this.getModalTitle();

    const isLoading = sendRemindEmailActionStatus === ACTION_STATUS.LOGINING || 
      selectAnswereesActionStatus === ACTION_STATUS.LOGINING ||
      acceptQuestionnaireResultActionStatus === ACTION_STATUS.LOGINING ||
      rejectQuestionnaireResultActionStatus === ACTION_STATUS.LOGINING;
        
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={600}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetQuestionnaireEmailEditModalDialog()}
          afterClose={this.modalClosedHandle}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <TextArea 
            rows={2} 
            value={emailTitle} 
            placeholder="Email Title"
            onChange={(e) => {
              this.onInputChange({ name: 'emailTitle', value: e.target.value })
            }}/>

          <TextArea 
            rows={4} 
            style={{ marginTop:10 }}
            value={emailContent} 
            placeholder="Email Content"
            onChange={(e) => {
              this.onInputChange({ name: 'emailContent', value: e.target.value })
            }}/>
          {
            !!respErrMsg &&  
            <Message negative>
                <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default QuestionnaireEmailEditDialog;