import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // change input
    ON_ELEMENT_TYPE_QUERY_INPUT_CHANGE,
    // query
    START_QUERY_ELEMENT_TYPE,
    QUERY_ELEMENT_TYPE_SUCCESS,
    QUERY_ELEMENT_TYPE_FAILURE,
    INIT_QUERY_ELEMENT_TYPE_ACTION_STATUS,
    // select data
    SELECT_ELEMENT_TYPE_LIST,
    SELECT_ELEMENT_TYPE_FIELD_LIST,
    // add or edit element type
    OPEN_ADD_OR_EDIT_ELEMENT_TYPE_MODAL,
    RESET_ADD_OR_EDIT_ELEMENT_TYPE_MODAL,
    ON_ELEMENT_TYPE_ADD_OR_EDIT_INPUT_CHANGE,
    START_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE,
    SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_SUCCESS,
    SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_ACTION_STATUS,
    // del element type
    START_DEL_ELEMENT_TYPE,
    DEL_ELEMENT_TYPE_SUCCESS,
    DEL_ELEMENT_TYPE_FAILURE,
    INIT_DEL_ELEMENT_TYPE_ACTION_STATUS,
    // add or edit field
    OPEN_ADD_OR_EDIT_FIELD_MODAL,
    RESET_ADD_OR_EDIT_FIELD_MODAL,
    ON_FIELD_ADD_OR_EDIT_INPUT_CHANGE,
    START_SUBMIT_ADD_OR_EDIT_FIELD,
    SUBMIT_ADD_OR_EDIT_FIELD_SUCCESS,
    SUBMIT_ADD_OR_EDIT_FIELD_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_FIELD_ACTION_STATUS,
    // del field
    START_DEL_ELEMENT_TYPE_FIELD,
    DEL_ELEMENT_TYPE_FIELD_SUCCESS,
    DEL_ELEMENT_TYPE_FIELD_FAILURE,
    INIT_DEL_ELEMENT_TYPE_FIELD_ACTION_STATUS,

} from './ElementTypeMngConstants';



// change input
export function onElementTypeQueryInputChange(param) {
    return {
        type: ON_ELEMENT_TYPE_QUERY_INPUT_CHANGE,
        payload: param,
    };
}


// query
function startQueryElementType() {
    return {
        type: START_QUERY_ELEMENT_TYPE,
    };
}
export function queryElementType(param) {
    const inputParam = {
        ...param,
        needField: true,
    }
    return function(dispatch) {
        dispatch(startQueryElementType());
        httpClient
        .get(UrlConfig.QUERY_ELEMENT_TYPE, inputParam)
        .then(resp => dispatch(queryElementTypeSuccess(resp)))
        .catch(err => dispatch(queryElementTypeFailure(err)));
    };
}
function queryElementTypeSuccess(resp) {
    return {
        type: QUERY_ELEMENT_TYPE_SUCCESS,
        payload: resp,
    };
}
function queryElementTypeFailure(err) {
    return {
        type: QUERY_ELEMENT_TYPE_FAILURE,
        payload: err,
    };
}
export function initQueryElementTypeActionStatus() {
    return {
        type: INIT_QUERY_ELEMENT_TYPE_ACTION_STATUS,
    };
}


// select data
export function selectElementTypeList(data) {
    return {
        type: SELECT_ELEMENT_TYPE_LIST,
        payload: data,
    };
}
export function selectElementTypeFieldList(data) {
    return {
        type: SELECT_ELEMENT_TYPE_FIELD_LIST,
        payload: data,
    };  
}


// add or edit element type
export function openAddOrEditElementTypeModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_ELEMENT_TYPE_MODAL,
        payload: {mode, data},
    }
}
export function resetAddOrEditElementTypeModal() {
    return {
        type: RESET_ADD_OR_EDIT_ELEMENT_TYPE_MODAL,
    }
}


export function onElementTypeAddOrEditInputChange(param) {
    return {
        type: ON_ELEMENT_TYPE_ADD_OR_EDIT_INPUT_CHANGE,
        payload: param,
    }
}


function startSubmitAddOrEditElementType() {
    return {
        type: START_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE,
    };
}
export function submitAddOrEditElementType(data) {
    return function(dispatch) {
        dispatch(startSubmitAddOrEditElementType());
        httpClient
        .post(UrlConfig.SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE, data)
        .then(resp => dispatch(submitAddOrEditElementTypeSuccess(resp)))
        .catch(err => dispatch(submitAddOrEditElementTypeFailure(err)));
    };
}
function submitAddOrEditElementTypeSuccess(resp) {
    return {
        type: SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_SUCCESS,
        payload: resp,
    };
}
function submitAddOrEditElementTypeFailure(err) {
    return {
        type: SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_FAILURE,
        payload: err,
    };
}
export function initSubmitAddOrEditElementTypeActionStatus() {
    return {
        type: INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_ACTION_STATUS,
    };
}



// del element type
function startDelElementTypeBtnClicked() {
    return {
        type: START_DEL_ELEMENT_TYPE,
    };
}
export function delElementTypeBtnClicked(data) {
    return function(dispatch) {
        dispatch(startDelElementTypeBtnClicked());
        httpClient
        .post(UrlConfig.DEL_ELEMENT_TYPE, data)
        .then(resp => dispatch(delElementTypeBtnClickedSuccess(resp)))
        .catch(err => dispatch(delElementTypeBtnClickedFailure(err)));
    };
}
function delElementTypeBtnClickedSuccess(resp) {
    return {
        type: DEL_ELEMENT_TYPE_SUCCESS,
        payload: resp,
    };
}
function delElementTypeBtnClickedFailure(err) {
    return {
        type: DEL_ELEMENT_TYPE_FAILURE,
        payload: err,
    };
}
export function initDelElementTypeBtnClickedActionStatus() {
    return {
        type: INIT_DEL_ELEMENT_TYPE_ACTION_STATUS,
    };
}



// add or edit field
export function openAddOrEditFieldModal(mode, field, elementTypeId) {
    return {
        type: OPEN_ADD_OR_EDIT_FIELD_MODAL,
        payload: {mode, field, elementTypeId},
    }
}
export function resetAddOrEditFieldModal() {
    return {
        type: RESET_ADD_OR_EDIT_FIELD_MODAL,
    }
}


export function onFieldAddOrEditInputChange(param) {
    return {
        type: ON_FIELD_ADD_OR_EDIT_INPUT_CHANGE,
        payload: param,
    }
}


function startSubmitAddOrEditField() {
    return {
        type: START_SUBMIT_ADD_OR_EDIT_FIELD,
    };
}
export function submitAddOrEditField(data) {
    return function(dispatch) {
        dispatch(startSubmitAddOrEditField());
        httpClient
        .post(UrlConfig.SUBMIT_ADD_OR_EDIT_FIELD, data)
        .then(resp => dispatch(submitAddOrEditFieldSuccess(resp)))
        .catch(err => dispatch(submitAddOrEditFieldFailure(err)));
    };
}
function submitAddOrEditFieldSuccess(resp) {
    return {
        type: SUBMIT_ADD_OR_EDIT_FIELD_SUCCESS,
        payload: resp,
    };
}
function submitAddOrEditFieldFailure(err) {
    return {
        type: SUBMIT_ADD_OR_EDIT_FIELD_FAILURE,
        payload: err,
    };
}
export function initSubmitAddOrEditFieldActionStatus() {
    return {
        type: INIT_SUBMIT_ADD_OR_EDIT_FIELD_ACTION_STATUS,
    };
}



// del field
function startDelFieldBtnClicked() {
    return {
        type: START_DEL_ELEMENT_TYPE_FIELD,
    };
}
export function delFieldBtnClicked(data) {
    return function(dispatch) {
        dispatch(startDelFieldBtnClicked());
        httpClient
        .post(UrlConfig.DEL_ELEMENT_TYPE_FIELD, data)
        .then(resp => dispatch(delFieldBtnClickedSuccess(resp)))
        .catch(err => dispatch(delFieldBtnClickedFailure(err)));
    };
}
function delFieldBtnClickedSuccess(resp) {
    return {
        type: DEL_ELEMENT_TYPE_FIELD_SUCCESS,
        payload: resp,
    };
}
function delFieldBtnClickedFailure(err) {
    return {
        type: DEL_ELEMENT_TYPE_FIELD_FAILURE,
        payload: err,
    };
}
export function initDelFieldBtnClickedActionStatus() {
    return {
        type: INIT_DEL_ELEMENT_TYPE_FIELD_ACTION_STATUS,
    };
}

