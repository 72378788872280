import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

class TradesReviewGrid extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          columnDefs: props.tradesReviewGridColumns,
          defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 100
          },
          groupDefaultExpanded: 0,
        };
    }


    onGridReady = grid => {
        this.props.addTradesReviewGridToProps(grid);
    }


    render() {
        const {
            queryTradesReviewActionStatus,
            queryTradesReviewActionResp,
        } = this.props;

        const isLoading = queryTradesReviewActionStatus === ACTION_STATUS.LOGINING;
        let dataArray = [];
        if(queryTradesReviewActionResp !== undefined && queryTradesReviewActionResp !== null && queryTradesReviewActionResp.respCode === 'S0001') {
            dataArray = queryTradesReviewActionResp.data;
        }
    

        return (
            <div className={`ag-theme-balham grid-wrapper`}>
                {isLoading ? (
                    <Loader active content="Loading..." />
                ) : (
                <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    rowData={dataArray}
                    defaultColDef={this.state.defaultColDef}
                    rowSelection={this.state.rowSelection}
                    rowClassRules={this.state.rowClassRules}
                    enableSorting={true}
                    enableFilter={true}
                    animateRows={true}
                    deltaRowDataMode={false}
                    enableColResize={true}
                    resizable={true}
                    sideBar={true}
                    getRowNodeId={this.getRowNodeId}
                    // event
                    onGridReady={this.onGridReady}
                />
                )}
            </div>
        );
    }

}

export default TradesReviewGrid;
