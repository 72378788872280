import React, { Component } from 'react';
import { Modal } from 'antd';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import QuestionAttachmentRenderer from '../../../renderer/QuestionAttachmentRenderer';
import './Questionnaire.css';

class AnswerReviewGrid extends Component {

  constructor(props) {
    super(props);

    let that = this;
    this.state = {
      columnDefs: props.questionnaireResultGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100,
      },
      rowSelection: "multiple",
      groupDefaultExpanded: 0,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs:props.questionAnswerGridColumns,
          enableColResize: true,
          enableSorting: true,
          enableFilter: true,
          animateRows: true,
          deltaRowDataMode: false,
          stopEditingWhenGridLosesFocus: true,
          context: { componentParent: that },
          frameworkComponents: {
            questionAttachmentRenderer: QuestionAttachmentRenderer,
          },
          onCellValueChanged: function(params) {
            if (Number.isNaN(Number(params.data.score))) {
              Modal.warning({
                title: 'Reminder',
                content: 'Please enter the integer number as a score',
              });
              return;
            }

            that.props.updateQuestionnaireScore(params.data);
            that.props.calculateQuestionnaireTotalScore();
            that.gridApi.refreshView();
          },
        },
        onGridReady: function(params) {
          params.api.setDomLayout("autoHeight");
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.questionAnswerList);
        },
        getRowNodeId: function(data) {
          return data.guid;
        },
      },
      getRowHeight: function(params) {
        if (params.node && params.node.detail) {
          var offset = 140;
          let length = 2;
          if (params.data.questionAnswerList.length !== 0) {
            length = params.data.questionAnswerList.length;
          }
          var allDetailRowHeight = length * 28;
          return allDetailRowHeight + offset;
        } else {
          return 28;
        }
      },
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var questionnaireResult = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectQuestionnaireResults(questionnaireResult);
  }

  getRowNodeId = data => {
    return data.guid;
  };

  scoreCellValueChanged = (params) => {
    console.log(params);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    // const COLUMNS_KEY = 'fund-list-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      questionnaireResultArray,
      className = 'grid-wrapper',
      loadQuestionnaireResultsActionStatus,
    } = this.props;

    const isLoading = loadQuestionnaireResultsActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={questionnaireResultArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            getRowHeight={this.state.getRowHeight}
            groupDefaultExpanded={this.state.groupDefaultExpanded}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default AnswerReviewGrid;