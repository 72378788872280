import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';
import { Input, Form, Row, Col, Modal, message, Select, Button, Divider, AutoComplete, Upload, Icon } from 'antd';


import {
    // query input
    ON_ELEMENT_QUERY_INPUT_CHANGE,
    // select data
    SELECT_ELEMENT_LIST,
    SELECT_ELEMENT_PROPS_LIST,
    // query element
    START_QUERY_ELEMENT,
    QUERY_ELEMENT_SUCCESS,
    QUERY_ELEMENT_FAILURE,
    INIT_QUERY_ELEMENT_ACTION_STATUS,
    // add or edit element
    RESET_ADD_OR_EDIT_ELEMENT_MODAL,
    CONSTRUCT_ELEMENT_TYPE_SELECT_OPTIONS_AND_OPEN_MODAL,
    CONSTRUCT_ELEMENT_SELECT_OPTIONS,
    ON_ELEMENT_DATA_INPUT_CHANGE,
    RESET_ELEMENT_PROPS,
    RESET_INIT_EDIT_OF_ADD_OR_EDIT_ELEMENT_MODAL,
    APPEND_PROPS_FORM_ITEM_TO_STATE,
    ON_ELEMENT_PROPS_INPUT_CHANGE,
    START_SUBMIT_ADD_OR_EDIT_ELEMENT,
    SUBMIT_ADD_OR_EDIT_ELEMENT_SUCCESS,
    SUBMIT_ADD_OR_EDIT_ELEMENT_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_ACTION_STATUS,
    // del element
    START_DEL_ELEMENT,
    DEL_ELEMENT_SUCCESS,
    DEL_ELEMENT_FAILURE,
    INIT_DEL_ELEMENT_ACTION_STATUS,
    // del props
    START_DEL_PROPS,
    DEL_PROPS_SUCCESS,
    DEL_PROPS_FAILURE,
    INIT_DEL_PROPS_ACTION_STATUS,


} from './ElementMngConstants';


// query input
export function onElementQueryInputChange(param) {
    return {
        type: ON_ELEMENT_QUERY_INPUT_CHANGE,
        payload: param,
    };
}


// select data
export function selectElementList(data) {
    return {
        type: SELECT_ELEMENT_LIST,
        payload: data,
    }
}
export function selectElementPropsList(data) {
    return {
        type: SELECT_ELEMENT_PROPS_LIST,
        payload: data,
    }
}


// query element
function startQueryElement() {
    return {
        type: START_QUERY_ELEMENT,
    };
}
export function queryElement(param) {
    const inputParam = {
        ...param,
        needProps: true,
    };
    return function(dispatch) {
        dispatch(startQueryElement());
        // 每次查询操作需要查两次，第一次用于生成AddOrEdit页面的from/to Props的下拉列表
        httpClient
            .get(UrlConfig.QUERY_ELEMENT, {needProps: false})
            .then(resp => dispatch(constructElementSelectOptions(resp)));
        httpClient
            .get(UrlConfig.QUERY_ELEMENT, inputParam)
            .then(resp => dispatch(queryElementSuccess(resp)))
            .catch(err => dispatch(queryElementFailure(err)));
    };
}
function queryElementSuccess(resp) {
    return {
        type: QUERY_ELEMENT_SUCCESS,
        payload: resp,
    };
}
function queryElementFailure(err) {
    return {
        type: QUERY_ELEMENT_FAILURE,
        payload: err,
    };
}
export function initQueryElementActionStatus() {
    return {
        type: INIT_QUERY_ELEMENT_ACTION_STATUS,
    };
}
function constructElementSelectOptions(resp) {
    return {
        type: CONSTRUCT_ELEMENT_SELECT_OPTIONS,
        payload: resp,
    }
}



// add or edit element
export function openAddOrEditElementModal(mode, data) {
    return function(dispatch) {

        httpClient
            .get(UrlConfig.QUERY_ELEMENT_TYPE, {needField: true})
            .then(resp => dispatch(constructElementTypeSelectOptionsAndOpenModal(resp, mode, data)))
    };
}
function constructElementTypeSelectOptionsAndOpenModal(resp, mode, data) {
    return {
        type: CONSTRUCT_ELEMENT_TYPE_SELECT_OPTIONS_AND_OPEN_MODAL,
        payload: {resp, mode, data},
    }
}
export function resetAddOrEditElementModal() {
    return {
        type: RESET_ADD_OR_EDIT_ELEMENT_MODAL,
    }
}
export function onElementDataInputChange(param) {
    return {
        type: ON_ELEMENT_DATA_INPUT_CHANGE,
        payload: param,
    }
}
export function resetElementProps() {
    return {
        type: RESET_ELEMENT_PROPS,
    }
}
export function resetInitEditOfAddOrEditElementModal() {
    return {
        type: RESET_INIT_EDIT_OF_ADD_OR_EDIT_ELEMENT_MODAL,
    }
}
export function appendPropsFormItemToState(array) {
    return {
        type: APPEND_PROPS_FORM_ITEM_TO_STATE,
        payload: array,
    }
}
export function onElementPropsInputChange(props) {
    return {
        type: ON_ELEMENT_PROPS_INPUT_CHANGE,
        payload: props,
    }
}


function startSubmitAddOrEditElement() {
    return {
        type: START_SUBMIT_ADD_OR_EDIT_ELEMENT,
    };
}
export function submitAddOrEditElement(data) {
    return function(dispatch) {
        dispatch(startSubmitAddOrEditElement());
        httpClient
            .post(UrlConfig.SUBMIT_ADD_OR_EDIT_ELEMENT, data)
            .then(resp => dispatch(submitAddOrEditElementSuccess(resp)))
            .catch(err => dispatch(submitAddOrEditElementFailure(err)));
    };
}
function submitAddOrEditElementSuccess(resp) {
    return {
        type: SUBMIT_ADD_OR_EDIT_ELEMENT_SUCCESS,
        payload: resp,
    };
}
export function submitAddOrEditElementFailure(err) {
    return {
        type: SUBMIT_ADD_OR_EDIT_ELEMENT_FAILURE,
        payload: err,
    };
}
export function initSubmitAddOrEditElementActionStatus() {
    return {
        type: INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_ACTION_STATUS,
    };
}



// del element
function startDelElementBtnClicked() {
    return {
        type: START_DEL_ELEMENT,
    };
}
export function delElementBtnClicked(data) {
    return function(dispatch) {
        dispatch(startDelElementBtnClicked());
        httpClient
        	.post(UrlConfig.DEL_ELEMENT, data)
        	.then(resp => dispatch(delElementBtnClickedSuccess(resp)))
        	.catch(err => dispatch(delElementBtnClickedFailure(err)));
    };
}
function delElementBtnClickedSuccess(resp) {
    return {
        type: DEL_ELEMENT_SUCCESS,
        payload: resp,
    };
}
function delElementBtnClickedFailure(err) {
    return {
        type: DEL_ELEMENT_FAILURE,
        payload: err,
    };
}
export function initDelElementBtnClickedActionStatus() {
    return {
        type: INIT_DEL_ELEMENT_ACTION_STATUS,
    };
}



// del props
function startDelPropsBtnClicked() {
    return {
        type: START_DEL_PROPS,
    };
}
export function delPropsBtnClicked(data) {
    return function(dispatch) {
        dispatch(startDelPropsBtnClicked());
        httpClient
        	.post(UrlConfig.DEL_PROPS, data)
        	.then(resp => dispatch(delPropsBtnClickedSuccess(resp)))
        	.catch(err => dispatch(delPropsBtnClickedFailure(err)));
    };
}
function delPropsBtnClickedSuccess(resp) {
    return {
        type: DEL_PROPS_SUCCESS,
        payload: resp,
    };
}
function delPropsBtnClickedFailure(err) {
    return {
        type: DEL_PROPS_FAILURE,
        payload: err,
    };
}
export function initDelPropsBtnClickedActionStatus() {
    return {
        type: INIT_DEL_PROPS_ACTION_STATUS,
    };
}

