import {connect} from 'react-redux';
import QuestionnairePreview from '../components/QuestionnairePreview';

import {
  loadQuestions,
  initLoadQuestionsActionStatus,
} from '../questionnaireActions';

const mapStateToProps = state => {
  return {
    questionArray: state.questionnaire.questionArray,
    questionnaireTitle: state.questionnaire.questionnaireTitle,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    loadQuestions: (questionnaireGuid) => dispatch(loadQuestions(questionnaireGuid)),
    initLoadQuestionsActionStatus: () => dispatch(initLoadQuestionsActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnairePreview);