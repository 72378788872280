import React, {Component, PropTypes} from 'react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { Breadcrumb, Button, DatePicker } from 'antd';
import moment from 'moment';
import SFCShortableListGrid from './SFCShortableListGrid';
import '../../ComplianceRule.css';

class SFCShortableList extends Component {

  state = {
    searchDate: moment(),
  }

  searchButtonOnClicked = () => {
    this.props.loadSFCShortInterest(this.state.searchDate.format('YYYY-MM-DD'));
  }


  render() {
    const {
      loadSFCShortInterestActionStatus
    } = this.props;
    
    const {
      searchDate
    } = this.state;

    const isLoading = loadSFCShortInterestActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="sfcShortableListWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>SFC Shortable List</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='operationBar'>
          <DatePicker 
            style={{ marginLeft: 10 }}
            allowClear={false}
            value={searchDate}
            onChange={(date, dateString) => {
              this.setState({
                searchDate: date
              })
            }} 
            />

          <Button 
            onClick={this.searchButtonOnClicked} 
            size='small'
            loading={isLoading}
            className='positive-button'>
            Search
          </Button>
        </div>
      
        <div className='sfcShortableListGrid'>
          <SFCShortableListGrid {...this.props} />
        </div>
      </div>
    );
  }
}

export default SFCShortableList;