import React, {Component, PropTypes} from 'react';

import { Radio, Breadcrumb, Button, message, Modal } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedAssetGrid from './RestrictedAssetGrid';
import RestrictedAssetEditDialog from './RestrictedAssetEditDialog';
import RadioOperationMode from '../../../../../utils/RadioOperationMode';
import '../../ComplianceRule.css';

const confirm = Modal.confirm;

class RestrictedAsset extends Component {

  componentDidMount () {
    this.props.loadRestrictedAsset();
  }

  componentDidUpdate() {
    const { 
      batchDeleteRestrictedAssetActionStatus,
    } = this.props;
  
    // 批量删除 Restricted Asset 成功
    if (batchDeleteRestrictedAssetActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted Asset are deleted successfully!');
      this.props.loadRestrictedAsset();
      this.props.initBatchDeleteRestrictedAssetActionStatus();
    }
      
    // 批量删除 Restricted Asset 失败
    if (batchDeleteRestrictedAssetActionStatus === ACTION_STATUS.ERROR) {
      message.success('Restricted Asset are deleted failed!');
      this.props.initBatchDeleteRestrictedAssetActionStatus();
    }
  }

  addButtonOnClicked = () => {
    this.props.openRestrictedAssetEditModalDialog(true, null);
  }

  editButtonOnClicked = () => {
    const {
      selectedRestrictedAsset,
    } = this.props;

    if (selectedRestrictedAsset.length !== 1) {
      message.warning("Please select one restricted asset!");
      return;
    }

    this.props.openRestrictedAssetEditModalDialog(false, selectedRestrictedAsset[0]);
  }

  delButtonOnClicked = () => {
    const {
      selectedRestrictedAsset,
    } = this.props;

    if (selectedRestrictedAsset.length === 0) {
      message.warning("Please select one restricted asset at least!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the restricted asset?',
      content: '',
      onOk() {
        const restrictedAssetIdList = selectedRestrictedAsset.map(restrictedAsset => restrictedAsset.id);

        that.props.batchDeleteRestrictedAsset(restrictedAssetIdList);
      },
      onCancel() {},
    });
  }

  radioGroupOnChanged = (e) => {
    this.props.radioGroupChange(e.target.value);
  }

  render() {
    
    return (
      <div className="restrictedAssetWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>Restricted Asset</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        

        <div className='radioGroupWrapper'>
          <Radio.Group defaultValue="1" onChange={this.radioGroupOnChanged}>
            <Radio.Button value={RadioOperationMode.ALL}>All</Radio.Button>
            <Radio.Button value={RadioOperationMode.WHITE}>White List</Radio.Button>
            <Radio.Button value={RadioOperationMode.BLACK}>Black List</Radio.Button>
          </Radio.Group>
        </div>

        <div className='operationBar'>
          <Button 
            onClick={this.addButtonOnClicked} 
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            className='positive-button'>
            Edit
          </Button>

          <Button 
            onClick={this.delButtonOnClicked} 
            size='small'
            className='negative-button'>
            Del
          </Button>
        </div>

        <div className='restrictedAssetGrid'>
          <RestrictedAssetGrid {...this.props} />
        </div>

        <RestrictedAssetEditDialog {...this.props} />
      </div>
    );
  }
}

export default RestrictedAsset;