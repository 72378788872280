import React, {Component} from "react";
import BaseUrlConfig from '../../net/BaseUrlConfig';
import UrlConfig from '../../net/UrlConfig';

class QuestionAttachmentRenderer extends Component {

  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
    this.previewImgPath = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_QUESTIONNAIRE_ATTACHMENT;
  }

  invokeParentMethod () {

  }

  render () {
    const node = this.props.node;

    let url = '';
    let answerContent = '';
    if (!!node) {
      const questionAnswer = node.data;

      if (!!questionAnswer) {
        answerContent = questionAnswer.answerContent;

        // 问题类型为Upload
        if (questionAnswer.questionType === '5') {
          url = this.previewImgPath + '/' + answerContent;
        }
      }
    }

    return (
      <div>
        {
          url === '' ? answerContent :
          <a href={url} target="_blank">{answerContent}</a>
        }
      </div>
    );
  }
}

export default QuestionAttachmentRenderer;