import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedSecurityOpRecordGrid from './RestrictedSecurityOpRecordGrid';

class RestrictedSecurityOpRecordDialog extends Component {

  refreshButtonOnClicked = () => {
    this.props.loadRestrictedSecurityOperationRecord();
  }

  render() {
    const {
      loadRestrictedSecurityOperationRecordActionStatus,
      restrictedSecurityOperationRecordControlModal,
    } = this.props;

    const {
      isOpened,
    } = restrictedSecurityOperationRecordControlModal;

    const isLoading = loadRestrictedSecurityOperationRecordActionStatus === ACTION_STATUS.LOGINING;

    let modalTitle = 'Restricted Security Operation Record';

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          onOk={() => this.props.resetRestrictedSecurityOperationRecordModalDialog()}
          onCancel={() => this.props.resetRestrictedSecurityOperationRecordModalDialog()}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <div style={{ marginBottom: 10 }}>
            <Button 
              onClick={this.refreshButtonOnClicked} 
              size='small'
              style={{ fontSize: 10 }}
              loading={isLoading}
              type='primary'>
              Refresh
            </Button>
          </div>

          <div style={{ height: 500 }}>
            <RestrictedSecurityOpRecordGrid {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default RestrictedSecurityOpRecordDialog;