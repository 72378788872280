import {connect} from 'react-redux';
import PresonalInfoDashboard from '../components/PresonalInfoDashboard';


import {
    // query
    onQueryInputChange,
    loadPresonalInfoInfo,
    initLoadPresonalInfoInfoActionStatus,
    // select
    selectPresonalInfo,
    // add or edit
    openAddOrEditPresonalInfoModal,
    addOrEditPresonalInfoChangeInput,
    resetSubmitSavePresonalInfoModal,
    submitSavePresonalInfo,
    submitSavePresonalInfoFailure,
    initSubmitSavePresonalInfoActionStatus,

    //Sub add or edit
    openAddOrEditPresonalInfoSubModal,
    addOrEditPresonalInfoSubChangeInput,
    resetSubmitSavePresonalInfoSubModal,
    submitSavePresonalInfoSub,
    submitSavePresonalInfoSubFailure,
    initSubmitSavePresonalInfoSubActionStatus,



    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForPresonalInfo,
    initBatchChangeStatusForPresonalInfoActionStatus,
    // finish
    selectPresonalInfoTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishPresonalInfoTask,
    initFinishPresonalInfoTaskActionStatus,


    openQuestionnaireEmailEditModalDialog,
    resetQuestionnaireEmailEditModalDialog,

  // Accept Questionnaire Result
  acceptQuestionnaireResult,
  initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  rejectQuestionnaireResult,
  initRejectQuestionnaireResultActionStatus,

    // Send Remind Email
    sendRemindEmail,
    initSendRemindEmailActionStatus,


} from '../PresonalInfoActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';

import {
    queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'

import {
    loadLicensingForm,
} from '../../licensingForm/licensingFormActions'
const mapStateToProps = state => {
    return {
        // basic options
        locationOptions: state.presonalInfo.locationOptions,
        fundCompanyOptions: state.presonalInfo.fundCompanyOptions,
        frequencyOptions: state.presonalInfo.frequencyOptions,
        weekdayOptions: state.presonalInfo.weekdayOptions,
        monthOptions: state.presonalInfo.monthOptions,
        responsibleDeptOptions: state.presonalInfo.responsibleDeptOptions,
        licensingFormOptions: state.presonalInfo.licensingFormOptions,
        departmentOptions: state.presonalInfo.departmentOptions,
        officeOptions: state.presonalInfo.officeOptions,
        statusOptions: state.presonalInfo.statusOptions,
        importanceOptions: state.presonalInfo.importanceOptions,
        userAccountOptions: state.userAccess.userAccountOptions,

        // query
        presonalInfoQueryFields: state.presonalInfo.presonalInfoQueryFields,
        loadPresonalInfoInfoActionStatus: state.presonalInfo.loadPresonalInfoInfoActionStatus,
        loadPresonalInfoInfoResp: state.presonalInfo.loadPresonalInfoInfoResp,
        // select
        selectedPresonalInfoArray: state.presonalInfo.selectedPresonalInfoArray,
        // add or edit
        addOrEditPresonalInfoModal: state.presonalInfo.addOrEditPresonalInfoModal,
        addOrEditPresonalInfoStatus: state.presonalInfo.addOrEditPresonalInfoStatus,

        // sub add or edit
        addOrEditPresonalInfoSubModal: state.presonalInfo.addOrEditPresonalInfoSubModal,
        addOrEditPresonalInfoSubStatus: state.presonalInfo.addOrEditPresonalInfoSubStatus,



        // batch change status
        batchChangeStatusModal: state.presonalInfo.batchChangeStatusModal,
        batchChangeStatusForPresonalInfoStatus: state.presonalInfo.batchChangeStatusForPresonalInfoStatus,
        // finish
        selectedPresonalInfoTaskArray: state.presonalInfo.selectedPresonalInfoTaskArray,
        finishTaskModal: state.presonalInfo.finishTaskModal,
        finishPresonalInfoTaskStatus: state.presonalInfo.finishPresonalInfoTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.presonalInfo.userAccountEmailOptions,
            // Open/Reset Questionnaire Email Edit Modal Dialog
        questionnaireEmailEditControlModal: state.presonalInfo.questionnaireEmailEditControlModal,

        
        // Accept Questionnaire Result
        acceptQuestionnaireResultActionStatus: state.presonalInfo.acceptQuestionnaireResultActionStatus,

        // Reject Questionnaire Result
        rejectQuestionnaireResultActionStatus: state.presonalInfo.rejectQuestionnaireResultActionStatus,
        // Send Remind Email
        sendRemindEmailActionStatus: state.presonalInfo.sendRemindEmailActionStatus,

    

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadPresonalInfoInfo: (params) => dispatch(loadPresonalInfoInfo(params)),
        initLoadPresonalInfoInfoActionStatus: () => dispatch(initLoadPresonalInfoInfoActionStatus()),
        // select
        selectPresonalInfo: (array) => dispatch(selectPresonalInfo(array)),
        // add or edit
        openAddOrEditPresonalInfoModal: (mode, data) => dispatch(openAddOrEditPresonalInfoModal(mode, data)),
        addOrEditPresonalInfoChangeInput: (data) => dispatch(addOrEditPresonalInfoChangeInput(data)),
        resetSubmitSavePresonalInfoModal: () => dispatch(resetSubmitSavePresonalInfoModal()),
        submitSavePresonalInfo: (bean) => dispatch(submitSavePresonalInfo(bean)),
        submitSavePresonalInfoFailure: (err) => dispatch(submitSavePresonalInfoFailure(err)),
        initSubmitSavePresonalInfoActionStatus: () => dispatch(initSubmitSavePresonalInfoActionStatus()),


        //sub add or edit
        openAddOrEditPresonalInfoSubModal: (mode, data,idList) => dispatch(openAddOrEditPresonalInfoSubModal(mode, data,idList)),
        addOrEditPresonalInfoSubChangeInput: (data) => dispatch(addOrEditPresonalInfoSubChangeInput(data)),
        resetSubmitSavePresonalInfoSubModal: () => dispatch(resetSubmitSavePresonalInfoSubModal()),
        submitSavePresonalInfoSub: (bean) => dispatch(submitSavePresonalInfoSub(bean)),
        submitSavePresonalInfoSubFailure: (err) => dispatch(submitSavePresonalInfoSubFailure(err)),
        initSubmitSavePresonalInfoSubActionStatus: () => dispatch(initSubmitSavePresonalInfoSubActionStatus()),



        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForPresonalInfo: (params) => dispatch(batchChangeStatusForPresonalInfo(params)),
        initBatchChangeStatusForPresonalInfoActionStatus: () => dispatch(initBatchChangeStatusForPresonalInfoActionStatus()),
        // finish
        selectPresonalInfoTask: (array) => dispatch(selectPresonalInfoTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishPresonalInfoTask: (task) => dispatch(finishPresonalInfoTask(task)),
        initFinishPresonalInfoTaskActionStatus: () => dispatch(initFinishPresonalInfoTaskActionStatus()),
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


        // Open/Reset Questionnaire Email Edit Modal Dialog
        openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
        resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),



        // Accept  Result
        acceptQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
        initAcceptQuestionnaireResultActionStatus: () => dispatch(initAcceptQuestionnaireResultActionStatus()),

        loadLicensingForm: (params) => dispatch(loadLicensingForm(params)),
        
        // Reject Questionnaire Result
        rejectQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
        initRejectQuestionnaireResultActionStatus: () => dispatch(initRejectQuestionnaireResultActionStatus()),

        // Send Remind Email
        sendRemindEmail: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent)),
        initSendRemindEmailActionStatus: () => dispatch(initSendRemindEmailActionStatus()),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(PresonalInfoDashboard);
