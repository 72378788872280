import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { localStorageClient } from '../../../utils/localStorageClient';

import {
  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
  LOAD_USER_ACCOUNTS_FAILURE,

  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,
  LOAD_SECURITY_TICKERS_FAILURE,

  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,
  LOAD_FUND_BOOK_DATA_FAILURE,

  // Load All BBG Tickers
  LOAD_ALL_BBG_TICKERS_SUCCESS,
  LOAD_ALL_BBG_TICKERS_FAILURE,

  // Load Asset Inst Class
  LOAD_ASSET_INST_CLASS_SUCCESS,
  LOAD_ASSET_INST_CLASS_FAILURE,

  // Load Country Codes
  LOAD_COUNTRY_CODES_SUCCESS,
  LOAD_COUNTRY_CODES_FAILURE,

    // Load Currency Codes
    LOAD_CURRENCY_CODES_SUCCESS,
    LOAD_CURRENCY_CODES_FAILURE,

  // Load Sector Codes
  LOAD_SECTOR_CODES_SUCCESS,
  LOAD_SECTOR_CODES_FAILURE,

  // get hierarchy menus
  GET_HIERARCHY_MENUS_SUCCESS,
  GET_HIERARCHY_MENUS_FAILURE,


} from './homeConstants';

import { createReducer } from '../../../utils/reducerUtil';

const initialState = {
  // userAccount
  userAccountArray: [],

  // Security Tickers Data
  securityTickerData: null,

  // Fund Book Data
  fundBookData: null,
  
  // All Bbg Tickers
  allBbgTickers: [],

  // Load Asset Inst Class
  assetClassArray: [],
  instClassArray: [],

  // Load Country Codes
  countryCodeDOArray: [],

    // Load Currency Codes
  currencyCodeDOArray: [],

  // Load Sector Codes
  sectorCodeDOArray: [],
}

function loadUserAccountsSuccess(state, resp) {
  return {
    ...state,
    userAccountArray: resp.data,
  };
}

function loadUserAccountsFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  return {
    ...state,
    securityTickerData: resp.data,
  };
}

function loadSecurityTickersFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  return {
    ...state,
    fundBookData: resp.data,
  };
}

function loadFundBookDataFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load All BBG Tickers
function loadAllBbgTickersSuccess(state, resp) {
  return {
    ...state,
    allBbgTickers: resp.data,
  };
}

function loadAllBbgTickersFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load Asset Inst Class
function loadAssetInstClassSuccess(state, resp) {
  const assetInstClass = resp.data;
  const assetClassArray = assetInstClass.assetClassList;
  const instClassArray = assetInstClass.instClassList;

  return {
    ...state,
    assetInstClass,
    assetClassArray,
    instClassArray,
  };
}

function loadAssetInstClassFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load Country Codes
function loadCountryCodesSuccess(state, resp) {
  return {
    ...state,
    countryCodeDOArray: resp.data,
  };
}

function loadCountryCodesFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load Currency Codes
function loadCurrencyCodesSuccess(state, resp) {
  return {
    ...state,
    currencyCodeDOArray: resp.data,
  };
}

function loadCurrencyCodesFailure(state, err) {
  return {
    ...state,
  };
}




// =================================================================
// Load Sector Codes
function loadSectorCodesSuccess(state, resp) {
  return {
    ...state,
    sectorCodeDOArray: resp.data,
  };
}

function loadSectorCodesFailure(state, err) {
  return {
    ...state,
  };
}


// get hierarchy menus
function getHierarchyMenusSuccess(state, resp) {
  const hierarchyMenus = resp.data.hierarchyMenus;
  const flatMenus = resp.data.flatMenus;
  const hierarchyMenuOptions = [];
  hierarchyMenus.forEach(m => {
    hierarchyMenuOptions.push(recursiveCreateMenu(m));
  })
  return {
    ...state,
    hierarchyMenus: hierarchyMenus,
    flatMenus: flatMenus,
    hierarchyMenuOptions: hierarchyMenuOptions,
  };
}
function getHierarchyMenusFailure(state, err) {
  return {
    ...state,
  };
}
// 工具方法，递归构造一个菜单
function recursiveCreateMenu(menu) {
  if(menu.type === 'Item') {
    // 检查当前用户是否有此菜单权限
    // const userRoleStr = localStorageClient.getRole();
    // const userRoleArray = userRoleStr.split(',');
    // const allowedRoleArray = menu.accessableRoleNames;
    // let authorized = false;
    // for(let i in userRoleArray) {
    //   if(userRoleArray[i] === 'CMS_ADMIN_USER') {
    //     authorized = true;  // 默认CMS_ADMIN_USER可以访问所有菜单
    //     break;
    //   }
    //   for(let j in allowedRoleArray) {
    //     if(userRoleArray[i] === allowedRoleArray[j]){
    //       authorized = true;
    //       break;
    //     }
    //   }
    //   if(authorized) {
    //     break;
    //   }
    // }
    // 返回
    return (
      <Menu.Item key={menu.permission.permissionId}>
        <NavLink to={menu.module}>{menu.name}</NavLink>
      </Menu.Item>
    );

  } else {
    // 先生成下级结构
    const subMenuOptions = [];
    menu.directSubMenus.forEach(e => {
      subMenuOptions.push(recursiveCreateMenu(e));
    })
    return (
      <Menu.SubMenu key={menu.permission.permissionId} title={menu.name}>
        {subMenuOptions}
      </Menu.SubMenu>
    );
  }
}



export default createReducer(initialState, {
  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
  [LOAD_USER_ACCOUNTS_FAILURE]: loadUserAccountsFailure,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,
  [LOAD_SECURITY_TICKERS_FAILURE]: loadSecurityTickersFailure,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,
  [LOAD_FUND_BOOK_DATA_FAILURE]: loadFundBookDataFailure,

  // Load All BBG Tickers
  [LOAD_ALL_BBG_TICKERS_SUCCESS]: loadAllBbgTickersSuccess,
  [LOAD_ALL_BBG_TICKERS_FAILURE]: loadAllBbgTickersFailure,

  // Load Asset Inst Class
  [LOAD_ASSET_INST_CLASS_SUCCESS]: loadAssetInstClassSuccess,
  [LOAD_ASSET_INST_CLASS_FAILURE]: loadAssetInstClassFailure,

  // Load Country Codes
  [LOAD_COUNTRY_CODES_SUCCESS]: loadCountryCodesSuccess,
  [LOAD_COUNTRY_CODES_FAILURE]: loadCountryCodesFailure,

    // Load Currency Codes
    [LOAD_CURRENCY_CODES_SUCCESS]: loadCurrencyCodesSuccess,
    [LOAD_CURRENCY_CODES_FAILURE]: loadCurrencyCodesFailure,

  // Load Sector Codes
  [LOAD_SECTOR_CODES_SUCCESS]: loadSectorCodesSuccess,
  [LOAD_SECTOR_CODES_FAILURE]: loadSectorCodesFailure,

  // get hierarchy menus
  [GET_HIERARCHY_MENUS_SUCCESS]: getHierarchyMenusSuccess,
  [GET_HIERARCHY_MENUS_FAILURE]: getHierarchyMenusFailure,

});
