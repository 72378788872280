// Load Restricted Asset
export const START_LOAD_RESTRICTED_ASSET = 'START_LOAD_RESTRICTED_ASSET';
export const LOAD_RESTRICTED_ASSET_SUCCESS = 'LOAD_RESTRICTED_ASSET_SUCCESS';
export const LOAD_RESTRICTED_ASSET_FAILURE = 'LOAD_RESTRICTED_ASSET_FAILURE';
export const INIT_LOAD_RESTRICTED_ASSET_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_ASSET_ACTION_STATUS';

// Create Restricted Asset
export const START_CREATE_RESTRICTED_ASSET = 'START_CREATE_RESTRICTED_ASSET';
export const CREATE_RESTRICTED_ASSET_SUCCESS = 'CREATE_RESTRICTED_ASSET_SUCCESS';
export const CREATE_RESTRICTED_ASSET_FAILURE = 'CREATE_RESTRICTED_ASSET_FAILURE';
export const INIT_CREATE_RESTRICTED_ASSET_ACTION_STATUS = 'INIT_CREATE_RESTRICTED_ASSET_ACTION_STATUS';

// Update Restricted Asset
export const START_UPDATE_RESTRICTED_ASSET = 'START_UPDATE_RESTRICTED_ASSET';
export const UPDATE_RESTRICTED_ASSET_SUCCESS = 'UPDATE_RESTRICTED_ASSET_SUCCESS';
export const UPDATE_RESTRICTED_ASSET_FAILURE = 'UPDATE_RESTRICTED_ASSET_FAILURE';
export const INIT_UPDATE_RESTRICTED_ASSET_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_ASSET_ACTION_STATUS';

// Batch Delete Restricted Asset
export const START_BATCH_DELETE_RESTRICTED_ASSET = 'START_BATCH_DELETE_RESTRICTED_ASSET';
export const BATCH_DELETE_RESTRICTED_ASSET_SUCCESS = 'BATCH_DELETE_RESTRICTED_ASSET_SUCCESS';
export const BATCH_DELETE_RESTRICTED_ASSET_FAILURE = 'BATCH_DELETE_RESTRICTED_ASSET_FAILURE';
export const INIT_BATCH_DELETE_RESTRICTED_ASSET_ACTION_STATUS = 'INIT_BATCH_DELETE_RESTRICTED_ASSET_ACTION_STATUS';

// Open/Reset Restricted Asset Modal Dialog
export const RESTRICTED_ASSET_MODAL_DIALOG_OPEN = 'RESTRICTED_ASSET_MODAL_DIALOG_OPEN';
export const RESTRICTED_ASSET_MODAL_DIALOG_RESET = 'RESTRICTED_ASSET_MODAL_DIALOG_RESET';

// Restricted Asset Edit Change Input
export const RESTRICTED_ASSET_EDIT_CHANGE_INPUT = 'RESTRICTED_ASSET_EDIT_CHANGE_INPUT';

// RadioGroup Change
export const RADIO_GROUP_CHANGE = 'RADIO_GROUP_CHANGE';


// Select Restricted Asset
export const SELECT_RESTRICTED_ASSET_CLASS = 'SELECT_RESTRICTED_ASSET_CLASS';

// Fund Code Select Changed
export const FUND_CODE_SELECT_CHANGED = 'FUND_CODE_SELECT_CHANGED';

// Book Code Select Changed
export const BOOK_CODE_SELECT_CHANGED = 'BOOK_CODE_SELECT_CHANGED';





