import {
  // query
  ON_QUERY_INPUT_CHANGE,
  // Load LicensingForm
  START_LOAD_LICENSINGFORM,
  LOAD_LICENSINGFORM_SUCCESS,
  LOAD_LICENSINGFORM_FAILURE,
  INIT_LOAD_LICENSINGFORM_ACTION_STATUS,

  // Select LicensingForm
  SELECT_LICENSINGFORM,
  
  // Load Question
  START_LOAD_QUESTIONS,
  LOAD_QUESTIONS_SUCCESS,
  LOAD_QUESTIONS_FAILURE,
  INIT_LOAD_QUESTIONS_ACTION_STATUS,

  // Select Questions
  SELECT_QUESTIONS,

  // Open/Reset Answeree Select Modal Dialog
  ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
  ANSWEREE_SELECT_MODAL_DIALOG_RESET,

  // Select All Answeree
  SELECT_ALL_ANSWEREE,

  // Select Invert Answeree
  SELECT_INVERT_ANSWEREE,

  // Answeree Check Changed
  ANSWEREE_CHECK_CHANGED,

  // Select Answerees
  START_SELECT_ANSWEREES,
  SELECT_ANSWEREES_SUCCESS,
  SELECT_ANSWEREES_FAILURE,
  INIT_SELECT_ANSWEREES_ACTION_STATUS,


  // Load Answers
  START_LOAD_ANSWERS,
  LOAD_ANSWERS_SUCCESS,
  LOAD_ANSWERS_FAILURE,
  INIT_LOAD_ANSWERS_ACTION_STATUS,

  // Load To-do LicensingForm
  START_LOAD_TODO_LICENSINGFORM,
  LOAD_TODO_LICENSINGFORM_SUCCESS,
  LOAD_TODO_LICENSINGFORM_FAILURE,
  INIT_LOAD_TODO_LICENSINGFORM_ACTION_STATUS,

  // Submit LicensingForm Answer
  START_SUBMIT_LICENSINGFORM_ANSWER,
  SUBMIT_LICENSINGFORM_ANSWER_SUCCESS,
  SUBMIT_LICENSINGFORM_ANSWER_FAILURE,
  INIT_SUBMIT_LICENSINGFORM_ANSWER_ACTION_STATUS,

  // Save LicensingForm Answer
  START_SAVE_LICENSINGFORM_ANSWER,
  SAVE_LICENSINGFORM_ANSWER_SUCCESS,
  SAVE_LICENSINGFORM_ANSWER_FAILURE,
  INIT_SAVE_LICENSINGFORM_ANSWER_ACTION_STATUS,

  // LicensingForm Answer Change Input
  LICENSINGFORM_ANSWER_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset LicensingForm Edit Modal Dialog
  LICENSINGFORM_EDIT_MODAL_DIALOG_OPEN,
  LICENSINGFORM_EDIT_MODAL_DIALOG_RESET,

  // Create LicensingForm
  START_CREATE_LICENSINGFORM,
  CREATE_LICENSINGFORM_SUCCESS,
  CREATE_LICENSINGFORM_FAILURE,
  INIT_CREATE_LICENSINGFORM_ACTION_STATUS,

  // Update LicensingForm
  START_UPDATE_LICENSINGFORM,
  UPDATE_LICENSINGFORM_SUCCESS,
  UPDATE_LICENSINGFORM_FAILURE,
  INIT_UPDATE_LICENSINGFORM_ACTION_STATUS,

  // Copy LicensingForm
  START_COPY_LICENSINGFORM,
  COPY_LICENSINGFORM_SUCCESS,
  COPY_LICENSINGFORM_FAILURE,
  INIT_COPY_LICENSINGFORM_ACTION_STATUS,

  // Batch Delete LicensingForm
  START_BATCH_DELETE_LICENSINGFORM,
  BATCH_DELETE_LICENSINGFORM_SUCCESS,
  BATCH_DELETE_LICENSINGFORM_FAILURE,
  INIT_BATCH_DELETE_LICENSINGFORM_ACTION_STATUS,

  // LicensingForm Edit Change Input
  LICENSINGFORM_EDIT_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  QUESTION_EDIT_MODAL_DIALOG_OPEN,
  QUESTION_EDIT_MODAL_DIALOG_RESET,

  // Create Question
  START_CREATE_QUESTION,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  INIT_CREATE_QUESTION_ACTION_STATUS,

  // Update Question
  START_UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  INIT_UPDATE_QUESTION_ACTION_STATUS,

  // Batch Delete Question
  START_BATCH_DELETE_QUESTION,
  BATCH_DELETE_QUESTION_SUCCESS,
  BATCH_DELETE_QUESTION_FAILURE,
  INIT_BATCH_DELETE_QUESTION_ACTION_STATUS,

  // Question Edit Change Input
  QUESTION_EDIT_CHANGE_INPUT,

  // LicensingForm Option Change
  LICENSINGFORM_OPTION_CHANGE,

  // Load LicensingForm Results
  START_LOAD_LICENSINGFORM_RESULTS,
  LOAD_LICENSINGFORM_RESULTS_SUCCESS,
  LOAD_LICENSINGFORM_RESULTS_FAILURE,
  INIT_LOAD_LICENSINGFORM_RESULTS_ACTION_STATUS,

  // Select LicensingForm Results
  SELECT_LICENSINGFORM_RESULTS,

  // Load LicensingForm Result Detail
  START_LOAD_LICENSINGFORM_RESULT_DETAIL,
  LOAD_LICENSINGFORM_RESULT_DETAIL_SUCCESS,
  LOAD_LICENSINGFORM_RESULT_DETAIL_FAILURE,
  INIT_LOAD_LICENSINGFORM_RESULT_DETAIL_ACTION_STATUS,

  // Accept LicensingForm Result
  START_ACCEPT_LICENSINGFORM_RESULT,
  ACCEPT_LICENSINGFORM_RESULT_SUCCESS,
  ACCEPT_LICENSINGFORM_RESULT_FAILURE,
  INIT_ACCEPT_LICENSINGFORM_RESULT_ACTION_STATUS,

  // Reject LicensingForm Result
  START_REJECT_LICENSINGFORM_RESULT,
  REJECT_LICENSINGFORM_RESULT_SUCCESS,
  REJECT_LICENSINGFORM_RESULT_FAILURE,
  INIT_REJECT_LICENSINGFORM_RESULT_ACTION_STATUS,

  // Open/Reset LicensingForm Result Operate Modal Dialog
  LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_OPEN,
  LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_RESET,

  // LicensingForm Answer Submitter Edit Change Input
  LICENSINGFORM_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,

  // Send Remind Email
  START_SEND_REMIND_EMAIL,
  SEND_REMIND_EMAIL_SUCCESS,
  SEND_REMIND_EMAIL_FAILURE,
  INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

  // Open/Reset LicensingForm Email Edit Modal Dialog
  LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_OPEN,
  LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_RESET,

  // Update LicensingForm Score
  START_UPDATE_LICENSINGFORM_SCORE,
  UPDATE_LICENSINGFORM_SCORE_SUCCESS,
  UPDATE_LICENSINGFORM_SCORE_FAILURE,
  INIT_UPDATE_LICENSINGFORM_SCORE_ACTION_STATUS,

  // Calculate LicensingForm Total Score
  CALC_LICENSINGFORM_TOTAL_SCORE,

  // Open/Reset Submitted LicensingForm History Modal Dialog
  SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_OPEN,
  SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_RESET,

  // Load Submitted LicensingForm History
  START_LOAD_SUBMITTED_LICENSINGFORM_HISTORY,
  LOAD_SUBMITTED_LICENSINGFORM_HISTORY_SUCCESS,
  LOAD_SUBMITTED_LICENSINGFORM_HISTORY_FAILURE,
  INIT_LOAD_SUBMITTED_LICENSINGFORM_HISTORY_ACTION_STATUS,

  // Select Submitted LicensingForm History
  SELECT_SUBMITTED_LICENSINGFORM_HISTORY,

  // Apply Submitted LicensingForm History
  APPLY_SUBMITTED_LICENSINGFORM_HISTORY,

} from './licensingFormConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';

// =================================================================

// query
export function onQueryInputChange({name, value}) {
  return {
      type: ON_QUERY_INPUT_CHANGE,
      payload: {
        name,
        value,
      }
  };
}
// Load LicensingForm
function startLoadLicensingForm() {
  return {
    type: START_LOAD_LICENSINGFORM
  };
}

export function loadLicensingForm(params) {
  return function(dispatch) {
    dispatch(startLoadLicensingForm());
    httpClient
      .get(UrlConfig.QUERY_LICENSINGFORM,params)
      .then(resp => dispatch(loadLicensingFormSuccess(resp)))
      .catch(err => dispatch(loadLicensingFormFailure(err)));
  };
}

function loadLicensingFormSuccess(resp) {
  return {
    type: LOAD_LICENSINGFORM_SUCCESS,
    payload: resp
  };
}

function loadLicensingFormFailure(err) {
  return {
    type: LOAD_LICENSINGFORM_FAILURE,
    payload: err
  };
}

export function initLoadLicensingFormActionStatus() {
  return {
    type: INIT_LOAD_LICENSINGFORM_ACTION_STATUS
  };
}

// Select LicensingForm
export function selectLicensingForm(payload) {
  return {
    type: SELECT_LICENSINGFORM,
    payload
  };
}




// =================================================================
// Load Question
function startLoadQuestions() {
  return {
    type: START_LOAD_QUESTIONS
  };
}

export function loadQuestions(licensingFormGuid) {
  const requestParams = {
    'licensingForm-guid': licensingFormGuid,
  };

  return function(dispatch) {
    dispatch(startLoadQuestions());
    httpClient
      .get(UrlConfig.QUERY_QUESTIONS, requestParams)
      .then(resp => dispatch(loadQuestionsSuccess(resp)))
      .catch(err => dispatch(loadQuestionsFailure(err)));
  };
}

function loadQuestionsSuccess(resp) {
  return {
    type: LOAD_QUESTIONS_SUCCESS,
    payload: resp
  };
}

function loadQuestionsFailure(err) {
  return {
    type: LOAD_QUESTIONS_FAILURE,
    payload: err
  };
}

export function initLoadQuestionsActionStatus() {
  return {
    type: INIT_LOAD_QUESTIONS_ACTION_STATUS
  };
}

// Select Questions
export function selectQuestions(payload) {
  return {
    type: SELECT_QUESTIONS,
    payload
  };
}

// Open/Reset Answeree Select Modal Dialog
export function openAnswereeSelectModalDialog() {
  return {
    type: ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    payload: {
      
    },
  };
}

export function resetAnswereeSelectModalDialog() {
  return {
    type: ANSWEREE_SELECT_MODAL_DIALOG_RESET
  };
}

// Select All Answeree
export function selectAllAnsweree() {
  return {
    type: SELECT_ALL_ANSWEREE
  };
}

// Select Invert Answeree
export function selectInvertAnsweree() {
  return {
    type: SELECT_INVERT_ANSWEREE
  };
}

// Answeree Check Changed
export function answereeCheckChanged(checked ,userAccountName) {
  return {
    type: ANSWEREE_CHECK_CHANGED,
    payload: {
      checked,
      userAccountName
    }
  };
}

// Select Answerees
function startSelectAnswerees() {
  return {
    type: START_SELECT_ANSWEREES
  };
}

export function selectAnswerees(userAccountNameList, licensingFormGuid, emailTitle, emailContent) {
  const requestParams = {
    userAccountNameList,
    licensingFormGuid, 
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startSelectAnswerees());
    httpClient
      .post(UrlConfig.SELECT_ANSWEREES, requestParams)
      .then(resp => dispatch(selectAnswereesSuccess(resp)))
      .catch(err => dispatch(selectAnswereesFailure(err)));
  };
}

function selectAnswereesSuccess(resp) {
  return {
    type: SELECT_ANSWEREES_SUCCESS,
    payload: resp
  };
}

function selectAnswereesFailure(err) {
  return {
    type: SELECT_ANSWEREES_FAILURE,
    payload: err
  };
}

export function initSelectAnswereesActionStatus() {
  return {
    type: INIT_SELECT_ANSWEREES_ACTION_STATUS
  };
}


















// =================================================================
// Load Answers
function startLoadAnswers() {
  return {
    type: START_LOAD_ANSWERS
  };
}

export function loadAnswers(params) {

  let licensingFormGuid = '';
  let userAccountName = '';
  if (params) {
    licensingFormGuid = params.licensingFormGuid || '';
    userAccountName = params.userAccountName || '';
  }

  const requestParams = {
    licensingFormGuid,
    userAccountName,
  };

  return function(dispatch) {
    dispatch(startLoadAnswers());
    httpClient
      .get(UrlConfig.QUERY_ANSWERS, requestParams)
      .then(resp => dispatch(loadAnswersSuccess(resp)))
      .catch(err => dispatch(loadAnswersFailure(err)));
  };
}

function loadAnswersSuccess(resp) {
  return {
    type: LOAD_ANSWERS_SUCCESS,
    payload: resp
  };
}

function loadAnswersFailure(err) {
  return {
    type: LOAD_ANSWERS_FAILURE,
    payload: err
  };
}

export function initLoadAnswersActionStatus() {
  return {
    type: INIT_LOAD_ANSWERS_ACTION_STATUS
  };
}





// Load To-do LicensingForm
function startLoadTodoLicensingForm() {
  return {
    type: START_LOAD_TODO_LICENSINGFORM
  };
}

export function loadTodoLicensingForm(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadTodoLicensingForm(requestParams));
    httpClient
      .get(UrlConfig.QUERY_TODO_LICENSINGFORM, requestParams)
      .then(resp => dispatch(loadTodoLicensingFormSuccess(resp)))
      .catch(err => dispatch(loadTodoLicensingFormFailure(err)));
  };
}

function loadTodoLicensingFormSuccess(resp) {
  return {
    type: LOAD_TODO_LICENSINGFORM_SUCCESS,
    payload: resp
  };
}

function loadTodoLicensingFormFailure(err) {
  return {
    type: LOAD_TODO_LICENSINGFORM_FAILURE,
    payload: err
  };
}

export function initLoadTodoLicensingFormActionStatus() {
  return {
    type: INIT_LOAD_TODO_LICENSINGFORM_ACTION_STATUS
  };
}


// Submit LicensingForm Answer
function startSubmitLicensingFormAnswer() {
  return {
    type: START_SUBMIT_LICENSINGFORM_ANSWER
  };
}

export function submitLicensingFormAnswer(authToken, licensingFormAnswerList, submitter, submittedDate) {
  const requestParams = {
    authToken,
    submitter,
    submittedDate,
    licensingFormAnswerList,
  };

  return function(dispatch) {
    dispatch(startSubmitLicensingFormAnswer(requestParams));
    httpClient
      .post(UrlConfig.SUBMIT_LICENSINGFORM_ANSWER, requestParams)
      .then(resp => dispatch(submitLicensingFormAnswerSuccess(resp)))
      .catch(err => dispatch(submitLicensingFormAnswerFailure(err)));
  };
}

function submitLicensingFormAnswerSuccess(resp) {
  return {
    type: SUBMIT_LICENSINGFORM_ANSWER_SUCCESS,
    payload: resp
  };
}

function submitLicensingFormAnswerFailure(err) {
  return {
    type: SUBMIT_LICENSINGFORM_ANSWER_FAILURE,
    payload: err
  };
}

export function initSubmitLicensingFormAnswerActionStatus() {
  return {
    type: INIT_SUBMIT_LICENSINGFORM_ANSWER_ACTION_STATUS
  };
}

// Save LicensingForm Answer
function startSaveLicensingFormAnswer() {
  return {
    type: START_SAVE_LICENSINGFORM_ANSWER
  };
}

export function saveLicensingFormAnswer(authToken, licensingFormAnswerList, submitter, submittedDate) {
  const requestParams = {
    authToken,
    submitter,
    submittedDate,
    licensingFormAnswerList,
  };

  return function(dispatch) {
    dispatch(startSaveLicensingFormAnswer(requestParams));
    httpClient
      .post(UrlConfig.SAVE_LICENSINGFORM_ANSWER, requestParams)
      .then(resp => dispatch(saveLicensingFormAnswerSuccess(resp)))
      .catch(err => dispatch(saveLicensingFormAnswerFailure(err)));
  };
}

function saveLicensingFormAnswerSuccess(resp) {
  return {
    type: SAVE_LICENSINGFORM_ANSWER_SUCCESS,
    payload: resp
  };
}

function saveLicensingFormAnswerFailure(err) {
  return {
    type: SAVE_LICENSINGFORM_ANSWER_FAILURE,
    payload: err
  };
}

export function initSaveLicensingFormAnswerActionStatus() {
  return {
    type: INIT_SAVE_LICENSINGFORM_ANSWER_ACTION_STATUS
  };
}

// LicensingForm Answer Change Input
export function licensingFormAnswerChangeInput({ answerGuid, answerContent, fileList }) {
  return {
    type: LICENSINGFORM_ANSWER_CHANGE_INPUT,
    payload: {
      answerGuid,
      answerContent,
      fileList,
    }
  };
}

// ================================================================================
// Open/Reset LicensingForm Edit Modal Dialog
export function openLicensingFormEditModalDialog(mode, licensingForm) {
  return {
    type: LICENSINGFORM_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      mode,
      licensingForm,
    },
  };
}

export function resetLicensingFormEditModalDialog() {
  return {
    type: LICENSINGFORM_EDIT_MODAL_DIALOG_RESET
  };
}

// Create LicensingForm
function startCreateLicensingForm() {
  return {
    type: START_CREATE_LICENSINGFORM
  };
}

export function createLicensingForm(licensingForm) {
  return function(dispatch) {
    dispatch(startCreateLicensingForm());
    httpClient
      .post(UrlConfig.NEW_LICENSINGFORM, licensingForm)
      .then(resp => dispatch(createLicensingFormSuccess(resp)))
      .catch(err => dispatch(createLicensingFormFailure(err)));
  };
}

function createLicensingFormSuccess(resp) {
  return {
    type: CREATE_LICENSINGFORM_SUCCESS,
    payload: resp
  };
}

function createLicensingFormFailure(err) {
  return {
    type: CREATE_LICENSINGFORM_FAILURE,
    payload: err
  };
}

export function initCreateLicensingFormActionStatus() {
  return {
    type: INIT_CREATE_LICENSINGFORM_ACTION_STATUS
  };
}

// Update LicensingForm
function startUpdateLicensingForm() {
  return {
    type: START_UPDATE_LICENSINGFORM
  };
}

export function updateLicensingForm(licensingForm) {
  return function(dispatch) {
    dispatch(startUpdateLicensingForm());
    httpClient
      .post(UrlConfig.UPDATED_LICENSINGFORM, licensingForm)
      .then(resp => dispatch(updateLicensingFormSuccess(resp)))
      .catch(err => dispatch(updateLicensingFormFailure(err)));
  };
}

function updateLicensingFormSuccess(resp) {
  return {
    type: UPDATE_LICENSINGFORM_SUCCESS,
    payload: resp
  };
}

function updateLicensingFormFailure(err) {
  return {
    type: UPDATE_LICENSINGFORM_FAILURE,
    payload: err
  };
}

export function initUpdateLicensingFormActionStatus() {
  return {
    type: INIT_UPDATE_LICENSINGFORM_ACTION_STATUS
  };
}

// Copy LicensingForm
function startCopyLicensingForm() {
  return {
    type: START_COPY_LICENSINGFORM
  };
}

export function copyLicensingForm(licensingForm) {
  return function(dispatch) {
    dispatch(startCopyLicensingForm());
    httpClient
      .post(UrlConfig.COPIED_LICENSINGFORM, licensingForm)
      .then(resp => dispatch(copyLicensingFormSuccess(resp)))
      .catch(err => dispatch(copyLicensingFormFailure(err)));
  };
}

function copyLicensingFormSuccess(resp) {
  return {
    type: COPY_LICENSINGFORM_SUCCESS,
    payload: resp
  };
}

function copyLicensingFormFailure(err) {
  return {
    type: COPY_LICENSINGFORM_FAILURE,
    payload: err
  };
}

export function initCopyLicensingFormActionStatus() {
  return {
    type: INIT_COPY_LICENSINGFORM_ACTION_STATUS
  };
}


// Batch Delete LicensingForm
function startBatchDeleteLicensingForm() {
  return {
    type: START_BATCH_DELETE_LICENSINGFORM
  };
}

export function batchDeleteLicensingForm(licensingFormGuidList) {
  return function(dispatch) {
    dispatch(startBatchDeleteLicensingForm());
    httpClient
      .post(UrlConfig.BATCH_DELETED_LICENSINGFORM, licensingFormGuidList)
      .then(resp => dispatch(batchDeleteLicensingFormSuccess(resp)))
      .catch(err => dispatch(batchDeleteLicensingFormFailure(err)));
  };
}

function batchDeleteLicensingFormSuccess(resp) {
  return {
    type: BATCH_DELETE_LICENSINGFORM_SUCCESS,
    payload: resp
  };
}

function batchDeleteLicensingFormFailure(err) {
  return {
    type: BATCH_DELETE_LICENSINGFORM_FAILURE,
    payload: err
  };
}

export function initBatchDeleteLicensingFormActionStatus() {
  return {
    type: INIT_BATCH_DELETE_LICENSINGFORM_ACTION_STATUS
  };
}

// LicensingForm Edit Change Input
export function licensingFormEditChangeInput({ name, value }) {
  return {
    type: LICENSINGFORM_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// ================================================================================
// Open/Reset Question Edit Modal Dialog
export function openQuestionEditModalDialog(isAddMode, question) {
  return {
    type: QUESTION_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      question,
    },
  };
}

export function resetQuestionEditModalDialog() {
  return {
    type: QUESTION_EDIT_MODAL_DIALOG_RESET
  };
}

// Create Question
function startCreateQuestion() {
  return {
    type: START_CREATE_QUESTION
  };
}

export function createQuestion(question) {
  return function(dispatch) {
    dispatch(startCreateQuestion());
    httpClient
      .post(UrlConfig.NEW_ITEM, question)
      .then(resp => dispatch(createQuestionSuccess(resp)))
      .catch(err => dispatch(createQuestionFailure(err)));
  };
}

function createQuestionSuccess(resp) {
  return {
    type: CREATE_QUESTION_SUCCESS,
    payload: resp
  };
}

function createQuestionFailure(err) {
  return {
    type: CREATE_QUESTION_FAILURE,
    payload: err
  };
}

export function initCreateQuestionActionStatus() {
  return {
    type: INIT_CREATE_QUESTION_ACTION_STATUS
  };
}

// Update Question
function startUpdateQuestion() {
  return {
    type: START_UPDATE_QUESTION
  };
}

export function updateQuestion(question) {
  return function(dispatch) {
    dispatch(startUpdateQuestion());
    httpClient
      .post(UrlConfig.UPDATED_ITEM, question)
      .then(resp => dispatch(updateQuestionSuccess(resp)))
      .catch(err => dispatch(updateQuestionFailure(err)));
  };
}

function updateQuestionSuccess(resp) {
  return {
    type: UPDATE_QUESTION_SUCCESS,
    payload: resp
  };
}

function updateQuestionFailure(err) {
  return {
    type: UPDATE_QUESTION_FAILURE,
    payload: err
  };
}

export function initUpdateQuestionActionStatus() {
  return {
    type: INIT_UPDATE_QUESTION_ACTION_STATUS
  };
}

// Batch Delete Question
function startBatchDeleteQuestion() {
  return {
    type: START_BATCH_DELETE_QUESTION
  };
}

export function batchDeleteQuestion(questionIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteQuestion());
    httpClient
      .post(UrlConfig.BATCH_DELETED_QUESTION, questionIdList)
      .then(resp => dispatch(batchDeleteQuestionSuccess(resp)))
      .catch(err => dispatch(batchDeleteQuestionFailure(err)));
  };
}

function batchDeleteQuestionSuccess(resp) {
  return {
    type: BATCH_DELETE_QUESTION_SUCCESS,
    payload: resp
  };
}

function batchDeleteQuestionFailure(err) {
  return {
    type: BATCH_DELETE_QUESTION_FAILURE,
    payload: err
  };
}

export function initBatchDeleteQuestionActionStatus() {
  return {
    type: INIT_BATCH_DELETE_QUESTION_ACTION_STATUS
  };
}

// Question Edit Change Input
export function questionEditChangeInput({ name, value }) {
  return {
    type: QUESTION_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// LicensingForm Option Change
export function licensingFormOptionChange(licensingFormGuid) {
  return {
    type: LICENSINGFORM_OPTION_CHANGE,
    payload: {
      licensingFormGuid,
    }
  };
}

// Load LicensingForm Results
function startLoadLicensingFormResults() {
  return {
    type: START_LOAD_LICENSINGFORM_RESULTS
  };
}

export function loadLicensingFormResults(params) {
  let licensingFormGuid = '';
  let userAccountName = '';
  let sentMonth = '';
  let subMonth = '';
  let answerStatus = '';
  let sentOnFrom = '';
  let sentOnTo = '';
  if (params) {
    licensingFormGuid = params.licensingFormGuid || '';
    userAccountName = params.userAccountName || '';
    sentMonth = params.sentMonth || '';
    subMonth = params.subMonth || '';
    answerStatus = params.answerStatus || '';
    sentOnFrom = params.sentOnFrom || '';
    sentOnTo = params.sentOnTo || '';
  }

  const requestParams = {
    licensingFormGuid,
    userAccountName,
    sentMonth,
    subMonth,
    answerStatus,
    sentOnFrom,
    sentOnTo,
  };

  return function(dispatch) {
    dispatch(startLoadLicensingFormResults());
    httpClient
      .get(UrlConfig.LOAD_LICENSINGFORM_RESULTS, requestParams)
      .then(resp => dispatch(loadLicensingFormResultsSuccess(resp)))
      .catch(err => dispatch(loadLicensingFormResultsFailure(err)));
  };
}

function loadLicensingFormResultsSuccess(resp) {
  return {
    type: LOAD_LICENSINGFORM_RESULTS_SUCCESS,
    payload: resp
  };
}

function loadLicensingFormResultsFailure(err) {
  return {
    type: LOAD_LICENSINGFORM_RESULTS_FAILURE,
    payload: err
  };
}

export function initLoadLicensingFormResultsActionStatus() {
  return {
    type: INIT_LOAD_LICENSINGFORM_RESULTS_ACTION_STATUS
  };
}

// Select LicensingForm Results
export function selectLicensingFormResults(payload) {
  return {
    type: SELECT_LICENSINGFORM_RESULTS,
    payload
  };
}

// Load LicensingForm Result Detail
function startLoadLicensingFormResultDetail() {
  return {
    type: START_LOAD_LICENSINGFORM_RESULT_DETAIL
  };
}

export function loadLicensingFormResultDetail(licensingFormResultGuid) {
  const requestParams = {
    'licensingForm-result-guid': licensingFormResultGuid,
  };

  return function(dispatch) {
    dispatch(startLoadLicensingFormResultDetail());
    httpClient
      .get(UrlConfig.LOAD_LICENSINGFORM_RESULT_DETAIL, requestParams)
      .then(resp => dispatch(loadLicensingFormResultDetailSuccess(resp)))
      .catch(err => dispatch(loadLicensingFormResultDetailFailure(err)));
  };
}

function loadLicensingFormResultDetailSuccess(resp) {
  return {
    type: LOAD_LICENSINGFORM_RESULT_DETAIL_SUCCESS,
    payload: resp
  };
}

function loadLicensingFormResultDetailFailure(err) {
  return {
    type: LOAD_LICENSINGFORM_RESULT_DETAIL_FAILURE,
    payload: err
  };
}

export function initLoadLicensingFormResultDetailActionStatus() {
  return {
    type: INIT_LOAD_LICENSINGFORM_RESULT_DETAIL_ACTION_STATUS
  };
}

// Accept LicensingForm Result
function startAcceptLicensingFormResult() {
  return {
    type: START_ACCEPT_LICENSINGFORM_RESULT
  };
}

export function acceptLicensingFormResult(licensingFormResultGuidList, emailTitle, emailContent) {
  const requestParams = {
    licensingFormResultGuidList,
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startAcceptLicensingFormResult());
    httpClient
      .post(UrlConfig.ACCEPT_LICENSINGFORM_RESULT, requestParams)
      .then(resp => dispatch(acceptLicensingFormResultSuccess(resp)))
      .catch(err => dispatch(acceptLicensingFormResultFailure(err)));
  };
}

function acceptLicensingFormResultSuccess(resp) {
  return {
    type: ACCEPT_LICENSINGFORM_RESULT_SUCCESS,
    payload: resp
  };
}

function acceptLicensingFormResultFailure(err) {
  return {
    type: ACCEPT_LICENSINGFORM_RESULT_FAILURE,
    payload: err
  };
}

export function initAcceptLicensingFormResultActionStatus() {
  return {
    type: INIT_ACCEPT_LICENSINGFORM_RESULT_ACTION_STATUS
  };
}

// Reject LicensingForm Result
function startRejectLicensingFormResult() {
  return {
    type: START_REJECT_LICENSINGFORM_RESULT
  };
}

export function rejectLicensingFormResult(licensingFormResultGuidList, emailTitle, emailContent) {
  const requestParams = {
    licensingFormResultGuidList,
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startRejectLicensingFormResult());
    httpClient
      .post(UrlConfig.REJECT_LICENSINGFORM_RESULT, requestParams)
      .then(resp => dispatch(rejectLicensingFormResultSuccess(resp)))
      .catch(err => dispatch(rejectLicensingFormResultFailure(err)));
  };
}

function rejectLicensingFormResultSuccess(resp) {
  return {
    type: REJECT_LICENSINGFORM_RESULT_SUCCESS,
    payload: resp
  };
}

function rejectLicensingFormResultFailure(err) {
  return {
    type: REJECT_LICENSINGFORM_RESULT_FAILURE,
    payload: err
  };
}

export function initRejectLicensingFormResultActionStatus() {
  return {
    type: INIT_REJECT_LICENSINGFORM_RESULT_ACTION_STATUS
  };
}

// Open/Reset LicensingForm Result Operate Modal Dialog
export function openLicensingFormResultOperateModalDialog(opMode, licensingFormResultGuidList) {
  return {
    type: LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_OPEN,
    payload: {
      opMode,
      licensingFormResultGuidList,
    },
  };
}

export function resetLicensingFormResultOperateModalDialog() {
  return {
    type: LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_RESET
  };
}

// LicensingForm Answer Submitter Edit Change Input
export function licensingFormAnswerSubmitterEditChangeInput(value) {
  return {
    type: LICENSINGFORM_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,
    payload: value,
  };
}

// Send Remind Email
function startSendRemindEmail() {
  return {
    type: START_SEND_REMIND_EMAIL
  };
}

export function sendRemindEmail(licensingFormResultGuidList, emailTitle, emailContent) {
  const requestParams = {
    licensingFormResultGuidList,
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startSendRemindEmail());
    httpClient
      .post(UrlConfig.REMIND_LICENSINGFORM_EMAIL, requestParams)
      .then(resp => dispatch(sendRemindEmailSuccess(resp)))
      .catch(err => dispatch(sendRemindEmailFailure(err)));
  };
}

function sendRemindEmailSuccess(resp) {
  return {
    type: SEND_REMIND_EMAIL_SUCCESS,
    payload: resp
  };
}

function sendRemindEmailFailure(err) {
  return {
    type: SEND_REMIND_EMAIL_FAILURE,
    payload: err
  };
}

export function initSendRemindEmailActionStatus() {
  return {
    type: INIT_SEND_REMIND_EMAIL_ACTION_STATUS
  };
}

// Open/Reset LicensingForm Email Edit Modal Dialog
export function openLicensingFormEmailEditModalDialog(sendMode, emailPayload) {
  return {
    type: LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      sendMode,
      emailPayload,
    },
  };
}

export function resetLicensingFormEmailEditModalDialog() {
  return {
    type: LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_RESET
  };
}

// Update LicensingForm Score
function startUpdateLicensingFormScore() {
  return {
    type: START_UPDATE_LICENSINGFORM_SCORE
  };
}

export function updateLicensingFormScore(licensingFormAnswer) {
  const requestParams = {
    licensingFormGuid : licensingFormAnswer.licensingFormGuid,
    questionGuid : licensingFormAnswer.questionGuid,
    answerGuid : licensingFormAnswer.answerGuid,
    licensingFormResultGuid : licensingFormAnswer.licensingFormResultGuid,
    answeree : licensingFormAnswer.answeree,
    score : licensingFormAnswer.score,
  };

  return function(dispatch) {
    dispatch(startUpdateLicensingFormScore());
    httpClient
      .post(UrlConfig.UPDATE_LICENSINGFORM_SCORE, requestParams)
      .then(resp => dispatch(updateLicensingFormScoreSuccess(resp)))
      .catch(err => dispatch(updateLicensingFormScoreFailure(err)));
  };
}

function updateLicensingFormScoreSuccess(resp) {
  return {
    type: UPDATE_LICENSINGFORM_SCORE_SUCCESS,
    payload: resp
  };
}

function updateLicensingFormScoreFailure(err) {
  return {
    type: UPDATE_LICENSINGFORM_SCORE_FAILURE,
    payload: err
  };
}

export function initUpdateLicensingFormScoreActionStatus() {
  return {
    type: INIT_UPDATE_LICENSINGFORM_SCORE_ACTION_STATUS
  };
}

// Calculate LicensingForm Total Score
export function calculateLicensingFormTotalScore() {
  return {
    type: CALC_LICENSINGFORM_TOTAL_SCORE
  };
}

// Open/Reset Submitted LicensingForm History Modal Dialog
export function openSubmittedLicensingFormHistoryModalDialog(authToken) {
  return {
    type: SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_OPEN,
    payload: {
      authToken,
    },
  };
}

export function resetSubmittedLicensingFormHistoryModalDialog() {
  return {
    type: SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_RESET
  };
}

// Load Submitted LicensingForm History
function startLoadSubmittedLicensingFormHistory() {
  return {
    type: START_LOAD_SUBMITTED_LICENSINGFORM_HISTORY
  };
}

export function loadSubmittedLicensingFormHistory(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadSubmittedLicensingFormHistory());
    httpClient
      .get(UrlConfig.LOAD_SUBMITTED_LICENSINGFORM_HISTORY, requestParams)
      .then(resp => dispatch(loadSubmittedLicensingFormHistorySuccess(resp)))
      .catch(err => dispatch(loadSubmittedLicensingFormHistoryFailure(err)));
  };
}

function loadSubmittedLicensingFormHistorySuccess(resp) {
  return {
    type: LOAD_SUBMITTED_LICENSINGFORM_HISTORY_SUCCESS,
    payload: resp
  };
}

function loadSubmittedLicensingFormHistoryFailure(err) {
  return {
    type: LOAD_SUBMITTED_LICENSINGFORM_HISTORY_FAILURE,
    payload: err
  };
}

export function initLoadSubmittedLicensingFormHistoryActionStatus() {
  return {
    type: INIT_LOAD_SUBMITTED_LICENSINGFORM_HISTORY_ACTION_STATUS
  };
}

// Select Submitted LicensingForm History
export function selectSubmittedLicensingFormHistory(payload) {
  return {
    type: SELECT_SUBMITTED_LICENSINGFORM_HISTORY,
    payload
  };
}

// Apply Submitted LicensingForm History
export function applySubmittedLicensingFormHistory(historyLicensingForm) {
  return {
    type: APPLY_SUBMITTED_LICENSINGFORM_HISTORY,
    payload: {
      historyLicensingForm,
    }
  };
}


// download pdf
export function downloadLicensingFormResultPDF(guidList) {
  const w = window.open('about:blank');
  w.location.href = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION 
                    + UrlConfig.DOWNLOAD_LICENSINGFORM_RESULT_PDF + '?guid-list=' + guidList.join(',');
  return function(dispatch) { }
}

