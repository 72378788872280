import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Generate 13F Long Report
  START_GENERATE_THIRTEENF_LONG_POS_REPORT,
  GENERATE_THIRTEENF_LONG_POS_REPORT_SUCCESS,
  GENERATE_THIRTEENF_LONG_POS_REPORT_FAILURE,
  INIT_GENERATE_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS,

  // Load 13F Long Report
  START_LOAD_THIRTEENF_LONG_POS_REPORT,
  LOAD_THIRTEENF_LONG_POS_REPORT_SUCCESS,
  LOAD_THIRTEENF_LONG_POS_REPORT_FAILURE,
  INIT_LOAD_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS,

  // Open/Reset 13F Long Report Check Result Modal Dialog
  THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN,
  THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET,

  // Load 13F Long Report Check Result
  START_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT,
  LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_SUCCESS,
  LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_FAILURE,
  INIT_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_ACTION_STATUS,

  // Report 13F Long Report
  START_REPORT_THIRTEENF_LONG_POS_REPORT,
  REPORT_THIRTEENF_LONG_POS_REPORT_SUCCESS,
  REPORT_THIRTEENF_LONG_POS_REPORT_FAILURE,
  INIT_REPORT_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS,

  // Report 13F Long Pos Summary Report
  START_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT,
  REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_SUCCESS,
  REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_FAILURE,
  INIT_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_ACTION_STATUS,

} from './thirteenFLongPosReportConstants';

// Generate 13F Long Report
function startGenerateThirteenFLongPosReport() {
  return {
    type: START_GENERATE_THIRTEENF_LONG_POS_REPORT
  };
}

export function generateThirteenFLongPosReport(fundCode, dateString) {
  const requestParams = {
    fundCode,
    dateString,
  };
  
  return function(dispatch) {
    dispatch(startGenerateThirteenFLongPosReport());
    httpClient
      .post(UrlConfig.GENERATE_13F_LONG_POS_REPORT, requestParams)
      .then(resp => dispatch(generateThirteenFLongPosReportSuccess(resp)))
      .catch(err => dispatch(generateThirteenFLongPosReportFailure(err)));
  };
}

function generateThirteenFLongPosReportSuccess(resp) {
  return {
    type: GENERATE_THIRTEENF_LONG_POS_REPORT_SUCCESS,
    payload: resp
  };
}

function generateThirteenFLongPosReportFailure(err) {
  return {
    type: GENERATE_THIRTEENF_LONG_POS_REPORT_FAILURE,
    payload: err
  };
}

export function initGenerateThirteenFLongPosReportActionStatus() {
  return {
    type: INIT_GENERATE_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS
  };
}

// Load 13F Long Position Report
function startLoadThirteenFLongPosReport() {
  return {
    type: START_LOAD_THIRTEENF_LONG_POS_REPORT
  };
}

export function loadThirteenFLongPosReport(fundCode, dateString) {
  const requestParams = {
    'fund-code': fundCode,
    'date-string': dateString,
  };

  return function(dispatch) {
    dispatch(startLoadThirteenFLongPosReport());
    httpClient
      .get(UrlConfig.LOAD_13F_LONG_POS_REPORT, requestParams)
      .then(resp => dispatch(loadThirteenFLongPosReportSuccess(resp)))
      .catch(err => dispatch(loadThirteenFLongPosReportFailure(err)));
  };
}

function loadThirteenFLongPosReportSuccess(resp) {
  return {
    type: LOAD_THIRTEENF_LONG_POS_REPORT_SUCCESS,
    payload: resp
  };
}

function loadThirteenFLongPosReportFailure(err) {
  return {
    type: LOAD_THIRTEENF_LONG_POS_REPORT_FAILURE,
    payload: err
  };
}

export function initLoadThirteenFLongPosReportActionStatus() {
  return {
    type: INIT_LOAD_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS
  };
}

// Open/Reset 13F Long Position Report Check Result Modal Dialog
export function openThirteenFLongPosReportCheckResultModalDialog(fundCode, dateString) {
  return {
    type: THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN,
    payload: {
      fundCode,
      dateString,
    },
  };
}

export function resetThirteenFLongPosReportCheckResultModalDialog() {
  return {
    type: THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET
  };
}

// Load 13F Long Position Report Check Result
function startLoadThirteenFLongPosReportCheckResult() {
  return {
    type: START_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT
  };
}

export function loadThirteenFLongPosReportCheckResult(fundCode, dateString) {
  const requestParams = {
    fundCode,
    dateString,
  };

  return function(dispatch) {
    dispatch(startLoadThirteenFLongPosReportCheckResult());
    httpClient
      .post(UrlConfig.CHECK_13F_LONG_POS_REPORT, requestParams)
      .then(resp => dispatch(loadThirteenFLongPosReportCheckResultSuccess(resp)))
      .catch(err => dispatch(loadThirteenFLongPosReportCheckResultFailure(err)));
  };
}

function loadThirteenFLongPosReportCheckResultSuccess(resp) {
  return {
    type: LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_SUCCESS,
    payload: resp
  };
}

function loadThirteenFLongPosReportCheckResultFailure(err) {
  return {
    type: LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_FAILURE,
    payload: err
  };
}

export function initLoadThirteenFLongPosReportCheckResultActionStatus() {
  return {
    type: INIT_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_ACTION_STATUS
  };
}

// Report 13F Long Position Report
function startReportThirteenFLongPosReport() {
  return {
    type: START_REPORT_THIRTEENF_LONG_POS_REPORT
  };
}

export function reportThirteenFLongPosReport(fundCode, dateString) {
  const requestParams = {
    fundCode,
    dateString,
  };
  
  return function(dispatch) {
    dispatch(startReportThirteenFLongPosReport());
    httpClient
      .post(UrlConfig.REPORT_13F_LONG_POS_REPORT, requestParams)
      .then(resp => dispatch(reportThirteenFLongPosReportSuccess(resp)))
      .catch(err => dispatch(reportThirteenFLongPosReportFailure(err)));
  };
}

function reportThirteenFLongPosReportSuccess(resp) {
  return {
    type: REPORT_THIRTEENF_LONG_POS_REPORT_SUCCESS,
    payload: resp
  };
}

function reportThirteenFLongPosReportFailure(err) {
  return {
    type: REPORT_THIRTEENF_LONG_POS_REPORT_FAILURE,
    payload: err
  };
}

export function initReportThirteenFLongPosReportActionStatus() {
  return {
    type: INIT_REPORT_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS
  };
}

// Report 13F Long Pos Summary Report
function startReportThirteenFLongPosSummaryReport() {
  return {
    type: START_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT
  };
}

export function reportThirteenFLongPosSummaryReport(dateString) {
  const requestParams = {
    dateString,
  };
  
  return function(dispatch) {
    dispatch(startReportThirteenFLongPosSummaryReport());
    httpClient
      .post(UrlConfig.REPORT_13F_LONG_POS_SUMMARY_REPORT, requestParams)
      .then(resp => dispatch(reportThirteenFLongPosSummaryReportSuccess(resp)))
      .catch(err => dispatch(reportThirteenFLongPosSummaryReportFailure(err)));
  };
}

function reportThirteenFLongPosSummaryReportSuccess(resp) {
  return {
    type: REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_SUCCESS,
    payload: resp
  };
}

function reportThirteenFLongPosSummaryReportFailure(err) {
  return {
    type: REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_FAILURE,
    payload: err
  };
}

export function initReportThirteenFLongPosSummaryReportActionStatus() {
  return {
    type: INIT_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_ACTION_STATUS
  };
}