import React, {Component} from "react";
import BaseUrlConfig from '../../net/BaseUrlConfig';
import UrlConfig from '../../net/UrlConfig';

class QuestionnaireAttachmentRenderer extends Component {

  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
    this.downloadAttachment = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_QUESTIONNAIRE_ATTACHMENT;
  }

  invokeParentMethod () {

  }

  render () {
    const node = this.props.node;

    const fileInfoArray = [];

    if (!!node) {
      const attachment = node.data.attachment;
      const attachmentOriginName = node.data.attachmentOriginName;

      if (!!attachment && !!attachmentOriginName) {
        const attachmentArray = attachment.split(',');
        const attachmentOriginNameArray = attachmentOriginName.split(',');
        for(let i in attachmentArray) {
          const fileInfo = {
            url: this.downloadAttachment + '/' + attachmentArray[i],
            attachment: attachmentArray[i],
            attachmentOriginName: attachmentOriginNameArray[i],
          };
  
          fileInfoArray.push(fileInfo);
        }
      }
    }

    return (
      <div>
        {
          fileInfoArray.map(fileInfo => {
            return (<a href={fileInfo.url} target="_blank" key={fileInfo.url} style={{ marginLeft: 10 }}>{fileInfo.attachmentOriginName}</a>);
          })
        }
      </div>
    );
  }
}

export default QuestionnaireAttachmentRenderer;