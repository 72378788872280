import {
  // Load Book List
  START_LOAD_BOOK_LIST,
  LOAD_BOOK_LIST_SUCCESS,
  LOAD_BOOK_LIST_FAILURE,
  INIT_LOAD_BOOK_LIST_ACTION_STATUS,
} from './bookConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Book List
function startLoadBookList() {
  return {
    type: START_LOAD_BOOK_LIST
  };
}

export function loadBookList() {
  return function(dispatch) {
    dispatch(startLoadBookList());
    httpClient
      .get(UrlConfig.QUERY_BOOK_LIST)
      .then(resp => dispatch(loadBookListSuccess(resp)))
      .catch(err => dispatch(loadBookListFailure(err)));
  };
}

function loadBookListSuccess(resp) {
  return {
    type: LOAD_BOOK_LIST_SUCCESS,
    payload: resp
  };
}

function loadBookListFailure(err) {
  return {
    type: LOAD_BOOK_LIST_FAILURE,
    payload: err
  };
}

export function initLoadBookListActionStatus() {
  return {
    type: INIT_LOAD_BOOK_LIST_ACTION_STATUS
  };
}
