// Load Banned Trade Request
export const START_LOAD_BANNED_TRADE_REQUEST = 'START_LOAD_BANNED_TRADE_REQUEST';
export const LOAD_BANNED_TRADE_REQUEST_SUCCESS = 'LOAD_BANNED_TRADE_REQUEST_SUCCESS';
export const LOAD_BANNED_TRADE_REQUEST_FAILURE = 'LOAD_BANNED_TRADE_REQUEST_FAILURE';
export const INIT_LOAD_BANNED_TRADE_REQUEST_ACTION_STATUS = 'INIT_LOAD_BANNED_TRADE_REQUEST_ACTION_STATUS';

// Select Banned Trade Request
export const SELECT_BANNED_TRADE_REQUEST = 'SELECT_BANNED_TRADE_REQUEST';

// Open/Reset Banned Trade Request Modal Dialog
export const BANNED_TRADE_REQUEST_MODAL_DIALOG_OPEN = 'BANNED_TRADE_REQUEST_MODAL_DIALOG_OPEN';
export const BANNED_TRADE_REQUEST_MODAL_DIALOG_RESET = 'BANNED_TRADE_REQUEST_MODAL_DIALOG_RESET';

// Banned Trade Request Edit Change Input
export const BANNED_TRADE_REQUEST_EDIT_CHANGE_INPUT = 'BANNED_TRADE_REQUEST_EDIT_CHANGE_INPUT';

// Approve Banned Trade Request
export const START_APPROVE_BANNED_TRADE_REQUEST = 'START_APPROVE_BANNED_TRADE_REQUEST';
export const APPROVE_BANNED_TRADE_REQUEST_SUCCESS = 'APPROVE_BANNED_TRADE_REQUEST_SUCCESS';
export const APPROVE_BANNED_TRADE_REQUEST_FAILURE = 'APPROVE_BANNED_TRADE_REQUEST_FAILURE';
export const INIT_APPROVE_BANNED_TRADE_REQUEST_ACTION_STATUS = 'INIT_APPROVE_BANNED_TRADE_REQUEST_ACTION_STATUS';

// Reject Banned Trade Request
export const START_REJECT_BANNED_TRADE_REQUEST = 'START_REJECT_BANNED_TRADE_REQUEST';
export const REJECT_BANNED_TRADE_REQUEST_SUCCESS = 'REJECT_BANNED_TRADE_REQUEST_SUCCESS';
export const REJECT_BANNED_TRADE_REQUEST_FAILURE = 'REJECT_BANNED_TRADE_REQUEST_FAILURE';
export const INIT_REJECT_BANNED_TRADE_REQUEST_ACTION_STATUS = 'INIT_REJECT_BANNED_TRADE_REQUEST_ACTION_STATUS';

// Feedback Banned Trade Request
export const START_FEEDBACK_BANNED_TRADE_REQUEST = 'START_FEEDBACK_BANNED_TRADE_REQUEST';
export const FEEDBACK_BANNED_TRADE_REQUEST_SUCCESS = 'FEEDBACK_BANNED_TRADE_REQUEST_SUCCESS';
export const FEEDBACK_BANNED_TRADE_REQUEST_FAILURE = 'FEEDBACK_BANNED_TRADE_REQUEST_FAILURE';
export const INIT_FEEDBACK_BANNED_TRADE_REQUEST_ACTION_STATUS = 'INIT_FEEDBACK_BANNED_TRADE_REQUEST_ACTION_STATUS';

// Update Banned Trade Request
export const START_UPDATE_BANNED_TRADE_REQUEST = 'START_UPDATE_BANNED_TRADE_REQUEST';
export const UPDATE_BANNED_TRADE_REQUEST_SUCCESS = 'UPDATE_BANNED_TRADE_REQUEST_SUCCESS';
export const UPDATE_BANNED_TRADE_REQUEST_FAILURE = 'UPDATE_BANNED_TRADE_REQUEST_FAILURE';
export const INIT_UPDATE_BANNED_TRADE_REQUEST_ACTION_STATUS = 'INIT_UPDATE_BANNED_TRADE_REQUEST_ACTION_STATUS';
