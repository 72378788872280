import {connect} from 'react-redux';
import ExpenseSummaryDashboard from '../components/ExpenseSummaryDashboard';


import {
    // query
    onQueryInputChange,
    loadExpenseSummaryInfo,
    initLoadExpenseSummaryInfoActionStatus,
    // select
    selectExpenseSummaryInfo,
    // add or edit
    openAddOrEditExpenseSummaryModal,
    addOrEditExpenseSummaryChangeInput,
    addOrEditExpenseSummary,
    initAddOrEditExpenseSummaryActionStatus,
    resetAddOrEditExpenseSummaryModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelExpenseSummaryModal,
    delExpenseSummaryChangeInput,
    delExpenseSummary,
    initDelExpenseSummaryActionStatus,
    resetDelExpenseSummaryModal,
    
} from '../ExpenseSummaryActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        categoryOptions: state.expenseSummary.categoryOptions,
        fundFirmRelatedOptions: state.expenseSummary.fundFirmRelatedOptions,
        ccyOptions: state.expenseSummary.ccyOptions,
        // expenseSummaryQueryFields
        expenseSummaryQueryFields: state.expenseSummary.expenseSummaryQueryFields,
        // query
        loadExpenseSummaryInfoActionStatus: state.expenseSummary.loadExpenseSummaryInfoActionStatus,
        loadExpenseSummaryInfoResp: state.expenseSummary.loadExpenseSummaryInfoResp,
        selectedExpenseSummaryInfoArray: state.expenseSummary.selectedExpenseSummaryInfoArray,
        expenseSummaryGridColumns: state.expenseSummary.expenseSummaryGridColumns,
        // add or edit
        addOrEditExpenseSummaryModal: state.expenseSummary.addOrEditExpenseSummaryModal,
        addOrEditExpenseSummaryStatus: state.expenseSummary.addOrEditExpenseSummaryStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.expenseSummary.refreshFxStatus,
        refreshFxResp: state.expenseSummary.refreshFxResp,
        // del
        delExpenseSummaryModal: state.expenseSummary.delExpenseSummaryModal,
        delExpenseSummaryStatus: state.expenseSummary.delExpenseSummaryStatus,

        // opeartionRcd
        operationRecord: state.operationRecord,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadExpenseSummaryInfo: (params) => dispatch(loadExpenseSummaryInfo(params)),
        initLoadExpenseSummaryInfoActionStatus: () => dispatch(initLoadExpenseSummaryInfoActionStatus()),
        // select
        selectExpenseSummaryInfo: (dataArray) => dispatch(selectExpenseSummaryInfo(dataArray)),
        // add or edit
        openAddOrEditExpenseSummaryModal: (mode, data) => dispatch(openAddOrEditExpenseSummaryModal(mode, data)),
        addOrEditExpenseSummaryChangeInput: (params) => dispatch(addOrEditExpenseSummaryChangeInput(params)),
        addOrEditExpenseSummary: (info) => dispatch(addOrEditExpenseSummary(info)),
        initAddOrEditExpenseSummaryActionStatus: () => dispatch(initAddOrEditExpenseSummaryActionStatus()),
        resetAddOrEditExpenseSummaryModal: () => dispatch(resetAddOrEditExpenseSummaryModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelExpenseSummaryModal: (info) => dispatch(openDelExpenseSummaryModal(info)),
        delExpenseSummaryChangeInput: (params) => dispatch(delExpenseSummaryChangeInput(params)),
        delExpenseSummary: (info) => dispatch(delExpenseSummary(info)),
        initDelExpenseSummaryActionStatus: () => dispatch(initDelExpenseSummaryActionStatus()),
        resetDelExpenseSummaryModal: () => dispatch(resetDelExpenseSummaryModal()),
        // operationRcd
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(ExpenseSummaryDashboard);