import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import PreviewTradeAttachRenderer from '../../../../renderer/PreviewTradeAttachRenderer';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import '../../ApprovalRequest.css';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';
class PersonalTradeOpRecordGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.personalTradeOpRecordGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      frameworkComponents: {
        previewTradeAttachRenderer: PreviewTradeAttachRenderer,
      },
      rowSelection: "multiple",
    };
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'personal-trade-op-list-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  render() {
    const {
      personalTradeOperationRecordArray,
      className = 'grid-wrapper',
      loadPersonalTradeOperationRecordActionStatus,
    } = this.props;

    const isLoading = loadPersonalTradeOperationRecordActionStatus === ACTION_STATUS.LOGINING;
    if (loadPersonalTradeOperationRecordActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadPersonalTradeOperationRecordActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={personalTradeOperationRecordArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            getRowNodeId={this.getRowNodeId}
            frameworkComponents={this.state.frameworkComponents}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default PersonalTradeOpRecordGrid;