import React, {Component} from 'react';

import { Button, message, Breadcrumb, DatePicker } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import ExchangeRateGrid from './ExchangeRateGrid';
import moment from 'moment';
import '../../DocumentReport.css';

class ExchangeRateDashboard extends Component {

  state = {
    searchDate: moment(),
  };

  componentDidMount() {
    this.props.loadExchangeRate();
  }

  componentDidUpdate() {
    const {
      syncExchangeRateActionStatus,
      loadExchangeRateActionStatus,
      loadExchangeRateErrMsg,
      syncExchangeRateErrMsg,
    } = this.props;

    // Sync Exchange Rate 成功
    if (syncExchangeRateActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('HKEX exchange rate are synchronized successfully!');
      this.props.initSyncExchangeRateActionStatus();
      this.props.loadExchangeRate();
    }

    if (syncExchangeRateActionStatus === ACTION_STATUS.ERROR) {
      message.error(syncExchangeRateErrMsg);
      this.props.initSyncExchangeRateActionStatus();
    }

    // Load Exchange Rate 成功
    if (loadExchangeRateActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadExchangeRateActionStatus();
    }

    if (loadExchangeRateActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadExchangeRateErrMsg);
      this.props.initLoadExchangeRateActionStatus();
    }

  }

  syncBtnOnClicked = () => {
    const {
      searchDate
    } = this.state;

    const dateString = searchDate.format("YYYYMMDD");
    this.props.syncExchangeRate(dateString);
  }

  refreshBtnOnClicked = () => {
    this.props.loadExchangeRate();
  }
  
  render() {
    const {
      syncExchangeRateActionStatus,
      loadExchangeRateActionStatus,
    } = this.props;

    const {
      searchDate
    } = this.state;

    const syncBtnIsLoading = syncExchangeRateActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadExchangeRateActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="shortPositionReportWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Document & Report</Breadcrumb.Item>
            <Breadcrumb.Item>HKEX Exchange Rate</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <DatePicker 
            style={{ marginLeft: 10 }}
            allowClear={false}
            value={searchDate}
            onChange={(date, dateString) => {
              this.setState({
                searchDate: date
              })
            }} 
          />

          <Button 
            onClick={this.syncBtnOnClicked} 
            size='small'
            loading={syncBtnIsLoading}
            type='primary'>
            Sync
          </Button>

          <Button 
            onClick={this.refreshBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='shortPositionReportGrid'>
          <ExchangeRateGrid {...this.props} />
        </div>

      </div>
    );
  }
}

export default ExchangeRateDashboard;