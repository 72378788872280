import React, {Component} from 'react';
import './CptDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider,DatePicker} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import CptGrid from './CptGrid';
import AddOrEditCptModalDialog from './AddOrEditCptModalDialog';
import AddOrEditCptSubModalDialog from './AddOrEditCptSubModalDialog';
import BatchChangeStatusModalDialog from './BatchChangeStatusModalDialog';
import FinishTaskModalDialog from './FinishTaskModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../../utils/QuestionnaireEmailSendMode';
import QuestionnaireEmailEditDialog from './QuestionnaireEmailEditDialog';
import moment from 'moment';
const { MonthPicker } = DatePicker;
class CptDashboard extends Component {
    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});
        this.props.loadCptInfo({id, location: undefined, fundCompany: undefined});
        this.props.loadLicensingForm({type: 'CPT',status: 'Activated'});
    }


    componentDidUpdate() {
        const {
            loadCptInfoActionStatus,
            loadCptInfoResp,
        } = this.props;
        if(loadCptInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadCptInfoActionStatus();
        }
        if(loadCptInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load Reporting Summary Info failed';
            if(!!loadCptInfoResp && !!loadCptInfoResp.respMessage) {
                err = loadCptInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadCptInfoActionStatus();
        }
    }

    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }

    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };




    refreshBtnOnClicked = () => {
        const {
            id,
            category,
            fundco,
            owner,
            monthStart,
            status,
        } = this.props.cptQueryFields;
        this.props.loadCptInfo({id, category, fundco,owner,monthStart,status});
    }


    addOrEditCptBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditCptModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedCptArray,
            } = this.props;
            if(selectedCptArray.length !== 1) {
                message.warning("Please choose one and only one Work Management");
                return;
            }
            const currentData = selectedCptArray[0];
            this.props.openAddOrEditCptModal(mode, currentData);
        }
    }

    addOrEditCptSubBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
             // COPY, EDIT
            const {
                selectedCptArray,
            } = this.props;
            if(selectedCptArray.length !== 1) {
                message.warning("Please choose one and only one Work Management");
                return;
            }
            const idList = selectedCptArray.map(e => e.id);
            this.props.openAddOrEditCptSubModal(mode, null,idList);
        } else {  // COPY, EDIT
            const {
                selectedCptTaskArray,
            } = this.props;
            if(selectedCptTaskArray.length !== 1) {
                message.warning("Please choose one and only one Work Management Sub");
                return;
            }
            const currentData = selectedCptTaskArray[0];
            this.props.openAddOrEditCptSubModal(mode, currentData,null);
        }
    }


    batchChangeStatusBtnOnClicked = (mode) => {
        if(mode === 'DEL' || mode === 'Terminated' ){
            const {
                selectedCptArray,
            } = this.props;
            if(selectedCptArray.length === 0) {
                message.warning("Please choose at least one ");
                return;
            }
            const idList = selectedCptArray.map(e => e.id);
            this.props.openBatchChangeStatusModal(mode, idList);
        } 
        // if( mode === 'Terminated') {
        //     const {
        //         selectedCptTaskArray,
        //     } = this.props;
        //     if(selectedCptTaskArray.length === 0) {
        //         message.warning("Please choose at least one Work Management Sub");
        //         return;
        //     }
        //     const idList = selectedCptTaskArray.map(e => e.id);
        //     this.props.openBatchChangeStatusModal(mode, idList);
        // }

    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'dataType', 'otherInfo', 'operateType', 'comments', 'before', 'after'];
    constructor(props){
        super(props);
        this.state={
            detailData:{
            }
        }
    }
 
    onInputChange = ({name, value}) => {
        this.props.onQueryInputChange({ name, value });
        const { detailData } = this.state;
        let updateData =  {
            ...detailData,
            [name]: value
        };
        this.setState({
            detailData: updateData
        })
    }

// 接受问卷答案
    acceptQuestionnaireAnswerBtnOnClicked = () => {
        const selectedQuestionnaireResults = this.props.selectedCptArray;

        if (selectedQuestionnaireResults.length === 0) {
        message.warning("Please select one result at least");
        return;
        }

        const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);

        // this.props.openQuestionnaireResultOperateModalDialog('Accept', questionnaireResultGuidList);
        this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_ACCEPT, {
        questionnaireResultGuidList,
        });
    }

    // 拒绝问卷答案
    rejectQuestionnaireAnswerBtnOnClicked = () => {
        const selectedQuestionnaireResults = this.props.selectedCptArray;

        if (selectedQuestionnaireResults.length === 0) {
        message.warning("Please select one result at least");
        return;
        }

        const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);

        // this.props.openQuestionnaireResultOperateModalDialog('Reject', questionnaireResultGuidList);
        this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_REJECT, {
        questionnaireResultGuidList,
        });
    }

  // 查看问卷答案
  viewQuestionnaireAnswerBtnOnClicked = () => {
    const {
        selectedCptArray,
    } = this.props;

    if (selectedCptArray.length !== 1) {
      message.warning("Please select a questionnaire result");
      return;
    }

    const questionnaireResult = selectedCptArray[0];

    this.props.history.push(`/questionnaire-answer-review/${questionnaireResult.guid}`);
  }

  // 重发邮件提醒
  remindEmailBtnOnClicked = () => {
    const selectedQuestionnaireResults = this.props.selectedCptArray;

    if (selectedQuestionnaireResults.length === 0) {
      message.warning("Please select 11111one result at least");
      return;
    }

    const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);

    // this.props.sendRemindEmail(questionnaireResultGuidList);

    this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.EMAIL_RESEND, {
      questionnaireResultGuidList,
    });
  }

  // 下载pdf
  downloadPDFBtnOnClicked = () => {
    const selectedQuestionnaireResults = this.props.selectedCptArray;
    if (selectedQuestionnaireResults.length === 0) {
      message.warning("Please select one questionnaire result at least");
      return;
    }
    const checkList = selectedQuestionnaireResults.filter(e => !!e.savedFileName);
    if(checkList.length === 0) {
      message.warning("At least one of the selected record must have Saved File Name");
      return;
    }
    if(checkList.length > 100) {
      message.warning("You can download at most 100 files at one time");
      return;
    }
    const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);
    this.props.downloadQuestionnaireResultPDF(questionnaireResultGuidList);
  }     






    render() {

        const {
            detailData
        } = this.state;

        let data = {
            monthStart: moment().format('YYYY-MM'),
            fileList: []
        };
        data ={...data,...detailData};
       // let month =moment().format('YYYY-MM');
        const {
            locationOptions,
            fundCompanyOptions,
            loadCptInfoActionStatus,
            categoryOptions,
            departmentOptions,
            officeOptions,
            fundCoOptions,
            statusOptions,
            monthStart,
        } = this.props;
        const {
            id,
            category,
            fundco,
            owner,
            endDate,
        } = this.props.cptQueryFields;
        const refreshBtnIsLoading = loadCptInfoActionStatus === ACTION_STATUS.LOGINING;

        // const isLoading = loadQuestionnaireResultsActionStatus === ACTION_STATUS.LOGINING;
        // const acceptBtnLoading = acceptQuestionnaireResultActionStatus === ACTION_STATUS.LOGINING;
        // const rejectBtnLoading = rejectQuestionnaireResultActionStatus === ACTION_STATUS.LOGINING;
        // const remindEmailBtnLoading = sendRemindEmailActionStatus === ACTION_STATUS.LOGINING;
        // const donwloadPDFBtnLoading = downloadQuestionnaireResultPDFActionStatus === ACTION_STATUS.LOGINING;
    
        return (
            <div className='CptWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Licensing</Breadcrumb.Item>
                        <Breadcrumb.Item>CPT</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <div className='breadcrumbWrapper'>   
                    <Breadcrumb>
                    <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                    </Breadcrumb>
                    </div>
                    {/* <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'category', value: val })
                        }} 

                        placeholder="Category">
                            {categoryOptions}
                    </Select>
                    <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'fundco', value: val })
                        }} 
                        placeholder="Fund/Co">
                            {fundCoOptions}
                    </Select> */}
                    <Input 
                        placeholder="Name"
                        size="small"
                        style={{ width: 130, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onQueryInputChange({ name: 'name', value: event.target.value })
                        }} 
                    />
{/* 
                    <MonthPicker
                        placeholder="Select Month"
                        style={{ marginLeft: 10, width: 130}}
                        allowClear={true}
                        // value={moment(
                        //     data.monthStart,
                        //     'YYYY-MM'
                        // )}
                        size="small"
                        onChange={(date, dateString) => {
                            this.onInputChange({
                            name: 'monthStart',
                            value: dateString
                            });
                        }}
                    /> */}

                    <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'status', value: val })
                        }} 
                        placeholder="Status">
                            {statusOptions}
                    </Select>
                    {/* <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='Start Date'
                        value={startDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'startDate', value: date });
                        }} 
                    />
                    <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='End Date'
                        value={endDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'endDate', value: date });
                        }} 
                    /> */}

                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditCptBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditCptBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>

                    <Divider type='vertical' />


                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('Terminated')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Terminate
                    </Button>
                    <Button 
                        onClick={this.acceptQuestionnaireAnswerBtnOnClicked} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Accept
                    </Button>
                    <Button 
                        className='negative-button'
                        size='small'
                        style={{ marginLeft: 10 }}
                        onClick={this.rejectQuestionnaireAnswerBtnOnClicked} 
                        // loading={rejectBtnLoading}
                    >Reject</Button>
                    <Button 
                        onClick={this.remindEmailBtnOnClicked} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Renew
                    </Button>
                    
                    {/* <Button 
                        onClick={()=>this.addOrEditCptBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button> */}
                    
                    {/* <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'Cpt'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button> */}

                </div>

                <div className='CptGrid'><CptGrid {...this.props} /></div>
                <AddOrEditCptModalDialog {...this.props} />
                <AddOrEditCptSubModalDialog {...this.props} />
                <BatchChangeStatusModalDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />
                <QuestionnaireEmailEditDialog {...this.props} />

            </div>
        );
    }
}

export default CptDashboard;
