import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Sync Short Stock
  START_SYNC_SHORT_STOCK,
  SYNC_SHORT_STOCK_SUCCESS,
  SYNC_SHORT_STOCK_FAILURE,
  INIT_SYNC_SHORT_STOCK_ACTION_STATUS,

  // Load Short Stock
  START_LOAD_SHORT_STOCK,
  LOAD_SHORT_STOCK_SUCCESS,
  LOAD_SHORT_STOCK_FAILURE,
  INIT_LOAD_SHORT_STOCK_ACTION_STATUS,

} from './shortStockConstants';

// Sync Short Stock
function startSyncShortStock() {
  return {
    type: START_SYNC_SHORT_STOCK
  };
}

export function syncShortStock() {
  return function(dispatch) {
    dispatch(startSyncShortStock());
    httpClient
      .post(UrlConfig.SYNC_SHORT_STOCK)
      .then(resp => dispatch(syncShortStockSuccess(resp)))
      .catch(err => dispatch(syncShortStockFailure(err)));
  };
}

function syncShortStockSuccess(resp) {
  return {
    type: SYNC_SHORT_STOCK_SUCCESS,
    payload: resp
  };
}

function syncShortStockFailure(err) {
  return {
    type: SYNC_SHORT_STOCK_FAILURE,
    payload: err
  };
}

export function initSyncShortStockActionStatus() {
  return {
    type: INIT_SYNC_SHORT_STOCK_ACTION_STATUS
  };
}

// Load Short Stock
function startLoadShortStock() {
  return {
    type: START_LOAD_SHORT_STOCK
  };
}

export function loadShortStock() {
  return function(dispatch) {
    dispatch(startLoadShortStock());
    httpClient
      .get(UrlConfig.LOAD_SHORT_STOCK)
      .then(resp => dispatch(loadShortStockSuccess(resp)))
      .catch(err => dispatch(loadShortStockFailure(err)));
  };
}

function loadShortStockSuccess(resp) {
  return {
    type: LOAD_SHORT_STOCK_SUCCESS,
    payload: resp
  };
}

function loadShortStockFailure(err) {
  return {
    type: LOAD_SHORT_STOCK_FAILURE,
    payload: err
  };
}

export function initLoadShortStockActionStatus() {
  return {
    type: INIT_LOAD_SHORT_STOCK_ACTION_STATUS
  };
}