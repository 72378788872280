import {connect} from 'react-redux';
import ThirteenFLongPosReportDashboard from '../components/ThirteenFLongPosReportDashboard';

import {
  // Generate 13F Long Report
  generateThirteenFLongPosReport,
  initGenerateThirteenFLongPosReportActionStatus,

  // Load 13F Long Report
  loadThirteenFLongPosReport,
  initLoadThirteenFLongPosReportActionStatus,

  // Open/Reset 13F Long Report Check Result Modal Dialog
  openThirteenFLongPosReportCheckResultModalDialog,
  resetThirteenFLongPosReportCheckResultModalDialog,

  // Load 13F Long Report Check Result
  loadThirteenFLongPosReportCheckResult,
  initLoadThirteenFLongPosReportCheckResultActionStatus,

  // Report 13F Long Report
  reportThirteenFLongPosReport,
  initReportThirteenFLongPosReportActionStatus,

  // Report 13F Long Pos Summary Report
  reportThirteenFLongPosSummaryReport,
  initReportThirteenFLongPosSummaryReportActionStatus,

} from '../thirteenFLongPosReportActions';

const mapStateToProps = state => {
  return {
    // Grid Columns
    thirteenFLongPosReportGridColumns: state.thirteenFLongPosReport.thirteenFLongPosReportGridColumns,
    thirteenFLongPosReportChkResultGridColumns: state.thirteenFLongPosReport.thirteenFLongPosReportChkResultGridColumns,

    // 13F Long Report
    thirteenFLongPosReportArray: state.thirteenFLongPosReport.thirteenFLongPosReportArray,

    // Generate 13F Long Report Action Status
    generateThirteenFLongPosReportActionStatus: state.thirteenFLongPosReport.generateThirteenFLongPosReportActionStatus,
    generateThirteenFLongPosReportErrMsg: state.thirteenFLongPosReport.generateThirteenFLongPosReportErrMsg,

    // Load 13F Long Report Action Status
    loadThirteenFLongPosReportActionStatus: state.thirteenFLongPosReport.loadThirteenFLongPosReportActionStatus,
    loadThirteenFLongPosReportErrMsg: state.thirteenFLongPosReport.loadThirteenFLongPosReportErrMsg,

    // 13F Long Report Check Result Modal Dialog
    thirteenFLongPosReportCheckResultControlModal: state.thirteenFLongPosReport.thirteenFLongPosReportCheckResultControlModal,

    // 13F Long Report Check Result
    thirteenFLongPosReportChkResult: state.thirteenFLongPosReport.thirteenFLongPosReportChkResult,

    // Load 13F Long Report Check Result Action Status
    loadThirteenFLongPosReportCheckResultActionStatus: state.thirteenFLongPosReport.loadThirteenFLongPosReportCheckResultActionStatus,
    loadThirteenFLongPosReportCheckResultErrMsg: state.thirteenFLongPosReport.loadThirteenFLongPosReportCheckResultErrMsg,

    // Report 13F Long Report
    reportThirteenFLongPosReportActionStatus: state.thirteenFLongPosReport.reportThirteenFLongPosReportActionStatus,
    reportFilePath: state.thirteenFLongPosReport.reportFilePath,
    reportThirteenFLongPosReportErrMsg: state.thirteenFLongPosReport.reportThirteenFLongPosReportErrMsg,

    // Report 13F Long Pos Summary Report
    reportThirteenFLongPosSummaryReportActionStatus: state.thirteenFLongPosReport.reportThirteenFLongPosSummaryReportActionStatus,
    summaryReportFilePath: state.thirteenFLongPosReport.summaryReportFilePath,
    reportThirteenFLongPosSummaryReportErrMsg: state.thirteenFLongPosReport.reportThirteenFLongPosSummaryReportErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Generate 13F Long Report
    generateThirteenFLongPosReport: (fundCode, dateString) => dispatch(generateThirteenFLongPosReport(fundCode, dateString)),
    initGenerateThirteenFLongPosReportActionStatus: () => dispatch(initGenerateThirteenFLongPosReportActionStatus()),

    // Load 13F Long Report
    loadThirteenFLongPosReport: (fundCode, dateString) => dispatch(loadThirteenFLongPosReport(fundCode, dateString)),
    initLoadThirteenFLongPosReportActionStatus: () => dispatch(initLoadThirteenFLongPosReportActionStatus()),

    // Open/Reset 13F Long Report Check Result Modal Dialog
    openThirteenFLongPosReportCheckResultModalDialog: (fundCode, dateString) => dispatch(openThirteenFLongPosReportCheckResultModalDialog(fundCode, dateString)),
    resetThirteenFLongPosReportCheckResultModalDialog: () => dispatch(resetThirteenFLongPosReportCheckResultModalDialog()),

    // Load 13F Long Report Check Result
    loadThirteenFLongPosReportCheckResult: (fundCode, dateString) => dispatch(loadThirteenFLongPosReportCheckResult(fundCode, dateString)),
    initLoadThirteenFLongPosReportCheckResultActionStatus: () => dispatch(initLoadThirteenFLongPosReportCheckResultActionStatus()),

    // Report 13F Long Report
    reportThirteenFLongPosReport: (fundCode, dateString) => dispatch(reportThirteenFLongPosReport(fundCode, dateString)),
    initReportThirteenFLongPosReportActionStatus: () => dispatch(initReportThirteenFLongPosReportActionStatus()),

    // Report 13F Long Pos Summary Report
    reportThirteenFLongPosSummaryReport: (dateString) => dispatch(reportThirteenFLongPosSummaryReport(dateString)),
    initReportThirteenFLongPosSummaryReportActionStatus: () => dispatch(initReportThirteenFLongPosSummaryReportActionStatus()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirteenFLongPosReportDashboard);