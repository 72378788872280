import {connect} from 'react-redux';
import ShortStockDashboard from '../components/ShortStockDashboard';

import {
  // Sync Short Stock
  syncShortStock,
  initSyncShortStockActionStatus,

  // Load Short Stock
  loadShortStock,
  initLoadShortStockActionStatus,

} from '../shortStockActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    shortStockMetaGridColumns: state.shortStock.shortStockMetaGridColumns,
    shortStockGridColumns: state.shortStock.shortStockGridColumns,

    // Short Stock
    shortStockArray: state.shortStock.shortStockArray,

    // Sync Short Stock Action Status
    syncShortStockActionStatus: state.shortStock.syncShortStockActionStatus,
    syncShortStockErrMsg: state.shortStock.syncShortStockErrMsg,

    // Load Short Stock Action Status
    loadShortStockActionStatus: state.shortStock.loadShortStockActionStatus,
    loadShortStockErrMsg: state.shortStock.loadShortStockErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Sync Short Stock
    syncShortStock: () => dispatch(syncShortStock()),
    initSyncShortStockActionStatus: () => dispatch(initSyncShortStockActionStatus()),

    // Load Short Stock
    loadShortStock: () => dispatch(loadShortStock()),
    initLoadShortStockActionStatus: () => dispatch(initLoadShortStockActionStatus()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortStockDashboard);