import React, {Component} from 'react';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';

import moment from 'moment';
import { Button, Checkbox, message, Radio, Input, DatePicker, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { QUESTION_TYPE_CODE } from '../../../../utils/QuestionTypeUtil';
import { QUESTIONNAIRE_STATUS_CODE } from '../../../../utils/QuestionnaireStatus';
import { QUESTIONNAIRE_TYPE_CODE } from '../../../../utils/QuestionnaireType';

import QuestionAttatchment from './QuestionAttatchment';
import SubmittedHistoryDialog from './SubmittedHistoryDialog';
import './QuestionnairePreview.css';

class LicensingPersonalAnswer extends Component {

  state = {
    undoneErrMsgShow: false,
    unsignedErrMsgShow: false,
    saveLicensingPersonalSucc: false,
    authToken: '',
  };

  componentDidMount() {
    this.loadTodoLicensingPersonal();
  }

  // 查询TodoQuestionnair
  loadTodoLicensingPersonal() {
    const search = this.props.location.search;
    if (search === undefined || search === null) {
      return;
    }

    const searchStrArray = search.split('=');

    if (searchStrArray.length != 2) {
      return;
    }

    const authToken = searchStrArray[1];
    this.setState({
      authToken
    });

    this.props.loadTodoLicensingPersonal(authToken);
    this.props.loadSubmittedLicensingPersonalHistory(authToken);
  }

  componentDidUpdate() {
    const {
      submitLicensingPersonalAnswerActionStatus,
      saveLicensingPersonalAnswerActionStatus,
    } = this.props;

    // 问卷调查提交成功
    if (submitLicensingPersonalAnswerActionStatus === ACTION_STATUS.SUCCESS) {
      // message.success('The questionnaire answers are submitted successfully!');
      this.props.initSubmitLicensingPersonalAnswerActionStatus();
      this.loadTodoLicensingPersonal();
      this.setState({
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
    }

    // 问卷调查提交失败
    if (submitLicensingPersonalAnswerActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSubmitLicensingPersonalAnswerActionStatus();
      this.setState({
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
    }

    // 问卷调查保存成功
    if (saveLicensingPersonalAnswerActionStatus === ACTION_STATUS.SUCCESS) {
      this.setState({
        saveLicensingPersonalSucc: true,
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
      this.props.initSaveLicensingPersonalAnswerActionStatus();
      this.loadTodoLicensingPersonal();
    }

    // 问卷调查保存失败
    if (saveLicensingPersonalAnswerActionStatus === ACTION_STATUS.ERROR) {
      this.setState({
        saveLicensingPersonalSucc: false,
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
      this.props.initSaveLicensingPersonalAnswerActionStatus();
    }
  }

  // Check
  questionnaireCheckOnChanged = (data, valueOption, e) => {
    if (e.target.checked) {
      if (data.answerContent === null || data.answerContent === '') {
        data.answerContent = valueOption;
      } else {
        data.answerContent = data.answerContent + ',' + valueOption;
      }
    } else {
      data.answerContent = data.answerContent.replace(valueOption, '');
      let answerContentArray = data.answerContent.split(',');
      answerContentArray = answerContentArray.filter(item => !!item);
      data.answerContent = answerContentArray.join(',');;
    }

    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent: data.answerContent,
    });
  }

  // Radio
  questionnaireRadioOnChanged = (data, e) => {
    console.log('radio checked', e.target.value);

    let answerContent = e.target.value;
    
    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
    });
  };

  // Date
  questionnaireDateOnChanged = (date, dateString, data) => {
    console.log('date ', dateString);

    let answerContent = dateString;
    
    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
    });
  };

  // Text
  questionnaireTextOnChanged = (data, e) => {
    console.log('text ', e.target.value);

    let answerContent = e.target.value;
    
    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
    });
  };

  // 附件上传
  attachmentHandleChange = (data, info) => {
    let fileList = [...info.fileList]; 

    const fileNameList = fileList.map(file => {
      if (file.response) {
        const filename = file.response.data.fileNameWithTimeStamp;
        return filename;
      }
    });

    let answerContent = fileNameList.join(',');

    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
      fileList,
    });
  }

  cancelButtonOnClicked = () => {
    this.props.history.goBack();
  }

  //  Submitter Text
  questionnaireSubmitterOnChanged = (e) => {
    console.log('text ', e.target.value);

    let submitter = e.target.value;
  
    this.props.questionnaireAnswerSubmitterEditChangeInput(submitter);
  };

  // 提交
  submitButtonOnClicked = () => {
    const {
      todoQuestionArray,
      todoLicensingPersonalAuthToken,
      questionnaireAnswerDate,
      questionnaireSubmitter,
    } = this.props;

    const filterTodoQuestions = todoQuestionArray.filter(questionnaire => questionnaire.answerContent === null || questionnaire.answerContent === '');

    let undoneErrMsgShow = false;
    if (filterTodoQuestions.length > 0) {
      undoneErrMsgShow = true;
    }

    this.setState({
      undoneErrMsgShow,
    });

    if (!undoneErrMsgShow) {
      let answerParam = [];
      
      for (let i = 0; i < todoQuestionArray.length; i++) {
        const todoQuestion = todoQuestionArray[i];
        const answerContent = todoQuestion.answerContent;
  
        answerParam.push({
          answerGuid: todoQuestion.answerGuid,
          questionnaireGuid: todoQuestion.questionnaireGuid,
          questionGuid: todoQuestion.questionGuid,
          answerContent: todoQuestion.answerContent,
        })
  
        for (let j = 0; j < todoQuestion.subTodoQuestionList.length; j++) {
          const subTodoQuestion = todoQuestion.subTodoQuestionList[j];
          const triggerOption = subTodoQuestion.triggerOption;
  
          const questionEnglish = subTodoQuestion.questionEnglish;
          const questionChinese = subTodoQuestion.questionChinese;
  
          if (triggerOption === answerContent && (subTodoQuestion.answerContent === null || subTodoQuestion.answerContent === "")) {
            message.warning(`The answer to the question " ${ questionEnglish } ( ${ questionChinese } ) " cannot be empty when you choose choice ${triggerOption}`);
            answerParam = [];
            return;
          }
  
          answerParam.push({
            answerGuid: subTodoQuestion.answerGuid,
            questionnaireGuid: subTodoQuestion.questionnaireGuid,
            questionGuid: subTodoQuestion.questionGuid,
            answerContent: subTodoQuestion.answerContent,
          })
        }
      }

      if (questionnaireSubmitter === null || questionnaireSubmitter === "") {
        // message.warning("Please sign at the bottom of the questionnaire");
        this.setState({
          unsignedErrMsgShow: true,
        });
        answerParam = [];
        return;
      }
      
      this.props.submitLicensingPersonalAnswer(todoLicensingPersonalAuthToken, answerParam, questionnaireSubmitter, questionnaireAnswerDate);
    }
  }

  // 保存
  saveButtonOnClicked = () => {
    const {
      todoQuestionArray,
      todoLicensingPersonalAuthToken,
      questionnaireAnswerDate,
      questionnaireSubmitter,
    } = this.props;

    let answerParam = [];

    for (let i = 0; i < todoQuestionArray.length; i++) {
      const todoQuestion = todoQuestionArray[i];
      const answerContent = todoQuestion.answerContent;

      answerParam.push({
        answerGuid: todoQuestion.answerGuid,
        questionnaireGuid: todoQuestion.questionnaireGuid,
        questionGuid: todoQuestion.questionGuid,
        answerContent: todoQuestion.answerContent,
      })

      for (let j = 0; j < todoQuestion.subTodoQuestionList.length; j++) {
        const subTodoQuestion = todoQuestion.subTodoQuestionList[j];
        const triggerOption = subTodoQuestion.triggerOption;

        const questionEnglish = subTodoQuestion.questionEnglish;
        const questionChinese = subTodoQuestion.questionChinese;

        // if (triggerOption === answerContent && (subTodoQuestion.answerContent === null || subTodoQuestion.answerContent === "")) {
        //   message.warning(`The answer to the question " ${ questionEnglish } ( ${ questionChinese } ) " cannot be empty when you choose choice ${triggerOption}`);
        //   answerParam = [];
        //   return;
        // }

        answerParam.push({
          answerGuid: subTodoQuestion.answerGuid,
          questionnaireGuid: subTodoQuestion.questionnaireGuid,
          questionGuid: subTodoQuestion.questionGuid,
          answerContent: subTodoQuestion.answerContent,
        })
      }
    }

    this.props.saveLicensingPersonalAnswer(todoLicensingPersonalAuthToken, answerParam, questionnaireSubmitter, questionnaireAnswerDate);
  }

  showSubmittedHistoryBtnOnClicked = () => {
    this.props.openSubmittedLicensingPersonalHistoryModalDialog(this.state.authToken);
  }

  render() {
    const {
      loadTodoLicensingPersonalActionStatus,
      loadTodoLicensingPersonalActionResp,

      submitLicensingPersonalAnswerActionStatus,
      submitLicensingPersonalAnswerErrMsg,

      saveLicensingPersonalAnswerActionStatus,
      saveLicensingPersonalAnswerErrMsg,

      todoQuestionArray,
      questionnaireTitle,
      questionnaireAnsweree,
      questionnaireAnswerDate,
      questionnaireSubmitter,
      questionnaireStatus,
      todoLicensingPersonal,

      // LicensingPersonal attachment Upload
      questionnaireAttachmentUploadUrl,

      submittedLicensingPersonalHistoryArray,
    } = this.props;


    // 点击邮件中的链接直接跳转，如果此时用户未登录，要报错，并提示进行登录
    if(!!loadTodoLicensingPersonalActionResp && loadTodoLicensingPersonalActionResp.respCode === 'GF_NO_AUTH') {
      return (
        <div className='questionnaireDashboardWrapper'>
          <div className='questionnaireDashboardContent'>
            <Message negative>
                <Message.Header>You haven't logined yet. Please click on the following button to login first.</Message.Header>
                </Message>
                <Button 
                      onClick={()=>{
                        window.location.href = 'https://' + BaseUrlConfig.report_frontend_dn;
                      }} 
                      size='small'
                      style={{ marginLeft: 10 }}
                      type='primary'>
                      Go To Login
                </Button>
          </div>
        </div>
      );
    }


    const {
      undoneErrMsgShow,
      unsignedErrMsgShow,
      saveLicensingPersonalSucc,
    } = this.state;

    const isSubmitBtnLoading = submitLicensingPersonalAnswerActionStatus === ACTION_STATUS.LOGINING;
    const isSaveBtnLoading = saveLicensingPersonalAnswerActionStatus === ACTION_STATUS.LOGINING;
    const readOnlyMode = questionnaireStatus === QUESTIONNAIRE_STATUS_CODE.SUBMITTED || questionnaireStatus === QUESTIONNAIRE_STATUS_CODE.ACCEPTED;

    return (
      <div className='questionnaireDashboardWrapper'>
        <div className='questionnaireDashboardContent'>
          <span className='title'>
            { questionnaireTitle }
          </span>
          {
            questionnaireStatus === QUESTIONNAIRE_STATUS_CODE.SUBMITTED && 
            <Message positive>
              <Message.Header>Your answer(s) has been submitted!</Message.Header>
            </Message>
          }
          <div className='question-content'>
            {
              todoQuestionArray.map((value, index) => {
                let isErrorQuestion = false;
                let borderColor = '';
                if (readOnlyMode && (value.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE ||
                    value.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE)
                    && todoLicensingPersonal.type === QUESTIONNAIRE_TYPE_CODE.QUIZ) {
                  isErrorQuestion = value.answerContent !== value.correctAnswer;
                  borderColor = isErrorQuestion ? '1px solid red' : '';
                }

                return (
                  <div className='questionContainer' style={{ border: borderColor }} key={index}>
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>{value.questionNumber}.</span>
                      <div>
                        <div className='question-desc'>
                          { value.questionEnglish }
                        </div>
                        <div className='question-desc'>
                          { value.questionChinese }
                        </div>
                      </div>
                    </div>
                    <div className='answerContainer'>
                      {
                        value.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE && 
                        <Radio.Group onChange={this.questionnaireRadioOnChanged.bind(this, value)} value={value.answerContent}>
                          {
                            value.optionsContent.split('|').map((valueOption, indexOption) => {
                              const key = indexOption + '_' + value.answerGuid;
                              return (
                                <Radio 
                                  className='radioStyle' 
                                  value={valueOption} 
                                  key={key} 
                                  disabled={readOnlyMode}
                                >{ valueOption }</Radio>
                              )
                            })
                          }
                        </Radio.Group>
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE && 
                        <div>
                          {
                            value.optionsContent.split('|').map((valueOption, indexOption) => {
                              const key = indexOption + '_' + value.answerGuid;
                              let answerChecked = false;
                              if (value.answerContent !== null && value.answerContent !== '' && value.answerContent.includes(valueOption)) {
                                answerChecked = true;
                              }

                              return (
                                <Checkbox 
                                  key={key}
                                  className='checkStyle'
                                  checked={answerChecked}
                                  disabled={readOnlyMode}
                                  onChange={this.questionnaireCheckOnChanged.bind(this, value, valueOption)}>{ valueOption }</Checkbox>
                              )
                            })
                          }
                        </div>
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.TEXT && 
                        <Input 
                          value={value.answerContent} 
                          disabled={readOnlyMode}
                          onChange={this.questionnaireTextOnChanged.bind(this, value)}
                          style={{ width: '70%' }}/>
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.DATE && 
                        <DatePicker 
                          disabled={readOnlyMode}
                          value={value.answerContent === '' || value.answerContent === null ? null : moment(value.answerContent)   }
                          onChange={(date, dateString) => this.questionnaireDateOnChanged(date, dateString, value)} />
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.UPLOAD && 
                        <Upload
                          disabled={readOnlyMode}
                          withCredentials={true}
                          action={questionnaireAttachmentUploadUrl}
                          onChange={this.attachmentHandleChange.bind(this, value)}
                          fileList={value.fileList}>
                          <Button>
                            <Icon type="upload" /> Upload
                          </Button>
                        </Upload>
                      }
                      {/* 展示附件 */}
                      {
                        (!!value.attachment && !!value.attachmentOriginName) &&
                        <QuestionAttatchment attachment={ value.attachment } attachmentOriginName={ value.attachmentOriginName } />
                      }
                      {
                        isErrorQuestion && 
                        <div style={{ marginTop: 10, color: 'red', fontWeight: 'bold' }}>
                          The correct answer is {value.correctAnswer}.
                        </div>
                      }
                    </div>
                    {/* 子问题 */}
                    <div className="sub-question-content">
                      {
                        value.subTodoQuestionList.map((subValue, subQuestionIndex) => {
                          const subQuestionContainerkey = subQuestionIndex + '_' + subValue.questionGuid + '_' + subValue.answerGuid;
                          let isErrorSubQuestion = false;
                          let borderColorOfSubQ = '';
                          if (readOnlyMode && (subValue.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE || 
                              subValue.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE)
                              && todoLicensingPersonal.type === QUESTIONNAIRE_TYPE_CODE.QUIZ) {
                            isErrorSubQuestion = subValue.answerContent !== subValue.correctAnswer;
                            borderColorOfSubQ = isErrorQuestion ? '1px solid red' : '';
                          } 

                          return (
                            <div key={subQuestionContainerkey}>
                              {
                                value.answerContent === subValue.triggerOption && 
                                <div className='sub-question-container' style={{ border: borderColorOfSubQ }}>
                                  <div className='sub-question-desc-container'>
                                    {/* <span className='sub-question-desc-number'>{subValue.subQuestionNumber}.</span> */}
                                    <div>
                                      <div className='sub-question-desc'>
                                        { subValue.questionEnglish }
                                      </div>
                                      <div className='sub-question-desc'>
                                        { subValue.questionChinese }
                                      </div>
                                    </div>
                                  </div>
                                  <div className='answerContainer'>
                                    {
                                      subValue.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE && 
                                      <Radio.Group onChange={this.questionnaireRadioOnChanged.bind(this, subValue)} value={subValue.answerContent}>
                                        {
                                          subValue.optionsContent.split('|').map((subValueOption, subIndexOption) => {
                                            const key = subIndexOption + '_' + subValue.answerGuid;
                                            return (
                                              <Radio 
                                                className='radioStyle' 
                                                disabled={readOnlyMode}
                                                value={subValueOption} 
                                                key={key}>{ subValueOption }</Radio>
                                            )
                                          })
                                        }
                                      </Radio.Group>
                                    }
                                    {
                                      subValue.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE && 
                                      <div>
                                        {
                                          subValue.optionsContent.split('|').map((subValueOption, subIndexOption) => {
                                            const key = subIndexOption + '_' + subValue.answerGuid;
                                            let answerChecked = false;
                                            if (subValue.answerContent !== null && subValue.answerContent !== '' && subValue.answerContent.includes(subValueOption)) {
                                              answerChecked = true;
                                            }
            
                                            return (
                                              <Checkbox 
                                                key={key}
                                                disabled={readOnlyMode}
                                                className='checkStyle'
                                                checked={answerChecked}
                                                onChange={this.questionnaireCheckOnChanged.bind(this, value, subValueOption)}>{ subValueOption }</Checkbox>
                                            )
                                          })
                                        }
                                      </div>
                                    }
                                    {
                                      subValue.questionType === QUESTION_TYPE_CODE.TEXT && 
                                      <Input 
                                        disabled={readOnlyMode}
                                        value={subValue.answerContent} 
                                        onChange={this.questionnaireTextOnChanged.bind(this, subValue)}
                                        style={{ width: '70%' }}/>
                                    }
                                    {
                                      subValue.questionType === QUESTION_TYPE_CODE.DATE && 
                                      <DatePicker 
                                        disabled={readOnlyMode}
                                        value={subValue.answerContent === '' || subValue.answerContent === null ? null : moment(subValue.answerContent)   }
                                        onChange={(date, dateString) => this.questionnaireDateOnChanged(date, dateString, subValue)} />
                                    }
                                    {
                                      subValue.questionType === QUESTION_TYPE_CODE.UPLOAD && 
                                      <Upload
                                        disabled={readOnlyMode}
                                        withCredentials={true}
                                        action={questionnaireAttachmentUploadUrl}
                                        onChange={this.attachmentHandleChange.bind(this, subValue)}
                                        fileList={subValue.fileList}>
                                        <Button>
                                          <Icon type="upload" /> Upload
                                        </Button>
                                      </Upload>
                                    }
                                    {/* 展示附件 */}
                                    {
                                      (!!subValue.attachment && !!subValue.attachmentOriginName) &&
                                      <QuestionAttatchment attachment={ subValue.attachment } attachmentOriginName={ subValue.attachmentOriginName } />
                                    }
                                    {
                                      isErrorSubQuestion && 
                                      <div style={{ marginTop: 10, color: 'red', fontWeight: 'bold' }}>
                                        The correct answer is {subValue.correctAnswer}.
                                      </div>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className="signature-container">
            <div>
              <span>Signature:</span><br/>
              <span>签名:</span>
            </div>
            <input 
              style={{ marginTop: 10, marginLeft: 30 }}
              value={questionnaireSubmitter}
              disabled={readOnlyMode}
              onChange={this.questionnaireSubmitterOnChanged.bind(this)}/>
            <div style={{ marginTop: 10 }}>
              <span>Name 姓名: { questionnaireAnsweree }</span><br/>
              <span>Date 日期: { questionnaireAnswerDate }</span>
            </div>
          </div>

          {
            questionnaireStatus === QUESTIONNAIRE_STATUS_CODE.SUBMITTED && 
            <Message positive>
              <Message.Header>Your answer(s) has been submitted!</Message.Header>
            </Message>
          }
          
          {
            undoneErrMsgShow && 
            <Message negative>
              <Message.Header>You haven't finished all the questions yet</Message.Header>
            </Message>
          }
          {
            unsignedErrMsgShow && 
            <Message negative>
              <Message.Header>You haven't signed your name yet</Message.Header>
            </Message>
          }
          {
            saveLicensingPersonalSucc &&
            <Message positive>
              <Message.Header>The questionnaire is saved successfully!</Message.Header>
            </Message>
          }
          {
            !!saveLicensingPersonalAnswerErrMsg &&
            <Message negative>
              <Message.Header>{saveLicensingPersonalAnswerErrMsg}</Message.Header>
            </Message>
          }
          {
            !!submitLicensingPersonalAnswerErrMsg && 
            <Message negative>
              <Message.Header>{submitLicensingPersonalAnswerErrMsg}</Message.Header>
            </Message>
          }
          <div className='buttons'>
            <Button 
              type="primary" 
              onClick={this.saveButtonOnClicked} 
              loading={isSaveBtnLoading}
              disabled={readOnlyMode}
              >Save</Button>
            <Button 
              type="primary" 
              style={{ marginLeft: 10 }}
              onClick={this.submitButtonOnClicked} 
              loading={isSubmitBtnLoading}
              disabled={readOnlyMode}
              >Submit</Button>
            {
              submittedLicensingPersonalHistoryArray.length > 0 &&
              <Button 
                type="primary" 
                style={{ marginLeft: 10 }}
                onClick={this.showSubmittedHistoryBtnOnClicked} 
                loading={isSubmitBtnLoading}
                disabled={readOnlyMode}
                >Submitted History</Button>
            }
          </div>
        </div>
      
        <SubmittedHistoryDialog {...this.props} />
      </div>
    );
  }
}

export default LicensingPersonalAnswer;