import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker,message, Select, Button, Checkbox,InputNumber} from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;



const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
let licenseOptionsByName = [];


class AddOrEditCptModalDialog extends Component {
    state =  {
        isopen: false,
    
      }
    refreshBtnOnClicked = () => {
        const {
            startDate,
            endDate,
        } = this.props.cptQueryFields;
        this.props.loadCptInfo({startDate,endDate});
    }

    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditCptStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditCptModal;

        if(addOrEditCptStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSaveCptActionStatus();
            this.props.resetSubmitSaveCptModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditCptStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveCptActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditCptChangeInput({name, value});
    }

    onSelectUserRegisterInfoByName =({name, value}) => {
        // this.onInputChange({ name: 'name', value: val })
        this.props.addOrEditCptChangeInput({name, value});
        this.props.selectUserRegisterInfoByName(value);
        //查询register根据username。
    }
    
    onRegulatorInputChange = ({name, value,list}) => {
        this.props.addOrEditCptChangeInput({name, value});

        licenseOptionsByName = list.filter(m =>m.regulator===value).map(e => {
            const kv = e.license;
            const text = e.license;
            return <Option key={kv} value={kv}>{text}</Option>
        });
        
    }
    onLienseChange = ({name, value,list}) => {
        this.props.addOrEditCptChangeInput({name, value});
        //根据regulator 和 licens 确定唯一一条，registerInfoList。赋值给licensed date、licensing no
        const {

            regulator,
            license,
        } = this.props.addOrEditCptModal.fields;
        let licenseList = list.filter(m =>m.regulator===regulator && m.license ===value);
        if(licenseList.length ==1){
            this.onInputChange({name: 'licenseNo', value: licenseList[0].licenseNo});
            this.onInputChange({name: 'licensedDate', value: licenseList[0].licensedDate});
        }else {
            this.onInputChange({name: 'licenseNo', value: undefined});
            this.onInputChange({name: 'licensedDate', value: undefined});
        }
     

    }

    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'frequency', value: value});
        this.onInputChange({name: 'deadline', value: undefined});
        this.clearDecomposedDeadline();
        this.onInputChange({name: 'notification', value: undefined});
    }
    onDeadlineNAInputChange = (value) => {
        const deadline = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
        this.clearDecomposedDeadline();
        // deadline是NA时，notification必须是NA，其他情况，清空notification重新填写
        this.onInputChange({name: 'notification', value: deadline});
        
    }
    onDeadlineEveryWorkdayInputChange = (value) => {
        const deadline = !!value ? 'EveryWorkday' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineEndOfMonthInputChange = (value) => {
        const deadline = !!value ? 'EndOfMonth' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineMonthInputChange = (value) => {
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineMonth', value: value});
    }
    // 清空分解后的deadline的各参数
    clearDecomposedDeadline = () => {
        this.onInputChange({name: 'deadlineMonth', value: undefined});
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineYear', value: undefined});
    }
    onNotificationNAInputChange = (value) => {
        const notification = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'notification', value: notification});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    submitSaveAndSendCptModal(){
        this.onInputChange({name: 'sendmail', value: 'true'});
        this.submitSaveCptModal();
    }


    submitSaveCptModal = () => {
        const {
            mode,
        } = this.props.addOrEditCptModal;
        const {
            id,
            name,
            regulator,
            license,
            licenseNo,
            applicationStatus,
            licensingStatus, 
            licensedDate, 
            validTo, 
            year,
            cptRequirement,
            examinationStatus, 
            examinationDeadline, 
            deregistrationDate,
            remarks,
            comments,
            sendmail,
            formGuid,

        } = this.props.addOrEditCptModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 执行提交
            this.props.submitSaveCpt({
                id,
                name,
                regulator,
                license,
                licenseNo,
                applicationStatus,
                licensingStatus, 
                licensedDate, 
                validTo, 
                year,
                cptRequirement,
                examinationStatus, 
                examinationDeadline, 
                deregistrationDate,
                remarks,
                comments,
                mode,
                sendmail,
                formGuid,
            });

        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { isopen } = this.state
        const {
 
            addOrEditCptModal,
            addOrEditCptStatus,
            userAccountEmailOptions,
            userAccountOptions,
            departmentOptions,
            officeOptions,
            statusOptions, 
            licensingFormOptions,
            importanceOptions,

            regulatorOptions,
            licenseOptions,
            applicationStatusOptions,
            licensingStatusOptions,
            examinationStatusOptions,
            registerInfoList,

        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditCptModal;

        const {
            id,
            name,
            regulator,
            license,
            licenseNo,
            applicationStatus,
            licensingStatus, 
            licensedDate, 
            validTo, 
            year,
            cptRequirement,
            examinationStatus, 
            examinationDeadline, 
            deregistrationDate,



            department,
            office,
            status,
            lastConfirmDate,
            terminationDate,
            formGuid,
            remarks,
            comments,


        } = addOrEditCptModal.fields;

        // const emailToArray = !emailTo ? [] : emailTo.split(';');


        const monthDateOptions = [];


        const startYearOptions = [];



        const modalTitle = this.getActionFromMode(mode) + ' Personal Info';
        const isSubmitLoading = addOrEditCptStatus === ACTION_STATUS.LOGINING;
        const licensedDateMent = !licensedDate ? undefined : moment(licensedDate);
        const validToMent = !year ? undefined : moment(year);
        const examinationDeadlineMent = !examinationDeadline ? undefined : moment(examinationDeadline);
        const deregistrationDateMent = !deregistrationDate ? undefined : moment(deregistrationDate);
        // const enddatement = !enddate ? undefined : moment(enddate);

        // customerOptions
        let regulatorOptionsByName = [];
        
        if(registerInfoList.length >1 ) {
            regulatorOptionsByName = registerInfoList.map(e => {
                const kv = e.regulator;
                const text = e.regulator;
                return <Option key={kv} value={kv}>{text}</Option>
            });
        }

        // let  licenseOptionsByName = [];
        // if( registerInfoList.length >1) {
        //     licenseOptionsByName = registerInfoList.map(e => {
        //         const kv = e.license;
        //         const text = e.license;
        //         return <Option key={kv} value={kv}>{text}</Option>
        //     });
        // }






        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveCptModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveCptModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveCptModal() }>
                          Save
                        </Button>, 
                        <Button key="Savee" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveAndSendCptModal() }>
                          Send
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                    <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Form Name">
                                    {getFieldDecorator('Form Name', {
                                        initialValue: formGuid,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Form Name',
                                        }],
                                        })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'formGuid', value: val })
                                            }} 
                                            placeholder="Please select Form Name">
                                                {licensingFormOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Name">
                                    {getFieldDecorator('Name', {
                                        initialValue: name,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Name',
                                        }],
                                        })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                // this.onInputChange({ name: 'name', value: val })
                                                this.onSelectUserRegisterInfoByName({ name: 'name', value: val })
                                            }} 
                                            placeholder="Please select Name">
                                                {userAccountOptions}
                                        </Select>

                                    )}
                                </FormItem>
                            </Col>
                            
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Regulator">
                                    {getFieldDecorator('regulator', {
                                        initialValue: regulator,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Regulator',
                                        }],
                                        })(
                                        <Select
                                            id= 'regulator'
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                // this.onInputChange({ name: 'regulator', value: val })
                                                this.onRegulatorInputChange({ name: 'regulator', value: val ,list:registerInfoList })
                                            }} 
                                            placeholder="Please select Regulator">
                                                {regulatorOptionsByName}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                            <FormItem {...formItemLayout} label="License">
                                        {getFieldDecorator('License', {
                                            initialValue: license,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                id= 'license'
                                                allowClear
                                                // mode='multiple'
                                                style={{ width: 300, marginLeft: 10 }}
                                                placeholder='Please select License' 
                                                onChange={(val) => {
                                                    // this.onInputChange({ name: 'license', value: val })
                                                    this.onLienseChange({ name: 'license', value: val ,list:registerInfoList})
                                            }}>
                                                {licenseOptionsByName}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="License no">
                                        {getFieldDecorator('licenseNo', {
                                            initialValue: licenseNo,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <TextArea 
                                                style={{ width: 300, marginLeft: 10 }}
                                                rows={3} 
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'licenseNo', value: e.target.value })
                                            }}/>
                                        )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            {/* <Col span={12}>
                            <FormItem {...formItemLayout} label="Licensing Status">
                                {getFieldDecorator('licensingStatus ', {
                                    initialValue: licensingStatus,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                    <Select 
                                        allowClear
                                        // mode='multiple'
                                        style={{ width: 300, marginLeft: 10 }}
                                        placeholder='Please select Licensing Status' 
                                        onChange={(val) => {
                                            this.onInputChange({ name: 'licensingStatus', value: val })
                                    }}>
                                        {licensingStatusOptions}
                                    </Select>
                                )}
                            </FormItem>
                            </Col> */}
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Licensed date">
                                {getFieldDecorator('licensedDate', {
                                    initialValue: licensedDateMent ,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Last Confirm date ',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'licensedDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>



                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Year">
                                {getFieldDecorator('year', {
                                    initialValue: validToMent,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Valid to',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        mode="year"
                                        format='YYYY'
                                        placeholder="Please select year" 
                                        open={isopen}
                                        onPanelChange={(v)=> {
                                            console.log(v.format("YYYY"))
                                            this.onInputChange({ name: 'year', value: v.format("YYYY") })
                                            this.setState({
                                                
                                                isopen: false
                                              })
                                
                                        }}

                                        onOpenChange={(status) => {
                                            if(status){
                                              this.setState({isopen: true})
                                            } else {
                                              this.setState({isopen: false})
                                            }
                                          }} 
                                        onChange={() => {
                                            // this.setState({time: null})
                                            this.onInputChange({ name: 'year', value: null })
                                        }}
                                    />

                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="CPT requirement">
                                {getFieldDecorator('CPTRequirement', {
                                    initialValue: cptRequirement,
                                    rules: [{
                                        required: false,
                                        message: 'Please select CPT requirement',
                                    }],
                                    })(
                                    <InputNumber
                                        value={cptRequirement}
                                        min={0}
                                        defaultValue={1}
                                        precision={0}
                                        onChange={(val) => {
                                            this.onInputChange({ name: 'cptRequirement', value: val })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>


                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditCptModalDialogForm = Form.create({ name: 'AddOrEditCptModalDialog' })(AddOrEditCptModalDialog);

export default AddOrEditCptModalDialogForm;
