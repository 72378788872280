import React, {Component} from 'react';
import { Breadcrumb, Select, Button, message, Input, Divider, Modal } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './ElementMngDashboard.css';
import ElementMngGrid from './ElementMngGrid';
import AddOrEditElementDialog from './AddOrEditElementDialog';


class ElementMngDashboard extends Component {

    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            queryElementActionStatus,
            queryElementActionResp,
            delElementActionStatus,
            delElementActionResp,
            delPropsActionStatus,
            delPropsActionResp,
        } = this.props;
        if(queryElementActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryElementActionStatus();
        }
        if(queryElementActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = 'Query Element failed';
            if(!!queryElementActionResp && !!queryElementActionResp.respMessage) {
                errMsg = queryElementActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initQueryElementActionStatus();
        }
        if(delElementActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Element succeeded');
            this.props.initDelElementBtnClickedActionStatus();
            this.refreshBtnOnClicked();
        }
        if(delElementActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = 'Delete Element failed';
            if(!!delElementActionResp && !!delElementActionResp.respMessage) {
                errMsg = delElementActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initDelElementBtnClickedActionStatus();
        }
        if(delPropsActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Props succeeded');
            this.props.initDelPropsBtnClickedActionStatus();
            this.refreshBtnOnClicked();
        }
        if(delPropsActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = 'Delete Props failed';
            if(!!delPropsActionResp && !!delPropsActionResp.respMessage) {
                errMsg = delPropsActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initDelPropsBtnClickedActionStatus();
        }
    }


    onElementQueryInputChange = (param) => {
        this.props.onElementQueryInputChange(param);
    }


    refreshBtnOnClicked = () => {
        this.props.queryElement(this.props.elementQueryFields);
    }


    addOrEditElementBtnClicked = (mode) => {
        let selectedElement = null;
        if(mode === 'EDIT') {
            const {selectedElementList} = this.props;
            if(!selectedElementList || selectedElementList.length !== 1) {
                message.warn('Please choose one and only one Element');
                return;
            }
            selectedElement = selectedElementList[0];
        }
        this.props.openAddOrEditElementModal(mode, selectedElement);
    }


    delElementBtnClicked = () => {
        const {selectedElementList} = this.props;
        if(!selectedElementList || selectedElementList.length !== 1) {
            message.warn('Please choose one and only one Element');
            return;
        }
        const selectedElement = selectedElementList[0];
        Modal.confirm({
            title: 'Are you sure to delete this Element?',
            onOk: () => {
                this.props.delElementBtnClicked(selectedElement);
            }
        });
    }


    delPropsBtnClicked = () => {
        const {selectedElementPropsList} = this.props;
        if(!selectedElementPropsList || selectedElementPropsList.length !== 1) {
            message.warn('Please choose one and only one Props');
            return;
        }
        const selectedProps = selectedElementPropsList[0];
        Modal.confirm({
            title: 'Are you sure to delete this Props?',
            onOk: () => {
                this.props.delPropsBtnClicked(selectedProps);
            }
        });
    }



    render() {

        const {
            elementQueryFields,
            queryElementActionStatus,
            queryElementActionResp,
        } = this.props;

        const {
            elementName,
        } = elementQueryFields;

        const refreshBtnIsLoading = queryElementActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div className='elementMngWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Pinpoint Group</Breadcrumb.Item>
                        <Breadcrumb.Item>Element Manage</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='operationBar'>
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="elementName" 
                        value={elementName}
                        onChange={(e) => {
                            this.onElementQueryInputChange({ name: 'elementName', value: e.target.value })
                        }}
                    />
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <span style={{ fontSize: 14 }}>Element: </span>
                    <Button 
                        onClick={() => this.addOrEditElementBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                        icon='plus'
                    />
                    <Button 
                        onClick={() => this.addOrEditElementBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon='edit'
                    />
                    <Button 
                        onClick={() => this.delElementBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                        icon='minus'
                    />

                    <Divider type='vertical' />

                    <span style={{ fontSize: 14 }}>Props: </span>
                    <Button 
                        onClick={() => this.delPropsBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                        icon='minus'
                    />


                </div>
                
                <div className='ElementMngGrid'><ElementMngGrid {...this.props} /></div>
                <AddOrEditElementDialog {...this.props} />

            </div>
        );
    }

}

export default ElementMngDashboard;
