const Initialize = 'Initialize';
const Valid = 'Valid';
const InValid = 'InValid';
const Deleted = 'Deleted';

export default {
  Initialize,
  Valid,
  InValid,
  Deleted,
};
