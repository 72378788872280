import { Row } from 'antd';
import numeral from 'numeral';

const numValueFormatter = function(params) {
  var number = params.value;
  return numeral(number).format('0,0');
}

const numValueFormatter2 = function(params) {
  var number = params.value;
  return numeral(number).format('0,0.00');
}

const numValueCellStyle = function(params) {
  return {color: 'green', fontWeight: 'bold'};
}

const cellStyleOfQty = function(params) {
  return cellColorStyleForQtyType(params.data.qtyType, params.data.qty);
} 

const cellColorStyleForQtyType = function(qtyType, qty) {
  if(qtyType === 'LONG' || qtyType === 'SHORT' || qtyType === 'NET') {
    if(qty > 0) {
      return  {color: 'green', fontWeight: 'bold'};
    } else if (qty < 0) {
      return  {color: 'red', fontWeight: 'bold'};
    } else {
      return  {color: 'black', fontWeight: 'bold'};
    }
  }
  return  {color: 'black', fontWeight: 'bold'};
}


const cellRenderOfQty = function(num) {
  const commaNum = numeral(num).format('0,0.00');
  if(num < 0) {
    return '(' + commaNum.substring(1) + ')';
  }
  return commaNum;
}


const shortStockMetaGridColumns = [
  {
    field: 'id',
    headerName: "Id",
    cellClass: 'non-number',
    width: 160,
    cellRenderer: "agGroupCellRenderer",
  },
  {
    field: 'effectiveDateLabel',
    headerName: "Effective Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'updateDateLabel',
    headerName: "Update Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedDateLabel',
    headerName: 'Last Modified Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const shortStockGridColumns = [
  {
    field: 'stockCode',
    headerName: "Stock Code",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'stockName',
    headerName: "Stock Name",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'exemptTickRule',
    headerName: 'Exempt from Tick Rule',
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    cellClass: 'non-number',
    width: 150,
  },
];

const thirteenFReportMetaGridColumns = [
  {
    field: 'id',
    headerName: "Id",
    cellClass: 'non-number',
    width: 160,
    cellRenderer: "agGroupCellRenderer",
  },
  {
    field: 'runDate',
    headerName: "Run Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'runTime',
    headerName: "Run Time",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'year',
    headerName: 'Year',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'qtr',
    headerName: 'Qtr',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'totalCount',
    headerName: 'Total Count',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedDateLabel',
    headerName: 'Last Modified Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const thirteenFReportGridColumns = [
  {
    field: 'cusipNo',
    headerName: "CUSIP NO",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'issuerName',
    headerName: "ISSUER NAME",
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'issuerDesc',
    headerName: 'ISSUER DESCRIPTION',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'non-number',
    width: 120,
  },
];

const exchangeRateMetaGridColumns = [
  // {
  //   field: 'id',
  //   headerName: "Id",
  //   cellClass: 'non-number',
  //   width: 100,
  // },
  {
    field: 'rateDateLabel',
    headerName: "Date",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'rmbToHKDRate',
    headerName: "RMBToHKD",
    cellClass: 'non-number',
    width: 150,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'usdToHKDRate',
    headerName: 'USDToHKD',
    cellClass: 'non-number',
    width: 150,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'usdToRMBRate',
    headerName: 'USDToRMB',
    cellClass: 'non-number',
    width: 150,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'lastModifiedDateLabel',
    headerName: 'Last Modified Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const shortPosReportChkResultGridColumns = [
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'bbgTicker',
    headerName: "BB_TCM",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'thresholdPercentLabel',
    headerName: 'Threshold %',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'sameCurrencyCodeLabel',
    headerName: 'Same CCY Code',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'sfcOnListLabel',
    headerName: 'SFC On List',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'moreThan30MLabel',
    headerName: 'More Than 30M',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'matchThresholdLabel',
    headerName: '0.02% Threshold',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'position',
    headerName: 'Position',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'security',
    headerName: 'Security',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'positionSecCCY',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 100,
  },
];

const shortPositionReportGridColumns = [
  {
    field: 'reportId',
    headerName: "Short Position Reporting ID",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'fundName',
    headerName: "Name",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'stockCode',
    headerName: "Stock Code",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'stockName',
    headerName: "Stock Name",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'positionShare',
    headerName: "No. Of Shares",
    cellClass: 'non-number',
    width: 150,
    valueFormatter: numValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'positionValue',
    headerName: "Net Short Position Value",
    cellClass: 'non-number',
    width: 200,
    valueFormatter: numValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'reportDateLabel',
    headerName: "Date",
    cellClass: 'non-number',
    width: 150,
  },
];

const fundLevelReportGridColumns = [
  {
    field: 'dataType',
    headerName: "dataType",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'reportDate',
    headerName: "Date",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'fundCode',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'securityId',
    headerName: "SecurityId",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'bbgTicker',
    headerName: "BbgTicker",
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'underlying',
    headerName: "Underlying",
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'market',
    headerName: "Market",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'assetClass',
    headerName: "AssetClass",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'qtyType',
    headerName: "Type",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'qty',
    headerName: "Position",
    cellClass: 'number',
    width: 160,
    cellRenderer: (node) => {
      return cellRenderOfQty(node.data.qty);
    },
    cellStyle: cellStyleOfQty,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: 'totalIssuedShare',
    headerName: "Issued Share",
    cellClass: 'number',
    width: 160,
    valueFormatter: numValueFormatter2,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: 'totalIssuedSharePercentPlain',
    headerName: "% of Issued Share",
    cellClass: 'number',
    width: 160,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: 'localCurrency',
    headerName: "Local Currency",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'fxRateUsdVsLocal',
    headerName: "Rate",
    cellClass: 'number',
    width: 100,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: "marketValueLocal",
    headerName: "Market Value Local",
    cellClass: 'number',
    width: 160,
    cellRenderer: (node) => {
      return cellRenderOfQty(node.data.marketValueLocal);
    },
    cellStyle: cellStyleOfQty,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: "marketValueUSD",
    headerName: "Market Value USD",
    cellClass: 'number',
    width: 160,
    cellRenderer: (node) => {
      return cellRenderOfQty(node.data.marketValueUSD);
    },
    cellStyle: cellStyleOfQty,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: 'fundAumUSD',
    headerName: "Fund NAV USD",
    cellClass: 'number',
    width: 160,
    valueFormatter: numValueFormatter2,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: 'fundAumPercentPlain',
    headerName: "% of Fund NAV",
    cellClass: 'number',
    width: 160,
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
];


const fundPositionGroupInfoGridColumns = [
  {
    field: 'dataType',
    headerName: "dataType",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'fund',
    headerName: "fund",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'tradeDate',
    headerName: "reportDate",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'market',
    headerName: "market",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'assetClass',
    headerName: "assetClass",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'type',
    headerName: "type",
    cellClass: 'non-number',
    width: 80,
  },
  {
    field: 'localCurrency',
    headerName: "localCurrency",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'mvAssetsLocalPlain',
    headerName: "MV of Assets Local",
    cellClass: 'non-number',
    width: 200,
    cellRenderer: (node) => {
      const floatData = parseFloat(node.data.mvAssetsLocalPlain);
      return cellRenderOfQty(floatData);
    },
    cellStyle: (node) => {
      const floatData = parseFloat(node.data.mvAssetsLocalPlain);
      return cellColorStyleForQtyType(node.data.type, floatData);
    },
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },
  {
    field: 'mvAssetsUSDPlain',
    headerName: "MV of Assets USD",
    cellClass: 'non-number',
    width: 200,
    cellRenderer: (node) => {
      const floatData = parseFloat(node.data.mvAssetsUSDPlain);
      return cellRenderOfQty(floatData);
    },
    cellStyle: (node) => {
      const floatData = parseFloat(node.data.mvAssetsUSDPlain);
      return cellColorStyleForQtyType(node.data.type, floatData);
    },
    comparator: (a, b) => {
      const floatA = parseFloat(a);
      const floatB = parseFloat(b);
      return floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
    }
  },

];


const thirteenFLongPosReportGridColumns = [
  {
    field: 'fundCode',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'bbgTicker',
    headerName: "BB_TCM",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'issuerName',
    headerName: "NAME OF ISSUER",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'classTitle',
    headerName: "TITLE OF CLASS",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'cusipNo',
    headerName: "CUSIP",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'position',
    headerName: "(X$1000)",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'amount',
    headerName: "SHRS OR PRN AMT",
    cellClass: 'non-number',
    width: 180,
  },
  {
    field: 'shOrPRN',
    headerName: "SH/PRN",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'putOrCall',
    headerName: "PUT/CALL",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'discretion',
    headerName: "INVESTMENT DISCRETION",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'otherManager',
    headerName: "OTHER MANAGER",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'sole',
    headerName: "SOLE",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'shared',
    headerName: "SHARED",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'none',
    headerName: "NONE",
    cellClass: 'non-number',
    width: 100,
  },
];

const thirteenFLongPosReportChkResultGridColumns = [
  {
    field: 'fundCode',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'bbgTicker',
    headerName: "BB_TCM",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'secOnListLabel',
    headerName: 'Security on list',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'matchPositionLabel',
    headerName: 'Position >= 10,000',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'matchAmountLabel',
    headerName: 'Amt >= 200,000',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'cusipNo',
    headerName: "CUSIP",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'issuerName',
    headerName: "Name of Issuer",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'issuerDesc',
    headerName: "Title of Class",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'position',
    headerName: "(X$1000)",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'amount',
    headerName: "SHRS OR PRN AMT",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'shOrPRN',
    headerName: "SH/PRN",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'putOrCall',
    headerName: "PUT/CALL",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'investmentDiscretion',
    headerName: "INVESTMENT DISCRETION",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'otherManager',
    headerName: "OTHER MANAGER",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'sole',
    headerName: "SOLE",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'shared',
    headerName: "SHARED",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'none',
    headerName: "NONE",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'security',
    headerName: 'Security',
    cellClass: 'non-number',
    width: 250,
  },
];

const GridColumnMap = {
  shortStockMetaGridColumns,
  shortStockGridColumns,

  thirteenFReportMetaGridColumns,
  thirteenFReportGridColumns,

  exchangeRateMetaGridColumns,

  shortPositionReportGridColumns,
  shortPosReportChkResultGridColumns,

  fundLevelReportGridColumns,
  fundPositionGroupInfoGridColumns,

  thirteenFLongPosReportGridColumns,
  thirteenFLongPosReportChkResultGridColumns,
};

export default GridColumnMap;