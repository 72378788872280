import {connect} from 'react-redux';
import RestrictedSecurityDashboard from '../components/RestrictedSecurityRequestDashboard';

import {
  // Load Submitted Restricted Security Request
  queryRestrictedSecurityInputChange,
  loadSubmittedRestrictedSecurityRequest,
  initLoadSubmittedRestrictedSecurityRequestActionStatus,

  // Select Restricted Security Request
  selectRestrictedSecurityRequest,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  openRestrictedSecurityRequestOperateModalDialog,
  resetRestrictedSecurityRequestOperateModalDialog,

  // Approve Restricted Security Request
  approveRestrictedSecurityRequest,
  initApproveRestrictedSecurityRequestActionStatus,

  // Reject Restricted Security Request
  rejectRestrictedSecurityRequest,
  initRejectRestrictedSecurityRequestActionStatus,

  // Feedback Restricted Security Request
  feedbackRestrictedSecurityRequest,
  initFeedbackRestrictedSecurityRequestActionStatus,

  // Open/Reset Restricted Security Operation Record Modal Dialog
  openRestrictedSecurityOperationRecordModalDialog,
  resetRestrictedSecurityOperationRecordModalDialog,

  // Load Restricted Security Operation Record
  loadRestrictedSecurityOperationRecord,
  initLoadRestrictedSecurityOperationRecordActionStatus,

  // Open/Reset Restricted Security Request Edit Modal Dialog
  openRestrictedSecurityRequestEditModalDialog,
  resetRestrictedSecurityRequestEditModalDialog,

  // Restricted Security Request Edit Change Input
  restrictedSecurityRequestEditChangeInput,

  // =================================================================
  // Load Restricted Security Request BBG Tickers
  loadRestrictedSecurityRequestBbgTickers,
  initLoadRestrictedSecurityRequestBbgTickersActionStatus,

  // Clear Restricted Security Request BBG Ticker
  clearRestrictedSecurityRequestBbgTickers,

  // Restricted Security Request BBG Ticker Select Changed
  restrictedSecurityRequestBbgTickerSelectChanged,

  // Restricted Security Request Fund Code Select Changed
  restrictedSecurityRequestFundCodeSelectChanged,

  // Restricted Security Request Book Code Select Changed
  restrictedSecurityRequestBookCodeSelectChanged,

  // Update Restricted Security Request
  updateRestrictedSecurityRequest,
  initUpdateRestrictedSecurityRequestActionStatus,
  addRestrictedSecurityRequest,
  initAddRestrictedSecurityRequestActionStatus,

  showValidRestrictedRules,
  showAllRestrictedRules,

  // Restricted Rule
  updateRestrictedRule,
  addRestrictedRule,
  loadRestrictedRuleList,
  restrictedRuleEditChangeInput,
  openRestrictedRuleDialog,
  closeRestrictedRuleDialog,
  openRestrictedRuleFundBookDialog,
  closeRestrictedRuleFundBookDialog,
} from '../restrictedSecurityRequestActions';


// import {
//   clearEmployeeNameList,
//   fuzzySearchEmployee,
// } from '../../../approvalRequest/personalAccount/personalAccountActions';

import {
  queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions';

import {
  doHistoryUploadAction,
} from '../../../common/historyUpload/HistoryUploadActions';


const mapStateToProps = state => {
  return {
    restrictedSecurityQueryParams: state.restrictedSecurityRequest.restrictedSecurityQueryParams,
    // fuzzyQueryEmployeeNameOptions4Query: state.personalAccount.fuzzyQueryEmployeeNameOptions.restrictedSecurity4Query,

    // Grdi Columns
    restrictedSecurityRequestGridColumns: state.restrictedSecurityRequest.restrictedSecurityRequestGridColumns,
    restrictedSecurityOpRecordGridColumns: state.restrictedSecurityRequest.restrictedSecurityOpRecordGridColumns,
    restrictedSecurityRuleGridColumns: state.restrictedSecurityRequest.restrictedSecurityRuleGridColumns,
    // Restricted Security Request
    restrictedSecurityRequestArray: state.restrictedSecurityRequest.restrictedSecurityRequestArray,
    filterRestrictedSecurityRequestArray: state.restrictedSecurityRequest.filterRestrictedSecurityRequestArray,

    // Selected Restricted Security Request
    selectedRestrictedSecurityRequestArray: state.restrictedSecurityRequest.selectedRestrictedSecurityRequestArray,

    // load Submitted Restricted Security Request Action Status
    loadSubmittedRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.loadSubmittedRestrictedSecurityRequestActionStatus,

    // Restricted Security Request Operate Modal Dialog
    restrictedSecurityRequestOperateControlModal: state.restrictedSecurityRequest.restrictedSecurityRequestOperateControlModal,

    // Approve/Reject Restricted Security Request Action Status
    approveRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.approveRestrictedSecurityRequestActionStatus,
    rejectRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.rejectRestrictedSecurityRequestActionStatus,
    updateRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.updateRestrictedSecurityRequestActionStatus,
    addRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.addRestrictedSecurityRequestActionStatus,

    // Feedback Restricted Security Request Action Status
    feedbackRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.feedbackRestrictedSecurityRequestActionStatus,
    
    // Restricted Security Operation Record Modal Dialog
    restrictedSecurityOperationRecordControlModal: state.restrictedSecurityRequest.restrictedSecurityOperationRecordControlModal,

    // Restricted Security Operation Record
    restrictedSecurityOperationRecordArray: state.restrictedSecurityRequest.restrictedSecurityOperationRecordArray,
    
    // Load Restricted Security Operation Record Action Status
    loadRestrictedSecurityOperationRecordActionStatus: state.restrictedSecurityRequest.loadRestrictedSecurityOperationRecordActionStatus,

    // Restricted Security Request Edit Modal
    restrictedSecurityRequestEditControlModal: state.restrictedSecurityRequest.restrictedSecurityRequestEditControlModal,

    // Security Ticker Data
    bbgTickerArray: state.restrictedSecurityRequest.bbgTickerArray,
    bbgTickerOptions: state.restrictedSecurityRequest.bbgTickerOptions,
    securityTickerArray: state.restrictedSecurityRequest.securityTickerArray,
    
    // Fund Book Data
    fundBookData: state.restrictedSecurityRequest.fundBookData,
    fundCodeArray: state.restrictedSecurityRequest.fundCodeArray,
    fundCodeOptions: state.restrictedSecurityRequest.fundCodeOptions,
    bookCodeArray: state.restrictedSecurityRequest.bookCodeArray,
    bookCodeOptions: state.restrictedSecurityRequest.bookCodeOptions,
    fundBookArray: state.restrictedSecurityRequest.fundBookArray,

    // Load User Account
    userAccountOptions: state.restrictedSecurityRequest.userAccountOptions,

    // 附件操作URL
    restrictedSecAttachmentUploadUrl: state.restrictedSecurityRequest.restrictedSecAttachmentUploadUrl,
    restrictedSecAttachmentPreviewUrl: state.restrictedSecurityRequest.restrictedSecAttachmentPreviewUrl,
    restrictedSecAttachmentDownloadUrl: state.restrictedSecurityRequest.restrictedSecAttachmentDownloadUrl,

    // historyUpload:
    historyUpload: state.historyUpload,

    //restrictedRule
    restrictedRuleList:  state.restrictedSecurityRequest.restrictedRuleList,
    selectedRestrictedRule: state.restrictedSecurityRequest.selectedRestrictedRule,
    restrictedRuleEditControlModal: state.restrictedSecurityRequest.restrictedRuleEditControlModal,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Submitted Restricted Security Request
    queryRestrictedSecurityInputChange: (params) => dispatch(queryRestrictedSecurityInputChange(params)),
    // clearEmployeeNameList: (module) => dispatch(clearEmployeeNameList(module)),
    // fuzzySearchEmployee: (module, namePart) => dispatch(fuzzySearchEmployee(module, namePart)),
    loadSubmittedRestrictedSecurityRequest: (params) => dispatch(loadSubmittedRestrictedSecurityRequest(params)),
    initLoadSubmittedRestrictedSecurityRequestActionStatus: () => dispatch(initLoadSubmittedRestrictedSecurityRequestActionStatus()),

    // Select Restricted Security Request
    selectRestrictedSecurityRequest: (payload) => dispatch(selectRestrictedSecurityRequest(payload)),

    // Open/Reset Restricted Security Request Operate Modal Dialog
    openRestrictedSecurityRequestOperateModalDialog: (operateMode, restrictedSecReqGuidList, requestStatus) => 
      dispatch(openRestrictedSecurityRequestOperateModalDialog(operateMode, restrictedSecReqGuidList, requestStatus)),
    resetRestrictedSecurityRequestOperateModalDialog: () => dispatch(resetRestrictedSecurityRequestOperateModalDialog()),

    // Approve Restricted Security Request
    approveRestrictedSecurityRequest: (comments, restrictedSecReqGuidList) => dispatch(approveRestrictedSecurityRequest(comments, restrictedSecReqGuidList)),
    initApproveRestrictedSecurityRequestActionStatus: () => dispatch(initApproveRestrictedSecurityRequestActionStatus()),

    // Reject Restricted Security Request
    rejectRestrictedSecurityRequest: (comments, restrictedSecReqGuidList) => dispatch(rejectRestrictedSecurityRequest(comments, restrictedSecReqGuidList)),
    initRejectRestrictedSecurityRequestActionStatus: () => dispatch(initRejectRestrictedSecurityRequestActionStatus()),

    // Feedback Restricted Security Request
    feedbackRestrictedSecurityRequest: (feedback, restrictedSecReqGuidList, comments) => dispatch(feedbackRestrictedSecurityRequest(feedback, restrictedSecReqGuidList, comments)),
    initFeedbackRestrictedSecurityRequestActionStatus: () => dispatch(initFeedbackRestrictedSecurityRequestActionStatus()),

    // Open/Reset Restricted Security Operation Record Modal Dialog
    openRestrictedSecurityOperationRecordModalDialog: () => dispatch(openRestrictedSecurityOperationRecordModalDialog()),
    resetRestrictedSecurityOperationRecordModalDialog: () => dispatch(resetRestrictedSecurityOperationRecordModalDialog()),

    // Load Restricted Security Operation Record
    loadRestrictedSecurityOperationRecord: () => dispatch(loadRestrictedSecurityOperationRecord()),
    initLoadRestrictedSecurityOperationRecordActionStatus: () => dispatch(initLoadRestrictedSecurityOperationRecordActionStatus()),

    // Open/Reset Restricted Security Request Edit Modal Dialog
    openRestrictedSecurityRequestEditModalDialog: (isAddMode, restrictedSecurityRequest) => dispatch(openRestrictedSecurityRequestEditModalDialog(isAddMode, restrictedSecurityRequest)),
    resetRestrictedSecurityRequestEditModalDialog: () => dispatch(resetRestrictedSecurityRequestEditModalDialog()),

    // Restricted Security Request Edit Change Input
    restrictedSecurityRequestEditChangeInput: (input) => dispatch(restrictedSecurityRequestEditChangeInput(input)),

    // =================================================================
    // Load Restricted Security Request BBG Tickers
    loadRestrictedSecurityRequestBbgTickers: (queryText) => dispatch(loadRestrictedSecurityRequestBbgTickers(queryText)),
    initLoadRestrictedSecurityRequestBbgTickersActionStatus: () => dispatch(initLoadRestrictedSecurityRequestBbgTickersActionStatus()),

    // Clear Restricted Security Request BBG Ticker
    clearRestrictedSecurityRequestBbgTickers: () => dispatch(clearRestrictedSecurityRequestBbgTickers()),

    // Restricted Security Request BBG Ticker Select Changed
    restrictedSecurityRequestBbgTickerSelectChanged: (bbgTicker) => dispatch(restrictedSecurityRequestBbgTickerSelectChanged(bbgTicker)),

    // Restricted Security Request Fund Code Select Changed
    restrictedSecurityRequestFundCodeSelectChanged: (fundCode) => dispatch(restrictedSecurityRequestFundCodeSelectChanged(fundCode)),

    // Restricted Security Request Book Code Select Changed
    restrictedSecurityRequestBookCodeSelectChanged: (bookCode) => dispatch(restrictedSecurityRequestBookCodeSelectChanged(bookCode)),

    // Update Restricted Security Request
    updateRestrictedSecurityRequest: (restrictedSecReq) => dispatch(updateRestrictedSecurityRequest(restrictedSecReq)),
    initUpdateRestrictedSecurityRequestActionStatus: () => dispatch(initUpdateRestrictedSecurityRequestActionStatus()),
    addRestrictedSecurityRequest: (restrictedSecReq) => dispatch(addRestrictedSecurityRequest(restrictedSecReq)),
    initAddRestrictedSecurityRequestActionStatus: () => dispatch(initAddRestrictedSecurityRequestActionStatus()),


    showValidRestrictedRules: () => dispatch(showValidRestrictedRules()),
    showAllRestrictedRules: () => dispatch(showAllRestrictedRules()),
    queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),

    // historyUpload
    doHistoryUploadAction: (params) => dispatch(doHistoryUploadAction(params)),

    //restrictedRule
    updateRestrictedRule: (params, queryParams) => dispatch(updateRestrictedRule(params, queryParams)),
    addRestrictedRule: (params, queryParams) => dispatch(addRestrictedRule(params, queryParams)),
    loadRestrictedRuleList: (params) => dispatch(loadRestrictedRuleList(params)),
    restrictedRuleEditChangeInput: (params) => dispatch(restrictedRuleEditChangeInput(params)),
    openRestrictedRuleDialog: (mode, detail) => dispatch(openRestrictedRuleDialog(mode, detail)),
    closeRestrictedRuleDialog: (params) => dispatch(closeRestrictedRuleDialog(params)),
    openRestrictedRuleFundBookDialog: () => dispatch(openRestrictedRuleFundBookDialog()),
    closeRestrictedRuleFundBookDialog: () => dispatch(closeRestrictedRuleFundBookDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedSecurityDashboard);