import {connect} from 'react-redux';
import LicensingPersonalAnswer from '../components/LicensingPersonalAnswer';

import {
  loadTodoQuestionnaire,
  initLoadTodoQuestionnaireActionStatus,

  loadTodoLicensingPersonal,
  initLoadTodoLicensingPersonalActionStatus,




  // Submit Questionnaire Answer
  submitQuestionnaireAnswer,
  initSubmitQuestionnaireAnswerActionStatus,

  submitLicensingPersonalAnswer,
  initSubmitLicensingPersonalAnswerActionStatus,



  // Save Questionnaire Answer
  saveQuestionnaireAnswer,
  initSaveQuestionnaireAnswerActionStatus,



  saveLicensingPersonalAnswer,
  initSaveLicensingPersonalAnswerActionStatus,

  
  // Questionnaire Answer Change Input
  questionnaireAnswerChangeInput,
  licensingPersonalAnswerChangeInput,

  // Questionnaire Answer Submitter Edit Change Input
  questionnaireAnswerSubmitterEditChangeInput,
  licensingPersonalAnswerSubmitterEditChangeInput,

  // Open/Reset Submitted Questionnaire History Modal Dialog
  openSubmittedQuestionnaireHistoryModalDialog,
  resetSubmittedQuestionnaireHistoryModalDialog,


  openSubmittedLicensingPersonalHistoryModalDialog,
  resetSubmittedLicensingPersonalHistoryModalDialog,


  // Load Submitted Questionnaire History
  loadSubmittedQuestionnaireHistory,
  initLoadSubmittedQuestionnaireHistoryActionStatus,

  loadSubmittedLicensingPersonalHistory,
  initLoadSubmittedLicensingPersonalHistoryActionStatus,

  // Select Submitted Questionnaire History
  selectSubmittedQuestionnaireHistory,
  selectSubmittedLicensingPersonalHistory,
  // Apply Submitted Questionnaire History
  applySubmittedQuestionnaireHistory,
  applySubmittedLicensingPersonalHistory,
} from '../questionnaireActions';

const mapStateToProps = state => {
  return {
    // Grid Columns
    submittedHistoryGridColumns: state.questionnaire.submittedHistoryGridColumns,

    // Load To-do Questionnaire
    loadTodoQuestionnaireActionStatus: state.questionnaire.loadTodoQuestionnaireActionStatus,
    loadTodoQuestionnaireActionResp: state.questionnaire.loadTodoQuestionnaireActionResp,
    todoQuestionnaire: state.questionnaire.todoQuestionnaire,
    questionnaireTitle: state.questionnaire.questionnaireTitle,
    todoQuestionArray: state.questionnaire.todoQuestionArray,
    todoQuestionnaireAuthToken: state.questionnaire.todoQuestionnaireAuthToken,

    todoLicensingPersonalAuthToken:state.questionnaire.todoLicensingPersonalAuthToken,
    todoLicensingPersonal: state.questionnaire.todoLicensingPersonal,






    questionnaireAnsweree: state.questionnaire.questionnaireAnsweree,
    questionnaireAnswerDate: state.questionnaire.questionnaireAnswerDate,
    questionnaireSubmitter: state.questionnaire.questionnaireSubmitter,
    questionnaireStatus: state.questionnaire.questionnaireStatus,
    questionnaireStatusLabel: state.questionnaire.questionnaireStatusLabel,

    // Submit Questionnaire Answer
    submitQuestionnaireAnswerActionStatus: state.questionnaire.submitQuestionnaireAnswerActionStatus,
    submitQuestionnaireAnswerErrMsg: state.questionnaire.submitQuestionnaireAnswerErrMsg,

    submitLicensingPersonalAnswerActionStatus: state.questionnaire.submitLicensingPersonalAnswerActionStatus,
    submitLicensingPersonalAnswerErrMsg: state.questionnaire.submitLicensingPersonalAnswerErrMsg,


    // Save Questionnaire Answer
    saveQuestionnaireAnswerActionStatus: state.questionnaire.saveQuestionnaireAnswerActionStatus,
    saveQuestionnaireAnswerErrMsg: state.questionnaire.saveQuestionnaireAnswerErrMsg,

    saveLicensingPersonalAnswerActionStatus: state.questionnaire.saveLicensingPersonalAnswerActionStatus,
    saveLicensingPersonalAnswerErrMsg: state.questionnaire.saveLicensingPersonalAnswerErrMsg,

    // Submitted Questionnaire History Modal Dialog
    submittedQuestionnaireHistoryControlModal: state.questionnaire.submittedQuestionnaireHistoryControlModal,
    
    // Load Submitted Questionnaire History
    loadSubmittedQuestionnaireHistoryActionStatus: state.questionnaire.loadSubmittedQuestionnaireHistoryActionStatus,
    submittedQuestionnaireHistoryArray: state.questionnaire.submittedQuestionnaireHistoryArray,


    loadSubmittedLicensingPersonalHistoryActionStatus: state.questionnaire.loadSubmittedLicensingPersonalHistoryActionStatus,
    submittedLicensingPersonalHistoryArray: state.questionnaire.submittedLicensingPersonalHistoryArray,

    // Select Submitted Questionnaire History
    selectSubmittedQuestionnaireHistoryArray: state.questionnaire.selectSubmittedQuestionnaireHistoryArray,

    questionnaireAttachmentUploadUrl: state.questionnaire.questionnaireAttachmentUploadUrl
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    loadTodoQuestionnaire: (authToken) => dispatch(loadTodoQuestionnaire(authToken)),
    initLoadTodoQuestionnaireActionStatus: () => dispatch(initLoadTodoQuestionnaireActionStatus()),
    // LicensingPersonal
    loadTodoLicensingPersonal: (authToken) => dispatch(loadTodoLicensingPersonal(authToken)),
    initLoadTodoLicensingPersonalActionStatus: () => dispatch(initLoadTodoLicensingPersonalActionStatus()),

    // Submit Questionnaire Answer
    submitQuestionnaireAnswer: (authToken, questionnaireAnswerList, submitter, submittedDate) =>
      dispatch(submitQuestionnaireAnswer(authToken, questionnaireAnswerList, submitter, submittedDate)),

    initSubmitQuestionnaireAnswerActionStatus: () => dispatch(initSubmitQuestionnaireAnswerActionStatus()),


    //LicensingPersonal
    submitLicensingPersonalAnswer: (authToken, questionnaireAnswerList, submitter, submittedDate) =>
      dispatch(submitLicensingPersonalAnswer(authToken, questionnaireAnswerList, submitter, submittedDate)),
    initSubmitLicensingPersonalAnswerActionStatus: () => dispatch(initSubmitLicensingPersonalAnswerActionStatus()),
    


    // Save Questionnaire Answer
    saveQuestionnaireAnswer: (authToken, questionnaireAnswerList, submitter, submittedDate) => 
      dispatch(saveQuestionnaireAnswer(authToken, questionnaireAnswerList, submitter, submittedDate)),
    
    initSaveQuestionnaireAnswerActionStatus: () => dispatch(initSaveQuestionnaireAnswerActionStatus()),

    //LicensingPersonal
    saveLicensingPersonalAnswer: (authToken, questionnaireAnswerList, submitter, submittedDate) => 
    dispatch(saveLicensingPersonalAnswer(authToken, questionnaireAnswerList, submitter, submittedDate)),
  
    initSaveLicensingPersonalAnswerActionStatus: () => dispatch(initSaveLicensingPersonalAnswerActionStatus()),

    // Questionnaire Answer Change Input
    questionnaireAnswerChangeInput: (input) => dispatch(questionnaireAnswerChangeInput(input)),
    //LicensingPersonal
    licensingPersonalAnswerChangeInput: (input) => dispatch(licensingPersonalAnswerChangeInput(input)),
    
    // Questionnaire Answer Submitter Edit Change Input
    questionnaireAnswerSubmitterEditChangeInput: (value) => dispatch(questionnaireAnswerSubmitterEditChangeInput(value)),

    //LicensingPersonal
    licensingPersonalAnswerSubmitterEditChangeInput: (value) => dispatch(licensingPersonalAnswerSubmitterEditChangeInput(value)),


    // Open/Reset Submitted Questionnaire History Modal Dialog
    openSubmittedQuestionnaireHistoryModalDialog: (authToken) => dispatch(openSubmittedQuestionnaireHistoryModalDialog(authToken)),
    resetSubmittedQuestionnaireHistoryModalDialog: () => dispatch(resetSubmittedQuestionnaireHistoryModalDialog()),

    //LicensingPersonal
    openSubmittedLicensingPersonalHistoryModalDialog: (authToken) => dispatch(openSubmittedLicensingPersonalHistoryModalDialog(authToken)),
    resetSubmittedLicensingPersonalHistoryModalDialog: () => dispatch(resetSubmittedLicensingPersonalHistoryModalDialog()),


    // Load Submitted Questionnaire History
    loadSubmittedQuestionnaireHistory: (authToken) => dispatch(loadSubmittedQuestionnaireHistory(authToken)),
    initLoadSubmittedQuestionnaireHistoryActionStatus: () => dispatch(initLoadSubmittedQuestionnaireHistoryActionStatus()),

    //LicensingPersonal
    loadSubmittedLicensingPersonalHistory: (authToken) => dispatch(loadSubmittedLicensingPersonalHistory(authToken)),
    initLoadSubmittedLicensingPersonalHistoryActionStatus: () => dispatch(initLoadSubmittedLicensingPersonalHistoryActionStatus()),



    // Select Submitted Questionnaire History
    selectSubmittedQuestionnaireHistory: (payload) => dispatch(selectSubmittedQuestionnaireHistory(payload)),
    //LicensingPersonal
    selectSubmittedLicensingPersonalHistory: (payload) => dispatch(selectSubmittedLicensingPersonalHistory(payload)),
    // Apply Submitted Questionnaire History
    applySubmittedQuestionnaireHistory: (payload) => dispatch(applySubmittedQuestionnaireHistory(payload)),
    //LicensingPersonal
    applySubmittedLicensingPersonalHistory: (payload) => dispatch(applySubmittedLicensingPersonalHistory(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicensingPersonalAnswer);