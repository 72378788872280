import {
  // Load SFC Short Interest
  START_LOAD_SFC_SHORT_INTEREST,
  LOAD_SFC_SHORT_INTEREST_SUCCESS,
  LOAD_SFC_SHORT_INTEREST_FAILURE,
  INIT_LOAD_SFC_SHORT_INTEREST_ACTION_STATUS,
} from './sfcShortConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load SFC Short Interest
function startLoadSFCShortInterest() {
  return {
    type: START_LOAD_SFC_SHORT_INTEREST
  };
}

export function loadSFCShortInterest(dateString) {
  const requestParam = {
    'date': dateString,
  };

  return function(dispatch) {
    dispatch(startLoadSFCShortInterest());
    httpClient
      .get(UrlConfig.QUERY_SFC_SHORT_INTEREST, requestParam)
      .then(resp => dispatch(loadSFCShortInterestSuccess(resp)))
      .catch(err => dispatch(loadSFCShortInterestFailure(err)));
  };
}

function loadSFCShortInterestSuccess(resp) {
  return {
    type: LOAD_SFC_SHORT_INTEREST_SUCCESS,
    payload: resp
  };
}

function loadSFCShortInterestFailure(err) {
  return {
    type: LOAD_SFC_SHORT_INTEREST_FAILURE,
    payload: err
  };
}

export function initLoadSFCShortInterestActionStatus() {
  return {
    type: INIT_LOAD_SFC_SHORT_INTEREST_ACTION_STATUS
  };
}