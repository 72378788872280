// Load User Account
export const LOAD_USER_ACCOUNTS_SUCCESS = 'LOAD_USER_ACCOUNTS_SUCCESS';
export const LOAD_USER_ACCOUNTS_FAILURE = 'LOAD_USER_ACCOUNTS_FAILURE';

// Load Security Tickers
export const LOAD_SECURITY_TICKERS_SUCCESS = 'LOAD_SECURITY_TICKERS_SUCCESS';
export const LOAD_SECURITY_TICKERS_FAILURE = 'LOAD_SECURITY_TICKERS_FAILURE';

// Load Fund Book Data
export const LOAD_FUND_BOOK_DATA_SUCCESS = 'LOAD_FUND_BOOK_DATA_SUCCESS';
export const LOAD_FUND_BOOK_DATA_FAILURE = 'LOAD_FUND_BOOK_DATA_FAILURE';

// Load All BBG Tickers
export const LOAD_ALL_BBG_TICKERS_SUCCESS = 'LOAD_ALL_BBG_TICKERS_SUCCESS';
export const LOAD_ALL_BBG_TICKERS_FAILURE = 'LOAD_ALL_BBG_TICKERS_FAILURE';

// Load Asset Inst Class
export const LOAD_ASSET_INST_CLASS_SUCCESS = 'LOAD_ASSET_INST_CLASS_SUCCESS';
export const LOAD_ASSET_INST_CLASS_FAILURE = 'LOAD_ASSET_INST_CLASS_FAILURE';

// Load Country Codes
export const LOAD_COUNTRY_CODES_SUCCESS = 'LOAD_COUNTRY_CODES_SUCCESS';
export const LOAD_COUNTRY_CODES_FAILURE = 'LOAD_COUNTRY_CODES_FAILURE';

// Load Currency Codes
export const LOAD_CURRENCY_CODES_SUCCESS = 'LOAD_CURRENCY_CODES_SUCCESS';
export const LOAD_CURRENCY_CODES_FAILURE = 'LOAD_CURRENCY_CODES_FAILURE';

// Load Sector Codes
export const LOAD_SECTOR_CODES_SUCCESS = 'LOAD_SECTOR_CODES_SUCCESS';
export const LOAD_SECTOR_CODES_FAILURE = 'LOAD_SECTOR_CODES_FAILURE';

// get hierarchy menus
export const GET_HIERARCHY_MENUS_SUCCESS = 'GET_HIERARCHY_MENUS_SUCCESS';
export const GET_HIERARCHY_MENUS_FAILURE = 'GET_HIERARCHY_MENUS_FAILURE';
