import React, { Component } from 'react';

import { Form, Row, Col, Modal, Select, Input} from 'antd';
import { Message } from 'semantic-ui-react'

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
class FundBookAddDialog extends Component {

    constructor(props){
      super();
      this.state = {
        errorMsg: null
      };
    }

    onInputChange = ({name, value}) => {
      const {
        fundBookFields
    } = this.props.restrictedRuleEditControlModal;
    const updatedFields = {
        ...fundBookFields,
        usertype: value
    }
        // this.props.restrictedRuleEditChangeInput({name, value});
        this.props.restrictedRuleEditChangeInput({ name: 'fundBookFields', value: updatedFields });
        this.setState({
          errorMsg: null
        });
    }

    fundCodeOnChange = (value) => {
        const {
            fundBookFields
        } = this.props.restrictedRuleEditControlModal;
        const updatedFields = {
            ...fundBookFields,
            employee: value
        }
        this.props.restrictedRuleEditChangeInput({ name: 'fundBookFields', value: updatedFields });
        this.setState({
          errorMsg: null
        });
    }
    
    bookCodeOnChange = (value) => {
        const {
            fundBookFields
        } = this.props.restrictedRuleEditControlModal;
        const updatedFields = {
            ...fundBookFields,
            book: value
        }
        this.props.restrictedRuleEditChangeInput({ name: 'fundBookFields', value: updatedFields });
        this.setState({
          errorMsg: null
        });
      }

    submitButtonOnClicked = () => {
      const {
        fundBookFields,
        fields
      } = this.props.restrictedRuleEditControlModal;

      let fundBookList = fields.fundBookList;
      //check exists
      if(fundBookList){
        const result = fundBookList.filter(r => 
          r.employee === fundBookFields.employee && r.usertype === fundBookFields.usertype);
          if(result && result.length>0){
            this.setState({
              errorMsg: 'Current config has exists'
            });
            return;
          }
        }
        fundBookList = fundBookList?fundBookList:[];
        fundBookList.push(fundBookFields);
        const updatedFields ={
          ...fields,
          fundBookList
        }
        this.props.restrictedRuleEditChangeInput({ name: 'fields', value: updatedFields });
        this.props.closeRestrictedRuleFundBookDialog();
      }

    render(){
        const {
            isFundBookOpened,
            fundBookFields,
          } = this.props.restrictedRuleEditControlModal;
          const {
            fundCodeOptions,
            userAccountOptions,
            bookCodeOptions,
          } = this.props;

        return(
        <div>
        <Modal
          title={"Fund Book Add"}
          centered
          width={1000}
          visible={isFundBookOpened}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.closeRestrictedRuleFundBookDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Employee">
                      <Select 
                        allowClear
                        showSearch
                        value={fundBookFields.employee}
                        onChange={this.fundCodeOnChange} 
                        placeholder="Please Choose"
                        >
                         {userAccountOptions}
                      </Select>

{/* 
                      <Select
                        allowClear
                        showSearch
                        style={{ width: 200, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'employee', value: val })
                        }} 
                        placeholder="Please select Employee">
                          {userAccountOptions}
                      </Select> */}

                </FormItem>
              </Col>
              <Col span={12}>
              <FormItem {...formItemLayout} label="User Type">
                      {/* <Select 
                        allowClear
                        showSearch
                        value={fundBookFields.book}
                        onChange={this.bookCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {bookCodeOptions}
                      </Select> */}
                      <Input
                                        // style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'usertype', value: e.target.value })
                                        }}
                                        // onChange={this.bookCodeOnChange} 
                                    />
                </FormItem>
              </Col>
            </Row>
            {
            this.state.errorMsg &&  
             <Message negative>
                <Message.Header>Response Error Message</Message.Header>
              <p>{ this.state.errorMsg }</p>
            </Message>
          }
        </Form>    
        </Modal>

        </div>)
    }
}

export default FundBookAddDialog;