import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Breadcrumb,Button, message,Input,Select,DatePicker} from 'antd';
import AddOrEditRestrictedRuleDialog from './AddOrEditRestrictedRuleDialog'
import { Message } from 'semantic-ui-react'
import OperationRecordDetail from '../OperationRecordDetail';
import MonthlyReviewDetailPanel from './MonthlyReviewDetailPanel'
class RestrictedSecurityPinPointGrid extends Component {

  constructor(props) {
    super(props);
    const that = this;
    this.state = {
      // columnDefs: props.restrictedSecurityRuleGridColumns,
      columnDefs: [
        {
          field: 'id',
          headerName: "Id",
          cellClass: 'non-number',
          width: 80,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          pinned: 'left',
          cellRenderer: 'agGroupCellRenderer',




        },
        {
          field: 'subject',
          headerName: "Subject*",
          cellClass: 'non-number',
          width: 300,
          // cellRenderer: 'agGroupCellRenderer',
      },
        {
          field: 'broker',
          headerName: "Broker*",
          cellClass: 'non-number',
          width: 300,
         
      },
      {
          field: 'start',
          headerName: "Start Time",
          cellClass: 'non-number',
          width: 150,
      },
      {
          field: 'end',
          headerName: "End Time",
          cellClass: 'non-number',
          width: 150,
      },
      {
          field: 'bodypreview',
          headerName: "Event info",
          cellClass: 'non-number',
          width: 400,
      },
            
      ],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "single",
      rowClassRules: {
        'MarkBackgroundRede': function(params) {
            // if(params.data.status === 'New') {
                return params.data.required === 'M' && params.data.progress.split('/')[0]==='0';
            // } alertFlag  limitFlag  1 表示出警

        },



        
        
      },
      context: { componentParent: this },
      frameworkComponents: {
      
        myDetailCellRenderer: MonthlyReviewDetailPanel,
      },
      detailCellRenderer: 'myDetailCellRenderer',
      selectedRow: null
    };
  }
//   onRowClicked = (event) => {
//     const selectedRcd = event.data;
//     this.props.doOperationRecordAction({action:'SHOW_DETAIL', detail: selectedRcd});
// }
  sendHistoryAttachementCellRender = (node) => {
    const attachement = node.data.filepath;
    if(!attachement) {
        return '';
    }
    const attArray = attachement.split(';');
    const retElement = document.createElement('div');
    let first = true;
    attArray.forEach(e => {
        if(!first) {
            retElement.append(' | ');
        }
        first = false;

        const url = this.props.meetingAndTrainingCalenderDownloadUrl + '/' + e;
        const link = document.createElement('a');
        link.innerText = e;
        link.target = '_blank';
        link.href = url;
        retElement.appendChild(link);

    });
    return retElement;
}
  componentDidMount(){
    const {
      title,
      participant,
      state
      
      
  } = this.props.meetingAndTrainingCalenderQueryFields;
  const person = participant;
  this.props.loadRestrictedPinPointList({title,person,state})

  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  refreshData = () => {
    const {
      subject,
      broker,
      start,
      end,
      

  } = this.props.meetingAndTrainingCalenderQueryFields;

  this.props.loadRestrictedPinPointList({subject,broker,start,end})

  }

  addBtnClick = () => {
    this.props.openRestrictedRuleDialog('NEW', {
      isFundBookMatched: 0
    });
  }

  updateBtnClick = () => {
    const {
      selectedRow
    } = this.state;
    if(selectedRow){
      this.props.openRestrictedRuleDialog('EDIT', selectedRow);
    }
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(rowNode => rowNode.data);

    this.setState({
      selectedRow: restrictedSecurityRequestArray[0]
    });
  }
  getRScore =(array) =>{
    if(array.length>0){
    let rArray = array.filter(r=>r.required==='M').reduce(function(prev, cur) {
      return parseInt(cur.score) + prev;
      }, 0);
      return rArray;
    }else{
      return 0;
    }
  
  };

  getOScore =(array) =>{
    if(array.length>0){
      let rArray = array.filter(r=>r.required==='O').reduce(function(prev, cur) {
        return parseInt(cur.score) + prev;
        }, 0);
        return rArray;
      }else{
        return 0;
      }
};
  onInputChange = ({ name, value }) => {
    this.props.onQueryInputChange({ name, value });
};

  render() {
    const {
      restrictedPinPointList,
      typeOptions,
      brokerOptions,
      userAccountOptions,
      className = 'grid-wrapper'
    } = this.props;
    const {

      participant,

    } = this.props.meetingAndTrainingCalenderQueryFields;
    const name = participant;
    
    const isShow = name !== null && name !== '';
    
    // let filterRestrictedSecArray =restrictedPinPointList.filter(rel=>rel.participant.includes(name));
    // const detailShow = filterRestrictedSecArray.length > 0 ? true :false; 
    // const rScore = this.getRScore(filterRestrictedSecArray);
    // const oScore = this.getOScore(filterRestrictedSecArray);
    //style={{height: '100px'}}
    return (
      <div className='MeetingAndTrainingCalenderGrid'>
        <div className='operationBar'>
          <div className='breadcrumbWrapper'>   
            <Breadcrumb>
            <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <Input 
              allowClear={true}
              placeholder="Participant Name"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'participant', value: event.target.value })
              }} 
          /> */}
          {/* <Select
                allowClear
                showSearch
                style={{ width: 200, marginLeft: 10 }}
                onChange={(val) => {
                    this.onInputChange({ name: 'participant', value: val })
                }} 
                placeholder="Select participant">
            {userAccountOptions}
        </Select> */}
        <Input 
              allowClear={true}
              placeholder="Subject"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'subject', value: event.target.value })
              }} 
          />
          <Select
              allowClear
              showSearch
              size='small'
              style={{ width: 150, marginLeft: 10 }}
              onChange={(val) => {
                  this.onInputChange({ name: 'broker', value: val })
              }} 
              placeholder="broker">
              {brokerOptions}
          </Select>

          <DatePicker 
            allowClear={true}
            size='small'
            style={{ width: 150, marginLeft: 10 }}
            placeholder=" Date Start"
            onChange={(startTime, dateString) => {
              this.onInputChange({ name: 'start', value: dateString });
            }}
          />
          <DatePicker 
            allowClear={true}
            size='small'
            style={{ width: 150, marginLeft: 10 }}
            placeholder="Date End"
            onChange={(entTime, dateString) => {
              this.onInputChange({ name: 'ent', value: dateString });
            }}
          /> 
         
          <Button 
              onClick={this.refreshData} 
              // loading={refreshBtnIsLoading}
              size='small'
              style={{ marginLeft: 10 }}
              type='primary'
              icon="search" 
          />
          {/* <Button 
            onClick={this.refreshData} 
            size='small'
            className='positive-button'>
            
          </Button> */}
        </div>
{/* 
        <div className='operationBar' >
          {
            detailShow && 
            <Message negative>
              <Message.Header>{name} Mandatory Score- {rScore}, Optional Score- {oScore}.</Message.Header>
            </Message>
          }
            
        </div> */}

        <div className={`ag-theme-balham ${className}`} style={{height: '85%'}}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={restrictedPinPointList}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}

            //
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            enableRangeSelection={true}
            statusBar={this.state.statusBar}
            //
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            // detailCellRendererParams={this.state.detailCellRendererParams}
            detailCellRenderer={this.state.detailCellRenderer}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}


            // rowData={monthlyReportList}
            // defaultColDef={this.state.defaultColDef}
            // rowSelection={this.state.rowSelection}
            // suppressRowClickSelection={true}
            // suppressAggFuncInHeader={true}
            // enableSorting={true}
            // enableFilter={true}
            // animateRows={true}
            // deltaRowDataMode={false}
            // enableColResize={true}
            // enableRangeSelection={true}
            // getRowNodeId={this.getRowNodeId}
            // context={this.state.context}
            // statusBar={this.state.statusBar}
            // onGridReady={this.onGridReady}
            // frameworkComponents={this.state.frameworkComponents}
            
            onSelectionChanged={this.onRowClickedHandle}
            masterDetail={true}
          />
        </div> 
        <OperationRecordDetail {...this.props} /> 
      </div>
    );
  }
}

export default RestrictedSecurityPinPointGrid;