import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Tabs, message } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import CustodianGrid from './CustodianGrid';
import CustodianAccountGrid from './CustodianAccountGrid';
import '../../EntityAccount.css';

const { TabPane } = Tabs;

class CustodianDashboard extends Component {

  componentDidMount() {
    this.props.loadCustodian();
    this.props.loadCustodianAccount();
  }
  
  render() {
    return (
      <div className="custodianWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Approval Request</Breadcrumb.Item>
            <Breadcrumb.Item>Custodian</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Tabs defaultActiveKey="1" size="small">
          <TabPane tab="List" key="1">
            <CustodianGrid {...this.props} />
          </TabPane>
          <TabPane tab="Account" key="2">
            <CustodianAccountGrid {...this.props} />
          </TabPane>
          <TabPane tab="Holding" key="3">
            
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default CustodianDashboard;