import {
  // query
  ON_QUERY_INPUT_CHANGE,
  // Load Questionnaire
  START_LOAD_QUESTIONNAIRES,
  LOAD_QUESTIONNAIRES_SUCCESS,
  LOAD_QUESTIONNAIRES_FAILURE,
  INIT_LOAD_QUESTIONNAIRES_ACTION_STATUS,

  // Select Questionnaires
  SELECT_QUESTIONNAIRES,
  
  // Load Question
  START_LOAD_QUESTIONS,
  LOAD_QUESTIONS_SUCCESS,
  LOAD_QUESTIONS_FAILURE,
  INIT_LOAD_QUESTIONS_ACTION_STATUS,

  // Select Questions
  SELECT_QUESTIONS,

  // Open/Reset Answeree Select Modal Dialog
  ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
  ANSWEREE_SELECT_MODAL_DIALOG_RESET,

  // Select All Answeree
  SELECT_ALL_ANSWEREE,

  // Select Invert Answeree
  SELECT_INVERT_ANSWEREE,

  // Answeree Check Changed
  ANSWEREE_CHECK_CHANGED,

  // Select Answerees
  START_SELECT_ANSWEREES,
  SELECT_ANSWEREES_SUCCESS,
  SELECT_ANSWEREES_FAILURE,
  INIT_SELECT_ANSWEREES_ACTION_STATUS,


  // Load Answers
  START_LOAD_ANSWERS,
  LOAD_ANSWERS_SUCCESS,
  LOAD_ANSWERS_FAILURE,
  INIT_LOAD_ANSWERS_ACTION_STATUS,

  // Load To-do Questionnaire
  START_LOAD_TODO_QUESTIONNAIRE,
  LOAD_TODO_QUESTIONNAIRE_SUCCESS,
  LOAD_TODO_QUESTIONNAIRE_FAILURE,
  INIT_LOAD_TODO_QUESTIONNAIRE_ACTION_STATUS,


  START_LOAD_MEETINGANDTRANINGSUB,
  LOAD_MEETINGANDTRANINGSUB_SUCCESS,
  LOAD_MEETINGANDTRANINGSUB_FAILURE,
  

  // Submit Questionnaire Answer
  START_SUBMIT_QUESTIONNAIRE_ANSWER,
  SUBMIT_QUESTIONNAIRE_ANSWER_SUCCESS,
  SUBMIT_QUESTIONNAIRE_ANSWER_FAILURE,
  INIT_SUBMIT_QUESTIONNAIRE_ANSWER_ACTION_STATUS,

  START_SUBMIT_MEETINGANDTRANINGSUB_ANSWER,
  SUBMIT_MEETINGANDTRANINGSUB_ANSWER_SUCCESS,
  SUBMIT_MEETINGANDTRANINGSUB_ANSWER_FAILURE,
  INIT_SUBMIT_MEETINGANDTRANINGSUB_ANSWER_ACTION_STATUS,




  // Save Questionnaire Answer
  START_SAVE_QUESTIONNAIRE_ANSWER,
  SAVE_QUESTIONNAIRE_ANSWER_SUCCESS,
  SAVE_QUESTIONNAIRE_ANSWER_FAILURE,
  INIT_SAVE_QUESTIONNAIRE_ANSWER_ACTION_STATUS,

  // Questionnaire Answer Change Input
  QUESTIONNAIRE_ANSWER_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset Questionnaire Edit Modal Dialog
  QUESTIONNAIRE_EDIT_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_EDIT_MODAL_DIALOG_RESET,

  // Create Questionnaire
  START_CREATE_QUESTIONNAIRE,
  CREATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTIONNAIRE_FAILURE,
  INIT_CREATE_QUESTIONNAIRE_ACTION_STATUS,

  // Update Questionnaire
  START_UPDATE_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE,
  INIT_UPDATE_QUESTIONNAIRE_ACTION_STATUS,

  // Copy Questionnaire
  START_COPY_QUESTIONNAIRE,
  COPY_QUESTIONNAIRE_SUCCESS,
  COPY_QUESTIONNAIRE_FAILURE,
  INIT_COPY_QUESTIONNAIRE_ACTION_STATUS,

  // Batch Delete Questionnaire
  START_BATCH_DELETE_QUESTIONNAIRE,
  BATCH_DELETE_QUESTIONNAIRE_SUCCESS,
  BATCH_DELETE_QUESTIONNAIRE_FAILURE,
  INIT_BATCH_DELETE_QUESTIONNAIRE_ACTION_STATUS,

  // Questionnaire Edit Change Input
  QUESTIONNAIRE_EDIT_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  QUESTION_EDIT_MODAL_DIALOG_OPEN,
  QUESTION_EDIT_MODAL_DIALOG_RESET,

  // Create Question
  START_CREATE_QUESTION,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  INIT_CREATE_QUESTION_ACTION_STATUS,

  // Update Question
  START_UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  INIT_UPDATE_QUESTION_ACTION_STATUS,

  // Batch Delete Question
  START_BATCH_DELETE_QUESTION,
  BATCH_DELETE_QUESTION_SUCCESS,
  BATCH_DELETE_QUESTION_FAILURE,
  INIT_BATCH_DELETE_QUESTION_ACTION_STATUS,

  // Question Edit Change Input
  QUESTION_EDIT_CHANGE_INPUT,

  // Questionnaire Option Change
  QUESTIONNAIRE_OPTION_CHANGE,

  // Load Questionnaire Results
  START_LOAD_QUESTIONNAIRE_RESULTS,
  LOAD_QUESTIONNAIRE_RESULTS_SUCCESS,
  LOAD_QUESTIONNAIRE_RESULTS_FAILURE,
  INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS,

  // Select Questionnaire Results
  SELECT_QUESTIONNAIRE_RESULTS,

  // Load Questionnaire Result Detail
  START_LOAD_QUESTIONNAIRE_RESULT_DETAIL,
  LOAD_QUESTIONNAIRE_RESULT_DETAIL_SUCCESS,
  LOAD_QUESTIONNAIRE_RESULT_DETAIL_FAILURE,
  INIT_LOAD_QUESTIONNAIRE_RESULT_DETAIL_ACTION_STATUS,

  // Accept Questionnaire Result
  START_ACCEPT_QUESTIONNAIRE_RESULT,
  ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
  ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

  // Reject Questionnaire Result
  START_REJECT_QUESTIONNAIRE_RESULT,
  REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
  REJECT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

  // Open/Reset Questionnaire Result Operate Modal Dialog
  QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_RESET,

  // Questionnaire Answer Submitter Edit Change Input
  QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,
  MEETINGANDTRAINING_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,

  // Send Remind Email
  START_SEND_REMIND_EMAIL,
  SEND_REMIND_EMAIL_SUCCESS,
  SEND_REMIND_EMAIL_FAILURE,
  INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,

  // Update Questionnaire Score
  START_UPDATE_QUESTIONNAIRE_SCORE,
  UPDATE_QUESTIONNAIRE_SCORE_SUCCESS,
  UPDATE_QUESTIONNAIRE_SCORE_FAILURE,
  INIT_UPDATE_QUESTIONNAIRE_SCORE_ACTION_STATUS,

  // Calculate Questionnaire Total Score
  CALC_QUESTIONNAIRE_TOTAL_SCORE,

  // Open/Reset Submitted Questionnaire History Modal Dialog
  SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN,
  SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET,

  // Load Submitted Questionnaire History
  START_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY,
  LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_SUCCESS,
  LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_FAILURE,
  INIT_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_ACTION_STATUS,

  // Select Submitted Questionnaire History
  SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY,

  // Apply Submitted Questionnaire History
  APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY,


  //licensing - Personal
  START_LOAD_TODO_LICENSINGPERSONAL,
  LOAD_TODO_LICENSINGPERSONAL_SUCCESS,
  LOAD_TODO_LICENSINGPERSONAL_FAILURE,
  INIT_LOAD_TODO_LICENSINGPERSONAL_ACTION_STATUS,


  START_SAVE_LICENSINGPERSONAL_ANSWER,
  SAVE_LICENSINGPERSONAL_ANSWER_SUCCESS,
  SAVE_LICENSINGPERSONAL_ANSWER_FAILURE,
  INIT_SAVE_LICENSINGPERSONAL_ANSWER_ACTION_STATUS,

  START_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY,
  LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_SUCCESS,
  LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_FAILURE,
  INIT_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_ACTION_STATUS,

  START_SUBMIT_LICENSINGPERSONAL_ANSWER,
  SUBMIT_LICENSINGPERSONAL_ANSWER_SUCCESS,
  SUBMIT_LICENSINGPERSONAL_ANSWER_FAILURE,
  INIT_SUBMIT_LICENSINGPERSONAL_ANSWER_ACTION_STATUS,

} from './questionnaireConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';
import BaseUrlConfig from '../../../net/BaseUrlConfig';

// =================================================================

// query
export function onQueryInputChange({name, value}) {
  return {
      type: ON_QUERY_INPUT_CHANGE,
      payload: {
        name,
        value,
      }
  };
}
// Load Questionnaire
function startLoadQuestionnaires() {
  return {
    type: START_LOAD_QUESTIONNAIRES
  };
}

export function loadQuestionnaires(params) {
  return function(dispatch) {
    dispatch(startLoadQuestionnaires());
    httpClient
      .get(UrlConfig.QUERY_QUESTIONNAIRES,params)
      .then(resp => dispatch(loadQuestionnairesSuccess(resp)))
      .catch(err => dispatch(loadQuestionnairesFailure(err)));
  };
}

function loadQuestionnairesSuccess(resp) {
  return {
    type: LOAD_QUESTIONNAIRES_SUCCESS,
    payload: resp
  };
}

function loadQuestionnairesFailure(err) {
  return {
    type: LOAD_QUESTIONNAIRES_FAILURE,
    payload: err
  };
}

export function initLoadQuestionnairesActionStatus() {
  return {
    type: INIT_LOAD_QUESTIONNAIRES_ACTION_STATUS
  };
}

// Select Questionnaires
export function selectQuestionnaires(payload) {
  return {
    type: SELECT_QUESTIONNAIRES,
    payload
  };
}




// =================================================================
// Load Question
function startLoadQuestions() {
  return {
    type: START_LOAD_QUESTIONS
  };
}

export function loadQuestions(questionnaireGuid) {
  const requestParams = {
    'questionnaire-guid': questionnaireGuid,
  };

  return function(dispatch) {
    dispatch(startLoadQuestions());
    httpClient
      .get(UrlConfig.QUERY_QUESTIONS, requestParams)
      .then(resp => dispatch(loadQuestionsSuccess(resp)))
      .catch(err => dispatch(loadQuestionsFailure(err)));
  };
}

function loadQuestionsSuccess(resp) {
  return {
    type: LOAD_QUESTIONS_SUCCESS,
    payload: resp
  };
}

function loadQuestionsFailure(err) {
  return {
    type: LOAD_QUESTIONS_FAILURE,
    payload: err
  };
}

export function initLoadQuestionsActionStatus() {
  return {
    type: INIT_LOAD_QUESTIONS_ACTION_STATUS
  };
}

// Select Questions
export function selectQuestions(payload) {
  return {
    type: SELECT_QUESTIONS,
    payload
  };
}

// Open/Reset Answeree Select Modal Dialog
export function openAnswereeSelectModalDialog() {
  return {
    type: ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    payload: {
      
    },
  };
}

export function resetAnswereeSelectModalDialog() {
  return {
    type: ANSWEREE_SELECT_MODAL_DIALOG_RESET
  };
}

// Select All Answeree
export function selectAllAnsweree() {
  return {
    type: SELECT_ALL_ANSWEREE
  };
}

// Select Invert Answeree
export function selectInvertAnsweree() {
  return {
    type: SELECT_INVERT_ANSWEREE
  };
}

// Answeree Check Changed
export function answereeCheckChanged(checked ,userAccountName) {
  return {
    type: ANSWEREE_CHECK_CHANGED,
    payload: {
      checked,
      userAccountName
    }
  };
}

// Select Answerees
function startSelectAnswerees() {
  return {
    type: START_SELECT_ANSWEREES
  };
}

export function selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent) {
  const requestParams = {
    userAccountNameList,
    questionnaireGuid, 
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startSelectAnswerees());
    httpClient
      .post(UrlConfig.SELECT_ANSWEREES, requestParams)
      .then(resp => dispatch(selectAnswereesSuccess(resp)))
      .catch(err => dispatch(selectAnswereesFailure(err)));
  };
}

function selectAnswereesSuccess(resp) {
  return {
    type: SELECT_ANSWEREES_SUCCESS,
    payload: resp
  };
}

function selectAnswereesFailure(err) {
  return {
    type: SELECT_ANSWEREES_FAILURE,
    payload: err
  };
}

export function initSelectAnswereesActionStatus() {
  return {
    type: INIT_SELECT_ANSWEREES_ACTION_STATUS
  };
}


















// =================================================================
// Load Answers
function startLoadAnswers() {
  return {
    type: START_LOAD_ANSWERS
  };
}

export function loadAnswers(params) {

  let questionnaireGuid = '';
  let userAccountName = '';
  if (params) {
    questionnaireGuid = params.questionnaireGuid || '';
    userAccountName = params.userAccountName || '';
  }

  const requestParams = {
    questionnaireGuid,
    userAccountName,
  };

  return function(dispatch) {
    dispatch(startLoadAnswers());
    httpClient
      .get(UrlConfig.QUERY_ANSWERS, requestParams)
      .then(resp => dispatch(loadAnswersSuccess(resp)))
      .catch(err => dispatch(loadAnswersFailure(err)));
  };
}

function loadAnswersSuccess(resp) {
  return {
    type: LOAD_ANSWERS_SUCCESS,
    payload: resp
  };
}

function loadAnswersFailure(err) {
  return {
    type: LOAD_ANSWERS_FAILURE,
    payload: err
  };
}

export function initLoadAnswersActionStatus() {
  return {
    type: INIT_LOAD_ANSWERS_ACTION_STATUS
  };
}





// Load To-do Questionnaire
function startLoadTodoQuestionnaire() {
  return {
    type: START_LOAD_TODO_QUESTIONNAIRE
  };
}

export function loadTodoQuestionnaire(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadTodoQuestionnaire(requestParams));
    httpClient
      .get(UrlConfig.QUERY_TODO_QUESTIONNAIRE, requestParams)
      .then(resp => dispatch(loadTodoQuestionnaireSuccess(resp)))
      .catch(err => dispatch(loadTodoQuestionnaireFailure(err)));
  };
}

function loadTodoQuestionnaireSuccess(resp) {
  return {
    type: LOAD_TODO_QUESTIONNAIRE_SUCCESS,
    payload: resp
  };
}

function loadTodoQuestionnaireFailure(err) {
  return {
    type: LOAD_TODO_QUESTIONNAIRE_FAILURE,
    payload: err
  };
}

// Load To-do  loadMeetingAndTraningSub
function startLoadMeetingAndTraningSub() {
  return {
    type: START_LOAD_MEETINGANDTRANINGSUB
  };
}
export function loadMeetingAndTraningSub(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadMeetingAndTraningSub(requestParams));
    httpClient
      .get(UrlConfig.QUERY_MEETINGANDTRANINGSUB, requestParams)
      .then(resp => dispatch(loadMeetingAndTraningSubSuccess(resp)))
      .catch(err => dispatch(loadMeetingAndTraningSubFailure(err)));
  };
}

function loadMeetingAndTraningSubSuccess(resp) {
  return {
    type: LOAD_MEETINGANDTRANINGSUB_SUCCESS,
    payload: resp
  };
}

function loadMeetingAndTraningSubFailure(err) {
  return {
    type: LOAD_MEETINGANDTRANINGSUB_FAILURE,
    payload: err
  };
}


// Load To-do Licensing - personal  todo
function startLoadTodoLicensingPersonal() {
  return {
    type: START_LOAD_TODO_LICENSINGPERSONAL
  };
}

export function loadTodoLicensingPersonal(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadTodoLicensingPersonal(requestParams));
    httpClient
      .get(UrlConfig.QUERY_TODO_LICENSINGFORM, requestParams)
      .then(resp => dispatch(loadTodoLicensingPersonalSuccess(resp)))
      .catch(err => dispatch(loadTodoLicensingPersonalFailure(err)));
  };
}

function loadTodoLicensingPersonalSuccess(resp) {
  return {
    type: LOAD_TODO_LICENSINGPERSONAL_SUCCESS,
    payload: resp
  };
}

function loadTodoLicensingPersonalFailure(err) {
  return {
    type: LOAD_TODO_LICENSINGPERSONAL_FAILURE,
    payload: err
  };
}



export function initLoadTodoLicensingPersonalActionStatus() {
  return {
    type: INIT_LOAD_TODO_LICENSINGPERSONAL_ACTION_STATUS
  };
}


























export function initLoadTodoQuestionnaireActionStatus() {
  return {
    type: INIT_LOAD_TODO_QUESTIONNAIRE_ACTION_STATUS
  };
}


// Submit Questionnaire Answer
function startSubmitQuestionnaireAnswer() {
  return {
    type: START_SUBMIT_QUESTIONNAIRE_ANSWER
  };
}

export function submitQuestionnaireAnswer(authToken, questionnaireAnswerList, submitter, submittedDate) {
  const requestParams = {
    authToken,
    submitter,
    submittedDate,
    questionnaireAnswerList,
  };

  return function(dispatch) {
    dispatch(startSubmitQuestionnaireAnswer(requestParams));
    httpClient
      .post(UrlConfig.SUBMIT_QUESTIONNAIRE_ANSWER, requestParams)
      .then(resp => dispatch(submitQuestionnaireAnswerSuccess(resp)))
      .catch(err => dispatch(submitQuestionnaireAnswerFailure(err)));
  };
}

function submitQuestionnaireAnswerSuccess(resp) {
  return {
    type: SUBMIT_QUESTIONNAIRE_ANSWER_SUCCESS,
    payload: resp
  };
}

function submitQuestionnaireAnswerFailure(err) {
  return {
    type: SUBMIT_QUESTIONNAIRE_ANSWER_FAILURE,
    payload: err
  };
}

export function initSubmitQuestionnaireAnswerActionStatus() {
  return {
    type: INIT_SUBMIT_QUESTIONNAIRE_ANSWER_ACTION_STATUS
  };
}

// Submit LicensingPersonal Answer
function startSubmitLicensingPersonalAnswer() {
  return {
    type: START_SUBMIT_LICENSINGPERSONAL_ANSWER
  };
}

export function submitLicensingPersonalAnswer(authToken, questionnaireAnswerList, submitter, submittedDate) {
  const requestParams = {
    authToken,
    submitter,
    submittedDate,
    questionnaireAnswerList,
  };

  return function(dispatch) {
    dispatch(startSubmitLicensingPersonalAnswer(requestParams));
    httpClient
      .post(UrlConfig.SUBMIT_LICENSINGFORM_ANSWER, requestParams)
      .then(resp => dispatch(submitLicensingPersonalAnswerSuccess(resp)))
      .catch(err => dispatch(submitLicensingPersonalAnswerFailure(err)));
  };
}

function submitLicensingPersonalAnswerSuccess(resp) {
  return {
    type: SUBMIT_LICENSINGPERSONAL_ANSWER_SUCCESS,
    payload: resp
  };
}

function submitLicensingPersonalAnswerFailure(err) {
  return {
    type: SUBMIT_LICENSINGPERSONAL_ANSWER_FAILURE,
    payload: err
  };
}

export function initSubmitLicensingPersonalAnswerActionStatus() {
  return {
    type: INIT_SUBMIT_LICENSINGPERSONAL_ANSWER_ACTION_STATUS
  };
}

// 签到
function startSubmitMeetingAndTraningAnswer() {
  return {
    type: START_SUBMIT_MEETINGANDTRANINGSUB_ANSWER
  };
}

export function submitMeetingAndTraningAnswer(borker, sector,title,starttime, address,group) {
  const requestParams = {
    borker,
    sector,
    title,
    starttime,
    address,
    group,

  };

  return function(dispatch) {
    dispatch(startSubmitMeetingAndTraningAnswer(requestParams));
    httpClient
      .post(UrlConfig.SALE_MEETINGANDTRANINGS, requestParams)
      .then(resp => dispatch(submitMeetingAndTraningAnswerSuccess(resp)))
      .catch(err => dispatch(submitMeetingAndTraningAnswerFailure(err)));
  };
}

function submitMeetingAndTraningAnswerSuccess(resp) {
  return {
    type: SUBMIT_MEETINGANDTRANINGSUB_ANSWER_SUCCESS,
    payload: resp
  };
}

function submitMeetingAndTraningAnswerFailure(err) {
  return {
    type: SUBMIT_MEETINGANDTRANINGSUB_ANSWER_FAILURE,
    payload: err
  };
}

export function initSubmitMeetingAndTraningAnswerActionStatus() {
  return {
    type: INIT_SUBMIT_MEETINGANDTRANINGSUB_ANSWER_ACTION_STATUS
  };
}






















// Save Questionnaire Answer
function startSaveQuestionnaireAnswer() {
  return {
    type: START_SAVE_QUESTIONNAIRE_ANSWER
  };
}

export function saveQuestionnaireAnswer(authToken, questionnaireAnswerList, submitter, submittedDate) {
  const requestParams = {
    authToken,
    submitter,
    submittedDate,
    questionnaireAnswerList,
  };

  return function(dispatch) {
    dispatch(startSaveQuestionnaireAnswer(requestParams));
    httpClient
      .post(UrlConfig.SAVE_QUESTIONNAIRE_ANSWER, requestParams)
      .then(resp => dispatch(saveQuestionnaireAnswerSuccess(resp)))
      .catch(err => dispatch(saveQuestionnaireAnswerFailure(err)));
  };
}

function saveQuestionnaireAnswerSuccess(resp) {
  return {
    type: SAVE_QUESTIONNAIRE_ANSWER_SUCCESS,
    payload: resp
  };
}

function saveQuestionnaireAnswerFailure(err) {
  return {
    type: SAVE_QUESTIONNAIRE_ANSWER_FAILURE,
    payload: err
  };
}

export function initSaveQuestionnaireAnswerActionStatus() {
  return {
    type: INIT_SAVE_QUESTIONNAIRE_ANSWER_ACTION_STATUS
  };
}


// Save Questionnaire Answer
function startSaveLicensingPersonalAnswer() {
  return {
    type: START_SAVE_LICENSINGPERSONAL_ANSWER  
  };
}

export function saveLicensingPersonalAnswer(authToken, questionnaireAnswerList, submitter, submittedDate) {
  const requestParams = {
    authToken,
    submitter,
    submittedDate,
    questionnaireAnswerList,
  };

  return function(dispatch) {
    dispatch(startSaveLicensingPersonalAnswer(requestParams));
    httpClient
      .post(UrlConfig.SAVE_LICENSINGFORM_ANSWER, requestParams)
      .then(resp => dispatch(saveLicensingPersonalAnswerSuccess(resp)))
      .catch(err => dispatch(saveLicensingPersonalAnswerFailure(err)));
  };
}

function saveLicensingPersonalAnswerSuccess(resp) {
  return {
    type: SAVE_LICENSINGPERSONAL_ANSWER_SUCCESS,
    payload: resp
  };
}

function saveLicensingPersonalAnswerFailure(err) {
  return {
    type: SAVE_LICENSINGPERSONAL_ANSWER_FAILURE,
    payload: err
  };
}

export function initSaveLicensingPersonalAnswerActionStatus() {
  return {
    type: INIT_SAVE_LICENSINGPERSONAL_ANSWER_ACTION_STATUS
  };
}

// Questionnaire Answer Change Input
export function questionnaireAnswerChangeInput({ answerGuid, answerContent, fileList }) {
  return {
    type: QUESTIONNAIRE_ANSWER_CHANGE_INPUT,
    payload: {
      answerGuid,
      answerContent,
      fileList,
    }
  };
}


export function licensingPersonalAnswerChangeInput({ answerGuid, answerContent, fileList }) {
  return {
    type: QUESTIONNAIRE_ANSWER_CHANGE_INPUT,
    payload: {
      answerGuid,
      answerContent,
      fileList,
    }
  };
}

// ================================================================================
// Open/Reset Questionnaire Edit Modal Dialog
export function openQuestionnaireEditModalDialog(mode, questionnaire) {
  return {
    type: QUESTIONNAIRE_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      mode,
      questionnaire,
    },
  };
}

export function resetQuestionnaireEditModalDialog() {
  return {
    type: QUESTIONNAIRE_EDIT_MODAL_DIALOG_RESET
  };
}






// Create Questionnaire
function startCreateQuestionnaire() {
  return {
    type: START_CREATE_QUESTIONNAIRE
  };
}

export function createQuestionnaire(questionnaire) {
  return function(dispatch) {
    dispatch(startCreateQuestionnaire());
    httpClient
      .post(UrlConfig.NEW_QUESTIONNAIRE, questionnaire)
      .then(resp => dispatch(createQuestionnaireSuccess(resp)))
      .catch(err => dispatch(createQuestionnaireFailure(err)));
  };
}

function createQuestionnaireSuccess(resp) {
  return {
    type: CREATE_QUESTIONNAIRE_SUCCESS,
    payload: resp
  };
}

function createQuestionnaireFailure(err) {
  return {
    type: CREATE_QUESTIONNAIRE_FAILURE,
    payload: err
  };
}

export function initCreateQuestionnaireActionStatus() {
  return {
    type: INIT_CREATE_QUESTIONNAIRE_ACTION_STATUS
  };
}

// Update Questionnaire
function startUpdateQuestionnaire() {
  return {
    type: START_UPDATE_QUESTIONNAIRE
  };
}

export function updateQuestionnaire(questionnaire) {
  return function(dispatch) {
    dispatch(startUpdateQuestionnaire());
    httpClient
      .post(UrlConfig.UPDATED_QUESTIONNAIRE, questionnaire)
      .then(resp => dispatch(updateQuestionnaireSuccess(resp)))
      .catch(err => dispatch(updateQuestionnaireFailure(err)));
  };
}

function updateQuestionnaireSuccess(resp) {
  return {
    type: UPDATE_QUESTIONNAIRE_SUCCESS,
    payload: resp
  };
}

function updateQuestionnaireFailure(err) {
  return {
    type: UPDATE_QUESTIONNAIRE_FAILURE,
    payload: err
  };
}

export function initUpdateQuestionnaireActionStatus() {
  return {
    type: INIT_UPDATE_QUESTIONNAIRE_ACTION_STATUS
  };
}

// Copy Questionnaire
function startCopyQuestionnaire() {
  return {
    type: START_COPY_QUESTIONNAIRE
  };
}

export function copyQuestionnaire(questionnaire) {
  return function(dispatch) {
    dispatch(startCopyQuestionnaire());
    httpClient
      .post(UrlConfig.COPIED_QUESTIONNAIRE, questionnaire)
      .then(resp => dispatch(copyQuestionnaireSuccess(resp)))
      .catch(err => dispatch(copyQuestionnaireFailure(err)));
  };
}

function copyQuestionnaireSuccess(resp) {
  return {
    type: COPY_QUESTIONNAIRE_SUCCESS,
    payload: resp
  };
}

function copyQuestionnaireFailure(err) {
  return {
    type: COPY_QUESTIONNAIRE_FAILURE,
    payload: err
  };
}

export function initCopyQuestionnaireActionStatus() {
  return {
    type: INIT_COPY_QUESTIONNAIRE_ACTION_STATUS
  };
}


// Batch Delete Questionnaire
function startBatchDeleteQuestionnaire() {
  return {
    type: START_BATCH_DELETE_QUESTIONNAIRE
  };
}

export function batchDeleteQuestionnaire(questionnaireGuidList) {
  return function(dispatch) {
    dispatch(startBatchDeleteQuestionnaire());
    httpClient
      .post(UrlConfig.BATCH_DELETED_QUESTIONNAIRE, questionnaireGuidList)
      .then(resp => dispatch(batchDeleteQuestionnaireSuccess(resp)))
      .catch(err => dispatch(batchDeleteQuestionnaireFailure(err)));
  };
}

function batchDeleteQuestionnaireSuccess(resp) {
  return {
    type: BATCH_DELETE_QUESTIONNAIRE_SUCCESS,
    payload: resp
  };
}

function batchDeleteQuestionnaireFailure(err) {
  return {
    type: BATCH_DELETE_QUESTIONNAIRE_FAILURE,
    payload: err
  };
}

export function initBatchDeleteQuestionnaireActionStatus() {
  return {
    type: INIT_BATCH_DELETE_QUESTIONNAIRE_ACTION_STATUS
  };
}

// Questionnaire Edit Change Input
export function questionnaireEditChangeInput({ name, value }) {
  return {
    type: QUESTIONNAIRE_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// ================================================================================
// Open/Reset Question Edit Modal Dialog
export function openQuestionEditModalDialog(isAddMode, question) {
  return {
    type: QUESTION_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      question,
    },
  };
}

export function resetQuestionEditModalDialog() {
  return {
    type: QUESTION_EDIT_MODAL_DIALOG_RESET
  };
}

// Create Question
function startCreateQuestion() {
  return {
    type: START_CREATE_QUESTION
  };
}

export function createQuestion(question) {
  return function(dispatch) {
    dispatch(startCreateQuestion());
    httpClient
      .post(UrlConfig.NEW_QUESTION, question)
      .then(resp => dispatch(createQuestionSuccess(resp)))
      .catch(err => dispatch(createQuestionFailure(err)));
  };
}

function createQuestionSuccess(resp) {
  return {
    type: CREATE_QUESTION_SUCCESS,
    payload: resp
  };
}

function createQuestionFailure(err) {
  return {
    type: CREATE_QUESTION_FAILURE,
    payload: err
  };
}

export function initCreateQuestionActionStatus() {
  return {
    type: INIT_CREATE_QUESTION_ACTION_STATUS
  };
}

// Update Question
function startUpdateQuestion() {
  return {
    type: START_UPDATE_QUESTION
  };
}

export function updateQuestion(question) {
  return function(dispatch) {
    dispatch(startUpdateQuestion());
    httpClient
      .post(UrlConfig.UPDATED_QUESTION, question)
      .then(resp => dispatch(updateQuestionSuccess(resp)))
      .catch(err => dispatch(updateQuestionFailure(err)));
  };
}

function updateQuestionSuccess(resp) {
  return {
    type: UPDATE_QUESTION_SUCCESS,
    payload: resp
  };
}

function updateQuestionFailure(err) {
  return {
    type: UPDATE_QUESTION_FAILURE,
    payload: err
  };
}

export function initUpdateQuestionActionStatus() {
  return {
    type: INIT_UPDATE_QUESTION_ACTION_STATUS
  };
}

// Batch Delete Question
function startBatchDeleteQuestion() {
  return {
    type: START_BATCH_DELETE_QUESTION
  };
}

export function batchDeleteQuestion(questionIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteQuestion());
    httpClient
      .post(UrlConfig.BATCH_DELETED_QUESTION, questionIdList)
      .then(resp => dispatch(batchDeleteQuestionSuccess(resp)))
      .catch(err => dispatch(batchDeleteQuestionFailure(err)));
  };
}

function batchDeleteQuestionSuccess(resp) {
  return {
    type: BATCH_DELETE_QUESTION_SUCCESS,
    payload: resp
  };
}

function batchDeleteQuestionFailure(err) {
  return {
    type: BATCH_DELETE_QUESTION_FAILURE,
    payload: err
  };
}

export function initBatchDeleteQuestionActionStatus() {
  return {
    type: INIT_BATCH_DELETE_QUESTION_ACTION_STATUS
  };
}

// Question Edit Change Input
export function questionEditChangeInput({ name, value }) {
  return {
    type: QUESTION_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// Questionnaire Option Change
export function questionnaireOptionChange(questionnaireGuid) {
  return {
    type: QUESTIONNAIRE_OPTION_CHANGE,
    payload: {
      questionnaireGuid,
    }
  };
}

// Load Questionnaire Results
function startLoadQuestionnaireResults() {
  return {
    type: START_LOAD_QUESTIONNAIRE_RESULTS
  };
}

export function loadQuestionnaireResults(params) {
  let questionnaireGuid = '';
  let userAccountName = '';
  let sentMonth = '';
  let subMonth = '';
  let answerStatus = '';
  let sentOnFrom = '';
  let sentOnTo = '';
  let questionnaireVersion = '';
  if (params) {
    questionnaireGuid = params.questionnaireGuid || '';
    userAccountName = params.userAccountName || '';
    sentMonth = params.sentMonth || '';
    subMonth = params.subMonth || '';
    answerStatus = params.answerStatus || '';
    sentOnFrom = params.sentOnFrom || '';
    sentOnTo = params.sentOnTo || '';
    questionnaireVersion = params.questionnaireVersion || '';
  }

  const requestParams = {
    questionnaireGuid,
    userAccountName,
    sentMonth,
    subMonth,
    answerStatus,
    sentOnFrom,
    sentOnTo,
    questionnaireVersion,
  };

  return function(dispatch) {
    dispatch(startLoadQuestionnaireResults());
    httpClient
      .get(UrlConfig.LOAD_QUESTIONNAIRE_RESULTS, requestParams)
      .then(resp => dispatch(loadQuestionnaireResultsSuccess(resp)))
      .catch(err => dispatch(loadQuestionnaireResultsFailure(err)));
  };
}

function loadQuestionnaireResultsSuccess(resp) {
  return {
    type: LOAD_QUESTIONNAIRE_RESULTS_SUCCESS,
    payload: resp
  };
}

function loadQuestionnaireResultsFailure(err) {
  return {
    type: LOAD_QUESTIONNAIRE_RESULTS_FAILURE,
    payload: err
  };
}

export function initLoadQuestionnaireResultsActionStatus() {
  return {
    type: INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS
  };
}

// Select Questionnaire Results
export function selectQuestionnaireResults(payload) {
  return {
    type: SELECT_QUESTIONNAIRE_RESULTS,
    payload
  };
}

// Load Questionnaire Result Detail
function startLoadQuestionnaireResultDetail() {
  return {
    type: START_LOAD_QUESTIONNAIRE_RESULT_DETAIL
  };
}

export function loadQuestionnaireResultDetail(questionnaireResultGuid) {
  const requestParams = {
    'questionnaire-result-guid': questionnaireResultGuid,
  };

  return function(dispatch) {
    dispatch(startLoadQuestionnaireResultDetail());
    httpClient
      .get(UrlConfig.LOAD_QUESTIONNAIRE_RESULT_DETAIL, requestParams)
      .then(resp => dispatch(loadQuestionnaireResultDetailSuccess(resp)))
      .catch(err => dispatch(loadQuestionnaireResultDetailFailure(err)));
  };
}

function loadQuestionnaireResultDetailSuccess(resp) {
  return {
    type: LOAD_QUESTIONNAIRE_RESULT_DETAIL_SUCCESS,
    payload: resp
  };
}

function loadQuestionnaireResultDetailFailure(err) {
  return {
    type: LOAD_QUESTIONNAIRE_RESULT_DETAIL_FAILURE,
    payload: err
  };
}

export function initLoadQuestionnaireResultDetailActionStatus() {
  return {
    type: INIT_LOAD_QUESTIONNAIRE_RESULT_DETAIL_ACTION_STATUS
  };
}

// Accept Questionnaire Result
function startAcceptQuestionnaireResult() {
  return {
    type: START_ACCEPT_QUESTIONNAIRE_RESULT
  };
}

export function acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
  const requestParams = {
    questionnaireResultGuidList,
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startAcceptQuestionnaireResult());
    httpClient
      .post(UrlConfig.ACCEPT_QUESTIONNAIRE_RESULT, requestParams)
      .then(resp => dispatch(acceptQuestionnaireResultSuccess(resp)))
      .catch(err => dispatch(acceptQuestionnaireResultFailure(err)));
  };
}

function acceptQuestionnaireResultSuccess(resp) {
  return {
    type: ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
    payload: resp
  };
}

function acceptQuestionnaireResultFailure(err) {
  return {
    type: ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
    payload: err
  };
}

export function initAcceptQuestionnaireResultActionStatus() {
  return {
    type: INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS
  };
}

// Reject Questionnaire Result
function startRejectQuestionnaireResult() {
  return {
    type: START_REJECT_QUESTIONNAIRE_RESULT
  };
}

export function rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
  const requestParams = {
    questionnaireResultGuidList,
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startRejectQuestionnaireResult());
    httpClient
      .post(UrlConfig.REJECT_QUESTIONNAIRE_RESULT, requestParams)
      .then(resp => dispatch(rejectQuestionnaireResultSuccess(resp)))
      .catch(err => dispatch(rejectQuestionnaireResultFailure(err)));
  };
}

function rejectQuestionnaireResultSuccess(resp) {
  return {
    type: REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
    payload: resp
  };
}

function rejectQuestionnaireResultFailure(err) {
  return {
    type: REJECT_QUESTIONNAIRE_RESULT_FAILURE,
    payload: err
  };
}

export function initRejectQuestionnaireResultActionStatus() {
  return {
    type: INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS
  };
}

// Open/Reset Questionnaire Result Operate Modal Dialog
export function openQuestionnaireResultOperateModalDialog(opMode, questionnaireResultGuidList) {
  return {
    type: QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_OPEN,
    payload: {
      opMode,
      questionnaireResultGuidList,
    },
  };
}

export function resetQuestionnaireResultOperateModalDialog() {
  return {
    type: QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_RESET
  };
}

// Questionnaire Answer Submitter Edit Change Input
export function questionnaireAnswerSubmitterEditChangeInput(value) {
  return {
    type: QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,
    payload: value,
  };
}
export function meetingAndTrainingAnswerSubmitterEditChangeInput(value) {
  return {
    type: MEETINGANDTRAINING_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,
    payload: value,
  };
}

export function licensingPersonalAnswerSubmitterEditChangeInput(value) {
  return {
    type: QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,
    payload: value,
  };
}

// Send Remind Email
function startSendRemindEmail() {
  return {
    type: START_SEND_REMIND_EMAIL
  };
}

export function sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent) {
  const requestParams = {
    questionnaireResultGuidList,
    emailTitle,
    emailContent
  };

  return function(dispatch) {
    dispatch(startSendRemindEmail());
    httpClient
      .post(UrlConfig.REMIND_QUESTIONNAIRE_EMAIL, requestParams)
      .then(resp => dispatch(sendRemindEmailSuccess(resp)))
      .catch(err => dispatch(sendRemindEmailFailure(err)));
  };
}

function sendRemindEmailSuccess(resp) {
  return {
    type: SEND_REMIND_EMAIL_SUCCESS,
    payload: resp
  };
}

function sendRemindEmailFailure(err) {
  return {
    type: SEND_REMIND_EMAIL_FAILURE,
    payload: err
  };
}

export function initSendRemindEmailActionStatus() {
  return {
    type: INIT_SEND_REMIND_EMAIL_ACTION_STATUS
  };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
  return {
    type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      sendMode,
      emailPayload,
    },
  };
}

export function resetQuestionnaireEmailEditModalDialog() {
  return {
    type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET
  };
}

// Update Questionnaire Score
function startUpdateQuestionnaireScore() {
  return {
    type: START_UPDATE_QUESTIONNAIRE_SCORE
  };
}

export function updateQuestionnaireScore(questionnaireAnswer) {
  const requestParams = {
    questionnaireGuid : questionnaireAnswer.questionnaireGuid,
    questionGuid : questionnaireAnswer.questionGuid,
    answerGuid : questionnaireAnswer.answerGuid,
    questionnaireResultGuid : questionnaireAnswer.questionnaireResultGuid,
    answeree : questionnaireAnswer.answeree,
    score : questionnaireAnswer.score,
  };

  return function(dispatch) {
    dispatch(startUpdateQuestionnaireScore());
    httpClient
      .post(UrlConfig.UPDATE_QUESTIONNAIRE_SCORE, requestParams)
      .then(resp => dispatch(updateQuestionnaireScoreSuccess(resp)))
      .catch(err => dispatch(updateQuestionnaireScoreFailure(err)));
  };
}

function updateQuestionnaireScoreSuccess(resp) {
  return {
    type: UPDATE_QUESTIONNAIRE_SCORE_SUCCESS,
    payload: resp
  };
}

function updateQuestionnaireScoreFailure(err) {
  return {
    type: UPDATE_QUESTIONNAIRE_SCORE_FAILURE,
    payload: err
  };
}

export function initUpdateQuestionnaireScoreActionStatus() {
  return {
    type: INIT_UPDATE_QUESTIONNAIRE_SCORE_ACTION_STATUS
  };
}

// Calculate Questionnaire Total Score
export function calculateQuestionnaireTotalScore() {
  return {
    type: CALC_QUESTIONNAIRE_TOTAL_SCORE
  };
}

// Open/Reset Submitted Questionnaire History Modal Dialog
export function openSubmittedQuestionnaireHistoryModalDialog(authToken) {
  return {
    type: SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN,
    payload: {
      authToken,
    },
  };
}

export function resetSubmittedQuestionnaireHistoryModalDialog() {
  return {
    type: SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET
  };
}


export function openSubmittedLicensingPersonalHistoryModalDialog(authToken) {
  return {
    type: SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN,
    payload: {
      authToken,
    },
  };
}

export function resetSubmittedLicensingPersonalHistoryModalDialog() {
  return {
    type: SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET
  };
}





// Load Submitted Questionnaire History
function startLoadSubmittedQuestionnaireHistory() {
  return {
    type: START_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY
  };
}

export function loadSubmittedQuestionnaireHistory(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadSubmittedQuestionnaireHistory());
    httpClient
      .get(UrlConfig.LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY, requestParams)
      .then(resp => dispatch(loadSubmittedQuestionnaireHistorySuccess(resp)))
      .catch(err => dispatch(loadSubmittedQuestionnaireHistoryFailure(err)));
  };
}

function loadSubmittedQuestionnaireHistorySuccess(resp) {
  return {
    type: LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_SUCCESS,
    payload: resp
  };
}

function loadSubmittedQuestionnaireHistoryFailure(err) {
  return {
    type: LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_FAILURE,
    payload: err
  };
}

export function initLoadSubmittedQuestionnaireHistoryActionStatus() {
  return {
    type: INIT_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_ACTION_STATUS
  };
}


// Load Submitted Questionnaire History
function startLoadSubmittedLicensingPersonalHistory() {
  return {
    type: START_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY
  };
}

export function loadSubmittedLicensingPersonalHistory(authToken) {
  const requestParams = {
    'auth-token': authToken,
  };

  return function(dispatch) {
    dispatch(startLoadSubmittedLicensingPersonalHistory());
    httpClient
      .get(UrlConfig.LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY, requestParams)
      .then(resp => dispatch(loadSubmittedLicensingPersonalHistorySuccess(resp)))
      .catch(err => dispatch(loadSubmittedLicensingPersonalHistoryFailure(err)));
  };
}

function loadSubmittedLicensingPersonalHistorySuccess(resp) {
  return {
    type: LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_SUCCESS,
    payload: resp
  };
}

function loadSubmittedLicensingPersonalHistoryFailure(err) {
  return {
    type: LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_FAILURE,
    payload: err
  };
}

export function initLoadSubmittedLicensingPersonalHistoryActionStatus() {
  return {
    type: INIT_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_ACTION_STATUS
  };
}







// Select Submitted Questionnaire History
export function selectSubmittedLicensingPersonalHistory(payload) {
  return {
    type: SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY,
    payload
  };
}

// Apply Submitted Questionnaire History
export function applySubmittedLicensingPersonalHistory(historyQuestionnaire) {
  return {
    type: APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY,
    payload: {
      historyQuestionnaire,
    }
  };
}


// Select Submitted Questionnaire History
export function selectSubmittedQuestionnaireHistory(payload) {
  return {
    type: SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY,
    payload
  };
}

// Apply Submitted Questionnaire History
export function applySubmittedQuestionnaireHistory(historyQuestionnaire) {
  return {
    type: APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY,
    payload: {
      historyQuestionnaire,
    }
  };
}




// download pdf
export function downloadQuestionnaireResultPDF(guidList) {
  const w = window.open('about:blank');
  w.location.href = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION 
                    + UrlConfig.DOWNLOAD_QUESTIONNAIRE_RESULT_PDF + '?guid-list=' + guidList.join(',');
  return function(dispatch) { }
}

