import {

    // onLicensingQueryInputChange
    ON_LICENSING_QUERY_INPUT_CHANGE,

    // loadLicensingInfo
    START_LOAD_LICENSING_INFO,
    LOAD_LICENSING_INFO_SUCCESS,
    LOAD_LICENSING_INFO_FAILURE,
    INIT_LOAD_LICENSING_INFO_ACTION_STATUS,

    // add or edit license info
    OPEN_ADD_OR_EDIT_LICENSE_MODAL,
    ADD_OR_EDIT_LICENSE_CHANGE_INPUT,

    // loadLicenseBasicInfoOptions
    START_LOAD_LICENSE_BASIC_INFO_OPTIONS,
    LOAD_LICENSE_BASIC_INFO_OPTIONS_SUCCESS,
    LOAD_LICENSE_BASIC_INFO_OPTIONS_FAILURE,
    INIT_LOAD_LICENSE_BASIC_INFO_OPTIONS_ACTION_STATUS,

    // addOrEditLicenseEmpRequireRcd
    START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE,
    INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS,
    RESET_SUBMIT_SAVE_LICENSE_MODAL,

    // selectLicensingData
    SELECT_LICENSING_DATA,

    // batchDelLicenseInfo
    OPEN_DEL_LICENSE_MODAL,
    DEL_LICENSE_CHANGE_INPUT,
    START_BATCH_DEL_LICENSE_INFO,
    BATCH_DEL_LICENSE_INFO_SUCCESS,
    BATCH_DEL_LICENSE_INFO_FAILURE,
    INIT_BATCH_DEL_LICENSE_INFO_ACTION_STATUS,
    RESET_DEL_LICENSE_MODAL,

    // selectCptRcdData
    SELECT_CPT_RCD_DATA,

    // openEditCptRcdModal
    OPEN_EDIT_CPT_RCD_MODAL,

    // editCptRcdModalChangeInput
    EDIT_CPT_RCD_MODAL_CHANGE_INPUT,

    // edit cpt rcd
    START_SUBMIT_EDIT_CPT_RCD,
    SUBMIT_EDIT_CPT_RCD_SUCCESS,
    SUBMIT_EDIT_CPT_RCD_FAILURE,
    INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS,
    RESET_EDIT_CPT_RCD_MODAL,

    // op rcd
    OPEN_OPERATION_RCD_MODAL,
    START_QUERY_OPERATION_RCD,
    QUERY_OPERATION_RCD_SUCCESS,
    QUERY_OPERATION_RCD_FAILURE,
    INIT_QUERY_OPERATION_RCD_ACTION_STATUS,
    RESET_OPERATION_RCD_MODAL,


} from './LicensingConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// onLicensingQueryInputChange
export function onLicensingQueryInputChange({name, value}) {
    return {
        type: ON_LICENSING_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}


// loadLicensingInfo
function startLoadLicensingInfo() {
    return {
      type: START_LOAD_LICENSING_INFO,
    };
}
export function loadLicensingInfo(params) {
      return function(dispatch) {
        dispatch(startLoadLicensingInfo());
        httpClient
          .get(UrlConfig.LOAD_LICENSING_INFO, params)
          .then(resp => dispatch(loadLicensingInfoSuccess(resp)))
          .catch(err => dispatch(loadLicensingInfoFailure(err)));
      };
}
function loadLicensingInfoSuccess(resp) {
    return {
      type: LOAD_LICENSING_INFO_SUCCESS,
      payload: resp,
    };
}
function loadLicensingInfoFailure(err) {
    return {
        type: LOAD_LICENSING_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadLicensingInfoActionStatus() {
    return {
      type: INIT_LOAD_LICENSING_INFO_ACTION_STATUS
    };
}


// add or edit license info
export function openAddOrEditLicenseModal(mode, licenseInfo) {
    return {
        type: OPEN_ADD_OR_EDIT_LICENSE_MODAL,
        payload: {
            mode,
            licenseInfo,
        },
    };
}
export function addOrEditLicenseChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_LICENSE_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}


// loadLicenseBasicInfoOptions
function startLoadLicenseBasicInfoOptions() {
    return {
      type: START_LOAD_LICENSE_BASIC_INFO_OPTIONS,
    };
}
export function loadLicenseBasicInfoOptions(params) {
      return function(dispatch) {
        dispatch(startLoadLicenseBasicInfoOptions());
        httpClient
          .get(UrlConfig.LOAD_LICENSE_BASIC_INFO_OPTIONS, params)
          .then(resp => dispatch(loadLicenseBasicInfoOptionsSuccess(resp)))
          .catch(err => dispatch(loadLicenseBasicInfoOptionsFailure(err)));
      };
}
function loadLicenseBasicInfoOptionsSuccess(resp) {
    return {
      type: LOAD_LICENSE_BASIC_INFO_OPTIONS_SUCCESS,
      payload: resp,
    };
}
function loadLicenseBasicInfoOptionsFailure(err) {
    return {
        type: LOAD_LICENSE_BASIC_INFO_OPTIONS_FAILURE,
        payload: err,
    };
}
export function initLoadLicenseBasicInfoOptionsActionStatus() {
    return {
      type: INIT_LOAD_LICENSE_BASIC_INFO_OPTIONS_ACTION_STATUS,
    };
}


// addOrEditLicenseEmpRequireRcd
function startAddOrEditLicenseEmpRequireRcd() {
    return {
        type: START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
    };
}
export function addOrEditLicenseEmpRequireRcd(params) {
      return function(dispatch) {
        dispatch(startAddOrEditLicenseEmpRequireRcd());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD, params)
          .then(resp => dispatch(addOrEditLicenseEmpRequireRcdSuccess(resp)))
          .catch(err => dispatch(addOrEditLicenseEmpRequireRcdFailure(err)));
      };
}
function addOrEditLicenseEmpRequireRcdSuccess(resp) {
    return {
        type: ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS,
        payload: resp,
    };
}
function addOrEditLicenseEmpRequireRcdFailure(err) {
    return {
        type: ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE,
        payload: err,
    };
}
export function initAddOrEditLicenseEmpRequireRcdActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS,
    };
}
export function resetSubmitSaveLicenseModal() {
    return {
        type: RESET_SUBMIT_SAVE_LICENSE_MODAL,
    };
}


// selectLicensingData
export function selectLicensingData(array) {
    return {
        type: SELECT_LICENSING_DATA,
        payload: array,
    };
}

// batchDelLicenseInfo
export function openDelLicenseModal(mode) {
    return {
        type: OPEN_DEL_LICENSE_MODAL,
        payload: {
            mode,
        },
    };
}
export function delLicenseChangeInput({name, value}) {
    return {
        type: DEL_LICENSE_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

// batchDelLicenseInfo
function startBatchDelLicenseInfo() {
    return {
        type: START_BATCH_DEL_LICENSE_INFO,
    };
}
export function batchDelLicenseInfo(params, comments,mode) {
    const batchDelLicenseInfoVO = {
        forDelList: params,
        comments,
        mode,
    }
    return function(dispatch) {
    dispatch(startBatchDelLicenseInfo());
    httpClient
        .post(UrlConfig.BATCH_DEL_LICENSE_INFO, batchDelLicenseInfoVO)
        .then(resp => dispatch(batchDelLicenseInfoSuccess(resp)))
        .catch(err => dispatch(batchDelLicenseInfoFailure(err)));
    };
}
function batchDelLicenseInfoSuccess(resp) {
    return {
        type: BATCH_DEL_LICENSE_INFO_SUCCESS,
        payload: resp,
    };
}
function batchDelLicenseInfoFailure(err) {
    return {
        type: BATCH_DEL_LICENSE_INFO_FAILURE,
        payload: err,
    };
}
export function initBatchDelLicenseInfoActionStatus() {
    return {
        type: INIT_BATCH_DEL_LICENSE_INFO_ACTION_STATUS,
    };
}
export function resetDelLicenseModal() {
    return {
        type: RESET_DEL_LICENSE_MODAL,
    }; 
}


// selectCptRcdData
export function selectCptRcdData(array) {
    return {
        type: SELECT_CPT_RCD_DATA,
        payload: array,
    };
}


// openEditCptRcdModal
export function openEditCptRcdModal(cptRcd, employee, licenseBasic) {
    return {
        type: OPEN_EDIT_CPT_RCD_MODAL,
        payload: {
            cptRcd,
            employee,
            licenseBasic,
        },
    };
}


// editCptRcdModalChangeInput
export function editCptRcdModalChangeInput({name, value}) {
    return {
        type: EDIT_CPT_RCD_MODAL_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}


// edit cpt rcd
function startSubmitEditCptRcd() {
    return {
        type: START_SUBMIT_EDIT_CPT_RCD,
    };
}
export function submitEditCptRcd(rcd) {
    return function(dispatch) {
        dispatch(startSubmitEditCptRcd());
        httpClient
            .post(UrlConfig.SUBMIT_EDIT_CPT_RCD, rcd)
            .then(resp => dispatch(submitEditCptRcdSuccess(resp)))
            .catch(err => dispatch(submitEditCptRcdFailure(err)));
    };
}
function submitEditCptRcdSuccess(resp) {
    return {
        type: SUBMIT_EDIT_CPT_RCD_SUCCESS,
        payload: resp,
    };
}
function submitEditCptRcdFailure(err) {
    return {
        type: SUBMIT_EDIT_CPT_RCD_FAILURE,
        payload: err,
    };
}
export function initSubmitEditCptRcdActionStatus() {
    return {
        type: INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS,
    };
}
export function resetEditCptRcdModal() {
    return {
        type: RESET_EDIT_CPT_RCD_MODAL,
    }; 
}


// op rcd
export function openOperationRcdModal() {
    return {
        type: OPEN_OPERATION_RCD_MODAL,
    }; 
}
function startQueryOperationRcd() {
    return {
        type: START_QUERY_OPERATION_RCD,
    };
}
export function queryOperationRcd() {
    return function(dispatch) {
        dispatch(startQueryOperationRcd());
        httpClient
            .get(UrlConfig.QUERY_OPERATION_RCD)
            .then(resp => dispatch(queryOperationRcdSuccess(resp)))
            .catch(err => dispatch(queryOperationRcdFailure(err)));
    };
}
function queryOperationRcdSuccess(resp) {
    return {
        type: QUERY_OPERATION_RCD_SUCCESS,
        payload: resp.data,
    };
}
function queryOperationRcdFailure(err) {
    return {
        type: QUERY_OPERATION_RCD_FAILURE,
        payload: err,
    };
}
export function initQueryOperationRcdActionStatus() {
    return {
        type: INIT_QUERY_OPERATION_RCD_ACTION_STATUS,
    };
}
export function resetOperationRcdModal() {
    return {
        type: RESET_OPERATION_RCD_MODAL,
    }; 
}

