import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RestrictedSecurityOperateMode from '../../../../utils/RestrictedSecurityOperateMode';
import GridColumnMap from '../GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { Select } from 'antd';

import {
  // Load Restricted Security 
  START_LOAD_RESTRICTED_SECURITY,
  LOAD_RESTRICTED_SECURITY_SUCCESS,
  LOAD_RESTRICTED_SECURITY_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_ACTION_STATUS,

  // Select Restricted Security 
  SELECT_RESTRICTED_SECURITY,

  // Open/Reset Restricted Security Edit Modal Dialog
  RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_RESET,

  // Restricted Security  Edit Change Input
  RESTRICTED_SECURITY_EDIT_CHANGE_INPUT,

  // Create Restricted Security 
  START_CREATE_RESTRICTED_SECURITY,
  CREATE_RESTRICTED_SECURITY_SUCCESS,
  CREATE_RESTRICTED_SECURITY_FAILURE,
  INIT_CREATE_RESTRICTED_SECURITY_ACTION_STATUS,

  // Update Restricted Security 
  START_UPDATE_RESTRICTED_SECURITY,
  UPDATE_RESTRICTED_SECURITY_SUCCESS,
  UPDATE_RESTRICTED_SECURITY_FAILURE,
  INIT_UPDATE_RESTRICTED_SECURITY_ACTION_STATUS,

  // Batch Delete Restricted Security 
  START_BATCH_DELETE_RESTRICTED_SECURITY,
  BATCH_DELETE_RESTRICTED_SECURITY_SUCCESS,
  BATCH_DELETE_RESTRICTED_SECURITY_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_SECURITY_ACTION_STATUS,

  // Load Restricted Security BBG Tickers
  START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS,

  // Clear Restricted Security BBG Ticker
  CLEAR_RESTRICTED_SECURITY_BBG_TICKERS,

  // Restricted Security BBG Ticker Select Changed
  RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED,

  // Restricted Security Fund Code Select Changed
  RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED,

  // Restricted Security Book Code Select Changed
  RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED,

  // Open/Reset Restricted Security Operate Modal Dialog
  RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_RESET,

  // On List Restricted Security 
  START_ON_LIST_RESTRICTED_SECURITY,
  ON_LIST_RESTRICTED_SECURITY_SUCCESS,
  ON_LIST_RESTRICTED_SECURITY_FAILURE,
  INIT_ON_LIST_RESTRICTED_SECURITY_ACTION_STATUS,

  // Off List Restricted Security 
  START_OFF_LIST_RESTRICTED_SECURITY,
  OFF_LIST_RESTRICTED_SECURITY_SUCCESS,
  OFF_LIST_RESTRICTED_SECURITY_FAILURE,
  INIT_OFF_LIST_RESTRICTED_SECURITY_ACTION_STATUS,

  // Show Valid Restricted Securities
  SHOW_VALID_RESTRICTED_SECURITIES,

  // Show All Restricted Securities
  SHOW_ALL_RESTRICTED_SECURITIES,

  // Open/Reset Related Company Price Modal Dialog
  RELATED_COMPANY_PRICE_MODAL_DIALOG_OPEN,
  RELATED_COMPANY_PRICE_MODAL_DIALOG_RESET,

  // Generate Related Company Price Report
  START_GENERATE_RELATED_COMPANY_PRICE_REPORT,
  GENERATE_RELATED_COMPANY_PRICE_REPORT_SUCCESS,
  GENERATE_RELATED_COMPANY_PRICE_REPORT_FAILURE,
  INIT_GENERATE_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS,

  // Load Related Company Price Report
  START_LOAD_RELATED_COMPANY_PRICE_REPORT,
  LOAD_RELATED_COMPANY_PRICE_REPORT_SUCCESS,
  LOAD_RELATED_COMPANY_PRICE_REPORT_FAILURE,
  INIT_LOAD_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS,

} from './restrictedSecurityConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from '../../home/homeConstants';
import moment from 'moment';

const Option = Select.Option;

const initialState = {
  // Grdi Columns
  restrictedSecurityGridColumns: GridColumnMap.restrictedSecurityGridColumns,
  relatedCompPriceReportGridColumns: GridColumnMap.relatedCompPriceReportGridColumns,

  // Restricted Security 
  restrictedSecurityArray: [],
  filterRestrictedSecurityArray: [],

  // Selected Restricted Security 
  selectedRestrictedSecurityArray: [],

  // Restricted Security Edit Modal
  restrictedSecurityEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      guid: '',
      restrictionType: '',
      fund: '',
      book: '',
      personInCharge: '',
      securityId: '',
      bbgTicker: '',
      secName: '',
      validFrom: moment().format("YYYY-MM-DD HH:mm"),
      validTo: '9999-12-31 00:00',
      onListReason: '',
      offListReason: '',
      relatedCompany: '',
      attachment: '',
      attachmentOriginName: '',
      restrictedSecReqGuid: '',
      requestBy: '',
      comments: '',
      fileList: [],
    }
  },

  // load/create/update/delete Restricted Security  
  loadRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  createRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  updateRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  batchDeleteRestrictedSecActionStatus: ACTION_STATUS.READY,

  // Security Ticker Data
  securityTickerData: null,
  bbgTickerArray: [],
  bbgTickerOptions: [],
  securityTickerArray: [],

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // Load User Account
  userAccountArray: [],
  userAccountOptions: [],

  // Load Restricted Security BBG Tickers Action Status
  loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.READY,

  // Restricted Security Operate Modal Dialog
  restrictedSecurityOperateControlModal: {
    isOpened: false,
    operateMode: RestrictedSecurityOperateMode.DELETE,
    restrictedSecGuidList: [],
    respErrMsg: '',
  },

  // On/Off List Restricted Security Action Status
  onListRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  offListRestrictedSecurityActionStatus: ACTION_STATUS.READY,

  // 附件操作URL
  restrictedSecAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_RESTRICTED_SECURITY_ATTACHMENT,
  restrictedSecAttachmentPreviewUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PREVIEW_RESTRICTED_SECURITY_ATTACHMENT,
  restrictedSecAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT,

  // Related Company Price Modal Dialog
  relatedCompanyPriceControlModal: {
    isOpened: false,
  },

  // Generate Related Company Price Report
  generateRelatedCompanyPriceReportActionStatus: ACTION_STATUS.READY,
  generateRelatedCompanyPriceReportErrMsg: '',

  // Load Related Company Price Report
  loadRelatedCompanyPriceReportActionStatus: ACTION_STATUS.READY,
  loadRelatedCompanyPriceReportErrMsg: '',
  relatedCompanyPriceReportArray: [],
} 

// Load Restricted Security 
function startLoadRestrictedSecurity(state) {
  return {
    ...state,
    loadRestrictedSecurityActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    loadRestrictedSecurityActionStatus: ACTION_STATUS.SUCCESS,
    restrictedSecurityArray: resp.data,
    filterRestrictedSecurityArray: resp.data,
    selectedRestrictedSecurityArray: [],
  };
}

function loadRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    loadRestrictedSecurityActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    loadRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  };
}

// Select Restricted Security 
function selectRestrictedSecurity(state, payload) {
  return {
    ...state,
    selectedRestrictedSecurityArray: payload,
  };
}

// Open/Reset Restricted Security  Modal Dialog
function openRestrictedSecurityEditModalDialog(state, {isAddMode, restrictedSecurity}) {
  if (isAddMode) {
    return {
      ...state,
      restrictedSecurityEditControlModal: {
        ...state.restrictedSecurityEditControlModal,
        isOpened: true,
        isAddMode,
      },
    };
  } else {
    const restrictedSecAttachmentDownloadUrl = state.restrictedSecAttachmentDownloadUrl;

    const fileList = [];
    const attachment = restrictedSecurity.attachment;
    const attachmentOriginName = restrictedSecurity.attachmentOriginName;

    if (attachment && attachmentOriginName) {
      const file = {
        uid: '-1',
        name: attachmentOriginName,
        status: 'done',
        url: restrictedSecAttachmentDownloadUrl + "/" + attachment,
        response: {
          respCode: "S0001",
          data: {
            attachment,
            attachmentOriginName,
          }
        }
      };
      fileList.push(file);
    }

    return {
      ...state,
      restrictedSecurityEditControlModal: {
        ...state.restrictedSecurityEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedSecurityEditControlModal.fields,
          guid: restrictedSecurity.guid,
          restrictionType: restrictedSecurity.restrictionType,
          fund: restrictedSecurity.fund,
          book: restrictedSecurity.book,
          personInCharge: restrictedSecurity.personInCharge,
          securityId: restrictedSecurity.securityId,
          bbgTicker: restrictedSecurity.bbgTicker,
          secName: restrictedSecurity.secName,
          validFrom: restrictedSecurity.validFrom,
          validTo: restrictedSecurity.validTo,
          onListReason: restrictedSecurity.onListReason,
          offListReason: restrictedSecurity.offListReason,
          relatedCompany: restrictedSecurity.relatedCompany,
          attachment: restrictedSecurity.attachment,
          attachmentOriginName: restrictedSecurity.attachmentOriginName,
          restrictedSecReqGuid: restrictedSecurity.restrictedSecReqGuid,
          requestBy: restrictedSecurity.requestBy,
          fileList,
        }
      },
    };
  }
}

function resetRestrictedSecurityEditModalDialog(state) {
  return {
    ...state,
    restrictedSecurityEditControlModal: initialState.restrictedSecurityEditControlModal,
  };
}

// Restricted Security Edit Change Input
function restrictedSecurityEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedSecurityEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      fields: updatedFields,
    },
  };
}

// =============================================================
// Create Restricted Security 
function startCreateRestrictedSecurity(state) {
  return {
    ...state,
    createRestrictedSecurityActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    createRestrictedSecurityActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityArray: [],
  };
}

function createRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    createRestrictedSecurityActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initCreateRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    createRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  };
}

// Update Restricted Security 
function startUpdateRestrictedSecurity(state) {
  return {
    ...state,
    updateRestrictedSecurityActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    updateRestrictedSecurityActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityArray: [],
  };
}

function updateRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    updateRestrictedSecurityActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initUpdateRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    updateRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Restricted Security 
function startBatchDeleteRestrictedSecurity(state) {
  return {
    ...state,
    batchDeleteRestrictedSecActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    batchDeleteRestrictedSecActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityArray: [],
  };
}

function batchDeleteRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    batchDeleteRestrictedSecActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    batchDeleteRestrictedSecActionStatus: ACTION_STATUS.READY,
  };
}

// =================================================================
// Load Restricted Security BBG Tickers
function startLoadRestrictedSecurityBbgTickers(state) {
  return {
    ...state,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSecurityBbgTickersSuccess(state, resp) {
  const bbgTickerArray = resp.data;

  const bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);
  
  return {
    ...state,
    bbgTickerArray,
    bbgTickerOptions,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedSecurityBbgTickersFailure(state, err) {
  return {
    ...state,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSecurityBbgTickersActionStatus(state) {
  return {
    ...state,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.READY,
  };
}

// Clear Restricted Security bbgTickerArray bbgTickerOptions
function clearRestrictedSecurityBbgTickers(state) {
  return {
    ...state,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      fields: {
        ...state.restrictedSecurityEditControlModal.fields,
        secName: '',
      },
    },
    bbgTickerArray: [],
    bbgTickerOptions: [],
  };
}

// Restricted Security BBG Ticker Select Changed
function restrictedSecurityBbgTickerSelectChanged(state, bbgTicker) {
  const securityTickerArray = state.securityTickerArray;

  let securityName = '';
  let securityId = '';
  if (!!bbgTicker) {
    const filterSecurityTickerArray = securityTickerArray.filter(securityTicker => securityTicker.bbgTicker === bbgTicker);

    if (filterSecurityTickerArray.length > 0) {
      const securityTicker = filterSecurityTickerArray[0];
      securityName = securityTicker.securityName;
      securityId = securityTicker.securityId;
    }
  }

  const fields = state.restrictedSecurityEditControlModal.fields;

  securityName = securityName === '' ? fields.secName : securityName;

  return {
    ...state,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      fields: {
        ...state.restrictedSecurityEditControlModal.fields,
        secName: securityName,
        securityId,
      },
    },
  };
}

// Restricted Security Fund Code Select Changed
function restrictedSecurityFundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedSecurityEditControlModal.fields.book;

  let personInCharge = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      personInCharge = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      fields: {
        ...state.restrictedSecurityEditControlModal.fields,
        personInCharge,
      },
    },
  };
}

// Restricted Security Book Code Select Changed
function restrictedSecurityBookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedSecurityEditControlModal.fields.fund;

  let personInCharge = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      personInCharge = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSecurityEditControlModal: {
      ...state.restrictedSecurityEditControlModal,
      fields: {
        ...state.restrictedSecurityEditControlModal.fields,
        personInCharge,
      },
    },
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  // let bbgTickerArray = securityTickerData.bbgTickerList;
  let securityTickerArray = securityTickerData.securityTickerList;

  // let bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);

  return {
    ...state,
    securityTickerData,
    // bbgTickerArray,
    securityTickerArray,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}

// Open/Reset Restricted Security Operate Modal Dialog
function openRestrictedSecurityOperateModalDialog(state, {operateMode, restrictedSecGuidList}) {
  return {
    ...state,
    restrictedSecurityOperateControlModal: {
      ...state.restrictedSecurityOperateControlModal,
      isOpened: true,
      operateMode,
      restrictedSecGuidList,
    },
  };
}

function resetRestrictedSecurityOperateModalDialog(state) {
  return {
    ...state,
    restrictedSecurityOperateControlModal: initialState.restrictedSecurityOperateControlModal,
  };
}

// On List Restricted Security 
function startOnListRestrictedSecurity(state) {
  return {
    ...state,
    onListRestrictedSecurityActionStatus: ACTION_STATUS.LOGINING,
  };
}

function onListRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    onListRestrictedSecurityActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function onListRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    onListRestrictedSecurityActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityOperateControlModal: {
      ...state.restrictedSecurityOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initOnListRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    onListRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  };
}

// Off List Restricted Security 
function startOffListRestrictedSecurity(state) {
  return {
    ...state,
    offListRestrictedSecurityActionStatus: ACTION_STATUS.LOGINING,
  };
}

function offListRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    offListRestrictedSecurityActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function offListRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    offListRestrictedSecurityActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityOperateControlModal: {
      ...state.restrictedSecurityOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initOffListRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    offListRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  };
}

// Show Valid Restricted Securities
function showValidRestrictedSecurities(state) {
  const restrictedSecurityArray = state.restrictedSecurityArray;
  const filterRestrictedSecurityArray = restrictedSecurityArray.filter(restrictedRule => restrictedRule.isValid);

  return {
    ...state,
    filterRestrictedSecurityArray,
  };
}

// Show All Restricted Securities
function showAllRestrictedSecurities(state) {
  const restrictedSecurityArray = state.restrictedSecurityArray;
  const filterRestrictedSecurityArray = restrictedSecurityArray;

  return {
    ...state,
    filterRestrictedSecurityArray,
  };
}

// Open/Reset Related Company Price Modal Dialog
function openRelatedCompanyPriceModalDialog(state) {
  return {
    ...state,
    relatedCompanyPriceControlModal: {
      ...state.relatedCompanyPriceControlModal,
      isOpened: true,
    },
  };
}

function resetRelatedCompanyPriceModalDialog(state) {
  return {
    ...state,
    relatedCompanyPriceControlModal: initialState.relatedCompanyPriceControlModal,
  };
}

// Generate Related Company Price Report
function startGenerateRelatedCompanyPriceReport(state) {
  return {
    ...state,
    generateRelatedCompanyPriceReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function generateRelatedCompanyPriceReportSuccess(state, resp) {
  return {
    ...state,
    generateRelatedCompanyPriceReportActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function generateRelatedCompanyPriceReportFailure(state, err) {
  return {
    ...state,
    generateRelatedCompanyPriceReportActionStatus: ACTION_STATUS.ERROR,
    generateRelatedCompanyPriceReportErrMsg: err.respMessage
  };
}

function initGenerateRelatedCompanyPriceReportActionStatus(state) {
  return {
    ...state,
    generateRelatedCompanyPriceReportActionStatus: ACTION_STATUS.READY,
  };
}

// Load Related Company Price Report
function startLoadRelatedCompanyPriceReport(state) {
  return {
    ...state,
    loadRelatedCompanyPriceReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRelatedCompanyPriceReportSuccess(state, resp) {
  return {
    ...state,
    loadRelatedCompanyPriceReportActionStatus: ACTION_STATUS.SUCCESS,
    relatedCompanyPriceReportArray: resp.data,
  };
}

function loadRelatedCompanyPriceReportFailure(state, err) {
  return {
    ...state,
    loadRelatedCompanyPriceReportActionStatus: ACTION_STATUS.ERROR,
    loadRelatedCompanyPriceReportErrMsg: err.respMessage
  };
}

function initLoadRelatedCompanyPriceReportActionStatus(state) {
  return {
    ...state,
    loadRelatedCompanyPriceReportActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Restricted Security 
  [START_LOAD_RESTRICTED_SECURITY]: startLoadRestrictedSecurity,
  [LOAD_RESTRICTED_SECURITY_SUCCESS]: loadRestrictedSecuritySuccess,
  [LOAD_RESTRICTED_SECURITY_FAILURE]: loadRestrictedSecurityFailure,
  [INIT_LOAD_RESTRICTED_SECURITY_ACTION_STATUS]: initLoadRestrictedSecurityActionStatus,

  // Select Restricted Security 
  [SELECT_RESTRICTED_SECURITY]: selectRestrictedSecurity,

  // Open/Reset Restricted Security Edit Modal Dialog
  [RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_OPEN]: openRestrictedSecurityEditModalDialog,
  [RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_RESET]: resetRestrictedSecurityEditModalDialog,

  // Restricted Security Edit Change Input
  [RESTRICTED_SECURITY_EDIT_CHANGE_INPUT]: restrictedSecurityEditChangeInput,

  // Create Restricted Security 
  [START_CREATE_RESTRICTED_SECURITY]: startCreateRestrictedSecurity,
  [CREATE_RESTRICTED_SECURITY_SUCCESS]: createRestrictedSecuritySuccess,
  [CREATE_RESTRICTED_SECURITY_FAILURE]: createRestrictedSecurityFailure,
  [INIT_CREATE_RESTRICTED_SECURITY_ACTION_STATUS]: initCreateRestrictedSecurityActionStatus,

  // Update Restricted Security 
  [START_UPDATE_RESTRICTED_SECURITY]: startUpdateRestrictedSecurity,
  [UPDATE_RESTRICTED_SECURITY_SUCCESS]: updateRestrictedSecuritySuccess,
  [UPDATE_RESTRICTED_SECURITY_FAILURE]: updateRestrictedSecurityFailure,
  [INIT_UPDATE_RESTRICTED_SECURITY_ACTION_STATUS]: initUpdateRestrictedSecurityActionStatus,

  // Batch Delete Restricted Security 
  [START_BATCH_DELETE_RESTRICTED_SECURITY]: startBatchDeleteRestrictedSecurity,
  [BATCH_DELETE_RESTRICTED_SECURITY_SUCCESS]: batchDeleteRestrictedSecuritySuccess,
  [BATCH_DELETE_RESTRICTED_SECURITY_FAILURE]: batchDeleteRestrictedSecurityFailure,
  [INIT_BATCH_DELETE_RESTRICTED_SECURITY_ACTION_STATUS]: initBatchDeleteRestrictedSecurityActionStatus,

  // Load Restricted Security BBG Tickers
  [START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS]: startLoadRestrictedSecurityBbgTickers,
  [LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS]: loadRestrictedSecurityBbgTickersSuccess,
  [LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE]: loadRestrictedSecurityBbgTickersFailure,
  [INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS]: initLoadRestrictedSecurityBbgTickersActionStatus,

  // Clear Restricted Security BBG Ticker
  [CLEAR_RESTRICTED_SECURITY_BBG_TICKERS]: clearRestrictedSecurityBbgTickers,

  // Restricted Security BBG Ticker Select Changed
  [RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED]: restrictedSecurityBbgTickerSelectChanged,

  // Restricted Security Fund Code Select Changed
  [RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED]: restrictedSecurityFundCodeSelectChanged,

  // Restricted Security Book Code Select Changed
  [RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED]: restrictedSecurityBookCodeSelectChanged,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  // Open/Reset Restricted Security Operate Modal Dialog
  [RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_OPEN]: openRestrictedSecurityOperateModalDialog,
  [RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_RESET]: resetRestrictedSecurityOperateModalDialog,

  // On List Restricted Security 
  [START_ON_LIST_RESTRICTED_SECURITY]: startOnListRestrictedSecurity,
  [ON_LIST_RESTRICTED_SECURITY_SUCCESS]: onListRestrictedSecuritySuccess,
  [ON_LIST_RESTRICTED_SECURITY_FAILURE]: onListRestrictedSecurityFailure,
  [INIT_ON_LIST_RESTRICTED_SECURITY_ACTION_STATUS]: initOnListRestrictedSecurityActionStatus,


  // Off List Restricted Security 
  [START_OFF_LIST_RESTRICTED_SECURITY]: startOffListRestrictedSecurity,
  [OFF_LIST_RESTRICTED_SECURITY_SUCCESS]: offListRestrictedSecuritySuccess,
  [OFF_LIST_RESTRICTED_SECURITY_FAILURE]: offListRestrictedSecurityFailure,
  [INIT_OFF_LIST_RESTRICTED_SECURITY_ACTION_STATUS]: initOffListRestrictedSecurityActionStatus,

  // Show Valid Restricted Securities
  [SHOW_VALID_RESTRICTED_SECURITIES]: showValidRestrictedSecurities,

  // Show All Restricted Securities
  [SHOW_ALL_RESTRICTED_SECURITIES]: showAllRestrictedSecurities,

  // Open/Reset Related Company Price Modal Dialog
  [RELATED_COMPANY_PRICE_MODAL_DIALOG_OPEN]: openRelatedCompanyPriceModalDialog,
  [RELATED_COMPANY_PRICE_MODAL_DIALOG_RESET]: resetRelatedCompanyPriceModalDialog,

  // Generate Related Company Price Report
  [START_GENERATE_RELATED_COMPANY_PRICE_REPORT]: startGenerateRelatedCompanyPriceReport,
  [GENERATE_RELATED_COMPANY_PRICE_REPORT_SUCCESS]: generateRelatedCompanyPriceReportSuccess,
  [GENERATE_RELATED_COMPANY_PRICE_REPORT_FAILURE]: generateRelatedCompanyPriceReportFailure,
  [INIT_GENERATE_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS]: initGenerateRelatedCompanyPriceReportActionStatus,

  // Load Related Company Price Report
  [START_LOAD_RELATED_COMPANY_PRICE_REPORT]: startLoadRelatedCompanyPriceReport,
  [LOAD_RELATED_COMPANY_PRICE_REPORT_SUCCESS]: loadRelatedCompanyPriceReportSuccess,
  [LOAD_RELATED_COMPANY_PRICE_REPORT_FAILURE]: loadRelatedCompanyPriceReportFailure,
  [INIT_LOAD_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS]: initLoadRelatedCompanyPriceReportActionStatus,

});