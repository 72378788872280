export const monthlyReportGridColumns = [
    {
        field: 'id',
        headerName: "Id",
        cellClass: 'non-number',
        width: 80,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: 'left',
        cellRenderer: 'agGroupCellRenderer',
    },
    {
        field: 'month',
        headerName: "Month",
        cellClass: 'non-number',
        width: 90,
    },
    {
        field: 'createdBy',
        headerName: "Created By",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'createdDate',
        headerName: "Created Date",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'lastModifiedDate',
        headerName: "Last Updated",
        cellClass: 'non-number',
        width: 150,
    },
    // {
    //   field: 'attachment',
    //   headerName: 'Attachment',
    //   cellRenderer: "monthlyReportAttachRenderer",
    //   cellClass: 'non-number',
    //   width: 150,
    // },
];