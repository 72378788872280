import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Breadcrumb,Button, message,Input,Select,DatePicker} from 'antd';
import AddOrEditRestrictedRuleDialog from './AddOrEditRestrictedRuleDialog'
import { Message } from 'semantic-ui-react'
import OperationRecordDetail from '../OperationRecordDetail';
import MonthlyReviewDetailPanel from './MonthlyReviewDetailPanel'
import AddOrEditMeetingAndTrainingCalenderModalDialog from '../AddOrEditMeetingAndTrainingCalenderModalDialog';
class RestrictedSecurityPinPointGrid extends Component {

  constructor(props) {
    super(props);
    const that = this;
    this.state = {
      // columnDefs: props.restrictedSecurityRuleGridColumns,
      columnDefs: [
        {
          field: 'id',
          headerName: "Id",
          cellClass: 'non-number',
          width: 80,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          pinned: 'left',
          cellRenderer: 'agGroupCellRenderer',
        },
        {
          field: 'username',
          headerName: "Name*",
          cellClass: 'non-number',
          width: 150,
          // cellRenderer: 'agGroupCellRenderer',
        },
        {
          field: 'subject',
          headerName: "Subject*",
          cellClass: 'non-number',
          width: 300,
          // cellRenderer: 'agGroupCellRenderer',
        },
        {
          field: 'broker',
          headerName: "Broker*",
          cellClass: 'non-number',
          width: 100,
         
      },

      {
        field: 'type',
        headerName: "Type",
        cellClass: 'non-number',
        width: 150,
      },
      {
        field: 'note',
        headerName: "Note",
        cellClass: 'non-number',
        width: 150,
      },
      {
        field: 'researchdepth',
        headerName: "Research Depth",
        cellClass: 'non-number',
        width: 150,
      },
      {
        field: 'Stockpitch',
        headerName: "Stock Pitch",
        cellClass: 'non-number',
        width: 150,
      },
      {
        field: 'start',
        headerName: "Start Time",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'end',
        headerName: "End Time",
        cellClass: 'non-number',
        width: 150,
    },
      
            
      ],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "single",
      rowClassRules: {
        'MarkBackgroundRede': function(params) {
            // if(params.data.status === 'New') {
                return params.data.required === 'M' && params.data.progress.split('/')[0]==='0';
            // } alertFlag  limitFlag  1 表示出警

        },



        
        
      },
      context: { componentParent: this },
      frameworkComponents: {
      
        myDetailCellRenderer: MonthlyReviewDetailPanel,
      },
      detailCellRenderer: 'myDetailCellRenderer',
      selectedRow: null,
    };
  }


  sendHistoryAttachementCellRender = (node) => {
    const attachement = node.data.filepath;
    if(!attachement) {
        return '';
    }
    const attArray = attachement.split(';');
    const retElement = document.createElement('div');
    let first = true;
    attArray.forEach(e => {
        if(!first) {
            retElement.append(' | ');
        }
        first = false;

        const url = this.props.meetingAndTrainingCalenderDownloadUrl + '/' + e;
        const link = document.createElement('a');
        link.innerText = e;
        link.target = '_blank';
        link.href = url;
        retElement.appendChild(link);

    });
    return retElement;
}
  componentDidMount(){
    const {
      title,
      participant,
      state
      
      
  } = this.props.meetingAndTrainingCalenderQueryFields;
  const person = participant;
  this.props.loadRestrictedPinPointList({title,person,state})

  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  refreshData = () => {
    const {
      subject,
      broker,
      start,
      end,
      
      
  } = this.props.meetingAndTrainingCalenderQueryFields;

  this.props.loadRestrictedPersonalList({subject,broker,start,end})

  }

  addBtnClick = () => {
    this.props.openRestrictedRuleDialog('NEW', {
      isFundBookMatched: 0
    });
  }

  updateBtnClick = () => {
    const {
      selectedRow
    } = this.state;
    if(selectedRow){
      this.props.openRestrictedRuleDialog('EDIT', selectedRow);
    }
  }
  onDataSelectionChanged = (event) => {
    let rowNodeArray = event.api.getSelectedNodes();
    let meetingAndTrainingCalenderInfoArray = rowNodeArray.map(rowNode => rowNode.data);
    this.props.selectMeetingAndTrainingCalenderInfo(meetingAndTrainingCalenderInfoArray);
  }

  getRScore =(array) =>{
    if(array.length>0){
    let rArray = array.filter(r=>r.required==='M').reduce(function(prev, cur) {
      return parseInt(cur.score) + prev;
      }, 0);
      return rArray;
    }else{
      return 0;
    }
  
  };

  getOScore =(array) =>{
    if(array.length>0){
      let rArray = array.filter(r=>r.required==='O').reduce(function(prev, cur) {
        return parseInt(cur.score) + prev;
        }, 0);
        return rArray;
      }else{
        return 0;
      }
};
  onInputChange = ({ name, value }) => {
    this.props.onQueryInputChange({ name, value });
};

addOrEditBtnOnClicked = (mode) => {
  if(mode === 'sycn event') {
    message.warning("Please Try again later");
    return;
  }
  if(mode === 'ADD') {
      this.props.openAddOrEditMeetingAndTrainingCalenderModal(mode, null);
  } else {
      const {
          selectedMeetingAndTrainingCalenderInfoArray,
      } = this.props;
      if(selectedMeetingAndTrainingCalenderInfoArray.length !== 1) {
          message.warning("Please choose one and only one User Access Info");
          return;
      }
      const currentMeetingAndTrainingCalenderInfo = selectedMeetingAndTrainingCalenderInfoArray[0];
      this.props.openAddOrEditMeetingAndTrainingCalenderModal(mode, currentMeetingAndTrainingCalenderInfo);
  }
}

  render() {
    const {
      restrictedPersonalList,
      typeOptions,
      brokerOptions,
      userAccountOptions,
      className = 'grid-wrapper'
    } = this.props;
    const {

      participant,

    } = this.props.meetingAndTrainingCalenderQueryFields;
    const name = participant;
    
    const isShow = name !== null && name !== '';

    return (
      <div className='MeetingAndTrainingCalenderGrid'>
        <div className='operationBar'>
          <div className='breadcrumbWrapper'>   
            <Breadcrumb>
            <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Select
                allowClear
                showSearch
                style={{ width: 200, marginLeft: 10 }}
                onChange={(val) => {
                    this.onInputChange({ name: 'name', value: val })
                }} 
                placeholder="Select participant">
            {userAccountOptions}
        </Select>
        <Input 
              allowClear={true}
              placeholder="Subject"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'subject', value: event.target.value })
              }} 
          />
          <Select
              allowClear
              showSearch
              size='small'
              style={{ width: 150, marginLeft: 10 }}
              onChange={(val) => {
                  this.onInputChange({ name: 'broker', value: val })
              }} 
              placeholder="broker">
              {brokerOptions}
          </Select>

          <DatePicker 
            allowClear={true}
            size='small'
            style={{ width: 150, marginLeft: 10 }}
            placeholder=" Date Start"
            onChange={(startTime, dateString) => {
              this.onInputChange({ name: 'start', value: dateString });
            }}
          />
          <DatePicker 
            allowClear={true}
            size='small'
            style={{ width: 150, marginLeft: 10 }}
            placeholder="Date End"
            onChange={(entTime, dateString) => {
              this.onInputChange({ name: 'ent', value: dateString });
            }}
          /> 
          
          <Button 
              onClick={this.refreshData} 
              size='small'
              style={{ marginLeft: 10 }}
              type='primary'
              icon="search" 
          />

          {/* <Button 
            onClick={this.addBtnClick} 
            size='small'
            className='positive-button'>
            Meeting Record
          </Button> */}
          <Button 
              onClick={()=>this.addOrEditBtnOnClicked('sycn event')} 
              size='small'
              style={{ fontSize: 10, marginLeft: 10 }}
              className='positive-button'>
              sycn event
          </Button>
          <Button 
              onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
              size='small'
              style={{ fontSize: 10, marginLeft: 10 }}
              className='positive-button'>
              Meeting Record
          </Button>
        </div>
        <div className={`ag-theme-balham ${className}`} style={{height: '85%'}}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={restrictedPersonalList}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            enableRangeSelection={true}
            statusBar={this.state.statusBar}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            masterDetail={true}
            detailCellRenderer={this.state.detailCellRenderer}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onDataSelectionChanged}
            masterDetail={true}
          />
        </div> 
        <OperationRecordDetail {...this.props} /> 
        <AddOrEditRestrictedRuleDialog {...this.props} />   
        <AddOrEditMeetingAndTrainingCalenderModalDialog {...this.props} />
      </div>
    );
  }
}

export default RestrictedSecurityPinPointGrid;