import {connect} from 'react-redux';
import BannedTradeRequest from '../components/BannedTradeRequest';

import {
  // Load Banned Trade Request
  loadBannedTradeRequest,
  initLoadBannedTradeRequestActionStatus,

  // Select Banned Trade Request
  selectBannedTradeRequest,

  // Open/Reset Banned Trade Request Modal Dialog
  openBannedTradeRequestEditModalDialog,
  resetBannedTradeRequestEditModalDialog,

  // Banned Trade Request Edit Change Input
  bannedTradeRequestEditChangeInput,

  // Approve Banned Trade Request
  approveBannedTradeRequest,
  initApproveBannedTradeRequestActionStatus,

  // Reject Banned Trade Request
  rejectBannedTradeRequest,
  initRejectBannedTradeRequestActionStatus,

  // Feedback Banned Trade Request
  feedbackBannedTradeRequest,
  initFeedbackBannedTradeRequestActionStatus,

  // Update Banned Trade Request
  updateBannedTradeRequest,
  initUpdateBannedTradeRequestActionStatus,

} from '../bannedTradeRequestActions';

const mapStateToProps = state => {
  return {
    bannedTradeRequestGridColumns: state.bannedTradeRequest.bannedTradeRequestGridColumns,

    // Banned Trade Request
    bannedTradeRequestArray: state.bannedTradeRequest.bannedTradeRequestArray,

    bannedTradeRequestEditControlModal: state.bannedTradeRequest.bannedTradeRequestEditControlModal,
  
    // Selected Banned Trade Request
    selectedBannedTradeRequestArray: state.bannedTradeRequest.selectedBannedTradeRequestArray,

    // Load Banned Trade Request Action Status
    loadBannedTradeRequestActionStatus: state.bannedTradeRequest.loadBannedTradeRequestActionStatus,

    // Approve Banned Trade Request Action Status
    approveBannedTradeRequestActionStatus: state.bannedTradeRequest.approveBannedTradeRequestActionStatus,
  
    // Reject Banned Trade Request Action Status
    rejectBannedTradeRequestActionStatus: state.bannedTradeRequest.rejectBannedTradeRequestActionStatus,
  
    // Feedback Banned Trade Request Action Status
    feedbackBannedTradeRequestActionStatus: state.bannedTradeRequest.feedbackBannedTradeRequestActionStatus,

    // Update Banned Trade Request Action Status
    updateBannedTradeRequestActionStatus: state.bannedTradeRequest.updateBannedTradeRequestActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Banned Trade Request
    loadBannedTradeRequest: () => dispatch(loadBannedTradeRequest()),
    initLoadBannedTradeRequestActionStatus: () => dispatch(initLoadBannedTradeRequestActionStatus()),

    // Select Banned Trade Request
    selectBannedTradeRequest: (payload) => dispatch(selectBannedTradeRequest(payload)),

    // Open/Reset Banned Trade Request Modal Dialog
    openBannedTradeRequestEditModalDialog: (bannedTradeRequest) => dispatch(openBannedTradeRequestEditModalDialog(bannedTradeRequest)),
    resetBannedTradeRequestEditModalDialog: () => dispatch(resetBannedTradeRequestEditModalDialog()),

    // Banned Trade Request Edit Change Input
    bannedTradeRequestEditChangeInput: (input) => dispatch(bannedTradeRequestEditChangeInput(input)),

    // Approve Banned Trade Request
    approveBannedTradeRequest: (id) => dispatch(approveBannedTradeRequest(id)),
    initApproveBannedTradeRequestActionStatus: () => dispatch(initApproveBannedTradeRequestActionStatus()),

    // Reject Banned Trade Request
    rejectBannedTradeRequest: (id) => dispatch(rejectBannedTradeRequest(id)),
    initRejectBannedTradeRequestActionStatus: () => dispatch(initRejectBannedTradeRequestActionStatus()),

    // Feedback Banned Trade Request
    feedbackBannedTradeRequest: (id, feedback) => dispatch(feedbackBannedTradeRequest(id, feedback)),
    initFeedbackBannedTradeRequestActionStatus: () => dispatch(initFeedbackBannedTradeRequestActionStatus()),

    // Update Banned Trade Request
    updateBannedTradeRequest: (bannedTradeRequest) => dispatch(updateBannedTradeRequest(bannedTradeRequest)),
    initUpdateBannedTradeRequestActionStatus: () => dispatch(initUpdateBannedTradeRequestActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BannedTradeRequest);