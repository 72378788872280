import {connect} from 'react-redux';
import ShortPositionReportDashboard from '../components/ShortPositionReportDashboard';

import {
  // Generate Short Position Report
  generateShortPositionReport,
  initGenerateShortPositionReportActionStatus,

  // Load Short Position Report
  loadShortPositionReport,
  initLoadShortPositionReportActionStatus,

  // Open/Reset Short Position Report Check Result Modal Dialog
  openShortPositionReportCheckResultModalDialog,
  resetShortPositionReportCheckResultModalDialog,

  // Load Short Position Report Check Result
  loadShortPositionReportCheckResult,
  initLoadShortPositionReportCheckResultActionStatus,

  // Report Short Position Report
  reportShortPositionReport,
  initReportShortPositionReportActionStatus,

} from '../shortPositionReportActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    shortPositionReportGridColumns: state.shortPositionReport.shortPositionReportGridColumns,
    shortPosReportChkResultGridColumns: state.shortPositionReport.shortPosReportChkResultGridColumns,

    // Short Position Report
    shortPositionReportArray: state.shortPositionReport.shortPositionReportArray,

    // Generate Short Position Report Action Status
    generateShortPositionReportActionStatus: state.shortPositionReport.generateShortPositionReportActionStatus,
    generateShortPositionReportErrMsg: state.shortPositionReport.generateShortPositionReportErrMsg,

    // Load Short Position Report Action Status
    loadShortPositionReportActionStatus: state.shortPositionReport.loadShortPositionReportActionStatus,
    loadShortPositionReportErrMsg: state.shortPositionReport.loadShortPositionReportErrMsg,

    // Short Position Report Check Result Modal Dialog
    shortPositionReportCheckResultControlModal: state.shortPositionReport.shortPositionReportCheckResultControlModal,

    // Short Position Report Check Result
    shortPosReportChkResult: state.shortPositionReport.shortPosReportChkResult,

    // Load Short Position Report Check Result Action Status
    loadShortPositionReportCheckResultActionStatus: state.shortPositionReport.loadShortPositionReportCheckResultActionStatus,
    loadShortPositionReportCheckResultErrMsg: state.shortPositionReport.loadShortPositionReportCheckResultErrMsg,

    // Report Short Position Report
    reportShortPositionReportActionStatus: state.shortPositionReport.reportShortPositionReportActionStatus,
    reportFileArray: state.shortPositionReport.reportFileArray,
    reportShortPositionReportErrMsg: state.shortPositionReport.reportShortPositionReportErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Generate Short Position Report
    generateShortPositionReport: (fundCode, dateString) => dispatch(generateShortPositionReport(fundCode, dateString)),
    initGenerateShortPositionReportActionStatus: () => dispatch(initGenerateShortPositionReportActionStatus()),

    // Load Short Position Report
    loadShortPositionReport: (fundCode, dateString) => dispatch(loadShortPositionReport(fundCode, dateString)),
    initLoadShortPositionReportActionStatus: () => dispatch(initLoadShortPositionReportActionStatus()),

    // Open/Reset Short Position Report Check Result Modal Dialog
    openShortPositionReportCheckResultModalDialog: (fundCode, dateString) => dispatch(openShortPositionReportCheckResultModalDialog(fundCode, dateString)),
    resetShortPositionReportCheckResultModalDialog: () => dispatch(resetShortPositionReportCheckResultModalDialog()),

    // Load Short Position Report Check Result
    loadShortPositionReportCheckResult: (fundCode, dateString) => dispatch(loadShortPositionReportCheckResult(fundCode, dateString)),
    initLoadShortPositionReportCheckResultActionStatus: () => dispatch(initLoadShortPositionReportCheckResultActionStatus()),

    // Report Short Position Report
    reportShortPositionReport: (fundCode, dateString) => dispatch(reportShortPositionReport(fundCode, dateString)),
    initReportShortPositionReportActionStatus: () => dispatch(initReportShortPositionReportActionStatus()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortPositionReportDashboard);