import React, { Component } from 'react';

import moment from 'moment';
import { Form, Row, Col, Input, Button, Modal, Select, DatePicker, message, AutoComplete, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { RESTRICTION_TYPE_MAP } from '../../../../../utils/RestrictionTypeUtil';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

// Restriction Type
const RestrictionTypeOptions = [];
for (let i = 0; i < RESTRICTION_TYPE_MAP.length; i++) {
  RestrictionTypeOptions.push(<Option key={RESTRICTION_TYPE_MAP[i].code}>{RESTRICTION_TYPE_MAP[i].label}</Option>);
}


class RestrictedSecurityEditDialog extends Component {
  state = {
    
  };

  componentDidUpdate() {
    const { 
      createRestrictedSecurityActionStatus,
      updateRestrictedSecurityActionStatus,
    } = this.props;

    // 创建 Restricted Security 成功
    if (createRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedSecurity();
      this.props.initCreateRestrictedSecurityActionStatus();

      this.props.resetRestrictedSecurityEditModalDialog();
    }
      
    // 创建 Restricted Security 失败
    if (createRestrictedSecurityActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCreateRestrictedSecurityActionStatus();
    }

    // 更新 Restricted Security 成功
    if (updateRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedSecurity();
      this.props.initUpdateRestrictedSecurityActionStatus();

      this.props.resetRestrictedSecurityEditModalDialog();
    } 

    // 更新 Restricted Security 失败
    if (updateRestrictedSecurityActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateRestrictedSecurityActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.restrictedSecurityEditChangeInput({name, value});
  }

  bbgTickerOnChange = (value) => {
    if (!!!value) {
      this.props.clearRestrictedSecurityBbgTickers();
    }

    this.props.restrictedSecurityEditChangeInput({ name: 'bbgTicker', value });
    this.props.form.resetFields();
    this.props.restrictedSecurityBbgTickerSelectChanged(value);
  }


  lastTimeoutID = null;
  bbgTickerOnSearch = (value) => {
    if (!!!value) {
      this.props.clearRestrictedSecurityBbgTickers();
      return;
    }
    this.props.restrictedSecurityEditChangeInput({ name: 'bbgTicker', value });
    if(value.length < 2) {
      return;
    }
    // 输入完毕等待0.5秒后再查询
    // 无此控制会有以下bug：
    // 当输入 2007 时，会分别根据 20、200、2007进行查询
    // 根据2007的查询结果数据量少，最先返回
    // 根据20的查询结果数据量大，最后返回
    // 因此最终展示的，反而是根据20的查询结果，尽管输入的是2007
    if(!!this.lastTimeoutID) {
      clearTimeout(this.lastTimeoutID);
    }
    this.lastTimeoutID = setTimeout(() => {
      this.props.loadRestrictedSecurityBbgTickers(value);
    }, 500);
  }

  
  fundCodeOnChange = (value) => {
    this.props.restrictedSecurityEditChangeInput({ name: 'fund', value });

    if (!!value) {
      this.props.restrictedSecurityFundCodeSelectChanged(value);
    }
  }

  bookCodeOnChange = (value) => {
    this.props.restrictedSecurityEditChangeInput({ name: 'book', value });

    if (!!value) {
      this.props.restrictedSecurityBookCodeSelectChanged(value);
    }
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      restrictedSecurityEditControlModal,
    } = this.props;

    const isAddMode = restrictedSecurityEditControlModal.isAddMode;

    const {
      guid,
      restrictionType,
      fund,
      book,
      personInCharge,
      securityId,
      bbgTicker,
      secName,
      validFrom,
      validTo,
      onListReason,
      offListReason,
      relatedCompany,
      attachment,
      attachmentOriginName,
      restrictedSecReqGuid,
      comments,
    } = restrictedSecurityEditControlModal.fields;

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          if (validTo === '') {
            validTo = '9999-12-31';
          }

          if (isAddMode) {
            this.props.createRestrictedSecurity({
              guid,
              restrictionType,
              fund,
              book,
              personInCharge,
              securityId,
              bbgTicker,
              secName,
              validFrom,
              validTo,
              onListReason,
              offListReason,
              relatedCompany,
              attachment,
              attachmentOriginName,
              restrictedSecReqGuid,
              comments,
            });
          } else {
            this.props.updateRestrictedSecurity({
              guid,
              restrictionType,
              fund,
              book,
              personInCharge,
              securityId,
              bbgTicker,
              secName,
              validFrom,
              validTo,
              onListReason,
              offListReason,
              relatedCompany,
              attachment,
              attachmentOriginName,
              restrictedSecReqGuid,
              comments,
            });
          }
        }
      },
    );
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    fileList = fileList.slice(-1);
    this.props.restrictedSecurityEditChangeInput({name: 'fileList', value: fileList});

    fileList.forEach(file => {
      if (file.response) {
        const filename = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        this.props.restrictedSecurityEditChangeInput({name: 'attachment', value: filename});
        this.props.restrictedSecurityEditChangeInput({name: 'attachmentOriginName', value: originalFileName});
      }
    });
  }

  render() {
    const {
      restrictedSecurityEditControlModal,
      fundCodeOptions,
      bookCodeOptions,
      bbgTickerOptions,
      bbgTickerArray,
      userAccountOptions,
      loadRestrictedSecurityBbgTickersActionStatus,
      restrictedSecAttachmentUploadUrl,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = restrictedSecurityEditControlModal;

    const { getFieldDecorator } = this.props.form;

    const {
      restrictionType,
      fund,
      book,
      personInCharge,
      bbgTicker,
      secName,
      validFrom,
      validTo,
      onListReason,
      offListReason,
      relatedCompany,
      comments,
      fileList,
    } = restrictedSecurityEditControlModal.fields;

    const {
      createRestrictedSecurityActionStatus,
      updateRestrictedSecurityActionStatus,
    } = this.props;

    let modalTitle = "Edit Restricted Security";
    if (isAddMode) {
      modalTitle = "Add Restricted Security";
    }

    const isLoading = createRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING || 
      updateRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING; 

    const bbgTickersSelectIsLoading = loadRestrictedSecurityBbgTickersActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedSecurityEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Restriction Type">
                  {getFieldDecorator('restrictionType', {
                      initialValue: restrictionType,
                      rules: [{
                        required: true,
                        message: 'Please select restriction type',
                      }],
                    })(
                      <Select onChange={(value) => {
                            this.onInputChange({ name: 'restrictionType', value })
                          }} placeholder="Please Choose"
                        >
                        {RestrictionTypeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Fund">
                  {getFieldDecorator('fund', {
                      initialValue: fund,
                      rules: [{
                        required: true,
                        message: 'Please select fund',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.fundCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {fundCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Book">
                  {getFieldDecorator('book', {
                      initialValue: book,
                      rules: [{
                        required: true,
                        message: 'Please select book',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.bookCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {bookCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Person In Charge">
                  <Input placeholder="" 
                    value={personInCharge}
                    onChange={(e) => {
                        this.onInputChange({ name: 'personInCharge', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Bbg Ticker">
                  <AutoComplete 
                    allowClear
                    value={bbgTicker}
                    dataSource={bbgTickerArray}
                    onChange={this.bbgTickerOnChange}
                    onSearch={this.bbgTickerOnSearch} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Sec Name">
                  <Input placeholder="" 
                    value={secName}
                    allowClear
                    onChange={(e) => {
                        this.onInputChange({ name: 'secName', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              {
                isAddMode &&
                <Col span={12}>
                    <FormItem {...formItemLayout} label="Valid From">
                      {getFieldDecorator('validFrom', {
                        initialValue: validFrom === '' ? null : moment(validFrom),
                        rules: [{
                          required: true,
                          message: 'Please select valid from',
                        }],
                      })(
                        <DatePicker 
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          allowClear={false}
                          disabledDate={(currentDate) => {
                            return currentDate.isBefore(moment().format("YYYY-MM-DD"));
                          }}
                          onChange={(date, dateString) => {
                            this.onInputChange({ name: 'validFrom', value: dateString });
                          }} />
                        )
                      }
                    </FormItem>
                </Col>
              }
              {
                !isAddMode &&
                <Col span={12}>
                    <FormItem {...formItemLayout} label="Valid From">
                      {getFieldDecorator('validFrom', {
                        initialValue: validFrom === '' ? null : moment(validFrom),
                        rules: [{
                          required: true,
                          message: 'Please select valid from',
                        }],
                      })(
                        <DatePicker 
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          allowClear={false}
                          onChange={(date, dateString) => {
                            this.onInputChange({ name: 'validFrom', value: dateString });
                          }} />
                        )
                      }
                    </FormItem>
                </Col>
              }
              <Col span={12}>
                <FormItem {...formItemLayout} label="Valid To">
                  <DatePicker 
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    value={moment(validTo)}
                    allowClear={false}
                    onChange={(date, dateString) => {
                      this.onInputChange({ name: 'validTo', value: dateString === '' ? '9999-12-31 00:00' : dateString });
                    }} 
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="On List Reason">
                  {getFieldDecorator('onListReason', {
                      initialValue: onListReason,
                      rules: [{
                        required: true,
                        message: 'Please input on list reason',
                      }],
                    })(
                    <TextArea placeholder="On List Reason" 
                      rows={4}
                      onChange={(e) => {
                          this.onInputChange({ name: 'onListReason', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Off List Reason">
                  <TextArea placeholder="Off List Reason" 
                    rows={4}
                    value={offListReason}
                    onChange={(e) => {
                        this.onInputChange({ name: 'offListReason', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Related Company">
                  <TextArea placeholder="Related Company" 
                    value={relatedCompany}
                    rows={4}
                    onChange={(e) => {
                        this.onInputChange({ name: 'relatedCompany', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Attachment">
                  <Upload
                    name="attachment"
                    withCredentials={true}
                    multiple={true}
                    showUploadList={true}
                    fileList={fileList}
                    action={restrictedSecAttachmentUploadUrl}
                    onChange={this.attachmentHandleChange}
                  >
                    <Button>
                      <Icon type="upload" /> Upload Attachment
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Comments">
                  <TextArea placeholder="Comments" 
                    value={comments}
                    rows={4}
                    onChange={(e) => {
                        this.onInputChange({ name: 'comments', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ respErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const RestrictedSecurityEditDialogForm = Form.create({ name: 'restricted_list__edit_dialog' })(RestrictedSecurityEditDialog);

export default RestrictedSecurityEditDialogForm;