import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, DatePicker, Select, AutoComplete, InputNumber, Upload, Button, Icon, Tooltip } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../../utils/localStorageClient';
import { SIDE_TYPE_MAP } from '../../../../../utils/SideTypeUtil';
import { PRICE_TYPE_CODE, PRICE_TYPE_MAP } from '../../../../../utils/PriceTypeUtil';
import DataEditMode from '../../../../../utils/DataEditMode';
import { CATEGORY_MAP } from '../../../../../utils/CategoryUtil';
import moment from 'moment';

const FormItem = Form.Item;
const confirm = Modal.confirm;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

const remarkFormItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

// Side Type
const SideTypeOptions = [];
for (let i = 0; i < SIDE_TYPE_MAP.length; i++) {
  SideTypeOptions.push(<Option key={SIDE_TYPE_MAP[i].code}>{SIDE_TYPE_MAP[i].label}</Option>);
}

// Price Type
const PriceTypeOptions = [];
for (let i = 0; i < PRICE_TYPE_MAP.length; i++) {
  PriceTypeOptions.push(<Option key={PRICE_TYPE_MAP[i].code}>{PRICE_TYPE_MAP[i].label}</Option>);
}

// Category List
const categoryOptions = [];
for (let i = 0; i < CATEGORY_MAP.length; i++) {
    categoryOptions.push(<Option key={CATEGORY_MAP[i].code}>{CATEGORY_MAP[i].label}</Option>);
}

class PersonalTradeRequestEditDialog extends Component {

  state = {
    loading: false,
    comments: '',
  };


  loadPersonalTrade = () => {
    const {
      employee,
      tradeDateStart,
      tradeDateEnd,
    } = this.props.personalTradeQueryParam;
    const params = {employee, tradeDateStart, tradeDateEnd};
    this.props.loadPersonalTrade(params);
  }


  componentDidUpdate () {
    const {
      addPersonalTradeRequestActionStatus,
      editPersonalTradeRequestActionStatus,
      copyPersonalTradeRequestActionStatus,
      trigger30DayHoldingCheckInfoStatus,
    } = this.props;

    // Add Personal Trade Request 成功
    if (addPersonalTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Personal Trade Request is created successfully!');
      this.loadPersonalTrade();
      
      // this.props.loadPersonalTradeFieldHistory(localStorageClient.getUserName());
      this.props.initAddPersonalTradeRequestActionStatus();
      
      this.props.resetPersonalTradeRequestEditModalDialog();
    }
      
    // Add Personal Trade Request 失败
    if (addPersonalTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initAddPersonalTradeRequestActionStatus();
    }

    // ======================== Update ========================
    // Edit Personal Trade Request 成功
    if (editPersonalTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Personal Trade Request is updated successfully!');
      this.loadPersonalTrade();
      this.props.initEditPersonalTradeRequestActionStatus();
      this.props.resetPersonalTradeRequestEditModalDialog();
    }
      
    // Edit Personal Trade Request 失败
    if (editPersonalTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initEditPersonalTradeRequestActionStatus();
    }

    // ======================== Copy ========================
    // Copy Personal Trade Request 成功
    if (copyPersonalTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Personal Trade Request is copied successfully!');
      this.loadPersonalTrade();
      this.props.initCopyPersonalTradeRequestActionStatus();
      
      this.props.resetPersonalTradeRequestEditModalDialog();
    }
      
    // Copy Personal Trade Request 失败
    if (copyPersonalTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCopyPersonalTradeRequestActionStatus();
    }

    // 持仓30天检查，触发
    if(trigger30DayHoldingCheckInfoStatus === ACTION_STATUS.LOGINING) {
      this.triggerPresubmitCheck();
    }

  }


  // 预检查
  triggerPresubmitCheck = () => {
    this.props.stopTrigger30DayHoldingCheckInfoStatus();
    
    const employee = localStorageClient.getUserName();
    const {
      personalTradeRequestEditControlModal,
    } = this.props;
    const {
      editMode,
    } = personalTradeRequestEditControlModal;
    const {
      tradeId,
      accountGuid,
      securityId,
      bbgTicker,
      secName,
      stockMarket,
      side,
      priceType,
      price,
      applyQty,
      actualQty,
      currency,
      tradeDate,
      holdingDays,
      remark,
      attachment,
      attachmentOriginName,
      category,
    } = personalTradeRequestEditControlModal.fields;

    let currId = tradeId;
    if (editMode !== DataEditMode.MODIFY) {
      // NEW, COPY 都按新增处理，不提供id，不需要排除自身
      currId = null;
    }

    if(!accountGuid || !bbgTicker || (side !== 'SELL' && side !== 'COVER')) {
      // 信息不完整，清空
      this.props.reset30DayHoldingCheckInfo();
    } else {
      // 信息完整，提交查询
      const personalTradeInfo = {
        id: currId,
        employee,
        accountGuid,
        securityId,
        bbgTicker,
        secName,
        stockMarket,
        side,
        priceType,
        price,
        applyQty,
        actualQty,
        currency,
        tradeDate,
        holdingDays,
        remark,
        attachment,
        attachmentOriginName,
        category,
      };
      this.props.query30DayHoldingCheckInfo(personalTradeInfo);
    }
  }

  onInputChange = ({name, value}) => {
    this.props.personalTradeRequestEditChangeInput({name, value});
    if(name === 'accountGuid' || name === 'bbgTicker' || name === 'side') {
      this.props.startTrigger30DayHoldingCheckInfoStatus();
    }
  }

  bbgTickerOnChange = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
    }

    this.onInputChange({ name: 'bbgTicker', value });
    this.props.form.resetFields();
    this.props.bbgTickerSelectChanged(value);
  }


  lastTimeoutID = null;
  bbgTickerOnSearch = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
      return;
    }
    if(value.length < 2) {
      return;
    }
    // 输入完毕等待0.5秒后再查询
    // 无此控制会有以下bug：
    // 当输入 2007 时，会分别根据 20、200、2007进行查询
    // 根据2007的查询结果数据量少，最先返回
    // 根据20的查询结果数据量大，最后返回
    // 因此最终展示的，反而是根据20的查询结果，尽管输入的是2007
    if(!!this.lastTimeoutID) {
      clearTimeout(this.lastTimeoutID);
    }
    this.lastTimeoutID = setTimeout(() => {
      this.props.loadBbgTickers(value);
    }, 500);
  }

  stockMarketOnSearch = (value) => {
    if (!!!value) {
      this.props.clearExchangeCodes();
      return;
    }

    if (value.length >= 1) {
      this.props.loadExchangeCode(value);
    }
  }

  // PriceType Changed
  priceTypeChanged = (value) => {
    this.onInputChange({ name: 'priceType', value });
    if (value === PRICE_TYPE_CODE.MARKET) {
      this.onInputChange({ name: 'buyingPrice', value: '' });
      this.onInputChange({ name: 'sellingPrice', value: '' });
    } else if (value === PRICE_TYPE_CODE.LIMIT) {
      this.onInputChange({ name: 'marketPrice', value: '' });
    }
  }

  // Side Changed
  sideTypeChanged = (value) => {
    this.onInputChange({ name: 'side', value });
    // if (value === SIDE_TYPE_CODE.BUY || value === SIDE_TYPE_CODE.SHRT) {
    //   this.onInputChange({ name: 'holdingDays', value: '' });
    // }

  }

  // category Changed
  categoryTypeChanged = (value) => {
    this.onInputChange({ name: 'category', value });
  }

  checkValueNumber = (rule, value, callback) => {
    if (isNaN(value)) {
      if (rule.field === 'quantity') {
        callback(`Quantity must be a number`);
      }

      if (rule.field === 'amount') {
        callback(`Amount must be a number`);
      }

    } else {
      let numValue = Number(value);
      let valueString = value + '';

      if (rule.field === 'quantity' && (Math.floor(numValue) !== numValue || valueString.indexOf('.') >= 0)) {
        callback(`Quantity must be a integer`);
      }

      callback();
    }
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    fileList = fileList.slice(-1);
    this.props.personalTradeRequestEditChangeInput({name: 'fileList', value: fileList});

    fileList.forEach(file => {
      if (file.response) {
        const filename = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        this.props.personalTradeRequestEditChangeInput({name: 'attachment', value: filename});
        this.props.personalTradeRequestEditChangeInput({name: 'attachmentOriginName', value: originalFileName});
      }
    });
  }



  submitButtonOnClicked = () => {
    const {
      personalTradeRequestEditControlModal,
      resultOf30DayHoldingCheckInfo,
    } = this.props;
    const {
      side,
      applyQty,
      actualQty,
    } = personalTradeRequestEditControlModal.fields;

    // 30天持仓相关检查
    let skipConfirm = side !== 'SELL';
    let applyQtyIsOverAvailable = false;
    let actualQtyIsOverAvailable = false;
    if(!skipConfirm) {
      const {
        availableForSellQty
      } = resultOf30DayHoldingCheckInfo;
      applyQtyIsOverAvailable = applyQty!==undefined && applyQty!==null && applyQty!=='' && availableForSellQty < applyQty;
      actualQtyIsOverAvailable = actualQty!==undefined && actualQty!==null && actualQty!=='' && availableForSellQty < actualQty;
    }

    let doSubmitButtonOnClicked = this.doSubmitButtonOnClicked;
    if(applyQtyIsOverAvailable || actualQtyIsOverAvailable) {
      const checkField = actualQtyIsOverAvailable ? 'Actual Trade Quantity' : 'Apply Trade Quantity';
      confirm({
        title: 'The "' + checkField + '" you input is larger than "Quantity available for sell". Are you sure to continue?',
        onOk() {
          doSubmitButtonOnClicked();
        },
        onCancel() {},
      });

    } else {
      doSubmitButtonOnClicked();
    }

  }


  doSubmitButtonOnClicked = () => {
    const {
      personalTradeRequestEditControlModal,
    } = this.props;

    const {
      editMode,
    } = personalTradeRequestEditControlModal;

    const {
      tradeId,
      employee,
      accountGuid,
      securityId,
      bbgTicker,
      secName,
      stockMarket,
      side,
      priceType,
      price,
      applyQty,
      actualQty,
      currency,
      tradeDate,
      holdingDays,
      remark,
      attachment,
      attachmentOriginName,
      finishInDays,
      category,
    } = personalTradeRequestEditControlModal.fields;


    const {
      comments,
    } = this.state;
    
    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          if (editMode === DataEditMode.NEW) {
            this.props.addPersonalTradeRequest({
              employee,
              accountGuid,
              securityId,
              bbgTicker,
              secName,
              stockMarket,
              side,
              priceType,
              price,
              applyQty,
              actualQty,
              currency,
              tradeDate,
              holdingDays,
              remark,
              attachment,
              attachmentOriginName,
              comments,
              finishInDays,
              category,
            });
          } 

          if (editMode === DataEditMode.MODIFY) {
            this.props.editPersonalTradeRequest({
              id: tradeId,
              employee,
              accountGuid,
              securityId,
              bbgTicker,
              secName,
              stockMarket,
              side,
              priceType,
              price,
              applyQty,
              actualQty,
              currency,
              tradeDate,
              holdingDays,
              remark,
              attachment,
              attachmentOriginName,
              comments,
              finishInDays,
              category,
            });
          }

          debugger;
          if (editMode === DataEditMode.COPY) {
            this.props.copyPersonalTradeRequest({
              id: tradeId,
              employee,
              accountGuid,
              securityId,
              bbgTicker,
              secName,
              stockMarket,
              side,
              priceType,
              price,
              applyQty,
              actualQty,
              currency,
              tradeDate,
              holdingDays,
              remark,
              attachment,
              attachmentOriginName,
              comments,
              finishInDays,
              category,
            });
          }
        }
      },
    );
  }


  // Modal Closed
  modalClosedHandle = () => {
    this.setState({
      comments: '',
    });
  }

  render() {
    const {
      personalTradeRequestEditControlModal,
      addPersonalTradeRequestActionStatus,
      editPersonalTradeRequestActionStatus,
      copyPersonalTradeRequestActionStatus,

      personalAccountArray,

      // Bbg Ticker
      bbgTickerArray,
      bbgTickerOptions,
      loadBbgTickersActionStatus,

      // Personal Trade Field History
      personalTradeFieldHistory,

      // ExchangeCode
      loadExchangeCodeActionStatus,
      exchageCodeOptions,

      // User Account Options
      userAccountOptions,

      // 附件上传URL
      tradeAttachmentUploadUrl,

      // 30天持仓相关预检查
      resultOf30DayHoldingCheckInfo,

    } = this.props;


    // 30天持仓相关预检查
    const {
      allQuantity,
      in30DaysBuyQty,
      approveWaitSellQty,
      submittedNotAbandonedQty,
      availableForSellQty,
    } = resultOf30DayHoldingCheckInfo;

    const {
      isOpened,
      editMode,
      respErrMsg,
    } = personalTradeRequestEditControlModal;

    const {
      loading,
      comments,
    } = this.state;

    const { getFieldDecorator } = this.props.form;

    const {
      employee,
      accountGuid,
      securityId,
      bbgTicker,
      secName,
      stockMarket,
      side,
      priceType,
      price,
      applyQty,
      actualQty,
      currency,
      tradeDate,
      holdingDays,
      remark,
      fileList,
      finishInDays,
      category,
    } = personalTradeRequestEditControlModal.fields;




    let modalTitle = 'Add PA Dealing Application';
    if (editMode === DataEditMode.MODIFY) {
      modalTitle = 'Edit PA Dealing Application';
    } else if (editMode === DataEditMode.COPY) {
      modalTitle = 'Copy PA Dealing Application';
    }

    const isLoading = addPersonalTradeRequestActionStatus === ACTION_STATUS.LOGINING || 
        editPersonalTradeRequestActionStatus === ACTION_STATUS.LOGINING || 
        copyPersonalTradeRequestActionStatus === ACTION_STATUS.LOGINING;


    let personalAccountOptions = [];
    if(!!personalAccountArray) {
    personalAccountOptions = personalAccountArray
                  .map(perAcc => <Option key={perAcc.guid}>{perAcc.accountName} / {perAcc.brokerAccount} / {perAcc.accountNo}</Option>);
    }

    let sideS = '';
    let flag = '';
    let allQuantityS =allQuantity;
    let in30DaysBuyQtyS = in30DaysBuyQty;
    let approveWaitSellQtyS =approveWaitSellQty;
    let submittedNotAbandonedQtyS =submittedNotAbandonedQty;
    let availableForSellQtyS =availableForSellQty;
    if(side=='BUY' || side =='SELL'){
      sideS = 'Sell';
      allQuantityS = allQuantity ;
      in30DaysBuyQtyS =in30DaysBuyQty;
      approveWaitSellQtyS =approveWaitSellQty;
      submittedNotAbandonedQtyS =submittedNotAbandonedQty;
      availableForSellQtyS =availableForSellQty;
    } else {
      sideS = 'Cover';
      allQuantityS = allQuantity *-1;
      in30DaysBuyQtyS =in30DaysBuyQty*-1;
      approveWaitSellQtyS =approveWaitSellQty*-1;
      submittedNotAbandonedQtyS =submittedNotAbandonedQty*-1;
      availableForSellQtyS =availableForSellQty*-1;
    }
     ;   
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetPersonalTradeRequestEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Employee">
                  {getFieldDecorator('employee', {
                      initialValue: employee,
                      rules: [{
                        required: true,
                        message: 'Please input employee',
                      }],
                    })(
                    <Select
                      allowClear
                      showSearch
                      style={{ width: 220, marginLeft: 0 }}
                      onChange={(val) => {
                        this.onInputChange({ name: 'employee', value: val });
                        this.onInputChange({ name: 'accountGuid', value: '' }); // 联动清空, 状态
                        this.props.form.setFieldsValue({accountGuid: ''}); // 联动清空, UI
                        this.props.loadPersonalAccountWithEmployee({employee: val});
                      }} 
                      placeholder="Please Choose Employee">
                      {userAccountOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                  <FormItem {...formItemLayout} label={
                      <span>
                        Finish In Days&nbsp;
                        <Tooltip title="Employee must finish the trade and upload proof within number of these days, otherwise the employee will not be able to create new trade request">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                  }>
                      {getFieldDecorator('finishInDays', {
                        initialValue: finishInDays,
                        rules: [{
                          required: true,
                          pattern: new RegExp(/^[1-9][0-9]*$/, "g"),
                          message: 'Please input a positive integer',
                        }],
                      })(
                      <Input 
                        placeholder="" 
                        onChange={(e) => {
                          this.onInputChange({ name: 'finishInDays', value: e.target.value })
                        }}
                      />
                    )}
                  </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Account">
                  {getFieldDecorator('accountGuid', {
                      initialValue: accountGuid,
                      rules: [{
                        required: true,
                        message: 'Please choose Account',
                      }],
                    })(
                      <Select
                          allowClear
                          style={{ width: 720, marginLeft: 0 }}
                          onChange={(val) => {
                            this.onInputChange({ name: 'accountGuid', value: val });
                          }} 
                          placeholder="Please choose Account">
                          {personalAccountOptions}
                      </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label={
                    <span>
                      Bbg Ticker&nbsp;
                      <Tooltip title="Please input by using Bloomberg ticker">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }>
                   {getFieldDecorator('bbgTicker', {
                      initialValue: bbgTicker,
                      rules: [{
                        required: true,
                        message: 'Please input by using Bloomberg ticker',
                      }],
                    })(
                    <AutoComplete 
                      allowClear
                      dataSource={bbgTickerArray}
                      onChange={this.bbgTickerOnChange}
                      onSearch={this.bbgTickerOnSearch} />
                    )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Security Name">
                  {getFieldDecorator('secName', {
                      initialValue: secName,
                      rules: [{
                        required: true,
                        message: 'Please input security name',
                      }],
                    })(
                    <Input
                      placeholder="" 
                      allowClear
                      onChange={(e) => {
                          this.onInputChange({ name: 'secName', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Stock Market">
                  {getFieldDecorator('stockMarket', {
                      initialValue: stockMarket,
                      rules: [{
                        required: true,
                        message: 'Please input stock market',
                      }],
                    })(
                    <AutoComplete 
                      allowClear
                      dataSource={exchageCodeOptions}
                      onChange={(val) => {
                        this.onInputChange({ name: 'stockMarket', value: val })
                      }}
                      onSearch={this.stockMarketOnSearch} />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
              <FormItem {...formItemLayout} label='Category'>
                  {getFieldDecorator('category', {
                      initialValue: category,
                      rules: [{
                          required: true,
                          message: 'Please choose category',
                      }],
                      })(
                          <Select 
                            value={category}
                            onChange={this.categoryTypeChanged} 
                            placeholder="category">
                            {categoryOptions}
                          </Select>
                      )}
                  </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Side">
                  <Select 
                    value={side}
                    onChange={(value) => {
                        this.sideTypeChanged(value);
                      }} 
                      placeholder="Please Choose">
                    {SideTypeOptions}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Apply Trade Quantity">
                  {getFieldDecorator('applyQty', {
                      initialValue: applyQty,
                      rules: [{
                        required: true,
                        pattern: new RegExp(/^[1-9]\d*$/, "g"),
                        message: 'Please input correct apply quantity',
                      }],
                    })(
                    <Input 
                      placeholder="" 
                      onChange={(e) => {
                        this.onInputChange({ name: 'applyQty', value: e.target.value })
                      }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Actual Trade Quantity">
                    {getFieldDecorator('actualQty', {
                      initialValue: actualQty,
                      rules: [{
                        required: false,
                        pattern: new RegExp(/^[0-9]\d*$/, "g"),
                        message: 'Please input correct actual quantity',
                      }],
                    })(
                    <Input 
                      placeholder="" 
                      onChange={(e) => {
                        this.onInputChange({ name: 'actualQty', value: e.target.value })
                      }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
              <FormItem {...formItemLayout} label="Price Type">
                  <Select 
                    value={priceType}
                    onChange={this.priceTypeChanged} 
                    placeholder="Please Choose">
                    {PriceTypeOptions}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Price">
                    {getFieldDecorator('price', {
                        initialValue: price,
                        rules: [{
                          required: false,
                          pattern: new RegExp(/^(0|[1-9][0-9]*)(\.?)([0-9]*)$/, "g"),
                          message: 'Please input correct price',
                        }],
                      })(
                      <Input 
                        placeholder=''
                        onChange={(e) => {
                          this.onInputChange({ name: 'price', value: e.target.value })
                        }}
                      />
                    )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Currency">
                  <Input 
                    value={currency}
                    disabled
                    placeholder="" 
                    onChange={(e) => {
                        this.onInputChange({ name: 'currency', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Trade Date">
                  <DatePicker 
                    value={moment(tradeDate)}
                    disabled/>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={17}>
                <FormItem {...remarkFormItemLayout} label="Attachment">
                  <Upload
                    name="attachment"
                    withCredentials={true}
                    multiple={true}
                    showUploadList={true}
                    fileList={fileList}
                    action={tradeAttachmentUploadUrl}
                    onChange={this.attachmentHandleChange}
                  >
                    <Button loading={loading}>
                      <Icon type="upload" /> Proof of Executed Trade
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
            <Row span={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Remark">
                  <TextArea 
                    rows={2} 
                    value={remark} 
                    onChange={(e) => {
                      this.onInputChange({ name: 'remark', value: e.target.value })
                    }}/>
                </FormItem>
                <FormItem {...formItemLayout} label="Comment">
                    <TextArea 
                      rows={2} 
                      value={comments} 
                      onChange={(e) => {
                        this.setState({
                          comments: e.target.value
                        })
                      }}/>
                </FormItem>
              </Col>
              <Col span={12}>
                  <div>
                    <table cellPadding='2'>
                      <tbody>
                        <tr>
                          <td align='right'>Current Ticker quantity holding (S):</td>
                          <td align='left'>&nbsp;{allQuantityS}</td>
                        </tr>
                        <tr>
                          <td align='right'>Quantity holding less than 30 days (A):</td>
                          <td align='left'>&nbsp;<font color='red'>{in30DaysBuyQtyS}</font></td>
                        </tr>
                        <tr>
                          <td align='right'>{sideS} quantity approved waiting for trade (B):</td>
                          <td align='left'>&nbsp;<font color='red'>{approveWaitSellQtyS}</font></td>
                        </tr>
                        <tr>
                          <td align='right'>{sideS} quantity submitted and unabadoned (C):</td>
                          <td align='left'>&nbsp;<font color='red'>{submittedNotAbandonedQtyS}</font></td>
                        </tr>
                        <tr>
                          <td align='right'>Quantity available for {sideS} (X=S-A-B-C):</td>
                          <td align='left'>&nbsp;<font color='green'>{availableForSellQtyS}</font></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ respErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const PersonalTradeRequestEditDialogForm = Form.create({ name: 'personal_trade_request_edit_dialog' })(PersonalTradeRequestEditDialog);

export default PersonalTradeRequestEditDialogForm;