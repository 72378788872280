import React, {Component} from 'react';

import { Radio, Breadcrumb, Button, message, Modal } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedRuleGrid from './RestrictedRuleGrid';
import '../../ComplianceRule.css';

import RestrictedRuleEditDialog from './RestrictedRuleEditDialog';

const confirm = Modal.confirm;

class RestrictedRule extends Component {


  componentDidMount () {
    this.props.loadRestrictedRules();
  }

  componentDidUpdate() {
    const { 
      batchDeleteRestrictedRulesActionStatus,
    } = this.props;
  
    // 批量删除 Restricted Rule 成功
    if (batchDeleteRestrictedRulesActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted Rules are deleted successfully!');
      this.props.loadRestrictedRules();
      this.props.initBatchDeleteRestrictedRuleActionStatus();
    }
      
    // 批量删除 Restricted Rule 失败
    if (batchDeleteRestrictedRulesActionStatus === ACTION_STATUS.ERROR) {
      message.error('Restricted Rules are deleted failed!');
      this.props.initBatchDeleteRestrictedRuleActionStatus();
    }
  }

  addButtonOnClicked = () => {
    this.props.openRestrictedRuleEditModalDialog(true, null);

  }

  editButtonOnClicked = () => {
    const {
      selectedRestrictedRules
    } = this.props;

    if (selectedRestrictedRules.length !== 1) {
      message.warning("Please select one restricted security!");
      return;
    }

    this.props.openRestrictedRuleEditModalDialog(false, selectedRestrictedRules[0]);
  }

  delButtonOnClicked = () => {
    const {
      selectedRestrictedRules
    } = this.props;

    if (selectedRestrictedRules.length === 0) {
      message.warning("Please select one restricted security at least!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the restricted securities?',
      content: '',
      onOk() {
        const restrictedRuleIdList = selectedRestrictedRules.map(restrictedRule => restrictedRule.id);

        that.props.batchDeleteRestrictedRule(restrictedRuleIdList);
      },
      onCancel() {},
    });
  }

  refreshButtonOnClicked = () => {
    this.props.loadRestrictedRules();
  }

  showValidButtonOnClicked = () => {
    this.props.showValidRestrictedRules();
  }

  showAllButtonOnClicked = () => {
    this.props.showAllRestrictedRules();
  }


  render() {
    const {
      loadRestrictedRulesActionStatus
    } = this.props;

    const refreshBtnIsLoading = loadRestrictedRulesActionStatus === ACTION_STATUS.LOGINING;
    
    return (
      <div className="restrictedSecurityWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>Restricted Security</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='operationBar'>
          <Button 
            onClick={this.addButtonOnClicked} 
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            className='positive-button'>
            Edit
          </Button>

          <Button 
            onClick={this.delButtonOnClicked} 
            size='small'
            className='negative-button'>
            Del
          </Button>

          <Button 
            onClick={this.refreshButtonOnClicked} 
            loading={refreshBtnIsLoading}
            size='small'
            type='primary'>
            Refresh
          </Button>

          <Button 
            onClick={this.showValidButtonOnClicked} 
            size='small'
            type='primary'>
            Show Valid
          </Button>

          <Button 
            onClick={this.showAllButtonOnClicked} 
            size='small'
            type='primary'>
            Show All
          </Button>
        </div>

        <div className='restrictedSecurityGrid'>
          <RestrictedRuleGrid {...this.props} />
        </div>

        <RestrictedRuleEditDialog {...this.props} />
      </div>
    );
  }
}

export default RestrictedRule;