
// queryPendingTasksNum
export const QUERY_PENDING_TASKS_NUM_SUCCESS = 'QUERY_PENDING_TASKS_NUM_SUCCESS';
export const QUERY_PENDING_TASKS_NUM_FAILURE = 'QUERY_PENDING_TASKS_NUM_FAILURE';
// queryPendingTasksList
export const QUERY_PENDING_TASKS_INPUT_CHANGE = 'QUERY_PENDING_TASKS_INPUT_CHANGE';
export const START_QUERY_PENDING_TASKS_LIST = 'START_QUERY_PENDING_TASKS_LIST';
export const QUERY_PENDING_TASKS_LIST_SUCCESS = 'QUERY_PENDING_TASKS_LIST_SUCCESS';
export const QUERY_PENDING_TASKS_LIST_FAILURE = 'QUERY_PENDING_TASKS_LIST_FAILURE';
export const INIT_QUERY_PENDING_TASKS_LIST_STATUS = 'INIT_QUERY_PENDING_TASKS_LIST_STATUS';
// ignoreDeignorePendingTasks
export const IGNORE_DEIGNORE_PENDING_TASKS_SUCCESS = 'IGNORE_DEIGNORE_PENDING_TASKS_SUCCESS';
export const IGNORE_DEIGNORE_PENDING_TASKS_ERROR = 'IGNORE_DEIGNORE_PENDING_TASKS_ERROR';
export const INIT_IGNORE_DEIGNORE_PENDING_TASKS_STATUS = 'INIT_IGNORE_DEIGNORE_PENDING_TASKS_STATUS';

