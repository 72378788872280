import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './ExpenseSummaryDashboard.css';
import ExpenseSummaryGrid from './ExpenseSummaryGrid';
import AddOrEditExpenseSummaryModalDialog from './AddOrEditExpenseSummaryModalDialog';
import DelExpenseSummaryModalDialog from './DelExpenseSummaryModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';


class ExpenseSummaryDashboard extends Component {
    componentDidMount() {

    }


    componentDidUpdate() {
        const {
            // query
            loadExpenseSummaryInfoActionStatus,
            loadExpenseSummaryInfoResp,
        } = this.props;

        if(loadExpenseSummaryInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadExpenseSummaryInfoActionStatus();
        }
        if(loadExpenseSummaryInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load Expense Summary Info failed';
            if(!!loadExpenseSummaryInfoResp && !!loadExpenseSummaryInfoResp.respMessage) {
                err = loadExpenseSummaryInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadExpenseSummaryInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditExpenseSummaryModal(mode, null);
        } else if (mode === 'EDIT'){
            const {
                selectedExpenseSummaryInfoArray,
            } = this.props;
            if(selectedExpenseSummaryInfoArray.length !== 1) {
                message.warning("Please choose one and only one Expense Summary Info");
                return;
            }
            if(selectedExpenseSummaryInfoArray[0].payDate!==null) {
                message.warning("payDate is not null ");
                return;
            }

            const currentExpenseSummaryInfo = selectedExpenseSummaryInfoArray[0];
            this.props.openAddOrEditExpenseSummaryModal(mode, currentExpenseSummaryInfo);

        }else {
            const {
                selectedExpenseSummaryInfoArray,
            } = this.props;
            if(selectedExpenseSummaryInfoArray.length !== 1) {
                message.warning("Please choose one and only one Expense Summary Info");
                return;
            }
            const currentExpenseSummaryInfo = selectedExpenseSummaryInfoArray[0];
            this.props.openAddOrEditExpenseSummaryModal(mode, currentExpenseSummaryInfo);
        }
    }


    delBtnOnClicked = () => {
        const {
            selectedExpenseSummaryInfoArray,
        } = this.props;
        if(selectedExpenseSummaryInfoArray.length !== 1) {
            message.warning("Please choose one and only one Expense Summary Info");
            return;
        }
        const currentExpenseSummaryInfo = selectedExpenseSummaryInfoArray[0];
        this.props.openDelExpenseSummaryModal(currentExpenseSummaryInfo);
    }


    refreshBtnOnClicked = () => {
        const {
            category,
            invDateStart,
            invDateEnd,
            invNo,
            firm
        } = this.props.expenseSummaryQueryFields;
        this.props.loadExpenseSummaryInfo({category, invDateStart, invDateEnd, invNo, firm})
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {

        const {
            categoryOptions,
            expenseSummaryQueryFields,
            loadExpenseSummaryInfoActionStatus,
        } = this.props;

        const {
            category,
            invDateStart,
            invDateEnd,
        } = expenseSummaryQueryFields;

        const refreshBtnIsLoading = loadExpenseSummaryInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='ExpenseSummaryWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Departments</Breadcrumb.Item>
                        <Breadcrumb.Item>Expense Summary</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'category', value: val })
                        }} 
                        placeholder="Category">
                        {categoryOptions}
                    </Select>
                    <Input 
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="invNo"
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'invNo', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="firm"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'firm', value: event.target.value })
                        }} 
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date Start"
                      onChange={(invDateStart, dateString) => {
                        this.onInputChange({ name: 'invDateStart', value: dateString });
                      }}
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date End"
                      onChange={(invDateEnd, dateString) => {
                        this.onInputChange({ name: 'invDateEnd', value: dateString });
                      }}
                    />
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('ADD')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked()} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'ExpenseSummary'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button>
                </div>

                <div className='ExpenseSummaryGrid'><ExpenseSummaryGrid {...this.props} /></div>
                <AddOrEditExpenseSummaryModalDialog {...this.props} />
                <DelExpenseSummaryModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />

            </div>
        );
    }
}

export default ExpenseSummaryDashboard;
