export const TRADE_APPROVAL_STATUS_CODE = {
  APPROVED: '1',
  REJECTED: '2',
  UNPROCESSED: '3',
  FINAL_APPROVED: '4',
  UNKNOWN: '0',
};

const TRADE_APPROVAL_STATUS_LABEL = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  UNPROCESSED: 'Unprocessed',
  FINAL_APPROVED: 'FinalApproved',
  UNKNOWN: 'Unknown',
};

export const TRADE_APPROVAL_STATUS_MAP = [
  // APPROVED
  {
    code: TRADE_APPROVAL_STATUS_CODE.APPROVED,
    label: TRADE_APPROVAL_STATUS_LABEL.APPROVED
  },
  // REJECTED
  {
    code: TRADE_APPROVAL_STATUS_CODE.REJECTED,
    label: TRADE_APPROVAL_STATUS_LABEL.REJECTED
  },
  // UNPROCESSED
  {
    code: TRADE_APPROVAL_STATUS_CODE.UNPROCESSED,
    label: TRADE_APPROVAL_STATUS_LABEL.UNPROCESSED
  },
  // FINAL_APPROVED
  {
    code: TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED,
    label: TRADE_APPROVAL_STATUS_LABEL.FINAL_APPROVED
  },
  // UNKNOWN
  {
    code: TRADE_APPROVAL_STATUS_CODE.UNKNOWN,
    label: TRADE_APPROVAL_STATUS_LABEL.UNKNOWN
  },
];