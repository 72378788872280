import React, {Component} from 'react';
import { Button, message, Breadcrumb, DatePicker, Input } from 'antd';

import '../../FundMonitoring.css';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import TradesReviewGrid from './TradesReviewGrid';
import TradesReviewReportGrid from './TradesReviewReportGrid';


class TradesReviewDashboard extends Component {

    onInputChange = ({ name, value }) => {
        this.props.tradesReviewSearchChangeInput({ name, value });
    };

    queryTradesReview = () => {
        const {
            tradesReviewSearchFields,
        } = this.props;
        this.props.queryTradesReview(tradesReviewSearchFields);
    }

    queryTradesReviewReport = () => {
        const {
            tradesReviewSearchFields,
        } = this.props;
        this.props.queryTradesReviewReport(tradesReviewSearchFields);
    }

    exportAsXls = () => {
        const {
            tradesReviewSearchResultGridType,
            tradesReviewGrid,
            tradesReviewReportGrid,
        } = this.props;
        const grid = tradesReviewSearchResultGridType === 1 ? tradesReviewGrid : tradesReviewReportGrid;
        const fileName = tradesReviewSearchResultGridType === 1 ? 'trades_review.xlsx' : 'trades_review_report.xlsx';
        grid.api.exportDataAsExcel({fileName});
    }


    componentDidMount() { }


    componentDidUpdate() {
        const {
            queryTradesReviewActionStatus,
            queryTradesReviewActionResp,
        } = this.props;
        // 查询成功
        if(queryTradesReviewActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryTradesReviewActionStatus();
        }
        // 查询失败
        if(queryTradesReviewActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = "Query Trades Review failed.";
            if(queryTradesReviewActionResp !== undefined && queryTradesReviewActionResp.respMessage !== undefined) {
                errMsg = queryTradesReviewActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initQueryTradesReviewActionStatus();
        }
    }


    render() {
        const {
            tradesReviewSearchFields,
            queryTradesReviewActionStatus,
            queryTradesReviewReportActionStatus,
            tradesReviewSearchResultGridType,
        } = this.props;

        const {
            tradeDateStart,
            tradeDateEnd,
            ticker,
        } = tradesReviewSearchFields;

        const queryTradesReviewIsLoading = queryTradesReviewActionStatus === ACTION_STATUS.LOGINING;
        const queryTradesReviewReportIsLoading = queryTradesReviewReportActionStatus === ACTION_STATUS.LOGINING;

        return (
            <div className="tradesReviewWrapper">
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Fund Monitoring</Breadcrumb.Item>
                        <Breadcrumb.Item>Trades Review</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='operationBar'>
                    <DatePicker 
                        style={{ marginLeft: 10, fontSize: 10 }}
                        size='small'
                        allowClear={false}
                        placeholder='Trade Date Start'
                        value={tradeDateStart}
                        onChange={(date, dateString) => {
                            this.onInputChange({ name: 'tradeDateStart', value: date });
                        }} 
                    />
                    <DatePicker 
                        style={{ marginLeft: 10, fontSize: 10 }}
                        size='small'
                        allowClear={false}
                        placeholder='Trade Date End'
                        value={tradeDateEnd}
                        onChange={(date, dateString) => {
                            this.onInputChange({ name: 'tradeDateEnd', value: date });
                        }} 
                    />
                    <Input 
                        placeholder="Ticker" 
                        size='small' 
                        style={{ width: 150, marginLeft: 10 }}
                        value={ticker}
                        onChange={(e) => {
                                this.onInputChange({ name: 'ticker', value: e.target.value })
                            }
                        }
                    />
                    <Button type="primary" 
                        onClick={this.queryTradesReview} 
                        loading={queryTradesReviewIsLoading}
                        style={{ fontSize: 16, marginLeft: 10 }} 
                        size="small"
                        icon="search"
                    />
                    <Button type="primary" 
                        onClick={this.queryTradesReviewReport} 
                        loading={queryTradesReviewReportIsLoading}
                        style={{ fontSize: 12, marginLeft: 10 }} 
                        size="small"
                    >
                        Report
                    </Button>
                    <Button type="primary" 
                        onClick={this.exportAsXls} 
                        style={{ fontSize: 12, marginLeft: 10 }} 
                        size="small"
                    >
                        Export
                    </Button>
                </div>


                <div className='tradesReviewGrid'>
                    {
                        tradesReviewSearchResultGridType === 1
                        ? <TradesReviewGrid {...this.props} />
                        : <TradesReviewReportGrid {...this.props} />
                    }
                </div>

            </div>
        );
    }

}


export default TradesReviewDashboard;
