import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import GridColumnMap from './GridColumnMap';

import {

    // onLicensingQueryInputChange
    ON_LICENSING_QUERY_INPUT_CHANGE,

    // loadLicensingInfo
    START_LOAD_LICENSING_INFO,
    LOAD_LICENSING_INFO_SUCCESS,
    LOAD_LICENSING_INFO_FAILURE,
    INIT_LOAD_LICENSING_INFO_ACTION_STATUS,

    // add or edit license info
    OPEN_ADD_OR_EDIT_LICENSE_MODAL,
    ADD_OR_EDIT_LICENSE_CHANGE_INPUT,

    // loadLicenseBasicInfoOptions
    START_LOAD_LICENSE_BASIC_INFO_OPTIONS,
    LOAD_LICENSE_BASIC_INFO_OPTIONS_SUCCESS,
    LOAD_LICENSE_BASIC_INFO_OPTIONS_FAILURE,
    INIT_LOAD_LICENSE_BASIC_INFO_OPTIONS_ACTION_STATUS,

    // addOrEditLicenseEmpRequireRcd
    START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE,
    INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS,
    RESET_SUBMIT_SAVE_LICENSE_MODAL,

    // selectLicensingData
    SELECT_LICENSING_DATA,

    // batchDelLicenseInfo
    OPEN_DEL_LICENSE_MODAL,
    DEL_LICENSE_CHANGE_INPUT,
    START_BATCH_DEL_LICENSE_INFO,
    BATCH_DEL_LICENSE_INFO_SUCCESS,
    BATCH_DEL_LICENSE_INFO_FAILURE,
    INIT_BATCH_DEL_LICENSE_INFO_ACTION_STATUS,
    RESET_DEL_LICENSE_MODAL,

    // selectCptRcdData
    SELECT_CPT_RCD_DATA,

    // openEditCptRcdModal
    OPEN_EDIT_CPT_RCD_MODAL,

    // editCptRcdModalChangeInput
    EDIT_CPT_RCD_MODAL_CHANGE_INPUT,

    // edit cpt rcd
    START_SUBMIT_EDIT_CPT_RCD,
    SUBMIT_EDIT_CPT_RCD_SUCCESS,
    SUBMIT_EDIT_CPT_RCD_FAILURE,
    INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS,
    RESET_EDIT_CPT_RCD_MODAL,

    // op rcd
    OPEN_OPERATION_RCD_MODAL,
    START_QUERY_OPERATION_RCD,
    QUERY_OPERATION_RCD_SUCCESS,
    QUERY_OPERATION_RCD_FAILURE,
    INIT_QUERY_OPERATION_RCD_ACTION_STATUS,
    RESET_OPERATION_RCD_MODAL,


} from './LicensingConstants';

import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
} from '../home/homeConstants';


const Option = Select.Option;


const initialState = {
    licensingQuerySearchFields: {
        employee: '',
    },
    addOrEditLicenseModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            guid: '',
            employee: '',
            licenseBasicInfo: {guid: '',},
            licenseNo: '',
            regiStatus: '',
            licensedDate: '',
            examStatus: '',
            examDeadline: '',
            examLicenseInfo: '',
            latestUpload: '',
            historyUpload: '',
            remarks: '',
            feedback: '',
            comments: '',
            latestUploadFileList: [],
            historyUploadFileList: [],
        },
    },
    delLicenseModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            comments: '',
        },
    },
    editCptRcdModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            guid: '',
            employee: '',
            licenseBasic: '',
            year: '',
            licenseEmpRequireRcd: {guid: '',},
            cptHourRequire: '',
            cptHourFinished: '',
            cptHourAccepted: '',
            trainingInfo: '',
            latestUpload: '',
            historyUpload: '',
            remarks: '',
            feedback: '',
            comments: '',
            latestUploadFileList: [],
            historyUploadFileList: [],
        },
    },
    operationRcdModal: {
        isOpened: false,
    },
    selectedLicenseArray: [],
    loadLicensingInfoResp: [],
    selectedCptRcdDataArray: [],
    queryOperationRcdResp: [],

    // attachment urls
    examResultAttBaseUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_ATT_DOWNLOAD_URL,
    licensingExamCPTProofUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_EXAM_CPT_PROOF_UPLOAD_URL,

    // grid columns
    licensingGridColumns: GridColumnMap.licensingGridColumns,
    licensingCPTYearRcdGridColumns: GridColumnMap.licensingCPTYearRcdGridColumns,
    operationRcdGridColumns: GridColumnMap.operationRcdGridColumns,

}


// onLicensingQueryInputChange
function onLicensingQueryInputChange(state, { name, value }) {
    const updatedFields = {
        ...state.licensingQuerySearchFields,
        [name]: value
    };
    return {
        ...state,
        licensingQuerySearchFields: updatedFields,
    };
}


// loadLicensingInfo
function startLoadLicensingInfo(state) {
    return {
      ...state,
      loadLicensingInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadLicensingInfoSuccess(state, resp) {
    return {
        ...state,
        loadLicensingInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadLicensingInfoResp: resp.data,
    };
}
function loadLicensingInfoFailure(state, err) {
    return {
        ...state,
        loadLicensingInfoActionStatus: ACTION_STATUS.ERROR,
        loadLicensingInfoResp: err.respMessage,
    };
}
function initLoadLicensingInfoActionStatus(state) {
    return {
        ...state,
        loadLicensingInfoActionStatus: ACTION_STATUS.READY,
        selectedLicenseArray: [],
        selectedCptRcdDataArray: [],
    };
}


// add or edit license info
function openAddOrEditLicenseModal(state, {mode, licenseInfo}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditLicenseModal: {
                ...state.addOrEditLicenseModal,
                isOpened: true,
                mode,
            },
        };

    } else {
        const latestUploadFileList = [];
        const latestUpload = licenseInfo.latestUpload;
        if(!!latestUpload) {
            const file = {
                uid: '-1',
                name: latestUpload,
                status: 'done',
                url: state.examResultAttBaseUrl + "/" + latestUpload,
                response: {
                    respCode: "S0001",
                    latestUpload,
                }
            };
            latestUploadFileList.push(file);
        }

        const historyUploadFileList = [];
        const historyUpload = licenseInfo.historyUpload;
        if(!!historyUpload) {
            const file = {
                uid: '-1',
                name: historyUpload,
                status: 'done',
                url: state.examResultAttBaseUrl + "/" + historyUpload,
                response: {
                    respCode: "S0001",
                    historyUpload,
                }
            };
            historyUploadFileList.push(file);
        }

        return {
            ...state,
            addOrEditLicenseModal: {
                ...state.addOrEditLicenseModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditLicenseModal.fields,
                    guid: licenseInfo.guid,
                    employee: licenseInfo.employee,
                    licenseBasicInfo: licenseInfo.licenseBasicInfo,
                    licenseNo: licenseInfo.licenseNo,
                    regiStatus: licenseInfo.regiStatus,
                    licensedDate: licenseInfo.licensedDate,
                    examStatus: licenseInfo.examStatus,
                    examDeadline: licenseInfo.examDeadline,
                    examLicenseInfo: licenseInfo.examLicenseInfo,
                    latestUpload: licenseInfo.latestUpload,
                    historyUpload: licenseInfo.historyUpload,
                    remarks: licenseInfo.remarks,
                    feedback: licenseInfo.feedback,
                    latestUploadFileList: latestUploadFileList,
                    historyUploadFileList: historyUploadFileList,
                }
            },
        }
    }
}

function addOrEditLicenseChangeInput(state, {name, value}) {
    let updatedFields = null;
    if(name === 'licenseBasicInfoGuid') {
        updatedFields = {
            ...state.addOrEditLicenseModal.fields,
            licenseBasicInfo: {
                guid: value,
            },
        };
    } else {
        updatedFields = {
            ...state.addOrEditLicenseModal.fields,
            [name]: value,
        };
    }
    return {
        ...state,
        addOrEditLicenseModal: {
          ...state.addOrEditLicenseModal,
          fields: updatedFields,
        },
    };
}


// loadLicenseBasicInfoOptions
function startLoadLicenseBasicInfoOptions(state) {
    return {
      ...state,
      loadLicenseBasicInfoOptionsStatus: ACTION_STATUS.LOGINING,
    };
}
function loadLicenseBasicInfoOptionsSuccess(state, resp) {
    const licenseBasicInfoArray = resp.data;
    const licenseBasicInfoOptions = licenseBasicInfoArray.map(e => <Option key={e.guid}>{e.country} / {e.regulator} / {e.license}</Option>);
    return {
        ...state,
        loadLicenseBasicInfoOptionsStatus: ACTION_STATUS.SUCCESS,
        licenseBasicInfoOptions,
    };
}
function loadLicenseBasicInfoOptionsFailure(state, err) {
    return {
        ...state,
        loadLicenseBasicInfoOptionsStatus: ACTION_STATUS.ERROR,
    };
}
function initLoadLicenseBasicInfoOptionsActionStatus(state) {
    return {
        ...state,
        loadLicenseBasicInfoOptionsStatus: ACTION_STATUS.READY,
    };
}


// AddOrEditLicenseEmpRequiredRcd
function startAddOrEditLicenseEmpRequiredRcd(state) {
    return {
      ...state,
      addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditLicenseEmpRequiredRcdSuccess(state, resp) {
    return {
        ...state,
        addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditLicenseEmpRequiredRcdFailure(state, err) {
    return {
        ...state,
        addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.ERROR,
        addOrEditLicenseModal: {
            ...state.addOrEditLicenseModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditLicenseEmpRequiredRcdActionStatus(state) {
    return {
        ...state,
        addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.READY,
    };
}
function resetSubmitSaveLicenseModal(state) {
    return {
        ...state,
        addOrEditLicenseModal: initialState.addOrEditLicenseModal,
    }
}


// selectLicensingData
function selectLicensingData(state, array) {
    return {
        ...state,
        selectedLicenseArray: array,
    };
}


// batchDelLicenseInfo
function openDelLicenseModal(state,{mode}) {
    return {
        ...state,
        delLicenseModal: {
            ...state.delLicenseModal,
            isOpened: true,
            mode,
        },
    };
}
function delLicenseChangeInput(state, {name, value}) {
    return {
        ...state,
        delLicenseModal: {
            ...state.delLicenseModal,
            fields: {
                ...state.delLicenseModal.fields,
                [name]: value,  
            },
        },
    };
}


// batchDelLicenseInfo
function startBatchDelLicenseInfo(state) {
    return {
      ...state,
      batchDelLicenseInfoStatus: ACTION_STATUS.LOGINING,
    };
}
function batchDelLicenseInfoSuccess(state, resp) {
    return {
        ...state,
        batchDelLicenseInfoStatus: ACTION_STATUS.SUCCESS,
    };
}
function batchDelLicenseInfoFailure(state, err) {
    return {
        ...state,
        batchDelLicenseInfoStatus: ACTION_STATUS.ERROR,
        batchDelLicenseInfoResp: err,
    };
}
function initBatchDelLicenseInfoActionStatus(state) {
    return {
        ...state,
        batchDelLicenseInfoStatus: ACTION_STATUS.READY,
    };
}
function resetDelLicenseModal(state) {
    return {
        ...state,
        delLicenseModal: initialState.delLicenseModal,
    }
}


// selectCptRcdData
function selectCptRcdData(state, array) {
    return {
        ...state,
        selectedCptRcdDataArray: array,
    };
}


// openEditCptRcdModal
function openEditCptRcdModal(state, payload) {
    const latestUploadFileList = [];
    const latestUpload = payload.cptRcd.latestUpload;
    if(!!latestUpload) {
        const file = {
            uid: '-1',
            name: latestUpload,
            status: 'done',
            url: state.examResultAttBaseUrl + "/" + latestUpload,
            response: {
                respCode: "S0001",
                latestUpload,
            }
        };
        latestUploadFileList.push(file);
    }

    const historyUploadFileList = [];
    const historyUpload = payload.cptRcd.historyUpload;
    if(!!historyUpload) {
        const file = {
            uid: '-1',
            name: historyUpload,
            status: 'done',
            url: state.examResultAttBaseUrl + "/" + historyUpload,
            response: {
                respCode: "S0001",
                historyUpload,
            }
        };
        historyUploadFileList.push(file);
    }

    return {
        ...state,
        editCptRcdModal: {
            ...state.editCptRcdModal,
            isOpened: true,
            fields: {
                ...state.editCptRcdModal.fields,
                guid: payload.cptRcd.guid,
                employee: payload.employee,
                licenseBasic: payload.licenseBasic,
                year: payload.cptRcd.year,
                licenseEmpRequireRcd: payload.cptRcd.licenseEmpRequireRcd,
                cptHourRequire: payload.cptRcd.cptHourRequire,
                cptHourFinished: payload.cptRcd.cptHourFinished,
                cptHourAccepted: payload.cptRcd.cptHourAccepted,
                trainingInfo: payload.cptRcd.trainingInfo,
                latestUpload: payload.cptRcd.latestUpload,
                historyUpload: payload.cptRcd.historyUpload,
                remarks: payload.cptRcd.remarks,
                feedback: payload.cptRcd.feedback,
                comments: payload.cptRcd.comments,
                latestUploadFileList: latestUploadFileList,
                historyUploadFileList: historyUploadFileList,
            },
        },
    };
}


// editCptRcdModalChangeInput
function editCptRcdModalChangeInput(state, {name, value}) {
    return {
        ...state,
        editCptRcdModal: {
            ...state.editCptRcdModal,
            fields: {
                ...state.editCptRcdModal.fields,
                [name]: value,
            },
        },
    };
}


// edit cpt rcd
function startSubmitEditCptRcd(state) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.LOGINING,
      };
}
function submitEditCptRcdSuccess(state, resp) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitEditCptRcdFailure(state, err) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.ERROR,
        editCptRcdModal: {
            ...state.editCptRcdModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitEditCptRcdActionStatus(state) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.READY,
    };
}
function resetEditCptRcdModal(state) {
    return {
        ...state,
        editCptRcdModal: initialState.editCptRcdModal,
    }
}


// op rcd
export function openOperationRcdModal(state) {
    return {
        ...state,
        operationRcdModal: {
            ...state.operationRcdModal,
            isOpened: true,
        },
    }
}
function startQueryOperationRcd(state) {
    return {
        ...state,
        queryOperationRcdStatus: ACTION_STATUS.LOGINING,
      };
}
function queryOperationRcdSuccess(state, resp) {
    return {
        ...state,
        queryOperationRcdStatus: ACTION_STATUS.SUCCESS,
        queryOperationRcdResp: resp,
    };
}
function queryOperationRcdFailure(state, err) {
    return {
        ...state,
        queryOperationRcdStatus: ACTION_STATUS.ERROR,
        queryOperationRcdResp: err,
    };
}
function initQueryOperationRcdActionStatus(state) {
    return {
        ...state,
        queryOperationRcdStatus: ACTION_STATUS.READY,
    };
}
function resetOperationRcdModal(state) {
    return {
        ...state,
        operationRcdModal: initialState.operationRcdModal,
    }
}


function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
  
    return {
      ...state,
      userAccountArray,
      userAccountOptions,
    };
}

export default createReducer(initialState, {
    // onLicensingQueryInputChange
    [ON_LICENSING_QUERY_INPUT_CHANGE]: onLicensingQueryInputChange,
    // loadLicensingInfo
    [START_LOAD_LICENSING_INFO]: startLoadLicensingInfo,
    [LOAD_LICENSING_INFO_SUCCESS]: loadLicensingInfoSuccess,
    [LOAD_LICENSING_INFO_FAILURE]: loadLicensingInfoFailure,
    [INIT_LOAD_LICENSING_INFO_ACTION_STATUS]: initLoadLicensingInfoActionStatus,
    // add or edit license info
    [OPEN_ADD_OR_EDIT_LICENSE_MODAL]: openAddOrEditLicenseModal,
    [ADD_OR_EDIT_LICENSE_CHANGE_INPUT]: addOrEditLicenseChangeInput,
    // loadLicenseBasicInfoOptions
    [START_LOAD_LICENSE_BASIC_INFO_OPTIONS]: startLoadLicenseBasicInfoOptions,
    [LOAD_LICENSE_BASIC_INFO_OPTIONS_SUCCESS]: loadLicenseBasicInfoOptionsSuccess,
    [LOAD_LICENSE_BASIC_INFO_OPTIONS_FAILURE]: loadLicenseBasicInfoOptionsFailure,
    [INIT_LOAD_LICENSE_BASIC_INFO_OPTIONS_ACTION_STATUS]: initLoadLicenseBasicInfoOptionsActionStatus,
    // addOrEditLicenseModal
    [START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD]: startAddOrEditLicenseEmpRequiredRcd,
    [ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS]: addOrEditLicenseEmpRequiredRcdSuccess,
    [ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE]: addOrEditLicenseEmpRequiredRcdFailure,
    [INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS]: initAddOrEditLicenseEmpRequiredRcdActionStatus,
    [RESET_SUBMIT_SAVE_LICENSE_MODAL]: resetSubmitSaveLicenseModal,
    // selectLicensingData
    [SELECT_LICENSING_DATA]: selectLicensingData,
    // batchDelLicenseInfo
    [OPEN_DEL_LICENSE_MODAL]: openDelLicenseModal,
    [DEL_LICENSE_CHANGE_INPUT]: delLicenseChangeInput,
    [START_BATCH_DEL_LICENSE_INFO]: startBatchDelLicenseInfo,
    [BATCH_DEL_LICENSE_INFO_SUCCESS]: batchDelLicenseInfoSuccess,
    [BATCH_DEL_LICENSE_INFO_FAILURE]: batchDelLicenseInfoFailure,
    [INIT_BATCH_DEL_LICENSE_INFO_ACTION_STATUS]: initBatchDelLicenseInfoActionStatus,
    [RESET_DEL_LICENSE_MODAL]: resetDelLicenseModal,
    // selectCptRcdData
    [SELECT_CPT_RCD_DATA]: selectCptRcdData,
    // openEditCptRcdModal
    [OPEN_EDIT_CPT_RCD_MODAL]: openEditCptRcdModal,
    // editCptRcdModalChangeInput
    [EDIT_CPT_RCD_MODAL_CHANGE_INPUT]: editCptRcdModalChangeInput,
    // edit cpt rcd
    [START_SUBMIT_EDIT_CPT_RCD]: startSubmitEditCptRcd,
    [SUBMIT_EDIT_CPT_RCD_SUCCESS]: submitEditCptRcdSuccess,
    [SUBMIT_EDIT_CPT_RCD_FAILURE]: submitEditCptRcdFailure,
    [INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS]: initSubmitEditCptRcdActionStatus,
    [RESET_EDIT_CPT_RCD_MODAL]: resetEditCptRcdModal,
    // op rcd
    [OPEN_OPERATION_RCD_MODAL]: openOperationRcdModal,
    [START_QUERY_OPERATION_RCD]: startQueryOperationRcd,
    [QUERY_OPERATION_RCD_SUCCESS]: queryOperationRcdSuccess,
    [QUERY_OPERATION_RCD_FAILURE]: queryOperationRcdFailure,
    [INIT_QUERY_OPERATION_RCD_ACTION_STATUS]: initQueryOperationRcdActionStatus,
    [RESET_OPERATION_RCD_MODAL]: resetOperationRcdModal,

    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

});
