import {
  // Load Restricted Rules
  START_LOAD_RESTRICTED_RULES,
  LOAD_RESTRICTED_RULES_SUCCESS,
  LOAD_RESTRICTED_RULES_FAILURE,
  INIT_LOAD_RESTRICTED_RULES_ACTION_STATUS,

  // Create Restricted Rule
  START_CREATE_RESTRICTED_RULE,
  CREATE_RESTRICTED_RULE_SUCCESS,
  CREATE_RESTRICTED_RULE_FAILURE,
  INIT_CREATE_RESTRICTED_RULE_ACTION_STATUS,

  // Update Restricted Rule
  START_UPDATE_RESTRICTED_RULE,
  UPDATE_RESTRICTED_RULE_SUCCESS,
  UPDATE_RESTRICTED_RULE_FAILURE,
  INIT_UPDATE_RESTRICTED_RULE_ACTION_STATUS,

  // Batch Delete Restricted Rule
  START_BATCH_DELETE_RESTRICTED_RULE,
  BATCH_DELETE_RESTRICTED_RULE_SUCCESS,
  BATCH_DELETE_RESTRICTED_RULE_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_RULE_ACTION_STATUS,

  // Open/Reset Restricted Rule Modal Dialog
  RESTRICTED_RULE_MODAL_DIALOG_OPEN,
  RESTRICTED_RULE_MODAL_DIALOG_RESET,

  // Restricted Rule Edit Change Input
  RESTRICTED_RULE_EDIT_CHANGE_INPUT,

  // Load BBG Tickers
  START_LOAD_BBG_TICKERS,
  LOAD_BBG_TICKERS_SUCCESS,
  LOAD_BBG_TICKERS_FAILURE,
  INIT_LOAD_BBG_TICKERS_ACTION_STATUS,

  // Clear bbgTickerArray bbgTickerOptions
  CLEAR_BBG_TICKERS,

  // BBG Ticker Select Changed
  BBG_TICKER_SELECT_CHANGED,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,

  // Select Restricted Rules
  SELECT_RESTRICTED_RULES,

  // Show Valid Restricted Rules
  SHOW_VALID_RESTRICTED_RULES,

  // Show All Restricted Rules
  SHOW_ALL_RESTRICTED_RULES,
} from './restrictedRuleConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Restricted Rules
function startLoadRestrictedRules() {
  return {
    type: START_LOAD_RESTRICTED_RULES
  };
}

export function loadRestrictedRules() {
  return function(dispatch) {
    dispatch(startLoadRestrictedRules());
    httpClient
      .get(UrlConfig.QUERY_RESTRICTED_RULES)
      .then(resp => dispatch(loadRestrictedRulesSuccess(resp)))
      .catch(err => dispatch(loadRestrictedRulesFailure(err)));
  };
}

function loadRestrictedRulesSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_RULES_SUCCESS,
    payload: resp
  };
}

function loadRestrictedRulesFailure(err) {
  return {
    type: LOAD_RESTRICTED_RULES_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedRulesActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_RULES_ACTION_STATUS
  };
}

// Create Restricted Rule
function startCreateRestrictedRule() {
  return {
    type: START_CREATE_RESTRICTED_RULE
  };
}

export function createRestrictedRule(restrictedRule) {
  return function(dispatch) {
    dispatch(startCreateRestrictedRule());
    httpClient
      .post(UrlConfig.NEW_RESTRICTED_RULE, restrictedRule)
      .then(resp => dispatch(createRestrictedRuleSuccess(resp)))
      .catch(err => dispatch(createRestrictedRuleFailure(err)));
  };
}

function createRestrictedRuleSuccess(resp) {
  return {
    type: CREATE_RESTRICTED_RULE_SUCCESS,
    payload: resp
  };
}

function createRestrictedRuleFailure(err) {
  return {
    type: CREATE_RESTRICTED_RULE_FAILURE,
    payload: err
  };
}

export function initCreateRestrictedRuleActionStatus() {
  return {
    type: INIT_CREATE_RESTRICTED_RULE_ACTION_STATUS
  };
}

// Update Restricted Rule
function startUpdateRestrictedRule() {
  return {
    type: START_UPDATE_RESTRICTED_RULE
  };
}

export function updateRestrictedRule(restrictedRule) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedRule());
    httpClient
      .post(UrlConfig.UPDATED_RESTRICTED_RULE, restrictedRule)
      .then(resp => dispatch(updateRestrictedRuleSuccess(resp)))
      .catch(err => dispatch(updateRestrictedRuleFailure(err)));
  };
}

function updateRestrictedRuleSuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_RULE_SUCCESS,
    payload: resp
  };
}

function updateRestrictedRuleFailure(err) {
  return {
    type: UPDATE_RESTRICTED_RULE_FAILURE,
    payload: err
  };
}

export function initUpdateRestrictedRuleActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_RULE_ACTION_STATUS
  };
}

// Batch Delete Restricted Rule
function startBatchDeleteRestrictedRule() {
  return {
    type: START_BATCH_DELETE_RESTRICTED_RULE
  };
}

export function batchDeleteRestrictedRule(restrictedRuleIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteRestrictedRule());
    httpClient
      .post(UrlConfig.BATCH_DELETED_RESTRICTED_RULES, restrictedRuleIdList)
      .then(resp => dispatch(batchDeleteRestrictedRuleSuccess(resp)))
      .catch(err => dispatch(batchDeleteRestrictedRuleFailure(err)));
  };
}

function batchDeleteRestrictedRuleSuccess(resp) {
  return {
    type: BATCH_DELETE_RESTRICTED_RULE_SUCCESS,
    payload: resp
  };
}

function batchDeleteRestrictedRuleFailure(err) {
  return {
    type: BATCH_DELETE_RESTRICTED_RULE_FAILURE,
    payload: err
  };
}

export function initBatchDeleteRestrictedRuleActionStatus() {
  return {
    type: INIT_BATCH_DELETE_RESTRICTED_RULE_ACTION_STATUS
  };
}



// =================================================================
// Open/Reset Restricted Rule Modal Dialog
export function openRestrictedRuleEditModalDialog(isAddMode, restrictedRule) {
  return {
    type: RESTRICTED_RULE_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      restrictedRule,
    },
  };
}

export function resetRestrictedRuleEditModalDialog() {
  return {
    type: RESTRICTED_RULE_MODAL_DIALOG_RESET
  };
}

// Restricted Rule Edit Change Input
export function restrictedRuleEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_RULE_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// =================================================================
// Load BBG Tickers
function startLoadBBGTickers() {
  return {
    type: START_LOAD_BBG_TICKERS
  };
}

export function loadBbgTickers(queryText) {
  const requestParams = {
    'query-text': queryText,
    'page': 'restrictedRule',
    'source': 'Admin',
  };

  return function(dispatch) {
    dispatch(startLoadBBGTickers());
    httpClient
      .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
      .then(resp => dispatch(loadBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadBbgTickersFailure(err)));
  };
}

function loadBbgTickersSuccess(resp) {
  return {
    type: LOAD_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadBbgTickersFailure(err) {
  return {
    type: LOAD_BBG_TICKERS_FAILURE,
    payload: err
  };
}

export function initLoadBbgTickersActionStatus() {
  return {
    type: INIT_LOAD_BBG_TICKERS_ACTION_STATUS
  };
}

// Clear bbgTickerArray bbgTickerOptions
export function clearBbgTickers() {
  return {
    type: CLEAR_BBG_TICKERS,
  };
}

// BBG Ticker Select Changed
export function bbgTickerSelectChanged(bbgTicker) {
  return {
    type: BBG_TICKER_SELECT_CHANGED,
    payload: bbgTicker
  };
}

// Fund Code Select Changed
export function fundCodeSelectChanged(fundCode) {
  return {
    type: FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Book Code Select Changed
export function bookCodeSelectChanged(bookCode) {
  return {
    type: BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}

// Select Restricted Rule
export function selectRestrictedRules(payload) {
  return {
    type: SELECT_RESTRICTED_RULES,
    payload
  };
}

// Show Valid Restricted Rules
export function showValidRestrictedRules() {
  return {
    type: SHOW_VALID_RESTRICTED_RULES,
  };
}

// Show All Restricted Rules
export function showAllRestrictedRules() {
  return {
    type: SHOW_ALL_RESTRICTED_RULES,
  };
}