import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


class OperationRcdGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: props.operationRcdGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 100,
            },
        };
    }

    
    render() {
        const {
            queryOperationRcdResp,
            queryOperationRcdStatus,
        } = this.props;

        const isLoading = queryOperationRcdStatus === ACTION_STATUS.LOGINING;

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={queryOperationRcdResp}
                            defaultColDef={this.state.defaultColDef}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                         />
                    )}
            </div>
        );
    }
}


export default OperationRcdGrid;
