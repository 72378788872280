import React, {Component} from 'react';

import { Radio, Breadcrumb, Button, message, Modal } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedCountryGrid from './RestrictedCountryGrid';
import RestrictedCountryEditDialog from './RestrictedCountryEditDialog';
import RadioOperationMode from '../../../../../utils/RadioOperationMode';
import '../../ComplianceRule.css';

const confirm = Modal.confirm;

class RestrictedCountry extends Component {


  componentDidMount () {
    this.props.loadRestrictedCountries();
  }

  componentDidUpdate() {
    const { 
      batchDeleteRestrictedCountryActionStatus,
    } = this.props;
  
    // 批量删除 Restricted Country 成功
    if (batchDeleteRestrictedCountryActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted Country are deleted successfully!');
      this.props.loadRestrictedCountries();
      this.props.initBatchDeleteRestrictedCountryActionStatus();
    }
      
    // 批量删除 Restricted Country 失败
    if (batchDeleteRestrictedCountryActionStatus === ACTION_STATUS.ERROR) {
      message.success('Restricted Country are deleted failed!');
      this.props.initBatchDeleteRestrictedCountryActionStatus();
    }
  }

  addButtonOnClicked = () => {
    this.props.openRestrictedCountryEditModalDialog(true, null);
  }

  editButtonOnClicked = () => {
    const {
      selectedRestrictedCountry
    } = this.props;

    if (selectedRestrictedCountry.length !== 1) {
      message.warning("Please select one restricted country!");
      return;
    }

    this.props.openRestrictedCountryEditModalDialog(false, selectedRestrictedCountry[0]);
  }

  delButtonOnClicked = () => {
    const {
      selectedRestrictedCountry
    } = this.props;

    if (selectedRestrictedCountry.length === 0) {
      message.warning("Please select one restricted country at least!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the restricted country?',
      content: '',
      onOk() {
        const restrictedCountryIdList = selectedRestrictedCountry.map(restrictedCountry => restrictedCountry.id);

        that.props.batchDeleteRestrictedCountry(restrictedCountryIdList);
      },
      onCancel() {},
    });
  }

  radioGroupOnChanged = (e) => {
    this.props.radioGroupChange(e.target.value);
  }

  render() {
    
    return (
      <div className="restrictedCountryWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>Restricted Country</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        

        <div className='radioGroupWrapper'>
          <Radio.Group defaultValue="1" onChange={this.radioGroupOnChanged}>
            <Radio.Button value={RadioOperationMode.ALL}>All</Radio.Button>
            <Radio.Button value={RadioOperationMode.WHITE}>White List</Radio.Button>
            <Radio.Button value={RadioOperationMode.BLACK}>Black List</Radio.Button>
          </Radio.Group>
        </div>

        <div className='operationBar'>
          <Button 
            onClick={this.addButtonOnClicked} 
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            className='positive-button'>
            Edit
          </Button>

          <Button 
            onClick={this.delButtonOnClicked} 
            size='small'
            className='negative-button'>
            Del
          </Button>
        </div>

        <div className='restrictedCountryGrid'>
          <RestrictedCountryGrid {...this.props} />
        </div>

        <RestrictedCountryEditDialog {...this.props} />
      </div>
    );
  }
}

export default RestrictedCountry;