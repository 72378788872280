import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Tabs, message } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import CounterPartyGrid from './CounterPartyGrid';
import '../../EntityAccount.css';

const { TabPane } = Tabs;

class CounterPartyDashboard extends Component {

  componentDidMount() {
    this.props.loadCounterParty();
  }
  
  render() {
    return (
      <div className="personalTradeWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Entity & Account</Breadcrumb.Item>
            <Breadcrumb.Item>CounterParty</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Tabs defaultActiveKey="1" size="small">
          <TabPane tab="List" key="1">
            <CounterPartyGrid {...this.props} />
          </TabPane>
          <TabPane tab="Holding" key="2">
            
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default CounterPartyDashboard;