import {
  // Load Custodian
  START_LOAD_CUSTODIAN,
  LOAD_CUSTODIAN_SUCCESS,
  LOAD_CUSTODIAN_FAILURE,
  INIT_LOAD_CUSTODIAN_ACTION_STATUS,

  // Load Custodian Account
  START_LOAD_CUSTODIAN_ACCOUNT,
  LOAD_CUSTODIAN_ACCOUNT_SUCCESS,
  LOAD_CUSTODIAN_ACCOUNT_FAILURE,
  INIT_LOAD_CUSTODIAN_ACCOUNT_ACTION_STATUS,
} from './custodianConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Custodian
function startLoadCustodian() {
  return {
    type: START_LOAD_CUSTODIAN
  };
}

export function loadCustodian() {
  return function(dispatch) {
    dispatch(startLoadCustodian());
    httpClient
      .get(UrlConfig.QUERY_CUSTODIAN_LIST)
      .then(resp => dispatch(loadCustodianSuccess(resp)))
      .catch(err => dispatch(loadCustodianFailure(err)));
  };
}

function loadCustodianSuccess(resp) {
  return {
    type: LOAD_CUSTODIAN_SUCCESS,
    payload: resp
  };
}

function loadCustodianFailure(err) {
  return {
    type: LOAD_CUSTODIAN_FAILURE,
    payload: err
  };
}

export function initLoadCustodianActionStatus() {
  return {
    type: INIT_LOAD_CUSTODIAN_ACTION_STATUS
  };
}

// Load Custodian Account
function startLoadCustodianAccount() {
  return {
    type: START_LOAD_CUSTODIAN_ACCOUNT
  };
}

export function loadCustodianAccount() {
  return function(dispatch) {
    dispatch(startLoadCustodianAccount());
    httpClient
      .get(UrlConfig.QUERY_CUSTODIAN_ACCOUNT_LIST)
      .then(resp => dispatch(loadCustodianAccountSuccess(resp)))
      .catch(err => dispatch(loadCustodianAccountFailure(err)));
  };
}

function loadCustodianAccountSuccess(resp) {
  return {
    type: LOAD_CUSTODIAN_ACCOUNT_SUCCESS,
    payload: resp
  };
}

function loadCustodianAccountFailure(err) {
  return {
    type: LOAD_CUSTODIAN_ACCOUNT_FAILURE,
    payload: err
  };
}

export function initLoadCustodianAccountActionStatus() {
  return {
    type: INIT_LOAD_CUSTODIAN_ACCOUNT_ACTION_STATUS
  };
}