import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

class YearPicker extends React.Component {
  state = {
    mode: 'year',
    value: this.props.value ? moment(this.props.value, 'YYYY') : null,
  };

  handlePanelChange = (value) => {
    this.setState({ value, mode: 'year' });
    this.props.onChange(value, value ? value.format('YYYY') : '');
  };

  handleOpenChange = (status) => {
    if (!status) {
      this.setState({ mode: 'year' });
    }
  };

  render() {
    return (
      <DatePicker
        placeholder="Select year"
        allowClear={false}
        value={this.state.value}
        mode={this.state.mode}
        format="YYYY"
        size="small"
        onChange={() => {}}
        onPanelChange={this.handlePanelChange}
        onOpenChange={this.handleOpenChange}
        style={{ marginLeft: '10px' }}
      />
    );
  }
}

export default YearPicker;