import React, { Component } from 'react';
import { Redirect, Route, Switch, NavLink } from 'react-router-dom';
import { CMS_ADMIN_ROLE_PRIFIX } from '../../../../utils/UserRoleConstant';


// Approval Request
import BannedTradeRequestContainer from '../../approvalRequest/bannedTradeRequest/containers/BannedTradeRequestContainer';
import GiftHospitalityRequestContainer from '../../approvalRequest/giftHospitalityRequest/containers/GiftHospitalityRequestContainer';
import AccountHoldingDashboardContainer from '../../approvalRequest/personalAccount/containers/AccountHoldingDashboardContainer';
import PersonalTradeContainer from '../../approvalRequest/personalTrade/containers/PersonalTradeContainer';

// Compliance Rule
import PBShortInterestRateContainer from '../../complianceRule/pbShortInterestRate/containers/PBShortInterestRateContainer';
import RestrictedAssetContainer from '../../complianceRule/restrictedAsset/containers/RestrictedAssetContainer';
import RestrictedCountryContainer from '../../complianceRule/restrictedCountry/containers/RestrictedCountryContainer';
import RestrictedSectorContainer from '../../complianceRule/restrictedSector/containers/RestrictedSectorContainer';

import RestrictedSecurityContainer from '../../complianceRule/restrictedSecurity/containers/RestrictedSecurityContainer';
import RestrictedSecurityRequestContainer from '../../complianceRule/restrictedSecurityRequest/containers/RestrictedSecurityRequestContainer';

import SFCShortableListContainer from '../../complianceRule/sfcShort/containers/SFCShortableListContainer';
import RestrictedRuleContainer from '../../complianceRule/restrictedRule/containers/RestrictedRuleContainer';

// Questionnaire
import QuestionnaireListContainer from '../../questionnaire/containers/QuestionnaireListContainer';
import AnswerReviewContainer from '../../questionnaire/containers/AnswerReviewContainer';

// Licensing Form  
import LicensingFormListContainer from '../../licens/licensingForm/containers/LicensingFormListContainer';
import PresonalInfoContainer from '../../licens/presonalInfo/containers/PresonalInfoContainer';
import RegisterContainer from '../../licens/register/containers/RegisterContainer';
import CptContainer from '../../licens/cpt/containers/CptContainer';
// pinpoint-group
import GroupStructureContainer from '../../pinpoint-group/group-structure/containers/GroupStructureContainer';
import ElementTypeMngContainer from '../../pinpoint-group/element-type-mng/containers/ElementTypeMngContainer';
import ElementMngContainer from '../../pinpoint-group/element-mng/containers/ElementMngContainer';

// Entity Account 
import FundContainer from '../../entityAcount/fund/containers/FundContainer';
import BookContainer from '../../entityAcount/book/containers/BookContainer';
import CounterPartyContainer from '../../entityAcount/counterParty/containers/CounterPartyContainer';
import CustodianContainer from '../../entityAcount/custodian/containers/CustodianContainer';
import UserAccessContainer from '../../entityAcount/userAccess/containers/UserAccessContainer';
import RatingManagementContainer from '../../entityAcount/ratingManagement/containers/RatingManagementContainer';
// Document & Report
import ShortStockContainer from '../../documentReport/shortStock/containers/ShortStockContainer';
import ThirteenFReportContainer from '../../documentReport/thirteenFReport/containers/ThirteenFReportContainer';
import ExchangeRateContainer from '../../documentReport/exchangeRate/containers/ExchangeRateContainer';
import ShortPositionReportContainer from '../../documentReport/shortPositionReport/containers/ShortPositionReportContainer';
import FundLevelReportContainer from '../../documentReport/fundLevelReport/containers/FundLevelReportContainer';
import ThirteenFLongPosReportContainer from '../../documentReport/thirteenFLongPosReport/containers/ThirteenFLongPosReportContainer';

// Fund monitoring
import TradesReviewContainer from '../../fundMonitoring/tradesReview/containers/TradesReviewContainer';
import PortfolioMonitoringRuleContainer from '../../departments/portfolio-monitoring-rule/containers/PortfolioMonitoringRuleContainer';

// Licensing
import LicensingContainer from '../../licensing/containers/LicensingContainer';

// departments
import ExpenseSummaryContainer from '../../departments/expense-summary/containers/ExpenseSummaryContainer';
import ExpenseSummaryNewContainer from '../../departments/expense-summary-new/containers/ExpenseSummaryNewContainer';
import ReportingSummaryContainer from '../../departments/reporting-summary/containers/ReportingSummaryContainer';
import WorkManagementContainer from '../../departments/work-management/containers/WorkManagementContainer';

// PendingTasks
import PendingTasksContainer from '../../pendingTasks/containers/PendingTasksContainer';

// Agreement
import GeneralAgreementContainer from '../../agreement/general-agreement/containers/GeneralAgreementContainer';

import MeetingAndTrainingContainer from '../../pinpointCollege/meetingAndTraining/containers/MeetingAndTrainingContainer';
import MeetingAndTrainingReviewContainer from '../../pinpointCollege/meetingAndTrainingReview/containers/MeetingAndTrainingReviewContainer';
import MeetingAndTrainingGroupContainer from '../../pinpointCollege/meetingAndTrainingGroup/containers/MeetingAndTrainingGroupContainer';
import RotationReviewContainer from '../../pinpointCollege/rotationReview/containers/RotationReviewContainer';
import MeetingAndTrainingApplyRecordContainer from '../../pinpointCollege/meetingAndTrainingApplyRecord/containers/MeetingAndTrainingApplyRecordContainer';
import BrokerReviewContainer from '../../pinpointCollege/brokerReview/containers/BrokerReviewContainer';
import MeetingAndTrainingCalenderContainer from '../../pinpointCollege/meetingAndTrainingCalender/containers/MeetingAndTrainingCalenderContainer';


import { localStorageClient } from '../../../../utils/localStorageClient';

import { Layout, Menu, Icon, Button, Modal, Dropdown, Avatar, Tabs, Badge, message } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import MonthlyReportContainer from '../../departments/monthly-report/containers/MonthlyReportContainer';
const { Header, Sider, Content } = Layout;

const SubMenu = Menu.SubMenu;

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1107319_u9lpf6s82f.js',
});


class Home extends Component {
  state = {
    collapsed: false,
  };

  componentDidMount () {
    // Load User Account
    this.props.loadUserAccounts();

    // Load Security Tickers
    this.props.loadSecurityTickers();

    // Load Fund Book Data
    this.props.loadFundBookData();

    // Load All Bbg Tickers
    // this.props.loadAllBbgTickers();

    // Load Asset Inst Class
    this.props.loadAssetInstClass();

    // Load Country Codes
    this.props.loadCountryCodes();
        // Load Country Codes
    this.props.loadCurrencyCodes();

    // Load Sector Codes
    this.props.loadSectorCodes();

    // get hierarchy menus
    this.startHierarchyMenusRefreshTask();

    // pendingTasksNum
    this.startCountDownOfPendingTasks();
    
    
  }

  componentWillUnmount() {
    this.stopHierarchyMenusRefreshTask();
    this.stopCountDownOfPendingTasks();
  }


  hierarchyMenusRefreshTask = null;
  startHierarchyMenusRefreshTask = () => {
    this.props.getHierarchyMenus();
    this.hierarchyMenusRefreshTask =  setInterval(() => {
      this.props.getHierarchyMenus();
    }, 1*60*60*1000);  // 每1小时
  }
  stopHierarchyMenusRefreshTask = () => {
    if(!!this.hierarchyMenusRefreshTask) {
      clearInterval(this.hierarchyMenusRefreshTask);
    }
  }

  
  pendingTasksNumTask = null;
  startCountDownOfPendingTasks = () => {
    this.props.queryPendingTasksNum();
    this.pendingTasksNumTask = setInterval(() => {
      this.props.queryPendingTasksNum();
    }, 5*60*1000);  // 每5分钟
  }
  stopCountDownOfPendingTasks = () => {
    if(!!this.pendingTasksNumTask) {
      clearInterval(this.pendingTasksNumTask);
    }
  }


  componentDidUpdate() {

  }



  // 获得当前访问的菜单
  getCurrMenu = () => {
    const pathname = window.location.pathname;
    let currMenu = null;
    const flatMenus = this.props.flatMenus;
    if(!!flatMenus) {
      const selectedMenu = flatMenus.filter(e => e.module === pathname);
      if(!!selectedMenu && selectedMenu.length > 0) {
        currMenu = selectedMenu[0];
      }
    }
    return currMenu;
  }
  // 检查权限
  checkAuthorization = (currMenu) => {
    // if(!!currMenu)  {
    //   if(currMenu.type == 'SubMenu') {
    //     return true;  // SubMenu都可以访问
    //   }
    //   const userRoleStr = localStorageClient.getRole();
    //   const userRoleArray = userRoleStr.split(',');
    //   const allowedRoleArray = currMenu.accessableRoleNames;
    //   let authorized = false;
    //   for(let i in userRoleArray) {
    //     if(userRoleArray[i] === 'CMS_ADMIN_USER') {
    //       authorized = true;  // 默认CMS_ADMIN_USER可以访问所有菜单
    //       break;
    //     }
    //     for(let j in allowedRoleArray) {
    //       if(userRoleArray[i] === allowedRoleArray[j]){
    //         authorized = true;
    //         break;
    //       }
    //     }
    //     if(authorized) {
    //       break;
    //     }
    //   }
    //   return authorized;
    // }
    return true;
  }



  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  logoutButtonOnClicked = () => {
    this.props.logout();
  }



  render() {

    // 每次都需要检查用户角色，不满足则要登出
    const userRoleStr = localStorageClient.getRole();
    // 没有userRole一般是CMS登出了之后，其他支持单点登录的系统又登录了
    // 此时先步检查userRole，代码先往后走，根据token重新获得用户信息后，再在这里重新检查
    if(!!userRoleStr) {
      const userRoleList = userRoleStr.split(',');
      let authorized = false;
      if(!!userRoleList) {
        userRoleList.forEach(e => {
          if(!!e && e.startsWith(CMS_ADMIN_ROLE_PRIFIX)) {
            authorized = true;
          }
        })
      } 
      if(!authorized) {
        // 重定向到login页面重新做检查，以及做错误提示
        message.error('You are not authorized to access CMS Admin End.');
        return <Redirect to="/login" />;
      }
    }

    
    // 前端菜单权限检查
    // pendingTasks是例外，不做检查
    const pathname = window.location.pathname;
    if(pathname !== '/home/pending-tasks/pending-tasks') {
      const currMenu = this.getCurrMenu();
      if(!currMenu && !!this.props.flatMenus) {  // 第一次render时，flatMenus还不存在
        return (
          <Redirect to="/home/pending-tasks/pending-tasks?status=Pending" />
        );
      }
      if(!!this.props.flatMenus) {  // 第一次render时，flatMenus还不存在
        const authorized = this.checkAuthorization(currMenu);
        if(!authorized) {  
          message.error('You are not authorized to access this page.');
          return (
            <Redirect to="/home/pending-tasks/pending-tasks?status=Pending" />
          );
        }
      }
    }


    const menu = (
      <Menu>
        <Menu.Item key="logout" onClick={this.logoutButtonOnClicked}>
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );

    const employee = localStorageClient.getUserName();
    const hierarchyMenuOptions = this.props.hierarchyMenuOptions;


    const {
      pendingTasksNum,
    } = this.props;

    return (
      <div>
        <style>
          {`
            .trigger {
              font-size: 18px;
              line-height: 64px;
              padding: 0 24px;
              cursor: pointer;
              transition: color .3s;
              left: 240px;
            }
            
            .trigger:hover {
              color: #1890ff;
            }
            
            .logo {
              height: 32px;
              background: rgba(255,255,255,.2);
              margin: 16px;
            }

            .ant-layout {
              min-height: 100vh;
            }

            .ant-layout-header {
              background: #fff;
              padding: 0px;
              display: flex;
              justify-content: space-between;
            }

            .ant-tabs-bar {
              margin-bottom: 0px;
            }

            .ant-tabs-tabpane {
              height: calc(100vh - 104px);
              margin-top: 0px;
            }
          `}
        </style>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            width={250}
            collapsed={this.state.collapsed}
            style={{
              overflow: 'auto',
              height: '100vh',
              // position: 'fixed',
              left: 0,
            }}
          >
            <div className="logo" />
              <Menu theme="dark" mode="inline">

                {/* Pending Tasks 
                    例外，由于有角标，这里单独写死代码，且暂不控制权限
                */}
                 <Menu.Item key="pending-tasks-menu">
                      <NavLink  to="/home/pending-tasks/pending-tasks?status=Pending" >
                          {'Pending Tasks'}
                          <Badge  size='small'
                                  offset={[10, -20]} 
                                  showZero 
                                  count={pendingTasksNum} 
                                  style={pendingTasksNum==0 ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF3030' }}
                          />
                      </NavLink>
                </Menu.Item>
                {hierarchyMenuOptions}
              </Menu>
          </Sider>

          <Layout>
            <Header>
              <Icon
                className="trigger"
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
              />
              <div>
                {/* <span style={{ marginRight: 10 }}>{ localStorageClient.getToken() }</span>
                <Button style={{ marginRight: 10 }} onClick={this.logoutButtonOnClicked}>Logout</Button> */}
                <Dropdown
                  overlay={menu}
                  onVisibleChange={this.handleVisibleChange}
                  visible={this.state.visible}
                >
                  <span>
                    <Avatar
                      size="small"
                      alt="avatar"
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ marginRight: 20 }}>{employee}</span>
                  </span>
                </Dropdown>
              </div>
            </Header>
              {/* <Content style={{ height: '100%' }}> */}
              <Content>
                <Switch>
                  {/* Pending Tasks */}
                  <Route path="/home/pending-tasks/pending-tasks" component={PendingTasksContainer} />

                  {/* Monitoring */}
                  <Route path="/home/monitoring/employee-dealing/pa-dealing-application" component={PersonalTradeContainer} />
                  <Route path="/home/monitoring/employee-dealing/employee-pa-holding" component={AccountHoldingDashboardContainer} />
                  <Route path="/home/monitoring/gift-hospitality-report" component={GiftHospitalityRequestContainer} />
                  <Route path="/home/monitoring/licensing" component={LicensingContainer} />

                  {/* External Reporting */}
                  <Route path="/home/external-reporting/sec-13f/security-list" component={ThirteenFReportContainer} />
                  <Route path="/home/external-reporting/sec-13f/13f-report" component={ThirteenFLongPosReportContainer} />

                  <Route path="/home/external-reporting/hong-kong/sfc-short-position/hkex-security-list" component={ShortStockContainer} />
                  <Route path="/home/external-reporting/hong-kong/sfc-short-position/hkex-fx-rate" component={ExchangeRateContainer}/>
                  <Route path="/home/external-reporting/hong-kong/sfc-short-position/sfc-short-position-report" component={ShortPositionReportContainer}/>

                  {/* Fund Monitoring */}
                  <Route path="/home/fund-monitoring/fund-position" component={FundLevelReportContainer}/>
                  <Route path="/home/fund-monitoring/trades-review" component={TradesReviewContainer}/>
                  <Route path="/home/fund-monitoring/portfolio-monitoring-rule" component={PortfolioMonitoringRuleContainer} />


                  {/* Pinpoint Group */}
                  <Route path="/home/group-structure" component={GroupStructureContainer}/>
                  <Route path="/home/pinpoint-group/element-type-mng" component={ElementTypeMngContainer}/>
                  <Route path="/home/pinpoint-group/element-mng" component={ElementMngContainer}/>

                  {/* Approval Request */}
                  <Route path="/home/approval-request/banned-trade-request" component={BannedTradeRequestContainer} />
                  <Route path="/home/approval-request/gift-hospitality-request" component={GiftHospitalityRequestContainer} />
                  <Route path="/home/approval-request/account-holding-dashboard" component={AccountHoldingDashboardContainer} />
                  <Route path="/home/approval-request/personal-trade" component={PersonalTradeContainer} />

                  {/* Compliance Rule */}
                  <Route path="/home/compliance-rule/pb-short-interest-rate" component={PBShortInterestRateContainer} />
                  <Route path="/home/compliance-rule/restricted-asset" component={RestrictedAssetContainer} />
                  <Route path="/home/compliance-rule/restricted-country" component={RestrictedCountryContainer} />
                  <Route path="/home/compliance-rule/restricted-sector" component={RestrictedSectorContainer} />

                  {/* Restricted Security */}
                  <Route path="/home/monitoring/restricted-security/restricted-sec-list" component={RestrictedRuleContainer} />
                  <Route path="/home/monitoring/restricted-security/restricted-sec-application" component={RestrictedSecurityRequestContainer} />

                  <Route path="/home/compliance-rule/sfc-shortable-list" component={SFCShortableListContainer} />
                  <Route path="/home/compliance-rule/new-restricted-security" component={RestrictedSecurityContainer} />

                  {/* Questionnaire */}
                  <Route path="/home/questionnaire/question-list" component={QuestionnaireListContainer} />
                  <Route path="/home/questionnaire/answer-review" component={AnswerReviewContainer} />
                  {/* Personal Info */}
                  {/* <Route path="/home/monitoring/licensing-form-list" component={LicensingFormListContainer} /> */}
                  <Route path="/home/monitoring/presonal-info" component={PresonalInfoContainer} />
                  {/* <Route path="/home/monitoring/register" component={RegisterContainer} /> */}
                  {/* <Route path="/home/monitoring/cpt" component={CptContainer} /> */}

                  {/* Entity Account */}
                  <Route path="/home/entity-account/fund" component={FundContainer} />
                  <Route path="/home/entity-account/book" component={BookContainer} />
                  <Route path="/home/entity-account/counterparty" component={CounterPartyContainer} />
                  <Route path="/home/entity-account/custodian" component={CustodianContainer} />
                  <Route path="/home/entity-account/user-access" component={UserAccessContainer} />


                  {/* Document & Report */}
                  <Route path="/home/document-report/short-stock" component={ShortStockContainer} />
                  <Route path="/home/document-report/13f-report" component={ThirteenFReportContainer}/>
                  <Route path="/home/document-report/exchange-rate" component={ExchangeRateContainer}/>
                  <Route path="/home/document-report/short-pos-report" component={ShortPositionReportContainer}/>
                  <Route path="/home/document-report/fund-level-report" component={FundLevelReportContainer}/>
                  <Route path="/home/document-report/13f-long-report" component={ThirteenFLongPosReportContainer}/>

                  {/* Departments */}
                  <Route path="/home/departments/expenses-summary" component={ExpenseSummaryContainer} />
                  <Route path="/home/departments/expenses-summary-new" component={ExpenseSummaryNewContainer} />
                  <Route path="/home/departments/reporting-summary" component={ReportingSummaryContainer} />
                  <Route path="/home/departments/monthly-report" component={MonthlyReportContainer} />

                  <Route path="/home/departments/work-management" component={WorkManagementContainer} />


                  {/* Agreement */}
                  <Route path="/home/agreement/general-agreement" component={GeneralAgreementContainer} />


                  <Route path="/home/pinpoint-college/meeting-training" component={MeetingAndTrainingContainer} />
                  <Route path="/home/pinpoint-college/meeting-training-review" component={MeetingAndTrainingReviewContainer} />
                  <Route path="/home/pinpoint-college/meeting-training-group" component={MeetingAndTrainingGroupContainer} />

                  <Route path="/home/pinpoint-college/meeting-training-applyrecord" component={MeetingAndTrainingApplyRecordContainer} />

                  <Route path="/home/pinpoint-college/meeting-training-calender" component={MeetingAndTrainingCalenderContainer} />
                  <Route path="/home/pinpoint-college/rotation-review" component={RotationReviewContainer} />
                  <Route path="/home/pinpoint-college/broker-review" component={BrokerReviewContainer} />
                  <Route path="/home/pinpoint-college/rating-management" component={RatingManagementContainer} />


                  <Route exact path="/home" render={() => <Redirect to="/home/approval-request/banned-trade-request" />} />
                  <Route exact path="/home/approval-request" render={() => <Redirect to="/home/approval-request/banned-trade-request" />} />

                </Switch>
              </Content>
            </Layout>
        </Layout>
      </div>
    );
  }
}

export default Home;