import React, {Component, PropTypes} from 'react';

import moment from 'moment';
import { Button, Checkbox, Radio, Input, DatePicker, Upload, Icon } from 'antd';
import { QUESTION_TYPE_CODE } from '../../../../utils/QuestionTypeUtil';

import QuestionAttatchment from './QuestionAttatchment.js';
import './QuestionnairePreview.css';

class QuestionnairePreview extends Component {

  state = {
    
  };

  componentDidMount() {
    var questionnaireGuid = this.props.match.params.id;
    this.props.loadQuestions(questionnaireGuid);
  }

  cancelButtonOnClicked = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      questionArray,
      questionnaireTitle,
    } = this.props;

    return (
      <div className='questionnaireDashboardWrapper'>
        <div className='questionnaireDashboardContent'>
          <span className='title'>
            { questionnaireTitle }
          </span>
          <div className='question-content'>
            {
              questionArray.map((value, index) => {
                return (
                  <div className='questionContainer' key={index}>
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>{value.questionNumber}.</span>
                      <div>
                        <div className='question-desc'>
                          { value.questionEnglish }
                        </div>
                        <div className='question-desc'>
                          { value.questionChinese }
                        </div>
                      </div>
                    </div>
                    <div className='answerContainer'>
                      {
                        value.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE && 
                        <Radio.Group disabled>
                          {
                            value.options.split('|').map((valueOption, indexOption) => {
                              const key = indexOption + '_' + value.answerGuid;
                              return (
                                <Radio className='radioStyle' value={valueOption} key={key}>{ valueOption }</Radio>
                              )
                            })
                          }
                        </Radio.Group>
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE && 
                        <div>
                          {
                            value.options.split('|').map((valueOption, indexOption) => {
                              const key = indexOption + '_' + value.answerGuid;

                              return (
                                <Checkbox 
                                  key={key}
                                  className='checkStyle'
                                  disabled>{ valueOption }</Checkbox>
                              )
                            })
                          }
                        </div>
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.TEXT && 
                        <Input 
                          disabled
                          style={{ width: '70%' }}/>
                      }
                                            {
                        value.questionType === QUESTION_TYPE_CODE.SUBJECT_LINE && 
                        
                          
                         <p> {value.answerContent}</p> 
                          
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.DATE && 
                        <DatePicker disabled />
                      }
                      {
                        value.questionType === QUESTION_TYPE_CODE.UPLOAD && 
                        <Upload withCredentials={true}>
                          <Button disabled>
                            <Icon type="upload" /> Upload
                          </Button>
                        </Upload>
                      }
                      {/* 展示附件 */}
                      {
                        (!!value.attachment && !!value.attachmentOriginName) &&
                        <QuestionAttatchment attachment={ value.attachment } attachmentOriginName={ value.attachmentOriginName } />
                      }
                    </div>
                    {/* 子问题 */}
                    <div className="sub-question-content">
                      {
                        value.subQuestionList.map((subValue, index) => {
                          return (
                            <div className='sub-question-container' key={index}>
                              <div className='sub-question-desc-container'>
                                {/* <span className='sub-question-desc-number'>{subValue.subQuestionNumber}.</span> */}
                                <div>
                                  <div className='sub-question-desc'>
                                    { subValue.questionEnglish }
                                  </div>
                                  <div className='sub-question-desc'>
                                    { subValue.questionChinese }
                                  </div>
                                </div>
                              </div>
                              <div className='answerContainer'>
                                {
                                  subValue.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE && 
                                  <Radio.Group value={subValue.answerContent}>
                                    {
                                      subValue.options.split('|').map((valueOption, indexOption) => {
                                        const key = indexOption + '_' + subValue.answerGuid;
                                        return (
                                          <Radio className='radioStyle' value={valueOption} key={key}>{ valueOption }</Radio>
                                        )
                                      })
                                    }
                                  </Radio.Group>
                                }
                                {
                                  subValue.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE && 
                                  <div>
                                    {
                                      subValue.options.split('|').map((valueOption, indexOption) => {
                                        const key = indexOption + '_' + subValue.answerGuid;
      
                                        return (
                                          <Checkbox 
                                            key={key}
                                            className='checkStyle'>{ valueOption }</Checkbox>
                                        )
                                      })
                                    }
                                  </div>
                                }
                                {
                                  subValue.questionType === QUESTION_TYPE_CODE.TEXT && 
                                  <Input 
                                    disabled
                                    style={{ width: '70%' }}/>
                                }
                                                      {
                        value.questionType === QUESTION_TYPE_CODE.SUBJECT_LINE && 
                        
                          
                         <p> {value.answerContent}</p> 
                          
                      }
                                {
                                  subValue.questionType === QUESTION_TYPE_CODE.DATE && 
                                  <DatePicker disabled />
                                }
                                {
                                  subValue.questionType === QUESTION_TYPE_CODE.UPLOAD && 
                                  <Upload withCredentials={true}>
                                    <Button disabled>
                                      <Icon type="upload" /> Upload
                                    </Button>
                                  </Upload>
                                }
                                {/* 展示附件 */}
                                {
                                  (!!subValue.attachment && !!subValue.attachmentOriginName) &&
                                  <QuestionAttatchment attachment={ subValue.attachment } attachmentOriginName={ subValue.attachmentOriginName } />
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className="signature-container">
            <div>
              <span>Signature:</span><br/>
              <span>签名:</span>
            </div>
            <input disabled
              style={{ marginTop: 10, marginLeft: 30 }}
            />
            <div style={{ marginTop: 10 }}>
              <span>Name 姓名: --</span><br/>
              <span>Date 日期: --</span>
            </div>
          </div>
          <div className='buttons'>
            <Button onClick={this.cancelButtonOnClicked}>Go Back</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionnairePreview;