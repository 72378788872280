import React, {Component} from 'react';

import { Button, message, Breadcrumb } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import ThirteenFReportGrid from './ThirteenFReportGrid';
import '../../DocumentReport.css';

class ThirteenFReportDashboard extends Component {

  componentDidMount() {
    this.props.loadThirteenFReport();
  }

  componentDidUpdate() {
    const {
      syncThirteenFReportActionStatus,
      loadThirteenFReportActionStatus,
      loadThirteenFReportErrMsg,
      syncThirteenFReportErrMsg,
    } = this.props;

    // Sync 13F Report 成功
    if (syncThirteenFReportActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('13F Report list are synchronized successfully!');
      this.props.initSyncThirteenFReportActionStatus();
      this.props.loadThirteenFReport();
    }

    if (syncThirteenFReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(syncThirteenFReportErrMsg);
      this.props.initSyncThirteenFReportActionStatus();
    }

    // Load 13F Report 成功
    if (loadThirteenFReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadThirteenFReportActionStatus();
    }

    if (loadThirteenFReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadThirteenFReportErrMsg);
      this.props.initLoadThirteenFReportActionStatus();
    }

  }

  syncBtnOnClicked = () => {
    this.props.syncThirteenFReport();
  }

  refreshBtnOnClicked = () => {
    this.props.loadThirteenFReport();
  }
  
  render() {
    const {
      syncThirteenFReportActionStatus,
      loadThirteenFReportActionStatus,
    } = this.props;

    const syncBtnIsLoading = syncThirteenFReportActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadThirteenFReportActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="shortPositionReportWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Document & Report</Breadcrumb.Item>
            <Breadcrumb.Item>13F Report</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <Button 
            onClick={this.syncBtnOnClicked} 
            size='small'
            loading={syncBtnIsLoading}
            type='primary'>
            Sync
          </Button>

          <Button 
            onClick={this.refreshBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='shortPositionReportGrid'>
          <ThirteenFReportGrid {...this.props} />
        </div>

      </div>
    );
  }
}

export default ThirteenFReportDashboard;