import { ACTION_STATUS } from '../../../utils/ActionStatus';
import { createReducer } from '../../../utils/reducerUtil';

import {
  // queryPendingTasksNum
  QUERY_PENDING_TASKS_NUM_SUCCESS,
  QUERY_PENDING_TASKS_NUM_FAILURE,
  // queryPendingTasksList
  QUERY_PENDING_TASKS_INPUT_CHANGE,
  START_QUERY_PENDING_TASKS_LIST,
  QUERY_PENDING_TASKS_LIST_SUCCESS,
  QUERY_PENDING_TASKS_LIST_FAILURE,
  INIT_QUERY_PENDING_TASKS_LIST_STATUS,
  // ignoreDeignorePendingTasks
  IGNORE_DEIGNORE_PENDING_TASKS_SUCCESS,
  IGNORE_DEIGNORE_PENDING_TASKS_ERROR,
  INIT_IGNORE_DEIGNORE_PENDING_TASKS_STATUS,


} from './PendingTasksConstants';



const initialState = {
    // queryPendingTasksNum
    pendingTasksNum: 0,
    // queryPendingTasksInputParams
    queryPendingTasksInputParams: {
        status: undefined,
    },
  
  
};



// queryPendingTasksNum
function queryPendingTasksNumSuccess(state, resp) {
    return {
      ...state,
      pendingTasksNum: resp,
    };
}
function queryPendingTasksNumFailure(state, err) {
    return {
      ...state,
    };
}


// queryPendingTasksList
function queryPendingTasksInputChange(state, {name, value}) {
    return {
        ...state,
        queryPendingTasksInputParams: {
            ...state.queryPendingTasksInputParams,
            [name]: value,
        }
    };
}
function startQueryPendingTasksList(state) {
    return {
        ...state,
        queryPendingTasksListStatus: ACTION_STATUS.LOGINING,
    };
}
function queryPendingTasksListSuccess(state, resp) {
    return {
        ...state,
        queryPendingTasksListStatus: ACTION_STATUS.SUCCESS,
        queryPendingTasksListResp: resp,
    };  
}
function queryPendingTasksListFailure(state, err) {
    return {
        ...state,
        queryPendingTasksListStatus: ACTION_STATUS.ERROR,
        queryPendingTasksListResp: err,
    };    
}
function initQueryPendingTasksListStatus(state) {
    return {
        ...state,
        queryPendingTasksListStatus: ACTION_STATUS.READY,
    };
}


// ignoreDeignorePendingTasks
function ignoreDeignorePendingTasksSuccess(state) {
    return {
        ...state,
        ignoreDeignorePendingTasksStatus: ACTION_STATUS.SUCCESS,
    };
}
function ignoreDeignorePendingTasksFailure(state, err) {
    return {
        ...state,
        ignoreDeignorePendingTasksStatus: ACTION_STATUS.ERROR,
        ignoreDeignorePendingTasksResp: err,
    };   
}
function initIgnoreDeignorePendingTasksStatus(state) {
    return {
        ...state,
        ignoreDeignorePendingTasksStatus: ACTION_STATUS.READY,
    };   
}



export default createReducer(initialState, {
  // queryPendingTasksNum
  [QUERY_PENDING_TASKS_NUM_SUCCESS]: queryPendingTasksNumSuccess,
  [QUERY_PENDING_TASKS_NUM_FAILURE]: queryPendingTasksNumFailure,
  // queryPendingTasksList
  [QUERY_PENDING_TASKS_INPUT_CHANGE]: queryPendingTasksInputChange,
  [START_QUERY_PENDING_TASKS_LIST]: startQueryPendingTasksList,
  [QUERY_PENDING_TASKS_LIST_SUCCESS]: queryPendingTasksListSuccess,
  [QUERY_PENDING_TASKS_LIST_FAILURE]: queryPendingTasksListFailure,
  [INIT_QUERY_PENDING_TASKS_LIST_STATUS]: initQueryPendingTasksListStatus,
  // ignoreDeignorePendingTasks
  [IGNORE_DEIGNORE_PENDING_TASKS_SUCCESS]: ignoreDeignorePendingTasksSuccess,
  [IGNORE_DEIGNORE_PENDING_TASKS_ERROR]: ignoreDeignorePendingTasksFailure,
  [INIT_IGNORE_DEIGNORE_PENDING_TASKS_STATUS] : initIgnoreDeignorePendingTasksStatus,

});

