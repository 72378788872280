import {connect} from 'react-redux';
import GroupStructureDashboard from '../components/GroupStructureDashboard';


import {
    // query
    queryGroupStructure,
    initQueryGroupStructureActionStatus,
    // detail
    openGroupStructureDetailDialog,
    resetGroupStructureDetailDialog,
    // select
    selectdGroupStructure,
    openAddOrEditGroupStructureModal,
    resetAddOrEditGroupStructureModal,
    addOrEditGroupStructureChangeInput,
    submitAddOrEditGroupStructure,
    initSubmitAddOrEditGroupStructureActionStatus,
    // del
    openDelGroupStructureModal,
    resetDelGroupStructureModal,
    delGroupStructureChangeInput,
    submitDelGroupStructure,
    initSubmitDelGroupStructureActionStatus,


} from '../GroupStructureActions';


import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';

import {
    doHistoryUploadAction,
} from '../../../common/historyUpload/HistoryUploadActions';



const mapStateToProps = state => {
    return {
        // download url, options
        natureOptions: state.groupStructure.natureOptions,
        usageOptions: state.groupStructure.usageOptions,
        groupStructureDownloadUrl: state.groupStructure.groupStructureDownloadUrl,
        groupStructureUploadUrl: state.groupStructure.groupStructureUploadUrl,
        // query
        queryGroupStructureStatus: state.groupStructure.queryGroupStructureStatus,
        queryGroupStructureResp: state.groupStructure.queryGroupStructureResp,
        // detail
        groupStructureDetailDialog: state.groupStructure.groupStructureDetailDialog,
        // select
        selectedGroupStructureArray: state.groupStructure.selectedGroupStructureArray,
        // add or edit
        groupStructureModal: state.groupStructure.groupStructureModal,
        addOrEditGroupStructureStatus: state.groupStructure.addOrEditGroupStructureStatus,
        // del
        delGroupStructureModal: state.groupStructure.delGroupStructureModal,
        delGroupStructureStatus: state.groupStructure.delGroupStructureStatus,
        // op rcd, his upload
        operationRecord: state.operationRecord,
        historyUpload: state.historyUpload,

    };
};


const mapDispatchToProps = dispatch => {
    return {
        // query
        queryGroupStructure: () => dispatch(queryGroupStructure()),
        initQueryGroupStructureActionStatus: () => dispatch(initQueryGroupStructureActionStatus()),
        // detail
        openGroupStructureDetailDialog: (data) => dispatch(openGroupStructureDetailDialog(data)),
        resetGroupStructureDetailDialog: () => dispatch(resetGroupStructureDetailDialog()),
        // select
        selectdGroupStructure: (array) => dispatch(selectdGroupStructure(array)),
        // add or edit
        openAddOrEditGroupStructureModal: (opType, data) => dispatch(openAddOrEditGroupStructureModal(opType, data)),
        resetAddOrEditGroupStructureModal: () => dispatch(resetAddOrEditGroupStructureModal()),
        addOrEditGroupStructureChangeInput: (input) => dispatch(addOrEditGroupStructureChangeInput(input)),
        submitAddOrEditGroupStructure: (data) => dispatch(submitAddOrEditGroupStructure(data)),
        initSubmitAddOrEditGroupStructureActionStatus: () => dispatch(initSubmitAddOrEditGroupStructureActionStatus()),
        // del
        openDelGroupStructureModal: (data) => dispatch(openDelGroupStructureModal(data)),
        resetDelGroupStructureModal: () => dispatch(resetDelGroupStructureModal()),
        delGroupStructureChangeInput: (input) => dispatch(delGroupStructureChangeInput(input)),
        submitDelGroupStructure: (data) => dispatch(submitDelGroupStructure(data)),
        initSubmitDelGroupStructureActionStatus: () => dispatch(initSubmitDelGroupStructureActionStatus()),
        // op rcd, his upload
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
        doHistoryUploadAction: (params) => dispatch(doHistoryUploadAction(params)),

    };
    
};


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(GroupStructureDashboard);
