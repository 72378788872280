import {connect} from 'react-redux';
import ElementTypeMngDashboard from '../components/ElementTypeMngDashboard';


import {
    // change input
    onElementTypeQueryInputChange,
    // query
    queryElementType,
    initQueryElementTypeActionStatus,
    // select data
    selectElementTypeList,
    selectElementTypeFieldList,
    // add or edit element type
    openAddOrEditElementTypeModal,
    resetAddOrEditElementTypeModal,
    onElementTypeAddOrEditInputChange,
    submitAddOrEditElementType,
    initSubmitAddOrEditElementTypeActionStatus,
    // del element type
    delElementTypeBtnClicked,
    initDelElementTypeBtnClickedActionStatus,
    // add or edit field
    openAddOrEditFieldModal,
    resetAddOrEditFieldModal,
    onFieldAddOrEditInputChange,
    submitAddOrEditField,
    initSubmitAddOrEditFieldActionStatus,
    // del field
    delFieldBtnClicked,
    initDelFieldBtnClickedActionStatus,

} from '../ElementTypeMngActions';


const mapStateToProps = state => {
    return {
        // change input
        elementTypeQueryFields: state.elementTypeMng.elementTypeQueryFields,
        // query
        queryElementTypeActionStatus: state.elementTypeMng.queryElementTypeActionStatus,
        queryElementTypeActionResp: state.elementTypeMng.queryElementTypeActionResp,
        // select data
        selectedElementTypeList: state.elementTypeMng.selectedElementTypeList,
        selectedElementTypeFieldList: state.elementTypeMng.selectedElementTypeFieldList,
        // add or edit element type
        addOrEditElementTypeModal: state.elementTypeMng.addOrEditElementTypeModal,
        addOrEditElementTypeActionStatus: state.elementTypeMng.addOrEditElementTypeActionStatus,
        // del element type
        delElementTypeActionStatus: state.elementTypeMng.delElementTypeActionStatus,
        delElementTypeActionResp: state.elementTypeMng.delElementTypeActionResp,
        // add or edit field
        addOrEditFieldModal: state.elementTypeMng.addOrEditFieldModal,
        addOrEditFieldActionStatus: state.elementTypeMng.addOrEditFieldActionStatus,
        // del field
        delElementTypeFieldActionStatus: state.elementTypeMng.delElementTypeFieldActionStatus,
        delElementTypeFieldActionResp: state.elementTypeMng.delElementTypeFieldActionResp,


    };
}


const mapDispatchToProps = dispatch => {
    return {
        // change input
        onElementTypeQueryInputChange: (param) => dispatch(onElementTypeQueryInputChange(param)),
        // query
        queryElementType: (param) => dispatch(queryElementType(param)),
        initQueryElementTypeActionStatus: () => dispatch(initQueryElementTypeActionStatus()),
        // select data
        selectElementTypeList: (data) => dispatch(selectElementTypeList(data)),
        selectElementTypeFieldList: (data) => dispatch(selectElementTypeFieldList(data)),
        // add or edit element type
        openAddOrEditElementTypeModal: (mode, data) => dispatch(openAddOrEditElementTypeModal(mode, data)),
        resetAddOrEditElementTypeModal: () => dispatch(resetAddOrEditElementTypeModal()),
        onElementTypeAddOrEditInputChange: (param) => dispatch(onElementTypeAddOrEditInputChange(param)),
        submitAddOrEditElementType: (data) => dispatch(submitAddOrEditElementType(data)),
        initSubmitAddOrEditElementTypeActionStatus: () => dispatch(initSubmitAddOrEditElementTypeActionStatus()),
        // del element type
        delElementTypeBtnClicked: (data) => dispatch(delElementTypeBtnClicked(data)),
        initDelElementTypeBtnClickedActionStatus: () => dispatch(initDelElementTypeBtnClickedActionStatus()),
        // add or edit field
        openAddOrEditFieldModal: (mode, field, elementTypeId) => dispatch(openAddOrEditFieldModal(mode, field, elementTypeId)),
        resetAddOrEditFieldModal: () => dispatch(resetAddOrEditFieldModal()),
        onFieldAddOrEditInputChange: (param) => dispatch(onFieldAddOrEditInputChange(param)),
        submitAddOrEditField: (data) => dispatch(submitAddOrEditField(data)),
        initSubmitAddOrEditFieldActionStatus: () => dispatch(initSubmitAddOrEditFieldActionStatus()),
        // del field
        delFieldBtnClicked: (data) => dispatch(delFieldBtnClicked(data)),
        initDelFieldBtnClickedActionStatus: () => dispatch(initDelFieldBtnClickedActionStatus()),

    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ElementTypeMngDashboard);
