import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';


class PendingTasksGrid extends Component {

    columnDefActionCellrenderer = (node) => {
        const data = node.data;
        const status = node.data.status;
        if(status !== 'Pending' && status !== 'Ignored') {
            return '';
        }
        const btn = document.createElement('button');
        const txt = status === 'Pending' ? 'ignore' : 'de-ignore';
        const btnTxt = document.createTextNode(txt);
        btn.appendChild(btnTxt);
        const param = status === 'Pending' ? {opType: 1, id: data.id} : {opType: 2, id: data.id};
        btn.onclick = () => {
            data.ignoreDeignorePendingTasks(param);
        }
        return btn;
    }


    columnDefStatusCellStyle = (param) => {
        if(param.value === 'Pending') {
            return {color: 'red'};
        } else if(param.value === 'Ignored') {
            return {color: 'brown'};
        } else if(param.value === 'Finished') {
            return {color: 'green'};
        } else {
            return {color: 'black'};
        }
    }



    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50,
            },
            columnDefs: [
                {field: 'taskType', headerName: "taskType", cellClass: 'non-number', width: 150,},
                {field: 'taskTitle', headerName: "taskTitle", cellClass: 'non-number', width: 300,},
                {field: 'taskDesc', headerName: "taskDesc", cellClass: 'non-number', width: 350,},
                {field: 'startTime', headerName: "startTime", cellClass: 'non-number', width: 150,},
                {field: 'status', headerName: "status", cellClass: 'non-number', width: 80, cellStyle: this.columnDefStatusCellStyle,},
                {field: 'updatedTime', headerName: "updatedTime", cellClass: 'non-number', width: 150,},
                {field: 'updatedBy', headerName: "updatedBy", cellClass: 'non-number', width: 150,},
                { headerName: "action", width: 100, cellRenderer: this.columnDefActionCellrenderer,},
            ],
            rowClassRules: {
                // 业务需求：超过5个工作日标记黄色，超过10个工作日标记红色
                // 实际处理：超过7个自然日标记黄色，超过14个自然日标记红色
                'MarkBackgroundRed': function(params) {
                    if(params.data.status !== 'Pending' || params.data.taskType !== 'ReportingSummary') {
                        return false;
                    }
                    if(params.data.taskType === 'ReportingSummary' 
                        && (params.data.frequency !== 'Weekly' && params.data.frequency !== 'EveryWorkday' )
                        && params.data.deadline != null){
                            const startTime = params.data.deadline;
                            const dateDiff = moment(startTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'days');
                            return !!dateDiff && dateDiff <= 5;
                    }
                    return false;
                },
                'MarkBackgroundYellow': function(params) {
                    if(params.data.status !== 'Pending' || params.data.taskType !== 'ReportingSummary') {
                        return false;
                    }
                    if(params.data.taskType === 'ReportingSummary' 
                        && (params.data.frequency !== 'Weekly' && params.data.frequency !== 'EveryWorkday' )
                        && params.data.deadline != null){
                            const startTime = params.data.deadline;
                            const dateDiff = moment(startTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'days');
                            return !!dateDiff && dateDiff > 5 && dateDiff <= 10;
                    }
                    return false;
                },
            },

        };
    }


    onCellClicked = (event) => {
       if(event.colDef.headerName === 'action') {
           // 点击action中按钮时，不能同时触发跳转动作
           return;
       }
       const redirectURI = event.data.redirectURI;
       window.location.href = redirectURI;
    }


    render() {
        const {
            queryPendingTasksListStatus,
            queryPendingTasksListResp,
        } = this.props;

        const isLoading = queryPendingTasksListStatus === ACTION_STATUS.LOGINING;
        const pendingTasksArray = !!queryPendingTasksListResp && !!queryPendingTasksListResp.data ? queryPendingTasksListResp.data : [];

        // 将方法ignoreDeignorePendingTasks挂载到每个节点，ignore/de-ignore按钮需要调用
        pendingTasksArray.forEach(e=>{
            e.ignoreDeignorePendingTasks = this.props.ignoreDeignorePendingTasks;
        });


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={pendingTasksArray}
                            defaultColDef={this.state.defaultColDef}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            // event
                            onCellClicked={this.onCellClicked}
                        />
                    )}
            </div>
        );
    }

}

export default PendingTasksGrid;