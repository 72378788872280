import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input , Tabs} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './MeetingAndTrainingCalenderDashboard.css';
import MeetingAndTrainingCalenderGrid from './MeetingAndTrainingCalenderGrid';
import AddOrEditMeetingAndTrainingCalenderModalDialog from './AddOrEditMeetingAndTrainingCalenderModalDialog';
import DelMeetingAndTrainingCalenderModalDialog from './DelMeetingAndTrainingCalenderModalDialog';
import CopMeetingAndTrainingCalenderModalDialog from './CopMeetingAndTrainingCalenderModalDialog';
import OperationRecordDialog from './OperationRecordDialog';
import RestrictedSecurityRuleGrid from './restrictedRule/RestrictedSecurityRuleGrid';
import RestrictedSecurityPinPointGrid from './restrictedRule/RestrictedSecurityPinPointGrid';
import RestrictedSecurityPersonalGrid from './restrictedRule/RestrictedSecurityPersonalGrid';
import moment from 'moment';
import FinishTaskModalDialog from './FinishTaskModalDialog';
let date  = 0;
const TabPane = Tabs.TabPane;
const { MonthPicker } = DatePicker;
class MeetingAndTrainingCalenderDashboard extends Component {
    componentDidMount() {
        const {
            cpty,
            functiontype,
            fundandcompany,
            userName,
            status
        } = this.props.meetingAndTrainingCalenderQueryFields;
         this.props.loadMeetingAndTrainingCalenderInfo({cpty: undefined,functiontype: undefined,fundandcompany: undefined,userName: undefined, status: 'Active'})

    }


    componentDidUpdate() {
        const {
            // query
            loadMeetingAndTrainingCalenderInfoActionStatus,
            loadMeetingAndTrainingCalenderInfoResp,
        } = this.props;

        if(loadMeetingAndTrainingCalenderInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadMeetingAndTrainingCalenderInfoActionStatus();
        }
        if(loadMeetingAndTrainingCalenderInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load User Access Info failed';
            if(!!loadMeetingAndTrainingCalenderInfoResp && !!loadMeetingAndTrainingCalenderInfoResp.respMessage) {
                err = loadMeetingAndTrainingCalenderInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadMeetingAndTrainingCalenderInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditMeetingAndTrainingCalenderModal(mode, null);
        } else {
            const {
                selectedMeetingAndTrainingCalenderInfoArray,
            } = this.props;
            if(selectedMeetingAndTrainingCalenderInfoArray.length !== 1) {
                message.warning("Please choose one and only one User Access Info");
                return;
            }
            const currentMeetingAndTrainingCalenderInfo = selectedMeetingAndTrainingCalenderInfoArray[0];
            this.props.openAddOrEditMeetingAndTrainingCalenderModal(mode, currentMeetingAndTrainingCalenderInfo);
        }
    }
    copyAllBtnOnClicked = () => {
        const {
            selectedMeetingAndTrainingCalenderInfoArray,
        } = this.props;
        if(selectedMeetingAndTrainingCalenderInfoArray.length !== 1) {
            message.warning("Please choose one and only one User Access Info");
            return;
        }
        const currentMeetingAndTrainingCalenderInfo = selectedMeetingAndTrainingCalenderInfoArray[0];
        this.props.openCopMeetingAndTrainingCalenderModal(currentMeetingAndTrainingCalenderInfo);
    }

    delBtnOnClicked = (mode) => {
        const {
            selectedMeetingAndTrainingCalenderInfoArray,
        } = this.props;
        if(selectedMeetingAndTrainingCalenderInfoArray.length !== 1) {
            message.warning("Please choose one and only one Info");
            return;
        }
        const currentMeetingAndTrainingCalenderInfo = selectedMeetingAndTrainingCalenderInfoArray[0];
        this.props.openDelMeetingAndTrainingCalenderModal(currentMeetingAndTrainingCalenderInfo,mode);
    }


    refreshBtnOnClicked = () => {
        const {
            title,
            type,
            start,
            entTime,
            speaker,
            broker,
            employee
            
            
        } = this.props.meetingAndTrainingCalenderQueryFields;
        // const person = participant;
        this.props.loadMeetingAndTrainingCalenderInfo({subject:title,type,start,entTime,speaker,broker,employee})
    }
    befortOrAF = (flage) =>{
        if (flage == 'after'){
          date++;
      
          this.props.loadMeetingAndTrainingCalenderInfo({date})
        }else{
          date --
      
          this.props.loadMeetingAndTrainingCalenderInfo({date})
        }
      
      };

    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {
        const {
            queryParams
        } = this.props;
        const {
            typeOptions,
            optonalOptions,
            brokerOptions,
            userAccountOptions,
            meetingAndTrainingCalenderQueryFields,
            loadMeetingAndTrainingCalenderInfoActionStatus,
        } = this.props;

        const {
            userName,
            status,
        } = meetingAndTrainingCalenderQueryFields;


        const refreshBtnIsLoading = loadMeetingAndTrainingCalenderInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='MeetingAndTrainingCalenderWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Training Calender</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <Tabs defaultActiveKey="1" tabPosition={'top'}>
                {/* <TabPane  tab="Public Event" key="1">
                <RestrictedSecurityPinPointGrid {...this.props}/> 
                
                </TabPane>
                <TabPane  tab="Personal Event" key="2">
                <RestrictedSecurityPersonalGrid {...this.props}/> 
                
                </TabPane> */}
                <TabPane  tab="Broker View" key="3">
                <div className='OperationBar'>
                    <div className='breadcrumbWrapper'>   
                    <Breadcrumb>
                    <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                    </Breadcrumb>
                    </div>


                    <Input 
                        allowClear={true}
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="Title"
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'title', value: event.target.value })
                        }} 
                    />
                    {/* <Input 
                        placeholder="Function"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'functiontype', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="Fund/Co."
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'fundandcompany', value: event.target.value })
                        }} 
                    /> */}
                    
                    <Select
                        allowClear
                        showSearch
                        size='small'
                        // --value={!user ? undefined : user}
                        style={{marginLeft: 10, width: 150}}
                        //onChange={(value) => this.onInputChange({name: 'userName', value: value})}
                        onChange={(val) => {
                            this.onInputChange({ name: 'employee', value: val })
                        }} 
                        placeholder="Employee">
                        {userAccountOptions}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'broker', value: val })
                        }} 
                        placeholder="broker">
                        {brokerOptions}
                    </Select>
                    {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder=" Date Start"
                      onChange={(startTime, dateString) => {
                        this.onInputChange({ name: 'startTime', value: dateString });
                      }}
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Date End"
                      onChange={(entTime, dateString) => {
                        this.onInputChange({ name: 'entTime', value: dateString });
                      }}
                    />  */}

                    <MonthPicker
                        placeholder={'month start'}
                        allowClear={false}

                        allowClear={true}
                        size="small"
                        onChange={(date, dateString) => {
                          this.onInputChange({
                            name: 'start',
                            value: dateString
                          });
                        }}
                        style={{marginLeft: '10px'}}
                    />
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.befortOrAF('before')} 
                        style={{ marginLeft: 10 }}
                        size='small'
                        type='primary'>
                        Previous Month
                    </Button>
                    <Button 
                        onClick={()=>this.befortOrAF('after')} 
                        style={{ marginLeft: 10 }}
                        size='small'
                        type='primary'>
                        Next Month
                    </Button>

                    {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('ADD')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.copyAllBtnOnClicked()} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy All
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>*/}
                    {/* <Button 
                        onClick={()=>this.delBtnOnClicked('inactive')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Reset
                    </Button>  */}
                    {/* <Button 
                        onClick={()=>this.delBtnOnClicked('del')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Feedback
                    </Button>
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'MeetingAndTrainingCalender'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button> */}
                </div>

                <div className='MeetingAndTrainingCalenderGrid'><MeetingAndTrainingCalenderGrid {...this.props} /></div>
                {/* <AddOrEditMeetingAndTrainingCalenderModalDialog {...this.props} />
                
                <CopMeetingAndTrainingCalenderModalDialog {...this.props} /> */}

                <DelMeetingAndTrainingCalenderModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                </TabPane>
                {/* <TabPane tab="Personal view" key="4">
                <RestrictedSecurityRuleGrid {...this.props}/> 
                </TabPane> */}
          
                </Tabs>


            </div>
            
        );
    }
}

export default MeetingAndTrainingCalenderDashboard;
