// query
export const ON_QUERY_INPUT_CHANGE = 'ON_QUERY_INPUT_CHANGE';
// Load LicensingForm
export const START_LOAD_LICENSINGFORM = 'START_LOAD_LICENSINGFORM';
export const LOAD_LICENSINGFORM_SUCCESS = 'LOAD_LICENSINGFORM_SUCCESS';
export const LOAD_LICENSINGFORM_FAILURE = 'LOAD_LICENSINGFORM_FAILURE';
export const INIT_LOAD_LICENSINGFORM_ACTION_STATUS = 'INIT_LOAD_LICENSINGFORM_ACTION_STATUS';

// Select LicensingForm
export const SELECT_LICENSINGFORM = 'SELECT_LICENSINGFORM';

// Load Question
export const START_LOAD_QUESTIONS = 'START_LOAD_QUESTIONS';
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS';
export const LOAD_QUESTIONS_FAILURE = 'LOAD_QUESTIONS_FAILURE';
export const INIT_LOAD_QUESTIONS_ACTION_STATUS = 'INIT_LOAD_QUESTIONS_ACTION_STATUS';

// Select Questions
export const SELECT_QUESTIONS = 'SELECT_QUESTIONS';

// Open/Reset Answeree Select Modal Dialog
export const ANSWEREE_SELECT_MODAL_DIALOG_OPEN = 'ANSWEREE_SELECT_MODAL_DIALOG_OPEN';
export const ANSWEREE_SELECT_MODAL_DIALOG_RESET = 'ANSWEREE_SELECT_MODAL_DIALOG_RESET';

// Select All Answeree
export const SELECT_ALL_ANSWEREE = 'SELECT_ALL_ANSWEREE';

// Select Invert Answeree
export const SELECT_INVERT_ANSWEREE = 'SELECT_INVERT_ANSWEREE';

// Answeree Check Changed
export const ANSWEREE_CHECK_CHANGED = 'ANSWEREE_CHECK_CHANGED';

// Select Answerees
export const START_SELECT_ANSWEREES = 'START_SELECT_ANSWEREES';
export const SELECT_ANSWEREES_SUCCESS = 'SELECT_ANSWEREES_SUCCESS';
export const SELECT_ANSWEREES_FAILURE = 'SELECT_ANSWEREES_FAILURE';
export const INIT_SELECT_ANSWEREES_ACTION_STATUS = 'INIT_SELECT_ANSWEREES_ACTION_STATUS';

// LicensingForm Option Change
export const LICENSINGFORM_OPTION_CHANGE = 'LICENSINGFORM_OPTION_CHANGE';






// Load Answers
export const START_LOAD_ANSWERS = 'START_LOAD_ANSWERS';
export const LOAD_ANSWERS_SUCCESS = 'LOAD_ANSWERS_SUCCESS';
export const LOAD_ANSWERS_FAILURE = 'LOAD_ANSWERS_FAILURE';
export const INIT_LOAD_ANSWERS_ACTION_STATUS = 'INIT_LOAD_ANSWERS_ACTION_STATUS';





// Load To-do LicensingForm
export const START_LOAD_TODO_LICENSINGFORM = 'START_LOAD_TODO_LICENSINGFORM';
export const LOAD_TODO_LICENSINGFORM_SUCCESS = 'LOAD_TODO_LICENSINGFORM_SUCCESS';
export const LOAD_TODO_LICENSINGFORM_FAILURE = 'LOAD_TODO_LICENSINGFORM_FAILURE';
export const INIT_LOAD_TODO_LICENSINGFORM_ACTION_STATUS = 'INIT_LOAD_TODO_LICENSINGFORM_ACTION_STATUS';

// Submit LicensingForm Answer
export const START_SUBMIT_LICENSINGFORM_ANSWER = 'START_SUBMIT_LICENSINGFORM_ANSWER';
export const SUBMIT_LICENSINGFORM_ANSWER_SUCCESS = 'SUBMIT_LICENSINGFORM_ANSWER_SUCCESS';
export const SUBMIT_LICENSINGFORM_ANSWER_FAILURE = 'SUBMIT_LICENSINGFORM_ANSWER_FAILURE';
export const INIT_SUBMIT_LICENSINGFORM_ANSWER_ACTION_STATUS = 'INIT_SUBMIT_LICENSINGFORM_ANSWER_ACTION_STATUS';

// Save LicensingForm Answer
export const START_SAVE_LICENSINGFORM_ANSWER = 'START_SAVE_LICENSINGFORM_ANSWER';
export const SAVE_LICENSINGFORM_ANSWER_SUCCESS = 'SAVE_LICENSINGFORM_ANSWER_SUCCESS';
export const SAVE_LICENSINGFORM_ANSWER_FAILURE = 'SAVE_LICENSINGFORM_ANSWER_FAILURE';
export const INIT_SAVE_LICENSINGFORM_ANSWER_ACTION_STATUS = 'INIT_SAVE_LICENSINGFORM_ANSWER_ACTION_STATUS';

// LicensingForm Answer Change Input
export const LICENSINGFORM_ANSWER_CHANGE_INPUT = 'LICENSINGFORM_ANSWER_CHANGE_INPUT';

// ================================================================================
// Open/Reset LicensingForm Edit Modal Dialog
export const LICENSINGFORM_EDIT_MODAL_DIALOG_OPEN = 'LICENSINGFORM_EDIT_MODAL_DIALOG_OPEN';
export const LICENSINGFORM_EDIT_MODAL_DIALOG_RESET = 'LICENSINGFORM_EDIT_MODAL_DIALOG_RESET';

// Create LicensingForm
export const START_CREATE_LICENSINGFORM = 'START_CREATE_LICENSINGFORM';
export const CREATE_LICENSINGFORM_SUCCESS = 'CREATE_LICENSINGFORM_SUCCESS';
export const CREATE_LICENSINGFORM_FAILURE = 'CREATE_LICENSINGFORM_FAILURE';
export const INIT_CREATE_LICENSINGFORM_ACTION_STATUS = 'INIT_CREATE_LICENSINGFORM_ACTION_STATUS';

// Update LicensingForm
export const START_UPDATE_LICENSINGFORM = 'START_UPDATE_LICENSINGFORM';
export const UPDATE_LICENSINGFORM_SUCCESS = 'UPDATE_LICENSINGFORM_SUCCESS';
export const UPDATE_LICENSINGFORM_FAILURE = 'UPDATE_LICENSINGFORM_FAILURE';
export const INIT_UPDATE_LICENSINGFORM_ACTION_STATUS = 'INIT_UPDATE_LICENSINGFORM_ACTION_STATUS';

// Copy LicensingForm
export const START_COPY_LICENSINGFORM = 'START_COPY_LICENSINGFORM';
export const COPY_LICENSINGFORM_SUCCESS = 'COPY_LICENSINGFORM_SUCCESS';
export const COPY_LICENSINGFORM_FAILURE = 'COPY_LICENSINGFORM_FAILURE';
export const INIT_COPY_LICENSINGFORM_ACTION_STATUS = 'INIT_COPY_LICENSINGFORM_ACTION_STATUS';

// Delete LicensingForm
export const START_BATCH_DELETE_LICENSINGFORM = 'START_BATCH_DELETE_LICENSINGFORM';
export const BATCH_DELETE_LICENSINGFORM_SUCCESS = 'BATCH_DELETE_LICENSINGFORM_SUCCESS';
export const BATCH_DELETE_LICENSINGFORM_FAILURE = 'BATCH_DELETE_LICENSINGFORM_FAILURE';
export const INIT_BATCH_DELETE_LICENSINGFORM_ACTION_STATUS = 'INIT_BATCH_DELETE_LICENSINGFORM_ACTION_STATUS';

// LicensingForm Edit Change Input
export const LICENSINGFORM_EDIT_CHANGE_INPUT = 'LICENSINGFORM_EDIT_CHANGE_INPUT';


// ================================================================================
// Open/Reset Question Edit Modal Dialog
export const QUESTION_EDIT_MODAL_DIALOG_OPEN = 'QUESTION_EDIT_MODAL_DIALOG_OPEN';
export const QUESTION_EDIT_MODAL_DIALOG_RESET = 'QUESTION_EDIT_MODAL_DIALOG_RESET';

// Create Question
export const START_CREATE_QUESTION = 'START_CREATE_QUESTION';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAILURE = 'CREATE_QUESTION_FAILURE';
export const INIT_CREATE_QUESTION_ACTION_STATUS = 'INIT_CREATE_QUESTION_ACTION_STATUS';

// Update Question
export const START_UPDATE_QUESTION = 'START_UPDATE_QUESTION';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAILURE = 'UPDATE_QUESTION_FAILURE';
export const INIT_UPDATE_QUESTION_ACTION_STATUS = 'INIT_UPDATE_QUESTION_ACTION_STATUS';

// Delete Question
export const START_BATCH_DELETE_QUESTION = 'START_BATCH_DELETE_QUESTION';
export const BATCH_DELETE_QUESTION_SUCCESS = 'BATCH_DELETE_QUESTION_SUCCESS';
export const BATCH_DELETE_QUESTION_FAILURE = 'BATCH_DELETE_QUESTION_FAILURE';
export const INIT_BATCH_DELETE_QUESTION_ACTION_STATUS = 'INIT_BATCH_DELETE_QUESTION_ACTION_STATUS';

// Question Edit Change Input
export const QUESTION_EDIT_CHANGE_INPUT = 'QUESTION_EDIT_CHANGE_INPUT';


// Load LicensingForm Results
export const START_LOAD_LICENSINGFORM_RESULTS = 'START_LOAD_LICENSINGFORM_RESULTS';
export const LOAD_LICENSINGFORM_RESULTS_SUCCESS = 'LOAD_LICENSINGFORM_RESULTS_SUCCESS';
export const LOAD_LICENSINGFORM_RESULTS_FAILURE = 'LOAD_LICENSINGFORM_RESULTS_FAILURE';
export const INIT_LOAD_LICENSINGFORM_RESULTS_ACTION_STATUS = 'INIT_LOAD_LICENSINGFORM_RESULTS_ACTION_STATUS';

// Select LicensingForm Results
export const SELECT_LICENSINGFORM_RESULTS = 'SELECT_LICENSINGFORM_RESULTS';

// Load LicensingForm Result Detail
export const START_LOAD_LICENSINGFORM_RESULT_DETAIL = 'START_LOAD_LICENSINGFORM_RESULT_DETAIL';
export const LOAD_LICENSINGFORM_RESULT_DETAIL_SUCCESS = 'LOAD_LICENSINGFORM_RESULT_DETAIL_SUCCESS';
export const LOAD_LICENSINGFORM_RESULT_DETAIL_FAILURE = 'LOAD_LICENSINGFORM_RESULT_DETAIL_FAILURE';
export const INIT_LOAD_LICENSINGFORM_RESULT_DETAIL_ACTION_STATUS = 'INIT_LOAD_LICENSINGFORM_RESULT_DETAIL_ACTION_STATUS';

// Accept LicensingForm Result
export const START_ACCEPT_LICENSINGFORM_RESULT = 'START_ACCEPT_LICENSINGFORM_RESULT';
export const ACCEPT_LICENSINGFORM_RESULT_SUCCESS = 'ACCEPT_LICENSINGFORM_RESULT_SUCCESS';
export const ACCEPT_LICENSINGFORM_RESULT_FAILURE = 'ACCEPT_LICENSINGFORM_RESULT_FAILURE';
export const INIT_ACCEPT_LICENSINGFORM_RESULT_ACTION_STATUS = 'INIT_ACCEPT_LICENSINGFORM_RESULT_ACTION_STATUS';

// Reject LicensingForm Result
export const START_REJECT_LICENSINGFORM_RESULT = 'START_REJECT_LICENSINGFORM_RESULT';
export const REJECT_LICENSINGFORM_RESULT_SUCCESS = 'REJECT_LICENSINGFORM_RESULT_SUCCESS';
export const REJECT_LICENSINGFORM_RESULT_FAILURE = 'REJECT_LICENSINGFORM_RESULT_FAILURE';
export const INIT_REJECT_LICENSINGFORM_RESULT_ACTION_STATUS = 'INIT_REJECT_LICENSINGFORM_RESULT_ACTION_STATUS';

// Open/Reset LicensingForm Result Operate Modal Dialog
export const LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_OPEN = 'LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_OPEN';
export const LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_RESET = 'LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_RESET';

// LicensingForm Answer Submitter Edit Change Input
export const LICENSINGFORM_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT = 'LICENSINGFORM_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT';

// Send Remind Email
export const START_SEND_REMIND_EMAIL = 'START_SEND_REMIND_EMAIL';
export const SEND_REMIND_EMAIL_SUCCESS = 'SEND_REMIND_EMAIL_SUCCESS';
export const SEND_REMIND_EMAIL_FAILURE = 'SEND_REMIND_EMAIL_FAILURE';
export const INIT_SEND_REMIND_EMAIL_ACTION_STATUS = 'INIT_SEND_REMIND_EMAIL_ACTION_STATUS';

// Open/Reset LicensingForm Email Edit Modal Dialog
export const LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_OPEN = 'LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_OPEN';
export const LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_RESET = 'LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_RESET';

// Update LicensingForm Score
export const START_UPDATE_LICENSINGFORM_SCORE = 'START_UPDATE_LICENSINGFORM_SCORE';
export const UPDATE_LICENSINGFORM_SCORE_SUCCESS = 'UPDATE_LICENSINGFORM_SCORE_SUCCESS';
export const UPDATE_LICENSINGFORM_SCORE_FAILURE = 'UPDATE_LICENSINGFORM_SCORE_FAILURE';
export const INIT_UPDATE_LICENSINGFORM_SCORE_ACTION_STATUS = 'INIT_UPDATE_LICENSINGFORM_SCORE_ACTION_STATUS';

// Calculate LicensingForm Total Score
export const CALC_LICENSINGFORM_TOTAL_SCORE = 'CALC_LICENSINGFORM_TOTAL_SCORE';

// Open/Reset Submitted LicensingForm History Modal Dialog
export const SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_OPEN = 'SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_OPEN';
export const SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_RESET = 'SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_RESET';

// Load Submitted LicensingForm History
export const START_LOAD_SUBMITTED_LICENSINGFORM_HISTORY = 'START_LOAD_SUBMITTED_LICENSINGFORM_HISTORY';
export const LOAD_SUBMITTED_LICENSINGFORM_HISTORY_SUCCESS = 'LOAD_SUBMITTED_LICENSINGFORM_HISTORY_SUCCESS';
export const LOAD_SUBMITTED_LICENSINGFORM_HISTORY_FAILURE = 'LOAD_SUBMITTED_LICENSINGFORM_HISTORY_FAILURE';
export const INIT_LOAD_SUBMITTED_LICENSINGFORM_HISTORY_ACTION_STATUS = 'INIT_LOAD_SUBMITTED_LICENSINGFORM_HISTORY_ACTION_STATUS';

// Select Submitted LicensingForm History
export const SELECT_SUBMITTED_LICENSINGFORM_HISTORY = 'SELECT_SUBMITTED_LICENSINGFORM_HISTORY';

// Apply Submitted LicensingForm History
export const APPLY_SUBMITTED_LICENSINGFORM_HISTORY = 'APPLY_SUBMITTED_LICENSINGFORM_HISTORY';


