import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_RATING_MANAGEMENT_INFO,
    LOAD_RATING_MANAGEMENT_INFO_SUCCESS,
    LOAD_RATING_MANAGEMENT_INFO_FAILURE,
    INIT_LOAD_RATING_MANAGEMENT_INFO_ACTION_STATUS,
    // select
    SELECT_RATING_MANAGEMENT_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL,
    ADD_OR_EDIT_RATING_MANAGEMENT_CHANGE_INPUT,
    START_ADD_OR_EDIT_RATING_MANAGEMENT,
    ADD_OR_EDIT_RATING_MANAGEMENT_SUCCESS,
    ADD_OR_EDIT_RATING_MANAGEMENT_FAILURE,
    INIT_ADD_OR_EDIT_RATING_MANAGEMENT_ACTION_STATUS,
    RESET_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_RATING_MANAGEMENT_MODAL,
    DEL_RATING_MANAGEMENT_CHANGE_INPUT,
    START_DEL_RATING_MANAGEMENT,
    DEL_RATING_MANAGEMENT_SUCCESS,
    DEL_RATING_MANAGEMENT_FAILURE,
    INIT_DEL_RATING_MANAGEMENT_ACTION_STATUS,
    RESET_DEL_RATING_MANAGEMENT_MODAL,

    // COP
    OPEN_COP_RATING_MANAGEMENT_MODAL,
    COP_RATING_MANAGEMENT_CHANGE_INPUT,
    START_COP_RATING_MANAGEMENT,
    COP_RATING_MANAGEMENT_SUCCESS,
    COP_RATING_MANAGEMENT_FAILURE,
    INIT_COP_RATING_MANAGEMENT_ACTION_STATUS,
    RESET_COP_RATING_MANAGEMENT_MODAL,



    OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    RESTRICTED_RULE_INPUT_CHANGE,
} from './RatingManagementConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadRatingManagementInfo() {
    return {
      type: START_LOAD_RATING_MANAGEMENT_INFO,
    };
}
export function loadRatingManagementInfo(params) {
    return function(dispatch) {
        dispatch(startLoadRatingManagementInfo());
        httpClient
           .post(UrlConfig.LOAD_RATING_MANAGEMENT_INFO, params)
          .then(resp => dispatch(loadRatingManagementInfoSuccess(resp)))
          .catch(err => dispatch(loadRatingManagementInfoFailure(err)));
    };
}
function loadRatingManagementInfoSuccess(resp) {
    return {
      type: LOAD_RATING_MANAGEMENT_INFO_SUCCESS,
      payload: resp,
    };
}
function loadRatingManagementInfoFailure(err) {
    return {
        type: LOAD_RATING_MANAGEMENT_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadRatingManagementInfoActionStatus() {
    return {
      type: INIT_LOAD_RATING_MANAGEMENT_INFO_ACTION_STATUS,
    };
}

// select
export function selectRatingManagementInfo(array) {
    return {
        type: SELECT_RATING_MANAGEMENT_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditRatingManagementModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditRatingManagementChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_RATING_MANAGEMENT_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditRatingManagement() {
    return {
        type: START_ADD_OR_EDIT_RATING_MANAGEMENT,
    };
}
export function addOrEditRatingManagement(params) {
      return function(dispatch) {
        dispatch(startAddOrEditRatingManagement());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_RATING_MANAGEMENT, params)
          .then(resp => dispatch(addOrEditRatingManagementSuccess(resp)))
          .catch(err => dispatch(addOrEditRatingManagementFailure(err)));
      };
}
function addOrEditRatingManagementSuccess(resp) {
    return {
        type: ADD_OR_EDIT_RATING_MANAGEMENT_SUCCESS,
        payload: resp,
    };
}
function addOrEditRatingManagementFailure(err) {
    return {
        type: ADD_OR_EDIT_RATING_MANAGEMENT_FAILURE,
        payload: err,
    };
}
export function initAddOrEditRatingManagementActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_RATING_MANAGEMENT_ACTION_STATUS,
    };
}
export function resetAddOrEditRatingManagementModal() {
    return {
        type: RESET_ADD_OR_EDIT_RATING_MANAGEMENT_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelRatingManagementModal(info,mode,idList,level) {
    return {
        type: OPEN_DEL_RATING_MANAGEMENT_MODAL,
        payload: 
        {
            info,
            mode,
            idList,
            level,
        },
    };  
}
export function delRatingManagementChangeInput({name, value}) {
    return {
        type: DEL_RATING_MANAGEMENT_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelRatingManagement() {
    return {
        type: START_DEL_RATING_MANAGEMENT,
    };
}
export function delRatingManagement(params) {
      return function(dispatch) {
        dispatch(startDelRatingManagement());
        httpClient
          .get(UrlConfig.DEL_RATING_MANAGEMENT, params)
          .then(resp => dispatch(delRatingManagementSuccess(resp)))
          .catch(err => dispatch(delRatingManagementFailure(err)));
      };
}
function delRatingManagementSuccess(resp) {
    return {
        type: DEL_RATING_MANAGEMENT_SUCCESS,
        payload: resp,
    };
}
function delRatingManagementFailure(err) {
    return {
        type: DEL_RATING_MANAGEMENT_FAILURE,
        payload: err,
    };
}
export function initDelRatingManagementActionStatus() {
    return {
        type: INIT_DEL_RATING_MANAGEMENT_ACTION_STATUS,
    };
}
export function resetDelRatingManagementModal() {
    return {
        type: RESET_DEL_RATING_MANAGEMENT_MODAL,
    };
}












//cop
export function openCopRatingManagementModal(info) {
    return {
        type: OPEN_COP_RATING_MANAGEMENT_MODAL,
        payload: info,
    };  
}
export function copRatingManagementChangeInput({name, value}) {
    return {
        type: COP_RATING_MANAGEMENT_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startCopRatingManagement() {
    return {
        type: START_COP_RATING_MANAGEMENT,
    };
}
export function copRatingManagement(params) {
      return function(dispatch) {
        dispatch(startCopRatingManagement());
        httpClient
          .get(UrlConfig.COP_ALL_RATING_MANAGEMENT, params)
          .then(resp => dispatch(copRatingManagementSuccess(resp)))
          .catch(err => dispatch(copRatingManagementFailure(err)));
      };
}
function copRatingManagementSuccess(resp) {
    return {
        type: COP_RATING_MANAGEMENT_SUCCESS,
        payload: resp,
    };
}
function copRatingManagementFailure(err) {
    return {
        type: COP_RATING_MANAGEMENT_FAILURE,
        payload: err,
    };
}
export function initCopRatingManagementActionStatus() {
    return {
        type: INIT_COP_RATING_MANAGEMENT_ACTION_STATUS,
    };
}
export function resetCopRatingManagementModal() {
    return {
        type: RESET_COP_RATING_MANAGEMENT_MODAL,
    };
}

export function openRestrictedRuleFundBookDialog() {
    return {
      type: OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG
    };
  }
  
  export function closeRestrictedRuleFundBookDialog() {
    return {
      type: CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG
    };
  }
  export function restrictedRuleEditChangeInput({ name, value }) {
    return {
      type: RESTRICTED_RULE_INPUT_CHANGE,
      payload: {
        name,
        value,
      }
    };
  }