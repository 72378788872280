export const QUESTIONNAIRE_STATUS_CODE = {
  NEW: '1',
  SUBMITTED: '2',
  ACCEPTED: '3',
  REJECTED: '4',
  UNKNOWN: '0',
};

const QUESTIONNAIRE_STATUS_LABEL = {
  NEW: 'New',
  SUBMITTED: 'Submitted',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  UNKNOWN: 'Unknown',
};

export const QUESTIONNAIRE_STATUS_MAP = [
  // NEW
  {
    code: QUESTIONNAIRE_STATUS_CODE.NEW,
    label: QUESTIONNAIRE_STATUS_LABEL.NEW
  },
  // Submitted
  {
    code: QUESTIONNAIRE_STATUS_CODE.SUBMITTED,
    label: QUESTIONNAIRE_STATUS_LABEL.SUBMITTED
  },
  // Accepted
  {
    code: QUESTIONNAIRE_STATUS_CODE.ACCEPTED,
    label: QUESTIONNAIRE_STATUS_LABEL.ACCEPTED
  },
  // Rejected
  {
    code: QUESTIONNAIRE_STATUS_CODE.REJECTED,
    label: QUESTIONNAIRE_STATUS_LABEL.REJECTED
  },
  // Unknown
  {
    code: QUESTIONNAIRE_STATUS_CODE.UNKNOWN,
    label: QUESTIONNAIRE_STATUS_LABEL.UNKNOWN
  },
];