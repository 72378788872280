import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import JsonTooltipComponent from '../../../../../utils/JsonTooltipComponent';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';
class BrokerReviewGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [{
                field: 'id',
                headerName: "ID",
                cellClass: 'non-number',
                width: 80,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                hide: true,
            },
            {
                field: 'interactionId',
                headerName: "Interaction ID",
                cellClass: 'non-number',
                width: 100,
                enableRowGroup: true,
                hide: true,
            },
            {
                field: 'broker',
                headerName: "Broker",
                cellClass: 'non-number',
                width: 100,
                enableRowGroup: true,
                rowGroup: true,
                hide: true,
            },
            {
                field: 'interactionDate',
                headerName: "Interaction Date",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'pinpointContact',
                headerName: "Pinpoint Contact",
                cellClass: 'non-number',
                width: 120,
            },
            {
                field: 'employee',
                headerName: "Employee",
                cellClass: 'non-number',
                width: 100,
                cellRenderer: 'jsonTooltipComponent'
            },
            // {
            //     field: 'employeeEmails',
            //     headerName: "Employee Emails",
            //     cellClass: 'non-number',
            //     width: 100,
            // },
            // {
            //     field: 'employeeType',
            //     headerName: "Employee Types",
            //     cellClass: 'non-number',
            //     width: 100,
            // },
            {
                field: 'interactionRegion',
                headerName: "Interaction Region",
                cellClass: 'non-number',
                width: 200,
                
            },
            {
                field: 'interactionCategory',
                headerName: "Interaction Category",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'interactionPurpose',
                headerName: "Interaction Purpose",
                cellClass: 'number',
                width: 100,
                enableRowGroup: true,
            },
            {
                field: 'interactionType',
                headerName: "Interaction Type",
                cellClass: 'non-number',
                width: 150,
            },

            {
                field: 'interactionSubject',
                headerName: "Interaction Subject",
                cellClass: 'non-number',
                width: 300,
                cellRenderer: 'jsonTooltipComponent'

            },
            {
                field: 'corproate',
                headerName: "Corproate",
                cellClass: 'non-number',
                width: 300,

            },
            {
                field: 'corporateAttendeesOrExperts',
                headerName: "Corporate Attendees/Experts",
                cellClass: 'non-number',
                width: 300,

            },
            {
                field: 'sector',
                headerName: "Sector",
                cellClass: 'non-number',
                width: 300,

            },
            {
                field: 'fileName',
                headerName: "FileName",
                cellClass: 'non-number',
                width: 300,
                hide: true,

            },
            {
                field: 'reportDate',
                headerName: "ReportDate",
                cellClass: 'non-number',
                width: 300,
                hide: true,

            },
        ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                // tagComponent: TagComponent
              },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            selectedRow: null,
            groupDefaultExpanded: 1,
            rowGroupPanelShow: 'always',
            pivotPanelShow: 'always',
                };
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.brokerReviewDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let brokerReviewInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectBrokerReviewInfo(brokerReviewInfoArray);
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'user-access-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };
    render() {

        const {
            // query
            loadBrokerReviewInfoActionStatus,
            loadBrokerReviewInfoResp,
        } = this.props;


        const isLoading = loadBrokerReviewInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadBrokerReviewInfoArray = [];
        if(!!loadBrokerReviewInfoResp && !!loadBrokerReviewInfoResp.data) {
            loadBrokerReviewInfoArray = loadBrokerReviewInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            // columnDefs={this.state.columnDefs}
                            // rowData={loadBrokerReviewInfoArray}
                            // defaultColDef={this.state.defaultColDef}
                            // rowSelection={this.state.rowSelection}
                            // suppressRowClickSelection={true}
                            // suppressAggFuncInHeader={true}
                            // enableSorting={true}
                            // enableFilter={true}
                            // animateRows={true}
                            // deltaRowDataMode={false}
                            // enableColResize={true}
                            // enableRangeSelection={true}
                            // getRowNodeId={this.getRowNodeId}
                            // context={this.state.context}
                            // statusBar={this.state.statusBar}
                            // // event
                            // onSelectionChanged={this.onDataSelectionChanged}
                            // resizable={true}
                            // sideBar={true}
                            // frameworkComponents={this.state.frameworkComponents}
                            // onGridReady={this.onGridReady}




                            columnDefs={this.state.columnDefs}
                            rowData={loadBrokerReviewInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            resizable={true}
                            sideBar={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            frameworkComponents={this.state.frameworkComponents}
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            onSelectionChanged={this.onSelectionChanged}
                            rowGroupPanelShow={this.state.rowGroupPanelShow}
                            groupDefaultExpanded={this.state.groupDefaultExpanded}
                            // onCellValueChanged={this.onCellValueChanged.bind(this)}
                        />
                    )}
            </div>
        );
    }
}

export default BrokerReviewGrid;
