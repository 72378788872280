import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load PB Short Interest Rate
  START_LOAD_PB_SHORT_INTEREST_RATE,
  LOAD_PB_SHORT_INTEREST_RATE_SUCCESS,
  LOAD_PB_SHORT_INTEREST_RATE_FAILURE,
  INIT_LOAD_PB_SHORT_INTEREST_RATE_ACTION_STATUS,
} from './pbShortInterestRateConstants';

const initialState = {
  pbShortInterestRateGridColumns: GridColumnMap.pbShortInterestRateGridColumns,

  // PB Short Interest Rate
  pbShortInterestRateArray: [],

  // Load PB Short Interest RateAction Status
  loadPBShortInterestRateActionStatus: ACTION_STATUS.READY,
}


// Load  PB Short Interest Rate
function startLoadPBShortInterestRate(state) {
  return {
    ...state,
    loadPBShortInterestRateActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPBShortInterestRateSuccess(state, resp) {
  return {
    ...state,
    loadPBShortInterestRateActionStatus: ACTION_STATUS.SUCCESS,
    pbShortInterestRateArray: resp.data,
  };
}

function loadPBShortInterestRateFailure(state, err) {
  return {
    ...state,
    loadPBShortInterestRateActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadPBShortInterestRateActionStatus(state) {
  return {
    ...state,
    loadPBShortInterestRateActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load PB Short Interest Rate
  [START_LOAD_PB_SHORT_INTEREST_RATE]: startLoadPBShortInterestRate,
  [LOAD_PB_SHORT_INTEREST_RATE_SUCCESS]: loadPBShortInterestRateSuccess,
  [LOAD_PB_SHORT_INTEREST_RATE_FAILURE]: loadPBShortInterestRateFailure,
  [INIT_LOAD_PB_SHORT_INTEREST_RATE_ACTION_STATUS]: initLoadPBShortInterestRateActionStatus,
});