import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';

import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_GENERAL_AGREEMENT_INFO,
    LOAD_GENERAL_AGREEMENT_INFO_SUCCESS,
    LOAD_GENERAL_AGREEMENT_INFO_FAILURE,
    INIT_LOAD_GENERAL_AGREEMENT_INFO_ACTION_STATUS,
    // select
    SELECT_GENERAL_AGREEMENT,
    SET_GRID_EVENT_ON_STATE,
    // add or edit
    OPEN_ADD_OR_EDIT_GENERAL_AGREEMENT_MODAL,
    RESET_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL,
    ADD_OR_EDIT_GENERAL_AGREEMENT_CHANGE_INPUT,
    START_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL,
    SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_SUCCESS,
    SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_FAILURE,
    INIT_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_ACTION_STATUS,
    // change status
    OPEN_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL,
    RESET_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL,
    CHANGE_GENERAL_AGREEMENT_STATUS_CHANGE_INPUT,
    START_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS,
    SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_SUCCESS,
    SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_FAILURE,
    INIT_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_ACTION_STATUS,
    // showAllshowValid
    SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,

} from './GeneralAgreementConstants';

import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
} from '../../../features/home/homeConstants';

const Option = Select.Option;


const departmentOptions = [];
departmentOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
departmentOptions.push(<Option key={'IT'}>{'IT'}</Option>);
departmentOptions.push(<Option key={'BD'}>{'BD'}</Option>);
departmentOptions.push(<Option key={'Admin'}>{'Admin'}</Option>);
departmentOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
departmentOptions.push(<Option key={'Operation'}>{'Operation'}</Option>);
departmentOptions.push(<Option key={'Finance'}>{'Finance'}</Option>);
departmentOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);

const companyOptions = [];
companyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
companyOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
companyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
companyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
companyOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
companyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
companyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
companyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
companyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
companyOptions.push(<Option key={'投资顾问 (上海)'}>{'投资顾问 (上海)'}</Option>);
companyOptions.push(<Option key={'私募管理 (上海)'}>{'私募管理 (上海)'}</Option>);
companyOptions.push(<Option key={'私募管理 (海南)'}>{'私募管理 (海南)'}</Option>);
companyOptions.push(<Option key={'资产管理 (上海)'}>{'资产管理 (上海)'}</Option>);
companyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
companyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
companyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
companyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
companyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
companyOptions.push(<Option key={'COP'}>{'COP'}</Option>);
companyOptions.push(<Option key={'EJ'}>{'EJ'}</Option>);
companyOptions.push(<Option key={'Wealth Ocean'}>{'Wealth Ocean'}</Option>);
companyOptions.push(<Option key={'Pinpoint Charity'}>{'Pinpoint Charity'}</Option>);
companyOptions.push(<Option key={'MetaPoint'}>{'MetaPoint'}</Option>);
companyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
companyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
companyOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const agreementTypeOptions = [];
agreementTypeOptions.push(<Option key={'Agreement'}>{'Agreement'}</Option>);
agreementTypeOptions.push(<Option key={'ApplicationForm'}>{'ApplicationForm'}</Option>);
agreementTypeOptions.push(<Option key={'NDA'}>{'NDA'}</Option>);
agreementTypeOptions.push(<Option key={'Contract'}>{'Contract'}</Option>);
agreementTypeOptions.push(<Option key={'EngagementLetter'}>{'EngagementLetter'}</Option>);

const noticePeriodOptions = [];
// noticePeriodOptions.push(<Option key={'7'}>{'7 days'}</Option>);
// noticePeriodOptions.push(<Option key={'30'}>{'30 days'}</Option>);
// noticePeriodOptions.push(<Option key={'60'}>{'60 days'}</Option>);
// noticePeriodOptions.push(<Option key={'90'}>{'90 days'}</Option>);
// noticePeriodOptions.push(<Option key={'180'}>{'180 days'}</Option>);
// noticePeriodOptions.push(<Option key={'365'}>{'365 days'}</Option>);

const ccyOptions = [];
ccyOptions.push(<Option key={'USD'}>{'USD'}</Option>);
ccyOptions.push(<Option key={'CNY'}>{'CNY'}</Option>);
ccyOptions.push(<Option key={'HKD'}>{'HKD'}</Option>);
ccyOptions.push(<Option key={'SGD'}>{'SGD'}</Option>);
ccyOptions.push(<Option key={'JPY'}>{'JPY'}</Option>);
ccyOptions.push(<Option key={'INR'}>{'INR'}</Option>);

const initialState = {
    departmentOptions: departmentOptions,
    companyOptions: companyOptions,
    agreementTypeOptions: agreementTypeOptions,
    noticePeriodOptions: noticePeriodOptions,
    ccyOptions: ccyOptions,

    generalAgreementUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.GENERAL_AGREEMENT_UPLOAD_URL,
    generalAgreementDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.GENERAL_AGREEMENT_DOWNLOAD_URL,

    generalAgreementQueryFields: {
        id: undefined,
        title: undefined,
        personalInCharge: undefined,
    },
    selectedGeneralAgreementArray: [],

    addOrEditGeneralAgreementModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: undefined,
            title: undefined,
            department: undefined,
            personInCharge: undefined,
            company: undefined,
            counterparty: undefined,
            agreementType: undefined,
            executionDate: undefined,
            expiryDate: undefined,
            noticePeriod: undefined,
            terms: undefined,
            uploadOriName: undefined,
            uploadRealName: undefined,
            amount: undefined,
            amountCCY: undefined,
            payer: undefined,
            remarks: undefined,
            comments: undefined,
            source: 'Admin',
            modes: undefined,
            agreementUploadFileList: [],
        },
    },

    changeGeneralAgreementStatusModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: undefined,
            title: undefined,
            department: undefined,
            personInCharge: undefined,
            company: undefined,
            counterparty: undefined,
            agreementType: undefined,
            executionDate: undefined,
            expiryDate: undefined,
            noticePeriod: undefined,
            terms: undefined,
            uploadOriName: undefined,
            uploadRealName: undefined,
            remarks: undefined,
            comments: undefined,
            source: 'Admin',
            modes: undefined, 
        }
    },

    onlyShowValidGeneralAgreement: true,

};


// user account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
    return {
        ...state,
        userAccountOptions,
      };
}


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.generalAgreementQueryFields,
        [name]: value,
    };
    return {
        ...state,
        generalAgreementQueryFields: upatedFields,
    }
}
function startLoadGeneralAgreementInfo(state) {
    return {
        ...state,
        loadGeneralAgreementActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadGeneralAgreementInfoSuccess(state, resp) {
    return {
        ...state,
        loadGeneralAgreementActionStatus: ACTION_STATUS.SUCCESS,
        loadGeneralAgreementResp: resp,
    };
}
function loadGeneralAgreementInfoFailure(state, err) {
    return {
        ...state,
        loadGeneralAgreementActionStatus: ACTION_STATUS.ERROR,
        loadGeneralAgreementResp: err,
    };
}
function initLoadGeneralAgreementInfoActionStatus(state) {
    return {
        ...state,
        loadGeneralAgreementActionStatus: ACTION_STATUS.READY,
        selectedGeneralAgreementArray: [],
    };
}


// select
function selectGeneralAgreement(state, array) {
    return {
        ...state,
        selectedGeneralAgreementArray: array,
    };
}
function setGridEventOnState(state, event) {
    return {
        ...state,
        generalAgreementGridEvent: event,
    }
}


// add or edit
function openAddOrEditGeneralAgreementModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditGeneralAgreementModal: {
                ...state.addOrEditGeneralAgreementModal,
                isOpened: true,
                mode,
            },
        };

    } else {
        const {
            id,
            status,
            title,
            department,
            personInCharge,
            company,
            counterparty,
            agreementType,
            executionDate,
            expiryDate,
            noticePeriod,
            terms,
            uploadOriName,
            uploadRealName,
            amount,
            amountCCY,
            payer,
            remarks,
        } = data;

        // COPY时不COPY附件
        const agreementUploadFileList = [];
        let newUploadOriName = uploadOriName;
        let newUploadRealName = uploadRealName;
        if(mode === 'EDIT' && !!uploadOriName && !!uploadRealName) {
            const file = {
                uid: '-1',
                name: uploadOriName,
                status: 'done',
                url: state.generalAgreementDownloadUrl + "/" + uploadRealName,
                response: {
                    respCode: "S0001",
                    fileNameWithTimeStamp: uploadRealName,
                    originalFileName: uploadOriName,
                }
            };
            agreementUploadFileList.push(file);

        } else {
            newUploadOriName = undefined;
            newUploadRealName = undefined;
        }

        return {
            ...state,
            addOrEditGeneralAgreementModal: {
                ...state.addOrEditGeneralAgreementModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditGeneralAgreementModal.fields,
                    id,
                    status,
                    title,
                    department,
                    personInCharge,
                    company,
                    counterparty,
                    agreementType,
                    executionDate,
                    expiryDate,
                    noticePeriod,
                    terms,
                    uploadOriName: newUploadOriName,
                    uploadRealName: newUploadRealName,
                    amount,
                    amountCCY,
                    payer,
                    remarks,
                    agreementUploadFileList,
                }
            },
        };
    }
}
function resetSubmitSaveGeneralAgreementModal(state) {
    return {
        ...state,
        addOrEditGeneralAgreementModal: initialState.addOrEditGeneralAgreementModal,
    }
}
function addOrEditGeneralAgreementChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditGeneralAgreementModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditGeneralAgreementModal: {
          ...state.addOrEditGeneralAgreementModal,
          fields: updatedFields,
        },
    };
}

function startSubmitSaveGeneralAgreementModal(state) {
    return {
        ...state,
        submitSaveGeneralAgreementActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveGeneralAgreementModalSuccess(state, resp) {
    return {
        ...state,
        submitSaveGeneralAgreementActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitSaveGeneralAgreementModalFailure(state, err) {
    return {
        ...state,
        submitSaveGeneralAgreementActionStatus: ACTION_STATUS.ERROR,
        addOrEditGeneralAgreementModal: {
            ...state.addOrEditGeneralAgreementModal,
            respErrMsg: err.respMessage,
        },
    };
}
function initSubmitSaveGeneralAgreementModalActionStatus(state) {
    return {
        ...state,
        submitSaveGeneralAgreementActionStatus: ACTION_STATUS.READY,
    }; 
}


// change status
function openChangeGeneralAgreementStatusModal(state, {mode, data}) {
    const {
        id,
        status,
        title,
        department,
        personInCharge,
        company,
        counterparty,
        agreementType,
        executionDate,
        expiryDate,
        noticePeriod,
        terms,
        uploadOriName,
        uploadRealName,
        amount,
        amountCCY,
        payer,
        remarks,
    } = data;
    return {
        ...state,
        changeGeneralAgreementStatusModal: {
            ...state.changeGeneralAgreementStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.changeGeneralAgreementStatusModal.fields,
                id,
                status,
                title,
                department,
                personInCharge,
                company,
                counterparty,
                agreementType,
                executionDate,
                expiryDate,
                noticePeriod,
                terms,
                uploadOriName,
                uploadRealName,
                amount,
                amountCCY,
                payer,
                remarks,
            }
        },
    };
}
function resetChangeGeneralAgreementStatusModal(state) {
    return {
        ...state,
        changeGeneralAgreementStatusModal: initialState.changeGeneralAgreementStatusModal,
    }
}
function changeGeneralAgreementStatusChangeInput(state, {name, value}) {
    return {
        ...state,
        changeGeneralAgreementStatusModal: {
            ...state.changeGeneralAgreementStatusModal,
            fields: {
                ...state.changeGeneralAgreementStatusModal.fields,
                [name]: value,
            }
        },
    }
}

function startSubmitChangeGeneralAgreementStatus(state) {
    return {
        ...state,
        submitChangeGeneralAgreementStatusActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitChangeGeneralAgreementStatusSuccess(state, resp) {
    return {
        ...state,
        submitChangeGeneralAgreementStatusActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitChangeGeneralAgreementStatusFailure(state, err) {
    return {
        ...state,
        submitChangeGeneralAgreementStatusActionStatus: ACTION_STATUS.ERROR,
        changeGeneralAgreementStatusModal: {
            ...state.changeGeneralAgreementStatusModal,
            respErrMsg: err.respMessage,
        },
    };
}
function initSubmitChangeGeneralAgreementStatusActionStatus(state) {
    return {
        ...state,
        submitChangeGeneralAgreementStatusActionStatus: ACTION_STATUS.READY,
    }; 
}


// showAllshowValid
function showAllShowValidBtnOnClicked(state) {
    return {
        ...state,
        onlyShowValidGeneralAgreement: !state.onlyShowValidGeneralAgreement,
        selectedGeneralAgreementArray: [],
    }
}


export default createReducer(initialState, {
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_GENERAL_AGREEMENT_INFO]: startLoadGeneralAgreementInfo,
    [LOAD_GENERAL_AGREEMENT_INFO_SUCCESS]: loadGeneralAgreementInfoSuccess,
    [LOAD_GENERAL_AGREEMENT_INFO_FAILURE]: loadGeneralAgreementInfoFailure,
    [INIT_LOAD_GENERAL_AGREEMENT_INFO_ACTION_STATUS]: initLoadGeneralAgreementInfoActionStatus,
    // select
    [SELECT_GENERAL_AGREEMENT]: selectGeneralAgreement,
    [SET_GRID_EVENT_ON_STATE]: setGridEventOnState,
    // add or edit
    [OPEN_ADD_OR_EDIT_GENERAL_AGREEMENT_MODAL]: openAddOrEditGeneralAgreementModal,
    [RESET_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL]: resetSubmitSaveGeneralAgreementModal,
    [ADD_OR_EDIT_GENERAL_AGREEMENT_CHANGE_INPUT]: addOrEditGeneralAgreementChangeInput,
    [START_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL]: startSubmitSaveGeneralAgreementModal,
    [SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_SUCCESS]: submitSaveGeneralAgreementModalSuccess,
    [SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_FAILURE]: submitSaveGeneralAgreementModalFailure,
    [INIT_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_ACTION_STATUS]: initSubmitSaveGeneralAgreementModalActionStatus,
    // change status
    [OPEN_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL]: openChangeGeneralAgreementStatusModal,
    [RESET_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL]: resetChangeGeneralAgreementStatusModal,
    [CHANGE_GENERAL_AGREEMENT_STATUS_CHANGE_INPUT]: changeGeneralAgreementStatusChangeInput,
    [START_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS]: startSubmitChangeGeneralAgreementStatus,
    [SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_SUCCESS]: submitChangeGeneralAgreementStatusSuccess,
    [SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_FAILURE]: submitChangeGeneralAgreementStatusFailure,
    [INIT_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_ACTION_STATUS]: initSubmitChangeGeneralAgreementStatusActionStatus,
    // showAllshowValid
    [SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED]: showAllShowValidBtnOnClicked,

});