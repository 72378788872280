import React, { Component } from 'react';
import { Input, Form, Modal, Button, message,Select } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelBrokerReviewModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.brokerReviewQueryFields;
        this.props.loadBrokerReviewInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'inactive') {
            return 'Inactive User Access Info';
        } 
        if(mode === 'REPLACE'){
            return 'REPLACE User ';
        }
        if(mode === 'del'){
            return 'DEL User Access Info';
        }
    }
    componentDidUpdate() {
        const {
            delBrokerReviewStatus,
        } = this.props;
        if(delBrokerReviewStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delBrokerReviewModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelBrokerReviewActionStatus();
            this.props.resetDelBrokerReviewModal();
            this.refreshQuery();
        }
        if(delBrokerReviewStatus === ACTION_STATUS.ERROR) {
            this.props.initDelBrokerReviewActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delBrokerReviewChangeInput({name, value});
    }


    submitDelBrokerReview = () => {

        Modal.confirm({
            title: 'Are you sure del or inactive or replace this record?',
            onOk: () => {

                const {
                    mode,
                    idList,
                } = this.props.delBrokerReviewModal;
                const {
                    id,
                    comments,
                    employee,
                } = this.props.delBrokerReviewModal.fields;
                this.props.delBrokerReview({id, mode, comments,employee,idList});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delBrokerReviewModal,
            delBrokerReviewStatus,
            userAccountOptions,
        } = this.props;
        const {
            isOpened,
            mode,
            idList,
            respErrMsg,
        } = delBrokerReviewModal;


        const isSubmitLoading = delBrokerReviewStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        const replaceFlag = mode ==='REPLACE'
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={400}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelBrokerReviewModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelBrokerReviewModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelBrokerReview}>
                          Submit
                        </Button>,
                      ]}
                >
                    {
                    replaceFlag && 
                        <Select
                                allowClear
                                showSearch
                                style={{ width: 350, marginLeft: 0 }}
                                onChange={(val) => {
                                    this.onInputChange({ name: 'employee', value: val })
                                }} 
                                placeholder="replace with user">
                            {userAccountOptions}
                        </Select>
                    }
                        <TextArea 
                       
                            style={{ width: 400 ,marginTop: 10}}
                            rows={3} 
                            placeholder="Remark"
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelBrokerReviewModalDialogForm = Form.create({ name: 'DelBrokerReviewModalDialog' })(DelBrokerReviewModalDialog);
export default DelBrokerReviewModalDialogForm;
