
import moment from 'moment';

const questionListGridColumns = [
  {
    field: 'questionNumber',
    headerName: "Item Number",
    cellClass: 'non-number',
    width: 150,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'subQuestionNumber',
    headerName: "SubItem Number",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'questionEnglish',
    headerName: "Item English",
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'questionChinese',
    headerName: "Item Chinese",
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'questionTypeLabel',
    headerName: 'Item Type',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'options',
    headerName: "Options",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'parentQuestionNumber',
    headerName: "Parent Item Number",
    cellClass: 'non-number',
    width: 180,
  },
  {
    field: 'triggerOption',
    headerName: "Trigger Option",
    cellClass: 'non-number',
    width: 130,
  },
  // {
  //   field: 'correctAnswer',
  //   headerName: "Correct Answer",
  //   cellClass: 'non-number',
  //   width: 130,
  // },
  // {
  //   field: 'score',
  //   headerName: "Score",
  //   cellClass: 'non-number',
  //   width: 130,
  // },
  {
    field: 'attachmentOriginName',
    headerName: 'Attachment',
    cellRenderer: "questionniareAttachmentRenderer",
    cellClass: 'non-number',
    width: 350,
  },
];

const licensingFormGridColumns = [
  {
    field: 'name',
    headerName: "Licensing Form Version",
    cellClass: 'non-number',
    width: 300,
    cellRenderer: "agGroupCellRenderer",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'title',
    headerName: "Name on Licensing Form",
    cellClass: 'non-number',
    width: 400,
  },
  {
    field: 'type',
    headerName: "Type",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'activeLabel',
    headerName: "Status",
    cellClass: 'non-number',
    width: 120,
  },
  
  {
    field: 'createdBy',
    headerName: "Create By",
    cellClass: 'non-number',
    width: 120,
  },
  // {
  //   field: 'expiredDateLabel',
  //   headerName: "Expired Date",
  //   cellClass: 'non-number',
  //   width: 120,
  // },
  // {
  //   field: 'attachmentOriginName',
  //   headerName: 'Attachment',
  //   cellRenderer: "questionniareAttachmentRenderer",
  //   cellClass: 'non-number',
  //   width: 350,
  // },
];

const licensingFormResultGridColumns = [
  {
    field: 'questionnaireName',
    headerName: "Questionnaire Version",
    cellClass: 'non-number',
    width: 300,
    cellRenderer: "agGroupCellRenderer",
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'answeree',
    headerName: "Answeree",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'submitter',
    headerName: "Submitter",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'createdDateLabel',
    headerName: "Sent On",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'submittedDateLabel',
    headerName: "Submitted On",
    cellClass: 'non-number',
    width: 120,
    cellClassRules: {
      'quiz-expired': function(node) {
          if(node.data.type !== 'QUIZ') {
            return false;
          }
          let receivedOn = node.data.createdDateLabel;
          let submittedOn = node.data.submittedDateLabel;
          if(!submittedOn) {
            submittedOn = moment().format('YYYY-MM-DD');
          }
          return (submittedOn > receivedOn);
      },
    },
  },
  {
    field: 'statusLabel',
    headerName: "Status",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'type',
    headerName: "Type",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'emailSendStatusLabel',
    headerName: "Email Send Status",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'totalScore',
    headerName: "Total Score",
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'result',
    headerName: "Result",
    cellClass: 'non-number',
    width: 100,
    cellStyle: (param) => {
      if(param.value === 'Pending') {
          return {color: 'Goldenrod'};
      } else if(param.value === 'Pass') {
          return {color: 'green'};
      } else if(param.value === 'Fail') {
          return {color: 'red'};
      } else {
          return {color: 'black'};
      }
    }
  },
  {
    field: 'savedFileName',
    headerName: "Saved File Name",
    cellClass: 'non-number',
    width: 180,
  },
];

const questionAnswerGridColumns = [
  // {
  //   field: 'questionnaireName',
  //   headerName: "Questionnaire Name",
  //   cellClass: 'non-number',
  //   width: 200,
  // },
  {
    field: 'questionNumber',
    headerName: "Item Number",
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'subQuestionNumber',
    headerName: "SubQuestion Number",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'questionEnglish',
    headerName: "Item English",
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'questionChinese',
    headerName: "Item Chinese",
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'questionTypeLabel',
    headerName: "Item Type",
    cellClass: 'non-number',
    width: 120,
  },
  // {
  //   field: 'answeree',
  //   headerName: "Answeree",
  //   cellClass: 'non-number',
  //   width: 150,
  // },
  {
    field: 'answerContent',
    headerName: "Answer Content",
    cellClass: 'non-number',
    width: 250,
  },
  // {
  //   field: 'score',
  //   headerName: "Score",
  //   cellClass: 'non-number',
  //   editable: function (params) {
  //     return !(params.data.questionType === '1' || params.data.questionType === '2');
  //   },
  //   // cellEditorSelector: function (params) {
  //   //   if (params.data.questionType === '1' || params.data.questionType === '2') {
  //   //     return {
  //   //       component: 'numericCellEditor'
  //   //     };
  //   //   }
  //   //   return null;
  //   // },
  //   // onCellValueChanged: function (params) {
  //   //   debugger;
  //   //   console.log(this);
  //   //   console.log(params);
  //   // },
  //   width: 120,
  // },
  {
    field: 'correctAnswer',
    headerName: "Correct Answer",
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'answerDateLabel',
    headerName: "Answer Date",
    cellClass: 'non-number',
    width: 150,
  },
];

const submittedHistoryGridColumns = [
  {
    field: 'name',
    headerName: "Name",
    cellClass: 'non-number',
    width: 200,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'submittedDateLabel',
    headerName: "Submitted Date",
    cellClass: 'non-number',
    width: 150,
  },
];

const GridColumnMap = {
  questionListGridColumns,
  licensingFormGridColumns,
  licensingFormResultGridColumns,
  questionAnswerGridColumns,
  submittedHistoryGridColumns,
};

export default GridColumnMap;