import {connect} from 'react-redux';
import PersonalTradeDashboard from '../components/PersonalTradeDashboard';

import {
  // Load Personal Trade
  loadPersonalTrade,
  initLoadPersonalTradeActionStatus,
  queryPersonalTradeInputChange,

  // Select Personal Trade Request
  selectPersonalTradeRequest,

  // Open/Reset Personal Trade Feedback Modal Dialog
  openPersonalTradeFeedbackModalDialog,
  resetPersonalTradeFeedbackModalDialog,

  // Personal Trade Feedback Edit Change Input
  personalTradeFeedbackEditChangeInput,

  // Approve Personal Trade
  approvePersonalTrade,
  initApprovePersonalTradeActionStatus,

  // Final Approve Personal Trade
  finalApprovePersonalTrade,
  initFinalApprovePersonalTradeActionStatus,

  // Reject Personal Trade
  rejectPersonalTrade,
  initRejectPersonalTradeActionStatus,

  // Feedback Personal Trade
  feedbackPersonalTrade,
  initFeedbackPersonalTradeActionStatus,

  // Open/Reset Personal Trade Operation Record Modal Dialog
  openPersonalTradeOperationRecordModalDialog,
  resetPersonalTradeOperationRecordModalDialog,

  // Load Personal Trade Operation Record
  loadPersonalTradeOperationRecord,
  initLoadPersonalTradeOperationRecordActionStatus,

  // Open/Reset Personal Trade Request Edit Modal Dialog
  openPersonalTradeRequestEditModalDialog,
  resetPersonalTradeRequestEditModalDialog,

  // Personal Trade Request Edit Change Input
  personalTradeRequestEditChangeInput,

  // Add Personal Trade Request
  addPersonalTradeRequest,
  initAddPersonalTradeRequestActionStatus,

  // Edit Personal Trade Request
  editPersonalTradeRequest,
  initEditPersonalTradeRequestActionStatus,

  // Delete Personal Trade Request
  batchDeletePersonalTradeRequest,
  initBatchDeletePersonalTradeRequestActionStatus,

  // Copy Personal Trade Request
  copyPersonalTradeRequest,
  initCopyPersonalTradeRequestActionStatus,

  // Load BBG Tickers
  loadBbgTickers,
  initLoadBbgTickersActionStatus,

  // Clear bbgTickerArray bbgTickerOptions
  clearBbgTickers,

  // BBG Ticker Select Changed
  bbgTickerSelectChanged,

  // Load Personal Trade Field History
  loadPersonalTradeFieldHistory,
  initLoadPersonalTradeFieldHistoryActionStatus,

  // Load Exchange Code
  loadExchangeCode,
  initLoadExchangeCodeActionStatus,

  // Clear exchageCodeArray exchageCodeOptions
  clearExchangeCodes,

  // Exchange Code Select Changed
  exchangeCodeSelectChanged,

  // Open/Reset Personal Trade Request Recognize Modal Dialog
  openPersonalTradeRequestRecognizeModalDialog,
  resetPersonalTradeRequestRecognizeModalDialog,

  // Recognize Personal Trade Request
  recognizePersonalTradeRequest,
  initRecognizePersonalTradeRequestActionStatus,

  // Open/Reset Personal Trade Request Operate Modal Dialog
  openPersonalTradeRequestOperateModalDialog,
  resetPersonalTradeRequestOperateModalDialog,

  // Open/Reset Monthly Report Modal Dialog
  openMonthlyReportModalDialog,
  resetMonthlyReportModalDialog,

  // Load Monthly Report
  loadMonthlyReport,
  initLoadMonthlyReportActionStatus,

  // Send PA Dealing Apply Monthly Report Email
  sendPADealingApplyMonthlyReportEmail,
  initSendPADealingApplyMonthlyReportEmailActionStatus,

  // 30天持仓相关预检查
  reset30DayHoldingCheckInfo,
  query30DayHoldingCheckInfo,
  startTrigger30DayHoldingCheckInfoStatus,
  stopTrigger30DayHoldingCheckInfoStatus,

} from '../personalTradeActions';


import {
  loadPersonalAccountWithEmployee,
} from '../../personalAccount/AccountHoldingActions';

import {
  queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions';



const mapStateToProps = state => {
  return {
    personalTradeGridColumns: state.personalTrade.personalTradeGridColumns,
    personalTradeQueryParam: state.personalTrade.personalTradeQueryParam,

    // Personal Trade
    personalTradeArray: state.personalTrade.personalTradeArray,

    // PersonalTrade Feedback
    personalTradeFeedbackControlModal: state.personalTrade.personalTradeFeedbackControlModal,
  
    // Selected Personal Trade
    selectedPersonalTradeArray: state.personalTrade.selectedPersonalTradeArray,

    // Load Personal Trade Action Status
    loadPersonalTradeActionStatus: state.personalTrade.loadPersonalTradeActionStatus,

    // Approve Personal Trade Action Status
    approvePersonalTradeActionStatus: state.personalTrade.approvePersonalTradeActionStatus,

    // Final Approve Personal Trade Action Status
    finalApprovePersonalTradeActionStatus: state.personalTrade.finalApprovePersonalTradeActionStatus,

    // Reject Personal Trade Action Status
    rejectPersonalTradeActionStatus: state.personalTrade.rejectPersonalTradeActionStatus,

    // Feedback Personal Trade Action Status
    feedbackPersonalTradeActionStatus: state.personalTrade.feedbackPersonalTradeActionStatus,

    // Personal Trade Operation Record Modal Dialog
    personalTradeOpRecordControlModal: state.personalTrade.personalTradeOpRecordControlModal,

    personalTradeOpRecordGridColumns: state.personalTrade.personalTradeOpRecordGridColumns,

    // Load Personal Trade Operation Record Action Status
    loadPersonalTradeOperationRecordActionStatus: state.personalTrade.loadPersonalTradeOperationRecordActionStatus,
    personalTradeOperationRecordArray: state.personalTrade.personalTradeOperationRecordArray,

    // Personal Trade Request Edit Control Modal
    personalTradeRequestEditControlModal: state.personalTrade.personalTradeRequestEditControlModal,

    // Add/Edit/Delete Personal Trade Request Action Status
    addPersonalTradeRequestActionStatus: state.personalTrade.addPersonalTradeRequestActionStatus,
    editPersonalTradeRequestActionStatus: state.personalTrade.editPersonalTradeRequestActionStatus,
    batchDeletePersonalTradeRequestActionStatus: state.personalTrade.batchDeletePersonalTradeRequestActionStatus,
    copyPersonalTradeRequestActionStatus: state.personalTrade.copyPersonalTradeRequestActionStatus,
    batchDeletePersonalTradeRequestErrMsg: state.personalTrade.batchDeletePersonalTradeRequestErrMsg,

    // Security Ticker Data
    securityTickerData: state.personalTrade.securityTickerData,
    bbgTickerArray: state.personalTrade.bbgTickerArray,
    bbgTickerOptions: state.personalTrade.bbgTickerOptions,
    securityTickerArray: state.personalTrade.securityTickerArray,

    // Load BBG Tickers Action Status
    loadBbgTickersActionStatus: state.personalTrade.loadBbgTickersActionStatus,

    // Load Personal Trade Field History
    loadTradeFieldHistoryActionStatus: state.personalTrade.loadTradeFieldHistoryActionStatus,
    personalTradeFieldHistory: state.personalTrade.personalTradeFieldHistory,

    // Load Exchange Code
    loadExchangeCodeActionStatus: state.personalTrade.loadExchangeCodeActionStatus,
    exchageCodeArray: state.personalTrade.exchageCodeArray,
    exchageCodeOptions: state.personalTrade.exchageCodeOptions,

    // Load User Account
    userAccountOptions: state.personalTrade.userAccountOptions,

    // Personal Trade Request Recognize Modal Dialog
    personalTradeRequestRecognizeControlModal: state.personalTrade.personalTradeRequestRecognizeControlModal,

    // Personal Trade Request Recognize Grid Columns
    personalTradeRecognizeGridColumns: state.personalTrade.personalTradeRecognizeGridColumns,

    // Recognize Personal Trade Request
    recognizePersonalTradeRequestActionStatus: state.personalTrade.recognizePersonalTradeRequestActionStatus,
    recognizePersonalTradeRequestErrMsg: state.personalTrade.recognizePersonalTradeRequestErrMsg,
    recognizePersonalTradeResultArray: state.personalTrade.recognizePersonalTradeResultArray,

    // 附件操作URL
    tradeAttachmentUploadUrl: state.personalTrade.tradeAttachmentUploadUrl,
    tradeAttachmentPreviewUrl: state.personalTrade.tradeAttachmentPreviewUrl,
    tradeAttachmentDownloadUrl: state.personalTrade.tradeAttachmentDownloadUrl,

    // Personal Trade Request Operate Modal Dialog
    personalTradeRequestOperateControlModal: state.personalTrade.personalTradeRequestOperateControlModal,

    // Open/Reset Monthly Report Modal Dialog
    monthlyReportControlModal: state.personalTrade.monthlyReportControlModal,

    // Monthly Report Result
    monthlyReportResult: state.personalTrade.monthlyReportResult,

    // Load Monthly Report Action Status
    loadMonthlyReportActionStatus: state.personalTrade.loadMonthlyReportActionStatus,

    // Send PA Dealing Apply Monthly Report Email Action Status
    sendPADealingApplyMonthlyReportEmailActionStatus: state.personalTrade.sendPADealingApplyMonthlyReportEmailActionStatus,
    sendPADealingApplyMonthlyReportEmailErrMsg: state.personalTrade.sendPADealingApplyMonthlyReportEmailErrMsg,

    // PA Dealing Daily Report操作URL
    exportPADealingDailyReportUrl: state.personalTrade.exportPADealingDailyReportUrl,

    personalAccountArray: state.personalAccount.personalAccountArray,

    // 30天持仓相关预检查
    query30DayHoldingCheckInfoStatus:  state.personalTrade.query30DayHoldingCheckInfoStatus,
    resultOf30DayHoldingCheckInfo:  state.personalTrade.resultOf30DayHoldingCheckInfo,
    trigger30DayHoldingCheckInfoStatus: state.personalTrade.trigger30DayHoldingCheckInfoStatus,

    // fuzzyQueryEmployeeNameOptions: state.personalAccount.fuzzyQueryEmployeeNameOptions.personalTrade,

  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Personal Trade
    loadPersonalTrade: (params) => dispatch(loadPersonalTrade(params)),
    initLoadPersonalTradeActionStatus: () => dispatch(initLoadPersonalTradeActionStatus()),
    queryPersonalTradeInputChange: (param) => dispatch(queryPersonalTradeInputChange(param)),
    // clearEmployeeNameList: (module) => dispatch(clearEmployeeNameList(module)),
    // fuzzySearchEmployee: (module, namePart) => dispatch(fuzzySearchEmployee(module, namePart)),
    
    // Select Personal Trade Request
    selectPersonalTradeRequest: (payload) => dispatch(selectPersonalTradeRequest(payload)),

    // Open/Reset Personal Trade Feedback Modal Dialog
    openPersonalTradeFeedbackModalDialog: (personalTrade) => dispatch(openPersonalTradeFeedbackModalDialog(personalTrade)),
    resetPersonalTradeFeedbackModalDialog: () => dispatch(resetPersonalTradeFeedbackModalDialog()),

    // Personal Trade Feedback Edit Change Input
    personalTradeFeedbackEditChangeInput: (input) => dispatch(personalTradeFeedbackEditChangeInput(input)),

    // Approve Personal Trade
    approvePersonalTrade: (comments, personalTradeIdArray) => dispatch(approvePersonalTrade(comments, personalTradeIdArray)),
    initApprovePersonalTradeActionStatus: () => dispatch(initApprovePersonalTradeActionStatus()),

    // Final Approve Personal Trade
    finalApprovePersonalTrade: (comments, personalTradeIdArray) => dispatch(finalApprovePersonalTrade(comments, personalTradeIdArray)),
    initFinalApprovePersonalTradeActionStatus: () => dispatch(initFinalApprovePersonalTradeActionStatus()),

    // Reject Personal Trade
    rejectPersonalTrade: (comments, personalTradeIdArray) => dispatch(rejectPersonalTrade(comments, personalTradeIdArray)),
    initRejectPersonalTradeActionStatus: () => dispatch(initRejectPersonalTradeActionStatus()),

    // Feedback Personal Trade
    feedbackPersonalTrade: (id, feedback) => dispatch(feedbackPersonalTrade(id, feedback)),
    initFeedbackPersonalTradeActionStatus: () => dispatch(initFeedbackPersonalTradeActionStatus()),

    // Open/Reset Personal Trade Operation Record Modal Dialog
    openPersonalTradeOperationRecordModalDialog: () => dispatch(openPersonalTradeOperationRecordModalDialog()),
    resetPersonalTradeOperationRecordModalDialog: () => dispatch(resetPersonalTradeOperationRecordModalDialog()),

    // Load Personal Trade Operation Record
    loadPersonalTradeOperationRecord: () => dispatch(loadPersonalTradeOperationRecord()),
    initLoadPersonalTradeOperationRecordActionStatus: () => dispatch(initLoadPersonalTradeOperationRecordActionStatus()),

    // Open/Reset Personal Trade Request Edit Modal Dialog
    openPersonalTradeRequestEditModalDialog: (isAddMode, tradeRequest) => dispatch(openPersonalTradeRequestEditModalDialog(isAddMode, tradeRequest)),
    resetPersonalTradeRequestEditModalDialog: () => dispatch(resetPersonalTradeRequestEditModalDialog()),

    // Personal Trade Request Edit Change Input
    personalTradeRequestEditChangeInput: (input) => dispatch(personalTradeRequestEditChangeInput(input)),

    // Add Personal Trade Request
    addPersonalTradeRequest: (tradeRequest) => dispatch(addPersonalTradeRequest(tradeRequest)),
    initAddPersonalTradeRequestActionStatus: () => dispatch(initAddPersonalTradeRequestActionStatus()),

    // Edit Personal Trade Request
    editPersonalTradeRequest: (tradeRequest) => dispatch(editPersonalTradeRequest(tradeRequest)),
    initEditPersonalTradeRequestActionStatus: () => dispatch(initEditPersonalTradeRequestActionStatus()),

    // Batch Delete Personal Trade Request
    batchDeletePersonalTradeRequest: (comments, tradeRequestIdList) => dispatch(batchDeletePersonalTradeRequest(comments, tradeRequestIdList)),
    initBatchDeletePersonalTradeRequestActionStatus: () => dispatch(initBatchDeletePersonalTradeRequestActionStatus()),

    // Copy Personal Trade Request
    copyPersonalTradeRequest: (tradeRequest) => dispatch(copyPersonalTradeRequest(tradeRequest)),
    initCopyPersonalTradeRequestActionStatus: () => dispatch(initCopyPersonalTradeRequestActionStatus()),

    // Load BBG Tickers
    loadBbgTickers: (queryText) => dispatch(loadBbgTickers(queryText)),
    initLoadBbgTickersActionStatus: () => dispatch(initLoadBbgTickersActionStatus()),

    // Clear bbgTickerArray bbgTickerOptions
    clearBbgTickers: () => dispatch(clearBbgTickers()),
    
    // BBG Ticker Select Changed
    bbgTickerSelectChanged: (bbgTicker) => dispatch(bbgTickerSelectChanged(bbgTicker)),

    // Load Personal Trade Field History
    // loadPersonalTradeFieldHistory: (employee) => dispatch(loadPersonalTradeFieldHistory(employee)),
    // initLoadPersonalTradeFieldHistoryActionStatus: () => dispatch(initLoadPersonalTradeFieldHistoryActionStatus()),
    loadPersonalAccountWithEmployee: (employee) => dispatch(loadPersonalAccountWithEmployee(employee)),

    // Load Exchange Code
    loadExchangeCode: (queryText) => dispatch(loadExchangeCode(queryText)),
    initLoadExchangeCodeActionStatus: () => dispatch(initLoadExchangeCodeActionStatus()),

    // Clear exchageCodeArray exchageCodeOptions
    clearExchangeCodes: () => dispatch(clearExchangeCodes()),

    // Exchange Code Select Changed
    exchangeCodeSelectChanged: (exchangeCode) => dispatch(exchangeCodeSelectChanged(exchangeCode)),

    // Open/Reset Personal Trade Request Recognize Modal Dialog
    openPersonalTradeRequestRecognizeModalDialog: (personalTradeRequestIdList) => dispatch(openPersonalTradeRequestRecognizeModalDialog(personalTradeRequestIdList)),
    resetPersonalTradeRequestRecognizeModalDialog: () => dispatch(resetPersonalTradeRequestRecognizeModalDialog()),

    // Recognize Personal Trade Request
    recognizePersonalTradeRequest: (personalTradeRequestIdList) => dispatch(recognizePersonalTradeRequest(personalTradeRequestIdList)),
    initRecognizePersonalTradeRequestActionStatus: () => dispatch(initRecognizePersonalTradeRequestActionStatus()),

    // Open/Reset Personal Trade Request Operate Modal Dialog
    openPersonalTradeRequestOperateModalDialog: (operateMode, isNonCompliance, personalTradeRequestIdList) => dispatch(openPersonalTradeRequestOperateModalDialog(operateMode, isNonCompliance, personalTradeRequestIdList)),
    resetPersonalTradeRequestOperateModalDialog: () => dispatch(resetPersonalTradeRequestOperateModalDialog()),

    // Open/Reset Monthly Report Modal Dialog
    openMonthlyReportModalDialog: () => dispatch(openMonthlyReportModalDialog()),
    resetMonthlyReportModalDialog: () => dispatch(resetMonthlyReportModalDialog()),

    // Load Monthly Report
    loadMonthlyReport: (dateText) => dispatch(loadMonthlyReport(dateText)),
    initLoadMonthlyReportActionStatus: () => dispatch(initLoadMonthlyReportActionStatus()),

    // Send PA Dealing Apply Monthly Report Email
    sendPADealingApplyMonthlyReportEmail: (dateText) => dispatch(sendPADealingApplyMonthlyReportEmail(dateText)),
    initSendPADealingApplyMonthlyReportEmailActionStatus: () => dispatch(initSendPADealingApplyMonthlyReportEmailActionStatus()),

    // 30天持仓相关预检查
    reset30DayHoldingCheckInfo: () => dispatch(reset30DayHoldingCheckInfo()),
    query30DayHoldingCheckInfo: (personalTradeInfo) => dispatch(query30DayHoldingCheckInfo(personalTradeInfo)),
    startTrigger30DayHoldingCheckInfoStatus: () => dispatch(startTrigger30DayHoldingCheckInfoStatus()),
    stopTrigger30DayHoldingCheckInfoStatus: () => dispatch(stopTrigger30DayHoldingCheckInfoStatus()),

    queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalTradeDashboard);