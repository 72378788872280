import { combineReducers } from 'redux';
import homeReducer from '../../views/features/home/homeReducer';

// Approval Request
import bannedTradeRequestReducer from '../../views/features/approvalRequest/bannedTradeRequest/bannedTradeRequestReducer';
import giftHospitalityRequestReducer from '../../views/features/approvalRequest/giftHospitalityRequest/giftHospitalityRequestReducer';
import AccountHoldingReducer from '../../views/features/approvalRequest/personalAccount/AccountHoldingReducer';
import personalTradeReducer from '../../views/features/approvalRequest/personalTrade/personalTradeReducer';

// Account Holding Report
// import accountHoldingReportReducer from '../../views/features/accountHoldingReport/accountHoldingReportReducer';

import authReducer from '../../views/features/auth/authReducer';

// Questionnaire
import questionnaireReducer from '../../views/features/questionnaire/questionnaireReducer';


// LicensingForm
import licensingFormReducer from '../../views/features/licens/licensingForm/licensingFormReducer';


// Compliance Rule
import pbShortInterestRateReducer from '../../views/features/complianceRule/pbShortInterestRate/pbShortInterestRateReducer';
import restrictedAssetReducer from '../../views/features/complianceRule/restrictedAsset/restrictedAssetReducer';
import restrictedCountryReducer from '../../views/features/complianceRule/restrictedCountry/restrictedCountryReducer';
import restrictedSectorReducer from '../../views/features/complianceRule/restrictedSector/restrictedSectorReducer';
import restrictedSecurityReducer from '../../views/features/complianceRule/restrictedSecurity/restrictedSecurityReducer';
import sfcShortReducer from '../../views/features/complianceRule/sfcShort/sfcShortReducer';
import restrictedRuleReducer from '../../views/features/complianceRule/restrictedRule/restrictedRuleReducer';
import restrictedSecurityRequestReducer from '../../views/features/complianceRule/restrictedSecurityRequest/restrictedSecurityRequestReducer';

// Entity & Account
import fundReducer from '../../views/features/entityAcount/fund/fundReducer';
import bookReducer from '../../views/features/entityAcount/book/bookReducer';
import counterPartyReducer from '../../views/features/entityAcount/counterParty/counterPartyReducer';
import custodianReducer from '../../views/features/entityAcount/custodian/custodianReducer';
import userAccessReducer from '../../views/features/entityAcount/userAccess/UserAccessReducer';
import ratingManagementReducer from '../../views/features/entityAcount/ratingManagement/RatingManagementReducer';
// Document & Report
import shortStockReducer from '../../views/features/documentReport/shortStock/shortStockReducer';
import thirteenFReportReducer from '../../views/features/documentReport/thirteenFReport/thirteenFReportReducer';
import exchangeRateReducer from '../../views/features/documentReport/exchangeRate/exchangeRateReducer';
import shortPositionReportReducer from '../../views/features/documentReport/shortPositionReport/shortPositionReportReducer';
import fundLevelReportReducer from '../../views/features/documentReport/fundLevelReport/fundLevelReportReducer';
import thirteenFLongPosReportReducer from '../../views/features/documentReport/thirteenFLongPosReport/thirteenFLongPosReportReducer';

// Fund Monitoring
import tradesReviewReducer from '../../views/features/fundMonitoring/tradesReview/tradesReviewReducer';
import portfolioMonitoringRuleReducer from '../../views/features/departments/portfolio-monitoring-rule/PortfolioMonitoringRuleReducer';
// Licensing
import LicensingReducer from '../../views/features/licensing/LicensingReducer';

// Departments
import ExpenseSummaryReducer from '../../views/features/departments/expense-summary/ExpenseSummaryReducer';
import ExpenseSummaryNewReducer from '../../views/features/departments/expense-summary-new/ExpenseSummaryNewReducer';
import ReportingSummaryRecuder from '../../views/features/departments/reporting-summary/ReportingSummaryReducer';
import WorkManagementReducer from '../../views/features/departments/work-management/WorkManagementReducer';
// common
import OperationRecordReducer from '../../views/features/common/operationRcd/OperationRecordReducer';
import HistoryUploadReducer from '../../views/features/common/historyUpload/HistoryUploadReducer';

// PendingTasks
import PendingTasksReducer from '../../views/features/pendingTasks/PendingTasksReducer';

// Agreement
import GeneralAgreementReducer from '../../views/features/agreement/general-agreement/GeneralAgreementReducer';


import meetingAndTrainingReducer from '../../views/features/pinpointCollege/meetingAndTraining/MeetingAndTrainingReducer';

import meetingAndTrainingReviewReducer from '../../views/features/pinpointCollege/meetingAndTrainingReview/MeetingAndTrainingReviewReducer';
import meetingAndTrainingGroupReducer from '../../views/features/pinpointCollege/meetingAndTrainingGroup/MeetingAndTrainingGroupReducer';
import meetingAndTrainingApplyRecordReducer from '../../views/features/pinpointCollege/meetingAndTrainingApplyRecord/MeetingAndTrainingApplyRecordReducer';
import rotationReviewReducer from '../../views/features/pinpointCollege/rotationReview/RotationReviewReducer';
import brokerReviewReducer from '../../views/features/pinpointCollege/brokerReview/BrokerReviewReducer';
import meetingAndTrainingCalenderReducer from '../../views/features/pinpointCollege/meetingAndTrainingCalender/MeetingAndTrainingCalenderReducer';



// Pinpoint Group
import GroupStructureReducer from '../../views/features/pinpoint-group/group-structure/GroupStructureReducer';
import ElementTypeMngReducer from '../../views/features/pinpoint-group/element-type-mng/ElementTypeMngReducer';
import ElementMngReducer from '../../views/features/pinpoint-group/element-mng/ElementMngReducer';
import MonthlyReportReducer from '../../views/features/departments/monthly-report/MonthlyReportReducer';
import PresonalInfoReducer from '../../views/features/licens/presonalInfo/PresonalInfoReducer';
import RegisterReducer from '../../views/features/licens/register/RegisterReducer';

import CptReducer from '../../views/features/licens/cpt/CptReducer';


const rootReducer = combineReducers({
  home: homeReducer,

  // Approval Request
  bannedTradeRequest: bannedTradeRequestReducer,
  giftHospitalityRequest: giftHospitalityRequestReducer,
  personalAccount: AccountHoldingReducer,
  personalTrade: personalTradeReducer,

  // Account Holding Report
  // accountHoldingReport: accountHoldingReportReducer,

  auth: authReducer,

  // Compliance Rule
  pbShortInterestRate: pbShortInterestRateReducer,
  restrictedAsset: restrictedAssetReducer,
  restrictedCountry: restrictedCountryReducer,
  restrictedSector: restrictedSectorReducer,

  restrictedSecurity: restrictedSecurityReducer,
  restrictedSecurityRequest: restrictedSecurityRequestReducer,
  
  sfcShort: sfcShortReducer,
  restrictedRule: restrictedRuleReducer,

  // Questionnaire
  questionnaire: questionnaireReducer,


  // LicensingForm
  licensingForm: licensingFormReducer,

  // Entity & Account
  fund: fundReducer,
  book: bookReducer,
  counterParty: counterPartyReducer,
  custodian: custodianReducer,
  userAccess: userAccessReducer,
  ratingManagement: ratingManagementReducer,

  // Document & Report
  shortStock: shortStockReducer,
  thirteenFReport: thirteenFReportReducer,
  exchangeRate: exchangeRateReducer,
  shortPositionReport: shortPositionReportReducer,
  fundLevelReport: fundLevelReportReducer,
  thirteenFLongPosReport: thirteenFLongPosReportReducer,

  // Fund Monitoring
  tradesReview: tradesReviewReducer,
  portfolioMonitoringRule: portfolioMonitoringRuleReducer,


  // licensing
  licensing: LicensingReducer,

  // Departments
  expenseSummary: ExpenseSummaryReducer,
  expenseSummaryNew: ExpenseSummaryNewReducer,
  reportingSummary: ReportingSummaryRecuder,
  workManagement: WorkManagementReducer,

  // common
  operationRecord: OperationRecordReducer,
  historyUpload: HistoryUploadReducer,

  // PendingTasks
  pendingTasks: PendingTasksReducer,

  // Agreement
  generalAgreement: GeneralAgreementReducer,

  meetingAndTraining: meetingAndTrainingReducer,

  meetingAndTrainingReview: meetingAndTrainingReviewReducer,
  meetingAndTrainingGroup: meetingAndTrainingGroupReducer,
  rotationReview: rotationReviewReducer,
  meetingAndTrainingCalender: meetingAndTrainingCalenderReducer,

  // Pinpoint Group
  groupStructure: GroupStructureReducer,
  elementTypeMng: ElementTypeMngReducer,
  elementMng: ElementMngReducer,
  
  monthlyReport: MonthlyReportReducer,

  presonalInfo: PresonalInfoReducer,

  register: RegisterReducer,
  cpt: CptReducer,
  meetingAndTrainingApplyRecord: meetingAndTrainingApplyRecordReducer,





  brokerReview: brokerReviewReducer,


});

export default rootReducer;