import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input , Tabs} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './MeetingAndTrainingReviewDashboard.css';
import MeetingAndTrainingReviewGrid from './MeetingAndTrainingReviewGrid';
import AddOrEditMeetingAndTrainingReviewModalDialog from './AddOrEditMeetingAndTrainingReviewModalDialog';
import DelMeetingAndTrainingReviewModalDialog from './DelMeetingAndTrainingReviewModalDialog';
import CopMeetingAndTrainingReviewModalDialog from './CopMeetingAndTrainingReviewModalDialog';
import OperationRecordDialog from './OperationRecordDialog';
import RestrictedSecurityRuleGrid from './restrictedRule/RestrictedSecurityRuleGrid';
import FinishTaskModalDialog from './FinishTaskModalDialog';
const TabPane = Tabs.TabPane;
class MeetingAndTrainingReviewDashboard extends Component {
    componentDidMount() {
        const {
            cpty,
            functiontype,
            fundandcompany,
            userName,
            status
        } = this.props.meetingAndTrainingReviewQueryFields;
         this.props.loadMeetingAndTrainingReviewInfo({cpty: undefined,functiontype: undefined,fundandcompany: undefined,userName: undefined, status: 'Active'})

    }


    componentDidUpdate() {
        const {
            // query
            loadMeetingAndTrainingReviewInfoActionStatus,
            loadMeetingAndTrainingReviewInfoResp,
        } = this.props;

        if(loadMeetingAndTrainingReviewInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadMeetingAndTrainingReviewInfoActionStatus();
        }
        if(loadMeetingAndTrainingReviewInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load User Access Info failed';
            if(!!loadMeetingAndTrainingReviewInfoResp && !!loadMeetingAndTrainingReviewInfoResp.respMessage) {
                err = loadMeetingAndTrainingReviewInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadMeetingAndTrainingReviewInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditMeetingAndTrainingReviewModal(mode, null);
        } else {
            const {
                selectedMeetingAndTrainingReviewInfoArray,
            } = this.props;
            if(selectedMeetingAndTrainingReviewInfoArray.length !== 1) {
                message.warning("Please choose one and only one User Access Info");
                return;
            }
            const currentMeetingAndTrainingReviewInfo = selectedMeetingAndTrainingReviewInfoArray[0];
            this.props.openAddOrEditMeetingAndTrainingReviewModal(mode, currentMeetingAndTrainingReviewInfo);
        }
    }
    copyAllBtnOnClicked = () => {
        const {
            selectedMeetingAndTrainingReviewInfoArray,
        } = this.props;
        if(selectedMeetingAndTrainingReviewInfoArray.length !== 1) {
            message.warning("Please choose one and only one User Access Info");
            return;
        }
        const currentMeetingAndTrainingReviewInfo = selectedMeetingAndTrainingReviewInfoArray[0];
        this.props.openCopMeetingAndTrainingReviewModal(currentMeetingAndTrainingReviewInfo);
    }

    delBtnOnClicked = (mode) => {
        const {
            selectedMeetingAndTrainingReviewInfoArray,
        } = this.props;
        if(selectedMeetingAndTrainingReviewInfoArray.length !== 1) {
            message.warning("Please choose one and only one Info");
            return;
        }
        const currentMeetingAndTrainingReviewInfo = selectedMeetingAndTrainingReviewInfoArray[0];
        this.props.openDelMeetingAndTrainingReviewModal(currentMeetingAndTrainingReviewInfo,mode);
    }


    refreshBtnOnClicked = () => {
        const {
            title,
            type,
            startTime,
            entTime,
            speaker
            
            
        } = this.props.meetingAndTrainingReviewQueryFields;
        // const person = participant;
        this.props.loadMeetingAndTrainingReviewInfo({title,type,startTime,entTime,speaker})
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {

        const {
            typeOptions,
            userAccountOptions,
            meetingAndTrainingReviewQueryFields,
            loadMeetingAndTrainingReviewInfoActionStatus,
        } = this.props;

        const {
            userName,
            status,
        } = meetingAndTrainingReviewQueryFields;


        const refreshBtnIsLoading = loadMeetingAndTrainingReviewInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='MeetingAndTrainingReviewWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Training Review</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <Tabs defaultActiveKey="1" tabPosition={'top'}>
                <TabPane  tab="Training Session" key="1">
                <div className='OperationBar'>
                    <div className='breadcrumbWrapper'>   
                    <Breadcrumb>
                    <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                    </Breadcrumb>
                    </div>


                    <Input 
                        allowClear={true}
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="Title"
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'title', value: event.target.value })
                        }} 
                    />
                    {/* <Input 
                        placeholder="Function"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'functiontype', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="Fund/Co."
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'fundandcompany', value: event.target.value })
                        }} 
                    /> */}
                    
                    {/* <Select
                        allowClear
                        showSearch
                        size='small'
                        // --value={!user ? undefined : user}
                        style={{marginLeft: 10, width: 150}}
                        //onChange={(value) => this.onInputChange({name: 'userName', value: value})}
                        onChange={(val) => {
                            this.onInputChange({ name: 'userName', value: val })
                        }} 
                        placeholder="Employee">
                        {userAccountOptions}
                    </Select> */}
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'type', value: val })
                        }} 
                        placeholder="type">
                        {typeOptions}
                    </Select>
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder=" Date Start"
                      onChange={(startTime, dateString) => {
                        this.onInputChange({ name: 'startTime', value: dateString });
                      }}
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Date End"
                      onChange={(entTime, dateString) => {
                        this.onInputChange({ name: 'entTime', value: dateString });
                      }}
                    /> 
                    <Input 
                        allowClear={true}
                        placeholder="Speaker"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'speaker', value: event.target.value })
                        }} 
                    />
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('ADD')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.copyAllBtnOnClicked()} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy All
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>*/}
                    <Button 
                        onClick={()=>this.delBtnOnClicked('inactive')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Reset
                    </Button> 
                    {/* <Button 
                        onClick={()=>this.delBtnOnClicked('del')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Feedback
                    </Button>
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'MeetingAndTrainingReview'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button> */}
                </div>

                <div className='MeetingAndTrainingReviewGrid'><MeetingAndTrainingReviewGrid {...this.props} /></div>
                {/* <AddOrEditMeetingAndTrainingReviewModalDialog {...this.props} />
                
                <CopMeetingAndTrainingReviewModalDialog {...this.props} /> */}

                <DelMeetingAndTrainingReviewModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                </TabPane>
                <TabPane tab="Individual" key="2">
                <RestrictedSecurityRuleGrid {...this.props}/> 
                </TabPane>
          
                </Tabs>


            </div>
            
        );
    }
}

export default MeetingAndTrainingReviewDashboard;
