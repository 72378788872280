import moment from 'moment';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from '../GridColumnMap';

import {
    // search change input 
    TRADES_REVIEW_SEARCH_CHANGE_INPUT,

    // do query
    START_QUERY_TRADES_REVIEW,
    QUERY_TRADES_REVIEW_SUCCESS,
    QUERY_TRADES_REVIEW_FAILURE,
    INIT_QUERY_TRADES_REVIEW_ACTION_STATUS,

    // query report
    START_QUERY_TRADES_REVIEW_REPORT,
    QUERY_TRADES_REVIEW_REPORT_SUCCESS,
    QUERY_TRADES_REVIEW_REPORT_FAILURE,
    INIT_QUERY_TRADES_REVIEW_REPORT_ACTION_STATUS,

    // add grid to container
    ADD_TRADES_REVIEW_GRID_TO_PROPS,
    ADD_TRADES_REVIEW_REPORT_GRID_TO_PROPS,


} from './tradesReviewConstants';


// 上一日期，跳过周末
let initSearchDate = moment().subtract(1, 'days');
while(initSearchDate.day()===0 || initSearchDate.day()===6) {
    initSearchDate = initSearchDate.subtract(1, 'days');
}


const initialState = {
    tradesReviewSearchFields: {
        tradeDateStart: initSearchDate,
        tradeDateEnd: initSearchDate,
        ticker: '',
    },
    tradesReviewSearchResultGridType: 1,
    tradesReviewGridColumns: GridColumnMap.tradesReviewGridColumns,
    tradesReviewReportGridColumns: GridColumnMap.tradesReviewReportGridColumns,

}


// search change input
function tradesReviewSearchChangeInput(state, { name, value }) {
    const updatedFields = {
        ...state.tradesReviewSearchFields,
        [name]: value
      };
       return {
        ...state,
        tradesReviewSearchFields: updatedFields,
      };
}


// do query
function startQueryTradesReview(state) {
    return {
        ...state,
        queryTradesReviewActionStatus: ACTION_STATUS.LOGINING,
        tradesReviewSearchResultGridType: 1,
    };
}
function queryTradesReviewSuccess(state, payload) {
    return {
        ...state,
        queryTradesReviewActionStatus: ACTION_STATUS.SUCCESS,
        queryTradesReviewActionResp: payload,
    };
}
function queryTradesReviewFailure(state, err) {
    return {
        ...state,
        queryTradesReviewActionStatus: ACTION_STATUS.ERROR,
        queryTradesReviewActionResp: err,
    };
}
function initQueryTradesReviewActionStatus(state) {
    return {
        ...state,
        queryTradesReviewActionStatus: ACTION_STATUS.READY,
    };
}


// query report
function startQueryTradesReviewReport(state) {
    return {
        ...state,
        queryTradesReviewReportActionStatus: ACTION_STATUS.LOGINING,
        tradesReviewSearchResultGridType: 2,
    };
}
function queryTradesReviewReportSuccess(state, payload) {
    return {
        ...state,
        queryTradesReviewReportActionStatus: ACTION_STATUS.SUCCESS,
        queryTradesReviewReportActionResp: payload,
    };
}
function queryTradesReviewReportFailure(state, err) {
    return {
        ...state,
        queryTradesReviewReportActionStatus: ACTION_STATUS.ERROR,
        queryTradesReviewActionResp: err,
    };
}
function initQueryTradesReviewReportActionStatus(state) {
    return {
        ...state,
        queryTradesReviewReportActionStatus: ACTION_STATUS.READY,
    };
}


// add grid to container
function addTradesReviewGridToProps(state, grid) {
    return {
        ...state,
        tradesReviewGrid: grid,
    };
}
function addTradesReviewReportGridToProps(state, grid) {
    return {
        ...state,
        tradesReviewReportGrid: grid,
    };
}


export default createReducer(initialState, {
    // search change input 
    [TRADES_REVIEW_SEARCH_CHANGE_INPUT]: tradesReviewSearchChangeInput,

    // do query
    [START_QUERY_TRADES_REVIEW]: startQueryTradesReview,
    [QUERY_TRADES_REVIEW_SUCCESS]: queryTradesReviewSuccess,
    [QUERY_TRADES_REVIEW_FAILURE]: queryTradesReviewFailure,
    [INIT_QUERY_TRADES_REVIEW_ACTION_STATUS]: initQueryTradesReviewActionStatus,

    // query report
    [START_QUERY_TRADES_REVIEW_REPORT]: startQueryTradesReviewReport,
    [QUERY_TRADES_REVIEW_REPORT_SUCCESS]: queryTradesReviewReportSuccess,
    [QUERY_TRADES_REVIEW_REPORT_FAILURE]: queryTradesReviewReportFailure,
    [INIT_QUERY_TRADES_REVIEW_REPORT_ACTION_STATUS]: initQueryTradesReviewReportActionStatus,

    // add grid to container
    [ADD_TRADES_REVIEW_GRID_TO_PROPS]: addTradesReviewGridToProps,
    [ADD_TRADES_REVIEW_REPORT_GRID_TO_PROPS]: addTradesReviewReportGridToProps,

});

