// Generate Short Position Report
export const START_GENERATE_SHORT_POSITION_REPORT = 'START_GENERATE_SHORT_POSITION_REPORT';
export const GENERATE_SHORT_POSITION_REPORT_SUCCESS = 'GENERATE_SHORT_POSITION_REPORT_SUCCESS';
export const GENERATE_SHORT_POSITION_REPORT_FAILURE = 'GENERATE_SHORT_POSITION_REPORT_FAILURE';
export const INIT_GENERATE_SHORT_POSITION_REPORT_ACTION_STATUS = 'INIT_GENERATE_SHORT_POSITION_REPORT_ACTION_STATUS';

// Load Short Position Report
export const START_LOAD_SHORT_POSITION_REPORT = 'START_LOAD_SHORT_POSITION_REPORT';
export const LOAD_SHORT_POSITION_REPORT_SUCCESS = 'LOAD_SHORT_POSITION_REPORT_SUCCESS';
export const LOAD_SHORT_POSITION_REPORT_FAILURE = 'LOAD_SHORT_POSITION_REPORT_FAILURE';
export const INIT_LOAD_SHORT_POSITION_REPORT_ACTION_STATUS = 'INIT_LOAD_SHORT_POSITION_REPORT_ACTION_STATUS';

// Open/Reset Short Position Report Check Result Modal Dialog
export const SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN = 'SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN';
export const SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET = 'SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET';

// Load Short Position Report Check Result
export const START_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT = 'START_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT';
export const LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_SUCCESS = 'LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_SUCCESS';
export const LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_FAILURE = 'LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_FAILURE';
export const INIT_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_ACTION_STATUS = 'INIT_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_ACTION_STATUS';

// Report Short Position Report
export const START_REPORT_SHORT_POSITION_REPORT = 'START_REPORT_SHORT_POSITION_REPORT';
export const REPORT_SHORT_POSITION_REPORT_SUCCESS = 'REPORT_SHORT_POSITION_REPORT_SUCCESS';
export const REPORT_SHORT_POSITION_REPORT_FAILURE = 'REPORT_SHORT_POSITION_REPORT_FAILURE';
export const INIT_REPORT_SHORT_POSITION_REPORT_ACTION_STATUS = 'INIT_REPORT_SHORT_POSITION_REPORT_ACTION_STATUS';