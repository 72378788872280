import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

import {
  // queryPendingTasksNum
  QUERY_PENDING_TASKS_NUM_SUCCESS,
  QUERY_PENDING_TASKS_NUM_FAILURE,
  // queryPendingTasksList
  QUERY_PENDING_TASKS_INPUT_CHANGE,
  START_QUERY_PENDING_TASKS_LIST,
  QUERY_PENDING_TASKS_LIST_SUCCESS,
  QUERY_PENDING_TASKS_LIST_FAILURE,
  INIT_QUERY_PENDING_TASKS_LIST_STATUS,
  // ignoreDeignorePendingTasks
  IGNORE_DEIGNORE_PENDING_TASKS_SUCCESS,
  IGNORE_DEIGNORE_PENDING_TASKS_ERROR,
  INIT_IGNORE_DEIGNORE_PENDING_TASKS_STATUS,
  

} from './PendingTasksConstants';



// queryPendingTasksNum
export function queryPendingTasksNum() {
    const params = {status: 'Pending'};
    return function(dispatch) {
      httpClient
        .get(UrlConfig.QUERY_PENDING_TASKS_NUM, params)
        .then(resp => dispatch(queryPendingTasksNumSuccess(resp)))
        .catch(err => dispatch(queryPendingTasksNumFailure(err)));
    };
}
function queryPendingTasksNumSuccess(resp) {
    return {
      type: QUERY_PENDING_TASKS_NUM_SUCCESS,
      payload: resp.data,
    }
}
function queryPendingTasksNumFailure(err) {
    return {
      type: QUERY_PENDING_TASKS_NUM_FAILURE,
      payload: err,
    }
}

// queryPendingTasksList
export function queryPendingTasksInputChange(params) {
    return {
        type: QUERY_PENDING_TASKS_INPUT_CHANGE,
        payload: params,
    }
}
export function queryPendingTasksList(params) {
    return function(dispatch) {
        dispatch(startQueryPendingTasksList());
        httpClient
          .get(UrlConfig.QUERY_PENDING_TASKS_LIST, params)
          .then(resp => dispatch(queryPendingTasksListSuccess(resp)))
          .catch(err => dispatch(queryPendingTasksListFailure(err)));
    };
}
function startQueryPendingTasksList() {
    return {
        type: START_QUERY_PENDING_TASKS_LIST,
    };
}
function queryPendingTasksListSuccess(resp) {
    return {
        type: QUERY_PENDING_TASKS_LIST_SUCCESS,
        payload: resp,
    };
}
function queryPendingTasksListFailure(err) {
    return {
        type: QUERY_PENDING_TASKS_LIST_FAILURE,
        payload: err,
    }
}
export function initQueryPendingTasksListStatus() {
    return {
        type: INIT_QUERY_PENDING_TASKS_LIST_STATUS,
    };
}


// ignoreDeignorePendingTasks
export function ignoreDeignorePendingTasks(params) {
    return function(dispatch) {
        httpClient
          .post(UrlConfig.IGNORE_DEIGNORE_PENDING_TASKS, params)
          .then(resp => dispatch(ignoreDeignorePendingTasksSuccess(resp)))
          .catch(err => dispatch(ignoreDeignorePendingTasksFailure(err)));
    };
}
function ignoreDeignorePendingTasksSuccess(resp) {
    return {
        type: IGNORE_DEIGNORE_PENDING_TASKS_SUCCESS,
    };
}
function ignoreDeignorePendingTasksFailure(err) {
    return {
        type: IGNORE_DEIGNORE_PENDING_TASKS_ERROR,
        payload: err,
    }; 
}
export function initIignoreDeignorePendingTasksStatus() {
    return {
        type: INIT_IGNORE_DEIGNORE_PENDING_TASKS_STATUS,
    }
}

