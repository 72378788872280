import {
  // Load Restricted Countries
  START_LOAD_RESTRICTED_COUNTRIES,
  LOAD_RESTRICTED_COUNTRIES_SUCCESS,
  LOAD_RESTRICTED_COUNTRIES_FAILURE,
  INIT_LOAD_RESTRICTED_COUNTRIES_ACTION_STATUS,

  // Create Restricted Country
  START_CREATE_RESTRICTED_COUNTRY,
  CREATE_RESTRICTED_COUNTRY_SUCCESS,
  CREATE_RESTRICTED_COUNTRY_FAILURE,
  INIT_CREATE_RESTRICTED_COUNTRY_ACTION_STATUS,

  // Update Restricted Country
  START_UPDATE_RESTRICTED_COUNTRY,
  UPDATE_RESTRICTED_COUNTRY_SUCCESS,
  UPDATE_RESTRICTED_COUNTRY_FAILURE,
  INIT_UPDATE_RESTRICTED_COUNTRY_ACTION_STATUS,

  // Batch Delete Restricted Country
  START_BATCH_DELETE_RESTRICTED_COUNTRY,
  BATCH_DELETE_RESTRICTED_COUNTRY_SUCCESS,
  BATCH_DELETE_RESTRICTED_COUNTRY_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_COUNTRY_ACTION_STATUS,

  // Open/Reset Restricted Country Modal Dialog
  RESTRICTED_COUNTRY_MODAL_DIALOG_OPEN,
  RESTRICTED_COUNTRY_MODAL_DIALOG_RESET,

  // Restricted Country Edit Change Input
  RESTRICTED_COUNTRY_EDIT_CHANGE_INPUT,

  // RadioGroup Change
  RADIO_GROUP_CHANGE,

  // Country Code Select Changed
  COUNTRY_CODE_SELECT_CHANGED,

  // Select Restricted Country
  SELECT_RESTRICTED_COUNTRY, 

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,
} from './restrictedCountryConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// ========================================================================
// Load Restricted Countries
function startLoadRestrictedCountries() {
  return {
    type: START_LOAD_RESTRICTED_COUNTRIES
  };
}

export function loadRestrictedCountries() {
  return function(dispatch) {
    dispatch(startLoadRestrictedCountries());
    httpClient
      .get(UrlConfig.QUERY_RESTRICTED_COUNTRIES)
      .then(resp => dispatch(loadRestrictedCountriesSuccess(resp)))
      .catch(err => dispatch(loadRestrictedCountriesFailure(err)));
  };
}

function loadRestrictedCountriesSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_COUNTRIES_SUCCESS,
    payload: resp
  };
}

function loadRestrictedCountriesFailure(err) {
  return {
    type: LOAD_RESTRICTED_COUNTRIES_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedCountriesActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_COUNTRIES_ACTION_STATUS
  };
}

// Create Restricted Country
function startCreateRestrictedCountry() {
  return {
    type: START_CREATE_RESTRICTED_COUNTRY
  };
}

export function createRestrictedCountry(restrictedCountry) {
  return function(dispatch) {
    dispatch(startCreateRestrictedCountry());
    httpClient
      .post(UrlConfig.NEW_RESTRICTED_COUNTRY, restrictedCountry)
      .then(resp => dispatch(createRestrictedCountrySuccess(resp)))
      .catch(err => dispatch(createRestrictedCountryFailure(err)));
  };
}

function createRestrictedCountrySuccess(resp) {
  return {
    type: CREATE_RESTRICTED_COUNTRY_SUCCESS,
    payload: resp
  };
}

function createRestrictedCountryFailure(err) {
  return {
    type: CREATE_RESTRICTED_COUNTRY_FAILURE,
    payload: err
  };
}

export function initCreateRestrictedCountryActionStatus() {
  return {
    type: INIT_CREATE_RESTRICTED_COUNTRY_ACTION_STATUS
  };
}

// Update Restricted Country
function startUpdateRestrictedCountry() {
  return {
    type: START_UPDATE_RESTRICTED_COUNTRY
  };
}

export function updateRestrictedCountry(restrictedCountry) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedCountry());
    httpClient
      .post(UrlConfig.UPDATED_RESTRICTED_COUNTRY, restrictedCountry)
      .then(resp => dispatch(updateRestrictedCountrySuccess(resp)))
      .catch(err => dispatch(updateRestrictedCountryFailure(err)));
  };
}

function updateRestrictedCountrySuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_COUNTRY_SUCCESS,
    payload: resp
  };
}

function updateRestrictedCountryFailure(err) {
  return {
    type: UPDATE_RESTRICTED_COUNTRY_FAILURE,
    payload: err
  };
}

export function initUpdateRestrictedCountryActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_COUNTRY_ACTION_STATUS
  };
}

// Batch Delete Restricted Country
function startBatchDeleteRestrictedCountry() {
  return {
    type: START_BATCH_DELETE_RESTRICTED_COUNTRY
  };
}

export function batchDeleteRestrictedCountry(restrictedCountryIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteRestrictedCountry());
    httpClient
      .post(UrlConfig.BATCH_DELETED_RESTRICTED_COUNTRY, restrictedCountryIdList)
      .then(resp => dispatch(batchDeleteRestrictedCountrySuccess(resp)))
      .catch(err => dispatch(batchDeleteRestrictedCountryFailure(err)));
  };
}

function batchDeleteRestrictedCountrySuccess(resp) {
  return {
    type: BATCH_DELETE_RESTRICTED_COUNTRY_SUCCESS,
    payload: resp
  };
}

function batchDeleteRestrictedCountryFailure(err) {
  return {
    type: BATCH_DELETE_RESTRICTED_COUNTRY_FAILURE,
    payload: err
  };
}

export function initBatchDeleteRestrictedCountryActionStatus() {
  return {
    type: INIT_BATCH_DELETE_RESTRICTED_COUNTRY_ACTION_STATUS
  };
}


// ========================================================================
// RadioGroup Change
export function radioGroupChange(value) {
  return {
    type: RADIO_GROUP_CHANGE,
    payload: value,
  };
}


// Open/Reset Restricted Country Modal Dialog
export function openRestrictedCountryEditModalDialog(isAddMode, restrictedCountry) {
  return {
    type: RESTRICTED_COUNTRY_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      restrictedCountry,
    },
  };
}

export function resetRestrictedCountryEditModalDialog() {
  return {
    type: RESTRICTED_COUNTRY_MODAL_DIALOG_RESET
  };
}

// Restricted Country Edit Change Input
export function restrictedCountryEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_COUNTRY_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// ========================================================================
// Country Code Select Changed
export function countryCodeSelectChanged(countryCode) {
  return {
    type: COUNTRY_CODE_SELECT_CHANGED,
    payload: countryCode
  };
}

// Select Restricted Country
export function selectRestrictedCountry(payload) {
  return {
    type: SELECT_RESTRICTED_COUNTRY,
    payload
  };
}

// Fund Code Select Changed
export function fundCodeSelectChanged(fundCode) {
  return {
    type: FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Book Code Select Changed
export function bookCodeSelectChanged(bookCode) {
  return {
    type: BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}