import React, {Component} from 'react';
import { Breadcrumb, Select, Button, message, Divider,Options } from 'antd';
import moment from 'moment';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import AddOrEditLicenseModalDialog from './AddOrEditLicenseModalDialog';
import DelLicenseModalDialog from './DelLicenseModalDialog';
import EditCptRcdModalDialog from './EditCptRcdModalDialog';
import OperationRcdModalDialog from './OperationRcdModalDialog';
import './LicensingDashboard.css';
import LicensingGrid from './LicensingGrid';
const Option = Select.Option;
const regiStatusOptions = [];
regiStatusOptions.push(<Option key={'Registered'}>{'Registered'}</Option>);
regiStatusOptions.push(<Option key={'Unregistered'}>{'Unregistered'}</Option>);
regiStatusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);
regiStatusOptions.push(<Option key={'Deregistered'}>{'Deregistered'}</Option>);
class LicensingDashboard extends Component {

    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let employee = null;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'employee') {
                        employee = subParamArray[1];
                        employee = employee.replace(/%20/g,' ');
                    }
                }
            }
        }
        this.props.loadLicenseBasicInfoOptions();
        this.onInputChange({name: 'employee', value: employee});
        this.props.loadLicensingInfo({employee,status:undefined ,source:'Admin'});
    }


    componentDidUpdate() {
        const {
            // loadLicensingInfo
            loadLicensingInfoActionStatus,
            loadLicensingInfoResp,
            // loadLicenseBasicInfoOptionsStatus
            loadLicenseBasicInfoOptionsStatus,

        } = this.props;
        
        if(loadLicensingInfoActionStatus == ACTION_STATUS.SUCCESS) {
            this.props.initLoadLicensingInfoActionStatus();
        }
        if(loadLicensingInfoActionStatus == ACTION_STATUS.ERROR) {
            let err = 'Load licensing info failed';
            if(!!loadLicensingInfoResp) {
                err = loadLicensingInfoResp;
            }
            message.error(err);
            this.props.initLoadLicensingInfoActionStatus();
        }

        if(loadLicenseBasicInfoOptionsStatus == ACTION_STATUS.SUCCESS) {
            this.props.initLoadLicenseBasicInfoOptionsActionStatus();
        }
        if(loadLicenseBasicInfoOptionsStatus == ACTION_STATUS.ERROR) {
            message.error('load license basic info failed');
            this.props.initLoadLicenseBasicInfoOptionsActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onLicensingQueryInputChange({ name, value });
    };


    refreshBtnOnClicked = () => {
        const {
            employee,
            status,
        } = this.props.licensingQuerySearchFields;
        this.props.loadLicensingInfo({employee,status, source:'Admin'});
    }


    addOrEditLicenseBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditLicenseModal(mode, null);
        } else {
            const {
                selectedLicenseArray,
            } = this.props;
            if(selectedLicenseArray.length !== 1) {
                message.warning("Please choose one and only one License record");
                return;
            }
            const currentLicense = selectedLicenseArray[0];
            this.props.openAddOrEditLicenseModal(mode, currentLicense);
        }
    }


    delLicenseBtnOnClicked = (mode) => {
        const {
            selectedLicenseArray,
        } = this.props;
        if(selectedLicenseArray.length === 0) {
            message.warning("Please choose at least one License record");
            return;
        }
        this.props.openDelLicenseModal(mode);
    }


    editCPTRcdBtnOnClicked = () => {
        const {
            selectedLicenseArray,
            selectedCptRcdDataArray,
            loadLicensingInfoResp,
        } = this.props;
        const lincenseDataLength = selectedLicenseArray.length;
        const cptRcdDataLength = selectedCptRcdDataArray.length;
        if(lincenseDataLength + cptRcdDataLength !== 1) {
            message.warning('Please choose one and only one record. You can choose one CPT Record to edit, or one License Record to edit its CPT Record data of current year.');
            return;
        }

        let selectedCptRcdData = null;
        let parentLicenseData = null;
        if(lincenseDataLength === 1) {
            const currYear = moment().format('YYYY');
            selectedCptRcdData = selectedLicenseArray[0].licenseCPTYearRcdList.filter(e => e.year+'' === currYear)[0];
            parentLicenseData = selectedLicenseArray[0];
        } else if(cptRcdDataLength === 1) {
            selectedCptRcdData = selectedCptRcdDataArray[0];
            parentLicenseData = loadLicensingInfoResp.filter(e => e.guid === selectedCptRcdData.licenseEmpRequireRcd.guid)[0];
        }

        if(!selectedCptRcdData) {
            message.warning('System Error!');
            return;
        }

        const parentLicenseEmployee = parentLicenseData.employee;
        const parentLicneseBasicInfo = parentLicenseData.licenseBasicInfo.country + ' / ' + parentLicenseData.licenseBasicInfo.regulator + ' / ' + parentLicenseData.licenseBasicInfo.license;

        this.props.openEditCptRcdModal(selectedCptRcdData, parentLicenseEmployee, parentLicneseBasicInfo);
    }


    operationRcdBtnOnClicked = () => {
        this.props.queryOperationRcd();
        this.props.openOperationRcdModal();
    }


    // lastTimeoutID = null;
    // fuzzySearchEmployee = (value) => {
    //     if(!value) {
    //         this.props.clearEmployeeNameList('personalTrade4Search');
    //     }
    //     if(value.length < 2) {
    //         return;  // 长度过短不查询
    //     }
    //     // 输入完毕等待0.5秒后再查询
    //     if(!!this.lastTimeoutID) {
    //         clearTimeout(this.lastTimeoutID);
    //     }
    //     this.lastTimeoutID = setTimeout(() => {
    //         this.props.fuzzySearchEmployee('personalTrade4Search', value);
    //     }, 500);
    // }


    render() {
        const {
            userAccountOptions,
            loadLicensingInfoActionStatus,
        } = this.props;
        const {
            employee,
            status,
        } = this.props.licensingQuerySearchFields;

        // loading
        const refreshBtnIsLoading = loadLicensingInfoActionStatus === ACTION_STATUS.LOGINING;
        

        return(
            <div className="LicensingWrapper">
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Monitoring</Breadcrumb.Item>
                        <Breadcrumb.Item>Licensing</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='LicensingOperationBar'>
                    <Select
                        allowClear
                        size='small'
                        showSearch
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="Employee" 
                        value={!employee ? undefined: employee}
                        onChange={(val) => {
                            this.onInputChange({ name: 'employee', value: val })
                        }} >
                        {userAccountOptions}
                    </Select>
                    <Select
                        allowClear
                        size='small'
                        showSearch
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="Status" 
                        value={!status ? undefined: status}
                        onChange={(val) => {
                            this.onInputChange({ name: 'status', value: val })
                        }} >
                        {regiStatusOptions}
                    </Select>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditLicenseBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add License
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditLicenseBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit License
                    </Button>
                    <Button 
                        onClick={()=>this.delLicenseBtnOnClicked('Del')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del License
                    </Button>
                    <Button 
                        onClick={()=>this.delLicenseBtnOnClicked('Deregister')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Deregister
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.editCPTRcdBtnOnClicked()} 
                        size='small'
                        type='primary'>
                        Edit CPT Record
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.operationRcdBtnOnClicked()} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button>


                </div>

                <div className='LicensingGrid'><LicensingGrid {...this.props} /></div>
                <AddOrEditLicenseModalDialog {...this.props} />
                <DelLicenseModalDialog {...this.props} />
                <EditCptRcdModalDialog {...this.props} />
                <OperationRcdModalDialog {...this.props} />
            </div>
        );
    }

}

export default LicensingDashboard;
