import {connect} from 'react-redux';
import QuestionnaireList from '../components/QuestionnaireList';

import {
  // query
  onQueryInputChange,
  loadQuestionnaires,
  initLoadQuestionnairesActionStatus,

  // Load Answer
  loadAnswers,
  initLoadAnswersActionStatus,

  // Open/Reset Answeree Select Modal Dialog
  openAnswereeSelectModalDialog,
  resetAnswereeSelectModalDialog,

  // Select All Answeree
  selectAllAnsweree,

  // Select Invert Answeree
  selectInvertAnsweree,

  // Answeree Check Changed
  answereeCheckChanged,

  // Select Answeree
  selectAnswerees,
  initSelectAnswereesActionStatus,

  // Select Questionnaires
  selectQuestionnaires,
    
  // Select Questions
  selectQuestions,

  // ================================================================================
  // Open/Reset Questionnaire Edit Modal Dialog
  openQuestionnaireEditModalDialog,
  resetQuestionnaireEditModalDialog,

  // Create Questionnaire
  createQuestionnaire,
  initCreateQuestionnaireActionStatus,

  // Update Questionnaire
  updateQuestionnaire,
  initUpdateQuestionnaireActionStatus,

  // Copy Questionnaire
  copyQuestionnaire,
  initCopyQuestionnaireActionStatus,

  // Batch Delete Questionnaire
  batchDeleteQuestionnaire,
  initBatchDeleteQuestionnaireActionStatus,

  // Questionnaire Edit Change Input
  questionnaireEditChangeInput,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  openQuestionEditModalDialog,
  resetQuestionEditModalDialog,

  // Create Question
  createQuestion,
  initCreateQuestionActionStatus,

  // Update Question
  updateQuestion,
  initUpdateQuestionActionStatus,

  // Batch Delete Question
  batchDeleteQuestion,
  initBatchDeleteQuestionActionStatus,

  // Question Edit Change Input
  questionEditChangeInput,

  // Questionnaire Option Change
  questionnaireOptionChange,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  openQuestionnaireEmailEditModalDialog,
  resetQuestionnaireEmailEditModalDialog,

} from '../questionnaireActions';


import {
  loadUserAccounts,
} from '../../home/homeActions';



const mapStateToProps = state => {
  return {
    questionnaireGridColumns: state.questionnaire.questionnaireGridColumns,
    questionListGridColumns: state.questionnaire.questionListGridColumns,
    statusOptions: state.questionnaire.statusOptions,
    // userAccessQueryFields
    questionnaireQueryFields: state.questionnaire.questionnaireQueryFields,


    // Questionnaires
    questionnaireArray: state.questionnaire.questionnaireArray,
    questionnaireOptions: state.questionnaire.questionnaireOptions,
    parentQuestionOptions: state.questionnaire.parentQuestionOptions,
    loadQuestionnairesActionStatus: state.questionnaire.loadQuestionnairesActionStatus,

    answereeSelectControlModal: state.questionnaire.answereeSelectControlModal,

    // Answers
    answerArray: state.questionnaire.answerArray,
    
    // Select Answerees
    selectAnswereesActionStatus: state.questionnaire.selectAnswereesActionStatus,

    userAccountArray: state.questionnaire.userAccountArray,
    pmArray: state.questionnaire.pmArray,
    pmGroupArray: state.questionnaire.pmGroupArray,

    // Select Questionnaires
    selectedQuestionnaires: state.questionnaire.selectedQuestionnaires,

    // Select Questions
    selectedQuestions: state.questionnaire.selectedQuestions,

    // Questionnaire Edit Modal
    questionnaireEditControlModal: state.questionnaire.questionnaireEditControlModal,

    // create/update/delete Questionnaire
    createQuestionnaireActionStatus: state.questionnaire.createQuestionnaireActionStatus,
    updateQuestionnaireActionStatus: state.questionnaire.updateQuestionnaireActionStatus,
    copyQuestionnaireActionStatus: state.questionnaire.copyQuestionnaireActionStatus,
    batchDeleteQuestionnairesActionStatus: state.questionnaire.batchDeleteQuestionnairesActionStatus,

    // Question Edit Modal
    questionEditControlModal: state.questionnaire.questionEditControlModal,

    // create/update/delete Question
    createQuestionActionStatus: state.questionnaire.createQuestionActionStatus,
    updateQuestionActionStatus: state.questionnaire.updateQuestionActionStatus,
    batchDeleteQuestionsActionStatus: state.questionnaire.batchDeleteQuestionsActionStatus,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    questionnaireEmailEditControlModal: state.questionnaire.questionnaireEmailEditControlModal,

    // Questionnaire attachment
    questionnaireAttachmentUploadUrl: state.questionnaire.questionnaireAttachmentUploadUrl,
    questionnaireAttachmentDownloadUrl: state.questionnaire.questionnaireAttachmentDownloadUrl,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // query
    onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
    loadQuestionnaires: (params) => dispatch(loadQuestionnaires(params)),
    initLoadQuestionnairesActionStatus: () => dispatch(initLoadQuestionnairesActionStatus()),

    // Load Answer
    loadAnswers: () => dispatch(loadAnswers()),
    initLoadAnswersActionStatus: () => dispatch(initLoadAnswersActionStatus()),

    // Open/Reset Answeree Select Modal Dialog
    openAnswereeSelectModalDialog: () => dispatch(openAnswereeSelectModalDialog()),
    resetAnswereeSelectModalDialog: () => dispatch(resetAnswereeSelectModalDialog()),

    // Select All Answeree
    selectAllAnsweree: () => dispatch(selectAllAnsweree()),

    // Select Invert Answeree
    selectInvertAnsweree: () => dispatch(selectInvertAnsweree()),

    // Answeree Check Changed
    answereeCheckChanged: (checked ,userAccountName) => dispatch(answereeCheckChanged(checked ,userAccountName)),

    // Select Answeree
    selectAnswerees: (userAccountNameList, questionnaireGuid, emailTitle, emailContent) => 
      dispatch(selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent)),
      
    initSelectAnswereesActionStatus: () => dispatch(initSelectAnswereesActionStatus()),
    
    // Select Questionnaires
    selectQuestionnaires: (payload) => dispatch(selectQuestionnaires(payload)),
      
    // Select Questions
    selectQuestions: (payload) => dispatch(selectQuestions(payload)),

    // ================================================================================
    // Open/Reset Questionnaire Edit Modal Dialog
    openQuestionnaireEditModalDialog: (mode, questionnaire) => dispatch(openQuestionnaireEditModalDialog(mode, questionnaire)),
    resetQuestionnaireEditModalDialog: () => dispatch(resetQuestionnaireEditModalDialog()),

    // Create Questionnaire
    createQuestionnaire: (questionnaire) => dispatch(createQuestionnaire(questionnaire)),
    initCreateQuestionnaireActionStatus: () => dispatch(initCreateQuestionnaireActionStatus()),

    // Update Questionnaire
    updateQuestionnaire: (questionnaire) => dispatch(updateQuestionnaire(questionnaire)),
    initUpdateQuestionnaireActionStatus: () => dispatch(initUpdateQuestionnaireActionStatus()),

    // Copy Questionnaire
    copyQuestionnaire: (questionnaire) => dispatch(copyQuestionnaire(questionnaire)),
    initCopyQuestionnaireActionStatus: () => dispatch(initCopyQuestionnaireActionStatus()),

    // Batch Delete Questionnaire
    batchDeleteQuestionnaire: (questionnaireGuidList) => dispatch(batchDeleteQuestionnaire(questionnaireGuidList)),
    initBatchDeleteQuestionnaireActionStatus: () => dispatch(initBatchDeleteQuestionnaireActionStatus()),

    // Questionnaire Edit Change Input
    questionnaireEditChangeInput: (input) => dispatch(questionnaireEditChangeInput(input)),

    // ================================================================================
    // Open/Reset Question Edit Modal Dialog
    openQuestionEditModalDialog: (isAddMode, question) => dispatch(openQuestionEditModalDialog(isAddMode, question)),
    resetQuestionEditModalDialog: () => dispatch(resetQuestionEditModalDialog()),

    // Create Question
    createQuestion: (question) => dispatch(createQuestion(question)),
    initCreateQuestionActionStatus: () => dispatch(initCreateQuestionActionStatus()),

    // Update Question
    updateQuestion: (question) => dispatch(updateQuestion(question)),
    initUpdateQuestionActionStatus: () => dispatch(initUpdateQuestionActionStatus()),

    // Batch Delete Question
    batchDeleteQuestion: (questionGuidList) => dispatch(batchDeleteQuestion(questionGuidList)),
    initBatchDeleteQuestionActionStatus: () => dispatch(initBatchDeleteQuestionActionStatus()),

    // Question Edit Change Input
    questionEditChangeInput: (input) => dispatch(questionEditChangeInput(input)),

    // Questionnaire Option Change
    questionnaireOptionChange: (questionnaireGuid) => dispatch(questionnaireOptionChange(questionnaireGuid)),

    // Open/Reset Questionnaire Email Edit Modal Dialog
    openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
    resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),

    // loadUserAccounts
    loadUserAccounts: () => dispatch(loadUserAccounts()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireList);