// Sync Short Stock
export const START_SYNC_SHORT_STOCK = 'START_SYNC_SHORT_STOCK';
export const SYNC_SHORT_STOCK_SUCCESS = 'SYNC_SHORT_STOCK_SUCCESS';
export const SYNC_SHORT_STOCK_FAILURE = 'SYNC_SHORT_STOCK_FAILURE';
export const INIT_SYNC_SHORT_STOCK_ACTION_STATUS = 'INIT_SYNC_SHORT_STOCK_ACTION_STATUS';

// Load Short Stock
export const START_LOAD_SHORT_STOCK = 'START_LOAD_SHORT_STOCK';
export const LOAD_SHORT_STOCK_SUCCESS = 'LOAD_SHORT_STOCK_SUCCESS';
export const LOAD_SHORT_STOCK_FAILURE = 'LOAD_SHORT_STOCK_FAILURE';
export const INIT_LOAD_SHORT_STOCK_ACTION_STATUS = 'INIT_LOAD_SHORT_STOCK_ACTION_STATUS';