import React, { Component } from 'react';
import { Input, Form, Modal, Button, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelExpenseSummaryModalDialog extends Component {

    refreshQuery() {
        const {
            category,
            invDateStart,
            invDateEnd,
            invNo,
            firm
        } = this.props.expenseSummaryQueryFields;
        this.props.loadExpenseSummaryInfo({category, invDateStart, invDateEnd, invNo, firm})
    }

    componentDidUpdate() {
        const {
            delExpenseSummaryStatus,
        } = this.props;
        if(delExpenseSummaryStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Expense Summary Info succeeded');
            this.props.initDelExpenseSummaryActionStatus();
            this.props.resetDelExpenseSummaryModal();
            this.refreshQuery();
        }
        if(delExpenseSummaryStatus === ACTION_STATUS.ERROR) {
            this.props.initDelExpenseSummaryActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delExpenseSummaryChangeInput({name, value});
    }


    submitDelExpenseSummary = () => {
        Modal.confirm({
            title: 'Are you sure to delete this record?',
            onOk: () => {
                const {
                    id,
                    guid,
                    comments,
                } = this.props.delExpenseSummaryModal.fields;
                this.props.delExpenseSummary({id, guid, comments});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delExpenseSummaryModal,
            delExpenseSummaryStatus,
        } = this.props;
        const {
            isOpened,
            respErrMsg,
        } = delExpenseSummaryModal;

        const isSubmitLoading = delExpenseSummaryStatus === ACTION_STATUS.LOGINING;
        
        return (
            <div>
                <Modal
                    title={'Del Expense Summary Info'}
                    centered
                    width={400}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelExpenseSummaryModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelExpenseSummaryModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelExpenseSummary}>
                          Submit
                        </Button>,
                      ]}
                >
                        <TextArea 
                            style={{ width: 400 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelExpenseSummaryModalDialogForm = Form.create({ name: 'DelExpenseSummaryModalDialog' })(DelExpenseSummaryModalDialog);
export default DelExpenseSummaryModalDialogForm;
