import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';
import numeral from 'numeral';

class PortfolioMonitoringRuleGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
                {field: 'id', headerName: "ID", cellClass: 'non-number', width: 120, cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                {field: 'category', headerName: 'Category', cellClass: 'non-number', width: 100},
                {field: 'ruleName', headerName: 'Rule Name', cellClass: 'non-number', width: 100},
                {field: 'tag', headerName: 'Tag', cellClass: 'non-number', width: 100},
                {field: 'ruleStatus', headerName: 'Status', cellClass: 'non-number', width: 100 , cellStyle: this.dataStatusColorCellStyle},
                {field: 'ruleType', headerName: 'Rule Type', cellClass: 'non-number', width: 150},
                {field: 'imIa', headerName: 'IM/IA', cellClass: 'non-number', width: 100},
                {field: 'fund', headerName: 'Fund', cellClass: 'non-number', width: 100},
                {field: 'market', headerName: 'Market', cellClass: 'non-number', width: 100},
                {field: 'assetType', headerName: 'Asset Type', cellClass: 'non-number', width: 120},
                {field: 'direction', headerName: 'Direction', cellClass: 'non-number', width: 120},
                {field: 'unitValue', headerName: 'Unit Value', cellClass: 'non-number', width: 150},

                {field: 'sumOf', headerName: 'Sum Of', cellClass: 'non-number', width: 150},
                {field: 'latestValue', headerName: 'Latest value', cellClass: 'non-number', width: 150},
                {headerName: 'Compliance Alert',
                    children: [
                    {field: 'alertValue', headerName: 'Value', cellClass: 'non-number', width: 150, valueFormatter: this.numValueFormatterAllowEmpty,},
                    {field: 'alertInCurrency', headerName: 'In Currency', cellClass: 'non-number', width: 200},
                    {field: 'alertPercent', headerName: '%', cellClass: 'non-number', width: 120},
                    {field: 'alertNumerator', headerName: 'Numerator of', cellClass: 'non-number', width: 150},
                    {field: 'alertDenominator', headerName: 'Denominator of', cellClass: 'non-number', width: 120},
                ]
                },
                {headerName: 'Compliance Limit',
                    children: [
                    {field: 'limitValue', headerName: 'Value', cellClass: 'non-number', width: 150 , valueFormatter: this.numValueFormatterAllowEmpty,},
                    {field: 'limitInCurrency', headerName: 'In Currency', cellClass: 'non-number', width: 200},
                    {field: 'limitPercent', headerName: '%', cellClass: 'non-number', width: 120},
                    {field: 'limitNumerator', headerName: 'Numerator of', cellClass: 'non-number', width: 150},
                    {field: 'limitDenominator', headerName: 'Denominator of', cellClass: 'non-number', width: 120},
                    ]
                },

                {field: 'descriptionReference', headerName: 'Description/Reference', cellClass: 'non-number', width: 150},
                {field: 'effectiveDate', headerName: 'EffectiveDate', cellClass: 'non-number', width: 200},
                {field: 'remarks', headerName: 'remarks', cellClass: 'non-number', width: 120},
                {field: 'alertFlag', headerName: '22', cellClass: 'non-number', width: 120,hide:true},
     
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            rowClassRules: {
                'MarkBackgroundOr': function(params) {
                    // if(params.data.status === 'New') {
                        return params.data.alertFlag === '1';
                    // } alertFlag  limitFlag  1 表示出警

                },
                'MarkBackgroundRede': function(params) {
                    // if(params.data.status === 'New') {
                        return params.data.limitFlag === '1';
                    // } alertFlag  limitFlag  1 表示出警

                },
                // 'MarkBackgroundRed': function(params) { 
                //     return params.data.ruleStatus === 'Red' ;
                // },
                // 'MarkBackgroundGreen': function(params) {
                //     return params.data.bgColor === 'Green';
                // },
                // 'MarkBackgroundGray': function(params) {
                //     return params.data.bgColor === 'Gray';
                // },
                // 'MarkBackgroundYellow': function(params) {
                //     return params.data.bgColor === 'Yellow';
                // },
                // 'MarkBackgroundGreen': function(params) {
                //     const today = moment().format('YYYY-MM-DD');
                //     const taskList = params.data.workManagementSubtasksList;
                //     const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                //     if(redTaskList.length > 0) {
                //         return false;
                //     }
                //     const greedTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline >= today);
                //     return greedTaskList.length > 0;
                // },
                // 'MarkBackgroundGray': function(params) {
                //     const today = moment().format('YYYY-MM-DD');
                //     const taskList = params.data.workManagementSubtasksList;
                //     const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                //     if(redTaskList.length > 0) {
                //         return false;
                //     }
                //     const greedTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline >= today);
                //     if(greedTaskList.length > 0) {
                //         return false;
                //     }
                //     return params.data.status === 'Paused';
                // },
            },


            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                        {field: 'imIa', headerName: 'IM/IA', cellClass: 'non-number', width: 150},
                        {field: 'fund', headerName: 'Fund', cellClass: 'non-number', width: 150},
                        {field: 'assetType', headerName: 'Asset Type', cellClass: 'non-number', width: 120},
                        //{field: 'targetdate', headerName: 'Target Date', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                        {field: 'currentValue', headerName: 'Current Value', cellClass: 'non-number', width: 100},
                        {field: 'underlying', headerName: 'Underlying', cellClass: 'non-number', width: 250},

                        {field: 'fundBook', headerName: 'FundBook', cellClass: 'non-number', width: 100},
                        {field: 'side', headerName: 'Side', cellClass: 'non-number', width: 80},
                        {field: 'qtyShare', headerName: 'QtyShare', cellClass: 'non-number', width: 100},
                        {field: 'securityDesc', headerName: 'SecurityDesc', cellClass: 'non-number', width: 250},
                        {field: 'remark', headerName: 'InvestmentReason', cellClass: 'non-number', width: 300},
                        // {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectPortfolioMonitoringRuleTask(cptRcdDataArray);
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.portfolioMonitoringRuleSubList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">Rule Details</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        }
    }


    dataStatusColorCellStyle(param) {
        const status = param.value;
        if(status === 'New') {
            // return {color: 'green'};
            return { background: '#11e91c '};
        }
        if(status === 'Submit'){
            return {cobackgroundlor: '#f80b0b'};
        }
        
    }
    numValueFormatterAllowEmpty(params) {
        var number = params.value;
        if(number === undefined || number === null || number === '') {
          return '';
        }
        return numeral(number).format('0,0');
      }

    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
        if(data.status === 'Finished') {
            return retDiv;
        }
        const finishTaskBtn = document.createElement('button');
        const finishTaskText = document.createTextNode("FinishTask");
        finishTaskBtn.appendChild(finishTaskText);
        finishTaskBtn.onclick = () => {
            props.openFinishTaskModal(data);
        }
        retDiv.appendChild(finishTaskBtn);
        return retDiv;
    }


    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let workManagementArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectPortfolioMonitoringRule(workManagementArray);
    }
    onDataSelectionTaskChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let workManagementSubArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectPortfolioMonitoringRuleTask(workManagementSubArray);
    }


    render() {
        const {
            loadPortfolioMonitoringRuleInfoActionStatus,
            loadPortfolioMonitoringRuleInfoResp,
        } = this.props;

        const isLoading = loadPortfolioMonitoringRuleInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadPortfolioMonitoringRuleInfoArray = [];
        if(!!loadPortfolioMonitoringRuleInfoResp && !!loadPortfolioMonitoringRuleInfoResp.data) {
            loadPortfolioMonitoringRuleInfoArray = loadPortfolioMonitoringRuleInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadPortfolioMonitoringRuleInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onSelectionChanged={this.onDataSelectionChanged}
                        />
                    )}
            </div>
        );
    }

}

export default PortfolioMonitoringRuleGrid;
