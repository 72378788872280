import React, { Component } from 'react';
import { Form, Row, Col, Modal, Input, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class DelLicenseModalDialog extends Component {

    componentDidUpdate() {
        const {
            // batchDelLicenseInfo
            batchDelLicenseInfoStatus,
            batchDelLicenseInfoResp,
        } = this.props;

        if(batchDelLicenseInfoStatus === ACTION_STATUS.SUCCESS) {
            message.success('batch delete license record succeeded');
            this.props.initBatchDelLicenseInfoActionStatus();
            this.props.resetDelLicenseModal();
            const {
                employee,
            } = this.props.licensingQuerySearchFields;
            this.props.loadLicensingInfo({employee, source:'Admin'});
        }
        if(batchDelLicenseInfoStatus === ACTION_STATUS.ERROR) {
            let err = 'batch delete license record failed';
            if(!!batchDelLicenseInfoResp && !!batchDelLicenseInfoResp.respMessage) {
                err = batchDelLicenseInfoResp.respMessage;
            }
            message.error(err);
            this.props.initBatchDelLicenseInfoActionStatus();
        }

    }

    onInputChange = ({name, value}) => {
        this.props.delLicenseChangeInput({name, value});
    }

    submitDelLicenseModal = () => {
        const {
            selectedLicenseArray,
            delLicenseModal,
        } = this.props;
        if(selectedLicenseArray.length === 0) {
            message.warning("Please choose at least one License record");
            return;
        }

        const {
            comments,
        } = this.props.delLicenseModal.fields;
        const {
            mode,
        } = delLicenseModal;
        Modal.confirm({
            title: 'Are you sure to '+mode+' the selected records?',
            onOk: () => {
                this.props.batchDelLicenseInfo(selectedLicenseArray, comments,mode);
            },
        });
    }


    render() {
        const {
            delLicenseModal,
            batchDelLicenseInfoStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delLicenseModal;


        const {
            comments,
        } = delLicenseModal.fields;

        const batchDelIsLoading = batchDelLicenseInfoStatus === ACTION_STATUS.LOGINING;
        const modalTitle = mode === 'Del' ? 'Del License Record' : 'Deregister License Record';
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={600}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    confirmLoading={batchDelIsLoading}
                    onCancel={() => this.props.resetDelLicenseModal()}
                    onOk={() => this.submitDelLicenseModal()}
                    okText="Submit"
                    cancelText="Cancel"
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Comments">
                                    <TextArea 
                                        style={{ width: 350, marginLeft: 10 }}
                                        rows={3} 
                                        value={comments} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form> 
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }

}


const DelLicenseModalDialogForm = Form.create({ name: 'del_license_modal_dialog' })(DelLicenseModalDialog);

export default DelLicenseModalDialogForm;
