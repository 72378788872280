import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // change input
    ON_ELEMENT_TYPE_QUERY_INPUT_CHANGE,
    // query
    START_QUERY_ELEMENT_TYPE,
    QUERY_ELEMENT_TYPE_SUCCESS,
    QUERY_ELEMENT_TYPE_FAILURE,
    INIT_QUERY_ELEMENT_TYPE_ACTION_STATUS,
    // select data
    SELECT_ELEMENT_TYPE_LIST,
    SELECT_ELEMENT_TYPE_FIELD_LIST,
    // add or edit element type
    OPEN_ADD_OR_EDIT_ELEMENT_TYPE_MODAL,
    RESET_ADD_OR_EDIT_ELEMENT_TYPE_MODAL,
    ON_ELEMENT_TYPE_ADD_OR_EDIT_INPUT_CHANGE,
    START_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE,
    SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_SUCCESS,
    SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_ACTION_STATUS,
    // del element type
    START_DEL_ELEMENT_TYPE,
    DEL_ELEMENT_TYPE_SUCCESS,
    DEL_ELEMENT_TYPE_FAILURE,
    INIT_DEL_ELEMENT_TYPE_ACTION_STATUS,
    // add or edit field
    OPEN_ADD_OR_EDIT_FIELD_MODAL,
    RESET_ADD_OR_EDIT_FIELD_MODAL,
    ON_FIELD_ADD_OR_EDIT_INPUT_CHANGE,
    START_SUBMIT_ADD_OR_EDIT_FIELD,
    SUBMIT_ADD_OR_EDIT_FIELD_SUCCESS,
    SUBMIT_ADD_OR_EDIT_FIELD_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_FIELD_ACTION_STATUS,
    // del field
    START_DEL_ELEMENT_TYPE_FIELD,
    DEL_ELEMENT_TYPE_FIELD_SUCCESS,
    DEL_ELEMENT_TYPE_FIELD_FAILURE,
    INIT_DEL_ELEMENT_TYPE_FIELD_ACTION_STATUS,

} from './ElementTypeMngConstants';


const initialState = {
    elementTypeQueryFields: {
        baseType: undefined,
        elementTypeName: undefined,
    },
    selectedElementTypeList: [],
    selectedElementTypeFieldList: [],
    addOrEditElementTypeModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            baseType: undefined,
            elementTypeName: undefined,
            shape: undefined,
            color: undefined,
        },
    },
    addOrEditFieldModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            elementTypeId: undefined,
            isConstraint: 'N',
            fieldName: undefined,
        },
    }

};


// change input
function onElementTypeQueryInputChange(state, {name, value}) {
    return {
        ...state,
        elementTypeQueryFields: {
            ...state.elementTypeQueryFields,
            [name]: value,
        }
    };
}

// query
function startQueryElementType(state) {
    return {
      ...state,
      queryElementTypeActionStatus: ACTION_STATUS.LOGINING,
    };
}
function queryElementTypeSuccess(state, resp) {
    return {
        ...state,
        queryElementTypeActionStatus: ACTION_STATUS.SUCCESS,
        queryElementTypeActionResp: resp,
    };
}
function queryElementTypeFailure(state, err) {
    return {
        ...state,
        queryElementTypeActionStatus: ACTION_STATUS.ERROR,
        queryElementTypeActionResp: err,
    };
}
function initQueryElementTypeActionStatus(state) {
    return {
        ...state,
        queryElementTypeActionStatus: ACTION_STATUS.READY,
        selectedElementTypeList: [],
        selectedElementTypeFieldList: [],
    };
}


// select data
function selectElementTypeList(state, data) {
    return {
        ...state,
        selectedElementTypeList: data,
    }
}
function selectElementTypeFieldList(state, data) {
    return {
        ...state,
        selectedElementTypeFieldList: data,
    }
}


// add or edit element type
function openAddOrEditElementTypeModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditElementTypeModal: {
                ...state.addOrEditElementTypeModal,
                mode,
                isOpened: true,
            }
        }
    } else {
        return {
            ...state,
            addOrEditElementTypeModal: {
                ...state.addOrEditElementTypeModal,
                mode,
                isOpened: true,
                fields: {
                    id: data.id,
                    baseType: data.baseType,
                    elementTypeName: data.elementTypeName,
                    shape: data.shape,
                    color: data.color,
                },
            }
        }
    }
}
function resetAddOrEditElementTypeModal(state) {
    return {
        ...state,
        addOrEditElementTypeModal: initialState.addOrEditElementTypeModal,
    }
}


function onElementTypeAddOrEditEditInputChange(state, {name, value}) {
    return {
        ...state,
        addOrEditElementTypeModal: {
            ...state.addOrEditElementTypeModal,
            fields: {
                ...state.addOrEditElementTypeModal.fields,
                [name]: value,
            }
        }
    }
}


function startSubmitAddOrEditElementType(state) {
    return {
      ...state,
      addOrEditElementTypeActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitAddOrEditElementTypeSuccess(state, resp) {
    return {
        ...state,
        addOrEditElementTypeActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitAddOrEditElementTypeFailure(state, err) {
    return {
        ...state,
        addOrEditElementTypeActionStatus: ACTION_STATUS.ERROR,
        addOrEditElementTypeModal: {
            ...state.addOrEditElementTypeModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitAddOrEditElementTypeActionStatus(state) {
    return {
        ...state,
        addOrEditElementTypeActionStatus: ACTION_STATUS.READY,
    };
}



// del element type
function startDelElementType(state) {
    return {
      ...state,
      delElementTypeActionStatus: ACTION_STATUS.LOGINING,
    };
}
function delElementTypeSuccess(state, resp) {
    return {
        ...state,
        delElementTypeActionStatus: ACTION_STATUS.SUCCESS,
        delElementTypeActionResp: resp,
    };
}
function delElementTypeFailure(state, err) {
    return {
        ...state,
        delElementTypeActionStatus: ACTION_STATUS.ERROR,
        delElementTypeActionResp: err,
    };
}
function initDelElementTypeActionStatus(state) {
    return {
        ...state,
        delElementTypeActionStatus: ACTION_STATUS.READY,
    };
}


// add or edit field
function openAddOrEditFieldModal(state, {mode, field, elementTypeId}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditFieldModal: {
                ...state.addOrEditFieldModal,
                mode,
                isOpened: true,
                fields: {
                    id: undefined,
                    elementTypeId: elementTypeId,
                    isConstraint: 'N',
                    fieldName: undefined,
                }
            }
        }
    } else {
        return {
            ...state,
            addOrEditFieldModal: {
                ...state.addOrEditFieldModal,
                mode,
                isOpened: true,
                fields: {
                    id: field.id,
                    elementTypeId: field.elementTypeId,
                    isConstraint: field.isConstraint,
                    fieldName: field.fieldName,
                },
            }
        }
    }
}
function resetAddOrEditFieldModal(state) {
    return {
        ...state,
        addOrEditFieldModal: initialState.addOrEditFieldModal,
    }
}


function onFieldAddOrEditInputChange(state, {name, value}) {
    return {
        ...state,
        addOrEditFieldModal: {
            ...state.addOrEditFieldModal,
            fields: {
                ...state.addOrEditFieldModal.fields,
                [name]: value,
            }
        }
    }
}


function startSubmitAddOrEditField(state) {
    return {
      ...state,
      addOrEditFieldActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitAddOrEditFieldSuccess(state, resp) {
    return {
        ...state,
        addOrEditFieldActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitAddOrEditFieldFailure(state, err) {
    return {
        ...state,
        addOrEditFieldActionStatus: ACTION_STATUS.ERROR,
        addOrEditFieldModal: {
            ...state.addOrEditFieldModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitAddOrEditFieldActionStatus(state) {
    return {
        ...state,
        addOrEditFieldActionStatus: ACTION_STATUS.READY,
    };
}



// del field
function startDelElementTypeField(state) {
    return {
      ...state,
      delElementTypeFieldActionStatus: ACTION_STATUS.LOGINING,
    };
}
function delElementTypeFieldSuccess(state, resp) {
    return {
        ...state,
        delElementTypeFieldActionStatus: ACTION_STATUS.SUCCESS,
        delElementTypeFieldActionResp:  resp,
    };
}
function delElementTypeFieldFailure(state, err) {
    return {
        ...state,
        delElementTypeFieldActionStatus: ACTION_STATUS.ERROR,
        delElementTypeFieldActionResp:  err,
    };
}
function initDelElementTypeFieldActionStatus(state) {
    return {
        ...state,
        delElementTypeFieldActionStatus: ACTION_STATUS.READY,
    };
}




export default createReducer(initialState, {
    // change input
    [ON_ELEMENT_TYPE_QUERY_INPUT_CHANGE]: onElementTypeQueryInputChange,
    // query
    [START_QUERY_ELEMENT_TYPE]: startQueryElementType,
    [QUERY_ELEMENT_TYPE_SUCCESS]: queryElementTypeSuccess,
    [QUERY_ELEMENT_TYPE_FAILURE]: queryElementTypeFailure,
    [INIT_QUERY_ELEMENT_TYPE_ACTION_STATUS]: initQueryElementTypeActionStatus,
    // select data
    [SELECT_ELEMENT_TYPE_LIST]: selectElementTypeList,
    [SELECT_ELEMENT_TYPE_FIELD_LIST]: selectElementTypeFieldList,
    // add or edit element type
    [OPEN_ADD_OR_EDIT_ELEMENT_TYPE_MODAL]: openAddOrEditElementTypeModal,
    [RESET_ADD_OR_EDIT_ELEMENT_TYPE_MODAL]: resetAddOrEditElementTypeModal,
    [ON_ELEMENT_TYPE_ADD_OR_EDIT_INPUT_CHANGE]:onElementTypeAddOrEditEditInputChange,
    [START_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE]: startSubmitAddOrEditElementType,
    [SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_SUCCESS]: submitAddOrEditElementTypeSuccess,
    [SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_FAILURE]: submitAddOrEditElementTypeFailure,
    [INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_ACTION_STATUS]: initSubmitAddOrEditElementTypeActionStatus,
    // del element type
    [START_DEL_ELEMENT_TYPE]: startDelElementType,
    [DEL_ELEMENT_TYPE_SUCCESS]: delElementTypeSuccess,
    [DEL_ELEMENT_TYPE_FAILURE]: delElementTypeFailure,
    [INIT_DEL_ELEMENT_TYPE_ACTION_STATUS]: initDelElementTypeActionStatus,
    // add or edit field
    [OPEN_ADD_OR_EDIT_FIELD_MODAL]: openAddOrEditFieldModal,
    [RESET_ADD_OR_EDIT_FIELD_MODAL]: resetAddOrEditFieldModal,
    [ON_FIELD_ADD_OR_EDIT_INPUT_CHANGE]: onFieldAddOrEditInputChange,
    [START_SUBMIT_ADD_OR_EDIT_FIELD]: startSubmitAddOrEditField,
    [SUBMIT_ADD_OR_EDIT_FIELD_SUCCESS]: submitAddOrEditFieldSuccess,
    [SUBMIT_ADD_OR_EDIT_FIELD_FAILURE]: submitAddOrEditFieldFailure,
    [INIT_SUBMIT_ADD_OR_EDIT_FIELD_ACTION_STATUS]: initSubmitAddOrEditFieldActionStatus,
    // del field
    [START_DEL_ELEMENT_TYPE_FIELD]: startDelElementTypeField,
    [DEL_ELEMENT_TYPE_FIELD_SUCCESS]: delElementTypeFieldSuccess,
    [DEL_ELEMENT_TYPE_FIELD_FAILURE]: delElementTypeFieldFailure,
    [INIT_DEL_ELEMENT_TYPE_FIELD_ACTION_STATUS]: initDelElementTypeFieldActionStatus,

});