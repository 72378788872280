import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_REPORT_SUMMARY_QUERY_INPUT_CHANGE,
    START_LOAD_REPORTING_SUMMARY_INFO,
    LOAD_REPORTING_SUMMARY_INFO_SUCCESS,
    LOAD_REPORTING_SUMMARY_INFO_FAILURE,
    INIT_LOAD_REPORTING_SUMMARY_INFO_ACTION_STATUS,
    // select
    SELECT_REPORTING_SUMMRAY,
    // add or edit
    OPEN_ADD_OR_EDIT_REPORTING_SUMMARY_MODAL,
    ADD_OR_EDIT_REPORTING_SUMMARY_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_REPORTING_SUMMARY_MODAL,
    START_SUBMIT_SAVE_REPORTING_SUMMARY,
    SUBMIT_SAVE_REPORTING_SUMMARY_SUCCESS,
    SUBMIT_SAVE_REPORTING_SUMMARY_FAILURE,
    INIT_SUBMIT_SAVE_REPORTING_SUMMARY_ACTION_STATUS,
    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY,
    BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_ACTION_STATUS,
    // finish
    SELECT_REPORTING_SUMMRAY_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_REPORTING_SUMMARY_TASK,
    FINISH_REPORTING_SUMMARY_TASK_SUCCESS,
    FINISH_REPORTING_SUMMARY_TASK_FAILURE,
    INIT_FINISH_REPORTING_SUMMARY_TASK_ACTION_STATUS,


} from './ReportingSummaryConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_REPORT_SUMMARY_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadReportingSummaryInfo() {
    return {
      type: START_LOAD_REPORTING_SUMMARY_INFO,
    };
}
export function loadReportingSummaryInfo(params) {
    return function(dispatch) {
        dispatch(startLoadReportingSummaryInfo());
        httpClient
          .get(UrlConfig.LOAD_REPORTING_SUMMARY_INFO, params)
          .then(resp => dispatch(loadReportingSummaryInfoSuccess(resp)))
          .catch(err => dispatch(loadReportingSummaryInfoFailure(err)));
    };
}
function loadReportingSummaryInfoSuccess(resp) {
    return {
      type: LOAD_REPORTING_SUMMARY_INFO_SUCCESS,
      payload: resp,
    };
}
function loadReportingSummaryInfoFailure(err) {
    return {
        type: LOAD_REPORTING_SUMMARY_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadReportingSummaryInfoActionStatus() {
    return {
      type: INIT_LOAD_REPORTING_SUMMARY_INFO_ACTION_STATUS,
    };
}


// select
export function selectReportingSummary(array) {
    return {
        type: SELECT_REPORTING_SUMMRAY,
        payload: array,
    };
}


// add or edit
export function openAddOrEditReportingSummaryModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_REPORTING_SUMMARY_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditReportingSummaryChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_REPORTING_SUMMARY_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveReportingSummaryModal() {
    return {
        type: RESET_SUBMIT_SAVE_REPORTING_SUMMARY_MODAL,
    };
}

function startSubmitSaveReportingSummary() {
    return {
        type: START_SUBMIT_SAVE_REPORTING_SUMMARY,
    };
}
export function submitSaveReportingSummary(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveReportingSummary());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_REPORTING_SUMMARY;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_REPORTING_SUMMARY;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveReportingSummarySuccess(resp)))
            .catch(err => dispatch(submitSaveReportingSummaryFailure(err)));        
    };
}
function submitSaveReportingSummarySuccess(resp) {
    return {
        type: SUBMIT_SAVE_REPORTING_SUMMARY_SUCCESS,
        payload: resp,
    };
}
export function submitSaveReportingSummaryFailure(err) {
    return {
        type: SUBMIT_SAVE_REPORTING_SUMMARY_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveReportingSummaryActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_REPORTING_SUMMARY_ACTION_STATUS,
    };
}


// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForReportingSummary() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY,
    };
}
export function batchChangeStatusForReportingSummary(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForReportingSummary());
        httpClient
            .post(UrlConfig.BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY, params)
            .then(resp => dispatch(batchChangeStatusForReportingSummarySuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForReportingSummaryFailure(err)));        
    };
}
function batchChangeStatusForReportingSummarySuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForReportingSummaryFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForReportingSummaryActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_ACTION_STATUS,
    };
}


// finish
export function selectReportingSummaryTask(array) {
    return {
        type: SELECT_REPORTING_SUMMRAY_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishReportingSummaryTask() {
    return {
        type: START_FINISH_REPORTING_SUMMARY_TASK,
    };
}
export function finishReportingSummaryTask(params) {
    return function(dispatch) {
        dispatch(startFinishReportingSummaryTask());
        httpClient
            .post(UrlConfig.FINISH_REPORTING_SUMMARY_TASK, params)
            .then(resp => dispatch(finishReportingSummaryTaskSuccess(resp)))
            .catch(err => dispatch(finishReportingSummaryTaskFailure(err)));        
    };
}
function finishReportingSummaryTaskSuccess(resp) {
    return {
        type: FINISH_REPORTING_SUMMARY_TASK_SUCCESS,
        payload: resp,
    };
}
function finishReportingSummaryTaskFailure(err) {
    return {
        type: FINISH_REPORTING_SUMMARY_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishReportingSummaryTaskActionStatus() {
    return {
        type: INIT_FINISH_REPORTING_SUMMARY_TASK_ACTION_STATUS,
    };
}

