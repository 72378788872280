import React, { Component } from 'react';
import { Input, Form, Modal, Button, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelMeetingAndTrainingReviewModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingReviewQueryFields;
        this.props.loadMeetingAndTrainingReviewInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'inactive') {
            return 'Rest Info';
        }  else {
            return 'Note Info';
        }
    }
    componentDidUpdate() {
        const {
            delMeetingAndTrainingReviewStatus,
        } = this.props;
        if(delMeetingAndTrainingReviewStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delMeetingAndTrainingReviewModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelMeetingAndTrainingReviewActionStatus();
            this.props.resetDelMeetingAndTrainingReviewModal();
            this.refreshQuery();
        }
        if(delMeetingAndTrainingReviewStatus === ACTION_STATUS.ERROR) {
            this.props.initDelMeetingAndTrainingReviewActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delMeetingAndTrainingReviewChangeInput({name, value});
    }


    submitDelMeetingAndTrainingReview = () => {

        Modal.confirm({
            title: 'Are you sure submit ?',
            onOk: () => {

                const {
                    mode,
                } = this.props.delMeetingAndTrainingReviewModal;
                const {
                    id,
                    comments,
                } = this.props.delMeetingAndTrainingReviewModal.fields;
                this.props.delMeetingAndTrainingReview({id, mode, comments});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delMeetingAndTrainingReviewModal,
            delMeetingAndTrainingReviewStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delMeetingAndTrainingReviewModal;


        const isSubmitLoading = delMeetingAndTrainingReviewStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={800}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelMeetingAndTrainingReviewModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelMeetingAndTrainingReviewModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelMeetingAndTrainingReview}>
                          Submit
                        </Button>,
                      ]}
                >
                        <TextArea 
                            style={{ width: 700 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelMeetingAndTrainingReviewModalDialogForm = Form.create({ name: 'DelMeetingAndTrainingReviewModalDialog' })(DelMeetingAndTrainingReviewModalDialog);
export default DelMeetingAndTrainingReviewModalDialogForm;
