import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RelatedCompPriceReportGrid from './RelatedCompPriceReportGrid';
import moment from 'moment';

class RelatedCompPriceReportDialog extends Component {

  state = {
    
  };

  componentDidMount() {
    
  }

  componentDidUpdate() {
    
  }

  refreshBtnOnClicked = () => {
    this.props.loadRelatedCompanyPriceReport();
  }

  render() {
    const {
      relatedCompanyPriceControlModal,
    } = this.props;

    const {
      isOpened,
    } = relatedCompanyPriceControlModal;

    let modalTitle = 'Related Company Price Report';

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          onOk={() => this.props.resetRelatedCompanyPriceModalDialog()}
          onCancel={() => this.props.resetRelatedCompanyPriceModalDialog()}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
        >
          <div style={{ marginBottom: 10 }}>
            <Button 
              onClick={this.refreshBtnOnClicked} 
              size='small'
              style={{ fontSize: 10 }}
              type='primary'>
              Refresh
            </Button>

          </div>
          
          <div style={{ height: 600 }}>
            <RelatedCompPriceReportGrid {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default RelatedCompPriceReportDialog;