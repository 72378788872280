import React, {Component} from 'react';
import { Breadcrumb, Select, Button, message, Input, Divider } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import '../../ApprovalRequest.css';
import AccountHoldingGrid from './AccountHoldingGrid';
import AddOrEditAccountDialog from './AddOrEditAccountDialog';
import DelOrAuditAccountDialog from './DelOrAuditAccountDialog';
import AddOrEditHoldingDialog from './AddOrEditHoldingDialog';
import DelHoldingDialog from './DelHoldingDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import HistoryUploadDialog from '../../../common/historyUpload/components/HistoryUploadDialog';


class AccountHoldingDashboard extends Component {

    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});
        this.onQueryInputChange({name: 'employee', value: undefined});
        const param = {
            id,
            employee: '',
            approvalStatus: '',
            needHoldingData: 'true',
            source: 'Admin',
        }
        this.props.loadPersonalAccount(param);
    }


    componentDidUpdate() {
        const {
            loadPersonalAccountActionStatus,
            loadPersonalAccountResp,
        } = this.props;
        if(loadPersonalAccountActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadPersonalAccountActionStatus();
        }
        if(loadPersonalAccountActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load Personal Account failed';
            if(!!loadPersonalAccountResp && !!loadPersonalAccountResp.respMessage) {
                err = loadPersonalAccountResp.respMessage;
            }
            message.error(err);
            this.props.initLoadPersonalAccountActionStatus();
        }

    }


    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }
    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    refreshBtnOnClicked = () => {
        const {
            id,
            employee,
        } = this.props.personalAccountQueryFields;
        const param = {
            id,
            employee,
            approvalStatus: '',
            needHoldingData: 'true',
            source: 'Admin',
        }
        this.props.loadPersonalAccount(param);
    }


    showAllShowValidBtnOnClicked = () => {
        // 在这里去掉UI上所有选中，在Reducer中清空状态记录
        this.props.dataGridEvent.api.forEachNode(node => node.setSelected(false));
        if(!!this.props.detailDataGridEvent) {
            this.props.detailDataGridEvent.api.forEachNode(node => node.setSelected(false));
        }
        this.props.showAllShowValidBtnOnClicked();
    }


    addOrEditAccountBtnClicked = (mode) => {
        let personalAccount = null;
        let employee = null;
        if(mode === 'EDIT') {
            const {
                selectedPersonalAccountList,
            } = this.props;
            if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
                message.warn('Please choose one and only one Personal Account');
                return;
            }
            personalAccount = selectedPersonalAccountList[0];
            employee = personalAccount.employee;
        }
        this.props.loadAccountOptionsForAddOrEdit(employee);
        this.props.openAddOrEditAccountDialog(mode, personalAccount);
    }


    delOrAuditBtnClicked = (mode) => {
        const {
            selectedPersonalAccountList,
        } = this.props;
        if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
            message.warn('Please choose one and only one Personal Account');
            return;
        }
        const personalAccount = selectedPersonalAccountList[0];
        this.props.openDelOrAuditAccountDialog(mode, personalAccount);
    }


    addOrEditHoldingBtnClicked = (mode) => {
        let personalHolding = null;
        if(mode === 'EDIT') {
            const {
                selectedPersonalHoldingList,
            } = this.props;
            if(!selectedPersonalHoldingList || selectedPersonalHoldingList.length !== 1) {
                message.warn('Please choose one and only one Personal Holding');
                return;
            }
            personalHolding = selectedPersonalHoldingList[0];

        } else {
            const {
                selectedPersonalAccountList,
            } = this.props;
            if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
                message.warn('Please choose one and only one Personal Account');
                return;
            }
            const personalAccount = selectedPersonalAccountList[0];
            // 只能添加到FinalApproved状态的Account中
            if(personalAccount.approvalStatus !== 'FinalApproved') {
                message.warn('You can only add Personal Holding into a FinalApproved Personal Account');
                return;
            }
            personalHolding = {
                employee: personalAccount.employee,
                personalAccountGuid: personalAccount.guid,
                personalAccountId: personalAccount.id,
                personalAccountName: personalAccount.accountName,
            };

        }
        this.props.openAddOrEditHoldingDialog(mode, personalHolding);
    }


    delHoldingBtnClicked = () => {
        const {
            selectedPersonalHoldingList,
        } = this.props;
        if(!selectedPersonalHoldingList || selectedPersonalHoldingList.length === 0) {
            message.warn('Please choose at least one Personal Holding');
            return;
        }
        const personalHoldingIdList = selectedPersonalHoldingList.map(e => e.id);
        this.props.openDelHoldingDialog(personalHoldingIdList);
    } 


    historyUploadOnClicked = () => {
        const {
            selectedPersonalAccountList,
            personalAccountDownloadUrl,
        } = this.props;
        if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
            message.warn('Please choose one and only one Personal Account');
            return;
        }
        const account = selectedPersonalAccountList[0];
        this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', queryFields: {module: 'PersonalAccountHolding', dataId: ''+account.id}, 
                                            baseDownloadUrl: personalAccountDownloadUrl });
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'operateSource', 'dataId', 'operateType', 'dataType', 'comments', 'before', 'after'];

    render() {
        const {
            userAccountOptions,
            personalAccountQueryFields,
            loadPersonalAccountActionStatus,
            onlyShowValidData,
        } = this.props;
        
        const {
            id,
            employee,
        } = personalAccountQueryFields;

        const refreshBtnIsLoading = loadPersonalAccountActionStatus === ACTION_STATUS.LOGINING;
        const showAllShowValidBtnName = onlyShowValidData ? 'showAll' : 'showValid';

        return(
            <div className="personalAccountWrapper">
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Monitoring</Breadcrumb.Item>
                        <Breadcrumb.Item>Account &amp; Holding</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='accountHoldingOperationBar'>
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="dataID" 
                        value={id}
                        onChange={(e) => {
                            this.onDataIDQueryInputChange({ name: 'id', value: e.target.value })
                        }}
                    />
                    <Select
                        allowClear
                        showSearch
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'employee', value: val })
                        }} 
                        value={employee}
                        placeholder="Employee">
                        {userAccountOptions}
                    </Select>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.showAllShowValidBtnOnClicked()} 
                        size='small'
                        style={{ width: 75, marginLeft: 10 }}
                        type='primary'>
                        {showAllShowValidBtnName}
                    </Button>

                    <Divider type='vertical' />
                    
                    <span style={{ fontSize: 14 }}>Account: </span>
                    <Button 
                        onClick={() => this.addOrEditAccountBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                        icon='plus'
                    />
                    <Button 
                        onClick={() => this.addOrEditAccountBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon='edit'
                    />
                    <Button 
                        onClick={() => this.delOrAuditBtnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                        icon='minus'
                    />
                    <Button 
                        onClick={() => this.delOrAuditBtnClicked('APPROVE')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'>
                        Approve
                    </Button>
                    <Button 
                        onClick={() => this.delOrAuditBtnClicked('FINALAPPROVE')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'>
                        Final Approve
                    </Button>
                    <Button 
                        onClick={() => this.delOrAuditBtnClicked('REJECT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'>
                        Reject
                    </Button>

                    <Divider type='vertical' />

                    <span style={{ fontSize: 14 }}>Holding: </span>
                    <Button 
                        onClick={() => this.addOrEditHoldingBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                        icon='plus'
                    />
                    <Button 
                        onClick={() => this.addOrEditHoldingBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon='edit'
                    />
                    <Button 
                        onClick={() => this.delHoldingBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                        icon='minus'
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'PersonalAccountHolding'}, customColumns: this.opRcdCustomColumns})} 
                        style={{ fontSize: 12 }}
                        size='small'
                        type='primary'>
                        OperationRecord
                    </Button>
                    <Button 
                        onClick={() => this.historyUploadOnClicked()} 
                        style={{ marginLeft: 10, fontSize: 12 }}
                        size='small'
                        type='primary'>
                        HistoryUpload
                    </Button>


                </div>

                <div className='personalAccountGrid'><AccountHoldingGrid {...this.props} /></div>
                <AddOrEditAccountDialog {...this.props} />
                <DelOrAuditAccountDialog {...this.props} />
                <AddOrEditHoldingDialog {...this.props} />
                <DelHoldingDialog {...this.props} />
                <OperationRecordDialog {...this.props} />
                <HistoryUploadDialog {...this.props} />

            </div>
        );
    }

}


export default AccountHoldingDashboard;