import React, { Component } from 'react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';

import '../../ComplianceRule.css';

class SFCShortableListGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.sfcShortableListGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        // minWidth: 30
      },
      rowSelection: "multiple",
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    // const COLUMNS_KEY = 'fund-list-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      sfcShortInterestArray,
      className = 'grid-wrapper',
      loadSFCShortInterestActionStatus,
    } = this.props;

    const isLoading = loadSFCShortInterestActionStatus === ACTION_STATUS.LOGINING;
    if (loadSFCShortInterestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadSFCShortInterestActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={sfcShortInterestArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            getRowNodeId={this.getRowNodeId}
            // events
            onGridReady={this.onGridReady}
          />
        )}
      </div>
    );
  }
}

export default SFCShortableListGrid;