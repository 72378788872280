import {
  // Load LicensingForm
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_LICENSINGFORM,
  LOAD_LICENSINGFORM_SUCCESS,
  LOAD_LICENSINGFORM_FAILURE,
  INIT_LOAD_LICENSINGFORM_ACTION_STATUS,

  // Select LicensingForm
  SELECT_LICENSINGFORM,
  
  // Load Question
  START_LOAD_QUESTIONS,
  LOAD_QUESTIONS_SUCCESS,
  LOAD_QUESTIONS_FAILURE,
  INIT_LOAD_QUESTIONS_ACTION_STATUS,

  // Select Questions
  SELECT_QUESTIONS,

  // Open/Reset Answeree Select Modal Dialog
  ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
  ANSWEREE_SELECT_MODAL_DIALOG_RESET,

  // Select All Answeree
  SELECT_ALL_ANSWEREE,

  // Select Invert Answeree
  SELECT_INVERT_ANSWEREE,

  // Answeree Check Changed
  ANSWEREE_CHECK_CHANGED,

  // Select Answerees
  START_SELECT_ANSWEREES,
  SELECT_ANSWEREES_SUCCESS,
  SELECT_ANSWEREES_FAILURE,
  INIT_SELECT_ANSWEREES_ACTION_STATUS,






  // Load Answers
  START_LOAD_ANSWERS,
  LOAD_ANSWERS_SUCCESS,
  LOAD_ANSWERS_FAILURE,
  INIT_LOAD_ANSWERS_ACTION_STATUS,

  // Load To-do LicensingForm
  START_LOAD_TODO_LICENSINGFORM,
  LOAD_TODO_LICENSINGFORM_SUCCESS,
  LOAD_TODO_LICENSINGFORM_FAILURE,
  INIT_LOAD_TODO_LICENSINGFORM_ACTION_STATUS,

  // Submit LicensingForm Answer
  START_SUBMIT_LICENSINGFORM_ANSWER,
  SUBMIT_LICENSINGFORM_ANSWER_SUCCESS,
  SUBMIT_LICENSINGFORM_ANSWER_FAILURE,
  INIT_SUBMIT_LICENSINGFORM_ANSWER_ACTION_STATUS,

  // Save LicensingForm Answer
  START_SAVE_LICENSINGFORM_ANSWER,
  SAVE_LICENSINGFORM_ANSWER_SUCCESS,
  SAVE_LICENSINGFORM_ANSWER_FAILURE,
  INIT_SAVE_LICENSINGFORM_ANSWER_ACTION_STATUS,

  // LicensingForm Answer Change Input
  LICENSINGFORM_ANSWER_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset LicensingForm Edit Modal Dialog
  LICENSINGFORM_EDIT_MODAL_DIALOG_OPEN,
  LICENSINGFORM_EDIT_MODAL_DIALOG_RESET,

  // Create LicensingForm
  START_CREATE_LICENSINGFORM,
  CREATE_LICENSINGFORM_SUCCESS,
  CREATE_LICENSINGFORM_FAILURE,
  INIT_CREATE_LICENSINGFORM_ACTION_STATUS,

  // Update LicensingForm
  START_UPDATE_LICENSINGFORM,
  UPDATE_LICENSINGFORM_SUCCESS,
  UPDATE_LICENSINGFORM_FAILURE,
  INIT_UPDATE_LICENSINGFORM_ACTION_STATUS,

  // Copy LicensingForm
  START_COPY_LICENSINGFORM,
  COPY_LICENSINGFORM_SUCCESS,
  COPY_LICENSINGFORM_FAILURE,
  INIT_COPY_LICENSINGFORM_ACTION_STATUS,

  // Delete LicensingForm
  START_BATCH_DELETE_LICENSINGFORM,
  BATCH_DELETE_LICENSINGFORM_SUCCESS,
  BATCH_DELETE_LICENSINGFORM_FAILURE,
  INIT_BATCH_DELETE_LICENSINGFORM_ACTION_STATUS,

  // LicensingForm Edit Change Input
  LICENSINGFORM_EDIT_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  QUESTION_EDIT_MODAL_DIALOG_OPEN,
  QUESTION_EDIT_MODAL_DIALOG_RESET,

  // Create Question
  START_CREATE_QUESTION,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  INIT_CREATE_QUESTION_ACTION_STATUS,

  // Update Question
  START_UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  INIT_UPDATE_QUESTION_ACTION_STATUS,

  // Delete Question
  START_BATCH_DELETE_QUESTION,
  BATCH_DELETE_QUESTION_SUCCESS,
  BATCH_DELETE_QUESTION_FAILURE,
  INIT_BATCH_DELETE_QUESTION_ACTION_STATUS,

  // Question Edit Change Input
  QUESTION_EDIT_CHANGE_INPUT,

  // LicensingForm Option Change
  LICENSINGFORM_OPTION_CHANGE,

  // Load LicensingForm Results
  START_LOAD_LICENSINGFORM_RESULTS,
  LOAD_LICENSINGFORM_RESULTS_SUCCESS,
  LOAD_LICENSINGFORM_RESULTS_FAILURE,
  INIT_LOAD_LICENSINGFORM_RESULTS_ACTION_STATUS,

  // Select LicensingForm Results
  SELECT_LICENSINGFORM_RESULTS,

  // Load LicensingForm Result Detail
  START_LOAD_LICENSINGFORM_RESULT_DETAIL,
  LOAD_LICENSINGFORM_RESULT_DETAIL_SUCCESS,
  LOAD_LICENSINGFORM_RESULT_DETAIL_FAILURE,
  INIT_LOAD_LICENSINGFORM_RESULT_DETAIL_ACTION_STATUS,

  // Accept LicensingForm Result
  START_ACCEPT_LICENSINGFORM_RESULT,
  ACCEPT_LICENSINGFORM_RESULT_SUCCESS,
  ACCEPT_LICENSINGFORM_RESULT_FAILURE,
  INIT_ACCEPT_LICENSINGFORM_RESULT_ACTION_STATUS,

  // Reject LicensingForm Result
  START_REJECT_LICENSINGFORM_RESULT,
  REJECT_LICENSINGFORM_RESULT_SUCCESS,
  REJECT_LICENSINGFORM_RESULT_FAILURE,
  INIT_REJECT_LICENSINGFORM_RESULT_ACTION_STATUS,

  // Open/Reset LicensingForm Result Operate Modal Dialog
  LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_OPEN,
  LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_RESET,

  // LicensingForm Answer Submitter Edit Change Input
  LICENSINGFORM_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,

  // Send Remind Email
  START_SEND_REMIND_EMAIL,
  SEND_REMIND_EMAIL_SUCCESS,
  SEND_REMIND_EMAIL_FAILURE,
  INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

  // Open/Reset LicensingForm Email Edit Modal Dialog
  LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_OPEN,
  LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_RESET,

  // Update LicensingForm Score
  START_UPDATE_LICENSINGFORM_SCORE,
  UPDATE_LICENSINGFORM_SCORE_SUCCESS,
  UPDATE_LICENSINGFORM_SCORE_FAILURE,
  INIT_UPDATE_LICENSINGFORM_SCORE_ACTION_STATUS,

  // Calculate LicensingForm Total Score
  CALC_LICENSINGFORM_TOTAL_SCORE,

  // Open/Reset Submitted LicensingForm History Modal Dialog
  SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_OPEN,
  SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_RESET,

  // Load Submitted LicensingForm History
  START_LOAD_SUBMITTED_LICENSINGFORM_HISTORY,
  LOAD_SUBMITTED_LICENSINGFORM_HISTORY_SUCCESS,
  LOAD_SUBMITTED_LICENSINGFORM_HISTORY_FAILURE,
  INIT_LOAD_SUBMITTED_LICENSINGFORM_HISTORY_ACTION_STATUS,

  // Select Submitted LicensingForm History
  SELECT_SUBMITTED_LICENSINGFORM_HISTORY,

  // Apply Submitted LicensingForm History
  APPLY_SUBMITTED_LICENSINGFORM_HISTORY,


} from './licensingFormConstants';

import {
  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,

} from '../../../features/home/homeConstants';

import React from 'react';
import moment from 'moment';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from './components/GridColumnMap.js';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { QUESTION_TYPE_CODE } from '../../../../utils/QuestionTypeUtil';
import LicensingFormOpMode from '../../../../utils/QuestionnaireOpMode';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../utils/QuestionnaireEmailSendMode';
import { QUESTIONNAIRE_TYPE_CODE } from '../../../../utils/QuestionnaireType';

const Option = Select.Option;
const statusOptions = [];
statusOptions.push(<Option key={'Activated'}>{'Activated'}</Option>);
statusOptions.push(<Option key={'Invalid'}>{'Invalid'}</Option>);
statusOptions.push(<Option key={'Not Activated'}>{'Not Activated'}</Option>);

const statusAnswerOptions = [];
statusAnswerOptions.push(<Option key={'Submitted'}>{'Submitted'}</Option>);
statusAnswerOptions.push(<Option key={'New'}>{'New'}</Option>);
statusAnswerOptions.push(<Option key={'Accepted'}>{'Accepted'}</Option>);
statusAnswerOptions.push(<Option key={'Rejected'}>{'Rejected'}</Option>);
statusAnswerOptions.push(<Option key={'Unknown'}>{'Unknown'}</Option>);


const initialState = {
  // Grid Columns
  questionListGridColumns: GridColumnMap.questionListGridColumns,
  licensingFormGridColumns: GridColumnMap.licensingFormGridColumns,
  licensingFormResultGridColumns: GridColumnMap.licensingFormResultGridColumns,
  questionAnswerGridColumns: GridColumnMap.questionAnswerGridColumns,
  submittedHistoryGridColumns: GridColumnMap.submittedHistoryGridColumns,
  statusOptions: statusOptions,
  statusAnswerOptions: statusAnswerOptions,
  licensingFormQueryFields: {
    licensingFormVersion: '',
    nameOnLicensingForm: '',
    status: ''
},
  // LicensingForm attachment
  licensingFormAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_LICENSINGFORM_ATTACHMENT,
  licensingFormAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_LICENSINGFORM_ATTACHMENT,

  // LicensingForm
  licensingFormArray: [],
  licensingFormOptions: [],
  parentQuestionOptions: [],
  loadLicensingFormActionStatus: ACTION_STATUS.READY,
  answereeSelectControlModal: {
    isOpened: false,
    respErrMsg: '',
  },

  // Questions
  questionArray: [],
  licensingFormTitle: '',
  loadQuestionsActionStatus: ACTION_STATUS.READY,

  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

  // Answers
  answerArray: [],
  loadAnswersActionStatus: ACTION_STATUS.READY,

  // Load User Account
  userAccountArray: [],
  pmArray: [],
  pmGroupArray: [],
  userAccountOptions: [],

  // Select LicensingForm
  selectedLicensingForm: [],

  // Select Questions
  selectedQuestions: [],

  // Load to-do LicensingForm
  loadTodoLicensingFormActionStatus: ACTION_STATUS.READY,
  todoLicensingForm: null,
  licensingFormTitle: '',
  todoQuestionArray: [],
  todoLicensingFormAuthToken: '',
  licensingFormAnsweree: '',
  licensingFormAnswerDate: '',
  licensingFormSubmitter: '',
  licensingFormStatus: '',
  licensingFormStatusLabel: '',

  // Submit LicensingForm Answer
  submitLicensingFormAnswerActionStatus: ACTION_STATUS.READY,
  submitLicensingFormAnswerErrMsg: '',

  // Save LicensingForm Answer
  saveLicensingFormAnswerActionStatus: ACTION_STATUS.READY,
  saveLicensingFormAnswerErrMsg: '',

  // LicensingForm Edit Modal
  licensingFormEditControlModal: {
    isOpened: false,
    mode: LicensingFormOpMode.NEW,
    respErrMsg: '',
    fields: {
      guid: '',
      name: '',
      title: '',
      isActive: true,
      expiredDate: moment().add(3, "M").format('YYYY-MM-DD'),
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
      type: QUESTIONNAIRE_TYPE_CODE.QUARTER,
    }
  },

  // create/update/copy/delete LicensingForm
  createLicensingFormActionStatus: ACTION_STATUS.READY,
  updateLicensingFormActionStatus: ACTION_STATUS.READY,
  copyLicensingFormActionStatus: ACTION_STATUS.READY,
  batchDeleteLicensingFormActionStatus: ACTION_STATUS.READY,

  // Question Edit Modal
  questionEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      guid: '',
      licensingFormGuid: '',
      questionnaireGuid:'',
      questionNumber: '',
      subQuestionNumber: '',
      questionEnglish: '',
      questionChinese: '',
      options: '',
      questionType: '',
      parentGuid: '',
      triggerOption: '',
      correctAnswer: '',
      score: 0,
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
    }
  },

  // create/update/delete Question
  createQuestionActionStatus: ACTION_STATUS.READY,
  updateQuestionActionStatus: ACTION_STATUS.READY,
  batchDeleteQuestionsActionStatus: ACTION_STATUS.READY,
  
  // Load LicensingForm Results
  loadLicensingFormResultsActionStatus: ACTION_STATUS.READY,
  licensingFormResultArray: [],

  // Select LicensingForm Results
  selectedLicensingFormResults: [],

  // Load LicensingForm Result Detail
  loadLicensingFormResultDetailActionStatus: ACTION_STATUS.READY,
  licensingFormResultDetail: null,
  questionAnswerList: [],


  // Accept LicensingForm Result
  acceptLicensingFormResultActionStatus: ACTION_STATUS.READY,

  // Reject LicensingForm Result
  rejectLicensingFormResultActionStatus: ACTION_STATUS.READY,

  // Open/Reset LicensingForm Result Operate Modal Dialog
  licensingFormResultOperateControlModal: {
    isOpened: false,
    opMode: '',
    licensingFormResultGuidList: [],
    respErrMsg: '',
  },

  // Send Remind Email
  sendRemindEmailActionStatus: ACTION_STATUS.READY,

  // Open/Reset LicensingForm Email Edit Modal Dialog
  licensingFormEmailEditControlModal: {
    isOpened: false,
    sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
    emailPayload: null,
    respErrMsg: '',
  },

  // Update LicensingForm Score 
  updateLicensingFormScoreActionStatus: ACTION_STATUS.READY,
  updateLicensingFormScoreErrMsg: '',

  // Open/Reset Submitted LicensingForm History Modal Dialog
  submittedLicensingFormHistoryControlModal: {
    isOpened: false,
    authToken: '',
    respErrMsg: '',
  },

  // Load Submitted LicensingForm History
  loadSubmittedLicensingFormHistoryActionStatus: ACTION_STATUS.READY,
  submittedLicensingFormHistoryArray: [],

  // Select Submitted LicensingForm History
  selectSubmittedLicensingFormHistoryArray: [],
}

// =================================================================
// query
function onQueryInputChange(state, {name, value}) {
  const upatedFields = {
      ...state.licensingFormQueryFields,
      [name]: value,
  };
  return {
      ...state,
      licensingFormQueryFields: upatedFields,
  }
}


// Load LicensingForm
function startLoadLicensingForm(state) {
  return {
    ...state,
    loadLicensingFormActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadLicensingFormSuccess(state, resp) {
  const licensingFormArray = resp.data;
  const licensingFormOptions = licensingFormArray.map(licensingForm => <Option key={licensingForm.guid}>{licensingForm.name}</Option>);

  return {
    ...state,
    loadLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
    licensingFormArray,
    licensingFormOptions,
  };
}

function loadLicensingFormFailure(state, err) {
  return {
    ...state,
    loadLicensingFormActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadLicensingFormActionStatus(state) {
  return {
    ...state,
    loadLicensingFormActionStatus: ACTION_STATUS.READY,
  };
}

// Select LicensingForm
function selectLicensingForm(state, payload) {
  const selectedLicensingForm = payload;

  // 已经存在的answeree 默认勾选
  const pmGroupArray = state.pmGroupArray;
  const answerArray = state.answerArray;

  if (pmGroupArray.length > 0 && answerArray.length > 0 && selectedLicensingForm.length > 0) {
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        const filterAnswereeArray = answerArray.filter(answer => answer.licensingFormGuid === selectedLicensingForm[0].id).map(answer => answer.answeree);
        if (filterAnswereeArray.includes(userAccount.userAccountName)) {
          userAccount.checked = true;
        }
      })
    });
  }

  return {
    ...state,
    selectedLicensingForm: payload
  };
}

// =================================================================
// Load Question
function startLoadQuestions(state) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadQuestionsSuccess(state, resp) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.SUCCESS,
    questionArray: resp.data.questionList,
    licensingFormTitle: resp.data.title,
  };
}

function loadQuestionsFailure(state, err) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadQuestionsActionStatus(state) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.READY,
  };
}

// Select Questions
function selectQuestions(state, payload) {
  return {
    ...state,
    selectedQuestions: payload
  };
}

// Open/Reset Answeree Select Modal Dialog
function openAnswereeSelectModalDialog(state) {
  return {
    ...state,
    answereeSelectControlModal: {
      ...state.answereeSelectControlModal,
      isOpened: true,
      fields: {
        ...state.answereeSelectControlModal.fields,
      }
    },
  };
}

function resetAnswereeSelectModalDialog(state) {
  const pmGroupArray = state.pmGroupArray;

  // 重置答题人勾选项
  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      userAccount.checked = false;
    })
  });

  return {
    ...state,
    answereeSelectControlModal: initialState.answereeSelectControlModal,
    pmGroupArray,
  };
}

// Select All Answeree
function selectAllAnsweree(state) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      userAccount.checked = true;
    })
  });

  return {
    ...state,
    pmGroupArray,
  };
}

// Select Invert Answeree
function selectInvertAnsweree(state) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      userAccount.checked = !userAccount.checked;
    })
  });

  return {
    ...state,
    pmGroupArray,
  };
}

// Answeree Check Changed
function answereeCheckChanged(state, {checked ,userAccountName}) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      if (userAccount.userAccountName === userAccountName) {
        userAccount.checked = checked;
      }
    })
  });

  return {
    ...state,
  };
}

// Select Answerees
function startSelectAnswerees(state) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function selectAnswereesSuccess(state, resp) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function selectAnswereesFailure(state, err) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.ERROR,
    answereeSelectControlModal: {
      ...state.answereeSelectControlModal,
      respErrMsg: err.respMessage,
    },
    licensingFormEmailEditControlModal: {
      ...state.licensingFormEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initSelectAnswereesActionStatus(state) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.READY,
  };
}

















// =================================================================
// Load Answers
function startLoadAnswers(state) {
  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadAnswersSuccess(state, resp) {
  const answerArray = resp.data;

  // 已经存在的answeree 默认勾选
  const pmGroupArray = state.pmGroupArray;
  const selectedLicensingForm = state.selectedLicensingForm;

  if (pmGroupArray.length > 0 && answerArray.length > 0 && selectedLicensingForm.length > 0) {
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        const filterAnswereeArray = answerArray.filter(answer => answer.licensingFormGuid === selectedLicensingForm[0].id).map(answer => answer.answeree);
        if (filterAnswereeArray.includes(userAccount.userAccountName)) {
          userAccount.checked = true;
        }
      })
    });
  }

  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.SUCCESS,
    answerArray,
  };
}

function loadAnswersFailure(state, err) {
  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.ERROR,
  };
}

 function initLoadAnswersActionStatus(state) {
  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.READY,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;

  let pmArray = [];
  let pmGroupArray = [];
  let rowSize = 4;
  if (userAccountArray.length > 0) {
    pmArray = userAccountArray
    .filter(userAccount => !!userAccount.isActive)
    .filter(userAccount =>  userAccount.firm.toUpperCase() === 'PINPOINT')
      .map(userAccount => Object.assign({}, userAccount, { checked: false }));

    const groupSize = Math.floor(pmArray.length / rowSize) + (pmArray.length % rowSize > 0 ? 1 : 0);
    for (let index = 0; index < groupSize; index++) {
      const array = pmArray.slice(index * rowSize, (index + 1) * rowSize);
      pmGroupArray.push(array);
    }
  }

  // 已经存在的answeree 默认勾选
  const answerArray = state.answerArray;
  const selectedLicensingForm = state.selectedLicensingForm;

  if (pmGroupArray.length > 0 && answerArray.length > 0 && selectedLicensingForm.length > 0) {
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        const filterAnswereeArray = answerArray.filter(answer => answer.licensingFormGuid === selectedLicensingForm[0].id).map(answer => answer.answeree);
        if (filterAnswereeArray.includes(userAccount.userAccountName)) {
          userAccount.checked = true;
        }
      })
    });
  }

  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.userAccountName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    pmArray,
    pmGroupArray,
    userAccountOptions,
  };
}

// Load To-do LicensingForm
function startLoadTodoLicensingForm(state) {
  return {
    ...state,
    loadTodoLicensingFormActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadTodoLicensingFormSuccess(state, resp) {
  const todoLicensingFormAuthToken = resp.data.authToken;
  const todoLicensingForm = resp.data.todoLicensingFormDTO;
  const licensingFormAnsweree = resp.data.answeree;
  const licensingFormAnswerDate = resp.data.answerDate;
  const licensingFormSubmitter = resp.data.submitter;

  const licensingFormStatus = resp.data.status;
  const licensingFormStatusLabel = resp.data.statusLabel;

  const todoQuestionArray = todoLicensingForm.todoQuestionDTOList;
  const licensingFormTitle = todoLicensingForm.title;

  const licensingFormAttachmentDownloadUrl = state.licensingFormAttachmentDownloadUrl;
  todoQuestionArray.forEach(todoQuestion => {
    todoQuestion.fileList = [];
    if (todoQuestion.questionType === QUESTION_TYPE_CODE.UPLOAD && todoQuestion.answerContent !== null && todoQuestion.answerContent !== '') {
      const answerArray = todoQuestion.answerContent.split(',');
      todoQuestion.fileList = answerArray.map((answerFile, index) => {
        return {
          uid: index,
          name: answerFile,
          status: 'done',
          url: licensingFormAttachmentDownloadUrl + "/" + answerFile,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: answerFile,
            }
          }
        };
      });
    }

    todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => {
      subTodoQuestion.fileList = [];
      if (subTodoQuestion.questionType === QUESTION_TYPE_CODE.UPLOAD && subTodoQuestion.answerContent !== null && subTodoQuestion.answerContent !== '') {
        const answerArray = subTodoQuestion.answerContent.split(',');
        subTodoQuestion.fileList = answerArray.map((answerFile, index) => {
          return {
            uid: index,
            name: answerFile,
            status: 'done',
            url: licensingFormAttachmentDownloadUrl + "/" + answerFile,
            response: {
              respCode: "S0001",
              data: {
                fileNameWithTimeStamp: answerFile,
              }
            }
          };
        });
      }
    });
  });

  return {
    ...state,
    loadTodoLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
    todoLicensingForm,
    todoQuestionArray,
    licensingFormTitle,
    todoLicensingFormAuthToken,
    licensingFormAnsweree,
    licensingFormAnswerDate,
    licensingFormSubmitter,
    licensingFormStatus,
    licensingFormStatusLabel,
    submitLicensingFormAnswerErrMsg: '',
    saveLicensingFormAnswerErrMsg: '',
  };
}

function loadTodoLicensingFormFailure(state, err) {
  return {
    ...state,
    loadTodoLicensingFormActionStatus: ACTION_STATUS.ERROR,
    loadTodoLicensingFormActionResp: err,
  };
}

function initLoadTodoLicensingFormActionStatus(state) {
  return {
    ...state,
    loadTodoLicensingFormActionStatus: ACTION_STATUS.READY,
  };
}

// Submit LicensingForm Answer
function startSubmitLicensingFormAnswer(state) {
  return {
    ...state,
    submitLicensingFormAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitLicensingFormAnswerSuccess(state, resp) {
  return {
    ...state,
    submitLicensingFormAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitLicensingFormAnswerErrMsg: '',
    saveLicensingFormAnswerErrMsg: '',
  };
}

function submitLicensingFormAnswerFailure(state, err) {
  return {
    ...state,
    submitLicensingFormAnswerActionStatus: ACTION_STATUS.ERROR,
    submitLicensingFormAnswerErrMsg: err.respMessage,
  };
}

function initSubmitLicensingFormAnswerActionStatus(state) {
  return {
    ...state,
    submitLicensingFormAnswerActionStatus: ACTION_STATUS.READY,
  };
}

// Save LicensingForm Answer
function startSaveLicensingFormAnswer(state) {
  return {
    ...state,
    saveLicensingFormAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function saveLicensingFormAnswerSuccess(state, resp) {
  return {
    ...state,
    saveLicensingFormAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitLicensingFormAnswerErrMsg: '',
    saveLicensingFormAnswerErrMsg: '',
  };
}

function saveLicensingFormAnswerFailure(state, err) {
  return {
    ...state,
    saveLicensingFormAnswerActionStatus: ACTION_STATUS.ERROR,
    saveLicensingFormAnswerErrMsg: err.respMessage,
  };
}

function initSaveLicensingFormAnswerActionStatus(state) {
  return {
    ...state,
    saveLicensingFormAnswerActionStatus: ACTION_STATUS.READY,
  };
}

// LicensingForm Answer Change Input
function licensingFormAnswerChangeInput(state, { answerGuid, answerContent, fileList }) {
  const todoQuestionArray = state.todoQuestionArray;

  todoQuestionArray.forEach(todoQuestion => { 
    if (todoQuestion.answerGuid === answerGuid) {
      todoQuestion.answerContent = answerContent;
      todoQuestion.fileList = fileList;

      // radio
      if (todoQuestion.questionType === '1') {
        todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => { 
          subTodoQuestion.answerContent = '';
          subTodoQuestion.fileList = '';
        });
      }
    } else {
      todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => { 
        if (subTodoQuestion.answerGuid === answerGuid) {
          subTodoQuestion.answerContent = answerContent;
          subTodoQuestion.fileList = fileList;
        }
      });
    }
  });

  return {
    ...state,
    todoQuestionArray,
  };
}

// ================================================================================
// Open/Reset LicensingForm Edit Modal Dialog
function openLicensingFormEditModalDialog(state, {mode, licensingForm}) {
  if (mode === LicensingFormOpMode.NEW) {
    return {
      ...state,
      licensingFormEditControlModal: {
        ...state.licensingFormEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.licensingFormEditControlModal.fields,
        }
      },
    };
  } 
  
  if (mode === LicensingFormOpMode.MODIFY) {
    const licensingFormAttachmentDownloadUrl = state.licensingFormAttachmentDownloadUrl;

    const fileList = [];
    const attachment = licensingForm.attachment;
    const attachmentOriginName = licensingForm.attachmentOriginName;

    if (!!attachment && !!attachmentOriginName) {
      const attachmentArray = attachment.split(',');
      const attachmentOriginNameArray = attachmentOriginName.split(',');
  
      let fileIndex = 0;
      attachmentArray.forEach(licensingFormAttachment => {
        let lastUnderlineIndex = licensingFormAttachment.lastIndexOf('_');
        const licensingFormAttachmentPart1 = licensingFormAttachment.substring(0, lastUnderlineIndex);
        const licensingFormAttachmentPart2 = licensingFormAttachment.substring(lastUnderlineIndex + 1);
        let dotIndex = licensingFormAttachmentPart2.indexOf('.');
        const licensingFormAttachmentExtName = licensingFormAttachmentPart2.substring(dotIndex);
        const assembleFileName = licensingFormAttachmentPart1 + licensingFormAttachmentExtName;
  
        const filterOriginNameArray = attachmentOriginNameArray.filter(attachmentOriginName => attachmentOriginName === assembleFileName);
        const licensingFormOriginAttachmentName = filterOriginNameArray[0];
  
        fileIndex++;
        const file = {
          uid: fileIndex,
          name: licensingFormOriginAttachmentName,
          status: 'done',
          url: licensingFormAttachmentDownloadUrl + "/" + licensingFormAttachment,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: licensingFormAttachment,
              originalFileName: licensingFormOriginAttachmentName,
            }
          }
        };
        fileList.push(file);
      });
    }

    return {
      ...state,
      licensingFormEditControlModal: {
        ...state.licensingFormEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.licensingFormEditControlModal.fields,
          guid: licensingForm.guid,
          name: licensingForm.name,
          title: licensingForm.title,
          isActive: licensingForm.isActive,
          expiredDate: licensingForm.expiredDate,
          attachment: licensingForm.attachment,
          attachmentOriginName: licensingForm.attachmentOriginName,
          fileList,
          type: licensingForm.type,
        }
      },
    };
  }

  if (mode === LicensingFormOpMode.COPY) {
    const licensingFormAttachmentDownloadUrl = state.licensingFormAttachmentDownloadUrl;

    const fileList = [];
    const attachment = licensingForm.attachment;
    const attachmentOriginName = licensingForm.attachmentOriginName;

    if (!!attachment && !!attachmentOriginName) {
      const attachmentArray = attachment.split(',');
      const attachmentOriginNameArray = attachmentOriginName.split(',');
  
      let fileIndex = 0;
      attachmentArray.forEach(licensingFormAttachment => {
        let lastUnderlineIndex = licensingFormAttachment.lastIndexOf('_');
        const licensingFormAttachmentPart1 = licensingFormAttachment.substring(0, lastUnderlineIndex);
        const licensingFormAttachmentPart2 = licensingFormAttachment.substring(lastUnderlineIndex + 1);
        let dotIndex = licensingFormAttachmentPart2.indexOf('.');
        const licensingFormAttachmentExtName = licensingFormAttachmentPart2.substring(dotIndex);
        const assembleFileName = licensingFormAttachmentPart1 + licensingFormAttachmentExtName;
  
        const filterOriginNameArray = attachmentOriginNameArray.filter(attachmentOriginName => attachmentOriginName === assembleFileName);
        const licensingFormOriginAttachmentName = filterOriginNameArray[0];
  
        fileIndex++;
        const file = {
          uid: fileIndex,
          name: licensingFormOriginAttachmentName,
          status: 'done',
          url: licensingFormAttachmentDownloadUrl + "/" + licensingFormAttachment,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: licensingFormAttachment,
              originalFileName: licensingFormOriginAttachmentName,
            }
          }
        };
        fileList.push(file);
      });
    }

    return {
      ...state,
      licensingFormEditControlModal: {
        ...state.licensingFormEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.licensingFormEditControlModal.fields,
          guid: licensingForm.guid,
          name: licensingForm.name,
          title: licensingForm.title,
          isActive: licensingForm.isActive,
          expiredDate: moment().add(3, "M").format('YYYY-MM-DD'),
          attachment: licensingForm.attachment,
          attachmentOriginName: licensingForm.attachmentOriginName,
          fileList,
          type: licensingForm.type,
        }
      },
    };
  }
}

function resetLicensingFormEditModalDialog(state) {
  return {
    ...state,
    licensingFormEditControlModal: initialState.licensingFormEditControlModal,
  };
}

// Create LicensingForm
function startCreateLicensingForm(state) {
  return {
    ...state,
    createLicensingFormActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createLicensingFormSuccess(state, resp) {
  return {
    ...state,
    createLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createLicensingFormFailure(state, err) {
  return {
    ...state,
    createLicensingFormActionStatus: ACTION_STATUS.ERROR,
    licensingFormEditControlModal: {
      ...state.licensingFormEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initCreateLicensingFormActionStatus(state) {
  return {
    ...state,
    createLicensingFormActionStatus: ACTION_STATUS.READY,
  };
}

// Update LicensingForm
function startUpdateLicensingForm(state) {
  return {
    ...state,
    updateLicensingFormActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateLicensingFormSuccess(state, resp) {
  return {
    ...state,
    updateLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateLicensingFormFailure(state, err) {
  return {
    ...state,
    updateLicensingFormActionStatus: ACTION_STATUS.ERROR,
    licensingFormEditControlModal: {
      ...state.licensingFormEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initUpdateLicensingFormActionStatus(state) {
  return {
    ...state,
    updateLicensingFormActionStatus: ACTION_STATUS.READY,
  };
}

// Copy LicensingForm
function startCopyLicensingForm(state) {
  return {
    ...state,
    copyLicensingFormActionStatus: ACTION_STATUS.LOGINING,
  };
}

function copyLicensingFormSuccess(state, resp) {
  return {
    ...state,
    copyLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function copyLicensingFormFailure(state, err) {
  return {
    ...state,
    copyLicensingFormActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCopyLicensingFormActionStatus(state) {
  return {
    ...state,
    copyLicensingFormActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete LicensingForm
function startBatchDeleteLicensingForm(state) {
  return {
    ...state,
    batchDeleteLicensingFormActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteLicensingFormSuccess(state, resp) {
  return {
    ...state,
    batchDeleteLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteLicensingFormFailure(state, err) {
  return {
    ...state,
    batchDeleteLicensingFormActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteLicensingFormActionStatus(state) {
  return {
    ...state,
    batchDeleteLicensingFormActionStatus: ACTION_STATUS.READY,
  };
}

// LicensingForm Edit Change Input
export function licensingFormEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.licensingFormEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    licensingFormEditControlModal: {
      ...state.licensingFormEditControlModal,
      fields: updatedFields,
    },
  };
}

// ================================================================================
// Open/Reset Question Edit Modal Dialog
function openQuestionEditModalDialog(state, {isAddMode, question}) {
  if (isAddMode) {
    return {
      ...state,
      questionEditControlModal: {
        ...state.questionEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.questionEditControlModal.fields,
        }
      },
    };
  } else {
    const licensingFormGuid = question.licensingFormGuid;

    const licensingFormArray = state.licensingFormArray;
    let parentQuestionOptions = [];

    const filterLicensingFormArray = licensingFormArray.filter(licensingForm => licensingForm.guid === licensingFormGuid);
    if (filterLicensingFormArray.length > 0) {
      const licensingForm = filterLicensingFormArray[0];
      const questionArray = licensingForm.questionList.filter(question => question.parentGuid === null || question.parentGuid === '');
      parentQuestionOptions = questionArray.map(question => <Option key={question.guid}>{question.questionNumber}</Option>);
    }

    // 处理附件列表
    const licensingFormAttachmentDownloadUrl = state.licensingFormAttachmentDownloadUrl;

    const fileList = [];
    const attachment = question.attachment;
    const attachmentOriginName = question.attachmentOriginName;

    if (!!attachment && !!attachmentOriginName) {
      const attachmentArray = attachment.split(',');
      const attachmentOriginNameArray = attachmentOriginName.split(',');
  
      for(let i in attachmentArray) {
        const file = {
          uid: (i+1),
          name: attachmentOriginNameArray[i],
          status: 'done',
          url: licensingFormAttachmentDownloadUrl + "/" + attachmentArray[i],
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: attachmentArray[i],
              originalFileName: attachmentOriginNameArray[i],
            }
          }
        };
        fileList.push(file);
      }

    }

    return {
      ...state,
      parentQuestionOptions,
      questionEditControlModal: {
        ...state.questionEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.questionEditControlModal.fields,
          guid: question.guid,
          licensingFormGuid: question.questionnaireGuid,
          questionNumber: question.questionNumber,
          subQuestionNumber: question.subQuestionNumber,
          questionEnglish: question.questionEnglish,
          questionChinese: question.questionChinese,
          options: question.options,
          questionType: question.questionType,
          parentGuid: question.parentGuid,
          triggerOption: question.triggerOption,
          correctAnswer: question.correctAnswer,
          score: question.score,
          attachment: question.attachment,
          attachmentOriginName: question.attachmentOriginName,
          fileList,
        }
      },
    };
  }
}

function resetQuestionEditModalDialog(state) {
  return {
    ...state,
    questionEditControlModal: initialState.questionEditControlModal,
  };
}

// Create Question
function startCreateQuestion(state) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createQuestionSuccess(state, resp) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createQuestionFailure(state, err) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.ERROR,
    questionEditControlModal: {
      ...state.questionEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initCreateQuetsionActionStatus(state) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.READY,
  };
}

// Update Question
function startUpdateQuestion(state) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateQuestionSuccess(state, resp) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateQuestionFailure(state, err) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.ERROR,
    questionEditControlModal: {
      ...state.questionEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initUpdateQuestionActionStatus(state) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Question
function startBatchDeleteQuestion(state) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteQuestionSuccess(state, resp) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteQuestionFailure(state, err) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initbatchDeleteQuestionsActionStatus(state) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.READY,
  };
}

// Question Edit Change Input
function questionEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.questionEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    questionEditControlModal: {
      ...state.questionEditControlModal,
      fields: updatedFields,
    },
  };
}

// LicensingForm Option Change
function licensingFormOptionChange(state, { licensingFormGuid }) {
  const licensingFormArray = state.licensingFormArray;
  let parentQuestionOptions = [];


  const filterLicensingFormArray = licensingFormArray.filter(licensingForm => licensingForm.guid === licensingFormGuid);
  if (filterLicensingFormArray.length > 0) {
    const licensingForm = filterLicensingFormArray[0];
    const questionArray = licensingForm.questionList.filter(question => question.parentGuid === null || question.parentGuid === '');
    parentQuestionOptions = questionArray.map(question => <Option key={question.guid}>{question.questionNumber}</Option>);
  }

  return {
    ...state,
    parentQuestionOptions,
  };
}


// Load LicensingForm Results
function startLoadLicensingFormResults(state) {
  return {
    ...state,
    loadLicensingFormResultsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadLicensingFormResultsSuccess(state, resp) {
  return {
    ...state,
    loadLicensingFormResultsActionStatus: ACTION_STATUS.SUCCESS,
    licensingFormResultArray: resp.data,
  };
}

function loadLicensingFormResultsFailure(state, err) {
  return {
    ...state,
    loadLicensingFormResultsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadLicensingFormResultsActionStatus(state) {
  return {
    ...state,
    loadLicensingFormResultsActionStatus: ACTION_STATUS.READY,
    selectedLicensingFormResults: [],
  };
}

// Select LicensingForm Results
function selectLicensingFormResults(state, payload) {
  return {
    ...state,
    selectedLicensingFormResults: payload,
  };
}

// Load LicensingForm Result Detail
function startLoadLicensingFormResultDetail(state) {
  return {
    ...state,
    loadLicensingFormResultDetailActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadLicensingFormResultDetailSuccess(state, resp) {
  const questionAnswerList = resp.data.questionAnswerList;
  const licensingFormAttachmentDownloadUrl = state.licensingFormAttachmentDownloadUrl;

  questionAnswerList.forEach(questionAnswer => {
    questionAnswer.fileList = [];
    if (questionAnswer.questionType === QUESTION_TYPE_CODE.UPLOAD && questionAnswer.answerContent !== null && questionAnswer.answerContent !== '') {
      const answerArray = questionAnswer.answerContent.split(',');
      questionAnswer.fileList = answerArray.map((answerFile, index) => {

        return {
          uid: index,
          name: answerFile,
          status: 'done',
          url: licensingFormAttachmentDownloadUrl + "/" + answerFile,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: answerFile,
            }
          }
        };
      });
    }

    if (questionAnswer.subQuestionAnswerList === null) {
      questionAnswer.subQuestionAnswerList = [];
    }

    questionAnswer.subQuestionAnswerList.forEach(subQuestionAnswer => {
      subQuestionAnswer.fileList = [];
      if (subQuestionAnswer.questionType === QUESTION_TYPE_CODE.UPLOAD && subQuestionAnswer.answerContent !== null && subQuestionAnswer.answerContent !== '') {
        const answerArray = subQuestionAnswer.answerContent.split(',');

        subQuestionAnswer.fileList = answerArray.map((answerFile, index) => {
          return {
            uid: index,
            name: answerFile,
            status: 'done',
            url: licensingFormAttachmentDownloadUrl + "/" + answerFile,
            response: {
              respCode: "S0001",
              data: {
                fileNameWithTimeStamp: answerFile,
              }
            }
          };
        });
      }
    });
  });

  return {
    ...state,
    loadLicensingFormResultDetailActionStatus: ACTION_STATUS.SUCCESS,
    licensingFormResultDetail: resp.data,
    questionAnswerList,
  };
}

function loadLicensingFormResultDetailFailure(state, err) {
  return {
    ...state,
    loadLicensingFormResultDetailActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadLicensingFormResultDetailActionStatus(state) {
  return {
    ...state,
    loadLicensingFormResultDetailActionStatus: ACTION_STATUS.READY,
  };
}

// Accept LicensingForm Result
function startAcceptLicensingFormResult(state) {
  return {
    ...state,
    acceptLicensingFormResultActionStatus: ACTION_STATUS.LOGINING,
  };
}

function acceptLicensingFormResultSuccess(state, resp) {
  return {
    ...state,
    acceptLicensingFormResultActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function acceptLicensingFormResultFailure(state, err) {
  return {
    ...state,
    acceptLicensingFormResultActionStatus: ACTION_STATUS.ERROR,
    licensingFormEmailEditControlModal: {
      ...state.licensingFormEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initAcceptLicensingFormResultActionStatus(state) {
  return {
    ...state,
    acceptLicensingFormResultActionStatus: ACTION_STATUS.READY,
  };
}

// Reject LicensingForm Result
function startRejectLicensingFormResult(state) {
  return {
    ...state,
    rejectLicensingFormResultActionStatus: ACTION_STATUS.LOGINING,
  };
}

function rejectLicensingFormResultSuccess(state, resp) {
  return {
    ...state,
    rejectLicensingFormResultActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function rejectLicensingFormResultFailure(state, err) {
  return {
    ...state,
    rejectLicensingFormResultActionStatus: ACTION_STATUS.ERROR,
    licensingFormEmailEditControlModal: {
      ...state.licensingFormEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initRejectLicensingFormResultActionStatus(state) {
  return {
    ...state,
    rejectLicensingFormResultActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset LicensingForm Result Operate Modal Dialog
function openLicensingFormResultOperateModalDialog(state, { opMode, licensingFormResultGuidList }) {
  return {
    ...state,
    licensingFormResultOperateControlModal: {
      ...state.licensingFormResultOperateControlModal,
      isOpened: true,
      opMode,
      licensingFormResultGuidList,
    },
  };
}

function resetLicensingFormResultOperateModalDialog(state) {
  return {
    ...state,
    licensingFormResultOperateControlModal: initialState.licensingFormResultOperateControlModal,
  };
}

// LicensingForm Answer Submitter Edit Change Input
function licensingFormAnswerSubmitterEditChangeInput(state, value) {
  return {
    ...state,
    licensingFormSubmitter: value,
  };
}

// Send Remind Email
function startSendRemindEmail(state) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.LOGINING,
  };
}

function sendRemindEmailSuccess(state, resp) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function sendRemindEmailFailure(state, err) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.ERROR,
    licensingFormEmailEditControlModal: {
      ...state.licensingFormEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initSendRemindEmailActionStatus(state) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset LicensingForm Email Edit Modal Dialog
function openLicensingFormEmailEditModalDialog(state, {sendMode, emailPayload}) {
  return {
    ...state,
    licensingFormEmailEditControlModal: {
      ...state.licensingFormEmailEditControlModal,
      isOpened: true,
      sendMode,
      emailPayload,
    },
  };
}

function resetLicensingFormEmailEditModalDialog(state) {
  return {
    ...state,
    licensingFormEmailEditControlModal: initialState.licensingFormEmailEditControlModal,
  };
}

// Update LicensingForm Score
function startUpdateLicensingFormScore(state) {
  return {
    ...state,
    updateLicensingFormScoreActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateLicensingFormScoreSuccess(state, resp) {
  return {
    ...state,
    updateLicensingFormScoreActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateLicensingFormScoreFailure(state, err) {
  return {
    ...state,
    updateLicensingFormScoreActionStatus: ACTION_STATUS.ERROR,
    updateLicensingFormScoreErrMsg: err.respMessage,
  };
}

function initUpdateLicensingFormScoreActionStatus(state) {
  return {
    ...state,
    updateLicensingFormScoreActionStatus: ACTION_STATUS.READY,
  };
}

// Calculate LicensingForm Total Score
function calculateLicensingFormTotalScore(state) {
  const licensingFormResultArray = state.licensingFormResultArray;

  licensingFormResultArray.forEach(licensingFormResult => {
    const questionAnswerList = licensingFormResult.questionAnswerList;

    const existNaNScoreList = questionAnswerList.filter(questionAnswer => 
      questionAnswer.score === null || Number.isNaN(Number(questionAnswer.score)) || !Number.isInteger(Number(questionAnswer.score)));
    if (existNaNScoreList.length > 0) {
      return;
    }

    let totalScore = questionAnswerList.map(questionAnswer => questionAnswer.score).reduce((total, score) => Number.parseInt(total) + Number.parseInt(score));

    licensingFormResult.totalScore = totalScore;
  });

  return {
    ...state,
    licensingFormResultArray,
  };
}

// Open/Reset Submitted LicensingForm History Modal Dialog
function openSubmittedLicensingFormHistoryModalDialog(state, { authToken }) {
  return {
    ...state,
    submittedLicensingFormHistoryControlModal: {
      ...state.submittedLicensingFormHistoryControlModal,
      isOpened: true,
      authToken,
    },
  };
}

function resetSubmittedLicensingFormHistoryModalDialog(state) {
  return {
    ...state,
    submittedLicensingFormHistoryControlModal: initialState.submittedLicensingFormHistoryControlModal,
  };
}

// Load Submitted LicensingForm History
function startLoadSubmittedLicensingFormHistory(state) {
  return {
    ...state,
    loadSubmittedLicensingFormHistoryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadSubmittedLicensingFormHistorySuccess(state, resp) {
  return {
    ...state,
    loadSubmittedLicensingFormHistoryActionStatus: ACTION_STATUS.SUCCESS,
    submittedLicensingFormHistoryArray: resp.data,
  };
}

function loadSubmittedLicensingFormHistoryFailure(state, err) {
  return {
    ...state,
    loadSubmittedLicensingFormHistoryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadSubmittedLicensingFormHistoryActionStatus(state) {
  return {
    ...state,
    loadSubmittedLicensingFormHistoryActionStatus: ACTION_STATUS.READY,
  };
}

// Select Submitted LicensingForm History
function selectSubmittedLicensingFormHistory(state, payload) {
  return {
    ...state,
    selectSubmittedLicensingFormHistoryArray: payload,
  };
}

// Apply Submitted LicensingForm History
function applySubmittedLicensingFormHistory(state, { historyLicensingForm }) {
  const todoQuestionArray = state.todoQuestionArray;

  if (!!historyLicensingForm || !!historyLicensingForm.historyQuestionAnswerList || 
    historyLicensingForm.historyQuestionAnswerList.length !== 0) {

    todoQuestionArray.forEach(todoQuestion => {

      const filterHistoryQuestionList = historyLicensingForm.historyQuestionAnswerList.filter(questionAnswer => {
        return todoQuestion.questionType !== '5' && // 不包括上传题
          questionAnswer.questionNumber === todoQuestion.questionNumber &&
          questionAnswer.subQuestionNumber === todoQuestion.subQuestionNumber &&
          questionAnswer.questionEnglish === todoQuestion.questionEnglish &&
          questionAnswer.questionChinese === todoQuestion.questionChinese &&
          questionAnswer.questionType === todoQuestion.questionType &&
          questionAnswer.score === todoQuestion.score &&
          (questionAnswer.correctAnswer === null ? '' : questionAnswer.correctAnswer) === (todoQuestion.correctAnswer === null ? '' : todoQuestion.correctAnswer) &&
          (questionAnswer.options === null ? '' : questionAnswer.options) === (todoQuestion.optionsContent === null ? '' : todoQuestion.optionsContent) &&
          (questionAnswer.triggerOption === null ? '' : questionAnswer.triggerOption) === (todoQuestion.triggerOption === null ? '' : todoQuestion.triggerOption);
      });

      if (filterHistoryQuestionList.length > 0) {
        const historyQuestionAnswer = filterHistoryQuestionList[0];
        todoQuestion.answerContent = historyQuestionAnswer.answerContent;
      }

      // 子问题
      todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => {
        const filterHistoryQuestionList = historyLicensingForm.historyQuestionAnswerList.filter(questionAnswer => {
          return subTodoQuestion.questionType !== '5' && // 不包括上传题
            questionAnswer.questionNumber === subTodoQuestion.questionNumber &&
            questionAnswer.subQuestionNumber === subTodoQuestion.subQuestionNumber &&
            questionAnswer.questionEnglish === subTodoQuestion.questionEnglish &&
            questionAnswer.questionChinese === subTodoQuestion.questionChinese &&
            questionAnswer.questionType === subTodoQuestion.questionType &&
            questionAnswer.score === subTodoQuestion.score &&
            (questionAnswer.correctAnswer === null ? '' : questionAnswer.correctAnswer) === (subTodoQuestion.correctAnswer === null ? '' : subTodoQuestion.correctAnswer) &&
            (questionAnswer.options === null ? '' : questionAnswer.options) === (subTodoQuestion.optionsContent === null ? '' : subTodoQuestion.optionsContent) &&
            (questionAnswer.triggerOption === null ? '' : questionAnswer.triggerOption) === (subTodoQuestion.triggerOption === null ? '' : subTodoQuestion.triggerOption);
        });
  
        if (filterHistoryQuestionList.length > 0) {
          const historyQuestionAnswer = filterHistoryQuestionList[0];
          subTodoQuestion.answerContent = historyQuestionAnswer.answerContent;
        }
      });
    });
  }

  return {
    ...state,
    todoQuestionArray,
  };
}


export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  // Load LicensingForm LicensingForm
  [START_LOAD_LICENSINGFORM]: startLoadLicensingForm,
  [LOAD_LICENSINGFORM_SUCCESS]: loadLicensingFormSuccess,
  [LOAD_LICENSINGFORM_FAILURE]: loadLicensingFormFailure,
  [INIT_LOAD_LICENSINGFORM_ACTION_STATUS]: initLoadLicensingFormActionStatus,

  // Select LicensingForm
  [SELECT_LICENSINGFORM]: selectLicensingForm,

  // Load Question
  [START_LOAD_QUESTIONS]: startLoadQuestions,
  [LOAD_QUESTIONS_SUCCESS]: loadQuestionsSuccess,
  [LOAD_QUESTIONS_FAILURE]: loadQuestionsFailure,
  [INIT_LOAD_QUESTIONS_ACTION_STATUS]: initLoadQuestionsActionStatus,

  // Select Questions
  [SELECT_QUESTIONS]: selectQuestions,

  // Open/Reset Answeree Select Modal Dialog
  [ANSWEREE_SELECT_MODAL_DIALOG_OPEN]: openAnswereeSelectModalDialog,
  [ANSWEREE_SELECT_MODAL_DIALOG_RESET]: resetAnswereeSelectModalDialog,

  // Select All Answeree
  [SELECT_ALL_ANSWEREE]: selectAllAnsweree,

  // Select Invert Answeree
  [SELECT_INVERT_ANSWEREE]: selectInvertAnsweree,

  // Answeree Check Changed
  [ANSWEREE_CHECK_CHANGED]: answereeCheckChanged,

  // Select Answerees
  [START_SELECT_ANSWEREES]: startSelectAnswerees,
  [SELECT_ANSWEREES_SUCCESS]: selectAnswereesSuccess,
  [SELECT_ANSWEREES_FAILURE]: selectAnswereesFailure,
  [INIT_SELECT_ANSWEREES_ACTION_STATUS]: initSelectAnswereesActionStatus,


  // Load Answers
  [START_LOAD_ANSWERS]: startLoadAnswers,
  [LOAD_ANSWERS_SUCCESS]: loadAnswersSuccess,
  [LOAD_ANSWERS_FAILURE]: loadAnswersFailure,
  [INIT_LOAD_ANSWERS_ACTION_STATUS]: initLoadAnswersActionStatus,


  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  // Load To-do LicensingForm
  [START_LOAD_TODO_LICENSINGFORM]: startLoadTodoLicensingForm,
  [LOAD_TODO_LICENSINGFORM_SUCCESS]: loadTodoLicensingFormSuccess,
  [LOAD_TODO_LICENSINGFORM_FAILURE]: loadTodoLicensingFormFailure,
  [INIT_LOAD_TODO_LICENSINGFORM_ACTION_STATUS]: initLoadTodoLicensingFormActionStatus,

  // Submit LicensingForm Answer
  [START_SUBMIT_LICENSINGFORM_ANSWER]: startSubmitLicensingFormAnswer,
  [SUBMIT_LICENSINGFORM_ANSWER_SUCCESS]: submitLicensingFormAnswerSuccess,
  [SUBMIT_LICENSINGFORM_ANSWER_FAILURE]: submitLicensingFormAnswerFailure,
  [INIT_SUBMIT_LICENSINGFORM_ANSWER_ACTION_STATUS]: initSubmitLicensingFormAnswerActionStatus,

  // Save LicensingForm Answer
  [START_SAVE_LICENSINGFORM_ANSWER]: startSaveLicensingFormAnswer,
  [SAVE_LICENSINGFORM_ANSWER_SUCCESS]: saveLicensingFormAnswerSuccess,
  [SAVE_LICENSINGFORM_ANSWER_FAILURE]: saveLicensingFormAnswerFailure,
  [INIT_SAVE_LICENSINGFORM_ANSWER_ACTION_STATUS]: initSaveLicensingFormAnswerActionStatus,

  // LicensingForm Answer Change Input
  [LICENSINGFORM_ANSWER_CHANGE_INPUT]: licensingFormAnswerChangeInput,

  // ================================================================================
  // Open/Reset LicensingForm Edit Modal Dialog
  [LICENSINGFORM_EDIT_MODAL_DIALOG_OPEN]: openLicensingFormEditModalDialog,
  [LICENSINGFORM_EDIT_MODAL_DIALOG_RESET]: resetLicensingFormEditModalDialog,

  // Create LicensingForm
  [START_CREATE_LICENSINGFORM]: startCreateLicensingForm,
  [CREATE_LICENSINGFORM_SUCCESS]: createLicensingFormSuccess,
  [CREATE_LICENSINGFORM_FAILURE]: createLicensingFormFailure,
  [INIT_CREATE_LICENSINGFORM_ACTION_STATUS]: initCreateLicensingFormActionStatus,

  // Update LicensingForm
  [START_UPDATE_LICENSINGFORM]: startUpdateLicensingForm,
  [UPDATE_LICENSINGFORM_SUCCESS]: updateLicensingFormSuccess,
  [UPDATE_LICENSINGFORM_FAILURE]: updateLicensingFormFailure,
  [INIT_UPDATE_LICENSINGFORM_ACTION_STATUS]: initUpdateLicensingFormActionStatus,

  // Copy LicensingForm
  [START_COPY_LICENSINGFORM]: startCopyLicensingForm,
  [COPY_LICENSINGFORM_SUCCESS]: copyLicensingFormSuccess,
  [COPY_LICENSINGFORM_FAILURE]: copyLicensingFormFailure,
  [INIT_COPY_LICENSINGFORM_ACTION_STATUS]: initCopyLicensingFormActionStatus,

  // Delete LicensingForm
  [START_BATCH_DELETE_LICENSINGFORM]: startBatchDeleteLicensingForm,
  [BATCH_DELETE_LICENSINGFORM_SUCCESS]: batchDeleteLicensingFormSuccess,
  [BATCH_DELETE_LICENSINGFORM_FAILURE]: batchDeleteLicensingFormFailure,
  [INIT_BATCH_DELETE_LICENSINGFORM_ACTION_STATUS]: initBatchDeleteLicensingFormActionStatus,

  // LicensingForm Answer Change Input
  [LICENSINGFORM_EDIT_CHANGE_INPUT]: licensingFormEditChangeInput,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  [QUESTION_EDIT_MODAL_DIALOG_OPEN]: openQuestionEditModalDialog,
  [QUESTION_EDIT_MODAL_DIALOG_RESET]: resetQuestionEditModalDialog,

  // Create Question
  [START_CREATE_QUESTION]: startCreateQuestion,
  [CREATE_QUESTION_SUCCESS]: createQuestionSuccess,
  [CREATE_QUESTION_FAILURE]: createQuestionFailure,
  [INIT_CREATE_QUESTION_ACTION_STATUS]: initCreateQuetsionActionStatus,

  // Update Question
  [START_UPDATE_QUESTION]: startUpdateQuestion,
  [UPDATE_QUESTION_SUCCESS]: updateQuestionSuccess,
  [UPDATE_QUESTION_FAILURE]: updateQuestionFailure,
  [INIT_UPDATE_QUESTION_ACTION_STATUS]: initUpdateQuestionActionStatus,

  // Delete Question
  [START_BATCH_DELETE_QUESTION]: startBatchDeleteQuestion,
  [BATCH_DELETE_QUESTION_SUCCESS]: batchDeleteQuestionSuccess,
  [BATCH_DELETE_QUESTION_FAILURE]: batchDeleteQuestionFailure,
  [INIT_BATCH_DELETE_QUESTION_ACTION_STATUS]: initbatchDeleteQuestionsActionStatus,

  // Question Answer Change Input
  [QUESTION_EDIT_CHANGE_INPUT]: questionEditChangeInput,

  // LicensingForm Option Change
  [LICENSINGFORM_OPTION_CHANGE]: licensingFormOptionChange,

  // Load LicensingForm Results
  [START_LOAD_LICENSINGFORM_RESULTS]: startLoadLicensingFormResults,
  [LOAD_LICENSINGFORM_RESULTS_SUCCESS]: loadLicensingFormResultsSuccess,
  [LOAD_LICENSINGFORM_RESULTS_FAILURE]: loadLicensingFormResultsFailure,
  [INIT_LOAD_LICENSINGFORM_RESULTS_ACTION_STATUS]: initLoadLicensingFormResultsActionStatus,

  // Select LicensingForm Results
  [SELECT_LICENSINGFORM_RESULTS]: selectLicensingFormResults,

  // Load LicensingForm Result Detail
  [START_LOAD_LICENSINGFORM_RESULT_DETAIL]: startLoadLicensingFormResultDetail,
  [LOAD_LICENSINGFORM_RESULT_DETAIL_SUCCESS]: loadLicensingFormResultDetailSuccess,
  [LOAD_LICENSINGFORM_RESULT_DETAIL_FAILURE]: loadLicensingFormResultDetailFailure,
  [INIT_LOAD_LICENSINGFORM_RESULT_DETAIL_ACTION_STATUS]: initLoadLicensingFormResultDetailActionStatus,

  // Accept LicensingForm Result
  [START_ACCEPT_LICENSINGFORM_RESULT]: startAcceptLicensingFormResult,
  [ACCEPT_LICENSINGFORM_RESULT_SUCCESS]: acceptLicensingFormResultSuccess,
  [ACCEPT_LICENSINGFORM_RESULT_FAILURE]: acceptLicensingFormResultFailure,
  [INIT_ACCEPT_LICENSINGFORM_RESULT_ACTION_STATUS]: initAcceptLicensingFormResultActionStatus,

  // Reject LicensingForm Result
  [START_REJECT_LICENSINGFORM_RESULT]: startRejectLicensingFormResult,
  [REJECT_LICENSINGFORM_RESULT_SUCCESS]: rejectLicensingFormResultSuccess,
  [REJECT_LICENSINGFORM_RESULT_FAILURE]: rejectLicensingFormResultFailure,
  [INIT_REJECT_LICENSINGFORM_RESULT_ACTION_STATUS]: initRejectLicensingFormResultActionStatus,

  // Open/Reset LicensingForm Result Operate Modal Dialog
  [LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_OPEN]: openLicensingFormResultOperateModalDialog,
  [LICENSINGFORM_RESULT_OPERATE_MODAL_DIALOG_RESET]: resetLicensingFormResultOperateModalDialog,

  // LicensingForm Answer Submitter Edit Change Input
  [LICENSINGFORM_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT]: licensingFormAnswerSubmitterEditChangeInput,

  // Send Remind Email
  [START_SEND_REMIND_EMAIL]: startSendRemindEmail,
  [SEND_REMIND_EMAIL_SUCCESS]: sendRemindEmailSuccess,
  [SEND_REMIND_EMAIL_FAILURE]: sendRemindEmailFailure,
  [INIT_SEND_REMIND_EMAIL_ACTION_STATUS]: initSendRemindEmailActionStatus,

  // Open/Reset LicensingForm Email Edit Modal Dialog
  [LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_OPEN]: openLicensingFormEmailEditModalDialog,
  [LICENSINGFORM_EMAIL_EDIT_MODAL_DIALOG_RESET]: resetLicensingFormEmailEditModalDialog,

  // Update LicensingForm Score
  [START_UPDATE_LICENSINGFORM_SCORE]: startUpdateLicensingFormScore,
  [UPDATE_LICENSINGFORM_SCORE_SUCCESS]: updateLicensingFormScoreSuccess,
  [UPDATE_LICENSINGFORM_SCORE_FAILURE]: updateLicensingFormScoreFailure,
  [INIT_UPDATE_LICENSINGFORM_SCORE_ACTION_STATUS]: initUpdateLicensingFormScoreActionStatus,

  // Calculate LicensingForm Total Score
  [CALC_LICENSINGFORM_TOTAL_SCORE]: calculateLicensingFormTotalScore,

  // Open/Reset Submitted LicensingForm History Modal Dialog
  [SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_OPEN]: openSubmittedLicensingFormHistoryModalDialog,
  [SUBMITTED_LICENSINGFORM_HISTORY_MODAL_DIALOG_RESET]: resetSubmittedLicensingFormHistoryModalDialog,

  // Load Submitted LicensingForm History
  [START_LOAD_SUBMITTED_LICENSINGFORM_HISTORY]: startLoadSubmittedLicensingFormHistory,
  [LOAD_SUBMITTED_LICENSINGFORM_HISTORY_SUCCESS]: loadSubmittedLicensingFormHistorySuccess,
  [LOAD_SUBMITTED_LICENSINGFORM_HISTORY_FAILURE]: loadSubmittedLicensingFormHistoryFailure,
  [INIT_LOAD_SUBMITTED_LICENSINGFORM_HISTORY_ACTION_STATUS]: initLoadSubmittedLicensingFormHistoryActionStatus,

  // Select Submitted LicensingForm History
  [SELECT_SUBMITTED_LICENSINGFORM_HISTORY]: selectSubmittedLicensingFormHistory,

  // Apply Submitted LicensingForm History
  [APPLY_SUBMITTED_LICENSINGFORM_HISTORY]: applySubmittedLicensingFormHistory,

});