import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Button, message, Divider, Select, DatePicker } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import GiftHospitalityRequestGrid from './GiftHospitalityRequestGrid';
import GiftHospitalityRequestEditDialog from './GiftHospitalityRequestEditDialog';
import '../../ApprovalRequest.css';
import moment from 'moment';


class GiftHospitalityRequest extends Component {

  componentDidMount() {
    // queryString中的查询条件
    let searchStr = !!this.props.location ? this.props.location.search : null;
    let employee = null;
    let date = null;
    if(!!searchStr) {
        if(searchStr.indexOf('?')  >= 0) {
            searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
            const searchArray = searchStr.split('&');
            for (let i=0; i<searchArray.length; i++) {
                const subParamArray = searchArray[i].split("=");
                if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'employee') {
                  employee = subParamArray[1];
                  employee = employee.replace(/%20/g,' ');
                }
                if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'date') {
                  date = subParamArray[1];
                }
            }
        }
    }
    this.props.queryGiftHospitalityInputChange({ name: 'employee', value: employee });
    this.props.queryGiftHospitalityInputChange({ name: 'dateStart', value: date });
    this.props.queryGiftHospitalityInputChange({ name: 'dateEnd', value: date });
    const params = {employee: employee, dateStart: date, dateEnd: date};
    this.props.loadGiftHospitalityRequest(params);
  }

  componentDidUpdate() {
    const {
      approveGiftHospitalityRequestActionStatus,
      rejectGiftHospitalityRequestActionStatus,
    } = this.props;

    // Approve
    if (approveGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Current request are approved successfully!');
      this.refreshButtonOnClicked();
      this.props.initApproveGiftHospitalityRequestActionStatus();
      this.props.queryPendingTasksNum();  // approve, reject成功时要刷新PendingTask数量
    }

    // Approve
    if (approveGiftHospitalityRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Current request are approved failed!');
      this.props.initApproveGiftHospitalityRequestActionStatus();
    }

    // Reject
    if (rejectGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Current request are rejected successfully!');
      this.refreshButtonOnClicked();
      this.props.initRejectGiftHospitalityRequestActionStatus();
      this.props.queryPendingTasksNum();  // approve, reject成功时要刷新PendingTask数量
    }

    // Reject
    if (rejectGiftHospitalityRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Current request are rejected failed!');
      this.props.initRejectGiftHospitalityRequestActionStatus();
    }
  }

  approveButtonOnClicked = () => {
    const {
      selectedGiftHospitalityRequestArray
    } = this.props;

    if (selectedGiftHospitalityRequestArray.length === 0) {
      message.warning("Please select one gift hospitality request at least!");
      return;
    }
    
    const giftHospitalitiyIdArray = selectedGiftHospitalityRequestArray.map(giftHospitalitiy => giftHospitalitiy.id);

    this.props.approveGiftHospitalityRequest(giftHospitalitiyIdArray);
  }

  rejectButtonOnClicked = () => {
    const {
      selectedGiftHospitalityRequestArray
    } = this.props;

    if (selectedGiftHospitalityRequestArray.length === 0) {
      message.warning("Please select one gift hospitality request at least!");
      return;
    }

    const giftHospitalitiyIdArray = selectedGiftHospitalityRequestArray.map(giftHospitalitiy => giftHospitalitiy.id);

    this.props.rejectGiftHospitalityRequest(giftHospitalitiyIdArray);
  }

  feedbackButtonOnClicked = () => {
    const {
      selectedGiftHospitalityRequestArray
    } = this.props;

    if (selectedGiftHospitalityRequestArray.length !== 1) {
      message.warning("Please select one gift hospitality request!");
      return;
    }

    this.props.openGiftHospitalityRequestEditModalDialog(selectedGiftHospitalityRequestArray[0]);
  }

  refreshButtonOnClicked = () => {
    const {
      employee,
      dateStart,
      dateEnd,
    } = this.props.gitfHospitalitySearchFields;
    this.props.loadGiftHospitalityRequest({employee, dateStart, dateEnd});
  }


  // lastTimeoutID = null;
  // fuzzySearchEmployee = (value) => {
  //   if(!value) {
  //     this.props.clearEmployeeNameList('giftHospitality4Search');
  //   }
  //   if(value.length < 2) {
  //     return;  // 长度过短不查询
  //   }
  //   // 输入完毕等待0.5秒后再查询
  //   if(!!this.lastTimeoutID) {
  //     clearTimeout(this.lastTimeoutID);
  //   }
  //   this.lastTimeoutID = setTimeout(() => {
  //     this.props.fuzzySearchEmployee('giftHospitality4Search', value);
  //   }, 500);
  // }

  
  render() {
    const {
      approveGiftHospitalityRequestActionStatus,
      rejectGiftHospitalityRequestActionStatus,
      loadGiftHospitalityRequestActionStatus,
      gitfHospitalitySearchFields,
      userAccountOptions,
    } = this.props;

    const {
      employee,
      dateStart,
      dateEnd,
    } = gitfHospitalitySearchFields;

    const approveBtnIsLoading = approveGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
    const rejectBtnIsLoading = rejectGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
    
    return (
      <div className="giftHospitalityRequestWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Approval Request</Breadcrumb.Item>
            <Breadcrumb.Item>Gift/Hospitality Report</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <Select
                allowClear
                size='small'
                showSearch
                style={{ width: 150, marginLeft: 10 }}
                placeholder="Employee" 
                value={!employee ? undefined : employee}
                onChange={(val) => {
                    this.props.queryGiftHospitalityInputChange({ name: 'employee', value: val })
                }} >
                {userAccountOptions}
          </Select>
          <DatePicker 
              allowClear
              size='small'
              style={{marginLeft: 10, width: 150}}
              value={!dateStart ? null : moment(dateStart)}
              placeholder='Date Start'
              onChange={(date, dateString) => {
                this.props.queryGiftHospitalityInputChange({ name: 'dateStart', value: dateString });
              }} />
          <DatePicker 
              allowClear
              size='small'
              style={{marginLeft: 10, width: 150}}
              value={!dateEnd ? null : moment(dateEnd)}
              placeholder='Date End'
              onChange={(date, dateString) => {
                this.props.queryGiftHospitalityInputChange({ name: 'dateEnd', value: dateString });
              }} />
          <Button 
                onClick={this.refreshButtonOnClicked} 
                loading={loadBtnIsLoading}
                size='small'
                style={{ marginLeft: 10 }}
                type='primary'
                icon="search" 
            />

          <Divider type='vertical' />

          <Button 
            onClick={this.approveButtonOnClicked} 
            size='small'
            loading={approveBtnIsLoading}
            className='positive-button'>
            Approve
          </Button>

          <Button 
            onClick={this.rejectButtonOnClicked} 
            size='small'
            loading={rejectBtnIsLoading}
            className='negative-button'>
            Reject
          </Button>
          
          <Button 
            onClick={this.feedbackButtonOnClicked} 
            size='small'
            type='primary'>
            Feedback
          </Button>

        </div>

        <div className='giftHospitalityRequestGrid'>
          <GiftHospitalityRequestGrid {...this.props} />
        </div>

        <GiftHospitalityRequestEditDialog {...this.props} />
      </div>
    );
  }
}

export default GiftHospitalityRequest;