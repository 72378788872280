import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditAccountDialog extends Component {

    componentDidMount() {

    }


    componentDidUpdate() {
        const {
            addOrEditPersonalAccountModal,
            submitAddOrEditAccountActionStatus,
        } = this.props;
        if(submitAddOrEditAccountActionStatus === ACTION_STATUS.SUCCESS) {
            const action = this.getActionFromMode(addOrEditPersonalAccountModal.mode);
            message.success(action + ' Personal Account succeeded');
            this.props.initSubmitAddOrEditAccountActionStatus();
            this.props.resetAddOrEditAccountDialog();
            const {
                id,
                employee,
            } = this.props.personalAccountQueryFields;
            const param = {
                id,
                employee,
                approvalStatus: '',
                needHoldingData: 'true',
                source: 'Admin',
            }
            this.props.loadPersonalAccount(param);
        }
        if(submitAddOrEditAccountActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitAddOrEditAccountActionStatus();
        }
    }


    onAccountInputChange = (param) => {
        this.props.onAccountInputChange(param);
    }


    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else {
            return 'Edit';
        }
    }


    attachmentUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onAccountInputChange({name: 'attachementUploadList', value: []});
            this.onAccountInputChange({name: 'attachment', value: undefined});
            this.onAccountInputChange({name: 'attachmentOriginName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        this.onAccountInputChange({name: 'attachementUploadList', value: fileList});
        const file = fileList[0];
        if (!!file && !!file.response) {
          const filename = file.response.data.fileNameWithTimeStamp;
          const originalFileName = file.response.data.originalFileName;
          this.onAccountInputChange({name: 'attachment', value: filename});
          this.onAccountInputChange({name: 'attachmentOriginName', value: originalFileName});
        }
    }


    complianceLetterUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onAccountInputChange({name: 'complianceLetterUploadList', value: []});
            this.onAccountInputChange({name: 'complianceLetterFileName', value: undefined});
            this.onAccountInputChange({name: 'complianceLetterOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        this.onAccountInputChange({name: 'complianceLetterUploadList', value: fileList});
        const file = fileList[0];
        if (!!file && !!file.response) {
          const filename = file.response.data.fileNameWithTimeStamp;
          const originalFileName = file.response.data.originalFileName;
          this.onAccountInputChange({name: 'complianceLetterFileName', value: filename});
          this.onAccountInputChange({name: 'complianceLetterOriName', value: originalFileName});
        }
    }


    submitAddOrEditAccount = () => {
        const {
            mode,
            fields,
        } = this.props.addOrEditPersonalAccountModal;
        const {
            id,
            guid,
            employee,
            accountName,
            brokerAccount,
            accountNo,
            relationship,
            approvalStatus,
            feedback,
            processedReason,
            relationshipDesc,
            attachment,
            attachmentOriginName,
            source,
            isAbandoned,
            needComplianceLetter,
            complianceLetterFileName,
            complianceLetterOriName,
            comments,
            withSubmit,
            attachementUploadList,
            complianceLetterUploadList,
        } = fields;
        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // Admin端新增记录时，或者修改FinalApproved状态的记录时，accountNo必填
            if(mode === 'ADD' && !accountNo) {
                const err = {respCode: 'F9999', respMessage: 'accountNo is required when Add a Personal Account from Admin End'};
                this.props.submitAddOrEditAccountFailure(err);
                return;
            }
            if(mode === 'EDIT' && (approvalStatus === 'FinalApproved' || approvalStatus === 'FinalApprRequest') && !accountNo) {
                const err = {respCode: 'F9999', respMessage: 'accountNo is required when Edit a Personal Account in FinalApproved or FinalApprRequest status from Admin End'};
                this.props.submitAddOrEditAccountFailure(err);
                return;
            }
            this.props.submitAddOrEditAccount({
                id,
                guid,
                employee,
                accountName,
                brokerAccount,
                accountNo,
                relationship,
                approvalStatus,
                feedback,
                processedReason,
                relationshipDesc,
                attachment,
                attachmentOriginName,
                source: 'Admin',
                isAbandoned,
                needComplianceLetter,
                complianceLetterFileName,
                complianceLetterOriName,
                comments,
                withSubmit: 'false',
                mode,
            });
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            userAccountOptions,
            accountNameOptions,
            brokerAccountOptions,
            accountNoOptions,
            addOrEditPersonalAccountModal,
            relationshipOptions,
            personalAccountUploadUrl,
            submitAddOrEditAccountActionStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
        } = addOrEditPersonalAccountModal;

        const {
            id,
            guid,
            employee,
            accountName,
            brokerAccount,
            accountNo,
            relationship,
            approvalStatus,
            feedback,
            processedReason,
            relationshipDesc,
            attachment,
            attachmentOriginName,
            source,
            isAbandoned,
            needComplianceLetter,
            complianceLetterFileName,
            complianceLetterOriName,
            comments,
            withSubmit,
            attachementUploadList,
            complianceLetterUploadList,
        } = fields;

        const modalTitle = this.getActionFromMode(mode) + ' Personal Account';
        const isSubmitLoading = submitAddOrEditAccountActionStatus === ACTION_STATUS.LOGINING;
        const isAddMode = mode === 'ADD';


        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditAccountDialog()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetAddOrEditAccountDialog() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditAccount() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="employee">
                                {getFieldDecorator('employee', {
                                    initialValue: employee,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose employee',
                                    }],
                                    })(
                                    <Select
                                        allowClear
                                        showSearch
                                        disabled={!isAddMode}
                                        onChange={(val) => {
                                            this.onAccountInputChange({ name: 'employee', value: val })
                                            this.props.loadAccountOptionsForAddOrEdit(val);
                                        }} 
                                        placeholder='employee'
                                        style={{ width: 300, marginLeft: 10 }}
                                    >
                                        {userAccountOptions}
                                    </Select>
                                )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="accountHolder">
                                {getFieldDecorator('accountName', {
                                    initialValue: accountName,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose or input accountHolder',
                                    }],
                                    })(
                                        <AutoComplete
                                            dataSource={accountNameOptions}
                                            onChange={(val) => {
                                                this.onAccountInputChange({ name: 'accountName', value: val })
                                            }}
                                            placeholder='accountHolder'
                                            style={{ width: 300, marginLeft: 10 }}
                                        />
                                )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="brokerageFirm">
                                {getFieldDecorator('brokerAccount', {
                                    initialValue: brokerAccount,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose or input brokerageFirm',
                                    }],
                                    })(
                                        <AutoComplete
                                            dataSource={brokerAccountOptions}
                                            onChange={(val) => {
                                                this.onAccountInputChange({ name: 'brokerAccount', value: val })
                                            }}
                                            placeholder='brokerageFirm'
                                            style={{ width: 300, marginLeft: 10 }}
                                        />
                                )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="accountNo">
                                {getFieldDecorator('accountNo', {
                                    initialValue: accountNo,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                        <AutoComplete
                                            dataSource={accountNoOptions}
                                            onChange={(val) => {
                                                this.onAccountInputChange({ name: 'accountNo', value: val })
                                            }}
                                            placeholder='accountNo'
                                            style={{ width: 300, marginLeft: 10 }}
                                        />
                                )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="relationship">
                                {getFieldDecorator('relationship', {
                                    initialValue: relationship,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose relationship',
                                    }],
                                    })(
                                        <Select 
                                            allowClear
                                            placeholder='relationship' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onAccountInputChange({ name: 'relationship', value: val })
                                        }}>
                                            {relationshipOptions}
                                        </Select>
                                )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="needComplianceLetter">
                                {getFieldDecorator('needComplianceLetter', {
                                    initialValue: needComplianceLetter,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose needComplianceLetter',
                                    }],
                                    })(
                                        <Select 
                                            allowClear
                                            placeholder='needComplianceLetter' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onAccountInputChange({ name: 'needComplianceLetter', value: val })
                                        }}>
                                            <Option key={'Yes'}>{'Yes'}</Option>
                                            <Option key={'No'}>{'No'}</Option>
                                        </Select>
                                )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="attachment">
                                    {getFieldDecorator('attachment', {
                                        initialValue: attachment,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload Account Proof',
                                        }],
                                        })(
                                        <Upload
                                            name="attachment"
                                            withCredentials={true}
                                            multiple={false}
                                            fileList={attachementUploadList}
                                            action={personalAccountUploadUrl}
                                            onChange={this.attachmentUploadChange}
                                        >
                                            <Button style={{ width: 300, marginLeft: 10 }}>
                                                <Icon type="upload" />Please upload Account Proof
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="complianceLetter">
                                    {getFieldDecorator('complianceLetterFileName', {
                                        initialValue: complianceLetterFileName,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload Compliance Letter',
                                        }],
                                        })(
                                        <Upload
                                            name="complianceLetterFileName"
                                            withCredentials={true}
                                            multiple={false}
                                            fileList={complianceLetterUploadList}
                                            action={personalAccountUploadUrl}
                                            onChange={this.complianceLetterUploadChange}
                                        >
                                            <Button style={{ width: 300, marginLeft: 10 }}>
                                                <Icon type="upload" />Please upload Compliance Letter
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="reasonForOpening">
                                    {getFieldDecorator('processedReason', {
                                        initialValue: processedReason,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='reasonForOpening' 
                                            onChange={(e) => {
                                                this.onAccountInputChange({ name: 'processedReason', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('relationshipDesc', {
                                        initialValue: relationshipDesc,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='remarks' 
                                            onChange={(e) => {
                                                this.onAccountInputChange({ name: 'relationshipDesc', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="feedback">
                                    {getFieldDecorator('feedback', {
                                        initialValue: feedback,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='feedback' 
                                            onChange={(e) => {
                                                this.onAccountInputChange({ name: 'feedback', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='comments' 
                                            onChange={(e) => {
                                                this.onAccountInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}


const AddOrEditAccountDialogForm = Form.create({ name: 'AddOrEditAccountDialog' })(AddOrEditAccountDialog);
export default AddOrEditAccountDialogForm;
