import {connect} from 'react-redux';
import TradesReviewDashboard from '../components/TradesReviewDashboard';

import {
    // search change input
    tradesReviewSearchChangeInput,

    // do query
    queryTradesReview,
    initQueryTradesReviewActionStatus,
    queryTradesReviewReport,
    initQueryTradesReviewReportActionStatus,

    // add grid to container
    addTradesReviewGridToProps,
    addTradesReviewReportGridToProps,

} from '../tradesReviewActions';


const mapStateToProps = state => {
    return {
        // search change input
        tradesReviewSearchFields: state.tradesReview.tradesReviewSearchFields,

        // do query
        tradesReviewSearchResultGridType: state.tradesReview.tradesReviewSearchResultGridType,
        queryTradesReviewActionStatus: state.tradesReview.queryTradesReviewActionStatus,
        queryTradesReviewActionResp: state.tradesReview.queryTradesReviewActionResp,
        queryTradesReviewReportActionStatus: state.tradesReview.queryTradesReviewReportActionStatus,
        queryTradesReviewReportActionResp: state.tradesReview.queryTradesReviewReportActionResp,

        // grid
        tradesReviewGridColumns: state.tradesReview.tradesReviewGridColumns,
        tradesReviewReportGridColumns: state.tradesReview.tradesReviewReportGridColumns,

        // add grid to container
        tradesReviewGrid: state.tradesReview.tradesReviewGrid,
        tradesReviewReportGrid: state.tradesReview.tradesReviewReportGrid,

    };
} 
  

const mapDispatchToProps = dispatch => {
    return {
        // search change input
        tradesReviewSearchChangeInput: (input) => dispatch(tradesReviewSearchChangeInput(input)),

        // do query
        queryTradesReview: (params) => dispatch(queryTradesReview(params)),
        initQueryTradesReviewActionStatus: () => dispatch(initQueryTradesReviewActionStatus()),
        queryTradesReviewReport: (params) => dispatch(queryTradesReviewReport(params)),
        initQueryTradesReviewReportActionStatus: () => dispatch(initQueryTradesReviewReportActionStatus()),

        // add grid to container
        addTradesReviewGridToProps: (grid) => dispatch(addTradesReviewGridToProps(grid)),
        addTradesReviewReportGridToProps: (grid) => dispatch(addTradesReviewReportGridToProps(grid)),

    };
}
  

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradesReviewDashboard);
