import React, {Component} from 'react';
import BaseUrlConfig from '../../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../../net/UrlConfig';
import { Button, message, Breadcrumb, Divider, Select, DatePicker, Tabs } from 'antd';
import moment from 'moment';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedSecurityGrid from './RestrictedSecurityRequestGrid';
import RestrictedSecurityOperateDialog from './RestrictedSecurityOperateDialog';
import RestrictedSecurityOpRecordDialog from './RestrictedSecurityOpRecordDialog';
import RestrictedSecurityRequestEditDialog from './RestrictedSecurityRequestEditDialog';
import RestrictedSecurityOperateMode from '../../../../../utils/RestrictedSecurityOperateMode';
import RestrictedSecurityApprovalStatus from '../../../../../utils/RestrictedSecurityApprovalStatus';
import '../../ComplianceRule.css';
import HistoryUploadDialog from '../../../common/historyUpload/components/HistoryUploadDialog';
import RestrictedSecurityRuleGrid from './restrictedRule/RestrictedSecurityRuleGrid'

const TabPane = Tabs.TabPane;
class RestrictedSecurityDashboard extends Component {

  componentDidMount() {
    // queryString中的查询条件
    let searchStr = !!this.props.location ? this.props.location.search : null;
    let employee = null;
    let validDate = null;
    if(!!searchStr) {
        if(searchStr.indexOf('?')  >= 0) {
            searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
            const searchArray = searchStr.split('&');
            for (let i=0; i<searchArray.length; i++) {
                const subParamArray = searchArray[i].split("=");
                if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'employee') {
                  employee = subParamArray[1];
                  employee = employee.replace(/%20/g,' ');
                }
                if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'validDate') {
                  validDate = subParamArray[1];
                }
            }
        }
    }
    this.props.queryRestrictedSecurityInputChange({name: 'employee', value: employee});
    this.props.queryRestrictedSecurityInputChange({name: 'validDateStart', value: validDate});
    this.props.queryRestrictedSecurityInputChange({name: 'validDateEnd', value: validDate});
    const params = {employee: employee, validDateStart: validDate, validDateEnd: validDate};
    this.props.loadSubmittedRestrictedSecurityRequest(params);
  }

  componentDidUpdate() {
    const {
      // Approve/Reject Restricted Security Request Action Status
      approveRestrictedSecurityRequestActionStatus,
      rejectRestrictedSecurityRequestActionStatus,

      // Feedback Restricted Security Request Action Status
      feedbackRestrictedSecurityRequestActionStatus,
    } = this.props;

    // Approve Restricted Security Request Action Status
    if (approveRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are approved successfully!');
      this.refreshButtonOnClicked();
      this.props.initApproveRestrictedSecurityRequestActionStatus();
      this.props.resetRestrictedSecurityRequestOperateModalDialog();
      this.props.queryPendingTasksNum();  // approve, reject成功时要刷新PendingTask数量
    }

    if (approveRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initApproveRestrictedSecurityRequestActionStatus();
    }

    // Reject Restricted Security Request Action Status
    if (rejectRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are rejected successfully!');
      this.refreshButtonOnClicked();
      this.props.initRejectRestrictedSecurityRequestActionStatus();
      this.props.resetRestrictedSecurityRequestOperateModalDialog();
      this.props.queryPendingTasksNum();  // approve, reject成功时要刷新PendingTask数量
    }

    if (rejectRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initRejectRestrictedSecurityRequestActionStatus();
    }

    // Feedback Restricted Security Request Action Status
    if (feedbackRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are feedbacked successfully!');
      this.refreshButtonOnClicked();
      this.props.initFeedbackRestrictedSecurityRequestActionStatus();
      this.props.resetRestrictedSecurityRequestOperateModalDialog();
    }

    if (feedbackRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initFeedbackRestrictedSecurityRequestActionStatus();
    }
  }

  // 跳转到新增页面
  addBtnOnClicked = () => {
    this.props.openRestrictedSecurityRequestEditModalDialog(true, null);
  }


  // 编辑申请
  editBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length !== 1) {
      message.warning("Please select a restricted security application");
      return;
    }

    this.props.openRestrictedSecurityRequestEditModalDialog(false, selectedRestrictedSecurityRequestArray[0]);
  }

  // 批准 Restricted Security Apply
  approveBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    // if (selectedRestrictedSecurityRequestArray.length !== 1) {
    //   message.warning("Please select a restricted security application");
    //   return;
    // }

    let filterRestrictedSecReqArray = selectedRestrictedSecurityRequestArray.filter(resctrictedSecurity => resctrictedSecurity.approvalStatus === RestrictedSecurityApprovalStatus.APPROVED);
    if (filterRestrictedSecReqArray.length > 0) {
      message.warning("You cannot approve the restricted security application that has been approved again!");
      return;
    }

    const restrictedSecReqGuidList = selectedRestrictedSecurityRequestArray.map(resctrictedSecurity => resctrictedSecurity.guid);
    const restrictedSecReq = selectedRestrictedSecurityRequestArray[0];
    const requestStatus = restrictedSecReq.requestStatus;

    this.props.openRestrictedSecurityRequestOperateModalDialog(RestrictedSecurityOperateMode.APPROVE, restrictedSecReqGuidList, requestStatus);
  }

  // 拒绝 Restricted Security Apply
  rejectBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length !== 1) {
      message.warning("Please select a restricted security application");
      return;
    }

    let filterRestrictedSecReqArray = selectedRestrictedSecurityRequestArray.filter(resctrictedSecurity => resctrictedSecurity.approvalStatus === RestrictedSecurityApprovalStatus.REJECTED);
    if (filterRestrictedSecReqArray.length > 0) {
      message.warning("You cannot reject the restricted security application that has been rejected again!");
      return;
    }

    const restrictedSecReqGuidList = selectedRestrictedSecurityRequestArray.map(resctrictedSecurity => resctrictedSecurity.guid);
    const restrictedSecReq = selectedRestrictedSecurityRequestArray[0];
    const requestStatus = restrictedSecReq.requestStatus;

    this.props.openRestrictedSecurityRequestOperateModalDialog(RestrictedSecurityOperateMode.REJECT, restrictedSecReqGuidList, requestStatus);
  }

  // 反馈 Restricted Security Apply
  feedbackBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length === 0) {
      message.warning("Please select a restricted security application at least!");
      return;
    }

    const restrictedSecReqGuidList = selectedRestrictedSecurityRequestArray.map(resctrictedSecurity => resctrictedSecurity.guid);

    this.props.openRestrictedSecurityRequestOperateModalDialog(RestrictedSecurityOperateMode.FEEDBACK, restrictedSecReqGuidList, '');
  }

  refreshButtonOnClicked = () => {
    const {
      employee,
      validDateStart,
      validDateEnd,
    } = this.props.restrictedSecurityQueryParams;
    this.props.loadSubmittedRestrictedSecurityRequest({employee, validDateStart, validDateEnd});
  }

  opRecordBtnOnClicked = () => {
    this.props.loadRestrictedSecurityOperationRecord();
    this.props.openRestrictedSecurityOperationRecordModalDialog();
  }

  showValidButtonOnClicked = () => {
    this.props.showValidRestrictedRules();
  }

  showAllButtonOnClicked = () => {
    this.props.showAllRestrictedRules();
  }



  // lastTimeoutID = null;
  // fuzzySearchEmployee = (value) => {
  //   if(!value) {
  //     this.props.clearEmployeeNameList('restrictedSecurity4Query');
  //   }
  //   if(value.length < 2) {
  //     return;  // 长度过短不查询
  //   }
  //   // 输入完毕等待0.5秒后再查询
  //   if(!!this.lastTimeoutID) {
  //     clearTimeout(this.lastTimeoutID);
  //   }
  //   this.lastTimeoutID = setTimeout(() => {
  //     this.props.fuzzySearchEmployee('restrictedSecurity4Query', value);
  //   }, 500);
  // }


  baseDownloadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT;
  doHistoryUploadAction = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length !== 1) {
      message.warning("Please select one and only one Restricted Security");
      return;
    }
    const dataId = selectedRestrictedSecurityRequestArray[0].restrictedSecGuid;
    this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', queryFields: {module: 'RestrictedSecurity', dataId: dataId}, baseDownloadUrl: this.baseDownloadUrl })
  }

  
  render() {
    const {
      loadSubmittedRestrictedSecurityRequestActionStatus,
      restrictedSecurityQueryParams,
      userAccountOptions,
    } = this.props;

    const {
      employee,
      validDateStart,
      validDateEnd,
    } = restrictedSecurityQueryParams;

    const loadBtnIsLoading = loadSubmittedRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="restrictedSecurityWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>Restricted Security Application</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab="Restricted Security" key="1">
            <div className='operationBar'>
              <Select
                    allowClear
                    size='small'
                    showSearch
                    style={{ width: 150, marginLeft: 10 }}
                    value={!employee ? undefined : employee}
                    placeholder="Applicant" 
                    onChange={(val) => {
                        this.props.queryRestrictedSecurityInputChange({ name: 'employee', value: val })
                    }} >
                    {userAccountOptions}
              </Select>
              <DatePicker 
                    allowClear
                    size='small'
                    style={{marginLeft: 10, width: 150}}
                    value={!validDateStart ? null : moment(validDateStart)}
                    placeholder='Valid Date Start'
                    onChange={(date, dateString) => {
                      this.props.queryRestrictedSecurityInputChange({ name: 'validDateStart', value: dateString });
                    }} />
              <DatePicker 
                    allowClear
                    size='small'
                    style={{marginLeft: 10, width: 150}}
                    value={!validDateEnd ? null : moment(validDateEnd)}
                    placeholder='Valid Date End'
                    onChange={(date, dateString) => {
                      this.props.queryRestrictedSecurityInputChange({ name: 'validDateEnd', value: dateString });
                    }} />
              <Button 
                  onClick={this.refreshButtonOnClicked} 
                  size='small'
                  loading={loadBtnIsLoading}
                  type='primary'
                  icon="search" 
              />

              <Divider type='vertical' />
                  
                  
              <Button 
                  onClick={this.addBtnOnClicked} 
                  size='small'
                  className='positive-button'>
                  Add&amp;Appr
                </Button>
                  
                <Button 
                  onClick={this.editBtnOnClicked} 
                  size='small'
                  type='primary'>
                  Edit
                </Button>
                  
                <Button 
                  onClick={this.approveBtnOnClicked} 
                  size='small'
                  className='positive-button'>
                  Approve
                </Button>
                  
                <Button 
                  onClick={this.rejectBtnOnClicked} 
                  size='small'
                  className='negative-button'>
                  Reject
                </Button>
                  
                <Button 
                  onClick={this.feedbackBtnOnClicked} 
                  size='small'
                  type='primary'>
                  Feedback
                </Button>
                  
                  
                <Button 
                  onClick={this.opRecordBtnOnClicked} 
                  size='small'
                  type='primary'>
                  Operation Record
                </Button>
                  
                <Button 
                  onClick={this.showValidButtonOnClicked} 
                  size='small'
                  type='primary'>
                  Show Valid
                </Button>
                  
                <Button 
                  onClick={this.showAllButtonOnClicked} 
                  size='small'
                  type='primary'>
                  Show All
                </Button>
                  
                <Button 
                  onClick={()=>this.doHistoryUploadAction()} 
                  size='small'
                  type='primary'>
                  HistoryUpload
                </Button>
                  
            </div>
                  
            <div className='restrictedSecurityGrid'>
              <RestrictedSecurityGrid {...this.props} />
            </div>
                
            {/* 批准/拒绝 Restricted Security */}
            <RestrictedSecurityOperateDialog {...this.props} />
                
            {/* 操作记录 */}
            <RestrictedSecurityOpRecordDialog {...this.props} />
                
            {/* 编辑申请 */}
            <RestrictedSecurityRequestEditDialog {...this.props} />
                
            {/* HistoryUpload */}
            <HistoryUploadDialog {...this.props} />
          </TabPane>
          <TabPane tab="Restricted Rule" key="2">
              <RestrictedSecurityRuleGrid {...this.props}/> 
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RestrictedSecurityDashboard;