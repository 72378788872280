import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload, Icon  } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditMeetingAndTrainingReviewModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingReviewQueryFields;
        this.props.loadMeetingAndTrainingReviewInfo({userName, status})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add User Access Info';
        } else if(mode === 'EDIT') {
            return 'Edit User Access Info';
        } else {
            return 'Copy User Access Info';
        }
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditMeetingAndTrainingReviewStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditMeetingAndTrainingReviewModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditMeetingAndTrainingReviewStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditMeetingAndTrainingReviewModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditMeetingAndTrainingReviewActionStatus();
            this.props.resetAddOrEditMeetingAndTrainingReviewModal();
            this.refreshQuery();
        }
        if(addOrEditMeetingAndTrainingReviewStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditMeetingAndTrainingReviewActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditMeetingAndTrainingReviewChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditMeetingAndTrainingReviewModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditMeetingAndTrainingReviewModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditMeetingAndTrainingReviewModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
    }


    submitAddOrEditMeetingAndTrainingReview = () => {
        const {
            addOrEditMeetingAndTrainingReviewModal,
        } = this.props;
        const {
            mode,
        } = addOrEditMeetingAndTrainingReviewModal;
        const {
            id,
            cpty,
            functiontype,
            usertype,
            fundandcompany,
            employee,
            status,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            remarks,
          
        } = addOrEditMeetingAndTrainingReviewModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditMeetingAndTrainingReview({
                    id,
                    cpty,
                    functiontype,
                    usertype,
                    fundandcompany,
                    employee,
                    status,
                    validetodate,
                    uploadChartOriName,
                    uploadChartRealName,
                    remarks,
                    mode,
                });                    
            }
        });
    }
    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
 

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditMeetingAndTrainingReviewModal,
            userAccountOptions,
            statusOptions,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditMeetingAndTrainingReviewStatus,
            meetingAndTrainingReviewUploadUrl,
            meetingAndTrainingReviewDownloadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditMeetingAndTrainingReviewModal;

        const {
            employee,
            usertype,
            cpty,
            status,
            functiontype,
            fundandcompany,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            chartFileList,
            remarks,

        } = addOrEditMeetingAndTrainingReviewModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const isSubmitLoading = addOrEditMeetingAndTrainingReviewStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditMeetingAndTrainingReviewModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditMeetingAndTrainingReviewModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditMeetingAndTrainingReview}>
                          Submit
                        </Button>,
                      ]}
                >
                <Form layout="horizontal">

                    <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Cpty">
                                {getFieldDecorator('cpty', {
                                    initialValue: cpty,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Cpty*',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'cpty', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Function">
                                {getFieldDecorator('functiontype', {
                                    initialValue: functiontype,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Function',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'functiontype', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>

                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Fund/Co.">
                                {getFieldDecorator('fundandcompany', {
                                    initialValue: fundandcompany,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Fund/Co.',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'fundandcompany', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row> 



                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Employee">
                                {getFieldDecorator('employee', {
                                    initialValue: employee,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Employee',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'employee', value: val })
                                            }} 
                                            placeholder="Please select Employee">
                                        {userAccountOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="User Type">
                                {getFieldDecorator('usertype', {
                                    initialValue: usertype,
                                    rules: [{
                                        required: true,
                                        message: 'Please input User Type',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'usertype', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                {getFieldDecorator('status', {
                                    initialValue: status,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Status',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'status', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                        {statusOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Valid to">
                                {getFieldDecorator('validetodate', {
                                    initialValue: validetodate,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Valid Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'validetodate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadChartOriName', {
                                        initialValue: uploadChartOriName,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload the chart',
                                        }],
                                        })(
                                            <Upload
                                                name="uploadChartOriName"
                                                withCredentials={true}
                                                multiple={false}
                                                fileList={chartFileList}
                                                action={meetingAndTrainingReviewUploadUrl}
                                                onChange={this.groupStructureUploadChange}
                                            >
                                                <Button style={{ width: 200, marginLeft: 10 }}>
                                                    <Icon type="upload" />Upload
                                                </Button>
                                            </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                {getFieldDecorator('remarks', {
                                    initialValue: remarks,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Remarks',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>                

                        
                        
                </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditMeetingAndTrainingReviewModalDialogForm = Form.create({ name: 'AddOrEditMeetingAndTrainingReviewModalDialog' })(AddOrEditMeetingAndTrainingReviewModalDialog);
export default AddOrEditMeetingAndTrainingReviewModalDialogForm;
