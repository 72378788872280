import React, { Component } from 'react';

import moment from 'moment';
import { Form, Row, Col, Input, Button, Modal, Select, DatePicker, message, AutoComplete } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../../utils/RadioOperationMode';
import { RESTRICTION_TYPE_MAP } from '../../../../../utils/RestrictionTypeUtil';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

// Restriction Type
const RestrictionTypeOptions = [];
for (let i = 0; i < RESTRICTION_TYPE_MAP.length; i++) {
  RestrictionTypeOptions.push(<Option key={RESTRICTION_TYPE_MAP[i].code}>{RESTRICTION_TYPE_MAP[i].label}</Option>);
}


class RestrictedRuleEditDialog extends Component {
  state = {
    
  };

  componentDidUpdate() {
    const { 
      createRestrictedRuleActionStatus,
      updateRestrictedRuleActionStatus,

      // Load BBG Tickers Action Status
      loadBbgTickersActionStatus,
    } = this.props;

    // 创建 Restricted Rule 成功
    if (createRestrictedRuleActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedRules();
      this.props.initCreateRestrictedRuleActionStatus();

      this.props.resetRestrictedRuleEditModalDialog();
    }
      
    // 创建 Restricted Rule 失败
    if (createRestrictedRuleActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCreateRestrictedRuleActionStatus();
    }

    // 更新 Restricted Rule 成功
    if (updateRestrictedRuleActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedRules();
      this.props.initUpdateRestrictedRuleActionStatus();

      this.props.resetRestrictedRuleEditModalDialog();
    } 

    // 更新 Restricted Rule 失败
    if (updateRestrictedRuleActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateRestrictedRuleActionStatus();
    }

    // Load BBG Tickers 成功
    if (loadBbgTickersActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadBbgTickersActionStatus();
    } 

    // Load BBG Tickers 失败
    if (loadBbgTickersActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadBbgTickersActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.restrictedRuleEditChangeInput({name, value});
  }

  bbgTickerOnChange = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
    }

    this.props.restrictedRuleEditChangeInput({ name: 'bbgTicker', value });
    this.props.form.resetFields();
    this.props.bbgTickerSelectChanged(value);
  }


  lastTimeoutID = null;
  bbgTickerOnSearch = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
      return;
    }
    this.props.restrictedRuleEditChangeInput({ name: 'bbgTicker', value });
    if(value.length < 2) {
      return;
    }
    // 输入完毕等待0.5秒后再查询
    // 无此控制会有以下bug：
    // 当输入 2007 时，会分别根据 20、200、2007进行查询
    // 根据2007的查询结果数据量少，最先返回
    // 根据20的查询结果数据量大，最后返回
    // 因此最终展示的，反而是根据20的查询结果，尽管输入的是2007
    if(!!this.lastTimeoutID) {
      clearTimeout(this.lastTimeoutID);
    }
    this.lastTimeoutID = setTimeout(() => {
      this.props.loadBbgTickers(value);
    }, 500);
  }

  
  fundCodeOnChange = (value) => {
    this.props.restrictedRuleEditChangeInput({ name: 'fund', value });

    if (!!value) {
      this.props.fundCodeSelectChanged(value);
    }
  }

  bookCodeOnChange = (value) => {
    this.props.restrictedRuleEditChangeInput({ name: 'team', value });

    if (!!value) {
      this.props.bookCodeSelectChanged(value);
    }
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      restrictedRuleEditControlModal,
    } = this.props;

    const isAddMode = restrictedRuleEditControlModal.isAddMode;

    const {
      id,
      restrictionType,
      fund,
      team,
      personInCharge,
      bbgTicker,
      secName,
      remark,
      validFrom,
      validTo,
    } = restrictedRuleEditControlModal.fields;

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          if (validTo === '') {
            validTo = '9999-12-31';
          }

          if (isAddMode) {
            this.props.createRestrictedRule({
              id,
              restrictionType,
              fund,
              team,
              personInCharge,
              bbgTicker,
              secName,
              remark,
              validFrom,
              validTo,
            });
          } else {
            this.props.updateRestrictedRule({
              id,
              restrictionType,
              fund,
              team,
              personInCharge,
              bbgTicker,
              secName,
              remark,
              validFrom,
              validTo,
            });
          }
        }
      },
    );
  }

  render() {
    const {
      restrictedRuleEditControlModal,
      fundCodeOptions,
      bookCodeOptions,
      bbgTickerOptions,
      bbgTickerArray,
      loadBbgTickersActionStatus,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = restrictedRuleEditControlModal;


    const { getFieldDecorator } = this.props.form;

    const {
      id,
      restrictionType,
      fund,
      team,
      personInCharge,
      bbgTicker,
      secName,
      remark,
      validFrom,
      validTo,
    } = restrictedRuleEditControlModal.fields;

    const {
      createRestrictedRuleActionStatus,
      updateRestrictedRuleActionStatus,
    } = this.props;

    let modalTitle = "Edit Restricted Rule";
    if (isAddMode) {
      modalTitle = "Add Restricted Rule";
    }

    const isLoading = createRestrictedRuleActionStatus === ACTION_STATUS.LOGINING || updateRestrictedRuleActionStatus === ACTION_STATUS.LOGINING; 
    const bbgTickersSelectIsLoading = loadBbgTickersActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedRuleEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Restriction Type">
                  {getFieldDecorator('restrictionType', {
                      initialValue: restrictionType,
                      rules: [{
                        required: true,
                        message: 'Please select restriction type',
                      }],
                    })(
                      <Select onChange={(value) => {
                            this.onInputChange({ name: 'restrictionType', value })
                          }} placeholder="Please Choose"
                        >
                        {RestrictionTypeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Fund">
                  {getFieldDecorator('fund', {
                      initialValue: fund,
                      rules: [{
                        required: true,
                        message: 'Please select fund',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.fundCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {fundCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Team">
                  {getFieldDecorator('team', {
                      initialValue: team,
                      rules: [{
                        required: true,
                        message: 'Please select team',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.bookCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {bookCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Person In Charge">
                  <Input placeholder="" 
                    value={personInCharge}
                    onChange={(e) => {
                        this.onInputChange({ name: 'personInCharge', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Bbg Ticker">
                  {/* <Select 
                    allowClear
                    showSearch
                    value={bbgTicker}
                    loading={bbgTickersSelectIsLoading}
                    onSearch={this.bbgTickerOnSearch}
                    onChange={this.bbgTickerOnChange} 
                    placeholder="Please Choose"
                    >
                    {bbgTickerOptions}
                  </Select> */}
                  {/* <Input placeholder="" 
                    value={bbgTicker}
                    onChange={(e) => {
                        this.onInputChange({ name: 'bbgTicker', value: e.target.value })
                      }}
                  /> */}
                  <AutoComplete 
                    allowClear
                    dataSource={bbgTickerArray}
                    onChange={this.bbgTickerOnChange}
                    onSearch={this.bbgTickerOnSearch} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Sec Name">
                  <Input placeholder="" 
                    value={secName}
                    allowClear
                    onChange={(e) => {
                        this.onInputChange({ name: 'secName', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Valid From">
                  {getFieldDecorator('validFrom', {
                    initialValue: validFrom === '' ? null : moment(validFrom),
                    rules: [{
                      required: true,
                      message: 'Please select valid from',
                    }],
                  })(
                    <DatePicker 
                      allowClear={false}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'validFrom', value: dateString });
                      }} />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Valid To">
                  <DatePicker 
                    value={moment(validTo)}
                    allowClear={false}
                    onChange={(date, dateString) => {
                      this.onInputChange({ name: 'validTo', value: dateString === '' ? '9999-12-31' : dateString });
                    }} 
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Remark">
                  <TextArea placeholder="Remark" 
                    value={remark}
                    onChange={(e) => {
                        this.onInputChange({ name: 'remark', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ respErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const RestrictedRuleEditDialogForm = Form.create({ name: 'restricted_rule_edit_dialog' })(RestrictedRuleEditDialog);

export default RestrictedRuleEditDialogForm;