import React, { Component } from 'react';
import { Input, Form, Modal, Button, message,Select, Row, Col,DatePicker,Checkbox,Upload, Icon} from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../../utils/localStorageClient';
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const regiStatusOptions = [];
regiStatusOptions.push(<Option key={'USD'}>{'USD'}</Option>);
regiStatusOptions.push(<Option key={'RMB'}>{'RMB'}</Option>);
regiStatusOptions.push(<Option key={'INR'}>{'INR'}</Option>);
regiStatusOptions.push(<Option key={'JPM'}>{'JPM'}</Option>);

const sizeOptions = [];
sizeOptions.push(<Option key={'3m'}>{'3m'}</Option>);
sizeOptions.push(<Option key={'5m'}>{'5m'}</Option>);
sizeOptions.push(<Option key={'10m'}>{'10m'}</Option>);
sizeOptions.push(<Option key={'20m'}>{'20m'}</Option>);
sizeOptions.push(<Option key={'30m'}>{'30m'}</Option>);
class DelMeetingAndTrainingApplyRecordModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingApplyRecordQueryFields;
        this.props.loadMeetingAndTrainingApplyRecordInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'Approve') {
            return 'Approve ';
        } if(mode === 'Reject'){
            return 'Reject'; 
        }
    }
    componentDidUpdate() {
        const {
            delMeetingAndTrainingApplyRecordStatus,
        } = this.props;
        if(delMeetingAndTrainingApplyRecordStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delMeetingAndTrainingApplyRecordModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelMeetingAndTrainingApplyRecordActionStatus();
            this.props.resetDelMeetingAndTrainingApplyRecordModal();
            this.refreshQuery();
        }
        if(delMeetingAndTrainingApplyRecordStatus === ACTION_STATUS.ERROR) {
            this.props.initDelMeetingAndTrainingApplyRecordActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delMeetingAndTrainingApplyRecordChangeInput({name, value});
    }


    submitDelMeetingAndTrainingApplyRecord = () => {

        Modal.confirm({
            title: 'Are you sure for this record?',
            onOk: () => {

                const {
                    mode,
                } = this.props.delMeetingAndTrainingApplyRecordModal;
                const {
                    id,
                    comments,
                    currency,
                    paperSize,
                } = this.props.delMeetingAndTrainingApplyRecordModal.fields;
                this.props.delMeetingAndTrainingApplyRecord({id, mode, comments,currency,paperSize});
            },
            onCancel: () => {},
        });
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            delMeetingAndTrainingApplyRecordModal,
            delMeetingAndTrainingApplyRecordStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,

            comments,
            market,
        } = delMeetingAndTrainingApplyRecordModal;

        const {
            id,

            currency,
            paperSize,
            applyType,
        } = this.props.delMeetingAndTrainingApplyRecordModal.fields;
        const isSubmitLoading = delMeetingAndTrainingApplyRecordStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);

        const userName = localStorageClient.getUserName();
        const isCio = 'Wang Qiang' === userName && mode === 'Approve';
        const isPaper = applyType === 'Paper Portfolio' || applyType === 'Book';
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={700}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelMeetingAndTrainingApplyRecordModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelMeetingAndTrainingApplyRecordModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelMeetingAndTrainingApplyRecord}>
                          Submit
                        </Button>,
                      ]}

                >
                       
                       {!!isPaper &&
                       <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="Currency">
                                        {getFieldDecorator('currency', {
                                           
                                            rules: [{
                                                required: true,
                                                message: 'Please select currency',
                                            }],
                                            })(
                                            <Select
                                                    allowClear
                                                    mode='multiple'
                                                    style={{ width: 300, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onEmailInputChange({ name: 'currency', value: val })
                                                    }} 
                                                    placeholder="Please select currency">
                                                {regiStatusOptions}
                                            </Select>
                                            )}
                                        </FormItem>
                                </Col>
                        </Row>
                    }
                    {!!isPaper &&
                        <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="Paper size">
                                        {getFieldDecorator('paperSize', {
                                            initialValue: paperSize,
                                            rules: [{
                                                required: true,
                                                message: 'Please select paperSize',
                                            }],
                                            })(
                                            <Select
                                                    allowClear
                                                    
                                                    style={{ width: 300, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onInputChange({ name: 'paperSize', value: val })
                                                    }} 
                                                    placeholder="Please select paperSize">
                                                {sizeOptions}
                                            </Select>
                                            )}
                                        </FormItem>
                                </Col>
                        </Row>
                    }
                        <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="Opinion">
                                        {getFieldDecorator('Opinion', {
                                            initialValue: comments,
                                            rules: [{
                                                required: true,
                                                message: 'Please select paperSize',
                                            }],
                                            })(
                                            <TextArea 
                                                style={{ width: 300, marginLeft: 10 }}
                                                rows={4} 
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'comments', value: e.target.value })
                                                }}
                                            />
                                            )}
                                        </FormItem>
                                </Col>
                        </Row>

                        {/* <TextArea 
                            style={{ width: 400 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        /> */}
                        {!!isCio &&
                            <Message negative>
                            <Message.Header>Reminder Message</Message.Header>
                            <p>同意后将自动发送通知邮件给BD组及夫新总。</p>
                        </Message>
                        }
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelMeetingAndTrainingApplyRecordModalDialogForm = Form.create({ name: 'DelMeetingAndTrainingApplyRecordModalDialog' })(DelMeetingAndTrainingApplyRecordModalDialog);
export default DelMeetingAndTrainingApplyRecordModalDialogForm;
