import React, { Component } from 'react';
import { Input, Form,Row, Col, Modal, Button,Select, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};
class CopMeetingAndTrainingGroupModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingGroupQueryFields;
        this.props.loadMeetingAndTrainingGroupInfo({userName, status})
    }

    componentDidUpdate() {
        const {
            copMeetingAndTrainingGroupStatus,
        } = this.props;
        if(copMeetingAndTrainingGroupStatus === ACTION_STATUS.SUCCESS) {
            message.success('CopAll User Access Info succeeded');
            this.props.initCopMeetingAndTrainingGroupActionStatus();
            this.props.resetCopMeetingAndTrainingGroupModal();
            this.refreshQuery();
        }
        if(copMeetingAndTrainingGroupStatus === ACTION_STATUS.ERROR) {
            this.props.initCopMeetingAndTrainingGroupActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.copMeetingAndTrainingGroupChangeInput({name, value});
    }


    submitCopMeetingAndTrainingGroup = () => {
        Modal.confirm({
            title: 'Are you sure copy access to select?',
            onOk: () => {
            
                const {
                    id,
                    employee,
                    comments,
                } = this.props.copMeetingAndTrainingGroupModal.fields;
                this.props.copMeetingAndTrainingGroup({id, employee, comments});
            },
            onCancel: () => {},
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            copMeetingAndTrainingGroupModal,
            copMeetingAndTrainingGroupStatus,
            userAccountOptions,
        } = this.props;
        const {
            isOpened,
            respErrMsg,
        } = copMeetingAndTrainingGroupModal;

        const {
            comments,
            employee,
        } = copMeetingAndTrainingGroupModal.fields

        const isSubmitLoading = copMeetingAndTrainingGroupStatus === ACTION_STATUS.LOGINING;
        

        return (
            <div>
                <Modal
                    title={'Copy ALL User Access Info'}
                    centered
                    width={500}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetCopMeetingAndTrainingGroupModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetCopMeetingAndTrainingGroupModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitCopMeetingAndTrainingGroup}>
                          Submit
                        </Button>,
                      ]}
                >
                <Row gutter={24}>
                    <Col span={12}>
                            <FormItem {...formItemLayout} label="Employee">
                            {getFieldDecorator('employee', {
                                initialValue: employee,
                                rules: [{
                                    required: true,
                                    message: 'Please select Employee',
                                }],
                                })(
                                <Select
                                        allowClear
                                        showSearch
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(val) => {
                                            this.onInputChange({ name: 'employee', value: val })
                                        }} 
                                        placeholder="Please select Employee">
                                    {userAccountOptions}
                                </Select>
                                )}
                            </FormItem>
                    </Col>
                </Row> 
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem {...formItemLayout} label="Comments">
                        {getFieldDecorator('comments', {
                            initialValue: comments,
                            rules: [{
                                required: false,
                                message: 'Please input Comments',
                            }],
                            })(
                            <TextArea 
                                style={{ width: 200, marginLeft: 10 }}
                                rows={3} 
                                onChange={(e) => {
                                    this.onInputChange({ name: 'comments', value: e.target.value })
                                }}
                            />
                            )}
                        </FormItem>
                    </Col>
                </Row> 



                    {
                    !!respErrMsg &&  
                    <Message negative>
                        <Message.Header>Response Error Message</Message.Header>
                        <p>{ respErrMsg }</p>
                    </Message>
                    }
                </Modal>
                
            </div>
            
        );
    }

}

const CopMeetingAndTrainingGroupModalDialogForm = Form.create({ name: 'CopMeetingAndTrainingGroupModalDialog' })(CopMeetingAndTrainingGroupModalDialog);
export default CopMeetingAndTrainingGroupModalDialogForm;
