import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker,message, Select, Button, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditPresonalInfoModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            startDate,
            endDate,
        } = this.props.presonalInfoQueryFields;
        this.props.loadPresonalInfoInfo({startDate,endDate});
    }

    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditPresonalInfoStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditPresonalInfoModal;

        if(addOrEditPresonalInfoStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSavePresonalInfoActionStatus();
            this.props.resetSubmitSavePresonalInfoModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditPresonalInfoStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSavePresonalInfoActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditPresonalInfoChangeInput({name, value});
    }
    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'frequency', value: value});
        this.onInputChange({name: 'deadline', value: undefined});
        this.clearDecomposedDeadline();
        this.onInputChange({name: 'notification', value: undefined});
    }
    onDeadlineNAInputChange = (value) => {
        const deadline = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
        this.clearDecomposedDeadline();
        // deadline是NA时，notification必须是NA，其他情况，清空notification重新填写
        this.onInputChange({name: 'notification', value: deadline});
        
    }
    onDeadlineEveryWorkdayInputChange = (value) => {
        const deadline = !!value ? 'EveryWorkday' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineEndOfMonthInputChange = (value) => {
        const deadline = !!value ? 'EndOfMonth' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineMonthInputChange = (value) => {
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineMonth', value: value});
    }
    // 清空分解后的deadline的各参数
    clearDecomposedDeadline = () => {
        this.onInputChange({name: 'deadlineMonth', value: undefined});
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineYear', value: undefined});
    }
    onNotificationNAInputChange = (value) => {
        const notification = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'notification', value: notification});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    submitSaveAndSendPresonalInfoModal(){
        this.onInputChange({name: 'sendmail', value: 'true'});
        this.submitSavePresonalInfoModal();
    }


    submitSavePresonalInfoModal = () => {
        const {
            mode,
        } = this.props.addOrEditPresonalInfoModal;
        const {
            id,
            name,
            department,
            office,
            status,
            lastConfirmDate,
            terminationDate,
            remarks,
            comments,
            sendmail,
            formGuid,

        } = this.props.addOrEditPresonalInfoModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 执行提交
            this.props.submitSavePresonalInfo({
                id,
                name,
                department,
                office,
                status,
                lastConfirmDate,
                terminationDate,
                remarks,
                comments,
                mode,
                sendmail,
                formGuid,
            });

        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
 
            addOrEditPresonalInfoModal,
            addOrEditPresonalInfoStatus,
            userAccountEmailOptions,
            userAccountOptions,
            departmentOptions,
            officeOptions,
            statusOptions, 
            licensingFormOptions,
            importanceOptions,


        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditPresonalInfoModal;

        const {
            id,
            name,
            department,
            office,
            status,
            lastConfirmDate,
            terminationDate,
            formGuid,
            remarks,
            comments,


        } = addOrEditPresonalInfoModal.fields;

        // const emailToArray = !emailTo ? [] : emailTo.split(';');


        const monthDateOptions = [];


        const startYearOptions = [];



        const modalTitle = this.getActionFromMode(mode) + ' Personal Info';
        const isSubmitLoading = addOrEditPresonalInfoStatus === ACTION_STATUS.LOGINING;
        const lastConfirmDateMent = !lastConfirmDate ? undefined : moment(lastConfirmDate);
        const terminationDateMent = !terminationDate ? undefined : moment(terminationDate);
        // const enddatement = !enddate ? undefined : moment(enddate);

        
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSavePresonalInfoModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSavePresonalInfoModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSavePresonalInfoModal() }>
                          Save
                        </Button>, 
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveAndSendPresonalInfoModal() }>
                          Send
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                    <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Form Name">
                                    {getFieldDecorator('Form Name', {
                                        initialValue: formGuid,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Form Name',
                                        }],
                                        })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'formGuid', value: val })
                                            }} 
                                            placeholder="Please select Form Name">
                                                {licensingFormOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Name">
                                    {getFieldDecorator('Name', {
                                        initialValue: name,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Name',
                                        }],
                                        })(
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'name', value: val })
                                            }} 
                                            placeholder="Please select Name">
                                                {userAccountOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Department">
                                    {getFieldDecorator('department', {
                                        initialValue: department,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Department',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'department', value: val })
                                            }} 
                                            placeholder="Please select Department">
                                                {departmentOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                            <FormItem {...formItemLayout} label="Office">
                                        {getFieldDecorator('office', {
                                            initialValue: office,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                // mode='multiple'
                                                style={{ width: 300, marginLeft: 10 }}
                                                placeholder='Please select Office' 
                                                onChange={(val) => {
                                                    this.onInputChange({ name: 'office', value: val })
                                            }}>
                                                {officeOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                        {getFieldDecorator('status', {
                                            initialValue: status,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                // mode='multiple'
                                                style={{ width: 300, marginLeft: 10 }}
                                                placeholder='Please select status' 
                                                onChange={(val) => {
                                                    this.onInputChange({ name: 'status', value: val })
                                            }}>
                                                {statusOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Termination Date ">
                                {getFieldDecorator('terminationDate', {
                                    initialValue: terminationDateMent,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Termination Date ',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'terminationDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Last Confirm date ">
                                {getFieldDecorator('lastConfirmDate', {
                                    initialValue: lastConfirmDateMent ,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Last Confirm date ',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'lastConfirmDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                        

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>


                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditPresonalInfoModalDialogForm = Form.create({ name: 'AddOrEditPresonalInfoModalDialog' })(AddOrEditPresonalInfoModalDialog);

export default AddOrEditPresonalInfoModalDialogForm;
