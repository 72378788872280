import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedSecurityOperateMode from '../../../../../utils/RestrictedSecurityOperateMode';
const { TextArea } = Input;

class RestrictedSecurityOperateDialog extends Component {

  state = {
    comments: '',
    feedback: '',
  };

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      operateMode,
      restrictedSecReqGuidList,
    } = this.props.restrictedSecurityRequestOperateControlModal;

    const {
      comments,
      feedback,
    } = this.state;

    if (operateMode === RestrictedSecurityOperateMode.APPROVE) {
      this.props.approveRestrictedSecurityRequest(comments, restrictedSecReqGuidList);
    } 
    
    if (operateMode === RestrictedSecurityOperateMode.REJECT) {
      this.props.rejectRestrictedSecurityRequest(comments, restrictedSecReqGuidList);
    } 

    if (operateMode === RestrictedSecurityOperateMode.FEEDBACK) {
      this.props.feedbackRestrictedSecurityRequest(feedback, restrictedSecReqGuidList, comments);
    } 
  }

  // Modal Closed
  modalClosedHandle = () => {
    this.setState({
      comments: '',
      feedback: '',
    });
  }

  render() {
    const {
      comments,
      feedback,
    } = this.state;

    const {
      // Restricted Security Request Operate Modal Dialog
      restrictedSecurityRequestOperateControlModal,

      // Approve/Reject Restricted Security Request Action Status
      approveRestrictedSecurityRequestActionStatus,
      rejectRestrictedSecurityRequestActionStatus,

      // Feedback Restricted Security Request Action Status
      feedbackRestrictedSecurityRequestActionStatus,
    } = this.props;

    const {
      isOpened,
      operateMode,
      requestStatus,
      respErrMsg,
    } = restrictedSecurityRequestOperateControlModal;
    
    let operation = 'Operate';
    if (operateMode === RestrictedSecurityOperateMode.APPROVE) {
      operation = 'Approve';
    } else if (operateMode === RestrictedSecurityOperateMode.REJECT) {
      operation = 'Reject';
    } else if (operateMode === RestrictedSecurityOperateMode.DELETE) {
      operation = 'Delete';
    } else if (operateMode === RestrictedSecurityOperateMode.FEEDBACK) {
      operation = 'Feedback';
    }

    let middlePartTitle = 'Restricted Security';
    if (operateMode === RestrictedSecurityOperateMode.APPROVE) {
      if (requestStatus === 'OnList') {
        middlePartTitle = 'On-list';
      } else if (requestStatus === 'OffList') {
        middlePartTitle = 'Off-list';
      }
    } else if (operateMode === RestrictedSecurityOperateMode.REJECT) {
      if (requestStatus === 'OnList') {
        middlePartTitle = 'On-list';
      } else if (requestStatus === 'OffList') {
        middlePartTitle = 'Off-list';
      }
    }

    let modalTitle = `${operation} ${middlePartTitle} Application`;

    const isLoading = approveRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING || 
        rejectRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING || 
        feedbackRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedSecurityRequestOperateModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
          okText="Submit"
          cancelText="Cancel"
        >
          {
            operateMode === RestrictedSecurityOperateMode.FEEDBACK &&
            <TextArea 
              rows={4} 
              style={{ marginBottom: 10 }}
              placeholder='Feedback'
              value={feedback} 
              onChange={(e) => {
                this.setState({
                  feedback: e.target.value
                })
              }}/>
          }
          <TextArea 
            rows={4} 
            placeholder='Comments'
            value={comments} 
            onChange={(e) => {
              this.setState({
                comments: e.target.value
              })
            }}/>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default RestrictedSecurityOperateDialog;