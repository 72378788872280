import React, {Component} from 'react';
import { Breadcrumb, Button, message, Modal, Select, Divider, DatePicker } from 'antd';
import moment from 'moment';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { TRADE_APPROVAL_STATUS_CODE } from '../../../../../utils/PersonalTradeApprovalStatus';
import PersonalTradeOperateMode from '../../../../../utils/PersonalTradeOperateMode';
import PersonalTradeGrid from './PersonalTradeGrid';
import PersonalTradeFeedbackDialog from './PersonalTradeFeedbackDialog';
import PersonalTradeOpRecordDialog from './PersonalTradeOpRecordDialog';
import PersonalTradeRequestEditDialog from './PersonalTradeRequestEditDialog';
import PersonalTradeRecognizeDialog from './PersonalTradeRecognizeDialog';
import PersonalTradeOperateDialog from './PersonalTradeOperateDialog';
import MonthlyReportDialog from './MonthlyReportDialog';
import PersonalTradeRecognizeResult from './PersonalTradeRecognizeResult';
import DataEditMode from '../../../../../utils/DataEditMode';
import '../../ApprovalRequest.css';

const confirm = Modal.confirm;

class PersonalTradeDashboard extends Component {

  componentDidMount() {
    // queryString中的查询条件
    let searchStr = !!this.props.location ? this.props.location.search : null;
    let employee = null;
    let tradeDate = null;
    if(!!searchStr) {
        if(searchStr.indexOf('?')  >= 0) {
            searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
            const searchArray = searchStr.split('&');
            for (let i=0; i<searchArray.length; i++) {
                const subParamArray = searchArray[i].split("=");
                if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'employee') {
                  employee = subParamArray[1];
                  employee = employee.replace(/%20/g,' ');
                }
                if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'tradeDate') {
                  tradeDate = subParamArray[1];
                }
            }
        }
    }
    this.props.queryPersonalTradeInputChange({name: 'employee', value: employee});
    this.props.queryPersonalTradeInputChange({name: 'tradeDateStart', value: tradeDate});
    this.props.queryPersonalTradeInputChange({name: 'tradeDateEnd', value: tradeDate});
    const params = {employee: employee, tradeDateStart: tradeDate, tradeDateEnd: tradeDate};
    this.props.loadPersonalTrade(params);
  }

  componentDidUpdate() {
    const {
      approvePersonalTradeActionStatus,

      // Final Approve Personal Trade Action Status
      finalApprovePersonalTradeActionStatus,

      rejectPersonalTradeActionStatus,
      batchDeletePersonalTradeRequestActionStatus,
      batchDeletePersonalTradeRequestErrMsg,

      // Recognize Personal Trade Request
      recognizePersonalTradeRequestActionStatus,
      recognizePersonalTradeRequestErrMsg,
    } = this.props;

    // Approve
    if (approvePersonalTradeActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('The PA dealing application are approved successfully!');
      this.refreshBtnOnClicked();
      this.props.initApprovePersonalTradeActionStatus();
      this.props.loadPersonalTradeOperationRecord();
      this.props.resetPersonalTradeRequestOperateModalDialog();
      this.props.queryPendingTasksNum();  // approve, reject, finalApprove成功时要刷新PendingTask数量
    }

    // Approve
    if (approvePersonalTradeActionStatus === ACTION_STATUS.ERROR) {
      this.props.initApprovePersonalTradeActionStatus();
    }

    // Final Approve Personal Trade
    if (finalApprovePersonalTradeActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('The PA dealing applicationt are final approved successfully!');
      this.refreshBtnOnClicked();
      this.props.initFinalApprovePersonalTradeActionStatus();
      this.props.loadPersonalTradeOperationRecord();
      this.props.resetPersonalTradeRequestOperateModalDialog()
      this.props.queryPendingTasksNum();  // approve, reject, finalApprove成功时要刷新PendingTask数量
    }

    // Final Approve Personal Trade
    if (finalApprovePersonalTradeActionStatus === ACTION_STATUS.ERROR) {
      this.props.initFinalApprovePersonalTradeActionStatus();
    }

    // Reject
    if (rejectPersonalTradeActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('The PA dealing application are rejected successfully!');
      this.refreshBtnOnClicked();
      this.props.initRejectPersonalTradeActionStatus();
      this.props.loadPersonalTradeOperationRecord();
      this.props.resetPersonalTradeRequestOperateModalDialog();
      this.props.queryPendingTasksNum();  // approve, reject, finalApprove成功时要刷新PendingTask数量
    }

    // Reject
    if (rejectPersonalTradeActionStatus === ACTION_STATUS.ERROR) {
      this.props.initRejectPersonalTradeActionStatus();
    }

    // 批量删除PersonalTradeRequest成功
    if (batchDeletePersonalTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('The PA dealing application are deleted successfully!');
      this.refreshBtnOnClicked();
      this.props.initBatchDeletePersonalTradeRequestActionStatus();

      this.props.resetPersonalTradeRequestOperateModalDialog()
    }

    // 批量删除PersonalTradeRequest失败
    if (batchDeletePersonalTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error(batchDeletePersonalTradeRequestErrMsg);
      this.props.initBatchDeletePersonalTradeRequestActionStatus();
    }

    // Recognize Personal Trade Request 成功
    if (recognizePersonalTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initRecognizePersonalTradeRequestActionStatus();
      this.showRecognizeResultBeforeApproved();
    }

    // Recognize Personal Trade Request 失败
    if (recognizePersonalTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error(recognizePersonalTradeRequestErrMsg);
      this.props.initRecognizePersonalTradeRequestActionStatus();
    }

  }

  // 新增交易请求
  addTradeRequestBtnOnClicked = () => {
    this.props.loadPersonalAccountWithEmployee({employee: null});
    this.openPersonalTradeRequestEditModalDialog(DataEditMode.NEW, null);
  }

  // 编辑交易请求
  editTradeRequestBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    if (selectedPersonalTradeArray.length !== 1) {
      message.warning("Please select a PA dealing application!");
      return;
    }

    let filterPersonalTradeArray = selectedPersonalTradeArray.filter(tradeRequest => tradeRequest.isAbandoned);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot edit an abandoned PA dealing application!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot edit the PA dealing application that has been final approved again!");
      return;
    }

    const {
      employee,
    } = selectedPersonalTradeArray[0];
    this.props.loadPersonalAccountWithEmployee({employee: employee});

    this.openPersonalTradeRequestEditModalDialog(DataEditMode.MODIFY, selectedPersonalTradeArray[0]);
  }

  // 删除交易请求
  deleteTradeRequestBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    if (selectedPersonalTradeArray.length === 0) {
      message.warning("Please select a PA dealing application at least!");
      return;
    }

    let filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot delete the PA dealing application that has been approved!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot delete the PA dealing application that has been final approved!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.REJECTED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot delete the PA dealing application that has been rejected!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the PA dealing application?',
      content: '',
      onOk() {
        const tradeRequestIdList = selectedPersonalTradeArray.map(tradeRequest => tradeRequest.tradeId);

        // that.props.batchDeletePersonalTradeRequest(tradeRequestIdList);
        that.props.openPersonalTradeRequestOperateModalDialog(PersonalTradeOperateMode.DELETE, null, tradeRequestIdList);
      },
      onCancel() {},
    });
  }

  // 复制一条交易请求记录
  copyTradeRequestBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    if (selectedPersonalTradeArray.length !== 1) {
      message.warning("Please select a PA dealing application!");
      return;
    }

    const {
      employee,
    } = selectedPersonalTradeArray[0];
    this.props.loadPersonalAccountWithEmployee({employee: employee});

    this.openPersonalTradeRequestEditModalDialog(DataEditMode.COPY, selectedPersonalTradeArray[0]);
  }


  openPersonalTradeRequestEditModalDialog(mode, trade) {
    this.props.openPersonalTradeRequestEditModalDialog(mode, trade);
    this.props.startTrigger30DayHoldingCheckInfoStatus();
  }


  approveBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    // if (selectedPersonalTradeArray.length === 0) {
    //   message.warning("Please select a PA dealing application at least!");
    //   return;
    // }

    // let filterPersonalTradeArray = selectedPersonalTradeArray.filter(tradeRequest => tradeRequest.isAbandoned);
    // if (filterPersonalTradeArray.length > 0) {
    //   message.warning("You cannot approve an abandoned PA dealing application!");
    //   return;
    // }

    // filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED);
    // if (filterPersonalTradeArray.length > 0) {
    //   message.warning("You cannot approve the PA dealing application that has been approved again!");
    //   return;
    // }

    // filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    // if (filterPersonalTradeArray.length > 0) {
    //   message.warning("You cannot approve the PA dealing application that has been final approved again!");
    //   return;
    // }

    // // Check Match Result
    // filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.matchActiveTrade || 
    //     personalTrade.matchComplianceRule || personalTrade.matchReportedHolding || personalTrade.matchTradeYesterday);

    // let isNonCompliance = false;
    // if (filterPersonalTradeArray.length > 0) {
    //   isNonCompliance = true;
    // }

    const personalTradeIdArray = selectedPersonalTradeArray.map(personalTrade => personalTrade.tradeId);

    // this.props.openPersonalTradeRequestOperateModalDialog(PersonalTradeOperateMode.APPROVE, isNonCompliance, personalTradeIdArray);

    this.props.recognizePersonalTradeRequest(personalTradeIdArray);
  }

  // Approved 前展示系统识别结果
  showRecognizeResultBeforeApproved = () => {
    const {
      selectedPersonalTradeArray,
    } = this.props;

    let that = this;
    confirm({
      title: 'Application result is as follow, are you sure you want to approve?',
      content: <PersonalTradeRecognizeResult {...this.props} />,
      width: 750,
      onOk() {
        // Check Match Result
        let filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.matchActiveTrade || 
          personalTrade.matchComplianceRule || personalTrade.matchReportedHolding || personalTrade.matchTradeYesterday);

        let isNonCompliance = false;
        if (filterPersonalTradeArray.length > 0) {
          isNonCompliance = true;
        }

        const personalTradeIdArray = selectedPersonalTradeArray.map(personalTrade => personalTrade.tradeId);

        that.props.openPersonalTradeRequestOperateModalDialog(PersonalTradeOperateMode.APPROVE, isNonCompliance, personalTradeIdArray);
      },
      onCancel() {},
    });
  }

  // 最终批准交易
  finalApproveBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    if (selectedPersonalTradeArray.length === 0) {
      message.warning("Please select a PA dealing application at least!");
      return;
    }

    let filterPersonalTradeArray = selectedPersonalTradeArray.filter(tradeRequest => tradeRequest.isAbandoned);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot final approve an abandoned PA dealing application!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus !== TRADE_APPROVAL_STATUS_CODE.APPROVED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot final approve the PA dealing application that is not approved yet!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot final approve the PA dealing application that has been final approved!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.actualQty === null || !personalTrade.attachment && personalTrade.actualQty != 0);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot final approve the PA dealing application without the actual trade qty, or without an attached proof if the actual trade qty is not zero!");
      return;
    }

    // Check Match Result
    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.matchActiveTrade || 
        personalTrade.matchComplianceRule || personalTrade.matchReportedHolding || personalTrade.matchTradeYesterday);

    let isNonCompliance = false;
    if (filterPersonalTradeArray.length > 0) {
      isNonCompliance = true;
    }

    const personalTradeIdArray = selectedPersonalTradeArray.map(personalTrade => personalTrade.tradeId);
    
    this.props.openPersonalTradeRequestOperateModalDialog(PersonalTradeOperateMode.FINAL_APPROVE, isNonCompliance, personalTradeIdArray);
  }

  rejectBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    if (selectedPersonalTradeArray.length === 0) {
      message.warning("Please select a PA dealing application at least!");
      return;
    }

    let filterPersonalTradeArray = selectedPersonalTradeArray.filter(tradeRequest => tradeRequest.isAbandoned);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot reject an abandoned PA dealing application!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.REJECTED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot reject the PA dealing application that has been rejected again!");
      return;
    }

    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot reject the PA dealing application that has been final approved again!");
      return;
    }

    // Check Match Result
    filterPersonalTradeArray = selectedPersonalTradeArray.filter(personalTrade => personalTrade.matchActiveTrade || 
      personalTrade.matchComplianceRule || personalTrade.matchReportedHolding || personalTrade.matchTradeYesterday);

    let isNonCompliance = false;
    if (filterPersonalTradeArray.length > 0) {
      isNonCompliance = true;
    }

    const personalTradeIdArray = selectedPersonalTradeArray.map(personalTrade => personalTrade.tradeId);

    // this.props.rejectPersonalTrade(personalTradeIdArray);
    this.props.openPersonalTradeRequestOperateModalDialog(PersonalTradeOperateMode.REJECT, isNonCompliance, personalTradeIdArray);
  }

  feedbackBtnOnClicked = () => {
    const {
      selectedPersonalTradeArray
    } = this.props;

    if (selectedPersonalTradeArray.length !== 1) {
      message.warning("Please select a PA dealing application!");
      return;
    }

    let filterPersonalTradeArray = selectedPersonalTradeArray.filter(tradeRequest => tradeRequest.isAbandoned);
    if (filterPersonalTradeArray.length > 0) {
      message.warning("You cannot feedback an abandoned PA dealing application!");
      return;
    }

    this.props.openPersonalTradeFeedbackModalDialog(selectedPersonalTradeArray[0]);
  }

  refreshBtnOnClicked = () => {
    const {
      employee,
      tradeDateStart,
      tradeDateEnd,
    } = this.props.personalTradeQueryParam;
    const params = {employee, tradeDateStart, tradeDateEnd};
    this.props.loadPersonalTrade(params);
  }

  opRecordBtnOnClicked = () => {
    this.props.openPersonalTradeOperationRecordModalDialog();
  }

  monthlyReportBtnOnClicked = () => {
    const monthlyReportResult = this.props.monthlyReportResult;
    if (!!!monthlyReportResult) {
      this.props.loadMonthlyReport(moment().format('YYYY-MM'));
    }

    this.props.openMonthlyReportModalDialog();
  }


  // lastTimeoutID = null;
  // fuzzySearchEmployee = (value) => {
  //   if(!value) {
  //     this.props.clearEmployeeNameList('personalTrade');
  //   }
  //   if(value.length < 2) {
  //     return;  // 长度过短不查询
  //   }
  //   // 输入完毕等待0.5秒后再查询
  //   if(!!this.lastTimeoutID) {
  //     clearTimeout(this.lastTimeoutID);
  //   }
  //   this.lastTimeoutID = setTimeout(() => {
  //     this.props.fuzzySearchEmployee('personalTrade', value);
  //   }, 500);
  // }


  
  render() {
    const {
      approvePersonalTradeActionStatus,

      // Final Approve Personal Trade Action Status
      finalApprovePersonalTradeActionStatus,

      rejectPersonalTradeActionStatus,
      loadPersonalTradeActionStatus,

      // Recognize Personal Trade Request
      recognizePersonalTradeRequestActionStatus,

      personalTradeQueryParam,
      // fuzzyQueryEmployeeNameOptions,
      userAccountOptions,
    } = this.props;

    const {
      employee,
      tradeDateStart,
      tradeDateEnd,
    } = personalTradeQueryParam;


    const approveBtnIsLoading = approvePersonalTradeActionStatus === ACTION_STATUS.LOGINING || recognizePersonalTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    const finalApproveBtnIsLoading = finalApprovePersonalTradeActionStatus === ACTION_STATUS.LOGINING;
    const rejectBtnIsLoading = rejectPersonalTradeActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadPersonalTradeActionStatus === ACTION_STATUS.LOGINING;

    const deleteBtnIsLoading = false;

    return (
      <div className="personalTradeWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Approval Request</Breadcrumb.Item>
            <Breadcrumb.Item>PA Dealing Application</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <Select
              allowClear
              showSearch
              size='small'
              value={!employee ? undefined : employee}
              style={{marginLeft: 10, width: 150}}
              onChange={(value) => this.props.queryPersonalTradeInputChange({name: 'employee', value: value})}
              placeholder="Employee">
              {userAccountOptions}
          </Select>
          <DatePicker 
              allowClear
              size='small'
              style={{marginLeft: 10, width: 150}}
              value={!tradeDateStart ? null : moment(tradeDateStart)}
              placeholder='Trade Date Start'
              onChange={(date, dateString) => {
                this.props.queryPersonalTradeInputChange({ name: 'tradeDateStart', value: dateString });
              }} />
          <DatePicker 
              allowClear
              size='small'
              style={{marginLeft: 10, width: 150}}
              value={!tradeDateEnd ? null : moment(tradeDateEnd)}
              placeholder='Trade Date End'
              onChange={(date, dateString) => {
                this.props.queryPersonalTradeInputChange({ name: 'tradeDateEnd', value: dateString });
              }} />
          <Button 
              onClick={this.refreshBtnOnClicked} 
              size='small'
              loading={loadBtnIsLoading}
              type='primary'
              icon="search" 
            />
            

          <Divider type='vertical' />
          

          <Button 
            onClick={this.addTradeRequestBtnOnClicked} 
            style={{ marginLeft: 10 }}
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editTradeRequestBtnOnClicked} 
            size='small'
            type='primary'>
            Edit
          </Button>

          <Button 
            onClick={this.deleteTradeRequestBtnOnClicked} 
            size='small'
            loading={deleteBtnIsLoading}
            className='negative-button'>
            Del
          </Button>

          <Button 
            onClick={this.copyTradeRequestBtnOnClicked} 
            style={{ marginLeft: 10 }}
            size='small'
            type='primary'>
            Copy
          </Button>

          <Button 
            onClick={this.approveBtnOnClicked} 
            size='small'
            loading={approveBtnIsLoading}
            className='positive-button'>
            Approve
          </Button>

          <Button 
            onClick={this.finalApproveBtnOnClicked} 
            size='small'
            loading={finalApproveBtnIsLoading}
            className='positive-button'>
            Final Approve
          </Button>

          <Button 
            onClick={this.rejectBtnOnClicked} 
            size='small'
            loading={rejectBtnIsLoading}
            className='negative-button'>
            Reject
          </Button>
          
          <Button 
            onClick={this.feedbackBtnOnClicked} 
            size='small'
            type='primary'>
            Feedback
          </Button>

          <Button 
            onClick={this.monthlyReportBtnOnClicked} 
            size='small'
            type='primary'>
            Monthly Report
          </Button>

          <Button 
            onClick={this.opRecordBtnOnClicked} 
            size='small'
            type='primary'>
            Operation Record
          </Button>
        </div>

        <div className='personalTradeGrid'>
          <PersonalTradeGrid {...this.props} />
        </div>

        <PersonalTradeFeedbackDialog {...this.props} />

        <PersonalTradeOpRecordDialog {...this.props} />

        <PersonalTradeRequestEditDialog {...this.props} />

        {/* 查看识别结果 */}
        <PersonalTradeRecognizeDialog {...this.props} />

        {/* Personal Trade Operate(Approve/Final Approve/Reject) */}
        <PersonalTradeOperateDialog {...this.props} />

        {/* 查看月报 */}
        <MonthlyReportDialog {...this.props} />
      </div>
    );
  }
}

export default PersonalTradeDashboard;
