import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './app/layout/App';
import configureStore from './app/store/configureStore';

import 'semantic-ui-css-offline-fonts/semantic.css';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";

import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from '@ag-grid-enterprise/core';
import RedirectComponent from './utils/RedirectComponent';
LicenseManager.setLicenseKey(
  'Shanghai_Pinpoint_Investment_Management_Limited_IMS_1Devs8_August_2019__MTU2NTIxODgwMDAwMA==95c1392e256d2e14a9760c2e378224c3'
);

ModuleRegistry.registerModules(AllModules);

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
           <RedirectComponent />
    </Router>
  </Provider>,
  document.getElementById('root')
);