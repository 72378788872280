import {connect} from 'react-redux';
import MeetingAndTrainingApplyRecordDashboard from '../components/MeetingAndTrainingApplyRecordDashboard';

import {
    loadUserAccounts,
  } from '../../../home/homeActions';
import {
    // query
    onQueryInputChange,
    loadMeetingAndTrainingApplyRecordInfo,
    initLoadMeetingAndTrainingApplyRecordInfoActionStatus,
    // select
    selectMeetingAndTrainingApplyRecordInfo,
    // add or edit
    openAddOrEditMeetingAndTrainingApplyRecordModal,
    addOrEditMeetingAndTrainingApplyRecordChangeInput,
    addOrEditMeetingAndTrainingApplyRecord,
    initAddOrEditMeetingAndTrainingApplyRecordActionStatus,
    resetAddOrEditMeetingAndTrainingApplyRecordModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelMeetingAndTrainingApplyRecordModal,
    delMeetingAndTrainingApplyRecordChangeInput,
    delMeetingAndTrainingApplyRecord,
    initDelMeetingAndTrainingApplyRecordActionStatus,
    resetDelMeetingAndTrainingApplyRecordModal,

    //cop
    openCopMeetingAndTrainingApplyRecordModal,
    copMeetingAndTrainingApplyRecordChangeInput,
    copMeetingAndTrainingApplyRecord,
    initCopMeetingAndTrainingApplyRecordActionStatus,
    resetCopMeetingAndTrainingApplyRecordModal,

    
    openAnswereeSelectModalDialog,
    resetAnswereeSelectModalDialog,

    // Select All Answeree
    selectAllAnsweree,

    // Select Invert Answeree
    selectInvertAnsweree,

    // Answeree Check Changed
    answereeCheckChanged,


    // Open/Reset Questionnaire Email Edit Modal Dialog
    openQuestionnaireEmailEditModalDialog,
    resetQuestionnaireEmailEditModalDialog,

      // Select Answeree
    selectAnswerees,
    initSelectAnswereesActionStatus,
    doOperationRecordAction,


} from '../MeetingAndTrainingApplyRecordActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.meetingAndTrainingApplyRecord.statusOptions,
        typeOptions: state.meetingAndTrainingApplyRecord.typeOptions,
        repeatOptions: state.meetingAndTrainingApplyRecord.repeatOptions,
        applyTypeOptions: state.meetingAndTrainingApplyRecord.applyTypeOptions,
        repeatMonthlyOptions: state.meetingAndTrainingApplyRecord.repeatMonthlyOptions,
        repeatEveryOptions: state.meetingAndTrainingApplyRecord.repeatEveryOptions,
        weekdayOptions: state.meetingAndTrainingApplyRecord.weekdayOptions,
        fundFirmRelatedOptions: state.meetingAndTrainingApplyRecord.fundFirmRelatedOptions,
        ccyOptions: state.meetingAndTrainingApplyRecord.ccyOptions,
        userAccountOptions: state.meetingAndTrainingApplyRecord.userAccountOptions,
        // meetingAndTrainingApplyRecordQueryFields
        meetingAndTrainingApplyRecordQueryFields: state.meetingAndTrainingApplyRecord.meetingAndTrainingApplyRecordQueryFields,
        // query
        loadMeetingAndTrainingApplyRecordInfoActionStatus: state.meetingAndTrainingApplyRecord.loadMeetingAndTrainingApplyRecordInfoActionStatus,
        loadMeetingAndTrainingApplyRecordInfoResp: state.meetingAndTrainingApplyRecord.loadMeetingAndTrainingApplyRecordInfoResp,
        selectedMeetingAndTrainingApplyRecordInfoArray: state.meetingAndTrainingApplyRecord.selectedMeetingAndTrainingApplyRecordInfoArray,
        meetingAndTrainingApplyRecordGridColumns: state.meetingAndTrainingApplyRecord.meetingAndTrainingApplyRecordGridColumns,
        // add or edit
        addOrEditMeetingAndTrainingApplyRecordModal: state.meetingAndTrainingApplyRecord.addOrEditMeetingAndTrainingApplyRecordModal,
        addOrEditMeetingAndTrainingApplyRecordStatus: state.meetingAndTrainingApplyRecord.addOrEditMeetingAndTrainingApplyRecordStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.meetingAndTrainingApplyRecord.refreshFxStatus,
        refreshFxResp: state.meetingAndTrainingApplyRecord.refreshFxResp,
        // del
        delMeetingAndTrainingApplyRecordModal: state.meetingAndTrainingApplyRecord.delMeetingAndTrainingApplyRecordModal,
        delMeetingAndTrainingApplyRecordStatus: state.meetingAndTrainingApplyRecord.delMeetingAndTrainingApplyRecordStatus,
        // cop
        copMeetingAndTrainingApplyRecordModal: state.meetingAndTrainingApplyRecord.copMeetingAndTrainingApplyRecordModal,
        copMeetingAndTrainingApplyRecordStatus: state.meetingAndTrainingApplyRecord.copMeetingAndTrainingApplyRecordStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        meetingAndTrainingApplyRecordUploadUrl: state.meetingAndTrainingApplyRecord.meetingAndTrainingApplyRecordUploadUrl,
        meetingAndTrainingApplyRecordDownloadUrl: state.meetingAndTrainingApplyRecord.meetingAndTrainingApplyRecordDownloadUrl,
        answereeSelectControlModal: state.meetingAndTrainingApplyRecord.answereeSelectControlModal,

        pmArray: state.meetingAndTrainingApplyRecord.pmArray,
        pmApplyRecordArray: state.meetingAndTrainingApplyRecord.pmApplyRecordArray,
        userAccountArray: state.meetingAndTrainingApplyRecord.userAccountArray,
           // Answers
        answerArray: state.meetingAndTrainingApplyRecord.answerArray,

            // Open/Reset Questionnaire Email Edit Modal Dialog
        questionnaireEmailEditControlModal: state.meetingAndTrainingApplyRecord.questionnaireEmailEditControlModal,

        // Select Answerees
        selectAnswereesActionStatus: state.meetingAndTrainingApplyRecord.selectAnswereesActionStatus,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadMeetingAndTrainingApplyRecordInfo: (params) => dispatch(loadMeetingAndTrainingApplyRecordInfo(params)),
        initLoadMeetingAndTrainingApplyRecordInfoActionStatus: () => dispatch(initLoadMeetingAndTrainingApplyRecordInfoActionStatus()),
        // select
        selectMeetingAndTrainingApplyRecordInfo: (dataArray) => dispatch(selectMeetingAndTrainingApplyRecordInfo(dataArray)),
        // add or edit
        openAddOrEditMeetingAndTrainingApplyRecordModal: (mode, data) => dispatch(openAddOrEditMeetingAndTrainingApplyRecordModal(mode, data)),
        addOrEditMeetingAndTrainingApplyRecordChangeInput: (params) => dispatch(addOrEditMeetingAndTrainingApplyRecordChangeInput(params)),
        addOrEditMeetingAndTrainingApplyRecord: (info) => dispatch(addOrEditMeetingAndTrainingApplyRecord(info)),
        initAddOrEditMeetingAndTrainingApplyRecordActionStatus: () => dispatch(initAddOrEditMeetingAndTrainingApplyRecordActionStatus()),
        resetAddOrEditMeetingAndTrainingApplyRecordModal: () => dispatch(resetAddOrEditMeetingAndTrainingApplyRecordModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelMeetingAndTrainingApplyRecordModal: (info,mode) => dispatch(openDelMeetingAndTrainingApplyRecordModal(info,mode)),
        delMeetingAndTrainingApplyRecordChangeInput: (params) => dispatch(delMeetingAndTrainingApplyRecordChangeInput(params)),
        delMeetingAndTrainingApplyRecord: (info) => dispatch(delMeetingAndTrainingApplyRecord(info)),
        initDelMeetingAndTrainingApplyRecordActionStatus: () => dispatch(initDelMeetingAndTrainingApplyRecordActionStatus()),
        resetDelMeetingAndTrainingApplyRecordModal: () => dispatch(resetDelMeetingAndTrainingApplyRecordModal()),

        // cop
        openCopMeetingAndTrainingApplyRecordModal: (info) => dispatch(openCopMeetingAndTrainingApplyRecordModal(info)),
        copMeetingAndTrainingApplyRecordChangeInput: (params) => dispatch(copMeetingAndTrainingApplyRecordChangeInput(params)),
        copMeetingAndTrainingApplyRecord: (params) => dispatch(copMeetingAndTrainingApplyRecord(params)),
        initCopMeetingAndTrainingApplyRecordActionStatus: () => dispatch(initCopMeetingAndTrainingApplyRecordActionStatus()),
        resetCopMeetingAndTrainingApplyRecordModal: () => dispatch(resetCopMeetingAndTrainingApplyRecordModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),

        openAnswereeSelectModalDialog: () => dispatch(openAnswereeSelectModalDialog()),
        resetAnswereeSelectModalDialog: () => dispatch(resetAnswereeSelectModalDialog()),

            // loadUserAccounts
        loadUserAccounts: () => dispatch(loadUserAccounts()),


        // Select All Answeree
        selectAllAnsweree: () => dispatch(selectAllAnsweree()),

        // Select Invert Answeree
        selectInvertAnsweree: () => dispatch(selectInvertAnsweree()),

        // Answeree Check Changed
        answereeCheckChanged: (checked ,userAccountName) => dispatch(answereeCheckChanged(checked ,userAccountName)),

        // Open/Reset Questionnaire Email Edit Modal Dialog
        openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
        resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),

        // Select Answeree
        selectAnswerees: (userAccountNameList, questionnaireGuid, emailTitle, emailContent) => dispatch(selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent)),

        initSelectAnswereesActionStatus: () => dispatch(initSelectAnswereesActionStatus()),

        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MeetingAndTrainingApplyRecordDashboard);