import {
  // Login
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_LOGIN_STATUS,

  // Logout
  LOGOUT_SUCCESS,
} from './authConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// Login
function startLogin() {
  return {
    type: START_LOGIN
  };
}

export function login(user) {
  const requestParams = {
    userName: user.userName,
    pwd: user.password,
    source: 'Admin',
  };

  return function(dispatch) {
    dispatch(startLogin());
    httpClient
      .post(UrlConfig.LOGIN, requestParams)
      .then(resp => {
        dispatch(loginSuccess(resp));
      })
      .catch(err => {
        dispatch(loginFailure(err));
      });
  };
}

function loginSuccess(resp) {
  return {
    type: LOGIN_SUCCESS,
    payload: resp
  };
}

function loginFailure(err) {
  return {
    type: LOGIN_FAILURE,
    payload: err
  };
}

export function resetLoginStatus() {
  return {
    type: RESET_LOGIN_STATUS,
  };
}

// Logout
// 登出成功失败都按成功处理
export function logout() {
  return function(dispatch) {
    httpClient
      .post(UrlConfig.LOGOUT)
      .then(resp => {
        dispatch(logoutSuccess());
      })
      .catch(err => {
        dispatch(logoutSuccess());
      });
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
}
