import React, { Component } from 'react';


class PersonalTradeRecognizeResult extends Component {


  render() {
    const {
      recognizePersonalTradeResultArray,
    } = this.props;
  
    return (
      <div className="daily-all-employee-monthly-report">
        <table border="1">
          <thead>
            <tr>
              <th>TradeId</th>
              <th>Trade Today</th>
              <th>Restricted List</th>
              <th>30 Days Holding</th>
              <th>Trade On Prev 1 Day</th>
              <th>Chinese Wall checking</th>
            </tr>
          </thead>
          <tbody>
              {
                recognizePersonalTradeResultArray.map((trade) => {
                  return (
                    <tr key={trade.tradeId}>
                      <td>{ trade.tradeId }</td>
                      <td>{ trade.matchActiveTradeLabel }</td>
                      <td>{ trade.matchRestrictedListReason }</td>
                      <td>{ trade.matchReportedHoldingLabel }</td>
                      <td>{ trade.matchTradeYesterdayLabel }</td>
                      <td>{ trade.matchTradeYesterdayPMLocationLabel }</td>
                    </tr>
                  );
                })
              }
          </tbody>
        </table>
      </div>
    );
  }

}

export default PersonalTradeRecognizeResult;