import {connect} from 'react-redux';
import AnswerReview from '../components/AnswerReview';

import {
  loadQuestionnaires,
  loadAnswers,
  initLoadAnswersActionStatus,

  // Load Questionnaire Results
  loadQuestionnaireResults,
  initLoadQuestionnaireResultsActionStatus,

  // Select Questionnaire Results
  selectQuestionnaireResults,

  // Accept Questionnaire Result
  acceptQuestionnaireResult,
  initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  rejectQuestionnaireResult,
  initRejectQuestionnaireResultActionStatus,

  // Open/Reset Questionnaire Result Operate Modal Dialog
  openQuestionnaireResultOperateModalDialog,
  resetQuestionnaireResultOperateModalDialog,

  // Send Remind Email
  sendRemindEmail,
  initSendRemindEmailActionStatus,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  openQuestionnaireEmailEditModalDialog,
  resetQuestionnaireEmailEditModalDialog,

  // Update Questionnaire Score
  updateQuestionnaireScore,
  initUpdateQuestionnaireScoreActionStatus,

  // Calculate Questionnaire Total Score
  calculateQuestionnaireTotalScore,

  // download pdf
  downloadQuestionnaireResultPDF,
  initDownloadQuestionnaireResultPDFActionStatus,

} from '../questionnaireActions';

const mapStateToProps = state => {
  return {
    questionnaireResultGridColumns: state.questionnaire.questionnaireResultGridColumns,
    questionAnswerGridColumns: state.questionnaire.questionAnswerGridColumns,
    answerArray: state.questionnaire.answerArray,

    questionnaireOptions: state.questionnaire.questionnaireOptions,
    userAccountOptions: state.questionnaire.userAccountOptions,
    statusAnswerOptions: state.questionnaire.statusAnswerOptions,
    loadAnswersActionStatus: state.questionnaire.loadAnswersActionStatus,

    loadQuestionnaireResultsActionStatus: state.questionnaire.loadQuestionnaireResultsActionStatus,
    questionnaireResultArray: state.questionnaire.questionnaireResultArray,

    // Select Questionnaire Results
    selectedQuestionnaireResults: state.questionnaire.selectedQuestionnaireResults,

    // Accept Questionnaire Result
    acceptQuestionnaireResultActionStatus: state.questionnaire.acceptQuestionnaireResultActionStatus,

    // Reject Questionnaire Result
    rejectQuestionnaireResultActionStatus: state.questionnaire.rejectQuestionnaireResultActionStatus,

    // Open/Reset Questionnaire Result Operate Modal Dialog
    questionnaireResultOperateControlModal: state.questionnaire.questionnaireResultOperateControlModal,

    // Send Remind Email
    sendRemindEmailActionStatus: state.questionnaire.sendRemindEmailActionStatus,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    questionnaireEmailEditControlModal: state.questionnaire.questionnaireEmailEditControlModal,

    // Open/Reset Answeree Select Modal Dialog
    answereeSelectControlModal: state.questionnaire.answereeSelectControlModal,

    // Update Questionnaire Score 
    updateQuestionnaireScoreActionStatus: state.questionnaire.updateQuestionnaireScoreActionStatus,
    updateQuestionnaireScoreErrMsg: state.questionnaire.updateQuestionnaireScoreErrMsg,

    // download pdf
    downloadQuestionnaireResultPDFActionStatus: state.questionnaire.downloadQuestionnaireResultPDFActionStatus,
    downloadQuestionnaireResultPDFActionResp: state.questionnaire.downloadQuestionnaireResultPDFActionResp,

  };
} 

const mapDispatchToProps = dispatch => {
  return {
    loadQuestionnaires: () => dispatch(loadQuestionnaires()),
    loadAnswers: (params) => dispatch(loadAnswers(params)),
    initLoadAnswersActionStatus: () => dispatch(initLoadAnswersActionStatus()),

    // Load Questionnaire Results
    loadQuestionnaireResults: (params) => dispatch(loadQuestionnaireResults(params)),
    initLoadQuestionnaireResultsActionStatus: () => dispatch(initLoadQuestionnaireResultsActionStatus()),

    // Select Questionnaire Results
    selectQuestionnaireResults: (payload) => dispatch(selectQuestionnaireResults(payload)),

    // Open/Reset Questionnaire Result Operate Modal Dialog
    openQuestionnaireResultOperateModalDialog: (opMode, questionnaireResultGuidList) => 
      dispatch(openQuestionnaireResultOperateModalDialog(opMode, questionnaireResultGuidList)),

    resetQuestionnaireResultOperateModalDialog: () => dispatch(resetQuestionnaireResultOperateModalDialog()),

    // Accept Questionnaire Result
    acceptQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
      dispatch(acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
    initAcceptQuestionnaireResultActionStatus: () => dispatch(initAcceptQuestionnaireResultActionStatus()),

    // Reject Questionnaire Result
    rejectQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
      dispatch(rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
    initRejectQuestionnaireResultActionStatus: () => dispatch(initRejectQuestionnaireResultActionStatus()),

    // Send Remind Email
    sendRemindEmail: (questionnaireResultGuidList, emailTitle, emailContent) => 
      dispatch(sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent)),
    initSendRemindEmailActionStatus: () => dispatch(initSendRemindEmailActionStatus()),

    // Open/Reset Questionnaire Email Edit Modal Dialog
    openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
    resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),

    // Update Questionnaire Score
    updateQuestionnaireScore: (questionnaireAnswer) => dispatch(updateQuestionnaireScore(questionnaireAnswer)),
    initUpdateQuestionnaireScoreActionStatus: () => dispatch(initUpdateQuestionnaireScoreActionStatus()),

    // Calculate Questionnaire Total Score
    calculateQuestionnaireTotalScore: () => dispatch(calculateQuestionnaireTotalScore()),

    // download pdf
    downloadQuestionnaireResultPDF: (guidList) => dispatch(downloadQuestionnaireResultPDF(guidList)),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerReview);