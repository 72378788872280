import {connect} from 'react-redux';
import QuestionnaireAnswerReview from '../components/QuestionnaireAnswerReview';

import {
  // Load Questionnaire Result Detail
  loadQuestionnaireResultDetail,
  initLoadQuestionnaireResultDetailActionStatus,

  // Reject Questionnaire Result
  rejectQuestionnaireResult,
  initRejectQuestionnaireResultActionStatus,
} from '../questionnaireActions';

const mapStateToProps = state => {
  return {
    // Load Questionnaire Result Detail
    loadQuestionnaireResultDetailActionStatus: state.questionnaire.loadQuestionnaireResultDetailActionStatus,
    questionnaireResultDetail: state.questionnaire.questionnaireResultDetail,

    questionAnswerList: state.questionnaire.questionAnswerList,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Questionnaire Result Detail
    loadQuestionnaireResultDetail: (questionnaireResultGuid) => dispatch(loadQuestionnaireResultDetail(questionnaireResultGuid)),
    initLoadQuestionnaireResultDetailActionStatus: () => dispatch(initLoadQuestionnaireResultDetailActionStatus()),

    // Reject Questionnaire Result
    rejectQuestionnaireResult: (questionnaireResultGuidList, opReason) => dispatch(rejectQuestionnaireResult(questionnaireResultGuidList, opReason)),
    initRejectQuestionnaireResultActionStatus: () => dispatch(initRejectQuestionnaireResultActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireAnswerReview);