import { connect } from 'react-redux';

import {
  // Load User Account
  loadUserAccounts,

  // Load Security Tickers
  loadSecurityTickers,

  // Load Fund Book Data
  loadFundBookData,

  // Load All BBG Tickers
  loadAllBbgTickers,
  
  // Load Asset Inst Class
  loadAssetInstClass,

  // Load Country Codes
  loadCountryCodes,

  loadCurrencyCodes,

  // Load Sector Codes
  loadSectorCodes,

  // get hierarchy menus
  getHierarchyMenus,

} from '../homeActions';

import {
  logout,
} from  '../../auth/authActions';


import {
  // queryPendingTasksNum
  queryPendingTasksNum,
} from '../../pendingTasks/PendingTasksActions';


import Home from '../components/Home';

function mapStateToProps(state) {
  return {
    // User Account
    userAccountArray: state.home.userAccountArray,

    // Security Tickers
    securityTickerArray: state.home.securityTickerArray,

    // Fund Book Data
    fundBookData: state.home.fundBookData,

    // hierarchy menus
    hierarchyMenus: state.home.hierarchyMenus,
    flatMenus: state.home.flatMenus,
    hierarchyMenuOptions: state.home.hierarchyMenuOptions,

    // queryPendingTasksNum
    pendingTasksNum: state.pendingTasks.pendingTasksNum,
  
    currencyCodeDOArray: state.home.currencyCodeDOArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // Load User Account
    loadUserAccounts: () => dispatch(loadUserAccounts()),

    // Load Security Tickers
    loadSecurityTickers: () => dispatch(loadSecurityTickers()),

    // Load Fund Book Data
    loadFundBookData: () => dispatch(loadFundBookData()),

    // Load All BBG Tickers
    loadAllBbgTickers: () => dispatch(loadAllBbgTickers()),

    // Load Asset Inst Class
    loadAssetInstClass: () => dispatch(loadAssetInstClass()),

    // Load Country Codes
    loadCountryCodes: () => dispatch(loadCountryCodes()),

    // Load Currency Codes
    loadCurrencyCodes: () => dispatch(loadCurrencyCodes()),

    // Load Sector Codes
    loadSectorCodes: () => dispatch(loadSectorCodes()),

    // get hierarchy menus
    getHierarchyMenus: () => dispatch(getHierarchyMenus()),

    // queryPendingTasksNum
    queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),

    logout: () => dispatch(logout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
