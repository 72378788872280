import {connect} from 'react-redux';
import BookDashboard from '../components/BookDashboard';

import {
  // Load Book List
  loadBookList,
  initLoadBookListActionStatus,
} from '../bookActions';

const mapStateToProps = state => {
  return {
    bookGridColumns: state.book.bookGridColumns,

    // Book List
    bookArray: state.book.bookArray,

    // Load Book List Action Status
    loadBookListActionStatus: state.book.loadBookListActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Book List
    loadBookList: () => dispatch(loadBookList()),
    initLoadBookListActionStatus: () => dispatch(initLoadBookListActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookDashboard);