import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import SubmittedHistoryGrid from './SubmittedHistoryGrid';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';

class SubmittedHistoryDialog extends Component {

  refreshButtonOnClicked = () => {
    const authToken = this.props.submittedQuestionnaireHistoryControlModal.authToken;
    this.props.loadSubmittedQuestionnaireHistory(authToken);
  }

  
  reviewSubmittedQuestionnaireOnClicked = () => {
    const selectSubmittedQuestionnaireHistoryArray = this.props.selectSubmittedQuestionnaireHistoryArray;

    if (selectSubmittedQuestionnaireHistoryArray.length !== 1) {
      message.warning('You can only select one questionnaire');
      return;
    }

    const submittedQuestionnaireHistory = selectSubmittedQuestionnaireHistoryArray[0];
    const authToken = submittedQuestionnaireHistory.authToken;
    const url = 'https://' + BaseUrlConfig.admin_frontend_dn + '/questionnaire-answer?authToken=' + authToken;
  
    window.open(url, '_blank');
  }


  applyBtnOnClicked = () => {
    const selectSubmittedQuestionnaireHistoryArray = this.props.selectSubmittedQuestionnaireHistoryArray;

    if (selectSubmittedQuestionnaireHistoryArray.length !== 1) {
      message.warning('You can only select one questionnaire');
      return;
    }

    const submittedQuestionnaireHistory = selectSubmittedQuestionnaireHistoryArray[0];

    this.props.applySubmittedQuestionnaireHistory(submittedQuestionnaireHistory);
    this.props.resetSubmittedQuestionnaireHistoryModalDialog();
  }

  
  render() {
    const {
      // Load Submitted Questionnaire History
      loadSubmittedQuestionnaireHistoryActionStatus,
      submittedQuestionnaireHistoryControlModal,
    } = this.props;

    const {
      isOpened,
    } = submittedQuestionnaireHistoryControlModal;

    const isLoading = loadSubmittedQuestionnaireHistoryActionStatus === ACTION_STATUS.LOGINING;

    let modalTitle = 'Submitted Questionnaire History';

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={400}
          visible={isOpened}
          onCancel={() => this.props.resetSubmittedQuestionnaireHistoryModalDialog()}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          maskClosable={false}
          footer={[
            <Button key="cancel" size='small' style={{ fontSize: 10 }} onClick={() => this.props.resetSubmittedQuestionnaireHistoryModalDialog()}>
              Cancel
            </Button>,
            <Button key="review" type="primary" size='small' style={{ fontSize: 10 }} onClick={this.reviewSubmittedQuestionnaireOnClicked}>
              Review
            </Button>,
            <Button key="apply" type="primary" size='small' style={{ fontSize: 10 }} onClick={this.applyBtnOnClicked}>
              Apply
            </Button>,
          ]}
        >
          <div style={{ marginBottom: 10 }}>
            <Button 
              onClick={this.refreshButtonOnClicked} 
              size='small'
              style={{ fontSize: 10 }}
              loading={isLoading}
              type='primary'>
              Refresh
            </Button>
          </div>

          <div style={{ height: 200 }}>
            <SubmittedHistoryGrid {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default SubmittedHistoryDialog;