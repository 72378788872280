import React, {Component, PropTypes} from 'react';

import moment from 'moment';
import { Button, Checkbox, Radio, Input, DatePicker, Upload, Icon,Select } from 'antd';

import html2pdf from 'html2pdf.js';
import { QUESTION_TYPE_CODE } from '../../../../utils/QuestionTypeUtil';

import QuestionAttatchment from './QuestionAttatchment.js';
import './QuestionnairePreview.css';
const Option = Select.Option;
class QuestionnaireAnswerReview extends Component {

  state = {
  };

  componentDidMount() {
    var questionnaireResultGuid = this.props.match.params.guid;
    this.props.loadQuestionnaireResultDetail(questionnaireResultGuid);
  }

  componentDidUpdate() {
    const {
      
    } = this.props;

  }

  downloadPdfBtnOnClicked = () => {
    const {
      questionnaireResultDetail,
    } = this.props;

    let filename = 'questionnaire-answer.pdf';
    if (questionnaireResultDetail !== null) {
      const questionnaireTitle = questionnaireResultDetail.questionnaireTitle;
      filename = questionnaireTitle + '.pdf';
    }

    let element = document.getElementById('questionnaireContent');
    let options = {
      margin: 1,
      filename,
    };
    html2pdf().set(options).from(element).save();
  }

  cancelButtonOnClicked = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      loadTodoQuestionnaireActionStatus,
      questionAnswerList,
      questionnaireResultDetail,
      

      // Questionnaire attachment Upload
      questionnaireAttachmentUploadUrl
    } = this.props;

    const {

    } = this.state;
    
    let questionnaireTitle = '';
    let questionnaireAnsweree = '';
    let questionnaireAnswerDate = '';
    let submitter = '';
    if (questionnaireResultDetail !== null) {
      questionnaireTitle = questionnaireResultDetail.questionnaireTitle;
      questionnaireAnsweree = questionnaireResultDetail.answeree;
      questionnaireAnswerDate = questionnaireResultDetail.submittedDateLabel;
      submitter = questionnaireResultDetail.submitter;
    }

    return (
      <div className='questionnaireDashboardWrapper'>
        <Button onClick={this.downloadPdfBtnOnClicked} style={{ marginTop: 10 }}>Download as pdf</Button>
        <div className='questionnaireDashboardContent' id="questionnaireContent">
              <span className='title'>
                { questionnaireTitle }
              </span>
            <div className='question-content'>
              {
                questionAnswerList.map((value, index) => {
                  return (
                    <div className='questionContainer' key={index}>
                      <div className='questionDescContainer'>
                        <span className='question-desc-index'>{value.questionNumber}.</span>
                        <div>
                          <div className='question-desc'>
                            { value.questionEnglish }
                          </div>
                          <div className='question-desc'>
                            { value.questionChinese }
                          </div>
                        </div>
                      </div>
                      <div className='answerContainer'>
                        {
                          value.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE && 
                          <Radio.Group value={value.answerContent} disabled>
                            {
                              value.optionsContent.split('|').map((valueOption, indexOption) => {
                                const key = indexOption + '_' + value.answerGuid;
                                return (
                                  <Radio className='radioStyle' value={valueOption} key={key}>{ valueOption }</Radio>
                                )
                              })
                            }
                          </Radio.Group>
                        }
                        {
                          value.questionType === QUESTION_TYPE_CODE.LIST && 
                          <Select value={value.answerContent} disabled>
                            {
                              value.optionsContent.split('|').map((valueOption, indexOption) => {
                                const key = indexOption + '_' + value.answerGuid;
                                return (
                                  <Option className='listStyle' value={valueOption} key={key}>{ valueOption }</Option>
                                )
                              })
                            }
                          </Select>
                        }
                        {
                          value.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE && 
                          <div>
                            {
                              value.optionsContent.split('|').map((valueOption, indexOption) => {
                                const key = indexOption + '_' + value.answerGuid;
                                let answerChecked = false;
                                if (value.answerContent !== null && value.answerContent !== '' && value.answerContent.includes(valueOption)) {
                                  answerChecked = true;
                                }

                                return (
                                  <Checkbox 
                                    key={key}
                                    className='checkStyle'
                                    checked={answerChecked} disabled>{ valueOption }</Checkbox>
                                )
                              })
                            }
                          </div>
                        }
                        {
                          value.questionType === QUESTION_TYPE_CODE.TEXT && 
                          <Input 
                            value={value.answerContent} 
                            style={{ width: '70%' }} disabled/>
                        }
                       {
                        value.questionType === QUESTION_TYPE_CODE.SUBJECT_LINE && 
                        
                          
                         <p> {value.answerContent}</p> 
                          
                      }
                        {
                          value.questionType === QUESTION_TYPE_CODE.DATE && 
                          <DatePicker 
                            value={value.answerContent === '' || value.answerContent === null ? null : moment(value.answerContent)   }
                            disabled />
                        }
                        {
                          value.questionType === QUESTION_TYPE_CODE.UPLOAD && 
                          <Upload
                            withCredentials={true}
                            action={questionnaireAttachmentUploadUrl}
                            fileList={value.fileList}
                            disabled>
                            <Button disabled>
                              <Icon type="upload" /> Upload
                            </Button>
                          </Upload>
                        }
                        {/* 展示附件 */}
                        {
                          (!!value.attachment && !!value.attachmentOriginName) &&
                          <QuestionAttatchment attachment={ value.attachment } attachmentOriginName={ value.attachmentOriginName } />
                        }
                      </div>
                      {/* 子问题 */}
                      <div className="sub-question-content">
                        {
                          value.subQuestionAnswerList.map((subValue, index) => {
                            return (
                              <div className='sub-question-container' key={index}>
                                <div className='sub-question-desc-container'>
                                  {/* <span className='sub-question-desc-number'>{subValue.subQuestionNumber}.</span> */}
                                  <div>
                                    <div className='sub-question-desc'>
                                      { subValue.questionEnglish }
                                    </div>
                                    <div className='sub-question-desc'>
                                      { subValue.questionChinese }
                                    </div>
                                  </div>
                                </div>
                                <div className='answerContainer'>
                                  {
                                    subValue.questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE && 
                                    <Radio.Group value={subValue.answerContent} disabled>
                                      {
                                        subValue.optionsContent.split('|').map((valueOption, indexOption) => {
                                          const key = indexOption + '_' + subValue.answerGuid;
                                          return (
                                            <Radio className='radioStyle' value={valueOption} key={key}>{ valueOption }</Radio>
                                          )
                                        })
                                      }
                                    </Radio.Group>
                                  }
                                  {
                                    subValue.questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE && 
                                    <div>
                                      {
                                        subValue.optionsContent.split('|').map((valueOption, indexOption) => {
                                          const key = indexOption + '_' + subValue.answerGuid;
                                          let answerChecked = false;
                                          if (subValue.answerContent !== null && subValue.answerContent !== '' && subValue.answerContent.includes(valueOption)) {
                                            answerChecked = true;
                                          }
          
                                          return (
                                            <Checkbox 
                                              key={key}
                                              className='checkStyle'
                                              checked={answerChecked} disabled>{ valueOption }</Checkbox>
                                          )
                                        })
                                      }
                                    </div>
                                  }
                                  {
                                    subValue.questionType === QUESTION_TYPE_CODE.TEXT && 
                                    <Input 
                                      value={subValue.answerContent} 
                                      style={{ width: '70%' }} disabled/>
                                  }
                                                        {
                        value.questionType === QUESTION_TYPE_CODE.SUBJECT_LINE && 
                        
                          
                         <p> {value.answerContent}</p> 
                          
                      }
                                  {
                                    subValue.questionType === QUESTION_TYPE_CODE.DATE && 
                                    <DatePicker 
                                      disabled
                                      value={subValue.answerContent === '' || subValue.answerContent === null ? null : moment(subValue.answerContent)   }
                                      />
                                  }
                                  {
                                    subValue.questionType === QUESTION_TYPE_CODE.UPLOAD && 
                                    <Upload
                                      withCredentials={true}
                                      action={questionnaireAttachmentUploadUrl}
                                      fileList={subValue.fileList}
                                      disabled>
                                      <Button disabled>
                                        <Icon type="upload" /> Upload
                                      </Button>
                                    </Upload>
                                  }
                                  {/* 展示附件 */}
                                  {
                                    (!!subValue.attachment && !!subValue.attachmentOriginName) &&
                                    <QuestionAttatchment attachment={ subValue.attachment } attachmentOriginName={ subValue.attachmentOriginName } />
                                  }
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className="signature-container">
              <div>
                <span>Signature:</span><br/>
                <span>签名:</span>
              </div>
              <input 
                style={{ marginTop: 10, marginLeft: 30 }}
                value={submitter} 
                disabled
                />
              <div style={{ marginTop: 10 }}>
                <span>Name 姓名: { questionnaireAnsweree }</span><br/>
                <span>Date 日期: { questionnaireAnswerDate }</span>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default QuestionnaireAnswerReview;