// change input
export const ON_ELEMENT_TYPE_QUERY_INPUT_CHANGE = 'ON_ELEMENT_TYPE_QUERY_INPUT_CHANGE';
// query
export const START_QUERY_ELEMENT_TYPE = 'START_QUERY_ELEMENT_TYPE';
export const QUERY_ELEMENT_TYPE_SUCCESS = 'QUERY_ELEMENT_TYPE_SUCCESS';
export const QUERY_ELEMENT_TYPE_FAILURE = 'QUERY_ELEMENT_TYPE_FAILURE';
export const INIT_QUERY_ELEMENT_TYPE_ACTION_STATUS = 'INIT_QUERY_ELEMENT_TYPE_ACTION_STATUS';
// select data
export const SELECT_ELEMENT_TYPE_LIST = 'SELECT_ELEMENT_TYPE_LIST';
export const SELECT_ELEMENT_TYPE_FIELD_LIST = 'SELECT_ELEMENT_TYPE_FIELD_LIST';
// add or edit element type
export const OPEN_ADD_OR_EDIT_ELEMENT_TYPE_MODAL = 'OPEN_ADD_OR_EDIT_ELEMENT_TYPE_MODAL';
export const RESET_ADD_OR_EDIT_ELEMENT_TYPE_MODAL = 'RESET_ADD_OR_EDIT_ELEMENT_TYPE_MODAL';
export const ON_ELEMENT_TYPE_ADD_OR_EDIT_INPUT_CHANGE = 'ON_ELEMENT_TYPE_ADD_OR_EDIT_INPUT_CHANGE';
export const START_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE = 'START_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE';
export const SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_SUCCESS = 'SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_SUCCESS';
export const SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_FAILURE = 'SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_FAILURE';
export const INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_ACTION_STATUS = 'INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_TYPE_ACTION_STATUS';
// del element type
export const START_DEL_ELEMENT_TYPE = 'START_DEL_ELEMENT_TYPE';
export const DEL_ELEMENT_TYPE_SUCCESS = 'DEL_ELEMENT_TYPE_SUCCESS';
export const DEL_ELEMENT_TYPE_FAILURE = 'DEL_ELEMENT_TYPE_FAILURE';
export const INIT_DEL_ELEMENT_TYPE_ACTION_STATUS = 'INIT_DEL_ELEMENT_TYPE_ACTION_STATUS';
// add or edit field
export const OPEN_ADD_OR_EDIT_FIELD_MODAL = 'OPEN_ADD_OR_EDIT_FIELD_MODAL';
export const RESET_ADD_OR_EDIT_FIELD_MODAL = 'RESET_ADD_OR_EDIT_FIELD_MODAL';
export const ON_FIELD_ADD_OR_EDIT_INPUT_CHANGE = 'ON_FIELD_ADD_OR_EDIT_INPUT_CHANGE';
export const START_SUBMIT_ADD_OR_EDIT_FIELD = 'START_SUBMIT_ADD_OR_EDIT_FIELD';
export const SUBMIT_ADD_OR_EDIT_FIELD_SUCCESS = 'SUBMIT_ADD_OR_EDIT_FIELD_SUCCESS';
export const SUBMIT_ADD_OR_EDIT_FIELD_FAILURE = 'SUBMIT_ADD_OR_EDIT_FIELD_FAILURE';
export const INIT_SUBMIT_ADD_OR_EDIT_FIELD_ACTION_STATUS = 'INIT_SUBMIT_ADD_OR_EDIT_FIELD_ACTION_STATUS';
// del field
export const START_DEL_ELEMENT_TYPE_FIELD = 'START_DEL_ELEMENT_TYPE_FIELD';
export const DEL_ELEMENT_TYPE_FIELD_SUCCESS = 'DEL_ELEMENT_TYPE_FIELD_SUCCESS';
export const DEL_ELEMENT_TYPE_FIELD_FAILURE = 'DEL_ELEMENT_TYPE_FIELD_FAILURE';
export const INIT_DEL_ELEMENT_TYPE_FIELD_ACTION_STATUS = 'INIT_DEL_ELEMENT_TYPE_FIELD_ACTION_STATUS';
