import {connect} from 'react-redux';
import LicensingDashboard from '../components/LicensingDashboard';

import {
    // loadLicenseBasicInfoOptions
    loadLicenseBasicInfoOptions,
    initLoadLicenseBasicInfoOptionsActionStatus,
    // onLicensingQueryInputChange
    onLicensingQueryInputChange,
    // loadLicensingInfo
    loadLicensingInfo,
    initLoadLicensingInfoActionStatus,
    // add or edit license info
    openAddOrEditLicenseModal,
    addOrEditLicenseChangeInput,
    // addOrEditLicenseEmpRequireRcd
    addOrEditLicenseEmpRequireRcd,
    initAddOrEditLicenseEmpRequireRcdActionStatus,
    resetSubmitSaveLicenseModal,
    // selectLicensingData
    selectLicensingData,
    // batchDelLicenseInfo
    openDelLicenseModal,
    delLicenseChangeInput,
    batchDelLicenseInfo,
    initBatchDelLicenseInfoActionStatus,
    resetDelLicenseModal,
    // selectCptRcdData
    selectCptRcdData,
    // openEditCptRcdModal
    openEditCptRcdModal,
    // editCptRcdModalChangeInput
    editCptRcdModalChangeInput,
    // edit cpt rcd
    submitEditCptRcd,
    initSubmitEditCptRcdActionStatus,
    resetEditCptRcdModal,
    // op rcd
    openOperationRcdModal,
    queryOperationRcd,
    initQueryOperationRcdActionStatus,
    resetOperationRcdModal,


} from '../LicensingActions';


// import {
//     clearEmployeeNameList,
//     fuzzySearchEmployee,
// } from '../../approvalRequest/personalAccount/personalAccountActions';


import {
    queryPendingTasksNum,
} from '../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
    return {
        // userAccountOptions
        

        // loadLicenseBasicInfoOptions
        loadLicenseBasicInfoOptionsStatus: state.licensing.loadLicenseBasicInfoOptionsStatus,
        licenseBasicInfoOptions: state.licensing.licenseBasicInfoOptions,
        // onLicensingQueryInputChange
        licensingQuerySearchFields: state.licensing.licensingQuerySearchFields,
        // loadLicensingInfo
        loadLicensingInfoActionStatus: state.licensing.loadLicensingInfoActionStatus,
        loadLicensingInfoResp: state.licensing.loadLicensingInfoResp,
        // selectedLicenseArray
        selectedLicenseArray: state.licensing.selectedLicenseArray,
        // add or edit license info
        addOrEditLicenseModal: state.licensing.addOrEditLicenseModal,
        // upload attachment
        licensingExamCPTProofUploadUrl: state.licensing.licensingExamCPTProofUploadUrl,
        // addOrEditLicenseEmpRequireRcd
        addOrEditLicenseEmpRequiredRcdStatus: state.licensing.addOrEditLicenseEmpRequiredRcdStatus,
        // grid columns
        licensingGridColumns: state.licensing.licensingGridColumns,
        licensingCPTYearRcdGridColumns: state.licensing.licensingCPTYearRcdGridColumns,
        // batchDelLicenseInfo
        delLicenseModal: state.licensing.delLicenseModal,
        batchDelLicenseInfoStatus: state.licensing.batchDelLicenseInfoStatus,
        batchDelLicenseInfoResp: state.licensing.batchDelLicenseInfoResp,
        // selectCptRcdData
        selectedCptRcdDataArray: state.licensing.selectedCptRcdDataArray,
        // editCptRcdModal
        editCptRcdModal: state.licensing.editCptRcdModal,
        // edit cpt rcd
        submitEditCptRcdStatus: state.licensing.submitEditCptRcdStatus,
        // op rcd
        operationRcdModal: state.licensing.operationRcdModal,
        queryOperationRcdStatus: state.licensing.queryOperationRcdStatus,
        queryOperationRcdResp: state.licensing.queryOperationRcdResp,
        operationRcdGridColumns: state.licensing.operationRcdGridColumns,
        // employeeNameList
        // fuzzyQueryEmployeeNameOptions4Search: state.personalAccount.fuzzyQueryEmployeeNameOptions.personalTrade4Search,
        // fuzzyQueryEmployeeNameOptions4AddOrEdit: state.personalAccount.fuzzyQueryEmployeeNameOptions.personalTrade4AddOrEdit,

        // Load User Account
        userAccountOptions: state.restrictedSecurityRequest.userAccountOptions,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // loadLicenseBasicInfoOptions
        loadLicenseBasicInfoOptions: () => dispatch(loadLicenseBasicInfoOptions()),
        initLoadLicenseBasicInfoOptionsActionStatus: () => dispatch(initLoadLicenseBasicInfoOptionsActionStatus()),
        // onLicensingQueryInputChange
        onLicensingQueryInputChange: (params) => dispatch(onLicensingQueryInputChange(params)),
        // loadLicensingInfo
        loadLicensingInfo: (params) => dispatch(loadLicensingInfo(params)),
        initLoadLicensingInfoActionStatus: () => dispatch(initLoadLicensingInfoActionStatus()),
        // add or edit license info
        openAddOrEditLicenseModal: (mode, licenseInfo) =>  dispatch(openAddOrEditLicenseModal(mode, licenseInfo)),
        addOrEditLicenseChangeInput: (param) => dispatch(addOrEditLicenseChangeInput(param)),
        // addOrEditLicenseEmpRequireRcd
        addOrEditLicenseEmpRequireRcd: (params) => dispatch(addOrEditLicenseEmpRequireRcd(params)),
        initAddOrEditLicenseEmpRequireRcdActionStatus: () => dispatch(initAddOrEditLicenseEmpRequireRcdActionStatus()),
        resetSubmitSaveLicenseModal: () => dispatch(resetSubmitSaveLicenseModal()),
        // selectLicensingData
        selectLicensingData: (array) => dispatch(selectLicensingData(array)),
        // batchDelLicenseInfo
        openDelLicenseModal: (mode) => dispatch(openDelLicenseModal(mode)),
        delLicenseChangeInput: (params) => dispatch(delLicenseChangeInput(params)),
        batchDelLicenseInfo: (list, comments,mode) => dispatch(batchDelLicenseInfo(list, comments,mode)),
        initBatchDelLicenseInfoActionStatus: () => dispatch(initBatchDelLicenseInfoActionStatus()),
        resetDelLicenseModal: () => dispatch(resetDelLicenseModal()),
        // selectCptRcdData
        selectCptRcdData: (array) => dispatch(selectCptRcdData(array)),
        // openEditCptRcdModal
        openEditCptRcdModal: (cptRcd, employee, licenseBasic) => dispatch(openEditCptRcdModal(cptRcd, employee, licenseBasic)),
        // editCptRcdModalChangeInput
        editCptRcdModalChangeInput: (params) => dispatch(editCptRcdModalChangeInput(params)),
        // edit cpt rcd
        submitEditCptRcd: (rcd) => dispatch(submitEditCptRcd(rcd)),
        initSubmitEditCptRcdActionStatus: () => dispatch(initSubmitEditCptRcdActionStatus()),
        resetEditCptRcdModal: () => dispatch(resetEditCptRcdModal()),
        // op rcd
        openOperationRcdModal: () => dispatch(openOperationRcdModal()),
        queryOperationRcd: () => dispatch(queryOperationRcd()),
        initQueryOperationRcdActionStatus: () => dispatch(initQueryOperationRcdActionStatus()),
        resetOperationRcdModal: () => dispatch(resetOperationRcdModal()),
        // employeeNameList
        // clearEmployeeNameList: (module) => dispatch(clearEmployeeNameList(module)),
        // fuzzySearchEmployee: (module, namePart) => dispatch(fuzzySearchEmployee(module, namePart)),

        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(LicensingDashboard);
