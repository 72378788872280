import {connect} from 'react-redux';
import FundDashboard from '../components/FundDashboard';

import {
  // Load Fund List
  loadFundList,
  initLoadFundListActionStatus,
} from '../fundActions';

const mapStateToProps = state => {
  return {
    fundGridColumns: state.fund.fundGridColumns,

    // Fund
    fundArray: state.fund.fundArray,

    // Load Fund List Action Status
    loadFundListActionStatus: state.fund.loadFundActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Fund List
    loadFundList: () => dispatch(loadFundList()),
    initLoadFundListActionStatus: () => dispatch(initLoadFundListActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundDashboard);