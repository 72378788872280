import React, { Component } from 'react';
import { Input, Form, Modal, Button, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelMeetingAndTrainingCalenderModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingCalenderQueryFields;
        this.props.loadMeetingAndTrainingCalenderInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'inactive') {
            return 'Rest Info';
        }  else {
            return 'Note Info';
        }
    }
    componentDidUpdate() {
        const {
            delMeetingAndTrainingCalenderStatus,
        } = this.props;
        if(delMeetingAndTrainingCalenderStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delMeetingAndTrainingCalenderModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelMeetingAndTrainingCalenderActionStatus();
            this.props.resetDelMeetingAndTrainingCalenderModal();
            this.refreshQuery();
        }
        if(delMeetingAndTrainingCalenderStatus === ACTION_STATUS.ERROR) {
            this.props.initDelMeetingAndTrainingCalenderActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delMeetingAndTrainingCalenderChangeInput({name, value});
    }


    submitDelMeetingAndTrainingCalender = () => {

        Modal.confirm({
            title: 'Are you sure submit ?',
            onOk: () => {

                const {
                    mode,
                } = this.props.delMeetingAndTrainingCalenderModal;
                const {
                    id,
                    comments,
                } = this.props.delMeetingAndTrainingCalenderModal.fields;
                this.props.delMeetingAndTrainingCalender({id, mode, comments});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delMeetingAndTrainingCalenderModal,
            delMeetingAndTrainingCalenderStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delMeetingAndTrainingCalenderModal;


        const isSubmitLoading = delMeetingAndTrainingCalenderStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={800}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelMeetingAndTrainingCalenderModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelMeetingAndTrainingCalenderModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelMeetingAndTrainingCalender}>
                          Submit
                        </Button>,
                      ]}
                >
                        <TextArea 
                            style={{ width: 700 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelMeetingAndTrainingCalenderModalDialogForm = Form.create({ name: 'DelMeetingAndTrainingCalenderModalDialog' })(DelMeetingAndTrainingCalenderModalDialog);
export default DelMeetingAndTrainingCalenderModalDialogForm;
