import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


class ElementMngGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
                {field: 'elementName', headerName: 'elementName', cellClass: 'non-number', width: 200, checkboxSelection: true, cellRenderer: 'agGroupCellRenderer' },
                {field: 'elementType.baseType', headerName: 'baseType', cellClass: 'non-number', width: 150 },
                {field: 'elementType.elementTypeName', headerName: 'elementTypeName', cellClass: 'non-number', width: 200 },
                {field: 'createTime', headerName: 'createTime', cellClass: 'non-number', width: 150 },
                {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 150 },
                {field: 'updateTime', headerName: 'updateTime', cellClass: 'non-number', width: 150 },
                {field: 'updatedBy', headerName: 'updatedBy', cellClass: 'non-number', width: 150 },
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50,
            },
            rowSelection: 'single',
            context: { componentParent: this },

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {headerName: '', cellClass: 'non-number', width: 50, checkboxSelection: this.fieldCheckBoxSelection},
                        {field: 'elementTypeField.isConstraint', headerName: 'isConstraint', cellClass: 'non-number', width: 100},
                        {field: 'elementTypeField.fieldName', headerName: 'fieldName', cellClass: 'non-number', width: 150},
                        {field: 'value', headerName: 'value', cellClass: 'non-number', width: 200},
                        {field: 'createTime', headerName: 'createTime', cellClass: 'non-number', width: 150 },
                        {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 150 },
                        {field: 'updateTime', headerName: 'updateTime', cellClass: 'non-number', width: 150 },
                        {field: 'updatedBy', headerName: 'updatedBy', cellClass: 'non-number', width: 150 },
                    ],
                    rowSelection: 'single',
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    isRowSelectable: this.fieldCheckBoxSelection,
                    onSelectionChanged(node) {
                        const rowNodeArray = node.api.getSelectedNodes();
                        let elementPropsList = rowNodeArray.map(e => e.data);
                        that.props.selectElementPropsList(elementPropsList);
                    },

                },

                getDetailRowData: function(params) {
                    params.successCallback(params.data.elementPropsList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">Props</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                "</div>"
            },

        };
    }


    fieldCheckBoxSelection = (node) => {
        return node.data.elementTypeField.isConstraint === 'N';
    }


    getRowNodeId = data => {
        return data.id;
    };


    onSelectionChanged = (node) => {
        let rowNodeArray = node.api.getSelectedNodes();
        let elementList = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectElementList(elementList);
    }



    render() {
        const {
            queryElementActionStatus,
            queryElementActionResp,
        } = this.props;

        const isLoading = queryElementActionStatus === ACTION_STATUS.LOGINING;

        let presentDataArray = [];
        if(!!queryElementActionResp && !!queryElementActionResp.data) {
            presentDataArray = queryElementActionResp.data;
        }

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={presentDataArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onSelectionChanged={this.onSelectionChanged}
                        />
                    )}
            </div>
        );
    }

}


export default ElementMngGrid;
