import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from './GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../utils/QuestionnaireEmailSendMode';
import moment from 'moment';
import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_MEETING_AND_TRAINING_INFO,
    LOAD_MEETING_AND_TRAINING_INFO_SUCCESS,
    LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
    INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    // select
    SELECT_MEETING_AND_TRAINING_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_ADD_OR_EDIT_MEETING_AND_TRAINING,
    ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
    ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
    INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_MEETING_AND_TRAINING_MODAL,
    DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_DEL_MEETING_AND_TRAINING,
    DEL_MEETING_AND_TRAINING_SUCCESS,
    DEL_MEETING_AND_TRAINING_FAILURE,
    INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_DEL_MEETING_AND_TRAINING_MODAL,

    // del
    OPEN_COP_MEETING_AND_TRAINING_MODAL,
    COP_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_COP_MEETING_AND_TRAINING,
    COP_MEETING_AND_TRAINING_SUCCESS,
    COP_MEETING_AND_TRAINING_FAILURE,
    INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_COP_MEETING_AND_TRAINING_MODAL,


    ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    ANSWEREE_SELECT_MODAL_DIALOG_RESET,

    
    // Select All Answeree
    SELECT_ALL_ANSWEREE,

    // Select Invert Answeree
    SELECT_INVERT_ANSWEREE,

    // Answeree Check Changed
    ANSWEREE_CHECK_CHANGED,
    
      // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,




    // Select Answerees
    START_SELECT_ANSWEREES,
    SELECT_ANSWEREES_SUCCESS,
    SELECT_ANSWEREES_FAILURE,
    INIT_SELECT_ANSWEREES_ACTION_STATUS,

} from './MeetingAndTrainingConstants';

import {
    // Load Security Tickers
    LOAD_SECURITY_TICKERS_SUCCESS,
  
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  
  } from '../../home/homeConstants';
import {
    LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS,
} from '../meetingAndTrainingGroup/MeetingAndTrainingGroupConstants';
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={'New'}>{'New'}</Option>);
statusOptions.push(<Option key={'Active'}>{'Active'}</Option>);
statusOptions.push(<Option key={'Inactive'}>{'Inactive'}</Option>);

const repeatOptions = [];
repeatOptions.push(<Option key={'Never'}>{'Never'}</Option>);
repeatOptions.push(<Option key={'Daily'}>{'Daily'}</Option>);
repeatOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);
repeatOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);

const repeatMonthlyOptions = [];
repeatMonthlyOptions.push(<Option key={'Repeat by date'}>{'Repeat by date'}</Option>);
repeatMonthlyOptions.push(<Option key={'Repeat weekly'}>{'Repeat weekly'}</Option>);

const repeatEveryOptions = [];
repeatEveryOptions.push(<Option key={'1'}>{'1'}</Option>);
repeatEveryOptions.push(<Option key={'2'}>{'2'}</Option>);
repeatEveryOptions.push(<Option key={'3'}>{'3'}</Option>);
repeatEveryOptions.push(<Option key={'4'}>{'4'}</Option>);
repeatEveryOptions.push(<Option key={'5'}>{'5'}</Option>);
repeatEveryOptions.push(<Option key={'6'}>{'6'}</Option>);
repeatEveryOptions.push(<Option key={'7'}>{'7'}</Option>);
repeatEveryOptions.push(<Option key={'8'}>{'8'}</Option>);
repeatEveryOptions.push(<Option key={'9'}>{'9'}</Option>);
repeatEveryOptions.push(<Option key={'10'}>{'10'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'7'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'1'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'2'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'3'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'4'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'5'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'6'}>{'Sat'}</Option>);

const typeOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
typeOptions.push(<Option key={'CIO Tutorial'}>{'CIO Tutorial'}</Option>);
typeOptions.push(<Option key={'Sell Side Sector Training'}>{'Sell Side Sector Training'}</Option>);
typeOptions.push(<Option key={'Group Training'}>{'Group Training'}</Option>);
typeOptions.push(<Option key={'Modeling Class'}>{'Modeling Class'}</Option>);
typeOptions.push(<Option key={'Macro and Strategy Training'}>{'Macro and Strategy Training'}</Option>);
typeOptions.push(<Option key={'Buy Side Sharing'}>{'Buy Side Sharing'}</Option>);
typeOptions.push(<Option key={'Sale Side Meeting'}>{'Sale Side Meeting'}</Option>);
typeOptions.push(<Option key={'Other'}>{'Other'}</Option>);

const categoryOptions = [];
categoryOptions.push(<Option key={'Mandatory'}>{'Mandatory'}</Option>);
categoryOptions.push(<Option key={'Optional'}>{'Optional'}</Option>);

const optonalOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
optonalOptions.push(<Option key={'required'}>{'required'}</Option>);
optonalOptions.push(<Option key={'optonal'}>{'optonal'}</Option>);

const saleSideOptions = [];
saleSideOptions.push(<Option key={'Antique'}>{'Antique'}</Option>);
saleSideOptions.push(<Option key={'AnXin'}>{'AnXin'}</Option>);
saleSideOptions.push(<Option key={'Axis'}>{'Axis'}</Option>);
saleSideOptions.push(<Option key={'B&K'}>{'B&K'}</Option>);
saleSideOptions.push(<Option key={'BNP'}>{'BNP'}</Option>);
saleSideOptions.push(<Option key={'Cathay'}>{'Cathay'}</Option>);
saleSideOptions.push(<Option key={'ChangJiang'}>{'ChangJiang'}</Option>);
saleSideOptions.push(<Option key={'China Renaissance'}>{'China Renaissance'}</Option>);
saleSideOptions.push(<Option key={'CICC'}>{'CICC'}</Option>);
saleSideOptions.push(<Option key={'Citi'}>{'Citi'}</Option>);
saleSideOptions.push(<Option key={'CITIC'}>{'Citic + CLSA'}</Option>);
saleSideOptions.push(<Option key={'CLSA'}>{'CLSA'}</Option>);
saleSideOptions.push(<Option key={'CM'}>{'China Merchant'}</Option>);
saleSideOptions.push(<Option key={'Credit Suisse'}>{'Credit Suisse'}</Option>);
saleSideOptions.push(<Option key={'CS'}>{'Credit Suisse'}</Option>);
saleSideOptions.push(<Option key={'Daiwa'}>{'Daiwa'}</Option>);
saleSideOptions.push(<Option key={'DBS'}>{'DBS'}</Option>);
saleSideOptions.push(<Option key={'Dongbei'}>{'Dongbei'}</Option>);
saleSideOptions.push(<Option key={'DongWu'}>{'DongWu'}</Option>);
saleSideOptions.push(<Option key={'EDEL'}>{'EDEL'}</Option>);
saleSideOptions.push(<Option key={'Emkay'}>{'Emkay'}</Option>);
saleSideOptions.push(<Option key={'GS'}>{'Goldman Sachs'}</Option>);
saleSideOptions.push(<Option key={'GTJA'}>{'GTJA'}</Option>);
saleSideOptions.push(<Option key={'Guang Fa'}>{'Guang Fa'}</Option>);
saleSideOptions.push(<Option key={'Guangda'}>{'Guangda'}</Option>);
saleSideOptions.push(<Option key={'GuoJin'}>{'GuoJin'}</Option>);
saleSideOptions.push(<Option key={'GuoSheng'}>{'GuoSheng'}</Option>);
saleSideOptions.push(<Option key={'Haitong'}>{'Haitong'}</Option>);
saleSideOptions.push(<Option key={'HSBC'}>{'HSBC'}</Option>);
saleSideOptions.push(<Option key={'HUATAI'}>{'HUATAI'}</Option>);
saleSideOptions.push(<Option key={'Ichiyoshi'}>{'Ichiyoshi'}</Option>);
saleSideOptions.push(<Option key={'Jefferies'}>{'Jefferies'}</Option>);
saleSideOptions.push(<Option key={'JPM'}>{'JP Morgan'}</Option>);
saleSideOptions.push(<Option key={'KIS'}>{'KIS'}</Option>);
saleSideOptions.push(<Option key={'Kotak'}>{'Kotak'}</Option>);
saleSideOptions.push(<Option key={'Macquarie'}>{'Macquarie'}</Option>);
saleSideOptions.push(<Option key={'Mizuho'}>{'Mizuho'}</Option>);
saleSideOptions.push(<Option key={'ML'}>{'Merrill Lynch'}</Option>);
saleSideOptions.push(<Option key={'MLAP'}>{'MLAP'}</Option>);
saleSideOptions.push(<Option key={'Motilal'}>{'Motilal'}</Option>);
saleSideOptions.push(<Option key={'MS'}>{'Morgan Stanley'}</Option>);
saleSideOptions.push(<Option key={'MUFG'}>{'MUFG'}</Option>);
saleSideOptions.push(<Option key={'NOMURA'}>{'Nomura'}</Option>);
saleSideOptions.push(<Option key={'Orient Securities'}>{'Orient Securities'}</Option>);
saleSideOptions.push(<Option key={'Phillip Capital'}>{'Phillip Capital'}</Option>);
saleSideOptions.push(<Option key={'Samsung'}>{'Samsung'}</Option>);
saleSideOptions.push(<Option key={'SBI'}>{'SBI'}</Option>);
saleSideOptions.push(<Option key={'SMBC'}>{'SMBC'}</Option>);
saleSideOptions.push(<Option key={'Soc Gen'}>{'SocGen'}</Option>);
saleSideOptions.push(<Option key={'Spark'}>{'Spark'}</Option>);
saleSideOptions.push(<Option key={'TF'}>{'TF'}</Option>);
saleSideOptions.push(<Option key={'Tokai tokyo'}>{'Tokai tokyo'}</Option>);
saleSideOptions.push(<Option key={'UBS'}>{'UBS'}</Option>);
saleSideOptions.push(<Option key={'Zhongxinjiantou'}>{'Zhongxinjiantou'}</Option>);
                                        
                                        


const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {

    meetingAndTrainingUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_UPLOAD_URL,
    meetingAndTrainingDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,
    // meetingAndTrainingUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_UPLOAD_URL,
    // meetingAndTrainingDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,

    
    meetingAndTrainingGridColumns: GridColumnMap.meetingAndTrainingGridColumns,

    statusOptions: statusOptions,
    typeOptions: typeOptions,
    categoryOptions: categoryOptions,
    optonalOptions: optonalOptions,
    saleSideOptions: saleSideOptions,
    weekdayOptions: weekdayOptions,
    repeatEveryOptions: repeatEveryOptions,
    repeatOptions: repeatOptions,
    repeatMonthlyOptions: repeatMonthlyOptions,
    fundFirmRelatedOptions: fundFirmRelatedOptions,
    ccyOptions: ccyOptions,

    meetingAndTrainingQueryFields: {
        userName: '',
        status: ''
    },
    selectedMeetingAndTrainingInfoArray: [],
    addOrEditMeetingAndTrainingModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            category: '',
            firm: '',
            feedback: false,
            remark: true,
            sendmail: false,
            speaker:'',
            fundFirmRelated: '',
            invDate: '',
            starttime: '',
            endtime: '',
            invNo: '',
            ccy: '',
            amount: '',
            fx: '',
            amtUSD: '',
            payDate: '',
            nature: '',
            remarks: '',
            payMethod: '',
            comments: '',
        },
    },
    delMeetingAndTrainingModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
        }
    },
    copMeetingAndTrainingModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
            employee: null,
            comments: '',

        }
    },
    // Load User Account
      userAccountArray: [],
   //   userAccountOptions: [],
   answereeSelectControlModal: {
    isOpened: false,
    respErrMsg: '',
  },


  userAccountArray: [],
  pmArray: [],
  pmGroupArray: [],
  userAccountOptions: [],
  userGroupOptions: [],
  // Open/Reset Questionnaire Email Edit Modal Dialog
  questionnaireEmailEditControlModal: {
    isOpened: false,
    sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
    emailPayload: null,
    respErrMsg: '',
  },
  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

};


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.meetingAndTrainingQueryFields,
        [name]: value,
    };
    return {
        ...state,
        meetingAndTrainingQueryFields: upatedFields,
    }
}
function startLoadMeetingAndTrainingInfo(state) {
    return {
      ...state,
      loadMeetingAndTrainingInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadMeetingAndTrainingInfoSuccess(state, resp) {
    return {
        ...state,
        loadMeetingAndTrainingInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadMeetingAndTrainingInfoResp: resp,
    };
}
function loadMeetingAndTrainingInfoFailure(state, err) {
    return {
        ...state,
        loadMeetingAndTrainingInfoActionStatus: ACTION_STATUS.ERROR,
        loadMeetingAndTrainingInfoResp: err,
    };
}
function initLoadMeetingAndTrainingInfoActionStatus(state) {
    return {
        ...state,
        loadMeetingAndTrainingInfoActionStatus: ACTION_STATUS.READY,
        selectedMeetingAndTrainingInfoArray: [],
    };
}


// select
function selectMeetingAndTrainingInfo(state, array) {

    const pmGroupArray = state.pmGroupArray;
    const answerArray = state.answerArray;
  
    // if (pmGroupArray.length > 0 && answerArray.length > 0 && array.length > 0) {
    //   pmGroupArray.forEach(pmArray => {
    //     pmArray.forEach(userAccount => {
    //       const filterAnswereeArray = answerArray.filter(answer => answer.questionnaireGuid === array[0].id).map(answer => answer.answeree);
    //       if (filterAnswereeArray.includes(userAccount.userAccountName)) {
    //         userAccount.checked = true;
    //       }
    //     })
    //   });
    // }



    
    return {
        ...state,
        selectedMeetingAndTrainingInfoArray: array,
    }
}


// add or edit
function openAddOrEditMeetingAndTrainingModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditMeetingAndTrainingModal: {
                ...state.addOrEditMeetingAndTrainingModal,
                isOpened: true,
                starttime: moment().format("YYYY-MM-DD HH:mm"),
                endtime: moment().format("YYYY-MM-DD HH:mm"),
                mode,
            },
        };
    } else {
        return {
            ...state,
            addOrEditMeetingAndTrainingModal: {
                ...state.addOrEditMeetingAndTrainingModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditMeetingAndTrainingModal.fields,
                    id: data.id,
                    title: data.title,
                    desc: data.desc,
                    type: data.type,
                    broker: data.broker,
                    outsideemail: data.outsideemail,
                    category: data.category,
                    feedback: data.feedback,
                    speaker: data.speaker,
                    remark: data.remark,
                    sendmail: data.sendmail,
                    comperename: data.comperename,
                    starttime: data.starttime,
                    endtime: data.endtime,
                    score: data.score,
                    address: data.address,
                    requiredattendees: data.requiredattendees,
                    optionalattendees: data.optionalattendees,
                    repeat: data.repeat,
                    repeatevery: data.repeatevery,
                    week: data.week,
                    state: data.state,
                    
                }
            },
        };
    }
}
function addOrEditMeetingAndTrainingChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditMeetingAndTrainingModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditMeetingAndTrainingModal: {
          ...state.addOrEditMeetingAndTrainingModal,
          fields: updatedFields,
        },
    };
}
function startAddOrEditMeetingAndTraining(state) {
    return {
      ...state,
      addOrEditMeetingAndTrainingStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditMeetingAndTrainingSuccess(state, resp) {
    return {
        ...state,
        addOrEditMeetingAndTrainingStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditMeetingAndTrainingFailure(state, err) {
    return {
        ...state,
        addOrEditMeetingAndTrainingStatus: ACTION_STATUS.ERROR,
        addOrEditMeetingAndTrainingModal: {
            ...state.addOrEditMeetingAndTrainingModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditMeetingAndTrainingActionStatus(state) {
    return {
        ...state,
        addOrEditMeetingAndTrainingStatus: ACTION_STATUS.READY,
    };
}
function resetAddOrEditMeetingAndTrainingModal(state) {
    return {
        ...state,
        addOrEditMeetingAndTrainingModal: initialState.addOrEditMeetingAndTrainingModal,
    }
}


// refresh fx and amtUSD
function startRefreshFx(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.LOGINING,
    };
}
function refreshFxSuccess(state, resp) {
    const fx = (!!resp && !!resp.data && !!resp.data.last) ? resp.data.last : '';
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.SUCCESS,
        addOrEditMeetingAndTrainingModal: {
            ...state.addOrEditMeetingAndTrainingModal,
            fields: {
                ...state.addOrEditMeetingAndTrainingModal.fields,
                fx: fx,
            },
        }
    }
}
function refreshFxFailure(state, err) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.ERROR,
        refreshFxResp: err,
        addOrEditMeetingAndTrainingModal: {
            ...state.addOrEditMeetingAndTrainingModal,
            fields: {
                ...state.addOrEditMeetingAndTrainingModal.fields,
                fx: '',
            },
        }
    }
}
function initRefreshFxActionStatus(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.READY,
    };
}
function refreshAmtUSD(state, amtUSD) {
    return {
        ...state,
        addOrEditMeetingAndTrainingModal: {
            ...state.addOrEditMeetingAndTrainingModal,
            fields: {
                ...state.addOrEditMeetingAndTrainingModal.fields,
                amtUSD: amtUSD,
            },
        }
    };
}


// del
function openDelMeetingAndTrainingModal(state, {info,mode}) {
    return {
        ...state,
        delMeetingAndTrainingModal: {
            ...state.delMeetingAndTrainingModal,
            isOpened: true,
            mode:mode,
            fields: {
                ...state.delMeetingAndTrainingModal.fields,
                id: info.id,
                
            }
        }
    }
}
function delMeetingAndTrainingChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.delMeetingAndTrainingModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delMeetingAndTrainingModal: {
          ...state.delMeetingAndTrainingModal,
          fields: updatedFields,
        },
    };
}
function startDelMeetingAndTraining(state) {
    return {
      ...state,
      delMeetingAndTrainingStatus: ACTION_STATUS.LOGINING,
    };
}
function delMeetingAndTrainingSuccess(state, resp) {
    return {
        ...state,
        delMeetingAndTrainingStatus: ACTION_STATUS.SUCCESS,
    };
}
function delMeetingAndTrainingFailure(state, err) {
    return {
        ...state,
        delMeetingAndTrainingStatus: ACTION_STATUS.ERROR,
        delMeetingAndTrainingModal: {
            ...state.delMeetingAndTrainingModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelMeetingAndTrainingActionStatus(state) {
    return {
        ...state,
        delMeetingAndTrainingStatus: ACTION_STATUS.READY,
    };
}
function resetDelMeetingAndTrainingModal(state) {
    return {
        ...state,
        delMeetingAndTrainingModal: initialState.delMeetingAndTrainingModal,
    }
}



// cop
function openCopMeetingAndTrainingModal(state, payload) {
    return {
        ...state,
        copMeetingAndTrainingModal: {
            ...state.copMeetingAndTrainingModal,
            isOpened: true,
            fields: {
                ...state.copMeetingAndTrainingModal.fields,
                id: payload.id,
            }
        }
    }
}
function copMeetingAndTrainingChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.copMeetingAndTrainingModal.fields,
        [name]: value,
    };
    return {
        ...state,
        copMeetingAndTrainingModal: {
          ...state.copMeetingAndTrainingModal,
          fields: updatedFields,
        },
    };
}
function startCopMeetingAndTraining(state) {
    return {
      ...state,
      copMeetingAndTrainingStatus: ACTION_STATUS.LOGINING,
    };
}
function copMeetingAndTrainingSuccess(state, resp) {
    return {
        ...state,
        copMeetingAndTrainingStatus: ACTION_STATUS.SUCCESS,
    };
}
function copMeetingAndTrainingFailure(state, err) {
    return {
        ...state,
        copMeetingAndTrainingStatus: ACTION_STATUS.ERROR,
        copMeetingAndTrainingModal: {
            ...state.copMeetingAndTrainingModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initCopMeetingAndTrainingActionStatus(state) {
    return {
        ...state,
        copMeetingAndTrainingStatus: ACTION_STATUS.READY,
    };
}
function resetCopMeetingAndTrainingModal(state) {
    return {
        ...state,
        copMeetingAndTrainingModal: initialState.copMeetingAndTrainingModal,
    }
}



// Load User Account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    let pmArray = [];
  let pmGroupArray = [];
  let rowSize = 4;
  if (userAccountArray.length > 0) {
    pmArray = userAccountArray
    .filter(userAccount => !!userAccount.isActive)
    .filter(userAccount =>  userAccount.firm.toUpperCase() === 'PINPOINT')
      .map(userAccount => Object.assign({}, userAccount, { checked: false }));

    const groupSize = Math.floor(pmArray.length / rowSize) + (pmArray.length % rowSize > 0 ? 1 : 0);
    for (let index = 0; index < groupSize; index++) {
      const array = pmArray.slice(index * rowSize, (index + 1) * rowSize);
      pmGroupArray.push(array);
    }
  }

  // 已经存在的answeree 默认勾选
  const answerArray = state.answerArray;
  const selectedQuestionnaires = state.selectedQuestionnaires;


    
    
    
    
  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
  

    return {
        ...state,
        userAccountArray,
        pmArray,
        pmGroupArray,
        userAccountOptions,
      };
  }
  // Open/Reset Answeree Select Modal Dialog
function openAnswereeSelectModalDialog(state) {
    return {
      ...state,
      answereeSelectControlModal: {
        ...state.answereeSelectControlModal,
        isOpened: true,
        fields: {
          ...state.answereeSelectControlModal.fields,
        }
      },
    };
  }

function resetAnswereeSelectModalDialog(state) {
    const pmGroupArray = state.pmGroupArray;
  
    // 重置答题人勾选项
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        userAccount.checked = false;
      })
    });
  
    return {
      ...state,
      answereeSelectControlModal: initialState.answereeSelectControlModal,
      pmGroupArray,
    };
}
// Select All Answeree
function selectAllAnsweree(state) {
    const pmGroupArray = state.pmGroupArray;
  
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        userAccount.checked = true;
      })
    });
  
    return {
      ...state,
      pmGroupArray,
    };
}
  
  // Select Invert Answeree
function selectInvertAnsweree(state) {
    const pmGroupArray = state.pmGroupArray;
  
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        userAccount.checked = !userAccount.checked;
      })
    });
  
    return {
      ...state,
      pmGroupArray,
    };
}
  
  // Answeree Check Changed
function answereeCheckChanged(state, {checked ,userAccountName}) {
    const pmGroupArray = state.pmGroupArray;
  
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        if (userAccount.userAccountName === userAccountName) {
          userAccount.checked = checked;
        }
      })
    });
  
    return {
      ...state,
    };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
function openQuestionnaireEmailEditModalDialog(state, {sendMode, emailPayload}) {
    return {
      ...state,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        isOpened: true,
        sendMode,
        emailPayload,
      },
    };
  }
  
  function resetQuestionnaireEmailEditModalDialog(state) {
    return {
      ...state,
      questionnaireEmailEditControlModal: initialState.questionnaireEmailEditControlModal,
    };
  }



// Select Answerees
function startSelectAnswerees(state) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function selectAnswereesSuccess(state, resp) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function selectAnswereesFailure(state, err) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.ERROR,
      answereeSelectControlModal: {
        ...state.answereeSelectControlModal,
        respErrMsg: err.respMessage,
      },
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initSelectAnswereesActionStatus(state) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.READY,
    };
  }


// Load User Account
function meetingAndTrainingGroup(state, resp) {
    const userAccountArray = resp.data;

    const userGroupOptions = userAccountArray.map(userAccount => <Option key={userAccount.groupname}>{userAccount.groupname}</Option>);

    return {
        ...state,
      
        userGroupOptions,
      };
  }



export default createReducer(initialState, {
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_MEETING_AND_TRAINING_INFO]: startLoadMeetingAndTrainingInfo,
    [LOAD_MEETING_AND_TRAINING_INFO_SUCCESS]: loadMeetingAndTrainingInfoSuccess,
    [LOAD_MEETING_AND_TRAINING_INFO_FAILURE]: loadMeetingAndTrainingInfoFailure,
    [INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS]: initLoadMeetingAndTrainingInfoActionStatus,
    // select
    [SELECT_MEETING_AND_TRAINING_INFO]: selectMeetingAndTrainingInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL]: openAddOrEditMeetingAndTrainingModal,
    [ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT]: addOrEditMeetingAndTrainingChangeInput,
    [START_ADD_OR_EDIT_MEETING_AND_TRAINING]: startAddOrEditMeetingAndTraining,
    [ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS]: addOrEditMeetingAndTrainingSuccess,
    [ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE]: addOrEditMeetingAndTrainingFailure,
    [INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS]: initAddOrEditMeetingAndTrainingActionStatus,
    [RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL]: resetAddOrEditMeetingAndTrainingModal,
    // refresh fx and amtUSD
    [START_REFRESH_FX]: startRefreshFx,
    [REFRESH_FX_SUCCESS]: refreshFxSuccess,
    [REFRESH_FX_FAILURE]: refreshFxFailure,
    [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
    [REFRESH_AMT_USD]: refreshAmtUSD,
    // del
    [OPEN_DEL_MEETING_AND_TRAINING_MODAL]: openDelMeetingAndTrainingModal,
    [DEL_MEETING_AND_TRAINING_CHANGE_INPUT]: delMeetingAndTrainingChangeInput,
    [START_DEL_MEETING_AND_TRAINING]: startDelMeetingAndTraining,
    [DEL_MEETING_AND_TRAINING_SUCCESS]: delMeetingAndTrainingSuccess,
    [DEL_MEETING_AND_TRAINING_FAILURE]: delMeetingAndTrainingFailure,
    [INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS]: initDelMeetingAndTrainingActionStatus,
    [RESET_DEL_MEETING_AND_TRAINING_MODAL]: resetDelMeetingAndTrainingModal,


    // cop
    [OPEN_COP_MEETING_AND_TRAINING_MODAL]: openCopMeetingAndTrainingModal,
    [COP_MEETING_AND_TRAINING_CHANGE_INPUT]: copMeetingAndTrainingChangeInput,
    [START_COP_MEETING_AND_TRAINING]: startCopMeetingAndTraining,
    [COP_MEETING_AND_TRAINING_SUCCESS]: copMeetingAndTrainingSuccess,
    [COP_MEETING_AND_TRAINING_FAILURE]: copMeetingAndTrainingFailure,
    [INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS]: initCopMeetingAndTrainingActionStatus,
    [RESET_COP_MEETING_AND_TRAINING_MODAL]: resetCopMeetingAndTrainingModal,


    // Load User Account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
    [ANSWEREE_SELECT_MODAL_DIALOG_OPEN]: openAnswereeSelectModalDialog,
    [ANSWEREE_SELECT_MODAL_DIALOG_RESET]: resetAnswereeSelectModalDialog,
      // Answeree Check Changed
    [ANSWEREE_CHECK_CHANGED]: answereeCheckChanged,
      // Select All Answeree
    [SELECT_ALL_ANSWEREE]: selectAllAnsweree,

    // Select Invert Answeree
    [SELECT_INVERT_ANSWEREE]: selectInvertAnsweree,

      // Open/Reset Questionnaire Email Edit Modal Dialog
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN]: openQuestionnaireEmailEditModalDialog,
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET]: resetQuestionnaireEmailEditModalDialog,

    
    // Select Answerees
    [START_SELECT_ANSWEREES]: startSelectAnswerees,
    [SELECT_ANSWEREES_SUCCESS]: selectAnswereesSuccess,
    [SELECT_ANSWEREES_FAILURE]: selectAnswereesFailure,
    [INIT_SELECT_ANSWEREES_ACTION_STATUS]: initSelectAnswereesActionStatus,



    [LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS]: meetingAndTrainingGroup,
});