import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, DatePicker, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class AddOrEditGroupStructureModalDialog extends Component {

    componentDidUpdate() {
        const {
            addOrEditGroupStructureStatus,
        } = this.props;
        if(addOrEditGroupStructureStatus === ACTION_STATUS.SUCCESS) {
            message.success(this.getModalTitle() + ' succeeded');
            this.props.initSubmitAddOrEditGroupStructureActionStatus();
            this.props.resetAddOrEditGroupStructureModal();
            this.props.queryGroupStructure();
        }
        if(addOrEditGroupStructureStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitAddOrEditGroupStructureActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditGroupStructureChangeInput({name, value});
    }

    onUsageInputChange = (val) => {
        const value = !val ? undefined : val.join(',');
        this.onInputChange({name: 'usage', value});
    }


    getModalTitle() {
        const {
            mode
        } = this.props.groupStructureModal;
        let modalTitle = mode === 'ADD' ? 'Add' : 'Edit';
        modalTitle += ' Group Structure';
        return modalTitle;
    }


    // 仅支持这些类型的图片
    supportedChartFileType = ['png', 'jpg', 'jpeg', 'jpe', 'jfif'];

    beforeUpload = (file, fileList) => {
        if(!fileList || fileList.length === 0) {
            return false;
        }
        fileList = fileList.slice(-1);
        const currFile = fileList[0];
        const dotIdx = currFile.name.lastIndexOf('.');
        let fileType = (dotIdx > 0 && dotIdx < currFile.name.length-1) ?  currFile.name.substring(dotIdx+1) : '';
        fileType = fileType.toLowerCase();
        const supportedFileType = this.supportedChartFileType.filter(e => e===fileType).length > 0;
        if(!currFile.type.startsWith('image/') || !supportedFileType) {
            const resp = {respMessage : 'Upload failed, only support the following file types: ' + this.supportedChartFileType};
            currFile.response = resp;
            return false;
        }
        return true;
    }


    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
    

    submitAddOrEditGroupStructure = () => {
        const {
            mode,
        } = this.props.groupStructureModal;
        const {
            id,
            status,
            chartName,
            nature,
            usage,
            uploadChartOriName,
            uploadChartRealName,
            uploadPdfRealName,
            remarks,
            comments,
        } = this.props.groupStructureModal.fields;

        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 在addOrEdit页面添加附件后又删除，Form控件会检查不出来，要单独检查
            // uploadPdfRealName提交到后端后再生成
            if(!uploadChartOriName || !uploadChartRealName) {
                message.error('Upload of Chart is required');
                return;
            }
            this.props.submitAddOrEditGroupStructure({
                id,
                status,
                chartName,
                nature,
                usage,
                uploadChartOriName,
                uploadChartRealName,
                uploadPdfRealName,
                remarks,
                comments,
                opType: mode,
            });
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            natureOptions,
            usageOptions,
            groupStructureUploadUrl,
            groupStructureModal,
            addOrEditGroupStructureStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = groupStructureModal;
        const {
            id,
            status,
            chartName,
            nature,
            usage,
            uploadChartOriName,
            uploadChartRealName,
            uploadPdfRealName,
            remarks,
            comments,
            chartFileList,
        } = groupStructureModal.fields;

        const modalTitle = this.getModalTitle();
        const isSubmitLoading = addOrEditGroupStructureStatus === ACTION_STATUS.LOGINING;

        const usageArray = !!usage ? usage.split(',') : [];

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={800}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditGroupStructureModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetAddOrEditGroupStructureModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditGroupStructure() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="chartName">
                                    {getFieldDecorator('chartName', {
                                        initialValue: chartName,
                                        rules: [{
                                            required: true,
                                            message: 'Please input chartName',
                                        }],
                                        })(
                                            <Input placeholder='Please input chartName' 
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        this.onInputChange({ name: 'chartName', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="nature">
                                    {getFieldDecorator('nature', {
                                        initialValue: nature,
                                        rules: [{
                                            required: true,
                                            message: 'Please select nature',
                                        }],
                                        })(
                                            <Select 
                                                allowClear
                                                style={{ width: 200, marginLeft: 10 }}
                                                placeholder='Please select nature' 
                                                onChange={(val) => {
                                                    this.onInputChange({ name: 'nature', value: val })
                                                }}>
                                                {natureOptions}
                                            </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadChartOriName', {
                                        initialValue: uploadChartOriName,
                                        rules: [{
                                            required: true,
                                            message: 'Please upload the chart',
                                        }],
                                        })(
                                            <Upload
                                                name="uploadChartOriName"
                                                withCredentials={true}
                                                multiple={false}
                                                fileList={chartFileList}
                                                beforeUpload={this.beforeUpload}
                                                action={groupStructureUploadUrl}
                                                onChange={this.groupStructureUploadChange}
                                            >
                                                <Button style={{ width: 200, marginLeft: 10 }}>
                                                    <Icon type="upload" />Upload
                                                </Button>
                                            </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="usage">
                                    {getFieldDecorator('usage', {
                                        initialValue: usageArray,
                                        rules: [{
                                            required: true,
                                            message: 'Please select usage',
                                        }],
                                        })(
                                            <Select 
                                                allowClear
                                                mode='multiple'
                                                style={{ width: 200, marginLeft: 10 }}
                                                placeholder='Please select usage' 
                                                onChange={(val) => {
                                                    this.onUsageInputChange(val)
                                                }}>
                                                {usageOptions}
                                            </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 200, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input remarks' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 200, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input comments' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}


const AddOrEditGroupStructureModalDialogForm = Form.create({ name: 'AddOrEditGroupStructureModalDialog' })(AddOrEditGroupStructureModalDialog);
export default AddOrEditGroupStructureModalDialogForm;
