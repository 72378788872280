import {connect} from 'react-redux';
import CptDashboard from '../components/CptDashboard';


import {
    // query
    onQueryInputChange,
    loadCptInfo,
    initLoadCptInfoActionStatus,
    // select
    selectCpt,
    // add or edit
    openAddOrEditCptModal,
    addOrEditCptChangeInput,
    resetSubmitSaveCptModal,
    submitSaveCpt,
    submitSaveCptFailure,
    initSubmitSaveCptActionStatus,

    //Sub add or edit
    openAddOrEditCptSubModal,
    addOrEditCptSubChangeInput,
    resetSubmitSaveCptSubModal,
    submitSaveCptSub,
    submitSaveCptSubFailure,
    initSubmitSaveCptSubActionStatus,



    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForCpt,
    initBatchChangeStatusForCptActionStatus,
    // finish
    selectCptTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishCptTask,
    initFinishCptTaskActionStatus,


    openQuestionnaireEmailEditModalDialog,
    resetQuestionnaireEmailEditModalDialog,

  // Accept Questionnaire Result
  acceptQuestionnaireResult,
  initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  rejectQuestionnaireResult,
  initRejectQuestionnaireResultActionStatus,

    // Send Remind Email
    sendRemindEmail,
    initSendRemindEmailActionStatus,

    selectUserRegisterInfoByName,


} from '../CptActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';

import {
    queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'

import {
    loadLicensingForm,
} from '../../licensingForm/licensingFormActions'
const mapStateToProps = state => {
    return {
        // basic options
        locationOptions: state.cpt.locationOptions,
        fundCompanyOptions: state.cpt.fundCompanyOptions,
        frequencyOptions: state.cpt.frequencyOptions,
        weekdayOptions: state.cpt.weekdayOptions,
        monthOptions: state.cpt.monthOptions,
        responsibleDeptOptions: state.cpt.responsibleDeptOptions,
        licensingFormOptions: state.cpt.licensingFormOptions,
        departmentOptions: state.cpt.departmentOptions,
        officeOptions: state.cpt.officeOptions,
        statusOptions: state.cpt.statusOptions,
        importanceOptions: state.cpt.importanceOptions,
        userAccountOptions: state.userAccess.userAccountOptions,
        regulatorOptions: state.cpt.regulatorOptions,
        licenseOptions: state.cpt.licenseOptions,
        applicationStatusOptions: state.cpt.applicationStatusOptions,
        licensingStatusOptions: state.cpt.licensingStatusOptions,
        examinationStatusOptions: state.cpt.examinationStatusOptions,

        // query
        cptQueryFields: state.cpt.cptQueryFields,
        loadCptInfoActionStatus: state.cpt.loadCptInfoActionStatus,
        loadCptInfoResp: state.cpt.loadCptInfoResp,
        // select
        selectedCptArray: state.cpt.selectedCptArray,
        // add or edit
        addOrEditCptModal: state.cpt.addOrEditCptModal,
        addOrEditCptStatus: state.cpt.addOrEditCptStatus,

        // sub add or edit
        addOrEditCptSubModal: state.cpt.addOrEditCptSubModal,
        addOrEditCptSubStatus: state.cpt.addOrEditCptSubStatus,



        // batch change status
        batchChangeStatusModal: state.cpt.batchChangeStatusModal,
        batchChangeStatusForCptStatus: state.cpt.batchChangeStatusForCptStatus,
        // finish
        selectedCptTaskArray: state.cpt.selectedCptTaskArray,
        finishTaskModal: state.cpt.finishTaskModal,
        finishCptTaskStatus: state.cpt.finishCptTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.cpt.userAccountEmailOptions,
            // Open/Reset Questionnaire Email Edit Modal Dialog
        questionnaireEmailEditControlModal: state.cpt.questionnaireEmailEditControlModal,

        
        // Accept Questionnaire Result
        acceptQuestionnaireResultActionStatus: state.cpt.acceptQuestionnaireResultActionStatus,

        // Reject Questionnaire Result
        rejectQuestionnaireResultActionStatus: state.cpt.rejectQuestionnaireResultActionStatus,
        // Send Remind Email
        sendRemindEmailActionStatus: state.cpt.sendRemindEmailActionStatus,

        registerInfoList: state.cpt.registerInfoList,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadCptInfo: (params) => dispatch(loadCptInfo(params)),
        initLoadCptInfoActionStatus: () => dispatch(initLoadCptInfoActionStatus()),
        // select
        selectCpt: (array) => dispatch(selectCpt(array)),
        // add or edit
        openAddOrEditCptModal: (mode, data) => dispatch(openAddOrEditCptModal(mode, data)),
        addOrEditCptChangeInput: (data) => dispatch(addOrEditCptChangeInput(data)),
        resetSubmitSaveCptModal: () => dispatch(resetSubmitSaveCptModal()),
        submitSaveCpt: (bean) => dispatch(submitSaveCpt(bean)),
        submitSaveCptFailure: (err) => dispatch(submitSaveCptFailure(err)),
        initSubmitSaveCptActionStatus: () => dispatch(initSubmitSaveCptActionStatus()),


        //sub add or edit
        openAddOrEditCptSubModal: (mode, data,idList) => dispatch(openAddOrEditCptSubModal(mode, data,idList)),
        addOrEditCptSubChangeInput: (data) => dispatch(addOrEditCptSubChangeInput(data)),
        resetSubmitSaveCptSubModal: () => dispatch(resetSubmitSaveCptSubModal()),
        submitSaveCptSub: (bean) => dispatch(submitSaveCptSub(bean)),
        submitSaveCptSubFailure: (err) => dispatch(submitSaveCptSubFailure(err)),
        initSubmitSaveCptSubActionStatus: () => dispatch(initSubmitSaveCptSubActionStatus()),



        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForCpt: (params) => dispatch(batchChangeStatusForCpt(params)),
        initBatchChangeStatusForCptActionStatus: () => dispatch(initBatchChangeStatusForCptActionStatus()),
        // finish
        selectCptTask: (array) => dispatch(selectCptTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishCptTask: (task) => dispatch(finishCptTask(task)),
        initFinishCptTaskActionStatus: () => dispatch(initFinishCptTaskActionStatus()),
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


        // Open/Reset Questionnaire Email Edit Modal Dialog
        openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
        resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),



        // Accept  Result
        acceptQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
        initAcceptQuestionnaireResultActionStatus: () => dispatch(initAcceptQuestionnaireResultActionStatus()),

        loadLicensingForm: (params) => dispatch(loadLicensingForm(params)),
        
        // Reject Questionnaire Result
        rejectQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
        initRejectQuestionnaireResultActionStatus: () => dispatch(initRejectQuestionnaireResultActionStatus()),

        // Send Remind Email
        sendRemindEmail: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent)),
        initSendRemindEmailActionStatus: () => dispatch(initSendRemindEmailActionStatus()),


        selectUserRegisterInfoByName: (userName) => dispatch(selectUserRegisterInfoByName(userName)),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(CptDashboard);
