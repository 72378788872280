import React, {Component} from 'react';

import { Button, message, Breadcrumb, DatePicker, Select } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import ShortPositionReportGrid from './ShortPositionReportGrid';
import ShortPosReportChkResultDialog from './ShortPosReportChkResultDialog';
import moment from 'moment';
import '../../DocumentReport.css';

const Option = Select.Option;
const FundCodeOptions = [];
FundCodeOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
FundCodeOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
FundCodeOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
FundCodeOptions.push(<Option key={'COP'}>{'COP'}</Option>);

class ShortPositionReportDashboard extends Component {

  state = {
    searchDate: moment(),
    fundCode: '',
  };

  componentDidMount() {
    const {
      searchDate,
      fundCode,
    } = this.state;

    this.props.loadShortPositionReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  componentDidUpdate() {
    const {
      // Load Short Position Report Action Status
      loadShortPositionReportActionStatus,
      loadShortPositionReportErrMsg,

      // Load Short Position Report Check Result Action Status
      loadShortPositionReportCheckResultActionStatus,
      loadShortPositionReportCheckResultErrMsg,

      // Generate Short Position Report Action Status
      generateShortPositionReportActionStatus,
      generateShortPositionReportErrMsg,

      // Report Short Position Report
      reportShortPositionReportActionStatus,
      reportFileArray,
      reportShortPositionReportErrMsg,
    } = this.props;

    const {
      searchDate,
      fundCode,
    } = this.state;

    // Generate Short Position Report 成功
    if (generateShortPositionReportActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Short position report are generated successfully!');
      this.props.initGenerateShortPositionReportActionStatus();
      this.props.loadShortPositionReport(fundCode, searchDate.format("YYYY-MM-DD"));
    }

    // Generate Short Position Report 失败
    if (generateShortPositionReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(generateShortPositionReportErrMsg);
      this.props.initGenerateShortPositionReportActionStatus();
    }

    // Load Short Position Report 成功
    if (loadShortPositionReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadShortPositionReportActionStatus();
    }

    // Load Short Position Report 失败
    if (loadShortPositionReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadShortPositionReportErrMsg);
      this.props.initLoadShortPositionReportActionStatus();
    }

    // Load Short Position Report Check Result 成功
    if (loadShortPositionReportCheckResultActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadShortPositionReportCheckResultActionStatus();
    }

    // Load Short Position Report Check Result 失败
    if (loadShortPositionReportCheckResultActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadShortPositionReportCheckResultErrMsg);
      this.props.initLoadShortPositionReportCheckResultActionStatus();
    }

    // Report Short Position Report 成功
    if (reportShortPositionReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initReportShortPositionReportActionStatus();

      reportFileArray.forEach(reportFile => {
        window.open(reportFile);
      });
    }

    // Report Short Position Report 失败
    if (reportShortPositionReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(reportShortPositionReportErrMsg);
      this.props.initReportShortPositionReportActionStatus();
    }
  }

  fundOnChange = (value) => {
    this.setState({
      fundCode: value,
    });
  }

  searchBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    this.props.loadShortPositionReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  genreateReportBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    if(!fundCode) {
      message.warn('Please choose one fund');
      return;
    }

    this.props.generateShortPositionReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  reportBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    if(!fundCode) {
      message.warn('Please choose one fund');
      return;
    }

    this.props.reportShortPositionReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  reportCheckBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    const dateString = searchDate.format("YYYY-MM-DD");
    this.props.loadShortPositionReportCheckResult(fundCode, dateString);
    this.props.openShortPositionReportCheckResultModalDialog(fundCode, dateString);
  }
  
  render() {
    const {
      // Generate Short Position Report Action Status
      generateShortPositionReportActionStatus,
      loadShortPositionReportActionStatus,
      // Report Short Position Report
      reportShortPositionReportActionStatus,
    } = this.props;

    const {
      searchDate,
      fundCode,
    } = this.state;

    const generateBtnIsLoading = generateShortPositionReportActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadShortPositionReportActionStatus === ACTION_STATUS.LOGINING;
    const reportBtnIsLoading = reportShortPositionReportActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="shortPositionReportWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Document & Report</Breadcrumb.Item>
            <Breadcrumb.Item>Short Position Report</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <DatePicker 
            style={{ marginLeft: 10, fontSize: 10 }}
            size='small'
            allowClear={false}
            value={searchDate}
            onChange={(date, dateString) => {
              this.setState({
                searchDate: date
              })
            }} 
          />

          <Select 
            allowClear
            showSearch
            size='small'
            value={fundCode}
            style={{ marginLeft: 10, fontSize: 10, width: 150 }}
            onChange={this.fundOnChange} 
            placeholder="Please Choose Fund"
            >
            {FundCodeOptions}
          </Select>

          <Button 
            onClick={this.searchBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Search
          </Button>

          <Button 
            onClick={this.reportCheckBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Check
          </Button>

          <Button 
            onClick={this.genreateReportBtnOnClicked} 
            size='small'
            loading={generateBtnIsLoading}
            type='primary'>
            Generate
          </Button>

          <Button 
            onClick={this.reportBtnOnClicked} 
            size='small'
            loading={reportBtnIsLoading}
            type='primary'>
            Report
          </Button>

        </div>

        <div className='shortPositionReportGrid'>
          <ShortPositionReportGrid {...this.props} />
        </div>

        {/* Short Position Report 申报检查结果 */}
        <ShortPosReportChkResultDialog {...this.props} />

      </div>
    );
  }
}

export default ShortPositionReportDashboard;