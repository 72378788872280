import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import PersonalTradeOperateMode from '../../../../../utils/PersonalTradeOperateMode';
const { TextArea } = Input;

class PersonalTradeOperateDialog extends Component {

  state = {
    comments: '',
  };

  componentDidUpdate() {
    const { 
      
    } = this.props;

    
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      operateMode,
      personalTradeRequestIdList,
    } = this.props.personalTradeRequestOperateControlModal;

    const {
      comments,
    } = this.state;

    if (operateMode === PersonalTradeOperateMode.APPROVE) {
      this.props.approvePersonalTrade(comments, personalTradeRequestIdList);
    } 

    if (operateMode === PersonalTradeOperateMode.FINAL_APPROVE) {
      this.props.finalApprovePersonalTrade(comments, personalTradeRequestIdList);
    }
    
    if (operateMode === PersonalTradeOperateMode.REJECT) {
      this.props.rejectPersonalTrade(comments, personalTradeRequestIdList);
    } 

    if (operateMode === PersonalTradeOperateMode.DELETE) {
      this.props.batchDeletePersonalTradeRequest(comments, personalTradeRequestIdList);
    } 
  }

  // Modal Closed
  modalClosedHandle = () => {
    this.setState({
      comments: '',
    });
  }

  render() {
    const {
      comments,
    } = this.state;

    const {
      // Personal Trade Request Operate Modal Dialog
      personalTradeRequestOperateControlModal,

      // Approve Personal Trade Action Status
      approvePersonalTradeActionStatus,

      // Final Approve Personal Trade Action Status
      finalApprovePersonalTradeActionStatus,

      // Reject Personal Trade Action Status
      rejectPersonalTradeActionStatus,

      // Delete Personal Trade Action Status
      batchDeletePersonalTradeRequestActionStatus,
    } = this.props;

    const {
      isOpened,
      operateMode,
      isNonCompliance,
      respErrMsg,
    } = personalTradeRequestOperateControlModal;

    let operation = "Operate";
    if (operateMode === PersonalTradeOperateMode.APPROVE) {
      operation = 'Approve';
    } else if (operateMode === PersonalTradeOperateMode.REJECT) {
      operation = 'Reject';
    } else if (operateMode === PersonalTradeOperateMode.FINAL_APPROVE) {
      operation = 'Final Approve';
    } else if (operateMode === PersonalTradeOperateMode.DELETE) {
      operation = 'Delete';
    }

    let modalTitle = `${operation} Personal Trade`;

    const isLoading = approvePersonalTradeActionStatus === ACTION_STATUS.LOGINING || finalApprovePersonalTradeActionStatus === ACTION_STATUS.LOGINING || 
      rejectPersonalTradeActionStatus === ACTION_STATUS.LOGINING || batchDeletePersonalTradeRequestActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetPersonalTradeRequestOperateModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
          okText="Submit"
          cancelText="Cancel"
        >
          {
            (isNonCompliance && operateMode !== PersonalTradeOperateMode.REJECT) && 
            <div style={{ marginBottom: 10, fontWeight: "bold", fontSize: 16 }}>
              This Approval is non-compliant, are you sure to continue?
            </div>
          }
          {
            operateMode === PersonalTradeOperateMode.REJECT && 
            <div style={{ marginBottom: 10, fontWeight: "bold", fontSize: 16 }}>
              You are going to reject this application, are you sure to continue?
            </div>
          }
          <TextArea 
            rows={4} 
            placeholder="Comments"
            value={comments} 
            onChange={(e) => {
              this.setState({
                comments: e.target.value
              })
            }}/>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default PersonalTradeOperateDialog;