// onLicensingQueryInputChange
export const ON_LICENSING_QUERY_INPUT_CHANGE = 'ON_LICENSING_QUERY_INPUT_CHANGE';

// loadLicensingInfo
export const START_LOAD_LICENSING_INFO = 'START_LOAD_LICENSING_INFO';
export const LOAD_LICENSING_INFO_SUCCESS = 'LOAD_LICENSING_INFO_SUCCESS';
export const LOAD_LICENSING_INFO_FAILURE = 'LOAD_LICENSING_INFO_FAILURE';
export const INIT_LOAD_LICENSING_INFO_ACTION_STATUS = 'INIT_LOAD_LICENSING_INFO_ACTION_STATUS';

// add or edit license info
export const OPEN_ADD_OR_EDIT_LICENSE_MODAL = 'OPEN_ADD_OR_EDIT_LICENSE_MODAL';
export const ADD_OR_EDIT_LICENSE_CHANGE_INPUT = 'ADD_OR_EDIT_LICENSE_CHANGE_INPUT';

// loadLicenseBasicInfoOptions
export const START_LOAD_LICENSE_BASIC_INFO_OPTIONS = 'START_LOAD_LICENSE_BASIC_INFO_OPTIONS';
export const LOAD_LICENSE_BASIC_INFO_OPTIONS_SUCCESS = 'LOAD_LICENSE_BASIC_INFO_OPTIONS_SUCCESS';
export const LOAD_LICENSE_BASIC_INFO_OPTIONS_FAILURE = 'LOAD_LICENSE_BASIC_INFO_OPTIONS_FAILURE';
export const INIT_LOAD_LICENSE_BASIC_INFO_OPTIONS_ACTION_STATUS = 'INIT_LOAD_LICENSE_BASIC_INFO_OPTIONS_ACTION_STATUS';

// addOrEditLicenseEmpRequireRcd
export const START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD = 'START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD';
export const ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS = 'ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS';
export const ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE = 'ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE';
export const INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS = 'INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS';
export const RESET_SUBMIT_SAVE_LICENSE_MODAL = 'RESET_SUBMIT_SAVE_LICENSE_MODAL';

// selectLicensingData
export const SELECT_LICENSING_DATA = 'SELECT_LICENSING_DATA';

// batchDelLicenseInfo
export const OPEN_DEL_LICENSE_MODAL = 'OPEN_DEL_LICENSE_MODAL';
export const DEL_LICENSE_CHANGE_INPUT = 'DEL_LICENSE_CHANGE_INPUT';
export const START_BATCH_DEL_LICENSE_INFO = 'START_BATCH_DEL_LICENSE_INFO';
export const BATCH_DEL_LICENSE_INFO_SUCCESS = 'BATCH_DEL_LICENSE_INFO_SUCCESS';
export const BATCH_DEL_LICENSE_INFO_FAILURE = 'BATCH_DEL_LICENSE_INFO_FAILURE';
export const INIT_BATCH_DEL_LICENSE_INFO_ACTION_STATUS = 'INIT_BATCH_DEL_LICENSE_INFO_ACTION_STATUS';
export const RESET_DEL_LICENSE_MODAL = 'RESET_DEL_LICENSE_MODAL';

// selectCptRcdData
export const SELECT_CPT_RCD_DATA = 'SELECT_CPT_RCD_DATA';

// openEditCptRcdModal
export const OPEN_EDIT_CPT_RCD_MODAL = 'OPEN_EDIT_CPT_RCD_MODAL';

// editCptRcdModalChangeInput
export const EDIT_CPT_RCD_MODAL_CHANGE_INPUT = 'EDIT_CPT_RCD_MODAL_CHANGE_INPUT';

// edit cpt rcd
export const START_SUBMIT_EDIT_CPT_RCD = 'START_SUBMIT_EDIT_CPT_RCD';
export const SUBMIT_EDIT_CPT_RCD_SUCCESS = 'SUBMIT_EDIT_CPT_RCD_SUCCESS';
export const SUBMIT_EDIT_CPT_RCD_FAILURE = 'SUBMIT_EDIT_CPT_RCD_FAILURE';
export const INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS = 'INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS';
export const RESET_EDIT_CPT_RCD_MODAL = 'RESET_EDIT_CPT_RCD_MODAL';

// op rcd
export const OPEN_OPERATION_RCD_MODAL = 'OPEN_OPERATION_RCD_MODAL';
export const START_QUERY_OPERATION_RCD = 'START_QUERY_OPERATION_RCD';
export const QUERY_OPERATION_RCD_SUCCESS = 'QUERY_OPERATION_RCD_SUCCESS';
export const QUERY_OPERATION_RCD_FAILURE = 'QUERY_OPERATION_RCD_FAILURE';
export const INIT_QUERY_OPERATION_RCD_ACTION_STATUS = 'INIT_QUERY_OPERATION_RCD_ACTION_STATUS';
export const RESET_OPERATION_RCD_MODAL = 'RESET_OPERATION_RCD_MODAL';

