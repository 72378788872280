import {
  // Login
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_LOGIN_STATUS,

  // Logout
  LOGOUT_SUCCESS,
} from './authConstants';

import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

const initialState = {
  loginForm: {
    userName: '',
    password: '',
  },
  loginStatus: ACTION_STATUS.READY,
  loginErrMsg: '',
}

// Login
function startLogin(state) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.LOGINING,
  };
}

function loginSuccess(state, payload) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.SUCCESS
  };
}

function loginFailure(state, err) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.ERROR,
    loginErrMsg: err.respMessage,
  };
}

function resetLoginStatus(state) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.READY,
    loginErrMsg: '',
  };
}

// Logout
function logoutSuccess(state) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  // Login
  [START_LOGIN]: startLogin,
  [LOGIN_SUCCESS]: loginSuccess,
  [LOGIN_FAILURE]: loginFailure,
  [RESET_LOGIN_STATUS]: resetLoginStatus,

  // Logout
  [LOGOUT_SUCCESS]: logoutSuccess,
});