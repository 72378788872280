import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RestrictedSecurityOperateMode from '../../../../../utils/RestrictedSecurityOperateMode';
const { TextArea } = Input;

class RestrictedSecurityOperateDialog extends Component {

  state = {
    comments: '',
    reason: '',
  };

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      operateMode,
      restrictedSecGuidList,
    } = this.props.restrictedSecurityOperateControlModal;

    const {
      comments,
      reason,
    } = this.state;

    if (operateMode === RestrictedSecurityOperateMode.DELETE) {
      this.props.batchDeleteRestrictedSecurity(comments, restrictedSecGuidList);
    } 

    if (operateMode === RestrictedSecurityOperateMode.ONLIST) {
      this.props.onListRestrictedSecurity(comments, restrictedSecGuidList, reason);
    } 

    if (operateMode === RestrictedSecurityOperateMode.OFFLIST) {
      this.props.offListRestrictedSecurity(comments, restrictedSecGuidList, reason);
    } 
  }

  // Modal Closed
  modalClosedHandle = () => {
    this.setState({
      comments: '',
      reason: '',
    });
  }

  render() {
    const {
      comments,
      reason,
    } = this.state;

    const {
      // Restricted Security Operate Modal Dialog
      restrictedSecurityOperateControlModal,

      // Delete Restricted Security Action Status
      batchDeleteRestrictedSecActionStatus,

      // On/Off List Restricted Security Action Status
      onListRestrictedSecurityActionStatus,
      offListRestrictedSecurityActionStatus,
    } = this.props;

    const {
      isOpened,
      operateMode,
      respErrMsg,
    } = restrictedSecurityOperateControlModal;
    
    let operation = 'Operate';
    if (operateMode === RestrictedSecurityOperateMode.DELETE) {
      operation = 'Delete';
    } else if (operateMode === RestrictedSecurityOperateMode.ONLIST) {
      operation = 'OnList';
    } else if (operateMode === RestrictedSecurityOperateMode.OFFLIST) {
      operation = 'OffList';
    } 

    let modalTitle = `${operation} Restricted Security`;

    const isLoading = batchDeleteRestrictedSecActionStatus === ACTION_STATUS.LOGINING || 
        onListRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING || 
        offListRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedSecurityOperateModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
          okText="Submit"
          cancelText="Cancel"
        >
          {
            (operateMode === RestrictedSecurityOperateMode.ONLIST || operateMode === RestrictedSecurityOperateMode.OFFLIST) &&
            <TextArea 
              rows={4} 
              placeholder='Reason'
              style={{ marginBottom: 10 }}
              value={reason} 
              onChange={(e) => {
                this.setState({
                  reason: e.target.value
                })
              }}/>
          }
          <TextArea 
            rows={4} 
            placeholder='Comments'
            value={comments} 
            onChange={(e) => {
              this.setState({
                comments: e.target.value
              })
            }}/>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default RestrictedSecurityOperateDialog;