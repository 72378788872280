import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './MeetingAndTrainingApplyRecordDashboard.css';
import MeetingAndTrainingApplyRecordGrid from './MeetingAndTrainingApplyRecordGrid';
import AddOrEditMeetingAndTrainingApplyRecordModalDialog from './AddOrEditMeetingAndTrainingApplyRecordModalDialog';
import DelMeetingAndTrainingApplyRecordModalDialog from './DelMeetingAndTrainingApplyRecordModalDialog';
import CopMeetingAndTrainingApplyRecordModalDialog from './CopMeetingAndTrainingApplyRecordModalDialog';
import AnswereeSelectDialog from './AnswereeSelectDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import OperationRecordDetail from './OperationRecordDetail';


class MeetingAndTrainingApplyRecordDashboard extends Component {
    
    componentDidMount() {

        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        const {
            cpty,
            functiontype,
            fundandcompany,
            userName,
            status
        } = this.props.meetingAndTrainingApplyRecordQueryFields;
        this.onInputChange({name: 'id', value: id});
        // this.props.loadReportingSummaryInfo({id, location: undefined, fundCompany: undefined});

        this.props.loadMeetingAndTrainingApplyRecordInfo({id,cpty: undefined,functiontype: undefined,fundandcompany: undefined,userName: undefined, status: undefined})

    }


    componentDidUpdate() {
        const {
            // query
            loadMeetingAndTrainingApplyRecordInfoActionStatus,
            loadMeetingAndTrainingApplyRecordInfoResp,
        } = this.props;

        if(loadMeetingAndTrainingApplyRecordInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadMeetingAndTrainingApplyRecordInfoActionStatus();
        }
        if(loadMeetingAndTrainingApplyRecordInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load User Access Info failed';
            if(!!loadMeetingAndTrainingApplyRecordInfoResp && !!loadMeetingAndTrainingApplyRecordInfoResp.respMessage) {
                err = loadMeetingAndTrainingApplyRecordInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadMeetingAndTrainingApplyRecordInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditMeetingAndTrainingApplyRecordModal(mode, null);
        } else {
            const {
                selectedMeetingAndTrainingApplyRecordInfoArray,
            } = this.props;
            if(selectedMeetingAndTrainingApplyRecordInfoArray.length !== 1) {
                message.warning("Please choose one and only one User Access Info");
                return;
            }
            const currentMeetingAndTrainingApplyRecordInfo = selectedMeetingAndTrainingApplyRecordInfoArray[0];
            this.props.openAddOrEditMeetingAndTrainingApplyRecordModal(mode, currentMeetingAndTrainingApplyRecordInfo);
        }
    }
    copyAllBtnOnClicked = () => {
        const {
            selectedMeetingAndTrainingApplyRecordInfoArray,
        } = this.props;
        if(selectedMeetingAndTrainingApplyRecordInfoArray.length !== 1) {
            message.warning("Please choose one and only one User Access Info");
            return;
        }
        const currentMeetingAndTrainingApplyRecordInfo = selectedMeetingAndTrainingApplyRecordInfoArray[0];
        this.props.openCopMeetingAndTrainingApplyRecordModal(currentMeetingAndTrainingApplyRecordInfo);
    }

    delBtnOnClicked = (mode) => {
        const {
            selectedMeetingAndTrainingApplyRecordInfoArray,
        } = this.props;
        if(selectedMeetingAndTrainingApplyRecordInfoArray.length !== 1) {
            message.warning("Please choose one and only one Apply Record");
            return;
        }
        const currentMeetingAndTrainingApplyRecordInfo = selectedMeetingAndTrainingApplyRecordInfoArray[0];
        // if(currentMeetingAndTrainingApplyRecordInfo.state === 'Approve') {
        //     message.warning("The approval has been approved");
        //     return;
        // }
        this.props.openDelMeetingAndTrainingApplyRecordModal(currentMeetingAndTrainingApplyRecordInfo,mode);
    }


    refreshBtnOnClicked = () => {
        const {
            
            applyType,
            userName,
            state
        } = this.props.meetingAndTrainingApplyRecordQueryFields;
        this.props.loadMeetingAndTrainingApplyRecordInfo({userName, applyType,state})
    }

    selAnswereeButtonOnClicked = () => {
        const {
            selectedMeetingAndTrainingApplyRecordInfoArray
        } = this.props;
    
        if (selectedMeetingAndTrainingApplyRecordInfoArray.length !== 1) {
          message.warning("Please select one questionnaire!");
          return;
        }
    
        this.props.loadUserAccounts();  // 每次打开页面需要先刷新userAccount
        this.props.openAnswereeSelectModalDialog();
      }

    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {

        const {
            statusOptions,
            applyTypeOptions,
            meetingAndTrainingApplyRecordOptions,
            meetingAndTrainingApplyRecordQueryFields,
            loadMeetingAndTrainingApplyRecordInfoActionStatus,
        } = this.props;

        const {
            userName,
            status,
        } = meetingAndTrainingApplyRecordQueryFields;


        const refreshBtnIsLoading = loadMeetingAndTrainingApplyRecordInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='MeetingAndTrainingApplyRecordWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item></Breadcrumb.Item>
                        <Breadcrumb.Item>Apply Record</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                <div className='breadcrumbWrapper'>   
                <Breadcrumb>
                <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                </Breadcrumb>
                </div>


                    <Input 
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="Name"
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'userName', value: event.target.value })
                        }} 
                    />
                    {/* <Input 
                        placeholder="Function"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'functiontype', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="Fund/Co."
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'fundandcompany', value: event.target.value })
                        }} 
                    />
                    <Input 
                        placeholder="User"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'userName', value: event.target.value })
                        }} 
                    /> */}
               
                     <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'state', value: val })
                        }} 
                        placeholder="Status">
                        {statusOptions}
                    </Select> 
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'applyType', value: val })
                        }} 
                        placeholder="Apply Type">
                        {applyTypeOptions}
                    </Select> 
                    {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date Start"
                      onChange={(invDateStart, dateString) => {
                        this.onInputChange({ name: 'invDateStart', value: dateString });
                      }}
                    /> */}
                    {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date End"
                      onChange={(invDateEnd, dateString) => {
                        this.onInputChange({ name: 'invDateEnd', value: dateString });
                      }}
                    /> */}
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.delBtnOnClicked('Approve')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Approve
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('Reject')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negative-button'>
                        Reject
                    </Button>
                     {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                   <Button 
                        onClick={()=>this.copyAllBtnOnClicked()} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy All
                    </Button> */}
                    {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button> */}
                    {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDITALL')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit Series
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('inactive')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Inactivate User
                    </Button> */}

                    {/* <Button 
                        onClick={this.selAnswereeButtonOnClicked} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Select Answeree
                    </Button> */}
                    {/* <Button 
                        onClick={()=>this.delBtnOnClicked('send')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Send Email
                    </Button>
                     <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'MeetingAndTrainingApplyRecord'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button>  */}
                </div>

                <div className='MeetingAndTrainingApplyRecordGrid'><MeetingAndTrainingApplyRecordGrid {...this.props} /></div>
                <AddOrEditMeetingAndTrainingApplyRecordModalDialog {...this.props} />
                <DelMeetingAndTrainingApplyRecordModalDialog {...this.props} />
                <CopMeetingAndTrainingApplyRecordModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />
                <AnswereeSelectDialog {...this.props}/>
                <OperationRecordDetail {...this.props} />

            </div>
        );
    }
}

export default MeetingAndTrainingApplyRecordDashboard;
