
// query input
export const ON_ELEMENT_QUERY_INPUT_CHANGE = 'ON_ELEMENT_QUERY_INPUT_CHANGE';
// select data
export const SELECT_ELEMENT_LIST = 'SELECT_ELEMENT_LIST';
export const SELECT_ELEMENT_PROPS_LIST = 'SELECT_ELEMENT_PROPS_LIST';
// query element
export const START_QUERY_ELEMENT = 'START_QUERY_ELEMENT';
export const QUERY_ELEMENT_SUCCESS = 'QUERY_ELEMENT_SUCCESS';
export const QUERY_ELEMENT_FAILURE = 'QUERY_ELEMENT_FAILURE';
export const INIT_QUERY_ELEMENT_ACTION_STATUS = 'INIT_QUERY_ELEMENT_ACTION_STATUS';
// add or edit element
export const OPEN_ADD_OR_EDIT_ELEMENT_MODAL = 'OPEN_ADD_OR_EDIT_ELEMENT_MODAL';
export const RESET_ADD_OR_EDIT_ELEMENT_MODAL = 'RESET_ADD_OR_EDIT_ELEMENT_MODAL';
export const CONSTRUCT_ELEMENT_TYPE_SELECT_OPTIONS_AND_OPEN_MODAL = 'CONSTRUCT_ELEMENT_TYPE_SELECT_OPTIONS_AND_OPEN_MODAL';
export const CONSTRUCT_ELEMENT_SELECT_OPTIONS = 'CONSTRUCT_ELEMENT_SELECT_OPTIONS';
export const ON_ELEMENT_DATA_INPUT_CHANGE = 'ON_ELEMENT_DATA_INPUT_CHANGE';
export const RESET_ELEMENT_PROPS = 'RESET_ELEMENT_PROPS';
export const RESET_INIT_EDIT_OF_ADD_OR_EDIT_ELEMENT_MODAL = 'RESET_INIT_EDIT_OF_ADD_OR_EDIT_ELEMENT_MODAL';
export const APPEND_PROPS_FORM_ITEM_TO_STATE = 'APPEND_PROPS_FORM_ITEM_TO_STATE';
export const ON_ELEMENT_PROPS_INPUT_CHANGE = 'ON_ELEMENT_PROPS_INPUT_CHANGE';
export const START_SUBMIT_ADD_OR_EDIT_ELEMENT = 'START_SUBMIT_ADD_OR_EDIT_ELEMENT';
export const SUBMIT_ADD_OR_EDIT_ELEMENT_SUCCESS = 'SUBMIT_ADD_OR_EDIT_ELEMENT_SUCCESS';
export const SUBMIT_ADD_OR_EDIT_ELEMENT_FAILURE = 'SUBMIT_ADD_OR_EDIT_ELEMENT_FAILURE';
export const INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_ACTION_STATUS = 'INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_ACTION_STATUS';
// del element
export const START_DEL_ELEMENT = 'START_DEL_ELEMENT';
export const DEL_ELEMENT_SUCCESS = 'DEL_ELEMENT_SUCCESS';
export const DEL_ELEMENT_FAILURE = 'DEL_ELEMENT_FAILURE';
export const INIT_DEL_ELEMENT_ACTION_STATUS = 'INIT_DEL_ELEMENT_ACTION_STATUS';
// del props
export const START_DEL_PROPS = 'START_DEL_PROPS';
export const DEL_PROPS_SUCCESS = 'DEL_PROPS_SUCCESS';
export const DEL_PROPS_FAILURE = 'DEL_PROPS_FAILURE';
export const INIT_DEL_PROPS_ACTION_STATUS = 'INIT_DEL_PROPS_ACTION_STATUS';
