export const LOAD_MONTHLY_REPORT_LIST_SUCCESS = 'LOAD_MONTHLY_REPORT_LIST_SUCCESS';
export const LOAD_MONTHLY_REPORT_LIST_FAIL = 'LOAD_MONTHLY_REPORT_LIST_FAIL';

export const LOAD_MONTHLY_REPORT_DETAIL_SUCCESS = 'LOAD_MONTHLY_REPORT_DETAIL_SUCCESS';
export const LOAD_MONTHLY_REPORT_DETAIL_FAIL = 'LOAD_MONTHLY_REPORT_DETAIL_FAIL';

export const SAVE_MONTHLY_REPORT_SUCCESS = 'SAVE_MONTHLY_REPORT_SUCCESS';
export const SAVE_MONTHLY_REPORT_FAIL = 'SAVE_MONTHLY_REPORT_FAIL';

export const DEL_MONTHLY_REPORT_SUCCESS = 'DEL_MONTHLY_REPORT_SUCCESS';
export const DEL_MONTHLY_REPORT_FAIL = 'DEL_MONTHLY_REPORT_FAIL';

export const OPEN_MONTHLY_REPORT_LIST_DIALOG = 'OPEN_MONTHLY_REPORT_LIST_DIALOG';
export const CLOSE_MONTHLY_REPORT_LIST_DIALOG = 'CLOSE_MONTHLY_REPORT_LIST_DIALOG';

export const ON_QUERY_PARAMS_CHANGE = 'ON_QUERY_PARAMS_CHANGE';

