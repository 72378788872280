// search change input 
export const TRADES_REVIEW_SEARCH_CHANGE_INPUT = 'TRADES_REVIEW_SEARCH_CHANGE_INPUT';

// do query
export const START_QUERY_TRADES_REVIEW = 'START_QUERY_TRADES_REVIEW';
export const QUERY_TRADES_REVIEW_SUCCESS = 'QUERY_TRADES_REVIEW_SUCCESS';
export const QUERY_TRADES_REVIEW_FAILURE = 'QUERY_TRADES_REVIEW_FAILURE';
export const INIT_QUERY_TRADES_REVIEW_ACTION_STATUS = 'INIT_QUERY_TRADES_REVIEW_ACTION_STATUS';

// query report
export const START_QUERY_TRADES_REVIEW_REPORT = 'START_QUERY_TRADES_REVIEW_REPORT';
export const QUERY_TRADES_REVIEW_REPORT_SUCCESS = 'QUERY_TRADES_REVIEW_REPORT_SUCCESS';
export const QUERY_TRADES_REVIEW_REPORT_FAILURE = 'QUERY_TRADES_REVIEW_REPORT_FAILURE';
export const INIT_QUERY_TRADES_REVIEW_REPORT_ACTION_STATUS = 'INIT_QUERY_TRADES_REVIEW_REPORT_ACTION_STATUS';

// add grid to container
export const ADD_TRADES_REVIEW_GRID_TO_PROPS = 'ADD_TRADES_REVIEW_GRID_TO_PROPS';
export const ADD_TRADES_REVIEW_REPORT_GRID_TO_PROPS = 'ADD_TRADES_REVIEW_REPORT_GRID_TO_PROPS';
