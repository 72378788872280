import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Sync Exchange Rate
  START_SYNC_EXCHANGE_RATE,
  SYNC_EXCHANGE_RATE_SUCCESS,
  SYNC_EXCHANGE_RATE_FAILURE,
  INIT_SYNC_EXCHANGE_RATE_ACTION_STATUS,

  // Load Exchange Rate
  START_LOAD_EXCHANGE_RATE,
  LOAD_EXCHANGE_RATE_SUCCESS,
  LOAD_EXCHANGE_RATE_FAILURE,
  INIT_LOAD_EXCHANGE_RATE_ACTION_STATUS,

} from './exchangeRateConstants';

// Sync Exchange Rate
function startSyncExchangeRate() {
  return {
    type: START_SYNC_EXCHANGE_RATE
  };
}

export function syncExchangeRate(dateString) {
  const requestParams = {
    'date-string': dateString,
  };

  return function(dispatch) {
    dispatch(startSyncExchangeRate());
    httpClient
      .post(UrlConfig.SYNC_EXCHANGE_RATE, requestParams)
      .then(resp => dispatch(syncExchangeRateSuccess(resp)))
      .catch(err => dispatch(syncExchangeRateFailure(err)));
  };
}

function syncExchangeRateSuccess(resp) {
  return {
    type: SYNC_EXCHANGE_RATE_SUCCESS,
    payload: resp
  };
}

function syncExchangeRateFailure(err) {
  return {
    type: SYNC_EXCHANGE_RATE_FAILURE,
    payload: err
  };
}

export function initSyncExchangeRateActionStatus() {
  return {
    type: INIT_SYNC_EXCHANGE_RATE_ACTION_STATUS
  };
}

// Load Exchange Rate
function startLoadExchangeRate() {
  return {
    type: START_LOAD_EXCHANGE_RATE
  };
}

export function loadExchangeRate() {
  return function(dispatch) {
    dispatch(startLoadExchangeRate());
    httpClient
      .get(UrlConfig.LOAD_EXCHANGE_RATE_LIST)
      .then(resp => dispatch(loadExchangeRateSuccess(resp)))
      .catch(err => dispatch(loadExchangeRateFailure(err)));
  };
}

function loadExchangeRateSuccess(resp) {
  return {
    type: LOAD_EXCHANGE_RATE_SUCCESS,
    payload: resp
  };
}

function loadExchangeRateFailure(err) {
  return {
    type: LOAD_EXCHANGE_RATE_FAILURE,
    payload: err
  };
}

export function initLoadExchangeRateActionStatus() {
  return {
    type: INIT_LOAD_EXCHANGE_RATE_ACTION_STATUS
  };
}