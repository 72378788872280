import {connect} from 'react-redux';
import RatingManagementDashboard from '../components/RatingManagementDashboard';


import {
    // query
    onQueryInputChange,
    loadRatingManagementInfo,
    initLoadRatingManagementInfoActionStatus,
    // select
    selectRatingManagementInfo,
    // add or edit
    openAddOrEditRatingManagementModal,
    addOrEditRatingManagementChangeInput,
    addOrEditRatingManagement,
    initAddOrEditRatingManagementActionStatus,
    resetAddOrEditRatingManagementModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelRatingManagementModal,
    delRatingManagementChangeInput,
    delRatingManagement,
    initDelRatingManagementActionStatus,
    resetDelRatingManagementModal,

    //cop
    openCopRatingManagementModal,
    copRatingManagementChangeInput,
    copRatingManagement,
    initCopRatingManagementActionStatus,
    resetCopRatingManagementModal,

    openRestrictedRuleFundBookDialog,
    closeRestrictedRuleFundBookDialog,
    restrictedRuleEditChangeInput,

    
    
} from '../RatingManagementActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.ratingManagement.statusOptions,
        fundFirmRelatedOptions: state.ratingManagement.fundFirmRelatedOptions,
        ccyOptions: state.ratingManagement.ccyOptions,
        userAccountOptions: state.ratingManagement.userAccountOptions,
        // ratingManagementQueryFields
        ratingManagementQueryFields: state.ratingManagement.ratingManagementQueryFields,
        // query
        loadRatingManagementInfoActionStatus: state.ratingManagement.loadRatingManagementInfoActionStatus,
        loadRatingManagementInfoResp: state.ratingManagement.loadRatingManagementInfoResp,
        selectedRatingManagementInfoArray: state.ratingManagement.selectedRatingManagementInfoArray,
        ratingManagementGridColumns: state.ratingManagement.ratingManagementGridColumns,
        // add or edit
        addOrEditRatingManagementModal: state.ratingManagement.addOrEditRatingManagementModal,
        addOrEditRatingManagementStatus: state.ratingManagement.addOrEditRatingManagementStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.ratingManagement.refreshFxStatus,
        refreshFxResp: state.ratingManagement.refreshFxResp,
        // del
        delRatingManagementModal: state.ratingManagement.delRatingManagementModal,
        delRatingManagementStatus: state.ratingManagement.delRatingManagementStatus,
        // cop
        copRatingManagementModal: state.ratingManagement.copRatingManagementModal,
        copRatingManagementStatus: state.ratingManagement.copRatingManagementStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        ratingManagementUploadUrl: state.ratingManagement.ratingManagementUploadUrl,
        ratingManagementDownloadUrl: state.ratingManagement.ratingManagementDownloadUrl,
        restrictedRuleEditControlModal: state.ratingManagement.restrictedRuleEditControlModal,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadRatingManagementInfo: (params) => dispatch(loadRatingManagementInfo(params)),
        initLoadRatingManagementInfoActionStatus: () => dispatch(initLoadRatingManagementInfoActionStatus()),
        // select
        selectRatingManagementInfo: (dataArray) => dispatch(selectRatingManagementInfo(dataArray)),
        // add or edit
        openAddOrEditRatingManagementModal: (mode, data) => dispatch(openAddOrEditRatingManagementModal(mode, data)),
        addOrEditRatingManagementChangeInput: (params) => dispatch(addOrEditRatingManagementChangeInput(params)),
        addOrEditRatingManagement: (info) => dispatch(addOrEditRatingManagement(info)),
        initAddOrEditRatingManagementActionStatus: () => dispatch(initAddOrEditRatingManagementActionStatus()),
        resetAddOrEditRatingManagementModal: () => dispatch(resetAddOrEditRatingManagementModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelRatingManagementModal: (info,mode,idList,level) => dispatch(openDelRatingManagementModal(info,mode,idList,level)),
        delRatingManagementChangeInput: (params) => dispatch(delRatingManagementChangeInput(params)),
        delRatingManagement: (info) => dispatch(delRatingManagement(info)),
        initDelRatingManagementActionStatus: () => dispatch(initDelRatingManagementActionStatus()),
        resetDelRatingManagementModal: () => dispatch(resetDelRatingManagementModal()),

        // cop
        openCopRatingManagementModal: (info) => dispatch(openCopRatingManagementModal(info)),
        copRatingManagementChangeInput: (params) => dispatch(copRatingManagementChangeInput(params)),
        copRatingManagement: (params) => dispatch(copRatingManagement(params)),
        initCopRatingManagementActionStatus: () => dispatch(initCopRatingManagementActionStatus()),
        resetCopRatingManagementModal: () => dispatch(resetCopRatingManagementModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


        openRestrictedRuleFundBookDialog: () => dispatch(openRestrictedRuleFundBookDialog()),
        closeRestrictedRuleFundBookDialog: () => dispatch(closeRestrictedRuleFundBookDialog()),
        restrictedRuleEditChangeInput: (params) => dispatch(restrictedRuleEditChangeInput(params)),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(RatingManagementDashboard);