import React, {Component} from 'react';
import './GeneralAgreementDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import GeneralAgreementGrid from './GeneralAgreementGrid';
import AddOrEditGeneralAgreementModalDialog from './AddOrEditGeneralAgreementModalDialog';
import ChangeGeneralAgreementStatusModalDialog from './ChangeGeneralAgreementStatusModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';


class GeneralAgreementDashboard extends Component {

    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});
        this.props.loadGeneralAgreementInfo({id, title: undefined, personInCharge: undefined});
    }


    componentDidUpdate() {
        const {
            loadGeneralAgreementActionStatus,
            loadGeneralAgreementResp,
        } = this.props;
        if(loadGeneralAgreementActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadGeneralAgreementInfoActionStatus();
        }
        if(loadGeneralAgreementActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load General Agreement failed';
            if(!!loadGeneralAgreementResp && !!loadGeneralAgreementResp.respMessage) {
                err = loadGeneralAgreementResp.respMessage;
            }
            message.error(err);
            this.props.initLoadGeneralAgreementInfoActionStatus();
        }

    }


    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }

    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    refreshBtnOnClicked = () => {
        const {
            id,
            title,
            personInCharge,
        } = this.props.generalAgreementQueryFields;
        this.props.loadGeneralAgreementInfo({id, title, personInCharge});
    }


    addOrEditGeneralAgreementBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditGeneralAgreementModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedGeneralAgreementArray,
            } = this.props;
            if(selectedGeneralAgreementArray.length !== 1) {
                message.warning("Please choose one and only one General Agreement");
                return;
            }
            const currentData = selectedGeneralAgreementArray[0];
            this.props.openAddOrEditGeneralAgreementModal(mode, currentData);
        }
    }


    changeGeneralAgreementStatusBtnOnClicked = (mode) => {
        const {
            selectedGeneralAgreementArray,
        } = this.props;
        if(selectedGeneralAgreementArray.length !== 1) {
            message.warning("Please choose one and only one General Agreement");
            return;
        }
        const currentData = selectedGeneralAgreementArray[0];

        // Submitted和RequestExpired状态的不可以直接Del
        // 否则对应的PendingTasks没有机会被Finish
        if(mode === 'DEL' && (currentData.status === 'Submitted' || currentData.status === 'RequestExpired')) {
            message.warn('A General Agreement cannot be Deleted when it is in Submitted or RequestExpired status. Reject it first if you want to Delete it.');
            return;
        }

        if(currentData.status === 'Expired' && (mode === 'APPROVE' || mode === 'REJECT')) {
            message.warning("You cannot Approve or Reject one General Agreement in Expired status");
            return;
        }
        this.props.openChangeGeneralAgreementStatusModal(mode, currentData);
    }


    showAllShowValidBtnOnClicked = () => {
        // 在这里去掉UI上所有选中，在Reducer中清空状态记录
        this.props.generalAgreementGridEvent.api.forEachNode(node => node.setSelected(false));
        this.props.showAllShowValidBtnOnClicked();
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'operateSource', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {
        const {
            userAccountOptions,
            generalAgreementQueryFields,
            loadGeneralAgreementActionStatus,
            onlyShowValidGeneralAgreement,
        } = this.props;
        const {
            id,
            title,
            personInCharge,
        } = generalAgreementQueryFields;


        const refreshBtnIsLoading = loadGeneralAgreementActionStatus === ACTION_STATUS.LOGINING;
        const showAllShowValidBtnName = onlyShowValidGeneralAgreement ? 'showAll' : 'showValid';


        return (
            <div className='GeneralAgreementWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Agreement</Breadcrumb.Item>
                        <Breadcrumb.Item>General Agreement</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="dataID" 
                        value={id}
                        onChange={(e) => {
                            this.onDataIDQueryInputChange({ name: 'id', value: e.target.value })
                        }}
                    />
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="title" 
                        value={title}
                        onChange={(e) => {
                            this.onQueryInputChange({ name: 'title', value: e.target.value })
                        }}
                    />
                    <Select
                        allowClear
                        size='small'
                        showSearch
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="personInCharge" 
                        value={personInCharge}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'personInCharge', value: val })
                        }} >
                        {userAccountOptions}
                    </Select>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.showAllShowValidBtnOnClicked()} 
                        size='small'
                        style={{ width: 75, marginLeft: 10 }}
                        type='primary'>
                        {showAllShowValidBtnName}
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditGeneralAgreementBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditGeneralAgreementBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditGeneralAgreementBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.changeGeneralAgreementStatusBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.changeGeneralAgreementStatusBtnOnClicked('APPROVE')} 
                        size='small'
                        className='positive-button'>
                        Approve
                    </Button>
                    <Button 
                        onClick={()=>this.changeGeneralAgreementStatusBtnOnClicked('REJECT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Reject
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'GeneralAgreement'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button>

                </div>

                <div className='GeneralAgreementGrid'><GeneralAgreementGrid {...this.props} /></div>
                <AddOrEditGeneralAgreementModalDialog {...this.props} />
                <ChangeGeneralAgreementStatusModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />


            </div>
        );
    }

}

export default GeneralAgreementDashboard;
