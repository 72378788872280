import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


class LicensingGrid extends Component {

    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: props.licensingGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 100
            },
            rowSelection: "multiple",
            isRowSelectable: function(node) {
                return !!node.data.id;  // 有id的才能选中
            },
            rowClassRules: {
                'UnProcessedReport': function(params) {
                   let report = params.data.lastUpdatedSource === 'Report';
                   if(report) {
                       return report;
                   }
                   const cptYearList = params.data.licenseCPTYearRcdList;
                   if(!!cptYearList) {
                       report = cptYearList.filter(e => e.lastUpdatedSource === 'Report').length > 0;
                   }
                   return report;
                },
            },
            context: { componentParent: this },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:props.licensingCPTYearRcdGridColumns,
                    rowSelection: "multiple",
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectCptRcdData(cptRcdDataArray);
                    },
                    rowClassRules:{
                        'UnProcessedReport': function(params) {
                            return params.data.lastUpdatedSource === 'Report';
                        },
                    },
                },

                getDetailRowData: function(params) {
                    params.successCallback(params.data.licenseCPTYearRcdList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">CPT Record</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                "</div>"
            },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }

    getRowNodeId = data => {
        return data.id;
    };


    onLicensingDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let licensingDataArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectLicensingData(licensingDataArray);
    }


    render() {
        const {
            loadLicensingInfoResp,
            loadLicensingInfoActionStatus,
        } = this.props;

        const isLoading = loadLicensingInfoActionStatus === ACTION_STATUS.LOGINING;
        const loadLicensingInfoArray = (loadLicensingInfoActionStatus === ACTION_STATUS.READY && !!loadLicensingInfoResp) ? loadLicensingInfoResp : [];

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadLicensingInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            frameworkComponents={this.state.frameworkComponents}
                            isRowSelectable={this.state.isRowSelectable}
                            statusBar={this.state.statusBar}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onSelectionChanged={this.onLicensingDataSelectionChanged}
                        />
                    )}
            </div>
        );
    }

}

export default LicensingGrid;
