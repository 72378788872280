import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Select, Button, message, DatePicker,Input } from 'antd';
import moment from 'moment';

import AnswerReviewGrid from './AnswerReviewGrid';
import QuestionnaireEmailEditDialog from './QuestionnaireEmailEditDialog';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../utils/QuestionnaireEmailSendMode';
const { MonthPicker } = DatePicker;
const Option = Select.Option;

class AnswerReview extends Component {

  state ={
    questionnaireGuid: '',
    userAccountName: '',
    sentMonth: undefined,
    subMonth: undefined,
    answerStatus: '',
    sentOnFrom: undefined,
    sentOnTo: undefined,
    questionnaireVersion:'',
  }

  componentDidMount () {
    this.props.loadQuestionnaires();
    // 默认只查询最近90天的，否则数据量太大
    const last90 = moment().subtract('days', 90).format('YYYY-MM-DD');
    this.setState({
      sentOnFrom: last90
    });
    const today = moment().format('YYYY-MM-DD');
    if(!this.state.sentOnTo) {
      this.setState({
        sentOnTo: today
      });
    }
    // 初始查询
    this.props.loadQuestionnaireResults({
      questionnaireGuid: '',
      userAccountName: '',
      sentMonth: '',
      subMonth: '',
      answerStatus: '',
      sentOnFrom: last90,
      sentOnTo: today,
      questionnaireVersion:'',
    });
  }

  componentDidUpdate() {
    const {
      // query
      loadQuestionnaireResultsActionStatus,
      // Update Questionnaire Score 
      updateQuestionnaireScoreActionStatus,
      updateQuestionnaireScoreErrMsg,
      // download pdf
      downloadQuestionnaireResultPDFActionStatus,
      downloadQuestionnaireResultPDFActionResp,
    } = this.props;

    // query
    if (loadQuestionnaireResultsActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadQuestionnaireResultsActionStatus();
    }
    if (loadQuestionnaireResultsActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadQuestionnaireResultsActionStatus();
      message.error('query failed');
    }

    // Update Questionnaire Score
    if (updateQuestionnaireScoreActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initUpdateQuestionnaireScoreActionStatus();
    }
    if (updateQuestionnaireScoreActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateQuestionnaireScoreActionStatus();
      message.error(updateQuestionnaireScoreErrMsg);
    }

    // download pdf
    if (downloadQuestionnaireResultPDFActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initDownloadQuestionnaireResultPDFActionStatus();
    }
    if (downloadQuestionnaireResultPDFActionStatus === ACTION_STATUS.ERROR) {
      this.props.initDownloadQuestionnaireResultPDFActionStatus();
      let err = 'Download PDF failed';
      if(!!downloadQuestionnaireResultPDFActionResp && !!downloadQuestionnaireResultPDFActionResp.respMessage) {
        err = downloadQuestionnaireResultPDFActionResp.respMessage;
      }
      message.error(err);
    }

  }

  queryAnswersButtonOnClicked = () => {
    const {
      questionnaireGuid,
      userAccountName,
      sentMonth,
      subMonth,
      answerStatus,
      sentOnFrom,
      sentOnTo,
      questionnaireVersion,
    } = this.state;

    this.props.loadQuestionnaireResults({
      questionnaireGuid,
      userAccountName,
      sentMonth,
      subMonth,
      answerStatus,
      sentOnFrom,
      sentOnTo,
      questionnaireVersion,
    });
  }

  // 接受问卷答案
  acceptQuestionnaireAnswerBtnOnClicked = () => {
    const selectedQuestionnaireResults = this.props.selectedQuestionnaireResults;

    if (selectedQuestionnaireResults.length === 0) {
      message.warning("Please select a questionnaire result at least");
      return;
    }

    const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);

    // this.props.openQuestionnaireResultOperateModalDialog('Accept', questionnaireResultGuidList);
    this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_ACCEPT, {
      questionnaireResultGuidList,
    });
  }

  // 拒绝问卷答案
  rejectQuestionnaireAnswerBtnOnClicked = () => {
    const selectedQuestionnaireResults = this.props.selectedQuestionnaireResults;

    if (selectedQuestionnaireResults.length === 0) {
      message.warning("Please select a questionnaire result at least");
      return;
    }

    const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);

    // this.props.openQuestionnaireResultOperateModalDialog('Reject', questionnaireResultGuidList);
    this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.QUESTIONNAIRE_REJECT, {
      questionnaireResultGuidList,
    });
  }

  // 查看问卷答案
  viewQuestionnaireAnswerBtnOnClicked = () => {
    const {
      selectedQuestionnaireResults,
    } = this.props;

    if (selectedQuestionnaireResults.length !== 1) {
      message.warning("Please select a questionnaire result");
      return;
    }

    const questionnaireResult = selectedQuestionnaireResults[0];

    this.props.history.push(`/questionnaire-answer-review/${questionnaireResult.guid}`);
  }

  // 重发邮件提醒
  remindEmailBtnOnClicked = () => {
    const selectedQuestionnaireResults = this.props.selectedQuestionnaireResults;

    if (selectedQuestionnaireResults.length === 0) {
      message.warning("Please select a questionnaire result at least");
      return;
    }

    const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);

    // this.props.sendRemindEmail(questionnaireResultGuidList);

    this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.EMAIL_RESEND, {
      questionnaireResultGuidList,
    });
  }

  // 下载pdf
  downloadPDFBtnOnClicked = () => {
    const selectedQuestionnaireResults = this.props.selectedQuestionnaireResults;
    if (selectedQuestionnaireResults.length === 0) {
      message.warning("Please select one questionnaire result at least");
      return;
    }
    const checkList = selectedQuestionnaireResults.filter(e => !!e.savedFileName);
    if(checkList.length === 0) {
      message.warning("At least one of the selected record must have Saved File Name");
      return;
    }
    if(checkList.length > 100) {
      message.warning("You can download at most 100 files at one time");
      return;
    }
    const questionnaireResultGuidList = selectedQuestionnaireResults.map(questionnaireResult => questionnaireResult.guid);
    this.props.downloadQuestionnaireResultPDF(questionnaireResultGuidList);
  }
  constructor(props){
    super(props);
    this.state={
        detailData:{
        }
    }
}

onInputChange = ({name, value}) => {
    this.props.onQueryInputChange({ name, value });
    const { detailData } = this.state;
    let updateData =  {
        ...detailData,
        [name]: value
    };
    this.setState({
        detailData: updateData
    })
}
  render() {
    const {
      questionnaireOptions,
      userAccountOptions,
      statusAnswerOptions,
      loadQuestionnaireResultsActionStatus,
      // Accept Questionnaire Result
      acceptQuestionnaireResultActionStatus,

      // Reject Questionnaire Result
      rejectQuestionnaireResultActionStatus,

      // Send Remind Email
      sendRemindEmailActionStatus,

      // download pdf
      downloadQuestionnaireResultPDFActionStatus,
    } = this.props;


    const isLoading = loadQuestionnaireResultsActionStatus === ACTION_STATUS.LOGINING;
    const acceptBtnLoading = acceptQuestionnaireResultActionStatus === ACTION_STATUS.LOGINING;
    const rejectBtnLoading = rejectQuestionnaireResultActionStatus === ACTION_STATUS.LOGINING;
    const remindEmailBtnLoading = sendRemindEmailActionStatus === ACTION_STATUS.LOGINING;
    const donwloadPDFBtnLoading = downloadQuestionnaireResultPDFActionStatus === ACTION_STATUS.LOGINING;

    const sentOnFrom = this.state.sentOnFrom;
    const sentOnTo = this.state.sentOnTo;
    
    return (
      <div className='answerReviewWrapper'>

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Questionnaire</Breadcrumb.Item>
            <Breadcrumb.Item>Answer Review</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='operationBar'>
        <div className='breadcrumbWrapper'>   
          <Breadcrumb>
          <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
          </Breadcrumb>
          </div>
          <Input 
              placeholder="Questionnaire Version"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                this.setState({
                  questionnaireVersion: event.target.value
                });
                  // this.onInputChange({ name: 'questionnaireVersion', value: event.target.value })
              }} 
          />

          {/* <Select 
            allowClear
            showSearch
            size='small'
            style={{ width: 250, marginLeft: 10 }}
            onChange={(value) => {
              // this.onInputChange({ name: 'seriesGuid', value })
              this.setState({
                questionnaireGuid: value
              });
            }} 
            // value={questionnaireGuid}
            placeholder="Questionnaire Version">
            {questionnaireOptions}
          </Select> */}

          <Select
            allowClear
            size='small'
            showSearch
            style={{ width: 150, marginLeft: 10 }}
            onChange={(value) => {
              // this.onInputChange({ name: 'seriesGuid', value })
              this.setState({
                userAccountName: value
              });
            }} 
            placeholder="Answeree">
            {userAccountOptions}
          </Select>
          <Select
              allowClear
              size='small'
              style={{ width: 150, marginLeft: 10 }}
              onChange={(val) => {
                 // this.onInputChange({ name: 'answerStatus', value: val })
                 this.setState({
                  answerStatus: val
                });
              }} 
              placeholder="Status">
              {statusAnswerOptions}
          </Select>

          <MonthPicker
            placeholder="Submitted Month"
            style={{ marginLeft: 10, width: 150}}
            allowClear={true}
            // value={moment(
            //     data.monthStart,
            //     'YYYY-MM'
            // )}
            size="small"
            onChange={(date, dateString) => {
              this.setState({
                subMonth: dateString
              });
                // this.onInputChange({
                // name: 'monthStart',
                // value: dateString
                // });
            }}
          />
          <MonthPicker
            placeholder="Sent Month"
            style={{ marginLeft: 10, width: 150}}
            allowClear={true}
            // value={moment(
            //     data.monthStart,
            //     'YYYY-MM'
            // )}
            size="small"
            onChange={(date, dateString) => {
              this.setState({
                sentMonth: dateString
              });
                // this.onInputChange({
                // name: 'monthStart',
                // value: dateString
                // });
            }}
          />
          {/* <DatePicker 
              allowClear
              size='small'
              style={{marginLeft: 10, width: 150}}
              value={!sentOnFrom ? null : moment(sentOnFrom)}
              placeholder='Sent On From'
              onChange={(date, dateString) => {
                this.setState({
                  sentOnFrom: dateString
                });
          }} />
          <DatePicker 
              allowClear
              size='small'
              style={{marginLeft: 10, width: 150}}
              value={!sentOnTo ? null : moment(sentOnTo)}
              placeholder='Sent On To'
              onChange={(date, dateString) => {
                this.setState({
                  sentOnTo: dateString
                });
          }} /> */}

          <Button 
            type="primary" 
            icon="search" 
            style={{ fontSize:14 }} 
            size='small'
            onClick={this.queryAnswersButtonOnClicked} 
            loading={isLoading}
          />

          <Button 
            type="primary" 
            size='small'
            onClick={this.viewQuestionnaireAnswerBtnOnClicked} 
          >View</Button>

          <Button 
            className='positive-button'
            size='small'
            onClick={this.acceptQuestionnaireAnswerBtnOnClicked} 
            loading={acceptBtnLoading}
          >Accept</Button>

          <Button 
            className='negative-button'
            size='small'
            onClick={this.rejectQuestionnaireAnswerBtnOnClicked} 
            loading={rejectBtnLoading}
          >Reject</Button>

          <Button 
            type="primary" 
            size='small'
            onClick={this.remindEmailBtnOnClicked} 
            loading={remindEmailBtnLoading}
          >Remind</Button>

          <Button 
            type="primary" 
            size='small'
            onClick={this.downloadPDFBtnOnClicked} 
            loading={donwloadPDFBtnLoading}
          >downloadPDF</Button>

        </div>

        <div className='questionnaireListGrid'>
          <AnswerReviewGrid {...this.props} />
        </div>

        <QuestionnaireEmailEditDialog {...this.props} />
      </div>
    );
  }
}

export default AnswerReview;