import {
  // Load Restricted Asset
  START_LOAD_RESTRICTED_ASSET,
  LOAD_RESTRICTED_ASSET_SUCCESS,
  LOAD_RESTRICTED_ASSET_FAILURE,
  INIT_LOAD_RESTRICTED_ASSET_ACTION_STATUS,

  // Create Restricted Asset
  START_CREATE_RESTRICTED_ASSET,
  CREATE_RESTRICTED_ASSET_SUCCESS,
  CREATE_RESTRICTED_ASSET_FAILURE,
  INIT_CREATE_RESTRICTED_ASSET_ACTION_STATUS,

  // Update Restricted Asset
  START_UPDATE_RESTRICTED_ASSET,
  UPDATE_RESTRICTED_ASSET_SUCCESS,
  UPDATE_RESTRICTED_ASSET_FAILURE,
  INIT_UPDATE_RESTRICTED_ASSET_ACTION_STATUS,

  // Batch Delete Restricted Asset
  START_BATCH_DELETE_RESTRICTED_ASSET,
  BATCH_DELETE_RESTRICTED_ASSET_SUCCESS,
  BATCH_DELETE_RESTRICTED_ASSET_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_ASSET_ACTION_STATUS,

  // Open/Reset Restricted Asset Modal Dialog
  RESTRICTED_ASSET_MODAL_DIALOG_OPEN,
  RESTRICTED_ASSET_MODAL_DIALOG_RESET,

  // Restricted Asset Edit Change Input
  RESTRICTED_ASSET_EDIT_CHANGE_INPUT,

  // RadioGroup Change
  RADIO_GROUP_CHANGE,

  // Select Restricted Asset
  SELECT_RESTRICTED_ASSET_CLASS,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,

} from './restrictedAssetConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


// ========================================================================
// Load Restricted Asset
function startLoadRestrictedAsset() {
  return {
    type: START_LOAD_RESTRICTED_ASSET
  };
}

export function loadRestrictedAsset() {
  return function(dispatch) {
    dispatch(startLoadRestrictedAsset());
    httpClient
      .get(UrlConfig.QUERY_RESTRICTED_ASSET)
      .then(resp => dispatch(loadRestrictedAssetSuccess(resp)))
      .catch(err => dispatch(loadRestrictedAssetFailure(err)));
  };
}

function loadRestrictedAssetSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_ASSET_SUCCESS,
    payload: resp
  };
}

function loadRestrictedAssetFailure(err) {
  return {
    type: LOAD_RESTRICTED_ASSET_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedAssetActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_ASSET_ACTION_STATUS
  };
}

// Create Restricted Asset
function startCreateRestrictedAsset() {
  return {
    type: START_CREATE_RESTRICTED_ASSET
  };
}

export function createRestrictedAsset(restrictedAsset) {
  return function(dispatch) {
    dispatch(startCreateRestrictedAsset());
    httpClient
      .post(UrlConfig.NEW_RESTRICTED_ASSET, restrictedAsset)
      .then(resp => dispatch(createRestrictedAssetSuccess(resp)))
      .catch(err => dispatch(createRestrictedAssetFailure(err)));
  };
}

function createRestrictedAssetSuccess(resp) {
  return {
    type: CREATE_RESTRICTED_ASSET_SUCCESS,
    payload: resp
  };
}

function createRestrictedAssetFailure(err) {
  return {
    type: CREATE_RESTRICTED_ASSET_FAILURE,
    payload: err
  };
}

export function initCreateRestrictedAssetActionStatus() {
  return {
    type: INIT_CREATE_RESTRICTED_ASSET_ACTION_STATUS
  };
}

// Update Restricted Asset
function startUpdateRestrictedAsset() {
  return {
    type: START_UPDATE_RESTRICTED_ASSET
  };
}

export function updateRestrictedAsset(restrictedAsset) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedAsset());
    httpClient
      .post(UrlConfig.UPDATED_RESTRICTED_ASSET, restrictedAsset)
      .then(resp => dispatch(updateRestrictedAssetSuccess(resp)))
      .catch(err => dispatch(updateRestrictedAssetFailure(err)));
  };
}

function updateRestrictedAssetSuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_ASSET_SUCCESS,
    payload: resp
  };
}

function updateRestrictedAssetFailure(err) {
  return {
    type: UPDATE_RESTRICTED_ASSET_FAILURE,
    payload: err
  };
}

export function initUpdateRestrictedAssetActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_ASSET_ACTION_STATUS
  };
}

// Batch Delete Restricted Asset
function startBatchDeleteRestrictedAsset() {
  return {
    type: START_BATCH_DELETE_RESTRICTED_ASSET
  };
}

export function batchDeleteRestrictedAsset(restrictedAssetIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteRestrictedAsset());
    httpClient
      .post(UrlConfig.BATCH_DELETED_RESTRICTED_ASSET, restrictedAssetIdList)
      .then(resp => dispatch(batchDeleteRestrictedAssetSuccess(resp)))
      .catch(err => dispatch(batchDeleteRestrictedAssetFailure(err)));
  };
}

function batchDeleteRestrictedAssetSuccess(resp) {
  return {
    type: BATCH_DELETE_RESTRICTED_ASSET_SUCCESS,
    payload: resp
  };
}

function batchDeleteRestrictedAssetFailure(err) {
  return {
    type: BATCH_DELETE_RESTRICTED_ASSET_FAILURE,
    payload: err
  };
}

export function initBatchDeleteRestrictedAssetActionStatus() {
  return {
    type: INIT_BATCH_DELETE_RESTRICTED_ASSET_ACTION_STATUS
  };
}


// ========================================================================
// RadioGroup Change
export function radioGroupChange(value) {
  return {
    type: RADIO_GROUP_CHANGE,
    payload: value,
  };
}

// Open/Reset Restricted Asset Modal Dialog
export function openRestrictedAssetEditModalDialog(isAddMode, restrictedAsset) {
  return {
    type: RESTRICTED_ASSET_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      restrictedAsset,
    },
  };
}

export function resetRestrictedAssetEditModalDialog() {
  return {
    type: RESTRICTED_ASSET_MODAL_DIALOG_RESET
  };
}

// Restricted Asset Edit Change Input
export function restrictedAssetEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_ASSET_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}


// ========================================================================
// Select Restricted Asset
export function selectRestrictedAssetClass(payload) {
  return {
    type: SELECT_RESTRICTED_ASSET_CLASS,
    payload
  };
}

// Fund Code Select Changed
export function fundCodeSelectChanged(fundCode) {
  return {
    type: FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Book Code Select Changed
export function bookCodeSelectChanged(bookCode) {
  return {
    type: BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}