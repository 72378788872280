import { createReducer } from '../../../../utils/reducerUtil';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import moment from 'moment';

import {
    LOAD_MONTHLY_REPORT_LIST_SUCCESS,
    LOAD_MONTHLY_REPORT_LIST_FAIL,
    LOAD_MONTHLY_REPORT_DETAIL_SUCCESS,
    LOAD_MONTHLY_REPORT_DETAIL_FAIL,
    SAVE_MONTHLY_REPORT_SUCCESS,
    SAVE_MONTHLY_REPORT_FAIL,
    DEL_MONTHLY_REPORT_SUCCESS,
    DEL_MONTHLY_REPORT_FAIL,
    OPEN_MONTHLY_REPORT_LIST_DIALOG,
    CLOSE_MONTHLY_REPORT_LIST_DIALOG,
    ON_QUERY_PARAMS_CHANGE
} from './MonthlyReportConstants';

const initialState = {
    queryParams:{
        monthStart: moment()
        .clone()
        .add('month', -3)
        .format('YYYY-MM'),
        monthTo: moment().format('YYYY-MM'),
        currentSelected: null,
    },
    monthlyReportList: [],
    monthlyReportDetail:{
    },
    monthlyReportModal:{
        isOpened: false
    },
      // 附件操作URL
  monthlyReportAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MONTHLY_REVIEW_UPLOAD_ATTACHMENT,
  monthlyReportAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MONTHLY_REVIEW_DOWNLOAD_ATTACHMENT,
}

function loadMonthlyReportListSuccess(state, resp) {
  const monthlyReportList = resp.data;
  if(monthlyReportList){
    const monthlyReportAttachmentDownloadUrl = initialState.monthlyReportAttachmentDownloadUrl;
    monthlyReportList.forEach(item=>{
      const resData = item;
      if(resData && resData.attachmentOriginName){
        const fileList = [];
        const attachmentOriginName = resData.attachmentOriginName;
        const attachmentOriginNameArr = attachmentOriginName.split(";");
        const attachment = resData.attachment;
        let attachmentArr = [];
        attachmentArr = attachment.split(";");
        attachmentOriginNameArr.forEach((element, index) => {
          if(element){
            const attachmentData = attachmentArr[index];
            const file = {
              uid: attachmentData,
              name: element,
              status: 'done',
              url: monthlyReportAttachmentDownloadUrl + "/" + attachmentData,
              response: {
                respCode: "S0001",
                data: {
                  fileNameWithTimeStamp: attachmentData,
                  originalFileName: element,
                }
              }
            };
            fileList.push(file);
          }
        });
        resData['fileList'] = fileList;
      }
    })
  }
  return {
    ...state,
    monthlyReportList
  };
}

function loadMonthlyReportListFailure(state, err) {
  return {
    ...state
  };
}

function loadMonthlyReportDetailSuccess(state, resp) {
    const monthlyReportAttachmentDownloadUrl = initialState.monthlyReportAttachmentDownloadUrl;
    const resData = resp.data;
    if(resData && resData.attachmentOriginName){
      const fileList = [];
      const attachmentOriginName = resData.attachmentOriginName;
      const attachmentOriginNameArr = attachmentOriginName.split(";");
      const attachment = resData.attachment;
      let attachmentArr = [];
      attachmentArr = attachment.split(";");
      attachmentOriginNameArr.forEach((element, index) => {
        if(element){
          const attachmentData = attachmentArr[index];
          const file = {
            uid: attachmentData,
            name: element,
            status: 'done',
            url: monthlyReportAttachmentDownloadUrl + "/" + attachmentData,
            response: {
              respCode: "S0001",
              data: {
                fileNameWithTimeStamp: attachmentData,
                originalFileName: element,
              }
            }
          };
          fileList.push(file);
        }
      });
      resData['fileList'] = fileList;
    }
    return {
      ...state,
      monthlyReportDetail: resData
    };
}

function loadMonthlyReportDetailFailure(state, err) {
  return {
    ...state
  };
}

function saveMonthlyReportSuccess(state, resp) {
  return {
    ...state
  };
}

function saveMonthlyReportFailure(state, err) {
  return {
    ...state
  };
}

function delMonthlyReportSuccess(state, resp) {
  return {
    ...state
  };
}

function delMonthlyReportFailure(state, err) {
  return {
    ...state
  };
}

function openMonthlyReportDialog(state, params) {
    const updateData = {
        ...state.monthlyReportModal,
        ...params
    }
    updateData.isOpened = true;
    return {
        ...state,
        monthlyReportModal: updateData
    };
}

function closeMonthlyReportDialog(state) {
    const updateData = {
        ...state.monthlyReportModal
    }
    updateData.isOpened = false;
    return {
        ...state,
        monthlyReportModal: updateData
    };
}

export function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.queryParams,
        [name]: value,
    };
    return {
        ...state,
        queryParams: upatedFields,
    }
}

export default createReducer(initialState, {
    // query
    [LOAD_MONTHLY_REPORT_LIST_SUCCESS]: loadMonthlyReportListSuccess,
    [LOAD_MONTHLY_REPORT_LIST_FAIL]: loadMonthlyReportListFailure,
    [LOAD_MONTHLY_REPORT_DETAIL_SUCCESS]: loadMonthlyReportDetailSuccess,
    [LOAD_MONTHLY_REPORT_DETAIL_FAIL]: loadMonthlyReportDetailFailure,
    [SAVE_MONTHLY_REPORT_SUCCESS]: saveMonthlyReportSuccess,
    [SAVE_MONTHLY_REPORT_FAIL]: saveMonthlyReportFailure,
    [DEL_MONTHLY_REPORT_SUCCESS]: delMonthlyReportSuccess,
    [DEL_MONTHLY_REPORT_FAIL]: delMonthlyReportFailure,
    [OPEN_MONTHLY_REPORT_LIST_DIALOG]: openMonthlyReportDialog,
    [CLOSE_MONTHLY_REPORT_LIST_DIALOG]: closeMonthlyReportDialog,
    [ON_QUERY_PARAMS_CHANGE]: onQueryInputChange
});