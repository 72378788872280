import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
    // search change input 
    TRADES_REVIEW_SEARCH_CHANGE_INPUT,

    // do query
    START_QUERY_TRADES_REVIEW,
    QUERY_TRADES_REVIEW_SUCCESS,
    QUERY_TRADES_REVIEW_FAILURE,
    INIT_QUERY_TRADES_REVIEW_ACTION_STATUS,

    // query report
    START_QUERY_TRADES_REVIEW_REPORT,
    QUERY_TRADES_REVIEW_REPORT_SUCCESS,
    QUERY_TRADES_REVIEW_REPORT_FAILURE,
    INIT_QUERY_TRADES_REVIEW_REPORT_ACTION_STATUS,

    // add grid to container
    ADD_TRADES_REVIEW_GRID_TO_PROPS,
    ADD_TRADES_REVIEW_REPORT_GRID_TO_PROPS,


} from './tradesReviewConstants';


// search change input 
export function tradesReviewSearchChangeInput({name, value}) {
    return {
        type: TRADES_REVIEW_SEARCH_CHANGE_INPUT,
        payload: { name, value, }
    };
}


function getQueryReqParams(params) {
    let tradeDateStart = params.tradeDateStart !== undefined && params.tradeDateStart !== null && params.tradeDateStart !== ''
    ? params.tradeDateStart.format("YYYY-MM-DD") : '';
    let tradeDateEnd = params.tradeDateEnd !== undefined && params.tradeDateEnd !== null && params.tradeDateEnd !== ''
        ? params.tradeDateEnd.format("YYYY-MM-DD") : '';
    const requestParams = {
    tradeDateStart: tradeDateStart,
    tradeDateEnd: tradeDateEnd,
    ticker: params.ticker,
    };
    return requestParams;
}


// do query
export function queryTradesReview(params) {
    const requestParams = getQueryReqParams(params);
    return function(dispatch) {
      dispatch(startQueryTradesReview());
      httpClient
        .get(UrlConfig.QUERY_TRADES_REVIEW, requestParams)
        .then(resp => dispatch(queryTradesReviewSuccess(resp)))
        .catch(err => dispatch(queryTradesReviewFailure(err)));
    };
}    
function startQueryTradesReview() {
    return {
      type: START_QUERY_TRADES_REVIEW,
    };
}
function queryTradesReviewSuccess(resp) {
    return {
      type: QUERY_TRADES_REVIEW_SUCCESS,
      payload: resp
    };
  }
 function queryTradesReviewFailure(err) {
    return {
      type: QUERY_TRADES_REVIEW_FAILURE,
      payload: err
    };
}
export function initQueryTradesReviewActionStatus() {
    return {
        type: INIT_QUERY_TRADES_REVIEW_ACTION_STATUS
    };
}



// query report
export function queryTradesReviewReport(params) {
    const requestParams = getQueryReqParams(params);
    return function(dispatch) {
      dispatch(startQueryTradesReviewReport());
      httpClient
        .get(UrlConfig.QUERY_TRADES_REVIEW_REPORT, requestParams)
        .then(resp => dispatch(queryTradesReviewReportSuccess(resp)))
        .catch(err => dispatch(queryTradesReviewReportFailure(err)));
    };
}    
function startQueryTradesReviewReport() {
    return {
      type: START_QUERY_TRADES_REVIEW_REPORT,
    };
}
function queryTradesReviewReportSuccess(resp) {
    return {
      type: QUERY_TRADES_REVIEW_REPORT_SUCCESS,
      payload: resp
    };
  }
 function queryTradesReviewReportFailure(err) {
    return {
      type: QUERY_TRADES_REVIEW_REPORT_FAILURE,
      payload: err
    };
}
export function initQueryTradesReviewReportActionStatus() {
    return {
        type: INIT_QUERY_TRADES_REVIEW_REPORT_ACTION_STATUS
    };
}


// add grid to container
export function addTradesReviewGridToProps(grid) {
    return {
      type: ADD_TRADES_REVIEW_GRID_TO_PROPS,
      payload: grid,
    }
}
export function addTradesReviewReportGridToProps(grid) {
    return {
      type: ADD_TRADES_REVIEW_REPORT_GRID_TO_PROPS,
      payload: grid,
    }
}

