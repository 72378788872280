import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import ShortPosReportChkResultGrid from './ShortPosReportChkResultGrid';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

class ShortPosReportChkResultDialog extends Component {

  refreshBtnOnClicked = () => {
    const {
      fundCode,
      dateString,
    } = this.props.shortPositionReportCheckResultControlModal;

    this.props.loadShortPositionReportCheckResult(fundCode, dateString);
  }

  render() {
    const {
      shortPositionReportCheckResultControlModal,
      loadShortPositionReportCheckResultActionStatus,
    } = this.props;

    const {
      isOpened,
    } = shortPositionReportCheckResultControlModal;

    let modalTitle = 'Short Position Report Check Result';
    const isLoading = loadShortPositionReportCheckResultActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1200}
          visible={isOpened}
          onOk={() => this.props.resetShortPositionReportCheckResultModalDialog()}
          onCancel={() => this.props.resetShortPositionReportCheckResultModalDialog()}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
        >
          <div style={{ marginBottom: 10 }}>
            <Button 
              onClick={this.refreshBtnOnClicked} 
              size='small'
              style={{ fontSize: 10 }}
              loading={isLoading}
              type='primary'>
              Refresh
            </Button>

          </div>
          
          <div style={{ height: 600 }}>
            <ShortPosReportChkResultGrid {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default ShortPosReportChkResultDialog;