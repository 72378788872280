import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Button, message, Modal, Input,Select ,Row, Col,} from 'antd';

import LicensingFormListGrid from './LicensingFormListGrid';
import AnswereeSelectDialog from './AnswereeSelectDialog';
import LicensingFormEditDialog from './LicensingFormEditDialog';
import QuestionEditDialog from './QuestionEditDialog';
import './LicensingForm.css';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

//todo
import QuestionnaireOpMode from '../../../../../utils/QuestionnaireOpMode';

const confirm = Modal.confirm;

class LicensingFormList extends Component {

  componentDidMount () {
    this.props.loadLicensingForm({licensingFormVersion: undefined,nameOnLicensingForm: undefined,status: 'Activated'});
    // 默认勾选已选择的答题人
    this.props.loadAnswers();
  }

  componentDidUpdate() {
    const { 
      batchDeleteLicensingFormActionStatus,
      batchDeleteQuestionsActionStatus,
    } = this.props;

    // 批量删除 LicensingForm 成功
    if (batchDeleteLicensingFormActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('LicensingForm are deleted successfully!');
      this.props.loadLicensingForm();
      this.props.initBatchDeleteLicensingFormActionStatus();
    } 

    // 批量删除 LicensingForm 失败
    if (batchDeleteLicensingFormActionStatus === ACTION_STATUS.ERROR) {
      message.error('LicensingForm are deleted failed!');
      this.props.initBatchDeleteLicensingFormActionStatus();
    }

    // 批量删除 Question 成功
    if (batchDeleteQuestionsActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Questions are deleted successfully!');
      this.props.loadLicensingForm();
      this.props.initBatchDeleteQuestionActionStatus();
    } 

    // 批量删除 Question 失败
    if (batchDeleteQuestionsActionStatus === ACTION_STATUS.ERROR) {
      message.error('Questions are deleted failed!');
      this.props.initBatchDeleteQuestionActionStatus();
    }
  }

  addLicensingFormButtonOnClicked = () => {
    this.props.openLicensingFormEditModalDialog(QuestionnaireOpMode.NEW, null);
  }

  addQuestionButtonOnClicked = () => {
    this.props.openQuestionEditModalDialog(true, null);
  }

  editLicensingFormButtonOnClicked = () => {
    const {
      selectedLicensingForm,
    } = this.props;

    if (selectedLicensingForm.length !== 1) {
      message.warning("Please select one licensingForm!");
      return;
    }

    this.props.openLicensingFormEditModalDialog(QuestionnaireOpMode.MODIFY, selectedLicensingForm[0]);
  }

  copyLicensingFormButtonOnClicked = () => {
    const {
      selectedLicensingForm,
    } = this.props;

    if (selectedLicensingForm.length !== 1) {
      message.warning("Please select one licensingForm!");
      return;
    }

    this.props.openLicensingFormEditModalDialog(QuestionnaireOpMode.COPY, selectedLicensingForm[0]);
  }

  editQuestionButtonOnClicked = () => {
    const {
      selectedQuestions
    } = this.props;

    if (selectedQuestions.length !== 1) {
      message.warning("Please select one question!");
      return;
    }

    this.props.openQuestionEditModalDialog(false, selectedQuestions[0]);
  }

  delLicensingFormButtonOnClicked = () => {
    const {
      selectedLicensingForm,
    } = this.props;

    if (selectedLicensingForm.length === 0) {
      message.warning("Please select one licensingForm at least!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the licensingForms?',
      content: '',
      onOk() {
        const licensingFormIdList = selectedLicensingForm.map(licensingForm => licensingForm.guid);

        that.props.batchDeleteLicensingForm(licensingFormIdList);
      },
      onCancel() {},
    });
  }

  delQuestionButtonOnClicked = () => {
    const {
      selectedQuestions
    } = this.props;

    if (selectedQuestions.length === 0) {
      message.warning("Please select one question at least!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the questions?',
      content: '',
      onOk() {
        const questionIdList = selectedQuestions.map(question => question.guid);

        that.props.batchDeleteQuestion(questionIdList);
      },
      onCancel() {},
    });
  }

  previewButtonOnClicked = () => {
    const {
      selectedLicensingForm,
    } = this.props;

    if (selectedLicensingForm.length !== 1) {
      message.warning("Please select one licensingForm!");
      return;
    }

    const licensingForm = selectedLicensingForm[0];

    this.props.history.push(`/licensingForm-preview/${licensingForm.guid}`);
  }

  selAnswereeButtonOnClicked = () => {
    const {
      selectedLicensingForm
    } = this.props;

    if (selectedLicensingForm.length !== 1) {
      message.warning("Please select one licensingForm!");
      return;
    }

    this.props.loadUserAccounts();  // 每次打开页面需要先刷新userAccount
    this.props.openAnswereeSelectModalDialog();
  }

  refreshButtonOnClicked = () => {
    const {
      licensingFormVersion,
      nameOnLicensingForm,
      status,
  } = this.props.licensingFormQueryFields;

    this.props.loadLicensingForm({licensingFormVersion,nameOnLicensingForm,status});
    // 默认勾选已选择的答题人
    // this.props.loadAnswers();
  }
  onInputChange = ({ name, value }) => {
    this.props.onQueryInputChange({ name, value });
};
  render() {
    const {
      statusOptions,
      loadLicensingFormActionStatus,
    } = this.props;

    const refreshBtnIsLoading = loadLicensingFormActionStatus === ACTION_STATUS.LOGINING;
    
    return (
      <div className="licensingFormListWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>LicensingForm</Breadcrumb.Item>
            <Breadcrumb.Item>Question List</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      
        <div className='operationBar'>
        <div className='breadcrumbWrapper'>   
          <Breadcrumb>
          <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
          </Breadcrumb>
          </div>
          <Input 
              placeholder="LicensingForm Version"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'licensingFormVersion', value: event.target.value })
              }} 
          />
          <Input 
              placeholder="Name on LicensingForm"
              size="small"
              style={{ width: 150, marginLeft: 10 }}
              onPressEnter={this.refreshBtnOnClicked}
              onChange={(event) => {
                  this.onInputChange({ name: 'nameOnQuestionnaire', value: event.target.value })
              }} 
          />
          <Select
              allowClear
              size='small'
              style={{ width: 150, marginLeft: 10 }}
              onChange={(val) => {
                  this.onInputChange({ name: 'status', value: val })
              }} 
              placeholder="Status">
              {statusOptions}
          </Select>
          <Button 
              onClick={this.refreshButtonOnClicked} 
              loading={refreshBtnIsLoading}
              size='small'
              style={{ marginLeft: 10 }}
              type='primary'
              icon="search" 
          />
          </div>
          <div className='breadcrumbWrapper'>  
          <Button 
            onClick={this.addLicensingFormButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            className='positive-button'>
            Add LicensingForm
          </Button>
          <Button 
            onClick={this.editLicensingFormButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Edit LicensingForm
          </Button>
          <Button 
            onClick={this.copyLicensingFormButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Copy LicensingForm
          </Button>

          <Button 
            onClick={this.copyLicensingFormButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Deactivate
          </Button>

          <Button 
            onClick={this.delLicensingFormButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            className='negative-button'>
            Del LicensingForm
          </Button>

          <Button 
            onClick={this.addQuestionButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            className='positive-button'>
            Add Item
          </Button>





          <Button 
            onClick={this.editQuestionButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Edit Item
          </Button>



          <Button 
            onClick={this.delQuestionButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            className='negative-button'>
            Del Item
          </Button>

          <Button 
            onClick={this.refreshButtonOnClicked} 
            loading={refreshBtnIsLoading}
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Refresh
          </Button>

          <Button 
            onClick={this.previewButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Preview
          </Button>

          <Button 
            onClick={this.selAnswereeButtonOnClicked} 
            size='small'
            style={{ marginLeft: 10 }}
            type='primary'>
            Select Answeree
          </Button>
        </div>

        <div className='licensingFormListGrid'>
          <LicensingFormListGrid {...this.props} />
        </div>

        <AnswereeSelectDialog {...this.props}/>
        <LicensingFormEditDialog {...this.props}/>
        <QuestionEditDialog {...this.props}/>
      </div>
    );
  }
}

export default LicensingFormList;