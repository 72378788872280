import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';
import JsonTooltipComponent from '../../../../../utils/JsonTooltipComponent';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';

class PresonalInfoGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
                {field: 'id', headerName: "dataID", cellClass: 'non-number', width: 120, cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                {field: 'name', headerName: 'Name', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                {field: 'department', headerName: 'Department', cellClass: 'non-number', width: 100},
                {field: 'office', headerName: 'Office', cellClass: 'non-number', width: 100},
                {field: 'status', headerName: 'Status', cellClass: 'non-number', width: 100},
                {field: 'terminationDate', headerName: 'Termination Date', cellClass: 'non-number', width: 150},
                {field: 'lastConfirmDate', headerName: 'Last Confirm Date', cellClass: 'non-number', width: 100},

                {field: 'createdDate', headerName: 'createdDate', cellClass: 'non-number', width: 150},

     
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            rowClassRules: {

                'MarkBackgroundGreen': function(params) {
                     // if(params.data.status === 'New') {
                        return params.data.state === 'Accepted';
                    // } alertFlag  limitFlag  1 表示出警
                },
               
            },

            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                // tagComponent: TagComponent
              },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                        // {
                        //   field: 'questionnaireName',
                        //   headerName: "Questionnaire Name",
                        //   cellClass: 'non-number',
                        //   width: 200,
                        // },
                        {
                            field: 'questionNumber',
                            headerName: "Question Number",
                            cellClass: 'non-number',
                            width: 140,
                        },
                        {
                            field: 'subQuestionNumber',
                            headerName: "SubQuestion Number",
                            cellClass: 'non-number',
                            width: 160,
                        },
                        {
                            field: 'questionEnglish',
                            headerName: "Question English",
                            cellClass: 'non-number',
                            width: 300,
                        },
                        {
                            field: 'questionChinese',
                            headerName: "Question Chinese",
                            cellClass: 'non-number',
                            width: 300,
                        },
                        {
                            field: 'questionTypeLabel',
                            headerName: "Question Type",
                            cellClass: 'non-number',
                            width: 120,
                        },
                        // {
                        //   field: 'answeree',
                        //   headerName: "Answeree",
                        //   cellClass: 'non-number',
                        //   width: 150,
                        // },
                        {
                            field: 'answerContent',
                            headerName: "Answer Content",
                            cellClass: 'non-number',
                            width: 250,
                        },
                        // {
                        //     field: 'score',
                        //     headerName: "Score",
                        //     cellClass: 'non-number',
                        //     editable: function (params) {
                        //     return !(params.data.questionType === '1' || params.data.questionType === '2');
                        //     },
                        //     width: 120,
                        // },
                        {
                            field: 'correctAnswer',
                            headerName: "Correct Answer",
                            cellClass: 'non-number',
                            width: 250,
                        },
                        {
                            field: 'answerDateLabel',
                            headerName: "Answer Date",
                            cellClass: 'non-number',
                            width: 150,
                        },
                        // {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectPresonalInfoTask(cptRcdDataArray);
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.questionAnswerList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">Personal Info Form</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        }
    }


    dataStatusColorCellStyle(param) {
        const status = param.value;
        if(status === 'Working' || status === 'Finished') {
            return {color: 'green'};
        }
        return {color: 'black'};
    }


    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
        if(data.status === 'Finished') {
            return retDiv;
        }
        const finishTaskBtn = document.createElement('button');
        const finishTaskText = document.createTextNode("FinishTask");
        finishTaskBtn.appendChild(finishTaskText);
        finishTaskBtn.onclick = () => {
            props.openFinishTaskModal(data);
        }
        retDiv.appendChild(finishTaskBtn);
        return retDiv;
    }


    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let presonalInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectPresonalInfo(presonalInfoArray);
    }
    onDataSelectionTaskChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let presonalInfoSubArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectPresonalInfoTask(presonalInfoSubArray);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'personal-info-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };
    render() {
        const {
            loadPresonalInfoInfoActionStatus,
            loadPresonalInfoInfoResp,
        } = this.props;

        const isLoading = loadPresonalInfoInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadPresonalInfoInfoArray = [];
        if(!!loadPresonalInfoInfoResp && !!loadPresonalInfoInfoResp.data) {
            loadPresonalInfoInfoArray = loadPresonalInfoInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadPresonalInfoInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onSelectionChanged={this.onDataSelectionChanged}
                            resizable={true}
                            sideBar={true}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                        />
                    )}
            </div>
        );
    }

}

export default PresonalInfoGrid;
