// Load Personal Trade
export const START_LOAD_PERSONAL_TRADE = 'START_LOAD_PERSONAL_TRADE';
export const LOAD_PERSONAL_TRADE_SUCCESS = 'LOAD_PERSONAL_TRADE_SUCCESS';
export const LOAD_PERSONAL_TRADE_FAILURE = 'LOAD_PERSONAL_TRADE_FAILURE';
export const INIT_LOAD_PERSONAL_TRADE_ACTION_STATUS = 'INIT_LOAD_PERSONAL_TRADE_ACTION_STATUS';
export const QUERY_PERSONAL_TRADE_INPUT_CHANGE = 'QUERY_PERSONAL_TRADE_INPUT_CHANGE';

// Select Personal Trade Request
export const SELECT_PERSONAL_TRADE_REQUEST = 'SELECT_PERSONAL_TRADE_REQUEST';

// Open/Reset Personal Trade Feedback Modal Dialog
export const PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_OPEN = 'PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_OPEN';
export const PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_RESET = 'PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_RESET';

// Personal Trade Feedback Edit Change Input
export const PERSONAL_TRADE_FEEDBACK_EDIT_CHANGE_INPUT = 'PERSONAL_TRADE_FEEDBACK_EDIT_CHANGE_INPUT';

// Approve Personal Trade
export const START_APPROVE_PERSONAL_TRADE = 'START_APPROVE_PERSONAL_TRADE';
export const APPROVE_PERSONAL_TRADE_SUCCESS = 'APPROVE_PERSONAL_TRADE_SUCCESS';
export const APPROVE_PERSONAL_TRADE_FAILURE = 'APPROVE_PERSONAL_TRADE_FAILURE';
export const INIT_APPROVE_PERSONAL_TRADE_ACTION_STATUS = 'INIT_APPROVE_PERSONAL_TRADE_ACTION_STATUS';

// Final Approve Personal Trade
export const START_FINAL_APPROVE_PERSONAL_TRADE = 'START_FINAL_APPROVE_PERSONAL_TRADE';
export const FINAL_APPROVE_PERSONAL_TRADE_SUCCESS = 'FINAL_APPROVE_PERSONAL_TRADE_SUCCESS';
export const FINAL_APPROVE_PERSONAL_TRADE_FAILURE = 'FINAL_APPROVE_PERSONAL_TRADE_FAILURE';
export const INIT_FINAL_APPROVE_PERSONAL_TRADE_ACTION_STATUS = 'INIT_FINAL_APPROVE_PERSONAL_TRADE_ACTION_STATUS';

// Reject Personal Trade
export const START_REJECT_PERSONAL_TRADE = 'START_REJECT_PERSONAL_TRADE';
export const REJECT_PERSONAL_TRADE_SUCCESS = 'REJECT_PERSONAL_TRADE_SUCCESS';
export const REJECT_PERSONAL_TRADE_FAILURE = 'REJECT_PERSONAL_TRADE_FAILURE';
export const INIT_REJECT_PERSONAL_TRADE_ACTION_STATUS = 'INIT_REJECT_PERSONAL_TRADE_ACTION_STATUS';

// Feedback Personal Trade
export const START_FEEDBACK_PERSONAL_TRADE = 'START_FEEDBACK_PERSONAL_TRADE';
export const FEEDBACK_PERSONAL_TRADE_SUCCESS = 'FEEDBACK_PERSONAL_TRADE_SUCCESS';
export const FEEDBACK_PERSONAL_TRADE_FAILURE = 'FEEDBACK_PERSONAL_TRADE_FAILURE';
export const INIT_FEEDBACK_PERSONAL_TRADE_ACTION_STATUS = 'INIT_FEEDBACK_PERSONAL_TRADE_ACTION_STATUS';

// Open/Reset Personal Trade Operation Record Modal Dialog
export const PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_OPEN = 'PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_OPEN';
export const PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_RESET = 'PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_RESET';

// Load Personal Trade Operation Record
export const START_LOAD_PERSONAL_TRADE_OPERATION_RECORD = 'START_LOAD_PERSONAL_TRADE_OPERATION_RECORD';
export const LOAD_PERSONAL_TRADE_OPERATION_RECORD_SUCCESS = 'LOAD_PERSONAL_TRADE_OPERATION_RECORD_SUCCESS';
export const LOAD_PERSONAL_TRADE_OPERATION_RECORD_FAILURE = 'LOAD_PERSONAL_TRADE_OPERATION_RECORD_FAILURE';
export const INIT_LOAD_PERSONAL_TRADE_OPERATION_RECORD_ACTION_STATUS = 'INIT_LOAD_PERSONAL_TRADE_OPERATION_RECORD_ACTION_STATUS';


// Open/Reset Personal Trade Request Edit Modal Dialog
export const PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_OPEN = 'PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_OPEN';
export const PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_RESET = 'PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_RESET';

// Personal Trade Request Edit Change Input
export const PERSONAL_TRADE_REQUEST_EDIT_CHANGE_INPUT = 'PERSONAL_TRADE_REQUEST_EDIT_CHANGE_INPUT';

// Add Personal Trade Request
export const START_ADD_PERSONAL_TRADE_REQUEST = 'START_ADD_PERSONAL_TRADE_REQUEST';
export const ADD_PERSONAL_TRADE_REQUEST_SUCCESS = 'ADD_PERSONAL_TRADE_REQUEST_SUCCESS';
export const ADD_PERSONAL_TRADE_REQUEST_FAILURE = 'ADD_PERSONAL_TRADE_REQUEST_FAILURE';
export const INIT_ADD_PERSONAL_TRADE_REQUEST_ACTION_STATUS = 'INIT_ADD_PERSONAL_TRADE_REQUEST_ACTION_STATUS';

// Edit Personal Trade Request
export const START_EDIT_PERSONAL_TRADE_REQUEST = 'START_EDIT_PERSONAL_TRADE_REQUEST';
export const EDIT_PERSONAL_TRADE_REQUEST_SUCCESS = 'EDIT_PERSONAL_TRADE_REQUEST_SUCCESS';
export const EDIT_PERSONAL_TRADE_REQUEST_FAILURE = 'EDIT_PERSONAL_TRADE_REQUEST_FAILURE';
export const INIT_EDIT_PERSONAL_TRADE_REQUEST_ACTION_STATUS = 'INIT_EDIT_PERSONAL_TRADE_REQUEST_ACTION_STATUS';

// Batch Delete Personal Trade Request
export const START_BATCH_DELETE_PERSONAL_TRADE_REQUEST = 'START_BATCH_DELETE_PERSONAL_TRADE_REQUEST';
export const BATCH_DELETE_PERSONAL_TRADE_REQUEST_SUCCESS = 'BATCH_DELETE_PERSONAL_TRADE_REQUEST_SUCCESS';
export const BATCH_DELETE_PERSONAL_TRADE_REQUEST_FAILURE = 'BATCH_DELETE_PERSONAL_TRADE_REQUEST_FAILURE';
export const INIT_BATCH_DELETE_PERSONAL_TRADE_REQUEST_ACTION_STATUS = 'INIT_BATCH_DELETE_PERSONAL_TRADE_REQUEST_ACTION_STATUS';

// Copy Personal Trade Request
export const START_COPY_PERSONAL_TRADE_REQUEST = 'START_COPY_PERSONAL_TRADE_REQUEST';
export const COPY_PERSONAL_TRADE_REQUEST_SUCCESS = 'COPY_PERSONAL_TRADE_REQUEST_SUCCESS';
export const COPY_PERSONAL_TRADE_REQUEST_FAILURE = 'COPY_PERSONAL_TRADE_REQUEST_FAILURE';
export const INIT_COPY_PERSONAL_TRADE_REQUEST_ACTION_STATUS = 'INIT_COPY_PERSONAL_TRADE_REQUEST_ACTION_STATUS';

// Load BBG Tickers
export const START_LOAD_BBG_TICKERS = 'START_LOAD_BBG_TICKERS';
export const LOAD_BBG_TICKERS_SUCCESS = 'LOAD_BBG_TICKERS_SUCCESS';
export const LOAD_BBG_TICKERS_FAILURE = 'LOAD_BBG_TICKERS_FAILURE';
export const INIT_LOAD_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_BBG_TICKERS_ACTION_STATUS';

// Clear bbgTickerArray bbgTickerOptions
export const CLEAR_BBG_TICKERS = 'CLEAR_BBG_TICKERS';

// BBG Ticker Select Changed
export const BBG_TICKER_SELECT_CHANGED = 'BBG_TICKER_SELECT_CHANGED';

// Load Personal Trade Field History
export const START_LOAD_TRADE_FIELD_HISTORY = 'START_LOAD_TRADE_FIELD_HISTORY';
export const LOAD_TRADE_FIELD_HISTORY_SUCCESS = 'LOAD_TRADE_FIELD_HISTORY_SUCCESS';
export const LOAD_TRADE_FIELD_HISTORY_FAILURE = 'LOAD_TRADE_FIELD_HISTORY_FAILURE';
export const INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS = 'INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS';

// Load ExchangeCode
export const START_LOAD_EXCHANGE_CODE = 'START_LOAD_EXCHANGE_CODE';
export const LOAD_EXCHANGE_CODE_SUCCESS = 'LOAD_EXCHANGE_CODE_SUCCESS';
export const LOAD_EXCHANGE_CODE_FAILURE = 'LOAD_EXCHANGE_CODE_FAILURE';
export const INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS = 'INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS';

// Clear exchageCodeArray exchageCodeOptions
export const CLEAR_EXCHANGE_CODES = 'CLEAR_EXCHANGE_CODES';

// Exchange Code Select Changed
export const EXCHANGE_CODE_SELECT_CHANGED = 'EXCHANGE_CODE_SELECT_CHANGED';


// Open/Reset Personal Trade Request Recognize Modal Dialog
export const PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_OPEN = 'PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_OPEN';
export const PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_RESET = 'PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_RESET';

// Recognize Personal Trade Request
export const START_RECOGNIZE_PERSONAL_TRADE_REQUEST = 'START_RECOGNIZE_PERSONAL_TRADE_REQUEST';
export const RECOGNIZE_PERSONAL_TRADE_REQUEST_SUCCESS = 'RECOGNIZE_PERSONAL_TRADE_REQUEST_SUCCESS';
export const RECOGNIZE_PERSONAL_TRADE_REQUEST_FAILURE = 'RECOGNIZE_PERSONAL_TRADE_REQUEST_FAILURE';
export const INIT_RECOGNIZE_PERSONAL_TRADE_REQUEST_ACTION_STATUS = 'INIT_RECOGNIZE_PERSONAL_TRADE_REQUEST_ACTION_STATUS';

// Open/Reset Personal Trade Request Operate Modal Dialog
export const PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_OPEN = 'PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_OPEN';
export const PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_RESET = 'PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_RESET';

// Open/Reset Monthly Report Modal Dialog
export const MONTHLY_REPORT_MODAL_DIALOG_OPEN = 'MONTHLY_REPORT_MODAL_DIALOG_OPEN';
export const MONTHLY_REPORT_MODAL_DIALOG_RESET = 'MONTHLY_REPORT_MODAL_DIALOG_RESET';

// Load Monthly Report
export const START_LOAD_MONTHLY_REPORT = 'START_LOAD_MONTHLY_REPORT';
export const LOAD_MONTHLY_REPORT_SUCCESS = 'LOAD_MONTHLY_REPORT_SUCCESS';
export const LOAD_MONTHLY_REPORT_FAILURE = 'LOAD_MONTHLY_REPORT_FAILURE';
export const INIT_LOAD_MONTHLY_REPORT_ACTION_STATUS = 'INIT_LOAD_MONTHLY_REPORT_ACTION_STATUS';

// Send PA Dealing Apply Monthly Report Email
export const START_SEND_PA_DEALING_APPLY_REPORT_EMAIL = 'START_SEND_PA_DEALING_APPLY_REPORT_EMAIL';
export const SEND_PA_DEALING_APPLY_REPORT_EMAIL_SUCCESS = 'SEND_PA_DEALING_APPLY_REPORT_EMAIL_SUCCESS';
export const SEND_PA_DEALING_APPLY_REPORT_EMAIL_FAILURE = 'SEND_PA_DEALING_APPLY_REPORT_EMAIL_FAILURE';
export const INIT_SEND_PA_DEALING_APPLY_REPORT_EMAIL_ACTION_STATUS = 'INIT_SEND_PA_DEALING_APPLY_REPORT_EMAIL_ACTION_STATUS';


// 30天持仓相关预检查
export const START_QUERY_30_DAY_HOLDING_CHECK_INFO = 'START_QUERY_30_DAY_HOLDING_CHECK_INFO';
export const QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS = 'QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS';
export const QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE = 'QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE';
export const RESET_30_DAY_HOLDING_CHECK_INFO = 'RESET_30_DAY_HOLDING_CHECK_INFO';
export const START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS = 'START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS';
export const STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS = 'STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS';