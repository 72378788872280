import React, {Component} from 'react';

import { Button, message, Breadcrumb } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import ShortStockGrid from './ShortStockGrid';
import '../../DocumentReport.css';

class ShortStockDashboard extends Component {

  componentDidMount() {
    this.props.loadShortStock();
  }

  componentDidUpdate() {
    const {
      syncShortStockActionStatus,
      loadShortStockActionStatus,
      syncShortStockErrMsg,
      loadShortStockErrMsg,
    } = this.props;

    // Sync Short Stock 成功
    if (syncShortStockActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('HKEX short stock list are synchronized successfully!');
      this.props.initSyncShortStockActionStatus();
      this.props.loadShortStock();
    }

    if (syncShortStockActionStatus === ACTION_STATUS.ERROR) {
      message.error(syncShortStockErrMsg);
      this.props.initSyncShortStockActionStatus();
    }

    // Load Short Stock 成功
    if (loadShortStockActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadShortStockActionStatus();
    }

    if (loadShortStockActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadShortStockErrMsg);
      this.props.initLoadShortStockActionStatus();
    }

  }

  syncBtnOnClicked = () => {
    this.props.syncShortStock();
  }

  refreshBtnOnClicked = () => {
    this.props.loadShortStock();
  }
  
  render() {
    const {
      syncShortStockActionStatus,
      loadShortStockActionStatus,
    } = this.props;

    const syncBtnIsLoading = syncShortStockActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadShortStockActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="shortPositionReportWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Document & Report</Breadcrumb.Item>
            <Breadcrumb.Item>HKEX Short Stock</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <Button 
            onClick={this.syncBtnOnClicked} 
            size='small'
            loading={syncBtnIsLoading}
            type='primary'>
            Sync
          </Button>

          <Button 
            onClick={this.refreshBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='shortPositionReportGrid'>
          <ShortStockGrid {...this.props} />
        </div>

      </div>
    );
  }
}

export default ShortStockDashboard;