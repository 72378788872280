// Load Restricted Countries
export const START_LOAD_RESTRICTED_COUNTRIES = 'START_LOAD_RESTRICTED_COUNTRIES';
export const LOAD_RESTRICTED_COUNTRIES_SUCCESS = 'LOAD_RESTRICTED_COUNTRIES_SUCCESS';
export const LOAD_RESTRICTED_COUNTRIES_FAILURE = 'LOAD_RESTRICTED_COUNTRIES_FAILURE';
export const INIT_LOAD_RESTRICTED_COUNTRIES_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_COUNTRIES_ACTION_STATUS';

// Create Restricted Country
export const START_CREATE_RESTRICTED_COUNTRY = 'START_CREATE_RESTRICTED_COUNTRY';
export const CREATE_RESTRICTED_COUNTRY_SUCCESS = 'CREATE_RESTRICTED_COUNTRY_SUCCESS';
export const CREATE_RESTRICTED_COUNTRY_FAILURE = 'CREATE_RESTRICTED_COUNTRY_FAILURE';
export const INIT_CREATE_RESTRICTED_COUNTRY_ACTION_STATUS = 'INIT_CREATE_RESTRICTED_COUNTRY_ACTION_STATUS';

// Update Restricted Country
export const START_UPDATE_RESTRICTED_COUNTRY = 'START_UPDATE_RESTRICTED_COUNTRY';
export const UPDATE_RESTRICTED_COUNTRY_SUCCESS = 'UPDATE_RESTRICTED_COUNTRY_SUCCESS';
export const UPDATE_RESTRICTED_COUNTRY_FAILURE = 'UPDATE_RESTRICTED_COUNTRY_FAILURE';
export const INIT_UPDATE_RESTRICTED_COUNTRY_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_COUNTRY_ACTION_STATUS';

// Batch Delete Restricted Country
export const START_BATCH_DELETE_RESTRICTED_COUNTRY = 'START_BATCH_DELETE_RESTRICTED_COUNTRY';
export const BATCH_DELETE_RESTRICTED_COUNTRY_SUCCESS = 'BATCH_DELETE_RESTRICTED_COUNTRY_SUCCESS';
export const BATCH_DELETE_RESTRICTED_COUNTRY_FAILURE = 'BATCH_DELETE_RESTRICTED_COUNTRY_FAILURE';
export const INIT_BATCH_DELETE_RESTRICTED_COUNTRY_ACTION_STATUS = 'INIT_BATCH_DELETE_RESTRICTED_COUNTRY_ACTION_STATUS';

// Open/Reset Restricted Country Modal Dialog
export const RESTRICTED_COUNTRY_MODAL_DIALOG_OPEN = 'RESTRICTED_COUNTRY_MODAL_DIALOG_OPEN';
export const RESTRICTED_COUNTRY_MODAL_DIALOG_RESET = 'RESTRICTED_COUNTRY_MODAL_DIALOG_RESET';

// Restricted Country Edit Change Input
export const RESTRICTED_COUNTRY_EDIT_CHANGE_INPUT = 'RESTRICTED_COUNTRY_EDIT_CHANGE_INPUT';

// RadioGroup Change
export const RADIO_GROUP_CHANGE = 'RADIO_GROUP_CHANGE';

// Country Code Select Changed
export const COUNTRY_CODE_SELECT_CHANGED = 'COUNTRY_CODE_SELECT_CHANGED';

// Select Restricted Country
export const SELECT_RESTRICTED_COUNTRY = 'SELECT_RESTRICTED_COUNTRY';

// Fund Code Select Changed
export const FUND_CODE_SELECT_CHANGED = 'FUND_CODE_SELECT_CHANGED';

// Book Code Select Changed
export const BOOK_CODE_SELECT_CHANGED = 'BOOK_CODE_SELECT_CHANGED';
