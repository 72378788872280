import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_PRESONAL_INFO_INFO,
    LOAD_PRESONAL_INFO_INFO_SUCCESS,
    LOAD_PRESONAL_INFO_INFO_FAILURE,
    INIT_LOAD_PRESONAL_INFO_INFO_ACTION_STATUS,
    // Select
    SELECT_PRESONAL_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_PRESONAL_INFO_MODAL,
    ADD_OR_EDIT_PRESONAL_INFO_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_PRESONAL_INFO_MODAL,
    START_SUBMIT_SAVE_PRESONAL_INFO,
    SUBMIT_SAVE_PRESONAL_INFO_SUCCESS,
    SUBMIT_SAVE_PRESONAL_INFO_FAILURE,
    INIT_SUBMIT_SAVE_PRESONAL_INFO_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_PRESONAL_INFOSUB_MODAL,
    ADD_OR_EDIT_PRESONAL_INFOSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_PRESONAL_INFOSUB_MODAL,
    START_SUBMIT_SAVE_PRESONAL_INFOSUB,
    SUBMIT_SAVE_PRESONAL_INFOSUB_SUCCESS,
    SUBMIT_SAVE_PRESONAL_INFOSUB_FAILURE,
    INIT_SUBMIT_SAVE_PRESONAL_INFOSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO,
    BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_ACTION_STATUS,
    // finish
    SELECT_PRESONAL_INFO_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_PRESONAL_INFO_TASK,
    FINISH_PRESONAL_INFO_TASK_SUCCESS,
    FINISH_PRESONAL_INFO_TASK_FAILURE,
    INIT_FINISH_PRESONAL_INFO_TASK_ACTION_STATUS,

      // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,


  // Accept Questionnaire Result
  START_ACCEPT_QUESTIONNAIRE_RESULT,
  ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
  ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

  // Reject Questionnaire Result
  START_REJECT_QUESTIONNAIRE_RESULT,
  REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
  REJECT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

    // Send Remind Email
    START_SEND_REMIND_EMAIL,
    SEND_REMIND_EMAIL_SUCCESS,
    SEND_REMIND_EMAIL_FAILURE,
    INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

} from './PresonalInfoConstants';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../utils/QuestionnaireEmailSendMode';
import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  } from '../../../features/home/homeConstants';

  import {
    LOAD_LICENSINGFORM_SUCCESS,
} from '../licensingForm/licensingFormConstants';
const Option = Select.Option;

const departmentOptions = [];
departmentOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
departmentOptions.push(<Option key={'Trading'}>{'Trading'}</Option>);
departmentOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
departmentOptions.push(<Option key={'Operations'}>{'Operations'}</Option>);
departmentOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
departmentOptions.push(<Option key={'BD'}>{'BD'}</Option>);
departmentOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
departmentOptions.push(<Option key={'IT'}>{'IT'}</Option>);
departmentOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
departmentOptions.push(<Option key={'Admin'}>{'Admin'}</Option>);
departmentOptions.push(<Option key={'C-level'}>{'C-level'}</Option>);
// categoryOptions.push(<Option key={'C-level'}>{'C-level'}</Option>);

const officeOptions = [];
officeOptions.push(<Option key={'SH'}>{'SH'}</Option>);
officeOptions.push(<Option key={'HK'}>{'HK'}</Option>);
officeOptions.push(<Option key={'SG'}>{'SG'}</Option>);
officeOptions.push(<Option key={'IN'}>{'IN'}</Option>);
officeOptions.push(<Option key={'JP'}>{'JP'}</Option>);
officeOptions.push(<Option key={'HN'}>{'HN'}</Option>);
// fundCoOptions.push(<Option key={'PIAS (India)'}>{'PIAS (India)'}</Option>);
// fundCoOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
// fundCoOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
// fundCoOptions.push(<Option key={'COP'}>{'COP'}</Option>);
// fundCoOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
// fundCoOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
// fundCoOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
// fundCoOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
// fundCoOptions.push(<Option key={'SHLH'}>{'SHLH'}</Option>);
// fundCoOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
// fundCoOptions.push(<Option key={'COP'}>{'COP'}</Option>);
// fundCoOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
// fundCoOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const statusOptions = [];
statusOptions.push(<Option key={'Accepted'}>{'Accepted'}</Option>);
statusOptions.push(<Option key={'Outstanding'}>{'Outstanding'}</Option>);
statusOptions.push(<Option key={'Terminated'}>{'Terminated'}</Option>);
statusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);


const importanceOptions = [];
importanceOptions.push(<Option key={'Low'}>{'Low'}</Option>);
importanceOptions.push(<Option key={'Normal'}>{'Normal'}</Option>);
importanceOptions.push(<Option key={'High'}>{'High'}</Option>);




const locationOptions = [];
locationOptions.push(<Option key={'All'}>{'All'}</Option>);
locationOptions.push(<Option key={'Cayman'}>{'Cayman'}</Option>);
locationOptions.push(<Option key={'Hong Kong'}>{'Hong Kong'}</Option>);
locationOptions.push(<Option key={'India'}>{'India'}</Option>);
locationOptions.push(<Option key={'Japan'}>{'Japan'}</Option>);
locationOptions.push(<Option key={'Shanghai'}>{'Shanghai'}</Option>);
locationOptions.push(<Option key={'Singapore'}>{'Singapore'}</Option>);
locationOptions.push(<Option key={'USA'}>{'USA'}</Option>);

const fundCompanyOptions = [];
fundCompanyOptions.push(<Option key={'All'}>{'All'}</Option>);
fundCompanyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundCompanyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundCompanyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundCompanyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundCompanyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
fundCompanyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
fundCompanyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
fundCompanyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundCompanyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundCompanyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundCompanyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundCompanyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundCompanyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundCompanyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);

const frequencyOptions = [];
frequencyOptions.push(<Option key={'EveryWorkday'}>{'EveryWorkday'}</Option>);
frequencyOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);
frequencyOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);
frequencyOptions.push(<Option key={'Quarterly'}>{'Quarterly'}</Option>);
frequencyOptions.push(<Option key={'SemiAnnually'}>{'SemiAnnually'}</Option>);
frequencyOptions.push(<Option key={'Annually'}>{'Annually'}</Option>);
frequencyOptions.push(<Option key={'Every3Years'}>{'Every3Years'}</Option>);
frequencyOptions.push(<Option key={'Incidental'}>{'Incidental'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'Sun'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'Mon'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'Tue'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'Wed'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'Thu'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'Fri'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'Sat'}>{'Sat'}</Option>);

const monthOptions = [];
monthOptions.push(<Option key={'01'}>{'Jan'}</Option>);
monthOptions.push(<Option key={'02'}>{'Feb'}</Option>);
monthOptions.push(<Option key={'03'}>{'Mar'}</Option>);
monthOptions.push(<Option key={'04'}>{'Apr'}</Option>);
monthOptions.push(<Option key={'05'}>{'May'}</Option>);
monthOptions.push(<Option key={'06'}>{'Jun'}</Option>);
monthOptions.push(<Option key={'07'}>{'Jul'}</Option>);
monthOptions.push(<Option key={'08'}>{'Aug'}</Option>);
monthOptions.push(<Option key={'09'}>{'Sep'}</Option>);
monthOptions.push(<Option key={'10'}>{'Oct'}</Option>);
monthOptions.push(<Option key={'11'}>{'Nov'}</Option>);
monthOptions.push(<Option key={'12'}>{'Dec'}</Option>);

const responsibleDeptOptions = [];
responsibleDeptOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
responsibleDeptOptions.push(<Option key={'BD'}>{'BD'}</Option>);
responsibleDeptOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
responsibleDeptOptions.push(<Option key={'CS'}>{'CS'}</Option>);
responsibleDeptOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
responsibleDeptOptions.push(<Option key={'IT'}>{'IT'}</Option>);
responsibleDeptOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
responsibleDeptOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
responsibleDeptOptions.push(<Option key={'Settlement'}>{'Settlement'}</Option>);


const initialState = {
    locationOptions: locationOptions,
    fundCompanyOptions: fundCompanyOptions,
    frequencyOptions: frequencyOptions,
    weekdayOptions: weekdayOptions,
    monthOptions: monthOptions,
    responsibleDeptOptions: responsibleDeptOptions,
    // categoryOptions: categoryOptions,
    departmentOptions: departmentOptions,
    officeOptions: officeOptions,
    // fundCoOptions: fundCoOptions,
    statusOptions: statusOptions,
    importanceOptions: importanceOptions,

    presonalInfoQueryFields: {
        id: undefined,
        name: undefined,
        status: undefined,
    
    },

    selectedPresonalInfoArray: [],
    selectedPresonalInfoTaskArray: [],

    addOrEditPresonalInfoModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            name: undefined,
            department: undefined,
            office: undefined,
            status: undefined,
            lastConfirmDate: undefined,
            terminationDate: undefined,
            sendmail: undefined,
            remarks: undefined,
            comments: undefined,

        },
    },
    addOrEditPresonalInfoSubModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            workid: undefined,
            details: undefined,
            startdate: undefined,
            targetdate: undefined,
            enddate: undefined,
            personlncharge: undefined,
            status: undefined,
            sendmail: undefined,
            // status: undefined,
            // importance: undefined,
            // remarks: undefined,
            // comments: undefined,

        },
    },
    batchChangeStatusModal: {
        isOpened: false,
        mode: 'PAUSE',
        respErrMsg: '',
        fields: {
            idList: [],
            comments: undefined,
        },
    },

    finishTaskModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: undefined,
            remarks: undefined,
            comments: undefined,
        },
    },

    userAccountEmailOptions: [],
    // Open/Reset Questionnaire Email Edit Modal Dialog
    questionnaireEmailEditControlModal: {
        isOpened: false,
        sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
        emailPayload: null,
        respErrMsg: '',
    },
    // Accept Questionnaire Result
    acceptQuestionnaireResultActionStatus: ACTION_STATUS.READY,

    // Reject Questionnaire Result
    rejectQuestionnaireResultActionStatus: ACTION_STATUS.READY,
    licensingFormOptions: [],

    };



// query
function onReportSummaryQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.presonalInfoQueryFields,
        [name]: value,
    };
    return {
        ...state,
        presonalInfoQueryFields: upatedFields,
    }
}
function startLoadPresonalInfoInfo(state) {
    return {
        ...state,
        loadPresonalInfoInfoActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadPresonalInfoInfoSuccess(state, resp) {
    return {
        ...state,
        loadPresonalInfoInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadPresonalInfoInfoResp: resp,
    };
}
function loadPresonalInfoInfoFailure(state, err) {
    return {
        ...state,
        loadPresonalInfoInfoActionStatus: ACTION_STATUS.ERROR,
        loadPresonalInfoInfoResp: err,
    };
}
function initLoadPresonalInfoInfoActionStatus(state) {
    return {
        ...state,
        loadPresonalInfoInfoActionStatus: ACTION_STATUS.READY,
        selectedPresonalInfoArray: [],
        selectedPresonalInfoTaskArray: [],
    };
}


// select
function selectPresonalInfo(state, array) {
    return {
        ...state,
        selectedPresonalInfoArray: array,
    };
}


// add or edit
function openAddOrEditPresonalInfoModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditPresonalInfoModal: {
                ...state.addOrEditPresonalInfoModal,
                isOpened: true,
                mode,
            },
        };

    } else {

        const {
            id,
            name,
            department,
            office,
            status,
            lastConfirmDate,
            terminationDate,
            remarks,
            comments,
            sendmail,
        } = data;



        return {
            ...state,
            addOrEditPresonalInfoModal: {
                ...state.addOrEditPresonalInfoModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditPresonalInfoModal.fields,
                
                    id: data.id,
                    formGuid:  data.formGuid,
                    name: data.name,
                    department: data.department,
                    office: data.office,
                    status: data.status,
                    lastConfirmDate: data.lastConfirmDate,
                    terminationDate: data.terminationDate,
                    remarks: data.remarks,
                    comments: data.comments,
                    sendmail: data.sendmail,
                
                }
            },
        }

    }
}
function addOrEditPresonalInfoChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditPresonalInfoModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditPresonalInfoModal: {
          ...state.addOrEditPresonalInfoModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSavePresonalInfoModal(state) {
    return {
        ...state,
        addOrEditPresonalInfoModal: initialState.addOrEditPresonalInfoModal,
    }
}

function startSubmitSavePresonalInfo(state) {
    return {
        ...state,
        addOrEditPresonalInfoStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSavePresonalInfoSuccess(state, resp) {
    return {
        ...state,
        addOrEditPresonalInfoStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSavePresonalInfoFailure(state, err) {
    return {
        ...state,
        addOrEditPresonalInfoStatus: ACTION_STATUS.ERROR,
        addOrEditPresonalInfoModal: {
            ...state.addOrEditPresonalInfoModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSavePresonalInfoActionStatus(state) {
    return {
        ...state,
        addOrEditPresonalInfoStatus: ACTION_STATUS.READY,
    };
}



//sub、 add or edit
function openAddOrEditPresonalInfoSubModal(state, {mode, data,idList}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditPresonalInfoSubModal: {
                ...state.addOrEditPresonalInfoSubModal,
                isOpened: true,
                mode,
                idList,
            },
        };

    } else {

        const {
            id,
            workid,
            details,
            startdate,
            targetdate,
            enddate,
            personlncharge,
            status,
        } = data;



        return {
            ...state,
            addOrEditPresonalInfoSubModal: {
                ...state.addOrEditPresonalInfoSubModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditPresonalInfoSubModal.fields,
                    id,
                    workid,
                    details,
                    startdate,
                    targetdate,
                    enddate,
                    personlncharge,
                    status,
                
                }
            },
        }

    }
}
function addOrEditPresonalInfoSubChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditPresonalInfoSubModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditPresonalInfoSubModal: {
          ...state.addOrEditPresonalInfoSubModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSavePresonalInfoSubModal(state) {
    return {
        ...state,
        addOrEditPresonalInfoSubModal: initialState.addOrEditPresonalInfoSubModal,
    }
}

function startSubmitSavePresonalInfoSub(state) {
    return {
        ...state,
        addOrEditPresonalInfoSubStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSavePresonalInfoSubSuccess(state, resp) {
    return {
        ...state,
        addOrEditPresonalInfoSubStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSavePresonalInfoSubFailure(state, err) {
    return {
        ...state,
        addOrEditPresonalInfoSubStatus: ACTION_STATUS.ERROR,
        addOrEditPresonalInfoSubModal: {
            ...state.addOrEditPresonalInfoSubModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSavePresonalInfoSubActionStatus(state) {
    return {
        ...state,
        addOrEditPresonalInfoSubStatus: ACTION_STATUS.READY,
    };
}





















// batch change status
function openBatchChangeStatusModal(state, {mode, idList}) {
    return {
        ...state,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.batchChangeStatusModal.fields,
                idList,
            },
        },
    };
}
function resetBatchChangeStatusModal(state) {
    return {
        ...state,
        batchChangeStatusModal: initialState.batchChangeStatusModal,
    }
}
function batchChangeStatusChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.batchChangeStatusModal.fields,
        [name]: value,
    };
    return {
        ...state,
        batchChangeStatusModal: {
          ...state.batchChangeStatusModal,
          fields: updatedFields,
        },
    };
}

function startBatchChangeStatusForPresonalInfo(state) {
    return {
        ...state,
        batchChangeStatusForPresonalInfoStatus: ACTION_STATUS.LOGINING,
    };
}
function batchChangeStatusForPresonalInfoSuccess(state, resp) {
    return {
        ...state,
        batchChangeStatusForPresonalInfoStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function batchChangeStatusForPresonalInfoFailure(state, err) {
    return {
        ...state,
        batchChangeStatusForPresonalInfoStatus: ACTION_STATUS.ERROR,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initBatchChangeStatusForPresonalInfoActionStatus(state) {
    return {
        ...state,
        batchChangeStatusForPresonalInfoStatus: ACTION_STATUS.READY,
    };
}


// finish
function selectPresonalInfoTask(state, array) {
    return {
        ...state,
        selectedPresonalInfoTaskArray: array,
    };
}
function openFinishTaskModal(state, task) {
    return {
        ...state,
        finishTaskModal: {
            ...state.finishTaskModal,
            isOpened: true,
            fields: {
                ...state.finishTaskModal.fields,
                id: task.id,
                remarks: task.remarks,
            },
        },
    };
}
function resetFinishTaskModal(state) {
    return {
        ...state,
        finishTaskModal: initialState.finishTaskModal,
    }; 
}
function finishTaskChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.finishTaskModal.fields,
        [name]: value,
    };
    return {
        ...state,
        finishTaskModal: {
          ...state.finishTaskModal,
          fields: updatedFields,
        },
    };
}

function startFinishPresonalInfoTask(state) {
    return {
        ...state,
        finishPresonalInfoTaskStatus: ACTION_STATUS.LOGINING,
    };
}
function finishPresonalInfoTaskSuccess(state, resp) {
    return {
        ...state,
        finishPresonalInfoTaskStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function finishPresonalInfoTaskFailure(state, err) {
    return {
        ...state,
        finishPresonalInfoTaskStatus: ACTION_STATUS.ERROR,
        finishTaskModal: {
            ...state.finishTaskModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initFinishPresonalInfoTaskActionStatus(state) {
    return {
        ...state,
        finishPresonalInfoTaskStatus: ACTION_STATUS.READY,
    };
}


// user account
function loadUserAccountsSuccess(state, resp) {
      const userAccountArray = resp.data;
      const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
    
      return {
        ...state,
        userAccountArray,
        userAccountOptions,
      };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
function openQuestionnaireEmailEditModalDialog(state, {sendMode, emailPayload}) {
    return {
      ...state,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        isOpened: true,
        sendMode,
        emailPayload,
      },
    };
  }
  
  function resetQuestionnaireEmailEditModalDialog(state) {
    return {
      ...state,
      questionnaireEmailEditControlModal: initialState.questionnaireEmailEditControlModal,
    };
  }

// Accept Questionnaire Result
function startAcceptQuestionnaireResult(state) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function acceptQuestionnaireResultSuccess(state, resp) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function acceptQuestionnaireResultFailure(state, err) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.ERROR,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initAcceptQuestionnaireResultActionStatus(state) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.READY,
    };
  }
  
  // Reject Questionnaire Result
  function startRejectQuestionnaireResult(state) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function rejectQuestionnaireResultSuccess(state, resp) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function rejectQuestionnaireResultFailure(state, err) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.ERROR,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initRejectQuestionnaireResultActionStatus(state) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.READY,
    };
  }

  function loadLicensingFormSuccess(state, resp) {
    const licensingFormArray = resp.data;
    const licensingFormOptions = licensingFormArray.map(licensingForm => <Option key={licensingForm.guid}>{licensingForm.name}</Option>);
  
    return {
      ...state,
    //   loadLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
    //   licensingFormArray,
      licensingFormOptions,
    };
  }

// Send Remind Email
function startSendRemindEmail(state) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function sendRemindEmailSuccess(state, resp) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function sendRemindEmailFailure(state, err) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.ERROR,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initSendRemindEmailActionStatus(state) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.READY,
    };
  }


export default createReducer(initialState, {
    // query
    [ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE]: onReportSummaryQueryInputChange,
    [START_LOAD_PRESONAL_INFO_INFO]: startLoadPresonalInfoInfo,
    [LOAD_PRESONAL_INFO_INFO_SUCCESS]: loadPresonalInfoInfoSuccess,
    [LOAD_PRESONAL_INFO_INFO_FAILURE]: loadPresonalInfoInfoFailure,
    [INIT_LOAD_PRESONAL_INFO_INFO_ACTION_STATUS]: initLoadPresonalInfoInfoActionStatus,
    // select
    [SELECT_PRESONAL_INFO]: selectPresonalInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_PRESONAL_INFO_MODAL]: openAddOrEditPresonalInfoModal,
    [ADD_OR_EDIT_PRESONAL_INFO_CHANGE_INPUT]: addOrEditPresonalInfoChangeInput,
    [RESET_SUBMIT_SAVE_PRESONAL_INFO_MODAL]: resetSubmitSavePresonalInfoModal,
    [START_SUBMIT_SAVE_PRESONAL_INFO]: startSubmitSavePresonalInfo,
    [SUBMIT_SAVE_PRESONAL_INFO_SUCCESS]: submitSavePresonalInfoSuccess,
    [SUBMIT_SAVE_PRESONAL_INFO_FAILURE]: submitSavePresonalInfoFailure,
    [INIT_SUBMIT_SAVE_PRESONAL_INFO_ACTION_STATUS]: initSubmitSavePresonalInfoActionStatus,


    //SUB add or edit
    [OPEN_ADD_OR_EDIT_PRESONAL_INFOSUB_MODAL]: openAddOrEditPresonalInfoSubModal,
    [ADD_OR_EDIT_PRESONAL_INFOSUB_CHANGE_INPUT]: addOrEditPresonalInfoSubChangeInput,
    [RESET_SUBMIT_SAVE_PRESONAL_INFOSUB_MODAL]: resetSubmitSavePresonalInfoSubModal,
    [START_SUBMIT_SAVE_PRESONAL_INFOSUB]: startSubmitSavePresonalInfoSub,
    [SUBMIT_SAVE_PRESONAL_INFOSUB_SUCCESS]: submitSavePresonalInfoSubSuccess,
    [SUBMIT_SAVE_PRESONAL_INFOSUB_FAILURE]: submitSavePresonalInfoSubFailure,
    [INIT_SUBMIT_SAVE_PRESONAL_INFOSUB_ACTION_STATUS]: initSubmitSavePresonalInfoSubActionStatus,





    // batch change status
    [OPEN_BATCH_CHANGE_STATUS_MODAL]: openBatchChangeStatusModal,
    [RESET_BATCH_CHANGE_STATUS_MODAL]: resetBatchChangeStatusModal,
    [BATCH_CHANGE_STATUS_CHANGE_INPUT]: batchChangeStatusChangeInput,
    [START_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO]: startBatchChangeStatusForPresonalInfo,
    [BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_SUCCESS]: batchChangeStatusForPresonalInfoSuccess,
    [BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_FAILURE]: batchChangeStatusForPresonalInfoFailure,
    [INIT_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_ACTION_STATUS]: initBatchChangeStatusForPresonalInfoActionStatus,
    // finish
    [SELECT_PRESONAL_INFO_TASK]: selectPresonalInfoTask,
    [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,
    [RESET_FINISH_TASK_MODAL]: resetFinishTaskModal,
    [FINISH_TASK_CHANGE_INPUT]: finishTaskChangeInput,
    [START_FINISH_PRESONAL_INFO_TASK]: startFinishPresonalInfoTask,
    [FINISH_PRESONAL_INFO_TASK_SUCCESS]: finishPresonalInfoTaskSuccess,
    [FINISH_PRESONAL_INFO_TASK_FAILURE]: finishPresonalInfoTaskFailure,
    [INIT_FINISH_PRESONAL_INFO_TASK_ACTION_STATUS]: initFinishPresonalInfoTaskActionStatus,
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

        // Load User Account
        [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

      // Open/Reset Questionnaire Email Edit Modal Dialog
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN]: openQuestionnaireEmailEditModalDialog,
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET]: resetQuestionnaireEmailEditModalDialog,
    [LOAD_LICENSINGFORM_SUCCESS]: loadLicensingFormSuccess,


    // Accept Questionnaire Result
  [START_ACCEPT_QUESTIONNAIRE_RESULT]: startAcceptQuestionnaireResult,
  [ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS]: acceptQuestionnaireResultSuccess,
  [ACCEPT_QUESTIONNAIRE_RESULT_FAILURE]: acceptQuestionnaireResultFailure,
  [INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS]: initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  [START_REJECT_QUESTIONNAIRE_RESULT]: startRejectQuestionnaireResult,
  [REJECT_QUESTIONNAIRE_RESULT_SUCCESS]: rejectQuestionnaireResultSuccess,
  [REJECT_QUESTIONNAIRE_RESULT_FAILURE]: rejectQuestionnaireResultFailure,
  [INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS]: initRejectQuestionnaireResultActionStatus,
  // Send Remind Email
  [START_SEND_REMIND_EMAIL]: startSendRemindEmail,
  [SEND_REMIND_EMAIL_SUCCESS]: sendRemindEmailSuccess,
  [SEND_REMIND_EMAIL_FAILURE]: sendRemindEmailFailure,
  [INIT_SEND_REMIND_EMAIL_ACTION_STATUS]: initSendRemindEmailActionStatus,
});

