import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


import {
    // query
    ON_PORTFOLIO_MONITORING_RULE_QUERY_INPUT_CHANGE,
    START_LOAD_PORTFOLIO_MONITORING_RULE_INFO,
    LOAD_PORTFOLIO_MONITORING_RULE_INFO_SUCCESS,
    LOAD_PORTFOLIO_MONITORING_RULE_INFO_FAILURE,
    INIT_LOAD_PORTFOLIO_MONITORING_RULE_INFO_ACTION_STATUS,
    // Select
    SELECT_PORTFOLIO_MONITORING_RULE,
    // add or edit
    OPEN_ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_MODAL,
    ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_MODAL,
    START_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE,
    SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_SUCCESS,
    SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_FAILURE,
    INIT_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL,
    ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL,
    START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE,
    INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE,
    BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_ACTION_STATUS,
    // finish
    SELECT_PORTFOLIO_MONITORING_RULE_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_PORTFOLIO_MONITORING_RULE_TASK,
    FINISH_PORTFOLIO_MONITORING_RULE_TASK_SUCCESS,
    FINISH_PORTFOLIO_MONITORING_RULE_TASK_FAILURE,
    INIT_FINISH_PORTFOLIO_MONITORING_RULE_TASK_ACTION_STATUS,

    
} from './PortfolioMonitoringRuleConstants';

import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,

    LOAD_CURRENCY_CODES_SUCCESS,
    LOAD_COUNTRY_CODES_SUCCESS,
  } from '../../home/homeConstants';


const Option = Select.Option;

const categoryOptions = [];
categoryOptions.push(<Option key={'PPM'}>{'PPM'}</Option>);
categoryOptions.push(<Option key={'Side Letter'}>{'Side Letter'}</Option>);
categoryOptions.push(<Option key={'Regulator'}>{'Regulator'}</Option>);
categoryOptions.push(<Option key={'Internal Ctrl'}>{'Internal Ctrl'}</Option>);



const statusOptions = [];
// statusOptions.push(<Option key={'Active'}>{'Active'}</Option>);
// statusOptions.push(<Option key={'Inactive'}>{'Inactive'}</Option>);
// statusOptions.push(<Option key={'Rejected'}>{'Rejected'}</Option>);
statusOptions.push(<Option key={'New'}>{'New'}</Option>);
// statusOptions.push(<Option key={'Deactivate'}>{'Deactivate'}</Option>); 禁用申请


const ruleTypeOptions = [];
ruleTypeOptions.push(<Option key={'Single'}>{'Single'}</Option>);
ruleTypeOptions.push(<Option key={'and'}>{'and'}</Option>);
ruleTypeOptions.push(<Option key={'or'}>{'or'}</Option>);


const imIaOptions = [];
imIaOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
imIaOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
imIaOptions.push(<Option key={'上海保银'}>{'上海保银'}</Option>);
imIaOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
imIaOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);

const fundOptions = [];
fundOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);
fundOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
fundOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundOptions.push(<Option key={'COP'}>{'COP'}</Option>);
fundOptions.push(<Option key={'VCC'}>{'VCC'}</Option>);
fundOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundOptions.push(<Option key={'SHLH'}>{'SHLH'}</Option>);
fundOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);

// 市场获取
const marketOptions = [];
// 币种
const inCurrencyOptions = [];

// marketOptions.push(<Option key={'Pinpoint Group'}>{'Pinpoint Group'}</Option>);
// marketOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
// marketOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
// marketOptions.push(<Option key={'上海保银'}>{'上海保银'}</Option>);
// marketOptions.push(<Option key={'海南保银'}>{'海南保银'}</Option>);



const assetTypeOptions = [];
assetTypeOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);
assetTypeOptions.push(<Option key={'EQTY'}>{'EQTY'}</Option>);
assetTypeOptions.push(<Option key={'SWAP'}>{'SWAP'}</Option>);
assetTypeOptions.push(<Option key={'OPTION'}>{'OPTION'}</Option>);
assetTypeOptions.push(<Option key={'FUTURE'}>{'FUTURE'}</Option>);
assetTypeOptions.push(<Option key={'FIXED_INCOME'}>{'FIXED_INCOME'}</Option>);
assetTypeOptions.push(<Option key={'CCY'}>{'CCY'}</Option>);
assetTypeOptions.push(<Option key={'BASKINDX'}>{'BASKINDX'}</Option>);
assetTypeOptions.push(<Option key={'EQINDX_FT'}>{'EQINDX_FT'}</Option>);

const directionOptions = [];
directionOptions.push(<Option key={'LONG'}>{'LONG'}</Option>);
directionOptions.push(<Option key={'SHORT'}>{'SHORT'}</Option>);
directionOptions.push(<Option key={'Net'}>{'Net'}</Option>);
directionOptions.push(<Option key={'Gross'}>{'Gross'}</Option>);


const unitValueOptions = [];
unitValueOptions.push(<Option key={'Notional Mkt'}>{'Notional Mkt'}</Option>);
unitValueOptions.push(<Option key={'Shares'}>{'Shares'}</Option>);
unitValueOptions.push(<Option key={'Margin'}>{'Margin'}</Option>);


const sumOfOptions = [];
sumOfOptions.push(<Option key={'Selected asset types'}>{'Selected asset types'}</Option>);
sumOfOptions.push(<Option key={'Same underlying'}>{'Same underlying'}</Option>);



//分子
const numeratorOptions = [];
numeratorOptions.push(<Option key={'Mkt Shares'}>{'Mkt Shares'}</Option>);
numeratorOptions.push(<Option key={'NAV'}>{'NAV'}</Option>);
numeratorOptions.push(<Option key={'Mkt value'}>{'Mkt value'}</Option>);
//分母
const denominatorOptions = [];
denominatorOptions.push(<Option key={'Fund'}>{'Fund'}</Option>);
denominatorOptions.push(<Option key={'Same underlying'}>{'Same underlying'}</Option>);
denominatorOptions.push(<Option key={'IM/IA (tbc,  optional)'}>{'IM/IA (tbc,  optional)'}</Option>);





const initialState = {
    categoryOptions: categoryOptions,
    statusOptions: statusOptions,
    ruleTypeOptions: ruleTypeOptions,
    imIaOptions: imIaOptions,
    fundOptions: fundOptions,
    assetTypeOptions: assetTypeOptions,
    directionOptions: directionOptions,
    unitValueOptions: unitValueOptions,
    sumOfOptions: sumOfOptions,
    numeratorOptions: numeratorOptions,
    denominatorOptions: denominatorOptions,


    portfolioMonitoringRuleQueryFields: {
        id: undefined,
        location: undefined,
        fundCompany: undefined,
    },

    selectedPortfolioMonitoringRuleArray: [],
    selectedPortfolioMonitoringRuleTaskArray: [],

    addOrEditPortfolioMonitoringRuleModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            category: undefined,
            ruleName: undefined,
            tag: undefined,
            ruleStatus: undefined,
            ruleType: undefined,
            imIa: undefined,
            fund: undefined,
            market: undefined,
            assetType: undefined,
            direction: undefined,
            unitValue: undefined,
            sumOf: undefined,
            latestValue: undefined,
            alertValue: undefined,
            alertInCurrency: undefined,
            alertPercent: undefined,
            alertNumerator: undefined,
            alertDenominator: undefined,
            limitValue: undefined,
            limitInCurrency: undefined,
            limitPercent: undefined,
            limitNumerator: undefined,
            limitDenominator: undefined,
            descriptionReference: undefined,
            effectiveDate: undefined,
            remarks: undefined,
            comments: undefined,

        },
    },

    batchChangeStatusModal: {
        isOpened: false,
        mode: 'PAUSE',
        respErrMsg: '',
        fields: {
            idList: [],
            comments: undefined,
        },
    },

    finishTaskModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: undefined,
            remarks: undefined,
            comments: undefined,
        },
    },

    userAccountEmailOptions: [],

};



// query
function onPortfolioMonitoringRuleQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.portfolioMonitoringRuleQueryFields,
        [name]: value,
    };
    return {
        ...state,
        portfolioMonitoringRuleQueryFields: upatedFields,
    }
}
function startLoadPortfolioMonitoringRuleInfo(state) {
    return {
        ...state,
        loadPortfolioMonitoringRuleInfoActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadPortfolioMonitoringRuleInfoSuccess(state, resp) {
    return {
        ...state,
        loadPortfolioMonitoringRuleInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadPortfolioMonitoringRuleInfoResp: resp,
    };
}
function loadPortfolioMonitoringRuleInfoFailure(state, err) {
    return {
        ...state,
        loadPortfolioMonitoringRuleInfoActionStatus: ACTION_STATUS.ERROR,
        loadPortfolioMonitoringRuleInfoResp: err,
    };
}
function initLoadPortfolioMonitoringRuleInfoActionStatus(state) {
    return {
        ...state,
        loadPortfolioMonitoringRuleInfoActionStatus: ACTION_STATUS.READY,
        selectedPortfolioMonitoringRuleArray: [],
        selectedPortfolioMonitoringRuleTaskArray: [],
    };
}


// select
function selectPortfolioMonitoringRule(state, array) {
    return {
        ...state,
        selectedPortfolioMonitoringRuleArray: array,
    };
}


// add or edit
function openAddOrEditPortfolioMonitoringRuleModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditPortfolioMonitoringRuleModal: {
                ...state.addOrEditPortfolioMonitoringRuleModal,
                isOpened: true,
                mode,
            },
        };

    } else {

        const {
            id,
            category,
            ruleName,
            tag,
            ruleStatus,
            ruleType,
            imIa,
            fund,
            market,
            assetType,
            direction,
            unitValue,
            sumOf,
            latestValue,
            alertValue,
            alertInCurrency,
            alertPercent,
            alertNumerator,
            alertDenominator,
            limitValue,
            limitInCurrency,
            limitPercent,
            limitNumerator,
            limitDenominator,
            descriptionReference,
            effectiveDate,
            remarks,
        } = data;



        return {
            ...state,
            addOrEditPortfolioMonitoringRuleModal: {
                ...state.addOrEditPortfolioMonitoringRuleModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditPortfolioMonitoringRuleModal.fields,
                    id,
                    category,
                    ruleName,
                    tag,
                    ruleStatus,
                    ruleType,
                    imIa,
                    fund,
                    market,
                    assetType,
                    direction,
                    unitValue,
                    sumOf,
                    latestValue,
                    alertValue,
                    alertInCurrency,
                    alertPercent,
                    alertNumerator,
                    alertDenominator,
                    limitValue,
                    limitInCurrency,
                    limitPercent,
                    limitNumerator,
                    limitDenominator,
                    descriptionReference,
                    effectiveDate,
                    remarks,
                
                }
            },
        }

    }
}
function addOrEditPortfolioMonitoringRuleChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditPortfolioMonitoringRuleModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditPortfolioMonitoringRuleModal: {
          ...state.addOrEditPortfolioMonitoringRuleModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSavePortfolioMonitoringRuleModal(state) {
    return {
        ...state,
        addOrEditPortfolioMonitoringRuleModal: initialState.addOrEditPortfolioMonitoringRuleModal,
    }
}

function startSubmitSavePortfolioMonitoringRule(state) {
    return {
        ...state,
        addOrEditPortfolioMonitoringRuleStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSavePortfolioMonitoringRuleSuccess(state, resp) {
    return {
        ...state,
        addOrEditPortfolioMonitoringRuleStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSavePortfolioMonitoringRuleFailure(state, err) {
    return {
        ...state,
        addOrEditPortfolioMonitoringRuleStatus: ACTION_STATUS.ERROR,
        addOrEditPortfolioMonitoringRuleModal: {
            ...state.addOrEditPortfolioMonitoringRuleModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSavePortfolioMonitoringRuleActionStatus(state) {
    return {
        ...state,
        addOrEditPortfolioMonitoringRuleStatus: ACTION_STATUS.READY,
    };
}




// batch change status
function openBatchChangeStatusModal(state, {mode, idList}) {
    return {
        ...state,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.batchChangeStatusModal.fields,
                idList,
            },
        },
    };
}
function resetBatchChangeStatusModal(state) {
    return {
        ...state,
        batchChangeStatusModal: initialState.batchChangeStatusModal,
    }
}
function batchChangeStatusChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.batchChangeStatusModal.fields,
        [name]: value,
    };
    return {
        ...state,
        batchChangeStatusModal: {
          ...state.batchChangeStatusModal,
          fields: updatedFields,
        },
    };
}

function startBatchChangeStatusForPortfolioMonitoringRule(state) {
    return {
        ...state,
        batchChangeStatusForPortfolioMonitoringRuleStatus: ACTION_STATUS.LOGINING,
    };
}
function batchChangeStatusForPortfolioMonitoringRuleSuccess(state, resp) {
    return {
        ...state,
        batchChangeStatusForPortfolioMonitoringRuleStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function batchChangeStatusForPortfolioMonitoringRuleFailure(state, err) {
    return {
        ...state,
        batchChangeStatusForPortfolioMonitoringRuleStatus: ACTION_STATUS.ERROR,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initBatchChangeStatusForPortfolioMonitoringRuleActionStatus(state) {
    return {
        ...state,
        batchChangeStatusForPortfolioMonitoringRuleStatus: ACTION_STATUS.READY,
    };
}


// finish
function selectPortfolioMonitoringRuleTask(state, array) {
    return {
        ...state,
        selectedPortfolioMonitoringRuleTaskArray: array,
    };
}
function openFinishTaskModal(state, task) {
    return {
        ...state,
        finishTaskModal: {
            ...state.finishTaskModal,
            isOpened: true,
            fields: {
                ...state.finishTaskModal.fields,
                id: task.id,
                remarks: task.remarks,
            },
        },
    };
}
function resetFinishTaskModal(state) {
    return {
        ...state,
        finishTaskModal: initialState.finishTaskModal,
    }; 
}
function finishTaskChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.finishTaskModal.fields,
        [name]: value,
    };
    return {
        ...state,
        finishTaskModal: {
          ...state.finishTaskModal,
          fields: updatedFields,
        },
    };
}

function startFinishPortfolioMonitoringRuleTask(state) {
    return {
        ...state,
        finishPortfolioMonitoringRuleTaskStatus: ACTION_STATUS.LOGINING,
    };
}
function finishPortfolioMonitoringRuleTaskSuccess(state, resp) {
    return {
        ...state,
        finishPortfolioMonitoringRuleTaskStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function finishPortfolioMonitoringRuleTaskFailure(state, err) {
    return {
        ...state,
        finishPortfolioMonitoringRuleTaskStatus: ACTION_STATUS.ERROR,
        finishTaskModal: {
            ...state.finishTaskModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initFinishPortfolioMonitoringRuleTaskActionStatus(state) {
    return {
        ...state,
        finishPortfolioMonitoringRuleTaskStatus: ACTION_STATUS.READY,
    };
}


// user account
function loadUserAccountsSuccess(state, resp) {
      const userAccountArray = resp.data;
      const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
    
      return {
        ...state,
        userAccountArray,
        userAccountOptions,
      };
}


// // 币种
// const inCurrencyOptions = [];
// currency code 
function loadCurrencyCodesSuccess(state, resp) {
    let inCurrencyArray = resp.data;
    let inCurrencyCodeArray = inCurrencyArray.map(countryCodeDO => countryCodeDO.currencyCode);
    let inCurrencyOptions = inCurrencyCodeArray.map(currencyCode => <Option key={currencyCode}>{currencyCode}</Option>);
  
    return {
      ...state,
      inCurrencyArray,
      inCurrencyCodeArray,
      inCurrencyOptions,
    };
}


// 市场获取  loadCountryCodesSuccess
// const marketOptions = [];
function loadCountryCodesSuccess(state, resp) {
    let marketCodeDOArray = resp.data;
    let marketCodeArray = marketCodeDOArray.map(countryCodeDO => countryCodeDO.countryCode);

    const marketOptions = [];
    marketOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);
    marketOptions.push(marketCodeArray.map(countryCode => <Option key={countryCode}>{countryCode}</Option>));
 
    return {
      ...state,
      marketCodeDOArray,
      marketCodeArray,
      marketOptions,
    };
  }


export default createReducer(initialState, {
    // query
    [ON_PORTFOLIO_MONITORING_RULE_QUERY_INPUT_CHANGE]: onPortfolioMonitoringRuleQueryInputChange,
    [START_LOAD_PORTFOLIO_MONITORING_RULE_INFO]: startLoadPortfolioMonitoringRuleInfo,
    [LOAD_PORTFOLIO_MONITORING_RULE_INFO_SUCCESS]: loadPortfolioMonitoringRuleInfoSuccess,
    [LOAD_PORTFOLIO_MONITORING_RULE_INFO_FAILURE]: loadPortfolioMonitoringRuleInfoFailure,
    [INIT_LOAD_PORTFOLIO_MONITORING_RULE_INFO_ACTION_STATUS]: initLoadPortfolioMonitoringRuleInfoActionStatus,
    // select
    [SELECT_PORTFOLIO_MONITORING_RULE]: selectPortfolioMonitoringRule,
    // add or edit
    [OPEN_ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_MODAL]: openAddOrEditPortfolioMonitoringRuleModal,
    [ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_CHANGE_INPUT]: addOrEditPortfolioMonitoringRuleChangeInput,
    [RESET_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_MODAL]: resetSubmitSavePortfolioMonitoringRuleModal,
    [START_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE]: startSubmitSavePortfolioMonitoringRule,
    [SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_SUCCESS]: submitSavePortfolioMonitoringRuleSuccess,
    [SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_FAILURE]: submitSavePortfolioMonitoringRuleFailure,
    [INIT_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_ACTION_STATUS]: initSubmitSavePortfolioMonitoringRuleActionStatus,







    // batch change status
    [OPEN_BATCH_CHANGE_STATUS_MODAL]: openBatchChangeStatusModal,
    [RESET_BATCH_CHANGE_STATUS_MODAL]: resetBatchChangeStatusModal,
    [BATCH_CHANGE_STATUS_CHANGE_INPUT]: batchChangeStatusChangeInput,
    [START_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE]: startBatchChangeStatusForPortfolioMonitoringRule,
    [BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_SUCCESS]: batchChangeStatusForPortfolioMonitoringRuleSuccess,
    [BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_FAILURE]: batchChangeStatusForPortfolioMonitoringRuleFailure,
    [INIT_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_ACTION_STATUS]: initBatchChangeStatusForPortfolioMonitoringRuleActionStatus,
    // finish
    [SELECT_PORTFOLIO_MONITORING_RULE_TASK]: selectPortfolioMonitoringRuleTask,
    [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,
    [RESET_FINISH_TASK_MODAL]: resetFinishTaskModal,
    [FINISH_TASK_CHANGE_INPUT]: finishTaskChangeInput,
    [START_FINISH_PORTFOLIO_MONITORING_RULE_TASK]: startFinishPortfolioMonitoringRuleTask,
    [FINISH_PORTFOLIO_MONITORING_RULE_TASK_SUCCESS]: finishPortfolioMonitoringRuleTaskSuccess,
    [FINISH_PORTFOLIO_MONITORING_RULE_TASK_FAILURE]: finishPortfolioMonitoringRuleTaskFailure,
    [INIT_FINISH_PORTFOLIO_MONITORING_RULE_TASK_ACTION_STATUS]: initFinishPortfolioMonitoringRuleTaskActionStatus,
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
    // currency code 
    [LOAD_CURRENCY_CODES_SUCCESS]: loadCurrencyCodesSuccess,
   
      // Load Country Codes
    [LOAD_COUNTRY_CODES_SUCCESS]: loadCountryCodesSuccess,

});

