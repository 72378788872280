import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import GridColumnMap from '../GridColumnMap';

import {
  // Generate 13F Long Report
  START_GENERATE_THIRTEENF_LONG_POS_REPORT,
  GENERATE_THIRTEENF_LONG_POS_REPORT_SUCCESS,
  GENERATE_THIRTEENF_LONG_POS_REPORT_FAILURE,
  INIT_GENERATE_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS,

  // Load 13F Long Report
  START_LOAD_THIRTEENF_LONG_POS_REPORT,
  LOAD_THIRTEENF_LONG_POS_REPORT_SUCCESS,
  LOAD_THIRTEENF_LONG_POS_REPORT_FAILURE,
  INIT_LOAD_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS,

  // Open/Reset 13F Long Report Check Result Modal Dialog
  THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN,
  THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET,

  // Load 13F Long Report Check Result
  START_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT,
  LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_SUCCESS,
  LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_FAILURE,
  INIT_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_ACTION_STATUS,

  // Report 13F Long Report
  START_REPORT_THIRTEENF_LONG_POS_REPORT,
  REPORT_THIRTEENF_LONG_POS_REPORT_SUCCESS,
  REPORT_THIRTEENF_LONG_POS_REPORT_FAILURE,
  INIT_REPORT_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS,

  // Report 13F Long Pos Summary Report
  START_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT,
  REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_SUCCESS,
  REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_FAILURE,
  INIT_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_ACTION_STATUS,

} from './thirteenFLongPosReportConstants';

const initialState = {
  // Grdi Columns
  thirteenFLongPosReportGridColumns: GridColumnMap.thirteenFLongPosReportGridColumns,
  thirteenFLongPosReportChkResultGridColumns: GridColumnMap.thirteenFLongPosReportChkResultGridColumns,

  // 13F Long Report
  thirteenFLongPosReportArray: [],

  // Load 13F Long Report Action Status
  loadThirteenFLongPosReportActionStatus: ACTION_STATUS.READY,
  loadThirteenFLongPosReportErrMsg: '',

  // Generate 13F Long Report Action Status
  generateThirteenFLongPosReportActionStatus: ACTION_STATUS.READY,
  generateThirteenFLongPosReportErrMsg: '',

  // 13F Long Report Check Result Modal Dialog
  thirteenFLongPosReportCheckResultControlModal: {
    isOpened: false,
    dateString: '',
  },

  // 13F Long Report Check Result
  thirteenFLongPosReportChkResult: [],

  // Load 13F Long Report Check Result Action Status
  loadThirteenFLongPosReportCheckResultActionStatus: ACTION_STATUS.READY,
  loadThirteenFLongPosReportCheckResultErrMsg: '',

  // Report 13F Long Report
  reportThirteenFLongPosReportActionStatus: ACTION_STATUS.READY,
  reportFilePath: '',
  reportThirteenFLongPosReportErrMsg: '',

  // Report 13F Long Pos Summary Report
  reportThirteenFLongPosSummaryReportActionStatus: ACTION_STATUS.READY,
  summaryReportFilePath: '',
  reportThirteenFLongPosSummaryReportErrMsg: '',

} 

// Generate 13F Long Report
function startGenerateThirteenFLongPosReport(state) {
  return {
    ...state,
    generateThirteenFLongPosReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function generateThirteenFLongPosReportSuccess(state, resp) {
  return {
    ...state,
    generateThirteenFLongPosReportActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function generateThirteenFLongPosReportFailure(state, err) {
  return {
    ...state,
    generateThirteenFLongPosReportActionStatus: ACTION_STATUS.ERROR,
    generateThirteenFLongPosReportErrMsg: err.respMessage,
  };
}

function initGenerateThirteenFLongPosReportActionStatus(state) {
  return {
    ...state,
    generateThirteenFLongPosReportActionStatus: ACTION_STATUS.READY,
  };
}

// Load 13F Long Report
function startLoadThirteenFLongPosReport(state) {
  return {
    ...state,
    loadThirteenFLongPosReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadThirteenFLongPosReportSuccess(state, resp) {
  return {
    ...state,
    loadThirteenFLongPosReportActionStatus: ACTION_STATUS.SUCCESS,
    thirteenFLongPosReportArray: resp.data,
  };
}

function loadThirteenFLongPosReportFailure(state, err) {
  return {
    ...state,
    loadThirteenFLongPosReportActionStatus: ACTION_STATUS.ERROR,
    loadThirteenFLongPosReportErrMsg: err.respMessage,
  };
}

function initLoadThirteenFLongPosReportActionStatus(state) {
  return {
    ...state,
    loadThirteenFLongPosReportActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset 13F Long Report Check Result Modal Dialog
function openThirteenFLongPosReportCheckResultModalDialog(state, { fundCode, dateString }) {
  return {
    ...state,
    thirteenFLongPosReportCheckResultControlModal: {
      ...state.thirteenFLongPosReportCheckResultControlModal,
      isOpened: true,
      fundCode,
      dateString,
    },
  };
}

function resetThirteenFLongPosReportCheckResultModalDialog(state) {
  return {
    ...state,
    thirteenFLongPosReportCheckResultControlModal: initialState.thirteenFLongPosReportCheckResultControlModal,
  };
}

// Load 13F Long Report Check Result
function startLoadThirteenFLongPosReportCheckResult(state) {
  return {
    ...state,
    loadThirteenFLongPosReportCheckResultActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadThirteenFLongPosReportCheckResultSuccess(state, resp) {
  return {
    ...state,
    loadThirteenFLongPosReportCheckResultActionStatus: ACTION_STATUS.SUCCESS,
    thirteenFLongPosReportChkResult: resp.data,
  };
}

function loadThirteenFLongPosReportCheckResultFailure(state, err) {
  return {
    ...state,
    loadThirteenFLongPosReportCheckResultActionStatus: ACTION_STATUS.ERROR,
    loadThirteenFLongPosReportCheckResultErrMsg: err.respMessage,
  };
}

function initLoadThirteenFLongPosReportCheckResultActionStatus(state) {
  return {
    ...state,
    loadThirteenFLongPosReportCheckResultActionStatus: ACTION_STATUS.READY,
  };
}

// Report 13F Long Report
function startReportThirteenFLongPosReport(state) {
  return {
    ...state,
    reportThirteenFLongPosReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function reportThirteenFLongPosReportSuccess(state, resp) {
  const fileName = resp.data;
  const reportFilePath = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_13F_LONG_POS_REPORT + fileName;
  
  return {
    ...state,
    reportThirteenFLongPosReportActionStatus: ACTION_STATUS.SUCCESS,
    reportFilePath,
  };
}

function reportThirteenFLongPosReportFailure(state, err) {
  return {
    ...state,
    reportThirteenFLongPosReportActionStatus: ACTION_STATUS.ERROR,
    reportThirteenFLongPosReportErrMsg: err.respMessage,
  };
}

function initReportThirteenFLongPosReportActionStatus(state) {
  return {
    ...state,
    reportThirteenFLongPosReportActionStatus: ACTION_STATUS.READY,
  };
}

// Report 13F Long Pos Summary Report
function startReportThirteenFLongPosSummaryReport(state) {
  return {
    ...state,
    reportThirteenFLongPosSummaryReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function reportThirteenFLongPosSummaryReportSuccess(state, resp) {
  const fileName = resp.data;
  const summaryReportFilePath = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_13F_LONG_POS_REPORT + fileName;
  
  return {
    ...state,
    reportThirteenFLongPosSummaryReportActionStatus: ACTION_STATUS.SUCCESS,
    summaryReportFilePath,
  };
}

function reportThirteenFLongPosSummaryReportFailure(state, err) {
  return {
    ...state,
    reportThirteenFLongPosSummaryReportActionStatus: ACTION_STATUS.ERROR,
    reportThirteenFLongPosSummaryReportErrMsg: err.respMessage,
  };
}

function initReportThirteenFLongPosSummaryReportActionStatus(state) {
  return {
    ...state,
    reportThirteenFLongPosSummaryReportActionStatus: ACTION_STATUS.READY,
  };
}


export default createReducer(initialState, {
  // Generate 13F Long Report
  [START_GENERATE_THIRTEENF_LONG_POS_REPORT]: startGenerateThirteenFLongPosReport,
  [GENERATE_THIRTEENF_LONG_POS_REPORT_SUCCESS]: generateThirteenFLongPosReportSuccess,
  [GENERATE_THIRTEENF_LONG_POS_REPORT_FAILURE]: generateThirteenFLongPosReportFailure,
  [INIT_GENERATE_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS]: initGenerateThirteenFLongPosReportActionStatus,

  // Load 13F Long Report
  [START_LOAD_THIRTEENF_LONG_POS_REPORT]: startLoadThirteenFLongPosReport,
  [LOAD_THIRTEENF_LONG_POS_REPORT_SUCCESS]: loadThirteenFLongPosReportSuccess,
  [LOAD_THIRTEENF_LONG_POS_REPORT_FAILURE]: loadThirteenFLongPosReportFailure,
  [INIT_LOAD_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS]: initLoadThirteenFLongPosReportActionStatus,

  // Open/Reset 13F Long Report Check Result Modal Dialog
  [THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN]: openThirteenFLongPosReportCheckResultModalDialog,
  [THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET]: resetThirteenFLongPosReportCheckResultModalDialog,

  // Load 13F Long Report Check Result
  [START_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT]: startLoadThirteenFLongPosReportCheckResult,
  [LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_SUCCESS]: loadThirteenFLongPosReportCheckResultSuccess,
  [LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_FAILURE]: loadThirteenFLongPosReportCheckResultFailure,
  [INIT_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_ACTION_STATUS]: initLoadThirteenFLongPosReportCheckResultActionStatus,

  // Report 13F Long Report
  [START_REPORT_THIRTEENF_LONG_POS_REPORT]: startReportThirteenFLongPosReport,
  [REPORT_THIRTEENF_LONG_POS_REPORT_SUCCESS]: reportThirteenFLongPosReportSuccess,
  [REPORT_THIRTEENF_LONG_POS_REPORT_FAILURE]: reportThirteenFLongPosReportFailure,
  [INIT_REPORT_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS]: initReportThirteenFLongPosReportActionStatus,

  // Report 13F Long Pos Summary Report
  [START_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT]: startReportThirteenFLongPosSummaryReport,
  [REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_SUCCESS]: reportThirteenFLongPosSummaryReportSuccess,
  [REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_FAILURE]: reportThirteenFLongPosSummaryReportFailure,
  [INIT_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_ACTION_STATUS]: initReportThirteenFLongPosSummaryReportActionStatus,

});