import {connect} from 'react-redux';
import MonthlyReportDashboard from '../components/MonthlyReportDashboard';

import {
    // query
    loadMonthlyReportList,
    loadMonthlyReportDetail,
    saveMonthlyReport,
    delMonthlyReport,
    openMonthlyReportDialog,
    closeMonthlyReportDialog,
    onQueryInputChange
} from '../MonthlyReportActions';

const mapStateToProps = state => {
    return {
        queryParams: state.monthlyReport.queryParams,
        monthlyReportList: state.monthlyReport.monthlyReportList,
        monthlyReportDetail: state.monthlyReport.monthlyReportDetail,
        monthlyReportModal: state.monthlyReport.monthlyReportModal,
        monthlyReportAttachmentUploadUrl: state.monthlyReport.monthlyReportAttachmentUploadUrl,
        monthlyReportAttachmentDownloadUrl: state.monthlyReport.monthlyReportAttachmentDownloadUrl,

    };
}

const mapDispatchToProps = dispatch => {
    return {
        loadMonthlyReportList: (params) => dispatch(loadMonthlyReportList(params)),
        loadMonthlyReportDetail: (params) => dispatch(loadMonthlyReportDetail(params)),
        saveMonthlyReport: (params,queryParams) => dispatch(saveMonthlyReport(params,queryParams)),
        openMonthlyReportDialog: (params) => dispatch(openMonthlyReportDialog(params)),
        delMonthlyReport: (params,queryParams) => dispatch(delMonthlyReport(params,queryParams)),
        closeMonthlyReportDialog: () => dispatch(closeMonthlyReportDialog()),
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MonthlyReportDashboard);
