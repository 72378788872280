const APPROVE = 'APPROVE';
const FINAL_APPROVE = 'FINAL APPROVE';
const REJECT = 'REJECT';
const DELETE = 'DELETE';

export default {
  APPROVE,
  FINAL_APPROVE,
  REJECT,
  DELETE,
};
