// Sync Exchange Rate
export const START_SYNC_EXCHANGE_RATE = 'START_SYNC_EXCHANGE_RATE';
export const SYNC_EXCHANGE_RATE_SUCCESS = 'SYNC_EXCHANGE_RATE_SUCCESS';
export const SYNC_EXCHANGE_RATE_FAILURE = 'SYNC_EXCHANGE_RATE_FAILURE';
export const INIT_SYNC_EXCHANGE_RATE_ACTION_STATUS = 'INIT_SYNC_EXCHANGE_RATE_ACTION_STATUS';

// Load Exchange Rate
export const START_LOAD_EXCHANGE_RATE = 'START_LOAD_EXCHANGE_RATE';
export const LOAD_EXCHANGE_RATE_SUCCESS = 'LOAD_EXCHANGE_RATE_SUCCESS';
export const LOAD_EXCHANGE_RATE_FAILURE = 'LOAD_EXCHANGE_RATE_FAILURE';
export const INIT_LOAD_EXCHANGE_RATE_ACTION_STATUS = 'INIT_LOAD_EXCHANGE_RATE_ACTION_STATUS';