import React, {Component, useEffect} from 'react';

import { Button, message, Modal, Breadcrumb } from 'antd';

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../../utils/localStorageClient';

import RestrictedSecurityGrid from './RestrictedSecurityGrid';
import RestrictedSecurityEditDialog from './RestrictedSecurityEditDialog';
import RestrictedSecurityOperateDialog from './RestrictedSecurityOperateDialog';
import RelatedCompPriceReportDialog from './RelatedCompPriceReportDialog';
import RestrictedSecurityOperateMode from '../../../../../utils/RestrictedSecurityOperateMode';
import RestrictedSecurityValidStatus from '../../../../../utils/RestrictedSecurityValidStatus';

import '../../ComplianceRule.css';

const confirm = Modal.confirm;

class RestrictedSecurityDashboard extends Component {

  componentDidMount() {
    this.props.loadRestrictedSecurity();
  }

  componentDidUpdate() {
    const {
      // Delete Restricted Security 
      batchDeleteRestrictedSecActionStatus,

      // On/Off List Restricted Security Action Status
      onListRestrictedSecurityActionStatus,
      offListRestrictedSecurityActionStatus,

      // Generate Related Company Price Report
      generateRelatedCompanyPriceReportActionStatus,
      generateRelatedCompanyPriceReportErrMsg,

      // Load Related Company Price Report
      loadRelatedCompanyPriceReportActionStatus,
      loadRelatedCompanyPriceReportErrMsg,
    } = this.props;

    // Restricted Security 批量删除成功
    if (batchDeleteRestrictedSecActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are deleted successfully!');
      this.props.loadRestrictedSecurity();
      this.props.initBatchDeleteRestrictedSecurityActionStatus();
      this.props.resetRestrictedSecurityOperateModalDialog();
    }

    // Restricted Security 批量删除失败
    if (batchDeleteRestrictedSecActionStatus === ACTION_STATUS.ERROR) {
      message.error('Restricted security application are deleted failed!');
      this.props.initBatchDeleteRestrictedSecurityActionStatus();
    }

    // On List Restricted Security 成功
    if (onListRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security are on list successfully!');
      this.props.loadRestrictedSecurity();
      this.props.initOnListRestrictedSecurityActionStatus();
      this.props.resetRestrictedSecurityOperateModalDialog();
    }

    // On List Restricted Security 失败
    if (onListRestrictedSecurityActionStatus === ACTION_STATUS.ERROR) {
      message.error('Restricted security are on list failed!');
      this.props.initOnListRestrictedSecurityActionStatus();
    }

    // Off List Restricted Security 成功
    if (offListRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security are off list successfully!');
      this.props.loadRestrictedSecurity();
      this.props.initOffListRestrictedSecurityActionStatus();
      this.props.resetRestrictedSecurityOperateModalDialog();
    }

    // Off List Restricted Security 失败
    if (offListRestrictedSecurityActionStatus === ACTION_STATUS.ERROR) {
      message.error('Restricted security are off list failed!');
      this.props.initOffListRestrictedSecurityActionStatus();
    }

    // Generate Related Company Price Report 成功
    if (generateRelatedCompanyPriceReportActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Related company price report are generated successfully!');
      this.props.loadRelatedCompanyPriceReport();
      this.props.openRelatedCompanyPriceModalDialog();
      this.props.initGenerateRelatedCompanyPriceReportActionStatus();
    }

    // Generate Related Company Price Report 失败
    if (generateRelatedCompanyPriceReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(generateRelatedCompanyPriceReportErrMsg);
      this.props.initGenerateRelatedCompanyPriceReportActionStatus();
    }

    // Load Related Company Price Report 成功
    if (loadRelatedCompanyPriceReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadRelatedCompanyPriceReportActionStatus();
    }

    // Load Related Company Price Report 失败
    if (loadRelatedCompanyPriceReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadRelatedCompanyPriceReportErrMsg);
      this.props.initLoadRelatedCompanyPriceReportActionStatus();
    }

  }

  addButtonOnClicked = () => {
    this.props.openRestrictedSecurityEditModalDialog(true, null);
  }

  editButtonOnClicked = () => {
    const {
      selectedRestrictedSecurityArray
    } = this.props;

    if (selectedRestrictedSecurityArray.length !== 1) {
      message.warning("Please select a restricted security application!");
      return;
    }

    this.props.openRestrictedSecurityEditModalDialog(false, selectedRestrictedSecurityArray[0]);
  }

  deleteButtonOnClicked = () => {
    const {
      selectedRestrictedSecurityArray
    } = this.props;

    if (selectedRestrictedSecurityArray.length === 0) {
      message.warning("Please select a restricted security application at least!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the restricted security?',
      content: '',
      onOk() {
        const restrictedSecGuidList = selectedRestrictedSecurityArray.map(resctrictedSecurity => resctrictedSecurity.guid);

        that.props.openRestrictedSecurityOperateModalDialog(RestrictedSecurityOperateMode.DELETE, restrictedSecGuidList);
      },
      onCancel() {},
    });
  }

  refreshButtonOnClicked = () => {
    this.props.loadRestrictedSecurity();
  }

  onListBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityArray
    } = this.props;

    if (selectedRestrictedSecurityArray.length === 0) {
      message.warning("Please select a restricted security at least!");
      return;
    }

    let filterRestrictedSecArray = selectedRestrictedSecurityArray.filter(resctrictedSecurity => 
      !!(resctrictedSecurity.validStatus) && resctrictedSecurity.validStatus === RestrictedSecurityValidStatus.Valid);

    if (filterRestrictedSecArray.length > 0) {
      message.warning("You cannot on list the valid restricted security!");
      return;
    }

    const restrictedSecGuidList = selectedRestrictedSecurityArray.map(resctrictedSecurity => resctrictedSecurity.guid);

    this.props.openRestrictedSecurityOperateModalDialog(RestrictedSecurityOperateMode.ONLIST, restrictedSecGuidList);
  }

  offListBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityArray
    } = this.props;

    if (selectedRestrictedSecurityArray.length === 0) {
      message.warning("Please select a restricted security at least!");
      return;
    }

    let filterRestrictedSecArray = selectedRestrictedSecurityArray.filter(resctrictedSecurity => 
      !!(resctrictedSecurity.validStatus) && resctrictedSecurity.validStatus === RestrictedSecurityValidStatus.InValid);

    if (filterRestrictedSecArray.length > 0) {
      message.warning("You cannot off list the invalid restricted security!");
      return;
    }

    const restrictedSecGuidList = selectedRestrictedSecurityArray.map(resctrictedSecurity => resctrictedSecurity.guid);

    this.props.openRestrictedSecurityOperateModalDialog(RestrictedSecurityOperateMode.OFFLIST, restrictedSecGuidList);
  }

  showValidBtnOnClicked = () => {
    this.props.showValidRestrictedSecurities();
  }

  showAllBtnOnClicked = () => {
    this.props.showAllRestrictedSecurities();
  }

  genReportBtnOnClicked = () => {
    this.props.generateRelatedCompanyPriceReport();
  }

  // 显示价格浮动报告
  priceReportBtnOnClicked = () => {
    this.props.loadRelatedCompanyPriceReport();
    this.props.openRelatedCompanyPriceModalDialog();
  }
  
  render() {
    const {
      loadRestrictedSecurityActionStatus,

      // Generate Related Company Price Report
      generateRelatedCompanyPriceReportActionStatus,

      // Load Related Company Price Report
      loadRelatedCompanyPriceReportActionStatus,
    } = this.props;

    const loadBtnIsLoading = loadRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING;
    const genReportBtnIsLoading = generateRelatedCompanyPriceReportActionStatus === ACTION_STATUS.LOGINING;
    const loadReportBtnIsLoading = loadRelatedCompanyPriceReportActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="restrictedSecurityWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>Restricted Security</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <Button 
            onClick={this.addButtonOnClicked} 
            style={{ marginLeft: 10 }}
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            type='primary'>
            Edit
          </Button>

          <Button 
            onClick={this.deleteButtonOnClicked} 
            size='small'
            className='negative-button'>
            Del
          </Button>

          {/* <Button 
            onClick={this.onListBtnOnClicked} 
            size='small'
            type='primary'>
            OnList
          </Button>

          <Button 
            onClick={this.offListBtnOnClicked} 
            size='small'
            type='primary'>
            OffList
          </Button> */}

          <Button 
            onClick={this.showValidBtnOnClicked} 
            size='small'
            type='primary'>
            Show Valid
          </Button>

          <Button 
            onClick={this.showAllBtnOnClicked} 
            size='small'
            type='primary'>
            Show All
          </Button>

          <Button 
            onClick={this.genReportBtnOnClicked} 
            size='small'
            loading={genReportBtnIsLoading}
            type='primary'>
            Generate Report
          </Button>

          <Button 
            onClick={this.priceReportBtnOnClicked} 
            size='small'
            loading={loadReportBtnIsLoading}
            type='primary'>
            Price Report
          </Button>

          <Button 
            onClick={this.refreshButtonOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='restrictedSecurityGrid'>
          <RestrictedSecurityGrid {...this.props} />
        </div>

        <RestrictedSecurityEditDialog {...this.props} />

        {/* 删除 Restricted Security */}
        <RestrictedSecurityOperateDialog {...this.props} />

        {/* Related Comp Price Report */}
        <RelatedCompPriceReportDialog {...this.props} />
      </div>
    );
  }
}

export default RestrictedSecurityDashboard;