import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../utils/RadioOperationMode';
import { Select } from 'antd';

import {
  // Load Restricted Asset
  START_LOAD_RESTRICTED_ASSET,
  LOAD_RESTRICTED_ASSET_SUCCESS,
  LOAD_RESTRICTED_ASSET_FAILURE,
  INIT_LOAD_RESTRICTED_ASSET_ACTION_STATUS,

  // Create Restricted Asset
  START_CREATE_RESTRICTED_ASSET,
  CREATE_RESTRICTED_ASSET_SUCCESS,
  CREATE_RESTRICTED_ASSET_FAILURE,
  INIT_CREATE_RESTRICTED_ASSET_ACTION_STATUS,

  // Update Restricted Asset
  START_UPDATE_RESTRICTED_ASSET,
  UPDATE_RESTRICTED_ASSET_SUCCESS,
  UPDATE_RESTRICTED_ASSET_FAILURE,
  INIT_UPDATE_RESTRICTED_ASSET_ACTION_STATUS,

  // Batch Delete Restricted Asset
  START_BATCH_DELETE_RESTRICTED_ASSET,
  BATCH_DELETE_RESTRICTED_ASSET_SUCCESS,
  BATCH_DELETE_RESTRICTED_ASSET_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_ASSET_ACTION_STATUS,

  // Open/Reset Restricted Asset Modal Dialog
  RESTRICTED_ASSET_MODAL_DIALOG_OPEN,
  RESTRICTED_ASSET_MODAL_DIALOG_RESET,

  // Restricted Asset Edit Change Input
  RESTRICTED_ASSET_EDIT_CHANGE_INPUT,

  // RadioGroup Change
  RADIO_GROUP_CHANGE,

  // Select Restricted Asset
  SELECT_RESTRICTED_ASSET_CLASS,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,
} from './restrictedAssetConstants';

import {
  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,

  // Load Asset Inst Class
  LOAD_ASSET_INST_CLASS_SUCCESS,
} from '../../home/homeConstants';

const Option = Select.Option;

const initialState = {
  restrictedAssetGridColumns: GridColumnMap.restrictedAssetGridColumns,

  // Restricted Asset
  restrictedAssetArray: [],
  filterRestrictedAssetArray: [],

  restrictedAssetEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      id: '',
      restrictionType: '',
      fundCode: '',
      bookCode: '',
      pmName: '',
      assetClass: '',
      instClass: '',
      explanation: '',
      kickOffDate: '',
      expiryDate: '',
      isWhite: true,
    }
  },

  // Load Restricted Asset Action Status
  loadRestrictedAssetActionStatus: ACTION_STATUS.READY,
  createRestrictedAssetActionStatus: ACTION_STATUS.READY,
  updateRestrictedAssetActionStatus: ACTION_STATUS.READY,
  batchDeleteRestrictedAssetActionStatus: ACTION_STATUS.READY,

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // Asset Inst Class Data
  assetClassArray: [],
  instClassArray: [],
  assetClassOptions: [],
  instClassOptions: [],

  // Selected Restricted Asset
  selectedRestrictedAsset: [],
}


// ========================================================================
// Load Restricted Asset
function startLoadRestrictedAsset(state) {
  return {
    ...state,
    loadRestrictedAssetActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedAssetSuccess(state, resp) {
  return {
    ...state,
    restrictedAssetArray: resp.data,
    filterRestrictedAssetArray: resp.data,
    loadRestrictedAssetActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedAssetFailure(state, err) {
  return {
    ...state,
    loadRestrictedAssetActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedAssetActionStatus(state) {
  return {
    ...state,
    loadRestrictedAssetActionStatus: ACTION_STATUS.READY,
  };
}

// Create Restricted Asset
function startCreateRestrictedAsset(state) {
  return {
    ...state,
    createRestrictedAssetActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createRestrictedAssetSuccess(state, resp) {
  return {
    ...state,
    createRestrictedAssetActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createRestrictedAssetFailure(state, err) {
  return {
    ...state,
    createRestrictedAssetActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCreateRestrictedAssetActionStatus(state) {
  return {
    ...state,
    createRestrictedAssetActionStatus: ACTION_STATUS.READY,
  };
}

// Update Restricted Asset
function startUpdateRestrictedAsset(state) {
  return {
    ...state,
    updateRestrictedAssetActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateRestrictedAssetSuccess(state, resp) {
  return {
    ...state,
    updateRestrictedAssetActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateRestrictedAssetFailure(state, err) {
  return {
    ...state,
    updateRestrictedAssetActionStatus: ACTION_STATUS.ERROR,
  };
}

function initUpdateRestrictedAssetActionStatus(state) {
  return {
    ...state,
    updateRestrictedAssetActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Restricted Asset
function startBatchDeleteRestrictedAsset(state) {
  return {
    ...state,
    batchDeleteRestrictedAssetActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteRestrictedAssetSuccess(state, resp) {
  return {
    ...state,
    batchDeleteRestrictedAssetActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteRestrictedAssetFailure(state, err) {
  return {
    ...state,
    batchDeleteRestrictedAssetActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteRestrictedAssetActionStatus(state) {
  return {
    ...state,
    batchDeleteRestrictedAssetActionStatus: ACTION_STATUS.READY,
  };
}


// ========================================================================
// RadioGroup Change
function radioGroupChange(state, value) {
  const restrictedAssetArray = state.restrictedAssetArray;
  let filterRestrictedAssetArray = [];

  if (value === RadioOperationMode.ALL) {
    filterRestrictedAssetArray = restrictedAssetArray;
  }

  if (value === RadioOperationMode.WHITE) {
    filterRestrictedAssetArray = restrictedAssetArray.filter(restrictedAsset => restrictedAsset.isWhite);
  }

  if (value === RadioOperationMode.BLACK) {
    filterRestrictedAssetArray = restrictedAssetArray.filter(restrictedAsset => !restrictedAsset.isWhite);
  }

  return {
    ...state,
    loadRestrictedAssetActionStatus: ACTION_STATUS.READY,
    filterRestrictedAssetArray,
  };
}

// Open/Reset Restricted Asset Modal Dialog
function openRestrictedAssetEditModalDialog(state, {isAddMode, restrictedAsset}) {
  if (isAddMode) {
    return {
      ...state,
      restrictedAssetEditControlModal: {
        ...state.restrictedAssetEditControlModal,
        isOpened: true,
        isAddMode,
      },
    };
  } else {
    return {
      ...state,
      restrictedAssetEditControlModal: {
        ...state.restrictedAssetEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedAssetEditControlModal.fields,
          id: restrictedAsset.id,
          restrictionType: restrictedAsset.restrictionType,
          fundCode: restrictedAsset.fund,
          bookCode: restrictedAsset.book,
          pmName: restrictedAsset.pmName,
          assetClass: restrictedAsset.assetClass,
          instClass: restrictedAsset.instClass,
          explanation: restrictedAsset.explanation,
          kickOffDate: restrictedAsset.kickOffDate,
          expiryDate: restrictedAsset.expiryDate,
          isWhite: restrictedAsset.isWhite,
        }
      },
    };
  }
}

function resetRestrictedAssetEditModalDialog(state) {
  return {
    ...state,
    restrictedAssetEditControlModal: initialState.restrictedAssetEditControlModal,
  };
}

// Restricted Asset Edit Change Input
function restrictedAssetEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedAssetEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedAssetEditControlModal: {
      ...state.restrictedAssetEditControlModal,
      fields: updatedFields,
    },
  };
}

// ========================================================================
// Select Restricted Asset
function selectRestrictedAssetClass(state, payload) {
  return {
    ...state,
    selectedRestrictedAsset: payload,
  };
}

// Fund Code Select Changed
function fundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedAssetEditControlModal.fields.bookCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedAssetEditControlModal: {
      ...state.restrictedAssetEditControlModal,
      fields: {
        ...state.restrictedAssetEditControlModal.fields,
        pmName,
      },
    },
  };
}

// Book Code Select Changed
function bookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedAssetEditControlModal.fields.fundCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedAssetEditControlModal: {
      ...state.restrictedAssetEditControlModal,
      fields: {
        ...state.restrictedAssetEditControlModal.fields,
        pmName,
      },
    },
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// Load Asset Inst Class
function loadAssetInstClassSuccess(state, resp) {
  const assetInstClass = resp.data;
  const assetClassArray = assetInstClass.assetClassList;
  const instClassArray = assetInstClass.instClassList;

  let assetClassOptions = assetClassArray.map(assetClass => <Option key={assetClass}>{assetClass}</Option>);
  let instClassOptions = instClassArray.map(instClass => <Option key={instClass}>{instClass}</Option>);

  return {
    ...state,
    assetInstClass,
    assetClassArray,
    instClassArray,
    assetClassOptions,
    instClassOptions,
  };
}

export default createReducer(initialState, {
  // Load Restricted Asset
  [START_LOAD_RESTRICTED_ASSET]: startLoadRestrictedAsset,
  [LOAD_RESTRICTED_ASSET_SUCCESS]: loadRestrictedAssetSuccess,
  [LOAD_RESTRICTED_ASSET_FAILURE]: loadRestrictedAssetFailure,
  [INIT_LOAD_RESTRICTED_ASSET_ACTION_STATUS]: initLoadRestrictedAssetActionStatus,

  // Create Restricted Asset
  [START_CREATE_RESTRICTED_ASSET]: startCreateRestrictedAsset,
  [CREATE_RESTRICTED_ASSET_SUCCESS]: createRestrictedAssetSuccess,
  [CREATE_RESTRICTED_ASSET_FAILURE]: createRestrictedAssetFailure,
  [INIT_CREATE_RESTRICTED_ASSET_ACTION_STATUS]: initCreateRestrictedAssetActionStatus,

  // Update Restricted Asset
  [START_UPDATE_RESTRICTED_ASSET]: startUpdateRestrictedAsset,
  [UPDATE_RESTRICTED_ASSET_SUCCESS]: updateRestrictedAssetSuccess,
  [UPDATE_RESTRICTED_ASSET_FAILURE]: updateRestrictedAssetFailure,
  [INIT_UPDATE_RESTRICTED_ASSET_ACTION_STATUS]: initUpdateRestrictedAssetActionStatus,

  // Batch Delete Restricted Asset
  [START_BATCH_DELETE_RESTRICTED_ASSET]: startBatchDeleteRestrictedAsset,
  [BATCH_DELETE_RESTRICTED_ASSET_SUCCESS]: batchDeleteRestrictedAssetSuccess,
  [BATCH_DELETE_RESTRICTED_ASSET_FAILURE]: batchDeleteRestrictedAssetFailure,
  [INIT_BATCH_DELETE_RESTRICTED_ASSET_ACTION_STATUS]: initBatchDeleteRestrictedAssetActionStatus,

  // RadioGroup Change
  [RADIO_GROUP_CHANGE]: radioGroupChange,

  // Open/Reset Restricted Asset Modal Dialog
  [RESTRICTED_ASSET_MODAL_DIALOG_OPEN]: openRestrictedAssetEditModalDialog,
  [RESTRICTED_ASSET_MODAL_DIALOG_RESET]: resetRestrictedAssetEditModalDialog,

  // Restricted Asset Edit Change Input
  [RESTRICTED_ASSET_EDIT_CHANGE_INPUT]: restrictedAssetEditChangeInput,

  // Select Restricted Asset
  [SELECT_RESTRICTED_ASSET_CLASS]: selectRestrictedAssetClass,

  // Fund Code Select Changed
  [FUND_CODE_SELECT_CHANGED]: fundCodeSelectChanged,

  // Book Code Select Changed
  [BOOK_CODE_SELECT_CHANGED]: bookCodeSelectChanged,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load Asset Inst Class
  [LOAD_ASSET_INST_CLASS_SUCCESS]: loadAssetInstClassSuccess,
});