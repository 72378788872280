// Load Restricted Security 
export const START_LOAD_RESTRICTED_SECURITY = 'START_LOAD_RESTRICTED_SECURITY';
export const LOAD_RESTRICTED_SECURITY_SUCCESS = 'LOAD_RESTRICTED_SECURITY_SUCCESS';
export const LOAD_RESTRICTED_SECURITY_FAILURE = 'LOAD_RESTRICTED_SECURITY_FAILURE';
export const INIT_LOAD_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECURITY_ACTION_STATUS';

// Select Restricted Security 
export const SELECT_RESTRICTED_SECURITY = 'SELECT_RESTRICTED_SECURITY';

// Open/Reset Restricted Security Edit Modal Dialog
export const RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_RESET';

// Restricted Security Edit Change Input
export const RESTRICTED_SECURITY_EDIT_CHANGE_INPUT = 'RESTRICTED_SECURITY_EDIT_CHANGE_INPUT';

// Create Restricted Security 
export const START_CREATE_RESTRICTED_SECURITY = 'START_CREATE_RESTRICTED_SECURITY';
export const CREATE_RESTRICTED_SECURITY_SUCCESS = 'CREATE_RESTRICTED_SECURITY_SUCCESS';
export const CREATE_RESTRICTED_SECURITY_FAILURE = 'CREATE_RESTRICTED_SECURITY_FAILURE';
export const INIT_CREATE_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_CREATE_RESTRICTED_SECURITY_ACTION_STATUS';

// Update Restricted Security 
export const START_UPDATE_RESTRICTED_SECURITY = 'START_UPDATE_RESTRICTED_SECURITY';
export const UPDATE_RESTRICTED_SECURITY_SUCCESS = 'UPDATE_RESTRICTED_SECURITY_SUCCESS';
export const UPDATE_RESTRICTED_SECURITY_FAILURE = 'UPDATE_RESTRICTED_SECURITY_FAILURE';
export const INIT_UPDATE_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_SECURITY_ACTION_STATUS';

// Batch Delete Restricted Security 
export const START_BATCH_DELETE_RESTRICTED_SECURITY = 'START_BATCH_DELETE_RESTRICTED_SECURITY';
export const BATCH_DELETE_RESTRICTED_SECURITY_SUCCESS = 'BATCH_DELETE_RESTRICTED_SECURITY_SUCCESS';
export const BATCH_DELETE_RESTRICTED_SECURITY_FAILURE = 'BATCH_DELETE_RESTRICTED_SECURITY_FAILURE';
export const INIT_BATCH_DELETE_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_BATCH_DELETE_RESTRICTED_SECURITY_ACTION_STATUS';

// Load Restricted Security BBG Tickers
export const START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS = 'START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS';
export const LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS = 'LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS';
export const LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE = 'LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE';
export const INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS';

// Clear Restricted Security BBG Ticker
export const CLEAR_RESTRICTED_SECURITY_BBG_TICKERS = 'CLEAR_RESTRICTED_SECURITY_BBG_TICKERS';

// Restricted Security BBG Ticker Select Changed
export const RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED = 'RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED';

// Restricted Security Fund Code Select Changed
export const RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED = 'RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED';

// Restricted Security Book Code Select Changed
export const RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED = 'RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED';

// Open/Reset Restricted Security Operate Modal Dialog
export const RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_RESET';

// On List Restricted Security 
export const START_ON_LIST_RESTRICTED_SECURITY = 'START_ON_LIST_RESTRICTED_SECURITY';
export const ON_LIST_RESTRICTED_SECURITY_SUCCESS = 'ON_LIST_RESTRICTED_SECURITY_SUCCESS';
export const ON_LIST_RESTRICTED_SECURITY_FAILURE = 'ON_LIST_RESTRICTED_SECURITY_FAILURE';
export const INIT_ON_LIST_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_ON_LIST_RESTRICTED_SECURITY_ACTION_STATUS';

// Off List Restricted Security 
export const START_OFF_LIST_RESTRICTED_SECURITY = 'START_OFF_LIST_RESTRICTED_SECURITY';
export const OFF_LIST_RESTRICTED_SECURITY_SUCCESS = 'OFF_LIST_RESTRICTED_SECURITY_SUCCESS';
export const OFF_LIST_RESTRICTED_SECURITY_FAILURE = 'OFF_LIST_RESTRICTED_SECURITY_FAILURE';
export const INIT_OFF_LIST_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_OFF_LIST_RESTRICTED_SECURITY_ACTION_STATUS';

// Show Valid Restricted Securities
export const SHOW_VALID_RESTRICTED_SECURITIES = 'SHOW_VALID_RESTRICTED_SECURITIES';

// Show All Restricted Securities
export const SHOW_ALL_RESTRICTED_SECURITIES = 'SHOW_ALL_RESTRICTED_SECURITIES';

// Open/Reset Related Company Price Modal Dialog
export const RELATED_COMPANY_PRICE_MODAL_DIALOG_OPEN = 'RELATED_COMPANY_PRICE_MODAL_DIALOG_OPEN';
export const RELATED_COMPANY_PRICE_MODAL_DIALOG_RESET = 'RELATED_COMPANY_PRICE_MODAL_DIALOG_RESET';

// Generate Related Company Price Report
export const START_GENERATE_RELATED_COMPANY_PRICE_REPORT = 'START_GENERATE_RELATED_COMPANY_PRICE_REPORT';
export const GENERATE_RELATED_COMPANY_PRICE_REPORT_SUCCESS = 'GENERATE_RELATED_COMPANY_PRICE_REPORT_SUCCESS';
export const GENERATE_RELATED_COMPANY_PRICE_REPORT_FAILURE = 'GENERATE_RELATED_COMPANY_PRICE_REPORT_FAILURE';
export const INIT_GENERATE_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS = 'INIT_GENERATE_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS';

// Load Related Company Price Report
export const START_LOAD_RELATED_COMPANY_PRICE_REPORT = 'START_LOAD_RELATED_COMPANY_PRICE_REPORT';
export const LOAD_RELATED_COMPANY_PRICE_REPORT_SUCCESS = 'LOAD_RELATED_COMPANY_PRICE_REPORT_SUCCESS';
export const LOAD_RELATED_COMPANY_PRICE_REPORT_FAILURE = 'LOAD_RELATED_COMPANY_PRICE_REPORT_FAILURE';
export const INIT_LOAD_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS = 'INIT_LOAD_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS';