import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

class RotationReviewGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
            //     {
            //     field: 'meetingid',
            //     headerName: "MeetingID",
            //     cellClass: 'non-number',
            //     width: 80,
            //     cellRenderer: 'agGroupCellRenderer',
            //     // headerCheckboxSelection: true,
            //     // headerCheckboxSelectionFilteredOnly: true,
            //     // checkboxSelection: true
            // },
            {
                field: 'title',
                headerName: "Title*",
                cellClass: 'non-number',
                width: 300,
                cellRenderer: 'agGroupCellRenderer',
            },
            {
                field: 'starttime',
                headerName: "StartTime*",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'endtime',
                headerName: "EntTime*",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'type',
                headerName: "Type",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'category',
                headerName: "Category",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'comperename',
                headerName: "Coach",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'desc',
                headerName: "Desc*",
                cellClass: 'non-number',
                width: 300,
            },
            {
                field: 'score',
                headerName: "Credit",
                cellClass: 'non-number',
                width: 80,
            },
            {
                field: 'confirmRNumReat',
                headerName: "Mandatory Participation Rate",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'confirmONumReat',
                headerName: "Optional Participation",
                cellClass: 'non-number',
                width: 200,
            },

            {
                field: 'speaker',
                headerName: "Speaker",
                cellClass: 'non-number',
                width: 150,
            },



            // {
            //     field: 'state',
            //     headerName: "state",
            //     cellClass: 'non-number',
            //     width: 100,
            // },
            // {
            //     field: 'signin',
            //     headerName: "Sumitter*",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
           
            // {
            //     field: 'participant',
            //     headerName: "Participant*",
            //     cellClass: 'non-number',
            //     width: 200,
            // },

            // {
            //     field: 'note',
            //     headerName: "Note",
            //     cellClass: 'non-number',
            //     width: 300,
            // },
            // {
            //     field: 'filepath',
            //     headerName: "FilePath",
            //     cellClass: 'non-number',
            //     width: 300,
            //     cellRenderer: this.sendHistoryAttachementCellRender
            // },

            // {
            //     field: 'lastupdatetime',
            //     headerName: "Last Modified date",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
            // {
            //     field: 'validetodate',
            //     headerName: "Valid to",
            //     cellClass: 'number',
            //     width: 100,
            // },
            // {
            //     field: 'filepath',
            //     headerName: "User Access Form",
            //     cellClass: 'non-number',
            //     width: 300,
            //     cellRenderer: this.sendHistoryAttachementCellRender
            // },
        ],
        frameworkComponents: {
        },
        
        defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 50
        },

        // defaultColDef:{
        //     enableCellChangeFlash: true,
        //     minWidth: 50,
        //     editable: true,
        //     sortable: true,
        //     flex: 1,
        //     minWidth: 100,
        //     filter: true,
        //     resizable: true,
        //     tooltipValueGetter: (params) => {
        //       return params.value;
        //     }
        // },



        rowSelection: "multiple",
        context: { componentParent: this },
            
      

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[

                        {
                            field: 'starttime',
                            headerName: "Training Date*",
                            cellClass: 'non-number',
                            width: 100,
                            cellRenderer: (params) => {

                                return params.data.starttime.substring(0, 10);;
                            },
                        },
                        {field: 'title', headerName: 'Title', cellClass: 'non-number', width: 150},
                        {field: 'subtitle', headerName: 'SubTitle', cellClass: 'non-number', width: 150},
                        {
                            field: 'comperename',
                            headerName: "Coach",
                            cellClass: 'non-number',
                            width: 200,
                        },
                        {
                            field: 'requiredattendees',
                            headerName: "Name",
                            cellClass: 'non-number',
                            width: 200,
                        },
                        
                        {
                            field: 'confirmRNumReat',
                            headerName: "Mandatory Participation Rate",
                            cellClass: 'non-number',
                            width: 200,
                        },
                    
                        {field: 'meetingID', headerName: 'id', cellClass: 'non-number', width: 120,hide:true},
                        {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                       
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectRotationReviewInfo(cptRcdDataArray);
                    },
                    defaultColDef:{
                        enableCellChangeFlash: true,
                        minWidth: 50,
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                        tooltipValueGetter: (params) => {
                          return params.value;
                        }
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.detialList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">  Detail</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.rotationReviewDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.closeToolPanel();
      };
    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let rotationReviewInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectRotationReviewInfo(rotationReviewInfoArray);
    }
    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
        // if(data.status === 'Finished') {
        //     return retDiv;
        // }
        const finishTaskBtn = document.createElement('button');
        const finishTaskText = document.createTextNode("ShowDetail");
        finishTaskBtn.appendChild(finishTaskText);
        finishTaskBtn.onclick = () => {
            props.openFinishTaskModal({id:data.id,participant:null});
        }
        retDiv.appendChild(finishTaskBtn);
        return retDiv;
    }
    dataColorCellStyle(param) {
        const status = param.value;
        if(status === 'true') {
            // return {color: 'green'};
            return { background: '#fcf800 '};
        }
        
        
    }
    render() {

        const {
            // query
            loadRotationReviewInfoActionStatus,
            loadRotationReviewInfoResp,
        } = this.props;


        const isLoading = loadRotationReviewInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadRotationReviewInfoArray = [];
        if(!!loadRotationReviewInfoResp && !!loadRotationReviewInfoResp.data) {
            loadRotationReviewInfoArray = loadRotationReviewInfoResp.data;
        }
//style={{height: '500px'}}

        return (
            <div className={'ag-theme-balham grid-wrapper'} >
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadRotationReviewInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            rowClassRules={this.state.rowClassRules}
                            // frameworkComponents={this.state.frameworkComponents}
                            
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            masterDetail={true}
                            sideBar={true}
                            resizable={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            // onGridReady={this.onGridReady}
                            onSelectionChanged={this.onDataSelectionChanged}
                            tooltipShowDelay={0} 
                     
                        />
                    )} 
            </div>
        );
    }
}

export default RotationReviewGrid;
