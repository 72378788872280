import {connect} from 'react-redux';
import RestrictedSecurityDashboard from '../components/RestrictedSecurityDashboard';

import {
  // Load Restricted Security 
  loadRestrictedSecurity,
  initLoadRestrictedSecurityActionStatus,

  // Select Restricted Security 
  selectRestrictedSecurity,

  // Open/Reset Restricted Security  Modal Dialog
  openRestrictedSecurityEditModalDialog,
  resetRestrictedSecurityEditModalDialog,

  // Restricted Security  Edit Change Input
  restrictedSecurityEditChangeInput,

  // =============================================================
  // Create Restricted Security 
  createRestrictedSecurity,
  initCreateRestrictedSecurityActionStatus,

  // Update Restricted Security 
  updateRestrictedSecurity,
  initUpdateRestrictedSecurityActionStatus,

  // Batch Delete Restricted Security 
  batchDeleteRestrictedSecurity,
  initBatchDeleteRestrictedSecurityActionStatus,

  // =================================================================
  // Load Restricted Security BBG Tickers
  loadRestrictedSecurityBbgTickers,
  initLoadRestrictedSecurityBbgTickersActionStatus,

  // Clear Restricted Security bbgTickerArray bbgTickerOptions
  clearRestrictedSecurityBbgTickers,

  // Restricted Security BBG Ticker Select Changed
  restrictedSecurityBbgTickerSelectChanged,

  // Restricted Security Fund Code Select Changed
  restrictedSecurityFundCodeSelectChanged,

  // Restricted Security Book Code Select Changed
  restrictedSecurityBookCodeSelectChanged,

  // Open/Reset Restricted Security Operate Modal Dialog
  openRestrictedSecurityOperateModalDialog,
  resetRestrictedSecurityOperateModalDialog,

  // On List Restricted Security
  onListRestrictedSecurity,
  initOnListRestrictedSecurityActionStatus,

  // Off List Restricted Security
  offListRestrictedSecurity,
  initOffListRestrictedSecurityActionStatus,

  // Show Valid Restricted Securities
  showValidRestrictedSecurities,

  // Show All Restricted Securities
  showAllRestrictedSecurities,

  // Open/Reset Related Company Price Modal Dialog
  openRelatedCompanyPriceModalDialog,
  resetRelatedCompanyPriceModalDialog,

  // Generate Related Company Price Report
  generateRelatedCompanyPriceReport,
  initGenerateRelatedCompanyPriceReportActionStatus,

  // Load Related Company Price Report
  loadRelatedCompanyPriceReport,
  initLoadRelatedCompanyPriceReportActionStatus,

} from '../restrictedSecurityActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    restrictedSecurityGridColumns: state.restrictedSecurity.restrictedSecurityGridColumns,
    relatedCompPriceReportGridColumns: state.restrictedSecurity.relatedCompPriceReportGridColumns,

    // Restricted Security  
    restrictedSecurityArray: state.restrictedSecurity.restrictedSecurityArray,
    filterRestrictedSecurityArray: state.restrictedSecurity.filterRestrictedSecurityArray,

    // Selected Restricted Security 
    selectedRestrictedSecurityArray: state.restrictedSecurity.selectedRestrictedSecurityArray,

    // Restricted Security  Edit Modal
    restrictedSecurityEditControlModal: state.restrictedSecurity.restrictedSecurityEditControlModal,

    // load/create/update/delete Restricted Security  
    loadRestrictedSecurityActionStatus: state.restrictedSecurity.loadRestrictedSecurityActionStatus,
    createRestrictedSecurityActionStatus: state.restrictedSecurity.createRestrictedSecurityActionStatus,
    updateRestrictedSecurityActionStatus: state.restrictedSecurity.updateRestrictedSecurityActionStatus,
    batchDeleteRestrictedSecActionStatus: state.restrictedSecurity.batchDeleteRestrictedSecActionStatus,

    // Security Ticker Data
    bbgTickerArray: state.restrictedSecurity.bbgTickerArray,
    bbgTickerOptions: state.restrictedSecurity.bbgTickerOptions,
    securityTickerArray: state.restrictedSecurity.securityTickerArray,
    
    // Fund Book Data
    fundBookData: state.restrictedSecurity.fundBookData,
    fundCodeArray: state.restrictedSecurity.fundCodeArray,
    fundCodeOptions: state.restrictedSecurity.fundCodeOptions,
    bookCodeArray: state.restrictedSecurity.bookCodeArray,
    bookCodeOptions: state.restrictedSecurity.bookCodeOptions,
    fundBookArray: state.restrictedSecurity.fundBookArray,

    // Load User Account
    userAccountOptions: state.restrictedSecurity.userAccountOptions,

    // Load Restricted Security BBG Tickers Action Status
    loadRestrictedSecurityBbgTickersActionStatus: state.restrictedSecurity.loadRestrictedSecurityBbgTickersActionStatus,

    // Restricted Security Request Operate Modal Dialog
    restrictedSecurityRequestOperateControlModal: state.restrictedSecurity.restrictedSecurityRequestOperateControlModal,

    // Restricted Security Operate Modal Dialog
    restrictedSecurityOperateControlModal: state.restrictedSecurity.restrictedSecurityOperateControlModal,

    // On/Off List Restricted Security Action Status
    onListRestrictedSecurityActionStatus: state.restrictedSecurity.onListRestrictedSecurityActionStatus,
    offListRestrictedSecurityActionStatus: state.restrictedSecurity.offListRestrictedSecurityActionStatus,

    // 附件操作URL
    restrictedSecAttachmentUploadUrl: state.restrictedSecurity.restrictedSecAttachmentUploadUrl,
    restrictedSecAttachmentPreviewUrl: state.restrictedSecurity.restrictedSecAttachmentPreviewUrl,
    restrictedSecAttachmentDownloadUrl: state.restrictedSecurity.restrictedSecAttachmentDownloadUrl,

    // Related Company Price Modal Dialog
    relatedCompanyPriceControlModal: state.restrictedSecurity.relatedCompanyPriceControlModal,

    // Generate Related Company Price Report
    generateRelatedCompanyPriceReportActionStatus: state.restrictedSecurity.generateRelatedCompanyPriceReportActionStatus,
    generateRelatedCompanyPriceReportErrMsg: state.restrictedSecurity.generateRelatedCompanyPriceReportErrMsg,

    // Load Related Company Price Report
    loadRelatedCompanyPriceReportActionStatus: state.restrictedSecurity.loadRelatedCompanyPriceReportActionStatus,
    loadRelatedCompanyPriceReportErrMsg: state.restrictedSecurity.loadRelatedCompanyPriceReportErrMsg,
    relatedCompanyPriceReportArray: state.restrictedSecurity.relatedCompanyPriceReportArray,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Restricted Security 
    loadRestrictedSecurity: () => dispatch(loadRestrictedSecurity()),
    initLoadRestrictedSecurityActionStatus: () => dispatch(initLoadRestrictedSecurityActionStatus()),

    // Select Restricted Security 
    selectRestrictedSecurity: (payload) => dispatch(selectRestrictedSecurity(payload)),

    // Open/Reset Restricted Security Edit Modal Dialog
    openRestrictedSecurityEditModalDialog: (isAddMode, restrictedSecurity) => dispatch(openRestrictedSecurityEditModalDialog(isAddMode, restrictedSecurity)),
    resetRestrictedSecurityEditModalDialog: () => dispatch(resetRestrictedSecurityEditModalDialog()),

    // Restricted Security  Edit Change Input
    restrictedSecurityEditChangeInput: (input) => dispatch(restrictedSecurityEditChangeInput(input)),

    // =============================================================
    // Create Restricted Security 
    createRestrictedSecurity: (restrictedSecurity) => dispatch(createRestrictedSecurity(restrictedSecurity)),
    initCreateRestrictedSecurityActionStatus: () => dispatch(initCreateRestrictedSecurityActionStatus()),

    // Update Restricted Security 
    updateRestrictedSecurity: (restrictedSecurity) => dispatch(updateRestrictedSecurity(restrictedSecurity)),
    initUpdateRestrictedSecurityActionStatus: () => dispatch(initUpdateRestrictedSecurityActionStatus()),

    // Batch Delete Restricted Security 
    batchDeleteRestrictedSecurity: (comments, restrictedSecGuidList) => dispatch(batchDeleteRestrictedSecurity(comments, restrictedSecGuidList)),
    initBatchDeleteRestrictedSecurityActionStatus: () => dispatch(initBatchDeleteRestrictedSecurityActionStatus()),

    // =================================================================
    // Load Restricted Security BBG Tickers
    loadRestrictedSecurityBbgTickers: (queryText) => dispatch(loadRestrictedSecurityBbgTickers(queryText)),
    initLoadRestrictedSecurityBbgTickersActionStatus: () => dispatch(initLoadRestrictedSecurityBbgTickersActionStatus()),

    // Clear Restricted Security bbgTickerArray bbgTickerOptions
    clearRestrictedSecurityBbgTickers: () => dispatch(clearRestrictedSecurityBbgTickers()),

    // Restricted Security BBG Ticker Select Changed
    restrictedSecurityBbgTickerSelectChanged: (bbgTicker) => dispatch(restrictedSecurityBbgTickerSelectChanged(bbgTicker)),

    // Restricted Security Fund Code Select Changed
    restrictedSecurityFundCodeSelectChanged: (fundCode) => dispatch(restrictedSecurityFundCodeSelectChanged(fundCode)),

    // Restricted Security Book Code Select Changed
    restrictedSecurityBookCodeSelectChanged: (bookCode) => dispatch(restrictedSecurityBookCodeSelectChanged(bookCode)),

    // Open/Reset Restricted Security Operate Modal Dialog
    openRestrictedSecurityOperateModalDialog: (operateMode, restrictedSecGuidList) => dispatch(openRestrictedSecurityOperateModalDialog(operateMode, restrictedSecGuidList)),
    resetRestrictedSecurityOperateModalDialog: () => dispatch(resetRestrictedSecurityOperateModalDialog()),

    // On List Restricted Security
    onListRestrictedSecurity: (comments, restrictedSecGuidList, reason) => dispatch(onListRestrictedSecurity(comments, restrictedSecGuidList, reason)),
    initOnListRestrictedSecurityActionStatus: () => dispatch(initOnListRestrictedSecurityActionStatus()),

    // Off List Restricted Security
    offListRestrictedSecurity: (comments, restrictedSecGuidList, reason) => dispatch(offListRestrictedSecurity(comments, restrictedSecGuidList, reason)),
    initOffListRestrictedSecurityActionStatus: () => dispatch(initOffListRestrictedSecurityActionStatus()),

    // Show Valid Restricted Securities
    showValidRestrictedSecurities: () => dispatch(showValidRestrictedSecurities()),

    // Show All Restricted Securities
    showAllRestrictedSecurities: () => dispatch(showAllRestrictedSecurities()),

    // Open/Reset Related Company Price Modal Dialog
    openRelatedCompanyPriceModalDialog: () => dispatch(openRelatedCompanyPriceModalDialog()),
    resetRelatedCompanyPriceModalDialog: () => dispatch(resetRelatedCompanyPriceModalDialog()),

    // Generate Related Company Price Report
    generateRelatedCompanyPriceReport: () => dispatch(generateRelatedCompanyPriceReport()),
    initGenerateRelatedCompanyPriceReportActionStatus: () => dispatch(initGenerateRelatedCompanyPriceReportActionStatus()),

    // Load Related Company Price Report
    loadRelatedCompanyPriceReport: () => dispatch(loadRelatedCompanyPriceReport()),
    initLoadRelatedCompanyPriceReportActionStatus: () => dispatch(initLoadRelatedCompanyPriceReportActionStatus()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedSecurityDashboard);