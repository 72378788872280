import {connect} from 'react-redux';
import ExchangeRateDashboard from '../components/ExchangeRateDashboard';

import {
  // Sync Exchange Rate
  syncExchangeRate,
  initSyncExchangeRateActionStatus,

  // Load Exchange Rate
  loadExchangeRate,
  initLoadExchangeRateActionStatus,

} from '../exchangeRateActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    exchangeRateMetaGridColumns: state.exchangeRate.exchangeRateMetaGridColumns,

    // Exchange Rate
    exchangeRateArray: state.exchangeRate.exchangeRateArray,

    // Sync Exchange Rate Action Status
    syncExchangeRateActionStatus: state.exchangeRate.syncExchangeRateActionStatus,
    syncExchangeRateErrMsg: state.exchangeRate.syncExchangeRateErrMsg,

    // Load Exchange Rate Action Status
    loadExchangeRateActionStatus: state.exchangeRate.loadExchangeRateActionStatus,
    loadExchangeRateErrMsg: state.exchangeRate.loadExchangeRateErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Sync Exchange Rate
    syncExchangeRate: (dateString) => dispatch(syncExchangeRate(dateString)),
    initSyncExchangeRateActionStatus: () => dispatch(initSyncExchangeRateActionStatus()),

    // Load Exchange Rate
    loadExchangeRate: () => dispatch(loadExchangeRate()),
    initLoadExchangeRateActionStatus: () => dispatch(initLoadExchangeRateActionStatus()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeRateDashboard);