import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import PersonalTradeOpRecordGrid from './PersonalTradeOpRecordGrid';

class PersonalTradeOpRecordDialog extends Component {

  componentDidMount() {
    this.props.loadPersonalTradeOperationRecord();
  }

  refreshButtonOnClicked = () => {
    this.props.loadPersonalTradeOperationRecord();
  }

  render() {
    const {
      loadPersonalTradeOperationRecordActionStatus,
      personalTradeOpRecordControlModal,
    } = this.props;

    const {
      isOpened,
    } = personalTradeOpRecordControlModal;

    const isLoading = loadPersonalTradeOperationRecordActionStatus === ACTION_STATUS.LOGINING;

    let modalTitle = 'Personal Trade Operation Record';

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          onOk={() => this.props.resetPersonalTradeOperationRecordModalDialog()}
          onCancel={() => this.props.resetPersonalTradeOperationRecordModalDialog()}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          maskClosable={false}
        >
          <div style={{ marginBottom: 10 }}>
            <Button 
              onClick={this.refreshButtonOnClicked} 
              size='small'
              style={{ fontSize: 10 }}
              loading={isLoading}
              type='primary'>
              Refresh
            </Button>
          </div>

          <div style={{ height: 500 }}>
            <PersonalTradeOpRecordGrid {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default PersonalTradeOpRecordDialog;