import React, { Component, useState, useEffect,useRef } from 'react';
import { Input, Form, Row, Col, Modal, message, Button, Icon, Upload,DatePicker } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import Table from 'braft-extensions/dist/table'
import 'braft-extensions/dist/table.css'
import { localStorageClient } from '../../../../../utils/localStorageClient';
// import MyEditor from './MyEditor'; 


const TextArea = Input.TextArea;
const FormItem = Form.Item;
const { MonthPicker } = DatePicker;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
let ref = "";


const blockImportFn = Table()[2].importer;
const blockExportFn = Table()[2].exporter;
class MonthlyReportDialog extends Component {
   
    constructor(props){
        super(props);
        this.state={
            detailData:{
            }
        }
    }
    state = {
        editorState: null
    }
    componentDidUpdate() {
        let {data} = this.props;
    }


      submitContent = async () => {

        const htmlContent = this.state.editorState.toHTML()

      }

    attachmentHandleChange = (info) => {
        const { detailData } = this.state;
        let fileList = [...info.fileList]; 
        let attachment = '';
        let attachmentOriginName= '';
        fileList.forEach(file => {
          if (file.response) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            attachment = attachment + ";" + filename;
            attachmentOriginName = attachmentOriginName + ";" + originalFileName;
          }
        });
        const updateData =  {
            ...detailData,
            fileList,
            attachment,
            attachmentOriginName
        };
        this.setState({
            detailData: updateData
        })
      }
      
    onInputChange = ({name, value}) => {
        const { detailData } = this.state;
        let updateData =  {
            ...detailData,
            [name]: value
        };
        this.setState({
            detailData: updateData
        })
    }

    saveMonthlyReport = () => {
        const { 
            monthlyReportDetail ,
            monthlyReportModal ,
            queryParams
        } = this.props;

        const op = monthlyReportModal.op;
        const {
            detailData
        } = this.state;
        let data = {
            month: moment().format('YYYY-MM'),
            content:ref,
            fileList: []
        };
        if(op === 'UPDATE'){
            monthlyReportDetail.content = ref;
            const userName = localStorageClient.getUserName();
            if(!monthlyReportDetail) return;
            if(monthlyReportDetail 
                && monthlyReportDetail.createdBy !== userName){
                message.error("Only create user can edit/del this record");
                return;
            }
            const yearMonth = moment().subtract(14,"days").format("YYYY-MM");
            const month = monthlyReportDetail.month;
            if(yearMonth > month){
                message.error("Can't update this record, exceeds modifiable time");
                return;
            }
        }
        data = op === 'ADD' ? {...data,...detailData} : {...monthlyReportDetail,...detailData};
        this.props.saveMonthlyReport(data,queryParams);
        this.setState({
            detailData: {}
        });
    }

    closeMonthlyReportDialog = () =>{
        this.setState({
            detailData: {}
        })
        this.props.closeMonthlyReportDialog();
    }
    handleEditorChange = (editorState) => {
        const htmlContent = editorState.toHTML();
        ref= editorState.toHTML();
        const htmlContent22 = ""; 
      }


    disabledDate = (current) => {
        return current && current < moment().startOf('day')
    }

    
    render() {
        const options = {
            defaultColumns: 3, // 默认列数
            defaultRows: 3, // 默认行数
            withDropdown: true, // 插入表格前是否弹出下拉菜单
            columnResizable: true, // 是否允许拖动调整列宽，默认false
            exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
            includeEditors: ['editor-id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
            excludeEditors: ['editor-id-2'],  // 指定该模块对哪些BraftEditor无效
            exportAttrString: 'border="1" style="border-collapse: collapse"'
          }
          BraftEditor.use(Table(options))  
        const { 
            monthlyReportDetail,
            monthlyReportModal ,
            monthlyReportAttachmentUploadUrl,
        } = this.props;
        
        const {
            detailData
        } = this.state;
        const op = monthlyReportModal.op;
        let data = {
            month: moment().format('YYYY-MM'),
            fileList: []
        };
        data = op === 'ADD' ? {...data,...detailData} : {...monthlyReportDetail,...detailData};
        const shStatus = op === 'Open' ?  'none': 'block';
        const shStatusW = op === 'Open' ?  'block': 'none';
        ref=data.content;
        // const { 
        //     editorState,
        // } = this.state
      
        const editorState =  BraftEditor.createEditorState(data.content, { blockImportFn, blockExportFn });
 
        const extendControlsContent = [
            {
              key: 'antd-uploader',
              type: 'component',
              component: (
                <Upload
                  accept="*"
                  showUploadList={false}
                //   onChange={uploadHandler}
                //   action={environment().backendUrl + '/sysfrontpicture/upload'}
                >
                  <button type="button" className="control-item button upload-button" data-title="插入图片">
                    上传图片
                  </button>
                </Upload>
              ),
            },
          ];

        return (
            <div>
                <Modal
                    title={'Monthly Report'}
                    centered
                    width={1000}
                    visible={monthlyReportModal.isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.closeMonthlyReportDialog()}
                    footer={[
                        
                        <Button key="Cancel" onClick={() => this.closeMonthlyReportDialog() }>
                          Cancel
                        </Button>,
                        //  <div style={{display: shStatus}}>
                        <Button  key="Save" type="primary" onClick={() => this.saveMonthlyReport() }>
                          Save
                        </Button>,
                        // </div>
                    ]}
                    >   
                    
                    <Form layout="horizontal" required={false}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Month" required>
                                    <MonthPicker
                                        allowClear={false}
                                        value={moment(
                                            data.month,
                                            'YYYY-MM'
                                        )}
                                        size="small"
                                        onChange={(date, dateString) => {
                                          this.onInputChange({
                                            name: 'month',
                                            value: dateString
                                          });
                                        }}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Content" required>

                                    
                                    {
                                    <BraftEditor
                                    id="editor-id-1"
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    onSave={this.submitContent}
                                    extendControls={extendControlsContent}
                                    /> } 




                        </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Attachment">
                                  <Upload
                                    name="attachment"
                                    withCredentials={true}
                                    multiple={true}
                                    showUploadList={true}
                                    fileList={data.fileList}
                                    action={monthlyReportAttachmentUploadUrl}
                                    onChange={this.attachmentHandleChange}
                                  >
                                    <Button>
                                      <Icon type="upload" /> Upload Attachment
                                    </Button>
                                  </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                
            </div>
            
            
        );
    }

}

export default MonthlyReportDialog;
