// query
export const ON_PORTFOLIO_MONITORING_RULE_QUERY_INPUT_CHANGE = 'ON_PORTFOLIO_MONITORING_RULE_QUERY_INPUT_CHANGE';
export const START_LOAD_PORTFOLIO_MONITORING_RULE_INFO = 'START_LOAD_PORTFOLIO_MONITORING_RULE_INFO';
export const LOAD_PORTFOLIO_MONITORING_RULE_INFO_SUCCESS = 'LOAD_PORTFOLIO_MONITORING_RULE_INFO_SUCCESS';
export const LOAD_PORTFOLIO_MONITORING_RULE_INFO_FAILURE = 'LOAD_PORTFOLIO_MONITORING_RULE_INFO_FAILURE';
export const INIT_LOAD_PORTFOLIO_MONITORING_RULE_INFO_ACTION_STATUS = 'INIT_LOAD_PORTFOLIO_MONITORING_RULE_INFO_ACTION_STATUS';
// select
export const SELECT_PORTFOLIO_MONITORING_RULE = 'SELECT_PORTFOLIO_MONITORING_RULE';
// add or edit
export const OPEN_ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_MODAL = 'OPEN_ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_MODAL';
export const ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_CHANGE_INPUT = 'ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_MODAL = 'RESET_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_MODAL';
export const START_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE = 'START_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE';
export const SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_SUCCESS = 'SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_SUCCESS';
export const SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_FAILURE = 'SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_FAILURE';
export const INIT_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_ACTION_STATUS = 'INIT_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_ACTION_STATUS';


//Sub add or edit
export const OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL = 'OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL';
export const ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT = 'ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL = 'RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL';
export const START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB = 'START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB';
export const SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS = 'SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS';
export const SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE = 'SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE';
export const INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS = 'INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS';


// batch change status
export const OPEN_BATCH_CHANGE_STATUS_MODAL = 'OPEN_BATCH_CHANGE_STATUS_MODAL';
export const RESET_BATCH_CHANGE_STATUS_MODAL = 'RESET_BATCH_CHANGE_STATUS_MODAL';
export const BATCH_CHANGE_STATUS_CHANGE_INPUT = 'BATCH_CHANGE_STATUS_CHANGE_INPUT';
export const START_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE = 'START_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE';
export const BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_SUCCESS = 'BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_SUCCESS';
export const BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_FAILURE = 'BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_FAILURE';
export const INIT_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_ACTION_STATUS = 'INIT_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_ACTION_STATUS';
// finish
export const SELECT_PORTFOLIO_MONITORING_RULE_TASK = 'SELECT_PORTFOLIO_MONITORING_RULE_TASK';
export const OPEN_FINISH_TASK_MODAL = 'OPEN_FINISH_TASK_MODAL';
export const RESET_FINISH_TASK_MODAL = 'RESET_FINISH_TASK_MODAL';
export const FINISH_TASK_CHANGE_INPUT = 'FINISH_TASK_CHANGE_INPUT';
export const START_FINISH_PORTFOLIO_MONITORING_RULE_TASK = 'START_FINISH_PORTFOLIO_MONITORING_RULE_TASK';
export const FINISH_PORTFOLIO_MONITORING_RULE_TASK_SUCCESS = 'FINISH_PORTFOLIO_MONITORING_RULE_TASK_SUCCESS';
export const FINISH_PORTFOLIO_MONITORING_RULE_TASK_FAILURE = 'FINISH_PORTFOLIO_MONITORING_RULE_TASK_FAILURE';
export const INIT_FINISH_PORTFOLIO_MONITORING_RULE_TASK_ACTION_STATUS = 'INIT_FINISH_PORTFOLIO_MONITORING_RULE_TASK_ACTION_STATUS';

