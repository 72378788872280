import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Load Restricted Security 
  START_LOAD_RESTRICTED_SECURITY,
  LOAD_RESTRICTED_SECURITY_SUCCESS,
  LOAD_RESTRICTED_SECURITY_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_ACTION_STATUS,

  // Select Restricted Security 
  SELECT_RESTRICTED_SECURITY,

  // Open/Reset Restricted Security Edit Modal Dialog
  RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_RESET,

  // Restricted Security  Edit Change Input
  RESTRICTED_SECURITY_EDIT_CHANGE_INPUT,

  // Create Restricted Security 
  START_CREATE_RESTRICTED_SECURITY,
  CREATE_RESTRICTED_SECURITY_SUCCESS,
  CREATE_RESTRICTED_SECURITY_FAILURE,
  INIT_CREATE_RESTRICTED_SECURITY_ACTION_STATUS,

  // Update Restricted Security 
  START_UPDATE_RESTRICTED_SECURITY,
  UPDATE_RESTRICTED_SECURITY_SUCCESS,
  UPDATE_RESTRICTED_SECURITY_FAILURE,
  INIT_UPDATE_RESTRICTED_SECURITY_ACTION_STATUS,

  // Batch Delete Restricted Security 
  START_BATCH_DELETE_RESTRICTED_SECURITY,
  BATCH_DELETE_RESTRICTED_SECURITY_SUCCESS,
  BATCH_DELETE_RESTRICTED_SECURITY_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_SECURITY_ACTION_STATUS,

  // Load Restricted Security BBG Tickers
  START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS,

  // Clear Restricted Security BBG Ticker
  CLEAR_RESTRICTED_SECURITY_BBG_TICKERS,

  // Restricted Security BBG Ticker Select Changed
  RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED,

  // Restricted Security Fund Code Select Changed
  RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED,

  // Restricted Security Book Code Select Changed
  RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED,

  // Open/Reset Restricted Security Operate Modal Dialog
  RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_RESET,

  // On List Restricted Security 
  START_ON_LIST_RESTRICTED_SECURITY,
  ON_LIST_RESTRICTED_SECURITY_SUCCESS,
  ON_LIST_RESTRICTED_SECURITY_FAILURE,
  INIT_ON_LIST_RESTRICTED_SECURITY_ACTION_STATUS,

  // Off List Restricted Security 
  START_OFF_LIST_RESTRICTED_SECURITY,
  OFF_LIST_RESTRICTED_SECURITY_SUCCESS,
  OFF_LIST_RESTRICTED_SECURITY_FAILURE,
  INIT_OFF_LIST_RESTRICTED_SECURITY_ACTION_STATUS,

  // Show Valid Restricted Securities
  SHOW_VALID_RESTRICTED_SECURITIES,

  // Show All Restricted Securities
  SHOW_ALL_RESTRICTED_SECURITIES,

  // Open/Reset Related Company Price Modal Dialog
  RELATED_COMPANY_PRICE_MODAL_DIALOG_OPEN,
  RELATED_COMPANY_PRICE_MODAL_DIALOG_RESET,

  // Generate Related Company Price Report
  START_GENERATE_RELATED_COMPANY_PRICE_REPORT,
  GENERATE_RELATED_COMPANY_PRICE_REPORT_SUCCESS,
  GENERATE_RELATED_COMPANY_PRICE_REPORT_FAILURE,
  INIT_GENERATE_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS,

  // Load Related Company Price Report
  START_LOAD_RELATED_COMPANY_PRICE_REPORT,
  LOAD_RELATED_COMPANY_PRICE_REPORT_SUCCESS,
  LOAD_RELATED_COMPANY_PRICE_REPORT_FAILURE,
  INIT_LOAD_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS,

} from './restrictedSecurityConstants';

// Load Restricted Security 
function startLoadRestrictedSecurity() {
  return {
    type: START_LOAD_RESTRICTED_SECURITY
  };
}

export function loadRestrictedSecurity() {
  return function(dispatch) {
    dispatch(startLoadRestrictedSecurity());
    httpClient
      .get(UrlConfig.LOAD_SUBMITTED_RESTRICTED_SECURITIES)
      .then(resp => dispatch(loadRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(loadRestrictedSecurityFailure(err)));
  };
}

function loadRestrictedSecuritySuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSecurityFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSecurityActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECURITY_ACTION_STATUS
  };
}

// Select Restricted Security 
export function selectRestrictedSecurity(payload) {
  return {
    type: SELECT_RESTRICTED_SECURITY,
    payload
  };
}

// Open/Reset Restricted Security Edit Modal Dialog
export function openRestrictedSecurityEditModalDialog(isAddMode, restrictedSecurity) {
  return {
    type: RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      restrictedSecurity,
    },
  };
}

export function resetRestrictedSecurityEditModalDialog() {
  return {
    type: RESTRICTED_SECURITY_EDIT_MODAL_DIALOG_RESET
  };
}

// Restricted Security Edit Change Input
export function restrictedSecurityEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_SECURITY_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// =============================================================
// Create Restricted Security 
function startCreateRestrictedSecurity() {
  return {
    type: START_CREATE_RESTRICTED_SECURITY
  };
}

export function createRestrictedSecurity(restrictedSecurity) {
  debugger;
  return function(dispatch) {
    dispatch(startCreateRestrictedSecurity());
    httpClient
      .post(UrlConfig.NEW_RESTRICTED_SECURITY_BY_ADMIN, restrictedSecurity)
      .then(resp => dispatch(createRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(createRestrictedSecurityFailure(err)));
  };
}

function createRestrictedSecuritySuccess(resp) {
  return {
    type: CREATE_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function createRestrictedSecurityFailure(err) {
  return {
    type: CREATE_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initCreateRestrictedSecurityActionStatus() {
  return {
    type: INIT_CREATE_RESTRICTED_SECURITY_ACTION_STATUS
  };
}

// Update Restricted Security 
function startUpdateRestrictedSecurity() {
  return {
    type: START_UPDATE_RESTRICTED_SECURITY
  };
}

export function updateRestrictedSecurity(restrictedSecurity) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedSecurity());
    httpClient
      .post(UrlConfig.UPDATE_RESTRICTED_SECURITY_BY_ADMIN, restrictedSecurity)
      .then(resp => dispatch(updateRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(updateRestrictedSecurityFailure(err)));
  };
}

function updateRestrictedSecuritySuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function updateRestrictedSecurityFailure(err) {
  return {
    type: UPDATE_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initUpdateRestrictedSecurityActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_SECURITY_ACTION_STATUS
  };
}

// Batch Delete Restricted Security 
function startBatchDeleteRestrictedSecurity() {
  return {
    type: START_BATCH_DELETE_RESTRICTED_SECURITY
  };
}

export function batchDeleteRestrictedSecurity(comments, restrictedSecGuidList) {
  const requestParams = {
    restrictedSecGuidList,
    comments,
  };

  return function(dispatch) {
    dispatch(startBatchDeleteRestrictedSecurity());
    httpClient
      .post(UrlConfig.BATCH_DELETE_RESTRICTED_SECURITY_BY_ADMIN, requestParams)
      .then(resp => dispatch(batchDeleteRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(batchDeleteRestrictedSecurityFailure(err)));
  };
}

function batchDeleteRestrictedSecuritySuccess(resp) {
  return {
    type: BATCH_DELETE_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function batchDeleteRestrictedSecurityFailure(err) {
  return {
    type: BATCH_DELETE_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initBatchDeleteRestrictedSecurityActionStatus() {
  return {
    type: INIT_BATCH_DELETE_RESTRICTED_SECURITY_ACTION_STATUS
  };
}

// =================================================================
// Load Restricted Security BBG Tickers
function startLoadRestrictedSecurityBbgTickers() {
  return {
    type: START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS,
  };
}

export function loadRestrictedSecurityBbgTickers(queryText) {
  const requestParams = {
    'query-text': queryText,
    'page': 'restrictedSecurity',
    'source': 'Admin',
  };

  return function(dispatch) {
    dispatch(startLoadRestrictedSecurityBbgTickers());
    httpClient
      .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
      .then(resp => dispatch(loadRestrictedSecurityBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadRestrictedSecurityBbgTickersFailure(err)));
  };
}

function loadRestrictedSecurityBbgTickersSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSecurityBbgTickersFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSecurityBbgTickersActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS
  };
}

// Clear Restricted Security bbgTickerArray bbgTickerOptions
export function clearRestrictedSecurityBbgTickers() {
  return {
    type: CLEAR_RESTRICTED_SECURITY_BBG_TICKERS,
  };
}

// Restricted Security BBG Ticker Select Changed
export function restrictedSecurityBbgTickerSelectChanged(bbgTicker) {
  return {
    type: RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED,
    payload: bbgTicker
  };
}

// Restricted Security Fund Code Select Changed
export function restrictedSecurityFundCodeSelectChanged(fundCode) {
  return {
    type: RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Restricted Security Book Code Select Changed
export function restrictedSecurityBookCodeSelectChanged(bookCode) {
  return {
    type: RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}

// Open/Reset Restricted Security Operate Modal Dialog
export function openRestrictedSecurityOperateModalDialog(operateMode, restrictedSecGuidList) {
  return {
    type: RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_OPEN,
    payload: {
      operateMode,
      restrictedSecGuidList,
    },
  };
}

export function resetRestrictedSecurityOperateModalDialog() {
  return {
    type: RESTRICTED_SECURITY_OPERATE_MODAL_DIALOG_RESET
  };
}

// On List Restricted Security 
function startOnListRestrictedSecurity() {
  return {
    type: START_ON_LIST_RESTRICTED_SECURITY
  };
}

export function onListRestrictedSecurity(comments, restrictedSecGuidList, reason) {
  const requestParams = {
    restrictedSecGuidList,
    comments,
    reason,
  };

  return function(dispatch) {
    dispatch(startOnListRestrictedSecurity());
    httpClient
      .post(UrlConfig.ON_LIST_RESTRICTED_SECURITY_BY_ADMIN, requestParams)
      .then(resp => dispatch(onListRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(onListRestrictedSecurityFailure(err)));
  };
}

function onListRestrictedSecuritySuccess(resp) {
  return {
    type: ON_LIST_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function onListRestrictedSecurityFailure(err) {
  return {
    type: ON_LIST_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initOnListRestrictedSecurityActionStatus() {
  return {
    type: INIT_ON_LIST_RESTRICTED_SECURITY_ACTION_STATUS
  };
}

// Off List Restricted Security 
function startOffListRestrictedSecurity() {
  return {
    type: START_OFF_LIST_RESTRICTED_SECURITY
  };
}

export function offListRestrictedSecurity(comments, restrictedSecGuidList, reason) {
  const requestParams = {
    restrictedSecGuidList,
    comments,
    reason,
  };

  return function(dispatch) {
    dispatch(startOffListRestrictedSecurity());
    httpClient
      .post(UrlConfig.OFF_LIST_RESTRICTED_SECURITY_BY_ADMIN, requestParams)
      .then(resp => dispatch(offListRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(offListRestrictedSecurityFailure(err)));
  };
}

function offListRestrictedSecuritySuccess(resp) {
  return {
    type: OFF_LIST_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function offListRestrictedSecurityFailure(err) {
  return {
    type: OFF_LIST_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initOffListRestrictedSecurityActionStatus() {
  return {
    type: INIT_OFF_LIST_RESTRICTED_SECURITY_ACTION_STATUS
  };
}

// Show Valid Restricted Securities
export function showValidRestrictedSecurities() {
  return {
    type: SHOW_VALID_RESTRICTED_SECURITIES,
  };
}

// Show All Restricted Securities
export function showAllRestrictedSecurities() {
  return {
    type: SHOW_ALL_RESTRICTED_SECURITIES,
  };
}

// Open/Reset Related Company Price Modal Dialog
export function openRelatedCompanyPriceModalDialog() {
  return {
    type: RELATED_COMPANY_PRICE_MODAL_DIALOG_OPEN,
    payload: {
    },
  };
}

export function resetRelatedCompanyPriceModalDialog() {
  return {
    type: RELATED_COMPANY_PRICE_MODAL_DIALOG_RESET
  };
}

// Generate Related Company Price Report
function startGenerateRelatedCompanyPriceReport() {
  return {
    type: START_GENERATE_RELATED_COMPANY_PRICE_REPORT
  };
}

export function generateRelatedCompanyPriceReport() {
  return function(dispatch) {
    dispatch(startGenerateRelatedCompanyPriceReport());
    httpClient
      .post(UrlConfig.GENERATE_RELATED_COMPANY_PRICE_REPORT)
      .then(resp => dispatch(generateRelatedCompanyPriceReportSuccess(resp)))
      .catch(err => dispatch(generateRelatedCompanyPriceReportFailure(err)));
  };
}

function generateRelatedCompanyPriceReportSuccess(resp) {
  return {
    type: GENERATE_RELATED_COMPANY_PRICE_REPORT_SUCCESS,
    payload: resp
  };
}

function generateRelatedCompanyPriceReportFailure(err) {
  return {
    type: GENERATE_RELATED_COMPANY_PRICE_REPORT_FAILURE,
    payload: err
  };
}

export function initGenerateRelatedCompanyPriceReportActionStatus() {
  return {
    type: INIT_GENERATE_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS
  };
}

// Load Related Company Price Report
function startLoadRelatedCompanyPriceReport() {
  return {
    type: START_LOAD_RELATED_COMPANY_PRICE_REPORT
  };
}

export function loadRelatedCompanyPriceReport() {
  return function(dispatch) {
    dispatch(startLoadRelatedCompanyPriceReport());
    httpClient
      .get(UrlConfig.LOAD_RELATED_COMPANY_PRICE_REPORT)
      .then(resp => dispatch(loadRelatedCompanyPriceReportSuccess(resp)))
      .catch(err => dispatch(loadRelatedCompanyPriceReportFailure(err)));
  };
}

function loadRelatedCompanyPriceReportSuccess(resp) {
  return {
    type: LOAD_RELATED_COMPANY_PRICE_REPORT_SUCCESS,
    payload: resp
  };
}

function loadRelatedCompanyPriceReportFailure(err) {
  return {
    type: LOAD_RELATED_COMPANY_PRICE_REPORT_FAILURE,
    payload: err
  };
}

export function initLoadRelatedCompanyPriceReportActionStatus() {
  return {
    type: INIT_LOAD_RELATED_COMPANY_PRICE_REPORT_ACTION_STATUS
  };
}