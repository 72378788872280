import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
    LOAD_MONTHLY_REPORT_LIST_SUCCESS,
    LOAD_MONTHLY_REPORT_LIST_FAIL,
    LOAD_MONTHLY_REPORT_DETAIL_SUCCESS,
    LOAD_MONTHLY_REPORT_DETAIL_FAIL,
    SAVE_MONTHLY_REPORT_SUCCESS,
    SAVE_MONTHLY_REPORT_FAIL,
    DEL_MONTHLY_REPORT_SUCCESS,
    DEL_MONTHLY_REPORT_FAIL,
    OPEN_MONTHLY_REPORT_LIST_DIALOG,
    CLOSE_MONTHLY_REPORT_LIST_DIALOG,
    ON_QUERY_PARAMS_CHANGE,
} from './MonthlyReportConstants';

export function loadMonthlyReportList(params) {
    return function(dispatch) {
      httpClient
        .post(UrlConfig.LOAD_MONTHLY_REPORT_LIST, params)
        .then(resp => {
            dispatch(loadMonthlyReportListSuccess(resp));
            if(resp.data && resp.data.length>0){
                dispatch(loadMonthlyReportDetail(resp.data[0]));
            }
        })
        .catch(err => dispatch(loadMonthlyReportListFailure(err)));
    };
  }
  
  function loadMonthlyReportListSuccess(resp) {
    return {
      type: LOAD_MONTHLY_REPORT_LIST_SUCCESS,
      payload: resp
    };
  }
  
  function loadMonthlyReportListFailure(err) {
    return {
      type: LOAD_MONTHLY_REPORT_LIST_FAIL,
      payload: err
    };
  }

  export function loadMonthlyReportDetail(params) {
    return function(dispatch) {
      httpClient
        .post(UrlConfig.LOAD_MONTHLY_REPORT_DETAIL, params)
        .then(resp => dispatch(loadMonthlyReportDetailSuccess(resp)))
        .catch(err => dispatch(loadMonthlyReportDetailFailure(err)));
    };
  }
  
  function loadMonthlyReportDetailSuccess(resp) {
    return {
      type: LOAD_MONTHLY_REPORT_DETAIL_SUCCESS,
      payload: resp
    };
  }
  
  function loadMonthlyReportDetailFailure(err) {
    return {
      type: LOAD_MONTHLY_REPORT_DETAIL_FAIL,
      payload: err
    };
  }

  export function saveMonthlyReport(params,queryParams) {
    return function(dispatch) {
      httpClient
        .post(UrlConfig.SAVE_MONTHLY_REPORT, params)
        .then(resp => {
            dispatch(saveMonthlyReportSuccess(resp));
            dispatch(closeMonthlyReportDialog())
            dispatch(loadMonthlyReportList(queryParams))
        })
        .catch(err => dispatch(saveMonthlyReportFailure(err)));
    };
  }
  
  function saveMonthlyReportSuccess(resp) {
    return {
      type: SAVE_MONTHLY_REPORT_SUCCESS,
      payload: resp
    };
  }
  
  function saveMonthlyReportFailure(err) {
    return {
      type: SAVE_MONTHLY_REPORT_FAIL,
      payload: err
    };
  }

  export function delMonthlyReport(params,queryParams) {
    return function(dispatch) {
      httpClient
        .post(UrlConfig.DEL_MONTHLY_REPORT, params)
        .then(resp => {
            dispatch(delMonthlyReportSuccess(resp));
            dispatch(loadMonthlyReportList(queryParams));
        })
        .catch(err => dispatch(delMonthlyReportFailure(err)));
    };
  }
  
  function delMonthlyReportSuccess(resp) {
    return {
      type: DEL_MONTHLY_REPORT_SUCCESS,
      payload: resp
    };
  }
  
  function delMonthlyReportFailure(err) {
    return {
      type: DEL_MONTHLY_REPORT_FAIL,
      payload: err
    };
  }

export function openMonthlyReportDialog(params) {
    return {
        type: OPEN_MONTHLY_REPORT_LIST_DIALOG,
        payload: params
    };
}

export function closeMonthlyReportDialog() {
    return {
        type: CLOSE_MONTHLY_REPORT_LIST_DIALOG
    };
}


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_PARAMS_CHANGE,
        payload: {
          name,
          value,
        }
    };
}