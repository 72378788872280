import React, { Component } from 'react';

import { Form, Row, Col, Input, Modal, message, DatePicker, Checkbox, Select, Upload, Button, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import moment from 'moment';

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import LicensingFormOpMode from '../../../../../utils/QuestionnaireOpMode';
import { QUESTIONNAIRE_TYPE_MAP } from '../../../../../utils/QuestionnaireType';
import { LICENSINGFORM_TYPE_MAP } from '../../../../../utils/LicensingFormType';


const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

// LicensingForm Type
const LicensingFormTypeOptions = [];
for (let i = 0; i < LICENSINGFORM_TYPE_MAP.length; i++) {
  LicensingFormTypeOptions.push(<Option key={LICENSINGFORM_TYPE_MAP[i].code}>{LICENSINGFORM_TYPE_MAP[i].label}</Option>);
}

class LicensingFormEditDialog extends Component {
  state = {
    
  };

  onInputChange = ({ name, value }) => {
    this.props.licensingFormEditChangeInput({ name, value });
  };

  componentDidUpdate() {
    const { 
      createLicensingFormActionStatus,
      updateLicensingFormActionStatus,
      copyLicensingFormActionStatus,
    } = this.props;

    // 创建 LicensingForm 成功
    if (createLicensingFormActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('LicensingForm is created successfully!');
      this.props.loadLicensingForm();
      this.props.initCreateLicensingFormActionStatus();

      this.props.form.resetFields();
      this.props.resetLicensingFormEditModalDialog();
    } 

    // 创建 LicensingForm 失败
    if (createLicensingFormActionStatus === ACTION_STATUS.ERROR) {
      message.error('LicensingForm is created failed!');
      this.props.initCreateLicensingFormActionStatus();
    }

    // 更新 LicensingForm 成功
    if (updateLicensingFormActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('LicensingForm is updated successfully!');
      this.props.loadLicensingForm();
      this.props.initUpdateLicensingFormActionStatus();

      this.props.form.resetFields();
      this.props.resetLicensingFormEditModalDialog();
    } 

    // 更新 LicensingForm 失败
    if (updateLicensingFormActionStatus === ACTION_STATUS.ERROR) {
      message.error('LicensingForm is updated failed!');
      this.props.initUpdateLicensingFormActionStatus();
    }

    // Copy LicensingForm 成功
    if (copyLicensingFormActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('LicensingForm is copied successfully!');
      this.props.loadLicensingForm();
      this.props.initCopyLicensingFormActionStatus();

      this.props.form.resetFields();
      this.props.resetLicensingFormEditModalDialog();
    } 

    // Copy LicensingForm 失败
    if (copyLicensingFormActionStatus === ACTION_STATUS.ERROR) {
      message.error('LicensingForm is copied failed!');
      this.props.initCopyLicensingFormActionStatus();
    }
  }

  // Submit Create/Edit Member
  submitButtonOnClicked = () => {
    const {
      licensingFormEditControlModal,
    } =  this.props;

    const {
      mode,
      fields,
    } =  licensingFormEditControlModal;

    const {
      guid,
      name,
      title,
      isActive,
      expiredDate,
      attachment,
      attachmentOriginName,
      type,
    } = fields;

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          console.info('success');

          if (mode === LicensingFormOpMode.NEW) {
            this.props.createLicensingForm({
              guid,
              name,
              title,
              isActive,
              expiredDate,
              attachment,
              attachmentOriginName,
              type,
            });
          } 
          
          if (mode === LicensingFormOpMode.MODIFY) {
            this.props.updateLicensingForm({
              guid,
              name,
              title,
              isActive,
              expiredDate,
              attachment,
              attachmentOriginName,
              type,
            });
          }

          if (mode === LicensingFormOpMode.COPY) {
            this.props.copyLicensingForm({
              guid,
              name,
              title,
              isActive,
              expiredDate,
              attachment,
              attachmentOriginName,
              type,
            });
          }
        }
      },
    );
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    // fileList = fileList.slice(-1);
    this.props.licensingFormEditChangeInput({name: 'fileList', value: fileList});

    let fileNameArray = [];
    let originalFileNameArray = [];
    fileList.forEach(file => {
      if (file.response) {
        const fileName = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        
        fileNameArray.push(fileName);
        originalFileNameArray.push(originalFileName);
      }
    });

    if (fileList.length === fileNameArray.length) {
      const fileNames = fileNameArray.join(',');
      const originalFileNames = originalFileNameArray.join(',');
      this.props.licensingFormEditChangeInput({name: 'attachment', value: fileNames});
      this.props.licensingFormEditChangeInput({name: 'attachmentOriginName', value: originalFileNames});
    }
  }

  // 删除
  attachmentHandleRemove = (file) => {
    const {
      fileList,
    } =  this.props.licensingFormEditControlModal.fields;

    const fileListWithoutRemovedFile = fileList.filter(fileItem => fileItem.uid !== file.uid);
    this.props.licensingFormEditChangeInput({name: 'fileList', value: fileListWithoutRemovedFile});

    let fileNameArray = fileListWithoutRemovedFile.map(fileItem => fileItem.response.data.fileNameWithTimeStamp);
    let originalFileNameArray = fileListWithoutRemovedFile.map(fileItem => fileItem.response.data.originalFileName);
    
    const fileNames = fileNameArray.join(',');
    const originalFileNames = originalFileNameArray.join(',');
    this.props.licensingFormEditChangeInput({name: 'attachment', value: fileNames});
    this.props.licensingFormEditChangeInput({name: 'attachmentOriginName', value: originalFileNames});
  }

  licensingFormTypeChanged = (val) => {
    this.onInputChange({ name: 'type', value: val });
  }

  render() {
    const {
      licensingFormEditControlModal,
      createLicensingFormActionStatus,
      updateLicensingFormActionStatus,
      copyLicensingFormActionStatus,

      // 问卷附件上传Url
      licensingFormAttachmentUploadUrl,
    } = this.props;

    const {
      isOpened,
      mode,
      respErrMsg,
    } = licensingFormEditControlModal;


    const { getFieldDecorator } = this.props.form;

    const {
      name,
      title,
      isActive,
      expiredDate,
      fileList,
      type
    } = licensingFormEditControlModal.fields;

    let modalTitle = "Add LicensingForm";
    if (mode === LicensingFormOpMode.MODIFY) {
      modalTitle = "Edit LicensingForm";
    } else if (mode === LicensingFormOpMode.COPY) {
      modalTitle = "Copy LicensingForm";
    }

    const isLoading = createLicensingFormActionStatus === ACTION_STATUS.LOGINING || 
      updateLicensingFormActionStatus === ACTION_STATUS.LOGINING || copyLicensingFormActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={600}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetLicensingFormEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Name">
                  {getFieldDecorator('name', {
                    initialValue: name,
                    rules: [{
                      required: true,
                      message: 'Please input licensingForm name',
                    }],
                  })(
                    <Input placeholder="LicensingForm Name" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'name', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Title">
                  {getFieldDecorator('title', {
                    initialValue: title,
                    rules: [{
                      required: true,
                      message: 'Please input title',
                    }],
                  })(
                    <Input placeholder="LicensingForm Title" 
                      onChange={(e) => {
                        this.onInputChange({ name: 'title', value: e.target.value })
                      }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Active">
                  <Checkbox 
                    checked={isActive}
                    onChange={(e) => {
                      this.onInputChange({ name: 'isActive', value: e.target.checked })
                    }}
                    ></Checkbox>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              {/* <Col span={24}>
                <FormItem {...formItemLayout} label="Expired Date">
                  {getFieldDecorator('expiredDate', {
                    initialValue: expiredDate === '' ? null : moment(expiredDate),
                    rules: [{
                      required: true,
                      message: 'Please select expired date',
                    }],
                  })(
                    <DatePicker 
                      allowClear={false}
                      disabledDate={(currentDate) => {
                        return currentDate.isBefore(moment());
                      }}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'expiredDate', value: dateString });
                      }} />
                    )
                  }
                </FormItem>
              </Col> */}
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Type">
                  {getFieldDecorator('type', {
                      initialValue: type,
                      rules: [{
                        required: true,
                        message: 'Please choose Type',
                      }],
                  })(
                      <Select 
                        value={type}
                        onChange={this.licensingFormTypeChanged} 
                        placeholder="Please Choose">
                        {LicensingFormTypeOptions}
                      </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            {/* <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Attachment">
                  <Upload
                    name="attachment"
                    multiple={true}
                    showUploadList={true}
                    fileList={fileList}
                    action={licensingFormAttachmentUploadUrl}
                    onChange={this.attachmentHandleChange}
                    onRemove={this.attachmentHandleRemove}
                  >
                    <Button>
                      <Icon type="upload" /> Template of LicensingForm
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row> */}
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
                <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

const LicensingFormEditDialogForm = Form.create({ name: 'licensingForm_edit_dialog' })(LicensingFormEditDialog);

export default LicensingFormEditDialogForm;