import React, {Component} from 'react';
import './PendingTasksDashboard.css';
import { Button, message, Select } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import PendingTasksGrid from './PendingTasksGrid';

const Option = Select.Option;


const PendingTasksStatusOptions = [];
PendingTasksStatusOptions.push(<Option key={'Pending'}>{'Pending'}</Option>);
PendingTasksStatusOptions.push(<Option key={'Ignored'}>{'Ignored'}</Option>);
PendingTasksStatusOptions.push(<Option key={'Finished'}>{'Finished'}</Option>);


class PendingTasksDashboard extends Component {

    componentDidMount() {
        // queryPendingTaskList
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let status = null;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'status') {
                        status = subParamArray[1];
                        break;
                    }
                }
            }
        }
        if(!!status) {
            this.onInputChange({name: 'status', value: status});
        }
        const param = {status};
        this.props.queryPendingTasksList(param);
    }

    componentWillUnmount() {
        this.props.queryPendingTasksNum(); // 进入页面、ignore/deignore时都会重新进行查询，因此只需在退出页面、查询时刷新数量
    }


    componentDidUpdate() {
        const {
            queryPendingTasksListStatus,
            queryPendingTasksListResp,
            ignoreDeignorePendingTasksStatus,
            ignoreDeignorePendingTasksResp,
        } = this.props;

        if(queryPendingTasksListStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryPendingTasksListStatus();
            this.props.queryPendingTasksNum();  // 进入页面、ignore/deignore时都会重新进行查询，因此只需在退出页面、查询时刷新数量
        }
        if(queryPendingTasksListStatus === ACTION_STATUS.ERROR) {
            let err = 'query Pending Tasks failed';
            if(!!queryPendingTasksListResp && !!queryPendingTasksListResp.respMessage) {
                err = queryPendingTasksListResp.respMessage;
            }
            message.error(err);
            this.props.initQueryPendingTasksListStatus();
        }

        if(ignoreDeignorePendingTasksStatus === ACTION_STATUS.SUCCESS) {
            this.props.initIignoreDeignorePendingTasksStatus();
            this.refreshBtnOnClicked();
        }
        if(ignoreDeignorePendingTasksStatus === ACTION_STATUS.ERROR) {
            let err = 'ignore/de-ignore task failed';
            if(!!ignoreDeignorePendingTasksResp && !!ignoreDeignorePendingTasksResp.respMessage) {
                err = ignoreDeignorePendingTasksResp.respMessage;
            }
            message.error(err);
            this.props.initIignoreDeignorePendingTasksStatus();
            this.refreshBtnOnClicked();
        }

    }


    onInputChange(params) {
        this.props.queryPendingTasksInputChange(params);
    }

    refreshBtnOnClicked = () => {
        const {
            status,
        } = this.props.queryPendingTasksInputParams;
        const param = {status};
        this.props.queryPendingTasksList(param);
    }


    render() {
        const {
            queryPendingTasksInputParams,
            queryPendingTasksListStatus,
        } = this.props;
        const {
            status,
        } = queryPendingTasksInputParams;

        const refreshBtnIsLoading = queryPendingTasksListStatus === ACTION_STATUS.LOGINING;

        return (
            <div className="PendingTasksWrapper">
                <div className='PendingTasksOperationBar'>
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'status', value: val })
                        }} 
                        value={status}
                        placeholder='Status'>
                            {PendingTasksStatusOptions}
                    </Select>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                </div>
                <div className='PendingTasksGrid'><PendingTasksGrid {...this.props} /></div>
            </div>
        );
    }

}

export default PendingTasksDashboard;
