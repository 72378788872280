import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import GridColumnMap from '../GridColumnMap';

import {
  // Generate Fund Level Report
  START_GENERATE_FUND_LEVEL_REPORT,
  GENERATE_FUND_LEVEL_REPORT_SUCCESS,
  GENERATE_FUND_LEVEL_REPORT_FAILURE,
  INIT_GENERATE_FUND_LEVEL_REPORT_ACTION_STATUS,

  // Load Fund Level Report
  START_LOAD_FUND_LEVEL_REPORT,
  LOAD_FUND_LEVEL_REPORT_SUCCESS,
  LOAD_FUND_LEVEL_REPORT_FAILURE,
  INIT_LOAD_FUND_LEVEL_REPORT_ACTION_STATUS,

  // Load Fund Level Report
  START_LIST_FUND_POS_GROUP_INFO,
  LIST_FUND_POS_GROUP_INFO_SUCCESS,
  LIST_FUND_POS_GROUP_INFO_FAILURE,
  INIT_LIST_FUND_POS_GROUP_INFO_ACTION_STATUS,

  // Filter Long More Than 2% Fund Level Report
  FILTER_LONG_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT,

  // Filter Long More Than 5% Fund Level Report
  FILTER_LONG_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT,

  // Filter Short More Than 2% Fund Level Report
  FILTER_SHORT_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT,

  // Filter Short More Than 5% Fund Level Report
  FILTER_SHORT_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT,

  // Show All Fund Level Report
  SHOW_ALL_FUND_LEVEL_REPORT,

  // add grid to container
  FUND_POSITION_GROUP_INFO_GRID_TO_PROPS,
  FUND_LEVEL_REPORT_GRID_TO_PROPS,

} from './fundLevelReportConstants';

const initialState = {
  // Grdi Columns
  gridType: 1,
  fundLevelReportGridColumns: GridColumnMap.fundLevelReportGridColumns,
  fundPositionGroupInfoGridColumns: GridColumnMap.fundPositionGroupInfoGridColumns,

  // Fund Level Report
  fundLevelReportArray: [],
  filterFundLevelReportArray: [],

  // Load Fund Level Report Action Status
  loadFundLevelReportActionStatus: ACTION_STATUS.READY,
  loadFundLevelReportErrMsg: '',

  // Generate Fund Level Report Action Status
  generateFundLevelReportActionStatus: ACTION_STATUS.READY,
  generateFundLevelReportErrMsg: '',

} 

// Generate Fund Level Report
function startGenerateFundLevelReport(state) {
  return {
    ...state,
    generateFundLevelReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function generateFundLevelReportSuccess(state, resp) {
  return {
    ...state,
    generateFundLevelReportActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function generateFundLevelReportFailure(state, err) {
  return {
    ...state,
    generateFundLevelReportActionStatus: ACTION_STATUS.ERROR,
    generateFundLevelReportErrMsg: err.respMessage,
  };
}

function initGenerateFundLevelReportActionStatus(state) {
  return {
    ...state,
    generateFundLevelReportActionStatus: ACTION_STATUS.READY,
  };
}

// Load Fund Level Report
function startLoadFundLevelReport(state) {
  return {
    ...state,
    loadFundLevelReportActionStatus: ACTION_STATUS.LOGINING,
    gridType: 1,
  };
}

function loadFundLevelReportSuccess(state, resp) {
  const fundLevelReportArray = resp.data;
  const filterFundLevelReportArray = fundLevelReportArray;

  return {
    ...state,
    loadFundLevelReportActionStatus: ACTION_STATUS.SUCCESS,
    fundLevelReportArray,
    filterFundLevelReportArray,
  };
}

function loadFundLevelReportFailure(state, err) {
  return {
    ...state,
    loadFundLevelReportActionStatus: ACTION_STATUS.ERROR,
    loadFundLevelReportErrMsg: err.respMessage,
  };
}

function initLoadFundLevelReportActionStatus(state) {
  return {
    ...state,
    loadFundLevelReportActionStatus: ACTION_STATUS.READY,
  };
}


// Filter Long More Than 2% Fund Level Report
function filterLongMoreThan2PercentFundLevelReport(state) {
  const fundLevelReportArray = state.fundLevelReportArray;
  const filterFundLevelReportArray = fundLevelReportArray.filter(fundLevelReport => fundLevelReport.longMoreThan2Percent);

  return {
    ...state,
    filterFundLevelReportArray,
  };
}

// Filter Long More Than 5% Fund Level Report
function filterLongMoreThan5PercentFundLevelReport(state) {
  const fundLevelReportArray = state.fundLevelReportArray;
  const filterFundLevelReportArray = fundLevelReportArray.filter(fundLevelReport => fundLevelReport.longMoreThan5Percent);

  return {
    ...state,
    filterFundLevelReportArray,
  };
}

// Filter Short More Than 2% Fund Level Report
function filterShortMoreThan2PercentFundLevelReport(state) {
  const fundLevelReportArray = state.fundLevelReportArray;
  const filterFundLevelReportArray = fundLevelReportArray.filter(fundLevelReport => fundLevelReport.shortMoreThan2Percent);

  return {
    ...state,
    filterFundLevelReportArray,
  };
}

// Filter Short More Than 5% Fund Level Report
function filterShortMoreThan5PercentFundLevelReport(state) {
  const fundLevelReportArray = state.fundLevelReportArray;
  const filterFundLevelReportArray = fundLevelReportArray.filter(fundLevelReport => fundLevelReport.shortMoreThan5Percent);

  return {
    ...state,
    filterFundLevelReportArray,
  };
}

// Show All Fund Level Report
export function showAllFundLevelReport(state) {
  const fundLevelReportArray = state.fundLevelReportArray;

  return {
    ...state,
    filterFundLevelReportArray: fundLevelReportArray,
  };
}


// Load Fund Level Report
function startListFundPosGroupInfo(state) {
  return {
    ...state,
    listFundPosGroupInfoActionStatus: ACTION_STATUS.LOGINING,
    gridType: 2,
  };
}
function listFundPosGroupInfoSuccess(state, resp) {
  return {
    ...state,
    listFundPosGroupInfoActionStatus: ACTION_STATUS.SUCCESS,
    listFundPosGroupInfoActionResp: resp,
  };
}
function listFundPosGroupInfoFailure(state, err) {
  return {
    ...state,
    listFundPosGroupInfoActionStatus: ACTION_STATUS.ERROR,
    listFundPosGroupInfoActionResp: err,
  };
}
function initListFundPosGroupInfoActionStatus(state) {
  return {
    ...state,
    listFundPosGroupInfoActionStatus: ACTION_STATUS.READY,
  };
}



// add grid to container
function fundPositionGroupInfoGridToProps(state, grid) {
  return {
      ...state,
      fundPositionGroupInfoGrid: grid,
  };
}
function fundLevelReportGridToProps(state, grid) {
  return {
      ...state,
      fundLevelReportGrid: grid,
  };
}



export default createReducer(initialState, {
  // Generate Fund Level Report
  [START_GENERATE_FUND_LEVEL_REPORT]: startGenerateFundLevelReport,
  [GENERATE_FUND_LEVEL_REPORT_SUCCESS]: generateFundLevelReportSuccess,
  [GENERATE_FUND_LEVEL_REPORT_FAILURE]: generateFundLevelReportFailure,
  [INIT_GENERATE_FUND_LEVEL_REPORT_ACTION_STATUS]: initGenerateFundLevelReportActionStatus,

  // Load Fund Level Report
  [START_LOAD_FUND_LEVEL_REPORT]: startLoadFundLevelReport,
  [LOAD_FUND_LEVEL_REPORT_SUCCESS]: loadFundLevelReportSuccess,
  [LOAD_FUND_LEVEL_REPORT_FAILURE]: loadFundLevelReportFailure,
  [INIT_LOAD_FUND_LEVEL_REPORT_ACTION_STATUS]: initLoadFundLevelReportActionStatus,

  // Filter Long More Than 2% Fund Level Report
  [FILTER_LONG_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT]: filterLongMoreThan2PercentFundLevelReport,

  // Filter Long More Than 5% Fund Level Report
  [FILTER_LONG_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT]: filterLongMoreThan5PercentFundLevelReport,

  // Filter Short More Than 2% Fund Level Report
  [FILTER_SHORT_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT]: filterShortMoreThan2PercentFundLevelReport,

  // Filter Short More Than 5% Fund Level Report
  [FILTER_SHORT_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT]: filterShortMoreThan5PercentFundLevelReport,

  // Show All Fund Level Report
  [SHOW_ALL_FUND_LEVEL_REPORT]: showAllFundLevelReport,


  // Load Fund Level Report
  [START_LIST_FUND_POS_GROUP_INFO]: startListFundPosGroupInfo,
  [LIST_FUND_POS_GROUP_INFO_SUCCESS]: listFundPosGroupInfoSuccess,
  [LIST_FUND_POS_GROUP_INFO_FAILURE]: listFundPosGroupInfoFailure,
  [INIT_LIST_FUND_POS_GROUP_INFO_ACTION_STATUS]: initListFundPosGroupInfoActionStatus,

  // add grid to container
  [FUND_POSITION_GROUP_INFO_GRID_TO_PROPS]: fundPositionGroupInfoGridToProps,
  [FUND_LEVEL_REPORT_GRID_TO_PROPS]: fundLevelReportGridToProps,

});