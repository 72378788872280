import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { monthlyReportGridColumns } from '../GridColumnMap'
import MonthlyReportAttachRenderer from './MonthlyReportAttachRenderer'
import MonthlyReviewDetailPanel from './MonthlyReviewDetailPanel'

class MonthlyReportGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: monthlyReportGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "single",
            detailRowHeight: '500',
            context: { componentParent: this },
            frameworkComponents: {
                monthlyReportAttachRenderer: MonthlyReportAttachRenderer,
                myDetailCellRenderer: MonthlyReviewDetailPanel,
            },
            detailCellRenderer: 'myDetailCellRenderer',
        };
    }

    getRowNodeId = data => {
        return data.id;
    };

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        this.gridApi.sizeColumnsToFit();
        this.onRowDataChanged();
    };

    onRowDataChanged = () => {
        const nodes = this.gridApi.getRenderedNodes();
        if (this.gridApi && nodes && nodes.length>1) {
            this.gridApi.selectNode(nodes[0], true);
        }
    };

    onRowClickedHandle = () => {
        const selectedRows = this.gridApi.getSelectedRows();
        if(selectedRows && selectedRows.length > 0){
            const item = selectedRows[0];
            this.props.loadMonthlyReportDetail(item);
        }
    };

    render() {

        const {
            // query
            monthlyReportList,
        } = this.props;
        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    rowData={monthlyReportList}
                    defaultColDef={this.state.defaultColDef}
                    rowSelection={this.state.rowSelection}
                    suppressRowClickSelection={true}
                    suppressAggFuncInHeader={true}
                    enableSorting={true}
                    enableFilter={true}
                    animateRows={true}
                    deltaRowDataMode={false}
                    enableColResize={true}
                    enableRangeSelection={true}
                    getRowNodeId={this.getRowNodeId}
                    context={this.state.context}
                    statusBar={this.state.statusBar}
                    onGridReady={this.onGridReady}
                    frameworkComponents={this.state.frameworkComponents}
                    detailCellRenderer={this.state.detailCellRenderer}
                    onSelectionChanged={this.onRowClickedHandle}
                    masterDetail={true}
                    // event
                />
            </div>
        );
    }
}

export default MonthlyReportGrid;
