import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, DatePicker } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../../utils/localStorageClient';
import { SIDE_TYPE_MAP } from '../../../../../utils/SideTypeUtil';
import moment from 'moment';

const FormItem = Form.Item;
const confirm = Modal.confirm;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

// Side Type
const SideTypeOptions = [];
for (let i = 0; i < SIDE_TYPE_MAP.length; i++) {
  SideTypeOptions.push(<Option key={SIDE_TYPE_MAP[i].code}>{SIDE_TYPE_MAP[i].label}</Option>);
}

class BannedTradeRequestEditDialog extends Component {

  componentDidUpdate () {
    const {
      updateBannedTradeRequestActionStatus,
    } = this.props;

    // ======================== Update ========================
    // Update Banned Trade 成功
    if (updateBannedTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Banned Trade Request is updated successfully!');
      this.props.loadBannedTradeRequest();
      this.props.initUpdateBannedTradeRequestActionStatus();
      
      this.props.resetBannedTradeRequestEditModalDialog();
    }
      
    // Update Banned Trade 失败
    if (updateBannedTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Banned Trade Request is updated failed!');
      this.props.initUpdateBannedTradeRequestActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.bannedTradeRequestEditChangeInput({name, value});
  }

  bbgTickerOnChange = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
    }

    this.props.bannedTradeRequestEditChangeInput({ name: 'bbgTicker', value });
    this.props.bbgTickerSelectChanged(value);
  }


  lastTimeoutID = null;
  bbgTickerOnSearch = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
      return;
    }
    if(value.length < 2) {
      return;
    }
    // 输入完毕等待0.5秒后再查询
    // 无此控制会有以下bug：
    // 当输入 2007 时，会分别根据 20、200、2007进行查询
    // 根据2007的查询结果数据量少，最先返回
    // 根据20的查询结果数据量大，最后返回
    // 因此最终展示的，反而是根据20的查询结果，尽管输入的是2007
    if(!!this.lastTimeoutID) {
      clearTimeout(this.lastTimeoutID);
    }
    this.lastTimeoutID = setTimeout(() => {
      this.props.loadBbgTickers(value);
    }, 500);
  }

  submitButtonOnClicked = () => {
    const {
      bannedTradeRequestEditControlModal,
    } = this.props;

    const {
      isAddMode,
    } = bannedTradeRequestEditControlModal;

    const {
      id,
      securityId,
      bbgTicker,
      secName,
      counterparty,
      responsiblePM,
      remark,
      reason,
      feedback,
    } = bannedTradeRequestEditControlModal.fields;

    const employee = localStorageClient.getUserName();
    if (!!!employee) {
      let that = this;
      confirm({
        title: 'Login status is invalid, please login again!',
        content: 'Do you need to log out to the login page',
        onOk() {
          that.props.history.push({
            pathname: '/login',
          });
        },
        onCancel() {},
      });

      return;
    }

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          console.info('success');

          this.props.updateBannedTradeRequest({
            id,
            securityId,
            bbgTicker,
            secName,
            counterparty,
            responsiblePM,
            remark,
            reason,
            feedback,
          });
        }
      },
    );
  }

  render() {
    const {
      bannedTradeRequestEditControlModal,
      updateBannedTradeRequestActionStatus,

      // Bbg Ticker
      bbgTickerOptions,
      loadBbgTickersActionStatus,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = bannedTradeRequestEditControlModal;

    const { getFieldDecorator } = this.props.form;

    const {
      bbgTicker,
      secName,
      counterparty,
      responsiblePM,
      remark,
      reason,
      feedback,
    } = bannedTradeRequestEditControlModal.fields;

    let modalTitle = 'Edit Banned Trade Request';
    if (isAddMode) {
      modalTitle = 'Add Banned Trade Request';
    }

    const isLoading = updateBannedTradeRequestActionStatus === ACTION_STATUS.LOGINING;

    const bbgTickersSelectIsLoading = loadBbgTickersActionStatus === ACTION_STATUS.LOGINING;
        
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetBannedTradeRequestEditModalDialog()}
          destroyOnClose={true}
          okButtonProps={{ disabled: secName === '' }}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Security Name">
                    <Input
                      placeholder="" 
                      disabled
                      value={secName}
                      onChange={(e) => {
                          this.onInputChange({ name: 'secName', value: e.target.value })
                        }}
                    />
                  </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Bbg Ticker">
                  <Select 
                    disabled
                    allowClear
                    showSearch
                    value={bbgTicker}
                    loading={bbgTickersSelectIsLoading}
                    onSearch={this.bbgTickerOnSearch}
                    onChange={this.bbgTickerOnChange} 
                    placeholder="Please Choose"
                    >
                    {bbgTickerOptions}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Counterparty">
                  <Input 
                    value={counterparty}
                    placeholder="" 
                    onChange={(e) => {
                        this.onInputChange({ name: 'counterparty', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Responsible PM">
                  <Input
                    value={responsiblePM}
                    placeholder="" 
                    onChange={(e) => {
                        this.onInputChange({ name: 'responsiblePM', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Reason">
                  <TextArea 
                    rows={4} 
                    disabled
                    value={reason} 
                    onChange={(e) => {
                      this.onInputChange({ name: 'reason', value: e.target.value })
                    }}/>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Remark">
                  <TextArea 
                    rows={4} 
                    value={remark} 
                    onChange={(e) => {
                      this.onInputChange({ name: 'remark', value: e.target.value })
                    }}/>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Feedback">
                  <TextArea 
                    rows={4} 
                    value={feedback} 
                    onChange={(e) => {
                      this.onInputChange({ name: 'feedback', value: e.target.value })
                    }}/>
                </FormItem>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ respErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const BannedTradeRequestEditDialogForm = Form.create({ name: 'banned_trade_request_edit_dialog' })(BannedTradeRequestEditDialog);

export default BannedTradeRequestEditDialogForm;