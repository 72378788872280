import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_WORKING_MANAGEMENT_INFO,
    LOAD_WORKING_MANAGEMENT_INFO_SUCCESS,
    LOAD_WORKING_MANAGEMENT_INFO_FAILURE,
    INIT_LOAD_WORKING_MANAGEMENT_INFO_ACTION_STATUS,
    // Select
    SELECT_WORKING_MANAGEMENT,
    // add or edit
    OPEN_ADD_OR_EDIT_WORKING_MANAGEMENT_MODAL,
    ADD_OR_EDIT_WORKING_MANAGEMENT_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_WORKING_MANAGEMENT_MODAL,
    START_SUBMIT_SAVE_WORKING_MANAGEMENT,
    SUBMIT_SAVE_WORKING_MANAGEMENT_SUCCESS,
    SUBMIT_SAVE_WORKING_MANAGEMENT_FAILURE,
    INIT_SUBMIT_SAVE_WORKING_MANAGEMENT_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL,
    ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL,
    START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE,
    INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT,
    BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_ACTION_STATUS,
    // finish
    SELECT_WORKING_MANAGEMENT_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_WORKING_MANAGEMENT_TASK,
    FINISH_WORKING_MANAGEMENT_TASK_SUCCESS,
    FINISH_WORKING_MANAGEMENT_TASK_FAILURE,
    INIT_FINISH_WORKING_MANAGEMENT_TASK_ACTION_STATUS,

    
} from './WorkManagementConstants';

import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  } from '../../../features/home/homeConstants';


const Option = Select.Option;

const categoryOptions = [];
categoryOptions.push(<Option key={'Monitoring'}>{'Monitoring'}</Option>);
categoryOptions.push(<Option key={'Broker A/C'}>{'Broker A/C'}</Option>);
categoryOptions.push(<Option key={'Licensing'}>{'Licensing'}</Option>);
categoryOptions.push(<Option key={'CoSec'}>{'CoSec'}</Option>);
categoryOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
categoryOptions.push(<Option key={'Reporting'}>{'Reporting'}</Option>);
categoryOptions.push(<Option key={'Visa'}>{'Visa'}</Option>);
categoryOptions.push(<Option key={'CMS'}>{'CMS'}</Option>);
categoryOptions.push(<Option key={'Legal'}>{'Legal'}</Option>);
categoryOptions.push(<Option key={'Corporate'}>{'Corporate'}</Option>);
categoryOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const fundCoOptions = [];
fundCoOptions.push(<Option key={'Pinpoint Group'}>{'Pinpoint Group'}</Option>);
fundCoOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
fundCoOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundCoOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundCoOptions.push(<Option key={'上海保银'}>{'上海保银'}</Option>);
fundCoOptions.push(<Option key={'海南保银'}>{'海南保银'}</Option>);
fundCoOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundCoOptions.push(<Option key={'PIAS (India)'}>{'PIAS (India)'}</Option>);
fundCoOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
fundCoOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
fundCoOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
fundCoOptions.push(<Option key={'COP'}>{'COP'}</Option>);
fundCoOptions.push(<Option key={'EJ'}>{'EJ'}</Option>);
fundCoOptions.push(<Option key={'Wealth Ocean'}>{'Wealth Ocean'}</Option>);
fundCoOptions.push(<Option key={'Pinpoint Charity'}>{'Pinpoint Charity'}</Option>);
fundCoOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
fundCoOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
fundCoOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundCoOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundCoOptions.push(<Option key={'SHLH'}>{'SHLH'}</Option>);
fundCoOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundCoOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundCoOptions.push(<Option key={'MetaPoint'}>{'MetaPoint'}</Option>);
fundCoOptions.push(<Option key={'投资顾问 (上海)'}>{'投资顾问 (上海)'}</Option>);
fundCoOptions.push(<Option key={'私募管理 (上海)'}>{'私募管理 (上海)'}</Option>);
fundCoOptions.push(<Option key={'私募管理 (海南)'}>{'私募管理 (海南)'}</Option>);
fundCoOptions.push(<Option key={'资产管理 (上海)'}>{'资产管理 (上海)'}</Option>);
fundCoOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundCoOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const statusOptions = [];
statusOptions.push(<Option key={'Not started'}>{'Not started'}</Option>);
statusOptions.push(<Option key={'In progress'}>{'In progress'}</Option>);
statusOptions.push(<Option key={'Finished'}>{'Finished'}</Option>);
statusOptions.push(<Option key={'Waiting for someone else'}>{'Waiting for someone else'}</Option>);
statusOptions.push(<Option key={'Deferred'}>{'Deferred'}</Option>);

const importanceOptions = [];
importanceOptions.push(<Option key={'Low'}>{'Low'}</Option>);
importanceOptions.push(<Option key={'Normal'}>{'Normal'}</Option>);
importanceOptions.push(<Option key={'High'}>{'High'}</Option>);




const locationOptions = [];
locationOptions.push(<Option key={'All'}>{'All'}</Option>);
locationOptions.push(<Option key={'Cayman'}>{'Cayman'}</Option>);
locationOptions.push(<Option key={'Hong Kong'}>{'Hong Kong'}</Option>);
locationOptions.push(<Option key={'India'}>{'India'}</Option>);
locationOptions.push(<Option key={'Japan'}>{'Japan'}</Option>);
locationOptions.push(<Option key={'Shanghai'}>{'Shanghai'}</Option>);
locationOptions.push(<Option key={'Singapore'}>{'Singapore'}</Option>);
locationOptions.push(<Option key={'USA'}>{'USA'}</Option>);

const fundCompanyOptions = [];
fundCompanyOptions.push(<Option key={'All'}>{'All'}</Option>);
fundCompanyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundCompanyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundCompanyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundCompanyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundCompanyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
fundCompanyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
fundCompanyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
fundCompanyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundCompanyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundCompanyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundCompanyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundCompanyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundCompanyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundCompanyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);

const frequencyOptions = [];
frequencyOptions.push(<Option key={'EveryWorkday'}>{'EveryWorkday'}</Option>);
frequencyOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);
frequencyOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);
frequencyOptions.push(<Option key={'Quarterly'}>{'Quarterly'}</Option>);
frequencyOptions.push(<Option key={'SemiAnnually'}>{'SemiAnnually'}</Option>);
frequencyOptions.push(<Option key={'Annually'}>{'Annually'}</Option>);
frequencyOptions.push(<Option key={'Every3Years'}>{'Every3Years'}</Option>);
frequencyOptions.push(<Option key={'Incidental'}>{'Incidental'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'Sun'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'Mon'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'Tue'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'Wed'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'Thu'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'Fri'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'Sat'}>{'Sat'}</Option>);

const monthOptions = [];
monthOptions.push(<Option key={'01'}>{'Jan'}</Option>);
monthOptions.push(<Option key={'02'}>{'Feb'}</Option>);
monthOptions.push(<Option key={'03'}>{'Mar'}</Option>);
monthOptions.push(<Option key={'04'}>{'Apr'}</Option>);
monthOptions.push(<Option key={'05'}>{'May'}</Option>);
monthOptions.push(<Option key={'06'}>{'Jun'}</Option>);
monthOptions.push(<Option key={'07'}>{'Jul'}</Option>);
monthOptions.push(<Option key={'08'}>{'Aug'}</Option>);
monthOptions.push(<Option key={'09'}>{'Sep'}</Option>);
monthOptions.push(<Option key={'10'}>{'Oct'}</Option>);
monthOptions.push(<Option key={'11'}>{'Nov'}</Option>);
monthOptions.push(<Option key={'12'}>{'Dec'}</Option>);

const responsibleDeptOptions = [];
responsibleDeptOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
responsibleDeptOptions.push(<Option key={'BD'}>{'BD'}</Option>);
responsibleDeptOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
responsibleDeptOptions.push(<Option key={'CS'}>{'CS'}</Option>);
responsibleDeptOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
responsibleDeptOptions.push(<Option key={'IT'}>{'IT'}</Option>);
responsibleDeptOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
responsibleDeptOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
responsibleDeptOptions.push(<Option key={'Settlement'}>{'Settlement'}</Option>);


const initialState = {
    locationOptions: locationOptions,
    fundCompanyOptions: fundCompanyOptions,
    frequencyOptions: frequencyOptions,
    weekdayOptions: weekdayOptions,
    monthOptions: monthOptions,
    responsibleDeptOptions: responsibleDeptOptions,
    categoryOptions: categoryOptions,
    fundCoOptions: fundCoOptions,
    statusOptions: statusOptions,
    importanceOptions: importanceOptions,

    workManagementQueryFields: {
        id: undefined,
        category: undefined,
        fundco: undefined,
        owner: undefined,
    },

    selectedWorkManagementArray: [],
    selectedWorkManagementTaskArray: [],

    addOrEditWorkManagementModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            category: undefined,
            fundco: undefined,
            description: undefined,
            owner: undefined,
            startdate: undefined,
            targetdate: undefined,
            enddate: undefined,
            status: undefined,
            importance: undefined,
            remarks: undefined,
            comments: undefined,

        },
    },
    addOrEditWorkManagementSubModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            workid: undefined,
            details: undefined,
            startdate: undefined,
            targetdate: undefined,
            enddate: undefined,
            personlncharge: undefined,
            status: undefined,
            // status: undefined,
            // importance: undefined,
            // remarks: undefined,
            // comments: undefined,

        },
    },
    batchChangeStatusModal: {
        isOpened: false,
        mode: 'PAUSE',
        respErrMsg: '',
        fields: {
            idList: [],
            comments: undefined,
        },
    },

    finishTaskModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: undefined,
            remarks: undefined,
            comments: undefined,
        },
    },

    userAccountEmailOptions: [],

};



// query
function onReportSummaryQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.workManagementQueryFields,
        [name]: value,
    };
    return {
        ...state,
        workManagementQueryFields: upatedFields,
    }
}
function startLoadWorkManagementInfo(state) {
    return {
        ...state,
        loadWorkManagementInfoActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadWorkManagementInfoSuccess(state, resp) {
    return {
        ...state,
        loadWorkManagementInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadWorkManagementInfoResp: resp,
    };
}
function loadWorkManagementInfoFailure(state, err) {
    return {
        ...state,
        loadWorkManagementInfoActionStatus: ACTION_STATUS.ERROR,
        loadWorkManagementInfoResp: err,
    };
}
function initLoadWorkManagementInfoActionStatus(state) {
    return {
        ...state,
        loadWorkManagementInfoActionStatus: ACTION_STATUS.READY,
        selectedWorkManagementArray: [],
        selectedWorkManagementTaskArray: [],
    };
}


// select
function selectWorkManagement(state, array) {
    return {
        ...state,
        selectedWorkManagementArray: array,
    };
}


// add or edit
function openAddOrEditWorkManagementModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditWorkManagementModal: {
                ...state.addOrEditWorkManagementModal,
                isOpened: true,
                mode,
            },
        };

    } else {

        const {
            id,
            category,
            fundco,
            description,
            owner,
            startdate,
            targetdate,
            enddate,
            status,
            expiryDate,
            importance,
            remarks,
            comments,

        } = data;



        return {
            ...state,
            addOrEditWorkManagementModal: {
                ...state.addOrEditWorkManagementModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditWorkManagementModal.fields,
                    id,
                    category,
                    fundco,
                    description,
                    owner,
                    startdate,
                    targetdate,
                    enddate,
                    status,
                    expiryDate,
                    importance,
                    remarks,
                    comments,
                
                }
            },
        }

    }
}
function addOrEditWorkManagementChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditWorkManagementModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditWorkManagementModal: {
          ...state.addOrEditWorkManagementModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSaveWorkManagementModal(state) {
    return {
        ...state,
        addOrEditWorkManagementModal: initialState.addOrEditWorkManagementModal,
    }
}

function startSubmitSaveWorkManagement(state) {
    return {
        ...state,
        addOrEditWorkManagementStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveWorkManagementSuccess(state, resp) {
    return {
        ...state,
        addOrEditWorkManagementStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSaveWorkManagementFailure(state, err) {
    return {
        ...state,
        addOrEditWorkManagementStatus: ACTION_STATUS.ERROR,
        addOrEditWorkManagementModal: {
            ...state.addOrEditWorkManagementModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSaveWorkManagementActionStatus(state) {
    return {
        ...state,
        addOrEditWorkManagementStatus: ACTION_STATUS.READY,
    };
}



//sub、 add or edit
function openAddOrEditWorkManagementSubModal(state, {mode, data,idList}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditWorkManagementSubModal: {
                ...state.addOrEditWorkManagementSubModal,
                isOpened: true,
                mode,
                idList,
            },
        };

    } else {

        const {
            id,
            workid,
            details,
            startdate,
            targetdate,
            enddate,
            personlncharge,
            status,
            remarks,
            comments,
        } = data;



        return {
            ...state,
            addOrEditWorkManagementSubModal: {
                ...state.addOrEditWorkManagementSubModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditWorkManagementSubModal.fields,
                    id,
                    workid,
                    details,
                    startdate,
                    targetdate,
                    enddate,
                    personlncharge,
                    status,
                    remarks,
                    comments,
                
                }
            },
        }

    }
}
function addOrEditWorkManagementSubChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditWorkManagementSubModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditWorkManagementSubModal: {
          ...state.addOrEditWorkManagementSubModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSaveWorkManagementSubModal(state) {
    return {
        ...state,
        addOrEditWorkManagementSubModal: initialState.addOrEditWorkManagementSubModal,
    }
}

function startSubmitSaveWorkManagementSub(state) {
    return {
        ...state,
        addOrEditWorkManagementSubStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveWorkManagementSubSuccess(state, resp) {
    return {
        ...state,
        addOrEditWorkManagementSubStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSaveWorkManagementSubFailure(state, err) {
    return {
        ...state,
        addOrEditWorkManagementSubStatus: ACTION_STATUS.ERROR,
        addOrEditWorkManagementSubModal: {
            ...state.addOrEditWorkManagementSubModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSaveWorkManagementSubActionStatus(state) {
    return {
        ...state,
        addOrEditWorkManagementSubStatus: ACTION_STATUS.READY,
    };
}





















// batch change status
function openBatchChangeStatusModal(state, {mode, idList}) {
    return {
        ...state,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.batchChangeStatusModal.fields,
                idList,
            },
        },
    };
}
function resetBatchChangeStatusModal(state) {
    return {
        ...state,
        batchChangeStatusModal: initialState.batchChangeStatusModal,
    }
}
function batchChangeStatusChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.batchChangeStatusModal.fields,
        [name]: value,
    };
    return {
        ...state,
        batchChangeStatusModal: {
          ...state.batchChangeStatusModal,
          fields: updatedFields,
        },
    };
}

function startBatchChangeStatusForWorkManagement(state) {
    return {
        ...state,
        batchChangeStatusForWorkManagementStatus: ACTION_STATUS.LOGINING,
    };
}
function batchChangeStatusForWorkManagementSuccess(state, resp) {
    return {
        ...state,
        batchChangeStatusForWorkManagementStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function batchChangeStatusForWorkManagementFailure(state, err) {
    return {
        ...state,
        batchChangeStatusForWorkManagementStatus: ACTION_STATUS.ERROR,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initBatchChangeStatusForWorkManagementActionStatus(state) {
    return {
        ...state,
        batchChangeStatusForWorkManagementStatus: ACTION_STATUS.READY,
    };
}


// finish
function selectWorkManagementTask(state, array) {
    return {
        ...state,
        selectedWorkManagementTaskArray: array,
    };
}
function openFinishTaskModal(state, task) {
    return {
        ...state,
        finishTaskModal: {
            ...state.finishTaskModal,
            isOpened: true,
            fields: {
                ...state.finishTaskModal.fields,
                id: task.id,
                remarks: task.remarks,
            },
        },
    };
}
function resetFinishTaskModal(state) {
    return {
        ...state,
        finishTaskModal: initialState.finishTaskModal,
    }; 
}
function finishTaskChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.finishTaskModal.fields,
        [name]: value,
    };
    return {
        ...state,
        finishTaskModal: {
          ...state.finishTaskModal,
          fields: updatedFields,
        },
    };
}

function startFinishWorkManagementTask(state) {
    return {
        ...state,
        finishWorkManagementTaskStatus: ACTION_STATUS.LOGINING,
    };
}
function finishWorkManagementTaskSuccess(state, resp) {
    return {
        ...state,
        finishWorkManagementTaskStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function finishWorkManagementTaskFailure(state, err) {
    return {
        ...state,
        finishWorkManagementTaskStatus: ACTION_STATUS.ERROR,
        finishTaskModal: {
            ...state.finishTaskModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initFinishWorkManagementTaskActionStatus(state) {
    return {
        ...state,
        finishWorkManagementTaskStatus: ACTION_STATUS.READY,
    };
}


// user account
function loadUserAccountsSuccess(state, resp) {
      const userAccountArray = resp.data;
      const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
    
      return {
        ...state,
        userAccountArray,
        userAccountOptions,
      };
}


export default createReducer(initialState, {
    // query
    [ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE]: onReportSummaryQueryInputChange,
    [START_LOAD_WORKING_MANAGEMENT_INFO]: startLoadWorkManagementInfo,
    [LOAD_WORKING_MANAGEMENT_INFO_SUCCESS]: loadWorkManagementInfoSuccess,
    [LOAD_WORKING_MANAGEMENT_INFO_FAILURE]: loadWorkManagementInfoFailure,
    [INIT_LOAD_WORKING_MANAGEMENT_INFO_ACTION_STATUS]: initLoadWorkManagementInfoActionStatus,
    // select
    [SELECT_WORKING_MANAGEMENT]: selectWorkManagement,
    // add or edit
    [OPEN_ADD_OR_EDIT_WORKING_MANAGEMENT_MODAL]: openAddOrEditWorkManagementModal,
    [ADD_OR_EDIT_WORKING_MANAGEMENT_CHANGE_INPUT]: addOrEditWorkManagementChangeInput,
    [RESET_SUBMIT_SAVE_WORKING_MANAGEMENT_MODAL]: resetSubmitSaveWorkManagementModal,
    [START_SUBMIT_SAVE_WORKING_MANAGEMENT]: startSubmitSaveWorkManagement,
    [SUBMIT_SAVE_WORKING_MANAGEMENT_SUCCESS]: submitSaveWorkManagementSuccess,
    [SUBMIT_SAVE_WORKING_MANAGEMENT_FAILURE]: submitSaveWorkManagementFailure,
    [INIT_SUBMIT_SAVE_WORKING_MANAGEMENT_ACTION_STATUS]: initSubmitSaveWorkManagementActionStatus,


    //SUB add or edit
    [OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL]: openAddOrEditWorkManagementSubModal,
    [ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT]: addOrEditWorkManagementSubChangeInput,
    [RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL]: resetSubmitSaveWorkManagementSubModal,
    [START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB]: startSubmitSaveWorkManagementSub,
    [SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS]: submitSaveWorkManagementSubSuccess,
    [SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE]: submitSaveWorkManagementSubFailure,
    [INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS]: initSubmitSaveWorkManagementSubActionStatus,





    // batch change status
    [OPEN_BATCH_CHANGE_STATUS_MODAL]: openBatchChangeStatusModal,
    [RESET_BATCH_CHANGE_STATUS_MODAL]: resetBatchChangeStatusModal,
    [BATCH_CHANGE_STATUS_CHANGE_INPUT]: batchChangeStatusChangeInput,
    [START_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT]: startBatchChangeStatusForWorkManagement,
    [BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_SUCCESS]: batchChangeStatusForWorkManagementSuccess,
    [BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_FAILURE]: batchChangeStatusForWorkManagementFailure,
    [INIT_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_ACTION_STATUS]: initBatchChangeStatusForWorkManagementActionStatus,
    // finish
    [SELECT_WORKING_MANAGEMENT_TASK]: selectWorkManagementTask,
    [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,
    [RESET_FINISH_TASK_MODAL]: resetFinishTaskModal,
    [FINISH_TASK_CHANGE_INPUT]: finishTaskChangeInput,
    [START_FINISH_WORKING_MANAGEMENT_TASK]: startFinishWorkManagementTask,
    [FINISH_WORKING_MANAGEMENT_TASK_SUCCESS]: finishWorkManagementTaskSuccess,
    [FINISH_WORKING_MANAGEMENT_TASK_FAILURE]: finishWorkManagementTaskFailure,
    [INIT_FINISH_WORKING_MANAGEMENT_TASK_ACTION_STATUS]: initFinishWorkManagementTaskActionStatus,
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

        // Load User Account
        [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

});

