import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PrivateRoute from '../../views/components/PrivateRoute';

import './App.css';
import 'antd/dist/antd.css';

// auth
import LoginContainer from '../../views/features/auth/containers/LoginContainer';

import HomeContainer from '../../views/features/home/containers/HomeContainer';
import QuestionnairePreviewContainer from '../../views/features/questionnaire/containers/QuestionnairePreviewContainer';
import QuestionnaireAnswerContainer from '../../views/features/questionnaire/containers/QuestionnaireAnswerContainer';
// import AccountHoldingReportContainer from '../../views/features/accountHoldingReport/containers/AccountHoldingReportContainer';
import QuestionnaireAnswerReviewContainer from '../../views/features/questionnaire/containers/QuestionnaireAnswerReviewContainer';
import MeetingAndTrainingAnswerContainer from '../../views/features/questionnaire/containers/MeetingAndTrainingAnswerContainer';

import LicensingPersonalAnswerContainer from '../../views/features/questionnaire/containers/LicensingPersonalAnswerContainer';

class App extends Component {
  render() {
    return (
      <div className="box">
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <PrivateRoute path="/home" component={HomeContainer} isLoggedIn= {true}/>
          <Route path="/questionnaire-preview/:id" component={QuestionnairePreviewContainer} />
          <Route path="/questionnaire-answer" component={QuestionnaireAnswerContainer} />
          <Route exact path="/" render={() => <Redirect to="/home/pending-tasks/pending-tasks?status=Pending" />} />
          {/* <Route path="/personal-account-report" component={AccountHoldingReportContainer} /> */}
          <Route path="/questionnaire-answer-review/:guid" component={QuestionnaireAnswerReviewContainer} />
          <Route path="/meetingAndTraining-answer" component={MeetingAndTrainingAnswerContainer} />
          <Route path="/licensingPersonal-answer" component={LicensingPersonalAnswerContainer} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
