import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Upload, Icon, Select, Button, Tooltip, AutoComplete } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};

const regiStatusOptions = [];
regiStatusOptions.push(<Option key={'Registered'}>{'Registered'}</Option>);
regiStatusOptions.push(<Option key={'Unregistered'}>{'Unregistered'}</Option>);
regiStatusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);
regiStatusOptions.push(<Option key={'Deregistered'}>{'Deregistered'}</Option>);

const examStatusOptions = [];
examStatusOptions.push(<Option key={'Outstanding'}>{'Outstanding'}</Option>);
examStatusOptions.push(<Option key={'Completed'}>{'Completed'}</Option>);
examStatusOptions.push(<Option key={'NA'}>{'NA'}</Option>);


class AddOrEditLicenseModalDialog extends Component {

    onInputChange = ({name, value}) => {
        this.props.addOrEditLicenseChangeInput({name, value});
    }


    attachmentHandleChange = (info) => {
        let fileList = [...info.fileList]; 
        fileList = fileList.slice(-1);
        this.props.addOrEditLicenseChangeInput({name: 'latestUploadFileList', value: fileList});
        if(!!fileList && fileList.length > 0) {
            const file = fileList[0];
            if (file.response) {
                const filename = file.response.data.fileNameWithTimeStamp;
                this.props.addOrEditLicenseChangeInput({name: 'latestUpload', value: filename});
            }
        }
    }


    submitSaveLicenseModal = (notify) => {
        const {
            addOrEditLicenseModal,
        } = this.props;
        const {
            mode,
        } = addOrEditLicenseModal;
        const {
            guid,
            employee,
            licenseBasicInfo,
            licenseNo,
            regiStatus,
            licensedDate,
            examStatus,
            examDeadline,
            examLicenseInfo,
            latestUpload,
            historyUpload,
            remarks,
            feedback,
            comments,
        } = addOrEditLicenseModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                if(notify) {
                    Modal.confirm({
                        title: 'Are you sure to submit the changes, and notify the employee by email?',
                        onOk: () => {
                            this.props.addOrEditLicenseEmpRequireRcd({
                                guid,
                                employee,
                                licenseBasicInfo,
                                licenseNo,
                                regiStatus,
                                licensedDate,
                                examStatus,
                                examDeadline,
                                examLicenseInfo,
                                latestUpload,
                                historyUpload,
                                remarks,
                                feedback,
                                comments,
                                notify,
                                mode,
                                source: 'Admin',
                            });
                        }
                    });
                } else {
                    this.props.addOrEditLicenseEmpRequireRcd({
                        guid,
                        employee,
                        licenseBasicInfo,
                        licenseNo,
                        regiStatus,
                        licensedDate,
                        examStatus,
                        examDeadline,
                        examLicenseInfo,
                        latestUpload,
                        historyUpload,
                        remarks,
                        feedback,
                        comments,
                        notify,
                        mode,
                        source: 'Admin',
                    });
                }
            }
        });
    }


    componentDidUpdate() {
        const {
            addOrEditLicenseModal,
            addOrEditLicenseEmpRequiredRcdStatus,
        } = this.props;

        if(addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.SUCCESS) {
            let opMode = addOrEditLicenseModal.mode === 'ADD' ? 'Add' : 'Edit';
            let msg = opMode + ' License Info succeeded';
            message.success(msg);
            this.props.initAddOrEditLicenseEmpRequireRcdActionStatus();
            this.props.resetSubmitSaveLicenseModal();
            const {
                employee,
            } = this.props.licensingQuerySearchFields;
            this.props.loadLicensingInfo({employee, source:'Admin'});
            this.props.queryPendingTasksNum();  // 新增、修改成功时，要刷新PendingTasks任务数量
        }
        if(addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditLicenseEmpRequireRcdActionStatus();
        }

    }



    // lastTimeoutID = null;
    // fuzzySearchEmployee = (value) => {
    //     if(!value) {
    //         this.props.clearEmployeeNameList('personalTrade4AddOrEdit');
    //     }
    //     if(value.length < 2) {
    //         return;  // 长度过短不查询
    //     }
    //     // 输入完毕等待0.5秒后再查询
    //     if(!!this.lastTimeoutID) {
    //         clearTimeout(this.lastTimeoutID);
    //     }
    //     this.lastTimeoutID = setTimeout(() => {
    //         this.props.fuzzySearchEmployee('personalTrade4AddOrEdit', value);
    //     }, 500);
    // }



    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditLicenseModal,
            userAccountOptions,
            licenseBasicInfoOptions,
            licensingExamCPTProofUploadUrl,
            addOrEditLicenseEmpRequiredRcdStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditLicenseModal;

        const {
            employee,
            licenseBasicInfo,
            licenseNo,
            regiStatus,
            licensedDate,
            examStatus,
            examDeadline,
            examLicenseInfo,
            latestUpload,
            historyUpload,
            remarks,
            feedback,
            comments,
            latestUploadFileList,
            historyUploadFileList,
        } = addOrEditLicenseModal.fields;

        const licensedDateMoment = !!licensedDate ? moment(licensedDate) : '';
        const examDeadlineMoment = !!examDeadline ? moment(examDeadline) : '';
        const modalTitle = mode === 'ADD' ? 'Add License Record' : 'Edit License Record';
        const isSubmitLoading = addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.LOGINING;
        const isSubmitAndNotifyLoading = addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.LOGINING;

        const historyUploadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_ATT_DOWNLOAD_URL + '/' + historyUpload;
        const historyUploadLink = <a href={historyUploadUrl} target="_blank">{historyUpload}</a>


        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveLicenseModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveLicenseModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveLicenseModal(false) }>
                          Save
                        </Button>,
                        <Button key="Save&amp;Notify" type="primary" loading={isSubmitAndNotifyLoading} onClick={() => this.submitSaveLicenseModal(true) }>
                          Save&amp;Notify
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Employee">
                                {getFieldDecorator('employee', {
                                    initialValue: employee,
                                    rules: [{
                                        required: true,
                                        message: 'Please input and select Employee',
                                    }],
                                    })(
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{ width: 300, marginLeft: 10 }}
                                        placeholder="Employee" 
                                        value={!employee ? undefined: employee}
                                        onChange={(val) => {
                                            this.onInputChange({ name: 'employee', value: val })
                                        }} >
                                        {userAccountOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="License Type">
                                {getFieldDecorator('licenseBasicInfoGuid', {
                                    initialValue: licenseBasicInfo.guid,
                                    rules: [{
                                        required: true,
                                        message: 'Please select License Type',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'licenseBasicInfoGuid', value: val })
                                            }} 
                                            placeholder="Please select License Type">
                                        {licenseBasicInfoOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="License No">
                                {getFieldDecorator('licenseNo', {
                                    initialValue: licenseNo,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'licenseNo', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Register Status">
                                {getFieldDecorator('regiStatus', {
                                    initialValue: regiStatus,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Register Status',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'regiStatus', value: val })
                                            }} 
                                            placeholder="Please select Register Status">
                                        {regiStatusOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>  
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Licensed Date">
                                {getFieldDecorator('licensedDate', {
                                    initialValue: licensedDateMoment,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'licensedDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Exam Status">
                                {getFieldDecorator('examStatus', {
                                    initialValue: examStatus,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Exam Status',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'examStatus', value: val })
                                            }} 
                                            placeholder="Please select Exam Status">
                                        {examStatusOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>  
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                        Proof of Exam Results&nbsp;
                                        <Tooltip title={<div>{'Please compress all your Exam results, including the proofs submitted previously, into one single zip archive before upload. You may retrieve your previous records from "History Upload".'}
                                                            <br/>{'请将本年度所有考试证明（包括之前提交过的证明），打zip包后一次上传。提交过的证明可以从“History Upload”截取。'}</div>}>
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }>
                                {getFieldDecorator('latestUpload', {
                                    initialValue: latestUpload,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                    <Upload
                                        name="latestUpload"
                                        withCredentials={true}
                                        multiple={false}
                                        fileList={latestUploadFileList}
                                        action={licensingExamCPTProofUploadUrl}
                                        onChange={this.attachmentHandleChange}
                                    >
                                        <Button style={{ width: 300, marginLeft: 10 }}>
                                            <Icon type="upload" />Upload
                                        </Button>
                                    </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Exam Deadline">
                                {getFieldDecorator('examDeadline', {
                                    initialValue: examDeadlineMoment,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'examDeadline', value: dateString })
                                        }} 
                                    />
                                )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="History Upload">
                                    {historyUploadLink}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                    Exam Info&nbsp;
                                    <Tooltip title={<div>{'Please indicate the paper you have passed.'}
                                                        <br/>{'请写下您提交的证明是哪一份考卷。'}</div>}>
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                    </span>
                                }>
                                    {getFieldDecorator('examLicenseInfo', {
                                        initialValue: examLicenseInfo,
                                        rules: [{
                                            required: true,
                                            message: 'Please input Exam Info',
                                        }],
                                        })(
                                            <TextArea 
                                                style={{ width: 300, marginLeft: 10 }}
                                                rows={3} 
                                                value={examLicenseInfo} 
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'examLicenseInfo', value: e.target.value })
                                            }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                    <TextArea 
                                        style={{ width: 300, marginLeft: 10 }}
                                        rows={3} 
                                        value={remarks} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                </FormItem>
                                <FormItem {...formItemLayout} label="Feedback">
                                    <TextArea 
                                        rows={3} 
                                        style={{ width: 300, marginLeft: 10 }}
                                        value={feedback} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'feedback', value: e.target.value })
                                        }}/>
                                </FormItem>
                                <FormItem {...formItemLayout} label="Comments">
                                    <TextArea 
                                        style={{ width: 300, marginLeft: 10 }}
                                        rows={3} 
                                        value={comments} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <p>Please compress all your Exam results, including the proofs submitted previously, into one single zip archive before upload. 
                                    You may retrieve your previous records from "History Upload". And please indicate the paper you have passed in "Exam Info".</p>
                                <p>请将本年度所有考试证明（包括之前提交过的证明），打zip包后一次上传。提交过的证明可以从“History Upload”截取。请在“Exam Info”中写下您提交的证明是哪一份考卷。</p>
                            </Col>
                        </Row>
                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>

            </div>
        );
    }
}

const AddOrEditLicenseModalDialogForm = Form.create({ name: 'add_or_edit_license_modal_dialog' })(AddOrEditLicenseModalDialog);

export default AddOrEditLicenseModalDialogForm;
