import {connect} from 'react-redux';
import MeetingAndTrainingAnswer from '../components/MeetingAndTrainingAnswer';

import {
  loadTodoQuestionnaire,
  loadMeetingAndTraningSub,


  initLoadTodoQuestionnaireActionStatus,

  // Submit Questionnaire Answer
  submitQuestionnaireAnswer,
  submitMeetingAndTraningAnswer,

  initSubmitQuestionnaireAnswerActionStatus,

  // Save Questionnaire Answer
  saveQuestionnaireAnswer,
  initSaveQuestionnaireAnswerActionStatus,

  // Questionnaire Answer Change Input
  questionnaireAnswerChangeInput,

  // Questionnaire Answer Submitter Edit Change Input
  questionnaireAnswerSubmitterEditChangeInput,
  meetingAndTrainingAnswerSubmitterEditChangeInput,

  // Open/Reset Submitted Questionnaire History Modal Dialog
  openSubmittedQuestionnaireHistoryModalDialog,
  resetSubmittedQuestionnaireHistoryModalDialog,

  // Load Submitted Questionnaire History
  loadSubmittedQuestionnaireHistory,
  initLoadSubmittedQuestionnaireHistoryActionStatus,

  // Select Submitted Questionnaire History
  selectSubmittedQuestionnaireHistory,

  // Apply Submitted Questionnaire History
  applySubmittedQuestionnaireHistory,

} from '../questionnaireActions';

const mapStateToProps = state => {
  return {
    // Grid Columns
    submittedHistoryGridColumns: state.questionnaire.submittedHistoryGridColumns,

    // Load To-do Questionnaire
    loadTodoQuestionnaireActionStatus: state.questionnaire.loadTodoQuestionnaireActionStatus,
    loadTodoQuestionnaireActionResp: state.questionnaire.loadTodoQuestionnaireActionResp,
    todoQuestionnaire: state.questionnaire.todoQuestionnaire,
    questionnaireTitle: state.questionnaire.questionnaireTitle,
    todoQuestionArray: state.questionnaire.todoQuestionArray,
    todoQuestionnaireAuthToken: state.questionnaire.todoQuestionnaireAuthToken,
    questionnaireAnsweree: state.questionnaire.questionnaireAnsweree,
    questionnaireAnswerDate: state.questionnaire.questionnaireAnswerDate,
    questionnaireSubmitter: state.questionnaire.questionnaireSubmitter,
    questionnaireStatus: state.questionnaire.questionnaireStatus,
    questionnaireStatusLabel: state.questionnaire.questionnaireStatusLabel,


    loadMeetingAndTraningSubActionStatus: state.questionnaire.loadMeetingAndTraningSubActionStatus,
    title: state.questionnaire.title,
    desc: state.questionnaire.desc,
    comperename: state.questionnaire.comperename,
    starttime: state.questionnaire.starttime,
    id: state.questionnaire.id,
    meetingid: state.questionnaire.meetingid,
    substate: state.questionnaire.substate,
    authtoken: state.questionnaire.authtoken,
    signin: state.questionnaire.signin,
    participant: state.questionnaire.participant,
    lastmodifieddate: state.questionnaire.lastmodifieddate,
    // Submit Questionnaire Answer
    submitQuestionnaireAnswerActionStatus: state.questionnaire.submitQuestionnaireAnswerActionStatus,
    submitQuestionnaireAnswerErrMsg: state.questionnaire.submitQuestionnaireAnswerErrMsg,


    submitMeetingAndTraningAnswerActionStatus: state.questionnaire.submitMeetingAndTraningAnswerActionStatus,
    submitMeetingAndTraningAnswerErrMsg: state.questionnaire.submitMeetingAndTraningAnswerErrMsg,

    // Save Questionnaire Answer
    saveQuestionnaireAnswerActionStatus: state.questionnaire.saveQuestionnaireAnswerActionStatus,
    saveQuestionnaireAnswerErrMsg: state.questionnaire.saveQuestionnaireAnswerErrMsg,

    // Submitted Questionnaire History Modal Dialog
    submittedQuestionnaireHistoryControlModal: state.questionnaire.submittedQuestionnaireHistoryControlModal,

    // Load Submitted Questionnaire History
    loadSubmittedQuestionnaireHistoryActionStatus: state.questionnaire.loadSubmittedQuestionnaireHistoryActionStatus,
    submittedQuestionnaireHistoryArray: state.questionnaire.submittedQuestionnaireHistoryArray,

    // Select Submitted Questionnaire History
    selectSubmittedQuestionnaireHistoryArray: state.questionnaire.selectSubmittedQuestionnaireHistoryArray,

    questionnaireAttachmentUploadUrl: state.questionnaire.questionnaireAttachmentUploadUrl
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    loadTodoQuestionnaire: (authToken) => dispatch(loadTodoQuestionnaire(authToken)),
    loadMeetingAndTraningSub: (authToken) => dispatch(loadMeetingAndTraningSub(authToken)),



    initLoadTodoQuestionnaireActionStatus: () => dispatch(initLoadTodoQuestionnaireActionStatus()),

    // Submit Questionnaire Answer
    submitQuestionnaireAnswer: (authToken, questionnaireAnswerList, submitter, submittedDate) =>
      dispatch(submitQuestionnaireAnswer(authToken, questionnaireAnswerList, submitter, submittedDate)),
    initSubmitQuestionnaireAnswerActionStatus: () => dispatch(initSubmitQuestionnaireAnswerActionStatus()),

    submitMeetingAndTraningAnswer: (borker, sector,title,starttime, address,group) =>
      dispatch(submitMeetingAndTraningAnswer(borker, sector,title,starttime, address,group)),
    initSubmitQuestionnaireAnswerActionStatus: () => dispatch(initSubmitQuestionnaireAnswerActionStatus()),




    // Save Questionnaire Answer
    saveQuestionnaireAnswer: (authToken, questionnaireAnswerList, submitter, submittedDate) => 
      dispatch(saveQuestionnaireAnswer(authToken, questionnaireAnswerList, submitter, submittedDate)),
    
    initSaveQuestionnaireAnswerActionStatus: () => dispatch(initSaveQuestionnaireAnswerActionStatus()),

    // Questionnaire Answer Change Input
    questionnaireAnswerChangeInput: (input) => dispatch(questionnaireAnswerChangeInput(input)),

    // Questionnaire Answer Submitter Edit Change Input
    questionnaireAnswerSubmitterEditChangeInput: (value) => dispatch(questionnaireAnswerSubmitterEditChangeInput(value)),

    meetingAndTrainingAnswerSubmitterEditChangeInput: (value) => dispatch(meetingAndTrainingAnswerSubmitterEditChangeInput(value)),

    // Open/Reset Submitted Questionnaire History Modal Dialog
    openSubmittedQuestionnaireHistoryModalDialog: (authToken) => dispatch(openSubmittedQuestionnaireHistoryModalDialog(authToken)),
    resetSubmittedQuestionnaireHistoryModalDialog: () => dispatch(resetSubmittedQuestionnaireHistoryModalDialog()),

    // Load Submitted Questionnaire History
    loadSubmittedQuestionnaireHistory: (authToken) => dispatch(loadSubmittedQuestionnaireHistory(authToken)),
    initLoadSubmittedQuestionnaireHistoryActionStatus: () => dispatch(initLoadSubmittedQuestionnaireHistoryActionStatus()),

    // Select Submitted Questionnaire History
    selectSubmittedQuestionnaireHistory: (payload) => dispatch(selectSubmittedQuestionnaireHistory(payload)),

    // Apply Submitted Questionnaire History
    applySubmittedQuestionnaireHistory: (payload) => dispatch(applySubmittedQuestionnaireHistory(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingAndTrainingAnswer);