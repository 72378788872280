
const restrictedSecurityRequestGridColumns = [
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    enableRowGroup: true
  },
  // {
  //   field: 'fund',
  //   headerName: "Fund",
  //   cellClass: 'non-number',
  //   width: 120,
  // },
  // {
  //   field: 'book',
  //   headerName: "Book",
  //   cellClass: 'non-number',
  //   width: 120,
  // },
  {
    field: 'ruleCode',
    headerName: "RuleCode",
    cellClass: 'non-number',
    width: 200,
    enableRowGroup: true
  },
  {
    field: 'personInCharge',
    headerName: "PersonInCharge",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestBy',
    headerName: 'Applicant',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'bbgTicker',
    headerName: 'Bbg Ticker',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'validFromLabel',
    headerName: 'Valid From',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: "validToLabel",
    headerName: 'Valid To',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'onListReason',
    headerName: 'On List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'offListReason',
    headerName: 'Off List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'relatedCompany',
    headerName: 'Related Company',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "restrictedSecAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'feedbackTimeLabel',
    headerName: 'Feedback Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'feedbackBy',
    headerName: 'FeedbackBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestStatus',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestTimeLabel',
    headerName: 'Application Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatus',
    headerName: 'Approval Status',
    width: 150,
    cellStyle: function(params) {
      if (params.value=='Approved') {
          return {color: 'green', fontWeight: 'bold'};
      } else if (params.value=='Rejected') {
        return {color: 'red', fontWeight: 'bold'};
      }
    },
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'source',
    headerName: "Source",
    cellClass: 'non-number',
    width: 120,
  },
];

const restrictedSecurityGridColumns = [
  {
    field: 'id',
    headerName: "Id",
    cellClass: 'number',
    width: 100,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'book',
    headerName: "Book",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'personInCharge',
    headerName: "PersonInCharge",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestBy',
    headerName: "Applicant",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'bbgTicker',
    headerName: 'Bbg Ticker',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'validFromLabel',
    headerName: 'Valid From',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: "validToLabel",
    headerName: 'Valid To',
    cellClass: 'non-number',
    width: 120,
  },
  // {
  //   field: 'validStatus',
  //   headerName: "Valid Status",
  //   cellClass: 'non-number',
  //   width: 120,
  // },
  {
    field: 'onListReason',
    headerName: 'On List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'offListReason',
    headerName: 'Off List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'relatedCompany',
    headerName: 'Related Company',
    cellClass: 'non-number',
    width: 300,
  }, 
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "restrictedSecAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'source',
    headerName: "Source",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'lastModifiedDateLabel',
    headerName: 'LastModifiedDate',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedBy',
    headerName: 'LastModifiedBy',
    cellClass: 'non-number',
    width: 150,
  },
];

const restrictedSecurityOpRecordGridColumns = [
  {
    field: 'operator',
    headerName: "Operator",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'operationTimeLabel',
    headerName: 'Operation Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'operation',
    headerName: "Operation",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'comment',
    headerName: "Comment",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'book',
    headerName: "Book",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'personInCharge',
    headerName: "PersonInCharge",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'bbgTicker',
    headerName: 'Bbg Ticker',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'validFromLabel',
    headerName: 'Valid From',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: "validToLabel",
    headerName: 'Valid To',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'onListReason',
    headerName: 'On List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'offListReason',
    headerName: 'Off List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'relatedCompany',
    headerName: 'Related Company',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'feedbackTimeLabel',
    headerName: 'Feedback Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'feedbackBy',
    headerName: 'FeedbackBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestStatus',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestTimeLabel',
    headerName: 'Application Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestBy',
    headerName: 'Applicant',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatus',
    headerName: 'Approval Status',
    width: 150,
    cellStyle: function(params) {
      if (params.value=='Approved') {
          return {color: 'green', fontWeight: 'bold'};
      } else if (params.value=='Rejected') {
        return {color: 'red', fontWeight: 'bold'};
      }
    },
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'source',
    headerName: "Source",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'createdDateLabel',
    headerName: 'Created Date',
    cellClass: 'non-number',
    width: 150,
  },
];


const relatedCompPriceReportGridColumns = [
  {
    field: 'bbgTicker',
    headerName: "BbgTicker",
    cellClass: 'non-number',
    width: 160,
    chartDataType: 'excluded',
  },
  {
    field: 'priceDateLabel',
    headerName: 'Price Date',
    cellClass: 'non-number',
    width: 120,
    chartDataType: 'category',
  },
  {
    field: 'price',
    headerName: "Price",
    cellClass: 'non-number',
    width: 120,
    chartDataType: 'series',
  },
  {
    field: 'checkDateLabel',
    headerName: 'Check Date',
    cellClass: 'non-number',
    width: 120,
    chartDataType: 'excluded',
  },
  {
    field: 'onListDateLabel',
    headerName: 'OnList Date',
    cellClass: 'non-number',
    width: 120,
    chartDataType: 'excluded',
  },
];

const restrictedRuleGridColumns = [
  {
    field: 'id',
    headerName: "Id",
    cellClass: 'number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'team',
    headerName: "Team",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'personInCharge',
    headerName: 'Person In Charge',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'bbgTicker',
    headerName: 'Bbg Ticker',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'remark',
    headerName: 'Remark',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'validFromLabel',
    headerName: 'Valid From',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: "validToLabel",
    headerName: 'Valid To',
    cellClass: 'non-number',
    width: 120,
  },
];

const restrictedAssetGridColumns = [
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'book',
    headerName: "Book",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'pmName',
    headerName: 'PM Name',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'assetClass',
    headerName: 'Asset Class',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'instClass',
    headerName: 'Inst Class',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'explanation',
    headerName: 'Explanation',
    cellClass: 'number',
    width: 300,
  },
  {
    field: 'kickOffDateLabel',
    headerName: 'Kick Off Date',
    cellClass: 'number',
    width: 120,
  },
  {
    field: "expiryDateLabel",
    headerName: 'Expiry Date',
    cellClass: 'number',
    width: 120,
  },
];

const restrictedCountryGridColumns = [
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'book',
    headerName: "Book",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'pmName',
    headerName: 'PM Name',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'countryId',
    headerName: 'Country Id',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'countryCode',
    headerName: 'Country Code',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'explanation',
    headerName: 'Explanation',
    cellClass: 'number',
    width: 300,
  },
  {
    field: 'kickOffDateLabel',
    headerName: 'Kick Off Date',
    cellClass: 'number',
    width: 120,
  },
  {
    field: "expiryDateLabel",
    headerName: 'Expiry Date',
    cellClass: 'number',
    width: 120,
  },
];

const restrictedSectorGridColumns = [
  {
    field: 'restrictionType',
    headerName: "Restriction Type",
    cellClass: 'non-number',
    width: 160,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'fund',
    headerName: "Fund",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'book',
    headerName: "Book",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'pmName',
    headerName: 'PM Name',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'sectorId',
    headerName: 'Sector Id',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'sectorName',
    headerName: 'Sector Name',
    cellClass: 'number',
    width: 200,
  },
  {
    field: 'explanation',
    headerName: 'Explanation',
    cellClass: 'number',
    width: 300,
  },
  {
    field: 'kickOffDateLabel',
    headerName: 'Kick Off Date',
    cellClass: 'number',
    width: 120,
  },
  {
    field: "expiryDateLabel",
    headerName: 'Expiry Date',
    cellClass: 'number',
    width: 120,
  },
];

const sfcShortableListGridColumns = [
  {
    field: 'dateLabel',
    headerName: "Date",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'bbgTicker',
    headerName: "Bbg Ticker",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'number',
    width: 200,
  },
  {
    field: 'shares',
    headerName: 'Shares',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'dollarAmt',
    headerName: 'Dollar Amt',
    cellClass: 'number',
    width: 120,
  },
];

const pbShortInterestRateGridColumns = [
  {
    field: 'ticker',
    headerName: "Ticker",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'msAvailableQty',
    headerName: 'MS Avail Qty',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'msRate',
    headerName: 'MS Rate',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'msDateLabel',
    headerName: 'MS Date',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'ciccAvailableQty',
    headerName: 'CICC Avail Qty',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'ciccRate',
    headerName: 'CICC Rate',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'ciccDateLabel',
    headerName: 'CICC Date',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'jpmAvailableQty',
    headerName: 'JPM Avail Qty',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'jpmRate',
    headerName: 'JPM Rate',
    cellClass: 'number',
    width: 120,
  },
  {
    field: 'jpmDateLabel',
    headerName: 'JPM Date',
    cellClass: 'non-number',
    width: 120,
  },
];

const restrictedSecurityRuleGridColumns = [
  {
    field: 'code',
    headerName: "Restriction Code",
    cellClass: 'non-number',
    width: 230, 
    cellRenderer: 'agGroupCellRenderer', 
  },
  {
    field: 'name',
    headerName: "Restriction Name",
    cellClass: 'non-number',
    width: 230,
  },
  {
    field: 'isActive',
    headerName: "Active",
    cellClass: 'non-number',
    width: 100,
    valueFormatter: (params) => {
      if(params.value === 1){
        return 'Y'
      }else{
        return 'N'
      }
    }
  },
  {
    field: 'market',
    headerName: "Market",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'assetClass',
    headerName: "Asset Class",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'quantityDirection',
    headerName: 'Quantity Direction',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'isFundBookMatched',
    headerName: 'IsFundBookMatched',
    cellClass: 'non-number',
    width: 100,
    valueFormatter: (params) => {
      if(params.value === 1){
        return 'Y'
      }else{
        return 'N'
      }
    }
  },
  {
    field: 'isTickerMatched',
    headerName: 'IsTickerMatched',
    cellClass: 'non-number',
    width: 120,
    valueFormatter: (params) => {
      if(params.value === 1){
        return 'Y'
      }else{
        return 'N'
      }
    }
  },
  {
    field: "isQuantIncluded",
    headerName: 'IsQuantIncluded',
    cellClass: 'non-number',
    width: 120,
    valueFormatter: (params) => {
      if(params.value === 1){
        return 'Y'
      }else{
        return 'N'
      }
    }
  },
  {
    field: 'lastModifiedDate',
    headerName: 'LastModifiedDate',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'lastModifiedBy',
    headerName: 'LastModifiedBy',
    cellClass: 'non-number',
    width: 150,
  },
];

const GridColumnMap = {
  restrictedSecurityRequestGridColumns,
  restrictedSecurityGridColumns,
  restrictedSecurityOpRecordGridColumns,
  relatedCompPriceReportGridColumns,

  restrictedRuleGridColumns,
  restrictedAssetGridColumns,
  restrictedCountryGridColumns,
  restrictedSectorGridColumns,
  sfcShortableListGridColumns,
  pbShortInterestRateGridColumns,
  restrictedSecurityRuleGridColumns
};

export default GridColumnMap;