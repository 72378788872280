
const expenseSummaryGridColumns = [
    {
        field: 'id',
        headerName: "ID",
        cellClass: 'non-number',
        width: 80,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
    },
    {
        field: 'category',
        headerName: "Category",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'firm',
        headerName: "Firm",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'fundFirmRelated',
        headerName: "Fund/Firm related",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'invDate',
        headerName: "Inv Date",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'invNo',
        headerName: "Inv No.",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'ccy',
        headerName: "CCY",
        cellClass: 'non-number',
        width: 60,
    },
    {
        field: 'amount',
        headerName: "Amount",
        cellClass: 'number',
        width: 100,
    },
    {
        field: 'fx',
        headerName: "FX",
        cellClass: 'number',
        width: 60,
    },
    {
        field: 'amtUSD',
        headerName: "USD Amt",
        cellClass: 'number',
        width: 100,
    },
    {
        field: 'payDate',
        headerName: "Pay Date",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'nature',
        headerName: "Nature",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'remarks',
        headerName: "Remarks",
        cellClass: 'non-number',
        width: 200,
        cellRenderer: 'jsonTooltipComponent'
    },
    {
        field: 'payMethod',
        headerName: "Payment Method",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'createdBy',
        headerName: "createdBy",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'createTime',
        headerName: "createTime",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'lastUpdatedBy',
        headerName: "lastUpdatedBy",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'lastUpdateTime',
        headerName: "lastUpdateTime",
        cellClass: 'non-number',
        width: 150,
    },
];


const GridColumnMap = {
    expenseSummaryGridColumns,
};

export default GridColumnMap;

