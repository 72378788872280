
const rotationReviewGridColumns = [
    {
        field: 'id',
        headerName: "ID",
        cellClass: 'non-number',
        width: 80,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
    },
    {
        field: 'cpty',
        headerName: "Cpty*",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'functiontype',
        headerName: "Function*",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'fundandcompany',
        headerName: "Fund/Co.*",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'employee',
        headerName: "User*",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'usertype',
        headerName: "User Type*",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'remarks',
        headerName: "Remarks",
        cellClass: 'non-number',
        width: 200,
    },
    {
        field: 'status',
        headerName: "Status",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'lastupdatetime',
        headerName: "Last Modified date",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'validetodate',
        headerName: "Valid to",
        cellClass: 'number',
        width: 100,
    },
    {
        field: 'filepath',
        headerName: "User Access Form",
        cellClass: 'non-number',
        width: 300,
    },

];


const GridColumnMap = {
    rotationReviewGridColumns,
};


export default GridColumnMap;

