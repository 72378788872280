import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


class GroupStructureGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {field: 'id', headerName: 'dataID', cellClass: 'non-number', width: 100, checkboxSelection: true},
                {field: 'chartName', headerName: 'chartName', cellClass: 'non-number', width: 200},
                {field: 'nature', headerName: 'nature', cellClass: 'non-number', width: 100},
                {field: 'usage', headerName: 'usage', cellClass: 'non-number', width: 200},
                {field: 'uploadChartOriName', headerName: 'chart', cellClass: 'non-number', width: 150, cellRenderer: this.openDetailPageCellRenderer},
                {field: 'uploadPdfRealName', headerName: 'pdf', cellClass: 'non-number', width: 150, cellRenderer: (node) => this.downloadPdfCellRenderer(node, props)},
                {field: 'remarks', headerName: 'remarks', cellClass: 'non-number', width: 200},
                {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 120},
                {field: 'createTime', headerName: 'createTime', cellClass: 'non-number', width: 150},
                {field: 'updatedBy', headerName: 'updatedBy', cellClass: 'non-number', width: 120},
                {field: 'updatedTime', headerName: 'updatedTime', cellClass: 'non-number', width: 150},
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "single",
            context: { componentParent: this },
         };
    }


    openDetailPageCellRenderer = (node) => {
        const data = node.data;
        const chartName = data.uploadChartOriName;
        if(!!chartName) {
            let link = document.createElement('a');
            link.innerText = chartName;
            link.href = '#';
            link.onclick = () => {
                data.openGroupStructureDetailDialog(data);
                return false;
            };
            return link;
        }
        return '';
    }

    
    downloadPdfCellRenderer = (node, props) => {
        const pdfFileName = node.data.uploadPdfRealName;
        if (!!pdfFileName) {
            const url = props.groupStructureDownloadUrl + '/' + pdfFileName;
            let link = document.createElement('a');
            link.innerText = pdfFileName;
            link.target = '_blank';
            link.href = url;
            return link;
        }
        return '';
    }


    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let selectedGroupStructureArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectdGroupStructure(selectedGroupStructureArray);
    }


    render () {

        const {
            queryGroupStructureStatus,
            queryGroupStructureResp,
        } = this.props;

        const isLoading = queryGroupStructureStatus === ACTION_STATUS.LOGINING;
        const groupStructureArray = !!queryGroupStructureResp && !!queryGroupStructureResp.data ? queryGroupStructureResp.data : [];

        // 将  方法添加在每个节点data上，供点击chart超链接时使用
        groupStructureArray.forEach(e => {
            e.openGroupStructureDetailDialog = this.props.openGroupStructureDetailDialog;
        });

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={groupStructureArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // event
                            onSelectionChanged={this.onDataSelectionChanged}
                        />
                )}
            </div>
        );
    }
}


export default GroupStructureGrid;
