import React, { Component } from 'react';
import { Input, Form, Modal, Button, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelRotationReviewModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.rotationReviewQueryFields;
        this.props.loadRotationReviewInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'inactive') {
            return 'Rest Info';
        }  else {
            return 'Note Info';
        }
    }
    componentDidUpdate() {
        const {
            delRotationReviewStatus,
        } = this.props;
        if(delRotationReviewStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delRotationReviewModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelRotationReviewActionStatus();
            this.props.resetDelRotationReviewModal();
            this.refreshQuery();
        }
        if(delRotationReviewStatus === ACTION_STATUS.ERROR) {
            this.props.initDelRotationReviewActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delRotationReviewChangeInput({name, value});
    }


    submitDelRotationReview = () => {

        Modal.confirm({
            title: 'Are you sure submit ?',
            onOk: () => {

                const {
                    mode,
                } = this.props.delRotationReviewModal;
                const {
                    id,
                    comments,
                } = this.props.delRotationReviewModal.fields;
                this.props.delRotationReview({id, mode, comments});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delRotationReviewModal,
            delRotationReviewStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delRotationReviewModal;


        const isSubmitLoading = delRotationReviewStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={800}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelRotationReviewModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelRotationReviewModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelRotationReview}>
                          Submit
                        </Button>,
                      ]}
                >
                        <TextArea 
                            style={{ width: 700 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelRotationReviewModalDialogForm = Form.create({ name: 'DelRotationReviewModalDialog' })(DelRotationReviewModalDialog);
export default DelRotationReviewModalDialogForm;
