// Load Custodian
export const START_LOAD_CUSTODIAN = 'START_LOAD_CUSTODIAN';
export const LOAD_CUSTODIAN_SUCCESS = 'LOAD_CUSTODIAN_SUCCESS';
export const LOAD_CUSTODIAN_FAILURE = 'LOAD_CUSTODIAN_FAILURE';
export const INIT_LOAD_CUSTODIAN_ACTION_STATUS = 'INIT_LOAD_CUSTODIAN_ACTION_STATUS';

// Load Custodian Account
export const START_LOAD_CUSTODIAN_ACCOUNT = 'START_LOAD_CUSTODIAN_ACCOUNT';
export const LOAD_CUSTODIAN_ACCOUNT_SUCCESS = 'LOAD_CUSTODIAN_ACCOUNT_SUCCESS';
export const LOAD_CUSTODIAN_ACCOUNT_FAILURE = 'LOAD_CUSTODIAN_ACCOUNT_FAILURE';
export const INIT_LOAD_CUSTODIAN_ACCOUNT_ACTION_STATUS = 'INIT_LOAD_CUSTODIAN_ACCOUNT_ACTION_STATUS';
