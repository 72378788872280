import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';

import {
    // query
    START_QUERY_GROUP_STRUCTURE,
    QUERY_GROUP_STRUCTURE_SUCCESS,
    QUERY_GROUP_STRUCTURE_FAILURE,
    INIT_QUERY_GROUP_STRUCTURE_ACTION_STATUS,
    // detail
    OPEN_GROUP_STRUCTURE_DETAIL_DIALOG,
    RESET_GROUP_STRUCTURE_DETAIL_DIALOG,
    // select
    SELECT_GROUP_STRUCTURE,
    // add or edit
    OPEN_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL,
    RESET_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL,
    ADD_OR_EDIT_GROUP_STRUCTURE_CHANGE_INPUT,
    START_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE,
    SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_SUCCESS,
    SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_ACTION_STATUS,
    // del
    OPEN_DEL_GROUP_STRUCTURE_MODAL,
    RESET_DEL_GROUP_STRUCTURE_MODAL,
    DEL_GROUP_STRUCTURE_CHANGE_INPUT,
    START_SUBMIT_DEL_GROUP_STRUCTURE,
    SUBMIT_DEL_GROUP_STRUCTURE_SUCCESS,
    SUBMIT_DEL_GROUP_STRUCTURE_FAILURE,
    INIT_SUBMIT_DEL_GROUP_STRUCTURE_ACTION_STATUS,

} from './GroupStructureConstants';


const Option = Select.Option;

const natureOptions = [];
natureOptions.push(<Option key={'Company'}>{'Company'}</Option>);
natureOptions.push(<Option key={'Function'}>{'Function'}</Option>);

const usageOptions = [];
usageOptions.push(<Option key={'Internal'}>{'Internal'}</Option>);
usageOptions.push(<Option key={'Company'}>{'Company'}</Option>);
usageOptions.push(<Option key={'Regulator'}>{'Regulator'}</Option>);
usageOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);


const initialState = {
    // options
    natureOptions: natureOptions,
    usageOptions: usageOptions,

    groupStructureDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.GROUP_STRUCTURE_DOWNLOAD_URL,
    groupStructureUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.GROUP_STRUCTURE_UPLOAD_URL,
    

    groupStructureDetailDialog: {
        isOpened: false,
        fields: {
            id: undefined,
            chartName: undefined,
            nature: undefined,
            usage: undefined,
            uploadChartOriName: undefined,
            uploadChartRealName: undefined,
            uploadPdfRealName: undefined,
            remarks: undefined,
        },
    },
    selectedGroupStructureArray: [],
    groupStructureModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: 'Valid',
            chartName: undefined,
            nature: undefined,
            usage: undefined,
            uploadChartOriName: undefined,
            uploadChartRealName: undefined,
            uploadPdfRealName: undefined,
            remarks: undefined,
            comments: undefined,
            opType: undefined,
            chartFileList: [],
        },
    },
    delGroupStructureModal: {
        isOpened: false,
        mode: 'DEL',
        respErrMsg: '',
        fields: {
            id: undefined,
            comments: undefined,
        }
    }

};


// query
function startQueryGroupStructure(state) {
    return {
        ...state,
        queryGroupStructureStatus: ACTION_STATUS.LOGINING,
    };
}
function queryGroupStructureSuccess(state, resp) {
    return {
        ...state,
        queryGroupStructureStatus: ACTION_STATUS.SUCCESS,
        queryGroupStructureResp: resp,
    };
}
function queryGroupStructureFailure(state, err) {
    return {
        ...state,
        queryGroupStructureStatus: ACTION_STATUS.ERROR,
        queryGroupStructureResp: err,
    };
}
function initQueryGroupStructureActionStatus(state) {
    return {
        ...state,
        queryGroupStructureStatus: ACTION_STATUS.READY,
        selectedGroupStructureArray: [],
    };   
}


// detail
function openGroupStructureDetailDialog(state, data) {
    return {
        ...state,
        groupStructureDetailDialog: {
            ...state.groupStructureDetailDialog,
            isOpened: true,
            fields: {
                ...state.groupStructureDetailDialog.fields,
                id: data.id,
                chartName: data.chartName,
                nature: data.nature,
                usage: data.usage,
                uploadChartOriName: data.uploadChartOriName,
                uploadChartRealName: data.uploadChartRealName,
                uploadPdfRealName: data.uploadPdfRealName,
                remarks: data.remarks,
            }
        }
    }
}
function resetGroupStructureDetailDialog(state) {
    return {
        ...state,
        groupStructureDetailDialog: initialState.groupStructureDetailDialog,
    }
}


// select
function selectGroupStructure(state, array) {
    return {
        ...state,
        selectedGroupStructureArray: array,
    }
}


// add or edti
function openAddOrEditGroupStructureModal(state, {opType, data}) {
    if(opType === 'ADD') {
        return {
            ...state,
            groupStructureModal: {
                ...initialState.groupStructureModal,
                isOpened: true,
            }
        };
    } else {
        const chartFileList = [];
        if(!!data.uploadPdfRealName) {
            const file = {
                uid: '-1',
                name: data.uploadChartOriName,
                status: 'done',
                url: state.groupStructureDownloadUrl + '/' + data.uploadChartRealName,
                response: {
                    respCode: 'S0001',
                    fileNameWithTimeStamp: data.uploadChartRealName,
                    originalFileName: data.uploadChartOriName,
                }
            };
            chartFileList.push(file);
        }
        return {
            ...state,
            groupStructureModal: {
                ...initialState.groupStructureModal,
                isOpened: true,
                mode: 'EDIT',
                fields: {
                    ...initialState.groupStructureModal.fields,
                    id: data.id,
                    status: data.status,
                    chartName: data.chartName,
                    nature: data.nature,
                    usage: data.usage,
                    uploadChartOriName: data.uploadChartOriName,
                    uploadChartRealName: data.uploadChartRealName,
                    uploadPdfRealName: data.uploadPdfRealName,
                    remarks: data.remarks,
                    comments: undefined,
                    chartFileList: chartFileList,
                },
            }
        };
    }
}
function resetAddOrEditGroupStructureModal(state) {
    return {
        ...state,
        groupStructureModal: initialState.groupStructureModal,
    };
}
function addOrEditGroupStructureChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.groupStructureModal.fields,
        [name]: value,
    };
    return {
        ...state,
        groupStructureModal: {
          ...state.groupStructureModal,
          fields: updatedFields,
        },
    };
}


function startSubmitAddOrEditGroupStructure(state) {
    return {
        ...state,
        addOrEditGroupStructureStatus: ACTION_STATUS.LOGINING,
    };
}
function submitAddOrEditGroupStructureSuccess(state, resp) {
    return {
        ...state,
        addOrEditGroupStructureStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitAddOrEditGroupStructureFailure(state, err) {
    return {
        ...state,
        addOrEditGroupStructureStatus: ACTION_STATUS.ERROR,
        groupStructureModal: {
            ...state.groupStructureModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitAddOrEditGroupStructureActionStatus(state) {
    return {
        ...state,
        addOrEditGroupStructureStatus: ACTION_STATUS.READY,
    };   
}


// del
function openDelGroupStructureModal(state, data) {
    return {
        ...state,
        delGroupStructureModal: {
            ...state.delGroupStructureModal,
            isOpened: true,
            fields: {
                ...state.delGroupStructureModal.fields,
                id: data.id,
            }
        },
    };
}
function resetDelGroupStructureModal(state) {
    return {
        ...state,
        delGroupStructureModal: initialState.delGroupStructureModal,
    };
}
function delGroupStructureChangeInput(state, {name, value}) {
    return {
        ...state,
        delGroupStructureModal: {
            ...state.delGroupStructureModal,
            fields: {
                ...state.delGroupStructureModal.fields,
                [name]: value,
            }
        },
    }
}

function startSubmitDelGroupStructure(state) {
    return {
        ...state,
        delGroupStructureStatus: ACTION_STATUS.LOGINING,
    };
}
function submitDelGroupStructureSuccess(state, resp) {
    return {
        ...state,
        delGroupStructureStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitDelGroupStructureFailure(state, err) {
    return {
        ...state,
        delGroupStructureStatus: ACTION_STATUS.ERROR,
        delGroupStructureModal: {
            ...state.delGroupStructureModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitDelGroupStructureActionStatus(state) {
    return {
        ...state,
        delGroupStructureStatus: ACTION_STATUS.READY,
    };   
}


export default createReducer(initialState, {
    // query
    [START_QUERY_GROUP_STRUCTURE]: startQueryGroupStructure,
    [QUERY_GROUP_STRUCTURE_SUCCESS]: queryGroupStructureSuccess,
    [QUERY_GROUP_STRUCTURE_FAILURE]: queryGroupStructureFailure,
    [INIT_QUERY_GROUP_STRUCTURE_ACTION_STATUS]: initQueryGroupStructureActionStatus,
    // detail
    [OPEN_GROUP_STRUCTURE_DETAIL_DIALOG]: openGroupStructureDetailDialog,
    [RESET_GROUP_STRUCTURE_DETAIL_DIALOG]: resetGroupStructureDetailDialog,
    // select
    [SELECT_GROUP_STRUCTURE]: selectGroupStructure,
    // add or edit
    [OPEN_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL]: openAddOrEditGroupStructureModal,
    [RESET_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL]: resetAddOrEditGroupStructureModal,
    [ADD_OR_EDIT_GROUP_STRUCTURE_CHANGE_INPUT]: addOrEditGroupStructureChangeInput,
    [START_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE]: startSubmitAddOrEditGroupStructure,
    [SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_SUCCESS]: submitAddOrEditGroupStructureSuccess,
    [SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_FAILURE]: submitAddOrEditGroupStructureFailure,
    [INIT_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_ACTION_STATUS]: initSubmitAddOrEditGroupStructureActionStatus,
    // del
    [OPEN_DEL_GROUP_STRUCTURE_MODAL]: openDelGroupStructureModal,
    [RESET_DEL_GROUP_STRUCTURE_MODAL]: resetDelGroupStructureModal,
    [DEL_GROUP_STRUCTURE_CHANGE_INPUT]: delGroupStructureChangeInput,
    [START_SUBMIT_DEL_GROUP_STRUCTURE]: startSubmitDelGroupStructure,
    [SUBMIT_DEL_GROUP_STRUCTURE_SUCCESS]: submitDelGroupStructureSuccess,
    [SUBMIT_DEL_GROUP_STRUCTURE_FAILURE]: submitDelGroupStructureFailure,
    [INIT_SUBMIT_DEL_GROUP_STRUCTURE_ACTION_STATUS]: initSubmitDelGroupStructureActionStatus,

});