import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_USER_ACCESS_INFO,
    LOAD_USER_ACCESS_INFO_SUCCESS,
    LOAD_USER_ACCESS_INFO_FAILURE,
    INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
    // select
    SELECT_USER_ACCESS_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
    ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
    START_ADD_OR_EDIT_USER_ACCESS,
    ADD_OR_EDIT_USER_ACCESS_SUCCESS,
    ADD_OR_EDIT_USER_ACCESS_FAILURE,
    INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
    RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_USER_ACCESS_MODAL,
    DEL_USER_ACCESS_CHANGE_INPUT,
    START_DEL_USER_ACCESS,
    DEL_USER_ACCESS_SUCCESS,
    DEL_USER_ACCESS_FAILURE,
    INIT_DEL_USER_ACCESS_ACTION_STATUS,
    RESET_DEL_USER_ACCESS_MODAL,

    // COP
    OPEN_COP_USER_ACCESS_MODAL,
    COP_USER_ACCESS_CHANGE_INPUT,
    START_COP_USER_ACCESS,
    COP_USER_ACCESS_SUCCESS,
    COP_USER_ACCESS_FAILURE,
    INIT_COP_USER_ACCESS_ACTION_STATUS,
    RESET_COP_USER_ACCESS_MODAL,



    OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    RESTRICTED_RULE_INPUT_CHANGE,
} from './UserAccessConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadUserAccessInfo() {
    return {
      type: START_LOAD_USER_ACCESS_INFO,
    };
}
export function loadUserAccessInfo(params) {
    return function(dispatch) {
        dispatch(startLoadUserAccessInfo());
        httpClient
          .get(UrlConfig.LOAD_USER_ACCESS_INFO, params)
          .then(resp => dispatch(loadUserAccessInfoSuccess(resp)))
          .catch(err => dispatch(loadUserAccessInfoFailure(err)));
    };
}
function loadUserAccessInfoSuccess(resp) {
    return {
      type: LOAD_USER_ACCESS_INFO_SUCCESS,
      payload: resp,
    };
}
function loadUserAccessInfoFailure(err) {
    return {
        type: LOAD_USER_ACCESS_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadUserAccessInfoActionStatus() {
    return {
      type: INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS,
    };
}

// select
export function selectUserAccessInfo(array) {
    return {
        type: SELECT_USER_ACCESS_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditUserAccessModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditUserAccessChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditUserAccess() {
    return {
        type: START_ADD_OR_EDIT_USER_ACCESS,
    };
}
export function addOrEditUserAccess(params) {
      return function(dispatch) {
        dispatch(startAddOrEditUserAccess());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_USER_ACCESS, params)
          .then(resp => dispatch(addOrEditUserAccessSuccess(resp)))
          .catch(err => dispatch(addOrEditUserAccessFailure(err)));
      };
}
function addOrEditUserAccessSuccess(resp) {
    return {
        type: ADD_OR_EDIT_USER_ACCESS_SUCCESS,
        payload: resp,
    };
}
function addOrEditUserAccessFailure(err) {
    return {
        type: ADD_OR_EDIT_USER_ACCESS_FAILURE,
        payload: err,
    };
}
export function initAddOrEditUserAccessActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS,
    };
}
export function resetAddOrEditUserAccessModal() {
    return {
        type: RESET_ADD_OR_EDIT_USER_ACCESS_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelUserAccessModal(info,mode,idList) {
    return {
        type: OPEN_DEL_USER_ACCESS_MODAL,
        payload: 
        {
            info,
            mode,
            idList,
        },
    };  
}
export function delUserAccessChangeInput({name, value}) {
    return {
        type: DEL_USER_ACCESS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelUserAccess() {
    return {
        type: START_DEL_USER_ACCESS,
    };
}
export function delUserAccess(params) {
      return function(dispatch) {
        dispatch(startDelUserAccess());
        httpClient
          .get(UrlConfig.DEL_USER_ACCESS, params)
          .then(resp => dispatch(delUserAccessSuccess(resp)))
          .catch(err => dispatch(delUserAccessFailure(err)));
      };
}
function delUserAccessSuccess(resp) {
    return {
        type: DEL_USER_ACCESS_SUCCESS,
        payload: resp,
    };
}
function delUserAccessFailure(err) {
    return {
        type: DEL_USER_ACCESS_FAILURE,
        payload: err,
    };
}
export function initDelUserAccessActionStatus() {
    return {
        type: INIT_DEL_USER_ACCESS_ACTION_STATUS,
    };
}
export function resetDelUserAccessModal() {
    return {
        type: RESET_DEL_USER_ACCESS_MODAL,
    };
}












//cop
export function openCopUserAccessModal(info) {
    return {
        type: OPEN_COP_USER_ACCESS_MODAL,
        payload: info,
    };  
}
export function copUserAccessChangeInput({name, value}) {
    return {
        type: COP_USER_ACCESS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startCopUserAccess() {
    return {
        type: START_COP_USER_ACCESS,
    };
}
export function copUserAccess(params) {
      return function(dispatch) {
        dispatch(startCopUserAccess());
        httpClient
          .get(UrlConfig.COP_ALL_USER_ACCESS, params)
          .then(resp => dispatch(copUserAccessSuccess(resp)))
          .catch(err => dispatch(copUserAccessFailure(err)));
      };
}
function copUserAccessSuccess(resp) {
    return {
        type: COP_USER_ACCESS_SUCCESS,
        payload: resp,
    };
}
function copUserAccessFailure(err) {
    return {
        type: COP_USER_ACCESS_FAILURE,
        payload: err,
    };
}
export function initCopUserAccessActionStatus() {
    return {
        type: INIT_COP_USER_ACCESS_ACTION_STATUS,
    };
}
export function resetCopUserAccessModal() {
    return {
        type: RESET_COP_USER_ACCESS_MODAL,
    };
}

export function openRestrictedRuleFundBookDialog() {
    return {
      type: OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG
    };
  }
  
  export function closeRestrictedRuleFundBookDialog() {
    return {
      type: CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG
    };
  }
  export function restrictedRuleEditChangeInput({ name, value }) {
    return {
      type: RESTRICTED_RULE_INPUT_CHANGE,
      payload: {
        name,
        value,
      }
    };
  }