import React, { Component } from 'react';

import { Form, Row, Col, Button, Modal, message, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react'

import QuestionnaireEmailEditDialog from './QuestionnaireEmailEditDialog';

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../../utils/QuestionnaireEmailSendMode';

class AnswereeSelectDialog extends Component {
  state = {
    
  };

  componentDidMount () {
    
  }

  componentDidUpdate() {
    const { 
      selectAnswereesActionStatus,
    } = this.props;

    // 选择Answeree成功
    if (selectAnswereesActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Employee selected successfully!');
      // this.props.loadQuestionnaires();
      // this.props.loadAnswers();
      this.props.resetQuestionnaireEmailEditModalDialog();
      this.props.initLoadMeetingAndTrainingGroupInfoActionStatus();
      this.props.resetAnswereeSelectModalDialog();
      this.props.loadMeetingAndTrainingGroupInfo({cpty: undefined,functiontype: undefined,fundandcompany: undefined,userName: undefined, status: 'Active'})
      this.props.initSelectAnswereesActionStatus();

      // this.props.resetQuestionnaireEmailEditModalDialog();
      // this.props.resetAnswereeSelectModalDialog();
    } 

    // 选择Answeree失败
    if (selectAnswereesActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSelectAnswereesActionStatus();
    }
  }

  selectAllButtonOnClicked = () => {
    // this.props.selectAllAnsweree();
  }

  selectInvertButtonOnClicked = () => {
    // this.props.selectInvertAnsweree();
  }

  onChange = (e, value) => {
    console.log('checked = ', e.target.checked);
    this.props.answereeCheckChanged(e.target.checked, value.userAccountName);

    const {
      pmGroupArray,
    } = this.props;

    console.log(pmGroupArray);
  };

  // Submit Create/Edit Investor Net Value
  submitButtonOnClicked = () => {
    const {
      selectedQuestionnaires,
      selectedMeetingAndTrainingGroupInfoArray,
      pmGroupArray
    } = this.props;

    const userAccountNameList = [];
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        if (userAccount.checked) {
          userAccountNameList.push(userAccount.userAccountName);
        }
      })
    });

    if (userAccountNameList.length === 0) {
      message.warning("Please select one answeree at least!");
      return;
    }

    // this.props.selectAnswerees(userAccountNameList, selectedQuestionnaires[0].guid);
    this.props.openQuestionnaireEmailEditModalDialog(QUESTIONNAIRE_EMAIL_SEND_MODE.ANSWEREE_SELECT, {
      userAccountNameList,
      questionnaireGuid: selectedMeetingAndTrainingGroupInfoArray[0].id,
    });
  }

  render() {
    const {
      answereeSelectControlModal,
      pmGroupArray,
      answerArray,
      selectedQuestionnaires,
      selectAnswereesActionStatus,
    } = this.props;

    const {
      isOpened,
      respErrMsg,
    } = answereeSelectControlModal;

    // const isLoading = selectAnswereesActionStatus === ACTION_STATUS.LOGINING; 
    const isLoading = false;
    
    return (
      <div>
        <Modal
          title='Select Employee'
          centered
          width={800}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetAnswereeSelectModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <div style={{ marginBottom: 10 }}>
            <Button 
              onClick={this.selectAllButtonOnClicked} 
              size='small'
              type="primary">
              Select All
            </Button>

            <Button 
              onClick={this.selectInvertButtonOnClicked} 
              size='small'
              style={{ marginLeft: 10 }}
              type="primary">
              Select Invert
            </Button>
          </div>

          {/* <Form layout="horizontal"> */}
            {
              pmGroupArray.map((pmArray, index) => {
                return (
                  <Row gutter={24} key={ index }>
                    {
                      pmArray.map((value, index) => {
                        return (
                          <Col span={6} key={ value.userAccountName }>
                            <Checkbox checked={ value.checked } onChange={(e) => this.onChange(e, value)}>
                              { value.englishName }
                            </Checkbox>
                          </Col>
                        );
                      })
                    }
                  </Row>
                );
              })
            }
          {/* </Form> */}
          {/* {
            !!respErrMsg &&  
            <Message negative>
                <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          } */}
        </Modal>

        <QuestionnaireEmailEditDialog {...this.props} />
      </div>
    );
  }
}

export default AnswereeSelectDialog;