import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import moment from 'moment';
import RestrictedSecAttachRenderer from '../../../../renderer/RestrictedSecAttachRenderer';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
// import { localStorageClient } from '../../../../../utils/localStorageClient';
// import StateSynchronizer from '../../../../../utils/StateSynchronizer';

class RestrictedSecurityGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.restrictedSecurityGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "multiple",
      context: { componentParent: this },
      frameworkComponents: {
        restrictedSecAttachRenderer: RestrictedSecAttachRenderer,
      },
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityArray = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectRestrictedSecurity(restrictedSecurityArray);
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.closeToolPanel();

    // const COLUMNS_KEY = 'trade--report-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      filterRestrictedSecurityArray,
      className = 'grid-wrapper',
      loadRestrictedSecurityActionStatus
    } = this.props;

    const isLoading = loadRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING;
    if (loadRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadRestrictedSecurityActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={filterRestrictedSecurityArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default RestrictedSecurityGrid;