import {
  // Load Fund List
  START_LOAD_FUND_LIST,
  LOAD_FUND_LIST_SUCCESS,
  LOAD_FUND_LIST_FAILURE,
  INIT_LOAD_FUND_LIST_ACTION_STATUS,
} from './fundConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Fund List
function startLoadFundList() {
  return {
    type: START_LOAD_FUND_LIST
  };
}

export function loadFundList() {
  return function(dispatch) {
    dispatch(startLoadFundList());
    httpClient
      .get(UrlConfig.QUERY_FUND_LIST)
      .then(resp => dispatch(loadFundListSuccess(resp)))
      .catch(err => dispatch(loadFundListFailure(err)));
  };
}

function loadFundListSuccess(resp) {
  return {
    type: LOAD_FUND_LIST_SUCCESS,
    payload: resp
  };
}

function loadFundListFailure(err) {
  return {
    type: LOAD_FUND_LIST_FAILURE,
    payload: err
  };
}

export function initLoadFundListActionStatus() {
  return {
    type: INIT_LOAD_FUND_LIST_ACTION_STATUS
  };
}
