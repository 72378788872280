import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Sync 13F Report
  START_SYNC_THIRTEENF_REPORT,
  SYNC_THIRTEENF_REPORT_SUCCESS,
  SYNC_THIRTEENF_REPORT_FAILURE,
  INIT_SYNC_THIRTEENF_REPORT_ACTION_STATUS,

  // Load 13F Report
  START_LOAD_THIRTEENF_REPORT,
  LOAD_THIRTEENF_REPORT_SUCCESS,
  LOAD_THIRTEENF_REPORT_FAILURE,
  INIT_LOAD_THIRTEENF_REPORT_ACTION_STATUS,

} from './thirteenFReportConstants';

// Sync 13F Report
function startSyncThirteenFReport() {
  return {
    type: START_SYNC_THIRTEENF_REPORT
  };
}

export function syncThirteenFReport() {
  return function(dispatch) {
    dispatch(startSyncThirteenFReport());
    httpClient
      .post(UrlConfig.SYNC_13F_REPORT)
      .then(resp => dispatch(syncThirteenFReportSuccess(resp)))
      .catch(err => dispatch(syncThirteenFReportFailure(err)));
  };
}

function syncThirteenFReportSuccess(resp) {
  return {
    type: SYNC_THIRTEENF_REPORT_SUCCESS,
    payload: resp
  };
}

function syncThirteenFReportFailure(err) {
  return {
    type: SYNC_THIRTEENF_REPORT_FAILURE,
    payload: err
  };
}

export function initSyncThirteenFReportActionStatus() {
  return {
    type: INIT_SYNC_THIRTEENF_REPORT_ACTION_STATUS
  };
}

// Load 13F Report
function startLoadThirteenFReport() {
  return {
    type: START_LOAD_THIRTEENF_REPORT
  };
}

export function loadThirteenFReport() {
  return function(dispatch) {
    dispatch(startLoadThirteenFReport());
    httpClient
      .get(UrlConfig.LOAD_13F_REPORT_LIST)
      .then(resp => dispatch(loadThirteenFReportSuccess(resp)))
      .catch(err => dispatch(loadThirteenFReportFailure(err)));
  };
}

function loadThirteenFReportSuccess(resp) {
  return {
    type: LOAD_THIRTEENF_REPORT_SUCCESS,
    payload: resp
  };
}

function loadThirteenFReportFailure(err) {
  return {
    type: LOAD_THIRTEENF_REPORT_FAILURE,
    payload: err
  };
}

export function initLoadThirteenFReportActionStatus() {
  return {
    type: INIT_LOAD_THIRTEENF_REPORT_ACTION_STATUS
  };
}