import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import GridColumnMap from '../GridColumnMap';

import {
  // Generate Short Position Report
  START_GENERATE_SHORT_POSITION_REPORT,
  GENERATE_SHORT_POSITION_REPORT_SUCCESS,
  GENERATE_SHORT_POSITION_REPORT_FAILURE,
  INIT_GENERATE_SHORT_POSITION_REPORT_ACTION_STATUS,

  // Load Short Position Report
  START_LOAD_SHORT_POSITION_REPORT,
  LOAD_SHORT_POSITION_REPORT_SUCCESS,
  LOAD_SHORT_POSITION_REPORT_FAILURE,
  INIT_LOAD_SHORT_POSITION_REPORT_ACTION_STATUS,

  // Open/Reset Short Position Report Check Result Modal Dialog
  SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN,
  SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET,

  // Load Short Position Report Check Result
  START_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT,
  LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_SUCCESS,
  LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_FAILURE,
  INIT_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_ACTION_STATUS,

  // Report Short Position Report
  START_REPORT_SHORT_POSITION_REPORT,
  REPORT_SHORT_POSITION_REPORT_SUCCESS,
  REPORT_SHORT_POSITION_REPORT_FAILURE,
  INIT_REPORT_SHORT_POSITION_REPORT_ACTION_STATUS,

} from './shortPositionReportConstants';

const initialState = {
  // Grdi Columns
  shortPositionReportGridColumns: GridColumnMap.shortPositionReportGridColumns,
  shortPosReportChkResultGridColumns: GridColumnMap.shortPosReportChkResultGridColumns,

  // Short Position Report
  shortPositionReportArray: [],

  // Load Short Position Report Action Status
  loadShortPositionReportActionStatus: ACTION_STATUS.READY,
  loadShortPositionReportErrMsg: '',

  // Generate Short Position Report Action Status
  generateShortPositionReportActionStatus: ACTION_STATUS.READY,
  generateShortPositionReportErrMsg: '',

  // Short Position Report Check Result Modal Dialog
  shortPositionReportCheckResultControlModal: {
    isOpened: false,
    fundCode: '', 
    dateString: '',
  },

  // Short Position Report Check Result
  shortPosReportChkResult: [],

  // Load Short Position Report Check Result Action Status
  loadShortPositionReportCheckResultActionStatus: ACTION_STATUS.READY,
  loadShortPositionReportCheckResultErrMsg: '',

  // Report Short Position Report
  reportShortPositionReportActionStatus: ACTION_STATUS.READY,
  reportFileArray: [],
  reportShortPositionReportErrMsg: '',

} 

// Generate Short Position Report
function startGenerateShortPositionReport(state) {
  return {
    ...state,
    generateShortPositionReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function generateShortPositionReportSuccess(state, resp) {
  return {
    ...state,
    generateShortPositionReportActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function generateShortPositionReportFailure(state, err) {
  return {
    ...state,
    generateShortPositionReportActionStatus: ACTION_STATUS.ERROR,
    generateShortPositionReportErrMsg: err.respMessage,
  };
}

function initGenerateShortPositionReportActionStatus(state) {
  return {
    ...state,
    generateShortPositionReportActionStatus: ACTION_STATUS.READY,
  };
}

// Load Short Position Report
function startLoadShortPositionReport(state) {
  return {
    ...state,
    loadShortPositionReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadShortPositionReportSuccess(state, resp) {
  return {
    ...state,
    loadShortPositionReportActionStatus: ACTION_STATUS.SUCCESS,
    shortPositionReportArray: resp.data,
  };
}

function loadShortPositionReportFailure(state, err) {
  return {
    ...state,
    loadShortPositionReportActionStatus: ACTION_STATUS.ERROR,
    loadShortPositionReportErrMsg: err.respMessage,
  };
}

function initLoadShortPositionReportActionStatus(state) {
  return {
    ...state,
    loadShortPositionReportActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Short Position Report Check Result Modal Dialog
function openShortPositionReportCheckResultModalDialog(state, { fundCode, dateString }) {
  return {
    ...state,
    shortPositionReportCheckResultControlModal: {
      ...state.shortPositionReportCheckResultControlModal,
      isOpened: true,
      fundCode,
      dateString,
    },
  };
}

function resetShortPositionReportCheckResultModalDialog(state) {
  return {
    ...state,
    shortPositionReportCheckResultControlModal: initialState.shortPositionReportCheckResultControlModal,
  };
}

// Load Short Position Report Check Result
function startLoadShortPositionReportCheckResult(state) {
  return {
    ...state,
    loadShortPositionReportCheckResultActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadShortPositionReportCheckResultSuccess(state, resp) {
  return {
    ...state,
    loadShortPositionReportCheckResultActionStatus: ACTION_STATUS.SUCCESS,
    shortPosReportChkResult: resp.data,
  };
}

function loadShortPositionReportCheckResultFailure(state, err) {
  return {
    ...state,
    loadShortPositionReportCheckResultActionStatus: ACTION_STATUS.ERROR,
    loadShortPositionReportCheckResultErrMsg: err.respMessage,
  };
}

function initLoadShortPositionReportCheckResultActionStatus(state) {
  return {
    ...state,
    loadShortPositionReportCheckResultActionStatus: ACTION_STATUS.READY,
  };
}

// Report Short Position Report
function startReportShortPositionReport(state) {
  return {
    ...state,
    reportShortPositionReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function reportShortPositionReportSuccess(state, resp) {
  const fileNameArray = resp.data;
  const reportFileArray = fileNameArray.map(fileName => BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_SHORT_POSITION_REPORT + fileName);
  
  console.log(reportFileArray);
  return {
    ...state,
    reportShortPositionReportActionStatus: ACTION_STATUS.SUCCESS,
    reportFileArray,
  };
}

function reportShortPositionReportFailure(state, err) {
  return {
    ...state,
    reportShortPositionReportActionStatus: ACTION_STATUS.ERROR,
    reportShortPositionReportErrMsg: err.respMessage,
  };
}

function initReportShortPositionReportActionStatus(state) {
  return {
    ...state,
    reportShortPositionReportActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Generate Short Position Report
  [START_GENERATE_SHORT_POSITION_REPORT]: startGenerateShortPositionReport,
  [GENERATE_SHORT_POSITION_REPORT_SUCCESS]: generateShortPositionReportSuccess,
  [GENERATE_SHORT_POSITION_REPORT_FAILURE]: generateShortPositionReportFailure,
  [INIT_GENERATE_SHORT_POSITION_REPORT_ACTION_STATUS]: initGenerateShortPositionReportActionStatus,

  // Load Short Position Report
  [START_LOAD_SHORT_POSITION_REPORT]: startLoadShortPositionReport,
  [LOAD_SHORT_POSITION_REPORT_SUCCESS]: loadShortPositionReportSuccess,
  [LOAD_SHORT_POSITION_REPORT_FAILURE]: loadShortPositionReportFailure,
  [INIT_LOAD_SHORT_POSITION_REPORT_ACTION_STATUS]: initLoadShortPositionReportActionStatus,

  // Open/Reset Short Position Report Check Result Modal Dialog
  [SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN]: openShortPositionReportCheckResultModalDialog,
  [SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET]: resetShortPositionReportCheckResultModalDialog,

  // Load Short Position Report Check Result
  [START_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT]: startLoadShortPositionReportCheckResult,
  [LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_SUCCESS]: loadShortPositionReportCheckResultSuccess,
  [LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_FAILURE]: loadShortPositionReportCheckResultFailure,
  [INIT_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_ACTION_STATUS]: initLoadShortPositionReportCheckResultActionStatus,

  // Report Short Position Report
  [START_REPORT_SHORT_POSITION_REPORT]: startReportShortPositionReport,
  [REPORT_SHORT_POSITION_REPORT_SUCCESS]: reportShortPositionReportSuccess,
  [REPORT_SHORT_POSITION_REPORT_FAILURE]: reportShortPositionReportFailure,
  [INIT_REPORT_SHORT_POSITION_REPORT_ACTION_STATUS]: initReportShortPositionReportActionStatus,

});