import React from 'react';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';

function QuestionAttatchment(props) {
  const attachment = props.attachment;
  const attachmentOriginName = props.attachmentOriginName;

  const attachmentArray = attachment.split(',');
  const attachmentOriginNameArray = attachmentOriginName.split(',');
  const downloadAttachment = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_QUESTIONNAIRE_ATTACHMENT;

  const fileInfoArray = [];
  attachmentArray.forEach(questionnaireAttachment => {
    let lastUnderlineIndex = questionnaireAttachment.lastIndexOf('_');
    const questionnaireAttachmentPart1 = questionnaireAttachment.substring(0, lastUnderlineIndex);
    const questionnaireAttachmentPart2 = questionnaireAttachment.substring(lastUnderlineIndex + 1);
    let dotIndex = questionnaireAttachmentPart2.indexOf('.');
    const questionnaireAttachmentExtName = questionnaireAttachmentPart2.substring(dotIndex);
    const assembleFileName = questionnaireAttachmentPart1 + questionnaireAttachmentExtName;

    const filterOriginNameArray = attachmentOriginNameArray.filter(attachmentOriginName => attachmentOriginName === assembleFileName);
    const questionnaireOriginAttachmentName = filterOriginNameArray[0];

    const fileInfo = {
      url: downloadAttachment + '/' + questionnaireAttachment,
      attachment: questionnaireAttachment,
      attachmentOriginName: questionnaireOriginAttachmentName,
    };

    fileInfoArray.push(fileInfo);
  }); 

  return (<div style={{ marginTop: 10 }}>
    <div>Template 模板</div>
    {
      fileInfoArray.map(fileInfo => {
        return (<div style={{ marginTop: 5 }} key={fileInfo.url}>
          <a href={fileInfo.url} target="_blank" key={fileInfo.url} style={{ marginLeft: 10 }}>{fileInfo.attachmentOriginName}</a>
        </div>);
      })
    }
  </div>)
}

export default QuestionAttatchment;