import {connect} from 'react-redux';
import PortfolioMonitoringRuleDashboard from '../components/PortfolioMonitoringRuleDashboard';


import {
    // query
    onQueryInputChange,
    loadPortfolioMonitoringRuleInfo,
    initLoadPortfolioMonitoringRuleInfoActionStatus,
    // select
    selectPortfolioMonitoringRule,
    // add or edit
    openAddOrEditPortfolioMonitoringRuleModal,
    addOrEditPortfolioMonitoringRuleChangeInput,
    resetSubmitSavePortfolioMonitoringRuleModal,
    submitSavePortfolioMonitoringRule,
    submitSavePortfolioMonitoringRuleFailure,
    initSubmitSavePortfolioMonitoringRuleActionStatus,

    //Sub add or edit
    openAddOrEditWorkManagementSubModal,
    addOrEditWorkManagementSubChangeInput,
    resetSubmitSaveWorkManagementSubModal,
    submitSaveWorkManagementSub,
    submitSaveWorkManagementSubFailure,
    initSubmitSaveWorkManagementSubActionStatus,



    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForPortfolioMonitoringRule,
    initBatchChangeStatusForPortfolioMonitoringRuleActionStatus,
    // finish
    selectPortfolioMonitoringRuleTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishPortfolioMonitoringRuleTask,
    initFinishPortfolioMonitoringRuleTaskActionStatus,

} from '../PortfolioMonitoringRuleActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';

import {
    queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
    return {
        // basic options
        categoryOptions: state.portfolioMonitoringRule.categoryOptions,
        statusOptions: state.portfolioMonitoringRule.statusOptions,
        ruleTypeOptions: state.portfolioMonitoringRule.ruleTypeOptions,
        imIaOptions: state.portfolioMonitoringRule.imIaOptions,
        fundOptions: state.portfolioMonitoringRule.fundOptions,
        assetTypeOptions: state.portfolioMonitoringRule.assetTypeOptions,
        directionOptions: state.portfolioMonitoringRule.directionOptions,
        unitValueOptions: state.portfolioMonitoringRule.unitValueOptions,
        sumOfOptions: state.portfolioMonitoringRule.sumOfOptions,
        numeratorOptions: state.portfolioMonitoringRule.numeratorOptions,
        denominatorOptions: state.portfolioMonitoringRule.denominatorOptions,
        
        inCurrencyOptions: state.portfolioMonitoringRule.inCurrencyOptions,
        marketOptions: state.portfolioMonitoringRule.marketOptions,
        userAccountOptions: state.userAccess.userAccountOptions,

        // query
        portfolioMonitoringRuleQueryFields: state.portfolioMonitoringRule.portfolioMonitoringRuleQueryFields,
        loadPortfolioMonitoringRuleInfoActionStatus: state.portfolioMonitoringRule.loadPortfolioMonitoringRuleInfoActionStatus,
        loadPortfolioMonitoringRuleInfoResp: state.portfolioMonitoringRule.loadPortfolioMonitoringRuleInfoResp,
        // select
        selectedPortfolioMonitoringRuleArray: state.portfolioMonitoringRule.selectedPortfolioMonitoringRuleArray,
        // add or edit
        addOrEditPortfolioMonitoringRuleModal: state.portfolioMonitoringRule.addOrEditPortfolioMonitoringRuleModal,
        addOrEditPortfolioMonitoringRuleStatus: state.portfolioMonitoringRule.addOrEditPortfolioMonitoringRuleStatus,

        // sub add or edit
        addOrEditPortfolioMonitoringRuleSubModal: state.portfolioMonitoringRule.addOrEditPortfolioMonitoringRuleSubModal,
        addOrEditPortfolioMonitoringRuleSubStatus: state.portfolioMonitoringRule.addOrEditPortfolioMonitoringRuleSubStatus,



        // batch change status
        batchChangeStatusModal: state.portfolioMonitoringRule.batchChangeStatusModal,
        batchChangeStatusForPortfolioMonitoringRuleStatus: state.portfolioMonitoringRule.batchChangeStatusForPortfolioMonitoringRuleStatus,
        // finish
        selectedPortfolioMonitoringRuleTaskArray: state.portfolioMonitoringRule.selectedPortfolioMonitoringRuleTaskArray,
        finishTaskModal: state.portfolioMonitoringRule.finishTaskModal,
        finishPortfolioMonitoringRuleTaskStatus: state.portfolioMonitoringRule.finishPortfolioMonitoringRuleTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.portfolioMonitoringRule.userAccountEmailOptions,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadPortfolioMonitoringRuleInfo: (params) => dispatch(loadPortfolioMonitoringRuleInfo(params)),
        initLoadPortfolioMonitoringRuleInfoActionStatus: () => dispatch(initLoadPortfolioMonitoringRuleInfoActionStatus()),
        // select
        selectPortfolioMonitoringRule: (array) => dispatch(selectPortfolioMonitoringRule(array)),
        // add or edit
        openAddOrEditPortfolioMonitoringRuleModal: (mode, data) => dispatch(openAddOrEditPortfolioMonitoringRuleModal(mode, data)),
        addOrEditPortfolioMonitoringRuleChangeInput: (data) => dispatch(addOrEditPortfolioMonitoringRuleChangeInput(data)),
        resetSubmitSavePortfolioMonitoringRuleModal: () => dispatch(resetSubmitSavePortfolioMonitoringRuleModal()),
        submitSavePortfolioMonitoringRule: (bean) => dispatch(submitSavePortfolioMonitoringRule(bean)),
        submitSavePortfolioMonitoringRuleFailure: (err) => dispatch(submitSavePortfolioMonitoringRuleFailure(err)),
        initSubmitSavePortfolioMonitoringRuleActionStatus: () => dispatch(initSubmitSavePortfolioMonitoringRuleActionStatus()),


        //sub add or edit
        openAddOrEditWorkManagementSubModal: (mode, data,idList) => dispatch(openAddOrEditWorkManagementSubModal(mode, data,idList)),
        addOrEditWorkManagementSubChangeInput: (data) => dispatch(addOrEditWorkManagementSubChangeInput(data)),
        resetSubmitSaveWorkManagementSubModal: () => dispatch(resetSubmitSaveWorkManagementSubModal()),
        submitSaveWorkManagementSub: (bean) => dispatch(submitSaveWorkManagementSub(bean)),
        submitSaveWorkManagementSubFailure: (err) => dispatch(submitSaveWorkManagementSubFailure(err)),
        initSubmitSaveWorkManagementSubActionStatus: () => dispatch(initSubmitSaveWorkManagementSubActionStatus()),



        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForPortfolioMonitoringRule: (params) => dispatch(batchChangeStatusForPortfolioMonitoringRule(params)),
        initBatchChangeStatusForPortfolioMonitoringRuleActionStatus: () => dispatch(initBatchChangeStatusForPortfolioMonitoringRuleActionStatus()),
        // finish
        selectPortfolioMonitoringRuleTask: (array) => dispatch(selectPortfolioMonitoringRuleTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishPortfolioMonitoringRuleTask: (task) => dispatch(finishPortfolioMonitoringRuleTask(task)),
        initFinishPortfolioMonitoringRuleTaskActionStatus: () => dispatch(initFinishPortfolioMonitoringRuleTaskActionStatus()),
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(PortfolioMonitoringRuleDashboard);
