import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load Banned Trade Request
  START_LOAD_BANNED_TRADE_REQUEST,
  LOAD_BANNED_TRADE_REQUEST_SUCCESS,
  LOAD_BANNED_TRADE_REQUEST_FAILURE,
  INIT_LOAD_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Select Banned Trade Request
  SELECT_BANNED_TRADE_REQUEST,

  // Open/Reset Banned Trade Request Modal Dialog
  BANNED_TRADE_REQUEST_MODAL_DIALOG_OPEN,
  BANNED_TRADE_REQUEST_MODAL_DIALOG_RESET,

  // Banned Trade Request Edit Change Input
  BANNED_TRADE_REQUEST_EDIT_CHANGE_INPUT,

  // Approve Banned Trade Request
  START_APPROVE_BANNED_TRADE_REQUEST,
  APPROVE_BANNED_TRADE_REQUEST_SUCCESS,
  APPROVE_BANNED_TRADE_REQUEST_FAILURE,
  INIT_APPROVE_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Reject Banned Trade Request
  START_REJECT_BANNED_TRADE_REQUEST,
  REJECT_BANNED_TRADE_REQUEST_SUCCESS,
  REJECT_BANNED_TRADE_REQUEST_FAILURE,
  INIT_REJECT_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Feedback Banned Trade Request
  START_FEEDBACK_BANNED_TRADE_REQUEST,
  FEEDBACK_BANNED_TRADE_REQUEST_SUCCESS,
  FEEDBACK_BANNED_TRADE_REQUEST_FAILURE,
  INIT_FEEDBACK_BANNED_TRADE_REQUEST_ACTION_STATUS,
  
  // Update Banned Trade Request
  START_UPDATE_BANNED_TRADE_REQUEST,
  UPDATE_BANNED_TRADE_REQUEST_SUCCESS,
  UPDATE_BANNED_TRADE_REQUEST_FAILURE,
  INIT_UPDATE_BANNED_TRADE_REQUEST_ACTION_STATUS,
} from './bannedTradeRequestConstants';

const initialState = {
  bannedTradeRequestGridColumns: GridColumnMap.bannedTradeRequestGridColumns,

  // Banned Trade Request
  bannedTradeRequestArray: [],

  // bannedTradeRequestEditControlModal: {
  //   isOpened: false,
  //   respErrMsg: '',
  //   fields: {
  //     id: '',
  //     feedback: '',
  //   }
  // },

  // Selected Banned Trade Request
  selectedBannedTradeRequestArray: [],

  // Load Banned Trade Request Action Status
  loadBannedTradeRequestActionStatus: ACTION_STATUS.READY,

  // Approve Banned Trade Request Action Status
  approveBannedTradeRequestActionStatus: ACTION_STATUS.READY,

  // Reject Banned Trade Request Action Status
  rejectBannedTradeRequestActionStatus: ACTION_STATUS.READY,

  // Feedback Banned Trade Request Action Status
  feedbackBannedTradeRequestActionStatus: ACTION_STATUS.READY,

  // Banned Trade Edit
  bannedTradeRequestEditControlModal: {
    isOpened: false,
    isAddMode: true,
    respErrMsg: '',
    fields: {
      id: '',
      securityId: '',
      bbgTicker: '',
      secName: '',
      enlistedDate: '',
      delistedDate: '',
      counterparty: '',
      responsiblePM: '',
      remark: '',
      reason: '',
      feedback: '',
    }
  },

  // Update Banned Trade Request Action Status
  updateBannedTradeRequestActionStatus: ACTION_STATUS.READY,
}

// Load Banned Trade Request
function startLoadBannedTradeRequest(state) {
  return {
    ...state,
    loadBannedTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadBannedTradeRequestSuccess(state, resp) {
  return {
    ...state,
    loadBannedTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
    bannedTradeRequestArray: resp.data,
  };
}

function loadBannedTradeRequestFailure(state, err) {
  return {
    ...state,
    loadBannedTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadBannedTradeRequestActionStatus(state) {
  return {
    ...state,
    loadBannedTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}


// Select Banned Trade Request
function selectBannedTradeRequest(state, payload) {
  return {
    ...state,
    selectedBannedTradeRequestArray: payload,
  };
}

// Open/Reset Banned Trade Request Modal Dialog
function openBannedTradeRequestEditModalDialog(state, {bannedTradeRequest}) {
  return {
    ...state,
    bannedTradeRequestEditControlModal: {
      ...state.bannedTradeRequestEditControlModal,
      isOpened: true,
      fields: {
        ...state.bannedTradeRequestEditControlModal.fields,
        id: bannedTradeRequest.id,
        securityId: bannedTradeRequest.securityId,
        bbgTicker: bannedTradeRequest.bbgTicker,
        secName: bannedTradeRequest.secName,
        counterparty: bannedTradeRequest.counterparty,
        responsiblePM: bannedTradeRequest.responsiblePM,
        reason: bannedTradeRequest.reason,
        remark: bannedTradeRequest.reason,
        feedback: bannedTradeRequest.feedback,
      }
    },
  };
}

function resetBannedTradeRequestEditModalDialog(state) {
  return {
    ...state,
    bannedTradeRequestEditControlModal: initialState.bannedTradeRequestEditControlModal,
  };
}

// Banned Trade Request Edit Change Input
function bannedTradeRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.bannedTradeRequestEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    bannedTradeRequestEditControlModal: {
      ...state.bannedTradeRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// Approve Banned Trade Request
function startApproveBannedTradeRequest(state) {
  return {
    ...state,
    approveBannedTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function approveBannedTradeRequestSuccess(state, resp) {
  return {
    ...state,
    approveBannedTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function approveBannedTradeRequestFailure(state, err) {
  return {
    ...state,
    approveBannedTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initApproveBannedTradeRequestActionStatus(state) {
  return {
    ...state,
    approveBannedTradeRequestActionStatus: ACTION_STATUS.READY,
    selectedBannedTradeRequestArray: [],
  };
}

// Reject Banned Trade Request
function startRejectBannedTradeRequest(state) {
  return {
    ...state,
    rejectBannedTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function rejectBannedTradeRequestSuccess(state, resp) {
  return {
    ...state,
    rejectBannedTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function rejectBannedTradeRequestFailure(state, err) {
  return {
    ...state,
    rejectBannedTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initRejectBannedTradeRequestActionStatus(state) {
  return {
    ...state,
    rejectBannedTradeRequestActionStatus: ACTION_STATUS.READY,
    selectedBannedTradeRequestArray: [],
  };
}

// Feedback Banned Trade Request
function startFeedbackBannedTradeRequest(state) {
  return {
    ...state,
    feedbackBannedTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function feedbackBannedTradeRequestSuccess(state, resp) {
  return {
    ...state,
    feedbackBannedTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function feedbackBannedTradeRequestFailure(state, err) {
  return {
    ...state,
    feedbackBannedTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initFeedbackBannedTradeRequestActionStatus(state) {
  return {
    ...state,
    feedbackBannedTradeRequestActionStatus: ACTION_STATUS.READY,
    selectedBannedTradeRequestArray: [],
  };
}


// Update Banned Trade Request
function startUpdateBannedTradeRequest(state) {
  return {
    ...state,
    updateBannedTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateBannedTradeRequestSuccess(state, resp) {
  return {
    ...state,
    updateBannedTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateBannedTradeRequestFailure(state, err) {
  return {
    ...state,
    updateBannedTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initUpdateBannedTradeRequestActionStatus(state) {
  return {
    ...state,
    updateBannedTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Banned Trade Request
  [START_LOAD_BANNED_TRADE_REQUEST]: startLoadBannedTradeRequest,
  [LOAD_BANNED_TRADE_REQUEST_SUCCESS]: loadBannedTradeRequestSuccess,
  [LOAD_BANNED_TRADE_REQUEST_FAILURE]: loadBannedTradeRequestFailure,
  [INIT_LOAD_BANNED_TRADE_REQUEST_ACTION_STATUS]: initLoadBannedTradeRequestActionStatus,

  // Select Banned Trade Request
  [SELECT_BANNED_TRADE_REQUEST]: selectBannedTradeRequest,

  // Open/Reset Banned Trade Request Modal Dialog
  [BANNED_TRADE_REQUEST_MODAL_DIALOG_OPEN]: openBannedTradeRequestEditModalDialog,
  [BANNED_TRADE_REQUEST_MODAL_DIALOG_RESET]: resetBannedTradeRequestEditModalDialog,

  // Banned Trade Request Edit Change Input
  [BANNED_TRADE_REQUEST_EDIT_CHANGE_INPUT]: bannedTradeRequestEditChangeInput,

  // Approve Banned Trade Request
  [START_APPROVE_BANNED_TRADE_REQUEST]: startApproveBannedTradeRequest,
  [APPROVE_BANNED_TRADE_REQUEST_SUCCESS]: approveBannedTradeRequestSuccess,
  [APPROVE_BANNED_TRADE_REQUEST_FAILURE]: approveBannedTradeRequestFailure,
  [INIT_APPROVE_BANNED_TRADE_REQUEST_ACTION_STATUS]: initApproveBannedTradeRequestActionStatus,

  // Reject Banned Trade Request
  [START_REJECT_BANNED_TRADE_REQUEST]: startRejectBannedTradeRequest,
  [REJECT_BANNED_TRADE_REQUEST_SUCCESS]: rejectBannedTradeRequestSuccess,
  [REJECT_BANNED_TRADE_REQUEST_FAILURE]: rejectBannedTradeRequestFailure,
  [INIT_REJECT_BANNED_TRADE_REQUEST_ACTION_STATUS]: initRejectBannedTradeRequestActionStatus,

  // Feedback Banned Trade Request
  [START_FEEDBACK_BANNED_TRADE_REQUEST]: startFeedbackBannedTradeRequest,
  [FEEDBACK_BANNED_TRADE_REQUEST_SUCCESS]: feedbackBannedTradeRequestSuccess,
  [FEEDBACK_BANNED_TRADE_REQUEST_FAILURE]: feedbackBannedTradeRequestFailure,
  [INIT_FEEDBACK_BANNED_TRADE_REQUEST_ACTION_STATUS]: initFeedbackBannedTradeRequestActionStatus,

  // Update Banned Trade Request
  [START_UPDATE_BANNED_TRADE_REQUEST]: startUpdateBannedTradeRequest,
  [UPDATE_BANNED_TRADE_REQUEST_SUCCESS]: updateBannedTradeRequestSuccess,
  [UPDATE_BANNED_TRADE_REQUEST_FAILURE]: updateBannedTradeRequestFailure,
  [INIT_UPDATE_BANNED_TRADE_REQUEST_ACTION_STATUS]: initUpdateBannedTradeRequestActionStatus,
});