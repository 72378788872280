const APPROVE = 'APPROVE';
const REJECT = 'REJECT';
const DELETE = 'DELETE';
const FEEDBACK = 'FEEDBACK';
const ONLIST = 'ONLIST';
const OFFLIST = 'OFFLIST';

export default {
  APPROVE,
  REJECT,
  DELETE,
  FEEDBACK,
  ONLIST,
  OFFLIST,
};
