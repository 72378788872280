

const fundGridColumns = [
  {
    field: 'fundCode',
    headerName: "Fund Code",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'fundDesc',
    headerName: "Fund Desc",
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'fundExtendedDesc',
    headerName: 'Fund Extended Desc',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'isActiveLabel',
    headerName: 'Is Active',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'isRealLabel',
    headerName: 'Is Real',
    cellClass: 'non-number',
    width: 150,
  },
];

const bookGridColumns = [
  {
    field: 'fundCode',
    headerName: "Fund Code",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'bookCode',
    headerName: "Book Code",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'fundBookCode',
    headerName: "Fund Book Code",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'isActiveLabel',
    headerName: 'Is Active',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'isRealLabel',
    headerName: 'Is Real',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'ownerTeam',
    headerName: 'Owner Team',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'manager',
    headerName: 'Manager',
    cellClass: 'non-number',
    width: 150,
  },
];

const counterpartyListGridColumns = [
  {
    field: 'counterpartyCode',
    headerName: "Counterparty Code",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'counterpartyDesc',
    headerName: "Counterparty Desc",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'counterpartyExtendedDesc',
    headerName: "Counterparty Extended Desc",
    cellClass: 'non-number',
    width: 400,
  },
];

const custodianGridColumns = [
  {
    field: 'custodianCode',
    headerName: "Custodian Code",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'custodianDesc',
    headerName: "Custodian Desc",
    cellClass: 'non-number',
    width: 150,
  },
];

const custodianAccountGridColumns = [
  {
    field: 'fundCode',
    headerName: "Fund Code",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'custodianCode',
    headerName: "Custodian Code",
    cellClass: 'non-number',
    width: 160,
  },
  {
    field: 'custodianAccountCode',
    headerName: "Custodian Account Code",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'custodianAccountDesc',
    headerName: "Custodian Account Desc",
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'custodianAccountTypeCode',
    headerName: "Custodian Account Type Code",
    cellClass: 'non-number',
    width: 160,
  },
];

const GridColumnMap = {
  fundGridColumns,
  bookGridColumns,
  counterpartyListGridColumns,
  custodianGridColumns,
  custodianAccountGridColumns,
};

export default GridColumnMap;