import {connect} from 'react-redux';
import ThirteenFReportDashboard from '../components/ThirteenFReportDashboard';

import {
  // Sync 13F Report
  syncThirteenFReport,
  initSyncThirteenFReportActionStatus,

  // Load 13F Report
  loadThirteenFReport,
  initLoadThirteenFReportActionStatus,

} from '../thirteenFReportActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    thirteenFReportMetaGridColumns: state.thirteenFReport.thirteenFReportMetaGridColumns,
    thirteenFReportGridColumns: state.thirteenFReport.thirteenFReportGridColumns,

    // 13F Report
    thirteenFReportArray: state.thirteenFReport.thirteenFReportArray,

    // Sync 13F Report Action Status
    syncThirteenFReportActionStatus: state.thirteenFReport.syncThirteenFReportActionStatus,
    syncThirteenFReportErrMsg: state.thirteenFReport.syncThirteenFReportErrMsg,

    // Load 13F Report Action Status
    loadThirteenFReportActionStatus: state.thirteenFReport.loadThirteenFReportActionStatus,
    loadThirteenFReportErrMsg: state.thirteenFReport.loadThirteenFReportErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Sync 13F Report
    syncThirteenFReport: () => dispatch(syncThirteenFReport()),
    initSyncThirteenFReportActionStatus: () => dispatch(initSyncThirteenFReportActionStatus()),

    // Load 13F Report
    loadThirteenFReport: () => dispatch(loadThirteenFReport()),
    initLoadThirteenFReportActionStatus: () => dispatch(initLoadThirteenFReportActionStatus()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirteenFReportDashboard);