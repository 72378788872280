import React, { Component } from 'react';
import { Modal, message, Button, Row, Col ,Input,Upload} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import OperationRecordGrid from './OperationRecordGrid';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import Table from 'braft-extensions/dist/table'
import 'braft-extensions/dist/table.css'
const TextArea = Input.TextArea;
const blockImportFn = Table()[2].importer;
const blockExportFn = Table()[2].exporter;
class OperationRecordDetail extends Component {

    closeDetailPage() {
        this.props.doOperationRecordAction({action:'RESET_DETAIL'});
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        // return (
        //     <div>
        //       {
        //         url && 
        //         <a href={url} target="_blank">{attachmentOriginName}</a>
        //       }
        //     </div>
        //   );
        attArray.forEach(e => {
            // if(!first) {
            //     retElement.append(' | ');
            // }
            // first = false;

            // const url = this.props.meetingAndTrainingCalenderDownloadUrl + '/' + e;
            // const link = document.createElement('a');
            // link.innerText = e;
            // link.target = '_blank';
            // link.href = url;
            // retElement.appendChild(link);

        });
        return retElement;
    }

    render() {
        const options = {
            defaultColumns: 3, // 默认列数
            defaultRows: 3, // 默认行数
            withDropdown: true, // 插入表格前是否弹出下拉菜单
            columnResizable: true, // 是否允许拖动调整列宽，默认false
            exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
            includeEditors: ['editor-id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
            excludeEditors: ['editor-id-2'],  // 指定该模块对哪些BraftEditor无效
            exportAttrString: 'border="1" style="border-collapse: collapse"'
          }
          BraftEditor.use(Table(options))  
        
          
          const extendControlsContent = [
              {
                key: 'antd-uploader',
                type: 'component',
                component: (
                  <Upload
                    accept="*"
                    showUploadList={false}
                  >
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                      上传图片
                    </button>
                  </Upload>
                ),
              },
          ];
          const {
            operationRecordDialogModal,
        } = this.props.operationRecord;
        const {
            isDetailOpened,
            detail,
        } = operationRecordDialogModal;

        const showFeedback = detail===null? false:detail.feedback ==='true'
        const showFileList = detail===null? false:detail.filepath === null ? false : true
        const url = this.props.meetingAndTrainingCalenderDownloadUrl + '/'
        let attArray =[]
        if(!!detail && !!detail.filepath){
             attArray = detail.filepath.split(';');
        }
        

        let beforeObj = JSON.parse('{}');
        let afterObj = JSON.parse('{}');
        if(!!detail && !!detail.before) {
            beforeObj = JSON.parse(detail.before);
        }
        if(!!detail && !!detail.after) {
            afterObj = JSON.parse(detail.after);
        }

        const detailTableBodyArray = [];
        for(let key in beforeObj) {
            detailTableBodyArray.push(<tr key={key}><td>{key}</td><td>{beforeObj[key]}</td><td>{afterObj[key]}</td></tr>);
        }
        for(let key in afterObj) {
            if(!beforeObj.hasOwnProperty(key)) {
                detailTableBodyArray.push(<tr key={key}><td>{key}</td><td>{beforeObj[key]}</td><td>{afterObj[key]}</td></tr>);
            }
        }
        const editorState =!!detail ? BraftEditor.createEditorState(detail.note, { blockImportFn, blockExportFn }): '';
        return (
            <div>
                <Modal
                    title={'Record Detail'}
                    centered
                    width={800}
                    visible={isDetailOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.closeDetailPage()}
                    footer={[
                        <Button key="back" onClick={() => this.closeDetailPage()}>
                          Close
                        </Button>,
                      ]}
                >

                    {/* <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>operator:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operator: ''}</Col>
                        <Col span={6} align={'right'}><b>operateTime:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operateTime: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>module:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.module: ''}</Col>
                        <Col span={6} align={'right'}><b>dataId:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.dataId: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>operateSource:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operateSource: ''}</Col>
                        <Col span={6} align={'right'}><b>operateType:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operateType: ''}</Col>
                    </Row>*/}
                    {/* <Row gutter={24} style={{marginTop: 5}}>
                            <b>Sub Title: detail.subtitle</b>
                    </Row> */}
                    <Row gutter={24} style={{marginTop: 5}}>
                        <b>Note:</b>
                   
                    </Row> 
                    <Row gutter={24} style={{marginTop: 5}}>
                            {/* <TextArea 
                                style={{ width: 780, marginLeft: 10 }}
                                rows={5} 
                                value={!!detail ? detail.note: ''} 
                                onChange={(e) => {
                                    this.onInputChange({ name: 'remarks', value: e.target.value })
                                }}
                            /> */}

                            <BraftEditor
                                    id="editor-id-1"
                                    value={editorState}
                                    // onChange={this.handleEditorChange}
                                    // onSave={this.submitContent}
                                    extendControls={extendControlsContent}
                            /> 

                    </Row>
                    {/* <Row gutter={24} style={{marginTop: 5}}>
                        <b>Coach's comments:</b>
                   
                    </Row> 
                    <Row gutter={24} style={{marginTop: 5}}>
                            <TextArea 
                                style={{ width: 780, marginLeft: 10 }}
                                rows={5} 
                                value={!!detail ? detail.comments: ''} 
                                onChange={(e) => {
                                    this.onInputChange({ name: 'comments', value: e.target.value })
                                }}
                            />

                    </Row> */}
                    
                        {/* <Row gutter={24} style={{marginTop: 5}}>
                             {
                            showFeedback &&  
                                <b>Feedback:</b>
                             }
                        </Row> 
                        <Row gutter={24} style={{marginTop: 5}}>
                             {
                            showFeedback && 
                                <TextArea 
                                    style={{ width: 780, marginLeft: 10 }}
                                    rows={5} 
                                    value={!!detail ? detail.feedbackComments: ''} 
                                    onChange={(e) => {
                                        this.onInputChange({ name: 'feedbackComments', value: e.target.value })
                                    }}
                                />
                            } 
                        </Row> */}
                        <Row gutter={24} style={{marginTop: 5}}>
                             {
                            showFileList && 
                                // <TextArea 
                                //     style={{ width: 780, marginLeft: 10 }}
                                //     rows={10} 
                                //     value={!!detail ? detail.feedbackComments: ''} 
                                //     onChange={(e) => {
                                //         this.onInputChange({ name: 'feedbackComments', value: e.target.value })
                                //     }}
                                // />
                                <div style={{ marginLeft: "30px",marginTop: "20px"}}>
                                <b>Attachment: </b>
                                { attArray.map(item => {
                                return (<div style={{marginLeft: '10px',marginTop: '5px',height:'25px'}}><a href={url+item} target="_blank">{item}</a><br/></div>)
                                })
                                }
                                </div>
                            } 
                        </Row>
                        
                </Modal>
            </div>
        );
    }
}


export default OperationRecordDetail;

