// Sync 13F Report
export const START_SYNC_THIRTEENF_REPORT = 'START_SYNC_THIRTEENF_REPORT';
export const SYNC_THIRTEENF_REPORT_SUCCESS = 'SYNC_THIRTEENF_REPORT_SUCCESS';
export const SYNC_THIRTEENF_REPORT_FAILURE = 'SYNC_THIRTEENF_REPORT_FAILURE';
export const INIT_SYNC_THIRTEENF_REPORT_ACTION_STATUS = 'INIT_SYNC_THIRTEENF_REPORT_ACTION_STATUS';

// Load 13F Report
export const START_LOAD_THIRTEENF_REPORT = 'START_LOAD_THIRTEENF_REPORT';
export const LOAD_THIRTEENF_REPORT_SUCCESS = 'LOAD_THIRTEENF_REPORT_SUCCESS';
export const LOAD_THIRTEENF_REPORT_FAILURE = 'LOAD_THIRTEENF_REPORT_FAILURE';
export const INIT_LOAD_THIRTEENF_REPORT_ACTION_STATUS = 'INIT_LOAD_THIRTEENF_REPORT_ACTION_STATUS';