import React, { Component } from 'react';
import { Input, Form, Modal, Button, message,DatePicker } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelExpenseSummaryNewModalDialog extends Component {

    refreshQuery() {
        const {
            category,
            invDateStart,
            invDateEnd,
            invNo,
            firm
        } = this.props.expenseSummaryNewQueryFields;
        this.props.loadExpenseSummaryNewInfo({category, invDateStart, invDateEnd, invNo, firm})
    }

    componentDidUpdate() {
        const {
            delExpenseSummaryNewStatus,
        } = this.props;
        if(delExpenseSummaryNewStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Expense Summary Info succeeded');
            this.props.initDelExpenseSummaryNewActionStatus();
            this.props.resetDelExpenseSummaryNewModal();
            this.refreshQuery();
        }
        if(delExpenseSummaryNewStatus === ACTION_STATUS.ERROR) {
            this.props.initDelExpenseSummaryNewActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delExpenseSummaryNewChangeInput({name, value});
    }


    submitDelExpenseSummaryNew = () => {
        Modal.confirm({
            title: 'Are you sure to this record?',
            onOk: () => {
                const {
                    id,
                    guid,
                    comments,
                    mode,
                } = this.props.delExpenseSummaryNewModal.fields;
                this.props.delExpenseSummaryNew({id, guid, comments,mode});
            },
            onCancel: () => {},
        });
    }

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add Expense Summary Info';
        } else if(mode === 'EDIT') {
            return 'Edit Expense Summary Info';
        } else if(mode === 'COPY') {
            return 'Copy Expense Summary Info';
        } else if(mode === 'DEL') {
            return 'DEL Expense Summary Info';
        } else if(mode === 'APPROVE') {
            return 'APPROVE Expense Summary Info';
        } else if(mode === 'REJECT') {
            return 'REJECT Expense Summary Info';
        } else if(mode === 'CALLBACK') {
            return 'CALLBACK Expense Summary Info';
        }else if(mode === 'OLB') {
            return 'OLB Expense Summary Info';
        }else if(mode === 'COMPLETE') {
            return 'CALLBACK Expense Summary Info';
        }
    }

    render() {

        const {
            delExpenseSummaryNewModal,
            delExpenseSummaryNewStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delExpenseSummaryNewModal;

        const isSubmitLoading = delExpenseSummaryNewStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={400}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelExpenseSummaryNewModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelExpenseSummaryNewModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelExpenseSummaryNew}>
                          Submit
                        </Button>,
                      ]}
                      
                >       
                        {
                        mode ==='COMPLETE' &&  
                        <div>
                        <DatePicker 
                            placeholder="PayDate, required"
                            required
                            style={{ width: 200 ,marginTop: 10}}
                            onChange={(date, dateString) => {
                                this.onInputChange({ name: 'payDate', value: dateString })
                            }}
                        />
                         </div>
                         }

                         <div>
                        <TextArea 
                            style={{ width: 200 ,marginTop: 10}}
                            rows={3} 
                            placeholder="comments"
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        </div>
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelExpenseSummaryNewModalDialogForm = Form.create({ name: 'DelExpenseSummaryNewModalDialog' })(DelExpenseSummaryNewModalDialog);
export default DelExpenseSummaryNewModalDialogForm;
