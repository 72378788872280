import {connect} from 'react-redux';
import SFCShortableList from '../components/SFCShortableList';

import {
  // Load SFC Short Interest
  loadSFCShortInterest,
  initLoadSFCShortInterestActionStatus,
} from '../sfcShortActions';

const mapStateToProps = state => {
  return {
    sfcShortableListGridColumns: state.sfcShort.sfcShortableListGridColumns,

    // SFC Short Interest
    sfcShortInterestArray: state.sfcShort.sfcShortInterestArray,

    // Load SFC Short Interest Action Status
    loadSFCShortInterestActionStatus: state.sfcShort.loadSFCShortInterestActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load SFC Short Interest
    loadSFCShortInterest: (dateString) => dispatch(loadSFCShortInterest(dateString)),
    initLoadSFCShortInterestActionStatus: () => dispatch(initLoadSFCShortInterestActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SFCShortableList);