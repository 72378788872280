import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditExpenseSummaryModalDialog extends Component {

    refreshQuery() {
        const {
            category,
            invDateStart,
            invDateEnd,
            invNo,
            firm
        } = this.props.expenseSummaryQueryFields;
        this.props.loadExpenseSummaryInfo({category, invDateStart, invDateEnd, invNo, firm})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add Expense Summary Info';
        } else if(mode === 'EDIT') {
            return 'Edit Expense Summary Info';
        } else {
            return 'Copy Expense Summary Info';
        }
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditExpenseSummaryStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditExpenseSummaryModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditExpenseSummaryStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditExpenseSummaryModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditExpenseSummaryActionStatus();
            this.props.resetAddOrEditExpenseSummaryModal();
            this.refreshQuery();
        }
        if(addOrEditExpenseSummaryStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditExpenseSummaryActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditExpenseSummaryChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditExpenseSummaryModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditExpenseSummaryModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditExpenseSummaryModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
    }


    submitAddOrEditExpenseSummary = () => {
        const {
            addOrEditExpenseSummaryModal,
        } = this.props;
        const {
            mode,
        } = addOrEditExpenseSummaryModal;
        const {
            id,
            guid,
            category,
            firm,
            fundFirmRelated,
            invDate,
            invNo,
            ccy,
            amount,
            fx,
            amtUSD,
            payDate,
            nature,
            remarks,
            payMethod,
            comments,
        } = addOrEditExpenseSummaryModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditExpenseSummary({
                    id,
                    guid,
                    category,
                    firm,
                    fundFirmRelated,
                    invDate,
                    invNo,
                    ccy,
                    amount,
                    fx,
                    amtUSD,
                    payDate,
                    nature,
                    remarks,
                    payMethod,
                    comments,
                    mode,
                });                    
            }
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditExpenseSummaryModal,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditExpenseSummaryStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditExpenseSummaryModal;

        const {
            guid,
            category,
            firm,
            fundFirmRelated,
            invDate,
            invNo,
            ccy,
            amount,
            fx,
            amtUSD,
            payDate,
            nature,
            remarks,
            payMethod,
            comments,
        } = addOrEditExpenseSummaryModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const invDateMoment = !!invDate ? moment(invDate) : null;
        const payDateMoment = !!payDate ? moment(payDate) : null;
        const isSubmitLoading = addOrEditExpenseSummaryStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditExpenseSummaryModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditExpenseSummaryModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditExpenseSummary}>
                          Submit
                        </Button>,
                      ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Category">
                                {getFieldDecorator('category', {
                                    initialValue: category,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Category',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'category', value: val })
                                            }} 
                                            placeholder="Please select Category">
                                        {categoryOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Firm">
                                {getFieldDecorator('firm', {
                                    initialValue: firm,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Firm',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'firm', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Fund/Firm related">
                                {getFieldDecorator('fundFirmRelated', {
                                    initialValue: fundFirmRelated,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Fund/Firm related',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'fundFirmRelated', value: val })
                                            }} 
                                            placeholder="Please select Fund/Firm related">
                                        {fundFirmRelatedOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Inv Date">
                                {getFieldDecorator('invDate', {
                                    initialValue: invDateMoment,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Inv Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'invDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Inv No.">
                                {getFieldDecorator('invNo', {
                                    initialValue: invNo,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Inv No.',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'invNo', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="CCY">
                                {getFieldDecorator('ccy', {
                                    initialValue: ccy,
                                    rules: [{
                                        required: true,
                                        message: 'Please select CCY',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'ccy', value: val })
                                            }} 
                                            placeholder="Please select CCY">
                                        {ccyOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Amount">
                                {getFieldDecorator('amount', {
                                    initialValue: amount,
                                    rules: [{
                                        required: false,
                                        pattern: new RegExp(/^(0|[1-9][0-9]*)(\.[0-9]{1,2})?$/, "g"),
                                        message: 'Please input correct Amount',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'amount', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="FX">
                                {getFieldDecorator('fx', {
                                    initialValue: fx,
                                    rules: [{
                                        required: true,
                                        message: 'Please input FX',
                                    }],
                                    })(
                                    <Input
                                        disabled
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'fx', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="USD Amt">
                                {getFieldDecorator('amtUSD', {
                                    initialValue: amtUSD,
                                    rules: [{
                                        required: false,
                                        message: 'Please input USD Amt',
                                    }],
                                    })(
                                    <Input
                                        disabled
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'amtUSD', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Pay Date">
                                {getFieldDecorator('payDate', {
                                    initialValue: payDateMoment,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Pay Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'payDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Nature">
                                {getFieldDecorator('nature', {
                                    initialValue: nature,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Nature',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'nature', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Payment Method">
                                {getFieldDecorator('payMethod', {
                                    initialValue: payMethod,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Payment Method',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'payMethod', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                {getFieldDecorator('remarks', {
                                    initialValue: remarks,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Remarks',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Comments">
                                {getFieldDecorator('comments', {
                                    initialValue: comments,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Comments',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditExpenseSummaryModalDialogForm = Form.create({ name: 'AddOrEditExpenseSummaryModalDialog' })(AddOrEditExpenseSummaryModalDialog);
export default AddOrEditExpenseSummaryModalDialogForm;
