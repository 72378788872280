import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const FormItem = Form.Item;


class AddOrEditElementTypeDialog extends Component {

    componentDidMount() {

    }


    componentDidUpdate() {
        const {
            addOrEditElementTypeActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditElementTypeModal;
        if(addOrEditElementTypeActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getModalTitle(mode) + ' succeeded';
            message.success(msg);
            this.props.initSubmitAddOrEditElementTypeActionStatus();
            this.props.resetAddOrEditElementTypeModal();
            this.props.queryElementType(this.props.elementTypeQueryFields);
        }
        if(addOrEditElementTypeActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitAddOrEditElementTypeActionStatus();
        }
    }


    onElementTypeAddOrEditInputChange = (param) => {
        this.props.onElementTypeAddOrEditInputChange(param);
    }


    getModalTitle = (mode) => {
        const action = mode === 'ADD' ? 'Add' : 'Edit';
        return action + ' Element Type';
    }


    submitAddOrEditElementType = () => {
        const {
            mode,
            fields,
        } = this.props.addOrEditElementTypeModal;
        const {
            id,
            baseType,
            elementTypeName,
            shape,
            color,
        } = fields;
        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            this.props.submitAddOrEditElementType({
                id,
                baseType,
                elementTypeName,
                shape,
                color,
                mode,
            });
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            addOrEditElementTypeModal,
            addOrEditElementTypeActionStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
        } = addOrEditElementTypeModal;
        const {
            id,
            baseType,
            elementTypeName,
            shape,
            color,
        } = fields;

        const modalTitle = this.getModalTitle(mode);
        const isSubmitLoading = addOrEditElementTypeActionStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditElementTypeModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetAddOrEditElementTypeModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditElementType() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='baseType'>
                                    {getFieldDecorator('baseType', {
                                        initialValue: baseType,
                                        rules: [{
                                            required: true,
                                            message: 'Please choose baseType',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            disabled={mode==='EDIT'}
                                            onChange={(val) => {
                                                this.onElementTypeAddOrEditInputChange({ name: 'baseType', value: val })
                                            }} 
                                            placeholder='baseType'
                                            style={{ width: 200, marginLeft: 10 }}
                                        >
                                            <Select.Option key={'Entity'}>{'Entity'}</Select.Option>
                                            <Select.Option key={'Relation'}>{'Relation'}</Select.Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="elementTypeName">
                                    {getFieldDecorator('elementTypeName', {
                                        initialValue: elementTypeName,
                                        rules: [{
                                            required: true,
                                            message: 'Please input elementTypeName',
                                        }],
                                        })(
                                            <Input placeholder='elementTypeName' 
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        this.onElementTypeAddOrEditInputChange({ name: 'elementTypeName', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='shape(Demo)' >
                                    {getFieldDecorator('shape', {
                                        initialValue: shape,
                                        rules: [{
                                            required: true,
                                            message: 'Please input shape',
                                        }],
                                        })(
                                            <Input placeholder='shape' 
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        this.onElementTypeAddOrEditInputChange({ name: 'shape', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="color(Demo)">
                                    {getFieldDecorator('color', {
                                        initialValue: color,
                                        rules: [{
                                            required: true,
                                            message: 'Please input color',
                                        }],
                                        })(
                                            <Input placeholder='color' 
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        this.onElementTypeAddOrEditInputChange({ name: 'color', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }

}


const AddOrEditElementTypeDialogForm = Form.create({ name: 'AddOrEditElementTypeDialog' })(AddOrEditElementTypeDialog);
export default AddOrEditElementTypeDialogForm;

