import React, {Component} from 'react';

import { Button, message, Breadcrumb, DatePicker, Select } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import ThirteenFLongPosReportGrid from './ThirteenFLongPosReportGrid';
import ThirteenFLongPosReportChkResultDialog from './ThirteenFLongPosReportChkResultDialog';
import moment from 'moment';
import '../../DocumentReport.css';

const Option = Select.Option;
const FundCodeOptions = [];
FundCodeOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
FundCodeOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
FundCodeOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);

class ThirteenFLongPosReportDashboard extends Component {

  state = {
    searchDate: moment(),
    fundCode: '',
  };

  componentDidMount() {
    const {
      searchDate,
      fundCode,
    } = this.state;

    this.props.loadThirteenFLongPosReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  componentDidUpdate() {
    const {
      // Load 13F Long Report Action Status
      loadThirteenFLongPosReportActionStatus,
      loadThirteenFLongPosReportErrMsg,

      // Load 13F Long Report Check Result Action Status
      loadThirteenFLongPosReportCheckResultActionStatus,
      loadThirteenFLongPosReportCheckResultErrMsg,

      // Generate 13F Long Report Action Status
      generateThirteenFLongPosReportActionStatus,
      generateThirteenFLongPosReportErrMsg,

      // Report 13F Long Report
      reportThirteenFLongPosReportActionStatus,
      reportFilePath,
      reportThirteenFLongPosReportErrMsg,

      // Report 13F Long Pos Summary Report
      reportThirteenFLongPosSummaryReportActionStatus,
      summaryReportFilePath,
      reportThirteenFLongPosSummaryReportErrMsg,
    } = this.props;

    const {
      searchDate,
      fundCode,
    } = this.state;

    // Generate 13F Long Report 成功
    if (generateThirteenFLongPosReportActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('13F Long report are generated successfully!');
      this.props.initGenerateThirteenFLongPosReportActionStatus();
      this.props.loadThirteenFLongPosReport(fundCode, searchDate.format("YYYY-MM-DD"));
    }

    // Generate 13F Long Report 失败
    if (generateThirteenFLongPosReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(generateThirteenFLongPosReportErrMsg);
      this.props.initGenerateThirteenFLongPosReportActionStatus();
    }

    // Load 13F Long Report 成功
    if (loadThirteenFLongPosReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadThirteenFLongPosReportActionStatus();
    }

    // Load 13F Long Report 失败
    if (loadThirteenFLongPosReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadThirteenFLongPosReportErrMsg);
      this.props.initLoadThirteenFLongPosReportActionStatus();
    }

    // Load 13F Long Report Check Result 成功
    if (loadThirteenFLongPosReportCheckResultActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadThirteenFLongPosReportCheckResultActionStatus();
    }

    // Load 13F Long Report Check Result 失败
    if (loadThirteenFLongPosReportCheckResultActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadThirteenFLongPosReportCheckResultErrMsg);
      this.props.initLoadThirteenFLongPosReportCheckResultActionStatus();
    }

    // Report 13F Long Report 成功
    if (reportThirteenFLongPosReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initReportThirteenFLongPosReportActionStatus();
      window.open(reportFilePath);
    }

    // Report 13F Long Report 失败
    if (reportThirteenFLongPosReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(reportThirteenFLongPosReportErrMsg);
      this.props.initReportThirteenFLongPosReportActionStatus();
    }

    // Report 13F Long Pos Summary Report 成功
    if (reportThirteenFLongPosSummaryReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initReportThirteenFLongPosSummaryReportActionStatus();
      window.open(summaryReportFilePath);
    }

    // Report 13F Long Pos Summary Report 失败
    if (reportThirteenFLongPosSummaryReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(reportThirteenFLongPosSummaryReportErrMsg);
      this.props.initReportThirteenFLongPosSummaryReportActionStatus();
    }
  }

  fundOnChange = (value) => {
    this.setState({
      fundCode: value,
    });
  }

  searchBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    this.props.loadThirteenFLongPosReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  genreateReportBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    this.props.generateThirteenFLongPosReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  reportBtnOnClicked = () => {
    const {
      searchDate,
      fundCode,
    } = this.state;

    this.props.reportThirteenFLongPosReport(fundCode, searchDate.format("YYYY-MM-DD"));
  }

  reportCheckBtnOnClicked = () => {
    const {
      fundCode,
      searchDate,
    } = this.state;

    const dateString = searchDate.format("YYYY-MM-DD");
    this.props.loadThirteenFLongPosReportCheckResult(fundCode, dateString);
    this.props.openThirteenFLongPosReportCheckResultModalDialog(fundCode, dateString);
  }

  reportSummaryBtnOnClicked = () => {
    const {
      searchDate,
    } = this.state;

    this.props.reportThirteenFLongPosSummaryReport(searchDate.format("YYYY-MM-DD"));
  }
  
  render() {
    const {
      // Generate 13F Long Report Action Status
      generateThirteenFLongPosReportActionStatus,
      loadThirteenFLongPosReportActionStatus,
      // Report 13F Long Report
      reportThirteenFLongPosReportActionStatus,
      // Report 13F Long Pos Summary Report
      reportThirteenFLongPosSummaryReportActionStatus,
    } = this.props;

    const {
      searchDate,
      fundCode,
    } = this.state;

    const generateBtnIsLoading = generateThirteenFLongPosReportActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadThirteenFLongPosReportActionStatus === ACTION_STATUS.LOGINING;
    const reportBtnIsLoading = reportThirteenFLongPosReportActionStatus === ACTION_STATUS.LOGINING;
    const reportSummaryBtnIsLoading = reportThirteenFLongPosSummaryReportActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="thirteenFLongPosReportWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Document & Report</Breadcrumb.Item>
            <Breadcrumb.Item>13F Long Report</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <DatePicker 
            style={{ marginLeft: 10, fontSize: 10 }}
            size='small'
            allowClear={false}
            value={searchDate}
            onChange={(date, dateString) => {
              this.setState({
                searchDate: date
              })
            }} 
          />

          <Select 
            allowClear
            showSearch
            size='small'
            value={fundCode}
            style={{ marginLeft: 10, fontSize: 10, width: 150 }}
            onChange={this.fundOnChange} 
            placeholder="Please Choose Fund"
            >
            {FundCodeOptions}
          </Select>

          <Button 
            onClick={this.searchBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Search
          </Button>

          <Button 
            onClick={this.reportCheckBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Check
          </Button>

          <Button 
            onClick={this.genreateReportBtnOnClicked} 
            size='small'
            loading={generateBtnIsLoading}
            type='primary'>
            Generate
          </Button>

          <Button 
            onClick={this.reportBtnOnClicked} 
            size='small'
            loading={reportBtnIsLoading}
            type='primary'>
            Fund Level Report
          </Button>

          <Button 
            onClick={this.reportSummaryBtnOnClicked} 
            size='small'
            loading={reportSummaryBtnIsLoading}
            type='primary'>
            All Funds Report
          </Button>
        </div>

        <div className='thirteenFLongPosReportGrid'>
          <ThirteenFLongPosReportGrid {...this.props} />
        </div>

        {/* 13F Long Report 申报检查结果 */}
        <ThirteenFLongPosReportChkResultDialog {...this.props} />

      </div>
    );
  }
}

export default ThirteenFLongPosReportDashboard;