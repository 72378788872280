import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load CounterParty
  START_LOAD_COUNTER_PARTY,
  LOAD_COUNTER_PARTY_SUCCESS,
  LOAD_COUNTER_PARTY_FAILURE,
  INIT_LOAD_COUNTER_PARTY_ACTION_STATUS,

} from './counterPartyConstants';

const initialState = {
  counterpartyListGridColumns: GridColumnMap.counterpartyListGridColumns,

  // CounterParty
  counterPartyArray: [],

  // Load CounterParty Action Status
  loadCounterPartyActionStatus: ACTION_STATUS.READY,

}

// Load CounterParty
function startLoadCounterParty(state) {
  return {
    ...state,
    loadCounterPartyActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadCounterPartySuccess(state, resp) {
  return {
    ...state,
    loadCounterPartyActionStatus: ACTION_STATUS.SUCCESS,
    counterPartyArray: resp.data,
  };
}

function loadCounterPartyFailure(state, err) {
  return {
    ...state,
    loadCounterPartyActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadCounterPartyActionStatus(state) {
  return {
    ...state,
    loadCounterPartyActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load CounterParty
  [START_LOAD_COUNTER_PARTY]: startLoadCounterParty,
  [LOAD_COUNTER_PARTY_SUCCESS]: loadCounterPartySuccess,
  [LOAD_COUNTER_PARTY_FAILURE]: loadCounterPartyFailure,
  [INIT_LOAD_COUNTER_PARTY_ACTION_STATUS]: initLoadCounterPartyActionStatus,
  
});