import React, {Component} from 'react';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';

import moment from 'moment';
import { Button, Checkbox, message, Radio, Input, DatePicker, Upload, Icon,Select } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { QUESTION_TYPE_CODE } from '../../../../utils/QuestionTypeUtil';
import { QUESTIONNAIRE_STATUS_CODE } from '../../../../utils/QuestionnaireStatus';
import { QUESTIONNAIRE_TYPE_CODE } from '../../../../utils/QuestionnaireType';

import QuestionAttatchment from './QuestionAttatchment';
import SubmittedHistoryDialog from './SubmittedHistoryDialog';
import './QuestionnairePreview.css';
const Option = Select.Option;
const TextArea = Input.TextArea;
const bokerOptions = [
  <Option key="ACF PB">ACF PB</Option>,
  <Option key="BCP">BCP</Option>,
  <Option key="BINANCE">BINANCE</Option>,
  <Option key="BMTB">BMTB</Option>,
  <Option key="BNPI">BNPI</Option>,
  <Option key="CACF PB">CACF PB</Option>,
  <Option key="CCHL">CCHL</Option>,
  <Option key="CHINA RENAISSANCE">CHINA RENAISSANCE</Option>,
  <Option key="CICC">CICC</Option>,
  <Option key="CITI">CITI</Option>,
  <Option key="CITIC">CITIC</Option>,
  <Option key="CLSA">CLSA</Option>,
  <Option key="CMS">CMS</Option>,
  <Option key="COIN">COIN</Option>,
  <Option key="CPTF PB">CPTF PB</Option>,
  <Option key="CS">CS</Option>,
  <Option key="DAIWA">DAIWA</Option>,
  <Option key="DB LDN">DB LDN</Option>,
  <Option key="DFZQ">DFZQ</Option>,
  <Option key="DONGWU">DONGWU</Option>,
  <Option key="DUMMY CPTY">DUMMY CPTY</Option>,
  <Option key="DUMY">DUMY</Option>,
  <Option key="EB">EB</Option>,
  <Option key="EDEL">EDEL</Option>,
  <Option key="EMKAY">EMKAY</Option>,
  <Option key="ESHK">ESHK</Option>,
  <Option key="FA_PCF">FA_PCF</Option>,
  <Option key="FA_PLUS">FA_PLUS</Option>,
  <Option key="FA_PMSF">FA_PMSF</Option>,
  <Option key="FURONG">FURONG</Option>,
  <Option key="GSIL">GSIL</Option>,
  <Option key="GTJA">GTJA</Option>,
  <Option key="GUANG FA">GUANG FA</Option>,
  <Option key="GUOXIN">GUOXIN</Option>,
  <Option key="HAITONG">HAITONG</Option>,
  <Option key="HSBC FA">HSBC FA</Option>,
  <Option key="HUATAI">HUATAI</Option>,
  <Option key="IB">IB</Option>,
  <Option key="INCA HK">INCA HK</Option>,
  <Option key="ITG">ITG</Option>,
  <Option key="JARDEN">JARDEN</Option>,
  <Option key="JEFFERIES">JEFFERIES</Option>,
  <Option key="JIAOYIN">JIAOYIN</Option>,
  <Option key="JPM">JPM</Option>,
  <Option key="KGI">KGI</Option>,
  <Option key="KOTAK">KOTAK</Option>,
  <Option key="LIQUIDNET">LIQUIDNET</Option>,
  <Option key="MARL">MARL</Option>,
  <Option key="MIZUHO">MIZUHO</Option>,
  <Option key="ML">ML</Option>,
  <Option key="ML EC 92707">ML EC 92707</Option>,
  <Option key="MOTILAL">MOTILAL</Option>,
  <Option key="MQ">MQ</Option>,
  <Option key="MS">MS</Option>,
  <Option key="MUFG">MUFG</Option>,
  <Option key="NOMURA EC90997">NOMURA EC90997</Option>,
  <Option key="NOMURA INTERNATIONAL PLC">NOMURA INTERNATIONAL PLC</Option>,
  <Option key="OPPENHEIMER">OPPENHEIMER</Option>,
  <Option key="PB CLIENT MOVE">PB CLIENT MOVE</Option>,
  <Option key="PCIL">PCIL</Option>,
  <Option key="PTF PB">PTF PB</Option>,
  <Option key="RELIGARE">RELIGARE</Option>,
  <Option key="SC LOWY">SC LOWY</Option>,
  <Option key="SEAPORT EC 96565">SEAPORT EC 96565</Option>,
  <Option key="SGEN">SGEN</Option>,
  <Option key="SMBC">SMBC</Option>,
  <Option key="SPARK">SPARK</Option>,
  <Option key="SWHY">SWHY</Option>,
  <Option key="TFIS">TFIS</Option>,
  <Option key="TFS">TFS</Option>,
  <Option key="UBS">UBS</Option>,
  <Option key="VCM">VCM</Option>,
  <Option key="YONGAN">YONGAN</Option>
  
]
const sectorOptions = [
  <Option key="TMT">TMT</Option>,
  <Option key="Cyclical">Cyclical</Option>,
  <Option key="Healthcare">Healthcare</Option>,
  <Option key="Strategy">Strategy</Option>,
  <Option key="Consumer">Consumer</Option>
  
]
const groupOptions = [
  <Option key="Internet">Internet</Option>,
  <Option key="Hardware">Hardware</Option>,
  <Option key="Cyclical">Cyclical</Option>,
  <Option key="Consumer">Consumer</Option>,
  <Option key="QuantTeam">QuantTeam</Option>
  
]

class MeetingAndTrainingAnswer extends Component {

  state = {
    undoneErrMsgShow: false,
    unsignedErrMsgShow: false,
    saveQuestionnaireSucc: false,
    authToken: '',
    borker: '',
    sector: '',
    title: '',
    starttime: '',
    address: '',
    group: '',
  };
  onQueryInputChange = ({ name, value }) => {
    if(name === 'borker'){
      this.setState({
        borker: value,
      });
    }else if (name ==='sector'){
      this.setState({
        sector: value,
      });
    }else if (name ==='group') {
      this.setState({
        group: value,
      });
    }else if (name === 'title') {
      this.setState({
        title: value,
      });
    }
    else if (name === 'address') {
      this.setState({
        address: value,
      });
    }
    else if (name === 'starttime') {
      this.setState({
        starttime: value,
      });
    }
  };
  componentDidMount() {
    this.loadTodoQuestionnaire();
  }

  // 查询TodoQuestionnair
  loadTodoQuestionnaire() {
    const search = this.props.location.search;
    if (search === undefined || search === null) {
      return;
    }

    const searchStrArray = search.split('=');

    if (searchStrArray.length != 2) {
      return;
    }

    const authToken = searchStrArray[1];
    this.setState({
      authToken
    });

    this.props.loadMeetingAndTraningSub(authToken);
    // this.props.loadSubmittedQuestionnaireHistory(authToken);
  }

  componentDidUpdate() {
    const {
      submitQuestionnaireAnswerActionStatus,
      saveQuestionnaireAnswerActionStatus,
    } = this.props;

    // 问卷调查提交成功
    if (submitQuestionnaireAnswerActionStatus === ACTION_STATUS.SUCCESS) {
      // message.success('The questionnaire answers are submitted successfully!');
      this.props.initSubmitQuestionnaireAnswerActionStatus();
      this.loadTodoQuestionnaire();
      this.setState({
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
    }

    // 问卷调查提交失败
    if (submitQuestionnaireAnswerActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSubmitQuestionnaireAnswerActionStatus();
      this.setState({
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
    }

    // 问卷调查保存成功
    if (saveQuestionnaireAnswerActionStatus === ACTION_STATUS.SUCCESS) {
      this.setState({
        saveQuestionnaireSucc: true,
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
      this.props.initSaveQuestionnaireAnswerActionStatus();
      this.loadTodoQuestionnaire();
    }

    // 问卷调查保存失败
    if (saveQuestionnaireAnswerActionStatus === ACTION_STATUS.ERROR) {
      this.setState({
        saveQuestionnaireSucc: false,
        undoneErrMsgShow: false,
        unsignedErrMsgShow: false,
      });
      this.props.initSaveQuestionnaireAnswerActionStatus();
    }
  }

  // Check
  questionnaireCheckOnChanged = (data, valueOption, e) => {
    if (e.target.checked) {
      if (data.answerContent === null || data.answerContent === '') {
        data.answerContent = valueOption;
      } else {
        data.answerContent = data.answerContent + ',' + valueOption;
      }
    } else {
      data.answerContent = data.answerContent.replace(valueOption, '');
      let answerContentArray = data.answerContent.split(',');
      answerContentArray = answerContentArray.filter(item => !!item);
      data.answerContent = answerContentArray.join(',');;
    }

    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent: data.answerContent,
    });
  }

  // Radio
  questionnaireRadioOnChanged = (data, e) => {
    console.log('radio checked', e.target.value);

    let answerContent = e.target.value;
    
    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
    });
  };

  // Date
  questionnaireDateOnChanged = (date, dateString, data) => {
    console.log('date ', dateString);

    let answerContent = dateString;
    
    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
    });
  };

  // Text
  questionnaireTextOnChanged = (data, e) => {
    console.log('text ', e.target.value);

    let answerContent = e.target.value;
    
    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
    });
  };

  // 附件上传
  attachmentHandleChange = (data, info) => {
    let fileList = [...info.fileList]; 

    const fileNameList = fileList.map(file => {
      if (file.response) {
        const filename = file.response.data.fileNameWithTimeStamp;
        return filename;
      }
    });

    let answerContent = fileNameList.join(',');

    this.props.questionnaireAnswerChangeInput({
      answerGuid: data.answerGuid, 
      answerContent,
      fileList,
    });
  }

  cancelButtonOnClicked = () => {
    this.props.history.goBack();
  }

  //  Submitter Text
  questionnaireSubmitterOnChanged = (e) => {
    console.log('text ', e.target.value);

    let submitter = e.target.value;
  
    this.props.questionnaireAnswerSubmitterEditChangeInput(submitter);
  };

    //  Submitter Text
    meetingAndTrainingSubmitterOnChanged = (e) => {
      console.log('text ', e.target.value);
  
      let submitter = e.target.value;
    
      this.props.meetingAndTrainingAnswerSubmitterEditChangeInput(submitter);
    };

  // 提交
  submitButtonOnClicked = () => {
    const {
      authtoken,
      signin,
    } = this.props;

    const {
      borker,
      sector,
      title,
      starttime,
      address,
      group,
    } = this.state;

    if (signin === null || signin === "") {
        // message.warning("Please sign at the bottom of the questionnaire");
        this.setState({
          unsignedErrMsgShow: true,
        });
     
        return;
      }
      
      this.props.submitMeetingAndTraningAnswer(borker, sector,title,starttime, address,group);
    
  }

  // 保存
  saveButtonOnClicked = () => {
    const {
      todoQuestionArray,
      todoQuestionnaireAuthToken,
      questionnaireAnswerDate,
      questionnaireSubmitter,
    } = this.props;

    let answerParam = [];

    for (let i = 0; i < todoQuestionArray.length; i++) {
      const todoQuestion = todoQuestionArray[i];
      const answerContent = todoQuestion.answerContent;

      answerParam.push({
        answerGuid: todoQuestion.answerGuid,
        questionnaireGuid: todoQuestion.questionnaireGuid,
        questionGuid: todoQuestion.questionGuid,
        answerContent: todoQuestion.answerContent,
      })

      for (let j = 0; j < todoQuestion.subTodoQuestionList.length; j++) {
        const subTodoQuestion = todoQuestion.subTodoQuestionList[j];
        const triggerOption = subTodoQuestion.triggerOption;

        const questionEnglish = subTodoQuestion.questionEnglish;
        const questionChinese = subTodoQuestion.questionChinese;

        // if (triggerOption === answerContent && (subTodoQuestion.answerContent === null || subTodoQuestion.answerContent === "")) {
        //   message.warning(`The answer to the question " ${ questionEnglish } ( ${ questionChinese } ) " cannot be empty when you choose choice ${triggerOption}`);
        //   answerParam = [];
        //   return;
        // }

        answerParam.push({
          answerGuid: subTodoQuestion.answerGuid,
          questionnaireGuid: subTodoQuestion.questionnaireGuid,
          questionGuid: subTodoQuestion.questionGuid,
          answerContent: subTodoQuestion.answerContent,
        })
      }
    }

    this.props.saveQuestionnaireAnswer(todoQuestionnaireAuthToken, answerParam, questionnaireSubmitter, questionnaireAnswerDate);
  }

  showSubmittedHistoryBtnOnClicked = () => {
    this.props.openSubmittedQuestionnaireHistoryModalDialog(this.state.authToken);
  }
  getPageNameByMode = (mode) => {
    if(mode === 'New') {
        return '确认签到';
    } else {
        return 'Sale Side Meeting';
    }
}



  render() {
    const {
      loadMeetingAndTraningSubActionStatus,
      title,
      desc,
      comperename,
      starttime,
      id,
      meetingid,
      substate,
      authtoken,
      signin,
      participant,
      lastmodifieddate,

      loadTodoQuestionnaireActionStatus,
      loadTodoQuestionnaireActionResp,

      submitQuestionnaireAnswerActionStatus,
      submitQuestionnaireAnswerErrMsg,

      saveQuestionnaireAnswerActionStatus,
      saveQuestionnaireAnswerErrMsg,

      todoQuestionArray,
      questionnaireTitle,
      questionnaireAnsweree,
      questionnaireAnswerDate,
      questionnaireSubmitter,
      questionnaireStatus,
      todoQuestionnaire,

      // Questionnaire attachment Upload
      questionnaireAttachmentUploadUrl,

      submittedQuestionnaireHistoryArray,
    } = this.props;


    // 点击邮件中的链接直接跳转，如果此时用户未登录，要报错，并提示进行登录
    // if(!!loadTodoQuestionnaireActionResp && loadTodoQuestionnaireActionResp.respCode === 'GF_NO_AUTH') {
    //   return (
    //     <div className='questionnaireDashboardWrapper'>
    //       <div className='questionnaireDashboardContent'>
    //         <Message negative>
    //             <Message.Header>You haven't logined yet. Please click on the following button to login first.</Message.Header>
    //             </Message>
    //             <Button 
    //                   onClick={()=>{
    //                     window.location.href = 'https://' + BaseUrlConfig.report_frontend_dn;
    //                   }} 
    //                   size='small'
    //                   style={{ marginLeft: 10 }}
    //                   type='primary'>
    //                   Go To Login
    //             </Button>
    //       </div>
    //     </div>
    //   );
    // }


    const {
      undoneErrMsgShow,
      unsignedErrMsgShow,
      saveQuestionnaireSucc,
    } = this.state;

    const isSubmitBtnLoading = submitQuestionnaireAnswerActionStatus === ACTION_STATUS.LOGINING;
    const isSaveBtnLoading = saveQuestionnaireAnswerActionStatus === ACTION_STATUS.LOGINING;
    const readOnlyMode = substate === 'Confirm'|| substate === 'Feedback';
    const str ='';


    const modalTitle = this.getPageNameByMode(substate);
    return (
      <div className='questionnaireDashboardWrapper'>
        <div className='questionnaireDashboardContent'>
          <span className='title'>
            { modalTitle }
          </span>
          {
            questionnaireStatus === QUESTIONNAIRE_STATUS_CODE.SUBMITTED && 
            <Message positive>
              <Message.Header>Your answer(s) has been submitted!</Message.Header>
            </Message>
          }
          <div className='signature-container'>

                  <div className='questionContainer'  >
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>Broker:</span>
                      <div>
                        <div className='question-desc'>
                        
                        </div>
                        <div className='question-desc'>
                        </div>
                      </div>

                    </div>
                    <div className='answerContainer'>
                    <Select
                        className='radioStyle' 
                        allowClear
                        size='small'
                        showSearch
                        
                        style={{ width: '70%', marginLeft: 10 }}
                        placeholder="" 
                        // value={personInCharge}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'broker', value: val })
                        }} >
                        {bokerOptions}
                    </Select>
                    </div>
                  </div>

                  <div className='questionContainer'  >
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>Sector:</span>
                      <div>
                        <div className='question-desc'>
                        
                       
                        </div>
                        <div className='question-desc'>
                        </div>
                      </div>
                     
                    </div>
                    <div className='answerContainer'>
                      <Select
                        className='radioStyle' 
                        allowClear
                        size='small'
                        showSearch
                        style={{ width: '70%', marginLeft: 10 }}
                        placeholder="" 
                        // value={personInCharge}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'sector', value: val })
                        }} >
                        {sectorOptions}
                        </Select>

                      </div>
                  </div>

                  <div className='questionContainer'  >
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>主题:</span>
                      <div>
                        <div className='question-desc'>
                          { comperename }
                        </div>
                        <div className='question-desc'>
                        </div>
                      </div>

                    </div>
                    <div className='answerContainer'>
                      <Input 
                          placeholder="主题"
                          size="small"
                          style={{ width: '70%', marginLeft: 10 }}
                          onPressEnter={this.refreshBtnOnClicked}
                          onChange={(event) => {
                              this.onQueryInputChange({ name: 'title', value: event.target.value })
                          }} 
                      />
                    </div>
                  </div>
                  <div className='questionContainer'  >
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>时间:</span>
                      <div>
                        <div className='question-desc'>
                          { starttime }
                        </div>
                        <div className='question-desc'>
                        </div>
                      </div>

                    </div>
                    <div className='answerContainer'>
                      <DatePicker 
                                  style={{ width: '70%', marginLeft: 10 }}
                                  // value={!starttime ? null : moment(starttime)}
                                  showTime={{ format: 'HH:mm' }}
                                  format="YYYY-MM-DD HH:mm"
                                  allowClear={false}
                                  placeholder="时间"
                                  // disabledDate={(currentDate) => {
                                  //     return currentDate.isBefore(moment().format("YYYY-MM-DD"));
                                  //   }}
                                  onChange={(date, dateString) => {
                                      this.onQueryInputChange({ name: 'starttime', value: dateString });
                                  }} />
                    </div>
                  </div>
                  <div className='questionContainer'  >
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>参会方式:</span>
                      <div>
                        <div className='question-desc'>
                          { starttime }
                        </div>
                        <div className='question-desc'>
                        </div>
                      </div>

                    </div>
                    <div className='answerContainer'>
                    <TextArea 
                        style={{ width: 250, marginLeft: 10 }}
                        rows={3} 
                        onChange={(e) => {
                            this.onQueryInputChange({ name: 'address', value: e.target.value })
                        }}
                    />

                    </div>
                  </div>
                  <div className='questionContainer'  >
                    <div className='questionDescContainer'>
                      <span className='question-desc-index'>Notified people or group:</span>
                      <div>
                        <div className='question-desc'>
                          { starttime }
                        </div>
                        <div className='question-desc'>
                        </div>
                      </div>

                    </div>
                    <div className='answerContainer'>
                      <Select
                        className='radioStyle' 
                        allowClear
                        size='small'
                        showSearch
                        style={{ width: '70%', marginLeft: 10 }}
                        placeholder="" 
                        // value={personInCharge}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'group', value: val })
                        }} >
                        {groupOptions}
                        </Select>

                      </div>
                  </div>
                  

          </div>
          <div className="signature-container">
            <div>
              <span>Signature:</span><br/>
              <span>签名:</span>
            </div>
            <input 
              style={{ marginTop: 10, marginLeft: 30 }}
              value={signin}
              disabled={readOnlyMode}
              onChange={this.meetingAndTrainingSubmitterOnChanged.bind(this)}/>
            <div style={{ marginTop: 10 }}>
              <span>Name 姓名: { participant }</span><br/>
              <span>Date 日期: { lastmodifieddate }</span>
            </div>
          </div>
          <Message positive>
              <Message.Header>The system will automatically send an invitation to PinPoint public calendar (Pinpoint@pinpointfund.com). All research team member will have access to this meeting.</Message.Header>
          </Message>
          {
            questionnaireStatus === QUESTIONNAIRE_STATUS_CODE.SUBMITTED && 
            <Message positive>
              <Message.Header>Your answer(s) has been submitted!</Message.Header>
            </Message>
          }
          
          {
            undoneErrMsgShow && 
            <Message negative>
              <Message.Header>You haven't finished all the questions yet</Message.Header>
            </Message>
          }
          {
            unsignedErrMsgShow && 
            <Message negative>
              <Message.Header>You haven't signed your name yet</Message.Header>
            </Message>
          }
          {
            saveQuestionnaireSucc &&
            <Message positive>
              <Message.Header>The questionnaire is saved successfully!</Message.Header>
            </Message>
          }
          {
            !!saveQuestionnaireAnswerErrMsg &&
            <Message negative>
              <Message.Header>{saveQuestionnaireAnswerErrMsg}</Message.Header>
            </Message>
          }
          {
            !!submitQuestionnaireAnswerErrMsg && 
            <Message negative>
              <Message.Header>{submitQuestionnaireAnswerErrMsg}</Message.Header>
            </Message>
          }
          <div className='buttons'>
            {/* <Button 
              type="primary" 
              onClick={this.saveButtonOnClicked} 
              loading={isSaveBtnLoading}
              disabled={readOnlyMode}
              >Save</Button> */}
            <Button 
              type="primary" 
              style={{ marginLeft: 10 }}
              onClick={this.submitButtonOnClicked} 
              loading={isSubmitBtnLoading}
              disabled={readOnlyMode}
              >Submit</Button>
            {
              submittedQuestionnaireHistoryArray.length > 0 &&
              <Button 
                type="primary" 
                style={{ marginLeft: 10 }}
                onClick={this.showSubmittedHistoryBtnOnClicked} 
                loading={isSubmitBtnLoading}
                disabled={readOnlyMode}
                >Submitted History</Button>
            }
          </div>
        </div>
      
        <SubmittedHistoryDialog {...this.props} />
      </div>
    );
  }
}

export default MeetingAndTrainingAnswer;