import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_PRESONAL_INFO_INFO,
    LOAD_PRESONAL_INFO_INFO_SUCCESS,
    LOAD_PRESONAL_INFO_INFO_FAILURE,
    INIT_LOAD_PRESONAL_INFO_INFO_ACTION_STATUS,
    // select
    SELECT_PRESONAL_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_PRESONAL_INFO_MODAL,
    ADD_OR_EDIT_PRESONAL_INFO_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_PRESONAL_INFO_MODAL,
    START_SUBMIT_SAVE_PRESONAL_INFO,
    SUBMIT_SAVE_PRESONAL_INFO_SUCCESS,
    SUBMIT_SAVE_PRESONAL_INFO_FAILURE,
    INIT_SUBMIT_SAVE_PRESONAL_INFO_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_PRESONAL_INFOSUB_MODAL,
    ADD_OR_EDIT_PRESONAL_INFOSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_PRESONAL_INFOSUB_MODAL,
    START_SUBMIT_SAVE_PRESONAL_INFOSUB,
    SUBMIT_SAVE_PRESONAL_INFOSUB_SUCCESS,
    SUBMIT_SAVE_PRESONAL_INFOSUB_FAILURE,
    INIT_SUBMIT_SAVE_PRESONAL_INFOSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO,
    BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_ACTION_STATUS,
    // finish
    SELECT_PRESONAL_INFO_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_PRESONAL_INFO_TASK,
    FINISH_PRESONAL_INFO_TASK_SUCCESS,
    FINISH_PRESONAL_INFO_TASK_FAILURE,
    INIT_FINISH_PRESONAL_INFO_TASK_ACTION_STATUS,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,



      // Accept Questionnaire Result
    START_ACCEPT_QUESTIONNAIRE_RESULT,
    ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
    ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
    INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

    // Reject Questionnaire Result
    START_REJECT_QUESTIONNAIRE_RESULT,
    REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
    REJECT_QUESTIONNAIRE_RESULT_FAILURE,
    INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

      // Send Remind Email
    START_SEND_REMIND_EMAIL,
    SEND_REMIND_EMAIL_SUCCESS,
    SEND_REMIND_EMAIL_FAILURE,
    INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

} from './PresonalInfoConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadPresonalInfoInfo() {
    return {
      type: START_LOAD_PRESONAL_INFO_INFO,
    };
}

function getQueryReqParams(params) {
    let startDate = params.startDate !== undefined && params.startDate !== null && params.startDate !== ''
    ? params.startDate.format("YYYY-MM-DD") : '';
    let endDate = params.endDate !== undefined && params.endDate !== null && params.endDate !== ''
        ? params.endDate.format("YYYY-MM-DD") : '';
    const requestParams = {
    startDate: startDate,
    endDate: endDate,
    category: params.category,
    fundco: params.fundco,
    owner: params.owner,
    monthStart: params.monthStart,
    workstatus: params.status,
    };
    return requestParams;
}

export function loadPresonalInfoInfo(params) {

    const requestParams = getQueryReqParams(params);

    return function(dispatch) {
        dispatch(startLoadPresonalInfoInfo());
        httpClient  
          .get(UrlConfig.LOAD_PRESONAL_INFO_INFO, requestParams)
          .then(resp => dispatch(loadPresonalInfoInfoSuccess(resp)))
          .catch(err => dispatch(loadPresonalInfoInfoFailure(err)));
    };
}
function loadPresonalInfoInfoSuccess(resp) {
    return {
      type: LOAD_PRESONAL_INFO_INFO_SUCCESS,
      payload: resp,
    };
}
function loadPresonalInfoInfoFailure(err) {
    return {
        type: LOAD_PRESONAL_INFO_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadPresonalInfoInfoActionStatus() {
    return {
      type: INIT_LOAD_PRESONAL_INFO_INFO_ACTION_STATUS,
    };
}


// select
export function selectPresonalInfo(array) {
    return {
        type: SELECT_PRESONAL_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditPresonalInfoModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_PRESONAL_INFO_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditPresonalInfoChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_PRESONAL_INFO_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSavePresonalInfoModal() {
    return {
        type: RESET_SUBMIT_SAVE_PRESONAL_INFO_MODAL,
    };
}

function startSubmitSavePresonalInfo() {
    return {
        type: START_SUBMIT_SAVE_PRESONAL_INFO,
    };
}
export function submitSavePresonalInfo(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSavePresonalInfo());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_PRESONAL_INFO;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_PRESONAL_INFO;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSavePresonalInfoSuccess(resp)))
            .catch(err => dispatch(submitSavePresonalInfoFailure(err)));        
    };
}
function submitSavePresonalInfoSuccess(resp) {
    return {
        type: SUBMIT_SAVE_PRESONAL_INFO_SUCCESS,
        payload: resp,
    };
}
export function submitSavePresonalInfoFailure(err) {
    return {
        type: SUBMIT_SAVE_PRESONAL_INFO_FAILURE,
        payload: err,
    };
}
export function initSubmitSavePresonalInfoActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_PRESONAL_INFO_ACTION_STATUS,
    };
}


//sub add or edit
export function openAddOrEditPresonalInfoSubModal(mode, data,idList) {
    return {
        type: OPEN_ADD_OR_EDIT_PRESONAL_INFOSUB_MODAL,
        payload: {
            mode,
            data,
            idList,
        },
    };
}
export function addOrEditPresonalInfoSubChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_PRESONAL_INFOSUB_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSavePresonalInfoSubModal() {
    return {
        type: RESET_SUBMIT_SAVE_PRESONAL_INFOSUB_MODAL,
    };
}

function startSubmitSavePresonalInfoSub() {
    return {
        type: START_SUBMIT_SAVE_PRESONAL_INFOSUB,
    };
}
export function submitSavePresonalInfoSub(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSavePresonalInfoSub());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_PRESONAL_INFOSUB;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_PRESONAL_INFOSUB;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSavePresonalInfoSubSuccess(resp)))
            .catch(err => dispatch(submitSavePresonalInfoSubFailure(err)));        
    };
}
function submitSavePresonalInfoSubSuccess(resp) {
    return {
        type: SUBMIT_SAVE_PRESONAL_INFOSUB_SUCCESS,
        payload: resp,
    };
}
export function submitSavePresonalInfoSubFailure(err) {
    return {
        type: SUBMIT_SAVE_PRESONAL_INFOSUB_FAILURE,
        payload: err,
    };
}
export function initSubmitSavePresonalInfoSubActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_PRESONAL_INFOSUB_ACTION_STATUS,
    };
}



// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForPresonalInfo() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO,
    };
}
export function batchChangeStatusForPresonalInfo(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForPresonalInfo());
        httpClient
            .post(UrlConfig.BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO, params)
            .then(resp => dispatch(batchChangeStatusForPresonalInfoSuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForPresonalInfoFailure(err)));        
    };
}
function batchChangeStatusForPresonalInfoSuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForPresonalInfoFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForPresonalInfoActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_PRESONAL_INFO_ACTION_STATUS,
    };
}


// finish
export function selectPresonalInfoTask(array) {
    return {
        type: SELECT_PRESONAL_INFO_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishPresonalInfoTask() {
    return {
        type: START_FINISH_PRESONAL_INFO_TASK,
    };
}
export function finishPresonalInfoTask(params) {
    return function(dispatch) {
        dispatch(startFinishPresonalInfoTask());
        httpClient
            .post(UrlConfig.FINISH_PRESONAL_INFO_TASK, params)
            .then(resp => dispatch(finishPresonalInfoTaskSuccess(resp)))
            .catch(err => dispatch(finishPresonalInfoTaskFailure(err)));        
    };
}
function finishPresonalInfoTaskSuccess(resp) {
    return {
        type: FINISH_PRESONAL_INFO_TASK_SUCCESS,
        payload: resp,
    };
}
function finishPresonalInfoTaskFailure(err) {
    return {
        type: FINISH_PRESONAL_INFO_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishPresonalInfoTaskActionStatus() {
    return {
        type: INIT_FINISH_PRESONAL_INFO_TASK_ACTION_STATUS,
    };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
      payload: {
        sendMode,
        emailPayload,
      },
    };
  }
  
  export function resetQuestionnaireEmailEditModalDialog() {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET
    };
  }


  // Accept Questionnaire Result
function startAcceptQuestionnaireResult() {
    return {
      type: START_ACCEPT_QUESTIONNAIRE_RESULT
    };
  }
  
  export function acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startAcceptQuestionnaireResult());
      httpClient
        .post(UrlConfig.ACCEPT_PRESONAL_INFO_RESULT, requestParams)
        .then(resp => dispatch(acceptQuestionnaireResultSuccess(resp)))
        .catch(err => dispatch(acceptQuestionnaireResultFailure(err)));
    };
  }
  
  function acceptQuestionnaireResultSuccess(resp) {
    return {
      type: ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
      payload: resp
    };
  }
  
  function acceptQuestionnaireResultFailure(err) {
    return {
      type: ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
      payload: err
    };
  }
  
  export function initAcceptQuestionnaireResultActionStatus() {
    return {
      type: INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS
    };
  }

// Reject Questionnaire Result
function startRejectQuestionnaireResult() {
    return {
      type: START_REJECT_QUESTIONNAIRE_RESULT
    };
  }
  
  export function rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startRejectQuestionnaireResult());
      httpClient   
        .post(UrlConfig.REJECT_PRESONAL_INFO_RESULT, requestParams)
        .then(resp => dispatch(rejectQuestionnaireResultSuccess(resp)))
        .catch(err => dispatch(rejectQuestionnaireResultFailure(err)));
    };
  }
  
  function rejectQuestionnaireResultSuccess(resp) {
    return {
      type: REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
      payload: resp
    };
  }
  
  function rejectQuestionnaireResultFailure(err) {
    return {
      type: REJECT_QUESTIONNAIRE_RESULT_FAILURE,
      payload: err
    };
  }
  
  export function initRejectQuestionnaireResultActionStatus() {
    return {
      type: INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS
    };
  }

  // Send Remind Email
function startSendRemindEmail() {
    return {
      type: START_SEND_REMIND_EMAIL
    };
  }
  
  export function sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startSendRemindEmail());
      httpClient
        .post(UrlConfig.REMIND_PRESONAL_INFO_EMAIL, requestParams)
        .then(resp => dispatch(sendRemindEmailSuccess(resp)))
        .catch(err => dispatch(sendRemindEmailFailure(err)));
    };
  }
  
  function sendRemindEmailSuccess(resp) {
    return {
      type: SEND_REMIND_EMAIL_SUCCESS,
      payload: resp
    };
  }
  
  function sendRemindEmailFailure(err) {
    return {
      type: SEND_REMIND_EMAIL_FAILURE,
      payload: err
    };
  }
  
  export function initSendRemindEmailActionStatus() {
    return {
      type: INIT_SEND_REMIND_EMAIL_ACTION_STATUS
    };
  }