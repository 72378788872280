import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
const { TextArea } = Input;

class GiftHospitalityRequestEditDialog extends Component {


  refreshGiftHospitality = () => {
    const {
      employee,
      dateStart,
      dateEnd,
    } = this.props.gitfHospitalitySearchFields;
    this.props.loadGiftHospitalityRequest({employee, dateStart, dateEnd});
  }


  componentDidUpdate() {
    const { 
      feedbackGiftHospitalityRequestActionStatus,
    } = this.props;

    // Feedback Gift Hospitality Request 成功
    if (feedbackGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.refreshGiftHospitality();
      this.props.initFeedbackGiftHospitalityRequestActionStatus();

      this.props.resetGiftHospitalityRequestEditModalDialog();
    }
      
    // Feedback Gift Hospitality Request 失败
    if (feedbackGiftHospitalityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initFeedbackGiftHospitalityRequestActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.giftHospitalityRequestEditChangeInput({name, value});
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      giftHospitalityRequestEditControlModal,
    } = this.props;

    const {
      id,
      feedback,
    } = giftHospitalityRequestEditControlModal.fields;

    if (feedback) {
      this.props.feedbackGiftHospitalityRequest(id, feedback);
    }
  }

  render() {
    const {
      giftHospitalityRequestEditControlModal,
      feedbackGiftHospitalityRequestActionStatus,
    } = this.props;

    const {
      isOpened,
      respErrMsg,
    } = giftHospitalityRequestEditControlModal;

    const {
      feedback,
    } = giftHospitalityRequestEditControlModal.fields;

    let modalTitle = "Feedback Gift Hospitality Request";

    const isLoading = feedbackGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING; 
    const submitBtnDisabled = feedback === '' || feedback === null;

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetGiftHospitalityRequestEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okButtonProps={{ disabled: submitBtnDisabled }}
          okText="Submit"
          cancelText="Cancel"
        >
          <TextArea 
            rows={4} 
            value={feedback} 
            onChange={(e) => {
              this.onInputChange({ name: 'feedback', value: e.target.value })
            }}/>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default GiftHospitalityRequestEditDialog;