import React, {Component} from 'react';

import { Breadcrumb, Button, message } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import BannedTradeRequestGrid from './BannedTradeRequestGrid';
import BannedTradeRequestEditDialog from './BannedTradeRequestEditDialog';
import '../../ApprovalRequest.css';

class BannedTradeRequest extends Component {

  componentDidMount() {
    this.props.loadBannedTradeRequest();
  }

  componentDidUpdate() {
    const {
      approveBannedTradeRequestActionStatus,
      rejectBannedTradeRequestActionStatus,
    } = this.props;

    // Approve
    if (approveBannedTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Current request are approved successfully!');
      this.props.loadBannedTradeRequest();
      this.props.initApproveBannedTradeRequestActionStatus();
    }

    // Approve
    if (approveBannedTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Current request are approved failed!');
      this.props.initApproveBannedTradeRequestActionStatus();
    }

    // Reject
    if (rejectBannedTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Current request are rejected successfully!');
      this.props.loadBannedTradeRequest();
      this.props.initRejectBannedTradeRequestActionStatus();
    }

    // Reject
    if (rejectBannedTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Current request are rejected failed!');
      this.props.initRejectBannedTradeRequestActionStatus();
    }
  }

  approveButtonOnClicked = () => {
    const {
      selectedBannedTradeRequestArray
    } = this.props;

    if (selectedBannedTradeRequestArray.length !== 1) {
      message.warning("Please select one banned trade request!");
      return;
    }

    if (selectedBannedTradeRequestArray[0].approvalStatus === 'APPROVE') {
      message.warning("You cannot approve the banned trade request that has been approved again!");
      return;
    }

    this.props.approveBannedTradeRequest(selectedBannedTradeRequestArray[0].id);
  }

  rejectButtonOnClicked = () => {
    const {
      selectedBannedTradeRequestArray
    } = this.props;

    if (selectedBannedTradeRequestArray.length !== 1) {
      message.warning("Please select one banned trade request!");
      return;
    }

    if (selectedBannedTradeRequestArray[0].approvalStatus === 'REJECT') {
      message.warning("You cannot reject the banned trade request that has been rejected again!");
      return;
    }

    this.props.rejectBannedTradeRequest(selectedBannedTradeRequestArray[0].id);
  }

  editButtonOnClicked = () => {
    const {
      selectedBannedTradeRequestArray
    } = this.props;

    if (selectedBannedTradeRequestArray.length !== 1) {
      message.warning("Please select one banned trade request!");
      return;
    }

    this.props.openBannedTradeRequestEditModalDialog(selectedBannedTradeRequestArray[0]);
  }

  refreshButtonOnClicked = () => {
    this.props.loadBannedTradeRequest();
  }

  render() {
    const {
      approveBannedTradeRequestActionStatus,
      rejectBannedTradeRequestActionStatus,
      loadBannedTradeRequestActionStatus,
    } = this.props;
    
    const approveBtnIsLoading = approveBannedTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    const rejectBtnIsLoading = rejectBannedTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadBannedTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    
    return (
      <div className="bannedTradeRequestWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Approval Request</Breadcrumb.Item>
            <Breadcrumb.Item>Banned Trade</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <Button 
            onClick={this.approveButtonOnClicked} 
            size='small'
            loading={approveBtnIsLoading}
            className='positive-button'>
            Approve
          </Button>

          <Button 
            onClick={this.rejectButtonOnClicked} 
            size='small'
            loading={rejectBtnIsLoading}
            className='negative-button'>
            Reject
          </Button>
          
          {/* <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            type='primary'>
            Edit
          </Button> */}

          <Button 
            onClick={this.refreshButtonOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='bannedTradeRequestGrid'>
          <BannedTradeRequestGrid {...this.props} />
        </div>

        <BannedTradeRequestEditDialog {...this.props} />
      </div>
    );
  }
}

export default BannedTradeRequest;