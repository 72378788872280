import {connect} from 'react-redux';
import AccountHoldingDashboard from '../components/AccountHoldingDashboard';

import {
    // change input 
    onQueryInputChange,
    onAccountInputChange,
    onDelAuditAccountInputChange,
    onAddOrEditHoldingInputChange,
    onDelHoldingInputChange,
    // Load Personal Account
    loadPersonalAccount,
    initLoadPersonalAccountActionStatus,
    loadAccountOptionsForAddOrEdit,
    // select data
    selectPersonalAccountList,
    setGridEventOnState,
    showAllShowValidBtnOnClicked,
    selectPersonalHoldingList,
    setDetailGridEventOnState,
    // add or edit account
    openAddOrEditAccountDialog,
    resetAddOrEditAccountDialog,
    submitAddOrEditAccount,
    submitAddOrEditAccountFailure,
    initSubmitAddOrEditAccountActionStatus,
    // del or audit account,
    openDelOrAuditAccountDialog,
    resetDelOrAuditAccountDialog,
    delPersonalAccount,
    changePersonalAccountStatus,
    initDelPersonalAccountActionStatus,
    initChangePersonalAccountStatusActionStatus,
    // add or edit holding
    openAddOrEditHoldingDialog,
    resetAddOrEditHoldingDialog,
    submitAddOrEditHolding,
    initSubmitAddOrEditHoldingActionStatus,
    // bbgTicker
    loadHoldingBbgTickers,
    initLoadHoldingBbgTickersActionStatus,
    clearHoldingBbgTickers,
    holdingBbgTickerSelectChanged,
    // del holding
    openDelHoldingDialog,
    resetDelHoldingDialog,
    delPersonalHolding,
    initDelPersonalHoldingActionStatus,

} from '../AccountHoldingActions';


import {
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';
import {
  doHistoryUploadAction
} from '../../../common/historyUpload/HistoryUploadActions';
import {
  queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
    return {
      // basic data
      relationshipArray: state.personalAccount.relationshipArray,
      relationshipOptions: state.personalAccount.relationshipOptions,
      personalAccountDownloadUrl: state.personalAccount.personalAccountDownloadUrl,
      personalAccountUploadUrl: state.personalAccount.personalAccountUploadUrl,
      // user account
      userAccountOptions: state.personalAccount.userAccountOptions,
      // change input
      personalAccountQueryFields:  state.personalAccount.personalAccountQueryFields,
      // Load Personal Account
      loadPersonalAccountActionStatus: state.personalAccount.loadPersonalAccountActionStatus,
      personalAccountArray: state.personalAccount.personalAccountArray,
      loadPersonalAccountResp: state.personalAccount.loadPersonalAccountResp,
      accountNameOptions: state.personalAccount.accountNameOptions, 
      brokerAccountOptions: state.personalAccount.brokerAccountOptions,
      accountNoOptions: state.personalAccount.accountNoOptions,
      dataGridEvent: state.personalAccount.dataGridEvent,
      detailDataGridEvent: state.personalAccount.detailDataGridEvent,
      onlyShowValidData: state.personalAccount.onlyShowValidData,
      // select data
      selectedPersonalAccountList: state.personalAccount.selectedPersonalAccountList,
      selectedPersonalHoldingList: state.personalAccount.selectedPersonalHoldingList,
      // add or edit account
      addOrEditPersonalAccountModal: state.personalAccount.addOrEditPersonalAccountModal,
      submitAddOrEditAccountActionStatus: state.personalAccount.submitAddOrEditAccountActionStatus,
      // del or audit account,
      delOrAuditAccountModal: state.personalAccount.delOrAuditAccountModal,
      delPersonalAccountActionStatus: state.personalAccount.delPersonalAccountActionStatus,
      changePersonalAccountStatusActionStatus: state.personalAccount.changePersonalAccountStatusActionStatus,
      // add or edit account
      addOrEditPersonalHoldingModal: state.personalAccount.addOrEditPersonalHoldingModal,
      submitAddOrEditHoldingActionStatus: state.personalAccount.submitAddOrEditHoldingActionStatus,
      // bbgTicker
      securityTickerArray: state.personalAccount.securityTickerArray,
      loadBbgTickersActionStatus: state.personalAccount.loadBbgTickersActionStatus,
      bbgTickerArray: state.personalAccount.bbgTickerArray,
      // del holding
      delPersonalHoldingModal: state.personalAccount.delPersonalHoldingModal,
      delPersonalHoldingActionStatus: state.personalAccount.delPersonalHoldingActionStatus,
      // common action
      operationRecord: state.operationRecord,
      historyUpload: state.historyUpload,

    };
} 


const mapDispatchToProps = dispatch => {
    return {
        // change input 
        onQueryInputChange: (param) => dispatch(onQueryInputChange(param)),
        onAccountInputChange:(param) => dispatch(onAccountInputChange(param)),
        onDelAuditAccountInputChange: (param) => dispatch(onDelAuditAccountInputChange(param)),
        onAddOrEditHoldingInputChange: (param) => dispatch(onAddOrEditHoldingInputChange(param)),
        onDelHoldingInputChange: (param) => dispatch(onDelHoldingInputChange(param)),
        // Load Personal Account
        loadPersonalAccount: (param) => dispatch(loadPersonalAccount(param)),
        initLoadPersonalAccountActionStatus: () => dispatch(initLoadPersonalAccountActionStatus()),
        loadAccountOptionsForAddOrEdit: (employee) => dispatch(loadAccountOptionsForAddOrEdit(employee)),
        // select data
        selectPersonalAccountList: (list) => dispatch(selectPersonalAccountList(list)),
        setGridEventOnState: (event) => dispatch(setGridEventOnState(event)),
        showAllShowValidBtnOnClicked: () => dispatch(showAllShowValidBtnOnClicked()),
        selectPersonalHoldingList: (list) => dispatch(selectPersonalHoldingList(list)),
        setDetailGridEventOnState: (event) => dispatch(setDetailGridEventOnState(event)),
        // add or edit account
        openAddOrEditAccountDialog: (mode, account) => dispatch(openAddOrEditAccountDialog(mode, account)),
        resetAddOrEditAccountDialog: () => dispatch(resetAddOrEditAccountDialog()),
        submitAddOrEditAccount: (account) => dispatch(submitAddOrEditAccount(account)),
        submitAddOrEditAccountFailure: (err) => dispatch(submitAddOrEditAccountFailure(err)),
        initSubmitAddOrEditAccountActionStatus: () => dispatch(initSubmitAddOrEditAccountActionStatus()),
        // del or audit account
        openDelOrAuditAccountDialog: (mode, account) => dispatch(openDelOrAuditAccountDialog(mode, account)),
        resetDelOrAuditAccountDialog: () => dispatch(resetDelOrAuditAccountDialog()),
        delPersonalAccount: (account) => dispatch(delPersonalAccount(account)),
        initDelPersonalAccountActionStatus: () => dispatch(initDelPersonalAccountActionStatus()),
        changePersonalAccountStatus: (account) => dispatch(changePersonalAccountStatus(account)),
        initChangePersonalAccountStatusActionStatus: () => dispatch(initChangePersonalAccountStatusActionStatus()),
        // add or edit holding
        openAddOrEditHoldingDialog: (mode, holding) => dispatch(openAddOrEditHoldingDialog(mode, holding)),
        resetAddOrEditHoldingDialog: () => dispatch(resetAddOrEditHoldingDialog()),
        submitAddOrEditHolding: (holding) => dispatch(submitAddOrEditHolding(holding)),
        initSubmitAddOrEditHoldingActionStatus: () => dispatch(initSubmitAddOrEditHoldingActionStatus()),
        // bbgTicker
        loadHoldingBbgTickers: (bbgTicker) => dispatch(loadHoldingBbgTickers(bbgTicker)),
        initLoadHoldingBbgTickersActionStatus: () => dispatch(initLoadHoldingBbgTickersActionStatus()),
        clearHoldingBbgTickers: () => dispatch(clearHoldingBbgTickers()),
        holdingBbgTickerSelectChanged: (bbgTicker) => dispatch(holdingBbgTickerSelectChanged(bbgTicker)),
        // del holding
        openDelHoldingDialog: (idList) => dispatch(openDelHoldingDialog(idList)),
        resetDelHoldingDialog: () => dispatch(resetDelHoldingDialog()),
        delPersonalHolding: (param) => dispatch(delPersonalHolding(param)),
        initDelPersonalHoldingActionStatus: () => dispatch(initDelPersonalHoldingActionStatus()),
        // commont action
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
        doHistoryUploadAction: (params) => dispatch(doHistoryUploadAction(params)),
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),

    };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountHoldingDashboard);
