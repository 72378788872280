import React, {Component} from "react";
import BaseUrlConfig from '../../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../../net/UrlConfig';

class MonthlyReportAttachRenderer extends Component {

  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
    this.downloadAttachment = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MONTHLY_REVIEW_DOWNLOAD_ATTACHMENT;
  }

  invokeParentMethod () {

  }

  render () {
    const node = this.props.node;

    let url;
    let attachmentOriginName;
    if (!!node) {
      const attachment = node.data.attachment;
      if (!!attachment) {
        url = this.downloadAttachment + '/' + attachment;
        attachmentOriginName = node.data.attachmentOriginName;
      }
    }

    return (
      <div>
        {
          url && 
          <a href={url} target="_blank">{attachmentOriginName}</a>
        }
      </div>
    );
  }
}

export default MonthlyReportAttachRenderer;