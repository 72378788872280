import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_GENERAL_AGREEMENT_INFO,
    LOAD_GENERAL_AGREEMENT_INFO_SUCCESS,
    LOAD_GENERAL_AGREEMENT_INFO_FAILURE,
    INIT_LOAD_GENERAL_AGREEMENT_INFO_ACTION_STATUS,
    // select
    SELECT_GENERAL_AGREEMENT,
    SET_GRID_EVENT_ON_STATE,
    // add or edit
    OPEN_ADD_OR_EDIT_GENERAL_AGREEMENT_MODAL,
    RESET_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL,
    ADD_OR_EDIT_GENERAL_AGREEMENT_CHANGE_INPUT,
    START_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL,
    SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_SUCCESS,
    SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_FAILURE,
    INIT_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_ACTION_STATUS,
    // change status
    OPEN_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL,
    RESET_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL,
    CHANGE_GENERAL_AGREEMENT_STATUS_CHANGE_INPUT,
    START_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS,
    SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_SUCCESS,
    SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_FAILURE,
    INIT_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_ACTION_STATUS,
    // showAllshowValid
    SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,

} from './GeneralAgreementConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadGeneralAgreementInfo() {
    return {
      type: START_LOAD_GENERAL_AGREEMENT_INFO,
    };
}
export function loadGeneralAgreementInfo(params) {
    const queryParam = {
        source: 'Admin',
        id: params.id,
        title: params.title,
        personInCharge: params.personInCharge,
    };
    return function(dispatch) {
        dispatch(startLoadGeneralAgreementInfo());
        httpClient
          .get(UrlConfig.LOAD_GENERAL_AGREEMENT_INFO, queryParam)
          .then(resp => dispatch(loadGeneralAgreementInfoSuccess(resp)))
          .catch(err => dispatch(loadGeneralAgreementInfoFailure(err)));
    };
}
function loadGeneralAgreementInfoSuccess(resp) {
    return {
      type: LOAD_GENERAL_AGREEMENT_INFO_SUCCESS,
      payload: resp,
    };
}
function loadGeneralAgreementInfoFailure(err) {
    return {
        type: LOAD_GENERAL_AGREEMENT_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadGeneralAgreementInfoActionStatus() {
    return {
      type: INIT_LOAD_GENERAL_AGREEMENT_INFO_ACTION_STATUS,
    };
}


// select
export function selectGeneralAgreement(array) {
    return {
        type: SELECT_GENERAL_AGREEMENT,
        payload: array,
    };
}
export function setGridEventOnState(event) {
    return {
        type: SET_GRID_EVENT_ON_STATE,
        payload: event,
    }; 
}


// add or edit
export function openAddOrEditGeneralAgreementModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_GENERAL_AGREEMENT_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function resetSubmitSaveGeneralAgreementModal() {
    return {
        type: RESET_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL,
    };
}
export function addOrEditGeneralAgreemenChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_GENERAL_AGREEMENT_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startSubmitSaveGeneralAgreementModal() {
    return {
      type: START_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL,
    };
}
export function submitSaveGeneralAgreementModal(mode, bean) {
    let url = UrlConfig.INSERT_AGREEMENT_INFO;  // ADD, COPY
    if(mode === 'EDIT') {
        url = UrlConfig.UPDATE_AGREEMENT_INFO;
    }
    return function(dispatch) {
        dispatch(startSubmitSaveGeneralAgreementModal());
        httpClient
          .post(url, bean)
          .then(resp => dispatch(submitSaveGeneralAgreementModalSuccess(resp)))
          .catch(err => dispatch(submitSaveGeneralAgreementModalFailure(err)));
    };
}
function submitSaveGeneralAgreementModalSuccess(resp) {
    return {
      type: SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_SUCCESS,
      payload: resp,
    };
}
function submitSaveGeneralAgreementModalFailure(err) {
    return {
        type: SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveGeneralAgreementModalActionStatus() {
    return {
      type: INIT_SUBMIT_SAVE_GENERAL_AGREEMENT_MODAL_ACTION_STATUS,
    };
}


// change status
export function openChangeGeneralAgreementStatusModal(mode, data) {
    return {
        type: OPEN_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function resetChangeGeneralAgreementStatusModal() {
    return {
        type: RESET_CHANGE_GENERAL_AGREEMENT_STATUS_MODAL,
    };
}
export function changeGeneralAgreemenStatusChangeInput({name, value}) {
    return {
        type: CHANGE_GENERAL_AGREEMENT_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startSubmitChangeGeneralAgreementStatusModal() {
    return {
      type: START_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS,
    };
}
export function submitChangeGeneralAgreementStatusModal(bean) {
    return function(dispatch) {
        dispatch(startSubmitChangeGeneralAgreementStatusModal());
        httpClient
          .post(UrlConfig.SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS, bean)
          .then(resp => dispatch(submitChangeGeneralAgreementStatusModalSuccess(resp)))
          .catch(err => dispatch(submitChangeGeneralAgreementStatusModalFailure(err)));
    };
}
function submitChangeGeneralAgreementStatusModalSuccess(resp) {
    return {
      type: SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_SUCCESS,
      payload: resp,
    };
}
function submitChangeGeneralAgreementStatusModalFailure(err) {
    return {
        type: SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_FAILURE,
        payload: err,
    };
}
export function initSubmitChangeGeneralAgreementStatusModalActionStatus() {
    return {
      type: INIT_SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS_ACTION_STATUS,
    };
}


// showAllshowValid
export function showAllShowValidBtnOnClicked() {
    return {
        type: SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,
    }
}
