import React, { Component } from 'react';

import moment from 'moment';
import { Form, Row, Col, Input, Button, Modal, Select, DatePicker, message, Radio } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../../utils/RadioOperationMode';
import { RESTRICTION_TYPE_MAP } from '../../../../../utils/RestrictionTypeUtil';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const options = [
  { label: 'White List', value: true },
  { label: 'Black List', value: false },
];

// Restriction Type
const RestrictionTypeOptions = [];
for (let i = 0; i < RESTRICTION_TYPE_MAP.length; i++) {
  RestrictionTypeOptions.push(<Option key={RESTRICTION_TYPE_MAP[i].code}>{RESTRICTION_TYPE_MAP[i].label}</Option>);
}


class RestrictedCountryEditDialog extends Component {
  state = {
    
  };

  componentDidUpdate() {
    const { 
      createRestrictedCountryActionStatus,
      updateRestrictedCountryActionStatus,
    } = this.props;

    // 创建 Restricted Country 成功
    if (createRestrictedCountryActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedCountries();
      this.props.initCreateRestrictedCountryActionStatus();

      this.props.resetRestrictedCountryEditModalDialog();
    }
      
    // 创建 Restricted Country 失败
    if (createRestrictedCountryActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCreateRestrictedCountryActionStatus();
    }

    // 更新 Restricted Country 成功
    if (updateRestrictedCountryActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedCountries();
      this.props.initUpdateRestrictedCountryActionStatus();

      this.props.resetRestrictedCountryEditModalDialog();
    } 

    // 更新 Restricted Country 失败
    if (updateRestrictedCountryActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateRestrictedCountryActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.restrictedCountryEditChangeInput({name, value});
  }

  countryCodeOnChange = (value) => {
    this.props.restrictedCountryEditChangeInput({ name: 'countryCode', value });
    this.props.countryCodeSelectChanged(value);
  }

  fundCodeOnChange = (value) => {
    this.props.restrictedCountryEditChangeInput({ name: 'fundCode', value });

    if (!!value) {
      this.props.fundCodeSelectChanged(value);
    }
  }

  bookCodeOnChange = (value) => {
    this.props.restrictedCountryEditChangeInput({ name: 'bookCode', value });

    if (!!value) {
      this.props.bookCodeSelectChanged(value);
    }
  }

  radioGroupOnChanged = (e) => {
    this.props.restrictedCountryEditChangeInput({ name: 'isWhite', value: e.target.value });
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      restrictedCountryEditControlModal,
    } = this.props;

    const isAddMode = restrictedCountryEditControlModal.isAddMode;

    const {
      id,
      restrictionType,
      fundCode,
      bookCode,
      pmName,
      countryId,
      countryCode,
      explanation,
      kickOffDate,
      expiryDate,
      isWhite,
    } = restrictedCountryEditControlModal.fields;

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          console.info('success');

          if (isAddMode) {
            this.props.createRestrictedCountry({
              id,
              restrictionType,
              fundCode,
              bookCode,
              pmName,
              countryId,
              countryCode,
              explanation,
              kickOffDate,
              expiryDate,
              isWhite,
            });
          } else {
            this.props.updateRestrictedCountry({
              id,
              restrictionType,
              fundCode,
              bookCode,
              pmName,
              countryId,
              countryCode,
              explanation,
              kickOffDate,
              expiryDate,
              isWhite,
            });
          }
        }
      },
    );
  }

  render() {
    const {
      restrictedCountryEditControlModal,
      fundCodeOptions,
      bookCodeOptions,
      countryCodeOptions,
      createRestrictedCountryActionStatus,
      updateRestrictedCountryActionStatus,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = restrictedCountryEditControlModal;


    const { getFieldDecorator } = this.props.form;

    const {
      id,
      restrictionType,
      fundCode,
      bookCode,
      pmName,
      countryId,
      countryCode,
      explanation,
      kickOffDate,
      expiryDate,
      isWhite,
    } = restrictedCountryEditControlModal.fields;

    let modalTitle = "Edit Restricted Country";
    if (isAddMode) {
      modalTitle = "Add Restricted Country";
    }

    const isLoading = createRestrictedCountryActionStatus === ACTION_STATUS.LOGINING || updateRestrictedCountryActionStatus === ACTION_STATUS.LOGINING; 
    
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedCountryEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Restriction Type">
                  {getFieldDecorator('restrictionType', {
                      initialValue: restrictionType,
                      rules: [{
                        required: true,
                        message: 'Please select restriction type',
                      }],
                    })(
                      <Select onChange={(value) => {
                            this.onInputChange({ name: 'restrictionType', value })
                          }} placeholder="Please Choose"
                        >
                        {RestrictionTypeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Fund">
                  {getFieldDecorator('fundCode', {
                      initialValue: fundCode,
                      rules: [{
                        required: true,
                        message: 'Please select fund',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.fundCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {fundCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Book">
                  {getFieldDecorator('bookCode', {
                      initialValue: bookCode,
                      rules: [{
                        required: true,
                        message: 'Please select book',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.bookCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {bookCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="PM Name">
                  <Input placeholder="" 
                    value={pmName}
                    disabled
                    onChange={(e) => {
                        this.onInputChange({ name: 'pmName', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Country Id">
                  <Input placeholder="" 
                    value={countryId}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Country Code">
                  {getFieldDecorator('countryCode', {
                      initialValue: countryCode,
                      rules: [{
                        required: true,
                        message: 'Please select country code"',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.countryCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {countryCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Explanation">
                  <TextArea placeholder="Explanation" 
                    value={explanation}
                    onChange={(e) => {
                        this.onInputChange({ name: 'explanation', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Kick Off Date">
                  {getFieldDecorator('kickOffDate', {
                    initialValue: kickOffDate === '' ? null : moment(kickOffDate),
                    rules: [{
                      required: true,
                      message: 'Please select kick off date',
                    }],
                  })(
                    <DatePicker 
                      allowClear={false}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'kickOffDate', value: dateString });
                      }} />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Expiry Date">
                  {getFieldDecorator('expiryDate', {
                    initialValue: expiryDate === '' ? null : moment(expiryDate),
                    rules: [{
                      required: true,
                      message: 'Please select expiry date',
                    }],
                  })(
                    <DatePicker 
                      allowClear={false}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'expiryDate', value: dateString });
                      }} 
                      />
                   )
                  } 
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="List Type">
                  <Radio.Group options={options} defaultValue={isWhite} onChange={this.radioGroupOnChanged} />
                </FormItem>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ respErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const RestrictedCountryEditDialogForm = Form.create({ name: 'restricted_country_edit_dialog' })(RestrictedCountryEditDialog);

export default RestrictedCountryEditDialogForm;