import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../utils/RadioOperationMode';
import { Select } from 'antd';

import {
  // Load Restricted Countries
  START_LOAD_RESTRICTED_COUNTRIES,
  LOAD_RESTRICTED_COUNTRIES_SUCCESS,
  LOAD_RESTRICTED_COUNTRIES_FAILURE,
  INIT_LOAD_RESTRICTED_COUNTRIES_ACTION_STATUS,

  // Create Restricted Country
  START_CREATE_RESTRICTED_COUNTRY,
  CREATE_RESTRICTED_COUNTRY_SUCCESS,
  CREATE_RESTRICTED_COUNTRY_FAILURE,
  INIT_CREATE_RESTRICTED_COUNTRY_ACTION_STATUS,

  // Update Restricted Country
  START_UPDATE_RESTRICTED_COUNTRY,
  UPDATE_RESTRICTED_COUNTRY_SUCCESS,
  UPDATE_RESTRICTED_COUNTRY_FAILURE,
  INIT_UPDATE_RESTRICTED_COUNTRY_ACTION_STATUS,

  // Batch Delete Restricted Country
  START_BATCH_DELETE_RESTRICTED_COUNTRY,
  BATCH_DELETE_RESTRICTED_COUNTRY_SUCCESS,
  BATCH_DELETE_RESTRICTED_COUNTRY_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_COUNTRY_ACTION_STATUS,

  // Open/Reset Restricted Country Modal Dialog
  RESTRICTED_COUNTRY_MODAL_DIALOG_OPEN,
  RESTRICTED_COUNTRY_MODAL_DIALOG_RESET,

  // Restricted Country Edit Change Input
  RESTRICTED_COUNTRY_EDIT_CHANGE_INPUT,

  // RadioGroup Change
  RADIO_GROUP_CHANGE,

  // Country Code Select Changed
  COUNTRY_CODE_SELECT_CHANGED,

  // Select Restricted Country
  SELECT_RESTRICTED_COUNTRY,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,
} from './restrictedCountryConstants';

import {
  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,

  // Load Country Codes
  LOAD_COUNTRY_CODES_SUCCESS,
} from '../../home/homeConstants';

const Option = Select.Option;

const initialState = {
  restrictedCountryGridColumns: GridColumnMap.restrictedCountryGridColumns,

  // Restricted Country
  restrictedCountryArray: [],
  filterRestrictedCountryArray: [],

  restrictedCountryEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      id: '',
      restrictionType: '',
      fundCode: '',
      bookCode: '',
      pmName: '',
      countryId: '',
      countryCode: '',
      explanation: '',
      kickOffDate: '',
      expiryDate: '',
      isWhite: true,
    }
  },

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // Load Country Codes
  countryCodeDOArray: [],
  countryCodeArray: [],
  countryCodeOptions: [],

  // Load Restricted Country Action Status
  loadRestrictedCountryActionStatus: ACTION_STATUS.READY,
  createRestrictedCountryActionStatus: ACTION_STATUS.READY,
  updateRestrictedCountryActionStatus: ACTION_STATUS.READY,
  batchDeleteRestrictedCountryActionStatus: ACTION_STATUS.READY,

  // Selected Restricted Country
  selectedRestrictedCountry: [],
}


// ========================================================================
// Load Restricted Countries
function startLoadRestrictedCountries(state) {
  return {
    ...state,
    loadRestrictedCountryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedCountriesSuccess(state, resp) {
  return {
    ...state,
    restrictedCountryArray: resp.data,
    filterRestrictedCountryArray: resp.data,
    loadRestrictedCountryActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedCountriesFailure(state, err) {
  return {
    ...state,
    loadRestrictedCountryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedCountriesActionStatus(state) {
  return {
    ...state,
    loadRestrictedCountryActionStatus: ACTION_STATUS.READY,
  };
}

// Create Restricted Country
function startCreateRestrictedCountry(state) {
  return {
    ...state,
    createRestrictedCountryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createRestrictedCountrySuccess(state, resp) {
  return {
    ...state,
    createRestrictedCountryActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createRestrictedCountryFailure(state, err) {
  return {
    ...state,
    createRestrictedCountryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCreateRestrictedCountryActionStatus(state) {
  return {
    ...state,
    createRestrictedCountryActionStatus: ACTION_STATUS.READY,
  };
}

// Update Restricted Country
function startUpdateRestrictedCountry(state) {
  return {
    ...state,
    updateRestrictedCountryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateRestrictedCountrySuccess(state, resp) {
  return {
    ...state,
    updateRestrictedCountryActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateRestrictedCountryFailure(state, err) {
  return {
    ...state,
    updateRestrictedCountryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initUpdateRestrictedCountryActionStatus(state) {
  return {
    ...state,
    updateRestrictedCountryActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Restricted Country
function startBatchDeleteRestrictedCountry(state) {
  return {
    ...state,
    batchDeleteRestrictedCountryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteRestrictedCountrySuccess(state, resp) {
  return {
    ...state,
    batchDeleteRestrictedCountryActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteRestrictedCountryFailure(state, err) {
  return {
    ...state,
    batchDeleteRestrictedCountryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteRestrictedCountryActionStatus(state) {
  return {
    ...state,
    batchDeleteRestrictedCountryActionStatus: ACTION_STATUS.READY,
  };
}


// ========================================================================
// RadioGroup Change
function radioGroupChange(state, value) {
  const restrictedCountryArray = state.restrictedCountryArray;
  let filterRestrictedCountryArray = [];

  if (value === RadioOperationMode.ALL) {
    filterRestrictedCountryArray = restrictedCountryArray;
  }

  if (value === RadioOperationMode.WHITE) {
    filterRestrictedCountryArray = restrictedCountryArray.filter(restrictedCountry => restrictedCountry.isWhite);
  }

  if (value === RadioOperationMode.BLACK) {
    filterRestrictedCountryArray = restrictedCountryArray.filter(restrictedCountry => !restrictedCountry.isWhite);
  }

  return {
    ...state,
    filterRestrictedCountryArray,
  };
}

// Open/Reset Restricted Country Modal Dialog
function openRestrictedCountryEditModalDialog(state, {isAddMode, restrictedCountry}) {
  if (isAddMode) {
    return {
      ...state,
      restrictedCountryEditControlModal: {
        ...state.restrictedCountryEditControlModal,
        isOpened: true,
        isAddMode,
      },
    };
  } else {
    return {
      ...state,
      restrictedCountryEditControlModal: {
        ...state.restrictedCountryEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedCountryEditControlModal.fields,
          id: restrictedCountry.id,
          restrictionType: restrictedCountry.restrictionType,
          fundCode: restrictedCountry.fund,
          bookCode: restrictedCountry.book,
          pmName: restrictedCountry.pmName,
          countryId: restrictedCountry.countryId,
          countryCode: restrictedCountry.countryCode,
          explanation: restrictedCountry.explanation,
          kickOffDate: restrictedCountry.kickOffDate,
          expiryDate: restrictedCountry.expiryDate,
          isWhite: restrictedCountry.isWhite,
        }
      },
    };
  }
}

function resetRestrictedCountryEditModalDialog(state) {
  return {
    ...state,
    restrictedCountryEditControlModal: initialState.restrictedCountryEditControlModal,
  };
}

// Restricted Country Edit Change Input
function restrictedCountryEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedCountryEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedCountryEditControlModal: {
      ...state.restrictedCountryEditControlModal,
      fields: updatedFields,
    },
  };
}


// ========================================================================
// Country Code Select Changed
function countryCodeSelectChanged(state, countryCode) {
  let countryCodeDOArray = state.countryCodeDOArray;
  let filterCountryCodeArray = countryCodeDOArray.filter(countryCodeDO => countryCodeDO.countryCode === countryCode);
  
  let countryId = '';
  if (!!countryCode && filterCountryCodeArray.length !== 0) {
    countryId = filterCountryCodeArray[0].countryId;
  }

  return {
    ...state,
    restrictedCountryEditControlModal: {
      ...state.restrictedCountryEditControlModal,
      fields: {
        ...state.restrictedCountryEditControlModal.fields,
        countryId,
      },
    },
  };
}

// select Restricted Country
function selectRestrictedCountry(state, payload) {
  return {
    ...state,
    selectedRestrictedCountry: payload,
  };
}

// Fund Code Select Changed
function fundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedCountryEditControlModal.fields.bookCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedCountryEditControlModal: {
      ...state.restrictedCountryEditControlModal,
      fields: {
        ...state.restrictedCountryEditControlModal.fields,
        pmName,
      },
    },
  };
}

// Book Code Select Changed
function bookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedCountryEditControlModal.fields.fundCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedCountryEditControlModal: {
      ...state.restrictedCountryEditControlModal,
      fields: {
        ...state.restrictedCountryEditControlModal.fields,
        pmName,
      },
    },
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// Load Country Codes
function loadCountryCodesSuccess(state, resp) {
  let countryCodeDOArray = resp.data;
  let countryCodeArray = countryCodeDOArray.map(countryCodeDO => countryCodeDO.countryCode);
  let countryCodeOptions = countryCodeArray.map(countryCode => <Option key={countryCode}>{countryCode}</Option>);

  return {
    ...state,
    countryCodeDOArray,
    countryCodeArray,
    countryCodeOptions,
  };
}


export default createReducer(initialState, {
  // Load Restricted Country
  [START_LOAD_RESTRICTED_COUNTRIES]: startLoadRestrictedCountries,
  [LOAD_RESTRICTED_COUNTRIES_SUCCESS]: loadRestrictedCountriesSuccess,
  [LOAD_RESTRICTED_COUNTRIES_FAILURE]: loadRestrictedCountriesFailure,
  [INIT_LOAD_RESTRICTED_COUNTRIES_ACTION_STATUS]: initLoadRestrictedCountriesActionStatus,

  // Create Restricted Country
  [START_CREATE_RESTRICTED_COUNTRY]: startCreateRestrictedCountry,
  [CREATE_RESTRICTED_COUNTRY_SUCCESS]: createRestrictedCountrySuccess,
  [CREATE_RESTRICTED_COUNTRY_FAILURE]: createRestrictedCountryFailure,
  [INIT_CREATE_RESTRICTED_COUNTRY_ACTION_STATUS]: initCreateRestrictedCountryActionStatus,

  // Update Restricted Country
  [START_UPDATE_RESTRICTED_COUNTRY]: startUpdateRestrictedCountry,
  [UPDATE_RESTRICTED_COUNTRY_SUCCESS]: updateRestrictedCountrySuccess,
  [UPDATE_RESTRICTED_COUNTRY_FAILURE]: updateRestrictedCountryFailure,
  [INIT_UPDATE_RESTRICTED_COUNTRY_ACTION_STATUS]: initUpdateRestrictedCountryActionStatus,

  // Batch Delete Restricted Country
  [START_BATCH_DELETE_RESTRICTED_COUNTRY]: startBatchDeleteRestrictedCountry,
  [BATCH_DELETE_RESTRICTED_COUNTRY_SUCCESS]: batchDeleteRestrictedCountrySuccess,
  [BATCH_DELETE_RESTRICTED_COUNTRY_FAILURE]: batchDeleteRestrictedCountryFailure,
  [INIT_BATCH_DELETE_RESTRICTED_COUNTRY_ACTION_STATUS]: initBatchDeleteRestrictedCountryActionStatus,

  // Open/Reset Restricted Country Modal Dialog
  [RESTRICTED_COUNTRY_MODAL_DIALOG_OPEN]: openRestrictedCountryEditModalDialog,
  [RESTRICTED_COUNTRY_MODAL_DIALOG_RESET]: resetRestrictedCountryEditModalDialog,

  // Restricted Country Edit Change Input
  [RESTRICTED_COUNTRY_EDIT_CHANGE_INPUT]: restrictedCountryEditChangeInput,

  // RadioGroup Change
  [RADIO_GROUP_CHANGE]: radioGroupChange,

  // Country Code Select Changed
  [COUNTRY_CODE_SELECT_CHANGED]: countryCodeSelectChanged,

  // Select Restricted Country
  [SELECT_RESTRICTED_COUNTRY]: selectRestrictedCountry,

  // Fund Code Select Changed
  [FUND_CODE_SELECT_CHANGED]: fundCodeSelectChanged,

  // Book Code Select Changed
  [BOOK_CODE_SELECT_CHANGED]: bookCodeSelectChanged,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load Country Codes
  [LOAD_COUNTRY_CODES_SUCCESS]: loadCountryCodesSuccess,
});