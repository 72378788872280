import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { Select } from 'antd';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query input
    ON_ELEMENT_QUERY_INPUT_CHANGE,
    // select data
    SELECT_ELEMENT_LIST,
    SELECT_ELEMENT_PROPS_LIST,
    // query element
    START_QUERY_ELEMENT,
    QUERY_ELEMENT_SUCCESS,
    QUERY_ELEMENT_FAILURE,
    INIT_QUERY_ELEMENT_ACTION_STATUS,
    // add or edit element
    RESET_ADD_OR_EDIT_ELEMENT_MODAL,
    CONSTRUCT_ELEMENT_TYPE_SELECT_OPTIONS_AND_OPEN_MODAL,
    CONSTRUCT_ELEMENT_SELECT_OPTIONS,
    ON_ELEMENT_DATA_INPUT_CHANGE,
    RESET_ELEMENT_PROPS,
    RESET_INIT_EDIT_OF_ADD_OR_EDIT_ELEMENT_MODAL,
    APPEND_PROPS_FORM_ITEM_TO_STATE,
    ON_ELEMENT_PROPS_INPUT_CHANGE,
    START_SUBMIT_ADD_OR_EDIT_ELEMENT,
    SUBMIT_ADD_OR_EDIT_ELEMENT_SUCCESS,
    SUBMIT_ADD_OR_EDIT_ELEMENT_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_ACTION_STATUS,
    // del element
    START_DEL_ELEMENT,
    DEL_ELEMENT_SUCCESS,
    DEL_ELEMENT_FAILURE,
    INIT_DEL_ELEMENT_ACTION_STATUS,
    // del props
    START_DEL_PROPS,
    DEL_PROPS_SUCCESS,
    DEL_PROPS_FAILURE,
    INIT_DEL_PROPS_ACTION_STATUS,

    
} from './ElementMngConstants';


const initialState = {
    elementQueryFields: {
        elementName: undefined,
    },
    selectedElementList: [],
    selectedElementPropsList: [],
    addOrEditElementModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        initEdit: false,
        fields: {
            id: undefined,
            elementTypeId: undefined,
            elementName: undefined,
            elementPropsList: [],
        },
    },
    elementTypeList: [],
    elementTypeSelectOptions: [],
    entitySelectOptions: [],
    propsFormItemArray: [],

};


// query input
function onElementQueryInputChange(state, {name, value}) {
    return {
        ...state,
        elementQueryFields: {
            ...state.elementQueryFields,
            [name]: value,
        }
    };
}


// select data
function selectElementList(state, data) {
    return {
        ...state,
        selectedElementList: data,
    }
}
function selectElementPropsList(state, data) {
    return {
        ...state,
        selectedElementPropsList: data,
    }  
}


// query element
function startQueryElement(state) {
    return {
      ...state,
      queryElementActionStatus: ACTION_STATUS.LOGINING,
    };
}
function queryElementSuccess(state, resp) {
    return {
        ...state,
        queryElementActionStatus: ACTION_STATUS.SUCCESS,
        queryElementActionResp: resp,
    };
}
function queryElementFailure(state, err) {
    return {
        ...state,
        queryElementActionStatus: ACTION_STATUS.ERROR,
        queryElementActionResp: err,
    };
}
function initQueryElementActionStatus(state) {
    return {
        ...state,
        queryElementActionStatus: ACTION_STATUS.READY,
        selectedElementList: [],
        selectedElementPropsList: [],
    };
}



// add or edit element

function resetAddOrEditElementModal(state) {
    return {
        ...state,
        addOrEditElementModal: initialState.addOrEditElementModal,
        elementTypeList: [],
        elementTypeSelectOptions: [],
        propsFormItemArray: [],
    }
}
function constructElementTypeSelectOptionsAndOpenModal(state, {resp, mode, data}) {
    const options = resp.data.sort((a, b) => {
                            const baseTypeCompare = a.baseType > b.baseType ? 1 : (a.baseType < b.baseType ? -1 : 0);
                            if(baseTypeCompare !== 0) {
                                return baseTypeCompare;
                            }
                            return a.elementTypeName > b.elementTypeName ? 1 : (a.elementTypeName < b.elementTypeName ? -1 : 0);
                        })
                        .map(e => <Select.Option key={e.id}>{e.baseType} / {e.elementTypeName}</Select.Option>);

    if(mode === 'ADD') {
        return {
            ...state,
            elementTypeList: resp.data,
            elementTypeSelectOptions: options,
            addOrEditElementModal: {
                ...state.addOrEditElementModal,
                mode,
                isOpened: true,
            }
        }
    } else {
        return {
            ...state,
            elementTypeList: resp.data,
            elementTypeSelectOptions: options,
            addOrEditElementModal: {
                ...state.addOrEditElementModal,
                mode,
                isOpened: true,
                initEdit: true,
                fields: {
                    id: data.id,
                    elementTypeId: data.elementTypeId,
                    elementName: data.elementName,
                    elementPropsList: data.elementPropsList,
                },
            }
        }
    }   
}
function constructElementSelectOptions(state, resp) {
    const options = resp.data.filter(e => e.elementType.baseType === 'Entity')
                            .sort((a,b) => {
                                const baseTypeCompare = a.elementType.baseType > b.elementType.baseType ? 1 : (a.elementType.baseType < b.elementType.baseType ? -1 : 0);
                                if(baseTypeCompare !== 0) {
                                    return baseTypeCompare;
                                }
                                const elementTypeNameCompare = a.elementType.elementTypeName > b.elementType.elementTypeName ? 1 : (a.elementType.elementTypeName < b.elementType.elementTypeName ? -1 : 0);
                                if(elementTypeNameCompare !== 0) {
                                    return elementTypeNameCompare;
                                }
                                return a.elementName > b.elementName ? 1 : (a.elementName < b.elementName ? -1 : 0);
                            })
                            .map(e => <Select.Option key={e.id}>{e.elementType.baseType} / {e.elementType.elementTypeName} / {e.elementName}</Select.Option>);
   
    return {
        ...state,
        entitySelectOptions: options,
    }
        
}



function onElementDataInputChange(state, {name, value}) {
    return {
        ...state,
        addOrEditElementModal: {
            ...state.addOrEditElementModal,
            fields: {
                ...state.addOrEditElementModal.fields,
                [name]: value,
            }
        }
    }
}
function resetElementProps(state) {
    return {
        ...state,
        addOrEditElementModal: {
            ...state.addOrEditElementModal,
            fields: {
                ...state.addOrEditElementModal.fields,
                elementPropsList: [],
            }
        }
    }
}
function resetInitEditOfAddOrEditElementModal(state) {
    return {
        ...state,
        addOrEditElementModal: {
            ...state.addOrEditElementModal,
            initEdit: false,
        }
    }
}


function appendPropsFormItemToState(state, array) {
    return {
        ...state,
        propsFormItemArray: array,
    }
}

function onElementPropsInputChange(state, props) {
    const newPropsList = [];
    let replace = false;
    const currPropsList = state.addOrEditElementModal.fields.elementPropsList;
    currPropsList.forEach(e => {
        if(e.elementId === props.elementId && e.fieldId === props.fieldId) {
            newPropsList.push(props);
            replace = true;
        } else {
            newPropsList.push(e);
        }
    });
    if(!replace) {
        newPropsList.push(props);
    }
    return {
        ...state,
        addOrEditElementModal: {
            ...state.addOrEditElementModal,
            initEdit: true,
            fields: {
                ...state.addOrEditElementModal.fields,
                elementPropsList: newPropsList,
            }
        }
    }
}


function startSubmitAddOrEditElement(state) {
    return {
      ...state,
      addOrEditElementActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitAddOrEditElementSuccess(state, resp) {
    return {
        ...state,
        addOrEditElementActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitAddOrEditElementFailure(state, err) {
    return {
        ...state,
        addOrEditElementActionStatus: ACTION_STATUS.ERROR,
        addOrEditElementModal: {
            ...state.addOrEditElementModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitAddOrEditElementActionStatus(state) {
    return {
        ...state,
        addOrEditElementActionStatus: ACTION_STATUS.READY,
    };
}


// del element
function startDelElement(state) {
    return {
      ...state,
      delElementActionStatus: ACTION_STATUS.LOGINING,
    };
}
function delElementSuccess(state, resp) {
    return {
        ...state,
        delElementActionStatus: ACTION_STATUS.SUCCESS,
        delElementActionResp:  resp,
    };
}
function delElementFailure(state, err) {
    return {
        ...state,
        delElementActionStatus: ACTION_STATUS.ERROR,
        delElementActionResp:  err,
    };
}
function initDelElementActionStatus(state) {
    return {
        ...state,
        delElementActionStatus: ACTION_STATUS.READY,
    };
}


// del props
function startDelProps(state) {
    return {
      ...state,
      delPropsActionStatus: ACTION_STATUS.LOGINING,
    };
}
function delPropsSuccess(state, resp) {
    return {
        ...state,
        delPropsActionStatus: ACTION_STATUS.SUCCESS,
        delPropsActionResp:  resp,
    };
}
function delPropsFailure(state, err) {
    return {
        ...state,
        delPropsActionStatus: ACTION_STATUS.ERROR,
        delPropsActionResp:  err,
    };
}
function initDelPropsActionStatus(state) {
    return {
        ...state,
        delPropsActionStatus: ACTION_STATUS.READY,
    };
}





export default createReducer(initialState, {
    // query input
    [ON_ELEMENT_QUERY_INPUT_CHANGE]: onElementQueryInputChange,
    // select data
    [SELECT_ELEMENT_LIST]: selectElementList,
    [SELECT_ELEMENT_PROPS_LIST]: selectElementPropsList,
    // query element
    [START_QUERY_ELEMENT]: startQueryElement,
    [QUERY_ELEMENT_SUCCESS]: queryElementSuccess,
    [QUERY_ELEMENT_FAILURE]: queryElementFailure,
    [INIT_QUERY_ELEMENT_ACTION_STATUS]: initQueryElementActionStatus,
    // add or edit element
    [RESET_ADD_OR_EDIT_ELEMENT_MODAL]: resetAddOrEditElementModal,
    [CONSTRUCT_ELEMENT_TYPE_SELECT_OPTIONS_AND_OPEN_MODAL]: constructElementTypeSelectOptionsAndOpenModal,
    [CONSTRUCT_ELEMENT_SELECT_OPTIONS]: constructElementSelectOptions,
    [ON_ELEMENT_DATA_INPUT_CHANGE]: onElementDataInputChange,
    [RESET_ELEMENT_PROPS]: resetElementProps,
    [RESET_INIT_EDIT_OF_ADD_OR_EDIT_ELEMENT_MODAL]: resetInitEditOfAddOrEditElementModal,
    [APPEND_PROPS_FORM_ITEM_TO_STATE]: appendPropsFormItemToState,
    [ON_ELEMENT_PROPS_INPUT_CHANGE]: onElementPropsInputChange,
    [START_SUBMIT_ADD_OR_EDIT_ELEMENT]: startSubmitAddOrEditElement,
    [SUBMIT_ADD_OR_EDIT_ELEMENT_SUCCESS]: submitAddOrEditElementSuccess,
    [SUBMIT_ADD_OR_EDIT_ELEMENT_FAILURE]: submitAddOrEditElementFailure,
    [INIT_SUBMIT_ADD_OR_EDIT_ELEMENT_ACTION_STATUS]: initSubmitAddOrEditElementActionStatus,
    // del element
    [START_DEL_ELEMENT]: startDelElement,
    [DEL_ELEMENT_SUCCESS]: delElementSuccess,
    [DEL_ELEMENT_FAILURE]: delElementFailure,
    [INIT_DEL_ELEMENT_ACTION_STATUS]: initDelElementActionStatus,
    // del props
    [START_DEL_PROPS]: startDelProps,
    [DEL_PROPS_SUCCESS]: delPropsSuccess,
    [DEL_PROPS_FAILURE]: delPropsFailure,
    [INIT_DEL_PROPS_ACTION_STATUS]: initDelPropsActionStatus,

});
