export const   QUERY_GIFT_HOSPITALITY_INPUT_CHANGE = 'QUERY_GIFT_HOSPITALITY_INPUT_CHANGE';

// Load Gift Hospitality Request
export const START_LOAD_GIFT_HOSPITALITY_REQUEST = 'START_LOAD_GIFT_HOSPITALITY_REQUEST';
export const LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE = 'LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Select Gift Hospitality Request
export const SELECT_GIFT_HOSPITALITY_REQUEST = 'SELECT_GIFT_HOSPITALITY_REQUEST';

// Open/Reset Gift Hospitality Request Modal Dialog
export const GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN = 'GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN';
export const GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET = 'GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET';

// Gift Hospitality Request Edit Change Input
export const GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT = 'GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT';

// Approve Gift Hospitality Request
export const START_APPROVE_GIFT_HOSPITALITY_REQUEST = 'START_APPROVE_GIFT_HOSPITALITY_REQUEST';
export const APPROVE_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'APPROVE_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const APPROVE_GIFT_HOSPITALITY_REQUEST_FAILURE = 'APPROVE_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_APPROVE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_APPROVE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Reject Gift Hospitality Request
export const START_REJECT_GIFT_HOSPITALITY_REQUEST = 'START_REJECT_GIFT_HOSPITALITY_REQUEST';
export const REJECT_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'REJECT_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const REJECT_GIFT_HOSPITALITY_REQUEST_FAILURE = 'REJECT_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_REJECT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_REJECT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Feedback Gift Hospitality Request
export const START_FEEDBACK_GIFT_HOSPITALITY_REQUEST = 'START_FEEDBACK_GIFT_HOSPITALITY_REQUEST';
export const FEEDBACK_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'FEEDBACK_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const FEEDBACK_GIFT_HOSPITALITY_REQUEST_FAILURE = 'FEEDBACK_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_FEEDBACK_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_FEEDBACK_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';