// Load Restricted Rules
export const START_LOAD_RESTRICTED_RULES = 'START_LOAD_RESTRICTED_RULES';
export const LOAD_RESTRICTED_RULES_SUCCESS = 'LOAD_RESTRICTED_RULES_SUCCESS';
export const LOAD_RESTRICTED_RULES_FAILURE = 'LOAD_RESTRICTED_RULES_FAILURE';
export const INIT_LOAD_RESTRICTED_RULES_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_RULES_ACTION_STATUS';

// Create Restricted Rule
export const START_CREATE_RESTRICTED_RULE = 'START_CREATE_RESTRICTED_RULE';
export const CREATE_RESTRICTED_RULE_SUCCESS = 'CREATE_RESTRICTED_RULE_SUCCESS';
export const CREATE_RESTRICTED_RULE_FAILURE = 'CREATE_RESTRICTED_RULE_FAILURE';
export const INIT_CREATE_RESTRICTED_RULE_ACTION_STATUS = 'INIT_CREATE_RESTRICTED_RULE_ACTION_STATUS';

// Update Restricted Rule
export const START_UPDATE_RESTRICTED_RULE = 'START_UPDATE_RESTRICTED_RULE';
export const UPDATE_RESTRICTED_RULE_SUCCESS = 'UPDATE_RESTRICTED_RULE_SUCCESS';
export const UPDATE_RESTRICTED_RULE_FAILURE = 'UPDATE_RESTRICTED_RULE_FAILURE';
export const INIT_UPDATE_RESTRICTED_RULE_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_RULE_ACTION_STATUS';

// Batch Delete Restricted Rule
export const START_BATCH_DELETE_RESTRICTED_RULE = 'START_BATCH_DELETE_RESTRICTED_RULE';
export const BATCH_DELETE_RESTRICTED_RULE_SUCCESS = 'BATCH_DELETE_RESTRICTED_RULE_SUCCESS';
export const BATCH_DELETE_RESTRICTED_RULE_FAILURE = 'BATCH_DELETE_RESTRICTED_RULE_FAILURE';
export const INIT_BATCH_DELETE_RESTRICTED_RULE_ACTION_STATUS = 'INIT_BATCH_DELETE_RESTRICTED_RULE_ACTION_STATUS';

// Open/Reset Restricted Rule Modal Dialog
export const RESTRICTED_RULE_MODAL_DIALOG_OPEN = 'RESTRICTED_RULE_MODAL_DIALOG_OPEN';
export const RESTRICTED_RULE_MODAL_DIALOG_RESET = 'RESTRICTED_RULE_MODAL_DIALOG_RESET';

// Restricted Rule Edit Change Input
export const RESTRICTED_RULE_EDIT_CHANGE_INPUT = 'RESTRICTED_RULE_EDIT_CHANGE_INPUT';

// Load BBG Tickers
export const START_LOAD_BBG_TICKERS = 'START_LOAD_BBG_TICKERS';
export const LOAD_BBG_TICKERS_SUCCESS = 'LOAD_BBG_TICKERS_SUCCESS';
export const LOAD_BBG_TICKERS_FAILURE = 'LOAD_BBG_TICKERS_FAILURE';
export const INIT_LOAD_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_BBG_TICKERS_ACTION_STATUS';

// Clear bbgTickerArray bbgTickerOptions
export const CLEAR_BBG_TICKERS = 'CLEAR_BBG_TICKERS';

// BBG Ticker Select Changed
export const BBG_TICKER_SELECT_CHANGED = 'BBG_TICKER_SELECT_CHANGED';

// Fund Code Select Changed
export const FUND_CODE_SELECT_CHANGED = 'FUND_CODE_SELECT_CHANGED';

// Book Code Select Changed
export const BOOK_CODE_SELECT_CHANGED = 'BOOK_CODE_SELECT_CHANGED';

// Select Restricted Rules
export const SELECT_RESTRICTED_RULES = 'SELECT_RESTRICTED_RULES';

// Show Valid Restricted Rules
export const SHOW_VALID_RESTRICTED_RULES = 'SHOW_VALID_RESTRICTED_RULES';

// Show All Restricted Rules
export const SHOW_ALL_RESTRICTED_RULES = 'SHOW_ALL_RESTRICTED_RULES';