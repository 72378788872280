import {connect} from 'react-redux';
import Login from '../components/Login';

import {
  login,
  resetLoginStatus,
  logout,
} from '../authActions';

const mapStateToProps = state => {
  return {
    loginForm: state.auth.loginForm,
    loginStatus: state.auth.loginStatus,
    loginErrMsg: state.auth.loginErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    login: (user) => dispatch(login(user)),
    resetLoginStatus: () => dispatch(resetLoginStatus()),
    logout: () => dispatch(logout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);