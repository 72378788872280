import React, { Component } from 'react';
import { Modal, message, Button } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import './LicensingDashboard.css';
import OperationRcdGrid from './OperationRcdGrid';


class OperationRcdModalDialog extends Component {

    componentDidMount() {
    }


    componentDidUpdate() {
        const {
            queryOperationRcdStatus,
            queryOperationRcdResp,
        } = this.props;
        if(queryOperationRcdStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryOperationRcdActionStatus();
        }
        if(queryOperationRcdStatus === ACTION_STATUS.ERROR) {
            let err = 'Query operation record failed';
            if(!!queryOperationRcdResp && !!queryOperationRcdResp.respMessage) {
                err = queryOperationRcdResp.respMessage;
            }
            message.error(err);
            this.props.initQueryOperationRcdActionStatus();
        }
    }


    render() {
        const {
            operationRcdModal,
            queryOperationRcdStatus,
        } = this.props;
        const {
            isOpened
        } = operationRcdModal;

        const isLoading = queryOperationRcdStatus === ACTION_STATUS.LOGINING;

 
        return (
            <div>
                <Modal
                    title={'Operation Record'}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetOperationRcdModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetOperationRcdModal() }>
                          Close
                        </Button>,
                    ]}
                >
                    <div style={{ marginBottom: 10 }}>
                        <Button 
                            onClick={()=>this.props.queryOperationRcd()} 
                            loading={isLoading}
                            size='small'
                            type='primary'>
                            Refresh
                        </Button>
                    </div>

                    <div style={{ height: 500 }}><OperationRcdGrid {...this.props} /></div>

                </Modal>
            </div>
        );
    }

}

export default OperationRcdModalDialog;
