import React, {Component} from 'react';
import './WorkManagementDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider,DatePicker} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import WorkManagementGrid from './WorkManagementGrid';
import AddOrEditWorkManagementModalDialog from './AddOrEditWorkManagementModalDialog';
import AddOrEditWorkManagementSubModalDialog from './AddOrEditWorkManagementSubModalDialog';
import BatchChangeStatusModalDialog from './BatchChangeStatusModalDialog';
import FinishTaskModalDialog from './FinishTaskModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import moment from 'moment';
const { MonthPicker } = DatePicker;
class WorkManagementDashboard extends Component {
    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});
        this.props.loadWorkManagementInfo({id, location: undefined, fundCompany: undefined});
    }


    componentDidUpdate() {
        const {
            loadWorkManagementInfoActionStatus,
            loadWorkManagementInfoResp,
        } = this.props;
        if(loadWorkManagementInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadWorkManagementInfoActionStatus();
        }
        if(loadWorkManagementInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load Reporting Summary Info failed';
            if(!!loadWorkManagementInfoResp && !!loadWorkManagementInfoResp.respMessage) {
                err = loadWorkManagementInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadWorkManagementInfoActionStatus();
        }
    }

    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }

    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };




    refreshBtnOnClicked = () => {
        const {
            id,
            category,
            fundco,
            owner,
            monthStart,
            status,
            startDate,
            endDate,
        } = this.props.workManagementQueryFields;
        this.props.loadWorkManagementInfo({id, category, fundco,owner,monthStart,status,startDate,endDate});
    }


    addOrEditWorkManagementBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditWorkManagementModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedWorkManagementArray,
            } = this.props;
            if(selectedWorkManagementArray.length !== 1) {
                message.warning("Please choose one and only one Work Management");
                return;
            }
            const currentData = selectedWorkManagementArray[0];
            this.props.openAddOrEditWorkManagementModal(mode, currentData);
        }
    }

    addOrEditWorkManagementSubBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
             // COPY, EDIT
            const {
                selectedWorkManagementArray,
            } = this.props;
            if(selectedWorkManagementArray.length !== 1) {
                message.warning("Please choose one and only one Work Management");
                return;
            }
            const idList = selectedWorkManagementArray.map(e => e.id);
            this.props.openAddOrEditWorkManagementSubModal(mode, null,idList);
        } else {  // COPY, EDIT
            const {
                selectedWorkManagementTaskArray,
            } = this.props;
            if(selectedWorkManagementTaskArray.length !== 1) {
                message.warning("Please choose one and only one Work Management Sub");
                return;
            }
            const currentData = selectedWorkManagementTaskArray[0];
            this.props.openAddOrEditWorkManagementSubModal(mode, currentData,null);
        }
    }


    batchChangeStatusBtnOnClicked = (mode) => {
        if(mode === 'DEL'){
            const {
                selectedWorkManagementArray,
            } = this.props;
            if(selectedWorkManagementArray.length === 0) {
                message.warning("Please choose at least one Work Management");
                return;
            }
            const idList = selectedWorkManagementArray.map(e => e.id);
            this.props.openBatchChangeStatusModal(mode, idList);
        } else {
            const {
                selectedWorkManagementTaskArray,
            } = this.props;
            if(selectedWorkManagementTaskArray.length === 0) {
                message.warning("Please choose at least one Work Management Sub");
                return;
            }
            const idList = selectedWorkManagementTaskArray.map(e => e.id);
            this.props.openBatchChangeStatusModal(mode, idList);
        }

    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'dataType', 'otherInfo', 'operateType', 'comments', 'before', 'after'];
    constructor(props){
        super(props);
        this.state={
            detailData:{
            }
        }
    }
 
    onInputChange = ({name, value}) => {
        this.props.onQueryInputChange({ name, value });
        const { detailData } = this.state;
        let updateData =  {
            ...detailData,
            [name]: value
        };
        this.setState({
            detailData: updateData
        })
    }

    render() {

        const {
            detailData
        } = this.state;

        let data = {
            monthStart: moment().format('YYYY-MM'),
            fileList: []
        };
        data ={...data,...detailData};
       // let month =moment().format('YYYY-MM');
        const {
            locationOptions,
            fundCompanyOptions,
            loadWorkManagementInfoActionStatus,
            categoryOptions,
            fundCoOptions,
            statusOptions,
            monthStart,
        } = this.props;
        const {
            id,
            category,
            fundco,
            owner,
            endDate,
            startDate,
        } = this.props.workManagementQueryFields;
        const refreshBtnIsLoading = loadWorkManagementInfoActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div className='WorkManagementWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Departments</Breadcrumb.Item>
                        <Breadcrumb.Item>Work Management</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <div className='breadcrumbWrapper'>   
                    <Breadcrumb>
                    <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                    </Breadcrumb>
                    </div>
                    <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'category', value: val })
                        }} 

                        placeholder="Category">
                            {categoryOptions}
                    </Select>
                    <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'fundco', value: val })
                        }} 
                        placeholder="Fund/Co">
                            {fundCoOptions}
                    </Select>
                    <Input 
                        placeholder="Owner"
                        size="small"
                        style={{ width: 130, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onQueryInputChange({ name: 'owner', value: event.target.value })
                        }} 
                    />
                    <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='FromDate'
                        value={startDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'startDate', value: date });
                        }} 
                    />
                    <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='ToDate'
                        value={endDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'endDate', value: date });
                        }} 
                    />
                    {/* <MonthPicker
                        placeholder="Select Month"
                        style={{ marginLeft: 10, width: 130}}
                        allowClear={true}
                        // value={moment(
                        //     data.monthStart,
                        //     'YYYY-MM'
                        // )}
                        size="small"
                        onChange={(date, dateString) => {
                            this.onInputChange({
                            name: 'monthStart',
                            value: dateString
                            });
                        }}
                    /> */}


                    <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'status', value: val })
                        }} 
                        placeholder="Status">
                            {statusOptions}
                    </Select>
                    {/* <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='Start Date'
                        value={startDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'startDate', value: date });
                        }} 
                    />
                    <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='End Date'
                        value={endDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'endDate', value: date });
                        }} 
                    /> */}

                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditWorkManagementBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditWorkManagementBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditWorkManagementSubBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Sub Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditWorkManagementSubBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Sub Edit
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('DEL SUB')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Sub Del
                    </Button>

                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.addOrEditWorkManagementBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    
                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'WorkManagement'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button>

                </div>

                <div className='WorkManagementGrid'><WorkManagementGrid {...this.props} /></div>
                <AddOrEditWorkManagementModalDialog {...this.props} />
                <AddOrEditWorkManagementSubModalDialog {...this.props} />
                <BatchChangeStatusModalDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />

            </div>
        );
    }
}

export default WorkManagementDashboard;
