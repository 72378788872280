import React, { Component } from 'react';
import { Input, Modal } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
const { TextArea } = Input;

class PersonalTradeFeedbackDialog extends Component {

  componentDidUpdate() {
    const { 
      feedbackPersonalTradeActionStatus,
    } = this.props;

    // Feedback Personal Trade 成功
    if (feedbackPersonalTradeActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadPersonalTrade();
      this.props.initFeedbackPersonalTradeActionStatus();

      this.props.resetPersonalTradeFeedbackModalDialog();
    }
      
    // Feedback Personal Trade 失败
    if (feedbackPersonalTradeActionStatus === ACTION_STATUS.ERROR) {
      this.props.initFeedbackPersonalTradeActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.personalTradeFeedbackEditChangeInput({name, value});
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      personalTradeFeedbackControlModal,
    } = this.props;

    const {
      id,
      feedback,
    } = personalTradeFeedbackControlModal.fields;

    this.props.feedbackPersonalTrade(id, feedback);
  }

  render() {
    const {
      personalTradeFeedbackControlModal,
      feedbackPersonalTradeActionStatus,
    } = this.props;

    const {
      isOpened,
      respErrMsg,
    } = personalTradeFeedbackControlModal;

    const {
      feedback,
    } = personalTradeFeedbackControlModal.fields;

    let modalTitle = "Feedback Personal Trade";

    const isLoading = feedbackPersonalTradeActionStatus === ACTION_STATUS.LOGINING; 
    const submitBtnDisabled = feedback === '' || feedback === null;

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetPersonalTradeFeedbackModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okButtonProps={{ disabled: submitBtnDisabled }}
          okText="Submit"
          cancelText="Cancel"
        >
          <TextArea 
            rows={4} 
            value={feedback} 
            onChange={(e) => {
              this.onInputChange({ name: 'feedback', value: e.target.value })
            }}/>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default PersonalTradeFeedbackDialog;