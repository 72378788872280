// query
export const START_QUERY_GROUP_STRUCTURE = 'START_QUERY_GROUP_STRUCTURE';
export const QUERY_GROUP_STRUCTURE_SUCCESS = 'QUERY_GROUP_STRUCTURE_SUCCESS';
export const QUERY_GROUP_STRUCTURE_FAILURE = 'QUERY_GROUP_STRUCTURE_FAILURE';
export const INIT_QUERY_GROUP_STRUCTURE_ACTION_STATUS = 'INIT_QUERY_GROUP_STRUCTURE_ACTION_STATUS';
// dialog
export const OPEN_GROUP_STRUCTURE_DETAIL_DIALOG = 'OPEN_GROUP_STRUCTURE_DETAIL_DIALOG';
export const RESET_GROUP_STRUCTURE_DETAIL_DIALOG = 'RESET_GROUP_STRUCTURE_DETAIL_DIALOG';
//select
export const SELECT_GROUP_STRUCTURE = 'SELECT_GROUP_STRUCTURE';
// add or edit
export const OPEN_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL = 'OPEN_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL';
export const RESET_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL = 'RESET_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL';
export const ADD_OR_EDIT_GROUP_STRUCTURE_CHANGE_INPUT = 'ADD_OR_EDIT_GROUP_STRUCTURE_CHANGE_INPUT';
export const START_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE = 'START_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE';
export const SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_SUCCESS = 'SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_SUCCESS';
export const SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_FAILURE = 'SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_FAILURE';
export const INIT_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_ACTION_STATUS = 'INIT_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_ACTION_STATUS';
// del
export const OPEN_DEL_GROUP_STRUCTURE_MODAL = 'OPEN_DEL_GROUP_STRUCTURE_MODAL';
export const RESET_DEL_GROUP_STRUCTURE_MODAL = 'RESET_DEL_GROUP_STRUCTURE_MODAL';
export const DEL_GROUP_STRUCTURE_CHANGE_INPUT = 'DEL_GROUP_STRUCTURE_CHANGE_INPUT';
export const START_SUBMIT_DEL_GROUP_STRUCTURE = 'START_SUBMIT_DEL_GROUP_STRUCTURE';
export const SUBMIT_DEL_GROUP_STRUCTURE_SUCCESS = 'SUBMIT_DEL_GROUP_STRUCTURE_SUCCESS';
export const SUBMIT_DEL_GROUP_STRUCTURE_FAILURE = 'SUBMIT_DEL_GROUP_STRUCTURE_FAILURE';
export const INIT_SUBMIT_DEL_GROUP_STRUCTURE_ACTION_STATUS = 'INIT_SUBMIT_DEL_GROUP_STRUCTURE_ACTION_STATUS';

