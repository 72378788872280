import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import QuestionnaireAttachmentRenderer from '../../../renderer/QuestionnaireAttachmentRenderer';
import './Questionnaire.css';

class QuestionnaireListGrid extends Component {

  constructor(props) {
    super(props);

    let that = this;
    this.state = {
      columnDefs: props.questionnaireGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "multiple",
      groupDefaultExpanded: 0,
      context: { componentParent: that },
      frameworkComponents: {
        questionniareAttachmentRenderer: QuestionnaireAttachmentRenderer,
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs:props.questionListGridColumns,
          enableColResize: true,
          enableSorting: true,
          enableFilter: true,
          animateRows: true,
          deltaRowDataMode: false,
          context: { componentParent: that },
          frameworkComponents: {
            questionniareAttachmentRenderer: QuestionnaireAttachmentRenderer,
          },
          // Select Questions
          onSelectionChanged(event) {
            var rowNodeArray = event.api.getSelectedNodes();
            var questions = rowNodeArray.map(rowNode => rowNode.data);

            that.props.selectQuestions(questions);
          },
        },
        onGridReady: function(params) {
          params.api.setDomLayout("autoHeight");
        },
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.questionList);
        },
        getRowNodeId: function(data) {
          return data.id;
        },
      },
      getRowHeight: function(params) {
        if (params.node && params.node.detail) {
          var offset = 80;
          let length = 2;
          if (params.data.questionList.length !== 0) {
            length = params.data.questionList.length;
          }
          var allDetailRowHeight = length * 28;
          return allDetailRowHeight + offset;
        } else {
          return 28;
        }
      },
    };
  }

  // Select Questionnaires
  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var questionnaires = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectQuestionnaires(questionnaires);
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    // const COLUMNS_KEY = 'fund-list-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      questionnaireArray,
      className = 'grid-wrapper',
      loadQuestionnairesActionStatus,
    } = this.props;

    const isLoading = loadQuestionnairesActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={questionnaireArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            getRowHeight={this.state.getRowHeight}
            groupDefaultExpanded={this.state.groupDefaultExpanded}
            // groupDefaultExpanded={1}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default QuestionnaireListGrid;