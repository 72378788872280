import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // change input
    ON_ACCOUNT_QUERY_INPUT_CHANGE,
    ON_ACCOUNT_INPUT_CHANGE,
    ON_DEL_AUDIT_ACCOUNT_INPUT_CHANGE,
    ON_ADD_OR_EDIT_HOLDING_INPUT_CHANGE,
    ON_DEL_HOLDING_INPUT_CHANGE,
    // Load Personal Account
    START_LOAD_PERSONAL_ACCOUNT,
    LOAD_PERSONAL_ACCOUNT_SUCCESS,
    LOAD_PERSONAL_ACCOUNT_FAILURE,
    INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS,
    LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_SUCCESS,
    LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_FAILURE,
    // select data
    SELECT_PERSONAL_ACCOUNT_LIST,
    SET_GRID_EVENT_ON_STATE,
    SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,
    SELECT_PERSONAL_HOLDING_LIST,
    SET_DETAIL_GRID_EVENT_ON_STATE,
    // add or edit account
    OPEN_ADD_OR_EDIT_ACCOUNT_DIALOG,
    RESET_ADD_OR_EDIT_ACCOUNT_DIALOG,
    START_SUBMIT_ADD_OR_EDIT_ACCOUNT,
    SUBMIT_ADD_OR_EDIT_ACCOUNT_SUCCESS,
    SUBMIT_ADD_OR_EDIT_ACCOUNT_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_ACCOUNT_ACTION_STATUS,
    // del or audit account
    OPEN_DEL_OR_AUDIT_ACCOUNT_DIALOG,
    RESET_DEL_OR_AUDIT_ACCOUNT_DIALOG,
    START_DEL_PERSONAL_ACCOUNT,
    DEL_PERSONAL_ACCOUNT_SUCCESS,
    DEL_PERSONAL_ACCOUNT_FAILURE,
    INIT_DEL_PERSONAL_ACCOUNT_ACTION_STATUS,
    START_CHANGE_PERSONAL_ACCOUNT_STATUS,
    CHANGE_PERSONAL_ACCOUNT_STATUS_SUCCESS,
    CHANGE_PERSONAL_ACCOUNT_STATUS_FAILURE,
    INIT_CHANGE_PERSONAL_ACCOUNT_STATUS_ACTION_STATUS,
    // add or edit holding
    OPEN_ADD_OR_EDIT_HOLDING_DIALOG,
    RESET_ADD_OR_EDIT_HOLDING_DIALOG,
    START_SUBMIT_ADD_OR_EDIT_HOLDING,
    SUBMIT_ADD_OR_EDIT_HOLDING_SUCCESS,
    SUBMIT_ADD_OR_EDIT_HOLDING_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_HOLDING_ACTION_STATUS,
    // bbgTicker
    START_LOAD_HOLDING_BBG_TICKERS,
    LOAD_HOLDING_BBG_TICKERS_SUCCESS,
    LOAD_HOLDING_BBG_TICKERS_FAILURE,
    INIT_LOAD_HOLDING_BBG_TICKERS_ACTION_STATUS,
    CLEAR_HOLDING_BBG_TICKERS,
    HOLDING_BBG_TICKER_SELECT_CHANGED,
    // del holding
    OPEN_DEL_HOLDING_DIALOG,
    RESET_DEL_HOLDING_DIALOG,
    START_DEL_PERSONAL_HOLDING,
    DEL_PERSONAL_HOLDING_SUCCESS,
    DEL_PERSONAL_HOLDING_FAILURE,
    INIT_DEL_PERSONAL_HOLDING_ACTION_STATUS,

} from './AccountHoldingConstants';

import {
    LOAD_USER_ACCOUNTS_SUCCESS,
    LOAD_SECURITY_TICKERS_SUCCESS,
  } from '../../../features/home/homeConstants';


const Option = Select.Option;

const relationshipArray = ['Others', 'Spouse', 'Parents', 'Children', 'Company', 'Personal'];
const relationshipOptions = [];
for(let i in relationshipArray) {
    relationshipOptions.push(<Option key={i} >{relationshipArray[i]}</Option>);
}


const initialState = {
    relationshipArray: relationshipArray,
    relationshipOptions: relationshipOptions,
    personalAccountDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PERSONAL_ACCOUNT_DOWNLOAD_URL,
    personalAccountUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PERSONAL_ACCOUNT_UPLOAD_URL,

    bbgTickerArray: [],
    bbgTickerOptions: [],

    personalAccountQueryFields: {
        id: undefined,
        employee: undefined,
    },
    selectedPersonalAccountList: [],
    selectedPersonalHoldingList: [],
    addOrEditPersonalAccountModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            guid: undefined,
            employee: undefined,
            accountName: undefined,
            brokerAccount: undefined,
            accountNo: undefined,
            relationship: undefined,
            approvalStatus: undefined,
            feedback: undefined,
            processedReason: undefined,
            relationshipDesc: undefined,
            attachment: undefined,
            attachmentOriginName: undefined,
            source: undefined,
            isAbandoned: undefined,
            needComplianceLetter: undefined,
            complianceLetterFileName: undefined,
            complianceLetterOriName: undefined,
            comments: undefined,
            mode: undefined,
            withSubmit: undefined,
            attachementUploadList: [],
            complianceLetterUploadList: [],
        },
    },
    onlyShowValidData: true,
    delOrAuditAccountModal: {
        isOpened: false,
        mode: 'REJECT',
        respErrMsg: '',
        accountBean: undefined,
        fields: {
            comments: undefined,
        }
    },
    addOrEditPersonalHoldingModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            guid: undefined,
            employee: undefined,
            securityId: undefined,
            bbgTicker: undefined,
            secName: undefined,
            direction: undefined,
            quantity: undefined,
            price: undefined,
            amount: undefined,
            currency: undefined,
            personalAccountGuid: undefined,
            isAbandoned: undefined,
            comments: undefined,
            mode: undefined,
            personalAccountId: undefined,
            personalAccountName: undefined,
        },
    },
    delPersonalHoldingModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            personalHoldingIdList: [],
            comments: undefined,
        }
    },
    
};


// change input
function onAccountQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.personalAccountQueryFields,
        [name]: value,
    };
    return {
        ...state,
        personalAccountQueryFields: upatedFields,
    }
}
function onAccountInputChange(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditPersonalAccountModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditPersonalAccountModal: {
            ...state.addOrEditPersonalAccountModal,
            fields: updatedFields,
        },
    };
}
function onDelAuditAccountInputChange(state, {name, value}) {
    const updatedFields = {
        ...state.delOrAuditAccountModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delOrAuditAccountModal: {
            ...state.delOrAuditAccountModal,
            fields: updatedFields,
        },
    };
}
function onAddOrEditHoldingInputChange(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditPersonalHoldingModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditPersonalHoldingModal: {
            ...state.addOrEditPersonalHoldingModal,
            fields: updatedFields,
        },
    };
}
function onDelHoldingInputChange(state, {name, value}) {
    const updatedFields = {
        ...state.delPersonalHoldingModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delPersonalHoldingModal: {
            ...state.delPersonalHoldingModal,
            fields: updatedFields,
        },
    };
}



// Load User Account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    let employeeArray = [];
    if (userAccountArray.length > 0) {
      employeeArray = userAccountArray
      .filter(userAccount => userAccount.firm.toUpperCase() === 'PINPOINT')
        .map(userAccount => Object.assign({}, userAccount, { checked: false }));
    }
    const userAccountOptions = employeeArray.sort((a,b)=>a.englishName.toLowerCase() >= b.englishName.toLowerCase() ? 1 : -1)
                                          .map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
    return {
      ...state,
      userAccountOptions,
    };
  }


// Load Personal Account
function startLoadPersonalAccount(state) {
    return {
      ...state,
      loadPersonalAccountActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadPersonalAccountSuccess(state, resp) {
    return {
        ...state,
        loadPersonalAccountActionStatus: ACTION_STATUS.SUCCESS,
        personalAccountArray: resp.data,
    };
}
function loadPersonalAccountFailure(state, err) {
    return {
        ...state,
        loadPersonalAccountActionStatus: ACTION_STATUS.ERROR,
        loadPersonalAccountResp: err,
    };
}
function initLoadPersonalAccountActionStatus(state) {
    return {
        ...state,
        loadPersonalAccountActionStatus: ACTION_STATUS.READY,
        selectedPersonalAccountList: [],
        selectedPersonalHoldingList: [],

    };
}


function loadAccountOptionsForAddOrEditSuccess(state, resp) {
    const accountNameOptions = Array.from(new Set(resp.data.filter(e => !!e.accountName).map(e => e.accountName))).sort().map(e => <Option key={e} >{e}</Option>);
    const brokerAccountOptions = Array.from(new Set(resp.data.filter(e => !!e.brokerAccount).map(e => e.brokerAccount))).sort().map(e => <Option key={e} >{e}</Option>);
    const accountNoOptions = Array.from(new Set(resp.data.filter(e => !!e.accountNo).map(e => e.accountNo))).sort().map(e => <Option key={e} >{e}</Option>);
    return {
        ...state,
        accountNameOptions,
        brokerAccountOptions,
        accountNoOptions,
    };
}
function loadAccountOptionsForAddOrEditFailure(state, err) {
    return {
        ...state,
        accountNameOptions: [],
        brokerAccountOptions: [],
        accountNoOptions: [],
    };
}


// select data
function selectPersonalAccountList(state, list) {
    return {
        ...state,
        selectedPersonalAccountList: list,
    }
}
function setGridEventOnState(state, event) {
    return {
        ...state,
        dataGridEvent: event,
    }
}
function showAllShowValidBtnOnClicked(state) {
    return {
        ...state,
        onlyShowValidData: !state.onlyShowValidData,
        selectedPersonalAccountList: [],
        selectedPersonalHoldingList: [],
    }
}
function selectPersonalHoldingList(state, list) {
    return {
        ...state,
        selectedPersonalHoldingList: list,
    }
}
function setDeatilGridEventOnState(state, event) {
    return {
        ...state,
        detailDataGridEvent: event,
    }
}


// add or edit account
function openAddOrEditAccountDialog(state, {mode, account}) {
    if(mode === 'EDIT') {
        const attachementUploadList = [];
        if(!!account.attachment) {
            const attachmentFile = {
                uid: '-1',
                name: account.attachmentOriginName,
                status: 'done',
                url: state.personalAccountDownloadUrl + "/" + account.attachment,
                response: {
                    respCode: "S0001",
                    fileNameWithTimeStamp: account.attachment,
                    originalFileName: account.attachmentOriginName,
                }
            };
            attachementUploadList.push(attachmentFile);
        }
        const complianceLetterUploadList = [];
        if(!!account.complianceLetterFileName) {
            const letterFile = {
                uid: '-1',
                name: account.complianceLetterOriName,
                status: 'done',
                url: state.personalAccountDownloadUrl + "/" + account.complianceLetterFileName,
                response: {
                    respCode: "S0001",
                    fileNameWithTimeStamp: account.complianceLetterFileName,
                    originalFileName: account.complianceLetterOriName,
                }
            };
            complianceLetterUploadList.push(letterFile);
        }
        return {
            ...state,
            addOrEditPersonalAccountModal: {
                ...state.addOrEditPersonalAccountModal,
                isOpened: true,
                mode: mode,
                fields: {
                    ...state.addOrEditPersonalAccountModal.fields,
                    id: account.id,
                    guid: account.guid,
                    employee: account.employee,
                    accountName: account.accountName,
                    brokerAccount: account.brokerAccount,
                    accountNo: account.accountNo,
                    relationship: account.relationship,
                    approvalStatus: account.approvalStatus,
                    feedback: account.feedback,
                    processedReason: account.processedReason,
                    relationshipDesc: account.relationshipDesc,
                    attachment: account.attachment,
                    attachmentOriginName: account.attachmentOriginName,
                    source: account.source,
                    isAbandoned: account.isAbandoned,
                    needComplianceLetter: account.needComplianceLetter,
                    complianceLetterFileName: account.complianceLetterFileName,
                    complianceLetterOriName: account.complianceLetterOriName,
                    comments: account.comments,
                    mode: mode,
                    withSubmit: 'false',
                    attachementUploadList: attachementUploadList,
                    complianceLetterUploadList: complianceLetterUploadList,
                },
            },
        };

    } else {
        return {
            ...state,
            addOrEditPersonalAccountModal: {
                ...state.addOrEditPersonalAccountModal,
                isOpened: true,
                mode: mode,
            }
        };
    }
}
function resetAddOrEditAccountDialog(state) {
    return {
        ...state,
        addOrEditPersonalAccountModal: initialState.addOrEditPersonalAccountModal,
    }
}

function startSubmitAddOrEditAccount(state) {
    return {
      ...state,
      submitAddOrEditAccountActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitAddOrEditAccountSuccess(state, resp) {
    return {
        ...state,
        submitAddOrEditAccountActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitAddOrEditAccountFailure(state, err) {
    return {
        ...state,
        submitAddOrEditAccountActionStatus: ACTION_STATUS.ERROR,
        addOrEditPersonalAccountModal: {
            ...state.addOrEditPersonalAccountModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitAddOrEditAccountActionStatus(state) {
    return {
        ...state,
        submitAddOrEditAccountActionStatus: ACTION_STATUS.READY,
    };
}


// del or audit account
function openDelOrAuditAccountDialog(state, {mode, account}) {
    return {
        ...state,
        delOrAuditAccountModal: {
            ...state.delOrAuditAccountModal,
            isOpened: true,
            mode,
            accountBean: account,
        }
    }
}
function resetDelOrAuditAccountDialog(state) {
    return {
        ...state,
        delOrAuditAccountModal: initialState.delOrAuditAccountModal,
    }
}

function startDelPersonalAccount(state) {
    return {
      ...state,
      delPersonalAccountActionStatus: ACTION_STATUS.LOGINING,
    };
}
function delPersonalAccountSuccess(state, resp) {
    return {
        ...state,
        delPersonalAccountActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function delPersonalAccountFailure(state, err) {
    return {
        ...state,
        delPersonalAccountActionStatus: ACTION_STATUS.ERROR,
        delOrAuditAccountModal: {
            ...state.delOrAuditAccountModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelPersonalAccountActionStatus(state) {
    return {
        ...state,
        delPersonalAccountActionStatus: ACTION_STATUS.READY,
    };
}


function startChangePersonalAccountStatus(state) {
    return {
      ...state,
      changePersonalAccountStatusActionStatus: ACTION_STATUS.LOGINING,
    };
}
function changePersonalAccountStatusSuccess(state, resp) {
    return {
        ...state,
        changePersonalAccountStatusActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function changePersonalAccountStatusFailure(state, err) {
    return {
        ...state,
        changePersonalAccountStatusActionStatus: ACTION_STATUS.ERROR,
        delOrAuditAccountModal: {
            ...state.delOrAuditAccountModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initChangePersonalAccountStatusActionStatus(state) {
    return {
        ...state,
        changePersonalAccountStatusActionStatus: ACTION_STATUS.READY,
    };
}


// add or edit holding
function openAddOrEditHoldingDialog(state, {mode, holding}) {
    return {
        ...state,
        addOrEditPersonalHoldingModal: {
            ...state.addOrEditPersonalHoldingModal,
            isOpened: true,
            mode: mode,
            fields: {
                ...state.addOrEditPersonalHoldingModal.fields,
                id: holding.id,
                guid: holding.guid,
                employee: holding.employee,
                securityId: holding.securityId,
                bbgTicker: holding.bbgTicker,
                secName: holding.secName,
                direction: holding.direction,
                quantity: holding.quantity,
                price: holding.price,
                amount: holding.amount,
                currency: holding.currency,
                personalAccountGuid: holding.personalAccountGuid,
                isAbandoned: holding.isAbandoned,
                comments: holding.comments,
                mode,
                personalAccountId: holding.personalAccountId,
                personalAccountName: holding.personalAccountName,
                category: holding.category,
            },
        },
    };
}
function resetAddOrEditHoldingDialog(state) {
    return {
        ...state,
        addOrEditPersonalHoldingModal: initialState.addOrEditPersonalHoldingModal,
    }
}

function startSubmitAddOrEditHolding(state) {
    return {
      ...state,
      submitAddOrEditHoldingActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitAddOrEditHoldingSuccess(state, resp) {
    return {
        ...state,
        submitAddOrEditHoldingActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitAddOrEditHoldingFailure(state, err) {
    return {
        ...state,
        submitAddOrEditHoldingActionStatus: ACTION_STATUS.ERROR,
        addOrEditPersonalHoldingModal: {
            ...state.addOrEditPersonalHoldingModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitAddOrEditHoldingActionStatus(state) {
    return {
        ...state,
        submitAddOrEditHoldingActionStatus: ACTION_STATUS.READY,
    };
}


// bbgTicker
function loadSecurityTickersSuccess(state, resp) {
    let securityTickerData = resp.data;   
    let securityTickerArray = securityTickerData.securityTickerList;
    return {
      ...state,
      securityTickerData,
      securityTickerArray,
    };
}
function startLoadHoldingBBGTickers(state) {
    return {
        ...state,
        loadBbgTickersActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadHoldingBbgTickersSuccess(state, resp) {
    const bbgTickerArray = resp.data;
    return {
        ...state,
        bbgTickerArray,
        loadBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function loadHoldingBbgTickersFailure(state, err) {
    return {
        ...state,
        loadBbgTickersActionStatus: ACTION_STATUS.ERROR,
    };
}
function initLoadHoldingBbgTickersActionStatus(state) {
    return {
        ...state,
        loadBbgTickersActionStatus: ACTION_STATUS.READY,
    };
}
function clearHoldingBbgTickers(state) {
    return {
        ...state,
        addOrEditPersonalHoldingModal: {
            ...state.addOrEditPersonalHoldingModal,
            fields: {
                ...state.addOrEditPersonalHoldingModal.fields,
                securityId: undefined,
                secName: undefined,
                currency: undefined,
            },
        },
        bbgTickerArray: [],
    };
}
function holdingBbgTickerSelectChanged(state, bbgTicker) {
    const securityTickerArray = state.securityTickerArray;
    let securityId = '';
    let securityName = '';
    let currency = '';
    if (!!bbgTicker) {
        const filterSecurityTickerArray = securityTickerArray.filter(securityTicker => securityTicker.bbgTicker === bbgTicker);
        if (filterSecurityTickerArray.length > 0) {
            const securityTicker = filterSecurityTickerArray[0];
            securityId = securityTicker.securityId;
            securityName = securityTicker.securityName;
            currency = securityTicker.currency;
        }
    }
    return {
        ...state,
        addOrEditPersonalHoldingModal: {
            ...state.addOrEditPersonalHoldingModal,
            fields: {
            ...state.addOrEditPersonalHoldingModal.fields,
            securityId,
            secName: securityName,
            currency,
            },
        },
    };
}


// del holding
function openDelHoldingDialog(state, idList) {
    return {
        ...state,
        delPersonalHoldingModal: {
            ...state.delPersonalHoldingModal,
            isOpened: true,
            fields: {
                ...state.delPersonalHoldingModal.fields,
                personalHoldingIdList: idList,
            }
        }
    }
}
function resetDelHoldingDialog(state) {
    return {
        ...state,
        delPersonalHoldingModal: initialState.delPersonalHoldingModal,
    }
}

function startDelPersonalHolding(state) {
    return {
      ...state,
      delPersonalHoldingActionStatus: ACTION_STATUS.LOGINING,
    };
}
function delPersonalHoldingSuccess(state, resp) {
    return {
        ...state,
        delPersonalHoldingActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function delPersonalHoldingFailure(state, err) {
    return {
        ...state,
        delPersonalHoldingActionStatus: ACTION_STATUS.ERROR,
        delPersonalHoldingModal: {
            ...state.delPersonalHoldingModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelPersonalHoldingActionStatus(state) {
    return {
        ...state,
        delPersonalHoldingActionStatus: ACTION_STATUS.READY,
    };
}




export default createReducer(initialState, {
    // Load User Account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
    // change input
    [ON_ACCOUNT_QUERY_INPUT_CHANGE]: onAccountQueryInputChange,
    [ON_ACCOUNT_INPUT_CHANGE]: onAccountInputChange,
    [ON_DEL_AUDIT_ACCOUNT_INPUT_CHANGE]: onDelAuditAccountInputChange,
    [ON_ADD_OR_EDIT_HOLDING_INPUT_CHANGE]: onAddOrEditHoldingInputChange,
    [ON_DEL_HOLDING_INPUT_CHANGE]: onDelHoldingInputChange,
    // Load Personal Account
    [START_LOAD_PERSONAL_ACCOUNT]: startLoadPersonalAccount,
    [LOAD_PERSONAL_ACCOUNT_SUCCESS]: loadPersonalAccountSuccess,
    [LOAD_PERSONAL_ACCOUNT_FAILURE]: loadPersonalAccountFailure,
    [INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS]: initLoadPersonalAccountActionStatus,
    [LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_SUCCESS]: loadAccountOptionsForAddOrEditSuccess,
    [LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_FAILURE]: loadAccountOptionsForAddOrEditFailure,
    // select data
    [SELECT_PERSONAL_ACCOUNT_LIST]: selectPersonalAccountList,
    [SET_GRID_EVENT_ON_STATE]: setGridEventOnState,
    [SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED]: showAllShowValidBtnOnClicked,
    [SELECT_PERSONAL_HOLDING_LIST]: selectPersonalHoldingList,
    [SET_DETAIL_GRID_EVENT_ON_STATE]: setDeatilGridEventOnState,
    // add or edit account
    [OPEN_ADD_OR_EDIT_ACCOUNT_DIALOG]: openAddOrEditAccountDialog,
    [RESET_ADD_OR_EDIT_ACCOUNT_DIALOG]: resetAddOrEditAccountDialog,
    [START_SUBMIT_ADD_OR_EDIT_ACCOUNT]: startSubmitAddOrEditAccount,
    [SUBMIT_ADD_OR_EDIT_ACCOUNT_SUCCESS]: submitAddOrEditAccountSuccess,
    [SUBMIT_ADD_OR_EDIT_ACCOUNT_FAILURE]: submitAddOrEditAccountFailure,
    [INIT_SUBMIT_ADD_OR_EDIT_ACCOUNT_ACTION_STATUS]: initSubmitAddOrEditAccountActionStatus,
    // del or audit account
    [OPEN_DEL_OR_AUDIT_ACCOUNT_DIALOG]: openDelOrAuditAccountDialog,
    [RESET_DEL_OR_AUDIT_ACCOUNT_DIALOG]: resetDelOrAuditAccountDialog,
    [START_DEL_PERSONAL_ACCOUNT]: startDelPersonalAccount,
    [DEL_PERSONAL_ACCOUNT_SUCCESS]: delPersonalAccountSuccess,
    [DEL_PERSONAL_ACCOUNT_FAILURE]: delPersonalAccountFailure,
    [INIT_DEL_PERSONAL_ACCOUNT_ACTION_STATUS]: initDelPersonalAccountActionStatus,
    [START_CHANGE_PERSONAL_ACCOUNT_STATUS]: startChangePersonalAccountStatus,
    [CHANGE_PERSONAL_ACCOUNT_STATUS_SUCCESS]: changePersonalAccountStatusSuccess,
    [CHANGE_PERSONAL_ACCOUNT_STATUS_FAILURE]: changePersonalAccountStatusFailure,
    [INIT_CHANGE_PERSONAL_ACCOUNT_STATUS_ACTION_STATUS]: initChangePersonalAccountStatusActionStatus,
    // add or edit holding
    [OPEN_ADD_OR_EDIT_HOLDING_DIALOG]: openAddOrEditHoldingDialog,
    [RESET_ADD_OR_EDIT_HOLDING_DIALOG]: resetAddOrEditHoldingDialog,
    [START_SUBMIT_ADD_OR_EDIT_HOLDING]: startSubmitAddOrEditHolding,
    [SUBMIT_ADD_OR_EDIT_HOLDING_SUCCESS]: submitAddOrEditHoldingSuccess,
    [SUBMIT_ADD_OR_EDIT_HOLDING_FAILURE]: submitAddOrEditHoldingFailure,
    [INIT_SUBMIT_ADD_OR_EDIT_HOLDING_ACTION_STATUS]: initSubmitAddOrEditHoldingActionStatus,
    // BBG Tickers
    [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,
    [START_LOAD_HOLDING_BBG_TICKERS]: startLoadHoldingBBGTickers,
    [LOAD_HOLDING_BBG_TICKERS_SUCCESS]: loadHoldingBbgTickersSuccess,
    [LOAD_HOLDING_BBG_TICKERS_FAILURE]: loadHoldingBbgTickersFailure,
    [INIT_LOAD_HOLDING_BBG_TICKERS_ACTION_STATUS]: initLoadHoldingBbgTickersActionStatus,
    [CLEAR_HOLDING_BBG_TICKERS]: clearHoldingBbgTickers,
    [HOLDING_BBG_TICKER_SELECT_CHANGED]: holdingBbgTickerSelectChanged,
    // del holding
    [OPEN_DEL_HOLDING_DIALOG]: openDelHoldingDialog,
    [RESET_DEL_HOLDING_DIALOG]: resetDelHoldingDialog,
    [START_DEL_PERSONAL_HOLDING]: startDelPersonalHolding,
    [DEL_PERSONAL_HOLDING_SUCCESS]: delPersonalHoldingSuccess,
    [DEL_PERSONAL_HOLDING_FAILURE]: delPersonalHoldingFailure,
    [INIT_DEL_PERSONAL_HOLDING_ACTION_STATUS]: initDelPersonalHoldingActionStatus,

});