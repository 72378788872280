import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const FormItem = Form.Item;


class AddOrEditFieldDialog extends Component {
    
    componentDidMount() {

    }

    componentDidUpdate() {
        const {
            addOrEditFieldActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditFieldModal;
        if(addOrEditFieldActionStatus === ACTION_STATUS.SUCCESS) {
            const modalTitle = this.getModalTitle(mode);
            message.success(modalTitle + ' succeeded');
            this.props.initSubmitAddOrEditFieldActionStatus();
            this.props.resetAddOrEditFieldModal();
            this.props.queryElementType(this.props.elementTypeQueryFields);
        }
        if(addOrEditFieldActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitAddOrEditFieldActionStatus();
        }
    }


    getModalTitle = (mode) => {
        const action = mode === 'ADD' ? 'Add' : 'Edit';
        return action + ' Field';
    }


    onFieldAddOrEditInputChange = (param) => {
        this.props.onFieldAddOrEditInputChange(param);
    }


    submitAddOrEditField = () => {
        const {
            mode,
            fields,
        } = this.props.addOrEditFieldModal;
        const {
            id,
            elementTypeId,
            isConstraint,
            fieldName,
        } = fields;
        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            this.props.submitAddOrEditField({
                id,
                elementTypeId,
                isConstraint,
                fieldName,
                mode,
            });
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            addOrEditFieldModal,
            addOrEditFieldActionStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
        } = addOrEditFieldModal;
        const {
            id,
            elementTypeId,
            isConstraint,
            fieldName,
        } = fields;

        const modalTitle = this.getModalTitle(mode);
        const isSubmitLoading = addOrEditFieldActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={600}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditFieldModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetAddOrEditFieldModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditField() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="fieldName">
                                    {getFieldDecorator('fieldName', {
                                        initialValue: fieldName,
                                        rules: [{
                                            required: true,
                                            message: 'Please input fieldName',
                                        }],
                                        })(
                                            <Input placeholder='fieldName' 
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        this.onFieldAddOrEditInputChange({ name: 'fieldName', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }

}


const AddOrEditFieldDialogForm = Form.create({ name: 'AddOrEditFieldDialog' })(AddOrEditFieldDialog);
export default AddOrEditFieldDialogForm;
