import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Button, DatePicker } from 'antd';
import moment from 'moment';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import PBShortInterestRateGrid from './PBShortInterestRateGrid';
import '../../ComplianceRule.css';

class PBShortInterestRate extends Component {

  state = {
    searchDate: moment(),
  }

  componentDidMount() {

  }

  searchButtonOnClicked = () => {
    this.props.loadPBShortInterestRate(this.state.searchDate.format('YYYY-MM-DD'));
  }

  render() {
    const {
      loadPBShortInterestRateActionStatus
    } = this.props;

    const {
      searchDate
    } = this.state;

    const isLoading = loadPBShortInterestRateActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="pbShortInterestRateWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Compliance Rule</Breadcrumb.Item>
            <Breadcrumb.Item>PB Short Interest Rate</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='operationBar'>
          <DatePicker 
            style={{ marginLeft: 10 }}
            allowClear={false}
            value={searchDate}
            onChange={(date, dateString) => {
              this.setState({
                searchDate: date
              })
            }} 
            />

          <Button 
            onClick={this.searchButtonOnClicked} 
            size='small'
            loading={isLoading}
            className='positive-button'>
            Search
          </Button>
        </div>

        <div className='pbShortInterestRateGrid'>
          <PBShortInterestRateGrid {...this.props} />
        </div>
      </div>
    );
  }
}

export default PBShortInterestRate;