import {
  // Load Restricted Sectors
  START_LOAD_RESTRICTED_SECTORS,
  LOAD_RESTRICTED_SECTORS_SUCCESS,
  LOAD_RESTRICTED_SECTORS_FAILURE,
  INIT_LOAD_RESTRICTED_SECTORS_ACTION_STATUS,

  // Create Restricted SECTOR
  START_CREATE_RESTRICTED_SECTOR,
  CREATE_RESTRICTED_SECTOR_SUCCESS,
  CREATE_RESTRICTED_SECTOR_FAILURE,
  INIT_CREATE_RESTRICTED_SECTOR_ACTION_STATUS,

  // Update Restricted SECTOR
  START_UPDATE_RESTRICTED_SECTOR,
  UPDATE_RESTRICTED_SECTOR_SUCCESS,
  UPDATE_RESTRICTED_SECTOR_FAILURE,
  INIT_UPDATE_RESTRICTED_SECTOR_ACTION_STATUS,

  // Batch Delete Restricted SECTOR
  START_BATCH_DELETE_RESTRICTED_SECTOR,
  BATCH_DELETE_RESTRICTED_SECTOR_SUCCESS,
  BATCH_DELETE_RESTRICTED_SECTOR_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_SECTOR_ACTION_STATUS,

  // Open/Reset Restricted SECTOR Modal Dialog
  RESTRICTED_SECTOR_MODAL_DIALOG_OPEN,
  RESTRICTED_SECTOR_MODAL_DIALOG_RESET,

  // Restricted SECTOR Edit Change Input
  RESTRICTED_SECTOR_EDIT_CHANGE_INPUT,

  // RadioGroup Change
  RADIO_GROUP_CHANGE,

  // Sector Name Select Changed
  SECTOR_NAME_SELECT_CHANGED,

  // Select Restricted Sectors
  SELECT_RESTRICTED_SECTORS,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,
} from './restrictedSectorConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// ========================================================================
// Load Restricted Sectors
function startLoadRestrictedSectors() {
  return {
    type: START_LOAD_RESTRICTED_SECTORS
  };
}

export function loadRestrictedSectors() {
  return function(dispatch) {
    dispatch(startLoadRestrictedSectors());
    httpClient
      .get(UrlConfig.QUERY_RESTRICTED_SECTORS)
      .then(resp => dispatch(loadRestrictedSectorsSuccess(resp)))
      .catch(err => dispatch(loadRestrictedSectorsFailure(err)));
  };
}

function loadRestrictedSectorsSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECTORS_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSectorsFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECTORS_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSectorsActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECTORS_ACTION_STATUS
  };
}


// Create Restricted Sector
function startCreateRestrictedSector() {
  return {
    type: START_CREATE_RESTRICTED_SECTOR
  };
}

export function createRestrictedSector(restrictedSector) {
  return function(dispatch) {
    dispatch(startCreateRestrictedSector());
    httpClient
      .post(UrlConfig.NEW_RESTRICTED_SECTOR, restrictedSector)
      .then(resp => dispatch(createRestrictedSectorSuccess(resp)))
      .catch(err => dispatch(createRestrictedSectorFailure(err)));
  };
}

function createRestrictedSectorSuccess(resp) {
  return {
    type: CREATE_RESTRICTED_SECTOR_SUCCESS,
    payload: resp
  };
}

function createRestrictedSectorFailure(err) {
  return {
    type: CREATE_RESTRICTED_SECTOR_FAILURE,
    payload: err
  };
}

export function initCreateRestrictedSectorActionStatus() {
  return {
    type: INIT_CREATE_RESTRICTED_SECTOR_ACTION_STATUS
  };
}

// Update Restricted Sector
function startUpdateRestrictedSector() {
  return {
    type: START_UPDATE_RESTRICTED_SECTOR
  };
}

export function updateRestrictedSector(restrictedSector) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedSector());
    httpClient
      .post(UrlConfig.UPDATED_RESTRICTED_SECTOR, restrictedSector)
      .then(resp => dispatch(updateRestrictedSectorSuccess(resp)))
      .catch(err => dispatch(updateRestrictedSectorFailure(err)));
  };
}

function updateRestrictedSectorSuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_SECTOR_SUCCESS,
    payload: resp
  };
}

function updateRestrictedSectorFailure(err) {
  return {
    type: UPDATE_RESTRICTED_SECTOR_FAILURE,
    payload: err
  };
}

export function initUpdateRestrictedSectorActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_SECTOR_ACTION_STATUS
  };
}

// Batch Delete Restricted Sector
function startBatchDeleteRestrictedSector() {
  return {
    type: START_BATCH_DELETE_RESTRICTED_SECTOR
  };
}

export function batchDeleteRestrictedSector(restrictedSectorIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteRestrictedSector());
    httpClient
      .post(UrlConfig.BATCH_DELETED_RESTRICTED_SECTOR, restrictedSectorIdList)
      .then(resp => dispatch(batchDeleteRestrictedSectorSuccess(resp)))
      .catch(err => dispatch(batchDeleteRestrictedSectorFailure(err)));
  };
}

function batchDeleteRestrictedSectorSuccess(resp) {
  return {
    type: BATCH_DELETE_RESTRICTED_SECTOR_SUCCESS,
    payload: resp
  };
}

function batchDeleteRestrictedSectorFailure(err) {
  return {
    type: BATCH_DELETE_RESTRICTED_SECTOR_FAILURE,
    payload: err
  };
}

export function initBatchDeleteRestrictedSectorActionStatus() {
  return {
    type: INIT_BATCH_DELETE_RESTRICTED_SECTOR_ACTION_STATUS
  };
}


// ========================================================================
// RadioGroup Change
export function radioGroupChange(value) {
  return {
    type: RADIO_GROUP_CHANGE,
    payload: value,
  };
}


// Open/Reset Restricted Sector Modal Dialog
export function openRestrictedSectorEditModalDialog(isAddMode, restrictedSector) {
  return {
    type: RESTRICTED_SECTOR_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      restrictedSector,
    },
  };
}

export function resetRestrictedSectorEditModalDialog() {
  return {
    type: RESTRICTED_SECTOR_MODAL_DIALOG_RESET
  };
}

// Restricted Sector Edit Change Input
export function restrictedSectorEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_SECTOR_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// ========================================================================
// Sector Name Select Changed
export function sectorNameSelectChanged(sectorName) {
  return {
    type: SECTOR_NAME_SELECT_CHANGED,
    payload: sectorName
  };
}

// Select Restricted Sectors
export function selectRestrictedSectors(payload) {
  return {
    type: SELECT_RESTRICTED_SECTORS,
    payload
  };
}

// Fund Code Select Changed
export function fundCodeSelectChanged(fundCode) {
  return {
    type: FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Book Code Select Changed
export function bookCodeSelectChanged(bookCode) {
  return {
    type: BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}