import { connect } from 'react-redux';
import PendingTasksDashboard from '../components/PendingTasksDashboard';

import {
    // queryPendingTasksNum
    queryPendingTasksNum,
    // queryPendingTasksList
    queryPendingTasksInputChange,
    queryPendingTasksList,
    initQueryPendingTasksListStatus,
    // ignoreDeignorePendingTasks
    ignoreDeignorePendingTasks,
    initIignoreDeignorePendingTasksStatus,


} from '../PendingTasksActions';


function mapStateToProps(state) {
    return {
        // queryPendingTasksList
        queryPendingTasksInputParams: state.pendingTasks.queryPendingTasksInputParams,
        queryPendingTasksListStatus: state.pendingTasks.queryPendingTasksListStatus,
        queryPendingTasksListResp: state.pendingTasks.queryPendingTasksListResp,
        // ignoreDeignorePendingTasks
        ignoreDeignorePendingTasksStatus: state.pendingTasks.ignoreDeignorePendingTasksStatus,
        ignoreDeignorePendingTasksResp: state.pendingTasks.ignoreDeignorePendingTasksResp,

    };
}


function mapDispatchToProps(dispatch) {
    return {
        // queryPendingTasksNum
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // queryPendingTasksList
        queryPendingTasksInputChange: (params) => dispatch(queryPendingTasksInputChange(params)),
        queryPendingTasksList: (params) => dispatch(queryPendingTasksList(params)),
        initQueryPendingTasksListStatus: () => dispatch(initQueryPendingTasksListStatus()),
        // ignoreDeignorePendingTasks
        ignoreDeignorePendingTasks: (params) => dispatch(ignoreDeignorePendingTasks(params)),
        initIignoreDeignorePendingTasksStatus: () => dispatch(initIignoreDeignorePendingTasksStatus()),

    };

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingTasksDashboard);
