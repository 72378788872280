
const RESTRICTION_TYPE_CODE = {
  HARD_RESTRICTION: 'Hard Restriction',
  SOFT_RESTRICTION: 'Soft Restriction',
  US_RESTRICTION: 'Executive Order (US)',
  PLACING_RESTRICTION:'Cornerstone (Placing)',
  OTHERS_RESTRICTION: 'Others',
};

const RESTRICTION_TYPE_LABEL = {
  HARD_RESTRICTION: 'Hard Restriction',
  SOFT_RESTRICTION: 'Soft Restriction',
  US_RESTRICTION: 'Executive Order (US)',
  PLACING_RESTRICTION:'Cornerstone (Placing)',
  OTHERS_RESTRICTION: 'Others',
};

export const RESTRICTION_TYPE_MAP = [
  // Hard Restriction
  {
    code: RESTRICTION_TYPE_CODE.HARD_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.HARD_RESTRICTION
  },
  // Soft Restriction
  {
    code: RESTRICTION_TYPE_CODE.SOFT_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.SOFT_RESTRICTION
  },
  {
    code: RESTRICTION_TYPE_CODE.US_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.US_RESTRICTION
  },
  {
    code: RESTRICTION_TYPE_CODE.PLACING_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.PLACING_RESTRICTION
  },{
    code: RESTRICTION_TYPE_CODE.OTHERS_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.OTHERS_RESTRICTION
  },
];