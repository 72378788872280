import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from './GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../utils/QuestionnaireEmailSendMode';
import moment from 'moment';
import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_MEETING_AND_TRAINING_INFO,
    LOAD_MEETING_AND_TRAINING_INFO_APPLYRECORD_SUCCESS,
    LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
    INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    // select
    SELECT_MEETING_AND_TRAINING_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_ADD_OR_EDIT_MEETING_AND_TRAINING,
    ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
    ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
    INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_MEETING_AND_TRAINING_MODAL,
    DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_DEL_MEETING_AND_TRAINING,
    DEL_MEETING_AND_TRAINING_SUCCESS,
    DEL_MEETING_AND_TRAINING_FAILURE,
    INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_DEL_MEETING_AND_TRAINING_MODAL,

    // del
    OPEN_COP_MEETING_AND_TRAINING_MODAL,
    COP_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_COP_MEETING_AND_TRAINING,
    COP_MEETING_AND_TRAINING_SUCCESS,
    COP_MEETING_AND_TRAINING_FAILURE,
    INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_COP_MEETING_AND_TRAINING_MODAL,


    ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    ANSWEREE_SELECT_MODAL_DIALOG_RESET,

    
    // Select All Answeree
    SELECT_ALL_ANSWEREE,

    // Select Invert Answeree
    SELECT_INVERT_ANSWEREE,

    // Answeree Check Changed
    ANSWEREE_CHECK_CHANGED,
    
      // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,




    // Select Answerees
    START_SELECT_ANSWEREES,
    SELECT_ANSWEREES_SUCCESS,
    SELECT_ANSWEREES_FAILURE,
    INIT_SELECT_ANSWEREES_ACTION_STATUS,
    SHOW_DETAIL_RCD,
    RESET_DETAIL_RCD,

} from './MeetingAndTrainingApplyRecordConstants';

import {
    // Load Security Tickers
    LOAD_SECURITY_TICKERS_SUCCESS,
  
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  
  } from '../../home/homeConstants';
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={'New'}>{'New'}</Option>);
statusOptions.push(<Option key={'Approve'}>{'Approve'}</Option>);
statusOptions.push(<Option key={'Reject'}>{'Reject'}</Option>);
// Advanced Training; Paper Portfolio
const applyTypeOptions = [];
// applyTypeOptions.push(<Option key={'Advanced Training'}>{'Advanced Training'}</Option>);
applyTypeOptions.push(<Option key={'Paper Portfolio'}>{'Paper Portfolio'}</Option>);
applyTypeOptions.push(<Option key={'Stock Pitch'}>{'Stock Pitch'}</Option>);
// repeatOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);Stock Pitch
// repeatOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);

const repeatMonthlyOptions = [];
repeatMonthlyOptions.push(<Option key={'Repeat by date'}>{'Repeat by date'}</Option>);
repeatMonthlyOptions.push(<Option key={'Repeat weekly'}>{'Repeat weekly'}</Option>);

const repeatEveryOptions = [];
repeatEveryOptions.push(<Option key={'1'}>{'1'}</Option>);
repeatEveryOptions.push(<Option key={'2'}>{'2'}</Option>);
repeatEveryOptions.push(<Option key={'3'}>{'3'}</Option>);
repeatEveryOptions.push(<Option key={'4'}>{'4'}</Option>);
repeatEveryOptions.push(<Option key={'5'}>{'5'}</Option>);
repeatEveryOptions.push(<Option key={'6'}>{'6'}</Option>);
repeatEveryOptions.push(<Option key={'7'}>{'7'}</Option>);
repeatEveryOptions.push(<Option key={'8'}>{'8'}</Option>);
repeatEveryOptions.push(<Option key={'9'}>{'9'}</Option>);
repeatEveryOptions.push(<Option key={'10'}>{'10'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'7'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'1'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'2'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'3'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'4'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'5'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'6'}>{'Sat'}</Option>);

const typeOptions = [];
typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
typeOptions.push(<Option key={'Tranining'}>{'Tranining'}</Option>);
typeOptions.push(<Option key={'Other'}>{'Other'}</Option>);
const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {

    meetingAndTrainingApplyRecordUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_UPLOAD_URL,
    meetingAndTrainingApplyRecordDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL,
    meetingAndTrainingApplyRecordGridColumns: GridColumnMap.meetingAndTrainingApplyRecordGridColumns,
    
    statusOptions: statusOptions,
    typeOptions: typeOptions,
    weekdayOptions: weekdayOptions,
    repeatEveryOptions: repeatEveryOptions,
    // repeatOptions: repeatOptions,
    applyTypeOptions:applyTypeOptions,
    repeatMonthlyOptions: repeatMonthlyOptions,
    fundFirmRelatedOptions: fundFirmRelatedOptions,
    ccyOptions: ccyOptions,

    meetingAndTrainingApplyRecordQueryFields: {
        userName: '',
        status: ''
    },
    selectedMeetingAndTrainingApplyRecordInfoArray: [],
    addOrEditMeetingAndTrainingApplyRecordModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            category: '',
            firm: '',
            feedback: true,
            sendmail: false,
            fundFirmRelated: '',
            invDate: '',
            starttime: '',
            endtime: '',
            invNo: '',
            ccy: '',
            amount: '',
            fx: '',
            amtUSD: '',
            payDate: '',
            nature: '',
            remarks: '',
            payMethod: '',
            comments: '',
        },
    },
    delMeetingAndTrainingApplyRecordModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
        }
    },
    copMeetingAndTrainingApplyRecordModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
            employee: null,
            comments: '',

        }
    },
    // Load User Account
      userAccountArray: [],
   //   userAccountOptions: [],
   answereeSelectControlModal: {
    isOpened: false,
    respErrMsg: '',
  },


  userAccountArray: [],
  pmArray: [],
  pmApplyRecordArray: [],
  userAccountOptions: [],
  // Open/Reset Questionnaire Email Edit Modal Dialog
  questionnaireEmailEditControlModal: {
    isOpened: false,
    sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
    emailPayload: null,
    respErrMsg: '',
  },
  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

};


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.meetingAndTrainingApplyRecordQueryFields,
        [name]: value,
    };
    return {
        ...state,
        meetingAndTrainingApplyRecordQueryFields: upatedFields,
    }
}
function startLoadMeetingAndTrainingApplyRecordInfo(state) {
    return {
      ...state,
      loadMeetingAndTrainingApplyRecordInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadMeetingAndTrainingApplyRecordInfoSuccess(state, resp) {
    return {
        ...state,
        loadMeetingAndTrainingApplyRecordInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadMeetingAndTrainingApplyRecordInfoResp: resp,
    };
}
function loadMeetingAndTrainingApplyRecordInfoFailure(state, err) {
    return {
        ...state,
        loadMeetingAndTrainingApplyRecordInfoActionStatus: ACTION_STATUS.ERROR,
        loadMeetingAndTrainingApplyRecordInfoResp: err,
    };
}
function initLoadMeetingAndTrainingApplyRecordInfoActionStatus(state) {
    return {
        ...state,
        loadMeetingAndTrainingApplyRecordInfoActionStatus: ACTION_STATUS.READY,
        selectedMeetingAndTrainingApplyRecordInfoArray: [],
    };
}


// select
function selectMeetingAndTrainingApplyRecordInfo(state, array) {

    const pmApplyRecordArray = state.pmApplyRecordArray;
    const answerArray = state.answerArray;
  
    // if (pmApplyRecordArray.length > 0 && answerArray.length > 0 && array.length > 0) {
    //   pmApplyRecordArray.forEach(pmArray => {
    //     pmArray.forEach(userAccount => {
    //       const filterAnswereeArray = answerArray.filter(answer => answer.questionnaireGuid === array[0].id).map(answer => answer.answeree);
    //       if (filterAnswereeArray.includes(userAccount.userAccountName)) {
    //         userAccount.checked = true;
    //       }
    //     })
    //   });
    // }



    
    return {
        ...state,
        selectedMeetingAndTrainingApplyRecordInfoArray: array,
    }
}


// add or edit
function openAddOrEditMeetingAndTrainingApplyRecordModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditMeetingAndTrainingApplyRecordModal: {
                ...state.addOrEditMeetingAndTrainingApplyRecordModal,
                isOpened: true,
                starttime: moment().format("YYYY-MM-DD HH:mm"),
                endtime: moment().format("YYYY-MM-DD HH:mm"),
                mode,
            },
        };
    } else {
        return {
            ...state,
            addOrEditMeetingAndTrainingApplyRecordModal: {
                ...state.addOrEditMeetingAndTrainingApplyRecordModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditMeetingAndTrainingApplyRecordModal.fields,
                    id: data.id,
                    title: data.title,
                    desc: data.desc,
                    type: data.type,
                    feedback: data.feedback,
                    groupname: data.groupname,
                    accountname: data.accountname,
                    sendmail: data.sendmail,
                    comperename: data.comperename,
                    starttime: data.starttime,
                    endtime: data.endtime,
                    score: data.score,
                    address: data.address,
                    requiredattendees: data.requiredattendees,
                    optionalattendees: data.optionalattendees,
                    repeat: data.repeat,
                    repeatevery: data.repeatevery,
                    week: data.week,
                    
                }
            },
        };
    }
}
function addOrEditMeetingAndTrainingApplyRecordChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditMeetingAndTrainingApplyRecordModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditMeetingAndTrainingApplyRecordModal: {
          ...state.addOrEditMeetingAndTrainingApplyRecordModal,
          fields: updatedFields,
        },
    };
}
function startAddOrEditMeetingAndTrainingApplyRecord(state) {
    return {
      ...state,
      addOrEditMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditMeetingAndTrainingApplyRecordSuccess(state, resp) {
    return {
        ...state,
        addOrEditMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditMeetingAndTrainingApplyRecordFailure(state, err) {
    return {
        ...state,
        addOrEditMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.ERROR,
        addOrEditMeetingAndTrainingApplyRecordModal: {
            ...state.addOrEditMeetingAndTrainingApplyRecordModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditMeetingAndTrainingApplyRecordActionStatus(state) {
    return {
        ...state,
        addOrEditMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.READY,
    };
}
function resetAddOrEditMeetingAndTrainingApplyRecordModal(state) {
    return {
        ...state,
        addOrEditMeetingAndTrainingApplyRecordModal: initialState.addOrEditMeetingAndTrainingApplyRecordModal,
    }
}


// refresh fx and amtUSD
function startRefreshFx(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.LOGINING,
    };
}
function refreshFxSuccess(state, resp) {
    const fx = (!!resp && !!resp.data && !!resp.data.last) ? resp.data.last : '';
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.SUCCESS,
        addOrEditMeetingAndTrainingApplyRecordModal: {
            ...state.addOrEditMeetingAndTrainingApplyRecordModal,
            fields: {
                ...state.addOrEditMeetingAndTrainingApplyRecordModal.fields,
                fx: fx,
            },
        }
    }
}
function refreshFxFailure(state, err) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.ERROR,
        refreshFxResp: err,
        addOrEditMeetingAndTrainingApplyRecordModal: {
            ...state.addOrEditMeetingAndTrainingApplyRecordModal,
            fields: {
                ...state.addOrEditMeetingAndTrainingApplyRecordModal.fields,
                fx: '',
            },
        }
    }
}
function initRefreshFxActionStatus(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.READY,
    };
}
function refreshAmtUSD(state, amtUSD) {
    return {
        ...state,
        addOrEditMeetingAndTrainingApplyRecordModal: {
            ...state.addOrEditMeetingAndTrainingApplyRecordModal,
            fields: {
                ...state.addOrEditMeetingAndTrainingApplyRecordModal.fields,
                amtUSD: amtUSD,
            },
        }
    };
}


// del
function openDelMeetingAndTrainingApplyRecordModal(state, {info,mode}) {
    return {
        ...state,
        delMeetingAndTrainingApplyRecordModal: {
            ...state.delMeetingAndTrainingApplyRecordModal,
            isOpened: true,
            mode:mode,
            fields: {
                ...state.delMeetingAndTrainingApplyRecordModal.fields,
                id: info.id,
                paperSize: info.paperSize,
                currency: info.currency,
                applyType: info.applytype,
                
            }
        }
    }
}
function delMeetingAndTrainingApplyRecordChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.delMeetingAndTrainingApplyRecordModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delMeetingAndTrainingApplyRecordModal: {
          ...state.delMeetingAndTrainingApplyRecordModal,
          fields: updatedFields,
        },
    };
}
function startDelMeetingAndTrainingApplyRecord(state) {
    return {
      ...state,
      delMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.LOGINING,
    };
}
function delMeetingAndTrainingApplyRecordSuccess(state, resp) {
    return {
        ...state,
        delMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.SUCCESS,
    };
}
function delMeetingAndTrainingApplyRecordFailure(state, err) {
    return {
        ...state,
        delMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.ERROR,
        delMeetingAndTrainingApplyRecordModal: {
            ...state.delMeetingAndTrainingApplyRecordModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelMeetingAndTrainingApplyRecordActionStatus(state) {
    return {
        ...state,
        delMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.READY,
    };
}
function resetDelMeetingAndTrainingApplyRecordModal(state) {
    return {
        ...state,
        delMeetingAndTrainingApplyRecordModal: initialState.delMeetingAndTrainingApplyRecordModal,
    }
}



// cop
function openCopMeetingAndTrainingApplyRecordModal(state, payload) {
    return {
        ...state,
        copMeetingAndTrainingApplyRecordModal: {
            ...state.copMeetingAndTrainingApplyRecordModal,
            isOpened: true,
            fields: {
                ...state.copMeetingAndTrainingApplyRecordModal.fields,
                id: payload.id,
            }
        }
    }
}
function copMeetingAndTrainingApplyRecordChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.copMeetingAndTrainingApplyRecordModal.fields,
        [name]: value,
    };
    return {
        ...state,
        copMeetingAndTrainingApplyRecordModal: {
          ...state.copMeetingAndTrainingApplyRecordModal,
          fields: updatedFields,
        },
    };
}
function startCopMeetingAndTrainingApplyRecord(state) {
    return {
      ...state,
      copMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.LOGINING,
    };
}
function copMeetingAndTrainingApplyRecordSuccess(state, resp) {
    return {
        ...state,
        copMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.SUCCESS,
    };
}
function copMeetingAndTrainingApplyRecordFailure(state, err) {
    return {
        ...state,
        copMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.ERROR,
        copMeetingAndTrainingApplyRecordModal: {
            ...state.copMeetingAndTrainingApplyRecordModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initCopMeetingAndTrainingApplyRecordActionStatus(state) {
    return {
        ...state,
        copMeetingAndTrainingApplyRecordStatus: ACTION_STATUS.READY,
    };
}
function resetCopMeetingAndTrainingApplyRecordModal(state) {
    return {
        ...state,
        copMeetingAndTrainingApplyRecordModal: initialState.copMeetingAndTrainingApplyRecordModal,
    }
}



// Load User Account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    let pmArray = [];
  let pmApplyRecordArray = [];
  let rowSize = 4;
  if (userAccountArray.length > 0) {
    pmArray = userAccountArray
    .filter(userAccount => !!userAccount.isActive)
    .filter(userAccount =>  userAccount.firm.toUpperCase() === 'PINPOINT')
      .map(userAccount => Object.assign({}, userAccount, { checked: false }));

    const groupSize = Math.floor(pmArray.length / rowSize) + (pmArray.length % rowSize > 0 ? 1 : 0);
    for (let index = 0; index < groupSize; index++) {
      const array = pmArray.slice(index * rowSize, (index + 1) * rowSize);
      pmApplyRecordArray.push(array);
    }
  }

  // 已经存在的answeree 默认勾选
  const answerArray = state.answerArray;
  const selectedQuestionnaires = state.selectedQuestionnaires;


    
    
    
    
    const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
  

    return {
        ...state,
        userAccountArray,
        pmArray,
        pmApplyRecordArray,
        userAccountOptions,
      };
  }
  // Open/Reset Answeree Select Modal Dialog
function openAnswereeSelectModalDialog(state) {
    return {
      ...state,
      answereeSelectControlModal: {
        ...state.answereeSelectControlModal,
        isOpened: true,
        fields: {
          ...state.answereeSelectControlModal.fields,
        }
      },
    };
  }

function resetAnswereeSelectModalDialog(state) {
    const pmApplyRecordArray = state.pmApplyRecordArray;
  
    // 重置答题人勾选项
    pmApplyRecordArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        userAccount.checked = false;
      })
    });
  
    return {
      ...state,
      answereeSelectControlModal: initialState.answereeSelectControlModal,
      pmApplyRecordArray,
    };
}
// Select All Answeree
function selectAllAnsweree(state) {
    const pmApplyRecordArray = state.pmApplyRecordArray;
  
    pmApplyRecordArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        userAccount.checked = true;
      })
    });
  
    return {
      ...state,
      pmApplyRecordArray,
    };
}
  
  // Select Invert Answeree
function selectInvertAnsweree(state) {
    const pmApplyRecordArray = state.pmApplyRecordArray;
  
    pmApplyRecordArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        userAccount.checked = !userAccount.checked;
      })
    });
  
    return {
      ...state,
      pmApplyRecordArray,
    };
}
  
  // Answeree Check Changed
function answereeCheckChanged(state, {checked ,userAccountName}) {
    const pmApplyRecordArray = state.pmApplyRecordArray;
  
    pmApplyRecordArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        if (userAccount.userAccountName === userAccountName) {
          userAccount.checked = checked;
        }
      })
    });
  
    return {
      ...state,
    };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
function openQuestionnaireEmailEditModalDialog(state, {sendMode, emailPayload}) {
    return {
      ...state,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        isOpened: true,
        sendMode,
        emailPayload,
      },
    };
  }
  
  function resetQuestionnaireEmailEditModalDialog(state) {
    return {
      ...state,
      questionnaireEmailEditControlModal: initialState.questionnaireEmailEditControlModal,
    };
  }



// Select Answerees
function startSelectAnswerees(state) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function selectAnswereesSuccess(state, resp) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function selectAnswereesFailure(state, err) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.ERROR,
      answereeSelectControlModal: {
        ...state.answereeSelectControlModal,
        respErrMsg: err.respMessage,
      },
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initSelectAnswereesActionStatus(state) {
    return {
      ...state,
      selectAnswereesActionStatus: ACTION_STATUS.READY,
    };
  }


  function showDetailRcd(state, detail) {
    return {
        ...state,
        operationRecordDialogModal: {
            ...state.operationRecordDialogModal,
            isDetailOpened: true,
            detail: detail,
        }
    }
}
function resetDetailRcd(state) {
  return {
      ...state,
      operationRecordDialogModal: {
          ...state.operationRecordDialogModal,
          isDetailOpened: false,
          detail: null,
      }
  }
}



export default createReducer(initialState, {
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_MEETING_AND_TRAINING_INFO]: startLoadMeetingAndTrainingApplyRecordInfo,
    [LOAD_MEETING_AND_TRAINING_INFO_APPLYRECORD_SUCCESS]: loadMeetingAndTrainingApplyRecordInfoSuccess,
    [LOAD_MEETING_AND_TRAINING_INFO_FAILURE]: loadMeetingAndTrainingApplyRecordInfoFailure,
    [INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS]: initLoadMeetingAndTrainingApplyRecordInfoActionStatus,
    // select
    [SELECT_MEETING_AND_TRAINING_INFO]: selectMeetingAndTrainingApplyRecordInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL]: openAddOrEditMeetingAndTrainingApplyRecordModal,
    [ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT]: addOrEditMeetingAndTrainingApplyRecordChangeInput,
    [START_ADD_OR_EDIT_MEETING_AND_TRAINING]: startAddOrEditMeetingAndTrainingApplyRecord,
    [ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS]: addOrEditMeetingAndTrainingApplyRecordSuccess,
    [ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE]: addOrEditMeetingAndTrainingApplyRecordFailure,
    [INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS]: initAddOrEditMeetingAndTrainingApplyRecordActionStatus,
    [RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL]: resetAddOrEditMeetingAndTrainingApplyRecordModal,
    // refresh fx and amtUSD
    [START_REFRESH_FX]: startRefreshFx,
    [REFRESH_FX_SUCCESS]: refreshFxSuccess,
    [REFRESH_FX_FAILURE]: refreshFxFailure,
    [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
    [REFRESH_AMT_USD]: refreshAmtUSD,
    // del
    [OPEN_DEL_MEETING_AND_TRAINING_MODAL]: openDelMeetingAndTrainingApplyRecordModal,
    [DEL_MEETING_AND_TRAINING_CHANGE_INPUT]: delMeetingAndTrainingApplyRecordChangeInput,
    [START_DEL_MEETING_AND_TRAINING]: startDelMeetingAndTrainingApplyRecord,
    [DEL_MEETING_AND_TRAINING_SUCCESS]: delMeetingAndTrainingApplyRecordSuccess,
    [DEL_MEETING_AND_TRAINING_FAILURE]: delMeetingAndTrainingApplyRecordFailure,
    [INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS]: initDelMeetingAndTrainingApplyRecordActionStatus,
    [RESET_DEL_MEETING_AND_TRAINING_MODAL]: resetDelMeetingAndTrainingApplyRecordModal,


    // cop
    [OPEN_COP_MEETING_AND_TRAINING_MODAL]: openCopMeetingAndTrainingApplyRecordModal,
    [COP_MEETING_AND_TRAINING_CHANGE_INPUT]: copMeetingAndTrainingApplyRecordChangeInput,
    [START_COP_MEETING_AND_TRAINING]: startCopMeetingAndTrainingApplyRecord,
    [COP_MEETING_AND_TRAINING_SUCCESS]: copMeetingAndTrainingApplyRecordSuccess,
    [COP_MEETING_AND_TRAINING_FAILURE]: copMeetingAndTrainingApplyRecordFailure,
    [INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS]: initCopMeetingAndTrainingApplyRecordActionStatus,
    [RESET_COP_MEETING_AND_TRAINING_MODAL]: resetCopMeetingAndTrainingApplyRecordModal,


    // Load User Account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
    [ANSWEREE_SELECT_MODAL_DIALOG_OPEN]: openAnswereeSelectModalDialog,
    [ANSWEREE_SELECT_MODAL_DIALOG_RESET]: resetAnswereeSelectModalDialog,
      // Answeree Check Changed
    [ANSWEREE_CHECK_CHANGED]: answereeCheckChanged,
      // Select All Answeree
    [SELECT_ALL_ANSWEREE]: selectAllAnsweree,

    // Select Invert Answeree
    [SELECT_INVERT_ANSWEREE]: selectInvertAnsweree,

      // Open/Reset Questionnaire Email Edit Modal Dialog
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN]: openQuestionnaireEmailEditModalDialog,
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET]: resetQuestionnaireEmailEditModalDialog,

    
    // Select Answerees
    [START_SELECT_ANSWEREES]: startSelectAnswerees,
    [SELECT_ANSWEREES_SUCCESS]: selectAnswereesSuccess,
    [SELECT_ANSWEREES_FAILURE]: selectAnswereesFailure,
    [INIT_SELECT_ANSWEREES_ACTION_STATUS]: initSelectAnswereesActionStatus,
    [SHOW_DETAIL_RCD]: showDetailRcd,
    [RESET_DETAIL_RCD]: resetDetailRcd,
});