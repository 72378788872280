import {connect} from 'react-redux';
import RestrictedAsset from '../components/RestrictedAsset';

import {
  // Load Restricted Asset
  loadRestrictedAsset,
  initLoadRestrictedAssetActionStatus,

  // RadioGroup Change
  radioGroupChange,

  // Create Restricted Asset
  createRestrictedAsset,
  initCreateRestrictedAssetActionStatus,

  // Update Restricted Asset
  updateRestrictedAsset,
  initUpdateRestrictedAssetActionStatus,

  // Batch Delete Restricted Asset
  batchDeleteRestrictedAsset,
  initBatchDeleteRestrictedAssetActionStatus,

  // Open/Reset Restricted Asset Modal Dialog
  openRestrictedAssetEditModalDialog,
  resetRestrictedAssetEditModalDialog,

  // Restricted Asset Edit Change Input
  restrictedAssetEditChangeInput,

  // Select Restricted Asset
  selectRestrictedAssetClass,

  // Fund Code Select Changed
  fundCodeSelectChanged,

  // Book Code Select Changed
  bookCodeSelectChanged,
} from '../restrictedAssetActions';

const mapStateToProps = state => {
  return {
    restrictedAssetGridColumns: state.restrictedAsset.restrictedAssetGridColumns,

    restrictedAssetArray: state.restrictedAsset.restrictedAssetArray,
    filterRestrictedAssetArray: state.restrictedAsset.filterRestrictedAssetArray,

    // Edit Control Modal
    restrictedAssetEditControlModal: state.restrictedAsset.restrictedAssetEditControlModal,

    loadRestrictedAssetActionStatus: state.restrictedAsset.loadRestrictedAssetActionStatus,
    createRestrictedAssetActionStatus: state.restrictedAsset.createRestrictedAssetActionStatus,
    updateRestrictedAssetActionStatus: state.restrictedAsset.updateRestrictedAssetActionStatus,
    batchDeleteRestrictedAssetActionStatus: state.restrictedAsset.batchDeleteRestrictedAssetActionStatus,

    // Fund Book Data
    fundCodeOptions: state.restrictedAsset.fundCodeOptions,
    bookCodeOptions: state.restrictedAsset.bookCodeOptions,

    // Asset Inst Class Data
    assetClassOptions: state.restrictedAsset.assetClassOptions,
    instClassOptions: state.restrictedAsset.instClassOptions,

    // Selected Restricted Asset
    selectedRestrictedAsset: state.restrictedAsset.selectedRestrictedAsset,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Restricted Asset
    loadRestrictedAsset: () => dispatch(loadRestrictedAsset()),
    initLoadRestrictedAssetActionStatus: () => dispatch(initLoadRestrictedAssetActionStatus()),

    // RadioGroup Change
    radioGroupChange: (value) => dispatch(radioGroupChange(value)),

    // Create Restricted Asset
    createRestrictedAsset: (restrictedAsset) => dispatch(createRestrictedAsset(restrictedAsset)),
    initCreateRestrictedAssetActionStatus: () => dispatch(initCreateRestrictedAssetActionStatus()),

    // Update Restricted Asset
    updateRestrictedAsset: (restrictedAsset) => dispatch(updateRestrictedAsset(restrictedAsset)),
    initUpdateRestrictedAssetActionStatus: () => dispatch(initUpdateRestrictedAssetActionStatus()),

    // Batch Delete Restricted Asset
    batchDeleteRestrictedAsset: (restrictedAssetIdList) => dispatch(batchDeleteRestrictedAsset(restrictedAssetIdList)),
    initBatchDeleteRestrictedAssetActionStatus: () => dispatch(initBatchDeleteRestrictedAssetActionStatus()),

    // Open/Reset Restricted Asset Modal Dialog
    openRestrictedAssetEditModalDialog: (isAddMode, restrictedAsset) => dispatch(openRestrictedAssetEditModalDialog(isAddMode, restrictedAsset)),
    resetRestrictedAssetEditModalDialog: () => dispatch(resetRestrictedAssetEditModalDialog()),

    // Restricted Asset Edit Change Input
    restrictedAssetEditChangeInput: (input) => dispatch(restrictedAssetEditChangeInput(input)),

    // Select Restricted Asset
    selectRestrictedAssetClass: (payload) => dispatch(selectRestrictedAssetClass(payload)),

    // Fund Code Select Changed
    fundCodeSelectChanged: (fundCode) => dispatch(fundCodeSelectChanged(fundCode)),

    // Book Code Select Changed
    bookCodeSelectChanged: (bookCode) => dispatch(bookCodeSelectChanged(bookCode)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedAsset);