import {connect} from 'react-redux';
import RegisterDashboard from '../components/RegisterDashboard';


import {
    // query
    onQueryInputChange,
    loadRegisterInfo,
    initLoadRegisterInfoActionStatus,
    // select
    selectRegister,
    // add or edit
    openAddOrEditRegisterModal,
    addOrEditRegisterChangeInput,
    resetSubmitSaveRegisterModal,
    submitSaveRegister,
    submitSaveRegisterFailure,
    initSubmitSaveRegisterActionStatus,

    //Sub add or edit
    openAddOrEditRegisterSubModal,
    addOrEditRegisterSubChangeInput,
    resetSubmitSaveRegisterSubModal,
    submitSaveRegisterSub,
    submitSaveRegisterSubFailure,
    initSubmitSaveRegisterSubActionStatus,



    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForRegister,
    initBatchChangeStatusForRegisterActionStatus,
    // finish
    selectRegisterTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishRegisterTask,
    initFinishRegisterTaskActionStatus,


    openQuestionnaireEmailEditModalDialog,
    resetQuestionnaireEmailEditModalDialog,

  // Accept Questionnaire Result
  acceptQuestionnaireResult,
  initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  rejectQuestionnaireResult,
  initRejectQuestionnaireResultActionStatus,

    // Send Remind Email
    sendRemindEmail,
    initSendRemindEmailActionStatus,


} from '../RegisterActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';

import {
    queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'

import {
    loadLicensingForm,
} from '../../licensingForm/licensingFormActions'
const mapStateToProps = state => {
    return {
        // basic options
        locationOptions: state.register.locationOptions,
        fundCompanyOptions: state.register.fundCompanyOptions,
        frequencyOptions: state.register.frequencyOptions,
        weekdayOptions: state.register.weekdayOptions,
        monthOptions: state.register.monthOptions,
        responsibleDeptOptions: state.register.responsibleDeptOptions,
        licensingFormOptions: state.register.licensingFormOptions,
        departmentOptions: state.register.departmentOptions,
        officeOptions: state.register.officeOptions,
        statusOptions: state.register.statusOptions,
        importanceOptions: state.register.importanceOptions,
        userAccountOptions: state.userAccess.userAccountOptions,
        regulatorOptions: state.register.regulatorOptions,
        licenseOptions: state.register.licenseOptions,
        applicationStatusOptions: state.register.applicationStatusOptions,
        licensingStatusOptions: state.register.licensingStatusOptions,
        examinationStatusOptions: state.register.examinationStatusOptions,

        // query
        registerQueryFields: state.register.registerQueryFields,
        loadRegisterInfoActionStatus: state.register.loadRegisterInfoActionStatus,
        loadRegisterInfoResp: state.register.loadRegisterInfoResp,
        // select
        selectedRegisterArray: state.register.selectedRegisterArray,
        // add or edit
        addOrEditRegisterModal: state.register.addOrEditRegisterModal,
        addOrEditRegisterStatus: state.register.addOrEditRegisterStatus,

        // sub add or edit
        addOrEditRegisterSubModal: state.register.addOrEditRegisterSubModal,
        addOrEditRegisterSubStatus: state.register.addOrEditRegisterSubStatus,



        // batch change status
        batchChangeStatusModal: state.register.batchChangeStatusModal,
        batchChangeStatusForRegisterStatus: state.register.batchChangeStatusForRegisterStatus,
        // finish
        selectedRegisterTaskArray: state.register.selectedRegisterTaskArray,
        finishTaskModal: state.register.finishTaskModal,
        finishRegisterTaskStatus: state.register.finishRegisterTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.register.userAccountEmailOptions,
            // Open/Reset Questionnaire Email Edit Modal Dialog
        questionnaireEmailEditControlModal: state.register.questionnaireEmailEditControlModal,

        
        // Accept Questionnaire Result
        acceptQuestionnaireResultActionStatus: state.register.acceptQuestionnaireResultActionStatus,

        // Reject Questionnaire Result
        rejectQuestionnaireResultActionStatus: state.register.rejectQuestionnaireResultActionStatus,
        // Send Remind Email
        sendRemindEmailActionStatus: state.register.sendRemindEmailActionStatus,

    

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadRegisterInfo: (params) => dispatch(loadRegisterInfo(params)),
        initLoadRegisterInfoActionStatus: () => dispatch(initLoadRegisterInfoActionStatus()),
        // select
        selectRegister: (array) => dispatch(selectRegister(array)),
        // add or edit
        openAddOrEditRegisterModal: (mode, data) => dispatch(openAddOrEditRegisterModal(mode, data)),
        addOrEditRegisterChangeInput: (data) => dispatch(addOrEditRegisterChangeInput(data)),
        resetSubmitSaveRegisterModal: () => dispatch(resetSubmitSaveRegisterModal()),
        submitSaveRegister: (bean) => dispatch(submitSaveRegister(bean)),
        submitSaveRegisterFailure: (err) => dispatch(submitSaveRegisterFailure(err)),
        initSubmitSaveRegisterActionStatus: () => dispatch(initSubmitSaveRegisterActionStatus()),


        //sub add or edit
        openAddOrEditRegisterSubModal: (mode, data,idList) => dispatch(openAddOrEditRegisterSubModal(mode, data,idList)),
        addOrEditRegisterSubChangeInput: (data) => dispatch(addOrEditRegisterSubChangeInput(data)),
        resetSubmitSaveRegisterSubModal: () => dispatch(resetSubmitSaveRegisterSubModal()),
        submitSaveRegisterSub: (bean) => dispatch(submitSaveRegisterSub(bean)),
        submitSaveRegisterSubFailure: (err) => dispatch(submitSaveRegisterSubFailure(err)),
        initSubmitSaveRegisterSubActionStatus: () => dispatch(initSubmitSaveRegisterSubActionStatus()),



        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForRegister: (params) => dispatch(batchChangeStatusForRegister(params)),
        initBatchChangeStatusForRegisterActionStatus: () => dispatch(initBatchChangeStatusForRegisterActionStatus()),
        // finish
        selectRegisterTask: (array) => dispatch(selectRegisterTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishRegisterTask: (task) => dispatch(finishRegisterTask(task)),
        initFinishRegisterTaskActionStatus: () => dispatch(initFinishRegisterTaskActionStatus()),
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


        // Open/Reset Questionnaire Email Edit Modal Dialog
        openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
        resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),



        // Accept  Result
        acceptQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
        initAcceptQuestionnaireResultActionStatus: () => dispatch(initAcceptQuestionnaireResultActionStatus()),

        loadLicensingForm: (params) => dispatch(loadLicensingForm(params)),
        
        // Reject Questionnaire Result
        rejectQuestionnaireResult: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent)),
        initRejectQuestionnaireResultActionStatus: () => dispatch(initRejectQuestionnaireResultActionStatus()),

        // Send Remind Email
        sendRemindEmail: (questionnaireResultGuidList, emailTitle, emailContent) => 
            dispatch(sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent)),
        initSendRemindEmailActionStatus: () => dispatch(initSendRemindEmailActionStatus()),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(RegisterDashboard);
