import {connect} from 'react-redux';
import GiftHospitalityRequest from '../components/GiftHospitalityRequest';

import {
  queryGiftHospitalityInputChange,

  // Load Gift Hospitality Request
  loadGiftHospitalityRequest,
  initLoadGiftHospitalityRequestActionStatus,
  
  // Select Gift Hospitality Request
  selectGiftHospitalityRequest,

  // Open/Reset Gift Hospitality Request Modal Dialog
  openGiftHospitalityRequestEditModalDialog,
  resetGiftHospitalityRequestEditModalDialog,

  // Gift Hospitality Request Edit Change Input
  giftHospitalityRequestEditChangeInput,

  // Approve Gift Hospitality Request
  approveGiftHospitalityRequest,
  initApproveGiftHospitalityRequestActionStatus,

  // Reject Gift Hospitality Request
  rejectGiftHospitalityRequest,
  initRejectGiftHospitalityRequestActionStatus,

  // Feedback Gift Hospitality Request
  feedbackGiftHospitalityRequest,
  initFeedbackGiftHospitalityRequestActionStatus,

} from '../giftHospitalityRequestActions';


// import {
//   clearEmployeeNameList,
//   fuzzySearchEmployee,
// } from '../../personalAccount/personalAccountActions';


import {
  queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
  return {
    giftHospitalityRequestGridColumns: state.giftHospitalityRequest.giftHospitalityRequestGridColumns,
    gitfHospitalitySearchFields: state.giftHospitalityRequest.gitfHospitalitySearchFields,
    // fuzzyQueryEmployeeNameOptions4Search: state.personalAccount.fuzzyQueryEmployeeNameOptions.giftHospitality4Search,

    // Gift Hospitality Request
    giftHospitalityRequestArray: state.giftHospitalityRequest.giftHospitalityRequestArray,

    giftHospitalityRequestEditControlModal: state.giftHospitalityRequest.giftHospitalityRequestEditControlModal,
  
    // Selected Gift Hospitality Request
    selectedGiftHospitalityRequestArray: state.giftHospitalityRequest.selectedGiftHospitalityRequestArray,

    // Load Gift Hospitality Request Action Status
    loadGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.loadGiftHospitalityRequestActionStatus,

    // Approve Gift Hospitality Request Action Status
    approveGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.approveGiftHospitalityRequestActionStatus,

    // Reject Gift Hospitality Request Action Status
    rejectGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.rejectGiftHospitalityRequestActionStatus,

    // Feedback Gift Hospitality Request Action Status
    feedbackGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.feedbackGiftHospitalityRequestActionStatus,

    // Gfit Hospitality Upload / Preview Url
    uploadUrl: state.giftHospitalityRequest.uploadUrl,
    previewImgPath: state.giftHospitalityRequest.previewImgPath,

    // Load User Account
    userAccountOptions: state.restrictedSecurityRequest.userAccountOptions,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    queryGiftHospitalityInputChange: (param) => dispatch(queryGiftHospitalityInputChange(param)),
    // clearEmployeeNameList: (module) => dispatch(clearEmployeeNameList(module)),
    // fuzzySearchEmployee: (module, namePart) => dispatch(fuzzySearchEmployee(module, namePart)),

    // Load Gift Hospitality Request
    loadGiftHospitalityRequest: (params) => dispatch(loadGiftHospitalityRequest(params)),
    initLoadGiftHospitalityRequestActionStatus: () => dispatch(initLoadGiftHospitalityRequestActionStatus()),
    
    // Select Gift Hospitality Request
    selectGiftHospitalityRequest: (payload) => dispatch(selectGiftHospitalityRequest(payload)),

    // Open/Reset Gift Hospitality Request Modal Dialog
    openGiftHospitalityRequestEditModalDialog: (giftHospitalityRequest) => dispatch(openGiftHospitalityRequestEditModalDialog(giftHospitalityRequest)),
    resetGiftHospitalityRequestEditModalDialog: () => dispatch(resetGiftHospitalityRequestEditModalDialog()),

    // Gift Hospitality Request Edit Change Input
    giftHospitalityRequestEditChangeInput: (input) => dispatch(giftHospitalityRequestEditChangeInput(input)),

    // Approve Gift Hospitality Request
    approveGiftHospitalityRequest: (giftHospitalityIdList) => dispatch(approveGiftHospitalityRequest(giftHospitalityIdList)),
    initApproveGiftHospitalityRequestActionStatus: () => dispatch(initApproveGiftHospitalityRequestActionStatus()),

    // Reject Gift Hospitality Request
    rejectGiftHospitalityRequest: (giftHospitalityIdList) => dispatch(rejectGiftHospitalityRequest(giftHospitalityIdList)),
    initRejectGiftHospitalityRequestActionStatus: () => dispatch(initRejectGiftHospitalityRequestActionStatus()),

    // Feedback Gift Hospitality Request
    feedbackGiftHospitalityRequest: (id, feedback) => dispatch(feedbackGiftHospitalityRequest(id, feedback)),
    initFeedbackGiftHospitalityRequestActionStatus: () => dispatch(initFeedbackGiftHospitalityRequestActionStatus()),

    queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftHospitalityRequest);