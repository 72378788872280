import {connect} from 'react-redux';
import LicensingFormList from '../components/LicensingFormList';

import {
  // query
  onQueryInputChange,
  loadLicensingForm,
  initLoadLicensingFormActionStatus,

  // Load Answer
  loadAnswers,
  initLoadAnswersActionStatus,

  // Open/Reset Answeree Select Modal Dialog
  openAnswereeSelectModalDialog,
  resetAnswereeSelectModalDialog,

  // Select All Answeree
  selectAllAnsweree,

  // Select Invert Answeree
  selectInvertAnsweree,

  // Answeree Check Changed
  answereeCheckChanged,

  // Select Answeree
  selectAnswerees,
  initSelectAnswereesActionStatus,

  // Select LicensingForm
  selectLicensingForm,
    
  // Select Questions
  selectQuestions,

  // ================================================================================
  // Open/Reset LicensingForm Edit Modal Dialog
  openLicensingFormEditModalDialog,
  resetLicensingFormEditModalDialog,

  // Create LicensingForm
  createLicensingForm,
  initCreateLicensingFormActionStatus,

  // Update LicensingForm
  updateLicensingForm,
  initUpdateLicensingFormActionStatus,

  // Copy LicensingForm
  copyLicensingForm,
  initCopyLicensingFormActionStatus,

  // Batch Delete LicensingForm
  batchDeleteLicensingForm,
  initBatchDeleteLicensingFormActionStatus,

  // LicensingForm Edit Change Input
  licensingFormEditChangeInput,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  openQuestionEditModalDialog,
  resetQuestionEditModalDialog,

  // Create Question
  createQuestion,
  initCreateQuestionActionStatus,

  // Update Question
  updateQuestion,
  initUpdateQuestionActionStatus,

  // Batch Delete Question
  batchDeleteQuestion,
  initBatchDeleteQuestionActionStatus,

  // Question Edit Change Input
  questionEditChangeInput,

  // LicensingForm Option Change
  licensingFormOptionChange,

  // Open/Reset LicensingForm Email Edit Modal Dialog
  openLicensingFormEmailEditModalDialog,
  resetLicensingFormEmailEditModalDialog,

} from '../licensingFormActions';


import {
  loadUserAccounts,
} from '../../../home/homeActions';



const mapStateToProps = state => {
  return {
    licensingFormGridColumns: state.licensingForm.licensingFormGridColumns,
    questionListGridColumns: state.licensingForm.questionListGridColumns,
    statusOptions: state.licensingForm.statusOptions,
    // userAccessQueryFields
    licensingFormQueryFields: state.licensingForm.licensingFormQueryFields,


    // LicensingForm
    licensingFormArray: state.licensingForm.licensingFormArray,
    licensingFormOptions: state.licensingForm.licensingFormOptions,
    parentQuestionOptions: state.licensingForm.parentQuestionOptions,
    loadLicensingFormActionStatus: state.licensingForm.loadLicensingFormActionStatus,

    answereeSelectControlModal: state.licensingForm.answereeSelectControlModal,

    // Answers
    answerArray: state.licensingForm.answerArray,
    
    // Select Answerees
    selectAnswereesActionStatus: state.licensingForm.selectAnswereesActionStatus,

    userAccountArray: state.licensingForm.userAccountArray,
    pmArray: state.licensingForm.pmArray,
    pmGroupArray: state.licensingForm.pmGroupArray,

    // Select LicensingForm
    selectedLicensingForm: state.licensingForm.selectedLicensingForm,

    // Select Questions
    selectedQuestions: state.licensingForm.selectedQuestions,

    // LicensingForm Edit Modal
    licensingFormEditControlModal: state.licensingForm.licensingFormEditControlModal,

    // create/update/delete LicensingForm
    createLicensingFormActionStatus: state.licensingForm.createLicensingFormActionStatus,
    updateLicensingFormActionStatus: state.licensingForm.updateLicensingFormActionStatus,
    copyLicensingFormActionStatus: state.licensingForm.copyLicensingFormActionStatus,
    batchDeleteLicensingFormActionStatus: state.licensingForm.batchDeleteLicensingFormActionStatus,

    // Question Edit Modal
    questionEditControlModal: state.licensingForm.questionEditControlModal,

    // create/update/delete Question
    createQuestionActionStatus: state.licensingForm.createQuestionActionStatus,
    updateQuestionActionStatus: state.licensingForm.updateQuestionActionStatus,
    batchDeleteQuestionsActionStatus: state.licensingForm.batchDeleteQuestionsActionStatus,

    // Open/Reset LicensingForm Email Edit Modal Dialog
    licensingFormEmailEditControlModal: state.licensingForm.licensingFormEmailEditControlModal,

    // LicensingForm attachment
    licensingFormAttachmentUploadUrl: state.licensingForm.licensingFormAttachmentUploadUrl,
    licensingFormAttachmentDownloadUrl: state.licensingForm.licensingFormAttachmentDownloadUrl,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // query
    onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
    loadLicensingForm: (params) => dispatch(loadLicensingForm(params)),
    initLoadLicensingFormActionStatus: () => dispatch(initLoadLicensingFormActionStatus()),

    // Load Answer
    loadAnswers: () => dispatch(loadAnswers()),
    initLoadAnswersActionStatus: () => dispatch(initLoadAnswersActionStatus()),

    // Open/Reset Answeree Select Modal Dialog
    openAnswereeSelectModalDialog: () => dispatch(openAnswereeSelectModalDialog()),
    resetAnswereeSelectModalDialog: () => dispatch(resetAnswereeSelectModalDialog()),

    // Select All Answeree
    selectAllAnsweree: () => dispatch(selectAllAnsweree()),

    // Select Invert Answeree
    selectInvertAnsweree: () => dispatch(selectInvertAnsweree()),

    // Answeree Check Changed
    answereeCheckChanged: (checked ,userAccountName) => dispatch(answereeCheckChanged(checked ,userAccountName)),

    // Select Answeree
    selectAnswerees: (userAccountNameList, licensingFormGuid, emailTitle, emailContent) => 
      dispatch(selectAnswerees(userAccountNameList, licensingFormGuid, emailTitle, emailContent)),
      
    initSelectAnswereesActionStatus: () => dispatch(initSelectAnswereesActionStatus()),
    
    // Select LicensingForm
    selectLicensingForm: (payload) => dispatch(selectLicensingForm(payload)),
      
    // Select Questions
    selectQuestions: (payload) => dispatch(selectQuestions(payload)),

    // ================================================================================
    // Open/Reset LicensingForm Edit Modal Dialog
    openLicensingFormEditModalDialog: (mode, licensingForm) => dispatch(openLicensingFormEditModalDialog(mode, licensingForm)),
    resetLicensingFormEditModalDialog: () => dispatch(resetLicensingFormEditModalDialog()),

    // Create LicensingForm
    createLicensingForm: (licensingForm) => dispatch(createLicensingForm(licensingForm)),
    initCreateLicensingFormActionStatus: () => dispatch(initCreateLicensingFormActionStatus()),

    // Update LicensingForm
    updateLicensingForm: (licensingForm) => dispatch(updateLicensingForm(licensingForm)),
    initUpdateLicensingFormActionStatus: () => dispatch(initUpdateLicensingFormActionStatus()),

    // Copy LicensingForm
    copyLicensingForm: (licensingForm) => dispatch(copyLicensingForm(licensingForm)),
    initCopyLicensingFormActionStatus: () => dispatch(initCopyLicensingFormActionStatus()),

    // Batch Delete LicensingForm
    batchDeleteLicensingForm: (licensingFormGuidList) => dispatch(batchDeleteLicensingForm(licensingFormGuidList)),
    initBatchDeleteLicensingFormActionStatus: () => dispatch(initBatchDeleteLicensingFormActionStatus()),

    // LicensingForm Edit Change Input
    licensingFormEditChangeInput: (input) => dispatch(licensingFormEditChangeInput(input)),

    // ================================================================================
    // Open/Reset Question Edit Modal Dialog
    openQuestionEditModalDialog: (isAddMode, question) => dispatch(openQuestionEditModalDialog(isAddMode, question)),
    resetQuestionEditModalDialog: () => dispatch(resetQuestionEditModalDialog()),

    // Create Question
    createQuestion: (question) => dispatch(createQuestion(question)),
    initCreateQuestionActionStatus: () => dispatch(initCreateQuestionActionStatus()),

    // Update Question
    updateQuestion: (question) => dispatch(updateQuestion(question)),
    initUpdateQuestionActionStatus: () => dispatch(initUpdateQuestionActionStatus()),

    // Batch Delete Question
    batchDeleteQuestion: (questionGuidList) => dispatch(batchDeleteQuestion(questionGuidList)),
    initBatchDeleteQuestionActionStatus: () => dispatch(initBatchDeleteQuestionActionStatus()),

    // Question Edit Change Input
    questionEditChangeInput: (input) => dispatch(questionEditChangeInput(input)),

    // LicensingForm Option Change
    licensingFormOptionChange: (licensingFormGuid) => dispatch(licensingFormOptionChange(licensingFormGuid)),

    // Open/Reset LicensingForm Email Edit Modal Dialog
    openLicensingFormEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openLicensingFormEmailEditModalDialog(sendMode, emailPayload)),
    resetLicensingFormEmailEditModalDialog: () => dispatch(resetLicensingFormEmailEditModalDialog()),

    // loadUserAccounts
    loadUserAccounts: () => dispatch(loadUserAccounts()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicensingFormList);