
export const LICENSINGFORM_TYPE_CODE = {
  PERSONALINFO: 'Personal Info',
  REGISTER: 'Register',
  CPT: 'CPT',
};

const LICENSINGFORM_TYPE_LABEL = {
  PERSONALINFO: 'Personal Info',
  REGISTER: 'Register',
  CPT: 'CPT',
};

export const LICENSINGFORM_TYPE_MAP = [
  // Quarter
  {
    code: LICENSINGFORM_TYPE_CODE.PERSONALINFO,
    label: LICENSINGFORM_TYPE_LABEL.PERSONALINFO
  },
  // Quiz
  {
    code: LICENSINGFORM_TYPE_CODE.REGISTER,
    label: LICENSINGFORM_TYPE_LABEL.REGISTER
  },
  {
    code: LICENSINGFORM_TYPE_CODE.CPT,
    label: LICENSINGFORM_TYPE_LABEL.CPT
  },
];