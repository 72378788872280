import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input,Divider } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './RatingManagementDashboard.css';
import RatingManagementGrid from './RatingManagementGrid';
import AddOrEditRatingManagementModalDialog from './AddOrEditRatingManagementModalDialog';
import DelRatingManagementModalDialog from './DelRatingManagementModalDialog';
import CopRatingManagementModalDialog from './CopRatingManagementModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';


class RatingManagementDashboard extends Component {
    componentDidMount() {
        const {
            cpty,
            functiontype,
            fundandcompany,
            userName,
            status
        } = this.props.ratingManagementQueryFields;
        this.props.loadRatingManagementInfo({cpty: undefined,functiontype: undefined,fundandcompany: undefined,userName: undefined, status: 'Active'})

    }


    componentDidUpdate() {
        const {
            // query
            loadRatingManagementInfoActionStatus,
            loadRatingManagementInfoResp,
        } = this.props;

        if(loadRatingManagementInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadRatingManagementInfoActionStatus();
        }
        if(loadRatingManagementInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load Rating Management Info failed';
            if(!!loadRatingManagementInfoResp && !!loadRatingManagementInfoResp.respMessage) {
                err = loadRatingManagementInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadRatingManagementInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };

    batchReplace = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditRatingManagementModal(mode, null);
        } else {
            const {
                selectedRatingManagementInfoArray,
            } = this.props;
            if(selectedRatingManagementInfoArray.length === 0) {
                message.warning("Please choose one and  Rating Management Info");
                return;
            }
            const idList = selectedRatingManagementInfoArray.map(e => e.id);
            // this.props.openAddOrEditPortfolioMonitoringRuleSubModal(mode, null,idList);
            const currentRatingManagementInfo = selectedRatingManagementInfoArray[0];
            const ids_str = idList.join(',');
            this.props.openDelRatingManagementModal(currentRatingManagementInfo,mode,ids_str,currentRatingManagementInfo.level);
            // this.props.openDelRatingManagementModal(currentRatingManagementInfo,mode);
        }
    }
    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditRatingManagementModal(mode, null);
        } else {
            const {
                selectedRatingManagementInfoArray,
            } = this.props;
            if(selectedRatingManagementInfoArray.length !== 1) {
                message.warning("Please choose one and only one Rating Management Info");
                return;
            }
            const currentRatingManagementInfo = selectedRatingManagementInfoArray[0];
            this.props.openAddOrEditRatingManagementModal(mode, currentRatingManagementInfo);
        }
    }
    copyAllBtnOnClicked = () => {
        const {
            selectedRatingManagementInfoArray,
        } = this.props;
        if(selectedRatingManagementInfoArray.length !== 1) {
            message.warning("Please choose one and only one Rating Management Info");
            return;
        }
        const currentRatingManagementInfo = selectedRatingManagementInfoArray[0];
        this.props.openCopRatingManagementModal(currentRatingManagementInfo);
    }

    delBtnOnClicked = (mode) => {
        const {
            selectedRatingManagementInfoArray,
        } = this.props;
        if(selectedRatingManagementInfoArray.length !== 1) {
            message.warning("Please choose one and only one Rating Management Info");
            return;
        }
        const currentRatingManagementInfo = selectedRatingManagementInfoArray[0];
        this.props.openDelRatingManagementModal(currentRatingManagementInfo,mode);
    }


    refreshBtnOnClicked = () => {
        const {
            cpty,
            functiontype,
            fundandcompany,
            userName,
            status
        } = this.props.ratingManagementQueryFields;
        this.props.loadRatingManagementInfo({cpty,functiontype,fundandcompany,userName, status})
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {

        const {
            statusOptions,
            userAccountOptions,
            ratingManagementQueryFields,
            loadRatingManagementInfoActionStatus,
        } = this.props;

        const {
            userName,
            status,
        } = ratingManagementQueryFields;


        const refreshBtnIsLoading = loadRatingManagementInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='RatingManagementWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Entity & Account</Breadcrumb.Item>
                        <Breadcrumb.Item>Rating Management</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                <div className='breadcrumbWrapper'>   
                <Breadcrumb>
                <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                </Breadcrumb>
                </div>

                    {/* <Input 
                        placeholder="User"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'userName', value: event.target.value })
                        }} 
                    /> */}
                    {/* <Select
                        allowClear
                        showSearch
                        size='small'
                        // --value={!user ? undefined : user}
                        style={{marginLeft: 10, width: 150}}
                        //onChange={(value) => this.onInputChange({name: 'userName', value: value})}
                        onChange={(val) => {
                            this.onInputChange({ name: 'userName', value: val })
                        }} 
                        placeholder="Employee">
                        {userAccountOptions}
                    </Select> */}
                    {/* <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'status', value: val })
                        }} 
                        placeholder="Status">
                        {statusOptions}
                    </Select> */}
                    {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date Start"
                      onChange={(invDateStart, dateString) => {
                        this.onInputChange({ name: 'invDateStart', value: dateString });
                      }}
                    /> */}
                    {/* <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date End"
                      onChange={(invDateEnd, dateString) => {
                        this.onInputChange({ name: 'invDateEnd', value: dateString });
                      }}
                    /> */}
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.batchReplace('REPLACE')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negativeeee-button'>
                        Next Stage
                    </Button>

                    <Button 
                        onClick={()=>this.delBtnOnClicked('ADDNOIF')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negativeeee-button'>
                        Notification
                    </Button>
                    <Divider type='vertical' />


                    <Button 
                        onClick={()=>this.delBtnOnClicked('PROMOTION')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negativwww-button'>
                        Promotion
                    </Button>

                    <Button 
                        onClick={()=>this.delBtnOnClicked('BELONG')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negativwww-button'>
                        Change
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('ADD')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button> */}
                    {/* <Button 
                        onClick={()=>this.copyAllBtnOnClicked()} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy All
                    </Button> */}
                    {/* <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button> */}
                    <Button 
                        onClick={()=>this.delBtnOnClicked('inactive')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Inactivate User
                    </Button>
                    {/* <Button 
                        onClick={()=>this.delBtnOnClicked('ADDNOIF')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button> */}
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'CollegeRating'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button>
                </div>

                <div className='RatingManagementGrid'><RatingManagementGrid {...this.props} /></div>
                <AddOrEditRatingManagementModalDialog {...this.props} />
                <DelRatingManagementModalDialog {...this.props} />
                <CopRatingManagementModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />

            </div>
        );
    }
}

export default RatingManagementDashboard;
