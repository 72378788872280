import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    OPEN_OPERATION_RECORD_DIALOG,
    START_QUERY_OPERATION_RECORD,
    QUERY_OPERATION_RECORD_SUCESS,
    QUERY_OPERATION_RECORD_FAILURE,
    INIT_QUERY_OPERATION_RECORD_STATUS,
    RESET_OPERATION_RECORD_DIALOG,
    // detail
    SHOW_DETAIL_RCD,
    RESET_DETAIL_RCD,
} from './OperationRecordConstants';


// query page
function openOperationRecordDialog(queryParams) {
    return {
        type: OPEN_OPERATION_RECORD_DIALOG,
        payload: queryParams,
    };
}
function startQueryOperationRecord() {
    return {
        type: START_QUERY_OPERATION_RECORD,
    }
}
function openOperationRecordDialogAndQuery(queryFields, customColumns) {
    const currQueryFields = {
        module: !!queryFields.module ? queryFields.module : '',
        dataId: !!queryFields.dataId ? queryFields.dataId : '',
        operateTimeStart: !!queryFields.operateTimeStart ? queryFields.operateTimeStart : '',
        operateTimeEnd: !!queryFields.operateTimeEnd ? queryFields.operateTimeEnd : '',
    }
    const queryParams = {
        queryFields: currQueryFields,
        customColumns: customColumns,
    }

    return function(dispatch) {
        dispatch(openOperationRecordDialog(queryParams));
        dispatch(startQueryOperationRecord());
        httpClient
          .get(UrlConfig.QUERY_OPERATION_RECORD, currQueryFields)
          .then(resp => dispatch(queryOperationRecordSuccess(resp)))
          .catch(err => dispatch(queryOperationRecordFailure(err)));
    };
}
function queryOperationRecordSuccess(resp) {
    return {
        type: QUERY_OPERATION_RECORD_SUCESS,
        payload: resp,
    }
}
function queryOperationRecordFailure(err) {
    return {
        type: QUERY_OPERATION_RECORD_FAILURE,
        payload: err,
    }
}
function initQueryOperationRecordStatus() {
    return {
        type: INIT_QUERY_OPERATION_RECORD_STATUS,
    }
}
function resetOperationRecordDialog() {
    return {
        type: RESET_OPERATION_RECORD_DIALOG,
    }
}


// detail page
function showDetailRcd(detail) {
    return {
        type: SHOW_DETAIL_RCD,
        payload: detail,
    }
}
function resetDetailRcd() {
    return {
        type: RESET_DETAIL_RCD,
    }
}


// export
export function doOperationRecordAction(params) {
    const {action, queryFields, customColumns, detail} = params;
    if(action === 'OPEN_AND_QUERY') {
        return openOperationRecordDialogAndQuery(queryFields, customColumns);
    } else if(action === 'INIT_QUERY') {
        return initQueryOperationRecordStatus();
    } else if(action === 'RESET_QUERY') {
        return resetOperationRecordDialog();
    } else if(action === 'SHOW_DETAIL') {
        return showDetailRcd(detail);
    } else if(action === 'RESET_DETAIL') {
        return resetDetailRcd();
    }
}

