import {
  // Load Questionnaire
  ON_QUERY_INPUT_CHANGE,
  START_LOAD_QUESTIONNAIRES,
  LOAD_QUESTIONNAIRES_SUCCESS,
  LOAD_QUESTIONNAIRES_FAILURE,
  INIT_LOAD_QUESTIONNAIRES_ACTION_STATUS,

  // Select Questionnaires
  SELECT_QUESTIONNAIRES,
  
  // Load Question
  START_LOAD_QUESTIONS,
  LOAD_QUESTIONS_SUCCESS,
  LOAD_QUESTIONS_FAILURE,
  INIT_LOAD_QUESTIONS_ACTION_STATUS,

  // Select Questions
  SELECT_QUESTIONS,

  // Open/Reset Answeree Select Modal Dialog
  ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
  ANSWEREE_SELECT_MODAL_DIALOG_RESET,

  // Select All Answeree
  SELECT_ALL_ANSWEREE,

  // Select Invert Answeree
  SELECT_INVERT_ANSWEREE,

  // Answeree Check Changed
  ANSWEREE_CHECK_CHANGED,

  // Select Answerees
  START_SELECT_ANSWEREES,
  SELECT_ANSWEREES_SUCCESS,
  SELECT_ANSWEREES_FAILURE,
  INIT_SELECT_ANSWEREES_ACTION_STATUS,






  // Load Answers
  START_LOAD_ANSWERS,
  LOAD_ANSWERS_SUCCESS,
  LOAD_ANSWERS_FAILURE,
  INIT_LOAD_ANSWERS_ACTION_STATUS,

  // Load To-do Questionnaire
  START_LOAD_TODO_QUESTIONNAIRE,
  LOAD_TODO_QUESTIONNAIRE_SUCCESS,
  LOAD_TODO_QUESTIONNAIRE_FAILURE,
  INIT_LOAD_TODO_QUESTIONNAIRE_ACTION_STATUS,


  START_LOAD_MEETINGANDTRANINGSUB,
  LOAD_MEETINGANDTRANINGSUB_SUCCESS,
  LOAD_MEETINGANDTRANINGSUB_FAILURE,




    //licensing - Personal
  START_LOAD_TODO_LICENSINGPERSONAL,
  LOAD_TODO_LICENSINGPERSONAL_SUCCESS,
  LOAD_TODO_LICENSINGPERSONAL_FAILURE,
  INIT_LOAD_TODO_LICENSINGPERSONAL_ACTION_STATUS,

  START_SAVE_LICENSINGPERSONAL_ANSWER,
  SAVE_LICENSINGPERSONAL_ANSWER_SUCCESS,
  SAVE_LICENSINGPERSONAL_ANSWER_FAILURE,
  INIT_SAVE_LICENSINGPERSONAL_ANSWER_ACTION_STATUS,

  
  START_SUBMIT_LICENSINGPERSONAL_ANSWER,
  SUBMIT_LICENSINGPERSONAL_ANSWER_SUCCESS,
  SUBMIT_LICENSINGPERSONAL_ANSWER_FAILURE,
  INIT_SUBMIT_LICENSINGPERSONAL_ANSWER_ACTION_STATUS,


  // Submit Questionnaire Answer
  START_SUBMIT_QUESTIONNAIRE_ANSWER,
  SUBMIT_QUESTIONNAIRE_ANSWER_SUCCESS,
  SUBMIT_QUESTIONNAIRE_ANSWER_FAILURE,
  INIT_SUBMIT_QUESTIONNAIRE_ANSWER_ACTION_STATUS,

 

  START_SUBMIT_MEETINGANDTRANINGSUB_ANSWER,
  SUBMIT_MEETINGANDTRANINGSUB_ANSWER_SUCCESS,
  SUBMIT_MEETINGANDTRANINGSUB_ANSWER_FAILURE,
  INIT_SUBMIT_MEETINGANDTRANINGSUB_ANSWER_ACTION_STATUS,

  // Save Questionnaire Answer
  START_SAVE_QUESTIONNAIRE_ANSWER,
  SAVE_QUESTIONNAIRE_ANSWER_SUCCESS,
  SAVE_QUESTIONNAIRE_ANSWER_FAILURE,
  INIT_SAVE_QUESTIONNAIRE_ANSWER_ACTION_STATUS,

  // Questionnaire Answer Change Input
  QUESTIONNAIRE_ANSWER_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset Questionnaire Edit Modal Dialog
  QUESTIONNAIRE_EDIT_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_EDIT_MODAL_DIALOG_RESET,

  // Create Questionnaire
  START_CREATE_QUESTIONNAIRE,
  CREATE_QUESTIONNAIRE_SUCCESS,
  CREATE_QUESTIONNAIRE_FAILURE,
  INIT_CREATE_QUESTIONNAIRE_ACTION_STATUS,

  // Update Questionnaire
  START_UPDATE_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE_SUCCESS,
  UPDATE_QUESTIONNAIRE_FAILURE,
  INIT_UPDATE_QUESTIONNAIRE_ACTION_STATUS,

  // Copy Questionnaire
  START_COPY_QUESTIONNAIRE,
  COPY_QUESTIONNAIRE_SUCCESS,
  COPY_QUESTIONNAIRE_FAILURE,
  INIT_COPY_QUESTIONNAIRE_ACTION_STATUS,

  // Delete Questionnaire
  START_BATCH_DELETE_QUESTIONNAIRE,
  BATCH_DELETE_QUESTIONNAIRE_SUCCESS,
  BATCH_DELETE_QUESTIONNAIRE_FAILURE,
  INIT_BATCH_DELETE_QUESTIONNAIRE_ACTION_STATUS,

  // Questionnaire Edit Change Input
  QUESTIONNAIRE_EDIT_CHANGE_INPUT,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  QUESTION_EDIT_MODAL_DIALOG_OPEN,
  QUESTION_EDIT_MODAL_DIALOG_RESET,

  // Create Question
  START_CREATE_QUESTION,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  INIT_CREATE_QUESTION_ACTION_STATUS,

  // Update Question
  START_UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  INIT_UPDATE_QUESTION_ACTION_STATUS,

  // Delete Question
  START_BATCH_DELETE_QUESTION,
  BATCH_DELETE_QUESTION_SUCCESS,
  BATCH_DELETE_QUESTION_FAILURE,
  INIT_BATCH_DELETE_QUESTION_ACTION_STATUS,

  // Question Edit Change Input
  QUESTION_EDIT_CHANGE_INPUT,

  // Questionnaire Option Change
  QUESTIONNAIRE_OPTION_CHANGE,

  // Load Questionnaire Results
  START_LOAD_QUESTIONNAIRE_RESULTS,
  LOAD_QUESTIONNAIRE_RESULTS_SUCCESS,
  LOAD_QUESTIONNAIRE_RESULTS_FAILURE,
  INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS,

  // Select Questionnaire Results
  SELECT_QUESTIONNAIRE_RESULTS,

  // Load Questionnaire Result Detail
  START_LOAD_QUESTIONNAIRE_RESULT_DETAIL,
  LOAD_QUESTIONNAIRE_RESULT_DETAIL_SUCCESS,
  LOAD_QUESTIONNAIRE_RESULT_DETAIL_FAILURE,
  INIT_LOAD_QUESTIONNAIRE_RESULT_DETAIL_ACTION_STATUS,

  // Accept Questionnaire Result
  START_ACCEPT_QUESTIONNAIRE_RESULT,
  ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
  ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

  // Reject Questionnaire Result
  START_REJECT_QUESTIONNAIRE_RESULT,
  REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
  REJECT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

  // Open/Reset Questionnaire Result Operate Modal Dialog
  QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_RESET,

  // Questionnaire Answer Submitter Edit Change Input
  QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,
  MEETINGANDTRAINING_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT,

  // Send Remind Email
  START_SEND_REMIND_EMAIL,
  SEND_REMIND_EMAIL_SUCCESS,
  SEND_REMIND_EMAIL_FAILURE,
  INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
  QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,

  // Update Questionnaire Score
  START_UPDATE_QUESTIONNAIRE_SCORE,
  UPDATE_QUESTIONNAIRE_SCORE_SUCCESS,
  UPDATE_QUESTIONNAIRE_SCORE_FAILURE,
  INIT_UPDATE_QUESTIONNAIRE_SCORE_ACTION_STATUS,

  // Calculate Questionnaire Total Score
  CALC_QUESTIONNAIRE_TOTAL_SCORE,

  // Open/Reset Submitted Questionnaire History Modal Dialog
  SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN,
  SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET,

  // Load Submitted Questionnaire History
  START_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY,
  LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_SUCCESS,
  LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_FAILURE,
  INIT_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_ACTION_STATUS,

  // Select Submitted Questionnaire History
  SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY,

  // Apply Submitted Questionnaire History
  APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY,
  
  START_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY,
  LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_SUCCESS,
  LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_FAILURE,
  INIT_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_ACTION_STATUS,

} from './questionnaireConstants';

import {
  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,

} from '../../features/home/homeConstants';

import React from 'react';
import moment from 'moment';
import { Select } from 'antd';
import { createReducer } from '../../../utils/reducerUtil';
import GridColumnMap from './components/GridColumnMap.js';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import { QUESTION_TYPE_CODE } from '../../../utils/QuestionTypeUtil';
import QuestionnaireOpMode from '../../../utils/QuestionnaireOpMode';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../utils/QuestionnaireEmailSendMode';
import { QUESTIONNAIRE_TYPE_CODE } from '../../../utils/QuestionnaireType';

const Option = Select.Option;
const statusOptions = [];
statusOptions.push(<Option key={'Activated'}>{'Activated'}</Option>);
statusOptions.push(<Option key={'Invalid'}>{'Invalid'}</Option>);
statusOptions.push(<Option key={'Not Activated'}>{'Not Activated'}</Option>);

const statusAnswerOptions = [];
statusAnswerOptions.push(<Option key={'Submitted'}>{'Submitted'}</Option>);
statusAnswerOptions.push(<Option key={'New'}>{'New'}</Option>);
statusAnswerOptions.push(<Option key={'Accepted'}>{'Accepted'}</Option>);
statusAnswerOptions.push(<Option key={'Rejected'}>{'Rejected'}</Option>);
statusAnswerOptions.push(<Option key={'Unknown'}>{'Unknown'}</Option>);


const initialState = {
  // Grid Columns
  questionListGridColumns: GridColumnMap.questionListGridColumns,
  questionnaireGridColumns: GridColumnMap.questionnaireGridColumns,
  questionnaireResultGridColumns: GridColumnMap.questionnaireResultGridColumns,
  questionAnswerGridColumns: GridColumnMap.questionAnswerGridColumns,
  submittedHistoryGridColumns: GridColumnMap.submittedHistoryGridColumns,
  statusOptions: statusOptions,
  statusAnswerOptions: statusAnswerOptions,
  questionnaireQueryFields: {
    questionnaireVersion: '',
    nameOnQuestionnaire: '',
    status: ''
},
  // Questionnaire attachment
  questionnaireAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_QUESTIONNAIRE_ATTACHMENT,
  questionnaireAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_QUESTIONNAIRE_ATTACHMENT,

  // Questionnaires
  questionnaireArray: [],
  questionnaireOptions: [],
  parentQuestionOptions: [],
  loadQuestionnairesActionStatus: ACTION_STATUS.READY,
  answereeSelectControlModal: {
    isOpened: false,
    respErrMsg: '',
  },

  // Questions
  questionArray: [],
  questionnaireTitle: '',
  loadQuestionsActionStatus: ACTION_STATUS.READY,

  // Select Answerees
  selectAnswereesActionStatus: ACTION_STATUS.READY,

  // Answers
  answerArray: [],
  loadAnswersActionStatus: ACTION_STATUS.READY,

  // Load User Account
  userAccountArray: [],
  pmArray: [],
  pmGroupArray: [],
  userAccountOptions: [],

  // Select Questionnaires
  selectedQuestionnaires: [],

  // Select Questions
  selectedQuestions: [],

  // Load to-do Questionnaire
  loadTodoQuestionnaireActionStatus: ACTION_STATUS.READY,
  todoQuestionnaire: null,
  questionnaireTitle: '',
  todoQuestionArray: [],
  todoQuestionnaireAuthToken: '',
  questionnaireAnsweree: '',
  questionnaireAnswerDate: '',
  questionnaireSubmitter: '',
  questionnaireStatus: '',
  questionnaireStatusLabel: '',

  loadMeetingAndTraningSubActionStatus: ACTION_STATUS.READY,
  title: '',
  desc: '',
  comperename: '',
  starttime: '',
  id: '',
  meetingid: '',
  substate: '',
  authtoken: '',
  signin: '',
  participant: '',
  lastmodifieddate: '',

  loadTodoLicensingPersonalActionStatus: ACTION_STATUS.READY,
  todoLicensingPersonal: null,

  // Submit
  submitLicensingPersonalAnswerActionStatus: ACTION_STATUS.READY,
  submitLicensingPersonalAnswerErrMsg: '',

  // Save 
  saveLicensingPersonalAnswerActionStatus: ACTION_STATUS.READY,
  saveLicensingPersonalAnswerErrMsg: '',

  submitLicensingPersonalAnswerActionStatus: ACTION_STATUS.READY,
  submitLicensingPersonalAnswerErrMsg: '',


  // Submit Questionnaire Answer
  submitQuestionnaireAnswerActionStatus: ACTION_STATUS.READY,
  submitQuestionnaireAnswerErrMsg: '',

  submitMeetingAndTraningAnswerActionStatus: ACTION_STATUS.READY,
  submitMeetingAndTraningAnswerErrMsg: '',

  // Save Questionnaire Answer
  saveQuestionnaireAnswerActionStatus: ACTION_STATUS.READY,
  saveQuestionnaireAnswerErrMsg: '',



  // Questionnaire Edit Modal
  questionnaireEditControlModal: {
    isOpened: false,
    mode: QuestionnaireOpMode.NEW,
    respErrMsg: '',
    fields: {
      guid: '',
      name: '',
      title: '',
      isActive: true,
      expiredDate: moment().add(3, "M").format('YYYY-MM-DD'),
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
      type: QUESTIONNAIRE_TYPE_CODE.QUARTER,
    }
  },

  // create/update/copy/delete Questionnaire
  createQuestionnaireActionStatus: ACTION_STATUS.READY,
  updateQuestionnaireActionStatus: ACTION_STATUS.READY,
  copyQuestionnaireActionStatus: ACTION_STATUS.READY,
  batchDeleteQuestionnairesActionStatus: ACTION_STATUS.READY,

  // Question Edit Modal
  questionEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      guid: '',
      questionnaireGuid: '',
      questionNumber: '',
      subQuestionNumber: '',
      questionEnglish: '',
      questionChinese: '',
      options: '',
      questionType: '',
      parentGuid: '',
      triggerOption: '',
      correctAnswer: '',
      score: 0,
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
    }
  },

  // create/update/delete Question
  createQuestionActionStatus: ACTION_STATUS.READY,
  updateQuestionActionStatus: ACTION_STATUS.READY,
  batchDeleteQuestionsActionStatus: ACTION_STATUS.READY,
  
  // Load Questionnaire Results
  loadQuestionnaireResultsActionStatus: ACTION_STATUS.READY,
  questionnaireResultArray: [],

  // Select Questionnaire Results
  selectedQuestionnaireResults: [],

  // Load Questionnaire Result Detail
  loadQuestionnaireResultDetailActionStatus: ACTION_STATUS.READY,
  questionnaireResultDetail: null,
  questionAnswerList: [],


  // Accept Questionnaire Result
  acceptQuestionnaireResultActionStatus: ACTION_STATUS.READY,

  // Reject Questionnaire Result
  rejectQuestionnaireResultActionStatus: ACTION_STATUS.READY,

  // Open/Reset Questionnaire Result Operate Modal Dialog
  questionnaireResultOperateControlModal: {
    isOpened: false,
    opMode: '',
    questionnaireResultGuidList: [],
    respErrMsg: '',
  },

  // Send Remind Email
  sendRemindEmailActionStatus: ACTION_STATUS.READY,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  questionnaireEmailEditControlModal: {
    isOpened: false,
    sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
    emailPayload: null,
    respErrMsg: '',
  },

  // Update Questionnaire Score 
  updateQuestionnaireScoreActionStatus: ACTION_STATUS.READY,
  updateQuestionnaireScoreErrMsg: '',

  // Open/Reset Submitted Questionnaire History Modal Dialog
  submittedQuestionnaireHistoryControlModal: {
    isOpened: false,
    authToken: '',
    respErrMsg: '',
  },

  // Load Submitted Questionnaire History
  loadSubmittedQuestionnaireHistoryActionStatus: ACTION_STATUS.READY,
  submittedQuestionnaireHistoryArray: [],

  loadSubmittedLicensingPersonalHistoryActionStatus: ACTION_STATUS.READY,
  submittedLicensingPersonalHistoryArray: [],


  
  // Select Submitted Questionnaire History
  selectSubmittedQuestionnaireHistoryArray: [],
}

// =================================================================
// query
function onQueryInputChange(state, {name, value}) {
  const upatedFields = {
      ...state.questionnaireQueryFields,
      [name]: value,
  };
  return {
      ...state,
      questionnaireQueryFields: upatedFields,
  }
}


// Load Questionnaire
function startLoadQuestionnaires(state) {
  return {
    ...state,
    loadQuestionnairesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadQuestionnairesSuccess(state, resp) {
  const questionnaireArray = resp.data;
  const questionnaireOptions = questionnaireArray.map(questionnaire => <Option key={questionnaire.guid}>{questionnaire.name}</Option>);

  return {
    ...state,
    loadQuestionnairesActionStatus: ACTION_STATUS.SUCCESS,
    questionnaireArray,
    questionnaireOptions,
  };
}

function loadQuestionnairesFailure(state, err) {
  return {
    ...state,
    loadQuestionnairesActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadQuestionnairesActionStatus(state) {
  return {
    ...state,
    loadQuestionnairesActionStatus: ACTION_STATUS.READY,
  };
}

// Select Questionnaires
function selectQuestionnaires(state, payload) {
  const selectedQuestionnaires = payload;

  // 已经存在的answeree 默认勾选
  const pmGroupArray = state.pmGroupArray;
  const answerArray = state.answerArray;

  if (pmGroupArray.length > 0 && answerArray.length > 0 && selectedQuestionnaires.length > 0) {
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        const filterAnswereeArray = answerArray.filter(answer => answer.questionnaireGuid === selectedQuestionnaires[0].id).map(answer => answer.answeree);
        if (filterAnswereeArray.includes(userAccount.userAccountName)) {
          userAccount.checked = true;
        }
      })
    });
  }

  return {
    ...state,
    selectedQuestionnaires: payload
  };
}

// =================================================================
// Load Question
function startLoadQuestions(state) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadQuestionsSuccess(state, resp) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.SUCCESS,
    questionArray: resp.data.questionList,
    questionnaireTitle: resp.data.title,
  };
}

function loadQuestionsFailure(state, err) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadQuestionsActionStatus(state) {
  return {
    ...state,
    loadQuestionsActionStatus: ACTION_STATUS.READY,
  };
}

// Select Questions
function selectQuestions(state, payload) {
  return {
    ...state,
    selectedQuestions: payload
  };
}

// Open/Reset Answeree Select Modal Dialog
function openAnswereeSelectModalDialog(state) {
  return {
    ...state,
    answereeSelectControlModal: {
      ...state.answereeSelectControlModal,
      isOpened: true,
      fields: {
        ...state.answereeSelectControlModal.fields,
      }
    },
  };
}

function resetAnswereeSelectModalDialog(state) {
  const pmGroupArray = state.pmGroupArray;

  // 重置答题人勾选项
  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      userAccount.checked = false;
    })
  });

  return {
    ...state,
    answereeSelectControlModal: initialState.answereeSelectControlModal,
    pmGroupArray,
  };
}

// Select All Answeree
function selectAllAnsweree(state) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      userAccount.checked = true;
    })
  });

  return {
    ...state,
    pmGroupArray,
  };
}

// Select Invert Answeree
function selectInvertAnsweree(state) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      userAccount.checked = !userAccount.checked;
    })
  });

  return {
    ...state,
    pmGroupArray,
  };
}

// Answeree Check Changed
function answereeCheckChanged(state, {checked ,userAccountName}) {
  const pmGroupArray = state.pmGroupArray;

  pmGroupArray.forEach(pmArray => {
    pmArray.forEach(userAccount => {
      if (userAccount.userAccountName === userAccountName) {
        userAccount.checked = checked;
      }
    })
  });

  return {
    ...state,
  };
}

// Select Answerees
function startSelectAnswerees(state) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function selectAnswereesSuccess(state, resp) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function selectAnswereesFailure(state, err) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.ERROR,
    answereeSelectControlModal: {
      ...state.answereeSelectControlModal,
      respErrMsg: err.respMessage,
    },
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initSelectAnswereesActionStatus(state) {
  return {
    ...state,
    selectAnswereesActionStatus: ACTION_STATUS.READY,
  };
}

















// =================================================================
// Load Answers
function startLoadAnswers(state) {
  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadAnswersSuccess(state, resp) {
  const answerArray = resp.data;

  // 已经存在的answeree 默认勾选
  const pmGroupArray = state.pmGroupArray;
  const selectedQuestionnaires = state.selectedQuestionnaires;

  if (pmGroupArray.length > 0 && answerArray.length > 0 && selectedQuestionnaires.length > 0) {
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        const filterAnswereeArray = answerArray.filter(answer => answer.questionnaireGuid === selectedQuestionnaires[0].id).map(answer => answer.answeree);
        if (filterAnswereeArray.includes(userAccount.userAccountName)) {
          userAccount.checked = true;
        }
      })
    });
  }

  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.SUCCESS,
    answerArray,
  };
}

function loadAnswersFailure(state, err) {
  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.ERROR,
  };
}

 function initLoadAnswersActionStatus(state) {
  return {
    ...state,
    loadAnswersActionStatus: ACTION_STATUS.READY,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;

  let pmArray = [];
  let pmGroupArray = [];
  let rowSize = 4;
  if (userAccountArray.length > 0) {
    pmArray = userAccountArray
    .filter(userAccount => !!userAccount.isActive)
    .filter(userAccount =>  userAccount.firm.toUpperCase() === 'PINPOINT')
      .map(userAccount => Object.assign({}, userAccount, { checked: false }));

    const groupSize = Math.floor(pmArray.length / rowSize) + (pmArray.length % rowSize > 0 ? 1 : 0);
    for (let index = 0; index < groupSize; index++) {
      const array = pmArray.slice(index * rowSize, (index + 1) * rowSize);
      pmGroupArray.push(array);
    }
  }

  // 已经存在的answeree 默认勾选
  const answerArray = state.answerArray;
  const selectedQuestionnaires = state.selectedQuestionnaires;

  if (pmGroupArray.length > 0 && answerArray.length > 0 && selectedQuestionnaires.length > 0) {
    pmGroupArray.forEach(pmArray => {
      pmArray.forEach(userAccount => {
        const filterAnswereeArray = answerArray.filter(answer => answer.questionnaireGuid === selectedQuestionnaires[0].id).map(answer => answer.answeree);
        if (filterAnswereeArray.includes(userAccount.userAccountName)) {
          userAccount.checked = true;
        }
      })
    });
  }

  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.userAccountName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    pmArray,
    pmGroupArray,
    userAccountOptions,
  };
}

// Load To-do Questionnaire
function startLoadTodoQuestionnaire(state) {
  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadTodoQuestionnaireSuccess(state, resp) {
  const todoQuestionnaireAuthToken = resp.data.authToken;
  const todoQuestionnaire = resp.data.todoQuestionnaireDTO;
  const questionnaireAnsweree = resp.data.answeree;
  const questionnaireAnswerDate = resp.data.answerDate;
  const questionnaireSubmitter = resp.data.submitter;

  const questionnaireStatus = resp.data.status;
  const questionnaireStatusLabel = resp.data.statusLabel;

  const todoQuestionArray = todoQuestionnaire.todoQuestionDTOList;
  const questionnaireTitle = todoQuestionnaire.title;

  const questionnaireAttachmentDownloadUrl = state.questionnaireAttachmentDownloadUrl;
  todoQuestionArray.forEach(todoQuestion => {
    todoQuestion.fileList = [];
    if (todoQuestion.questionType === QUESTION_TYPE_CODE.UPLOAD && todoQuestion.answerContent !== null && todoQuestion.answerContent !== '') {
      const answerArray = todoQuestion.answerContent.split(',');
      todoQuestion.fileList = answerArray.map((answerFile, index) => {
        return {
          uid: index,
          name: answerFile,
          status: 'done',
          url: questionnaireAttachmentDownloadUrl + "/" + answerFile,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: answerFile,
            }
          }
        };
      });
    }

    todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => {
      subTodoQuestion.fileList = [];
      if (subTodoQuestion.questionType === QUESTION_TYPE_CODE.UPLOAD && subTodoQuestion.answerContent !== null && subTodoQuestion.answerContent !== '') {
        const answerArray = subTodoQuestion.answerContent.split(',');
        subTodoQuestion.fileList = answerArray.map((answerFile, index) => {
          return {
            uid: index,
            name: answerFile,
            status: 'done',
            url: questionnaireAttachmentDownloadUrl + "/" + answerFile,
            response: {
              respCode: "S0001",
              data: {
                fileNameWithTimeStamp: answerFile,
              }
            }
          };
        });
      }
    });
  });

  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.SUCCESS,
    todoQuestionnaire,
    todoQuestionArray,
    questionnaireTitle,
    todoQuestionnaireAuthToken,
    questionnaireAnsweree,
    questionnaireAnswerDate,
    questionnaireSubmitter,
    questionnaireStatus,
    questionnaireStatusLabel,
    submitQuestionnaireAnswerErrMsg: '',
    saveQuestionnaireAnswerErrMsg: '',
  };
}

function loadTodoQuestionnaireFailure(state, err) {
  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.ERROR,
    loadTodoQuestionnaireActionResp: err,
  };
}

function initLoadTodoQuestionnaireActionStatus(state) {
  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.READY,
  };
}

function startLoadMeetingAndTraningSub(state) {
  return {
    ...state,
    loadMeetingAndTraningSubActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadMeetingAndTraningSubSuccess(state, resp) {
  const title = resp.data.title;
  const desc = resp.data.desc;
  const comperename = resp.data.comperename;
  const starttime = resp.data.starttime;
  const id = resp.data.id;
  const meetingid = resp.data.meetingid;
  const substate = resp.data.state;
  const authtoken = resp.data.authtoken;
  const signin = resp.data.signin;
  const participant = resp.data.participant;
  const lastmodifieddate = resp.data.lastmodifieddate;

  let fundBookData = resp.data;

  let bookCodeArray = fundBookData.bookCodeList;

  let peopleOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);


  
  return {
    ...state,
    loadMeetingAndTraningSubActionStatus: ACTION_STATUS.SUCCESS,
    title,
    desc,
    comperename,
    starttime,
    id,
    meetingid,
    substate,
    authtoken,
    signin,
    participant,
    lastmodifieddate,
    peopleOptions,

  };
}

function loadMeetingAndTraningSubFailure(state, err) {
  return {
    ...state,
    loadMeetingAndTraningSubActionStatus: ACTION_STATUS.ERROR,
    loadTodoQuestionnaireActionResp: err,
  };
}





// Load To-do licensingPersonalInfo
function startLoadTodoLicensingPersonal(state) {
  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadTodoLicensingPersonalSuccess(state, resp) {
  const todoLicensingPersonalAuthToken = resp.data.authToken;
  const todoLicensingPersonal = resp.data.todoQuestionnaireDTO;
  const questionnaireAnsweree = resp.data.answeree;
  const questionnaireAnswerDate = resp.data.answerDate;
  const questionnaireSubmitter = resp.data.submitter;

  const questionnaireStatus = resp.data.status;
  const questionnaireStatusLabel = resp.data.statusLabel;

  const todoQuestionArray = todoLicensingPersonal.todoQuestionDTOList;
  const questionnaireTitle = todoLicensingPersonal.title;

  const questionnaireAttachmentDownloadUrl = state.questionnaireAttachmentDownloadUrl;
  todoQuestionArray.forEach(todoQuestion => {
    todoQuestion.fileList = [];
    if (todoQuestion.questionType === QUESTION_TYPE_CODE.UPLOAD && todoQuestion.answerContent !== null && todoQuestion.answerContent !== '') {
      const answerArray = todoQuestion.answerContent.split(',');
      todoQuestion.fileList = answerArray.map((answerFile, index) => {
        return {
          uid: index,
          name: answerFile,
          status: 'done',
          url: questionnaireAttachmentDownloadUrl + "/" + answerFile,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: answerFile,
            }
          }
        };
      });
    }

    todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => {
      subTodoQuestion.fileList = [];
      if (subTodoQuestion.questionType === QUESTION_TYPE_CODE.UPLOAD && subTodoQuestion.answerContent !== null && subTodoQuestion.answerContent !== '') {
        const answerArray = subTodoQuestion.answerContent.split(',');
        subTodoQuestion.fileList = answerArray.map((answerFile, index) => {
          return {
            uid: index,
            name: answerFile,
            status: 'done',
            url: questionnaireAttachmentDownloadUrl + "/" + answerFile,
            response: {
              respCode: "S0001",
              data: {
                fileNameWithTimeStamp: answerFile,
              }
            }
          };
        });
      }
    });
  });

  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.SUCCESS,
    todoLicensingPersonal,
    todoQuestionArray,
    questionnaireTitle,
    todoLicensingPersonalAuthToken,
    questionnaireAnsweree,
    questionnaireAnswerDate,
    questionnaireSubmitter,
    questionnaireStatus,
    questionnaireStatusLabel,
    submitQuestionnaireAnswerErrMsg: '',
    saveQuestionnaireAnswerErrMsg: '',
  };
}

function loadTodoLicensingPersonalFailure(state, err) {
  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.ERROR,
    loadTodoQuestionnaireActionResp: err,
  };
}

function initLoadTodoLicensingPersonalActionStatus(state) {
  return {
    ...state,
    loadTodoQuestionnaireActionStatus: ACTION_STATUS.READY,
  };
}


// Save Questionnaire Answer
function startSaveLicensingPersonalAnswer(state) {
  return {
    ...state,
    saveLicensingPersonalAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function saveLicensingPersonalAnswerSuccess(state, resp) {
  return {
    ...state,
    saveLicensingPersonalAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitLicensingPersonalAnswerErrMsg: '',
    saveLicensingPersonalAnswerErrMsg: '',
  };
}

function saveLicensingPersonalAnswerFailure(state, err) {
  return {
    ...state,
    saveLicensingPersonalAnswerActionStatus: ACTION_STATUS.ERROR,
    saveLicensingPersonalAnswerErrMsg: err.respMessage,
  };
}

function initSaveLicensingPersonalAnswerActionStatus(state) {
  return {
    ...state,
    saveLicensingPersonalAnswerActionStatus: ACTION_STATUS.READY,
  };
}

// Load History
function startLoadSubmittedLicensingPersonalHistory(state) {
  return {
    ...state,
    loadSubmittedLicensingPersonalHistoryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadSubmittedLicensingPersonalHistorySuccess(state, resp) {
  return {
    ...state,
    loadSubmittedLicensingPersonalHistoryActionStatus: ACTION_STATUS.SUCCESS,
    submittedLicensingPersonalHistoryArray: resp.data,
  };
}

function loadSubmittedLicensingPersonalHistoryFailure(state, err) {
  return {
    ...state,
    loadSubmittedLicensingPersonalHistoryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadSubmittedLicensingPersonalHistoryActionStatus(state) {
  return {
    ...state,
    loadSubmittedLicensingPersonalHistoryActionStatus: ACTION_STATUS.READY,
  };
}

// Submit  Answer
function startSubmitLicensingPersonalAnswer(state) {
  return {
    ...state,
    submitLicensingPersonalAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitLicensingPersonalAnswerSuccess(state, resp) {
  return {
    ...state,
    submitLicensingPersonalAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitLicensingPersonalAnswerErrMsg: '',
    saveLicensingPersonalAnswerErrMsg: '',
  };
}

function submitLicensingPersonalAnswerFailure(state, err) {
  return {
    ...state,
    submitLicensingPersonalAnswerActionStatus: ACTION_STATUS.ERROR,
    submitLicensingPersonalAnswerErrMsg: err.respMessage,
  };
}

function initSubmitLicensingPersonalAnswerActionStatus(state) {
  return {
    ...state,
    submitLicensingPersonalAnswerActionStatus: ACTION_STATUS.READY,
  };
}







// Submit Questionnaire Answer
function startSubmitQuestionnaireAnswer(state) {
  return {
    ...state,
    submitQuestionnaireAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitQuestionnaireAnswerSuccess(state, resp) {
  return {
    ...state,
    submitQuestionnaireAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitQuestionnaireAnswerErrMsg: '',
    saveQuestionnaireAnswerErrMsg: '',
  };
}

function submitQuestionnaireAnswerFailure(state, err) {
  return {
    ...state,
    submitQuestionnaireAnswerActionStatus: ACTION_STATUS.ERROR,
    submitQuestionnaireAnswerErrMsg: err.respMessage,
  };
}

function initSubmitQuestionnaireAnswerActionStatus(state) {
  return {
    ...state,
    submitQuestionnaireAnswerActionStatus: ACTION_STATUS.READY,
  };
}



// Submit Questionnaire Answer
function startSubmitMeetingAndTraningAnswer(state) {
  return {
    ...state,
    submitMeetingAndTraningAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitMeetingAndTraningAnswerSuccess(state, resp) {
  return {
    ...state,
    submitMeetingAndTraningAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitMeetingAndTraningAnswerErrMsg: '',
    saveMeetingAndTraningAnswerErrMsg: '',
  };
}

function submitMeetingAndTraningAnswerFailure(state, err) {
  return {
    ...state,
    submitMeetingAndTraningAnswerActionStatus: ACTION_STATUS.ERROR,
    submitMeetingAndTraningAnswerErrMsg: err.respMessage,
  };
}

function initSubmitMeetingAndTraningAnswerActionStatus(state) {
  return {
    ...state,
    submitMeetingAndTraningAnswerActionStatus: ACTION_STATUS.READY,
  };
}








// Save Questionnaire Answer
function startSaveQuestionnaireAnswer(state) {
  return {
    ...state,
    saveQuestionnaireAnswerActionStatus: ACTION_STATUS.LOGINING,
  };
}

function saveQuestionnaireAnswerSuccess(state, resp) {
  return {
    ...state,
    saveQuestionnaireAnswerActionStatus: ACTION_STATUS.SUCCESS,
    submitQuestionnaireAnswerErrMsg: '',
    saveQuestionnaireAnswerErrMsg: '',
  };
}

function saveQuestionnaireAnswerFailure(state, err) {
  return {
    ...state,
    saveQuestionnaireAnswerActionStatus: ACTION_STATUS.ERROR,
    saveQuestionnaireAnswerErrMsg: err.respMessage,
  };
}

function initSaveQuestionnaireAnswerActionStatus(state) {
  return {
    ...state,
    saveQuestionnaireAnswerActionStatus: ACTION_STATUS.READY,
  };
}

// Questionnaire Answer Change Input
function questionnaireAnswerChangeInput(state, { answerGuid, answerContent, fileList }) {
  const todoQuestionArray = state.todoQuestionArray;

  todoQuestionArray.forEach(todoQuestion => { 
    if (todoQuestion.answerGuid === answerGuid) {
      todoQuestion.answerContent = answerContent;
      todoQuestion.fileList = fileList;

      // radio
      if (todoQuestion.questionType === '1') {
        todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => { 
          subTodoQuestion.answerContent = '';
          subTodoQuestion.fileList = '';
        });
      }
    } else {
      todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => { 
        if (subTodoQuestion.answerGuid === answerGuid) {
          subTodoQuestion.answerContent = answerContent;
          subTodoQuestion.fileList = fileList;
        }
      });
    }
  });

  return {
    ...state,
    todoQuestionArray,
  };
}

// ================================================================================
// Open/Reset Questionnaire Edit Modal Dialog
function openQuestionnaireEditModalDialog(state, {mode, questionnaire}) {
  if (mode === QuestionnaireOpMode.NEW) {
    return {
      ...state,
      questionnaireEditControlModal: {
        ...state.questionnaireEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.questionnaireEditControlModal.fields,
        }
      },
    };
  } 
  
  if (mode === QuestionnaireOpMode.MODIFY) {
    const questionnaireAttachmentDownloadUrl = state.questionnaireAttachmentDownloadUrl;

    const fileList = [];
    const attachment = questionnaire.attachment;
    const attachmentOriginName = questionnaire.attachmentOriginName;

    if (!!attachment && !!attachmentOriginName) {
      const attachmentArray = attachment.split(',');
      const attachmentOriginNameArray = attachmentOriginName.split(',');
  
      let fileIndex = 0;
      attachmentArray.forEach(questionnaireAttachment => {
        let lastUnderlineIndex = questionnaireAttachment.lastIndexOf('_');
        const questionnaireAttachmentPart1 = questionnaireAttachment.substring(0, lastUnderlineIndex);
        const questionnaireAttachmentPart2 = questionnaireAttachment.substring(lastUnderlineIndex + 1);
        let dotIndex = questionnaireAttachmentPart2.indexOf('.');
        const questionnaireAttachmentExtName = questionnaireAttachmentPart2.substring(dotIndex);
        const assembleFileName = questionnaireAttachmentPart1 + questionnaireAttachmentExtName;
  
        const filterOriginNameArray = attachmentOriginNameArray.filter(attachmentOriginName => attachmentOriginName === assembleFileName);
        const questionnaireOriginAttachmentName = filterOriginNameArray[0];
  
        fileIndex++;
        const file = {
          uid: fileIndex,
          name: questionnaireOriginAttachmentName,
          status: 'done',
          url: questionnaireAttachmentDownloadUrl + "/" + questionnaireAttachment,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: questionnaireAttachment,
              originalFileName: questionnaireOriginAttachmentName,
            }
          }
        };
        fileList.push(file);
      });
    }

    return {
      ...state,
      questionnaireEditControlModal: {
        ...state.questionnaireEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.questionnaireEditControlModal.fields,
          guid: questionnaire.guid,
          name: questionnaire.name,
          title: questionnaire.title,
          isActive: questionnaire.isActive,
          expiredDate: questionnaire.expiredDate,
          attachment: questionnaire.attachment,
          attachmentOriginName: questionnaire.attachmentOriginName,
          fileList,
          type: questionnaire.type,
        }
      },
    };
  }

  if (mode === QuestionnaireOpMode.COPY) {
    const questionnaireAttachmentDownloadUrl = state.questionnaireAttachmentDownloadUrl;

    const fileList = [];
    const attachment = questionnaire.attachment;
    const attachmentOriginName = questionnaire.attachmentOriginName;

    if (!!attachment && !!attachmentOriginName) {
      const attachmentArray = attachment.split(',');
      const attachmentOriginNameArray = attachmentOriginName.split(',');
  
      let fileIndex = 0;
      attachmentArray.forEach(questionnaireAttachment => {
        let lastUnderlineIndex = questionnaireAttachment.lastIndexOf('_');
        const questionnaireAttachmentPart1 = questionnaireAttachment.substring(0, lastUnderlineIndex);
        const questionnaireAttachmentPart2 = questionnaireAttachment.substring(lastUnderlineIndex + 1);
        let dotIndex = questionnaireAttachmentPart2.indexOf('.');
        const questionnaireAttachmentExtName = questionnaireAttachmentPart2.substring(dotIndex);
        const assembleFileName = questionnaireAttachmentPart1 + questionnaireAttachmentExtName;
  
        const filterOriginNameArray = attachmentOriginNameArray.filter(attachmentOriginName => attachmentOriginName === assembleFileName);
        const questionnaireOriginAttachmentName = filterOriginNameArray[0];
  
        fileIndex++;
        const file = {
          uid: fileIndex,
          name: questionnaireOriginAttachmentName,
          status: 'done',
          url: questionnaireAttachmentDownloadUrl + "/" + questionnaireAttachment,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: questionnaireAttachment,
              originalFileName: questionnaireOriginAttachmentName,
            }
          }
        };
        fileList.push(file);
      });
    }

    return {
      ...state,
      questionnaireEditControlModal: {
        ...state.questionnaireEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.questionnaireEditControlModal.fields,
          guid: questionnaire.guid,
          name: questionnaire.name,
          title: questionnaire.title,
          isActive: questionnaire.isActive,
          expiredDate: moment().add(3, "M").format('YYYY-MM-DD'),
          attachment: questionnaire.attachment,
          attachmentOriginName: questionnaire.attachmentOriginName,
          fileList,
          type: questionnaire.type,
        }
      },
    };
  }
}

function resetQuestionnaireEditModalDialog(state) {
  return {
    ...state,
    questionnaireEditControlModal: initialState.questionnaireEditControlModal,
  };
}

// Create Questionnaire
function startCreateQuestionnaire(state) {
  return {
    ...state,
    createQuestionnaireActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createQuestionnaireSuccess(state, resp) {
  return {
    ...state,
    createQuestionnaireActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createQuestionnaireFailure(state, err) {
  return {
    ...state,
    createQuestionnaireActionStatus: ACTION_STATUS.ERROR,
    questionnaireEditControlModal: {
      ...state.questionnaireEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initCreateQuestionnaireActionStatus(state) {
  return {
    ...state,
    createQuestionnaireActionStatus: ACTION_STATUS.READY,
  };
}

// Update Questionnaire
function startUpdateQuestionnaire(state) {
  return {
    ...state,
    updateQuestionnaireActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateQuestionnaireSuccess(state, resp) {
  return {
    ...state,
    updateQuestionnaireActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateQuestionnaireFailure(state, err) {
  return {
    ...state,
    updateQuestionnaireActionStatus: ACTION_STATUS.ERROR,
    questionnaireEditControlModal: {
      ...state.questionnaireEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initUpdateQuestionnaireActionStatus(state) {
  return {
    ...state,
    updateQuestionnaireActionStatus: ACTION_STATUS.READY,
  };
}

// Copy Questionnaire
function startCopyQuestionnaire(state) {
  return {
    ...state,
    copyQuestionnaireActionStatus: ACTION_STATUS.LOGINING,
  };
}

function copyQuestionnaireSuccess(state, resp) {
  return {
    ...state,
    copyQuestionnaireActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function copyQuestionnaireFailure(state, err) {
  return {
    ...state,
    copyQuestionnaireActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCopyQuestionnaireActionStatus(state) {
  return {
    ...state,
    copyQuestionnaireActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Questionnaire
function startBatchDeleteQuestionnaire(state) {
  return {
    ...state,
    batchDeleteQuestionnairesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteQuestionnaireSuccess(state, resp) {
  return {
    ...state,
    batchDeleteQuestionnairesActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteQuestionnaireFailure(state, err) {
  return {
    ...state,
    batchDeleteQuestionnairesActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteQuestionnaireActionStatus(state) {
  return {
    ...state,
    batchDeleteQuestionnairesActionStatus: ACTION_STATUS.READY,
  };
}

// Questionnaire Edit Change Input
export function questionnaireEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.questionnaireEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    questionnaireEditControlModal: {
      ...state.questionnaireEditControlModal,
      fields: updatedFields,
    },
  };
}

// ================================================================================
// Open/Reset Question Edit Modal Dialog
function openQuestionEditModalDialog(state, {isAddMode, question}) {
  if (isAddMode) {
    return {
      ...state,
      questionEditControlModal: {
        ...state.questionEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.questionEditControlModal.fields,
        }
      },
    };
  } else {
    const questionnaireGuid = question.questionnaireGuid;

    const questionnaireArray = state.questionnaireArray;
    let parentQuestionOptions = [];

    const filterQuestionnaireArray = questionnaireArray.filter(questionnaire => questionnaire.guid === questionnaireGuid);
    if (filterQuestionnaireArray.length > 0) {
      const questionnaire = filterQuestionnaireArray[0];
      const questionArray = questionnaire.questionList.filter(question => question.parentGuid === null || question.parentGuid === '');
      parentQuestionOptions = questionArray.map(question => <Option key={question.guid}>{question.questionNumber}</Option>);
    }

    // 处理附件列表
    const questionnaireAttachmentDownloadUrl = state.questionnaireAttachmentDownloadUrl;

    const fileList = [];
    const attachment = question.attachment;
    const attachmentOriginName = question.attachmentOriginName;

    if (!!attachment && !!attachmentOriginName) {
      const attachmentArray = attachment.split(',');
      const attachmentOriginNameArray = attachmentOriginName.split(',');
  
      for(let i in attachmentArray) {
        const file = {
          uid: (i+1),
          name: attachmentOriginNameArray[i],
          status: 'done',
          url: questionnaireAttachmentDownloadUrl + "/" + attachmentArray[i],
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: attachmentArray[i],
              originalFileName: attachmentOriginNameArray[i],
            }
          }
        };
        fileList.push(file);
      }

    }

    return {
      ...state,
      parentQuestionOptions,
      questionEditControlModal: {
        ...state.questionEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.questionEditControlModal.fields,
          guid: question.guid,
          questionnaireGuid,
          questionNumber: question.questionNumber,
          subQuestionNumber: question.subQuestionNumber,
          questionEnglish: question.questionEnglish,
          questionChinese: question.questionChinese,
          options: question.options,
          questionType: question.questionType,
          parentGuid: question.parentGuid,
          triggerOption: question.triggerOption,
          correctAnswer: question.correctAnswer,
          score: question.score,
          attachment: question.attachment,
          attachmentOriginName: question.attachmentOriginName,
          fileList,
        }
      },
    };
  }
}

function resetQuestionEditModalDialog(state) {
  return {
    ...state,
    questionEditControlModal: initialState.questionEditControlModal,
  };
}

// Create Question
function startCreateQuestion(state) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createQuestionSuccess(state, resp) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createQuestionFailure(state, err) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.ERROR,
    questionEditControlModal: {
      ...state.questionEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initCreateQuetsionActionStatus(state) {
  return {
    ...state,
    createQuestionActionStatus: ACTION_STATUS.READY,
  };
}

// Update Question
function startUpdateQuestion(state) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateQuestionSuccess(state, resp) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateQuestionFailure(state, err) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.ERROR,
    questionEditControlModal: {
      ...state.questionEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initUpdateQuestionActionStatus(state) {
  return {
    ...state,
    updateQuestionActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Question
function startBatchDeleteQuestion(state) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteQuestionSuccess(state, resp) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteQuestionFailure(state, err) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initbatchDeleteQuestionsActionStatus(state) {
  return {
    ...state,
    batchDeleteQuestionsActionStatus: ACTION_STATUS.READY,
  };
}

// Question Edit Change Input
function questionEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.questionEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    questionEditControlModal: {
      ...state.questionEditControlModal,
      fields: updatedFields,
    },
  };
}

// Questionnaire Option Change
function questionnaireOptionChange(state, { questionnaireGuid }) {
  const questionnaireArray = state.questionnaireArray;
  let parentQuestionOptions = [];


  const filterQuestionnaireArray = questionnaireArray.filter(questionnaire => questionnaire.guid === questionnaireGuid);
  if (filterQuestionnaireArray.length > 0) {
    const questionnaire = filterQuestionnaireArray[0];
    const questionArray = questionnaire.questionList.filter(question => question.parentGuid === null || question.parentGuid === '');
    parentQuestionOptions = questionArray.map(question => <Option key={question.guid}>{question.questionNumber}</Option>);
  }

  return {
    ...state,
    parentQuestionOptions,
  };
}


// Load Questionnaire Results
function startLoadQuestionnaireResults(state) {
  return {
    ...state,
    loadQuestionnaireResultsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadQuestionnaireResultsSuccess(state, resp) {
  return {
    ...state,
    loadQuestionnaireResultsActionStatus: ACTION_STATUS.SUCCESS,
    questionnaireResultArray: resp.data,
  };
}

function loadQuestionnaireResultsFailure(state, err) {
  return {
    ...state,
    loadQuestionnaireResultsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadQuestionnaireResultsActionStatus(state) {
  return {
    ...state,
    loadQuestionnaireResultsActionStatus: ACTION_STATUS.READY,
    selectedQuestionnaireResults: [],
  };
}

// Select Questionnaire Results
function selectQuestionnaireResults(state, payload) {
  return {
    ...state,
    selectedQuestionnaireResults: payload,
  };
}

// Load Questionnaire Result Detail
function startLoadQuestionnaireResultDetail(state) {
  return {
    ...state,
    loadQuestionnaireResultDetailActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadQuestionnaireResultDetailSuccess(state, resp) {
  const questionAnswerList = resp.data.questionAnswerList;
  const questionnaireAttachmentDownloadUrl = state.questionnaireAttachmentDownloadUrl;

  questionAnswerList.forEach(questionAnswer => {
    questionAnswer.fileList = [];
    if (questionAnswer.questionType === QUESTION_TYPE_CODE.UPLOAD && questionAnswer.answerContent !== null && questionAnswer.answerContent !== '') {
      const answerArray = questionAnswer.answerContent.split(',');
      questionAnswer.fileList = answerArray.map((answerFile, index) => {

        return {
          uid: index,
          name: answerFile,
          status: 'done',
          url: questionnaireAttachmentDownloadUrl + "/" + answerFile,
          response: {
            respCode: "S0001",
            data: {
              fileNameWithTimeStamp: answerFile,
            }
          }
        };
      });
    }

    if (questionAnswer.subQuestionAnswerList === null) {
      questionAnswer.subQuestionAnswerList = [];
    }

    questionAnswer.subQuestionAnswerList.forEach(subQuestionAnswer => {
      subQuestionAnswer.fileList = [];
      if (subQuestionAnswer.questionType === QUESTION_TYPE_CODE.UPLOAD && subQuestionAnswer.answerContent !== null && subQuestionAnswer.answerContent !== '') {
        const answerArray = subQuestionAnswer.answerContent.split(',');

        subQuestionAnswer.fileList = answerArray.map((answerFile, index) => {
          return {
            uid: index,
            name: answerFile,
            status: 'done',
            url: questionnaireAttachmentDownloadUrl + "/" + answerFile,
            response: {
              respCode: "S0001",
              data: {
                fileNameWithTimeStamp: answerFile,
              }
            }
          };
        });
      }
    });
  });

  return {
    ...state,
    loadQuestionnaireResultDetailActionStatus: ACTION_STATUS.SUCCESS,
    questionnaireResultDetail: resp.data,
    questionAnswerList,
  };
}

function loadQuestionnaireResultDetailFailure(state, err) {
  return {
    ...state,
    loadQuestionnaireResultDetailActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadQuestionnaireResultDetailActionStatus(state) {
  return {
    ...state,
    loadQuestionnaireResultDetailActionStatus: ACTION_STATUS.READY,
  };
}

// Accept Questionnaire Result
function startAcceptQuestionnaireResult(state) {
  return {
    ...state,
    acceptQuestionnaireResultActionStatus: ACTION_STATUS.LOGINING,
  };
}

function acceptQuestionnaireResultSuccess(state, resp) {
  return {
    ...state,
    acceptQuestionnaireResultActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function acceptQuestionnaireResultFailure(state, err) {
  return {
    ...state,
    acceptQuestionnaireResultActionStatus: ACTION_STATUS.ERROR,
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initAcceptQuestionnaireResultActionStatus(state) {
  return {
    ...state,
    acceptQuestionnaireResultActionStatus: ACTION_STATUS.READY,
  };
}

// Reject Questionnaire Result
function startRejectQuestionnaireResult(state) {
  return {
    ...state,
    rejectQuestionnaireResultActionStatus: ACTION_STATUS.LOGINING,
  };
}

function rejectQuestionnaireResultSuccess(state, resp) {
  return {
    ...state,
    rejectQuestionnaireResultActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function rejectQuestionnaireResultFailure(state, err) {
  return {
    ...state,
    rejectQuestionnaireResultActionStatus: ACTION_STATUS.ERROR,
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initRejectQuestionnaireResultActionStatus(state) {
  return {
    ...state,
    rejectQuestionnaireResultActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Questionnaire Result Operate Modal Dialog
function openQuestionnaireResultOperateModalDialog(state, { opMode, questionnaireResultGuidList }) {
  return {
    ...state,
    questionnaireResultOperateControlModal: {
      ...state.questionnaireResultOperateControlModal,
      isOpened: true,
      opMode,
      questionnaireResultGuidList,
    },
  };
}

function resetQuestionnaireResultOperateModalDialog(state) {
  return {
    ...state,
    questionnaireResultOperateControlModal: initialState.questionnaireResultOperateControlModal,
  };
}

// Questionnaire Answer Submitter Edit Change Input
function questionnaireAnswerSubmitterEditChangeInput(state, value) {
  return {
    ...state,
    questionnaireSubmitter: value,
  };
}



function meetingAndTrainingAnswerSubmitterEditChangeInput(state, value) {
  return {
    ...state,
    signin: value,
  };
}

// Send Remind Email
function startSendRemindEmail(state) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.LOGINING,
  };
}

function sendRemindEmailSuccess(state, resp) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function sendRemindEmailFailure(state, err) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.ERROR,
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initSendRemindEmailActionStatus(state) {
  return {
    ...state,
    sendRemindEmailActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
function openQuestionnaireEmailEditModalDialog(state, {sendMode, emailPayload}) {
  return {
    ...state,
    questionnaireEmailEditControlModal: {
      ...state.questionnaireEmailEditControlModal,
      isOpened: true,
      sendMode,
      emailPayload,
    },
  };
}

function resetQuestionnaireEmailEditModalDialog(state) {
  return {
    ...state,
    questionnaireEmailEditControlModal: initialState.questionnaireEmailEditControlModal,
  };
}

// Update Questionnaire Score
function startUpdateQuestionnaireScore(state) {
  return {
    ...state,
    updateQuestionnaireScoreActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateQuestionnaireScoreSuccess(state, resp) {
  return {
    ...state,
    updateQuestionnaireScoreActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateQuestionnaireScoreFailure(state, err) {
  return {
    ...state,
    updateQuestionnaireScoreActionStatus: ACTION_STATUS.ERROR,
    updateQuestionnaireScoreErrMsg: err.respMessage,
  };
}

function initUpdateQuestionnaireScoreActionStatus(state) {
  return {
    ...state,
    updateQuestionnaireScoreActionStatus: ACTION_STATUS.READY,
  };
}

// Calculate Questionnaire Total Score
function calculateQuestionnaireTotalScore(state) {
  const questionnaireResultArray = state.questionnaireResultArray;

  questionnaireResultArray.forEach(questionnaireResult => {
    const questionAnswerList = questionnaireResult.questionAnswerList;

    const existNaNScoreList = questionAnswerList.filter(questionAnswer => 
      questionAnswer.score === null || Number.isNaN(Number(questionAnswer.score)) || !Number.isInteger(Number(questionAnswer.score)));
    if (existNaNScoreList.length > 0) {
      return;
    }

    let totalScore = questionAnswerList.map(questionAnswer => questionAnswer.score).reduce((total, score) => Number.parseInt(total) + Number.parseInt(score));

    questionnaireResult.totalScore = totalScore;
  });

  return {
    ...state,
    questionnaireResultArray,
  };
}

// Open/Reset Submitted Questionnaire History Modal Dialog
function openSubmittedQuestionnaireHistoryModalDialog(state, { authToken }) {
  return {
    ...state,
    submittedQuestionnaireHistoryControlModal: {
      ...state.submittedQuestionnaireHistoryControlModal,
      isOpened: true,
      authToken,
    },
  };
}

function resetSubmittedQuestionnaireHistoryModalDialog(state) {
  return {
    ...state,
    submittedQuestionnaireHistoryControlModal: initialState.submittedQuestionnaireHistoryControlModal,
  };
}

// Load Submitted Questionnaire History
function startLoadSubmittedQuestionnaireHistory(state) {
  return {
    ...state,
    loadSubmittedQuestionnaireHistoryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadSubmittedQuestionnaireHistorySuccess(state, resp) {
  return {
    ...state,
    loadSubmittedQuestionnaireHistoryActionStatus: ACTION_STATUS.SUCCESS,
    submittedQuestionnaireHistoryArray: resp.data,
  };
}

function loadSubmittedQuestionnaireHistoryFailure(state, err) {
  return {
    ...state,
    loadSubmittedQuestionnaireHistoryActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadSubmittedQuestionnaireHistoryActionStatus(state) {
  return {
    ...state,
    loadSubmittedQuestionnaireHistoryActionStatus: ACTION_STATUS.READY,
  };
}

// Select Submitted Questionnaire History
function selectSubmittedQuestionnaireHistory(state, payload) {
  return {
    ...state,
    selectSubmittedQuestionnaireHistoryArray: payload,
  };
}

// Apply Submitted Questionnaire History
function applySubmittedQuestionnaireHistory(state, { historyQuestionnaire }) {
  const todoQuestionArray = state.todoQuestionArray;

  if (!!historyQuestionnaire || !!historyQuestionnaire.historyQuestionAnswerList || 
    historyQuestionnaire.historyQuestionAnswerList.length !== 0) {

    todoQuestionArray.forEach(todoQuestion => {

      const filterHistoryQuestionList = historyQuestionnaire.historyQuestionAnswerList.filter(questionAnswer => {
        return todoQuestion.questionType !== '5' && // 不包括上传题
          questionAnswer.questionNumber === todoQuestion.questionNumber &&
          questionAnswer.subQuestionNumber === todoQuestion.subQuestionNumber &&
          questionAnswer.questionEnglish === todoQuestion.questionEnglish &&
          questionAnswer.questionChinese === todoQuestion.questionChinese &&
          questionAnswer.questionType === todoQuestion.questionType &&
          questionAnswer.score === todoQuestion.score &&
          (questionAnswer.correctAnswer === null ? '' : questionAnswer.correctAnswer) === (todoQuestion.correctAnswer === null ? '' : todoQuestion.correctAnswer) &&
          (questionAnswer.options === null ? '' : questionAnswer.options) === (todoQuestion.optionsContent === null ? '' : todoQuestion.optionsContent) &&
          (questionAnswer.triggerOption === null ? '' : questionAnswer.triggerOption) === (todoQuestion.triggerOption === null ? '' : todoQuestion.triggerOption);
      });

      if (filterHistoryQuestionList.length > 0) {
        const historyQuestionAnswer = filterHistoryQuestionList[0];
        todoQuestion.answerContent = historyQuestionAnswer.answerContent;
      }

      // 子问题
      todoQuestion.subTodoQuestionList.forEach(subTodoQuestion => {
        const filterHistoryQuestionList = historyQuestionnaire.historyQuestionAnswerList.filter(questionAnswer => {
          return subTodoQuestion.questionType !== '5' && // 不包括上传题
            questionAnswer.questionNumber === subTodoQuestion.questionNumber &&
            questionAnswer.subQuestionNumber === subTodoQuestion.subQuestionNumber &&
            questionAnswer.questionEnglish === subTodoQuestion.questionEnglish &&
            questionAnswer.questionChinese === subTodoQuestion.questionChinese &&
            questionAnswer.questionType === subTodoQuestion.questionType &&
            questionAnswer.score === subTodoQuestion.score &&
            (questionAnswer.correctAnswer === null ? '' : questionAnswer.correctAnswer) === (subTodoQuestion.correctAnswer === null ? '' : subTodoQuestion.correctAnswer) &&
            (questionAnswer.options === null ? '' : questionAnswer.options) === (subTodoQuestion.optionsContent === null ? '' : subTodoQuestion.optionsContent) &&
            (questionAnswer.triggerOption === null ? '' : questionAnswer.triggerOption) === (subTodoQuestion.triggerOption === null ? '' : subTodoQuestion.triggerOption);
        });
  
        if (filterHistoryQuestionList.length > 0) {
          const historyQuestionAnswer = filterHistoryQuestionList[0];
          subTodoQuestion.answerContent = historyQuestionAnswer.answerContent;
        }
      });
    });
  }

  return {
    ...state,
    todoQuestionArray,
  };
}


export default createReducer(initialState, {
  // query
  [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
  // Load Questionnaire
  [START_LOAD_QUESTIONNAIRES]: startLoadQuestionnaires,
  [LOAD_QUESTIONNAIRES_SUCCESS]: loadQuestionnairesSuccess,
  [LOAD_QUESTIONNAIRES_FAILURE]: loadQuestionnairesFailure,
  [INIT_LOAD_QUESTIONNAIRES_ACTION_STATUS]: initLoadQuestionnairesActionStatus,

  // Select Questionnaires
  [SELECT_QUESTIONNAIRES]: selectQuestionnaires,

  // Load Question
  [START_LOAD_QUESTIONS]: startLoadQuestions,
  [LOAD_QUESTIONS_SUCCESS]: loadQuestionsSuccess,
  [LOAD_QUESTIONS_FAILURE]: loadQuestionsFailure,
  [INIT_LOAD_QUESTIONS_ACTION_STATUS]: initLoadQuestionsActionStatus,

  // Select Questions
  [SELECT_QUESTIONS]: selectQuestions,

  // Open/Reset Answeree Select Modal Dialog
  [ANSWEREE_SELECT_MODAL_DIALOG_OPEN]: openAnswereeSelectModalDialog,
  [ANSWEREE_SELECT_MODAL_DIALOG_RESET]: resetAnswereeSelectModalDialog,

  // Select All Answeree
  [SELECT_ALL_ANSWEREE]: selectAllAnsweree,

  // Select Invert Answeree
  [SELECT_INVERT_ANSWEREE]: selectInvertAnsweree,

  // Answeree Check Changed
  [ANSWEREE_CHECK_CHANGED]: answereeCheckChanged,

  // Select Answerees
  [START_SELECT_ANSWEREES]: startSelectAnswerees,
  [SELECT_ANSWEREES_SUCCESS]: selectAnswereesSuccess,
  [SELECT_ANSWEREES_FAILURE]: selectAnswereesFailure,
  [INIT_SELECT_ANSWEREES_ACTION_STATUS]: initSelectAnswereesActionStatus,


  // Load Answers
  [START_LOAD_ANSWERS]: startLoadAnswers,
  [LOAD_ANSWERS_SUCCESS]: loadAnswersSuccess,
  [LOAD_ANSWERS_FAILURE]: loadAnswersFailure,
  [INIT_LOAD_ANSWERS_ACTION_STATUS]: initLoadAnswersActionStatus,


  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  // Load To-do Questionnaire
  [START_LOAD_TODO_QUESTIONNAIRE]: startLoadTodoQuestionnaire,
  [LOAD_TODO_QUESTIONNAIRE_SUCCESS]: loadTodoQuestionnaireSuccess,
  [LOAD_TODO_QUESTIONNAIRE_FAILURE]: loadTodoQuestionnaireFailure,
  [INIT_LOAD_TODO_QUESTIONNAIRE_ACTION_STATUS]: initLoadTodoQuestionnaireActionStatus,


  [START_LOAD_MEETINGANDTRANINGSUB]: startLoadMeetingAndTraningSub,
  [LOAD_MEETINGANDTRANINGSUB_SUCCESS]: loadMeetingAndTraningSubSuccess,
  [LOAD_MEETINGANDTRANINGSUB_FAILURE]: loadMeetingAndTraningSubFailure,


    // Load To-do Questionnaire startLoadTodoLicensingPersonal
    [START_LOAD_TODO_LICENSINGPERSONAL]: startLoadTodoLicensingPersonal,
    [LOAD_TODO_LICENSINGPERSONAL_SUCCESS]: loadTodoLicensingPersonalSuccess,
    [LOAD_TODO_LICENSINGPERSONAL_FAILURE]: loadTodoLicensingPersonalFailure,
    [INIT_LOAD_TODO_LICENSINGPERSONAL_ACTION_STATUS]: initLoadTodoLicensingPersonalActionStatus,

    [START_SAVE_LICENSINGPERSONAL_ANSWER]: startSaveLicensingPersonalAnswer,
    [SAVE_LICENSINGPERSONAL_ANSWER_SUCCESS]: saveLicensingPersonalAnswerSuccess,
    [SAVE_LICENSINGPERSONAL_ANSWER_FAILURE]: saveLicensingPersonalAnswerFailure,
    [INIT_SAVE_LICENSINGPERSONAL_ANSWER_ACTION_STATUS]: initSaveLicensingPersonalAnswerActionStatus,


    [START_SUBMIT_LICENSINGPERSONAL_ANSWER]: startSubmitLicensingPersonalAnswer,
    [SUBMIT_LICENSINGPERSONAL_ANSWER_SUCCESS]: submitLicensingPersonalAnswerSuccess,
    [SUBMIT_LICENSINGPERSONAL_ANSWER_FAILURE]: submitLicensingPersonalAnswerFailure,
    [INIT_SUBMIT_LICENSINGPERSONAL_ANSWER_ACTION_STATUS]: initSubmitLicensingPersonalAnswerActionStatus,


  // Submit Questionnaire Answer
  [START_SUBMIT_QUESTIONNAIRE_ANSWER]: startSubmitQuestionnaireAnswer,
  [SUBMIT_QUESTIONNAIRE_ANSWER_SUCCESS]: submitQuestionnaireAnswerSuccess,
  [SUBMIT_QUESTIONNAIRE_ANSWER_FAILURE]: submitQuestionnaireAnswerFailure,
  [INIT_SUBMIT_QUESTIONNAIRE_ANSWER_ACTION_STATUS]: initSubmitQuestionnaireAnswerActionStatus,


  
  [START_SUBMIT_MEETINGANDTRANINGSUB_ANSWER]: startSubmitMeetingAndTraningAnswer,
  [SUBMIT_MEETINGANDTRANINGSUB_ANSWER_SUCCESS]: submitMeetingAndTraningAnswerSuccess,
  [SUBMIT_MEETINGANDTRANINGSUB_ANSWER_FAILURE]: submitMeetingAndTraningAnswerFailure,
  [INIT_SUBMIT_MEETINGANDTRANINGSUB_ANSWER_ACTION_STATUS]: initSubmitMeetingAndTraningAnswerActionStatus,


  // Save Questionnaire Answer
  [START_SAVE_QUESTIONNAIRE_ANSWER]: startSaveQuestionnaireAnswer,
  [SAVE_QUESTIONNAIRE_ANSWER_SUCCESS]: saveQuestionnaireAnswerSuccess,
  [SAVE_QUESTIONNAIRE_ANSWER_FAILURE]: saveQuestionnaireAnswerFailure,
  [INIT_SAVE_QUESTIONNAIRE_ANSWER_ACTION_STATUS]: initSaveQuestionnaireAnswerActionStatus,

  // Questionnaire Answer Change Input
  [QUESTIONNAIRE_ANSWER_CHANGE_INPUT]: questionnaireAnswerChangeInput,

  // ================================================================================
  // Open/Reset Questionnaire Edit Modal Dialog
  [QUESTIONNAIRE_EDIT_MODAL_DIALOG_OPEN]: openQuestionnaireEditModalDialog,
  [QUESTIONNAIRE_EDIT_MODAL_DIALOG_RESET]: resetQuestionnaireEditModalDialog,

  // Create Questionnaire
  [START_CREATE_QUESTIONNAIRE]: startCreateQuestionnaire,
  [CREATE_QUESTIONNAIRE_SUCCESS]: createQuestionnaireSuccess,
  [CREATE_QUESTIONNAIRE_FAILURE]: createQuestionnaireFailure,
  [INIT_CREATE_QUESTIONNAIRE_ACTION_STATUS]: initCreateQuestionnaireActionStatus,

  // Update Questionnaire
  [START_UPDATE_QUESTIONNAIRE]: startUpdateQuestionnaire,
  [UPDATE_QUESTIONNAIRE_SUCCESS]: updateQuestionnaireSuccess,
  [UPDATE_QUESTIONNAIRE_FAILURE]: updateQuestionnaireFailure,
  [INIT_UPDATE_QUESTIONNAIRE_ACTION_STATUS]: initUpdateQuestionnaireActionStatus,

  // Copy Questionnaire
  [START_COPY_QUESTIONNAIRE]: startCopyQuestionnaire,
  [COPY_QUESTIONNAIRE_SUCCESS]: copyQuestionnaireSuccess,
  [COPY_QUESTIONNAIRE_FAILURE]: copyQuestionnaireFailure,
  [INIT_COPY_QUESTIONNAIRE_ACTION_STATUS]: initCopyQuestionnaireActionStatus,

  // Delete Questionnaire
  [START_BATCH_DELETE_QUESTIONNAIRE]: startBatchDeleteQuestionnaire,
  [BATCH_DELETE_QUESTIONNAIRE_SUCCESS]: batchDeleteQuestionnaireSuccess,
  [BATCH_DELETE_QUESTIONNAIRE_FAILURE]: batchDeleteQuestionnaireFailure,
  [INIT_BATCH_DELETE_QUESTIONNAIRE_ACTION_STATUS]: initBatchDeleteQuestionnaireActionStatus,

  // Questionnaire Answer Change Input
  [QUESTIONNAIRE_EDIT_CHANGE_INPUT]: questionnaireEditChangeInput,

  // ================================================================================
  // Open/Reset Question Edit Modal Dialog
  [QUESTION_EDIT_MODAL_DIALOG_OPEN]: openQuestionEditModalDialog,
  [QUESTION_EDIT_MODAL_DIALOG_RESET]: resetQuestionEditModalDialog,

  // Create Question
  [START_CREATE_QUESTION]: startCreateQuestion,
  [CREATE_QUESTION_SUCCESS]: createQuestionSuccess,
  [CREATE_QUESTION_FAILURE]: createQuestionFailure,
  [INIT_CREATE_QUESTION_ACTION_STATUS]: initCreateQuetsionActionStatus,

  // Update Question
  [START_UPDATE_QUESTION]: startUpdateQuestion,
  [UPDATE_QUESTION_SUCCESS]: updateQuestionSuccess,
  [UPDATE_QUESTION_FAILURE]: updateQuestionFailure,
  [INIT_UPDATE_QUESTION_ACTION_STATUS]: initUpdateQuestionActionStatus,

  // Delete Question
  [START_BATCH_DELETE_QUESTION]: startBatchDeleteQuestion,
  [BATCH_DELETE_QUESTION_SUCCESS]: batchDeleteQuestionSuccess,
  [BATCH_DELETE_QUESTION_FAILURE]: batchDeleteQuestionFailure,
  [INIT_BATCH_DELETE_QUESTION_ACTION_STATUS]: initbatchDeleteQuestionsActionStatus,

  // Question Answer Change Input
  [QUESTION_EDIT_CHANGE_INPUT]: questionEditChangeInput,

  // Questionnaire Option Change
  [QUESTIONNAIRE_OPTION_CHANGE]: questionnaireOptionChange,

  // Load Questionnaire Results
  [START_LOAD_QUESTIONNAIRE_RESULTS]: startLoadQuestionnaireResults,
  [LOAD_QUESTIONNAIRE_RESULTS_SUCCESS]: loadQuestionnaireResultsSuccess,
  [LOAD_QUESTIONNAIRE_RESULTS_FAILURE]: loadQuestionnaireResultsFailure,
  [INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS]: initLoadQuestionnaireResultsActionStatus,

  // Select Questionnaire Results
  [SELECT_QUESTIONNAIRE_RESULTS]: selectQuestionnaireResults,

  // Load Questionnaire Result Detail
  [START_LOAD_QUESTIONNAIRE_RESULT_DETAIL]: startLoadQuestionnaireResultDetail,
  [LOAD_QUESTIONNAIRE_RESULT_DETAIL_SUCCESS]: loadQuestionnaireResultDetailSuccess,
  [LOAD_QUESTIONNAIRE_RESULT_DETAIL_FAILURE]: loadQuestionnaireResultDetailFailure,
  [INIT_LOAD_QUESTIONNAIRE_RESULT_DETAIL_ACTION_STATUS]: initLoadQuestionnaireResultDetailActionStatus,

  // Accept Questionnaire Result
  [START_ACCEPT_QUESTIONNAIRE_RESULT]: startAcceptQuestionnaireResult,
  [ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS]: acceptQuestionnaireResultSuccess,
  [ACCEPT_QUESTIONNAIRE_RESULT_FAILURE]: acceptQuestionnaireResultFailure,
  [INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS]: initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  [START_REJECT_QUESTIONNAIRE_RESULT]: startRejectQuestionnaireResult,
  [REJECT_QUESTIONNAIRE_RESULT_SUCCESS]: rejectQuestionnaireResultSuccess,
  [REJECT_QUESTIONNAIRE_RESULT_FAILURE]: rejectQuestionnaireResultFailure,
  [INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS]: initRejectQuestionnaireResultActionStatus,

  // Open/Reset Questionnaire Result Operate Modal Dialog
  [QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_OPEN]: openQuestionnaireResultOperateModalDialog,
  [QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_RESET]: resetQuestionnaireResultOperateModalDialog,

  // Questionnaire Answer Submitter Edit Change Input
  [QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT]: questionnaireAnswerSubmitterEditChangeInput,
  [MEETINGANDTRAINING_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT]: meetingAndTrainingAnswerSubmitterEditChangeInput,
  // Send Remind Email
  [START_SEND_REMIND_EMAIL]: startSendRemindEmail,
  [SEND_REMIND_EMAIL_SUCCESS]: sendRemindEmailSuccess,
  [SEND_REMIND_EMAIL_FAILURE]: sendRemindEmailFailure,
  [INIT_SEND_REMIND_EMAIL_ACTION_STATUS]: initSendRemindEmailActionStatus,

  // Open/Reset Questionnaire Email Edit Modal Dialog
  [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN]: openQuestionnaireEmailEditModalDialog,
  [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET]: resetQuestionnaireEmailEditModalDialog,

  // Update Questionnaire Score
  [START_UPDATE_QUESTIONNAIRE_SCORE]: startUpdateQuestionnaireScore,
  [UPDATE_QUESTIONNAIRE_SCORE_SUCCESS]: updateQuestionnaireScoreSuccess,
  [UPDATE_QUESTIONNAIRE_SCORE_FAILURE]: updateQuestionnaireScoreFailure,
  [INIT_UPDATE_QUESTIONNAIRE_SCORE_ACTION_STATUS]: initUpdateQuestionnaireScoreActionStatus,

  // Calculate Questionnaire Total Score
  [CALC_QUESTIONNAIRE_TOTAL_SCORE]: calculateQuestionnaireTotalScore,

  // Open/Reset Submitted Questionnaire History Modal Dialog
  [SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN]: openSubmittedQuestionnaireHistoryModalDialog,
  [SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET]: resetSubmittedQuestionnaireHistoryModalDialog,

  // Load Submitted Questionnaire History
  [START_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY]: startLoadSubmittedQuestionnaireHistory,
  [LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_SUCCESS]: loadSubmittedQuestionnaireHistorySuccess,
  [LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_FAILURE]: loadSubmittedQuestionnaireHistoryFailure,
  [INIT_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_ACTION_STATUS]: initLoadSubmittedQuestionnaireHistoryActionStatus,

  // Select Submitted Questionnaire History
  [SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY]: selectSubmittedQuestionnaireHistory,

  // Apply Submitted Questionnaire History
  [APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY]: applySubmittedQuestionnaireHistory,
  

  [START_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY]: startLoadSubmittedLicensingPersonalHistory,
  [LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_SUCCESS]: loadSubmittedLicensingPersonalHistorySuccess,
  [LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_FAILURE]: loadSubmittedLicensingPersonalHistoryFailure,
  [INIT_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_ACTION_STATUS]: initLoadSubmittedLicensingPersonalHistoryActionStatus,



});