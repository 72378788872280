import React, {Component} from 'react';
import './GroupStructureDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import GroupStructureGrid from './GroupStructureGrid';
import GroupStructureDetailDialog from './GroupStructureDetailDialog';
import AddOrEditGroupStructureModalDialog from './AddOrEditGroupStructureModalDialog';
import DelGroupStructureModalDialog from './DelGroupStructureModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import HistoryUploadDialog from '../../../common/historyUpload/components/HistoryUploadDialog';


class GroupStructureDashboard extends Component {

    componentDidMount() {
        this.props.queryGroupStructure();
    }


    componentDidUpdate() {
        const {
            queryGroupStructureStatus,
            queryGroupStructureResp,
        } = this.props;
        if(queryGroupStructureStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryGroupStructureActionStatus();
        }
        if(queryGroupStructureStatus === ACTION_STATUS.ERROR) {
            let err = 'Query Group Structure failed';
            if(!!queryGroupStructureResp && !!queryGroupStructureResp.respMessage) {
                err = queryGroupStructureResp.respMessage;
            }
            message.error(err);
            this.props.initQueryGroupStructureActionStatus();
        }

    }


    refreshBtnOnClicked = () => {
        this.props.queryGroupStructure();
    }


    addOrEditGroupStructureBtnOnClicked = (opType) => {
        // ADD
        if(opType === 'ADD') {
            this.props.openAddOrEditGroupStructureModal(opType, null);
            return;
        }

        // EDIT
        const selectedGroupStructureArray = this.props.selectedGroupStructureArray;
        if(!selectedGroupStructureArray || selectedGroupStructureArray.length !== 1) {
            message.warn("Please select one and only one Group Structure");
            return;
        }
        this.props.openAddOrEditGroupStructureModal(opType, selectedGroupStructureArray[0]);
        return;

    }


    delGroupStructureBtnOnClicked = () => {
        const {
            selectedGroupStructureArray,
        } = this.props;
        if(selectedGroupStructureArray.length !== 1) {
            message.warn("Please select one and only one Group Structure");
            return;
        }
        this.props.openDelGroupStructureModal(selectedGroupStructureArray[0]);
    }


    doHistoryUploadAction = () => {
        const {
            selectedGroupStructureArray
        } = this.props;
        if (selectedGroupStructureArray.length !== 1) {
            message.warn("Please select one and only one Group Structure");
          return;
        }
        const dataId = selectedGroupStructureArray[0].id;
        this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', 
                                            queryFields: {module: 'GroupStructure', dataId: dataId}, 
                                            baseDownloadUrl: this.props.groupStructureDownloadUrl })
    }



    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];

    render() {

        const {
            queryGroupStructureStatus,
            queryGroupStructureResp,
        } = this.props;

        const refreshBtnIsLoading = queryGroupStructureStatus === ACTION_STATUS.LOGINING;

        return (
            <div className='GroupStructureWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Pinpoint Group</Breadcrumb.Item>
                        <Breadcrumb.Item>Group Structure</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditGroupStructureBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditGroupStructureBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.delGroupStructureBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'GroupStructure'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button>
                    <Button 
                        onClick={()=>this.doHistoryUploadAction()} 
                        style={{ marginLeft: 10 }}
                        size='small'
                        type='primary'>
                        History Upload
                    </Button>

                </div>

                <div className='GroupStructureGrid'><GroupStructureGrid {...this.props} /></div>
                <GroupStructureDetailDialog {...this.props} />
                <AddOrEditGroupStructureModalDialog {...this.props} />
                <DelGroupStructureModalDialog {...this.props} />

                <OperationRecordDialog {...this.props} />
                <HistoryUploadDialog {...this.props} />

            </div>
        );
    }

}

export default GroupStructureDashboard;
