/**
 * 角色规划<br>
 * 
 * 角色分类：<br>
 * CMS_ADMIN_* -- 可以访问ADMIN前端的角色<br>
 * CMS_REPORT_* -- 可以访问REPORT端的角色<br>
 * 
 * 两个要做特殊处理的角色：<br>
 * CMS_ADMIN_USER -- 可以访问所有后端API，可以访问所有ADMIN前端的功能<br>
 * CMS_REPORT_USER -- 默认所有用户都有此角色<br>
 * 
 */
export const CMS_ADMIN_ROLE_PRIFIX = "CMS_ADMIN_";
export const CMS_REPORT_ROLE_PRIFIX = "CMS_REPORT_";
export const CMS_ADMIN_USER = "CMS_ADMIN_USER";
export const CMS_REPORT_USER = "CMS_REPORT_USER";
