import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_MEETING_AND_TRAINING_INFO,
    LOAD_MEETING_AND_TRAINING_INFO_APPLYRECORD_SUCCESS,
    LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
    INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    // select
    SELECT_MEETING_AND_TRAINING_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_ADD_OR_EDIT_MEETING_AND_TRAINING,
    ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
    ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
    INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_MEETING_AND_TRAINING_MODAL,
    DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_DEL_MEETING_AND_TRAINING,
    DEL_MEETING_AND_TRAINING_SUCCESS,
    DEL_MEETING_AND_TRAINING_FAILURE,
    INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_DEL_MEETING_AND_TRAINING_MODAL,

    // COP
    OPEN_COP_MEETING_AND_TRAINING_MODAL,
    COP_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_COP_MEETING_AND_TRAINING,
    COP_MEETING_AND_TRAINING_SUCCESS,
    COP_MEETING_AND_TRAINING_FAILURE,
    INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_COP_MEETING_AND_TRAINING_MODAL,


    ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    ANSWEREE_SELECT_MODAL_DIALOG_RESET,

      // Select All Answeree
    SELECT_ALL_ANSWEREE,

    // Select Invert Answeree
    SELECT_INVERT_ANSWEREE,
      // Answeree Check Changed
    ANSWEREE_CHECK_CHANGED,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,


    // Select Answerees
    START_SELECT_ANSWEREES,
    SELECT_ANSWEREES_SUCCESS,
    SELECT_ANSWEREES_FAILURE,
    INIT_SELECT_ANSWEREES_ACTION_STATUS,
    SHOW_DETAIL_RCD,
    RESET_DETAIL_RCD,


} from './MeetingAndTrainingApplyRecordConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadMeetingAndTrainingApplyRecordInfo() {
    return {
      type: START_LOAD_MEETING_AND_TRAINING_INFO,
    };
}
export function loadMeetingAndTrainingApplyRecordInfo(params) {
    return function(dispatch) {
        dispatch(startLoadMeetingAndTrainingApplyRecordInfo());
        httpClient
          .get(UrlConfig.LOAD_MEETING_AND_TRAINING_APPLYRECORD_INFO, params)
          .then(resp => dispatch(loadMeetingAndTrainingApplyRecordInfoSuccess(resp)))
          .catch(err => dispatch(loadMeetingAndTrainingApplyRecordInfoFailure(err)));
    };
}
function loadMeetingAndTrainingApplyRecordInfoSuccess(resp) {
    return {
      type: LOAD_MEETING_AND_TRAINING_INFO_APPLYRECORD_SUCCESS,
      payload: resp,
    };
}
function loadMeetingAndTrainingApplyRecordInfoFailure(err) {
    return {
        type: LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadMeetingAndTrainingApplyRecordInfoActionStatus() {
    return {
      type: INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    };
}

// select
export function selectMeetingAndTrainingApplyRecordInfo(array) {
    return {
        type: SELECT_MEETING_AND_TRAINING_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditMeetingAndTrainingApplyRecordModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditMeetingAndTrainingApplyRecordChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditMeetingAndTrainingApplyRecord() {
    return {
        type: START_ADD_OR_EDIT_MEETING_AND_TRAINING,
    };
}
export function addOrEditMeetingAndTrainingApplyRecord(params) {
      return function(dispatch) {
        dispatch(startAddOrEditMeetingAndTrainingApplyRecord());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_MEETING_AND_TRAINING_APPLYRECORD, params)
          .then(resp => dispatch(addOrEditMeetingAndTrainingApplyRecordSuccess(resp)))
          .catch(err => dispatch(addOrEditMeetingAndTrainingApplyRecordFailure(err)));
      };
}
function addOrEditMeetingAndTrainingApplyRecordSuccess(resp) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
        payload: resp,
    };
}
function addOrEditMeetingAndTrainingApplyRecordFailure(err) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
        payload: err,
    };
}
export function initAddOrEditMeetingAndTrainingApplyRecordActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
    };
}
export function resetAddOrEditMeetingAndTrainingApplyRecordModal() {
    return {
        type: RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelMeetingAndTrainingApplyRecordModal(info,mode) {
    return {
        type: OPEN_DEL_MEETING_AND_TRAINING_MODAL,
        payload: 
        {
            info,
            mode,
        },
    };  
}
export function delMeetingAndTrainingApplyRecordChangeInput({name, value}) {
    return {
        type: DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelMeetingAndTrainingApplyRecord() {
    return {
        type: START_DEL_MEETING_AND_TRAINING,
    };
}
export function delMeetingAndTrainingApplyRecord(params) {
      return function(dispatch) {
        dispatch(startDelMeetingAndTrainingApplyRecord());
        httpClient
          .post(UrlConfig.UPDATE_MEETING_AND_TRAINING_APPLYRECORD, params)
          .then(resp => dispatch(delMeetingAndTrainingApplyRecordSuccess(resp)))
          .catch(err => dispatch(delMeetingAndTrainingApplyRecordFailure(err)));
      };
}
function delMeetingAndTrainingApplyRecordSuccess(resp) {
    return {
        type: DEL_MEETING_AND_TRAINING_SUCCESS,
        payload: resp,
    };
}
function delMeetingAndTrainingApplyRecordFailure(err) {
    return {
        type: DEL_MEETING_AND_TRAINING_FAILURE,
        payload: err,
    };
}
export function initDelMeetingAndTrainingApplyRecordActionStatus() {
    return {
        type: INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
    };
}
export function resetDelMeetingAndTrainingApplyRecordModal() {
    return {
        type: RESET_DEL_MEETING_AND_TRAINING_MODAL,
    };
}












//cop
export function openCopMeetingAndTrainingApplyRecordModal(info) {
    return {
        type: OPEN_COP_MEETING_AND_TRAINING_MODAL,
        payload: info,
    };  
}
export function copMeetingAndTrainingApplyRecordChangeInput({name, value}) {
    return {
        type: COP_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startCopMeetingAndTrainingApplyRecord() {
    return {
        type: START_COP_MEETING_AND_TRAINING,
    };
}
export function copMeetingAndTrainingApplyRecord(params) {
      return function(dispatch) {
        dispatch(startCopMeetingAndTrainingApplyRecord());
        httpClient
          .get(UrlConfig.COP_ALL_MEETING_AND_TRAINING, params)
          .then(resp => dispatch(copMeetingAndTrainingApplyRecordSuccess(resp)))
          .catch(err => dispatch(copMeetingAndTrainingApplyRecordFailure(err)));
      };
}
function copMeetingAndTrainingApplyRecordSuccess(resp) {
    return {
        type: COP_MEETING_AND_TRAINING_SUCCESS,
        payload: resp,
    };
}
function copMeetingAndTrainingApplyRecordFailure(err) {
    return {
        type: COP_MEETING_AND_TRAINING_FAILURE,
        payload: err,
    };
}
export function initCopMeetingAndTrainingApplyRecordActionStatus() {
    return {
        type: INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
    };
}
export function resetCopMeetingAndTrainingApplyRecordModal() {
    return {
        type: RESET_COP_MEETING_AND_TRAINING_MODAL,
    };
}
export function openAnswereeSelectModalDialog() {
    return {
      type: ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
      payload: {
        
      },
    };
}
export function resetAnswereeSelectModalDialog() {
    return {
      type: ANSWEREE_SELECT_MODAL_DIALOG_RESET
    };
  }








// Select All Answeree
export function selectAllAnsweree() {
    return {
      type: SELECT_ALL_ANSWEREE
    };
  }
  
  // Select Invert Answeree
  export function selectInvertAnsweree() {
    return {
      type: SELECT_INVERT_ANSWEREE
    };
  }

  // Answeree Check Changed
export function answereeCheckChanged(checked ,userAccountName) {
    return {
      type: ANSWEREE_CHECK_CHANGED,
      payload: {
        checked,
        userAccountName
      }
    };
  }

  // Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
      payload: {
        sendMode,
        emailPayload,
      },
    };
  }
  
  export function resetQuestionnaireEmailEditModalDialog() {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET
    };
  }

// Select Answerees
function startSelectAnswerees() {
    return {
      type: START_SELECT_ANSWEREES
    };
  }
  export function selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent) {
    const requestParams = {
      userAccountNameList,
      questionnaireGuid, 
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startSelectAnswerees());
      httpClient
        .post(UrlConfig.SELECT_MEETINGANSWEREES, requestParams)
        .then(resp => dispatch(selectAnswereesSuccess(resp)))
        .catch(err => dispatch(selectAnswereesFailure(err)));
    };
  }
  
  function selectAnswereesSuccess(resp) {
    return {
      type: SELECT_ANSWEREES_SUCCESS,
      payload: resp
    };
  }
  
  function selectAnswereesFailure(err) {
    return {
      type: SELECT_ANSWEREES_FAILURE,
      payload: err
    };
  }


export function initSelectAnswereesActionStatus() {
    return {
      type: INIT_SELECT_ANSWEREES_ACTION_STATUS
    };
  }

  export function doOperationRecordAction(params) {
    const {action, queryFields, customColumns, detail} = params;
     if(action === 'SHOW_DETAIL') {
        return showDetailRcd(detail);
    } else if(action === 'RESET_DETAIL') {
        return resetDetailRcd();
    }
}
// detail page
function showDetailRcd(detail) {
    return {
        type: SHOW_DETAIL_RCD,
        payload: detail,
    }
}
function resetDetailRcd() {
    return {
        type: RESET_DETAIL_RCD,
    }
}
