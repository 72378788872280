import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import JsonTooltipComponent from '../../../../../utils/JsonTooltipComponent';
import StateSynchronizer from '../../../../../utils/StateSynchronizer';
class UserAccessGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [{
                field: 'id',
                headerName: "ID",
                cellClass: 'non-number',
                width: 80,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
            {
                field: 'cpty',
                headerName: "Cpty*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'functiontype',
                headerName: "Function*",
                cellClass: 'non-number',
                width: 120,
            },
            {
                field: 'fundandcompany',
                headerName: "Fund/Co.*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'employee',
                headerName: "User*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'usertype',
                headerName: "User Type*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'remarks',
                headerName: "Remarks",
                cellClass: 'non-number',
                width: 200,
                cellRenderer: 'jsonTooltipComponent'
            },
            {
                field: 'status',
                headerName: "Status",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'lastupdatetime',
                headerName: "Last Modified date",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'validetodate',
                headerName: "Valid to",
                cellClass: 'number',
                width: 100,
            },
            {
                field: 'filepath',
                headerName: "User Access Form",
                cellClass: 'non-number',
                width: 300,
                cellRenderer: this.sendHistoryAttachementCellRender
            },
        ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                // tagComponent: TagComponent
              },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.userAccessDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let userAccessInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectUserAccessInfo(userAccessInfoArray);
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'user-access-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };
    render() {

        const {
            // query
            loadUserAccessInfoActionStatus,
            loadUserAccessInfoResp,
        } = this.props;


        const isLoading = loadUserAccessInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadUserAccessInfoArray = [];
        if(!!loadUserAccessInfoResp && !!loadUserAccessInfoResp.data) {
            loadUserAccessInfoArray = loadUserAccessInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadUserAccessInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // event
                            onSelectionChanged={this.onDataSelectionChanged}
                            resizable={true}
                            sideBar={true}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                        />
                    )}
            </div>
        );
    }
}

export default UserAccessGrid;
