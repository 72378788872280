import {connect} from 'react-redux';
import UserAccessDashboard from '../components/UserAccessDashboard';


import {
    // query
    onQueryInputChange,
    loadUserAccessInfo,
    initLoadUserAccessInfoActionStatus,
    // select
    selectUserAccessInfo,
    // add or edit
    openAddOrEditUserAccessModal,
    addOrEditUserAccessChangeInput,
    addOrEditUserAccess,
    initAddOrEditUserAccessActionStatus,
    resetAddOrEditUserAccessModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelUserAccessModal,
    delUserAccessChangeInput,
    delUserAccess,
    initDelUserAccessActionStatus,
    resetDelUserAccessModal,

    //cop
    openCopUserAccessModal,
    copUserAccessChangeInput,
    copUserAccess,
    initCopUserAccessActionStatus,
    resetCopUserAccessModal,

    openRestrictedRuleFundBookDialog,
    closeRestrictedRuleFundBookDialog,
    restrictedRuleEditChangeInput,

    
    
} from '../UserAccessActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.userAccess.statusOptions,
        fundFirmRelatedOptions: state.userAccess.fundFirmRelatedOptions,
        ccyOptions: state.userAccess.ccyOptions,
        userAccountOptions: state.userAccess.userAccountOptions,
        // userAccessQueryFields
        userAccessQueryFields: state.userAccess.userAccessQueryFields,
        // query
        loadUserAccessInfoActionStatus: state.userAccess.loadUserAccessInfoActionStatus,
        loadUserAccessInfoResp: state.userAccess.loadUserAccessInfoResp,
        selectedUserAccessInfoArray: state.userAccess.selectedUserAccessInfoArray,
        userAccessGridColumns: state.userAccess.userAccessGridColumns,
        // add or edit
        addOrEditUserAccessModal: state.userAccess.addOrEditUserAccessModal,
        addOrEditUserAccessStatus: state.userAccess.addOrEditUserAccessStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.userAccess.refreshFxStatus,
        refreshFxResp: state.userAccess.refreshFxResp,
        // del
        delUserAccessModal: state.userAccess.delUserAccessModal,
        delUserAccessStatus: state.userAccess.delUserAccessStatus,
        // cop
        copUserAccessModal: state.userAccess.copUserAccessModal,
        copUserAccessStatus: state.userAccess.copUserAccessStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        userAccessUploadUrl: state.userAccess.userAccessUploadUrl,
        userAccessDownloadUrl: state.userAccess.userAccessDownloadUrl,
        restrictedRuleEditControlModal: state.userAccess.restrictedRuleEditControlModal,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadUserAccessInfo: (params) => dispatch(loadUserAccessInfo(params)),
        initLoadUserAccessInfoActionStatus: () => dispatch(initLoadUserAccessInfoActionStatus()),
        // select
        selectUserAccessInfo: (dataArray) => dispatch(selectUserAccessInfo(dataArray)),
        // add or edit
        openAddOrEditUserAccessModal: (mode, data) => dispatch(openAddOrEditUserAccessModal(mode, data)),
        addOrEditUserAccessChangeInput: (params) => dispatch(addOrEditUserAccessChangeInput(params)),
        addOrEditUserAccess: (info) => dispatch(addOrEditUserAccess(info)),
        initAddOrEditUserAccessActionStatus: () => dispatch(initAddOrEditUserAccessActionStatus()),
        resetAddOrEditUserAccessModal: () => dispatch(resetAddOrEditUserAccessModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelUserAccessModal: (info,mode,idList) => dispatch(openDelUserAccessModal(info,mode,idList)),
        delUserAccessChangeInput: (params) => dispatch(delUserAccessChangeInput(params)),
        delUserAccess: (info) => dispatch(delUserAccess(info)),
        initDelUserAccessActionStatus: () => dispatch(initDelUserAccessActionStatus()),
        resetDelUserAccessModal: () => dispatch(resetDelUserAccessModal()),

        // cop
        openCopUserAccessModal: (info) => dispatch(openCopUserAccessModal(info)),
        copUserAccessChangeInput: (params) => dispatch(copUserAccessChangeInput(params)),
        copUserAccess: (params) => dispatch(copUserAccess(params)),
        initCopUserAccessActionStatus: () => dispatch(initCopUserAccessActionStatus()),
        resetCopUserAccessModal: () => dispatch(resetCopUserAccessModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


        openRestrictedRuleFundBookDialog: () => dispatch(openRestrictedRuleFundBookDialog()),
        closeRestrictedRuleFundBookDialog: () => dispatch(closeRestrictedRuleFundBookDialog()),
        restrictedRuleEditChangeInput: (params) => dispatch(restrictedRuleEditChangeInput(params)),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(UserAccessDashboard);