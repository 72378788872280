import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Generate Fund Level Report
  START_GENERATE_FUND_LEVEL_REPORT,
  GENERATE_FUND_LEVEL_REPORT_SUCCESS,
  GENERATE_FUND_LEVEL_REPORT_FAILURE,
  INIT_GENERATE_FUND_LEVEL_REPORT_ACTION_STATUS,

  // Load Fund Level Report
  START_LOAD_FUND_LEVEL_REPORT,
  LOAD_FUND_LEVEL_REPORT_SUCCESS,
  LOAD_FUND_LEVEL_REPORT_FAILURE,
  INIT_LOAD_FUND_LEVEL_REPORT_ACTION_STATUS,

  // Load Fund Level Report
  START_LIST_FUND_POS_GROUP_INFO,
  LIST_FUND_POS_GROUP_INFO_SUCCESS,
  LIST_FUND_POS_GROUP_INFO_FAILURE,
  INIT_LIST_FUND_POS_GROUP_INFO_ACTION_STATUS,


  // Filter Long More Than 2% Fund Level Report
  FILTER_LONG_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT,

  // Filter Long More Than 5% Fund Level Report
  FILTER_LONG_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT,

  // Filter Short More Than 2% Fund Level Report
  FILTER_SHORT_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT,

  // Filter Short More Than 5% Fund Level Report
  FILTER_SHORT_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT,

  // Show All Fund Level Report
  SHOW_ALL_FUND_LEVEL_REPORT,

  // add grid to container
  FUND_POSITION_GROUP_INFO_GRID_TO_PROPS,
  FUND_LEVEL_REPORT_GRID_TO_PROPS,

} from './fundLevelReportConstants';

// Generate Fund Level Report
function startGenerateFundLevelReport() {
  return {
    type: START_GENERATE_FUND_LEVEL_REPORT
  };
}

export function generateFundLevelReport(dateString, dataType) {
  const requestParams = {
    dateString,
    dataType,
  };
  
  return function(dispatch) {
    dispatch(startGenerateFundLevelReport());
    httpClient
      .post(UrlConfig.GENERATE_FUND_LEVEL_REPORT, requestParams)
      .then(resp => dispatch(generateFundLevelReportSuccess(resp)))
      .catch(err => dispatch(generateFundLevelReportFailure(err)));
  };
}

function generateFundLevelReportSuccess(resp) {
  return {
    type: GENERATE_FUND_LEVEL_REPORT_SUCCESS,
    payload: resp
  };
}

function generateFundLevelReportFailure(err) {
  return {
    type: GENERATE_FUND_LEVEL_REPORT_FAILURE,
    payload: err
  };
}

export function initGenerateFundLevelReportActionStatus() {
  return {
    type: INIT_GENERATE_FUND_LEVEL_REPORT_ACTION_STATUS
  };
}

// Load Fund Level Report
function startLoadFundLevelReport() {
  return {
    type: START_LOAD_FUND_LEVEL_REPORT
  };
}

export function loadFundLevelReport(dateString, dataType) {
  const requestParams = {
    'date-string': dateString,
    'date-type': dataType,
  };

  return function(dispatch) {
    dispatch(startLoadFundLevelReport());
    httpClient
      .get(UrlConfig.LOAD_FUND_LEVEL_REPORT, requestParams)
      .then(resp => dispatch(loadFundLevelReportSuccess(resp)))
      .catch(err => dispatch(loadFundLevelReportFailure(err)));
  };
}

function loadFundLevelReportSuccess(resp) {
  return {
    type: LOAD_FUND_LEVEL_REPORT_SUCCESS,
    payload: resp
  };
}

function loadFundLevelReportFailure(err) {
  return {
    type: LOAD_FUND_LEVEL_REPORT_FAILURE,
    payload: err
  };
}

export function initLoadFundLevelReportActionStatus() {
  return {
    type: INIT_LOAD_FUND_LEVEL_REPORT_ACTION_STATUS
  };
}

// Filter Long More Than 2% Fund Level Report
export function filterLongMoreThan2PercentFundLevelReport() {
  return {
    type: FILTER_LONG_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT
  };
}

// Filter Long More Than 5% Fund Level Report
export function filterLongMoreThan5PercentFundLevelReport() {
  return {
    type: FILTER_LONG_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT
  };
}

// Filter Short More Than 2% Fund Level Report
export function filterShortMoreThan2PercentFundLevelReport() {
  return {
    type: FILTER_SHORT_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT
  };
}

// Filter Short More Than 5% Fund Level Report
export function filterShortMoreThan5PercentFundLevelReport() {
  return {
    type: FILTER_SHORT_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT
  };
}

// Show All Fund Level Report
export function showAllFundLevelReport() {
  return {
    type: SHOW_ALL_FUND_LEVEL_REPORT
  };
}



// Load Fund Level Report
function startListFundPosGroupInfo() {
  return {
    type: START_LIST_FUND_POS_GROUP_INFO
  };
}
export function listFundPosGroupInfo(dateString, dataType) {
  const requestParams = {
    'date-string': dateString,
    'date-type': dataType,
  };
  return function(dispatch) {
    dispatch(startListFundPosGroupInfo());
    httpClient
      .get(UrlConfig.LIST_FUND_POS_GROUP_INFO, requestParams)
      .then(resp => dispatch(listFundPosGroupInfoSuccess(resp)))
      .catch(err => dispatch(listFundPosGroupInfoFailure(err)));
  };
}
function listFundPosGroupInfoSuccess(resp) {
  return {
    type: LIST_FUND_POS_GROUP_INFO_SUCCESS,
    payload: resp
  };
}
function listFundPosGroupInfoFailure(err) {
  return {
    type: LIST_FUND_POS_GROUP_INFO_FAILURE,
    payload: err
  };
}
export function initListFundPosGroupInfoActionStatus() {
  return {
    type: INIT_LIST_FUND_POS_GROUP_INFO_ACTION_STATUS
  };
}



// add grid to container
export function fundPositionGroupInfoGridToProps(grid) {
  return {
    type: FUND_POSITION_GROUP_INFO_GRID_TO_PROPS,
    payload: grid,
  }
}
export function fundLevelReportGridToProps(grid) {
  return {
    type: FUND_LEVEL_REPORT_GRID_TO_PROPS,
    payload: grid,
  }
}

