import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_CPT_INFO,
    LOAD_CPT_INFO_SUCCESS,
    LOAD_CPT_INFO_FAILURE,
    INIT_LOAD_CPT_INFO_ACTION_STATUS,
    // select
    SELECT_CPT,
    // add or edit
    OPEN_ADD_OR_EDIT_CPT_MODAL,
    ADD_OR_EDIT_CPT_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_CPT_MODAL,
    START_SUBMIT_SAVE_CPT,
    SUBMIT_SAVE_CPT_SUCCESS,
    SUBMIT_SAVE_CPT_FAILURE,
    INIT_SUBMIT_SAVE_CPT_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_CPTSUB_MODAL,
    ADD_OR_EDIT_CPTSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_CPTSUB_MODAL,
    START_SUBMIT_SAVE_CPTSUB,
    SUBMIT_SAVE_CPTSUB_SUCCESS,
    SUBMIT_SAVE_CPTSUB_FAILURE,
    INIT_SUBMIT_SAVE_CPTSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_CPT,
    BATCH_CHANGE_STATUS_FOR_CPT_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_CPT_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_CPT_ACTION_STATUS,
    // finish
    SELECT_CPT_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_CPT_TASK,
    FINISH_CPT_TASK_SUCCESS,
    FINISH_CPT_TASK_FAILURE,
    INIT_FINISH_CPT_TASK_ACTION_STATUS,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,



      // Accept Questionnaire Result
    START_ACCEPT_QUESTIONNAIRE_RESULT,
    ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
    ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
    INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

    // Reject Questionnaire Result
    START_REJECT_QUESTIONNAIRE_RESULT,
    REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
    REJECT_QUESTIONNAIRE_RESULT_FAILURE,
    INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

      // Send Remind Email
    START_SEND_REMIND_EMAIL,
    SEND_REMIND_EMAIL_SUCCESS,
    SEND_REMIND_EMAIL_FAILURE,
    INIT_SEND_REMIND_EMAIL_ACTION_STATUS,


    SET_REGISTER_INFO_BY_NAME,

} from './CptConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadCptInfo() {
    return {
      type: START_LOAD_CPT_INFO,
    };
}

function getQueryReqParams(params) {
    let startDate = params.startDate !== undefined && params.startDate !== null && params.startDate !== ''
    ? params.startDate.format("YYYY-MM-DD") : '';
    let endDate = params.endDate !== undefined && params.endDate !== null && params.endDate !== ''
        ? params.endDate.format("YYYY-MM-DD") : '';
    const requestParams = {
    // startDate: startDate,
    // endDate: endDate,
    category: params.category,
    fundco: params.fundco,
    owner: params.owner,
    monthStart: params.monthStart,
    status: params.status,
    };
    return requestParams;
}

export function loadCptInfo(params) {

    const requestParams = getQueryReqParams(params);

    return function(dispatch) {
        dispatch(startLoadCptInfo());
        httpClient  
          .get(UrlConfig.LOAD_CPT_INFO, requestParams)
          .then(resp => dispatch(loadCptInfoSuccess(resp)))
          .catch(err => dispatch(loadCptInfoFailure(err)));
    };
}
function loadCptInfoSuccess(resp) {
    return {
      type: LOAD_CPT_INFO_SUCCESS,
      payload: resp,
    };
}
function loadCptInfoFailure(err) {
    return {
        type: LOAD_CPT_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadCptInfoActionStatus() {
    return {
      type: INIT_LOAD_CPT_INFO_ACTION_STATUS,
    };
}


// select
export function selectCpt(array) {
    return {
        type: SELECT_CPT,
        payload: array,
    };
}


// add or edit
export function openAddOrEditCptModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_CPT_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditCptChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_CPT_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveCptModal() {
    return {
        type: RESET_SUBMIT_SAVE_CPT_MODAL,
    };
}

function startSubmitSaveCpt() {
    return {
        type: START_SUBMIT_SAVE_CPT,
    };
}
export function submitSaveCpt(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveCpt());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_CPT;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_CPT;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveCptSuccess(resp)))
            .catch(err => dispatch(submitSaveCptFailure(err)));        
    };
}
function submitSaveCptSuccess(resp) {
    return {
        type: SUBMIT_SAVE_CPT_SUCCESS,
        payload: resp,
    };
}
export function submitSaveCptFailure(err) {
    return {
        type: SUBMIT_SAVE_CPT_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveCptActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_CPT_ACTION_STATUS,
    };
}


//sub add or edit
export function openAddOrEditCptSubModal(mode, data,idList) {
    return {
        type: OPEN_ADD_OR_EDIT_CPTSUB_MODAL,
        payload: {
            mode,
            data,
            idList,
        },
    };
}
export function addOrEditCptSubChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_CPTSUB_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveCptSubModal() {
    return {
        type: RESET_SUBMIT_SAVE_CPTSUB_MODAL,
    };
}

function startSubmitSaveCptSub() {
    return {
        type: START_SUBMIT_SAVE_CPTSUB,
    };
}
export function submitSaveCptSub(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveCptSub());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_CPTSUB;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_CPTSUB;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveCptSubSuccess(resp)))
            .catch(err => dispatch(submitSaveCptSubFailure(err)));        
    };
}
function submitSaveCptSubSuccess(resp) {
    return {
        type: SUBMIT_SAVE_CPTSUB_SUCCESS,
        payload: resp,
    };
}
export function submitSaveCptSubFailure(err) {
    return {
        type: SUBMIT_SAVE_CPTSUB_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveCptSubActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_CPTSUB_ACTION_STATUS,
    };
}



// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForCpt() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_CPT,
    };
}
export function batchChangeStatusForCpt(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForCpt());
        httpClient
            .post(UrlConfig.BATCH_CHANGE_STATUS_FOR_CPT, params)
            .then(resp => dispatch(batchChangeStatusForCptSuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForCptFailure(err)));        
    };
}
function batchChangeStatusForCptSuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_CPT_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForCptFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_CPT_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForCptActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_CPT_ACTION_STATUS,
    };
}


// finish
export function selectCptTask(array) {
    return {
        type: SELECT_CPT_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishCptTask() {
    return {
        type: START_FINISH_CPT_TASK,
    };
}
export function finishCptTask(params) {
    return function(dispatch) {
        dispatch(startFinishCptTask());
        httpClient
            .post(UrlConfig.FINISH_CPT_TASK, params)
            .then(resp => dispatch(finishCptTaskSuccess(resp)))
            .catch(err => dispatch(finishCptTaskFailure(err)));        
    };
}
function finishCptTaskSuccess(resp) {
    return {
        type: FINISH_CPT_TASK_SUCCESS,
        payload: resp,
    };
}
function finishCptTaskFailure(err) {
    return {
        type: FINISH_CPT_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishCptTaskActionStatus() {
    return {
        type: INIT_FINISH_CPT_TASK_ACTION_STATUS,
    };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
      payload: {
        sendMode,
        emailPayload,
      },
    };
  }
  
  export function resetQuestionnaireEmailEditModalDialog() {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET
    };
  }


  // Accept Questionnaire Result
function startAcceptQuestionnaireResult() {
    return {
      type: START_ACCEPT_QUESTIONNAIRE_RESULT
    };
  }
  
  export function acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startAcceptQuestionnaireResult());
      httpClient
        .post(UrlConfig.ACCEPT_CPT_RESULT, requestParams)
        .then(resp => dispatch(acceptQuestionnaireResultSuccess(resp)))
        .catch(err => dispatch(acceptQuestionnaireResultFailure(err)));
    };
  }
  
  function acceptQuestionnaireResultSuccess(resp) {
    return {
      type: ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
      payload: resp
    };
  }
  
  function acceptQuestionnaireResultFailure(err) {
    return {
      type: ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
      payload: err
    };
  }
  
  export function initAcceptQuestionnaireResultActionStatus() {
    return {
      type: INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS
    };
  }

// Reject Questionnaire Result
function startRejectQuestionnaireResult() {
    return {
      type: START_REJECT_QUESTIONNAIRE_RESULT
    };
  }
  
  export function rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startRejectQuestionnaireResult());
      httpClient   
        .post(UrlConfig.REJECT_CPT_RESULT, requestParams)
        .then(resp => dispatch(rejectQuestionnaireResultSuccess(resp)))
        .catch(err => dispatch(rejectQuestionnaireResultFailure(err)));
    };
  }
  
  function rejectQuestionnaireResultSuccess(resp) {
    return {
      type: REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
      payload: resp
    };
  }
  
  function rejectQuestionnaireResultFailure(err) {
    return {
      type: REJECT_QUESTIONNAIRE_RESULT_FAILURE,
      payload: err
    };
  }
  
  export function initRejectQuestionnaireResultActionStatus() {
    return {
      type: INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS
    };
  }

  // Send Remind Email
function startSendRemindEmail() {
    return {
      type: START_SEND_REMIND_EMAIL
    };
  }
  
  export function sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startSendRemindEmail());
      httpClient
        .post(UrlConfig.REMIND_CPT_EMAIL, requestParams)
        .then(resp => dispatch(sendRemindEmailSuccess(resp)))
        .catch(err => dispatch(sendRemindEmailFailure(err)));
    };
  }
  
  function sendRemindEmailSuccess(resp) {
    return {
      type: SEND_REMIND_EMAIL_SUCCESS,
      payload: resp
    };
  }
  
  function sendRemindEmailFailure(err) {
    return {
      type: SEND_REMIND_EMAIL_FAILURE,
      payload: err
    };
  }
  
  export function initSendRemindEmailActionStatus() {
    return {
      type: INIT_SEND_REMIND_EMAIL_ACTION_STATUS
    };
  }

  export function selectUserRegisterInfoByName(userName) {
    return function(dispatch) {
        httpClient
          .get(UrlConfig.SELECT_REGISTERINFO_BY_NAME, {userName})
          .then(resp => 
            (
              // console.log(resp)
              dispatch(setRegisterInfoByName(resp))
            )
            
           )
          .catch(err => dispatch(setRegisterInfoByName(null)));
      };
}
export function resetCustomerInfo4CMSNet() {
  
    return setRegisterInfoByName(null);
}
function setRegisterInfoByName(resp) {
    let registerInfoList = [];
    if(!!resp && !!resp.data) {
      registerInfoList = resp.data;
    }
    return {
        type: SET_REGISTER_INFO_BY_NAME,
        payload: registerInfoList,
    }
}