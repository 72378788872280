import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

class FundLevelReportGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.fundLevelReportGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      groupDefaultExpanded: 0,
    };
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.props.fundLevelReportGridToProps(params);
  };

  render() {
    const {
      filterFundLevelReportArray,
      className = 'grid-wrapper',
      loadFundLevelReportActionStatus
    } = this.props;

    const isLoading = loadFundLevelReportActionStatus === ACTION_STATUS.LOGINING;
    if (loadFundLevelReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadFundLevelReportActionStatus();
    }


    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={filterFundLevelReportArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            // events
            onGridReady={this.onGridReady}
            // onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default FundLevelReportGrid;