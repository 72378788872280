import React, {Component} from 'react';

import { Button, message, Breadcrumb, DatePicker, Select } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import FundLevelReportGrid from './FundLevelReportGrid';
import FundPositionGroupInfoGrid from './FundPositionGroupInfoGrid';
import moment from 'moment';
import '../../DocumentReport.css';


class FundLevelReportDashboard extends Component {

  state = {
    searchDate: moment(),
    dataType: 'A',
  };

  componentDidMount() {
    const {
      searchDate,
      dataType,
    } = this.state;

    this.props.loadFundLevelReport(searchDate.format("YYYY-MM-DD"), dataType);
  }

  componentDidUpdate() {
    const {
      // Load Fund Level Report Action Status
      loadFundLevelReportActionStatus,
      loadFundLevelReportErrMsg,

      // Generate Fund Level Report Action Status
      generateFundLevelReportActionStatus,
      generateFundLevelReportErrMsg,

      listFundPosGroupInfoActionStatus,
      listFundPosGroupInfoActionResp,
    } = this.props;

    const {
      searchDate,
      dataType,
    } = this.state;

    // Generate Fund Level Report 成功
    if (generateFundLevelReportActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Short position report are generated successfully!');
      this.props.initGenerateFundLevelReportActionStatus();
      this.props.loadFundLevelReport(searchDate.format("YYYY-MM-DD"), dataType);
    }

    // Generate Fund Level Report 失败
    if (generateFundLevelReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(generateFundLevelReportErrMsg);
      this.props.initGenerateFundLevelReportActionStatus();
    }

    // Load Fund Level Report 成功
    if (loadFundLevelReportActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadFundLevelReportActionStatus();
    }

    // Load Fund Level Report 失败
    if (loadFundLevelReportActionStatus === ACTION_STATUS.ERROR) {
      message.error(loadFundLevelReportErrMsg);
      this.props.initLoadFundLevelReportActionStatus();
    }

    if(listFundPosGroupInfoActionStatus === ACTION_STATUS.SUCCESS) {
      if(!listFundPosGroupInfoActionResp || !listFundPosGroupInfoActionResp.data || listFundPosGroupInfoActionResp.data.length === 0) {
        message.warn('No data found. You need to "Generate" first');
      }
      this.props.initListFundPosGroupInfoActionStatus();
    }
    if(listFundPosGroupInfoActionStatus === ACTION_STATUS.ERROR) {
      let errMsg = 'Query Fund Position Report Info failed';
      if(!!listFundPosGroupInfoActionResp && !!listFundPosGroupInfoActionResp.respMessage) {
        errMsg = listFundPosGroupInfoActionResp.respMessage;
      }
      message.error(errMsg);
      this.props.initListFundPosGroupInfoActionStatus();
    }

  }

  searchBtnOnClicked = () => {
    const {
      searchDate,
      dataType,
    } = this.state;

    this.props.loadFundLevelReport(searchDate.format("YYYY-MM-DD"), dataType);
  }

  genreateReportBtnOnClicked = () => {
    const {
      searchDate,
      dataType,
    } = this.state;

    this.props.generateFundLevelReport(searchDate.format("YYYY-MM-DD"), dataType);
  }

  listFundPosGroupInfo = () => {
    const {
      searchDate,
      dataType,
    } = this.state;

    if(dataType === 'B') {
      message.warn('"EQTY & SWAP" is not supported by "Report"');
      return;
    }

    this.props.listFundPosGroupInfo(searchDate.format("YYYY-MM-DD"), dataType);
  }


  showAllBtnOnClicked = () => {
    this.props.showAllFundLevelReport();
  }


  exportAsXls = () => {
    const {
      gridType,
      fundLevelReportGrid,
      fundPositionGroupInfoGrid,
    } = this.props;
    const grid = gridType === 1 ? fundLevelReportGrid : fundPositionGroupInfoGrid;
    const fileName = gridType === 1 ? 'fund_position_detail.xlsx' : 'fund_position_summary_report.xlsx';
    grid.api.exportDataAsExcel({fileName});
}

  
  render() {
    const {
      // Generate Fund Level Report Action Status
      generateFundLevelReportActionStatus,
      loadFundLevelReportActionStatus,
      listFundPosGroupInfoActionStatus,
      gridType,
    } = this.props;

    const {
      searchDate,
      dataType,
    } = this.state;

    const generateBtnIsLoading = generateFundLevelReportActionStatus === ACTION_STATUS.LOGINING;
    const loadBtnIsLoading = loadFundLevelReportActionStatus === ACTION_STATUS.LOGINING;
    const listFundPosGroupInfoBtnIsLoading = listFundPosGroupInfoActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="fundLevelReportWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Document &amp; Report</Breadcrumb.Item>
            <Breadcrumb.Item>Fund Level Report</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='operationBar'>
          <DatePicker 
            style={{ marginLeft: 10, fontSize: 10, width: 150 }}
            size='small'
            allowClear={false}
            value={searchDate}
            onChange={(date, dateString) => {
              this.setState({ 
                  ...this.state,
                  searchDate: date,
              })
            }} 
          />
          <Select
              size='small'
              style={{ marginLeft: 10, fontSize: 10, width: 150 }}
              allowClear={false}
              value={dataType}
              onChange={(val) => {
                this.setState({
                    ...this.state,
                    dataType: val,
                })
              }}
          >
            <Select.Option key='A'>EQTY only</Select.Option>
            <Select.Option key='C'>SWAP only</Select.Option>
            <Select.Option key='B'>EQTY &amp; SWAP</Select.Option>
            <Select.Option key='D'>Future</Select.Option>
            <Select.Option key='F'>OPTION</Select.Option>
            <Select.Option key='E'>CCY</Select.Option>
            <Select.Option key='G'>BOND only</Select.Option>
            <Select.Option key='H'>BD_SWAP only</Select.Option>
            <Select.Option key='I'>BOND &amp; BD_SWAP</Select.Option>
          </Select>

          <Button 
            onClick={this.searchBtnOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Search
          </Button>

          <Button 
            onClick={this.genreateReportBtnOnClicked} 
            size='small'
            loading={generateBtnIsLoading}
            type='primary'>
            Generate
          </Button>

          <Button 
            onClick={this.listFundPosGroupInfo} 
            size='small'
            loading={listFundPosGroupInfoBtnIsLoading}
            type='primary'>
            Report
          </Button>

          <Button 
            onClick={this.exportAsXls} 
            size='small'
            type='primary'>
            Export
          </Button>

        </div>

        <div className='fundLevelReportGrid'>
          {
            gridType === 1
            ? <FundLevelReportGrid {...this.props} />
            : <FundPositionGroupInfoGrid {...this.props} />
          }
        </div>

      </div>
    );
  }
}

export default FundLevelReportDashboard;