import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load Book List
  START_LOAD_BOOK_LIST,
  LOAD_BOOK_LIST_SUCCESS,
  LOAD_BOOK_LIST_FAILURE,
  INIT_LOAD_BOOK_LIST_ACTION_STATUS,
  
} from './bookConstants';

const initialState = {
  bookGridColumns: GridColumnMap.bookGridColumns,

  // Book
  bookArray: [],

  // Load Book List Action Status
  loadBookListActionStatus: ACTION_STATUS.READY,
}

// Load Book
function startLoadBookList(state) {
  return {
    ...state,
    loadBookListActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadBookListSuccess(state, resp) {
  return {
    ...state,
    loadBookListActionStatus: ACTION_STATUS.SUCCESS,
    bookArray: resp.data,
  };
}

function loadBookListFailure(state, err) {
  return {
    ...state,
    loadBookListActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadBookListActionStatus(state) {
  return {
    ...state,
    loadBookListActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Book List
  [START_LOAD_BOOK_LIST]: startLoadBookList,
  [LOAD_BOOK_LIST_SUCCESS]: loadBookListSuccess,
  [LOAD_BOOK_LIST_FAILURE]: loadBookListFailure,
  [INIT_LOAD_BOOK_LIST_ACTION_STATUS]: initLoadBookListActionStatus,  
});