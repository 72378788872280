import React from 'react';
import moment from 'moment';
import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../utils/RadioOperationMode';
import { Select } from 'antd';

import {
  // Load Restricted Rules
  START_LOAD_RESTRICTED_RULES,
  LOAD_RESTRICTED_RULES_SUCCESS,
  LOAD_RESTRICTED_RULES_FAILURE,
  INIT_LOAD_RESTRICTED_RULES_ACTION_STATUS,

  // Create Restricted Rule
  START_CREATE_RESTRICTED_RULE,
  CREATE_RESTRICTED_RULE_SUCCESS,
  CREATE_RESTRICTED_RULE_FAILURE,
  INIT_CREATE_RESTRICTED_RULE_ACTION_STATUS,

  // Update Restricted Rule
  START_UPDATE_RESTRICTED_RULE,
  UPDATE_RESTRICTED_RULE_SUCCESS,
  UPDATE_RESTRICTED_RULE_FAILURE,
  INIT_UPDATE_RESTRICTED_RULE_ACTION_STATUS,

  // Batch Delete Restricted Rule
  START_BATCH_DELETE_RESTRICTED_RULE,
  BATCH_DELETE_RESTRICTED_RULE_SUCCESS,
  BATCH_DELETE_RESTRICTED_RULE_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_RULE_ACTION_STATUS,

  // Open/Reset Restricted Rule Modal Dialog
  RESTRICTED_RULE_MODAL_DIALOG_OPEN,
  RESTRICTED_RULE_MODAL_DIALOG_RESET,

  // Restricted Rule Edit Change Input
  RESTRICTED_RULE_EDIT_CHANGE_INPUT,

  // Load BBG Tickers
  START_LOAD_BBG_TICKERS,
  LOAD_BBG_TICKERS_SUCCESS,
  LOAD_BBG_TICKERS_FAILURE,
  INIT_LOAD_BBG_TICKERS_ACTION_STATUS,

  // Clear bbgTickerArray bbgTickerOptions
  CLEAR_BBG_TICKERS,

  // BBG Ticker Select Changed
  BBG_TICKER_SELECT_CHANGED,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,

  // Select Restricted Rules
  SELECT_RESTRICTED_RULES,

  // Show Valid Restricted Rules
  SHOW_VALID_RESTRICTED_RULES,

  // Show All Restricted Rules
  SHOW_ALL_RESTRICTED_RULES,
} from './restrictedRuleConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,

  // Load All BBG Tickers
  LOAD_ALL_BBG_TICKERS_SUCCESS,
} from '../../home/homeConstants';

const Option = Select.Option;

const initialState = {
  restrictedRuleGridColumns: GridColumnMap.restrictedRuleGridColumns,

  // Restricted Rules
  restrictedRuleArray: [],
  filterRestrictedRuleArray: [],

  restrictedRuleEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      id: '',
      restrictionType: '',
      fund: '',
      team: '',
      personInCharge: '',
      bbgTicker: '',
      secName: '',
      remark: '',
      validFrom: '',
      validTo: '9999-12-31 00:00:00',
    }
  },

  // Restricted Rules Action Status
  loadRestrictedRulesActionStatus: ACTION_STATUS.READY,
  createRestrictedRuleActionStatus: ACTION_STATUS.READY,
  updateRestrictedRuleActionStatus: ACTION_STATUS.READY,
  batchDeleteRestrictedRulesActionStatus: ACTION_STATUS.READY,

  // Security Ticker Data
  securityTickerData: null,
  bbgTickerArray: [],
  bbgTickerOptions: [],
  securityTickerArray: [],

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // All Bbg Tickers
  allBbgTickers: [],

  // Load BBG Tickers Action Status
  loadBbgTickersActionStatus: ACTION_STATUS.READY,

  // Select Restricted Rule
  selectedRestrictedRules: [],
}


// =================================================================
// Load Restricted Rules
function startLoadRestrictedRules(state) {
  return {
    ...state,
    loadRestrictedRulesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedRulesSuccess(state, resp) {
  const restrictedRuleArray = resp.data;
  let filterRestrictedRuleArray = [];
  if (!!restrictedRuleArray) {
    filterRestrictedRuleArray = restrictedRuleArray.filter(restrictedRule => restrictedRule.isValid);
  }
   

  return {
    ...state,
    restrictedRuleArray,
    filterRestrictedRuleArray,
    loadRestrictedRulesActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedRulesFailure(state, err) {
  return {
    ...state,
    loadRestrictedRulesActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedRulesActionStatus(state) {
  return {
    ...state,
    loadRestrictedRulesActionStatus: ACTION_STATUS.READY,
  };
}

// Create Restricted Rule
function startCreateRestrictedRule(state) {
  return {
    ...state,
    createRestrictedRuleActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createRestrictedRuleSuccess(state, resp) {
  return {
    ...state,
    createRestrictedRuleActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createRestrictedRuleFailure(state, err) {
  return {
    ...state,
    createRestrictedRuleActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCreateRestrictedRuleActionStatus(state) {
  return {
    ...state,
    createRestrictedRuleActionStatus: ACTION_STATUS.READY,
  };
}

// Update Restricted Rule
function startUpdateRestrictedRule(state) {
  return {
    ...state,
    updateRestrictedRuleActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateRestrictedRuleSuccess(state, resp) {
  return {
    ...state,
    updateRestrictedRuleActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateRestrictedRuleFailure(state, err) {
  return {
    ...state,
    updateRestrictedRuleActionStatus: ACTION_STATUS.ERROR,
  };
}

function initUpdateRestrictedRuleActionStatus(state) {
  return {
    ...state,
    updateRestrictedRuleActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Restricted Rule
function startBatchDeleteRestrictedRule(state) {
  return {
    ...state,
    batchDeleteRestrictedRulesActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteRestrictedRuleSuccess(state, resp) {
  return {
    ...state,
    batchDeleteRestrictedRulesActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteRestrictedRuleFailure(state, err) {
  return {
    ...state,
    batchDeleteRestrictedRulesActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteRestrictedRuleActionStatus(state) {
  return {
    ...state,
    batchDeleteRestrictedRulesActionStatus: ACTION_STATUS.READY,
  };
}



// =================================================================
// Open/Reset Restricted Rule Modal Dialog
function openRestrictedRuleEditModalDialog(state, {isAddMode, restrictedRule}) {
  if (isAddMode) {
    return {
      ...state,
      restrictedRuleEditControlModal: {
        ...state.restrictedRuleEditControlModal,
        isOpened: true,
        isAddMode,
      },
    };
  } else {
    return {
      ...state,
      restrictedRuleEditControlModal: {
        ...state.restrictedRuleEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedRuleEditControlModal.fields,
          id: restrictedRule.id,
          restrictionType: restrictedRule.restrictionType,
          fund: restrictedRule.fund,
          team: restrictedRule.team,
          personInCharge: restrictedRule.personInCharge,
          bbgTicker: restrictedRule.bbgTicker,
          secName: restrictedRule.secName,
          remark: restrictedRule.remark,
          validFrom: restrictedRule.validFrom,
          validTo: restrictedRule.validTo,
        }
      },
    };
  }
}

function resetRestrictedRuleEditModalDialog(state) {
  return {
    ...state,
    restrictedRuleEditControlModal: initialState.restrictedRuleEditControlModal,
  };
}

// Restricted Rule Edit Change Input
function restrictedRuleEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedRuleEditControlModal: {
      ...state.restrictedRuleEditControlModal,
      fields: updatedFields,
    },
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  // let bbgTickerArray = securityTickerData.bbgTickerList;
  let securityTickerArray = securityTickerData.securityTickerList;

  // let bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);

  return {
    ...state,
    securityTickerData,
    // bbgTickerArray,
    securityTickerArray,
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// =================================================================
// Load BBG Tickers
function startLoadBBGTickers(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadBbgTickersSuccess(state, resp) {
  const bbgTickerArray = resp.data;

  const bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);
  
  return {
    ...state,
    bbgTickerArray,
    bbgTickerOptions,
    loadBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadBbgTickersFailure(state, err) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadBbgTickersActionStatus(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.READY,
  };
}

// Load All BBG Tickers
function loadAllBbgTickersSuccess(state, resp) {
  const allBbgTickers = resp.data;

  const bbgTickerOptions = allBbgTickers.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);

  return {
    ...state,
    allBbgTickers,
    bbgTickerOptions,
  };
}

// Clear bbgTickerArray bbgTickerOptions
function clearBbgTickers(state) {
  return {
    ...state,
    restrictedRuleEditControlModal: {
      ...state.restrictedRuleEditControlModal,
      fields: {
        ...state.restrictedRuleEditControlModal.fields,
        secName: '',
      },
    },
    bbgTickerArray: [],
    bbgTickerOptions: [],
  };
}

// BBG Ticker Select Changed
function bbgTickerSelectChanged(state, bbgTicker) {
  const securityTickerArray = state.securityTickerArray;

  let securityName = '';
  if (!!bbgTicker) {
    const filterRuleTickerArray = securityTickerArray.filter(securityTicker => securityTicker.bbgTicker === bbgTicker);

    if (filterRuleTickerArray.length > 0) {
      const securityTicker = filterRuleTickerArray[0];
      securityName = securityTicker.securityName;
    }
  }

  const fields = state.restrictedRuleEditControlModal.fields;

  securityName = securityName === '' ? fields.secName : securityName;

  return {
    ...state,
    restrictedRuleEditControlModal: {
      ...state.restrictedRuleEditControlModal,
      fields: {
        ...state.restrictedRuleEditControlModal.fields,
        secName: securityName,
      },
    },
  };
}

// Fund Code Select Changed
function fundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedRuleEditControlModal.fields.bookCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedRuleEditControlModal: {
      ...state.restrictedRuleEditControlModal,
      fields: {
        ...state.restrictedRuleEditControlModal.fields,
        pmName,
      },
    },
  };
}

// Book Code Select Changed
function bookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedRuleEditControlModal.fields.fundCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedRuleEditControlModal: {
      ...state.restrictedRuleEditControlModal,
      fields: {
        ...state.restrictedRuleEditControlModal.fields,
        pmName,
      },
    },
  };
}

// Select Restricted Rule
function selectRestrictedRules(state, payload) {
  return {
    ...state,
    selectedRestrictedRules: payload
  };
}

// Show Valid Restricted Rules
function showValidRestrictedRules(state) {
  const restrictedRuleArray = state.restrictedRuleArray;
  const filterRestrictedRuleArray = restrictedRuleArray.filter(restrictedRule => restrictedRule.isValid);

  return {
    ...state,
    filterRestrictedRuleArray,
  };
}

// Show All Restricted Rules
function showAllRestrictedRules(state) {
  const restrictedRuleArray = state.restrictedRuleArray;
  const filterRestrictedRuleArray = restrictedRuleArray;

  return {
    ...state,
    filterRestrictedRuleArray,
  };
}

export default createReducer(initialState, {
  // Load Restricted Rules
  [START_LOAD_RESTRICTED_RULES]: startLoadRestrictedRules,
  [LOAD_RESTRICTED_RULES_SUCCESS]: loadRestrictedRulesSuccess,
  [LOAD_RESTRICTED_RULES_FAILURE]: loadRestrictedRulesFailure,
  [INIT_LOAD_RESTRICTED_RULES_ACTION_STATUS]: initLoadRestrictedRulesActionStatus,

  // Open/Reset Restricted Rule Modal Dialog
  [RESTRICTED_RULE_MODAL_DIALOG_OPEN]: openRestrictedRuleEditModalDialog,
  [RESTRICTED_RULE_MODAL_DIALOG_RESET]: resetRestrictedRuleEditModalDialog,

  // Restricted Rule Edit Change Input
  [RESTRICTED_RULE_EDIT_CHANGE_INPUT]: restrictedRuleEditChangeInput,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load BBG Tickers
  [START_LOAD_BBG_TICKERS]: startLoadBBGTickers,
  [LOAD_BBG_TICKERS_SUCCESS]: loadBbgTickersSuccess,
  [LOAD_BBG_TICKERS_FAILURE]: loadBbgTickersFailure,
  [INIT_LOAD_BBG_TICKERS_ACTION_STATUS]: initLoadBbgTickersActionStatus,

  // Load All BBG Tickers
  [LOAD_ALL_BBG_TICKERS_SUCCESS]: loadAllBbgTickersSuccess,

  // Clear bbgTickerArray bbgTickerOptions
  [CLEAR_BBG_TICKERS]: clearBbgTickers,

  // BBG Ticker Select Changed
  [BBG_TICKER_SELECT_CHANGED]: bbgTickerSelectChanged,

  // Fund Code Select Changed
  [FUND_CODE_SELECT_CHANGED]: fundCodeSelectChanged,

  // Book Code Select Changed
  [BOOK_CODE_SELECT_CHANGED]: bookCodeSelectChanged,

  // Create Restricted Rule
  [START_CREATE_RESTRICTED_RULE]: startCreateRestrictedRule,
  [CREATE_RESTRICTED_RULE_SUCCESS]: createRestrictedRuleSuccess,
  [CREATE_RESTRICTED_RULE_FAILURE]: createRestrictedRuleFailure,
  [INIT_CREATE_RESTRICTED_RULE_ACTION_STATUS]: initCreateRestrictedRuleActionStatus,

  // Update Restricted Rule
  [START_UPDATE_RESTRICTED_RULE]: startUpdateRestrictedRule,
  [UPDATE_RESTRICTED_RULE_SUCCESS]: updateRestrictedRuleSuccess,
  [UPDATE_RESTRICTED_RULE_FAILURE]: updateRestrictedRuleFailure,
  [INIT_UPDATE_RESTRICTED_RULE_ACTION_STATUS]: initUpdateRestrictedRuleActionStatus,

  // Batch Delete Restricted Rule
  [START_BATCH_DELETE_RESTRICTED_RULE]: startBatchDeleteRestrictedRule,
  [BATCH_DELETE_RESTRICTED_RULE_SUCCESS]: batchDeleteRestrictedRuleSuccess,
  [BATCH_DELETE_RESTRICTED_RULE_FAILURE]: batchDeleteRestrictedRuleFailure,
  [INIT_BATCH_DELETE_RESTRICTED_RULE_ACTION_STATUS]: initBatchDeleteRestrictedRuleActionStatus,

  // Select Restricted Rules
  [SELECT_RESTRICTED_RULES]: selectRestrictedRules,

  // Show Valid Restricted Rules
  [SHOW_VALID_RESTRICTED_RULES]: showValidRestrictedRules,

  // Show All Restricted Rules
  [SHOW_ALL_RESTRICTED_RULES]: showAllRestrictedRules,
});