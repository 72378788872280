import {
  // Load Personal Trade
  START_LOAD_PERSONAL_TRADE,
  LOAD_PERSONAL_TRADE_SUCCESS,
  LOAD_PERSONAL_TRADE_FAILURE,
  INIT_LOAD_PERSONAL_TRADE_ACTION_STATUS,
  QUERY_PERSONAL_TRADE_INPUT_CHANGE,
  
  // Select Personal Trade Request
  SELECT_PERSONAL_TRADE_REQUEST,

  // Open/Reset Personal Trade Feedback Modal Dialog
  PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_RESET,

  // Personal Trade Feedback Edit Change Input
  PERSONAL_TRADE_FEEDBACK_EDIT_CHANGE_INPUT,

  // Approve Personal Trade
  START_APPROVE_PERSONAL_TRADE,
  APPROVE_PERSONAL_TRADE_SUCCESS,
  APPROVE_PERSONAL_TRADE_FAILURE,
  INIT_APPROVE_PERSONAL_TRADE_ACTION_STATUS,

  // Final Approve Personal Trade
  START_FINAL_APPROVE_PERSONAL_TRADE,
  FINAL_APPROVE_PERSONAL_TRADE_SUCCESS,
  FINAL_APPROVE_PERSONAL_TRADE_FAILURE,
  INIT_FINAL_APPROVE_PERSONAL_TRADE_ACTION_STATUS,

  // Reject Personal Trade
  START_REJECT_PERSONAL_TRADE,
  REJECT_PERSONAL_TRADE_SUCCESS,
  REJECT_PERSONAL_TRADE_FAILURE,
  INIT_REJECT_PERSONAL_TRADE_ACTION_STATUS,

  // Feedback Personal Trade
  START_FEEDBACK_PERSONAL_TRADE,
  FEEDBACK_PERSONAL_TRADE_SUCCESS,
  FEEDBACK_PERSONAL_TRADE_FAILURE,
  INIT_FEEDBACK_PERSONAL_TRADE_ACTION_STATUS,

  // Open/Reset Personal Trade Operation Record Modal Dialog
  PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_RESET,

  // Load Personal Trade Operation Record
  START_LOAD_PERSONAL_TRADE_OPERATION_RECORD,
  LOAD_PERSONAL_TRADE_OPERATION_RECORD_SUCCESS,
  LOAD_PERSONAL_TRADE_OPERATION_RECORD_FAILURE,
  INIT_LOAD_PERSONAL_TRADE_OPERATION_RECORD_ACTION_STATUS,

  // Open/Reset Personal Trade Request Edit Modal Dialog
  PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_RESET,

  // Personal Trade Request Edit Change Input
  PERSONAL_TRADE_REQUEST_EDIT_CHANGE_INPUT,

  // Add Personal Trade Request
  START_ADD_PERSONAL_TRADE_REQUEST,
  ADD_PERSONAL_TRADE_REQUEST_SUCCESS,
  ADD_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_ADD_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Edit Personal Trade Request
  START_EDIT_PERSONAL_TRADE_REQUEST,
  EDIT_PERSONAL_TRADE_REQUEST_SUCCESS,
  EDIT_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_EDIT_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Batch Delete Personal Trade Request
  START_BATCH_DELETE_PERSONAL_TRADE_REQUEST,
  BATCH_DELETE_PERSONAL_TRADE_REQUEST_SUCCESS,
  BATCH_DELETE_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_BATCH_DELETE_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Copy Personal Trade Request
  START_COPY_PERSONAL_TRADE_REQUEST,
  COPY_PERSONAL_TRADE_REQUEST_SUCCESS,
  COPY_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_COPY_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Load BBG Tickers
  START_LOAD_BBG_TICKERS,
  LOAD_BBG_TICKERS_SUCCESS,
  LOAD_BBG_TICKERS_FAILURE,
  INIT_LOAD_BBG_TICKERS_ACTION_STATUS,

  // Clear bbgTickerArray bbgTickerOptions
  CLEAR_BBG_TICKERS,

  // BBG Ticker Select Changed
  BBG_TICKER_SELECT_CHANGED,

  // Load Personal Trade Field History
  START_LOAD_TRADE_FIELD_HISTORY,
  LOAD_TRADE_FIELD_HISTORY_SUCCESS,
  LOAD_TRADE_FIELD_HISTORY_FAILURE,
  INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS,

  // Load Exchange Code
  START_LOAD_EXCHANGE_CODE,
  LOAD_EXCHANGE_CODE_SUCCESS,
  LOAD_EXCHANGE_CODE_FAILURE,
  INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS,

  // Clear exchageCodeArray exchageCodeOptions
  CLEAR_EXCHANGE_CODES,

  // Exchange Code Select Changed
  EXCHANGE_CODE_SELECT_CHANGED,

  // Open/Reset Personal Trade Request Recognize Modal Dialog
  PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_RESET,

  // Recognize Personal Trade Request
  START_RECOGNIZE_PERSONAL_TRADE_REQUEST,
  RECOGNIZE_PERSONAL_TRADE_REQUEST_SUCCESS,
  RECOGNIZE_PERSONAL_TRADE_REQUEST_FAILURE,
  INIT_RECOGNIZE_PERSONAL_TRADE_REQUEST_ACTION_STATUS,

  // Open/Reset Personal Trade Request Operate Modal Dialog
  PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
  PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_RESET,

  // Open/Reset Monthly Report Modal Dialog
  MONTHLY_REPORT_MODAL_DIALOG_OPEN,
  MONTHLY_REPORT_MODAL_DIALOG_RESET,

  // Load Monthly Report
  START_LOAD_MONTHLY_REPORT,
  LOAD_MONTHLY_REPORT_SUCCESS,
  LOAD_MONTHLY_REPORT_FAILURE,
  INIT_LOAD_MONTHLY_REPORT_ACTION_STATUS,

  // Send PA Dealing Apply Monthly Report Email
  START_SEND_PA_DEALING_APPLY_REPORT_EMAIL,
  SEND_PA_DEALING_APPLY_REPORT_EMAIL_SUCCESS,
  SEND_PA_DEALING_APPLY_REPORT_EMAIL_FAILURE,
  INIT_SEND_PA_DEALING_APPLY_REPORT_EMAIL_ACTION_STATUS,


  // 30天持仓相关预检查
  START_QUERY_30_DAY_HOLDING_CHECK_INFO,
  QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS,
  QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE,
  RESET_30_DAY_HOLDING_CHECK_INFO,
  START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,

} from './personalTradeConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Personal Trade
export function queryPersonalTradeInputChange(param) {
  return {
    type: QUERY_PERSONAL_TRADE_INPUT_CHANGE,
    payload: param,
  }
}


function startLoadPersonalTrade() {
  return {
    type: START_LOAD_PERSONAL_TRADE
  };
}

export function loadPersonalTrade(params) {
  return function(dispatch) {
    dispatch(startLoadPersonalTrade());
    httpClient
      .get(UrlConfig.QUERY_PERSONAL_TRADE, params)
      .then(resp => dispatch(loadPersonalTradeSuccess(resp)))
      .catch(err => dispatch(loadPersonalTradeFailure(err)));
  };
}

function loadPersonalTradeSuccess(resp) {
  return {
    type: LOAD_PERSONAL_TRADE_SUCCESS,
    payload: resp
  };
}

function loadPersonalTradeFailure(err) {
  return {
    type: LOAD_PERSONAL_TRADE_FAILURE,
    payload: err
  };
}

export function initLoadPersonalTradeActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_TRADE_ACTION_STATUS
  };
}

// Select Personal Trade Request
export function selectPersonalTradeRequest(payload) {
  return {
    type: SELECT_PERSONAL_TRADE_REQUEST,
    payload
  };
}

// Open/Reset Personal Trade Feedback Modal Dialog
export function openPersonalTradeFeedbackModalDialog(personalTrade) {
  return {
    type: PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_OPEN,
    payload: {
      personalTrade,
    },
  };
}

export function resetPersonalTradeFeedbackModalDialog() {
  return {
    type: PERSONAL_TRADE_FEEDBACK_MODAL_DIALOG_RESET
  };
}

// Personal Trade Feedback Edit Change Input
export function personalTradeFeedbackEditChangeInput({ name, value }) {
  return {
    type: PERSONAL_TRADE_FEEDBACK_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// Approve Personal Trade
function startApprovePersonalTrade() {
  return {
    type: START_APPROVE_PERSONAL_TRADE
  };
}

export function approvePersonalTrade(comments, personalTradeIdArray) {
  const requestParams = {
    personaTradeIdList: personalTradeIdArray,
    comments,
  };

  return function(dispatch) {
    dispatch(startApprovePersonalTrade());
    httpClient
      .post(UrlConfig.APPROVE_PERSONAL_TRADE, requestParams)
      .then(resp => dispatch(approvePersonalTradeSuccess(resp)))
      .catch(err => dispatch(approvePersonalTradeFailure(err)));
  };
}

function approvePersonalTradeSuccess(resp) {
  return {
    type: APPROVE_PERSONAL_TRADE_SUCCESS,
    payload: resp
  };
}

function approvePersonalTradeFailure(err) {
  return {
    type: APPROVE_PERSONAL_TRADE_FAILURE,
    payload: err
  };
}

export function initApprovePersonalTradeActionStatus() {
  return {
    type: INIT_APPROVE_PERSONAL_TRADE_ACTION_STATUS
  };
}

// Final Approve Personal Trade
function startFinalApprovePersonalTrade() {
  return {
    type: START_FINAL_APPROVE_PERSONAL_TRADE
  };
}

export function finalApprovePersonalTrade(comments, personalTradeIdArray) {
  const requestParams = {
    personaTradeIdList: personalTradeIdArray,
    comments,
  };

  return function(dispatch) {
    dispatch(startFinalApprovePersonalTrade());
    httpClient
      .post(UrlConfig.FINAL_APPROVE_PERSONAL_TRADE, requestParams)
      .then(resp => dispatch(finalApprovePersonalTradeSuccess(resp)))
      .catch(err => dispatch(finalApprovePersonalTradeFailure(err)));
  };
}

function finalApprovePersonalTradeSuccess(resp) {
  return {
    type: FINAL_APPROVE_PERSONAL_TRADE_SUCCESS,
    payload: resp
  };
}

function finalApprovePersonalTradeFailure(err) {
  return {
    type: FINAL_APPROVE_PERSONAL_TRADE_FAILURE,
    payload: err
  };
}

export function initFinalApprovePersonalTradeActionStatus() {
  return {
    type: INIT_FINAL_APPROVE_PERSONAL_TRADE_ACTION_STATUS
  };
}



// Reject Personal Trade
function startRejectPersonalTrade() {
  return {
    type: START_REJECT_PERSONAL_TRADE
  };
}

export function rejectPersonalTrade(comments, personalTradeIdArray) {
  const requestParams = {
    personaTradeIdList: personalTradeIdArray,
    comments,
  };

  return function(dispatch) {
    dispatch(startRejectPersonalTrade());
    httpClient
      .post(UrlConfig.REJECT_PERSONAL_TRADE, requestParams)
      .then(resp => dispatch(rejectPersonalTradeSuccess(resp)))
      .catch(err => dispatch(rejectPersonalTradeFailure(err)));
  };
}

function rejectPersonalTradeSuccess(resp) {
  return {
    type: REJECT_PERSONAL_TRADE_SUCCESS,
    payload: resp
  };
}

function rejectPersonalTradeFailure(err) {
  return {
    type: REJECT_PERSONAL_TRADE_FAILURE,
    payload: err
  };
}

export function initRejectPersonalTradeActionStatus() {
  return {
    type: INIT_REJECT_PERSONAL_TRADE_ACTION_STATUS
  };
}

// Feedback Personal Trade
function startFeedbackPersonalTrade() {
  return {
    type: START_FEEDBACK_PERSONAL_TRADE
  };
}

export function feedbackPersonalTrade(id, feedback) {
  const requestParams = {
    id,
    feedback,
  };

  return function(dispatch) {
    dispatch(startFeedbackPersonalTrade());
    httpClient
      .post(UrlConfig.FEEDBACK_PERSONAL_TRADE, requestParams)
      .then(resp => dispatch(feedbackPersonalTradeSuccess(resp)))
      .catch(err => dispatch(feedbackPersonalTradeFailure(err)));
  };
}

function feedbackPersonalTradeSuccess(resp) {
  return {
    type: FEEDBACK_PERSONAL_TRADE_SUCCESS,
    payload: resp
  };
}

function feedbackPersonalTradeFailure(err) {
  return {
    type: FEEDBACK_PERSONAL_TRADE_FAILURE,
    payload: err
  };
}

export function initFeedbackPersonalTradeActionStatus() {
  return {
    type: INIT_FEEDBACK_PERSONAL_TRADE_ACTION_STATUS
  };
}

// Open/Reset Personal Trade Operation Record Modal Dialog
export function openPersonalTradeOperationRecordModalDialog() {
  return {
    type: PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_OPEN,
  };
}

export function resetPersonalTradeOperationRecordModalDialog() {
  return {
    type: PERSONAL_TRADE_OPERATION_RECORD_MODAL_DIALOG_RESET
  };
}

// Load Personal Trade Operation Record
function startLoadPersonalTradeOperationRecord() {
  return {
    type: START_LOAD_PERSONAL_TRADE_OPERATION_RECORD
  };
}

export function loadPersonalTradeOperationRecord() {
  return function(dispatch) {
    dispatch(startLoadPersonalTradeOperationRecord());
    httpClient
      .get(UrlConfig.QUERY_PERSONAL_TRADE_OPERATE_RECORD)
      .then(resp => dispatch(loadPersonalTradeOperationRecordSuccess(resp)))
      .catch(err => dispatch(loadPersonalTradeOperationRecordFailure(err)));
  };
}

function loadPersonalTradeOperationRecordSuccess(resp) {
  return {
    type: LOAD_PERSONAL_TRADE_OPERATION_RECORD_SUCCESS,
    payload: resp
  };
}

function loadPersonalTradeOperationRecordFailure(err) {
  return {
    type: LOAD_PERSONAL_TRADE_OPERATION_RECORD_FAILURE,
    payload: err
  };
}

export function initLoadPersonalTradeOperationRecordActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_TRADE_OPERATION_RECORD_ACTION_STATUS
  };
}

// Open/Reset Personal Trade Request Edit Modal Dialog
export function openPersonalTradeRequestEditModalDialog(editMode, tradeRequest) {
  return {
    type: PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      editMode,
      tradeRequest,
    },
  };
}

export function resetPersonalTradeRequestEditModalDialog() {
  return {
    type: PERSONAL_TRADE_REQUEST_EDIT_MODAL_DIALOG_RESET
  };
}

// Personal Trade Request Edit Change Input
export function personalTradeRequestEditChangeInput({ name, value }) {
  return {
    type: PERSONAL_TRADE_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// Add Personal Trade Request
function startAddPersonalTradeRequest() {
  return {
    type: START_ADD_PERSONAL_TRADE_REQUEST
  };
}

export function addPersonalTradeRequest(tradeRequest) {

  console.log(tradeRequest);

  return function(dispatch) {
    dispatch(startAddPersonalTradeRequest());
    httpClient
      .post(UrlConfig.NEW_PERSONAL_TRADE, tradeRequest)
      .then(resp => dispatch(addPersonalTradeRequestSuccess(resp)))
      .catch(err => dispatch(addPersonalTradeRequestFailure(err)));
  };
}

function addPersonalTradeRequestSuccess(resp) {
  return {
    type: ADD_PERSONAL_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function addPersonalTradeRequestFailure(err) {
  return {
    type: ADD_PERSONAL_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initAddPersonalTradeRequestActionStatus() {
  return {
    type: INIT_ADD_PERSONAL_TRADE_REQUEST_ACTION_STATUS
  };
}

// Edit Personal Trade Request
function startEditPersonalTradeRequest() {
  return {
    type: START_EDIT_PERSONAL_TRADE_REQUEST
  };
}

export function editPersonalTradeRequest(tradeRequest) {
  return function(dispatch) {
    dispatch(startEditPersonalTradeRequest());
    httpClient
      .post(UrlConfig.UPDATED_PERSONAL_TRADE, tradeRequest)
      .then(resp => dispatch(editPersonalTradeRequestSuccess(resp)))
      .catch(err => dispatch(editPersonalTradeRequestFailure(err)));
  };
}

function editPersonalTradeRequestSuccess(resp) {
  return {
    type: EDIT_PERSONAL_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function editPersonalTradeRequestFailure(err) {
  return {
    type: EDIT_PERSONAL_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initEditPersonalTradeRequestActionStatus() {
  return {
    type: INIT_EDIT_PERSONAL_TRADE_REQUEST_ACTION_STATUS
  };
}


// Batch Delete Personal Trade Request
function startBatchDeletePersonalTradeRequest() {
  return {
    type: START_BATCH_DELETE_PERSONAL_TRADE_REQUEST
  };
}

export function batchDeletePersonalTradeRequest(comments, tradeRequestIdList) {
  const requestParams = {
    personaTradeIdList: tradeRequestIdList,
    comments,
  };

  return function(dispatch) {
    dispatch(startBatchDeletePersonalTradeRequest());
    httpClient
      .post(UrlConfig.BATCH_DELETED_PERSONAL_TRADES, requestParams)
      .then(resp => dispatch(batchDeletePersonalTradeRequestSuccess(resp)))
      .catch(err => dispatch(batchDeletePersonalTradeRequestFailure(err)));
  };
}

function batchDeletePersonalTradeRequestSuccess(resp) {
  return {
    type: BATCH_DELETE_PERSONAL_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function batchDeletePersonalTradeRequestFailure(err) {
  return {
    type: BATCH_DELETE_PERSONAL_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initBatchDeletePersonalTradeRequestActionStatus() {
  return {
    type: INIT_BATCH_DELETE_PERSONAL_TRADE_REQUEST_ACTION_STATUS
  };
}


// =================================================================
// Load BBG Tickers
function startLoadBBGTickers() {
  return {
    type: START_LOAD_BBG_TICKERS
  };
}

export function loadBbgTickers(queryText) {
  const requestParams = {
    'query-text': queryText,
    'page': 'personalTrade',
    'source': 'Admin',
  };

  return function(dispatch) {
    dispatch(startLoadBBGTickers());
    httpClient
      .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
      .then(resp => dispatch(loadBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadBbgTickersFailure(err)));
  };
}

function loadBbgTickersSuccess(resp) {
  return {
    type: LOAD_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadBbgTickersFailure(err) {
  return {
    type: LOAD_BBG_TICKERS_FAILURE,
    payload: err
  };
}

export function initLoadBbgTickersActionStatus() {
  return {
    type: INIT_LOAD_BBG_TICKERS_ACTION_STATUS
  };
}

// Clear bbgTickerArray bbgTickerOptions
export function clearBbgTickers() {
  return {
    type: CLEAR_BBG_TICKERS,
  };
}

// BBG Ticker Select Changed
export function bbgTickerSelectChanged(bbgTicker) {
  return {
    type: BBG_TICKER_SELECT_CHANGED,
    payload: bbgTicker
  };
}

// Load Personal Trade Field History
function startLoadPersonalTradeFieldHistory() {
  return {
    type: START_LOAD_TRADE_FIELD_HISTORY
  };
}

export function loadPersonalTradeFieldHistory(employee) {
  const requestParams = {
    employee,
  };

  return function(dispatch) {
    dispatch(startLoadPersonalTradeFieldHistory());
    httpClient
      .get(UrlConfig.PERSONAL_TRADE_FIELD_HISTORY, requestParams)
      .then(resp => dispatch(loadPersonalTradeFieldHistorySuccess(resp)))
      .catch(err => dispatch(loadPersonalTradeFieldHistoryFailure(err)));
  };
}

function loadPersonalTradeFieldHistorySuccess(resp) {
  return {
    type: LOAD_TRADE_FIELD_HISTORY_SUCCESS,
    payload: resp
  };
}

function loadPersonalTradeFieldHistoryFailure(err) {
  return {
    type: LOAD_TRADE_FIELD_HISTORY_FAILURE,
    payload: err
  };
}

export function initLoadPersonalTradeFieldHistoryActionStatus() {
  return {
    type: INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS
  };
}

// Load Exchange Code
function startLoadExchangeCode() {
  return {
    type: START_LOAD_EXCHANGE_CODE
  };
}

export function loadExchangeCode(queryText) {
  const requestParams = {
    'query-text': queryText,
  };

  return function(dispatch) {
    dispatch(startLoadExchangeCode());
    httpClient
      .get(UrlConfig.QUERY_EXCHANGE_CODE, requestParams)
      .then(resp => dispatch(loadExchangeCodeSuccess(resp)))
      .catch(err => dispatch(loadExchangeCodeFailure(err)));
  };
}

function loadExchangeCodeSuccess(resp) {
  return {
    type: LOAD_EXCHANGE_CODE_SUCCESS,
    payload: resp
  };
}

function loadExchangeCodeFailure(err) {
  return {
    type: LOAD_EXCHANGE_CODE_FAILURE,
    payload: err
  };
}

export function initLoadExchangeCodeActionStatus() {
  return {
    type: INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS
  };
}

// Clear exchageCodeArray exchageCodeOptions
export function clearExchangeCodes() {
  return {
    type: CLEAR_EXCHANGE_CODES,
  };
}

// Exchange Code Select Changed
export function exchangeCodeSelectChanged(exchangeCode) {
  return {
    type: EXCHANGE_CODE_SELECT_CHANGED,
    payload: exchangeCode
  };
}

// Open/Reset Personal Trade Request Recognize Modal Dialog
export function openPersonalTradeRequestRecognizeModalDialog(personalTradeRequestIdList) {
  return {
    type: PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_OPEN,
    payload: {
      personalTradeRequestIdList,
    },
  };
}

export function resetPersonalTradeRequestRecognizeModalDialog() {
  return {
    type: PERSONAL_TRADE_REQUEST_RECOGNIZE_MODAL_DIALOG_RESET
  };
}

// Recognize Personal Trade Request
function startRecognizePersonalTradeRequest() {
  return {
    type: START_RECOGNIZE_PERSONAL_TRADE_REQUEST
  };
}

export function recognizePersonalTradeRequest(personalTradeRequestIdList) {
  return function(dispatch) {
    dispatch(startRecognizePersonalTradeRequest());
    httpClient
      .post(UrlConfig.RECOGNIZE_PERSONAL_TRADE_REQUEST, personalTradeRequestIdList)
      .then(resp => dispatch(recognizePersonalTradeRequestSuccess(resp)))
      .catch(err => dispatch(recognizePersonalTradeRequestFailure(err)));
  };
}

function recognizePersonalTradeRequestSuccess(resp) {
  return {
    type: RECOGNIZE_PERSONAL_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function recognizePersonalTradeRequestFailure(err) {
  return {
    type: RECOGNIZE_PERSONAL_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initRecognizePersonalTradeRequestActionStatus() {
  return {
    type: INIT_RECOGNIZE_PERSONAL_TRADE_REQUEST_ACTION_STATUS
  };
}

// Open/Reset Personal Trade Request Operate Modal Dialog
export function openPersonalTradeRequestOperateModalDialog(operateMode, isNonCompliance, personalTradeRequestIdList) {
  return {
    type: PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
    payload: {
      operateMode,
      isNonCompliance,
      personalTradeRequestIdList,
    },
  };
}

export function resetPersonalTradeRequestOperateModalDialog() {
  return {
    type: PERSONAL_TRADE_REQUEST_OPERATE_MODAL_DIALOG_RESET
  };
}

// Open/Reset Monthly Report Modal Dialog
export function openMonthlyReportModalDialog() {
  return {
    type: MONTHLY_REPORT_MODAL_DIALOG_OPEN,
    payload: {
    },
  };
}

export function resetMonthlyReportModalDialog() {
  return {
    type: MONTHLY_REPORT_MODAL_DIALOG_RESET
  };
}

// Load Monthly Report
function startLoadMonthlyReport() {
  return {
    type: START_LOAD_MONTHLY_REPORT
  };
}

export function loadMonthlyReport(dateText) {
  const requestParams = {
    'date-text': dateText,
  };

  return function(dispatch) {
    dispatch(startLoadMonthlyReport());
    httpClient
      .get(UrlConfig.LOAD_MONTHLY_REPORT, requestParams)
      .then(resp => dispatch(loadMonthlyReportSuccess(resp)))
      .catch(err => dispatch(loadMonthlyReportFailure(err)));
  };
}

function loadMonthlyReportSuccess(resp) {
  return {
    type: LOAD_MONTHLY_REPORT_SUCCESS,
    payload: resp
  };
}

function loadMonthlyReportFailure(err) {
  return {
    type: LOAD_MONTHLY_REPORT_FAILURE,
    payload: err
  };
}

export function initLoadMonthlyReportActionStatus() {
  return {
    type: INIT_LOAD_MONTHLY_REPORT_ACTION_STATUS
  };
}

// Send PA Dealing Apply Monthly Report Email
function startSendPADealingApplyMonthlyReportEmail() {
  return {
    type: START_SEND_PA_DEALING_APPLY_REPORT_EMAIL
  };
}

export function sendPADealingApplyMonthlyReportEmail(dateText) {
  // const requestParams = {
  //   'date-text': dateText,
  // };

  return function(dispatch) {
    dispatch(startSendPADealingApplyMonthlyReportEmail());
    httpClient
      .post(UrlConfig.SEND_PA_DEALING_APPLY_REPORT_EMAIL, dateText)
      .then(resp => dispatch(sendPADealingApplyMonthlyReportEmailSuccess(resp)))
      .catch(err => dispatch(sendPADealingApplyMonthlyReportEmailFailure(err)));
  };
}

function sendPADealingApplyMonthlyReportEmailSuccess(resp) {
  return {
    type: SEND_PA_DEALING_APPLY_REPORT_EMAIL_SUCCESS,
    payload: resp
  };
}

function sendPADealingApplyMonthlyReportEmailFailure(err) {
  return {
    type: SEND_PA_DEALING_APPLY_REPORT_EMAIL_FAILURE,
    payload: err
  };
}

export function initSendPADealingApplyMonthlyReportEmailActionStatus() {
  return {
    type: INIT_SEND_PA_DEALING_APPLY_REPORT_EMAIL_ACTION_STATUS
  };
}

// Copy Personal Trade Request
function startCopyPersonalTradeRequest() {
  return {
    type: START_COPY_PERSONAL_TRADE_REQUEST
  };
}

export function copyPersonalTradeRequest(tradeRequest) {
  return function(dispatch) {
    dispatch(startCopyPersonalTradeRequest());
    httpClient
      .post(UrlConfig.COPIED_PERSONAL_TRADE, tradeRequest)
      .then(resp => dispatch(copyPersonalTradeRequestSuccess(resp)))
      .catch(err => dispatch(copyPersonalTradeRequestFailure(err)));
  };
}

function copyPersonalTradeRequestSuccess(resp) {
  return {
    type: COPY_PERSONAL_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function copyPersonalTradeRequestFailure(err) {
  return {
    type: COPY_PERSONAL_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initCopyPersonalTradeRequestActionStatus() {
  return {
    type: INIT_COPY_PERSONAL_TRADE_REQUEST_ACTION_STATUS
  };
}


// 30天持仓相关预检查
function startQuery30DayHoldingCheckInfo() {
  return {
    type: START_QUERY_30_DAY_HOLDING_CHECK_INFO,
  };
}
export function query30DayHoldingCheckInfo(personalTrade) {
  return function(dispatch) {
    dispatch(startQuery30DayHoldingCheckInfo());
    httpClient
      .post(UrlConfig.QUERY_30_DAY_HOLDING_CHECK_INFO, personalTrade)
      .then(resp => dispatch(query30DayHoldingCheckInfoSuccess(resp)))
      .catch(err => dispatch(query30DayHoldingCheckInfoFailure(err)));
  };
}
function query30DayHoldingCheckInfoSuccess(resp) {
  return {
    type: QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS,
    payload: resp
  };
}
function query30DayHoldingCheckInfoFailure(err) {
  return {
    type: QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE,
    payload: err
  };
}
export function reset30DayHoldingCheckInfo() {
  return {
    type: RESET_30_DAY_HOLDING_CHECK_INFO,
  }
}
export function startTrigger30DayHoldingCheckInfoStatus() {
  return {
    type: START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  }
}
export function stopTrigger30DayHoldingCheckInfoStatus() {
  return {
    type: STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  }
}