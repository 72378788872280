import React from 'react';

import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { Select } from 'antd';

import {
  QUERY_GIFT_HOSPITALITY_INPUT_CHANGE,

  // Load Gift Hospitality Request
  START_LOAD_GIFT_HOSPITALITY_REQUEST,
  LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Select Gift Hospitality Request
  SELECT_GIFT_HOSPITALITY_REQUEST,

  // Open/Reset Gift Hospitality Request Modal Dialog
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN,
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET,

  // Gift Hospitality Request Edit Change Input
  GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT,

  // Approve Gift Hospitality Request
  START_APPROVE_GIFT_HOSPITALITY_REQUEST,
  APPROVE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  APPROVE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_APPROVE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Reject Gift Hospitality Request
  START_REJECT_GIFT_HOSPITALITY_REQUEST,
  REJECT_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  REJECT_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_REJECT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Feedback Gift Hospitality Request
  START_FEEDBACK_GIFT_HOSPITALITY_REQUEST,
  FEEDBACK_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  FEEDBACK_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_FEEDBACK_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

} from './giftHospitalityRequestConstants';

import {
  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from '../../home/homeConstants';

const Option = Select.Option;

const initialState = {
  gitfHospitalitySearchFields: {
    employee: null,
    dateStart: null,
    dateEnd: null,
  },

  giftHospitalityRequestGridColumns: GridColumnMap.giftHospitalityRequestGridColumns,

  // Gift Hospitality Request
  giftHospitalityRequestArray: [],

  giftHospitalityRequestEditControlModal: {
    isOpened: false,
    respErrMsg: '',
    fields: {
      id: '',
      feedback: '',
    }
  },

  // Selected Gift Hospitality Request
  selectedGiftHospitalityRequestArray: [],

  // Load Gift Hospitality Request Action Status
  loadGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,

  // Approve Gift Hospitality Request Action Status
  approveGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,

  // Reject Gift Hospitality Request Action Status
  rejectGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,

  // Feedback Gift Hospitality Request Action Status
  feedbackGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,

  // Gfit Hospitality Upload / Preview Url
  uploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_GIFT_HOSPITALITY_ATTACHMENT,
  previewImgPath: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_GIFT_HOSPITALITY_ATTACHMENT,
}

// Load Gift Hospitality Request
function startLoadGiftHospitalityRequest(state) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
    giftHospitalityRequestArray: resp.data,
  };
}

function loadGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}


// Select Gift Hospitality Request
function selectGiftHospitalityRequest(state, payload) {
  return {
    ...state,
    selectedGiftHospitalityRequestArray: payload,
  };
}

// Open/Reset Gift Hospitality Request Modal Dialog
function openGiftHospitalityRequestEditModalDialog(state, {giftHospitalityRequest}) {
  return {
    ...state,
    giftHospitalityRequestEditControlModal: {
      ...state.giftHospitalityRequestEditControlModal,
      isOpened: true,
      fields: {
        ...state.giftHospitalityRequestEditControlModal.fields,
        id: giftHospitalityRequest.id,
        feedback: giftHospitalityRequest.feedback,
      }
    },
  };
}

function resetGiftHospitalityRequestEditModalDialog(state) {
  return {
    ...state,
    giftHospitalityRequestEditControlModal: initialState.giftHospitalityRequestEditControlModal,
  };
}

// Gift Hospitality Request Edit Change Input
function giftHospitalityRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.giftHospitalityRequestEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    giftHospitalityRequestEditControlModal: {
      ...state.giftHospitalityRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// Approve Gift Hospitality Request
function startApproveGiftHospitalityRequest(state) {
  return {
    ...state,
    approveGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function approveGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    approveGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedGiftHospitalityRequestArray: [],
  };
}

function approveGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    approveGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initApproveGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    approveGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Reject Gift Hospitality Request
function startRejectGiftHospitalityRequest(state) {
  return {
    ...state,
    rejectGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function rejectGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    rejectGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedGiftHospitalityRequestArray: [],
  };
}

function rejectGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    rejectGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initRejectGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    rejectGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Feedback Gift Hospitality Request
function startFeedbackGiftHospitalityRequest(state) {
  return {
    ...state,
    feedbackGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function feedbackGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    feedbackGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedGiftHospitalityRequestArray: [],
  };
}

function feedbackGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    feedbackGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initFeedbackGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    feedbackGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}

function queryGiftHospitalityInputChange(state, {name, value}) {
  return {
    ...state,
    gitfHospitalitySearchFields: {
      ...state.gitfHospitalitySearchFields,
      [name]: value,
    }
  }
}


function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}


export default createReducer(initialState, {
  // Load Gift Hospitality Request
  [START_LOAD_GIFT_HOSPITALITY_REQUEST]: startLoadGiftHospitalityRequest,
  [LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS]: loadGiftHospitalityRequestSuccess,
  [LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE]: loadGiftHospitalityRequestFailure,
  [INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initLoadGiftHospitalityRequestActionStatus,
  
  // Select Gift Hospitality Request
  [SELECT_GIFT_HOSPITALITY_REQUEST]: selectGiftHospitalityRequest,

  // Open/Reset Gift Hospitality Request Modal Dialog
  [GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN]: openGiftHospitalityRequestEditModalDialog,
  [GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET]: resetGiftHospitalityRequestEditModalDialog,

  // Gift Hospitality Request Edit Change Input
  [GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT]: giftHospitalityRequestEditChangeInput,

  // Approve Gift Hospitality Request
  [START_APPROVE_GIFT_HOSPITALITY_REQUEST]: startApproveGiftHospitalityRequest,
  [APPROVE_GIFT_HOSPITALITY_REQUEST_SUCCESS]: approveGiftHospitalityRequestSuccess,
  [APPROVE_GIFT_HOSPITALITY_REQUEST_FAILURE]: approveGiftHospitalityRequestFailure,
  [INIT_APPROVE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initApproveGiftHospitalityRequestActionStatus,

  // Reject Gift Hospitality Request
  [START_REJECT_GIFT_HOSPITALITY_REQUEST]: startRejectGiftHospitalityRequest,
  [REJECT_GIFT_HOSPITALITY_REQUEST_SUCCESS]: rejectGiftHospitalityRequestSuccess,
  [REJECT_GIFT_HOSPITALITY_REQUEST_FAILURE]: rejectGiftHospitalityRequestFailure,
  [INIT_REJECT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initRejectGiftHospitalityRequestActionStatus,

  // Feedback Gift Hospitality Request
  [START_FEEDBACK_GIFT_HOSPITALITY_REQUEST]: startFeedbackGiftHospitalityRequest,
  [FEEDBACK_GIFT_HOSPITALITY_REQUEST_SUCCESS]: feedbackGiftHospitalityRequestSuccess,
  [FEEDBACK_GIFT_HOSPITALITY_REQUEST_FAILURE]: feedbackGiftHospitalityRequestFailure,
  [INIT_FEEDBACK_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initFeedbackGiftHospitalityRequestActionStatus,

  [QUERY_GIFT_HOSPITALITY_INPUT_CHANGE]: queryGiftHospitalityInputChange,
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

});