import React, { Component } from 'react';
import { Modal, message, Button, Row, Col } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import OperationRecordGrid from './OperationRecordGrid';


class OperationRecordDetail extends Component {

    closeDetailPage() {
        this.props.doOperationRecordAction({action:'RESET_DETAIL'});
    }


    render() {
        const {
            operationRecordDialogModal,
        } = this.props.operationRecord;
        const {
            isDetailOpened,
            detail,
        } = operationRecordDialogModal;

        let beforeObj = JSON.parse('{}');
        let afterObj = JSON.parse('{}');
        if(!!detail && !!detail.before) {
            beforeObj = JSON.parse(detail.before);
        }
        if(!!detail && !!detail.after) {
            afterObj = JSON.parse(detail.after);
        }

        const detailTableBodyArray = [];
        for(let key in beforeObj) {
            detailTableBodyArray.push(<tr key={key}><td>{key}</td><td>{beforeObj[key]}</td><td>{afterObj[key]}</td></tr>);
        }
        for(let key in afterObj) {
            if(!beforeObj.hasOwnProperty(key)) {
                detailTableBodyArray.push(<tr key={key}><td>{key}</td><td>{beforeObj[key]}</td><td>{afterObj[key]}</td></tr>);
            }
        }

        return (
            <div>
                <Modal
                    title={'Operation Record Detail'}
                    centered
                    width={800}
                    visible={isDetailOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.closeDetailPage()}
                    footer={[
                        <Button key="back" onClick={() => this.closeDetailPage()}>
                          Close
                        </Button>,
                      ]}
                >

                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>operator:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operator: ''}</Col>
                        <Col span={6} align={'right'}><b>operateTime:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operateTime: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>module:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.module: ''}</Col>
                        <Col span={6} align={'right'}><b>dataId:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.dataId: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>operateSource:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operateSource: ''}</Col>
                        <Col span={6} align={'right'}><b>operateType:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.operateType: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>dataType:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.dataType: ''}</Col>
                        <Col span={6} align={'right'}><b>otherInfo:</b></Col>
                        <Col span={6} align={'left'}>{!!detail ? detail.otherInfo: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>comments:</b></Col>
                        <Col span={18} align={'left'}>{!!detail ? detail.comments: ''}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={24}>
                            <table cellPadding='2' border='1' width='100%'>
                                <thead align='center'><tr><th>Field Name</th><th>Value Before Operation</th><th>Value After Operation</th></tr></thead>
                                <tbody align='center'>{detailTableBodyArray}</tbody>
                            </table>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}


export default OperationRecordDetail;

