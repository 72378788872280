// Load Submitted Restricted Security Request
export const QUERY_RESTRICTED_SECURITY_INPUT_CHANGE = 'QUERY_RESTRICTED_SECURITY_INPUT_CHANGE';
export const START_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST = 'START_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST';
export const LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_FAILURE = 'LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Select Restricted Security Request
export const SELECT_RESTRICTED_SECURITY_REQUEST = 'SELECT_RESTRICTED_SECURITY_REQUEST';

// Open/Reset Restricted Security Request Operate Modal Dialog
export const RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET';

// Approve Restricted Security Request
export const START_APPROVE_RESTRICTED_SECURITY_REQUEST = 'START_APPROVE_RESTRICTED_SECURITY_REQUEST';
export const APPROVE_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'APPROVE_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const APPROVE_RESTRICTED_SECURITY_REQUEST_FAILURE = 'APPROVE_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_APPROVE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_APPROVE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Reject Restricted Security Request
export const START_REJECT_RESTRICTED_SECURITY_REQUEST = 'START_REJECT_RESTRICTED_SECURITY_REQUEST';
export const REJECT_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'REJECT_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const REJECT_RESTRICTED_SECURITY_REQUEST_FAILURE = 'REJECT_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_REJECT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_REJECT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Feedback Restricted Security Request
export const START_FEEDBACK_RESTRICTED_SECURITY_REQUEST = 'START_FEEDBACK_RESTRICTED_SECURITY_REQUEST';
export const FEEDBACK_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'FEEDBACK_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const FEEDBACK_RESTRICTED_SECURITY_REQUEST_FAILURE = 'FEEDBACK_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_FEEDBACK_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_FEEDBACK_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Open/Reset Restricted Security Operation Record Modal Dialog
export const RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_RESET';

// Load Restricted Security Operation Record
export const START_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD = 'START_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD';
export const LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_SUCCESS = 'LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_SUCCESS';
export const LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_FAILURE = 'LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_FAILURE';
export const INIT_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_ACTION_STATUS';

// Open/Reset Restricted Security Request Edit Modal Dialog
export const RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_RESET';

// Restricted Security Request Edit Change Input
export const RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT = 'RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT';

// Load Restricted Security Request BBG Tickers
export const START_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS = 'START_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS';
export const LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_SUCCESS = 'LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_SUCCESS';
export const LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_FAILURE = 'LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_FAILURE';
export const INIT_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_ACTION_STATUS';

// Clear Restricted Security Request BBG Ticker
export const CLEAR_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS = 'CLEAR_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS';

// Restricted Security Request BBG Ticker Select Changed
export const RESTRICTED_SECURITY_REQUEST_BBG_TICKER_SELECT_CHANGED = 'RESTRICTED_SECURITY_REQUEST_BBG_TICKER_SELECT_CHANGED';

// Restricted Security Request Fund Code Select Changed
export const RESTRICTED_SECURITY_REQUEST_FUND_CODE_SELECT_CHANGED = 'RESTRICTED_SECURITY_REQUEST_FUND_CODE_SELECT_CHANGED';

// Restricted Security Request Book Code Select Changed
export const RESTRICTED_SECURITY_REQUEST_BOOK_CODE_SELECT_CHANGED = 'RESTRICTED_SECURITY_REQUEST_BOOK_CODE_SELECT_CHANGED';

// Update Restricted Security Request
export const START_UPDATE_RESTRICTED_SECURITY_REQUEST = 'START_UPDATE_RESTRICTED_SECURITY_REQUEST';
export const UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE = 'UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// add
export const START_ADD_RESTRICTED_SECURITY_REQUEST = 'START_ADD_RESTRICTED_SECURITY_REQUEST';
export const ADD_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'ADD_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const ADD_RESTRICTED_SECURITY_REQUEST_FAILURE = 'ADD_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_ADD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_ADD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Show Valid Restricted Rules
export const SHOW_VALID_RESTRICTED_RULES = 'SHOW_VALID_RESTRICTED_RULES';

// Show All Restricted Rules
export const SHOW_ALL_RESTRICTED_RULES = 'SHOW_ALL_RESTRICTED_RULES';


export const LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS = 'LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS';
export const LOAD_RESTRICTED_SECURITIES_RULE_FAILURE = 'LOAD_RESTRICTED_SECURITIES_RULE_FAILURE';
export const ADD_RESTRICTED_SECURITIES_RULE_SUCCESS = 'ADD_RESTRICTED_SECURITIES_RULE_SUCCESS';
export const ADD_RESTRICTED_SECURITIES_RULE_FAILURE = 'ADD_RESTRICTED_SECURITIES_RULE_FAILURE';
export const UPDATE_RESTRICTED_SECURITIES_RULE_SUCCESS = 'UPDATE_RESTRICTED_SECURITIES_RULE_SUCCESS';
export const UPDATE_RESTRICTED_SECURITIES_RULE_FAILURE = 'UPDATE_RESTRICTED_SECURITIES_RULE_FAILURE';
export const RESTRICTED_RULE_INPUT_CHANGE = 'RESTRICTED_RULE_INPUT_CHANGE';
export const OPEN_RESTRICTED_RULE_DIALOG = 'OPEN_RESTRICTED_RULE_DIALOG';
export const CLOSE_RESTRICTED_RULE_DIALOG = 'CLOSE_RESTRICTED_RULE_DIALOG';
export const OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG = 'OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG';
export const CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG = 'CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG';