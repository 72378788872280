import React, { Component } from 'react';
import { Input, Form, Modal, Button, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const TextArea = Input.TextArea;

class DelMeetingAndTrainingGroupModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingGroupQueryFields;
        this.props.loadMeetingAndTrainingGroupInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'inactive') {
            return 'Inactive User Access Info';
        } if(mode === 'send'){
            return 'Send email '; 
        } else {
            return 'DEL Meeting or Training Info';
        }
    }
    componentDidUpdate() {
        const {
            delMeetingAndTrainingGroupStatus,
        } = this.props;
        if(delMeetingAndTrainingGroupStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delMeetingAndTrainingGroupModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelMeetingAndTrainingGroupActionStatus();
            this.props.resetDelMeetingAndTrainingGroupModal();
            this.refreshQuery();
        }
        if(delMeetingAndTrainingGroupStatus === ACTION_STATUS.ERROR) {
            this.props.initDelMeetingAndTrainingGroupActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delMeetingAndTrainingGroupChangeInput({name, value});
    }


    submitDelMeetingAndTrainingGroup = () => {

        Modal.confirm({
            title: 'Are you sure for this record?',
            onOk: () => {

                const {
                    mode,
                } = this.props.delMeetingAndTrainingGroupModal;
                const {
                    id,
                    comments,
                } = this.props.delMeetingAndTrainingGroupModal.fields;
                this.props.delMeetingAndTrainingGroup({id, mode, comments});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delMeetingAndTrainingGroupModal,
            delMeetingAndTrainingGroupStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delMeetingAndTrainingGroupModal;


        const isSubmitLoading = delMeetingAndTrainingGroupStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={400}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelMeetingAndTrainingGroupModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelMeetingAndTrainingGroupModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelMeetingAndTrainingGroup}>
                          Submit
                        </Button>,
                      ]}
                >
                        <TextArea 
                            style={{ width: 400 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelMeetingAndTrainingGroupModalDialogForm = Form.create({ name: 'DelMeetingAndTrainingGroupModalDialog' })(DelMeetingAndTrainingGroupModalDialog);
export default DelMeetingAndTrainingGroupModalDialogForm;
