
import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    START_QUERY_GROUP_STRUCTURE,
    QUERY_GROUP_STRUCTURE_SUCCESS,
    QUERY_GROUP_STRUCTURE_FAILURE,
    INIT_QUERY_GROUP_STRUCTURE_ACTION_STATUS,
    // detail
    OPEN_GROUP_STRUCTURE_DETAIL_DIALOG,
    RESET_GROUP_STRUCTURE_DETAIL_DIALOG,
    // select
    SELECT_GROUP_STRUCTURE,
    // add or edit
    OPEN_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL,
    RESET_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL,
    ADD_OR_EDIT_GROUP_STRUCTURE_CHANGE_INPUT,
    START_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE,
    SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_SUCCESS,
    SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_ACTION_STATUS,
    // del
    OPEN_DEL_GROUP_STRUCTURE_MODAL,
    RESET_DEL_GROUP_STRUCTURE_MODAL,
    DEL_GROUP_STRUCTURE_CHANGE_INPUT,
    START_SUBMIT_DEL_GROUP_STRUCTURE,
    SUBMIT_DEL_GROUP_STRUCTURE_SUCCESS,
    SUBMIT_DEL_GROUP_STRUCTURE_FAILURE,
    INIT_SUBMIT_DEL_GROUP_STRUCTURE_ACTION_STATUS,


} from './GroupStructureConstants';


// query
function startQueryGroupStructure() {
    return {
      type: START_QUERY_GROUP_STRUCTURE,
    };
}
export function queryGroupStructure() {
    return function(dispatch) {
        dispatch(startQueryGroupStructure());
        httpClient
          .get(UrlConfig.QUERY_GROUP_STRUCTURE)
          .then(resp => dispatch(queryGroupStructureSuccess(resp)))
          .catch(err => dispatch(queryGroupStructureFailure(err)));
    };
}
function queryGroupStructureSuccess(resp) {
    return {
      type: QUERY_GROUP_STRUCTURE_SUCCESS,
      payload: resp,
    };
}
function queryGroupStructureFailure(err) {
    return {
        type: QUERY_GROUP_STRUCTURE_FAILURE,
        payload: err,
    };
}
export function initQueryGroupStructureActionStatus() {
    return {
      type: INIT_QUERY_GROUP_STRUCTURE_ACTION_STATUS,
    };
}


// detail
export function openGroupStructureDetailDialog(data) {
    return {
        type: OPEN_GROUP_STRUCTURE_DETAIL_DIALOG,
        payload: data,
    }
}
export function resetGroupStructureDetailDialog() {
    return {
        type: RESET_GROUP_STRUCTURE_DETAIL_DIALOG,
    }
}


// select
export function selectdGroupStructure(array) {
    return {
        type: SELECT_GROUP_STRUCTURE,
        payload: array,
    }
}


// add or edit
export function openAddOrEditGroupStructureModal(opType, data) {
    return {
        type: OPEN_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL,
        payload: {opType, data},
    }
}
export function resetAddOrEditGroupStructureModal() {
    return {
        type: RESET_ADD_OR_EDIT_GROUP_STRUCTURE_MODAL,
    }
}
export function addOrEditGroupStructureChangeInput(input) {
    return {
        type: ADD_OR_EDIT_GROUP_STRUCTURE_CHANGE_INPUT,
        payload: input,
    }
}


function startSubmitAddOrEditGroupStructure() {
    return {
      type: START_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE,
    };
}
export function submitAddOrEditGroupStructure(data) {
    return function(dispatch) {
        dispatch(startSubmitAddOrEditGroupStructure());
        httpClient
          .post(UrlConfig.SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE, data)
          .then(resp => dispatch(submitAddOrEditGroupStructureSuccess(resp)))
          .catch(err => dispatch(submitAddOrEditGroupStructureFailure(err)));
    };
}
function submitAddOrEditGroupStructureSuccess(resp) {
    return {
      type: SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_SUCCESS,
      payload: resp,
    };
}
function submitAddOrEditGroupStructureFailure(err) {
    return {
        type: SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_FAILURE,
        payload: err,
    };
}
export function initSubmitAddOrEditGroupStructureActionStatus() {
    return {
      type: INIT_SUBMIT_ADD_OR_EDIT_GROUP_STRUCTURE_ACTION_STATUS,
    };
}



// del
export function openDelGroupStructureModal(data) {
    return {
        type: OPEN_DEL_GROUP_STRUCTURE_MODAL,
        payload: data,
    }
}
export function resetDelGroupStructureModal() {
    return {
        type: RESET_DEL_GROUP_STRUCTURE_MODAL,
    }
}
export function delGroupStructureChangeInput(input) {
    return {
        type: DEL_GROUP_STRUCTURE_CHANGE_INPUT,
        payload: input,
    }
}


function startSubmitDelGroupStructure() {
    return {
      type: START_SUBMIT_DEL_GROUP_STRUCTURE,
    };
}
export function submitDelGroupStructure(data) {
    return function(dispatch) {
        dispatch(startSubmitDelGroupStructure());
        httpClient
          .post(UrlConfig.SUBMIT_DEL_GROUP_STRUCTURE, data)
          .then(resp => dispatch(submitDelGroupStructureSuccess(resp)))
          .catch(err => dispatch(submitDelGroupStructureFailure(err)));
    };
}
function submitDelGroupStructureSuccess(resp) {
    return {
      type: SUBMIT_DEL_GROUP_STRUCTURE_SUCCESS,
      payload: resp,
    };
}
function submitDelGroupStructureFailure(err) {
    return {
        type: SUBMIT_DEL_GROUP_STRUCTURE_FAILURE,
        payload: err,
    };
}
export function initSubmitDelGroupStructureActionStatus() {
    return {
      type: INIT_SUBMIT_DEL_GROUP_STRUCTURE_ACTION_STATUS,
    };
}