export const TRADE_REQUEST_STATUS_CODE = {
  NEW: '1',
  MODIFIED: '2',
  SUBMITTED: '3',
  UNKNOWN: '0',
};

export const TRADE_REQUEST_STATUS_LABEL = {
  NEW: 'New',
  MODIFIED: 'Modified',
  SUBMITTED: 'Submitted',
  UNKNOWN: 'Unknown',
};

export const TRADE_REQUEST_STATUS_MAP = [
  // NEW
  {
    code: TRADE_REQUEST_STATUS_CODE.NEW,
    label: TRADE_REQUEST_STATUS_LABEL.NEW
  },
  // MODIFIED
  {
    code: TRADE_REQUEST_STATUS_CODE.MODIFIED,
    label: TRADE_REQUEST_STATUS_LABEL.MODIFIED
  },
  // SUBMITTED
  {
    code: TRADE_REQUEST_STATUS_CODE.SUBMITTED,
    label: TRADE_REQUEST_STATUS_LABEL.SUBMITTED
  },
  // UNKNOWN
  {
    code: TRADE_REQUEST_STATUS_CODE.UNKNOWN,
    label: TRADE_REQUEST_STATUS_LABEL.UNKNOWN
  },
];