import {connect} from 'react-redux';
import MeetingAndTrainingGroupDashboard from '../components/MeetingAndTrainingGroupDashboard';

import {
    loadUserAccounts,
  } from '../../../home/homeActions';
import {
    // query
    onQueryInputChange,
    loadMeetingAndTrainingGroupInfo,
    initLoadMeetingAndTrainingGroupInfoActionStatus,
    // select
    selectMeetingAndTrainingGroupInfo,
    // add or edit
    openAddOrEditMeetingAndTrainingGroupModal,
    addOrEditMeetingAndTrainingGroupChangeInput,
    addOrEditMeetingAndTrainingGroup,
    initAddOrEditMeetingAndTrainingGroupActionStatus,
    resetAddOrEditMeetingAndTrainingGroupModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelMeetingAndTrainingGroupModal,
    delMeetingAndTrainingGroupChangeInput,
    delMeetingAndTrainingGroup,
    initDelMeetingAndTrainingGroupActionStatus,
    resetDelMeetingAndTrainingGroupModal,

    //cop
    openCopMeetingAndTrainingGroupModal,
    copMeetingAndTrainingGroupChangeInput,
    copMeetingAndTrainingGroup,
    initCopMeetingAndTrainingGroupActionStatus,
    resetCopMeetingAndTrainingGroupModal,

    
    openAnswereeSelectModalDialog,
    resetAnswereeSelectModalDialog,

    // Select All Answeree
    selectAllAnsweree,

    // Select Invert Answeree
    selectInvertAnsweree,

    // Answeree Check Changed
    answereeCheckChanged,


    // Open/Reset Questionnaire Email Edit Modal Dialog
    openQuestionnaireEmailEditModalDialog,
    resetQuestionnaireEmailEditModalDialog,

      // Select Answeree
    selectAnswerees,
    initSelectAnswereesActionStatus,

} from '../MeetingAndTrainingGroupActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.meetingAndTrainingGroup.statusOptions,
        typeOptions: state.meetingAndTrainingGroup.typeOptions,
        repeatOptions: state.meetingAndTrainingGroup.repeatOptions,
        repeatMonthlyOptions: state.meetingAndTrainingGroup.repeatMonthlyOptions,
        repeatEveryOptions: state.meetingAndTrainingGroup.repeatEveryOptions,
        weekdayOptions: state.meetingAndTrainingGroup.weekdayOptions,
        fundFirmRelatedOptions: state.meetingAndTrainingGroup.fundFirmRelatedOptions,
        ccyOptions: state.meetingAndTrainingGroup.ccyOptions,
        userAccountOptions: state.meetingAndTrainingGroup.userAccountOptions,
        // meetingAndTrainingGroupQueryFields
        meetingAndTrainingGroupQueryFields: state.meetingAndTrainingGroup.meetingAndTrainingGroupQueryFields,
        // query
        loadMeetingAndTrainingGroupInfoActionStatus: state.meetingAndTrainingGroup.loadMeetingAndTrainingGroupInfoActionStatus,
        loadMeetingAndTrainingGroupInfoResp: state.meetingAndTrainingGroup.loadMeetingAndTrainingGroupInfoResp,
        selectedMeetingAndTrainingGroupInfoArray: state.meetingAndTrainingGroup.selectedMeetingAndTrainingGroupInfoArray,
        meetingAndTrainingGroupGridColumns: state.meetingAndTrainingGroup.meetingAndTrainingGroupGridColumns,
        // add or edit
        addOrEditMeetingAndTrainingGroupModal: state.meetingAndTrainingGroup.addOrEditMeetingAndTrainingGroupModal,
        addOrEditMeetingAndTrainingGroupStatus: state.meetingAndTrainingGroup.addOrEditMeetingAndTrainingGroupStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.meetingAndTrainingGroup.refreshFxStatus,
        refreshFxResp: state.meetingAndTrainingGroup.refreshFxResp,
        // del
        delMeetingAndTrainingGroupModal: state.meetingAndTrainingGroup.delMeetingAndTrainingGroupModal,
        delMeetingAndTrainingGroupStatus: state.meetingAndTrainingGroup.delMeetingAndTrainingGroupStatus,
        // cop
        copMeetingAndTrainingGroupModal: state.meetingAndTrainingGroup.copMeetingAndTrainingGroupModal,
        copMeetingAndTrainingGroupStatus: state.meetingAndTrainingGroup.copMeetingAndTrainingGroupStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        meetingAndTrainingGroupUploadUrl: state.meetingAndTrainingGroup.meetingAndTrainingGroupUploadUrl,
        meetingAndTrainingGroupDownloadUrl: state.meetingAndTrainingGroup.meetingAndTrainingGroupDownloadUrl,
        answereeSelectControlModal: state.meetingAndTrainingGroup.answereeSelectControlModal,

        pmArray: state.meetingAndTrainingGroup.pmArray,
        pmGroupArray: state.meetingAndTrainingGroup.pmGroupArray,
        userAccountArray: state.meetingAndTrainingGroup.userAccountArray,
           // Answers
        answerArray: state.meetingAndTrainingGroup.answerArray,

            // Open/Reset Questionnaire Email Edit Modal Dialog
        questionnaireEmailEditControlModal: state.meetingAndTrainingGroup.questionnaireEmailEditControlModal,

        // Select Answerees
        selectAnswereesActionStatus: state.meetingAndTrainingGroup.selectAnswereesActionStatus,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadMeetingAndTrainingGroupInfo: (params) => dispatch(loadMeetingAndTrainingGroupInfo(params)),
        initLoadMeetingAndTrainingGroupInfoActionStatus: () => dispatch(initLoadMeetingAndTrainingGroupInfoActionStatus()),
        // select
        selectMeetingAndTrainingGroupInfo: (dataArray) => dispatch(selectMeetingAndTrainingGroupInfo(dataArray)),
        // add or edit
        openAddOrEditMeetingAndTrainingGroupModal: (mode, data) => dispatch(openAddOrEditMeetingAndTrainingGroupModal(mode, data)),
        addOrEditMeetingAndTrainingGroupChangeInput: (params) => dispatch(addOrEditMeetingAndTrainingGroupChangeInput(params)),
        addOrEditMeetingAndTrainingGroup: (info) => dispatch(addOrEditMeetingAndTrainingGroup(info)),
        initAddOrEditMeetingAndTrainingGroupActionStatus: () => dispatch(initAddOrEditMeetingAndTrainingGroupActionStatus()),
        resetAddOrEditMeetingAndTrainingGroupModal: () => dispatch(resetAddOrEditMeetingAndTrainingGroupModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelMeetingAndTrainingGroupModal: (info,mode) => dispatch(openDelMeetingAndTrainingGroupModal(info,mode)),
        delMeetingAndTrainingGroupChangeInput: (params) => dispatch(delMeetingAndTrainingGroupChangeInput(params)),
        delMeetingAndTrainingGroup: (info) => dispatch(delMeetingAndTrainingGroup(info)),
        initDelMeetingAndTrainingGroupActionStatus: () => dispatch(initDelMeetingAndTrainingGroupActionStatus()),
        resetDelMeetingAndTrainingGroupModal: () => dispatch(resetDelMeetingAndTrainingGroupModal()),

        // cop
        openCopMeetingAndTrainingGroupModal: (info) => dispatch(openCopMeetingAndTrainingGroupModal(info)),
        copMeetingAndTrainingGroupChangeInput: (params) => dispatch(copMeetingAndTrainingGroupChangeInput(params)),
        copMeetingAndTrainingGroup: (params) => dispatch(copMeetingAndTrainingGroup(params)),
        initCopMeetingAndTrainingGroupActionStatus: () => dispatch(initCopMeetingAndTrainingGroupActionStatus()),
        resetCopMeetingAndTrainingGroupModal: () => dispatch(resetCopMeetingAndTrainingGroupModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),

        openAnswereeSelectModalDialog: () => dispatch(openAnswereeSelectModalDialog()),
        resetAnswereeSelectModalDialog: () => dispatch(resetAnswereeSelectModalDialog()),

            // loadUserAccounts
        loadUserAccounts: () => dispatch(loadUserAccounts()),


        // Select All Answeree
        selectAllAnsweree: () => dispatch(selectAllAnsweree()),

        // Select Invert Answeree
        selectInvertAnsweree: () => dispatch(selectInvertAnsweree()),

        // Answeree Check Changed
        answereeCheckChanged: (checked ,userAccountName) => dispatch(answereeCheckChanged(checked ,userAccountName)),

        // Open/Reset Questionnaire Email Edit Modal Dialog
        openQuestionnaireEmailEditModalDialog: (sendMode, emailPayload) => dispatch(openQuestionnaireEmailEditModalDialog(sendMode, emailPayload)),
        resetQuestionnaireEmailEditModalDialog: () => dispatch(resetQuestionnaireEmailEditModalDialog()),

        // Select Answeree
        selectAnswerees: (userAccountNameList, questionnaireGuid, emailTitle, emailContent) => dispatch(selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent)),

        initSelectAnswereesActionStatus: () => dispatch(initSelectAnswereesActionStatus()),

        
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MeetingAndTrainingGroupDashboard);