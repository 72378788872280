const BaseUrlConfig = {
  dev: {
    profile: 'dev',
    admin_frontend_dn: 'localhost:3100',
    report_frontend_dn: 'localhost:3101',
    api: {
      REST_URL: 'http://localhost:15010/compliance',//dev
      //  REST_URL: 'http://localhost:15011/compliance',//pro
      // REST_URL: 'http://192.168.200.70:15010/compliance',
      // REST_URL: 'http://192.168.200.70:15011/compliance',

      // 外网
      // REST_URL: 'http://220.241.80.229:15011/compliance',
      API_VERSION: '/api/v1',
    },
  },
  qa: {
    profile: 'qa',
    admin_frontend_dn: 'qacms.pinpointfund.com',
    report_frontend_dn: 'qacms.report.pinpointfund.com',
    api: {
      REST_URL: 'https://qacms.pinpointfund.com/compliance',
      API_VERSION: '/api/v1',
    }
  },
  prod: {
    profile: 'prod',
    admin_frontend_dn: 'cms.pinpointfund.com',
    report_frontend_dn: 'cms.report.pinpointfund.com',
    api: {
      REST_URL: 'https://cms.pinpointfund.com/compliance',
      API_VERSION: '/api/v1',
    }
  },
  prodsh: {
    profile: 'prodsh',
    admin_frontend_dn: 'cms.pinpointfund.cn',
    report_frontend_dn: 'cms.report.pinpointfund.cn',
    api: {
      REST_URL: 'https://cms.pinpointfund.cn/compliance',
      API_VERSION: '/api/v1',
    }
  }
}[process.env.REACT_APP_ENV || 'dev'];

export default BaseUrlConfig;
