import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


class HistoryUploadGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            context: { componentParent: this },
            columnDefs: [
                {field: 'attachmentType', headerName: 'attachmentType', width: 150, cellClass: 'non-number'},
                {field: 'representFileName', headerName: 'attachment', width: 400, cellClass: 'non-number', cellRenderer: this.downloadAttachmentCellRenderer},
                {field: 'uploadTime', headerName: 'uploadTime', width: 150, cellClass: 'non-number'},
                {field: 'uploadedBy', headerName: 'uploadedBy', width: 150, cellClass: 'non-number'},
                {field: 'uploadSource', headerName: 'uploadSource', width: 100, cellClass: 'non-number'},
            ],
        };
    }


    downloadAttachmentCellRenderer = (node) => {
        const {
            baseDownloadUrl,
        } = this.props.historyUpload.historyUploadDialogModal;
        
        const representFileName = node.data.representFileName;
        const realFileName = node.data.realFileName;
        if (!!representFileName && !!realFileName) {
            const url = baseDownloadUrl + '/' + realFileName;
            let link = document.createElement('a');
            link.innerText = representFileName;
            link.target = '_blank';
            link.href = url;
            return link;
        }
        return '';
    }

    render() {
        const {
            queryHistoryUploadStatus,
            queryHistoryUploadResp,
        } = this.props.historyUpload;

        const isLoading = queryHistoryUploadStatus === ACTION_STATUS.LOGINING;
        const historyUploadArray = !!queryHistoryUploadResp && !!queryHistoryUploadResp.data ? queryHistoryUploadResp.data : [];

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={historyUploadArray}
                            defaultColDef={this.state.defaultColDef}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            rowSelection={'single'}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                        />
                    )}
            </div>
        );
    }

}


export default HistoryUploadGrid;
