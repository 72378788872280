import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from './GridColumnMap';

import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_EXPENSE_SUMMARY_INFO,
    LOAD_EXPENSE_SUMMARY_INFO_SUCCESS,
    LOAD_EXPENSE_SUMMARY_INFO_FAILURE,
    INIT_LOAD_EXPENSE_SUMMARY_INFO_ACTION_STATUS,
    // select
    SELECT_EXPENSE_SUMMARY_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL,
    ADD_OR_EDIT_EXPENSE_SUMMARY_CHANGE_INPUT,
    START_ADD_OR_EDIT_EXPENSE_SUMMARY,
    ADD_OR_EDIT_EXPENSE_SUMMARY_SUCCESS,
    ADD_OR_EDIT_EXPENSE_SUMMARY_FAILURE,
    INIT_ADD_OR_EDIT_EXPENSE_SUMMARY_ACTION_STATUS,
    RESET_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_EXPENSE_SUMMARY_MODAL,
    DEL_EXPENSE_SUMMARY_CHANGE_INPUT,
    START_DEL_EXPENSE_SUMMARY,
    DEL_EXPENSE_SUMMARY_SUCCESS,
    DEL_EXPENSE_SUMMARY_FAILURE,
    INIT_DEL_EXPENSE_SUMMARY_ACTION_STATUS,
    RESET_DEL_EXPENSE_SUMMARY_MODAL,

} from './ExpenseSummaryConstants';


const Option = Select.Option;

const categoryOptions = [];
categoryOptions.push(<Option key={'Legal'}>{'Legal'}</Option>);
categoryOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
categoryOptions.push(<Option key={'Data Fee'}>{'Data Fee'}</Option>);

const fundFirmRelatedOptions = [];
// fundFirmRelatedOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PAML – funds prepaid'}>{'PAML – funds prepaid'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PAML - PAJP'}>{'PAML - PAJP'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PAML - PASG'}>{'PAML - PASG'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PAML - PCF'}>{'PAML - PCF'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PAML - PIAS'}>{'PAML - PIAS'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PAML - PLUS'}>{'PAML - PLUS'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PAML - PMSF'}>{'PAML - PMSF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PASG – funds prepaid'}>{'PASG – funds prepaid'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
fundFirmRelatedOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'上海保银私募'}>{'上海保银私募'}</Option>);
fundFirmRelatedOptions.push(<Option key={'上海保银私募 – funds prepaid'}>{'上海保银私募 – funds prepaid'}</Option>);
fundFirmRelatedOptions.push(<Option key={'COP'}>{'COP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'EJ'}>{'EJ'}</Option>);
fundFirmRelatedOptions.push(<Option key={'Wealth Ocean'}>{'Wealth Ocean'}</Option>);
fundFirmRelatedOptions.push(<Option key={'Pinpoint Charity'}>{'Pinpoint Charity'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundFirmRelatedOptions.push(<Option key={'VCC'}>{'VCC'}</Option>);
fundFirmRelatedOptions.push(<Option key={'国内基金'}>{'国内基金'}</Option>);
fundFirmRelatedOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);

fundFirmRelatedOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);


fundFirmRelatedOptions.push(<Option key={'MetaPoint'}>{'MetaPoint'}</Option>);
fundFirmRelatedOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundFirmRelatedOptions.push(<Option key={'投资顾问 (上海)'}>{'投资顾问 (上海)'}</Option>);
fundFirmRelatedOptions.push(<Option key={'私募管理 (上海)'}>{'私募管理 (上海)'}</Option>);
fundFirmRelatedOptions.push(<Option key={'私募管理 (海南)'}>{'私募管理 (海南)'}</Option>);
fundFirmRelatedOptions.push(<Option key={'私募管理 (上海) - funds prepaid'}>{'私募管理 (上海) - funds prepaid'}</Option>);
fundFirmRelatedOptions.push(<Option key={'私募管理 (海南) - funds prepaid'}>{'私募管理 (海南) - funds prepaid'}</Option>);
fundFirmRelatedOptions.push(<Option key={'资产管理 (上海)'}>{'资产管理 (上海)CL'}</Option>);
fundFirmRelatedOptions.push(<Option key={'Others'}>{'Others'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PCL2006A'}>{'PCL2006A'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PLUSOFF'}>{'PLUSOFF'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PMSFOFF'}>{'PMSFOFF'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PMSFUS'}>{'PMSFUS'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'上海保銀投資'}>{'上海保銀投資'}</Option>);

const ccyOptions = [];
ccyOptions.push(<Option key={'USD'}>{'USD'}</Option>);
ccyOptions.push(<Option key={'CNY'}>{'CNY'}</Option>);
ccyOptions.push(<Option key={'HKD'}>{'HKD'}</Option>);
ccyOptions.push(<Option key={'SGD'}>{'SGD'}</Option>);
ccyOptions.push(<Option key={'JPY'}>{'JPY'}</Option>);
ccyOptions.push(<Option key={'INR'}>{'INR'}</Option>);
ccyOptions.push(<Option key={'GBP'}>{'GBP'}</Option>);

const initialState = {
    expenseSummaryGridColumns: GridColumnMap.expenseSummaryGridColumns,

    categoryOptions: categoryOptions,
    fundFirmRelatedOptions: fundFirmRelatedOptions,
    ccyOptions: ccyOptions,

    expenseSummaryQueryFields: {
        category: '',
        invDateStart: '',
        invDateEnd: '',
        invNo: '',
        firm: ''
    },
    selectedExpenseSummaryInfoArray: [],
    addOrEditExpenseSummaryModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            category: '',
            firm: '',
            fundFirmRelated: '',
            invDate: '',
            invNo: '',
            ccy: '',
            amount: '',
            fx: '',
            amtUSD: '',
            payDate: '',
            nature: '',
            remarks: '',
            payMethod: '',
            comments: '',
        },
    },
    delExpenseSummaryModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            comments: '',
        }
    },

};


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.expenseSummaryQueryFields,
        [name]: value,
    };
    return {
        ...state,
        expenseSummaryQueryFields: upatedFields,
    }
}
function startLoadExpenseSummaryInfo(state) {
    return {
      ...state,
      loadExpenseSummaryInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadExpenseSummaryInfoSuccess(state, resp) {
    return {
        ...state,
        loadExpenseSummaryInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadExpenseSummaryInfoResp: resp,
    };
}
function loadExpenseSummaryInfoFailure(state, err) {
    return {
        ...state,
        loadExpenseSummaryInfoActionStatus: ACTION_STATUS.ERROR,
        loadExpenseSummaryInfoResp: err,
    };
}
function initLoadExpenseSummaryInfoActionStatus(state) {
    return {
        ...state,
        loadExpenseSummaryInfoActionStatus: ACTION_STATUS.READY,
        selectedExpenseSummaryInfoArray: [],
    };
}


// select
function selectExpenseSummaryInfo(state, array) {
    return {
        ...state,
        selectedExpenseSummaryInfoArray: array,
    }
}


// add or edit
function openAddOrEditExpenseSummaryModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditExpenseSummaryModal: {
                ...state.addOrEditExpenseSummaryModal,
                isOpened: true,
                mode,
            },
        };
    } else {
        return {
            ...state,
            addOrEditExpenseSummaryModal: {
                ...state.addOrEditExpenseSummaryModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditExpenseSummaryModal.fields,
                    id: data.id,
                    guid: data.guid,
                    category: data.category,
                    firm: data.firm,
                    fundFirmRelated: data.fundFirmRelated,
                    invDate: data.invDate,
                    invNo: data.invNo,
                    ccy: data.ccy,
                    amount: data.amount,
                    fx: data.fx,
                    amtUSD: data.amtUSD,
                    payDate: data.payDate,
                    nature: data.nature,
                    remarks: data.remarks,
                    payMethod: data.payMethod,
                }
            },
        };
    }
}
function addOrEditExpenseSummaryChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditExpenseSummaryModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditExpenseSummaryModal: {
          ...state.addOrEditExpenseSummaryModal,
          fields: updatedFields,
        },
    };
}
function startAddOrEditExpenseSummary(state) {
    return {
      ...state,
      addOrEditExpenseSummaryStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditExpenseSummarySuccess(state, resp) {
    return {
        ...state,
        addOrEditExpenseSummaryStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditExpenseSummaryFailure(state, err) {
    return {
        ...state,
        addOrEditExpenseSummaryStatus: ACTION_STATUS.ERROR,
        addOrEditExpenseSummaryModal: {
            ...state.addOrEditExpenseSummaryModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditExpenseSummaryActionStatus(state) {
    return {
        ...state,
        addOrEditExpenseSummaryStatus: ACTION_STATUS.READY,
    };
}
function resetAddOrEditExpenseSummaryModal(state) {
    return {
        ...state,
        addOrEditExpenseSummaryModal: initialState.addOrEditExpenseSummaryModal,
    }
}


// refresh fx and amtUSD
function startRefreshFx(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.LOGINING,
    };
}
function refreshFxSuccess(state, resp) {
    const fx = (!!resp && !!resp.data && !!resp.data.last) ? resp.data.last : '';
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.SUCCESS,
        addOrEditExpenseSummaryModal: {
            ...state.addOrEditExpenseSummaryModal,
            fields: {
                ...state.addOrEditExpenseSummaryModal.fields,
                fx: fx,
            },
        }
    }
}
function refreshFxFailure(state, err) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.ERROR,
        refreshFxResp: err,
        addOrEditExpenseSummaryModal: {
            ...state.addOrEditExpenseSummaryModal,
            fields: {
                ...state.addOrEditExpenseSummaryModal.fields,
                fx: '',
            },
        }
    }
}
function initRefreshFxActionStatus(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.READY,
    };
}
function refreshAmtUSD(state, amtUSD) {
    return {
        ...state,
        addOrEditExpenseSummaryModal: {
            ...state.addOrEditExpenseSummaryModal,
            fields: {
                ...state.addOrEditExpenseSummaryModal.fields,
                amtUSD: amtUSD,
            },
        }
    };
}


// del
function openDelExpenseSummaryModal(state, payload) {
    return {
        ...state,
        delExpenseSummaryModal: {
            ...state.delExpenseSummaryModal,
            isOpened: true,
            fields: {
                ...state.delExpenseSummaryModal.fields,
                id: payload.id,
                guid: payload.guid,
            }
        }
    }
}
function delExpenseSummaryChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.delExpenseSummaryModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delExpenseSummaryModal: {
          ...state.delExpenseSummaryModal,
          fields: updatedFields,
        },
    };
}
function startDelExpenseSummary(state) {
    return {
      ...state,
      delExpenseSummaryStatus: ACTION_STATUS.LOGINING,
    };
}
function delExpenseSummarySuccess(state, resp) {
    return {
        ...state,
        delExpenseSummaryStatus: ACTION_STATUS.SUCCESS,
    };
}
function delExpenseSummaryFailure(state, err) {
    return {
        ...state,
        delExpenseSummaryStatus: ACTION_STATUS.ERROR,
        delExpenseSummaryModal: {
            ...state.delExpenseSummaryModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelExpenseSummaryActionStatus(state) {
    return {
        ...state,
        delExpenseSummaryStatus: ACTION_STATUS.READY,
    };
}
function resetDelExpenseSummaryModal(state) {
    return {
        ...state,
        delExpenseSummaryModal: initialState.delExpenseSummaryModal,
    }
}




export default createReducer(initialState, {
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_EXPENSE_SUMMARY_INFO]: startLoadExpenseSummaryInfo,
    [LOAD_EXPENSE_SUMMARY_INFO_SUCCESS]: loadExpenseSummaryInfoSuccess,
    [LOAD_EXPENSE_SUMMARY_INFO_FAILURE]: loadExpenseSummaryInfoFailure,
    [INIT_LOAD_EXPENSE_SUMMARY_INFO_ACTION_STATUS]: initLoadExpenseSummaryInfoActionStatus,
    // select
    [SELECT_EXPENSE_SUMMARY_INFO]: selectExpenseSummaryInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL]: openAddOrEditExpenseSummaryModal,
    [ADD_OR_EDIT_EXPENSE_SUMMARY_CHANGE_INPUT]: addOrEditExpenseSummaryChangeInput,
    [START_ADD_OR_EDIT_EXPENSE_SUMMARY]: startAddOrEditExpenseSummary,
    [ADD_OR_EDIT_EXPENSE_SUMMARY_SUCCESS]: addOrEditExpenseSummarySuccess,
    [ADD_OR_EDIT_EXPENSE_SUMMARY_FAILURE]: addOrEditExpenseSummaryFailure,
    [INIT_ADD_OR_EDIT_EXPENSE_SUMMARY_ACTION_STATUS]: initAddOrEditExpenseSummaryActionStatus,
    [RESET_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL]: resetAddOrEditExpenseSummaryModal,
    // refresh fx and amtUSD
    [START_REFRESH_FX]: startRefreshFx,
    [REFRESH_FX_SUCCESS]: refreshFxSuccess,
    [REFRESH_FX_FAILURE]: refreshFxFailure,
    [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
    [REFRESH_AMT_USD]: refreshAmtUSD,
    // del
    [OPEN_DEL_EXPENSE_SUMMARY_MODAL]: openDelExpenseSummaryModal,
    [DEL_EXPENSE_SUMMARY_CHANGE_INPUT]: delExpenseSummaryChangeInput,
    [START_DEL_EXPENSE_SUMMARY]: startDelExpenseSummary,
    [DEL_EXPENSE_SUMMARY_SUCCESS]: delExpenseSummarySuccess,
    [DEL_EXPENSE_SUMMARY_FAILURE]: delExpenseSummaryFailure,
    [INIT_DEL_EXPENSE_SUMMARY_ACTION_STATUS]: initDelExpenseSummaryActionStatus,
    [RESET_DEL_EXPENSE_SUMMARY_MODAL]: resetDelExpenseSummaryModal,

});