import React, {Component} from 'react';
import './PortfolioMonitoringRuleDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider,DatePicker} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import PortfolioMonitoringRuleGrid from './PortfolioMonitoringRuleGrid';
import AddOrEditPortfolioMonitoringRuleModalDialog from './AddOrEditPortfolioMonitoringRuleModalDialog';
// import AddOrEditPortfolioMonitoringRuleSubModalDialog from './AddOrEditPortfolioMonitoringRuleSubModalDialog';
import BatchChangeStatusModalDialog from './BatchChangeStatusModalDialog';
import FinishTaskModalDialog from './FinishTaskModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import moment from 'moment';

class PortfolioMonitoringRuleDashboard extends Component {

    componentDidMount() {
        // queryString中的查询条件

        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});
        this.props.loadPortfolioMonitoringRuleInfo({id, location: undefined, fundCompany: undefined});
    }


    componentDidUpdate() {
        const {
            loadPortfolioMonitoringRuleInfoActionStatus,
            loadPortfolioMonitoringRuleInfoResp,
        } = this.props;
        if(loadPortfolioMonitoringRuleInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadPortfolioMonitoringRuleInfoActionStatus();
        }
        if(loadPortfolioMonitoringRuleInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load Reporting Summary Info failed';
            if(!!loadPortfolioMonitoringRuleInfoResp && !!loadPortfolioMonitoringRuleInfoResp.respMessage) {
                err = loadPortfolioMonitoringRuleInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadPortfolioMonitoringRuleInfoActionStatus();
        }
    }

    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }

    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };




    addOrEditPortfolioMonitoringRuleBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditPortfolioMonitoringRuleModal(mode, null);
        } else if(mode === 'EDIT') {  // COPY, EDIT
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length !== 1) {
                message.warning("Please choose one and only one Rul");
                return;
            }
            const filterArray = selectedPortfolioMonitoringRuleArray.filter(a => a.ruleStatus === 'Active');
            if (filterArray.length > 0) {
              message.warning("You cannot Resubmit, Status is Active!");
              return;
            }
            const currentData = selectedPortfolioMonitoringRuleArray[0];
            this.props.openAddOrEditPortfolioMonitoringRuleModal(mode, currentData);
        }else {  // COPY, EDIT
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length !== 1) {
                message.warning("Please choose one and only one Rul");
                return;
            }
            
            const currentData = selectedPortfolioMonitoringRuleArray[0];
            this.props.openAddOrEditPortfolioMonitoringRuleModal(mode, currentData);
        }
    }

    addOrEditPortfolioMonitoringRuleSubBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
             // COPY, EDIT
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length !== 1) {
                message.warning("Please choose one and only one Work Management");
                return;
            }
            const idList = selectedPortfolioMonitoringRuleArray.map(e => e.id);
            this.props.openAddOrEditPortfolioMonitoringRuleSubModal(mode, null,idList);
        } else {  // COPY, EDIT
            const {
                selectedPortfolioMonitoringRuleTaskArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleTaskArray.length !== 1) {
                message.warning("Please choose one and only one Work Management Sub");
                return;
            }
            const currentData = selectedPortfolioMonitoringRuleTaskArray[0];
            this.props.openAddOrEditPortfolioMonitoringRuleSubModal(mode, currentData,null);
        }
    }

    refreshBtnOnClicked = (ruleStatus) => {
        const {
            id,
            busDate,
        } = this.props.portfolioMonitoringRuleQueryFields;
       // location: undefined, fundCompany: undefined
        this.props.loadPortfolioMonitoringRuleInfo({ruleStatus,busDate});
    }
    refreshBtnOnClickedSelect = () => {
        const {
            id,
            busDate,
        } = this.props.portfolioMonitoringRuleQueryFields;
       // location: undefined, fundCompany: undefined
        this.props.loadPortfolioMonitoringRuleInfo({ruleStatus:undefined,busDate});
    }

    batchChangeStatusBtnOnClicked = (mode) => {
        if(mode === 'Approve'){
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length === 0) {
                message.warning("Please choose at least one Rule");
                return;
            }
            const idList = selectedPortfolioMonitoringRuleArray.map(e => e.id);
            const status = selectedPortfolioMonitoringRuleArray.map(e => e.ruleStatus);
            const filterArray = selectedPortfolioMonitoringRuleArray.filter(a => a.ruleStatus != 'Submit');
            if (filterArray.length > 0) {
              message.warning("You cannot Approve, status must be Submit!");
              return;
            }

            this.props.openBatchChangeStatusModal(mode, idList);
        } else if (mode === 'Reject'){
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length === 0) {
                message.warning("Please choose at least one Rule");
                return;
            }
            const idList = selectedPortfolioMonitoringRuleArray.map(e => e.id);
            const status = selectedPortfolioMonitoringRuleArray.map(e => e.ruleStatus);
            const filterArray = selectedPortfolioMonitoringRuleArray.filter(a => a.ruleStatus != 'Submit');
            if (filterArray.length > 0) {
              message.warning("You cannot Reject, status must be Submit!");
              return;
            }

            this.props.openBatchChangeStatusModal(mode, idList);
        }else if (mode === 'Deactivate'){
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length === 0) {
                message.warning("Please choose at least one Rule");
                return;
            }
            const idList = selectedPortfolioMonitoringRuleArray.map(e => e.id);
            const status = selectedPortfolioMonitoringRuleArray.map(e => e.ruleStatus);
            const filterArray = selectedPortfolioMonitoringRuleArray.filter(a => a.ruleStatus != 'Active');
            if (filterArray.length > 0) {
              message.warning("You cannot Deactivate, status must be Active!");
              return;
            }

            this.props.openBatchChangeStatusModal(mode, idList);
        }else if (mode === 'Submit'){
            const {
                selectedPortfolioMonitoringRuleArray,
            } = this.props;
            if(selectedPortfolioMonitoringRuleArray.length === 0) {
                message.warning("Please choose at least one Rule");
                return;
            }
            const idList = selectedPortfolioMonitoringRuleArray.map(e => e.id);
            const status = selectedPortfolioMonitoringRuleArray.map(e => e.ruleStatus);
            const filterArray = selectedPortfolioMonitoringRuleArray.filter(a => a.ruleStatus != 'New');
            if (filterArray.length > 0) {
              message.warning("You cannot Submit, status must be New!");
              return;
            }

            this.props.openBatchChangeStatusModal(mode, idList);

        }

    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'dataType', 'otherInfo', 'operateType', 'comments', 'before', 'after'];

    render() {
        const {

            loadPortfolioMonitoringRuleInfoActionStatus,

        } = this.props;
        const {
            id,
            location,
            fundCompany,
            startDate,
            busDate,
            endDate,
        } = this.props.portfolioMonitoringRuleQueryFields;
        const refreshBtnIsLoading = loadPortfolioMonitoringRuleInfoActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div className='PortfolioMonitoringRuleWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Fund Monitoring</Breadcrumb.Item>
                        <Breadcrumb.Item>Portfolio Monitoring</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                <div className='breadcrumbWrapper'>   
                <Breadcrumb>
                <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                </Breadcrumb>
                </div>
                    <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='Trade Date'
                        value={busDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'busDate', value: date });
                        }} 
                    />
                    {/* <DatePicker 
                        style={{ marginLeft: 10, width: 150}}
                        size='small'
                        allowClear={true}
                        placeholder='End Date'
                        value={endDate}
                        onChange={(date, dateString) => {
                            this.onDataIDQueryInputChange({ name: 'endDate', value: date });
                        }} 
                    /> */}

                    <Button 
                        onClick={this.refreshBtnOnClickedSelect} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditPortfolioMonitoringRuleBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditPortfolioMonitoringRuleBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Modify
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditPortfolioMonitoringRuleBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('Submit')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'  >
                        Submit
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('Deactivate')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Deactivate
                    </Button>

                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('Approve')} 
                        size='small'
                        className='positive-button'>
                        Approve
                    </Button>


                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('Reject')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Reject
                    </Button>
 
                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.refreshBtnOnClicked('Active')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        className='positive-button'>
                        Show Active
                    </Button>

                    <Button 
                        onClick={()=>this.refreshBtnOnClicked('All')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Show All
                    </Button>
                    
                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'PortfolioMonitoringRule'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button>

                </div>

                <div className='PortfolioMonitoringRuleGrid'><PortfolioMonitoringRuleGrid {...this.props} /></div>
                <AddOrEditPortfolioMonitoringRuleModalDialog {...this.props} />
                {/* <AddOrEditPortfolioMonitoringRuleSubModalDialog {...this.props} /> */}
                <BatchChangeStatusModalDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                <OperationRecordDialog {...this.props} />

            </div>
        );
    }
}

export default PortfolioMonitoringRuleDashboard;
