
export const SIDE_TYPE_CODE = {
  BUY: 'BUY',
  SELL: 'SELL',
  SHORT: 'SHORT',
  COVER: 'COVER',
};

const SIDE_TYPE_LABEL = {
  BUY: 'BUY',
  SELL: 'SELL',
  SHORT: 'SHORT',
  COVER: 'COVER',
};

export const SIDE_TYPE_MAP = [
  // Buy
  {
    code: SIDE_TYPE_CODE.BUY,
    label: SIDE_TYPE_LABEL.BUY
  },
  // Sell
  {
    code: SIDE_TYPE_CODE.SELL,
    label: SIDE_TYPE_LABEL.SELL
  },
  {
    code: SIDE_TYPE_CODE.SHORT,
    label: SIDE_TYPE_LABEL.SHORT
  },
  {
    code: SIDE_TYPE_CODE.COVER,
    label: SIDE_TYPE_LABEL.COVER
  },
];