import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_ROTATION_INFO,
    LOAD_ROTATION_INFO_SUCCESS,
    LOAD_ROTATION_INFO_FAILURE,
    INIT_LOAD_ROTATION_INFO_ACTION_STATUS,
    // select
    SELECT_ROTATION_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_ROTATION_MODAL,
    ADD_OR_EDIT_ROTATION_CHANGE_INPUT,
    START_ADD_OR_EDIT_ROTATION,
    ADD_OR_EDIT_ROTATION_SUCCESS,
    ADD_OR_EDIT_ROTATION_FAILURE,
    INIT_ADD_OR_EDIT_ROTATION_ACTION_STATUS,
    RESET_ADD_OR_EDIT_ROTATION_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_ROTATION_MODAL,
    DEL_ROTATION_CHANGE_INPUT,
    START_DEL_ROTATION,
    DEL_ROTATION_SUCCESS,
    DEL_ROTATION_FAILURE,
    INIT_DEL_ROTATION_ACTION_STATUS,
    RESET_DEL_ROTATION_MODAL,

    // COP
    OPEN_COP_ROTATION_MODAL,
    COP_ROTATION_CHANGE_INPUT,
    START_COP_ROTATION,
    COP_ROTATION_SUCCESS,
    COP_ROTATION_FAILURE,
    INIT_COP_ROTATION_ACTION_STATUS,
    RESET_COP_ROTATION_MODAL,



    LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
    LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,

    LOAD_ROTATION_SCORE_FAILURE,
    LOAD_ROTATION_SCORE_SUCCESS,
    UPDATE_ROTATION_SCORE_FAILURE,
    UPDATE_ROTATION_SCORE_SUCCESS,

    OPEN_FINISH_TASK_MODAL,

    OPEN_OPERATION_RECORD_DIALOG,
    START_QUERY_OPERATION_RECORD,
    QUERY_OPERATION_RECORD_SUCESS,
    QUERY_OPERATION_RECORD_FAILURE,
    INIT_QUERY_OPERATION_RECORD_STATUS,
    RESET_OPERATION_RECORD_DIALOG,
    // detail
    SHOW_DETAIL_RCD,
    RESET_DETAIL_RCD,
    

} from './RotationReviewConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadRotationReviewInfo() {
    return {
      type: START_LOAD_ROTATION_INFO,
    };
}
export function loadRotationReviewInfo(params) {
    return function(dispatch) {
        dispatch(startLoadRotationReviewInfo());
        httpClient
        //   .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO_SUB, params)
          .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFOTO_SUB, params)
          .then(resp => dispatch(loadRotationReviewInfoSuccess(resp)))
          .catch(err => dispatch(loadRotationReviewInfoFailure(err)));
    };
}
function loadRotationReviewInfoSuccess(resp) {
    return {
      type: LOAD_ROTATION_INFO_SUCCESS,
      payload: resp,
    };
}
function loadRotationReviewInfoFailure(err) {
    return {
        type: LOAD_ROTATION_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadRotationReviewInfoActionStatus() {
    return {
      type: INIT_LOAD_ROTATION_INFO_ACTION_STATUS,
    };
}

// select
export function selectRotationReviewInfo(array) {
    return {
        type: SELECT_ROTATION_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditRotationReviewModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_ROTATION_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditRotationReviewChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_ROTATION_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditRotationReview() {
    return {
        type: START_ADD_OR_EDIT_ROTATION,
    };
}
export function addOrEditRotationReview(params) {
      return function(dispatch) {
        dispatch(startAddOrEditRotationReview());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_ROTATION, params)
          .then(resp => dispatch(addOrEditRotationReviewSuccess(resp)))
          .catch(err => dispatch(addOrEditRotationReviewFailure(err)));
      };
}
function addOrEditRotationReviewSuccess(resp) {
    return {
        type: ADD_OR_EDIT_ROTATION_SUCCESS,
        payload: resp,
    };
}
function addOrEditRotationReviewFailure(err) {
    return {
        type: ADD_OR_EDIT_ROTATION_FAILURE,
        payload: err,
    };
}
export function initAddOrEditRotationReviewActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_ROTATION_ACTION_STATUS,
    };
}
export function resetAddOrEditRotationReviewModal() {
    return {
        type: RESET_ADD_OR_EDIT_ROTATION_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelRotationReviewModal(info,mode) {
    return {
        type: OPEN_DEL_ROTATION_MODAL,
        payload: 
        {
            info,
            mode,
        },
    };  
}
export function delRotationReviewChangeInput({name, value}) {
    return {
        type: DEL_ROTATION_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelRotationReview() {
    return {
        type: START_DEL_ROTATION,
    };
}
export function delRotationReview(params) {
      return function(dispatch) {
        dispatch(startDelRotationReview());
        httpClient
          .get(UrlConfig.UPDATED_NOTE, params)
          .then(resp => dispatch(delRotationReviewSuccess(resp)))
          .catch(err => dispatch(delRotationReviewFailure(err)));
      };
}
function delRotationReviewSuccess(resp) {
    return {
        type: DEL_ROTATION_SUCCESS,
        payload: resp,
    };
}
function delRotationReviewFailure(err) {
    return {
        type: DEL_ROTATION_FAILURE,
        payload: err,
    };
}
export function initDelRotationReviewActionStatus() {
    return {
        type: INIT_DEL_ROTATION_ACTION_STATUS,
    };
}
export function resetDelRotationReviewModal() {
    return {
        type: RESET_DEL_ROTATION_MODAL,
    };
}












//cop
export function openCopRotationReviewModal(info) {
    return {
        type: OPEN_COP_ROTATION_MODAL,
        payload: info,
    };  
}
export function copRotationReviewChangeInput({name, value}) {
    return {
        type: COP_ROTATION_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startCopRotationReview() {
    return {
        type: START_COP_ROTATION,
    };
}
export function copRotationReview(params) {
      return function(dispatch) {
        dispatch(startCopRotationReview());
        httpClient
          .get(UrlConfig.COP_ALL_ROTATION, params)
          .then(resp => dispatch(copRotationReviewSuccess(resp)))
          .catch(err => dispatch(copRotationReviewFailure(err)));
      };
}
function copRotationReviewSuccess(resp) {
    return {
        type: COP_ROTATION_SUCCESS,
        payload: resp,
    };
}
function copRotationReviewFailure(err) {
    return {
        type: COP_ROTATION_FAILURE,
        payload: err,
    };
}
export function initCopRotationReviewActionStatus() {
    return {
        type: INIT_COP_ROTATION_ACTION_STATUS,
    };
}
export function resetCopRotationReviewModal() {
    return {
        type: RESET_COP_ROTATION_MODAL,
    };
}





export function loadRestrictedRuleList(params) {
    return function(dispatch) {
      httpClient
        .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO_SUB_FORIN, params)
        .then(resp => dispatch(loadRestrictedRuleListSuccess(resp)))
        .catch(err => dispatch(loadRestrictedRuleListFailure(err)));
    };
  }
  function loadRestrictedRuleListSuccess(resp) {
    return {
      type: LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
      payload: resp
    };
  }
  function loadRestrictedRuleListFailure(err) {
    return {
      type: LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,
      payload: err
    };
  }


  export function loadRotationScoreList(params) {
    return function(dispatch) {
      httpClient
        .post(UrlConfig.LOAD_ROTATION_SCORE_FORIN, params)
        .then(resp => dispatch(loadRotationScoreSuccess(resp)))
        // .then(resp => dispatch())
        .catch(err => dispatch(loadRotationScoreFailure(err)));
    };
  }
  function loadRotationScoreSuccess(resp) {
    return {
      type: LOAD_ROTATION_SCORE_SUCCESS,
      payload: resp
    };
  }
  function loadRotationScoreFailure(err) {
    return {
      type: LOAD_ROTATION_SCORE_FAILURE,
      payload: err
    };
  }



  export function upRotationScore(preliminaryTrades) {
    return function(dispatch) {
      httpClient
        .post(UrlConfig.UPDATE_ROTATION_SCORE_FORIN, preliminaryTrades)
        .then(resp => dispatch(upRotationScoreSuccess(resp)))
        .catch(err => dispatch(upRotationScoreFailure(err)));
    };
  }
  
  export function upRotationScoreSuccess(resp) {
    return {
      type: UPDATE_ROTATION_SCORE_SUCCESS,
      payload: resp
    };
  }
  
  export function upRotationScoreFailure(err) {
    return {
      type: UPDATE_ROTATION_SCORE_FAILURE,
      payload: err
    };
  }





  export function openFinishTaskModal(task) {
    const {id,participant} = task;
    return openOperationRecordDialogAndQuery(id,participant);
    // return {
    //     type: OPEN_FINISH_TASK_MODAL,
    //     payload: task,
    // };
}




// query page
function openOperationRecordDialog(queryParams) {
    return {
        type: OPEN_OPERATION_RECORD_DIALOG,
        payload: queryParams,
    };
}
function startQueryOperationRecord() {
    return {
        type: START_QUERY_OPERATION_RECORD,
    }
}
function openOperationRecordDialogAndQuery(id,participant) {
   
    const queryParams = {
      
        id:id,
        participant:participant
    }

    return function(dispatch) {
        dispatch(openOperationRecordDialog(queryParams));
        dispatch(startQueryOperationRecord());
        httpClient
          .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO_SUB_BY_MEETINGID, queryParams)
          .then(resp => dispatch(queryOperationRecordSuccess(resp)))
          .catch(err => dispatch(queryOperationRecordFailure(err)));
    };
}
function queryOperationRecordSuccess(resp) {
    return {
        type: QUERY_OPERATION_RECORD_SUCESS,
        payload: resp,
    }
}
function queryOperationRecordFailure(err) {
    return {
        type: QUERY_OPERATION_RECORD_FAILURE,
        payload: err,
    }
}
function initQueryOperationRecordStatus() {
    return {
        type: INIT_QUERY_OPERATION_RECORD_STATUS,
    }
}
function resetOperationRecordDialog() {
    return {
        type: RESET_OPERATION_RECORD_DIALOG,
    }
}


export function doOperationRecordAction(params) {
    const {action, queryFields, customColumns, detail} = params;
    if(action === 'OPEN_AND_QUERY') {
        return openOperationRecordDialogAndQuery(queryFields, customColumns);
    } else if(action === 'INIT_QUERY') {
        return initQueryOperationRecordStatus();
    } else if(action === 'RESET_QUERY') {
        return resetOperationRecordDialog();
    } else if(action === 'SHOW_DETAIL') {
        return showDetailRcd(detail);
    } else if(action === 'RESET_DETAIL') {
        return resetDetailRcd();
    }
}

// detail page
function showDetailRcd(detail) {
    return {
        type: SHOW_DETAIL_RCD,
        payload: detail,
    }
}
function resetDetailRcd() {
    return {
        type: RESET_DETAIL_RCD,
    }
}
  
