import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload, Icon  } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditMeetingAndTrainingCalenderModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingCalenderQueryFields;
        this.props.loadMeetingAndTrainingCalenderInfo({userName, status})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Meeting Record';
        } 
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditMeetingAndTrainingCalenderStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditMeetingAndTrainingCalenderModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditMeetingAndTrainingCalenderStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditMeetingAndTrainingCalenderModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditMeetingAndTrainingCalenderActionStatus();
            this.props.resetAddOrEditMeetingAndTrainingCalenderModal();
            this.refreshQuery();
        }
        if(addOrEditMeetingAndTrainingCalenderStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditMeetingAndTrainingCalenderActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditMeetingAndTrainingCalenderChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditMeetingAndTrainingCalenderModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditMeetingAndTrainingCalenderModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditMeetingAndTrainingCalenderModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
    }


    submitAddOrEditMeetingAndTrainingCalender = () => {
        const {
            addOrEditMeetingAndTrainingCalenderModal,
        } = this.props;
        const {
            mode,
        } = addOrEditMeetingAndTrainingCalenderModal;
        const {
            id,
            analyst,
            type,
            researchdepth,
            stockpitch,
            note,
          
        } = addOrEditMeetingAndTrainingCalenderModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditMeetingAndTrainingCalender({
                    id,
                    analyst,
                    type,
                    researchdepth,
                    stockpitch,
                    note,
                });                    
            }
        });
    }
    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
 

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditMeetingAndTrainingCalenderModal,
            userAccountOptions,
            typeOptions,
            optonalOptions,
            statusOptions,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditMeetingAndTrainingCalenderStatus,
            meetingAndTrainingCalenderUploadUrl,
            meetingAndTrainingCalenderDownloadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditMeetingAndTrainingCalenderModal;

        const {
            employee,
            usertype,
            analyst,
            type,
            researchdepth,
            stockpitch,
            note,
            fundandcompany,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            chartFileList,
            

        } = addOrEditMeetingAndTrainingCalenderModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const isSubmitLoading = addOrEditMeetingAndTrainingCalenderStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={700}
                   
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditMeetingAndTrainingCalenderModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditMeetingAndTrainingCalenderModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditMeetingAndTrainingCalender}>
                          Submit
                        </Button>,
                      ]}
                >
                <Form layout="horizontal">

                            <Row >
                                <Col >
                                    <FormItem {...formItemLayout} label="analyst">
                                    {getFieldDecorator('analyst', {
                                        initialValue: analyst,
                                        rules: [{
                                            required: true,
                                            message: 'Please input analyst*',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'analyst', value: e.target.value })
                                            }}
                                        />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                            <Col >
                                <FormItem {...formItemLayout} label="type">
                                {getFieldDecorator('type', {
                                    initialValue: type,
                                    rules: [{
                                        required: true,
                                        message: 'Please select type',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'type', value: val })
                                            }} 
                                            placeholder="Please select type">
                                        {typeOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                                            
                        <Row>
                            <Col >
                                <FormItem {...formItemLayout} label="note">
                                {getFieldDecorator('note', {
                                    initialValue: note,
                                    rules: [{
                                        required: false,
                                        message: 'Please input note',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 600, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'note', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row> 



                        <Row >
                        <Col >
                                <FormItem {...formItemLayout} label="Research Depth">
                                {getFieldDecorator('researchdepth', {
                                    initialValue: researchdepth,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Research Depth.',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'researchdepth', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col >
                                <FormItem {...formItemLayout} label="Stock Pitch">
                                {getFieldDecorator('stockpitch', {
                                    initialValue: stockpitch,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Stock Pitch',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'stockpitch', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
               

                        
                        
                </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditMeetingAndTrainingCalenderModalDialogForm = Form.create({ name: 'AddOrEditMeetingAndTrainingCalenderModalDialog' })(AddOrEditMeetingAndTrainingCalenderModalDialog);
export default AddOrEditMeetingAndTrainingCalenderModalDialogForm;
