import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Button, message } from 'antd';
import AddOrEditRestrictedRuleDialog from './AddOrEditRestrictedRuleDialog'

class RestrictedSecurityRuleGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.restrictedSecurityRuleGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "single",
      context: { componentParent: this },
      frameworkComponents: {
      },
      detailCellRendererParams: {
        detailGridOptions: {
            columnDefs:[
                {field: 'fund', headerName: 'Fund', cellClass: 'non-number', width: 150},
                {field: 'book', headerName: 'Book', cellClass: 'non-number', width: 150},
            ],
            suppressRowClickSelection: true,
            suppressAggFuncInHeader: true,
            enableColResize: true,
            enableSorting: true,
            enableFilter: true,
            animateRows: true,
            deltaRowDataMode: false,
        },
        getDetailRowData: function(params) {
            params.successCallback(params.data.fundBookList);
        },
        getRowNodeId: function(data) {
            return data.id;
        },
      },
      selectedRow: null
    };
  }

  componentDidMount(){
    this.props.loadRestrictedRuleList({});
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  refreshData = () => {
    this.props.loadRestrictedRuleList({});
  }

  addBtnClick = () => {
    this.props.openRestrictedRuleDialog('NEW', {
      isFundBookMatched: 0
    });
  }

  updateBtnClick = () => {
    const {
      selectedRow
    } = this.state;
    if(selectedRow){
      this.props.openRestrictedRuleDialog('EDIT', selectedRow);
    }
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(rowNode => rowNode.data);

    this.setState({
      selectedRow: restrictedSecurityRequestArray[0]
    });
  }

  render() {
    const {
      restrictedRuleList,
      className = 'grid-wrapper'
    } = this.props;

    return (
      <div className='restrictedSecurityGrid'>
        <div className='operationBar'>
          <Button 
            onClick={this.refreshData} 
            size='small'
            className='positive-button'>
            Refresh
          </Button>
          <Button 
            onClick={this.addBtnClick} 
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.updateBtnClick} 
            size='small'
            type='primary'>
            Edit
          </Button>
        </div>
        <div className={`ag-theme-balham ${className}`}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={restrictedRuleList}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        </div> 
        <AddOrEditRestrictedRuleDialog {...this.props} />    
      </div>
    );
  }
}

export default RestrictedSecurityRuleGrid;