import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../utils/RadioOperationMode';

import {
  // Load Restricted Sectors
  START_LOAD_RESTRICTED_SECTORS,
  LOAD_RESTRICTED_SECTORS_SUCCESS,
  LOAD_RESTRICTED_SECTORS_FAILURE,
  INIT_LOAD_RESTRICTED_SECTORS_ACTION_STATUS,

  // Create Restricted SECTOR
  START_CREATE_RESTRICTED_SECTOR,
  CREATE_RESTRICTED_SECTOR_SUCCESS,
  CREATE_RESTRICTED_SECTOR_FAILURE,
  INIT_CREATE_RESTRICTED_SECTOR_ACTION_STATUS,

  // Update Restricted SECTOR
  START_UPDATE_RESTRICTED_SECTOR,
  UPDATE_RESTRICTED_SECTOR_SUCCESS,
  UPDATE_RESTRICTED_SECTOR_FAILURE,
  INIT_UPDATE_RESTRICTED_SECTOR_ACTION_STATUS,

  // Batch Delete Restricted SECTOR
  START_BATCH_DELETE_RESTRICTED_SECTOR,
  BATCH_DELETE_RESTRICTED_SECTOR_SUCCESS,
  BATCH_DELETE_RESTRICTED_SECTOR_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_SECTOR_ACTION_STATUS,

  // Open/Reset Restricted SECTOR Modal Dialog
  RESTRICTED_SECTOR_MODAL_DIALOG_OPEN,
  RESTRICTED_SECTOR_MODAL_DIALOG_RESET,

  // Restricted SECTOR Edit Change Input
  RESTRICTED_SECTOR_EDIT_CHANGE_INPUT,

  // RadioGroup Change
  RADIO_GROUP_CHANGE,

  // Sector Name Select Changed
  SECTOR_NAME_SELECT_CHANGED,

  // Select Restricted Sectors
  SELECT_RESTRICTED_SECTORS,

  // Fund Code Select Changed
  FUND_CODE_SELECT_CHANGED,

  // Book Code Select Changed
  BOOK_CODE_SELECT_CHANGED,
} from './restrictedSectorConstants';

import {
  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,

  // Load Sector Codes
  LOAD_SECTOR_CODES_SUCCESS,
} from '../../home/homeConstants';

const Option = Select.Option;

const initialState = {
  restrictedSectorGridColumns: GridColumnMap.restrictedSectorGridColumns,

  // Restricted Sector
  restrictedSectorArray: [],
  filterRestrictedSectorArray: [],

  restrictedSectorEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      id: '',
      restrictionType: '',
      fundCode: '',
      bookCode: '',
      pmName: '',
      sectorId: '',
      sectorName: '',
      explanation: '',
      kickOffDate: '',
      expiryDate: '',
      isWhite: true,
    }
  },

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // Load Sector Codes
  sectorCodeDOArray: [],
  sectorCodeArray: [],
  sectorCodeOptions: [],

  // Load Restricted Sector Action Status
  loadRestrictedSectorActionStatus: ACTION_STATUS.READY,
  createRestrictedSectorActionStatus: ACTION_STATUS.READY,
  updateRestrictedSectorActionStatus: ACTION_STATUS.READY,
  batchDeleteRestrictedSectorActionStatus: ACTION_STATUS.READY,

  // Selected Restricted Sector
  selectedRestrictedSectors: [],
  
}

// ========================================================================
// Load Restricted Sectors
function startLoadRestrictedSectors(state) {
  return {
    ...state,
    loadRestrictedSectorActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSectorsSuccess(state, resp) {
  return {
    ...state,
    restrictedSectorArray: resp.data,
    filterRestrictedSectorArray: resp.data,
    loadRestrictedSectorActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedSectorsFailure(state, err) {
  return {
    ...state,
    loadRestrictedSectorActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSectorsActionStatus(state) {
  return {
    ...state,
    loadRestrictedCountryActionStatus: ACTION_STATUS.READY,
  };
}

// Create Restricted Sector
function startCreateRestrictedSector(state) {
  return {
    ...state,
    createRestrictedSectorActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createRestrictedSectorSuccess(state, resp) {
  return {
    ...state,
    createRestrictedSectorActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createRestrictedSectorFailure(state, err) {
  return {
    ...state,
    createRestrictedSectorActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCreateRestrictedSectorActionStatus(state) {
  return {
    ...state,
    createRestrictedSectorActionStatus: ACTION_STATUS.READY,
  };
}

// Update Restricted Sector
function startUpdateRestrictedSector(state) {
  return {
    ...state,
    updateRestrictedSectorActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateRestrictedSectorSuccess(state, resp) {
  return {
    ...state,
    updateRestrictedSectorActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateRestrictedSectorFailure(state, err) {
  return {
    ...state,
    updateRestrictedSectorActionStatus: ACTION_STATUS.ERROR,
  };
}

function initUpdateRestrictedSectorActionStatus(state) {
  return {
    ...state,
    updateRestrictedSectorActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Restricted Sector
function startBatchDeleteRestrictedSector(state) {
  return {
    ...state,
    batchDeleteRestrictedSectorActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteRestrictedSectorSuccess(state, resp) {
  return {
    ...state,
    batchDeleteRestrictedSectorActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteRestrictedSectorFailure(state, err) {
  return {
    ...state,
    batchDeleteRestrictedSectorActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteRestrictedSectorActionStatus(state) {
  return {
    ...state,
    batchDeleteRestrictedSectorActionStatus: ACTION_STATUS.READY,
  };
}

// ========================================================================
// RadioGroup Change
function radioGroupChange(state, value) {
  const restrictedSectorArray = state.restrictedSectorArray;
  let filterRestrictedSectorArray = [];

  if (value === RadioOperationMode.ALL) {
    filterRestrictedSectorArray = restrictedSectorArray;
  }

  if (value === RadioOperationMode.WHITE) {
    filterRestrictedSectorArray = restrictedSectorArray.filter(restrictedSector => restrictedSector.isWhite);
  }

  if (value === RadioOperationMode.BLACK) {
    filterRestrictedSectorArray = restrictedSectorArray.filter(restrictedSector => !restrictedSector.isWhite);
  }

  return {
    ...state,
    filterRestrictedSectorArray,
  };
}



// Open/Reset Restricted Sector Modal Dialog
function openRestrictedSectorEditModalDialog(state, {isAddMode, restrictedSector}) {
  if (isAddMode) {
    return {
      ...state,
      restrictedSectorEditControlModal: {
        ...state.restrictedSectorEditControlModal,
        isOpened: true,
        isAddMode,
      },
    };
  } else {
    return {
      ...state,
      restrictedSectorEditControlModal: {
        ...state.restrictedSectorEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedSectorEditControlModal.fields,
          id: restrictedSector.id,
          restrictionType: restrictedSector.restrictionType,
          fundCode: restrictedSector.fund,
          bookCode: restrictedSector.book,
          pmName: restrictedSector.pmName,
          sectorId: restrictedSector.sectorId,
          sectorName: restrictedSector.sectorName,
          explanation: restrictedSector.explanation,
          kickOffDate: restrictedSector.kickOffDate,
          expiryDate: restrictedSector.expiryDate,
          isWhite: restrictedSector.isWhite,
        }
      },
    };
  }
}

function resetRestrictedSectorEditModalDialog(state) {
  return {
    ...state,
    restrictedSectorEditControlModal: initialState.restrictedSectorEditControlModal,
  };
}

// Restricted Sector Edit Change Input
function restrictedSectorEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedSectorEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedSectorEditControlModal: {
      ...state.restrictedSectorEditControlModal,
      fields: updatedFields,
    },
  };
}


// ========================================================================
// Sector Name Select Changed
function sectorNameSelectChanged(state, sectorName) {
  let sectorCodeDOArray = state.sectorCodeDOArray;
  let filterSectorCodeArray = sectorCodeDOArray.filter(sectorCodeDO => sectorCodeDO.sectorCode === sectorName);
  
  let sectorId = '';
  if (!!sectorName && filterSectorCodeArray.length !== 0) {
    sectorId = filterSectorCodeArray[0].sectorId;
  }

  return {
    ...state,
    restrictedSectorEditControlModal: {
      ...state.restrictedSectorEditControlModal,
      fields: {
        ...state.restrictedSectorEditControlModal.fields,
        sectorId,
      },
    },
  };
}

// Select Restricted Sectors
function selectRestrictedSectors(state, payload) {
  return {
    ...state,
    selectedRestrictedSectors: payload,
  };
}


// Fund Code Select Changed
function fundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedSectorEditControlModal.fields.bookCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSectorEditControlModal: {
      ...state.restrictedSectorEditControlModal,
      fields: {
        ...state.restrictedSectorEditControlModal.fields,
        pmName,
      },
    },
  };
}

// Book Code Select Changed
function bookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedSectorEditControlModal.fields.fundCode;

  let pmName = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      pmName = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSectorEditControlModal: {
      ...state.restrictedSectorEditControlModal,
      fields: {
        ...state.restrictedSectorEditControlModal.fields,
        pmName,
      },
    },
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// Load Sector Codes
function loadSectorCodesSuccess(state, resp) {
  let sectorCodeDOArray = resp.data;
  let sectorCodeArray = sectorCodeDOArray.map(sectorCodeDO => sectorCodeDO.sectorCode);
  let sectorCodeOptions = sectorCodeArray.map(sectorCode => <Option key={sectorCode}>{sectorCode}</Option>);

  return {
    ...state,
    sectorCodeDOArray,
    sectorCodeArray,
    sectorCodeOptions,
  };
}

export default createReducer(initialState, {
  // Load Restricted Sector
  [START_LOAD_RESTRICTED_SECTORS]: startLoadRestrictedSectors,
  [LOAD_RESTRICTED_SECTORS_SUCCESS]: loadRestrictedSectorsSuccess,
  [LOAD_RESTRICTED_SECTORS_FAILURE]: loadRestrictedSectorsFailure,
  [INIT_LOAD_RESTRICTED_SECTORS_ACTION_STATUS]: initLoadRestrictedSectorsActionStatus,
  
  // Create Restricted SECTOR
  [START_CREATE_RESTRICTED_SECTOR]: startCreateRestrictedSector,
  [CREATE_RESTRICTED_SECTOR_SUCCESS]: createRestrictedSectorSuccess,
  [CREATE_RESTRICTED_SECTOR_FAILURE]: createRestrictedSectorFailure,
  [INIT_CREATE_RESTRICTED_SECTOR_ACTION_STATUS]: initCreateRestrictedSectorActionStatus,

  // Update Restricted SECTOR
  [START_UPDATE_RESTRICTED_SECTOR]: startUpdateRestrictedSector,
  [UPDATE_RESTRICTED_SECTOR_SUCCESS]: updateRestrictedSectorSuccess,
  [UPDATE_RESTRICTED_SECTOR_FAILURE]: updateRestrictedSectorFailure,
  [INIT_UPDATE_RESTRICTED_SECTOR_ACTION_STATUS]: initUpdateRestrictedSectorActionStatus,

  // Delete Restricted SECTOR
  [START_BATCH_DELETE_RESTRICTED_SECTOR]: startBatchDeleteRestrictedSector,
  [BATCH_DELETE_RESTRICTED_SECTOR_SUCCESS]: batchDeleteRestrictedSectorSuccess,
  [BATCH_DELETE_RESTRICTED_SECTOR_FAILURE]: batchDeleteRestrictedSectorFailure,
  [INIT_BATCH_DELETE_RESTRICTED_SECTOR_ACTION_STATUS]: initBatchDeleteRestrictedSectorActionStatus,

  // Open/Reset Restricted SECTOR Modal Dialog
  [RESTRICTED_SECTOR_MODAL_DIALOG_OPEN]: openRestrictedSectorEditModalDialog,
  [RESTRICTED_SECTOR_MODAL_DIALOG_RESET]: resetRestrictedSectorEditModalDialog,

  // Restricted SECTOR Edit Change Input
  [RESTRICTED_SECTOR_EDIT_CHANGE_INPUT]: restrictedSectorEditChangeInput,

  // RadioGroup Change
  [RADIO_GROUP_CHANGE]: radioGroupChange,

  // Sector Name Select Changed
  [SECTOR_NAME_SELECT_CHANGED]: sectorNameSelectChanged,

  // Select Restricted Sectors
  [SELECT_RESTRICTED_SECTORS]: selectRestrictedSectors,

  // Fund Code Select Changed
  [FUND_CODE_SELECT_CHANGED]: fundCodeSelectChanged,

  // Book Code Select Changed
  [BOOK_CODE_SELECT_CHANGED]: bookCodeSelectChanged,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load Sector Codes
  [LOAD_SECTOR_CODES_SUCCESS]: loadSectorCodesSuccess,
});