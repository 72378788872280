import {connect} from 'react-redux';
import RestrictedCountry from '../components/RestrictedCountry';

import {
  // Load Restricted Country
  loadRestrictedCountries,
  initLoadRestrictedCountriesActionStatus,

  // Create Restricted Country
  createRestrictedCountry,
  initCreateRestrictedCountryActionStatus,

  // Update Restricted Country
  updateRestrictedCountry,
  initUpdateRestrictedCountryActionStatus,

  // Batch Delete Restricted Country
  batchDeleteRestrictedCountry,
  initBatchDeleteRestrictedCountryActionStatus,

  // RadioGroup Change
  radioGroupChange,

  // Open/Reset Restricted Country Modal Dialog
  openRestrictedCountryEditModalDialog,
  resetRestrictedCountryEditModalDialog,

  // Restricted Country Edit Change Input
  restrictedCountryEditChangeInput,
 
  // select Restricted Country
  selectRestrictedCountry,

  // Country Code Select Changed
  countryCodeSelectChanged,

  // Fund Code Select Changed
  fundCodeSelectChanged,

  // Book Code Select Changed
  bookCodeSelectChanged,
} from '../restrictedCountryActions';

const mapStateToProps = state => {
  return {
    restrictedCountryGridColumns: state.restrictedCountry.restrictedCountryGridColumns,

    restrictedCountryArray: state.restrictedCountry.restrictedCountryArray,
    filterRestrictedCountryArray: state.restrictedCountry.filterRestrictedCountryArray,

    restrictedCountryEditControlModal: state.restrictedCountry.restrictedCountryEditControlModal,

    // Fund Book Data
    fundCodeOptions: state.restrictedCountry.fundCodeOptions,
    bookCodeOptions: state.restrictedCountry.bookCodeOptions,

    // Country Code
    countryCodeOptions: state.restrictedCountry.countryCodeOptions,

    loadRestrictedCountryActionStatus: state.restrictedCountry.loadRestrictedCountryActionStatus,
    createRestrictedCountryActionStatus: state.restrictedCountry.createRestrictedCountryActionStatus,
    updateRestrictedCountryActionStatus: state.restrictedCountry.updateRestrictedCountryActionStatus,
    batchDeleteRestrictedCountryActionStatus: state.restrictedCountry.batchDeleteRestrictedCountryActionStatus,

    // Selected Restricted Country
    selectedRestrictedCountry: state.restrictedCountry.selectedRestrictedCountry,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Restricted Country
    loadRestrictedCountries: (value) => dispatch(loadRestrictedCountries(value)),
    initLoadRestrictedCountriesActionStatus: (value) => dispatch(initLoadRestrictedCountriesActionStatus(value)),

    // Create Restricted Country
    createRestrictedCountry: (restrictedCountry) => dispatch(createRestrictedCountry(restrictedCountry)),
    initCreateRestrictedCountryActionStatus: () => dispatch(initCreateRestrictedCountryActionStatus()),

    // Update Restricted Country
    updateRestrictedCountry: (restrictedCountry) => dispatch(updateRestrictedCountry(restrictedCountry)),
    initUpdateRestrictedCountryActionStatus: () => dispatch(initUpdateRestrictedCountryActionStatus()),

    // Batch Delete Restricted Country
    batchDeleteRestrictedCountry: (restrictedCountryIdList) => dispatch(batchDeleteRestrictedCountry(restrictedCountryIdList)),
    initBatchDeleteRestrictedCountryActionStatus: () => dispatch(initBatchDeleteRestrictedCountryActionStatus()),

    // RadioGroup Change
    radioGroupChange: (value) => dispatch(radioGroupChange(value)),

    // Open/Reset Restricted Country Modal Dialog
    openRestrictedCountryEditModalDialog: (isAddMode, restrictedCountry) => dispatch(openRestrictedCountryEditModalDialog(isAddMode, restrictedCountry)),
    resetRestrictedCountryEditModalDialog: () => dispatch(resetRestrictedCountryEditModalDialog()),

    // Restricted Country Edit Change Input
    restrictedCountryEditChangeInput: (input) => dispatch(restrictedCountryEditChangeInput(input)),
 
    // select Restricted Country
    selectRestrictedCountry: (payload) => dispatch(selectRestrictedCountry(payload)),

    // Country Code Select Changed
    countryCodeSelectChanged: (countryCode) => dispatch(countryCodeSelectChanged(countryCode)),
 
    // Fund Code Select Changed
    fundCodeSelectChanged: (fundCode) => dispatch(fundCodeSelectChanged(fundCode)),
 
    // Book Code Select Changed
    bookCodeSelectChanged: (bookCode) => dispatch(bookCodeSelectChanged(bookCode)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedCountry);