import React, { Component } from 'react';

import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';

import '../../ComplianceRule.css';

class RestrictedAssetGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.restrictedAssetGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "multiple",
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var assetList = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectRestrictedAssetClass(assetList);
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    // const COLUMNS_KEY = 'fund-list-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      filterRestrictedAssetArray,
      className = 'grid-wrapper',
      isLoaded = false,
    } = this.props;

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoaded ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={filterRestrictedAssetArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            getRowNodeId={this.getRowNodeId}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default RestrictedAssetGrid;