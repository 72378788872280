// query
export const ON_QUERY_INPUT_CHANGE = 'ON_QUERY_INPUT_CHANGE';
// Load Questionnaire
export const START_LOAD_QUESTIONNAIRES = 'START_LOAD_QUESTIONNAIRES';
export const LOAD_QUESTIONNAIRES_SUCCESS = 'LOAD_QUESTIONNAIRES_SUCCESS';
export const LOAD_QUESTIONNAIRES_FAILURE = 'LOAD_QUESTIONNAIRES_FAILURE';
export const INIT_LOAD_QUESTIONNAIRES_ACTION_STATUS = 'INIT_LOAD_QUESTIONNAIRES_ACTION_STATUS';

// Select Questionnaires
export const SELECT_QUESTIONNAIRES = 'SELECT_QUESTIONNAIRES';

// Load Question
export const START_LOAD_QUESTIONS = 'START_LOAD_QUESTIONS';
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS';
export const LOAD_QUESTIONS_FAILURE = 'LOAD_QUESTIONS_FAILURE';
export const INIT_LOAD_QUESTIONS_ACTION_STATUS = 'INIT_LOAD_QUESTIONS_ACTION_STATUS';

// Select Questions
export const SELECT_QUESTIONS = 'SELECT_QUESTIONS';

// Open/Reset Answeree Select Modal Dialog
export const ANSWEREE_SELECT_MODAL_DIALOG_OPEN = 'ANSWEREE_SELECT_MODAL_DIALOG_OPEN';
export const ANSWEREE_SELECT_MODAL_DIALOG_RESET = 'ANSWEREE_SELECT_MODAL_DIALOG_RESET';

// Select All Answeree
export const SELECT_ALL_ANSWEREE = 'SELECT_ALL_ANSWEREE';

// Select Invert Answeree
export const SELECT_INVERT_ANSWEREE = 'SELECT_INVERT_ANSWEREE';

// Answeree Check Changed
export const ANSWEREE_CHECK_CHANGED = 'ANSWEREE_CHECK_CHANGED';

// Select Answerees
export const START_SELECT_ANSWEREES = 'START_SELECT_ANSWEREES';
export const SELECT_ANSWEREES_SUCCESS = 'SELECT_ANSWEREES_SUCCESS';
export const SELECT_ANSWEREES_FAILURE = 'SELECT_ANSWEREES_FAILURE';
export const INIT_SELECT_ANSWEREES_ACTION_STATUS = 'INIT_SELECT_ANSWEREES_ACTION_STATUS';

// Questionnaire Option Change
export const QUESTIONNAIRE_OPTION_CHANGE = 'QUESTIONNAIRE_OPTION_CHANGE';






// Load Answers
export const START_LOAD_ANSWERS = 'START_LOAD_ANSWERS';
export const LOAD_ANSWERS_SUCCESS = 'LOAD_ANSWERS_SUCCESS';
export const LOAD_ANSWERS_FAILURE = 'LOAD_ANSWERS_FAILURE';
export const INIT_LOAD_ANSWERS_ACTION_STATUS = 'INIT_LOAD_ANSWERS_ACTION_STATUS';





// Load To-do Questionnaire
export const START_LOAD_TODO_QUESTIONNAIRE = 'START_LOAD_TODO_QUESTIONNAIRE';
export const LOAD_TODO_QUESTIONNAIRE_SUCCESS = 'LOAD_TODO_QUESTIONNAIRE_SUCCESS';
export const LOAD_TODO_QUESTIONNAIRE_FAILURE = 'LOAD_TODO_QUESTIONNAIRE_FAILURE';
export const INIT_LOAD_TODO_QUESTIONNAIRE_ACTION_STATUS = 'INIT_LOAD_TODO_QUESTIONNAIRE_ACTION_STATUS';

export const START_LOAD_MEETINGANDTRANINGSUB = 'START_LOAD_MEETINGANDTRANINGSUB';
export const LOAD_MEETINGANDTRANINGSUB_SUCCESS = 'LOAD_MEETINGANDTRANINGSUB_SUCCESS';
export const LOAD_MEETINGANDTRANINGSUB_FAILURE = 'LOAD_MEETINGANDTRANINGSUB_FAILURE';

export const START_LOAD_TODO_LICENSINGPERSONAL = 'START_LOAD_TODO_LICENSINGPERSONAL';
export const LOAD_TODO_LICENSINGPERSONAL_SUCCESS = 'LOAD_TODO_LICENSINGPERSONAL_SUCCESS';
export const LOAD_TODO_LICENSINGPERSONAL_FAILURE = 'LOAD_TODO_LICENSINGPERSONAL_FAILURE';
export const INIT_LOAD_TODO_LICENSINGPERSONAL_ACTION_STATUS = 'INIT_LOAD_TODO_LICENSINGPERSONAL_ACTION_STATUS';


export const START_SAVE_LICENSINGPERSONAL_ANSWER = 'START_SAVE_LICENSINGPERSONAL_ANSWER';
export const SAVE_LICENSINGPERSONAL_ANSWER_SUCCESS = 'SAVE_LICENSINGPERSONAL_ANSWER_SUCCESS';
export const SAVE_LICENSINGPERSONAL_ANSWER_FAILURE = 'SAVE_LICENSINGPERSONAL_ANSWER_FAILURE';
export const INIT_SAVE_LICENSINGPERSONAL_ANSWER_ACTION_STATUS = 'INIT_SAVE_LICENSINGPERSONAL_ANSWER_ACTION_STATUS';



export const START_SUBMIT_LICENSINGPERSONAL_ANSWER = 'START_SUBMIT_LICENSINGPERSONAL_ANSWER';
export const SUBMIT_LICENSINGPERSONAL_ANSWER_SUCCESS = 'SUBMIT_LICENSINGPERSONAL_ANSWER_SUCCESS';
export const SUBMIT_LICENSINGPERSONAL_ANSWER_FAILURE = 'SUBMIT_LICENSINGPERSONAL_ANSWER_FAILURE';
export const INIT_SUBMIT_LICENSINGPERSONAL_ANSWER_ACTION_STATUS = 'INIT_SUBMIT_LICENSINGPERSONAL_ANSWER_ACTION_STATUS';

// Submit Questionnaire Answer
export const START_SUBMIT_QUESTIONNAIRE_ANSWER = 'START_SUBMIT_QUESTIONNAIRE_ANSWER';
export const SUBMIT_QUESTIONNAIRE_ANSWER_SUCCESS = 'SUBMIT_QUESTIONNAIRE_ANSWER_SUCCESS';
export const SUBMIT_QUESTIONNAIRE_ANSWER_FAILURE = 'SUBMIT_QUESTIONNAIRE_ANSWER_FAILURE';
export const INIT_SUBMIT_QUESTIONNAIRE_ANSWER_ACTION_STATUS = 'INIT_SUBMIT_QUESTIONNAIRE_ANSWER_ACTION_STATUS';

// 签到  MEETINGANDTRANINGSUB
export const START_SUBMIT_MEETINGANDTRANINGSUB_ANSWER = 'START_SUBMIT_MEETINGANDTRANINGSUB_ANSWER';
export const SUBMIT_MEETINGANDTRANINGSUB_ANSWER_SUCCESS = 'SUBMIT_MEETINGANDTRANINGSUB_ANSWER_SUCCESS';
export const SUBMIT_MEETINGANDTRANINGSUB_ANSWER_FAILURE = 'SUBMIT_MEETINGANDTRANINGSUB_ANSWER_FAILURE';
export const INIT_SUBMIT_MEETINGANDTRANINGSUB_ANSWER_ACTION_STATUS = 'INIT_SUBMIT_MEETINGANDTRANINGSUB_ANSWER_ACTION_STATUS';

// Save Questionnaire Answer
export const START_SAVE_QUESTIONNAIRE_ANSWER = 'START_SAVE_QUESTIONNAIRE_ANSWER';
export const SAVE_QUESTIONNAIRE_ANSWER_SUCCESS = 'SAVE_QUESTIONNAIRE_ANSWER_SUCCESS';
export const SAVE_QUESTIONNAIRE_ANSWER_FAILURE = 'SAVE_QUESTIONNAIRE_ANSWER_FAILURE';
export const INIT_SAVE_QUESTIONNAIRE_ANSWER_ACTION_STATUS = 'INIT_SAVE_QUESTIONNAIRE_ANSWER_ACTION_STATUS';

// Questionnaire Answer Change Input
export const QUESTIONNAIRE_ANSWER_CHANGE_INPUT = 'QUESTIONNAIRE_ANSWER_CHANGE_INPUT';

// ================================================================================
// Open/Reset Questionnaire Edit Modal Dialog
export const QUESTIONNAIRE_EDIT_MODAL_DIALOG_OPEN = 'QUESTIONNAIRE_EDIT_MODAL_DIALOG_OPEN';
export const QUESTIONNAIRE_EDIT_MODAL_DIALOG_RESET = 'QUESTIONNAIRE_EDIT_MODAL_DIALOG_RESET';

// Create Questionnaire
export const START_CREATE_QUESTIONNAIRE = 'START_CREATE_QUESTIONNAIRE';
export const CREATE_QUESTIONNAIRE_SUCCESS = 'CREATE_QUESTIONNAIRE_SUCCESS';
export const CREATE_QUESTIONNAIRE_FAILURE = 'CREATE_QUESTIONNAIRE_FAILURE';
export const INIT_CREATE_QUESTIONNAIRE_ACTION_STATUS = 'INIT_CREATE_QUESTIONNAIRE_ACTION_STATUS';

// Update Questionnaire
export const START_UPDATE_QUESTIONNAIRE = 'START_UPDATE_QUESTIONNAIRE';
export const UPDATE_QUESTIONNAIRE_SUCCESS = 'UPDATE_QUESTIONNAIRE_SUCCESS';
export const UPDATE_QUESTIONNAIRE_FAILURE = 'UPDATE_QUESTIONNAIRE_FAILURE';
export const INIT_UPDATE_QUESTIONNAIRE_ACTION_STATUS = 'INIT_UPDATE_QUESTIONNAIRE_ACTION_STATUS';

// Copy Questionnaire
export const START_COPY_QUESTIONNAIRE = 'START_COPY_QUESTIONNAIRE';
export const COPY_QUESTIONNAIRE_SUCCESS = 'COPY_QUESTIONNAIRE_SUCCESS';
export const COPY_QUESTIONNAIRE_FAILURE = 'COPY_QUESTIONNAIRE_FAILURE';
export const INIT_COPY_QUESTIONNAIRE_ACTION_STATUS = 'INIT_COPY_QUESTIONNAIRE_ACTION_STATUS';

// Delete Questionnaire
export const START_BATCH_DELETE_QUESTIONNAIRE = 'START_BATCH_DELETE_QUESTIONNAIRE';
export const BATCH_DELETE_QUESTIONNAIRE_SUCCESS = 'BATCH_DELETE_QUESTIONNAIRE_SUCCESS';
export const BATCH_DELETE_QUESTIONNAIRE_FAILURE = 'BATCH_DELETE_QUESTIONNAIRE_FAILURE';
export const INIT_BATCH_DELETE_QUESTIONNAIRE_ACTION_STATUS = 'INIT_BATCH_DELETE_QUESTIONNAIRE_ACTION_STATUS';

// Questionnaire Edit Change Input
export const QUESTIONNAIRE_EDIT_CHANGE_INPUT = 'QUESTIONNAIRE_EDIT_CHANGE_INPUT';


// ================================================================================
// Open/Reset Question Edit Modal Dialog
export const QUESTION_EDIT_MODAL_DIALOG_OPEN = 'QUESTION_EDIT_MODAL_DIALOG_OPEN';
export const QUESTION_EDIT_MODAL_DIALOG_RESET = 'QUESTION_EDIT_MODAL_DIALOG_RESET';

// Create Question
export const START_CREATE_QUESTION = 'START_CREATE_QUESTION';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAILURE = 'CREATE_QUESTION_FAILURE';
export const INIT_CREATE_QUESTION_ACTION_STATUS = 'INIT_CREATE_QUESTION_ACTION_STATUS';

// Update Question
export const START_UPDATE_QUESTION = 'START_UPDATE_QUESTION';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAILURE = 'UPDATE_QUESTION_FAILURE';
export const INIT_UPDATE_QUESTION_ACTION_STATUS = 'INIT_UPDATE_QUESTION_ACTION_STATUS';

// Delete Question
export const START_BATCH_DELETE_QUESTION = 'START_BATCH_DELETE_QUESTION';
export const BATCH_DELETE_QUESTION_SUCCESS = 'BATCH_DELETE_QUESTION_SUCCESS';
export const BATCH_DELETE_QUESTION_FAILURE = 'BATCH_DELETE_QUESTION_FAILURE';
export const INIT_BATCH_DELETE_QUESTION_ACTION_STATUS = 'INIT_BATCH_DELETE_QUESTION_ACTION_STATUS';

// Question Edit Change Input
export const QUESTION_EDIT_CHANGE_INPUT = 'QUESTION_EDIT_CHANGE_INPUT';


// Load Questionnaire Results
export const START_LOAD_QUESTIONNAIRE_RESULTS = 'START_LOAD_QUESTIONNAIRE_RESULTS';
export const LOAD_QUESTIONNAIRE_RESULTS_SUCCESS = 'LOAD_QUESTIONNAIRE_RESULTS_SUCCESS';
export const LOAD_QUESTIONNAIRE_RESULTS_FAILURE = 'LOAD_QUESTIONNAIRE_RESULTS_FAILURE';
export const INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS = 'INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS';

// Select Questionnaire Results
export const SELECT_QUESTIONNAIRE_RESULTS = 'SELECT_QUESTIONNAIRE_RESULTS';

// Load Questionnaire Result Detail
export const START_LOAD_QUESTIONNAIRE_RESULT_DETAIL = 'START_LOAD_QUESTIONNAIRE_RESULT_DETAIL';
export const LOAD_QUESTIONNAIRE_RESULT_DETAIL_SUCCESS = 'LOAD_QUESTIONNAIRE_RESULT_DETAIL_SUCCESS';
export const LOAD_QUESTIONNAIRE_RESULT_DETAIL_FAILURE = 'LOAD_QUESTIONNAIRE_RESULT_DETAIL_FAILURE';
export const INIT_LOAD_QUESTIONNAIRE_RESULT_DETAIL_ACTION_STATUS = 'INIT_LOAD_QUESTIONNAIRE_RESULT_DETAIL_ACTION_STATUS';

// Accept Questionnaire Result
export const START_ACCEPT_QUESTIONNAIRE_RESULT = 'START_ACCEPT_QUESTIONNAIRE_RESULT';
export const ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS = 'ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS';
export const ACCEPT_QUESTIONNAIRE_RESULT_FAILURE = 'ACCEPT_QUESTIONNAIRE_RESULT_FAILURE';
export const INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS = 'INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS';

// Reject Questionnaire Result
export const START_REJECT_QUESTIONNAIRE_RESULT = 'START_REJECT_QUESTIONNAIRE_RESULT';
export const REJECT_QUESTIONNAIRE_RESULT_SUCCESS = 'REJECT_QUESTIONNAIRE_RESULT_SUCCESS';
export const REJECT_QUESTIONNAIRE_RESULT_FAILURE = 'REJECT_QUESTIONNAIRE_RESULT_FAILURE';
export const INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS = 'INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS';

// Open/Reset Questionnaire Result Operate Modal Dialog
export const QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_OPEN = 'QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_OPEN';
export const QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_RESET = 'QUESTIONNAIRE_RESULT_OPERATE_MODAL_DIALOG_RESET';

// Questionnaire Answer Submitter Edit Change Input
export const QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT = 'QUESTIONNAIRE_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT';

export const MEETINGANDTRAINING_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT = 'MEETINGANDTRAINING_ANSWER_SUBMITTER_EDIT_CHANGE_INPUT';

// Send Remind Email
export const START_SEND_REMIND_EMAIL = 'START_SEND_REMIND_EMAIL';
export const SEND_REMIND_EMAIL_SUCCESS = 'SEND_REMIND_EMAIL_SUCCESS';
export const SEND_REMIND_EMAIL_FAILURE = 'SEND_REMIND_EMAIL_FAILURE';
export const INIT_SEND_REMIND_EMAIL_ACTION_STATUS = 'INIT_SEND_REMIND_EMAIL_ACTION_STATUS';

// Open/Reset Questionnaire Email Edit Modal Dialog
export const QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN = 'QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN';
export const QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET = 'QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET';

// Update Questionnaire Score
export const START_UPDATE_QUESTIONNAIRE_SCORE = 'START_UPDATE_QUESTIONNAIRE_SCORE';
export const UPDATE_QUESTIONNAIRE_SCORE_SUCCESS = 'UPDATE_QUESTIONNAIRE_SCORE_SUCCESS';
export const UPDATE_QUESTIONNAIRE_SCORE_FAILURE = 'UPDATE_QUESTIONNAIRE_SCORE_FAILURE';
export const INIT_UPDATE_QUESTIONNAIRE_SCORE_ACTION_STATUS = 'INIT_UPDATE_QUESTIONNAIRE_SCORE_ACTION_STATUS';

// Calculate Questionnaire Total Score
export const CALC_QUESTIONNAIRE_TOTAL_SCORE = 'CALC_QUESTIONNAIRE_TOTAL_SCORE';

// Open/Reset Submitted Questionnaire History Modal Dialog
export const SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN = 'SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_OPEN';
export const SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET = 'SUBMITTED_QUESTIONNAIRE_HISTORY_MODAL_DIALOG_RESET';

// Load Submitted Questionnaire History
export const START_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY = 'START_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY';
export const LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_SUCCESS = 'LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_SUCCESS';
export const LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_FAILURE = 'LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_FAILURE';
export const INIT_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_ACTION_STATUS = 'INIT_LOAD_SUBMITTED_QUESTIONNAIRE_HISTORY_ACTION_STATUS';

// Select Submitted Questionnaire History
export const SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY = 'SELECT_SUBMITTED_QUESTIONNAIRE_HISTORY';

// Apply Submitted Questionnaire History
export const APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY = 'APPLY_SUBMITTED_QUESTIONNAIRE_HISTORY';


export const START_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY = 'START_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY';
export const LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_SUCCESS = 'LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_SUCCESS';
export const LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_FAILURE = 'LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_FAILURE';
export const INIT_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_ACTION_STATUS = 'INIT_LOAD_SUBMITTED_LICENSINGPERSONAL_HISTORY_ACTION_STATUS';
