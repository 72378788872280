import React, { Component } from 'react';

import { Form, Row, Col, Input, Button, Modal, Select, message, AutoComplete, Upload, Icon, Checkbox } from 'antd';
import FundBookListGrid from './FundBookListGrid'
import FundBookAddDialog from './FundBookAddDialog'

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
const formItemLayoutDataGrid = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};  

const quantityDirectionOptions = [
    <Option key="BUY">BUY</Option>,
    <Option key="SELL">SELL</Option>,
    <Option key="SHRT">SHRT</Option>,
    <Option key="COVR">COVR</Option>
]

const assetClassOptions = [
  <Option key="Equity">Equity</Option>,
  <Option key="Swap">Swap</Option>,
  <Option key="Corp">Corp</Option>,
  <Option key="Comdty">Comdty</Option>
]
class AddOrEditRestrictedRuleDialog extends Component {

    onInputChange = ({name, value}) => {
      const {
        fields
      } = this.props.restrictedRuleEditControlModal;
      let updatedFields = {
        ...fields
      }
      if(name === 'isFundBookMatched' || name === 'isQuantIncluded' || name === 'isTickerMatched'){
        updatedFields = {
          ...fields,
          [name]: value?1:0
        }
      }else{
        updatedFields = {
          ...fields,
          [name]: value
        }
      }
     
      this.props.restrictedRuleEditChangeInput({name: 'fields', value: updatedFields});
    }

    submitButtonOnClicked= () => {
      const {
        fields
      } = this.props.restrictedRuleEditControlModal;
      if(fields.id){
        this.props.updateRestrictedRule(fields, {});
      }else{
        this.props.addRestrictedRule(fields, {});
      }
    }

    render(){
        const {
            isOpened,
            respErrMsg,
            isAddMode,
            fields
          } = this.props.restrictedRuleEditControlModal;

        return(
        <div>
        <Modal
          title={"Restricted Rule"}
          centered
          width={1000}
          visible={isOpened}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.closeRestrictedRuleDialog("CLOSE")}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
            <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Restriction Code" required>
                    <Input placeholder="" 
                        value={fields.code}
                        allowClear
                        onChange={(e) => {
                            this.onInputChange({ name: 'code', value: e.target.value })
                          }}
                      />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Restriction Name" required>
                    <Input placeholder="" 
                        value={fields.name}
                        allowClear
                        onChange={(e) => {
                            this.onInputChange({ name: 'name', value: e.target.value })
                          }}
                      />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Market">
                    <Input placeholder="" 
                        value={fields.market}
                        allowClear
                        onChange={(e) => {
                            this.onInputChange({ name: 'market', value: e.target.value })
                          }}
                      />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="AssetClass">
                  <Select
                        allowClear
                        showSearch
                        placeholder="assetClass" 
                        value={fields.assetClass}
                        onChange={(val) => {
                            this.onInputChange({ name: 'assetClass', value: val })
                        }} >
                        {assetClassOptions}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Quantity Direction">
                  <Select
                        allowClear
                        showSearch
                        placeholder="quantityDirection" 
                        value={fields.quantityDirection}
                        onChange={(val) => {
                            this.onInputChange({ name: 'quantityDirection', value: val })
                        }} >
                        {quantityDirectionOptions}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="IsFundBookMatched">
                    <Checkbox
                        value={fields.isFundBookMatched}
                        allowClear
                        checked={fields.isFundBookMatched === 1}
                        onChange={(e) => {
                            this.onInputChange({ name: 'isFundBookMatched', value: e.target.checked })
                          }}
                      />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="IsTickerMatched">
                    <Checkbox
                        value={fields.isTickerMatched}
                        allowClear
                        checked={fields.isTickerMatched === 1}
                        onChange={(e) => {
                            this.onInputChange({ name: 'isTickerMatched', value: e.target.checked })
                          }}
                      />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="IsQuantIncluded">
                    <Checkbox
                        value={fields.isQuantIncluded}
                        allowClear
                        checked={fields.isQuantIncluded === 1}
                        onChange={(e) => {
                            this.onInputChange({ name: 'isQuantIncluded', value: e.target.checked })
                          }}
                      />
                </FormItem>
              </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem {...formItemLayoutDataGrid} label="Fund Book List">
                        <FundBookListGrid {...this.props}/>
                    </FormItem>  
                </Col>
            </Row>
        </Form>    
        </Modal>
        <FundBookAddDialog {...this.props}/>
        </div>)
    }
}

export default AddOrEditRestrictedRuleDialog;