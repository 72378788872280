import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from '../GridColumnMap';

import {
  // Sync 13F Report
  START_SYNC_THIRTEENF_REPORT,
  SYNC_THIRTEENF_REPORT_SUCCESS,
  SYNC_THIRTEENF_REPORT_FAILURE,
  INIT_SYNC_THIRTEENF_REPORT_ACTION_STATUS,

  // Load 13F Report
  START_LOAD_THIRTEENF_REPORT,
  LOAD_THIRTEENF_REPORT_SUCCESS,
  LOAD_THIRTEENF_REPORT_FAILURE,
  INIT_LOAD_THIRTEENF_REPORT_ACTION_STATUS,

} from './thirteenFReportConstants';

const initialState = {
  // Grdi Columns
  thirteenFReportMetaGridColumns: GridColumnMap.thirteenFReportMetaGridColumns,
  thirteenFReportGridColumns: GridColumnMap.thirteenFReportGridColumns,

  // 13F Report
  thirteenFReportArray: [],

  // Load 13F Report Action Status
  loadThirteenFReportActionStatus: ACTION_STATUS.READY,
  loadThirteenFReportErrMsg: '',

  // Sync 13F Report Action Status
  syncThirteenFReportActionStatus: ACTION_STATUS.READY,
  syncThirteenFReportErrMsg: '',
  
} 

// Sync 13F Report
function startSyncThirteenFReport(state) {
  return {
    ...state,
    syncThirteenFReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function syncThirteenFReportSuccess(state, resp) {
  return {
    ...state,
    syncThirteenFReportActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function syncThirteenFReportFailure(state, err) {
  return {
    ...state,
    syncThirteenFReportActionStatus: ACTION_STATUS.ERROR,
    syncThirteenFReportErrMsg: err.respMessage,
  };
}

function initSyncThirteenFReportActionStatus(state) {
  return {
    ...state,
    syncThirteenFReportActionStatus: ACTION_STATUS.READY,
  };
}

// Load 13F Report
function startLoadThirteenFReport(state) {
  return {
    ...state,
    loadThirteenFReportActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadThirteenFReportSuccess(state, resp) {
  return {
    ...state,
    loadThirteenFReportActionStatus: ACTION_STATUS.SUCCESS,
    thirteenFReportArray: resp.data,
  };
}

function loadThirteenFReportFailure(state, err) {
  return {
    ...state,
    loadThirteenFReportActionStatus: ACTION_STATUS.ERROR,
    loadThirteenFReportErrMsg: err.respMessage,
  };
}

function initLoadThirteenFReportActionStatus(state) {
  return {
    ...state,
    loadThirteenFReportActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Sync 13F Report
  [START_SYNC_THIRTEENF_REPORT]: startSyncThirteenFReport,
  [SYNC_THIRTEENF_REPORT_SUCCESS]: syncThirteenFReportSuccess,
  [SYNC_THIRTEENF_REPORT_FAILURE]: syncThirteenFReportFailure,
  [INIT_SYNC_THIRTEENF_REPORT_ACTION_STATUS]: initSyncThirteenFReportActionStatus,

  // Load 13F Report
  [START_LOAD_THIRTEENF_REPORT]: startLoadThirteenFReport,
  [LOAD_THIRTEENF_REPORT_SUCCESS]: loadThirteenFReportSuccess,
  [LOAD_THIRTEENF_REPORT_FAILURE]: loadThirteenFReportFailure,
  [INIT_LOAD_THIRTEENF_REPORT_ACTION_STATUS]: initLoadThirteenFReportActionStatus,
});