import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

class MeetingAndTrainingApplyRecordGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
            {
                field: 'id',
                headerName: "ID",
                cellClass: 'non-number',
                width: 80,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
            {
                field: 'username',
                headerName: "Name*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'market',
                headerName: "market*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'sector',
                headerName: "sector*",
                cellClass: 'non-number',
                width: 100,
            },
            {
                field: 'applytype',
                headerName: "Apply Type",
                cellClass: 'non-number',
                width: 130,
            },
            {
                field: 'remark',
                headerName: "Note",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'state',
                headerName: "State",
                cellClass: 'number',
                width: 100,
                cellStyle: this.dataColorCellStyle,
            },
            {
                field: 'applydate',
                headerName: "ApplyDate",
                cellClass: 'number',
                width: 100,
            },
            {
                field: 'filePath',
                headerName: "Stock lists",
                cellClass: 'number',
                width: 200,
                cellRenderer: this.downloadMeetingAndTrainingCellRenderer
            },

            {
                field: 'currency',
                headerName: "Currency",
                cellClass: 'number',
                width: 100,
            },
            {
                field: 'paperSize',
                headerName: "Paper Size",
                cellClass: 'number',
                width: 150,
            }, 


            {
                field: 'coachname',
                headerName: "CoachName",
                cellClass: 'number',
                width: 100,
                hide: true,
            },
            {
                field: 'coachopinion',
                headerName: "Chase Opinion",
                cellClass: 'number',
                width: 150,
            }, 
            {
                field: 'ecoachname',
                headerName: "CoachName",
                cellClass: 'number',
                width: 100,
                hide: true,
            },
            {
                field: 'ecoachopinion',
                headerName: "Ethan Opinion",
                cellClass: 'number',
                width: 150,
            },
            {
                field: 'pmname',
                headerName: "PmName",
                cellClass: 'number',
                width: 100,
                hide: true,
            },
            {
                field: 'pmopinion',
                headerName: "PmOpinion",
                cellClass: 'number',
                width: 100,
                hide: true,
            },
            {
                field: 'finalname',
                headerName: "FinalName",
                cellClass: 'number',
                width: 100,
                hide: true,
            },
            {
                field: 'finalopinion',
                headerName: "finalOpinion",
                cellClass: 'number',
                width: 150,
            },
            
            {
                field: 'lastmodifiedby',
                headerName: "LastModifiedBy",
                cellClass: 'number',
                width: 100,
            },
            {
                field: 'lastmodifieddate',
                headerName: "LastModifiedDate",
                cellClass: 'number',
                width: 100,
            },
            // {
            //     field: 'filepath',
            //     headerName: "User Access Form",
            //     cellClass: 'non-number',
            //     width: 300,
            //     cellRenderer: this.sendHistoryAttachementCellRender
            // },
        ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            rowClassRules: {
                'MarkBackgroundRed': function(params) {
                    // if(params.data.status === 'New') {
                        return params.data.state === 'Reject';
                    // } alertFlag  limitFlag  1 表示出警

                },
                
                
                
                
            },

        };
    }
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingApplyRecordDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let meetingAndTrainingApplyRecordInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectMeetingAndTrainingApplyRecordInfo(meetingAndTrainingApplyRecordInfoArray);
    }
    dataColorCellStyle(param) {
        const status = param.value;
        if(status === 'SUBMIT'|| status===null) {
            // return {color: 'green'};
            return { background: '#fcf800 '};
        }
        if(status === 'Approved') {
            return {background: 'green'};
            // return { background: '#fcf800 '};
        }
        
        
    }
    onRowClicked = (event) => {
        const selectedRcd = event.data;
        this.props.doOperationRecordAction({action:'SHOW_DETAIL', detail: selectedRcd});
    }
    
    downloadMeetingAndTrainingCellRenderer = (node) => {
        const attachement = node.data.filePath;
        if(!attachement) {
            return '';
        }

        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingApplyRecordDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    render() {

        const {
            // query
            loadMeetingAndTrainingApplyRecordInfoActionStatus,
            loadMeetingAndTrainingApplyRecordInfoResp,
        } = this.props;


        const isLoading = loadMeetingAndTrainingApplyRecordInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadMeetingAndTrainingApplyRecordInfoArray = [];
        if(!!loadMeetingAndTrainingApplyRecordInfoResp && !!loadMeetingAndTrainingApplyRecordInfoResp.data) {
            loadMeetingAndTrainingApplyRecordInfoArray = loadMeetingAndTrainingApplyRecordInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadMeetingAndTrainingApplyRecordInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // event
                            sideBar={true}
                            resizable={true}
                            onSelectionChanged={this.onDataSelectionChanged}
                            // onRowClicked={this.onRowClicked}


                        />
                    )}
            </div>
        );
    }
}

export default MeetingAndTrainingApplyRecordGrid;
