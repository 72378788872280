import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Generate Short Position Report
  START_GENERATE_SHORT_POSITION_REPORT,
  GENERATE_SHORT_POSITION_REPORT_SUCCESS,
  GENERATE_SHORT_POSITION_REPORT_FAILURE,
  INIT_GENERATE_SHORT_POSITION_REPORT_ACTION_STATUS,

  // Load Short Position Report
  START_LOAD_SHORT_POSITION_REPORT,
  LOAD_SHORT_POSITION_REPORT_SUCCESS,
  LOAD_SHORT_POSITION_REPORT_FAILURE,
  INIT_LOAD_SHORT_POSITION_REPORT_ACTION_STATUS,

  // Open/Reset Short Position Report Check Result Modal Dialog
  SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN,
  SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET,

  // Load Short Position Report Check Result
  START_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT,
  LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_SUCCESS,
  LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_FAILURE,
  INIT_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_ACTION_STATUS,

  // Report Short Position Report
  START_REPORT_SHORT_POSITION_REPORT,
  REPORT_SHORT_POSITION_REPORT_SUCCESS,
  REPORT_SHORT_POSITION_REPORT_FAILURE,
  INIT_REPORT_SHORT_POSITION_REPORT_ACTION_STATUS,

} from './shortPositionReportConstants';

// Generate Short Position Report
function startGenerateShortPositionReport() {
  return {
    type: START_GENERATE_SHORT_POSITION_REPORT
  };
}

export function generateShortPositionReport(fundCode, dateString) {
  const requestParams = {
    fundCode,
    dateString,
  };
  
  return function(dispatch) {
    dispatch(startGenerateShortPositionReport());
    httpClient
      .post(UrlConfig.GENERATE_SHORT_POSITION_REPORT, requestParams)
      .then(resp => dispatch(generateShortPositionReportSuccess(resp)))
      .catch(err => dispatch(generateShortPositionReportFailure(err)));
  };
}

function generateShortPositionReportSuccess(resp) {
  return {
    type: GENERATE_SHORT_POSITION_REPORT_SUCCESS,
    payload: resp
  };
}

function generateShortPositionReportFailure(err) {
  return {
    type: GENERATE_SHORT_POSITION_REPORT_FAILURE,
    payload: err
  };
}

export function initGenerateShortPositionReportActionStatus() {
  return {
    type: INIT_GENERATE_SHORT_POSITION_REPORT_ACTION_STATUS
  };
}

// Load Short Position Report
function startLoadShortPositionReport() {
  return {
    type: START_LOAD_SHORT_POSITION_REPORT
  };
}

export function loadShortPositionReport(fundCode, dateString) {
  const requestParams = {
    'fund-code': fundCode,
    'date-string': dateString,
  };

  return function(dispatch) {
    dispatch(startLoadShortPositionReport());
    httpClient
      .get(UrlConfig.LOAD_SHORT_POSITION_REPORT, requestParams)
      .then(resp => dispatch(loadShortPositionReportSuccess(resp)))
      .catch(err => dispatch(loadShortPositionReportFailure(err)));
  };
}

function loadShortPositionReportSuccess(resp) {
  return {
    type: LOAD_SHORT_POSITION_REPORT_SUCCESS,
    payload: resp
  };
}

function loadShortPositionReportFailure(err) {
  return {
    type: LOAD_SHORT_POSITION_REPORT_FAILURE,
    payload: err
  };
}

export function initLoadShortPositionReportActionStatus() {
  return {
    type: INIT_LOAD_SHORT_POSITION_REPORT_ACTION_STATUS
  };
}

// Open/Reset Short Position Report Check Result Modal Dialog
export function openShortPositionReportCheckResultModalDialog(fundCode, dateString) {
  return {
    type: SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN,
    payload: {
      fundCode, 
      dateString,
    },
  };
}

export function resetShortPositionReportCheckResultModalDialog() {
  return {
    type: SHORT_POSITION_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET
  };
}

// Load Short Position Report Check Result
function startLoadShortPositionReportCheckResult() {
  return {
    type: START_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT
  };
}

export function loadShortPositionReportCheckResult(fundCode, dateString) {
  const requestParams = {
    'date-string': dateString,
    'fund-code': fundCode,
  };

  return function(dispatch) {
    dispatch(startLoadShortPositionReportCheckResult());
    httpClient
      .get(UrlConfig.SHORT_POSITION_REPORT_CHECK_RESULT, requestParams)
      .then(resp => dispatch(loadShortPositionReportCheckResultSuccess(resp)))
      .catch(err => dispatch(loadShortPositionReportCheckResultFailure(err)));
  };
}

function loadShortPositionReportCheckResultSuccess(resp) {
  return {
    type: LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_SUCCESS,
    payload: resp
  };
}

function loadShortPositionReportCheckResultFailure(err) {
  return {
    type: LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_FAILURE,
    payload: err
  };
}

export function initLoadShortPositionReportCheckResultActionStatus() {
  return {
    type: INIT_LOAD_SHORT_POSITION_REPORT_CHECK_RESULT_ACTION_STATUS
  };
}

// Report Short Position Report
function startReportShortPositionReport() {
  return {
    type: START_REPORT_SHORT_POSITION_REPORT
  };
}

export function reportShortPositionReport(fundCode, dateString) {
  const requestParams = {
    fundCode,
    dateString,
  };
  
  return function(dispatch) {
    dispatch(startReportShortPositionReport());
    httpClient
      .post(UrlConfig.REPORT_SHORT_POSITION_REPORT, requestParams)
      .then(resp => dispatch(reportShortPositionReportSuccess(resp)))
      .catch(err => dispatch(reportShortPositionReportFailure(err)));
  };
}

function reportShortPositionReportSuccess(resp) {
  return {
    type: REPORT_SHORT_POSITION_REPORT_SUCCESS,
    payload: resp
  };
}

function reportShortPositionReportFailure(err) {
  return {
    type: REPORT_SHORT_POSITION_REPORT_FAILURE,
    payload: err
  };
}

export function initReportShortPositionReportActionStatus() {
  return {
    type: INIT_REPORT_SHORT_POSITION_REPORT_ACTION_STATUS
  };
}