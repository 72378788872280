import React, {Component} from "react";
import { Form, Row, Col,Input} from 'antd';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const TextArea = Input.TextArea;
const FormItem = Form.Item;
class MonthlyReviewDetailPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ratingDetail: props.data,
    };
    
  }

  render () {
    const{
      ratingDetail
    } = this.state;
    const data = ratingDetail;
    const createMarkup = (text) => {
      // 替换 \n 为 <br />
      const formattedText = text.replace('/n', '<br />');
      return formattedText ;
    };
    return (
      <div style={{
                    backgroundColor:"white",
                    width:"100%",
                    height:"300px",
                    border:"1px #000000 solid",
                    overflow: "auto"}}>
              {/* <Row gutter={24}>
                  <Col span={24}>
                      <div style={{textAlign:"center",fontSize: '20px',marginTop:"10px"}}>
                        <b>{data.month} Monthly Report</b>
                      </div>
                  </Col>
              </Row> */}
              <Row gutter={24} style={{marginLeft: '0px',marginRight: '0px'}}>
                  <Col span={20}>
                  {
                    !_.isEmpty(data.fileList) &&<div style={{ marginLeft: "30px",marginTop: "20px"}}>
                    <b>Attachment: </b>
                    { data.fileList.map(item => {
                      return (<div style={{marginLeft: '10px',marginTop: '5px',height:'25px'}}><a href={item.url} target="_blank">{item.name}</a><br/></div>)
                    })
                    }
                    </div>
                  }
                  </Col>
              </Row>
              <Row gutter={24} style={{marginLeft: '0px',marginRight: '0px'}}>
                  <Col span={24}>
                    <div 
                      style={{whiteSpace: "pre-line",
                              marginLeft: "20px",
                              marginTop: "10px",
                              padding: "10px",
                              height:"100%"}}>
                      {ReactHtmlParser(data.remark)}
                      </div>
                  </Col>
              </Row>
      </div>
    );
  }
}

export default MonthlyReviewDetailPanel;