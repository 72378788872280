// query
export const ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE = 'ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE';
export const START_LOAD_REGISTER_INFO = 'START_LOAD_REGISTER_INFO';
export const LOAD_REGISTER_INFO_SUCCESS = 'LOAD_REGISTER_INFO_SUCCESS';
export const LOAD_REGISTER_INFO_FAILURE = 'LOAD_REGISTER_INFO_FAILURE';
export const INIT_LOAD_REGISTER_INFO_ACTION_STATUS = 'INIT_LOAD_REGISTER_INFO_ACTION_STATUS';
// select
export const SELECT_REGISTER = 'SELECT_REGISTER';
// add or edit
export const OPEN_ADD_OR_EDIT_REGISTER_MODAL = 'OPEN_ADD_OR_EDIT_REGISTER_MODAL';
export const ADD_OR_EDIT_REGISTER_CHANGE_INPUT = 'ADD_OR_EDIT_REGISTER_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_REGISTER_MODAL = 'RESET_SUBMIT_SAVE_REGISTER_MODAL';
export const START_SUBMIT_SAVE_REGISTER = 'START_SUBMIT_SAVE_REGISTER';
export const SUBMIT_SAVE_REGISTER_SUCCESS = 'SUBMIT_SAVE_REGISTER_SUCCESS';
export const SUBMIT_SAVE_REGISTER_FAILURE = 'SUBMIT_SAVE_REGISTER_FAILURE';
export const INIT_SUBMIT_SAVE_REGISTER_ACTION_STATUS = 'INIT_SUBMIT_SAVE_REGISTER_ACTION_STATUS';


//Sub add or edit
export const OPEN_ADD_OR_EDIT_REGISTERSUB_MODAL = 'OPEN_ADD_OR_EDIT_REGISTERSUB_MODAL';
export const ADD_OR_EDIT_REGISTERSUB_CHANGE_INPUT = 'ADD_OR_EDIT_REGISTERSUB_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_REGISTERSUB_MODAL = 'RESET_SUBMIT_SAVE_REGISTERSUB_MODAL';
export const START_SUBMIT_SAVE_REGISTERSUB = 'START_SUBMIT_SAVE_REGISTERSUB';
export const SUBMIT_SAVE_REGISTERSUB_SUCCESS = 'SUBMIT_SAVE_REGISTERSUB_SUCCESS';
export const SUBMIT_SAVE_REGISTERSUB_FAILURE = 'SUBMIT_SAVE_REGISTERSUB_FAILURE';
export const INIT_SUBMIT_SAVE_REGISTERSUB_ACTION_STATUS = 'INIT_SUBMIT_SAVE_REGISTERSUB_ACTION_STATUS';


// batch change status
export const OPEN_BATCH_CHANGE_STATUS_MODAL = 'OPEN_BATCH_CHANGE_STATUS_MODAL';
export const RESET_BATCH_CHANGE_STATUS_MODAL = 'RESET_BATCH_CHANGE_STATUS_MODAL';
export const BATCH_CHANGE_STATUS_CHANGE_INPUT = 'BATCH_CHANGE_STATUS_CHANGE_INPUT';
export const START_BATCH_CHANGE_STATUS_FOR_REGISTER = 'START_BATCH_CHANGE_STATUS_FOR_REGISTER';
export const BATCH_CHANGE_STATUS_FOR_REGISTER_SUCCESS = 'BATCH_CHANGE_STATUS_FOR_REGISTER_SUCCESS';
export const BATCH_CHANGE_STATUS_FOR_REGISTER_FAILURE = 'BATCH_CHANGE_STATUS_FOR_REGISTER_FAILURE';
export const INIT_BATCH_CHANGE_STATUS_FOR_REGISTER_ACTION_STATUS = 'INIT_BATCH_CHANGE_STATUS_FOR_REGISTER_ACTION_STATUS';
// finish
export const SELECT_REGISTER_TASK = 'SELECT_REGISTER_TASK';
export const OPEN_FINISH_TASK_MODAL = 'OPEN_FINISH_TASK_MODAL';
export const RESET_FINISH_TASK_MODAL = 'RESET_FINISH_TASK_MODAL';
export const FINISH_TASK_CHANGE_INPUT = 'FINISH_TASK_CHANGE_INPUT';
export const START_FINISH_REGISTER_TASK = 'START_FINISH_REGISTER_TASK';
export const FINISH_REGISTER_TASK_SUCCESS = 'FINISH_REGISTER_TASK_SUCCESS';
export const FINISH_REGISTER_TASK_FAILURE = 'FINISH_REGISTER_TASK_FAILURE';
export const INIT_FINISH_REGISTER_TASK_ACTION_STATUS = 'INIT_FINISH_REGISTER_TASK_ACTION_STATUS';

// Open/Reset Questionnaire Email Edit Modal Dialog
export const QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN = 'QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN';
export const QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET = 'QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET';

// Accept Questionnaire Result
export const START_ACCEPT_QUESTIONNAIRE_RESULT = 'START_ACCEPT_QUESTIONNAIRE_RESULT';
export const ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS = 'ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS';
export const ACCEPT_QUESTIONNAIRE_RESULT_FAILURE = 'ACCEPT_QUESTIONNAIRE_RESULT_FAILURE';
export const INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS = 'INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS';

// Reject Questionnaire Result
export const START_REJECT_QUESTIONNAIRE_RESULT = 'START_REJECT_QUESTIONNAIRE_RESULT';
export const REJECT_QUESTIONNAIRE_RESULT_SUCCESS = 'REJECT_QUESTIONNAIRE_RESULT_SUCCESS';
export const REJECT_QUESTIONNAIRE_RESULT_FAILURE = 'REJECT_QUESTIONNAIRE_RESULT_FAILURE';
export const INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS = 'INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS';

// Send Remind Email
export const START_SEND_REMIND_EMAIL = 'START_SEND_REMIND_EMAIL';
export const SEND_REMIND_EMAIL_SUCCESS = 'SEND_REMIND_EMAIL_SUCCESS';
export const SEND_REMIND_EMAIL_FAILURE = 'SEND_REMIND_EMAIL_FAILURE';
export const INIT_SEND_REMIND_EMAIL_ACTION_STATUS = 'INIT_SEND_REMIND_EMAIL_ACTION_STATUS';