import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class DelHoldingDialog extends Component {
    componentDidMount() {

    }

    componentDidUpdate() {
        const {
            delPersonalHoldingActionStatus,
        } = this.props;
        if(delPersonalHoldingActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Personal Holding succeeded');
            this.props.initDelPersonalHoldingActionStatus();
            this.props.resetDelHoldingDialog();
            const {
                id,
                employee,
            } = this.props.personalAccountQueryFields;
            const param = {
                id,
                employee,
                approvalStatus: '',
                needHoldingData: 'true',
                source: 'Admin',
            }
            this.props.loadPersonalAccount(param);
        }
        if(delPersonalHoldingActionStatus === ACTION_STATUS.ERROR) {
            this.props.initDelPersonalHoldingActionStatus();
        }
    }

    submitDelHolding = () => {
        const {
            fields,
        } = this.props.delPersonalHoldingModal;
        const holdingNum = fields.personalHoldingIdList.length;
        Modal.confirm({
            title: 'Are you sure to Delete the choosed ' + holdingNum + ' Personal Holdings?',
            onOk: () => {
                this.props.delPersonalHolding(fields);
            }
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            delPersonalHoldingModal,
            delPersonalHoldingActionStatus,
        } = this.props;
        const {
            isOpened,
            respErrMsg,
            fields,
        } = delPersonalHoldingModal;
        const {
            comments,
        } = fields;

        const isSubmitLoading = delPersonalHoldingActionStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={'Delete Personal Holding'}
                    centered
                    width={800}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelHoldingDialog()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetDelHoldingDialog() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitDelHolding() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='comments' 
                                            onChange={(e) => {
                                                this.props.onDelHoldingInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                    {
                    !!respErrMsg &&  
                    <Message negative>
                        <Message.Header>Response Error Message</Message.Header>
                        <p>{ respErrMsg }</p>
                    </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const DelHoldingDialogForm = Form.create({ name: 'DelHoldingDialog' })(DelHoldingDialog);
export default DelHoldingDialogForm;
