import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_REGISTER_INFO,
    LOAD_REGISTER_INFO_SUCCESS,
    LOAD_REGISTER_INFO_FAILURE,
    INIT_LOAD_REGISTER_INFO_ACTION_STATUS,
    // Select
    SELECT_REGISTER,
    // add or edit
    OPEN_ADD_OR_EDIT_REGISTER_MODAL,
    ADD_OR_EDIT_REGISTER_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_REGISTER_MODAL,
    START_SUBMIT_SAVE_REGISTER,
    SUBMIT_SAVE_REGISTER_SUCCESS,
    SUBMIT_SAVE_REGISTER_FAILURE,
    INIT_SUBMIT_SAVE_REGISTER_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_REGISTERSUB_MODAL,
    ADD_OR_EDIT_REGISTERSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_REGISTERSUB_MODAL,
    START_SUBMIT_SAVE_REGISTERSUB,
    SUBMIT_SAVE_REGISTERSUB_SUCCESS,
    SUBMIT_SAVE_REGISTERSUB_FAILURE,
    INIT_SUBMIT_SAVE_REGISTERSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_REGISTER,
    BATCH_CHANGE_STATUS_FOR_REGISTER_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_REGISTER_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_REGISTER_ACTION_STATUS,
    // finish
    SELECT_REGISTER_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_REGISTER_TASK,
    FINISH_REGISTER_TASK_SUCCESS,
    FINISH_REGISTER_TASK_FAILURE,
    INIT_FINISH_REGISTER_TASK_ACTION_STATUS,

      // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,


  // Accept Questionnaire Result
  START_ACCEPT_QUESTIONNAIRE_RESULT,
  ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
  ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

  // Reject Questionnaire Result
  START_REJECT_QUESTIONNAIRE_RESULT,
  REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
  REJECT_QUESTIONNAIRE_RESULT_FAILURE,
  INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

    // Send Remind Email
    START_SEND_REMIND_EMAIL,
    SEND_REMIND_EMAIL_SUCCESS,
    SEND_REMIND_EMAIL_FAILURE,
    INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

} from './RegisterConstants';
import { QUESTIONNAIRE_EMAIL_SEND_MODE } from '../../../../utils/QuestionnaireEmailSendMode';
import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  } from '../../../features/home/homeConstants';

  import {
    LOAD_LICENSINGFORM_SUCCESS,
} from '../licensingForm/licensingFormConstants';
const Option = Select.Option;

const departmentOptions = [];
departmentOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
departmentOptions.push(<Option key={'Trading'}>{'Trading'}</Option>);
departmentOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
departmentOptions.push(<Option key={'Operations'}>{'Operations'}</Option>);
departmentOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
departmentOptions.push(<Option key={'BD'}>{'BD'}</Option>);
departmentOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
departmentOptions.push(<Option key={'IT'}>{'IT'}</Option>);
departmentOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
departmentOptions.push(<Option key={'Admin'}>{'Admin'}</Option>);
departmentOptions.push(<Option key={'C-level'}>{'C-level'}</Option>);
// categoryOptions.push(<Option key={'C-level'}>{'C-level'}</Option>);
const regulatorOptions = [];
regulatorOptions.push(<Option key={'AMAC'}>{'AMAC'}</Option>);
regulatorOptions.push(<Option key={'MAS'}>{'MAS'}</Option>);
regulatorOptions.push(<Option key={'SFC'}>{'SFC'}</Option>);
regulatorOptions.push(<Option key={'SEBI'}>{'SEBI'}</Option>);
regulatorOptions.push(<Option key={'FSA'}>{'FSA'}</Option>);
const licenseOptions = []; 
licenseOptions.push(<Option key={'Investment Adviser'}>{'Investment Adviser'}</Option>);
licenseOptions.push(<Option key={'ITP'}>{'ITP'}</Option>);
licenseOptions.push(<Option key={'Type 9-LR'}>{'Type 9-LR'}</Option>);
licenseOptions.push(<Option key={'Type 9-RO'}>{'Type 9-RO'}</Option>);
licenseOptions.push(<Option key={'证券类投资基金从业资格'}>{'证券类投资基金从业资格'}</Option>);
licenseOptions.push(<Option key={'LFMC-LR'}>{'LFMC-LR'}</Option>);
licenseOptions.push(<Option key={'MIC'}>{'MIC'}</Option>);
licenseOptions.push(<Option key={'NIL'}>{'NIL'}</Option>);



const applicationStatusOptions = [];
applicationStatusOptions.push(<Option key={'Accepted'}>{'Accepted'}</Option>);
applicationStatusOptions.push(<Option key={'Outstanding'}>{'Outstanding'}</Option>);
applicationStatusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);

const licensingStatusOptions = [];
licensingStatusOptions.push(<Option key={'Registered'}>{'Registered'}</Option>);
licensingStatusOptions.push(<Option key={'Unregistered'}>{'Unregistered'}</Option>);
licensingStatusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);
licensingStatusOptions.push(<Option key={'Deregistered'}>{'Deregistered'}</Option>);

const examinationStatusOptions = [];
examinationStatusOptions.push(<Option key={'Passed'}>{'Passed'}</Option>);
examinationStatusOptions.push(<Option key={'To be completed'}>{'To be completed'}</Option>);
examinationStatusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);



const officeOptions = [];
officeOptions.push(<Option key={'SH'}>{'SH'}</Option>);
officeOptions.push(<Option key={'HK'}>{'HK'}</Option>);
officeOptions.push(<Option key={'SG'}>{'SG'}</Option>);
officeOptions.push(<Option key={'IN'}>{'IN'}</Option>);
officeOptions.push(<Option key={'JP'}>{'JP'}</Option>);
officeOptions.push(<Option key={'HN'}>{'HN'}</Option>);
// fundCoOptions.push(<Option key={'PIAS (India)'}>{'PIAS (India)'}</Option>);
// fundCoOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
// fundCoOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
// fundCoOptions.push(<Option key={'COP'}>{'COP'}</Option>);
// fundCoOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
// fundCoOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
// fundCoOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
// fundCoOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
// fundCoOptions.push(<Option key={'SHLH'}>{'SHLH'}</Option>);
// fundCoOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
// fundCoOptions.push(<Option key={'COP'}>{'COP'}</Option>);
// fundCoOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
// fundCoOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const statusOptions = [];
statusOptions.push(<Option key={'Accepted'}>{'Accepted'}</Option>);
statusOptions.push(<Option key={'Outstanding'}>{'Outstanding'}</Option>);
statusOptions.push(<Option key={'Terminated'}>{'Terminated'}</Option>);
statusOptions.push(<Option key={'N/A'}>{'N/A'}</Option>);


const importanceOptions = [];
importanceOptions.push(<Option key={'Low'}>{'Low'}</Option>);
importanceOptions.push(<Option key={'Normal'}>{'Normal'}</Option>);
importanceOptions.push(<Option key={'High'}>{'High'}</Option>);




const locationOptions = [];
locationOptions.push(<Option key={'All'}>{'All'}</Option>);
locationOptions.push(<Option key={'Cayman'}>{'Cayman'}</Option>);
locationOptions.push(<Option key={'Hong Kong'}>{'Hong Kong'}</Option>);
locationOptions.push(<Option key={'India'}>{'India'}</Option>);
locationOptions.push(<Option key={'Japan'}>{'Japan'}</Option>);
locationOptions.push(<Option key={'Shanghai'}>{'Shanghai'}</Option>);
locationOptions.push(<Option key={'Singapore'}>{'Singapore'}</Option>);
locationOptions.push(<Option key={'USA'}>{'USA'}</Option>);

const fundCompanyOptions = [];
fundCompanyOptions.push(<Option key={'All'}>{'All'}</Option>);
fundCompanyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundCompanyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundCompanyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundCompanyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundCompanyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
fundCompanyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
fundCompanyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
fundCompanyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundCompanyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundCompanyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundCompanyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundCompanyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundCompanyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundCompanyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);

const frequencyOptions = [];
frequencyOptions.push(<Option key={'EveryWorkday'}>{'EveryWorkday'}</Option>);
frequencyOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);
frequencyOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);
frequencyOptions.push(<Option key={'Quarterly'}>{'Quarterly'}</Option>);
frequencyOptions.push(<Option key={'SemiAnnually'}>{'SemiAnnually'}</Option>);
frequencyOptions.push(<Option key={'Annually'}>{'Annually'}</Option>);
frequencyOptions.push(<Option key={'Every3Years'}>{'Every3Years'}</Option>);
frequencyOptions.push(<Option key={'Incidental'}>{'Incidental'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'Sun'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'Mon'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'Tue'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'Wed'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'Thu'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'Fri'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'Sat'}>{'Sat'}</Option>);

const monthOptions = [];
monthOptions.push(<Option key={'01'}>{'Jan'}</Option>);
monthOptions.push(<Option key={'02'}>{'Feb'}</Option>);
monthOptions.push(<Option key={'03'}>{'Mar'}</Option>);
monthOptions.push(<Option key={'04'}>{'Apr'}</Option>);
monthOptions.push(<Option key={'05'}>{'May'}</Option>);
monthOptions.push(<Option key={'06'}>{'Jun'}</Option>);
monthOptions.push(<Option key={'07'}>{'Jul'}</Option>);
monthOptions.push(<Option key={'08'}>{'Aug'}</Option>);
monthOptions.push(<Option key={'09'}>{'Sep'}</Option>);
monthOptions.push(<Option key={'10'}>{'Oct'}</Option>);
monthOptions.push(<Option key={'11'}>{'Nov'}</Option>);
monthOptions.push(<Option key={'12'}>{'Dec'}</Option>);

const responsibleDeptOptions = [];
responsibleDeptOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
responsibleDeptOptions.push(<Option key={'BD'}>{'BD'}</Option>);
responsibleDeptOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
responsibleDeptOptions.push(<Option key={'CS'}>{'CS'}</Option>);
responsibleDeptOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
responsibleDeptOptions.push(<Option key={'IT'}>{'IT'}</Option>);
responsibleDeptOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
responsibleDeptOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
responsibleDeptOptions.push(<Option key={'Settlement'}>{'Settlement'}</Option>);


const initialState = {
    locationOptions: locationOptions,
    fundCompanyOptions: fundCompanyOptions,
    frequencyOptions: frequencyOptions,
    weekdayOptions: weekdayOptions,
    monthOptions: monthOptions,
    responsibleDeptOptions: responsibleDeptOptions,
    // categoryOptions: categoryOptions,
    departmentOptions: departmentOptions,
    officeOptions: officeOptions,
    // fundCoOptions: fundCoOptions,
    statusOptions: statusOptions,
    importanceOptions: importanceOptions,
    regulatorOptions: regulatorOptions,
    licenseOptions: licenseOptions,
    applicationStatusOptions: applicationStatusOptions,
    licensingStatusOptions: licensingStatusOptions,
    examinationStatusOptions: examinationStatusOptions,
    registerQueryFields: {
        id: undefined,
        name: undefined,
        status: undefined,
    
    },

    selectedRegisterArray: [],
    selectedRegisterTaskArray: [],

    addOrEditRegisterModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            name: undefined,
            regulator: undefined,
            license: undefined,
            licenseNo: undefined,
            applicationStatus: undefined,
            licensingStatus: undefined,
            licensedDate: undefined,
            validTo: undefined,
            examinationStatus: undefined,
            examinationDeadline: undefined,
            deregistrationDate: undefined,
            sendmail: undefined,
            remarks: undefined,
            comments: undefined,

        },
    },
    addOrEditRegisterSubModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            workid: undefined,
            details: undefined,
            startdate: undefined,
            targetdate: undefined,
            enddate: undefined,
            personlncharge: undefined,
            status: undefined,
            sendmail: undefined,
            // status: undefined,
            // importance: undefined,
            // remarks: undefined,
            // comments: undefined,

        },
    },
    batchChangeStatusModal: {
        isOpened: false,
        mode: 'PAUSE',
        respErrMsg: '',
        fields: {
            idList: [],
            comments: undefined,
        },
    },

    finishTaskModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: undefined,
            remarks: undefined,
            comments: undefined,
        },
    },

    userAccountEmailOptions: [],
    // Open/Reset Questionnaire Email Edit Modal Dialog
    questionnaireEmailEditControlModal: {
        isOpened: false,
        sendMode: QUESTIONNAIRE_EMAIL_SEND_MODE.NONE,
        emailPayload: null,
        respErrMsg: '',
    },
    // Accept Questionnaire Result
    acceptQuestionnaireResultActionStatus: ACTION_STATUS.READY,

    // Reject Questionnaire Result
    rejectQuestionnaireResultActionStatus: ACTION_STATUS.READY,
    licensingFormOptions: [],

    };



// query
function onReportSummaryQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.registerQueryFields,
        [name]: value,
    };
    return {
        ...state,
        registerQueryFields: upatedFields,
    }
}
function startLoadRegisterInfo(state) {
    return {
        ...state,
        loadRegisterInfoActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadRegisterInfoSuccess(state, resp) {
    return {
        ...state,
        loadRegisterInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadRegisterInfoResp: resp,
    };
}
function loadRegisterInfoFailure(state, err) {
    return {
        ...state,
        loadRegisterInfoActionStatus: ACTION_STATUS.ERROR,
        loadRegisterInfoResp: err,
    };
}
function initLoadRegisterInfoActionStatus(state) {
    return {
        ...state,
        loadRegisterInfoActionStatus: ACTION_STATUS.READY,
        selectedRegisterArray: [],
        selectedRegisterTaskArray: [],
    };
}


// select
function selectRegister(state, array) {
    return {
        ...state,
        selectedRegisterArray: array,
    };
}


// add or edit
function openAddOrEditRegisterModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditRegisterModal: {
                ...state.addOrEditRegisterModal,
                isOpened: true,
                mode,
            },
        };

    } else {

        const {
            id,
            name,
            regulator,
            license,
            licenseNo,
            applicationStatus,
            licensingStatus,
            licensedDate,
            validTo,
            examinationStatus,
            examinationDeadline,
            deregistrationDate,
            remarks,
            comments,
            sendmail,
        } = data;



        return {
            ...state,
            addOrEditRegisterModal: {
                ...state.addOrEditRegisterModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditRegisterModal.fields,
                
                    id: data.id,
                    formGuid:  data.formGuid,
                    name:  data.name,
                    regulator:  data.regulator,
                    license:  data.license,
                    licenseNo:  data.licenseNo,
                    applicationStatus:  data.applicationStatus,
                    licensingStatus:  data.licensingStatus,
                    licensedDate:  data.licensedDate,
                    validTo:  data.validTo,
                    examinationStatus:  data.examinationStatus,
                    examinationDeadline:  data.examinationDeadline,
                    deregistrationDate:  data.deregistrationDate,
                    remarks: data.remarks,
                    comments: data.comments,
                    sendmail: data.sendmail,
                
                }
            },
        }

    }
}
function addOrEditRegisterChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditRegisterModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditRegisterModal: {
          ...state.addOrEditRegisterModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSaveRegisterModal(state) {
    return {
        ...state,
        addOrEditRegisterModal: initialState.addOrEditRegisterModal,
    }
}

function startSubmitSaveRegister(state) {
    return {
        ...state,
        addOrEditRegisterStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveRegisterSuccess(state, resp) {
    return {
        ...state,
        addOrEditRegisterStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSaveRegisterFailure(state, err) {
    return {
        ...state,
        addOrEditRegisterStatus: ACTION_STATUS.ERROR,
        addOrEditRegisterModal: {
            ...state.addOrEditRegisterModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSaveRegisterActionStatus(state) {
    return {
        ...state,
        addOrEditRegisterStatus: ACTION_STATUS.READY,
    };
}



//sub、 add or edit
function openAddOrEditRegisterSubModal(state, {mode, data,idList}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditRegisterSubModal: {
                ...state.addOrEditRegisterSubModal,
                isOpened: true,
                mode,
                idList,
            },
        };

    } else {

        const {
            id,
            workid,
            details,
            startdate,
            targetdate,
            enddate,
            personlncharge,
            status,
        } = data;



        return {
            ...state,
            addOrEditRegisterSubModal: {
                ...state.addOrEditRegisterSubModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditRegisterSubModal.fields,
                    id,
                    workid,
                    details,
                    startdate,
                    targetdate,
                    enddate,
                    personlncharge,
                    status,
                
                }
            },
        }

    }
}
function addOrEditRegisterSubChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditRegisterSubModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditRegisterSubModal: {
          ...state.addOrEditRegisterSubModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSaveRegisterSubModal(state) {
    return {
        ...state,
        addOrEditRegisterSubModal: initialState.addOrEditRegisterSubModal,
    }
}

function startSubmitSaveRegisterSub(state) {
    return {
        ...state,
        addOrEditRegisterSubStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveRegisterSubSuccess(state, resp) {
    return {
        ...state,
        addOrEditRegisterSubStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSaveRegisterSubFailure(state, err) {
    return {
        ...state,
        addOrEditRegisterSubStatus: ACTION_STATUS.ERROR,
        addOrEditRegisterSubModal: {
            ...state.addOrEditRegisterSubModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSaveRegisterSubActionStatus(state) {
    return {
        ...state,
        addOrEditRegisterSubStatus: ACTION_STATUS.READY,
    };
}





















// batch change status
function openBatchChangeStatusModal(state, {mode, idList}) {
    return {
        ...state,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.batchChangeStatusModal.fields,
                idList,
            },
        },
    };
}
function resetBatchChangeStatusModal(state) {
    return {
        ...state,
        batchChangeStatusModal: initialState.batchChangeStatusModal,
    }
}
function batchChangeStatusChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.batchChangeStatusModal.fields,
        [name]: value,
    };
    return {
        ...state,
        batchChangeStatusModal: {
          ...state.batchChangeStatusModal,
          fields: updatedFields,
        },
    };
}

function startBatchChangeStatusForRegister(state) {
    return {
        ...state,
        batchChangeStatusForRegisterStatus: ACTION_STATUS.LOGINING,
    };
}
function batchChangeStatusForRegisterSuccess(state, resp) {
    return {
        ...state,
        batchChangeStatusForRegisterStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function batchChangeStatusForRegisterFailure(state, err) {
    return {
        ...state,
        batchChangeStatusForRegisterStatus: ACTION_STATUS.ERROR,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initBatchChangeStatusForRegisterActionStatus(state) {
    return {
        ...state,
        batchChangeStatusForRegisterStatus: ACTION_STATUS.READY,
    };
}


// finish
function selectRegisterTask(state, array) {
    return {
        ...state,
        selectedRegisterTaskArray: array,
    };
}
function openFinishTaskModal(state, task) {
    return {
        ...state,
        finishTaskModal: {
            ...state.finishTaskModal,
            isOpened: true,
            fields: {
                ...state.finishTaskModal.fields,
                id: task.id,
                remarks: task.remarks,
            },
        },
    };
}
function resetFinishTaskModal(state) {
    return {
        ...state,
        finishTaskModal: initialState.finishTaskModal,
    }; 
}
function finishTaskChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.finishTaskModal.fields,
        [name]: value,
    };
    return {
        ...state,
        finishTaskModal: {
          ...state.finishTaskModal,
          fields: updatedFields,
        },
    };
}

function startFinishRegisterTask(state) {
    return {
        ...state,
        finishRegisterTaskStatus: ACTION_STATUS.LOGINING,
    };
}
function finishRegisterTaskSuccess(state, resp) {
    return {
        ...state,
        finishRegisterTaskStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function finishRegisterTaskFailure(state, err) {
    return {
        ...state,
        finishRegisterTaskStatus: ACTION_STATUS.ERROR,
        finishTaskModal: {
            ...state.finishTaskModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initFinishRegisterTaskActionStatus(state) {
    return {
        ...state,
        finishRegisterTaskStatus: ACTION_STATUS.READY,
    };
}


// user account
function loadUserAccountsSuccess(state, resp) {
      const userAccountArray = resp.data;
      const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
    
      return {
        ...state,
        userAccountArray,
        userAccountOptions,
      };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
function openQuestionnaireEmailEditModalDialog(state, {sendMode, emailPayload}) {
    return {
      ...state,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        isOpened: true,
        sendMode,
        emailPayload,
      },
    };
  }
  
  function resetQuestionnaireEmailEditModalDialog(state) {
    return {
      ...state,
      questionnaireEmailEditControlModal: initialState.questionnaireEmailEditControlModal,
    };
  }

// Accept Questionnaire Result
function startAcceptQuestionnaireResult(state) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function acceptQuestionnaireResultSuccess(state, resp) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function acceptQuestionnaireResultFailure(state, err) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.ERROR,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initAcceptQuestionnaireResultActionStatus(state) {
    return {
      ...state,
      acceptQuestionnaireResultActionStatus: ACTION_STATUS.READY,
    };
  }
  
  // Reject Questionnaire Result
  function startRejectQuestionnaireResult(state) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function rejectQuestionnaireResultSuccess(state, resp) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function rejectQuestionnaireResultFailure(state, err) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.ERROR,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initRejectQuestionnaireResultActionStatus(state) {
    return {
      ...state,
      rejectQuestionnaireResultActionStatus: ACTION_STATUS.READY,
    };
  }

  function loadLicensingFormSuccess(state, resp) {
    const licensingFormArray = resp.data;
    const licensingFormOptions = licensingFormArray.map(licensingForm => <Option key={licensingForm.guid}>{licensingForm.name}</Option>);
  
    return {
      ...state,
    //   loadLicensingFormActionStatus: ACTION_STATUS.SUCCESS,
    //   licensingFormArray,
      licensingFormOptions,
    };
  }

// Send Remind Email
function startSendRemindEmail(state) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.LOGINING,
    };
  }
  
  function sendRemindEmailSuccess(state, resp) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.SUCCESS,
    };
  }
  
  function sendRemindEmailFailure(state, err) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.ERROR,
      questionnaireEmailEditControlModal: {
        ...state.questionnaireEmailEditControlModal,
        respErrMsg: err.respMessage,
      },
    };
  }
  
  function initSendRemindEmailActionStatus(state) {
    return {
      ...state,
      sendRemindEmailActionStatus: ACTION_STATUS.READY,
    };
  }


export default createReducer(initialState, {
    // query
    [ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE]: onReportSummaryQueryInputChange,
    [START_LOAD_REGISTER_INFO]: startLoadRegisterInfo,
    [LOAD_REGISTER_INFO_SUCCESS]: loadRegisterInfoSuccess,
    [LOAD_REGISTER_INFO_FAILURE]: loadRegisterInfoFailure,
    [INIT_LOAD_REGISTER_INFO_ACTION_STATUS]: initLoadRegisterInfoActionStatus,
    // select
    [SELECT_REGISTER]: selectRegister,
    // add or edit
    [OPEN_ADD_OR_EDIT_REGISTER_MODAL]: openAddOrEditRegisterModal,
    [ADD_OR_EDIT_REGISTER_CHANGE_INPUT]: addOrEditRegisterChangeInput,
    [RESET_SUBMIT_SAVE_REGISTER_MODAL]: resetSubmitSaveRegisterModal,
    [START_SUBMIT_SAVE_REGISTER]: startSubmitSaveRegister,
    [SUBMIT_SAVE_REGISTER_SUCCESS]: submitSaveRegisterSuccess,
    [SUBMIT_SAVE_REGISTER_FAILURE]: submitSaveRegisterFailure,
    [INIT_SUBMIT_SAVE_REGISTER_ACTION_STATUS]: initSubmitSaveRegisterActionStatus,


    //SUB add or edit
    [OPEN_ADD_OR_EDIT_REGISTERSUB_MODAL]: openAddOrEditRegisterSubModal,
    [ADD_OR_EDIT_REGISTERSUB_CHANGE_INPUT]: addOrEditRegisterSubChangeInput,
    [RESET_SUBMIT_SAVE_REGISTERSUB_MODAL]: resetSubmitSaveRegisterSubModal,
    [START_SUBMIT_SAVE_REGISTERSUB]: startSubmitSaveRegisterSub,
    [SUBMIT_SAVE_REGISTERSUB_SUCCESS]: submitSaveRegisterSubSuccess,
    [SUBMIT_SAVE_REGISTERSUB_FAILURE]: submitSaveRegisterSubFailure,
    [INIT_SUBMIT_SAVE_REGISTERSUB_ACTION_STATUS]: initSubmitSaveRegisterSubActionStatus,





    // batch change status
    [OPEN_BATCH_CHANGE_STATUS_MODAL]: openBatchChangeStatusModal,
    [RESET_BATCH_CHANGE_STATUS_MODAL]: resetBatchChangeStatusModal,
    [BATCH_CHANGE_STATUS_CHANGE_INPUT]: batchChangeStatusChangeInput,
    [START_BATCH_CHANGE_STATUS_FOR_REGISTER]: startBatchChangeStatusForRegister,
    [BATCH_CHANGE_STATUS_FOR_REGISTER_SUCCESS]: batchChangeStatusForRegisterSuccess,
    [BATCH_CHANGE_STATUS_FOR_REGISTER_FAILURE]: batchChangeStatusForRegisterFailure,
    [INIT_BATCH_CHANGE_STATUS_FOR_REGISTER_ACTION_STATUS]: initBatchChangeStatusForRegisterActionStatus,
    // finish
    [SELECT_REGISTER_TASK]: selectRegisterTask,
    [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,
    [RESET_FINISH_TASK_MODAL]: resetFinishTaskModal,
    [FINISH_TASK_CHANGE_INPUT]: finishTaskChangeInput,
    [START_FINISH_REGISTER_TASK]: startFinishRegisterTask,
    [FINISH_REGISTER_TASK_SUCCESS]: finishRegisterTaskSuccess,
    [FINISH_REGISTER_TASK_FAILURE]: finishRegisterTaskFailure,
    [INIT_FINISH_REGISTER_TASK_ACTION_STATUS]: initFinishRegisterTaskActionStatus,
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

        // Load User Account
        [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

      // Open/Reset Questionnaire Email Edit Modal Dialog
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN]: openQuestionnaireEmailEditModalDialog,
    [QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET]: resetQuestionnaireEmailEditModalDialog,
    [LOAD_LICENSINGFORM_SUCCESS]: loadLicensingFormSuccess,


    // Accept Questionnaire Result
  [START_ACCEPT_QUESTIONNAIRE_RESULT]: startAcceptQuestionnaireResult,
  [ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS]: acceptQuestionnaireResultSuccess,
  [ACCEPT_QUESTIONNAIRE_RESULT_FAILURE]: acceptQuestionnaireResultFailure,
  [INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS]: initAcceptQuestionnaireResultActionStatus,

  // Reject Questionnaire Result
  [START_REJECT_QUESTIONNAIRE_RESULT]: startRejectQuestionnaireResult,
  [REJECT_QUESTIONNAIRE_RESULT_SUCCESS]: rejectQuestionnaireResultSuccess,
  [REJECT_QUESTIONNAIRE_RESULT_FAILURE]: rejectQuestionnaireResultFailure,
  [INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS]: initRejectQuestionnaireResultActionStatus,
  // Send Remind Email
  [START_SEND_REMIND_EMAIL]: startSendRemindEmail,
  [SEND_REMIND_EMAIL_SUCCESS]: sendRemindEmailSuccess,
  [SEND_REMIND_EMAIL_FAILURE]: sendRemindEmailFailure,
  [INIT_SEND_REMIND_EMAIL_ACTION_STATUS]: initSendRemindEmailActionStatus,
});

