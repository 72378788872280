import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load SFC Short Interest
  START_LOAD_SFC_SHORT_INTEREST,
  LOAD_SFC_SHORT_INTEREST_SUCCESS,
  LOAD_SFC_SHORT_INTEREST_FAILURE,
  INIT_LOAD_SFC_SHORT_INTEREST_ACTION_STATUS,
} from './sfcShortConstants';

const initialState = {
  sfcShortableListGridColumns: GridColumnMap.sfcShortableListGridColumns,

  // SFC Short Interest
  sfcShortInterestArray: [],

  // Load SFC Short Interest Action Status
  loadSFCShortInterestActionStatus: ACTION_STATUS.READY,
}


// Load SFC Short Interest
function startLoadSFCShortInterest(state) {
  return {
    ...state,
    loadSFCShortInterestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadSFCShortInterestSuccess(state, resp) {
  return {
    ...state,
    loadSFCShortInterestActionStatus: ACTION_STATUS.SUCCESS,
    sfcShortInterestArray: resp.data,
  };
}

function loadSFCShortInterestFailure(state, err) {
  return {
    ...state,
    loadSFCShortInterestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadSFCShortInterestActionStatus(state) {
  return {
    ...state,
    loadSFCShortInterestActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load SFC Short Interest
  [START_LOAD_SFC_SHORT_INTEREST]: startLoadSFCShortInterest,
  [LOAD_SFC_SHORT_INTEREST_SUCCESS]: loadSFCShortInterestSuccess,
  [LOAD_SFC_SHORT_INTEREST_FAILURE]: loadSFCShortInterestFailure,
  [INIT_LOAD_SFC_SHORT_INTEREST_ACTION_STATUS]: initLoadSFCShortInterestActionStatus,

});