import {connect} from 'react-redux';
import ElementMngDashboard from '../components/ElementMngDashboard';


import {
    // query input
    onElementQueryInputChange,
    // select data
    selectElementList,
    selectElementPropsList,
    // query element
    queryElement,
    initQueryElementActionStatus,
    // add or edit element
    openAddOrEditElementModal,
    resetAddOrEditElementModal,
    onElementDataInputChange,
    resetElementProps,
    resetInitEditOfAddOrEditElementModal,
    appendPropsFormItemToState,
    onElementPropsInputChange,
    submitAddOrEditElement,
    submitAddOrEditElementFailure,
    initSubmitAddOrEditElementActionStatus,
    // del element
    delElementBtnClicked,
    initDelElementBtnClickedActionStatus,
    // del props
    delPropsBtnClicked,
    initDelPropsBtnClickedActionStatus,


} from '../ElementMngActions';



const mapStateToProps = state => {
    return {
        // query input
        elementQueryFields: state.elementMng.elementQueryFields,
        // select data
        selectedElementList: state.elementMng.selectedElementList,
        selectedElementPropsList: state.elementMng.selectedElementPropsList,
        // query element
        queryElementActionStatus: state.elementMng.queryElementActionStatus,
        queryElementActionResp: state.elementMng.queryElementActionResp,
        // add or edit element
        addOrEditElementModal: state.elementMng.addOrEditElementModal,
        elementTypeList: state.elementMng.elementTypeList,
        elementTypeSelectOptions: state.elementMng.elementTypeSelectOptions,
        entitySelectOptions: state.elementMng.entitySelectOptions,
        propsFormItemArray: state.elementMng.propsFormItemArray,
        addOrEditElementActionStatus: state.elementMng.addOrEditElementActionStatus,
        // del element
        delElementActionStatus: state.elementMng.delElementActionStatus,
        delElementActionResp: state.elementMng.delElementActionResp,
        // del props
        delPropsActionStatus: state.elementMng.delPropsActionStatus,
        delPropsActionResp: state.elementMng.delPropsActionResp,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query input
        onElementQueryInputChange: (param) => dispatch(onElementQueryInputChange(param)),
        // select data
        selectElementList: (data) => dispatch(selectElementList(data)),
        selectElementPropsList: (data) => dispatch(selectElementPropsList(data)),
        // query element
        queryElement: (param) => dispatch(queryElement(param)),
        initQueryElementActionStatus: () => dispatch(initQueryElementActionStatus()),
        // add or edit element
        openAddOrEditElementModal: (mode, data) => dispatch(openAddOrEditElementModal(mode, data)),
        resetAddOrEditElementModal: () => dispatch(resetAddOrEditElementModal()),
        onElementDataInputChange: (param) => dispatch(onElementDataInputChange(param)),
        resetElementProps: () => dispatch(resetElementProps()),
        resetInitEditOfAddOrEditElementModal: () => dispatch(resetInitEditOfAddOrEditElementModal()),
        appendPropsFormItemToState: (array) => dispatch(appendPropsFormItemToState(array)),
        onElementPropsInputChange: (props) => dispatch(onElementPropsInputChange(props)),
        submitAddOrEditElement: (bean) => dispatch(submitAddOrEditElement(bean)),
        submitAddOrEditElementFailure: (err) => dispatch(submitAddOrEditElementFailure(err)),
        initSubmitAddOrEditElementActionStatus: () => dispatch(initSubmitAddOrEditElementActionStatus()),
        // del element
        delElementBtnClicked: (bean) => dispatch(delElementBtnClicked(bean)),
        initDelElementBtnClickedActionStatus: () => dispatch(initDelElementBtnClickedActionStatus()),
        // del props
        delPropsBtnClicked: (props) => dispatch(delPropsBtnClicked(props)),
        initDelPropsBtnClickedActionStatus: () => dispatch(initDelPropsBtnClickedActionStatus()),

    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ElementMngDashboard);

