// Load Restricted Sectors
export const START_LOAD_RESTRICTED_SECTORS = 'START_LOAD_RESTRICTED_SECTORS';
export const LOAD_RESTRICTED_SECTORS_SUCCESS = 'LOAD_RESTRICTED_SECTORS_SUCCESS';
export const LOAD_RESTRICTED_SECTORS_FAILURE = 'LOAD_RESTRICTED_SECTORS_FAILURE';
export const INIT_LOAD_RESTRICTED_SECTORS_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECTORS_ACTION_STATUS';

// Create Restricted SECTOR
export const START_CREATE_RESTRICTED_SECTOR = 'START_CREATE_RESTRICTED_SECTOR';
export const CREATE_RESTRICTED_SECTOR_SUCCESS = 'CREATE_RESTRICTED_SECTOR_SUCCESS';
export const CREATE_RESTRICTED_SECTOR_FAILURE = 'CREATE_RESTRICTED_SECTOR_FAILURE';
export const INIT_CREATE_RESTRICTED_SECTOR_ACTION_STATUS = 'INIT_CREATE_RESTRICTED_SECTOR_ACTION_STATUS';

// Update Restricted SECTOR
export const START_UPDATE_RESTRICTED_SECTOR = 'START_UPDATE_RESTRICTED_SECTOR';
export const UPDATE_RESTRICTED_SECTOR_SUCCESS = 'UPDATE_RESTRICTED_SECTOR_SUCCESS';
export const UPDATE_RESTRICTED_SECTOR_FAILURE = 'UPDATE_RESTRICTED_SECTOR_FAILURE';
export const INIT_UPDATE_RESTRICTED_SECTOR_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_SECTOR_ACTION_STATUS';

// Batch Delete Restricted SECTOR
export const START_BATCH_DELETE_RESTRICTED_SECTOR = 'START_BATCH_DELETE_RESTRICTED_SECTOR';
export const BATCH_DELETE_RESTRICTED_SECTOR_SUCCESS = 'BATCH_DELETE_RESTRICTED_SECTOR_SUCCESS';
export const BATCH_DELETE_RESTRICTED_SECTOR_FAILURE = 'BATCH_DELETE_RESTRICTED_SECTOR_FAILURE';
export const INIT_BATCH_DELETE_RESTRICTED_SECTOR_ACTION_STATUS = 'INIT_BATCH_DELETE_RESTRICTED_SECTOR_ACTION_STATUS';

// Open/Reset Restricted SECTOR Modal Dialog
export const RESTRICTED_SECTOR_MODAL_DIALOG_OPEN = 'RESTRICTED_SECTOR_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECTOR_MODAL_DIALOG_RESET = 'RESTRICTED_SECTOR_MODAL_DIALOG_RESET';

// Restricted Sector Edit Change Input
export const RESTRICTED_SECTOR_EDIT_CHANGE_INPUT = 'RESTRICTED_SECTOR_EDIT_CHANGE_INPUT';

// RadioGroup Change
export const RADIO_GROUP_CHANGE = 'RADIO_GROUP_CHANGE';

// Sector Name Select Changed
export const SECTOR_NAME_SELECT_CHANGED = 'BBG_TICKER_SELECT_CHANGED';

// Select Restricted Sectors
export const SELECT_RESTRICTED_SECTORS = 'SELECT_RESTRICTED_SECTORS';

// Fund Code Select Changed
export const FUND_CODE_SELECT_CHANGED = 'FUND_CODE_SELECT_CHANGED';

// Book Code Select Changed
export const BOOK_CODE_SELECT_CHANGED = 'BOOK_CODE_SELECT_CHANGED';