import {connect} from 'react-redux';
import MeetingAndTrainingReviewDashboard from '../components/MeetingAndTrainingReviewDashboard';


import {
    // query
    onQueryInputChange,
    loadMeetingAndTrainingReviewInfo,
    initLoadMeetingAndTrainingReviewInfoActionStatus,
    // select
    selectMeetingAndTrainingReviewInfo,
    // add or edit
    openAddOrEditMeetingAndTrainingReviewModal,
    addOrEditMeetingAndTrainingReviewChangeInput,
    addOrEditMeetingAndTrainingReview,
    initAddOrEditMeetingAndTrainingReviewActionStatus,
    resetAddOrEditMeetingAndTrainingReviewModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelMeetingAndTrainingReviewModal,
    delMeetingAndTrainingReviewChangeInput,
    delMeetingAndTrainingReview,
    initDelMeetingAndTrainingReviewActionStatus,
    resetDelMeetingAndTrainingReviewModal,

    //cop
    openCopMeetingAndTrainingReviewModal,
    copMeetingAndTrainingReviewChangeInput,
    copMeetingAndTrainingReview,
    initCopMeetingAndTrainingReviewActionStatus,
    resetCopMeetingAndTrainingReviewModal,

      // Select Answeree
    selectAnswerees,
    initSelectAnswereesActionStatus,




    loadRestrictedRuleList,

    
    openFinishTaskModal,

    doOperationRecordAction,

} from '../MeetingAndTrainingReviewActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.meetingAndTrainingReview.statusOptions,
        typeOptions: state.meetingAndTrainingReview.typeOptions,
        fundFirmRelatedOptions: state.meetingAndTrainingReview.fundFirmRelatedOptions,
        ccyOptions: state.meetingAndTrainingReview.ccyOptions,
        userAccountOptions: state.meetingAndTrainingReview.userAccountOptions,
        // meetingAndTrainingReviewQueryFields
        meetingAndTrainingReviewQueryFields: state.meetingAndTrainingReview.meetingAndTrainingReviewQueryFields,
        // query
        loadMeetingAndTrainingReviewInfoActionStatus: state.meetingAndTrainingReview.loadMeetingAndTrainingReviewInfoActionStatus,
        loadMeetingAndTrainingReviewInfoResp: state.meetingAndTrainingReview.loadMeetingAndTrainingReviewInfoResp,
       
        restrictedRuleList:  state.meetingAndTrainingReview.restrictedRuleList,
       
        selectedMeetingAndTrainingReviewInfoArray: state.meetingAndTrainingReview.selectedMeetingAndTrainingReviewInfoArray,
        meetingAndTrainingReviewGridColumns: state.meetingAndTrainingReview.meetingAndTrainingReviewGridColumns,
        // add or edit
        addOrEditMeetingAndTrainingReviewModal: state.meetingAndTrainingReview.addOrEditMeetingAndTrainingReviewModal,
        addOrEditMeetingAndTrainingReviewStatus: state.meetingAndTrainingReview.addOrEditMeetingAndTrainingReviewStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.meetingAndTrainingReview.refreshFxStatus,
        refreshFxResp: state.meetingAndTrainingReview.refreshFxResp,
        // del
        delMeetingAndTrainingReviewModal: state.meetingAndTrainingReview.delMeetingAndTrainingReviewModal,
        delMeetingAndTrainingReviewStatus: state.meetingAndTrainingReview.delMeetingAndTrainingReviewStatus,
        // cop
        copMeetingAndTrainingReviewModal: state.meetingAndTrainingReview.copMeetingAndTrainingReviewModal,
        copMeetingAndTrainingReviewStatus: state.meetingAndTrainingReview.copMeetingAndTrainingReviewStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        meetingAndTrainingReviewUploadUrl: state.meetingAndTrainingReview.meetingAndTrainingReviewUploadUrl,
        meetingAndTrainingReviewDownloadUrl: state.meetingAndTrainingReview.meetingAndTrainingReviewDownloadUrl,
        finishTaskModal: state.meetingAndTrainingReview.finishTaskModal,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadMeetingAndTrainingReviewInfo: (params) => dispatch(loadMeetingAndTrainingReviewInfo(params)),
        initLoadMeetingAndTrainingReviewInfoActionStatus: () => dispatch(initLoadMeetingAndTrainingReviewInfoActionStatus()),
        // select
        selectMeetingAndTrainingReviewInfo: (dataArray) => dispatch(selectMeetingAndTrainingReviewInfo(dataArray)),
        // add or edit
        openAddOrEditMeetingAndTrainingReviewModal: (mode, data) => dispatch(openAddOrEditMeetingAndTrainingReviewModal(mode, data)),
        addOrEditMeetingAndTrainingReviewChangeInput: (params) => dispatch(addOrEditMeetingAndTrainingReviewChangeInput(params)),
        addOrEditMeetingAndTrainingReview: (info) => dispatch(addOrEditMeetingAndTrainingReview(info)),
        initAddOrEditMeetingAndTrainingReviewActionStatus: () => dispatch(initAddOrEditMeetingAndTrainingReviewActionStatus()),
        resetAddOrEditMeetingAndTrainingReviewModal: () => dispatch(resetAddOrEditMeetingAndTrainingReviewModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelMeetingAndTrainingReviewModal: (info,mode) => dispatch(openDelMeetingAndTrainingReviewModal(info,mode)),
        delMeetingAndTrainingReviewChangeInput: (params) => dispatch(delMeetingAndTrainingReviewChangeInput(params)),
        delMeetingAndTrainingReview: (info) => dispatch(delMeetingAndTrainingReview(info)),
        initDelMeetingAndTrainingReviewActionStatus: () => dispatch(initDelMeetingAndTrainingReviewActionStatus()),
        resetDelMeetingAndTrainingReviewModal: () => dispatch(resetDelMeetingAndTrainingReviewModal()),

        // cop
        openCopMeetingAndTrainingReviewModal: (info) => dispatch(openCopMeetingAndTrainingReviewModal(info)),
        copMeetingAndTrainingReviewChangeInput: (params) => dispatch(copMeetingAndTrainingReviewChangeInput(params)),
        copMeetingAndTrainingReview: (params) => dispatch(copMeetingAndTrainingReview(params)),
        initCopMeetingAndTrainingReviewActionStatus: () => dispatch(initCopMeetingAndTrainingReviewActionStatus()),
        resetCopMeetingAndTrainingReviewModal: () => dispatch(resetCopMeetingAndTrainingReviewModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),






        loadRestrictedRuleList: (params) => dispatch(loadRestrictedRuleList(params)),


        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),

    };
}


export default connect (
    
    mapStateToProps,
    mapDispatchToProps
)(MeetingAndTrainingReviewDashboard);