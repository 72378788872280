import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RestrictedSecurityOperateMode from '../../../../utils/RestrictedSecurityOperateMode';
import GridColumnMap from '../GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { Select } from 'antd';

import {
  // Load Submitted Restricted Security Request
  QUERY_RESTRICTED_SECURITY_INPUT_CHANGE,
  START_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST,
  LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Select Restricted Security Request
  SELECT_RESTRICTED_SECURITY_REQUEST,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET,

  // Approve Restricted Security Request
  START_APPROVE_RESTRICTED_SECURITY_REQUEST,
  APPROVE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  APPROVE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_APPROVE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Reject Restricted Security Request
  START_REJECT_RESTRICTED_SECURITY_REQUEST,
  REJECT_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  REJECT_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_REJECT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Feedback Restricted Security Request
  START_FEEDBACK_RESTRICTED_SECURITY_REQUEST,
  FEEDBACK_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  FEEDBACK_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_FEEDBACK_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Open/Reset Restricted Security Operation Record Modal Dialog
  RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_RESET,

  // Load Restricted Security Operation Record
  START_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD,
  LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_SUCCESS,
  LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_ACTION_STATUS,

  // Open/Reset Restricted Security Request Edit Modal Dialog
  RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_RESET,

  // Restricted Security Request Edit Change Input
  RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT,

  // Load Restricted Security Request BBG Tickers
  START_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS,
  LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_SUCCESS,
  LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_ACTION_STATUS,

  // Clear Restricted Security Request BBG Ticker
  CLEAR_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS,

  // Restricted Security Request BBG Ticker Select Changed
  RESTRICTED_SECURITY_REQUEST_BBG_TICKER_SELECT_CHANGED,

  // Restricted Security Request Fund Code Select Changed
  RESTRICTED_SECURITY_REQUEST_FUND_CODE_SELECT_CHANGED,

  // Restricted Security Request Book Code Select Changed
  RESTRICTED_SECURITY_REQUEST_BOOK_CODE_SELECT_CHANGED,

  // Update Restricted Security Request
  START_UPDATE_RESTRICTED_SECURITY_REQUEST,
  UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // add
  START_ADD_RESTRICTED_SECURITY_REQUEST,
  ADD_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  ADD_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_ADD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Show Valid Restricted Rules
  SHOW_VALID_RESTRICTED_RULES,

  // Show All Restricted Rules
  SHOW_ALL_RESTRICTED_RULES,

  //Restricted rule
  LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,
  ADD_RESTRICTED_SECURITIES_RULE_SUCCESS,
  ADD_RESTRICTED_SECURITIES_RULE_FAILURE,
  UPDATE_RESTRICTED_SECURITIES_RULE_SUCCESS,
  UPDATE_RESTRICTED_SECURITIES_RULE_FAILURE,
  RESTRICTED_RULE_INPUT_CHANGE,
  OPEN_RESTRICTED_RULE_DIALOG,
  CLOSE_RESTRICTED_RULE_DIALOG,
  OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG

} from './restrictedSecurityRequestConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,

  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
} from '../../home/homeConstants';
import moment from 'moment';

const Option = Select.Option;

const initialState = {

  restrictedSecurityQueryParams: {
    employee: null,
    validDateStart:  moment()
    .clone()
    .add('month', -3)
    .format('YYYY-MM-DD'),
    validDateEnd: moment().format('YYYY-MM-DD'),
  },

  // Grdi Columns
  restrictedSecurityRequestGridColumns: GridColumnMap.restrictedSecurityRequestGridColumns,
  restrictedSecurityOpRecordGridColumns: GridColumnMap.restrictedSecurityOpRecordGridColumns,
  restrictedSecurityRuleGridColumns: GridColumnMap.restrictedSecurityRuleGridColumns,

  // Restricted Security Request
  restrictedSecurityRequestArray: [],

  // Selected Restricted Security Request
  selectedRestrictedSecurityRequestArray: [],

  // load Submitted Restricted Security Request Action Status
  loadSubmittedRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  
  // Restricted Security Request Operate Modal Dialog
  restrictedSecurityRequestOperateControlModal: {
    isOpened: false,
    operateMode: RestrictedSecurityOperateMode.APPROVE,
    restrictedSecReqGuidList: [],
    respErrMsg: '',
  },

  // Approve/Reject Restricted Security Request Action Status
  approveRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  rejectRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,

  // Feedback Restricted Security Request Action Status
  feedbackRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,

  // Restricted Security Operation Record Modal Dialog
  restrictedSecurityOperationRecordControlModal: {
    isOpened: false,
  },

  // Restricted Security Operation Record
  restrictedSecurityOperationRecordArray: [],

  // Load Restricted Security Operation Record Action Status
  loadRestrictedSecurityOperationRecordActionStatus: ACTION_STATUS.READY,

  // Restricted Security Request Edit Modal
  restrictedSecurityRequestEditControlModal: {
    isOpened: false,
    isAddMode: true,
    respErrMsg: '',
    fields: {
      guid: '',
      restrictedSecGuid: '',
      restrictionType: '',
      fund: '',
      book: '',
      personInCharge: '',
      securityId: '',
      bbgTicker: '',
      secName: '',
      validFrom: '',
      validTo: '9999-12-31 00:00',
      onListReason: '',
      offListReason: '',
      relatedCompany: '',
      attachment: '',
      attachmentOriginName: '',
      requestBy: '',
      comments: '',
      fileList: [],
      ruleCode: 'RestrictionRule_Global'
    }
  },

  // Security Ticker Data
  securityTickerData: null,
  bbgTickerArray: [],
  bbgTickerOptions: [],
  securityTickerArray: [],

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // Load User Account
  userAccountArray: [],
  userAccountOptions: [],

  // Load Restricted Security Request BBG Tickers
  loadRestrictedSecurityRequestBbgTickersActionStatus: ACTION_STATUS.READY,

  // 附件操作URL
  restrictedSecAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_RESTRICTED_SECURITY_ATTACHMENT,
  restrictedSecAttachmentPreviewUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PREVIEW_RESTRICTED_SECURITY_ATTACHMENT,
  restrictedSecAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT,

  //restricted rule
  restrictedRuleList: [],
  selectedRestrictedRule: {

  },
  restrictedRuleEditControlModal: {
    isOpened: false,
    respErrMsg: null,
    isAddMode: null,
    isFundBookOpened: false,
    fields:{

    },
    fundBookFields:{

    }
  }
} 

// Load Submitted Restricted Security Request
function queryRestrictedSecurityInputChange(state, {name, value}) {
  return {
    ...state,
    restrictedSecurityQueryParams: {
      ...state.restrictedSecurityQueryParams,
      [name]: value,
    }
  }
}

function startLoadSubmittedRestrictedSecurityRequest(state) {
  return {
    ...state,
    loadSubmittedRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}


function dateToStr(inputDate) {
  if(inputDate === undefined || inputDate === null) {
    return '';
  }
  let year = inputDate.getFullYear();
  let month = inputDate.getMonth();
  let date = inputDate.getDate();
  let hour = inputDate.getHours();
  let minu = inputDate.getMinutes();
  let sec = inputDate.getSeconds();
  month = month + 1;
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;
  if (hour < 10) hour = "0" + hour;
  if (minu < 10) minu = "0" + minu;
  if (sec < 10) sec = "0" + sec;
  return year + '-' + month + '-' + date + ' ' + hour + ':' + minu + ':' + sec;
}


function loadSubmittedRestrictedSecurityRequestSuccess(state, resp) {
  const restrictedSecurityRequestArray = resp.data;
  const todayStr = dateToStr(new Date());
  let filterRestrictedSecurityRequestArray = [];
  if (!!restrictedSecurityRequestArray) {
    filterRestrictedSecurityRequestArray = restrictedSecurityRequestArray
              .filter(restrictedRule => restrictedRule.validTo===undefined || restrictedRule.validTo===null 
                                        || restrictedRule.validTo==='' || restrictedRule.validTo >=todayStr);
  }
  return {
    ...state,
    loadSubmittedRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
    restrictedSecurityRequestArray: restrictedSecurityRequestArray,
    filterRestrictedSecurityRequestArray: filterRestrictedSecurityRequestArray,
    selectedRestrictedSecurityRequestArray: [],
  };
}

function loadSubmittedRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    loadSubmittedRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadSubmittedRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    loadSubmittedRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Select Restricted Security Request
function selectRestrictedSecurityRequest(state, payload) {
  return {
    ...state,
    selectedRestrictedSecurityRequestArray: payload,
  };
}

// Open/Reset Restricted Security Request Operate Modal Dialog
function openRestrictedSecurityRequestOperateModalDialog(state, {operateMode, restrictedSecReqGuidList, requestStatus}) {
  return {
    ...state,
    restrictedSecurityRequestOperateControlModal: {
      ...state.restrictedSecurityRequestOperateControlModal,
      isOpened: true,
      operateMode,
      restrictedSecReqGuidList,
      requestStatus,
    },
  };
}

function resetRestrictedSecurityRequestOperateModalDialog(state) {
  return {
    ...state,
    restrictedSecurityRequestOperateControlModal: initialState.restrictedSecurityRequestOperateControlModal,
  };
}

// Approve Restricted Security Request
function startApproveRestrictedSecurityRequest(state) {
  return {
    ...state,
    approveRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function approveRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    approveRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityRequestArray: [],
  };
}

function approveRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    approveRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initApproveRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    approveRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Reject Restricted Security Request
function startRejectRestrictedSecurityRequest(state) {
  return {
    ...state,
    rejectRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function rejectRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    rejectRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityRequestArray: [],
  };
}

function rejectRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    rejectRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initRejectRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    rejectRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Feedback Restricted Security Request
function startFeedbackRestrictedSecurityRequest(state) {
  return {
    ...state,
    feedbackRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function feedbackRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    feedbackRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityRequestArray: [],
  };
}

function feedbackRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    feedbackRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initFeedbackRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    feedbackRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Restricted Security Operation Record Modal Dialog
function openRestrictedSecurityOperationRecordModalDialog(state) {
  return {
    ...state,
    restrictedSecurityOperationRecordControlModal: {
      ...state.restrictedSecurityOperationRecordControlModal,
      isOpened: true,
    },
  };
}

function resetRestrictedSecurityOperationRecordModalDialog(state) {
  return {
    ...state,
    restrictedSecurityOperationRecordControlModal: initialState.restrictedSecurityOperationRecordControlModal,
  };
}

// Load Restricted Security Operation Record
function startLoadRestrictedSecurityOperationRecord(state) {
  return {
    ...state,
    loadRestrictedSecurityOperationRecordActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSecurityOperationRecordSuccess(state, resp) {
  return {
    ...state,
    loadRestrictedSecurityOperationRecordActionStatus: ACTION_STATUS.SUCCESS,
    restrictedSecurityOperationRecordArray: resp.data,
  };
}

function loadRestrictedSecurityOperationRecordFailure(state, err) {
  return {
    ...state,
    loadRestrictedSecurityOperationRecordActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSecurityOperationRecordActionStatus(state) {
  return {
    ...state,
    loadRestrictedSecurityOperationRecordActionStatus: ACTION_STATUS.READY,
  };
}

// Open/Reset Restricted Security Request Edit Modal Dialog
function openRestrictedSecurityRequestEditModalDialog(state, { restrictedSecurityRequest, isAddMode }) {
   if(isAddMode) {
    return {
      ...state,
      restrictedSecurityRequestEditControlModal: {
        ...state.restrictedSecurityRequestEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedSecurityRequestEditControlModal.fields,
          validFrom: moment().format("YYYY-MM-DD HH:mm"),
        }
      },
    };

  } else {
  
    const restrictedSecAttachmentDownloadUrl = state.restrictedSecAttachmentDownloadUrl;

    const fileList = [];
    const attachment = restrictedSecurityRequest.attachment;
    const attachmentOriginName = restrictedSecurityRequest.attachmentOriginName;

    if (attachment && attachmentOriginName) {
      const file = {
        uid: '-1',
        name: attachmentOriginName,
        status: 'done',
        url: restrictedSecAttachmentDownloadUrl + "/" + attachment,
        response: {
          respCode: "S0001",
          data: {
            attachment,
            attachmentOriginName,
          }
        }
      };
      fileList.push(file);
    }

    return {
      ...state,
      restrictedSecurityRequestEditControlModal: {
        ...state.restrictedSecurityRequestEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedSecurityRequestEditControlModal.fields,
          guid: restrictedSecurityRequest.guid,
          restrictedSecGuid: restrictedSecurityRequest.restrictedSecGuid,
          restrictionType: restrictedSecurityRequest.restrictionType,
          fund: restrictedSecurityRequest.fund,
          book: restrictedSecurityRequest.book,
          personInCharge: restrictedSecurityRequest.personInCharge,
          securityId: restrictedSecurityRequest.securityId,
          bbgTicker: restrictedSecurityRequest.bbgTicker,
          secName: restrictedSecurityRequest.secName,
          validFrom: restrictedSecurityRequest.validFrom,
          validTo: restrictedSecurityRequest.validTo,
          onListReason: restrictedSecurityRequest.onListReason,
          offListReason: restrictedSecurityRequest.offListReason,
          relatedCompany: restrictedSecurityRequest.relatedCompany,
          attachment: restrictedSecurityRequest.attachment,
          attachmentOriginName: restrictedSecurityRequest.attachmentOriginName,
          requestBy: restrictedSecurityRequest.requestBy,
          fileList,
          ruleCode: restrictedSecurityRequest.ruleCode,
        }
      },
    };

  }

}

function resetRestrictedSecurityRequestEditModalDialog(state) {
  return {
    ...state,
    restrictedSecurityRequestEditControlModal: initialState.restrictedSecurityRequestEditControlModal,
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  // let bbgTickerArray = securityTickerData.bbgTickerList;
  let securityTickerArray = securityTickerData.securityTickerList;

  // let bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);

  return {
    ...state,
    securityTickerData,
    // bbgTickerArray,
    securityTickerArray,
  };
}

// Load User Account
function loadUserAccountsSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}

// Restricted Security Request Edit Change Input
function restrictedSecurityRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedSecurityRequestEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// =================================================================
// Load Restricted Security Request BBG Tickers
function startLoadRestrictedSecurityRequestBbgTickers(state) {
  return {
    ...state,
    loadRestrictedSecurityRequestBbgTickersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSecurityRequestBbgTickersSuccess(state, resp) {
  const bbgTickerArray = resp.data;

  const bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);
  
  return {
    ...state,
    bbgTickerArray,
    bbgTickerOptions,
    loadRestrictedSecurityRequestBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedSecurityRequestBbgTickersFailure(state, err) {
  return {
    ...state,
    loadRestrictedSecurityRequestBbgTickersActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSecurityRequestBbgTickersActionStatus(state) {
  return {
    ...state,
    loadRestrictedSecurityRequestBbgTickersActionStatus: ACTION_STATUS.READY,
  };
}

// Clear Restricted Security Request BBG Ticker
function clearRestrictedSecurityRequestBbgTickers(state) {
  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        secName: '',
      },
    },
    bbgTickerArray: [],
    bbgTickerOptions: [],
  };
}

// Restricted Security Request BBG Ticker Select Changed
function restrictedSecurityRequestBbgTickerSelectChanged(state, bbgTicker) {
  const securityTickerArray = state.securityTickerArray;

  let securityName = '';
  let securityId = '';
  if (!!bbgTicker) {
    const filterSecurityTickerArray = securityTickerArray.filter(securityTicker => securityTicker.bbgTicker === bbgTicker);

    if (filterSecurityTickerArray.length > 0) {
      const securityTicker = filterSecurityTickerArray[0];
      securityName = securityTicker.securityName;
      securityId = securityTicker.securityId;
    }
  }

  const fields = state.restrictedSecurityRequestEditControlModal.fields;

  securityName = securityName === '' ? fields.secName : securityName;

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        secName: securityName,
        securityId,
      },
    },
  };
}

// Restricted Security Request Fund Code Select Changed
function restrictedSecurityRequestFundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedSecurityRequestEditControlModal.fields.book;

  let personInCharge = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      personInCharge = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        personInCharge,
      },
    },
  };
}

// Restricted Security Request Book Code Select Changed
function restrictedSecurityRequestBookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedSecurityRequestEditControlModal.fields.fund;

  let personInCharge = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      personInCharge = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        personInCharge,
      },
    },
  };
}

// Update Restricted Security Request
function startUpdateRestrictedSecurityRequest(state) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}
function updateRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
    selectedRestrictedSecurityRequestArray: [],
  };
}
function updateRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}
export function initUpdateRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}


// Add
function startAddRestrictedSecurityRequest(state) {
  return {
    ...state,
    addRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}
function addRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    addRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}
function addRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    addRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}
export function initAddRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    addRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}



// Show Valid Restricted Rules
function showValidRestrictedRules(state) {
  const todayStr = dateToStr(new Date());
  const restrictedSecurityRequestArray = state.restrictedSecurityRequestArray;
  const filterRestrictedSecurityRequestArray = restrictedSecurityRequestArray
                                              .filter(restrictedRule => restrictedRule.validTo===undefined || restrictedRule.validTo===null 
                                                                        || restrictedRule.validTo==='' || restrictedRule.validTo >=todayStr);

  return {
    ...state,
    filterRestrictedSecurityRequestArray,
  };
}

// Show All Restricted Rules
function showAllRestrictedRules(state) {
  const restrictedSecurityRequestArray = state.restrictedSecurityRequestArray;
  const filterRestrictedSecurityRequestArray = restrictedSecurityRequestArray;

  return {
    ...state,
    filterRestrictedSecurityRequestArray,
  };
}

function loadRestrictedRuleListSuccess(state, resp) {
  return {
    ...state,
    restrictedRuleList: resp.data,
  };
}

function loadRestrictedRuleListFailure(state, err) {
  return {
    ...state
  };
}

function addRestrictedRuleSuccess(state, resp) {
  return {
    ...state
  }
}

function addRestrictedRuleFailure(state, err) {
  return {
    ...state
  };
}

function updateRestrictedRuleSuccess(state, resp) {
  return {
    ...state
  };
}

function updateRestrictedRuleFailure(state, err) {
  return {
    ...state
  };
}

function restrictedRuleEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    [name]: value,
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields
  };
}


function openRestrictedRuleDialog(state , {mode ,detail}) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    mode,
    fields: detail,
    isOpened: true
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields
  };
}

function closeRestrictedRuleDialog(state, {mode}) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    mode,
    fields: {},
    isOpened: false,
    isFundBookOpened: false,
    fundBookFields:{}
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields
  };
}

export function openRestrictedRuleFundBookDialog(state) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    isFundBookOpened: true,
    fundBookFields:{},
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields
  };
}

export function closeRestrictedRuleFundBookDialog(state) {
  let updatedFields = {
    ...state.restrictedRuleEditControlModal,
    isFundBookOpened: false,
    fundBookFields:{},
  };
  return {
    ...state,
    restrictedRuleEditControlModal: updatedFields
  };
}

export default createReducer(initialState, {
  // Load Submitted Restricted Security Request
  [QUERY_RESTRICTED_SECURITY_INPUT_CHANGE]: queryRestrictedSecurityInputChange,
  [START_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST]: startLoadSubmittedRestrictedSecurityRequest,
  [LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_SUCCESS]: loadSubmittedRestrictedSecurityRequestSuccess,
  [LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_FAILURE]: loadSubmittedRestrictedSecurityRequestFailure,
  [INIT_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initLoadSubmittedRestrictedSecurityRequestActionStatus,

  // Select Restricted Security Request
  [SELECT_RESTRICTED_SECURITY_REQUEST]: selectRestrictedSecurityRequest,
  
  // Open/Reset Restricted Security Request Operate Modal Dialog
  [RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN]: openRestrictedSecurityRequestOperateModalDialog,
  [RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET]: resetRestrictedSecurityRequestOperateModalDialog,

  // Approve Restricted Security Request
  [START_APPROVE_RESTRICTED_SECURITY_REQUEST]: startApproveRestrictedSecurityRequest,
  [APPROVE_RESTRICTED_SECURITY_REQUEST_SUCCESS]: approveRestrictedSecurityRequestSuccess,
  [APPROVE_RESTRICTED_SECURITY_REQUEST_FAILURE]: approveRestrictedSecurityRequestFailure,
  [INIT_APPROVE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initApproveRestrictedSecurityRequestActionStatus,

  // Reject Restricted Security Request
  [START_REJECT_RESTRICTED_SECURITY_REQUEST]: startRejectRestrictedSecurityRequest,
  [REJECT_RESTRICTED_SECURITY_REQUEST_SUCCESS]: rejectRestrictedSecurityRequestSuccess,
  [REJECT_RESTRICTED_SECURITY_REQUEST_FAILURE]: rejectRestrictedSecurityRequestFailure,
  [INIT_REJECT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initRejectRestrictedSecurityRequestActionStatus,

  // Feedback Restricted Security Request
  [START_FEEDBACK_RESTRICTED_SECURITY_REQUEST]: startFeedbackRestrictedSecurityRequest,
  [FEEDBACK_RESTRICTED_SECURITY_REQUEST_SUCCESS]: feedbackRestrictedSecurityRequestSuccess,
  [FEEDBACK_RESTRICTED_SECURITY_REQUEST_FAILURE]: feedbackRestrictedSecurityRequestFailure,
  [INIT_FEEDBACK_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initFeedbackRestrictedSecurityRequestActionStatus,

  // Open/Reset Restricted Security Operation Record Modal Dialog
  [RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_OPEN]: openRestrictedSecurityOperationRecordModalDialog,
  [RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_RESET]: resetRestrictedSecurityOperationRecordModalDialog,

  // Load Restricted Security Operation Record
  [START_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD]: startLoadRestrictedSecurityOperationRecord,
  [LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_SUCCESS]: loadRestrictedSecurityOperationRecordSuccess,
  [LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_FAILURE]: loadRestrictedSecurityOperationRecordFailure,
  [INIT_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_ACTION_STATUS]: initLoadRestrictedSecurityOperationRecordActionStatus,

  // Open/Reset Restricted Security Request Edit Modal Dialog
  [RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_OPEN]: openRestrictedSecurityRequestEditModalDialog,
  [RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_RESET]: resetRestrictedSecurityRequestEditModalDialog,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

  // Restricted Security Request Edit Change Input
  [RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT]: restrictedSecurityRequestEditChangeInput,

  // Load Restricted Security Request BBG Tickers
  [START_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS]: startLoadRestrictedSecurityRequestBbgTickers,
  [LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_SUCCESS]: loadRestrictedSecurityRequestBbgTickersSuccess,
  [LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_FAILURE]: loadRestrictedSecurityRequestBbgTickersFailure,
  [INIT_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_ACTION_STATUS]: initLoadRestrictedSecurityRequestBbgTickersActionStatus,

  // Clear Restricted Security Request BBG Ticker
  [CLEAR_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS]: clearRestrictedSecurityRequestBbgTickers,

  // Restricted Security Request BBG Ticker Select Changed
  [RESTRICTED_SECURITY_REQUEST_BBG_TICKER_SELECT_CHANGED]: restrictedSecurityRequestBbgTickerSelectChanged,

  // Restricted Security Request Fund Code Select Changed
  [RESTRICTED_SECURITY_REQUEST_FUND_CODE_SELECT_CHANGED]: restrictedSecurityRequestFundCodeSelectChanged,

  // Restricted Security Request Book Code Select Changed
  [RESTRICTED_SECURITY_REQUEST_BOOK_CODE_SELECT_CHANGED]: restrictedSecurityRequestBookCodeSelectChanged,

  // Update Restricted Security Request
  [START_UPDATE_RESTRICTED_SECURITY_REQUEST]: startUpdateRestrictedSecurityRequest,
  [UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS]: updateRestrictedSecurityRequestSuccess,
  [UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE]: updateRestrictedSecurityRequestFailure,
  [INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initUpdateRestrictedSecurityRequestActionStatus,

  // add
  [START_ADD_RESTRICTED_SECURITY_REQUEST]: startAddRestrictedSecurityRequest,
  [ADD_RESTRICTED_SECURITY_REQUEST_SUCCESS]: addRestrictedSecurityRequestSuccess,
  [ADD_RESTRICTED_SECURITY_REQUEST_FAILURE]: addRestrictedSecurityRequestFailure,
  [INIT_ADD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initAddRestrictedSecurityRequestActionStatus,

  // Show Valid Restricted Rules
  [SHOW_VALID_RESTRICTED_RULES]: showValidRestrictedRules,

  // Show All Restricted Rules
  [SHOW_ALL_RESTRICTED_RULES]: showAllRestrictedRules,

  //Restricte rule
  [LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS]: loadRestrictedRuleListSuccess,
  [LOAD_RESTRICTED_SECURITIES_RULE_FAILURE]: loadRestrictedRuleListFailure,
  [ADD_RESTRICTED_SECURITIES_RULE_SUCCESS]: addRestrictedRuleSuccess,
  [ADD_RESTRICTED_SECURITIES_RULE_FAILURE]: addRestrictedRuleFailure,
  [UPDATE_RESTRICTED_SECURITIES_RULE_SUCCESS]: updateRestrictedRuleSuccess,
  [UPDATE_RESTRICTED_SECURITIES_RULE_FAILURE]: updateRestrictedRuleFailure,
  [RESTRICTED_RULE_INPUT_CHANGE]: restrictedRuleEditChangeInput,
  [OPEN_RESTRICTED_RULE_DIALOG]: openRestrictedRuleDialog,
  [CLOSE_RESTRICTED_RULE_DIALOG]: closeRestrictedRuleDialog,
  [OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG]: openRestrictedRuleFundBookDialog,
  [CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG]: closeRestrictedRuleFundBookDialog,
});