import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from './GridColumnMap';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import moment from 'moment';
import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_BROKER_REVIEW_INFO,
    LOAD_BROKER_REVIEW_INFO_SUCCESS,
    LOAD_BROKER_REVIEW_INFO_FAILURE,
    INIT_LOAD_BROKER_REVIEW_INFO_ACTION_STATUS,
    // select
    SELECT_BROKER_REVIEW_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_BROKER_REVIEW_MODAL,
    ADD_OR_EDIT_BROKER_REVIEW_CHANGE_INPUT,
    START_ADD_OR_EDIT_BROKER_REVIEW,
    ADD_OR_EDIT_BROKER_REVIEW_SUCCESS,
    ADD_OR_EDIT_BROKER_REVIEW_FAILURE,
    INIT_ADD_OR_EDIT_BROKER_REVIEW_ACTION_STATUS,
    RESET_ADD_OR_EDIT_BROKER_REVIEW_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_BROKER_REVIEW_MODAL,
    DEL_BROKER_REVIEW_CHANGE_INPUT,
    START_DEL_BROKER_REVIEW,
    DEL_BROKER_REVIEW_SUCCESS,
    DEL_BROKER_REVIEW_FAILURE,
    INIT_DEL_BROKER_REVIEW_ACTION_STATUS,
    RESET_DEL_BROKER_REVIEW_MODAL,

    // del
    OPEN_COP_BROKER_REVIEW_MODAL,
    COP_BROKER_REVIEW_CHANGE_INPUT,
    START_COP_BROKER_REVIEW,
    COP_BROKER_REVIEW_SUCCESS,
    COP_BROKER_REVIEW_FAILURE,
    INIT_COP_BROKER_REVIEW_ACTION_STATUS,
    RESET_COP_BROKER_REVIEW_MODAL,
    OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,
    RESTRICTED_RULE_INPUT_CHANGE,

    
    LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
    LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,

} from './BrokerReviewConstants';

import {
    // Load Security Tickers
    LOAD_SECURITY_TICKERS_SUCCESS,
  
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  
  } from '../../home/homeConstants';
const Option = Select.Option;

const statusOptions = [];
statusOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);
statusOptions.push(<Option key={'Analyst Marketing'}>{'Analyst Marketing'}</Option>);
statusOptions.push(<Option key={'Responsiveness'}>{'Responsiveness'}</Option>);
statusOptions.push(<Option key={'Industry Expert'}>{'Industry Expert'}</Option>);
statusOptions.push(<Option key={'Corporate Meeting'}>{'Corporate Meeting'}</Option>);
statusOptions.push(<Option key={'Individual Investor Tour'}>{'Individual Investor Tour'}</Option>);
statusOptions.push(<Option key={'Conference Access'}>{'Conference Access'}</Option>);
statusOptions.push(<Option key={'Non Deal Roadshow'}>{'Non Deal Roadshow'}</Option>);
statusOptions.push(<Option key={'Non Deal Fieldtrip'}>{'Non Deal Fieldtrip'}</Option>);
statusOptions.push(<Option key={'ECM Banking'}>{'ECM Banking'}</Option>);
statusOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const brokerCodeOptions = [];
brokerCodeOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);
brokerCodeOptions.push(<Option key={'HSBC'}>{'HSBC'}</Option>);
brokerCodeOptions.push(<Option key={'MS'}>{'MS'}</Option>);
brokerCodeOptions.push(<Option key={'BAML'}>{'BAML'}</Option>);




const fundFirmRelatedOptions = [];

const ccyOptions = [];

const initialState = {

    brokerReviewUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.BROKER_REVIEW_UPLOAD_URL,
    brokerReviewDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.BROKER_REVIEW_DOWNLOAD_URL,
    brokerReviewGridColumns: GridColumnMap.brokerReviewGridColumns,

    statusOptions: statusOptions,
    brokerCodeOptions:brokerCodeOptions,
    fundFirmRelatedOptions: fundFirmRelatedOptions,
    ccyOptions: ccyOptions,

    brokerReviewQueryFields: {
        userName: '',
        status: ''
    },
    selectedBrokerReviewInfoArray: [],
    restrictedRuleList: [],
    addOrEditBrokerReviewModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            category: '',
            firm: '',
            fundFirmRelated: '',
            invDate: '',
            invNo: '',
            ccy: '',
            amount: '',
            fx: '',
            amtUSD: '',
            payDate: '',
            nature: '',
            remarks: '',
            payMethod: '',
            comments: '',
        },
    },
    delBrokerReviewModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
        }
    },
    copBrokerReviewModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
            employee: null,
            comments: '',

        }
    },
    // Load User Account
      userAccountArray: [],
   //   userAccountOptions: [],
    restrictedRuleEditControlModal: {
        isOpened: false,
        respErrMsg: null,
        isAddMode: null,
        isFundBookOpened: false,
        fields:{

        },
        fundBookFields:{

        },
        queryParams:{
            // monthStart: moment().startOf('month').format('YYYY-MM-DD'),
            // monthTo: moment().endOf('month').format('YYYY-MM-DD'),
            // currentSelected: null,
            monthStart: moment()
            .clone()
            .add('month', -3)
            .format('YYYY-MM'),
            monthTo: moment().format('YYYY-MM'),
            currentSelected: null,
        },
   }

};


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.brokerReviewQueryFields,
        [name]: value,
    };
    return {
        ...state,
        brokerReviewQueryFields: upatedFields,
    }
}
function startLoadBrokerReviewInfo(state) {
    return {
      ...state,
      loadBrokerReviewInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadBrokerReviewInfoSuccess(state, resp) {
    return {
        ...state,
        loadBrokerReviewInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadBrokerReviewInfoResp: resp,
    };
}
function loadBrokerReviewInfoFailure(state, err) {
    return {
        ...state,
        loadBrokerReviewInfoActionStatus: ACTION_STATUS.ERROR,
        loadBrokerReviewInfoResp: err,
    };
}
function initLoadBrokerReviewInfoActionStatus(state) {
    return {
        ...state,
        loadBrokerReviewInfoActionStatus: ACTION_STATUS.READY,
        selectedBrokerReviewInfoArray: [],
    };
}


// select
function selectBrokerReviewInfo(state, array) {
    return {
        ...state,
        selectedBrokerReviewInfoArray: array,
    }
}


// add or edit
function openAddOrEditBrokerReviewModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditBrokerReviewModal: {
                ...state.addOrEditBrokerReviewModal,
                isOpened: true,
                mode,
            },
        };
    } else {
        return {
            ...state,
            addOrEditBrokerReviewModal: {
                ...state.addOrEditBrokerReviewModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditBrokerReviewModal.fields,
                    id: data.id,
                    cpty: data.cpty,
                    functiontype: data.functiontype,
                    usertype: data.usertype,
                    fundandcompany: data.fundandcompany,
                    employee: data.employee,
                    status: data.status,
                   
                    remarks: data.remarks,
                }
            },
        };
    }
}
function addOrEditBrokerReviewChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditBrokerReviewModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditBrokerReviewModal: {
          ...state.addOrEditBrokerReviewModal,
          fields: updatedFields,
        },
    };
}
function startAddOrEditBrokerReview(state) {
    return {
      ...state,
      addOrEditBrokerReviewStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditBrokerReviewSuccess(state, resp) {
    return {
        ...state,
        addOrEditBrokerReviewStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditBrokerReviewFailure(state, err) {
    return {
        ...state,
        addOrEditBrokerReviewStatus: ACTION_STATUS.ERROR,
        addOrEditBrokerReviewModal: {
            ...state.addOrEditBrokerReviewModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditBrokerReviewActionStatus(state) {
    return {
        ...state,
        addOrEditBrokerReviewStatus: ACTION_STATUS.READY,
    };
}
function resetAddOrEditBrokerReviewModal(state) {
    return {
        ...state,
        addOrEditBrokerReviewModal: initialState.addOrEditBrokerReviewModal,
    }
}


// refresh fx and amtUSD
function startRefreshFx(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.LOGINING,
    };
}
function refreshFxSuccess(state, resp) {
    const fx = (!!resp && !!resp.data && !!resp.data.last) ? resp.data.last : '';
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.SUCCESS,
        addOrEditBrokerReviewModal: {
            ...state.addOrEditBrokerReviewModal,
            fields: {
                ...state.addOrEditBrokerReviewModal.fields,
                fx: fx,
            },
        }
    }
}
function refreshFxFailure(state, err) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.ERROR,
        refreshFxResp: err,
        addOrEditBrokerReviewModal: {
            ...state.addOrEditBrokerReviewModal,
            fields: {
                ...state.addOrEditBrokerReviewModal.fields,
                fx: '',
            },
        }
    }
}
function initRefreshFxActionStatus(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.READY,
    };
}
function refreshAmtUSD(state, amtUSD) {
    return {
        ...state,
        addOrEditBrokerReviewModal: {
            ...state.addOrEditBrokerReviewModal,
            fields: {
                ...state.addOrEditBrokerReviewModal.fields,
                amtUSD: amtUSD,
            },
        }
    };
}


// del
function openDelBrokerReviewModal(state, {info,mode,idList}) {
    return {
        ...state,
        delBrokerReviewModal: {
            ...state.delBrokerReviewModal,
            isOpened: true,
            mode:mode,
            idList:idList,
            fields: {
                ...state.delBrokerReviewModal.fields,
                id: info.id,
                
            }
        }
    }
}
function delBrokerReviewChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.delBrokerReviewModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delBrokerReviewModal: {
          ...state.delBrokerReviewModal,
          fields: updatedFields,
        },
    };
}
function startDelBrokerReview(state) {
    return {
      ...state,
      delBrokerReviewStatus: ACTION_STATUS.LOGINING,
    };
}
function delBrokerReviewSuccess(state, resp) {
    return {
        ...state,
        delBrokerReviewStatus: ACTION_STATUS.SUCCESS,
    };
}
function delBrokerReviewFailure(state, err) {
    return {
        ...state,
        delBrokerReviewStatus: ACTION_STATUS.ERROR,
        delBrokerReviewModal: {
            ...state.delBrokerReviewModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelBrokerReviewActionStatus(state) {
    return {
        ...state,
        delBrokerReviewStatus: ACTION_STATUS.READY,
    };
}
function resetDelBrokerReviewModal(state) {
    return {
        ...state,
        delBrokerReviewModal: initialState.delBrokerReviewModal,
    }
}



// cop
function openCopBrokerReviewModal(state, payload) {
    return {
        ...state,
        copBrokerReviewModal: {
            ...state.copBrokerReviewModal,
            isOpened: true,
            fields: {
                ...state.copBrokerReviewModal.fields,
                id: payload.id,
            }
        }
    }
}
function copBrokerReviewChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.copBrokerReviewModal.fields,
        [name]: value,
    };
    return {
        ...state,
        copBrokerReviewModal: {
          ...state.copBrokerReviewModal,
          fields: updatedFields,
        },
    };
}
function startCopBrokerReview(state) {
    return {
      ...state,
      copBrokerReviewStatus: ACTION_STATUS.LOGINING,
    };
}
function copBrokerReviewSuccess(state, resp) {
    return {
        ...state,
        copBrokerReviewStatus: ACTION_STATUS.SUCCESS,
    };
}
function copBrokerReviewFailure(state, err) {
    return {
        ...state,
        copBrokerReviewStatus: ACTION_STATUS.ERROR,
        copBrokerReviewModal: {
            ...state.copBrokerReviewModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initCopBrokerReviewActionStatus(state) {
    return {
        ...state,
        copBrokerReviewStatus: ACTION_STATUS.READY,
    };
}
function resetCopBrokerReviewModal(state) {
    return {
        ...state,
        copBrokerReviewModal: initialState.copBrokerReviewModal,
    }
}



// Load User Account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
  
    return {
      ...state,
      userAccountArray,
      userAccountOptions,
    };
  }
  
  export function openRestrictedRuleFundBookDialog(state) {
    let updatedFields = {
      ...state.restrictedRuleEditControlModal,
      isFundBookOpened: true,
      fundBookFields:{},
    };
    return {
      ...state,
      restrictedRuleEditControlModal: updatedFields
    };
  }
  
  export function closeRestrictedRuleFundBookDialog(state) {
    let updatedFields = {
      ...state.restrictedRuleEditControlModal,
      isFundBookOpened: false,
      fundBookFields:{},
    };
    return {
      ...state,
      restrictedRuleEditControlModal: updatedFields
    };
  }



  function restrictedRuleEditChangeInput(state, { name, value }) {
    let updatedFields = {
      ...state.restrictedRuleEditControlModal,
      [name]: value,
    };
    return {
      ...state,
      restrictedRuleEditControlModal: updatedFields
    };
  }
  
  
  function loadRestrictedRuleListSuccess(state, resp) {
    return {
      ...state,
      restrictedRuleList: resp.data,
    };
  }
  
  function loadRestrictedRuleListFailure(state, err) {
    return {
      ...state
    };
  }

export default createReducer(initialState, {
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_BROKER_REVIEW_INFO]: startLoadBrokerReviewInfo,
    [LOAD_BROKER_REVIEW_INFO_SUCCESS]: loadBrokerReviewInfoSuccess,
    [LOAD_BROKER_REVIEW_INFO_FAILURE]: loadBrokerReviewInfoFailure,
    [INIT_LOAD_BROKER_REVIEW_INFO_ACTION_STATUS]: initLoadBrokerReviewInfoActionStatus,
    // select
    [SELECT_BROKER_REVIEW_INFO]: selectBrokerReviewInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_BROKER_REVIEW_MODAL]: openAddOrEditBrokerReviewModal,
    [ADD_OR_EDIT_BROKER_REVIEW_CHANGE_INPUT]: addOrEditBrokerReviewChangeInput,
    [START_ADD_OR_EDIT_BROKER_REVIEW]: startAddOrEditBrokerReview,
    [ADD_OR_EDIT_BROKER_REVIEW_SUCCESS]: addOrEditBrokerReviewSuccess,
    [ADD_OR_EDIT_BROKER_REVIEW_FAILURE]: addOrEditBrokerReviewFailure,
    [INIT_ADD_OR_EDIT_BROKER_REVIEW_ACTION_STATUS]: initAddOrEditBrokerReviewActionStatus,
    [RESET_ADD_OR_EDIT_BROKER_REVIEW_MODAL]: resetAddOrEditBrokerReviewModal,
    // refresh fx and amtUSD
    [START_REFRESH_FX]: startRefreshFx,
    [REFRESH_FX_SUCCESS]: refreshFxSuccess,
    [REFRESH_FX_FAILURE]: refreshFxFailure,
    [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
    [REFRESH_AMT_USD]: refreshAmtUSD,
    // del
    [OPEN_DEL_BROKER_REVIEW_MODAL]: openDelBrokerReviewModal,
    [DEL_BROKER_REVIEW_CHANGE_INPUT]: delBrokerReviewChangeInput,
    [START_DEL_BROKER_REVIEW]: startDelBrokerReview,
    [DEL_BROKER_REVIEW_SUCCESS]: delBrokerReviewSuccess,
    [DEL_BROKER_REVIEW_FAILURE]: delBrokerReviewFailure,
    [INIT_DEL_BROKER_REVIEW_ACTION_STATUS]: initDelBrokerReviewActionStatus,
    [RESET_DEL_BROKER_REVIEW_MODAL]: resetDelBrokerReviewModal,


    // cop
    [OPEN_COP_BROKER_REVIEW_MODAL]: openCopBrokerReviewModal,
    [COP_BROKER_REVIEW_CHANGE_INPUT]: copBrokerReviewChangeInput,
    [START_COP_BROKER_REVIEW]: startCopBrokerReview,
    [COP_BROKER_REVIEW_SUCCESS]: copBrokerReviewSuccess,
    [COP_BROKER_REVIEW_FAILURE]: copBrokerReviewFailure,
    [INIT_COP_BROKER_REVIEW_ACTION_STATUS]: initCopBrokerReviewActionStatus,
    [RESET_COP_BROKER_REVIEW_MODAL]: resetCopBrokerReviewModal,


    // Load User Account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

    [OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG]: openRestrictedRuleFundBookDialog,
    [CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG]: closeRestrictedRuleFundBookDialog,
    [RESTRICTED_RULE_INPUT_CHANGE]: restrictedRuleEditChangeInput,



    [LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS]: loadRestrictedRuleListSuccess,
    [LOAD_RESTRICTED_SECURITIES_RULE_FAILURE]: loadRestrictedRuleListFailure,
});