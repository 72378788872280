
export const QUESTIONNAIRE_TYPE_CODE = {
  DECLARATION: 'DECLARATION',
  QUIZ: 'QUIZ',
};

const QUESTIONNAIRE_TYPE_LABEL = {
  DECLARATION: 'Declaration',
  QUIZ: 'Quiz',
};

export const QUESTIONNAIRE_TYPE_MAP = [
  // Quarter
  {
    code: QUESTIONNAIRE_TYPE_CODE.DECLARATION,
    label: QUESTIONNAIRE_TYPE_LABEL.DECLARATION
  },
  // Quiz
  {
    code: QUESTIONNAIRE_TYPE_CODE.QUIZ,
    label: QUESTIONNAIRE_TYPE_LABEL.QUIZ
  },
];