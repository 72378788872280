import {
  // Load PB Short Interest Rate
  START_LOAD_PB_SHORT_INTEREST_RATE,
  LOAD_PB_SHORT_INTEREST_RATE_SUCCESS,
  LOAD_PB_SHORT_INTEREST_RATE_FAILURE,
  INIT_LOAD_PB_SHORT_INTEREST_RATE_ACTION_STATUS,
} from './pbShortInterestRateConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load  PB Short Interest Rate
function startLoadPBShortInterestRate() {
  return {
    type: START_LOAD_PB_SHORT_INTEREST_RATE
  };
}

export function loadPBShortInterestRate(dateString) {
  const requestParam = {
    'date': dateString,
  };

  return function(dispatch) {
    dispatch(startLoadPBShortInterestRate());
    httpClient
      .get(UrlConfig.QUERY_PB_SHORT_INTEREST_RATE, requestParam)
      .then(resp => dispatch(loadPBShortInterestRateSuccess(resp)))
      .catch(err => dispatch(loadPBShortInterestRateFailure(err)));
  };
}

function loadPBShortInterestRateSuccess(resp) {
  return {
    type: LOAD_PB_SHORT_INTEREST_RATE_SUCCESS,
    payload: resp
  };
}

function loadPBShortInterestRateFailure(err) {
  return {
    type: LOAD_PB_SHORT_INTEREST_RATE_FAILURE,
    payload: err
  };
}

export function initLoadPBShortInterestRateActionStatus() {
  return {
    type: INIT_LOAD_PB_SHORT_INTEREST_RATE_ACTION_STATUS
  };
}