
export const PRICE_TYPE_CODE = {
  MARKET: 'MARKET',
  LIMIT: 'LIMIT',
};

const PRICE_TYPE_LABEL = {
  MARKET: 'Market',
  LIMIT: 'Limit',
};

export const PRICE_TYPE_MAP = [
  // Market
  {
    code: PRICE_TYPE_CODE.MARKET,
    label: PRICE_TYPE_LABEL.MARKET
  },
  // Limit
  {
    code: PRICE_TYPE_CODE.LIMIT,
    label: PRICE_TYPE_LABEL.LIMIT
  },
];