import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

const validDataStatus = ['Submitted', 'Approved', 'FinalApprRequest', 'FinalApproved'];

class AccountHoldingGrid extends Component {

    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
                {field: 'id', headerName: 'dataID', cellClass: 'non-number', width: 110, cellRenderer: 'agGroupCellRenderer', checkboxSelection: true},
                {field: 'employee', headerName: 'employee', cellClass: 'non-number', width: 120},
                {field: 'accountName', headerName: 'accountHolder', cellClass: 'non-number', width: 150},
                {field: 'brokerAccount', headerName: 'brokerageFirm', cellClass: 'non-number', width: 150},
                {field: 'accountNo', headerName: 'accountNo', cellClass: 'non-number', width: 150},
                {field: 'relationship', headerName: 'relationship', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.relationshipRenderer(node, this.props.relationshipArray)},
                {field: 'approvalStatus', headerName: 'approvalStatus', cellClass: 'non-number', width: 120, cellStyle: this.approvalStatusCellStyle},
                {field: 'processedReason', headerName: 'reasonForOpening', cellClass: 'non-number', width: 200},
                {field: 'relationshipDesc', headerName: 'remarks', cellClass: 'non-number', width: 200},
                {field: 'feedback', headerName: 'feedback', cellClass: 'non-number', width: 200},
                {field: 'attachment', headerName: 'attachment', cellClass: 'non-number', width: 150, cellRenderer: (node) => this.downloadFileCellRenderer(node, 'AccountInfoProof', this.props.personalAccountDownloadUrl)},
                {field: 'needComplianceLetter', headerName: 'needComplianceLetter', cellClass: 'non-number', width: 80},
                {field: 'complianceLetterFileName', headerName: 'complianceLetter', cellClass: 'non-number', width: 150, cellRenderer: (node) => this.downloadFileCellRenderer(node, 'ComplianceLetter', this.props.personalAccountDownloadUrl)},
                {field: 'createdDate', headerName: 'createdDate', cellClass: 'non-number', width: 150},
                {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 150},
                {field: 'lastModifiedDate', headerName: 'lastModifiedDate', cellClass: 'non-number', width: 150},
                {field: 'lastModifiedBy', headerName: 'lastModifiedBy', cellClass: 'non-number', width: 150},
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50,
            },
            rowSelection: 'single',
            context: { componentParent: this },
            rowClassRules: {
                'MarkBackgroundGreen': function(params) {
                    return params.data.approvalStatus === 'Submitted' || params.data.approvalStatus === 'FinalApprRequest';
                },
            },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'employee', headerName: 'employee', cellClass: 'non-number', width: 150, checkboxSelection: true, headerCheckboxSelection: true},
                        {field: 'bbgTicker', headerName: 'bbgTicker', cellClass: 'non-number', width: 150 },
                        {field: 'secName', headerName: 'secName', cellClass: 'non-number', width: 200 },
                        {field: 'category', headerName: 'category', cellClass: 'non-number', width: 200 },
                        {field: 'direction', headerName: 'direction', cellClass: 'non-number', width: 100 },
                        {field: 'quantity', headerName: 'quantity', cellClass: 'number', width: 100 },
                        {field: 'price', headerName: 'price', cellClass: 'number', width: 100 },
                        {field: 'amount', headerName: 'amount', cellClass: 'number', width: 100 },
                        {field: 'currency', headerName: 'currency', cellClass: 'non-number', width: 100 },
                        {field: 'createdDate', headerName: 'createdDate', cellClass: 'non-number', width: 150},
                        {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 150},
                        {field: 'lastModifiedDate', headerName: 'lastModifiedDate', cellClass: 'non-number', width: 150},
                        {field: 'lastModifiedBy', headerName: 'lastModifiedBy', cellClass: 'non-number', width: 150},
                        
                    ],
                    rowSelection: 'multiple',
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(node) {
                        let rowNodeArray = node.api.getSelectedNodes();
                        let personalHoldingList = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectPersonalHoldingList(personalHoldingList);
                    },
                    onGridReady(event){
                        that.props.setDetailGridEventOnState(event);
                    },

                },

                getDetailRowData: function(params) {
                    params.successCallback(params.data.personalHoldingList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">Holdings</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                "</div>"
            },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                ]
            },
        };
    }


    relationshipRenderer = (node, relationshipArray) => {
        const idx = parseInt(node.data.relationship);
        if(!!idx || idx === 0) {
            const relationship = relationshipArray[idx];
            return !!relationship ? relationship : 'Unknown';
        }
        return 'Unknown';
    }


    approvalStatusCellStyle = (node) => {
        const status = node.value;
        if(status === 'Approved' || status === 'FinalApproved') {
            return {color: 'green'};
        }
        if(status === 'Rejected') {
            return {color: 'red'};
        }
        return {color: 'black'};
    }


    downloadFileCellRenderer = (node, attType, baseDownloadUrl) => {
        const uploadOriName = attType === 'AccountInfoProof' ? node.data.attachmentOriginName : node.data.complianceLetterOriName;
        const uploadRealName = attType === 'AccountInfoProof' ? node.data.attachment : node.data.complianceLetterFileName;
        if (!!uploadOriName && !!uploadRealName) {
            const url = baseDownloadUrl + '/' + uploadRealName;
            let link = document.createElement('a');
            link.innerText = uploadOriName;
            link.target = '_blank';
            link.href = url;
            return link;
        }
        return '';
    }


    getRowNodeId = data => {
        return data.id;
    };


    onSelectionChanged = (node) => {
        let rowNodeArray = node.api.getSelectedNodes();
        let personalAccountList = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectPersonalAccountList(personalAccountList);
    }

    onGridReady = (event) => {
        this.props.setGridEventOnState(event);
    }


    render() {
        const {
            loadPersonalAccountActionStatus,
            personalAccountArray,
            onlyShowValidData,
        } = this.props;
        
        const isLoading = loadPersonalAccountActionStatus === ACTION_STATUS.LOGINING;
        let presentDataArray = !!personalAccountArray ? personalAccountArray : [];
        if(onlyShowValidData) {
            presentDataArray = presentDataArray.filter(a => validDataStatus.filter(s => s === a.approvalStatus).length > 0);
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={presentDataArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            rowClassRules={this.state.rowClassRules}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onSelectionChanged}
                        />
                    )}
            </div>
        );
    }

}


export default AccountHoldingGrid;
