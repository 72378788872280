import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker,message, Select, Button, Checkbox ,Tooltip,Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditPortfolioMonitoringRuleModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            startDate,
            endDate,
        } = this.props.portfolioMonitoringRuleQueryFields;
        this.props.loadPortfolioMonitoringRuleInfo({startDate,endDate});
    }

    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditPortfolioMonitoringRuleStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditPortfolioMonitoringRuleModal;

        if(addOrEditPortfolioMonitoringRuleStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSavePortfolioMonitoringRuleActionStatus();
            this.props.resetSubmitSavePortfolioMonitoringRuleModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditPortfolioMonitoringRuleStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSavePortfolioMonitoringRuleActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        
        this.props.addOrEditPortfolioMonitoringRuleChangeInput({name, value});
    }

    onAssetTypeInputChange = ({name, value}) => {
        let assetTypeStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!assetTypeStr) {
                    assetTypeStr += ',';
                }
                assetTypeStr += e;
            });
        }
        this.onInputChange({name, value: assetTypeStr});
    }







    submitSavePortfolioMonitoringRuleModal = () => {
        const {
            mode,
        } = this.props.addOrEditPortfolioMonitoringRuleModal;
        const {
            id,
            category,
            ruleName,
            tag,
            ruleStatus,
            ruleType,
            imIa,
            fund,
            market,
            assetType,
            direction,
            unitValue,
            sumOf,
            latestValue,
            alertValue,
            alertInCurrency,
            alertPercent,
            alertNumerator,
            alertDenominator,
            limitValue,
            limitInCurrency,
            limitPercent,
            limitNumerator,
            limitDenominator,
            descriptionReference,
            effectiveDate,
            remarks,
            comments,
            

        } = this.props.addOrEditPortfolioMonitoringRuleModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 执行提交
            this.props.submitSavePortfolioMonitoringRule({
                id,
                category,
                ruleName,
                tag,
                ruleStatus,
                ruleType,
                imIa,
                fund,
                market,
                assetType,
                direction,
                unitValue,
                sumOf,
                latestValue,
                alertValue,
                alertInCurrency,
                alertPercent,
                alertNumerator,
                alertDenominator,
                limitValue,
                limitInCurrency,
                limitPercent,
                limitNumerator,
                limitDenominator,
                descriptionReference,
                effectiveDate,
                remarks,
                comments,
                mode,
            });

        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
        
        categoryOptions,
        statusOptions,
        ruleTypeOptions,
        imIaOptions,
        fundOptions,
        assetTypeOptions,
        directionOptions,
        unitValueOptions,
        sumOfOptions,
        numeratorOptions,
        denominatorOptions,
        
        inCurrencyOptions,
        marketOptions,
        userAccountOptions,

        addOrEditPortfolioMonitoringRuleModal,
        addOrEditPortfolioMonitoringRuleStatus,



        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditPortfolioMonitoringRuleModal;

        const {
            id,
            category,
            ruleName,
            tag,
            ruleStatus,
            ruleType,
            imIa,
            fund,
            market,
            assetType,
            direction,
            unitValue,
            sumOf,
            latestValue,
            alertValue,
            alertInCurrency,
            alertPercent,
            alertNumerator,
            alertDenominator,
            limitValue,
            limitInCurrency,
            limitPercent,
            limitNumerator,
            limitDenominator,
            descriptionReference,
            effectiveDate,

            remarks,
            comments,


        } = addOrEditPortfolioMonitoringRuleModal.fields;

        //  const assetType = !assetTypeArry ? [] : assetTypeArry.split(';');


        const monthDateOptions = [];


        const startYearOptions = [];



        const modalTitle = this.getActionFromMode(mode) + ' Portfolio Monitoring';
        const isSubmitLoading = addOrEditPortfolioMonitoringRuleStatus === ACTION_STATUS.LOGINING;
        const effectiveDateMent = !effectiveDate ? undefined : moment(effectiveDate);
        // const targetdatement = !targetdate ? undefined : moment(targetdate);
        // const enddatement = !enddate ? undefined : moment(enddate);

        
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSavePortfolioMonitoringRuleModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSavePortfolioMonitoringRuleModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSavePortfolioMonitoringRuleModal() }>
                          Save
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Category">
                                    {getFieldDecorator('category', {
                                        initialValue: category,
                                        rules: [{
                                            required: true,
                                            message: 'Please select category',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'category', value: val })
                                            }} 
                                            placeholder="Please select category">
                                                {categoryOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Rule Name">
                                    {getFieldDecorator('ruleName', {
                                        initialValue: ruleName,
                                        rules: [{
                                            required: true,
                                            message: 'Please input ruleName',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='ruleName' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'ruleName', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Tag">
                                    {getFieldDecorator('tag', {
                                        initialValue: tag,
                                        rules: [{
                                            required: true,
                                            message: 'Please input tag',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='tag' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'tag', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                    {getFieldDecorator('ruleStatus', {
                                        initialValue: ruleStatus,
                                        rules: [{
                                            required: true,
                                            message: 'Please select fundco',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'ruleStatus', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                                {statusOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>                
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="Rule Type">
                                    {getFieldDecorator('ruleType', {
                                        initialValue: ruleType,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Rule Type',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'ruleType', value: val })
                                            }} 
                                            placeholder="Please select Rule Type">
                                                {ruleTypeOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>  
                        <Row gutter={24}>                
                        
                        <Col span={50}>
                                
                                <p2>---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p2>
                           
                            </Col>
                        </Row>  
                        <Row gutter={24}>

                            <Col span={12}>
                                <FormItem {...formItemLayout} label="IM/IA">
                                    {getFieldDecorator('imIa', {
                                        initialValue: imIa,
                                        rules: [{
                                            required: true,
                                            message: 'Please select IM/IA',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'imIa', value: val })
                                            }} 
                                            placeholder="Please select  IM/IA">
                                                {imIaOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Fund">
                                    {getFieldDecorator('fund', {
                                        initialValue: fund,
                                        rules: [{
                                            required: true,
                                            message: 'Please select fund',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            mode='multiple'
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onAssetTypeInputChange({ name: 'fund', value: val })
                                            }} 
                                            placeholder="Please select Fund">
                                                {fundOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Market">
                                    {getFieldDecorator('market', {
                                        initialValue: market,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Market',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'market', value: val })
                                            }} 
                                            placeholder="Please select Market">
                                                {marketOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Asset Type">
                                    {getFieldDecorator('assetType', {
                                        initialValue: assetType,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Asset Type',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            mode='multiple'
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onAssetTypeInputChange({ name: 'assetType', value: val })
                                            }} 
                                            placeholder="Please select Asset Type">
                                                {assetTypeOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Direction">
                                    {getFieldDecorator('direction', {
                                        initialValue: direction,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Direction',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'direction', value: val })
                                            }} 
                                            placeholder="Please select Direction">
                                                {directionOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Unit Value">
                                    {getFieldDecorator('unitValue', {
                                        initialValue: unitValue,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Unit Value',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'unitValue', value: val })
                                            }} 
                                            placeholder="Please select Unit Value">
                                                {unitValueOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Sum of">
                                    {getFieldDecorator('sumOf', {
                                        initialValue: sumOf,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Sum of',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'sumOf', value: val })
                                            }} 
                                            placeholder="Please select Sum of">
                                                {sumOfOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>                
                        
                        <Col span={50}>
                                
                                <p2>---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p2>
                                <p2>Compliance Alert:</p2>
                            </Col>
                        </Row> 
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Value">
                                    {getFieldDecorator('alertValue', {
                                        initialValue: alertValue,
                                        rules: [{
                                            required: false,
                                            message: 'Please select Unit Value',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='alertValue' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'alertValue', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="In currency">
                                    {getFieldDecorator('alertInCurrency', {
                                        initialValue: alertInCurrency,
                                        rules: [{
                                            required: false,
                                            message: 'Please select In currency',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'alertInCurrency', value: val })
                                            }} 
                                            placeholder="Please select In currency">
                                                {inCurrencyOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>  

                        <Row gutter={24}> 
                            <Col span={12}>
                                <FormItem {...formItemLayout} label=" % ">
                                    {getFieldDecorator('alertPercent', {
                                        initialValue: alertPercent,
                                        rules: [{
                                            required: false,
                                            message: 'Please input alertPercent',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='alertPercent' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'alertPercent', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            </Row>
                            <Row gutter={24}>  
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="of (Alert Numerator)">
                                    {getFieldDecorator('alertNumerator', {
                                        initialValue: alertNumerator,
                                        rules: [{
                                            required: false,
                                            message: 'Please select Numerator',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'alertNumerator', value: val })
                                            }} 
                                            placeholder="Please select Numerator">
                                                {numeratorOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="of (Alert Denominator)">
                                    {getFieldDecorator('alertDenominator', {
                                        initialValue: alertDenominator,
                                        rules: [{
                                            required: false,
                                            message: 'Please select  Denominator',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'alertDenominator', value: val })
                                            }} 
                                            placeholder="Please select Denominator">
                                                {denominatorOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>                
                        
                        <Col span={50}>
                                
                                <p2>---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p2>
                                <p2>Compliance Limit:</p2>
                            </Col>
                        </Row> 
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Value">
                                    {getFieldDecorator('limitValue', {
                                        initialValue: limitValue,
                                        rules: [{
                                            required: false,
                                            message: 'Please select limitValue',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='limitValue' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'limitValue', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="In currency">
                                    {getFieldDecorator('limitInCurrency', {
                                        initialValue: limitInCurrency,
                                        rules: [{
                                            required: false,
                                            message: 'Please select In currency',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'limitInCurrency', value: val })
                                            }} 
                                            placeholder="Please select In currency">
                                                {inCurrencyOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>  

                        <Row gutter={24}> 
                            <Col span={12}>
                                <FormItem {...formItemLayout} label=" % ">
                                    {getFieldDecorator('limitPercent', {
                                        initialValue: limitPercent,
                                        rules: [{
                                            required: false,
                                            message: 'Please input limitPercent',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='limitPercent' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'limitPercent', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            </Row>
                            <Row gutter={24}>  
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="of (Limit Numerator)">
                                    {getFieldDecorator('limitNumerator', {
                                        initialValue: limitNumerator,
                                        rules: [{
                                            required: false,
                                            message: 'Please select Numerator',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'limitNumerator', value: val })
                                            }} 
                                            placeholder="Please select Numerator">
                                                {numeratorOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="of (Limit Denominator)">
                                    {getFieldDecorator('limitDenominator', {
                                        initialValue: limitDenominator,
                                        rules: [{
                                            required: false,
                                            message: 'Please select  Denominator',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'limitDenominator', value: val })
                                            }} 
                                            placeholder="Please select Denominator">
                                                {denominatorOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row> 
                        <Row gutter={24}>                
                        
                        <Col span={50}>
                                
                                <p2>---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p2>
                               
                            </Col>
                        </Row>                 
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Description/Reference">
                                    {getFieldDecorator('descriptionReference', {
                                        initialValue: descriptionReference,
                                        rules: [{
                                            required: false,
                                            message: 'Please input DescriptionReference',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'descriptionReference', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Effective Date">
                                {getFieldDecorator('effectiveDate', {
                                    initialValue: effectiveDateMent,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Effective Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'effectiveDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        {/* <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Start Date">
                                {getFieldDecorator('startdate', {
                                    initialValue: startdatement,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Start Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'startdate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="End Date">
                                {getFieldDecorator('enddate', {
                                    initialValue: enddatement,
                                    rules: [{
                                        required: false,
                                        message: 'Please select End Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'enddate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                    {getFieldDecorator('status', {
                                        initialValue: status,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Status',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'status', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                                {statusOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Importance">
                                    {getFieldDecorator('importance', {
                                        initialValue: importance,
                                        rules: [{
                                            required: true,
                                            message: 'Please select importance',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'importance', value: val })
                                            }} 
                                            placeholder="Please select importance">
                                                {importanceOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row> */}


                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditPortfolioMonitoringRuleModalDialogForm = Form.create({ name: 'AddOrEditPortfolioMonitoringRuleModalDialog' })(AddOrEditPortfolioMonitoringRuleModalDialog);

export default AddOrEditPortfolioMonitoringRuleModalDialogForm;
