import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';

class TradesReviewReportGrid extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          columnDefs: props.tradesReviewReportGridColumns,
          defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 100
          },
          groupDefaultExpanded: 0,
        };
    }


    onGridReady = grid => {
        this.props.addTradesReviewReportGridToProps(grid);
    }


    render() {
        const {
            queryTradesReviewReportActionStatus,
            queryTradesReviewReportActionResp,
        } = this.props;

        const isLoading = queryTradesReviewReportActionStatus === ACTION_STATUS.LOGINING;
        let dataArray = [];
        if(queryTradesReviewReportActionResp !== undefined && queryTradesReviewReportActionResp !== null && queryTradesReviewReportActionResp.respCode === 'S0001') {
            dataArray = queryTradesReviewReportActionResp.data;
        }
    

        return (
            <div className={`ag-theme-balham grid-wrapper`}>
                {isLoading ? (
                    <Loader active content="Loading..." />
                ) : (
                <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    rowData={dataArray}
                    defaultColDef={this.state.defaultColDef}
                    enableSorting={true}
                    enableFilter={true}
                    animateRows={true}
                    deltaRowDataMode={false}
                    enableColResize={true}
                    resizable={true}
                    // event
                    onGridReady={this.onGridReady}
                />
                )}
            </div>
        );
    }

}

export default TradesReviewReportGrid;
