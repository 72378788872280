import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, DatePicker, Upload, Icon } from 'antd';


class GroupStructureDetailDialog extends Component {

    componentDidUpdate() {

    }

    render() {
        const {
            groupStructureDownloadUrl,
        } = this.props;
        const {
            isOpened,
            fields,
        } = this.props.groupStructureDetailDialog;
        const {
            id,
            chartName,
            nature,
            usage,
            uploadChartOriName,
            uploadChartRealName,
            uploadPdfRealName,
            remarks,
        } = fields;


        return (
            <div>
                <Modal
                    title={'Group Structure Detail'}
                    centered
                    width={1300}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetGroupStructureDetailDialog()}
                    footer={[
                        <Button key="Close" onClick={() => this.props.resetGroupStructureDetailDialog() }>
                          Close
                        </Button>,
                    ]}
                >
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>chartName:</b></Col>
                        <Col span={6} align={'left'}>{chartName}</Col>
                        <Col span={6} align={'right'}><b>nature:</b></Col>
                        <Col span={6} align={'left'}>{nature}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>pdf:</b></Col>
                        <Col span={6} align={'left'}>
                <a target={'_blank'} href={groupStructureDownloadUrl + '/' + uploadPdfRealName}>{uploadPdfRealName}</a>
                        </Col>
                        <Col span={6} align={'right'}><b>usage:</b></Col>
                        <Col span={6} align={'left'}>{usage}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={6} align={'right'}><b>remarks:</b></Col>
                        <Col span={18} align={'left'}>{remarks}</Col>
                    </Row>
                    <Row gutter={24} style={{marginTop: 5}}>
                        <Col span={24} align={'center'}>
                            <img src={groupStructureDownloadUrl + '/' + uploadChartRealName} 
                                    alt='Image Not Found' 
                                    style={{ marginTop: 10, maxWidth: 1200 }}
                            />
                        </Col>
                    </Row>

                </Modal>
            </div>
        );
    }

}

export default GroupStructureDetailDialog;
