import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
  // Load Submitted Restricted Security Request
  QUERY_RESTRICTED_SECURITY_INPUT_CHANGE,
  START_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST,
  LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Select Restricted Security Request
  SELECT_RESTRICTED_SECURITY_REQUEST,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET,

  // Approve Restricted Security Request
  START_APPROVE_RESTRICTED_SECURITY_REQUEST,
  APPROVE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  APPROVE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_APPROVE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Reject Restricted Security Request
  START_REJECT_RESTRICTED_SECURITY_REQUEST,
  REJECT_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  REJECT_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_REJECT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Feedback Restricted Security Request
  START_FEEDBACK_RESTRICTED_SECURITY_REQUEST,
  FEEDBACK_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  FEEDBACK_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_FEEDBACK_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Open/Reset Restricted Security Operation Record Modal Dialog
  RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_RESET,

  // Load Restricted Security Operation Record
  START_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD,
  LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_SUCCESS,
  LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_ACTION_STATUS,

  // Open/Reset Restricted Security Request Edit Modal Dialog
  RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_RESET,

  // Restricted Security Request Edit Change Input
  RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT,

  // Load Restricted Security Request BBG Tickers
  START_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS,
  LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_SUCCESS,
  LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_ACTION_STATUS,

  // Clear Restricted Security Request BBG Ticker
  CLEAR_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS,

  // Restricted Security Request BBG Ticker Select Changed
  RESTRICTED_SECURITY_REQUEST_BBG_TICKER_SELECT_CHANGED,

  // Restricted Security Request Fund Code Select Changed
  RESTRICTED_SECURITY_REQUEST_FUND_CODE_SELECT_CHANGED,

  // Restricted Security Request Book Code Select Changed
  RESTRICTED_SECURITY_REQUEST_BOOK_CODE_SELECT_CHANGED,

  // Update Restricted Security Request
  START_UPDATE_RESTRICTED_SECURITY_REQUEST,
  UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // add
  START_ADD_RESTRICTED_SECURITY_REQUEST,
  ADD_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  ADD_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_ADD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Show Valid Restricted Rules
  SHOW_VALID_RESTRICTED_RULES,

  // Show All Restricted Rules
  SHOW_ALL_RESTRICTED_RULES,

  //Restricted rule
  LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
  LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,
  ADD_RESTRICTED_SECURITIES_RULE_SUCCESS,
  ADD_RESTRICTED_SECURITIES_RULE_FAILURE,
  UPDATE_RESTRICTED_SECURITIES_RULE_SUCCESS,
  UPDATE_RESTRICTED_SECURITIES_RULE_FAILURE,
  RESTRICTED_RULE_INPUT_CHANGE,
  OPEN_RESTRICTED_RULE_DIALOG,
  CLOSE_RESTRICTED_RULE_DIALOG,
  OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG,
  CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG,

} from './restrictedSecurityRequestConstants';

// Load Submitted Restricted Security Request
export function queryRestrictedSecurityInputChange(param) {
  return {
    type: QUERY_RESTRICTED_SECURITY_INPUT_CHANGE,
    payload: param,
  }
}

function startLoadSubmittedRestrictedSecurityRequest() {
  return {
    type: START_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST
  };
}

export function loadSubmittedRestrictedSecurityRequest(params) {
  return function(dispatch) {
    dispatch(startLoadSubmittedRestrictedSecurityRequest());
    httpClient
      .get(UrlConfig.LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST, params)
      .then(resp => dispatch(loadSubmittedRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(loadSubmittedRestrictedSecurityRequestFailure(err)));
  };
}

function loadSubmittedRestrictedSecurityRequestSuccess(resp) {
  return {
    type: LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function loadSubmittedRestrictedSecurityRequestFailure(err) {
  return {
    type: LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initLoadSubmittedRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_LOAD_SUBMITTED_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Select Restricted Security Request
export function selectRestrictedSecurityRequest(payload) {
  return {
    type: SELECT_RESTRICTED_SECURITY_REQUEST,
    payload
  };
}

// Open/Reset Restricted Security Request Operate Modal Dialog
export function openRestrictedSecurityRequestOperateModalDialog(operateMode, restrictedSecReqGuidList, requestStatus) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
    payload: {
      operateMode,
      restrictedSecReqGuidList,
      requestStatus,
    },
  };
}

export function resetRestrictedSecurityRequestOperateModalDialog() {
  return {
    type: RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET
  };
}

// Approve Restricted Security Request
function startApproveRestrictedSecurityRequest() {
  return {
    type: START_APPROVE_RESTRICTED_SECURITY_REQUEST
  };
}

export function approveRestrictedSecurityRequest(comments, restrictedSecReqGuidList) {
  const requestParams = {
    restrictedSecReqGuidList,
    comments,
  };

  return function(dispatch) {
    dispatch(startApproveRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.APPROVE_RESTRICTED_SECURITY_REQUEST, requestParams)
      .then(resp => dispatch(approveRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(approveRestrictedSecurityRequestFailure(err)));
  };
}

function approveRestrictedSecurityRequestSuccess(resp) {
  return {
    type: APPROVE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function approveRestrictedSecurityRequestFailure(err) {
  return {
    type: APPROVE_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initApproveRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_APPROVE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Reject Restricted Security Request
function startRejectRestrictedSecurityRequest() {
  return {
    type: START_REJECT_RESTRICTED_SECURITY_REQUEST
  };
}

export function rejectRestrictedSecurityRequest(comments, restrictedSecReqGuidList) {
  const requestParams = {
    restrictedSecReqGuidList,
    comments,
  };

  return function(dispatch) {
    dispatch(startRejectRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.REJECT_RESTRICTED_SECURITY_REQUEST, requestParams)
      .then(resp => dispatch(rejectRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(rejectRestrictedSecurityRequestFailure(err)));
  };
}

function rejectRestrictedSecurityRequestSuccess(resp) {
  return {
    type: REJECT_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function rejectRestrictedSecurityRequestFailure(err) {
  return {
    type: REJECT_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initRejectRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_REJECT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Feedback Restricted Security Request
function startFeedbackRestrictedSecurityRequest() {
  return {
    type: START_FEEDBACK_RESTRICTED_SECURITY_REQUEST
  };
}

export function feedbackRestrictedSecurityRequest(feedback, restrictedSecReqGuidList, comments) {
  const requestParams = {
    restrictedSecReqGuidList,
    feedback,
    comments,
  };

  return function(dispatch) {
    dispatch(startFeedbackRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.FEEDBACK_RESTRICTED_SECURITY_REQUEST, requestParams)
      .then(resp => dispatch(feedbackRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(feedbackRestrictedSecurityRequestFailure(err)));
  };
}

function feedbackRestrictedSecurityRequestSuccess(resp) {
  return {
    type: FEEDBACK_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function feedbackRestrictedSecurityRequestFailure(err) {
  return {
    type: FEEDBACK_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initFeedbackRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_FEEDBACK_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Open/Reset Restricted Security Operation Record Modal Dialog
export function openRestrictedSecurityOperationRecordModalDialog() {
  return {
    type: RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_OPEN,
    payload: {
    },
  };
}

export function resetRestrictedSecurityOperationRecordModalDialog() {
  return {
    type: RESTRICTED_SECURITY_OPERATION_RECORD_MODAL_DIALOG_RESET
  };
}

// Load Restricted Security Operation Record
function startLoadRestrictedSecurityOperationRecord() {
  return {
    type: START_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD
  };
}

export function loadRestrictedSecurityOperationRecord() {
  return function(dispatch) {
    dispatch(startLoadRestrictedSecurityOperationRecord());
    httpClient
      .get(UrlConfig.LOAD_RESTRICTED_SECURITY_OPERATION_RECORD)
      .then(resp => dispatch(loadRestrictedSecurityOperationRecordSuccess(resp)))
      .catch(err => dispatch(loadRestrictedSecurityOperationRecordFailure(err)));
  };
}

function loadRestrictedSecurityOperationRecordSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSecurityOperationRecordFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSecurityOperationRecordActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECURITY_OPERATION_RECORD_ACTION_STATUS
  };
}

// Open/Reset Restricted Security Request Edit Modal Dialog
export function openRestrictedSecurityRequestEditModalDialog(isAddMode, restrictedSecurityRequest) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      restrictedSecurityRequest,
      isAddMode,
    },
  };
}

export function resetRestrictedSecurityRequestEditModalDialog() {
  return {
    type: RESTRICTED_SECURITY_REQUEST_EDIT_MODAL_DIALOG_RESET
  };
}

// Restricted Security Request Edit Change Input
export function restrictedSecurityRequestEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// =================================================================
// Load Restricted Security Request BBG Tickers
function startLoadRestrictedSecurityRequestBbgTickers() {
  return {
    type: START_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS,
  };
}

export function loadRestrictedSecurityRequestBbgTickers(queryText) {
  const requestParams = {
    'query-text': queryText,
    'page': 'restrictedSecurityRequest',
    'source': 'Admin',
  };

  return function(dispatch) {
    dispatch(startLoadRestrictedSecurityRequestBbgTickers());
    httpClient
      .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
      .then(resp => dispatch(loadRestrictedSecurityRequestBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadRestrictedSecurityRequestBbgTickersFailure(err)));
  };
}

function loadRestrictedSecurityRequestBbgTickersSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSecurityRequestBbgTickersFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSecurityRequestBbgTickersActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS_ACTION_STATUS
  };
}

// Clear Restricted Security Request BBG Ticker
export function clearRestrictedSecurityRequestBbgTickers() {
  return {
    type: CLEAR_RESTRICTED_SECURITY_REQUEST_BBG_TICKERS,
  };
}

// Restricted Security Request BBG Ticker Select Changed
export function restrictedSecurityRequestBbgTickerSelectChanged(bbgTicker) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_BBG_TICKER_SELECT_CHANGED,
    payload: bbgTicker
  };
}

// Restricted Security Request Fund Code Select Changed
export function restrictedSecurityRequestFundCodeSelectChanged(fundCode) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Restricted Security Request Book Code Select Changed
export function restrictedSecurityRequestBookCodeSelectChanged(bookCode) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}

// Update Restricted Security Request
function startUpdateRestrictedSecurityRequest() {
  return {
    type: START_UPDATE_RESTRICTED_SECURITY_REQUEST
  };
}
export function updateRestrictedSecurityRequest(restrictedSecReq) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.UPDATE_RESTRICTED_SECURITY_REQUEST, restrictedSecReq)
      .then(resp => dispatch(updateRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(updateRestrictedSecurityRequestFailure(err)));
  };
}
function updateRestrictedSecurityRequestSuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}
function updateRestrictedSecurityRequestFailure(err) {
  return {
    type: UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}
export function initUpdateRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Add
function startAddRestrictedSecurityRequest() {
  return {
    type: START_ADD_RESTRICTED_SECURITY_REQUEST
  };
}
export function addRestrictedSecurityRequest(restrictedSecReq) {
  return function(dispatch) {
    dispatch(startAddRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.ADD_RESTRICTED_SECURITY_REQUEST, restrictedSecReq)
      .then(resp => dispatch(addRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(addRestrictedSecurityRequestFailure(err)));
  };
}
function addRestrictedSecurityRequestSuccess(resp) {
  return {
    type: ADD_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}
function addRestrictedSecurityRequestFailure(err) {
  return {
    type: ADD_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}
export function initAddRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_ADD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}


// Show Valid Restricted Rules
export function showValidRestrictedRules() {
  return {
    type: SHOW_VALID_RESTRICTED_RULES,
  };
}

// Show All Restricted Rules
export function showAllRestrictedRules() {
  return {
    type: SHOW_ALL_RESTRICTED_RULES,
  };
}

export function loadRestrictedRuleList(params) {
  return function(dispatch) {
    httpClient
      .post(UrlConfig.LOAD_RESTRICTED_SECURITIES_RULE, params)
      .then(resp => dispatch(loadRestrictedRuleListSuccess(resp)))
      .catch(err => dispatch(loadRestrictedRuleListFailure(err)));
  };
}
function loadRestrictedRuleListSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS,
    payload: resp
  };
}
function loadRestrictedRuleListFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITIES_RULE_FAILURE,
    payload: err
  };
}

export function addRestrictedRule(params,queryParams) {
  return function(dispatch) {
    httpClient
      .post(UrlConfig.ADD_RESTRICTED_SECURITIES_RULE, params)
      .then(resp => {
        dispatch(loadRestrictedRuleList(queryParams));
        dispatch(addRestrictedRuleSuccess(resp));
        dispatch(closeRestrictedRuleDialog());
      })
      .catch(err => dispatch(addRestrictedRuleFailure(err)));
  };
}
function addRestrictedRuleSuccess(resp) {
  return {
    type: ADD_RESTRICTED_SECURITIES_RULE_SUCCESS,
    payload: resp
  };
}
function addRestrictedRuleFailure(err) {
  return {
    type: ADD_RESTRICTED_SECURITIES_RULE_FAILURE,
    payload: err
  };
}

export function updateRestrictedRule(params,queryParams) {
  return function(dispatch) {
    httpClient
      .post(UrlConfig.SAVE_RESTRICTED_SECURITIES_RULE, params)
      .then(resp => {
        dispatch(loadRestrictedRuleList(queryParams));
        dispatch(updateRestrictedRuleSuccess(resp));
        dispatch(closeRestrictedRuleDialog());
      })
      .catch(err => dispatch(updateRestrictedRuleFailure(err)));
  };
}
function updateRestrictedRuleSuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_SECURITIES_RULE_SUCCESS,
    payload: resp
  };
}
function updateRestrictedRuleFailure(err) {
  return {
    type: UPDATE_RESTRICTED_SECURITIES_RULE_FAILURE,
    payload: err
  };
}

export function restrictedRuleEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_RULE_INPUT_CHANGE,
    payload: {
      name,
      value,
    }
  };
}


export function openRestrictedRuleDialog(mode ,detail) {
  return {
    type: OPEN_RESTRICTED_RULE_DIALOG,
    payload: {
      mode,
      detail,
    }
  };
}

export function closeRestrictedRuleDialog(mode) {
  return {
    type: CLOSE_RESTRICTED_RULE_DIALOG,
    payload: {
      mode,
    }
  };
}

export function openRestrictedRuleFundBookDialog() {
  return {
    type: OPEN_RESTRICTED_RULE_FUND_BOOK_DIALOG
  };
}

export function closeRestrictedRuleFundBookDialog() {
  return {
    type: CLOSE_RESTRICTED_RULE_FUND_BOOK_DIALOG
  };
}

