import {
  // Load User Account
  LOAD_USER_ACCOUNTS_SUCCESS,
  LOAD_USER_ACCOUNTS_FAILURE,

  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,
  LOAD_SECURITY_TICKERS_FAILURE,

  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,
  LOAD_FUND_BOOK_DATA_FAILURE,

  // Load All BBG Tickers
  LOAD_ALL_BBG_TICKERS_SUCCESS,
  LOAD_ALL_BBG_TICKERS_FAILURE,

  // Load Asset Inst Class
  LOAD_ASSET_INST_CLASS_SUCCESS,
  LOAD_ASSET_INST_CLASS_FAILURE,

  // Load Country Codes
  LOAD_COUNTRY_CODES_SUCCESS,
  LOAD_COUNTRY_CODES_FAILURE,

  // Load Country Codes
  LOAD_CURRENCY_CODES_SUCCESS,
  LOAD_CURRENCY_CODES_FAILURE,

  // Load Sector Codes
  LOAD_SECTOR_CODES_SUCCESS,
  LOAD_SECTOR_CODES_FAILURE,

  // get hierarchy menus
  GET_HIERARCHY_MENUS_SUCCESS,
  GET_HIERARCHY_MENUS_FAILURE,

} from './homeConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';
import { localStorageClient } from '../../../utils/localStorageClient';

// =================================================================
// Load User Account
export function loadUserAccounts() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_USER_ACCOUNTS)
      .then(resp => dispatch(loadUserAccountsSuccess(resp)))
      .catch(err => dispatch(loadUserAccountsFailure(err)));
  };
}

function loadUserAccountsSuccess(resp) {
  return {
    type: LOAD_USER_ACCOUNTS_SUCCESS,
    payload: resp
  };
}

function loadUserAccountsFailure(err) {
  return {
    type: LOAD_USER_ACCOUNTS_FAILURE,
    payload: err
  };
}

// =================================================================
// Load Security Tickers
export function loadSecurityTickers() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_SECURITY_TICKER)
      .then(resp => dispatch(loadSecurityTickersSuccess(resp)))
      .catch(err => dispatch(loadSecurityTickersFailure(err)));
  };
}

function loadSecurityTickersSuccess(resp) {
  return {
    type: LOAD_SECURITY_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadSecurityTickersFailure(err) {
  return {
    type: LOAD_SECURITY_TICKERS_FAILURE,
    payload: err
  };
}


// =================================================================
// Load Fund Book Data
export function loadFundBookData() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_FUND_BOOK_DATA)
      .then(resp => dispatch(loadFundBookDataSuccess(resp)))
      .catch(err => dispatch(loadFundBookDataFailure(err)));
  };
}

function loadFundBookDataSuccess(resp) {
  return {
    type: LOAD_FUND_BOOK_DATA_SUCCESS,
    payload: resp
  };
}

function loadFundBookDataFailure(err) {
  return {
    type: LOAD_FUND_BOOK_DATA_FAILURE,
    payload: err
  };
}

// =================================================================
// Load All BBG Tickers
export function loadAllBbgTickers() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_ALL_BBG_TICKERS)
      .then(resp => dispatch(loadAllBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadAllBbgTickersFailure(err)));
  };
}

function loadAllBbgTickersSuccess(resp) {
  return {
    type: LOAD_ALL_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadAllBbgTickersFailure(err) {
  return {
    type: LOAD_ALL_BBG_TICKERS_FAILURE,
    payload: err
  };
}

// =================================================================
// Load Asset Inst Class
export function loadAssetInstClass() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_ASSET_INST_CLASS)
      .then(resp => dispatch(loadAssetInstClassSuccess(resp)))
      .catch(err => dispatch(loadAssetInstClassFailure(err)));
  };
}

function loadAssetInstClassSuccess(resp) {
  return {
    type: LOAD_ASSET_INST_CLASS_SUCCESS,
    payload: resp
  };
}

function loadAssetInstClassFailure(err) {
  return {
    type: LOAD_ASSET_INST_CLASS_FAILURE,
    payload: err
  };
}

// =================================================================
// Load Country Codes
export function loadCountryCodes() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_COUNTRY_CODES)
      .then(resp => dispatch(loadCountryCodesSuccess(resp)))
      .catch(err => dispatch(loadCountryCodesFailure(err)));
  };
}

function loadCountryCodesSuccess(resp) {
  return {
    type: LOAD_COUNTRY_CODES_SUCCESS,
    payload: resp
  };
}

function loadCountryCodesFailure(err) {
  return {
    type: LOAD_COUNTRY_CODES_FAILURE,
    payload: err
  };
}


// =================================================================
// Load Currency Codes
export function loadCurrencyCodes() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_CURRENCY_CODES)
      .then(resp => dispatch(loadCurrencyCodesSuccess(resp)))
      .catch(err => dispatch(loadCurrencyCodesFailure(err)));
  };
}

function loadCurrencyCodesSuccess(resp) {
  return {
    type: LOAD_CURRENCY_CODES_SUCCESS,
    payload: resp
  };
}

function loadCurrencyCodesFailure(err) {
  return {
    type: LOAD_CURRENCY_CODES_FAILURE,
    payload: err
  };
}

// =================================================================
// Load Sector Codes
export function loadSectorCodes() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_SECTOR_CODES)
      .then(resp => dispatch(loadSectorCodesSuccess(resp)))
      .catch(err => dispatch(loadSectorCodesFailure(err)));
  };
}

function loadSectorCodesSuccess(resp) {
  return {
    type: LOAD_SECTOR_CODES_SUCCESS,
    payload: resp
  };
}

function loadSectorCodesFailure(err) {
  return {
    type: LOAD_SECTOR_CODES_FAILURE,
    payload: err
  };
}


// get hierarchy menus
export function getHierarchyMenus() {
  const params = {
    source: 'Admin',
  };
  return function(dispatch) {
    httpClient
      .get(UrlConfig.GET_HIERARCHY_MENUS, params)
      .then(resp => dispatch(getHierarchyMenusSuccess(resp)))
      .catch(err => dispatch(getHierarchyMenusFailure(err)));
  };
}
function getHierarchyMenusSuccess(resp) {
  return {
    type: GET_HIERARCHY_MENUS_SUCCESS,
    payload: resp
  };
}
function getHierarchyMenusFailure(err) {
  return {
    type: GET_HIERARCHY_MENUS_FAILURE,
    payload: err
  };
}






