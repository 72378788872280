import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from '../GridColumnMap';

import {
  // Sync Exchange Rate
  START_SYNC_EXCHANGE_RATE,
  SYNC_EXCHANGE_RATE_SUCCESS,
  SYNC_EXCHANGE_RATE_FAILURE,
  INIT_SYNC_EXCHANGE_RATE_ACTION_STATUS,

  // Load Exchange Rate
  START_LOAD_EXCHANGE_RATE,
  LOAD_EXCHANGE_RATE_SUCCESS,
  LOAD_EXCHANGE_RATE_FAILURE,
  INIT_LOAD_EXCHANGE_RATE_ACTION_STATUS,

} from './exchangeRateConstants';

const initialState = {
  // Grdi Columns
  exchangeRateMetaGridColumns: GridColumnMap.exchangeRateMetaGridColumns,

  // Exchange Rate
  exchangeRateArray: [],

  // Load Exchange Rate Action Status
  loadExchangeRateActionStatus: ACTION_STATUS.READY,
  loadExchangeRateErrMsg: '',

  // Sync Exchange Rate Action Status
  syncExchangeRateActionStatus: ACTION_STATUS.READY,
  syncExchangeRateErrMsg: '',
  
} 


// Sync Short Position Report
function startSyncExchangeRate(state) {
  return {
    ...state,
    syncExchangeRateActionStatus: ACTION_STATUS.LOGINING,
  };
}

function syncExchangeRateSuccess(state, resp) {
  return {
    ...state,
    syncExchangeRateActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function syncExchangeRateFailure(state, err) {
  return {
    ...state,
    syncExchangeRateActionStatus: ACTION_STATUS.ERROR,
    syncExchangeRateErrMsg: err.respMessage,
  };
}

function initSyncExchangeRateActionStatus(state) {
  return {
    ...state,
    syncExchangeRateActionStatus: ACTION_STATUS.READY,
  };
}

// Load Exchange Rate
function startLoadExchangeRate(state) {
  return {
    ...state,
    loadExchangeRateActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadExchangeRateSuccess(state, resp) {
  return {
    ...state,
    loadExchangeRateActionStatus: ACTION_STATUS.SUCCESS,
    exchangeRateArray: resp.data,
  };
}

function loadExchangeRateFailure(state, err) {
  return {
    ...state,
    loadExchangeRateActionStatus: ACTION_STATUS.ERROR,
    loadExchangeRateErrMsg: err.respMessage,
  };
}

function initLoadExchangeRateActionStatus(state) {
  return {
    ...state,
    loadExchangeRateActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Sync Exchange Rate
  [START_SYNC_EXCHANGE_RATE]: startSyncExchangeRate,
  [SYNC_EXCHANGE_RATE_SUCCESS]: syncExchangeRateSuccess,
  [SYNC_EXCHANGE_RATE_FAILURE]: syncExchangeRateFailure,
  [INIT_SYNC_EXCHANGE_RATE_ACTION_STATUS]: initSyncExchangeRateActionStatus,

  // Load Exchange Rate
  [START_LOAD_EXCHANGE_RATE]: startLoadExchangeRate,
  [LOAD_EXCHANGE_RATE_SUCCESS]: loadExchangeRateSuccess,
  [LOAD_EXCHANGE_RATE_FAILURE]: loadExchangeRateFailure,
  [INIT_LOAD_EXCHANGE_RATE_ACTION_STATUS]: initLoadExchangeRateActionStatus,
});