import React, {Component, PropTypes} from 'react';

import { Breadcrumb, Button, message } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import FundGrid from './FundGrid';
import '../../EntityAccount.css';

class FundDashboard extends Component {

  componentDidMount() {
    this.props.loadFundList();
  }
  
  render() {
    return (
      <div className="fundWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Entity & Account</Breadcrumb.Item>
            <Breadcrumb.Item>Fund List</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      
        <div className='fundGrid'>
          <FundGrid {...this.props} />
        </div>
      </div>
    );
  }
}

export default FundDashboard;