import {
  QUERY_GIFT_HOSPITALITY_INPUT_CHANGE,

  // Load Gift Hospitality Request
  START_LOAD_GIFT_HOSPITALITY_REQUEST,
  LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Select Gift Hospitality Request
  SELECT_GIFT_HOSPITALITY_REQUEST,

  // Open/Reset Gift Hospitality Request Modal Dialog
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN,
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET,

  // Gift Hospitality Request Edit Change Input
  GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT,

  // Approve Gift Hospitality Request
  START_APPROVE_GIFT_HOSPITALITY_REQUEST,
  APPROVE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  APPROVE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_APPROVE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Reject Gift Hospitality Request
  START_REJECT_GIFT_HOSPITALITY_REQUEST,
  REJECT_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  REJECT_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_REJECT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Feedback Gift Hospitality Request
  START_FEEDBACK_GIFT_HOSPITALITY_REQUEST,
  FEEDBACK_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  FEEDBACK_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_FEEDBACK_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

} from './giftHospitalityRequestConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Gift Hospitality Request
function startLoadGiftHospitalityRequest() {
  return {
    type: START_LOAD_GIFT_HOSPITALITY_REQUEST
  };
}

export function loadGiftHospitalityRequest(params) {
  return function(dispatch) {
    dispatch(startLoadGiftHospitalityRequest());
    httpClient
      .get(UrlConfig.QUERY_GIFT_HOSPITALITY_REQUEST, params)
      .then(resp => dispatch(loadGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(loadGiftHospitalityRequestFailure(err)));
  };
}

function loadGiftHospitalityRequestSuccess(resp) {
  return {
    type: LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function loadGiftHospitalityRequestFailure(err) {
  return {
    type: LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initLoadGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}


// Select Gift Hospitality Request
export function selectGiftHospitalityRequest(payload) {
  return {
    type: SELECT_GIFT_HOSPITALITY_REQUEST,
    payload
  };
}

// Open/Reset Gift Hospitality Request Modal Dialog
export function openGiftHospitalityRequestEditModalDialog(giftHospitalityRequest) {
  return {
    type: GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN,
    payload: {
      giftHospitalityRequest,
    },
  };
}

export function resetGiftHospitalityRequestEditModalDialog() {
  return {
    type: GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET
  };
}

// Gift Hospitality Request Edit Change Input
export function giftHospitalityRequestEditChangeInput({ name, value }) {
  return {
    type: GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// Approve Gift Hospitality Request
function startApproveGiftHospitalityRequest() {
  return {
    type: START_APPROVE_GIFT_HOSPITALITY_REQUEST
  };
}

export function approveGiftHospitalityRequest(giftHospitalityIdList) {
  return function(dispatch) {
    dispatch(startApproveGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.APPROVE_GIFT_HOSPITALITY_REQUEST, giftHospitalityIdList)
      .then(resp => dispatch(approveGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(approveGiftHospitalityRequestFailure(err)));
  };
}

function approveGiftHospitalityRequestSuccess(resp) {
  return {
    type: APPROVE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function approveGiftHospitalityRequestFailure(err) {
  return {
    type: APPROVE_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initApproveGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_APPROVE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}

// Reject Gift Hospitality Request
function startRejectGiftHospitalityRequest() {
  return {
    type: START_REJECT_GIFT_HOSPITALITY_REQUEST
  };
}

export function rejectGiftHospitalityRequest(giftHospitalityIdList) {
  return function(dispatch) {
    dispatch(startRejectGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.REJECT_GIFT_HOSPITALITY_REQUEST, giftHospitalityIdList)
      .then(resp => dispatch(rejectGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(rejectGiftHospitalityRequestFailure(err)));
  };
}

function rejectGiftHospitalityRequestSuccess(resp) {
  return {
    type: REJECT_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function rejectGiftHospitalityRequestFailure(err) {
  return {
    type: REJECT_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initRejectGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_REJECT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}

// Feedback Gift Hospitality Request
function startFeedbackGiftHospitalityRequest() {
  return {
    type: START_FEEDBACK_GIFT_HOSPITALITY_REQUEST
  };
}

export function feedbackGiftHospitalityRequest(id, feedback) {
  const requestParams = {
    id,
    feedback,
  };

  return function(dispatch) {
    dispatch(startFeedbackGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.FEEDBACK_GIFT_HOSPITALITY_REQUEST, requestParams)
      .then(resp => dispatch(feedbackGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(feedbackGiftHospitalityRequestFailure(err)));
  };
}

function feedbackGiftHospitalityRequestSuccess(resp) {
  return {
    type: FEEDBACK_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function feedbackGiftHospitalityRequestFailure(err) {
  return {
    type: FEEDBACK_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initFeedbackGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_FEEDBACK_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}


// input change
export function queryGiftHospitalityInputChange(param) {
  return {
    type: QUERY_GIFT_HOSPITALITY_INPUT_CHANGE,
    payload: param,
  }
}

