import {
  // Load Banned Trade Request
  START_LOAD_BANNED_TRADE_REQUEST,
  LOAD_BANNED_TRADE_REQUEST_SUCCESS,
  LOAD_BANNED_TRADE_REQUEST_FAILURE,
  INIT_LOAD_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Select Banned Trade Request
  SELECT_BANNED_TRADE_REQUEST,

  // Open/Reset Banned Trade Request Modal Dialog
  BANNED_TRADE_REQUEST_MODAL_DIALOG_OPEN,
  BANNED_TRADE_REQUEST_MODAL_DIALOG_RESET,

  // Banned Trade Request Edit Change Input
  BANNED_TRADE_REQUEST_EDIT_CHANGE_INPUT,

  // Approve Banned Trade Request
  START_APPROVE_BANNED_TRADE_REQUEST,
  APPROVE_BANNED_TRADE_REQUEST_SUCCESS,
  APPROVE_BANNED_TRADE_REQUEST_FAILURE,
  INIT_APPROVE_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Reject Banned Trade Request
  START_REJECT_BANNED_TRADE_REQUEST,
  REJECT_BANNED_TRADE_REQUEST_SUCCESS,
  REJECT_BANNED_TRADE_REQUEST_FAILURE,
  INIT_REJECT_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Feedback Banned Trade Request
  START_FEEDBACK_BANNED_TRADE_REQUEST,
  FEEDBACK_BANNED_TRADE_REQUEST_SUCCESS,
  FEEDBACK_BANNED_TRADE_REQUEST_FAILURE,
  INIT_FEEDBACK_BANNED_TRADE_REQUEST_ACTION_STATUS,

  // Update Banned Trade Request
  START_UPDATE_BANNED_TRADE_REQUEST,
  UPDATE_BANNED_TRADE_REQUEST_SUCCESS,
  UPDATE_BANNED_TRADE_REQUEST_FAILURE,
  INIT_UPDATE_BANNED_TRADE_REQUEST_ACTION_STATUS,
} from './bannedTradeRequestConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load Banned Trade Request
function startLoadBannedTradeRequest() {
  return {
    type: START_LOAD_BANNED_TRADE_REQUEST
  };
}

export function loadBannedTradeRequest() {
  return function(dispatch) {
    dispatch(startLoadBannedTradeRequest());
    httpClient
      .get(UrlConfig.QUERY_BANNED_TRADE_REQUEST)
      .then(resp => dispatch(loadBannedTradeRequestSuccess(resp)))
      .catch(err => dispatch(loadBannedTradeRequestFailure(err)));
  };
}

function loadBannedTradeRequestSuccess(resp) {
  return {
    type: LOAD_BANNED_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function loadBannedTradeRequestFailure(err) {
  return {
    type: LOAD_BANNED_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initLoadBannedTradeRequestActionStatus() {
  return {
    type: INIT_LOAD_BANNED_TRADE_REQUEST_ACTION_STATUS
  };
}

// Select Banned Trade Request
export function selectBannedTradeRequest(payload) {
  return {
    type: SELECT_BANNED_TRADE_REQUEST,
    payload
  };
}

// Open/Reset Banned Trade Request Modal Dialog
export function openBannedTradeRequestEditModalDialog(bannedTradeRequest) {
  return {
    type: BANNED_TRADE_REQUEST_MODAL_DIALOG_OPEN,
    payload: {
      bannedTradeRequest,
    },
  };
}

export function resetBannedTradeRequestEditModalDialog() {
  return {
    type: BANNED_TRADE_REQUEST_MODAL_DIALOG_RESET
  };
}

// Banned Trade Request Edit Change Input
export function bannedTradeRequestEditChangeInput({ name, value }) {
  return {
    type: BANNED_TRADE_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// Approve Banned Trade Request
function startApproveBannedTradeRequest() {
  return {
    type: START_APPROVE_BANNED_TRADE_REQUEST
  };
}

export function approveBannedTradeRequest(id) {
  const requestParams = {
    id,
  };

  return function(dispatch) {
    dispatch(startApproveBannedTradeRequest());
    httpClient
      .post(UrlConfig.APPROVE_BANNED_TRADE_REQUEST, requestParams)
      .then(resp => dispatch(approveBannedTradeRequestSuccess(resp)))
      .catch(err => dispatch(approveBannedTradeRequestFailure(err)));
  };
}

function approveBannedTradeRequestSuccess(resp) {
  return {
    type: APPROVE_BANNED_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function approveBannedTradeRequestFailure(err) {
  return {
    type: APPROVE_BANNED_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initApproveBannedTradeRequestActionStatus() {
  return {
    type: INIT_APPROVE_BANNED_TRADE_REQUEST_ACTION_STATUS
  };
}

// Reject Banned Trade Request
function startRejectBannedTradeRequest() {
  return {
    type: START_REJECT_BANNED_TRADE_REQUEST
  };
}

export function rejectBannedTradeRequest(id) {
  const requestParams = {
    id,
  };

  return function(dispatch) {
    dispatch(startRejectBannedTradeRequest());
    httpClient
      .post(UrlConfig.REJECT_BANNED_TRADE_REQUEST, requestParams)
      .then(resp => dispatch(rejectBannedTradeRequestSuccess(resp)))
      .catch(err => dispatch(rejectBannedTradeRequestFailure(err)));
  };
}

function rejectBannedTradeRequestSuccess(resp) {
  return {
    type: REJECT_BANNED_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function rejectBannedTradeRequestFailure(err) {
  return {
    type: REJECT_BANNED_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initRejectBannedTradeRequestActionStatus() {
  return {
    type: INIT_REJECT_BANNED_TRADE_REQUEST_ACTION_STATUS
  };
}

// Feedback Banned Trade Request
function startFeedbackBannedTradeRequest() {
  return {
    type: START_FEEDBACK_BANNED_TRADE_REQUEST
  };
}

export function feedbackBannedTradeRequest(id, feedback) {
  const requestParams = {
    id,
    feedback,
  };

  return function(dispatch) {
    dispatch(startFeedbackBannedTradeRequest());
    httpClient
      .post(UrlConfig.FEEDBACK_BANNED_TRADE_REQUEST, requestParams)
      .then(resp => dispatch(feedbackBannedTradeRequestSuccess(resp)))
      .catch(err => dispatch(feedbackBannedTradeRequestFailure(err)));
  };
}

function feedbackBannedTradeRequestSuccess(resp) {
  return {
    type: FEEDBACK_BANNED_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function feedbackBannedTradeRequestFailure(err) {
  return {
    type: FEEDBACK_BANNED_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initFeedbackBannedTradeRequestActionStatus() {
  return {
    type: INIT_FEEDBACK_BANNED_TRADE_REQUEST_ACTION_STATUS
  };
}


// Update Banned Trade Request
function startUpdateBannedTradeRequest() {
  return {
    type: START_UPDATE_BANNED_TRADE_REQUEST
  };
}

export function updateBannedTradeRequest(bannedTradeRequest) {
  return function(dispatch) {
    dispatch(startUpdateBannedTradeRequest());
    httpClient
      .post(UrlConfig.UPDATED_BANNED_TRADE_REQUEST, bannedTradeRequest)
      .then(resp => dispatch(updateBannedTradeRequestSuccess(resp)))
      .catch(err => dispatch(updateBannedTradeRequestFailure(err)));
  };
}

function updateBannedTradeRequestSuccess(resp) {
  return {
    type: UPDATE_BANNED_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function updateBannedTradeRequestFailure(err) {
  return {
    type: UPDATE_BANNED_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initUpdateBannedTradeRequestActionStatus() {
  return {
    type: INIT_UPDATE_BANNED_TRADE_REQUEST_ACTION_STATUS
  };
}