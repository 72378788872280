import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class DelGroupStructureModalDialog extends Component {

    componentDidUpdate() {
        const {
            delGroupStructureStatus,
        } = this.props;
        if(delGroupStructureStatus === ACTION_STATUS.SUCCESS) {
            message.success("Delete Group Structure succeeded");
            this.props.initSubmitDelGroupStructureActionStatus();
            this.props.resetDelGroupStructureModal();
            this.props.queryGroupStructure();
        }
        if(delGroupStructureStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitDelGroupStructureActionStatus();
        }
    }

    onInputChange = ({name, value}) => {
        this.props.delGroupStructureChangeInput({name, value});
    }


    submitDelGroupStructureModal = () => {
        const {
            mode,
        } = this.props.delGroupStructureModal;
        const {
            id,
            comments,
        } = this.props.delGroupStructureModal.fields;
        Modal.confirm({
            title: 'Are you sure to Delete this Group Structure?',
            onOk: () => {
                this.props.submitDelGroupStructure({
                    id,
                    comments,
                    opType: mode,
                });
            }
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            delGroupStructureStatus,
        } = this.props;

        const {
            isOpened,
            respErrMsg,
        } = this.props.delGroupStructureModal;

        const {
            comments,
        } = this.props.delGroupStructureModal.fields;

        const isSubmitLoading = delGroupStructureStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={'Del Group Structure'}
                    centered
                    width={600}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelGroupStructureModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetDelGroupStructureModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitDelGroupStructureModal() }>
                          Submit
                        </Button>,
                    ]}
                >

                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input comments' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}

const DelGroupStructureModalDialogForm = Form.create({ name: 'DelGroupStructureModalDialog' })(DelGroupStructureModalDialog);
export default DelGroupStructureModalDialogForm;
