import {
  // Load CounterParty
  START_LOAD_COUNTER_PARTY,
  LOAD_COUNTER_PARTY_SUCCESS,
  LOAD_COUNTER_PARTY_FAILURE,
  INIT_LOAD_COUNTER_PARTY_ACTION_STATUS,

} from './counterPartyConstants';

import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

// Load CounterParty
function startLoadCounterParty() {
  return {
    type: START_LOAD_COUNTER_PARTY
  };
}

export function loadCounterParty() {
  return function(dispatch) {
    dispatch(startLoadCounterParty());
    httpClient
      .get(UrlConfig.QUERY_COUNTERPARTY_LIST)
      .then(resp => dispatch(loadCounterPartySuccess(resp)))
      .catch(err => dispatch(loadCounterPartyFailure(err)));
  };
}

function loadCounterPartySuccess(resp) {
  return {
    type: LOAD_COUNTER_PARTY_SUCCESS,
    payload: resp
  };
}

function loadCounterPartyFailure(err) {
  return {
    type: LOAD_COUNTER_PARTY_FAILURE,
    payload: err
  };
}

export function initLoadCounterPartyActionStatus() {
  return {
    type: INIT_LOAD_COUNTER_PARTY_ACTION_STATUS
  };
}

