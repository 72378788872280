import {connect} from 'react-redux';
import RestrictedRule from '../components/RestrictedRule';

import {
  // Load Restricted Rules
  loadRestrictedRules,
  initLoadRestrictedRulesActionStatus,

  // Open/Reset Restricted Rule Modal Dialog
  openRestrictedRuleEditModalDialog,
  resetRestrictedRuleEditModalDialog,

  // Restricted Rule Edit Change Input
  restrictedRuleEditChangeInput,

  // Create Restricted Rule
  createRestrictedRule,
  initCreateRestrictedRuleActionStatus,

  // Update Restricted Rule
  updateRestrictedRule,
  initUpdateRestrictedRuleActionStatus,

  // Batch Delete Restricted Rule
  batchDeleteRestrictedRule,
  initBatchDeleteRestrictedRuleActionStatus,

  // Load BBG Tickers
  loadBbgTickers,
  initLoadBbgTickersActionStatus,

  // Clear bbgTickerArray bbgTickerOptions
  clearBbgTickers,

  // BBG Ticker Select Changed
  bbgTickerSelectChanged,

  // Fund Code Select Changed
  fundCodeSelectChanged,

  // Book Code Select Changed
  bookCodeSelectChanged,

  // Select Restricted Rules
  selectRestrictedRules,

  // Show Valid Restricted Rules
  showValidRestrictedRules,

  // Show All Restricted Rules
  showAllRestrictedRules,

} from '../restrictedRuleActions';

const mapStateToProps = state => {
  return {
    restrictedRuleGridColumns: state.restrictedRule.restrictedRuleGridColumns,

    restrictedRuleArray: state.restrictedRule.restrictedRuleArray,
    filterRestrictedRuleArray: state.restrictedRule.filterRestrictedRuleArray,

    restrictedRuleEditControlModal: state.restrictedRule.restrictedRuleEditControlModal,

    // Restricted Rules Action Status
    loadRestrictedRulesActionStatus: state.restrictedRule.loadRestrictedRulesActionStatus,
    createRestrictedRuleActionStatus: state.restrictedRule.createRestrictedRuleActionStatus,
    updateRestrictedRuleActionStatus: state.restrictedRule.updateRestrictedRuleActionStatus,
    batchDeleteRestrictedRulesActionStatus: state.restrictedRule.batchDeleteRestrictedRulesActionStatus,

    // Rule Ticker Data
    securityTickerData: state.restrictedRule.securityTickerData,
    bbgTickerArray: state.restrictedRule.bbgTickerArray,
    bbgTickerOptions: state.restrictedRule.bbgTickerOptions,
    securityTickerArray: state.restrictedRule.securityTickerArray,
    allBbgTickers: state.restrictedRule.allBbgTickers,
    
    // Fund Book Data
    fundBookData: state.restrictedRule.fundBookData,
    fundCodeArray: state.restrictedRule.fundCodeArray,
    fundCodeOptions: state.restrictedRule.fundCodeOptions,
    bookCodeArray: state.restrictedRule.bookCodeArray,
    bookCodeOptions: state.restrictedRule.bookCodeOptions,
    fundBookArray: state.restrictedRule.fundBookArray,

    // Load BBG Tickers Action Status
    loadBbgTickersActionStatus: state.restrictedRule.loadBbgTickersActionStatus,

    // Select Restricted Rule
    selectedRestrictedRules: state.restrictedRule.selectedRestrictedRules,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Restricted Rules
    loadRestrictedRules: () => dispatch(loadRestrictedRules()),
    initLoadRestrictedRulesActionStatus: () => dispatch(initLoadRestrictedRulesActionStatus()),

    // Open/Reset Restricted Rule Modal Dialog
    openRestrictedRuleEditModalDialog: (isAddMode, restrictedRule) => dispatch(openRestrictedRuleEditModalDialog(isAddMode, restrictedRule)),
    resetRestrictedRuleEditModalDialog: () => dispatch(resetRestrictedRuleEditModalDialog()),

    // Restricted Rule Edit Change Input
    restrictedRuleEditChangeInput: (input) => dispatch(restrictedRuleEditChangeInput(input)),

    // Create Restricted Rule
    createRestrictedRule: (restrictedRule) => dispatch(createRestrictedRule(restrictedRule)),
    initCreateRestrictedRuleActionStatus: () => dispatch(initCreateRestrictedRuleActionStatus()),

    // Update Restricted Rule
    updateRestrictedRule: (restrictedRule) => dispatch(updateRestrictedRule(restrictedRule)),
    initUpdateRestrictedRuleActionStatus: () => dispatch(initUpdateRestrictedRuleActionStatus()),

    // Batch Delete Restricted Rule
    batchDeleteRestrictedRule: (restrictedRuleIdList) => dispatch(batchDeleteRestrictedRule(restrictedRuleIdList)),
    initBatchDeleteRestrictedRuleActionStatus: () => dispatch(initBatchDeleteRestrictedRuleActionStatus()),

    // Load BBG Tickers
    loadBbgTickers: (queryText) => dispatch(loadBbgTickers(queryText)),
    initLoadBbgTickersActionStatus: () => dispatch(initLoadBbgTickersActionStatus()),

    // Clear bbgTickerArray bbgTickerOptions
    clearBbgTickers: () => dispatch(clearBbgTickers()),
    
    // BBG Ticker Select Changed
    bbgTickerSelectChanged: (bbgTicker) => dispatch(bbgTickerSelectChanged(bbgTicker)),

    // Fund Code Select Changed
    fundCodeSelectChanged: (fundCode) => dispatch(fundCodeSelectChanged(fundCode)),

    // Book Code Select Changed
    bookCodeSelectChanged: (bookCode) => dispatch(bookCodeSelectChanged(bookCode)),

    // Select Restricted Rule
    selectRestrictedRules: (payload) => dispatch(selectRestrictedRules(payload)),

    // Show Valid Restricted Rules
    showValidRestrictedRules: () => dispatch(showValidRestrictedRules()),

    // Show All Restricted Rules
    showAllRestrictedRules: () => dispatch(showAllRestrictedRules()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedRule);