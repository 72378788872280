import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_REGISTER_INFO,
    LOAD_REGISTER_INFO_SUCCESS,
    LOAD_REGISTER_INFO_FAILURE,
    INIT_LOAD_REGISTER_INFO_ACTION_STATUS,
    // select
    SELECT_REGISTER,
    // add or edit
    OPEN_ADD_OR_EDIT_REGISTER_MODAL,
    ADD_OR_EDIT_REGISTER_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_REGISTER_MODAL,
    START_SUBMIT_SAVE_REGISTER,
    SUBMIT_SAVE_REGISTER_SUCCESS,
    SUBMIT_SAVE_REGISTER_FAILURE,
    INIT_SUBMIT_SAVE_REGISTER_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_REGISTERSUB_MODAL,
    ADD_OR_EDIT_REGISTERSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_REGISTERSUB_MODAL,
    START_SUBMIT_SAVE_REGISTERSUB,
    SUBMIT_SAVE_REGISTERSUB_SUCCESS,
    SUBMIT_SAVE_REGISTERSUB_FAILURE,
    INIT_SUBMIT_SAVE_REGISTERSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_REGISTER,
    BATCH_CHANGE_STATUS_FOR_REGISTER_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_REGISTER_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_REGISTER_ACTION_STATUS,
    // finish
    SELECT_REGISTER_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_REGISTER_TASK,
    FINISH_REGISTER_TASK_SUCCESS,
    FINISH_REGISTER_TASK_FAILURE,
    INIT_FINISH_REGISTER_TASK_ACTION_STATUS,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,



      // Accept Questionnaire Result
    START_ACCEPT_QUESTIONNAIRE_RESULT,
    ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
    ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
    INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

    // Reject Questionnaire Result
    START_REJECT_QUESTIONNAIRE_RESULT,
    REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
    REJECT_QUESTIONNAIRE_RESULT_FAILURE,
    INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS,

      // Send Remind Email
    START_SEND_REMIND_EMAIL,
    SEND_REMIND_EMAIL_SUCCESS,
    SEND_REMIND_EMAIL_FAILURE,
    INIT_SEND_REMIND_EMAIL_ACTION_STATUS,

} from './RegisterConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadRegisterInfo() {
    return {
      type: START_LOAD_REGISTER_INFO,
    };
}

function getQueryReqParams(params) {
    let startDate = params.startDate !== undefined && params.startDate !== null && params.startDate !== ''
    ? params.startDate.format("YYYY-MM-DD") : '';
    let endDate = params.endDate !== undefined && params.endDate !== null && params.endDate !== ''
        ? params.endDate.format("YYYY-MM-DD") : '';
    const requestParams = {
    // startDate: startDate,
    // endDate: endDate,
    category: params.category,
    fundco: params.fundco,
    owner: params.owner,
    monthStart: params.monthStart,
    status: params.status,
    };
    return requestParams;
}

export function loadRegisterInfo(params) {

    const requestParams = getQueryReqParams(params);

    return function(dispatch) {
        dispatch(startLoadRegisterInfo());
        httpClient  
          .get(UrlConfig.LOAD_REGISTER_INFO, requestParams)
          .then(resp => dispatch(loadRegisterInfoSuccess(resp)))
          .catch(err => dispatch(loadRegisterInfoFailure(err)));
    };
}
function loadRegisterInfoSuccess(resp) {
    return {
      type: LOAD_REGISTER_INFO_SUCCESS,
      payload: resp,
    };
}
function loadRegisterInfoFailure(err) {
    return {
        type: LOAD_REGISTER_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadRegisterInfoActionStatus() {
    return {
      type: INIT_LOAD_REGISTER_INFO_ACTION_STATUS,
    };
}


// select
export function selectRegister(array) {
    return {
        type: SELECT_REGISTER,
        payload: array,
    };
}


// add or edit
export function openAddOrEditRegisterModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_REGISTER_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditRegisterChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_REGISTER_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveRegisterModal() {
    return {
        type: RESET_SUBMIT_SAVE_REGISTER_MODAL,
    };
}

function startSubmitSaveRegister() {
    return {
        type: START_SUBMIT_SAVE_REGISTER,
    };
}
export function submitSaveRegister(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveRegister());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_REGISTER;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_REGISTER;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveRegisterSuccess(resp)))
            .catch(err => dispatch(submitSaveRegisterFailure(err)));        
    };
}
function submitSaveRegisterSuccess(resp) {
    return {
        type: SUBMIT_SAVE_REGISTER_SUCCESS,
        payload: resp,
    };
}
export function submitSaveRegisterFailure(err) {
    return {
        type: SUBMIT_SAVE_REGISTER_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveRegisterActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_REGISTER_ACTION_STATUS,
    };
}


//sub add or edit
export function openAddOrEditRegisterSubModal(mode, data,idList) {
    return {
        type: OPEN_ADD_OR_EDIT_REGISTERSUB_MODAL,
        payload: {
            mode,
            data,
            idList,
        },
    };
}
export function addOrEditRegisterSubChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_REGISTERSUB_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveRegisterSubModal() {
    return {
        type: RESET_SUBMIT_SAVE_REGISTERSUB_MODAL,
    };
}

function startSubmitSaveRegisterSub() {
    return {
        type: START_SUBMIT_SAVE_REGISTERSUB,
    };
}
export function submitSaveRegisterSub(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveRegisterSub());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_REGISTERSUB;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_REGISTERSUB;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveRegisterSubSuccess(resp)))
            .catch(err => dispatch(submitSaveRegisterSubFailure(err)));        
    };
}
function submitSaveRegisterSubSuccess(resp) {
    return {
        type: SUBMIT_SAVE_REGISTERSUB_SUCCESS,
        payload: resp,
    };
}
export function submitSaveRegisterSubFailure(err) {
    return {
        type: SUBMIT_SAVE_REGISTERSUB_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveRegisterSubActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_REGISTERSUB_ACTION_STATUS,
    };
}



// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForRegister() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_REGISTER,
    };
}
export function batchChangeStatusForRegister(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForRegister());
        httpClient
            .post(UrlConfig.BATCH_CHANGE_STATUS_FOR_REGISTER, params)
            .then(resp => dispatch(batchChangeStatusForRegisterSuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForRegisterFailure(err)));        
    };
}
function batchChangeStatusForRegisterSuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_REGISTER_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForRegisterFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_REGISTER_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForRegisterActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_REGISTER_ACTION_STATUS,
    };
}


// finish
export function selectRegisterTask(array) {
    return {
        type: SELECT_REGISTER_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishRegisterTask() {
    return {
        type: START_FINISH_REGISTER_TASK,
    };
}
export function finishRegisterTask(params) {
    return function(dispatch) {
        dispatch(startFinishRegisterTask());
        httpClient
            .post(UrlConfig.FINISH_REGISTER_TASK, params)
            .then(resp => dispatch(finishRegisterTaskSuccess(resp)))
            .catch(err => dispatch(finishRegisterTaskFailure(err)));        
    };
}
function finishRegisterTaskSuccess(resp) {
    return {
        type: FINISH_REGISTER_TASK_SUCCESS,
        payload: resp,
    };
}
function finishRegisterTaskFailure(err) {
    return {
        type: FINISH_REGISTER_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishRegisterTaskActionStatus() {
    return {
        type: INIT_FINISH_REGISTER_TASK_ACTION_STATUS,
    };
}

// Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
      payload: {
        sendMode,
        emailPayload,
      },
    };
  }
  
  export function resetQuestionnaireEmailEditModalDialog() {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET
    };
  }


  // Accept Questionnaire Result
function startAcceptQuestionnaireResult() {
    return {
      type: START_ACCEPT_QUESTIONNAIRE_RESULT
    };
  }
  
  export function acceptQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startAcceptQuestionnaireResult());
      httpClient
        .post(UrlConfig.ACCEPT_REGISTER_RESULT, requestParams)
        .then(resp => dispatch(acceptQuestionnaireResultSuccess(resp)))
        .catch(err => dispatch(acceptQuestionnaireResultFailure(err)));
    };
  }
  
  function acceptQuestionnaireResultSuccess(resp) {
    return {
      type: ACCEPT_QUESTIONNAIRE_RESULT_SUCCESS,
      payload: resp
    };
  }
  
  function acceptQuestionnaireResultFailure(err) {
    return {
      type: ACCEPT_QUESTIONNAIRE_RESULT_FAILURE,
      payload: err
    };
  }
  
  export function initAcceptQuestionnaireResultActionStatus() {
    return {
      type: INIT_ACCEPT_QUESTIONNAIRE_RESULT_ACTION_STATUS
    };
  }

// Reject Questionnaire Result
function startRejectQuestionnaireResult() {
    return {
      type: START_REJECT_QUESTIONNAIRE_RESULT
    };
  }
  
  export function rejectQuestionnaireResult(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startRejectQuestionnaireResult());
      httpClient   
        .post(UrlConfig.REJECT_REGISTER_RESULT, requestParams)
        .then(resp => dispatch(rejectQuestionnaireResultSuccess(resp)))
        .catch(err => dispatch(rejectQuestionnaireResultFailure(err)));
    };
  }
  
  function rejectQuestionnaireResultSuccess(resp) {
    return {
      type: REJECT_QUESTIONNAIRE_RESULT_SUCCESS,
      payload: resp
    };
  }
  
  function rejectQuestionnaireResultFailure(err) {
    return {
      type: REJECT_QUESTIONNAIRE_RESULT_FAILURE,
      payload: err
    };
  }
  
  export function initRejectQuestionnaireResultActionStatus() {
    return {
      type: INIT_REJECT_QUESTIONNAIRE_RESULT_ACTION_STATUS
    };
  }

  // Send Remind Email
function startSendRemindEmail() {
    return {
      type: START_SEND_REMIND_EMAIL
    };
  }
  
  export function sendRemindEmail(questionnaireResultGuidList, emailTitle, emailContent) {
    const requestParams = {
      questionnaireResultGuidList,
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startSendRemindEmail());
      httpClient
        .post(UrlConfig.REMIND_REGISTER_EMAIL, requestParams)
        .then(resp => dispatch(sendRemindEmailSuccess(resp)))
        .catch(err => dispatch(sendRemindEmailFailure(err)));
    };
  }
  
  function sendRemindEmailSuccess(resp) {
    return {
      type: SEND_REMIND_EMAIL_SUCCESS,
      payload: resp
    };
  }
  
  function sendRemindEmailFailure(err) {
    return {
      type: SEND_REMIND_EMAIL_FAILURE,
      payload: err
    };
  }
  
  export function initSendRemindEmailActionStatus() {
    return {
      type: INIT_SEND_REMIND_EMAIL_ACTION_STATUS
    };
  }