import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


import {
    // query
    ON_REPORT_SUMMARY_QUERY_INPUT_CHANGE,
    START_LOAD_REPORTING_SUMMARY_INFO,
    LOAD_REPORTING_SUMMARY_INFO_SUCCESS,
    LOAD_REPORTING_SUMMARY_INFO_FAILURE,
    INIT_LOAD_REPORTING_SUMMARY_INFO_ACTION_STATUS,
    // Select
    SELECT_REPORTING_SUMMRAY,
    // add or edit
    OPEN_ADD_OR_EDIT_REPORTING_SUMMARY_MODAL,
    ADD_OR_EDIT_REPORTING_SUMMARY_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_REPORTING_SUMMARY_MODAL,
    START_SUBMIT_SAVE_REPORTING_SUMMARY,
    SUBMIT_SAVE_REPORTING_SUMMARY_SUCCESS,
    SUBMIT_SAVE_REPORTING_SUMMARY_FAILURE,
    INIT_SUBMIT_SAVE_REPORTING_SUMMARY_ACTION_STATUS,
    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY,
    BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_ACTION_STATUS,
    // finish
    SELECT_REPORTING_SUMMRAY_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_REPORTING_SUMMARY_TASK,
    FINISH_REPORTING_SUMMARY_TASK_SUCCESS,
    FINISH_REPORTING_SUMMARY_TASK_FAILURE,
    INIT_FINISH_REPORTING_SUMMARY_TASK_ACTION_STATUS,

    
} from './ReportingSummaryConstants';

import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  } from '../../../features/home/homeConstants';


const Option = Select.Option;

const locationOptions = [];
locationOptions.push(<Option key={'All'}>{'All'}</Option>);
locationOptions.push(<Option key={'BVI'}>{'BVI'}</Option>);
locationOptions.push(<Option key={'Cayman'}>{'Cayman'}</Option>);
locationOptions.push(<Option key={'Hainan'}>{'Hainan'}</Option>);
locationOptions.push(<Option key={'Hong Kong'}>{'Hong Kong'}</Option>);
locationOptions.push(<Option key={'India'}>{'India'}</Option>);
locationOptions.push(<Option key={'Japan'}>{'Japan'}</Option>);
locationOptions.push(<Option key={'Shanghai'}>{'Shanghai'}</Option>);
locationOptions.push(<Option key={'Singapore'}>{'Singapore'}</Option>);
locationOptions.push(<Option key={'USA'}>{'USA'}</Option>);
locationOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const fundCompanyOptions = [];
fundCompanyOptions.push(<Option key={'All'}>{'All'}</Option>);
fundCompanyOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
fundCompanyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundCompanyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundCompanyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundCompanyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundCompanyOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
// fundCompanyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
// fundCompanyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
// fundCompanyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
fundCompanyOptions.push(<Option key={'投资顾问 (上海)'}>{'投资顾问 (上海)'}</Option>);
fundCompanyOptions.push(<Option key={'私募管理 (上海)'}>{'私募管理 (上海)'}</Option>);
fundCompanyOptions.push(<Option key={'私募管理 (海南)'}>{'私募管理 (海南)'}</Option>);
fundCompanyOptions.push(<Option key={'资产管理 (上海)'}>{'资产管理 (上海)'}</Option>);
fundCompanyOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
fundCompanyOptions.push(<Option key={'COP'}>{'COP'}</Option>);
fundCompanyOptions.push(<Option key={'EJ'}>{'EJ'}</Option>);
fundCompanyOptions.push(<Option key={'Wealth Ocean'}>{'Wealth Ocean'}</Option>);
fundCompanyOptions.push(<Option key={'Pinpoint Charity'}>{'Pinpoint Charity'}</Option>);
fundCompanyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundCompanyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundCompanyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundCompanyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundCompanyOptions.push(<Option key={'MetaPoint'}>{'MetaPoint'}</Option>);
fundCompanyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundCompanyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundCompanyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
fundCompanyOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const frequencyOptions = [];
frequencyOptions.push(<Option key={'EveryWorkday'}>{'EveryWorkday'}</Option>);
frequencyOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);
frequencyOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);
frequencyOptions.push(<Option key={'Quarterly'}>{'Quarterly'}</Option>);
frequencyOptions.push(<Option key={'SemiAnnually'}>{'SemiAnnually'}</Option>);
frequencyOptions.push(<Option key={'Annually'}>{'Annually'}</Option>);
frequencyOptions.push(<Option key={'Every3Years'}>{'Every3Years'}</Option>);
frequencyOptions.push(<Option key={'Incidental'}>{'Incidental'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'Sun'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'Mon'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'Tue'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'Wed'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'Thu'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'Fri'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'Sat'}>{'Sat'}</Option>);

const monthOptions = [];
monthOptions.push(<Option key={'01'}>{'Jan'}</Option>);
monthOptions.push(<Option key={'02'}>{'Feb'}</Option>);
monthOptions.push(<Option key={'03'}>{'Mar'}</Option>);
monthOptions.push(<Option key={'04'}>{'Apr'}</Option>);
monthOptions.push(<Option key={'05'}>{'May'}</Option>);
monthOptions.push(<Option key={'06'}>{'Jun'}</Option>);
monthOptions.push(<Option key={'07'}>{'Jul'}</Option>);
monthOptions.push(<Option key={'08'}>{'Aug'}</Option>);
monthOptions.push(<Option key={'09'}>{'Sep'}</Option>);
monthOptions.push(<Option key={'10'}>{'Oct'}</Option>);
monthOptions.push(<Option key={'11'}>{'Nov'}</Option>);
monthOptions.push(<Option key={'12'}>{'Dec'}</Option>);

const responsibleDeptOptions = [];
responsibleDeptOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
responsibleDeptOptions.push(<Option key={'BD'}>{'BD'}</Option>);
responsibleDeptOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
responsibleDeptOptions.push(<Option key={'CS'}>{'CS'}</Option>);
responsibleDeptOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
responsibleDeptOptions.push(<Option key={'IT'}>{'IT'}</Option>);
responsibleDeptOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
responsibleDeptOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
responsibleDeptOptions.push(<Option key={'Settlement'}>{'Settlement'}</Option>);


const initialState = {
    locationOptions: locationOptions,
    fundCompanyOptions: fundCompanyOptions,
    frequencyOptions: frequencyOptions,
    weekdayOptions: weekdayOptions,
    monthOptions: monthOptions,
    responsibleDeptOptions: responsibleDeptOptions,

    reportingSummaryQueryFields: {
        id: undefined,
        location: undefined,
        fundCompany: undefined,
    },

    selectedReportingSummaryArray: [],
    selectedReportingSummaryTaskArray: [],

    addOrEditReportingSummaryModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: 'Working',
            location: undefined,
            fundCompany: undefined,
            description: undefined,
            via: undefined,
            frequency: undefined,
            deadline: undefined,
            notification: undefined,
            regulator: undefined,
            responsibleDept: undefined,
            emailTo: undefined,
            emailCC: undefined,
            regulation: undefined,
            remarks: undefined,
            comments: undefined,
            // 将deadline分解后的参数
            deadlineMonth: undefined,
            deadlineDate: undefined,
            deadlineYear: undefined,
        },
    },

    batchChangeStatusModal: {
        isOpened: false,
        mode: 'PAUSE',
        respErrMsg: '',
        fields: {
            idList: [],
            comments: undefined,
        },
    },

    finishTaskModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: undefined,
            remarks: undefined,
            comments: undefined,
        },
    },

    userAccountEmailOptions: [],

};



// query
function onReportSummaryQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.reportingSummaryQueryFields,
        [name]: value,
    };
    return {
        ...state,
        reportingSummaryQueryFields: upatedFields,
    }
}
function startLoadReportingSummaryInfo(state) {
    return {
        ...state,
        loadReportingSummaryInfoActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadReportingSummaryInfoSuccess(state, resp) {
    return {
        ...state,
        loadReportingSummaryInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadReportingSummaryInfoResp: resp,
    };
}
function loadReportingSummaryInfoFailure(state, err) {
    return {
        ...state,
        loadReportingSummaryInfoActionStatus: ACTION_STATUS.ERROR,
        loadReportingSummaryInfoResp: err,
    };
}
function initLoadReportingSummaryInfoActionStatus(state) {
    return {
        ...state,
        loadReportingSummaryInfoActionStatus: ACTION_STATUS.READY,
        selectedReportingSummaryArray: [],
        selectedReportingSummaryTaskArray: [],
    };
}


// select
function selectReportingSummary(state, array) {
    return {
        ...state,
        selectedReportingSummaryArray: array,
    };
}


// add or edit
function openAddOrEditReportingSummaryModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditReportingSummaryModal: {
                ...state.addOrEditReportingSummaryModal,
                isOpened: true,
                mode,
            },
        };

    } else {
        // 分解deadline
        let deadlineMonth = undefined;
        let deadlineDate = undefined;
        let deadlineYear = undefined;
        let deadlineMMDD = data.deadline;
        if(data.frequency === 'Every3Years') {
            let commaIdx = deadlineMMDD.indexOf(',');
            deadlineYear = deadlineMMDD.substring(commaIdx+1);
            deadlineMMDD = deadlineMMDD.substring(0, commaIdx);
        }
        if(data.frequency === 'SemiAnnually' || data.frequency === 'Annually' || data.frequency === 'Every3Years') {
            let hyphenIdx = deadlineMMDD.indexOf('-');
            deadlineMonth = deadlineMMDD.substring(0, hyphenIdx);
            deadlineDate = deadlineMMDD.substring(hyphenIdx+1);
        }
        // notification是空，转成NA
        let notification = 'NA';
        if(data.notification !== undefined && data.notification !== null && data.notification !== '') {
            notification = data.notification;
        }
        // return
        return {
            ...state,
            addOrEditReportingSummaryModal: {
                ...state.addOrEditReportingSummaryModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditReportingSummaryModal.fields,
                    id: data.id,
                    status: data.status,
                    location: data.location,
                    fundCompany: data.fundCompany,
                    description: data.description,
                    via: data.via,
                    frequency: data.frequency,
                    deadline: data.deadline,
                    notification,
                    regulator: data.regulator,
                    responsibleDept: data.responsibleDept,
                    emailTo: data.emailTo,
                    emailCC: data.emailCC,
                    regulation: data.regulation,
                    remarks: data.remarks,
                    deadlineMonth,
                    deadlineDate,
                    deadlineYear,
                }
            },
        }

    }
}
function addOrEditReportingSummaryChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditReportingSummaryModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditReportingSummaryModal: {
          ...state.addOrEditReportingSummaryModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSaveReportingSummaryModal(state) {
    return {
        ...state,
        addOrEditReportingSummaryModal: initialState.addOrEditReportingSummaryModal,
    }
}

function startSubmitSaveReportingSummary(state) {
    return {
        ...state,
        addOrEditReportingSummaryStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveReportingSummarySuccess(state, resp) {
    return {
        ...state,
        addOrEditReportingSummaryStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSaveReportingSummaryFailure(state, err) {
    return {
        ...state,
        addOrEditReportingSummaryStatus: ACTION_STATUS.ERROR,
        addOrEditReportingSummaryModal: {
            ...state.addOrEditReportingSummaryModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSaveReportingSummaryActionStatus(state) {
    return {
        ...state,
        addOrEditReportingSummaryStatus: ACTION_STATUS.READY,
    };
}


// batch change status
function openBatchChangeStatusModal(state, {mode, idList}) {
    return {
        ...state,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.batchChangeStatusModal.fields,
                idList,
            },
        },
    };
}
function resetBatchChangeStatusModal(state) {
    return {
        ...state,
        batchChangeStatusModal: initialState.batchChangeStatusModal,
    }
}
function batchChangeStatusChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.batchChangeStatusModal.fields,
        [name]: value,
    };
    return {
        ...state,
        batchChangeStatusModal: {
          ...state.batchChangeStatusModal,
          fields: updatedFields,
        },
    };
}

function startBatchChangeStatusForReportingSummary(state) {
    return {
        ...state,
        batchChangeStatusForReportingSummaryStatus: ACTION_STATUS.LOGINING,
    };
}
function batchChangeStatusForReportingSummarySuccess(state, resp) {
    return {
        ...state,
        batchChangeStatusForReportingSummaryStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function batchChangeStatusForReportingSummaryFailure(state, err) {
    return {
        ...state,
        batchChangeStatusForReportingSummaryStatus: ACTION_STATUS.ERROR,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initBatchChangeStatusForReportingSummaryActionStatus(state) {
    return {
        ...state,
        batchChangeStatusForReportingSummaryStatus: ACTION_STATUS.READY,
    };
}


// finish
function selectReportingSummaryTask(state, array) {
    return {
        ...state,
        selectedReportingSummaryTaskArray: array,
    };
}
function openFinishTaskModal(state, task) {
    return {
        ...state,
        finishTaskModal: {
            ...state.finishTaskModal,
            isOpened: true,
            fields: {
                ...state.finishTaskModal.fields,
                id: task.id,
                remarks: task.remarks,
            },
        },
    };
}
function resetFinishTaskModal(state) {
    return {
        ...state,
        finishTaskModal: initialState.finishTaskModal,
    }; 
}
function finishTaskChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.finishTaskModal.fields,
        [name]: value,
    };
    return {
        ...state,
        finishTaskModal: {
          ...state.finishTaskModal,
          fields: updatedFields,
        },
    };
}

function startFinishReportingSummaryTask(state) {
    return {
        ...state,
        finishReportingSummaryTaskStatus: ACTION_STATUS.LOGINING,
    };
}
function finishReportingSummaryTaskSuccess(state, resp) {
    return {
        ...state,
        finishReportingSummaryTaskStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function finishReportingSummaryTaskFailure(state, err) {
    return {
        ...state,
        finishReportingSummaryTaskStatus: ACTION_STATUS.ERROR,
        finishTaskModal: {
            ...state.finishTaskModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initFinishReportingSummaryTaskActionStatus(state) {
    return {
        ...state,
        finishReportingSummaryTaskStatus: ACTION_STATUS.READY,
    };
}


// user account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    const userAccountEmailOptions = userAccountArray.map(userAccount => <Option key={userAccount.email}>{userAccount.englishName}</Option>);
    return {
        ...state,
        userAccountEmailOptions,
      };
}


export default createReducer(initialState, {
    // query
    [ON_REPORT_SUMMARY_QUERY_INPUT_CHANGE]: onReportSummaryQueryInputChange,
    [START_LOAD_REPORTING_SUMMARY_INFO]: startLoadReportingSummaryInfo,
    [LOAD_REPORTING_SUMMARY_INFO_SUCCESS]: loadReportingSummaryInfoSuccess,
    [LOAD_REPORTING_SUMMARY_INFO_FAILURE]: loadReportingSummaryInfoFailure,
    [INIT_LOAD_REPORTING_SUMMARY_INFO_ACTION_STATUS]: initLoadReportingSummaryInfoActionStatus,
    // select
    [SELECT_REPORTING_SUMMRAY]: selectReportingSummary,
    // add or edit
    [OPEN_ADD_OR_EDIT_REPORTING_SUMMARY_MODAL]: openAddOrEditReportingSummaryModal,
    [ADD_OR_EDIT_REPORTING_SUMMARY_CHANGE_INPUT]: addOrEditReportingSummaryChangeInput,
    [RESET_SUBMIT_SAVE_REPORTING_SUMMARY_MODAL]: resetSubmitSaveReportingSummaryModal,
    [START_SUBMIT_SAVE_REPORTING_SUMMARY]: startSubmitSaveReportingSummary,
    [SUBMIT_SAVE_REPORTING_SUMMARY_SUCCESS]: submitSaveReportingSummarySuccess,
    [SUBMIT_SAVE_REPORTING_SUMMARY_FAILURE]: submitSaveReportingSummaryFailure,
    [INIT_SUBMIT_SAVE_REPORTING_SUMMARY_ACTION_STATUS]: initSubmitSaveReportingSummaryActionStatus,
    // batch change status
    [OPEN_BATCH_CHANGE_STATUS_MODAL]: openBatchChangeStatusModal,
    [RESET_BATCH_CHANGE_STATUS_MODAL]: resetBatchChangeStatusModal,
    [BATCH_CHANGE_STATUS_CHANGE_INPUT]: batchChangeStatusChangeInput,
    [START_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY]: startBatchChangeStatusForReportingSummary,
    [BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_SUCCESS]: batchChangeStatusForReportingSummarySuccess,
    [BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_FAILURE]: batchChangeStatusForReportingSummaryFailure,
    [INIT_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_ACTION_STATUS]: initBatchChangeStatusForReportingSummaryActionStatus,
    // finish
    [SELECT_REPORTING_SUMMRAY_TASK]: selectReportingSummaryTask,
    [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,
    [RESET_FINISH_TASK_MODAL]: resetFinishTaskModal,
    [FINISH_TASK_CHANGE_INPUT]: finishTaskChangeInput,
    [START_FINISH_REPORTING_SUMMARY_TASK]: startFinishReportingSummaryTask,
    [FINISH_REPORTING_SUMMARY_TASK_SUCCESS]: finishReportingSummaryTaskSuccess,
    [FINISH_REPORTING_SUMMARY_TASK_FAILURE]: finishReportingSummaryTaskFailure,
    [INIT_FINISH_REPORTING_SUMMARY_TASK_ACTION_STATUS]: initFinishReportingSummaryTaskActionStatus,
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
});

