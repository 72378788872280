import {connect} from 'react-redux';
import PBShortInterestRate from '../components/PBShortInterestRate';

import {
  // Load  PB Short Interest Rate
  loadPBShortInterestRate,
  initLoadPBShortInterestRateActionStatus,
} from '../pbShortInterestRateActions';

const mapStateToProps = state => {
  return {
    pbShortInterestRateGridColumns: state.pbShortInterestRate.pbShortInterestRateGridColumns,

    // PB Short Interest Rate
    pbShortInterestRateArray: state.pbShortInterestRate.pbShortInterestRateArray,

    // Load PB Short Interest RateAction Status
    loadPBShortInterestRateActionStatus: state.pbShortInterestRate.loadPBShortInterestRateActionStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load  PB Short Interest Rate
    loadPBShortInterestRate: (dateString) => dispatch(loadPBShortInterestRate(dateString)),
    initLoadPBShortInterestRateActionStatus: () => dispatch(initLoadPBShortInterestRateActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PBShortInterestRate);