import React, { Component } from 'react';

import moment from 'moment';
import { Form, Row, Col, Input, Button, Modal, Select, DatePicker, message, Radio } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import RadioOperationMode from '../../../../../utils/RadioOperationMode';
import { RESTRICTION_TYPE_MAP } from '../../../../../utils/RestrictionTypeUtil';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const options = [
  { label: 'White List', value: true },
  { label: 'Black List', value: false },
];

// Restriction Type
const RestrictionTypeOptions = [];
for (let i = 0; i < RESTRICTION_TYPE_MAP.length; i++) {
  RestrictionTypeOptions.push(<Option key={RESTRICTION_TYPE_MAP[i].code}>{RESTRICTION_TYPE_MAP[i].label}</Option>);
}


class RestrictedSectorEditDialog extends Component {
  state = {
    
  };

  componentDidUpdate() {
    const { 
      createRestrictedSectorActionStatus,
      updateRestrictedSectorActionStatus,
    } = this.props;

    // 创建 Restricted Sector 成功
    if (createRestrictedSectorActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedSectors();
      this.props.initCreateRestrictedSectorActionStatus();

      this.props.resetRestrictedSectorEditModalDialog();
    }
      
    // 创建 Restricted Sector 失败
    if (createRestrictedSectorActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCreateRestrictedSectorActionStatus();
    }

    // 更新 Restricted Sector 成功
    if (updateRestrictedSectorActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedSectors();
      this.props.initUpdateRestrictedSectorActionStatus();

      this.props.resetRestrictedSectorEditModalDialog();
    } 

    // 更新 Restricted Sector 失败
    if (updateRestrictedSectorActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateRestrictedSectorActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.restrictedSectorEditChangeInput({name, value});
  }

  sectorNameOnChange = (value) => {
    this.props.restrictedSectorEditChangeInput({ name: 'sectorName', value });
    this.props.sectorNameSelectChanged(value);
  }

  fundCodeOnChange = (value) => {
    this.props.restrictedSectorEditChangeInput({ name: 'fundCode', value });

    if (!!value) {
      this.props.fundCodeSelectChanged(value);
    }
  }

  bookCodeOnChange = (value) => {
    this.props.restrictedSectorEditChangeInput({ name: 'bookCode', value });

    if (!!value) {
      this.props.bookCodeSelectChanged(value);
    }
  }

  radioGroupOnChanged = (e) => {
    this.props.restrictedSectorEditChangeInput({ name: 'isWhite', value: e.target.value });
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      restrictedSectorEditControlModal,
    } = this.props;

    const isAddMode = restrictedSectorEditControlModal.isAddMode;

    const {
      id,
      restrictionType,
      fundCode,
      bookCode,
      pmName,
      sectorId,
      sectorName,
      explanation,
      kickOffDate,
      expiryDate,
      isWhite,
    } = restrictedSectorEditControlModal.fields;

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          console.info('success');

          if (isAddMode) {
            this.props.createRestrictedSector({
              id,
              restrictionType,
              fundCode,
              bookCode,
              pmName,
              sectorId,
              sectorName,
              explanation,
              kickOffDate,
              expiryDate,
              isWhite,
            });
          } else {
            this.props.updateRestrictedSector({
              id,
              restrictionType,
              fundCode,
              bookCode,
              pmName,
              sectorId,
              sectorName,
              explanation,
              kickOffDate,
              expiryDate,
              isWhite,
            });
          }
        }
      },
    );
  }

  render() {
    const {
      restrictedSectorEditControlModal,
      fundCodeOptions,
      bookCodeOptions,
      sectorCodeOptions,
      createRestrictedSectorActionStatus,
      updateRestrictedSectorActionStatus,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = restrictedSectorEditControlModal;


    const { getFieldDecorator } = this.props.form;

    const {
      id,
      restrictionType,
      fundCode,
      bookCode,
      pmName,
      sectorId,
      sectorName,
      explanation,
      kickOffDate,
      expiryDate,
      isWhite,
    } = restrictedSectorEditControlModal.fields;

    let modalTitle = "Edit Restricted Sector";
    if (isAddMode) {
      modalTitle = "Add Restricted Sector";
    }

    const isLoading = createRestrictedSectorActionStatus === ACTION_STATUS.LOGINING || updateRestrictedSectorActionStatus === ACTION_STATUS.LOGINING; 
    
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedSectorEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Restriction Type">
                  {getFieldDecorator('restrictionType', {
                      initialValue: restrictionType,
                      rules: [{
                        required: true,
                        message: 'Please select restriction type',
                      }],
                    })(
                      <Select onChange={(value) => {
                            this.onInputChange({ name: 'restrictionType', value })
                          }} placeholder="Please Choose"
                        >
                        {RestrictionTypeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Fund">
                  {getFieldDecorator('fundCode', {
                      initialValue: fundCode,
                      rules: [{
                        required: true,
                        message: 'Please select fund',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.fundCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {fundCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Book">
                  {getFieldDecorator('bookCode', {
                      initialValue: bookCode,
                      rules: [{
                        required: true,
                        message: 'Please select book',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.bookCodeOnChange} 
                        placeholder="Please Choose"
                        >
                        {bookCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="PM Name">
                  <Input placeholder="" 
                    value={pmName}
                    disabled
                    onChange={(e) => {
                        this.onInputChange({ name: 'pmName', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Sector Id">
                  <Input placeholder="" 
                    value={sectorId}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Sector Name">
                  {getFieldDecorator('sectorName', {
                      initialValue: sectorName,
                      rules: [{
                        required: true,
                        message: 'Please select sector name"',
                      }],
                    })(
                      <Select 
                        allowClear
                        showSearch
                        onChange={this.sectorNameOnChange} 
                        placeholder="Please Choose"
                        >
                        {sectorCodeOptions}
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Explanation">
                  <TextArea placeholder="Explanation" 
                    value={explanation}
                    onChange={(e) => {
                        this.onInputChange({ name: 'explanation', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Kick Off Date">
                  {getFieldDecorator('kickOffDate', {
                    initialValue: kickOffDate === '' ? null : moment(kickOffDate),
                    rules: [{
                      required: true,
                      message: 'Please select kick off date',
                    }],
                  })(
                    <DatePicker 
                      allowClear={false}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'kickOffDate', value: dateString });
                      }} />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Expiry Date">
                  {getFieldDecorator('expiryDate', {
                    initialValue: expiryDate === '' ? null : moment(expiryDate),
                    rules: [{
                      required: true,
                      message: 'Please select expiry date',
                    }],
                  })(
                    <DatePicker 
                      allowClear={false}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'expiryDate', value: dateString });
                      }} 
                      />
                   )
                  } 
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="List Type">
                  <Radio.Group options={options} defaultValue={isWhite} onChange={this.radioGroupOnChanged} />
                </FormItem>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ respErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const RestrictedSectorEditDialogForm = Form.create({ name: 'restricted_sector_edit_dialog' })(RestrictedSectorEditDialog);

export default RestrictedSectorEditDialogForm;