import React, { Component } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


class OperationRecordGrid extends Component {

    // 有效的列
    validColumns = [
        {field: 'module', headerName: 'module', width: 100, cellClass: 'non-number'},
        {field: 'operator', headerName: 'operator', width: 100, cellClass: 'non-number'},
        {field: 'operateTime', headerName: 'operateTime', width: 150, cellClass: 'non-number'},
        {field: 'dataId', headerName: 'dataId', width: 80, cellClass: 'non-number'},
        {field: 'operateSource', headerName: 'operateSource', width: 100, cellClass: 'non-number'},
        {field: 'operateType', headerName: 'operateType', width: 100, cellClass: 'non-number'},
        {field: 'dataType', headerName: 'dataType', width: 100, cellClass: 'non-number'},
        {field: 'otherInfo', headerName: 'otherInfo', width: 100, cellClass: 'non-number'},
        {field: 'comments', headerName: 'comments', width: 250, cellClass: 'non-number'},
        {field: 'before', headerName: 'before', width: 250, cellClass: 'non-number'},
        {field: 'after', headerName: 'after', width: 250, cellClass: 'non-number'},
    ];

    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            context: { componentParent: this },
            columnDefs: [],
        };
        // 自定义展示的列，和“有效的列”求交集
        const customColumns = props.operationRecord.operationRecordDialogModal.customColumns;
        if(!customColumns || customColumns.length === 0) {
            this.state.columnDefs = this.validColumns;
        } else {
            customColumns.forEach(name => {
                const currEleArray = this.validColumns.filter(e=>e.field === name);
                if(!!currEleArray && currEleArray.length > 0) {
                    this.state.columnDefs.push(currEleArray[0]);
                }
            });
        }
        
    }


    onRowClicked = (event) => {
        const selectedRcd = event.data;
        this.props.doOperationRecordAction({action:'SHOW_DETAIL', detail: selectedRcd});
    }


    render() {
        const {
            queryOperationRecordStatus,
            queryOperationRecordResp
        } = this.props.operationRecord;

        const isLoading = queryOperationRecordStatus === ACTION_STATUS.LOGINING;
        const operationRecordArray = !!queryOperationRecordResp && !!queryOperationRecordResp.data ? queryOperationRecordResp.data : [];

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={operationRecordArray}
                            defaultColDef={this.state.defaultColDef}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            rowSelection={'single'}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            // EVENT
                            onRowClicked={this.onRowClicked}
                        />
                    )}
            </div>
        );
    }
}

export default OperationRecordGrid;
