import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load Fund List
  START_LOAD_FUND_LIST,
  LOAD_FUND_LIST_SUCCESS,
  LOAD_FUND_LIST_FAILURE,
  INIT_LOAD_FUND_LIST_ACTION_STATUS,

} from './fundConstants';

const initialState = {
  fundGridColumns: GridColumnMap.fundGridColumns,

  // Fund List
  fundArray: [],

  // Load Fund List Action Status
  loadFundListActionStatus: ACTION_STATUS.READY,
}

// Load Fund List
function startLoadFundList(state) {
  return {
    ...state,
    loadFundListActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadFundListSuccess(state, resp) {
  return {
    ...state,
    loadFundListActionStatus: ACTION_STATUS.SUCCESS,
    fundArray: resp.data,
  };
}

function loadFundListFailure(state, err) {
  return {
    ...state,
    loadFundListActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadFundListActionStatus(state) {
  return {
    ...state,
    loadFundListActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Fund List
  [START_LOAD_FUND_LIST]: startLoadFundList,
  [LOAD_FUND_LIST_SUCCESS]: loadFundListSuccess,
  [LOAD_FUND_LIST_FAILURE]: loadFundListFailure,
  [INIT_LOAD_FUND_LIST_ACTION_STATUS]: initLoadFundListActionStatus,  
});