import React, {Component} from 'react';
import { Breadcrumb,  Button, message, Modal, Divider,Input, DatePicker } from 'antd';
import { localStorageClient } from '../../../../../utils/localStorageClient';
import MonthlyReportDialog from '../components/MonthlyReportDialog'
import MonthlyReportGrid from '../components/MonthlyReportGrid'
import MonthlyReviewDetailPanel from './MonthlyReviewDetailPanel'
import moment from 'moment';
import SplitPane from 'react-split-pane';

const confirm = Modal.confirm;
const { MonthPicker } = DatePicker;
class MonthlyReportDashboard extends Component {

    componentDidMount() {
        this.refreshBtnOnClicked();
    }

    componentDidUpdate() {
    
    }

    addBtnClick = () =>{
        this.props.openMonthlyReportDialog({op:"ADD"});
    }
    openBtnClick = () =>{
        const {
            monthlyReportDetail
        } = this.props;
        const userName = localStorageClient.getUserName();
        if(!monthlyReportDetail) return;
        // if(monthlyReportDetail 
        //     && monthlyReportDetail.createdBy !== userName){
        //     message.error("Only create user can open this record");
        //     return;
        // }
        const yearMonth = moment().subtract(14,"days").format("YYYY-MM");
        // const month = monthlyReportDetail.month;
        // if(yearMonth > month){
        //     message.error("Can't update this record, exceeds modifiable time");
        //     return;
        // }
        // this.props.openMonthlyReportDialog({op:"UPDATE"});
        this.props.openMonthlyReportDialog({op:"Open"});
    }
    
    updateBtnClick = () =>{
        const {
            monthlyReportDetail
        } = this.props;
        // const userName = localStorageClient.getUserName();
        // if(!monthlyReportDetail) return;
        // if(monthlyReportDetail 
        //     && monthlyReportDetail.createdBy !== userName){
        //     message.error("Only create user can edit/del this record");
        //     return;
        // }
        // const yearMonth = moment().subtract(14,"days").format("YYYY-MM");
        // const month = monthlyReportDetail.month;
        // if(yearMonth > month){
        //     message.error("Can't update this record, exceeds modifiable time");
        //     return;
        // }
        this.props.openMonthlyReportDialog({op:"UPDATE"});
    }

    delBtnClick = () =>{
        const {
            monthlyReportDetail,
            queryParams,
            delMonthlyReport
        } = this.props;
        const userName = localStorageClient.getUserName();
        if(monthlyReportDetail && monthlyReportDetail.createdBy !== userName){
            message.error("Only create user can edit/del this record");
            return;
        }
        confirm({
            title: 'Del confirm',
            content: 'Confirm del this record?',
            onOk() {
                delMonthlyReport(monthlyReportDetail,queryParams);
            },
            onCancel() {},
        });
       
    }

    onQueryParamsChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };

    refreshBtnOnClicked = () =>{
        const {
            queryParams
        } = this.props;
        this.props.loadMonthlyReportList(queryParams);
    }

    render() {
        const {
            queryParams
        } = this.props;

        return (
            <div className='ReportingSummaryWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Departments</Breadcrumb.Item>
                        <Breadcrumb.Item>Monthly Report</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                <div className='breadcrumbWrapper'>   
                    <Breadcrumb>
                    <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                    </Breadcrumb>
                    </div>
                    <Input 
                        placeholder="Created by"
                        size="small"
                        style={{ width: 130, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.props.onQueryInputChange({ name: 'createBy', value: event.target.value })
                        }} 
                    />
                    <MonthPicker
                        placeholder={'month start'}
                        allowClear={false}
                        value={moment(
                            queryParams.monthStart,
                            'YYYY-MM'
                        )}
                        size="small"
                        onChange={(date, dateString) => {
                          this.onQueryParamsChange({
                            name: 'monthStart',
                            value: dateString
                          });
                        }}
                        style={{marginLeft: '10px'}}
                    />

                    <MonthPicker
                      placeholder={'month end'}
                      allowClear={false}
                      value={moment(
                        queryParams.monthTo,
                        'YYYY-MM'
                      )}
                      size="small"
                      onChange={(date, dateString) => {
                        this.onQueryParamsChange({
                          name: 'monthTo',
                          value: dateString
                        });
                      }}
                      style={{marginLeft: '10px'}}
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        size='small'
                        type='primary'
                    >Search</Button>
                    <Button 
                        onClick={()=>this.addBtnClick()} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.updateBtnClick()} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    {/* <Button 
                        onClick={()=>this.openBtnClick()} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Open
                    </Button> */}
                    <Button 
                        onClick={()=>this.delBtnClick()} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>
                </div>
                {/* <SplitPane
                    split="vertical"
                    defaultSize="37%"
                    style={{ position: 'relative', height: '100%' }}
                > */}
                    <MonthlyReportGrid {...this.props}/>
                    {/* <MonthlyReviewDetailPanel {...this.props}/> */}
                {/* </SplitPane> */}
                <MonthlyReportDialog {...this.props}/>
            </div>
        );
    }
}

export default MonthlyReportDashboard;
