import React from 'react';
import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GridColumnMap from '../GridColumnMap';

import {
  // Sync Short Stock
  START_SYNC_SHORT_STOCK,
  SYNC_SHORT_STOCK_SUCCESS,
  SYNC_SHORT_STOCK_FAILURE,
  INIT_SYNC_SHORT_STOCK_ACTION_STATUS,

  // Load Short Stock
  START_LOAD_SHORT_STOCK,
  LOAD_SHORT_STOCK_SUCCESS,
  LOAD_SHORT_STOCK_FAILURE,
  INIT_LOAD_SHORT_STOCK_ACTION_STATUS,

} from './shortStockConstants';

const initialState = {
  // Grdi Columns
  shortStockMetaGridColumns: GridColumnMap.shortStockMetaGridColumns,
  shortStockGridColumns: GridColumnMap.shortStockGridColumns,

  // Short Stock
  shortStockArray: [],

  // Load Short Stock Action Status
  loadShortStockActionStatus: ACTION_STATUS.READY,
  loadShortStockErrMsg: '',

  // Sync Short Stock Action Status
  syncShortStockActionStatus: ACTION_STATUS.READY,
  syncShortStockErrMsg: '',
} 


// Sync Short Position Report
function startSyncShortStock(state) {
  return {
    ...state,
    syncShortStockActionStatus: ACTION_STATUS.LOGINING,
  };
}

function syncShortStockSuccess(state, resp) {
  return {
    ...state,
    syncShortStockActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function syncShortStockFailure(state, err) {
  return {
    ...state,
    syncShortStockActionStatus: ACTION_STATUS.ERROR,
    syncShortStockErrMsg: err.respMessage,
  };
}

function initSyncShortStockActionStatus(state) {
  return {
    ...state,
    syncShortStockActionStatus: ACTION_STATUS.READY,
  };
}

// Load Short Stock
function startLoadShortStock(state) {
  return {
    ...state,
    loadShortStockActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadShortStockSuccess(state, resp) {
  return {
    ...state,
    loadShortStockActionStatus: ACTION_STATUS.SUCCESS,
    shortStockArray: resp.data,
  };
}

function loadShortStockFailure(state, err) {
  return {
    ...state,
    loadShortStockActionStatus: ACTION_STATUS.ERROR,
    loadShortStockErrMsg: err.respMessage,
  };
}

function initLoadShortStockActionStatus(state) {
  return {
    ...state,
    loadShortStockActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Sync Short Stock
  [START_SYNC_SHORT_STOCK]: startSyncShortStock,
  [SYNC_SHORT_STOCK_SUCCESS]: syncShortStockSuccess,
  [SYNC_SHORT_STOCK_FAILURE]: syncShortStockFailure,
  [INIT_SYNC_SHORT_STOCK_ACTION_STATUS]: initSyncShortStockActionStatus,

  // Load Short Stock
  [START_LOAD_SHORT_STOCK]: startLoadShortStock,
  [LOAD_SHORT_STOCK_SUCCESS]: loadShortStockSuccess,
  [LOAD_SHORT_STOCK_FAILURE]: loadShortStockFailure,
  [INIT_LOAD_SHORT_STOCK_ACTION_STATUS]: initLoadShortStockActionStatus,
});