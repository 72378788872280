
const tradesReviewGridColumns = [
    {
      field: 'tradeDate',
      headerName: "Trade Date",
      cellClass: 'non-number',
      width: 80,
    },
    {
        field: 'fund',
        headerName: "Fund",
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'team',
        headerName: "Team",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'assetClass',
        headerName: "Asset Class",
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'ticker',
        headerName: "Ticker",
        cellClass: 'non-number',
        width: 180,
    },
    {
        field: 'market',
        headerName: "Market",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'stockName',
        headerName: "Stock Name",
        cellClass: 'non-number',
        width: 250,
    },
    {
        field: 'direction',
        headerName: "Direction",
        cellClass: 'non-number',
        width: 60,
    },
    {
        field: 'qtyFilled',
        headerName: "Qty Filled",
        cellClass: 'non-number',
        width: 60,
        comparator: (a,b) => {
            const floatA = parseFloat(a);
            const floatB = parseFloat(b);
            const result = floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
            return result;
        }
    },
    {
        field: 'executionPrice',
        headerName: "Execution Price",
        cellClass: 'non-number',
        width: 150,
        comparator: (a,b) => {
            const floatA = parseFloat(a);
            const floatB = parseFloat(b);
            const result = floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
            return result;
        }
    },
    {
        field: 'lastPrice',
        headerName: "Last Price",
        cellClass: 'non-number',
        width: 100,
        comparator: (a,b) => {
            const floatA = parseFloat(a);
            const floatB = parseFloat(b);
            const result = floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
            return result;
        }
    },
    {
        field: 'pmOrderTime',
        headerName: "Order Time",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'traderExecuteTime',
        headerName: "Trader Exec Time",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'pmReason',
        headerName: "Reason",
        cellClass: 'non-number',
        width: 250,
    },
];


const tradesReviewReportGridColumns = [
    {
      field: 'fund',
      headerName: "fund",
      cellClass: 'non-number',
      width: 80,
    },
    {
        field: 'market',
        headerName: "market",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'tradeDate',
        headerName: "tradeDate",
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'assetClass',
        headerName: "assetClass",
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'direction',
        headerName: "direction",
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'localCurrency',
        headerName: "localCurrency",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'sharesTradedPlain',
        headerName: "No. of shares traded",
        cellClass: 'non-number',
        width: 180,
        comparator: (a,b) => {
            const floatA = parseFloat(a);
            const floatB = parseFloat(b);
            const result = floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
            return result;
        }
    },
    {
        field: 'mvLocalPlain',
        headerName: "MV traded Local",
        cellClass: 'non-number',
        width: 180,
        comparator: (a,b) => {
            const floatA = parseFloat(a);
            const floatB = parseFloat(b);
            const result = floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
            return result;
        }
    },
    {
        field: 'mvUSDPlain',
        headerName: "MV USD",
        cellClass: 'non-number',
        width: 180,
        comparator: (a,b) => {
            const floatA = parseFloat(a);
            const floatB = parseFloat(b);
            const result = floatA > floatB ? 1 : (floatA < floatB ? -1 : 0);
            return result;
        }
    }
];


const GridColumnMap = {
    tradesReviewGridColumns,
    tradesReviewReportGridColumns,
};
  

export default GridColumnMap;
