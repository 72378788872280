import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_MEETING_AND_TRAINING_INFO,
    LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS,
    LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
    INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    // select
    SELECT_MEETING_AND_TRAINING_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_ADD_OR_EDIT_MEETING_AND_TRAINING,
    ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
    ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
    INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_MEETING_AND_TRAINING_MODAL,
    DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_DEL_MEETING_AND_TRAINING,
    DEL_MEETING_AND_TRAINING_SUCCESS,
    DEL_MEETING_AND_TRAINING_FAILURE,
    INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_DEL_MEETING_AND_TRAINING_MODAL,

    // COP
    OPEN_COP_MEETING_AND_TRAINING_MODAL,
    COP_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_COP_MEETING_AND_TRAINING,
    COP_MEETING_AND_TRAINING_SUCCESS,
    COP_MEETING_AND_TRAINING_FAILURE,
    INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
    RESET_COP_MEETING_AND_TRAINING_MODAL,


    ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
    ANSWEREE_SELECT_MODAL_DIALOG_RESET,

      // Select All Answeree
    SELECT_ALL_ANSWEREE,

    // Select Invert Answeree
    SELECT_INVERT_ANSWEREE,
      // Answeree Check Changed
    ANSWEREE_CHECK_CHANGED,

    // Open/Reset Questionnaire Email Edit Modal Dialog
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
    QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET,


    // Select Answerees
    START_SELECT_ANSWEREES,
    SELECT_ANSWEREES_SUCCESS,
    SELECT_ANSWEREES_FAILURE,
    INIT_SELECT_ANSWEREES_ACTION_STATUS,



} from './MeetingAndTrainingGroupConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadMeetingAndTrainingGroupInfo() {
    return {
      type: START_LOAD_MEETING_AND_TRAINING_INFO,
    };
}
export function loadMeetingAndTrainingGroupInfo(params) {
    return function(dispatch) {
        dispatch(startLoadMeetingAndTrainingGroupInfo());
        httpClient
          .get(UrlConfig.LOAD_MEETING_AND_TRAINING_GROUP_INFO, params)
          .then(resp => dispatch(loadMeetingAndTrainingGroupInfoSuccess(resp)))
          .catch(err => dispatch(loadMeetingAndTrainingGroupInfoFailure(err)));
    };
}
function loadMeetingAndTrainingGroupInfoSuccess(resp) {
    return {
      type: LOAD_MEETING_AND_TRAINING_INFO_GROUP_SUCCESS,
      payload: resp,
    };
}
function loadMeetingAndTrainingGroupInfoFailure(err) {
    return {
        type: LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadMeetingAndTrainingGroupInfoActionStatus() {
    return {
      type: INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    };
}

// select
export function selectMeetingAndTrainingGroupInfo(array) {
    return {
        type: SELECT_MEETING_AND_TRAINING_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditMeetingAndTrainingGroupModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditMeetingAndTrainingGroupChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditMeetingAndTrainingGroup() {
    return {
        type: START_ADD_OR_EDIT_MEETING_AND_TRAINING,
    };
}
export function addOrEditMeetingAndTrainingGroup(params) {
      return function(dispatch) {
        dispatch(startAddOrEditMeetingAndTrainingGroup());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_MEETING_AND_TRAINING_GROUP, params)
          .then(resp => dispatch(addOrEditMeetingAndTrainingGroupSuccess(resp)))
          .catch(err => dispatch(addOrEditMeetingAndTrainingGroupFailure(err)));
      };
}
function addOrEditMeetingAndTrainingGroupSuccess(resp) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_SUCCESS,
        payload: resp,
    };
}
function addOrEditMeetingAndTrainingGroupFailure(err) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_FAILURE,
        payload: err,
    };
}
export function initAddOrEditMeetingAndTrainingGroupActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_MEETING_AND_TRAINING_ACTION_STATUS,
    };
}
export function resetAddOrEditMeetingAndTrainingGroupModal() {
    return {
        type: RESET_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelMeetingAndTrainingGroupModal(info,mode) {
    return {
        type: OPEN_DEL_MEETING_AND_TRAINING_MODAL,
        payload: 
        {
            info,
            mode,
        },
    };  
}
export function delMeetingAndTrainingGroupChangeInput({name, value}) {
    return {
        type: DEL_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelMeetingAndTrainingGroup() {
    return {
        type: START_DEL_MEETING_AND_TRAINING,
    };
}
export function delMeetingAndTrainingGroup(params) {
      return function(dispatch) {
        dispatch(startDelMeetingAndTrainingGroup());
        httpClient
          .get(UrlConfig.DEL_MEETING_AND_TRAINING_GROUP, params)
          .then(resp => dispatch(delMeetingAndTrainingGroupSuccess(resp)))
          .catch(err => dispatch(delMeetingAndTrainingGroupFailure(err)));
      };
}
function delMeetingAndTrainingGroupSuccess(resp) {
    return {
        type: DEL_MEETING_AND_TRAINING_SUCCESS,
        payload: resp,
    };
}
function delMeetingAndTrainingGroupFailure(err) {
    return {
        type: DEL_MEETING_AND_TRAINING_FAILURE,
        payload: err,
    };
}
export function initDelMeetingAndTrainingGroupActionStatus() {
    return {
        type: INIT_DEL_MEETING_AND_TRAINING_ACTION_STATUS,
    };
}
export function resetDelMeetingAndTrainingGroupModal() {
    return {
        type: RESET_DEL_MEETING_AND_TRAINING_MODAL,
    };
}












//cop
export function openCopMeetingAndTrainingGroupModal(info) {
    return {
        type: OPEN_COP_MEETING_AND_TRAINING_MODAL,
        payload: info,
    };  
}
export function copMeetingAndTrainingGroupChangeInput({name, value}) {
    return {
        type: COP_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startCopMeetingAndTrainingGroup() {
    return {
        type: START_COP_MEETING_AND_TRAINING,
    };
}
export function copMeetingAndTrainingGroup(params) {
      return function(dispatch) {
        dispatch(startCopMeetingAndTrainingGroup());
        httpClient
          .get(UrlConfig.COP_ALL_MEETING_AND_TRAINING, params)
          .then(resp => dispatch(copMeetingAndTrainingGroupSuccess(resp)))
          .catch(err => dispatch(copMeetingAndTrainingGroupFailure(err)));
      };
}
function copMeetingAndTrainingGroupSuccess(resp) {
    return {
        type: COP_MEETING_AND_TRAINING_SUCCESS,
        payload: resp,
    };
}
function copMeetingAndTrainingGroupFailure(err) {
    return {
        type: COP_MEETING_AND_TRAINING_FAILURE,
        payload: err,
    };
}
export function initCopMeetingAndTrainingGroupActionStatus() {
    return {
        type: INIT_COP_MEETING_AND_TRAINING_ACTION_STATUS,
    };
}
export function resetCopMeetingAndTrainingGroupModal() {
    return {
        type: RESET_COP_MEETING_AND_TRAINING_MODAL,
    };
}
export function openAnswereeSelectModalDialog() {
    return {
      type: ANSWEREE_SELECT_MODAL_DIALOG_OPEN,
      payload: {
        
      },
    };
}
export function resetAnswereeSelectModalDialog() {
    return {
      type: ANSWEREE_SELECT_MODAL_DIALOG_RESET
    };
  }








// Select All Answeree
export function selectAllAnsweree() {
    return {
      type: SELECT_ALL_ANSWEREE
    };
  }
  
  // Select Invert Answeree
  export function selectInvertAnsweree() {
    return {
      type: SELECT_INVERT_ANSWEREE
    };
  }

  // Answeree Check Changed
export function answereeCheckChanged(checked ,userAccountName) {
    return {
      type: ANSWEREE_CHECK_CHANGED,
      payload: {
        checked,
        userAccountName
      }
    };
  }

  // Open/Reset Questionnaire Email Edit Modal Dialog
export function openQuestionnaireEmailEditModalDialog(sendMode, emailPayload) {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_OPEN,
      payload: {
        sendMode,
        emailPayload,
      },
    };
  }
  
  export function resetQuestionnaireEmailEditModalDialog() {
    return {
      type: QUESTIONNAIRE_EMAIL_EDIT_MODAL_DIALOG_RESET
    };
  }

// Select Answerees
function startSelectAnswerees() {
    return {
      type: START_SELECT_ANSWEREES
    };
  }
  export function selectAnswerees(userAccountNameList, questionnaireGuid, emailTitle, emailContent) {
    const requestParams = {
      userAccountNameList,
      questionnaireGuid, 
      emailTitle,
      emailContent
    };
  
    return function(dispatch) {
      dispatch(startSelectAnswerees());
      httpClient
        .post(UrlConfig.SELECT_MEETINGANSWEREES, requestParams)
        .then(resp => dispatch(selectAnswereesSuccess(resp)))
        .catch(err => dispatch(selectAnswereesFailure(err)));
    };
  }
  
  function selectAnswereesSuccess(resp) {
    return {
      type: SELECT_ANSWEREES_SUCCESS,
      payload: resp
    };
  }
  
  function selectAnswereesFailure(err) {
    return {
      type: SELECT_ANSWEREES_FAILURE,
      payload: err
    };
  }


export function initSelectAnswereesActionStatus() {
    return {
      type: INIT_SELECT_ANSWEREES_ACTION_STATUS
    };
  }