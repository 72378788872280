import React, {Component, PropTypes} from 'react';

import { Breadcrumb } from 'antd';
import BookGrid from './BookGrid';
import '../../EntityAccount.css';

class BookDashboard extends Component {

  componentDidMount() {
    this.props.loadBookList();
  }

  render() {
    return (
      <div className="bookWrapper">

        <div className='breadcrumbWrapper'>
          <Breadcrumb>
            <Breadcrumb.Item>Entity & Account</Breadcrumb.Item>
            <Breadcrumb.Item>Book List</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
        <div className='bookGrid'>
          <BookGrid {...this.props} />
        </div>
      </div>
    );
  }
}

export default BookDashboard;