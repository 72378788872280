import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_PORTFOLIO_MONITORING_RULE_QUERY_INPUT_CHANGE,
    START_LOAD_PORTFOLIO_MONITORING_RULE_INFO,
    LOAD_PORTFOLIO_MONITORING_RULE_INFO_SUCCESS,
    LOAD_PORTFOLIO_MONITORING_RULE_INFO_FAILURE,
    INIT_LOAD_PORTFOLIO_MONITORING_RULE_INFO_ACTION_STATUS,
    // select
    SELECT_PORTFOLIO_MONITORING_RULE,
    // add or edit
    OPEN_ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_MODAL,
    ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_MODAL,
    START_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE,
    SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_SUCCESS,
    SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_FAILURE,
    INIT_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL,
    ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL,
    START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE,
    INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE,
    BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_ACTION_STATUS,
    // finish
    SELECT_PORTFOLIO_MONITORING_RULE_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_PORTFOLIO_MONITORING_RULE_TASK,
    FINISH_PORTFOLIO_MONITORING_RULE_TASK_SUCCESS,
    FINISH_PORTFOLIO_MONITORING_RULE_TASK_FAILURE,
    INIT_FINISH_PORTFOLIO_MONITORING_RULE_TASK_ACTION_STATUS,


} from './PortfolioMonitoringRuleConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_PORTFOLIO_MONITORING_RULE_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadPortfolioMonitoringRuleInfo() {
    return {
      type: START_LOAD_PORTFOLIO_MONITORING_RULE_INFO,
    };
}

function getQueryReqParams(params) {
    let ruleStatus = params.ruleStatus !== undefined && params.ruleStatus !== null && params.ruleStatus !== ''
    ? params.ruleStatus: '';
    let busDate = params.busDate !== undefined && params.busDate !== null && params.busDate !== ''
    ? params.busDate.format("YYYY-MM-DD") : '';
    const requestParams = {
    ruleStatus: ruleStatus,
    busDate: busDate,
    };
    return requestParams;
}

export function loadPortfolioMonitoringRuleInfo(params) {

    const requestParams = getQueryReqParams(params);

    return function(dispatch) {
        dispatch(startLoadPortfolioMonitoringRuleInfo());
        httpClient
          .get(UrlConfig.LOAD_PORTFOLIO_MONITORING_RULE_INFO, requestParams)
          .then(resp => dispatch(loadPortfolioMonitoringRuleInfoSuccess(resp)))
          .catch(err => dispatch(loadPortfolioMonitoringRuleInfoFailure(err)));
    };
}
function loadPortfolioMonitoringRuleInfoSuccess(resp) {
    return {
      type: LOAD_PORTFOLIO_MONITORING_RULE_INFO_SUCCESS,
      payload: resp,
    };
}
function loadPortfolioMonitoringRuleInfoFailure(err) {
    return {
        type: LOAD_PORTFOLIO_MONITORING_RULE_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadPortfolioMonitoringRuleInfoActionStatus() {
    return {
      type: INIT_LOAD_PORTFOLIO_MONITORING_RULE_INFO_ACTION_STATUS,
    };
}


// select
export function selectPortfolioMonitoringRule(array) {
    return {
        type: SELECT_PORTFOLIO_MONITORING_RULE,
        payload: array,
    };
}


// add or edit
export function openAddOrEditPortfolioMonitoringRuleModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditPortfolioMonitoringRuleChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_PORTFOLIO_MONITORING_RULE_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSavePortfolioMonitoringRuleModal() {
    return {
        type: RESET_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_MODAL,
    };
}

function startSubmitSavePortfolioMonitoringRule() {
    return {
        type: START_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE,
    };
}
export function submitSavePortfolioMonitoringRule(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSavePortfolioMonitoringRule());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_PORTFOLIO_MONITORING_RULE;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_PORTFOLIO_MONITORING_RULE;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSavePortfolioMonitoringRuleSuccess(resp)))
            .catch(err => dispatch(submitSavePortfolioMonitoringRuleFailure(err)));        
    };
}
function submitSavePortfolioMonitoringRuleSuccess(resp) {
    return {
        type: SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_SUCCESS,
        payload: resp,
    };
}
export function submitSavePortfolioMonitoringRuleFailure(err) {
    return {
        type: SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_FAILURE,
        payload: err,
    };
}
export function initSubmitSavePortfolioMonitoringRuleActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_PORTFOLIO_MONITORING_RULE_ACTION_STATUS,
    };
}


//sub add or edit
export function openAddOrEditWorkManagementSubModal(mode, data,idList) {
    return {
        type: OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL,
        payload: {
            mode,
            data,
            idList,
        },
    };
}
export function addOrEditWorkManagementSubChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveWorkManagementSubModal() {
    return {
        type: RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL,
    };
}

function startSubmitSaveWorkManagementSub() {
    return {
        type: START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB,
    };
}
export function submitSaveWorkManagementSub(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveWorkManagementSub());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_WORKING_MANAGEMENTSUB;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_WORKING_MANAGEMENTSUB;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveWorkManagementSubSuccess(resp)))
            .catch(err => dispatch(submitSaveWorkManagementSubFailure(err)));        
    };
}
function submitSaveWorkManagementSubSuccess(resp) {
    return {
        type: SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS,
        payload: resp,
    };
}
export function submitSaveWorkManagementSubFailure(err) {
    return {
        type: SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveWorkManagementSubActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS,
    };
}



// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForPortfolioMonitoringRule() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE,
    };
}
export function batchChangeStatusForPortfolioMonitoringRule(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForPortfolioMonitoringRule());
        httpClient
            .post(UrlConfig.BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE, params)
            .then(resp => dispatch(batchChangeStatusForPortfolioMonitoringRuleSuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForPortfolioMonitoringRuleFailure(err)));        
    };
}
function batchChangeStatusForPortfolioMonitoringRuleSuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForPortfolioMonitoringRuleFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForPortfolioMonitoringRuleActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_PORTFOLIO_MONITORING_RULE_ACTION_STATUS,
    };
}


// finish
export function selectPortfolioMonitoringRuleTask(array) {
    return {
        type: SELECT_PORTFOLIO_MONITORING_RULE_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishPortfolioMonitoringRuleTask() {
    return {
        type: START_FINISH_PORTFOLIO_MONITORING_RULE_TASK,
    };
}
export function finishPortfolioMonitoringRuleTask(params) {
    return function(dispatch) {
        dispatch(startFinishPortfolioMonitoringRuleTask());
        httpClient
            .post(UrlConfig.FINISH_PORTFOLIO_MONITORING_RULE_TASK, params)
            .then(resp => dispatch(finishPortfolioMonitoringRuleTaskSuccess(resp)))
            .catch(err => dispatch(finishPortfolioMonitoringRuleTaskFailure(err)));        
    };
}
function finishPortfolioMonitoringRuleTaskSuccess(resp) {
    return {
        type: FINISH_PORTFOLIO_MONITORING_RULE_TASK_SUCCESS,
        payload: resp,
    };
}
function finishPortfolioMonitoringRuleTaskFailure(err) {
    return {
        type: FINISH_PORTFOLIO_MONITORING_RULE_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishPortfolioMonitoringRuleTaskActionStatus() {
    return {
        type: INIT_FINISH_PORTFOLIO_MONITORING_RULE_TASK_ACTION_STATUS,
    };
}

