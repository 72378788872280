// query
export const ON_QUERY_INPUT_CHANGE = 'ON_QUERY_INPUT_CHANGE';
export const START_LOAD_USER_ACCESS_INFO = 'START_LOAD_USER_ACCESS_INFO';
export const LOAD_USER_ACCESS_INFO_SUCCESS = 'LOAD_USER_ACCESS_INFO_SUCCESS';
export const LOAD_USER_ACCESS_INFO_FAILURE = 'LOAD_USER_ACCESS_INFO_FAILURE';
export const INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS = 'INIT_LOAD_USER_ACCESS_INFO_ACTION_STATUS';
// select
export const SELECT_USER_ACCESS_INFO = 'SELECT_USER_ACCESS_INFO';
// add or edit
export const OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL = 'OPEN_ADD_OR_EDIT_USER_ACCESS_MODAL';
export const ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT = 'ADD_OR_EDIT_USER_ACCESS_CHANGE_INPUT';
export const START_ADD_OR_EDIT_USER_ACCESS = 'START_ADD_OR_EDIT_USER_ACCESS';
export const ADD_OR_EDIT_USER_ACCESS_SUCCESS = 'ADD_OR_EDIT_USER_ACCESS_SUCCESS';
export const ADD_OR_EDIT_USER_ACCESS_FAILURE = 'ADD_OR_EDIT_USER_ACCESS_FAILURE';
export const INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS = 'INIT_ADD_OR_EDIT_USER_ACCESS_ACTION_STATUS';
export const RESET_ADD_OR_EDIT_USER_ACCESS_MODAL = 'RESET_ADD_OR_EDIT_USER_ACCESS_MODAL';
// refresh fx and amtUSD
export const START_REFRESH_FX = 'START_REFRESH_FX';
export const REFRESH_FX_SUCCESS = 'REFRESH_FX_SUCCESS';
export const REFRESH_FX_FAILURE = 'REFRESH_FX_FAILURE';
export const INIT_REFRESH_FX_ACTION_STATUS = 'INIT_REFRESH_FX_ACTION_STATUS';
export const REFRESH_AMT_USD = 'REFRESH_AMT_USD';
// del
export const OPEN_DEL_USER_ACCESS_MODAL = 'OPEN_DEL_USER_ACCESS_MODAL';
export const DEL_USER_ACCESS_CHANGE_INPUT = 'DEL_USER_ACCESS_CHANGE_INPUT';
export const START_DEL_USER_ACCESS = 'START_DEL_USER_ACCESS';
export const DEL_USER_ACCESS_SUCCESS = 'DEL_USER_ACCESS_SUCCESS';
export const DEL_USER_ACCESS_FAILURE = 'DEL_USER_ACCESS_FAILURE';
export const INIT_DEL_USER_ACCESS_ACTION_STATUS = 'INIT_DEL_USER_ACCESS_ACTION_STATUS';
export const RESET_DEL_USER_ACCESS_MODAL = 'RESET_DEL_USER_ACCESS_MODAL';

// cop
export const OPEN_COP_USER_ACCESS_MODAL = 'OPEN_COP_USER_ACCESS_MODAL';
export const COP_USER_ACCESS_CHANGE_INPUT = 'COP_USER_ACCESS_CHANGE_INPUT';
export const START_COP_USER_ACCESS = 'START_COP_USER_ACCESS';
export const COP_USER_ACCESS_SUCCESS = 'COP_USER_ACCESS_SUCCESS';
export const COP_USER_ACCESS_FAILURE = 'COP_USER_ACCESS_FAILURE';
export const INIT_COP_USER_ACCESS_ACTION_STATUS = 'INIT_COP_USER_ACCESS_ACTION_STATUS';
export const RESET_COP_USER_ACCESS_MODAL = 'RESET_COP_USER_ACCESS_MODAL';



export const LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS = 'LOAD_RESTRICTED_SECURITIES_RULE_SUCCESS';
export const LOAD_RESTRICTED_SECURITIES_RULE_FAILURE = 'LOAD_RESTRICTED_SECURITIES_RULE_FAILURE';



export const OPEN_FINISH_TASK_MODAL = 'OPEN_FINISH_TASK_MODAL';

export const OPEN_OPERATION_RECORD_DIALOG = 'OPEN_OPERATION_RECORD_DIALOG';
export const START_QUERY_OPERATION_RECORD = 'START_QUERY_OPERATION_RECORD';
export const QUERY_OPERATION_RECORD_SUCESS = 'QUERY_OPERATION_RECORD_SUCESS';
export const QUERY_OPERATION_RECORD_FAILURE = 'QUERY_OPERATION_RECORD_FAILURE';
export const INIT_QUERY_OPERATION_RECORD_STATUS = 'INIT_QUERY_OPERATION_RECORD_STATUS';
export const RESET_OPERATION_RECORD_DIALOG = 'RESET_OPERATION_RECORD_DIALOG';
// detail
export const SHOW_DETAIL_RCD = 'SHOW_DETAIL_RCD';
export const RESET_DETAIL_RCD = 'RESET_DETAIL_RCD';
