// change input
export const ON_ACCOUNT_QUERY_INPUT_CHANGE = 'ON_ACCOUNT_QUERY_INPUT_CHANGE';
export const ON_ACCOUNT_INPUT_CHANGE = 'ON_ACCOUNT_INPUT_CHANGE';
export const ON_DEL_AUDIT_ACCOUNT_INPUT_CHANGE = 'ON_DEL_AUDIT_ACCOUNT_INPUT_CHANGE';
export const ON_ADD_OR_EDIT_HOLDING_INPUT_CHANGE = 'ON_ADD_OR_EDIT_HOLDING_INPUT_CHANGE';
export const ON_DEL_HOLDING_INPUT_CHANGE = 'ON_DEL_HOLDING_INPUT_CHANGE';
// Load Personal Account
export const START_LOAD_PERSONAL_ACCOUNT = 'START_LOAD_PERSONAL_ACCOUNT';
export const LOAD_PERSONAL_ACCOUNT_SUCCESS = 'LOAD_PERSONAL_ACCOUNT_SUCCESS';
export const LOAD_PERSONAL_ACCOUNT_FAILURE = 'LOAD_PERSONAL_ACCOUNT_FAILURE';
export const INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS = 'INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS';
export const LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_SUCCESS = 'LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_SUCCESS';
export const LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_FAILURE = 'LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_FAILURE';
// select data
export const SELECT_PERSONAL_ACCOUNT_LIST = 'SELECT_PERSONAL_ACCOUNT_LIST';
export const SET_GRID_EVENT_ON_STATE = 'SET_GRID_EVENT_ON_STATE';
export const SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED = 'SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED';
export const SELECT_PERSONAL_HOLDING_LIST = 'SELECT_PERSONAL_HOLDING_LIST';
export const SET_DETAIL_GRID_EVENT_ON_STATE = 'SET_DETAIL_GRID_EVENT_ON_STATE';
// add or edit account
export const OPEN_ADD_OR_EDIT_ACCOUNT_DIALOG = 'OPEN_ADD_OR_EDIT_ACCOUNT_DIALOG';
export const RESET_ADD_OR_EDIT_ACCOUNT_DIALOG = 'RESET_ADD_OR_EDIT_ACCOUNT_DIALOG';
export const START_SUBMIT_ADD_OR_EDIT_ACCOUNT = 'START_SUBMIT_ADD_OR_EDIT_ACCOUNT';
export const SUBMIT_ADD_OR_EDIT_ACCOUNT_SUCCESS = 'SUBMIT_ADD_OR_EDIT_ACCOUNT_SUCCESS';
export const SUBMIT_ADD_OR_EDIT_ACCOUNT_FAILURE = 'SUBMIT_ADD_OR_EDIT_ACCOUNT_FAILURE';
export const INIT_SUBMIT_ADD_OR_EDIT_ACCOUNT_ACTION_STATUS = 'INIT_SUBMIT_ADD_OR_EDIT_ACCOUNT_ACTION_STATUS';
// del or audit account
export const OPEN_DEL_OR_AUDIT_ACCOUNT_DIALOG = 'OPEN_DEL_OR_AUDIT_ACCOUNT_DIALOG';
export const RESET_DEL_OR_AUDIT_ACCOUNT_DIALOG = 'RESET_DEL_OR_AUDIT_ACCOUNT_DIALOG';
export const START_DEL_PERSONAL_ACCOUNT = 'START_DEL_PERSONAL_ACCOUNT';
export const DEL_PERSONAL_ACCOUNT_SUCCESS = 'DEL_PERSONAL_ACCOUNT_SUCCESS';
export const DEL_PERSONAL_ACCOUNT_FAILURE = 'DEL_PERSONAL_ACCOUNT_FAILURE';
export const INIT_DEL_PERSONAL_ACCOUNT_ACTION_STATUS = 'INIT_DEL_PERSONAL_ACCOUNT_ACTION_STATUS';
export const START_CHANGE_PERSONAL_ACCOUNT_STATUS = 'START_CHANGE_PERSONAL_ACCOUNT_STATUS';
export const CHANGE_PERSONAL_ACCOUNT_STATUS_SUCCESS = 'CHANGE_PERSONAL_ACCOUNT_STATUS_SUCCESS';
export const CHANGE_PERSONAL_ACCOUNT_STATUS_FAILURE = 'CHANGE_PERSONAL_ACCOUNT_STATUS_FAILURE';
export const INIT_CHANGE_PERSONAL_ACCOUNT_STATUS_ACTION_STATUS = 'INIT_CHANGE_PERSONAL_ACCOUNT_STATUS_ACTION_STATUS';
// add or edit holding
export const OPEN_ADD_OR_EDIT_HOLDING_DIALOG = 'OPEN_ADD_OR_EDIT_HOLDING_DIALOG';
export const RESET_ADD_OR_EDIT_HOLDING_DIALOG = 'RESET_ADD_OR_EDIT_HOLDING_DIALOG';
export const START_SUBMIT_ADD_OR_EDIT_HOLDING = 'START_SUBMIT_ADD_OR_EDIT_HOLDING';
export const SUBMIT_ADD_OR_EDIT_HOLDING_SUCCESS = 'SUBMIT_ADD_OR_EDIT_HOLDING_SUCCESS';
export const SUBMIT_ADD_OR_EDIT_HOLDING_FAILURE = 'SUBMIT_ADD_OR_EDIT_HOLDING_FAILURE';
export const INIT_SUBMIT_ADD_OR_EDIT_HOLDING_ACTION_STATUS = 'INIT_SUBMIT_ADD_OR_EDIT_HOLDING_ACTION_STATUS';
// bbgTicker
export const START_LOAD_HOLDING_BBG_TICKERS = 'START_LOAD_HOLDING_BBG_TICKERS';
export const LOAD_HOLDING_BBG_TICKERS_SUCCESS = 'LOAD_HOLDING_BBG_TICKERS_SUCCESS';
export const LOAD_HOLDING_BBG_TICKERS_FAILURE = 'LOAD_HOLDING_BBG_TICKERS_FAILURE';
export const INIT_LOAD_HOLDING_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_HOLDING_BBG_TICKERS_ACTION_STATUS';
export const CLEAR_HOLDING_BBG_TICKERS = 'CLEAR_HOLDING_BBG_TICKERS';
export const HOLDING_BBG_TICKER_SELECT_CHANGED = 'HOLDING_BBG_TICKER_SELECT_CHANGED';
// del holding
export const OPEN_DEL_HOLDING_DIALOG = 'OPEN_DEL_HOLDING_DIALOG';
export const RESET_DEL_HOLDING_DIALOG = 'RESET_DEL_HOLDING_DIALOG';
export const START_DEL_PERSONAL_HOLDING = 'START_DEL_PERSONAL_HOLDING';
export const DEL_PERSONAL_HOLDING_SUCCESS = 'DEL_PERSONAL_HOLDING_SUCCESS';
export const DEL_PERSONAL_HOLDING_FAILURE = 'DEL_PERSONAL_HOLDING_FAILURE';
export const INIT_DEL_PERSONAL_HOLDING_ACTION_STATUS = 'INIT_DEL_PERSONAL_HOLDING_ACTION_STATUS';


