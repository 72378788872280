export const QUESTION_TYPE_CODE = {
  SINGLE_CHOICE: '1',
  MULTIPLE_CHOICE: '2',
  TEXT: '3',
  DATE: '4',
  UPLOAD: '5',
  LIST: '6',
  SUBJECT_LINE: '7',
};

const QUESTION_TYPE_LABEL = {
 
  SINGLE_CHOICE: 'Single Choice',
  MULTIPLE_CHOICE: 'Multiple Choice',
  TEXT: 'Text',
  DATE: 'Date',
  UPLOAD: 'Upload',
  LIST: 'List',
  SUBJECT_LINE: 'Subject Line',
};

export const QUESTION_TYPE_MAP = [
  // Single Choice
  {
    code: QUESTION_TYPE_CODE.SINGLE_CHOICE,
    label: QUESTION_TYPE_LABEL.SINGLE_CHOICE
  },
  // Single Choice
  {
    code: QUESTION_TYPE_CODE.MULTIPLE_CHOICE,
    label: QUESTION_TYPE_LABEL.MULTIPLE_CHOICE
  },
  // Text
  {
    code: QUESTION_TYPE_CODE.TEXT,
    label: QUESTION_TYPE_LABEL.TEXT
  },
  // Date
  {
    code: QUESTION_TYPE_CODE.DATE,
    label: QUESTION_TYPE_LABEL.DATE
  },
  // Upload
  {
    code: QUESTION_TYPE_CODE.UPLOAD,
    label: QUESTION_TYPE_LABEL.UPLOAD
  },
  {
    code: QUESTION_TYPE_CODE.LIST,
    label: QUESTION_TYPE_LABEL.LIST
  },
  {
    code: QUESTION_TYPE_CODE.SUBJECT_LINE,
    label: QUESTION_TYPE_LABEL.SUBJECT_LINE
  },
];