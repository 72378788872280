// Generate Fund Level Report
export const START_GENERATE_FUND_LEVEL_REPORT = 'START_GENERATE_FUND_LEVEL_REPORT';
export const GENERATE_FUND_LEVEL_REPORT_SUCCESS = 'GENERATE_FUND_LEVEL_REPORT_SUCCESS';
export const GENERATE_FUND_LEVEL_REPORT_FAILURE = 'GENERATE_FUND_LEVEL_REPORT_FAILURE';
export const INIT_GENERATE_FUND_LEVEL_REPORT_ACTION_STATUS = 'INIT_GENERATE_FUND_LEVEL_REPORT_ACTION_STATUS';

// Load Fund Level Report
export const START_LOAD_FUND_LEVEL_REPORT = 'START_LOAD_FUND_LEVEL_REPORT';
export const LOAD_FUND_LEVEL_REPORT_SUCCESS = 'LOAD_FUND_LEVEL_REPORT_SUCCESS';
export const LOAD_FUND_LEVEL_REPORT_FAILURE = 'LOAD_FUND_LEVEL_REPORT_FAILURE';
export const INIT_LOAD_FUND_LEVEL_REPORT_ACTION_STATUS = 'INIT_LOAD_FUND_LEVEL_REPORT_ACTION_STATUS';

// Filter Long More Than 2% Fund Level Report
export const FILTER_LONG_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT = 'FILTER_LONG_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT';

// Filter Long More Than 5% Fund Level Report
export const FILTER_LONG_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT = 'FILTER_LONG_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT';

// Filter Short More Than 2% Fund Level Report
export const FILTER_SHORT_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT = 'FILTER_SHORT_MORE_THAN_TWO_PERCENT_FUND_LEVEL_REPORT';

// Filter Short More Than 5% Fund Level Report
export const FILTER_SHORT_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT = 'FILTER_SHORT_MORE_THAN_FIVE_PERCENT_FUND_LEVEL_REPORT';

// Show All Fund Level Report
export const SHOW_ALL_FUND_LEVEL_REPORT = 'SHOW_ALL_FUND_LEVEL_REPORT';


// Load Fund Level Report
export const START_LIST_FUND_POS_GROUP_INFO = 'START_LIST_FUND_POS_GROUP_INFO';
export const LIST_FUND_POS_GROUP_INFO_SUCCESS = 'LIST_FUND_POS_GROUP_INFO_SUCCESS';
export const LIST_FUND_POS_GROUP_INFO_FAILURE = 'LIST_FUND_POS_GROUP_INFO_FAILURE';
export const INIT_LIST_FUND_POS_GROUP_INFO_ACTION_STATUS = 'INIT_LIST_FUND_POS_GROUP_INFO_ACTION_STATUS';

// add grid to container
export const FUND_POSITION_GROUP_INFO_GRID_TO_PROPS = 'FUND_POSITION_GROUP_INFO_GRID_TO_PROPS';
export const FUND_LEVEL_REPORT_GRID_TO_PROPS = 'FUND_LEVEL_REPORT_GRID_TO_PROPS';
