// Generate 13F Long Pos Report
export const START_GENERATE_THIRTEENF_LONG_POS_REPORT = 'START_GENERATE_THIRTEENF_LONG_POS_REPORT';
export const GENERATE_THIRTEENF_LONG_POS_REPORT_SUCCESS = 'GENERATE_THIRTEENF_LONG_POS_REPORT_SUCCESS';
export const GENERATE_THIRTEENF_LONG_POS_REPORT_FAILURE = 'GENERATE_THIRTEENF_LONG_POS_REPORT_FAILURE';
export const INIT_GENERATE_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS = 'INIT_GENERATE_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS';

// Load 13F Long Pos Report
export const START_LOAD_THIRTEENF_LONG_POS_REPORT = 'START_LOAD_THIRTEENF_LONG_POS_REPORT';
export const LOAD_THIRTEENF_LONG_POS_REPORT_SUCCESS = 'LOAD_THIRTEENF_LONG_POS_REPORT_SUCCESS';
export const LOAD_THIRTEENF_LONG_POS_REPORT_FAILURE = 'LOAD_THIRTEENF_LONG_POS_REPORT_FAILURE';
export const INIT_LOAD_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS = 'INIT_LOAD_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS';

// Open/Reset 13F Long Pos Report Check Result Modal Dialog
export const THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN = 'THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_OPEN';
export const THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET = 'THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_MODAL_DIALOG_RESET';

// Load 13F Long Pos Report Check Result
export const START_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT = 'START_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT';
export const LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_SUCCESS = 'LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_SUCCESS';
export const LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_FAILURE = 'LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_FAILURE';
export const INIT_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_ACTION_STATUS = 'INIT_LOAD_THIRTEENF_LONG_POS_REPORT_CHECK_RESULT_ACTION_STATUS';

// Report 13F Long Pos Report
export const START_REPORT_THIRTEENF_LONG_POS_REPORT = 'START_REPORT_THIRTEENF_LONG_POS_REPORT';
export const REPORT_THIRTEENF_LONG_POS_REPORT_SUCCESS = 'REPORT_THIRTEENF_LONG_POS_REPORT_SUCCESS';
export const REPORT_THIRTEENF_LONG_POS_REPORT_FAILURE = 'REPORT_THIRTEENF_LONG_POS_REPORT_FAILURE';
export const INIT_REPORT_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS = 'INIT_REPORT_THIRTEENF_LONG_POS_REPORT_ACTION_STATUS';

// Report 13F Long Pos Summary Report
export const START_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT = 'START_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT';
export const REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_SUCCESS = 'REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_SUCCESS';
export const REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_FAILURE = 'REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_FAILURE';
export const INIT_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_ACTION_STATUS = 'INIT_REPORT_THIRTEENF_LONG_POS_SUMMARY_REPORT_ACTION_STATUS';