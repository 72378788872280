import React, { Component } from 'react';
import { Input, Form, Modal, Button, message,Select,DatePicker } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import moment from 'moment';

const TextArea = Input.TextArea;

class DelMeetingAndTrainingModalDialog extends Component {

    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingQueryFields;
        this.props.loadMeetingAndTrainingInfo({userName, status})
    }
    getPageNameByMode = (mode) => {
        if(mode === 'Append') {
            return 'Append User';
        } else if (mode === 'postponed'){
            return ' postponed end day for this recon ';
        } 
        if(mode === 'send'){
            return 'Send email '; 
        } else {
            return 'DEL Meeting or Training Info';
        }
    }
    componentDidUpdate() {
        const {
            delMeetingAndTrainingStatus,
        } = this.props;
        if(delMeetingAndTrainingStatus === ACTION_STATUS.SUCCESS) {

            const mode = this.props.delMeetingAndTrainingModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initDelMeetingAndTrainingActionStatus();
            this.props.resetDelMeetingAndTrainingModal();
            this.refreshQuery();
        }
        if(delMeetingAndTrainingStatus === ACTION_STATUS.ERROR) {
            this.props.initDelMeetingAndTrainingActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.delMeetingAndTrainingChangeInput({name, value});
    }

    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    submitDelMeetingAndTraining = () => {

        Modal.confirm({
            title: 'Are you sure for this record?',
            onOk: () => {

                const {
                    mode,
                } = this.props.delMeetingAndTrainingModal;
                const {
                    id,
                    comments,
                    endtime,
                    optionalattendees,
                    optionalType,
                } = this.props.delMeetingAndTrainingModal.fields;
                this.props.delMeetingAndTraining({id, mode, comments, endtime, optionalattendees,optionalType});
            },
            onCancel: () => {},
        });
    }


    render() {

        const {
            delMeetingAndTrainingModal,
            delMeetingAndTrainingStatus,
            userGroupOptions,
            optonalOptions,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
        } = delMeetingAndTrainingModal;


        const isSubmitLoading = delMeetingAndTrainingStatus === ACTION_STATUS.LOGINING;
        const modalTitle = this.getPageNameByMode(mode);
        const showDEL = mode === 'send' || mode === 'del';
        const showAppend = mode === 'Append';
        const showPostponed = mode === 'postponed';
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={400}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetDelMeetingAndTrainingModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetDelMeetingAndTrainingModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitDelMeetingAndTraining}>
                          Submit
                        </Button>,
                      ]}
                >
                    {
                     showDEL && 
                     <div>
                        <TextArea 
                            style={{ width: 400 }}
                            rows={3} 
                            onChange={(e) => {
                                this.onInputChange({ name: 'comments', value: e.target.value })
                            }}
                        />
                    </div>
                    }
                    {
                     showAppend && 
                     <div>
                        <Select 
                            allowClear
                            mode='multiple'
                            style={{ width: 200, marginLeft: 10 }}
                            placeholder='Append User' 
                            onChange={(val) => {
                                this.onEmailInputChange({ name: 'optionalattendees', value: val })
                            }}>
                            {userGroupOptions}
                        </Select>    
                        <Select 
                            allowClear
                            mode='multiple'
                            style={{ width: 200, marginLeft: 10 ,marginTop:10}}
                            placeholder='Optonal Or Required' 
                            onChange={(val) => {
                                this.onEmailInputChange({ name: 'optionalType', value: val })
                            }}>
                            {optonalOptions}
                        </Select>                                                             
                    </div>
                    }
                    {
                     showPostponed && 
                     <div>
                        <DatePicker 
                        style={{ width: 200, marginLeft: 10 }}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        // value={!endtime ? null : moment(endtime)}
                        allowClear={false}
                        // disabledDate={(currentDate1) => {
                        //     return currentDate1.isBefore(moment().format("YYYY-MM-DD"));
                        //   }}
                        onChange={(date, dateString) => {
                            this.onInputChange({ name: 'endtime', value: dateString });
                        }} />
                    </div>
                    }
                        {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                        }
                </Modal>
                
            </div>
        );
    }

}

const DelMeetingAndTrainingModalDialogForm = Form.create({ name: 'DelMeetingAndTrainingModalDialog' })(DelMeetingAndTrainingModalDialog);
export default DelMeetingAndTrainingModalDialogForm;
