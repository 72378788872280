import {connect} from 'react-redux';
import RestrictedSector from '../components/RestrictedSector';

import {
  // Load Restricted Sector
  loadRestrictedSectors,
  initLoadRestrictedSectorsActionStatus,

  // Create Restricted Sector
  createRestrictedSector,
  initCreateRestrictedSectorActionStatus,

  // Update Restricted Sector
  updateRestrictedSector,
  initUpdateRestrictedSectorActionStatus,

  // Batch Delete Restricted Sector
  batchDeleteRestrictedSector,
  initBatchDeleteRestrictedSectorActionStatus,

  // RadioGroup Change
  radioGroupChange,

  // Open/Reset Restricted Sector Modal Dialog
  openRestrictedSectorEditModalDialog,
  resetRestrictedSectorEditModalDialog,

  // Restricted Sector Edit Change Input
  restrictedSectorEditChangeInput,

  // select Restricted Sector
  selectRestrictedSectors,

  // Sector Name Select Changed
  sectorNameSelectChanged,

  // Fund Code Select Changed
  fundCodeSelectChanged,

  // Book Code Select Changed
  bookCodeSelectChanged,

} from '../restrictedSectorActions';

const mapStateToProps = state => {
  return {
    restrictedSectorGridColumns: state.restrictedSector.restrictedSectorGridColumns,
    
    restrictedSectorArray: state.restrictedSector.restrictedSectorArray,
    filterRestrictedSectorArray: state.restrictedSector.filterRestrictedSectorArray,

    restrictedSectorEditControlModal: state.restrictedSector.restrictedSectorEditControlModal,

    // Fund Book Data
    fundCodeOptions: state.restrictedSector.fundCodeOptions,
    bookCodeOptions: state.restrictedSector.bookCodeOptions,

    // Sector Codes
    sectorCodeOptions: state.restrictedSector.sectorCodeOptions,

    loadRestrictedSectorActionStatus: state.restrictedSector.loadRestrictedSectorActionStatus,
    createRestrictedSectorActionStatus: state.restrictedSector.createRestrictedSectorActionStatus,
    updateRestrictedSectorActionStatus: state.restrictedSector.updateRestrictedSectorActionStatus,
    batchDeleteRestrictedSectorActionStatus: state.restrictedSector.batchDeleteRestrictedSectorActionStatus,

    // Selected Restricted Sector
    selectedRestrictedSectors: state.restrictedSector.selectedRestrictedSectors,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Restricted Sector
    loadRestrictedSectors: () => dispatch(loadRestrictedSectors()),
    initLoadRestrictedSectorsActionStatus: () => dispatch(initLoadRestrictedSectorsActionStatus()),

    // Create Restricted Sector
    createRestrictedSector: (restrictedSector) => dispatch(createRestrictedSector(restrictedSector)),
    initCreateRestrictedSectorActionStatus: () => dispatch(initCreateRestrictedSectorActionStatus()),

    // Update Restricted Sector
    updateRestrictedSector: (restrictedSector) => dispatch(updateRestrictedSector(restrictedSector)),
    initUpdateRestrictedSectorActionStatus: () => dispatch(initUpdateRestrictedSectorActionStatus()),

    // Batch Delete Restricted Sector
    batchDeleteRestrictedSector: (restrictedSectorIdList) => dispatch(batchDeleteRestrictedSector(restrictedSectorIdList)),
    initBatchDeleteRestrictedSectorActionStatus: () => dispatch(initBatchDeleteRestrictedSectorActionStatus()),

    // RadioGroup Change
    radioGroupChange: (value) => dispatch(radioGroupChange(value)),

    // Open/Reset Restricted Sector Modal Dialog
    openRestrictedSectorEditModalDialog: (isAddMode, restrictedSector) => dispatch(openRestrictedSectorEditModalDialog(isAddMode, restrictedSector)),
    resetRestrictedSectorEditModalDialog: () => dispatch(resetRestrictedSectorEditModalDialog()),

    // Restricted Sector Edit Change Input
    restrictedSectorEditChangeInput: (input) => dispatch(restrictedSectorEditChangeInput(input)),

    // select Restricted Sectors
    selectRestrictedSectors: (payload) => dispatch(selectRestrictedSectors(payload)),

    // Sector Name Select Changed
    sectorNameSelectChanged: (sectorName) => dispatch(sectorNameSelectChanged(sectorName)),

    // Fund Code Select Changed
    fundCodeSelectChanged: (fundCode) => dispatch(fundCodeSelectChanged(fundCode)),
 
    // Book Code Select Changed
    bookCodeSelectChanged: (bookCode) => dispatch(bookCodeSelectChanged(bookCode)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedSector);