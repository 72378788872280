import React, {Component} from 'react';
import { Breadcrumb, Select, Button, message, Input, Divider, Modal } from 'antd';
import './ElementTypeMngDashboard.css';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import ElementTypeMngGrid from './ElementTypeMngGrid';
import AddOrEditElementTypeDialog from './AddOrEditElementTypeDialog';
import AddOrEditFieldDialog from './AddOrEditFieldDialog';


class ElementTypeMngDashboard extends Component {

    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            queryElementTypeActionStatus,
            queryElementTypeActionResp,
            delElementTypeActionStatus,
            delElementTypeActionResp,
            delElementTypeFieldActionStatus,
            delElementTypeFieldActionResp,
        } = this.props;
        if(queryElementTypeActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryElementTypeActionStatus();
        }
        if(queryElementTypeActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = 'Query Element Type failed';
            if(!!queryElementTypeActionResp && !!queryElementTypeActionResp.respMessage) {
                errMsg = queryElementTypeActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initQueryElementTypeActionStatus();
        }
        if(delElementTypeActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Element Type succeeded');
            this.props.initDelElementTypeBtnClickedActionStatus();
            this.props.queryElementType(this.props.elementTypeQueryFields);
        }
        if(delElementTypeActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = 'Delete Element Type failed';
            if(!!delElementTypeActionResp && !!delElementTypeActionResp.respMessage) {
                errMsg = delElementTypeActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initDelElementTypeBtnClickedActionStatus();
        }
        if(delElementTypeFieldActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Element Type Field succeeded');
            this.props.initDelFieldBtnClickedActionStatus();
            this.props.queryElementType(this.props.elementTypeQueryFields);
        }
        if(delElementTypeFieldActionStatus === ACTION_STATUS.ERROR) {
            let errMsg = 'Delete Element Type Field failed';
            if(!!delElementTypeFieldActionResp && !!delElementTypeFieldActionResp.respMessage) {
                errMsg = delElementTypeFieldActionResp.respMessage;
            }
            message.error(errMsg);
            this.props.initDelFieldBtnClickedActionStatus();
        }
    }


    onElementTypeQueryInputChange = (param) => {
        this.props.onElementTypeQueryInputChange(param);
    }

    refreshBtnOnClicked = () => {
        this.props.queryElementType(this.props.elementTypeQueryFields);
    }


    addOrEditElementTypeBtnClicked = (mode) => {
        let selectedElementType = null;
        if(mode === 'EDIT') {
            const {selectedElementTypeList} = this.props;
            if(!selectedElementTypeList || selectedElementTypeList.length !== 1) {
                message.warn('Please choose one and only one Element Type');
                return;
            }
            selectedElementType = selectedElementTypeList[0];
        }
        this.props.openAddOrEditElementTypeModal(mode, selectedElementType);
    }


    delElementTypeBtnClicked = () => {
        const {selectedElementTypeList} = this.props;
        if(!selectedElementTypeList || selectedElementTypeList.length !== 1) {
            message.warn('Please choose one and only one Element Type');
            return;
        }
        const selectedElementType = selectedElementTypeList[0];
        Modal.confirm({
            title: 'Are you sure to delete this Element Type?',
            onOk: () => {
                this.props.delElementTypeBtnClicked(selectedElementType);
            }
        });
    }


    addOrEditFieldBtnClicked = (mode) => {
        let selectedField = null;
        let elementTypeId = null;
        if(mode === 'EDIT') {
            const {selectedElementTypeFieldList} = this.props;
            if(!selectedElementTypeFieldList || selectedElementTypeFieldList.length !== 1) {
                message.warn('Please choose one and only one Field');
                return;
            }
            selectedField = selectedElementTypeFieldList[0];
            elementTypeId = selectedField.elementTypeId;
        } else {
            const {selectedElementTypeList} = this.props;
            if(!selectedElementTypeList || selectedElementTypeList.length !== 1) {
                message.warn('Please choose one and only one Element Type');
                return;
            }
            elementTypeId = selectedElementTypeList[0].id;
        }
        this.props.openAddOrEditFieldModal(mode, selectedField, elementTypeId);
    }


    delFieldBtnClicked = () => {
        const {selectedElementTypeFieldList} = this.props;
        if(!selectedElementTypeFieldList || selectedElementTypeFieldList.length !== 1) {
            message.warn('Please choose one and only one Field');
            return;
        }
        const selectedField = selectedElementTypeFieldList[0];
        Modal.confirm({
            title: 'Are you sure to delete this Field?',
            onOk: () => {
                this.props.delFieldBtnClicked(selectedField);
            }
        });
    }


    render() {

        const {
            elementTypeQueryFields,
            queryElementTypeActionStatus,
        } = this.props;

        const {
            baseType,
            elementTypeName,
        } = elementTypeQueryFields;

        const refreshBtnIsLoading = queryElementTypeActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='elementTypeMngWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Pinpoint Group</Breadcrumb.Item>
                        <Breadcrumb.Item>Element Type Manage</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='operationBar'>
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onElementTypeQueryInputChange({ name: 'baseType', value: val })
                        }} 
                        value={baseType}
                        placeholder="baseType">
                            <Select.Option key={'Entity'}>{'Entity'}</Select.Option>
                            <Select.Option key={'Relation'}>{'Relation'}</Select.Option>
                    </Select>
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="elementTypeName" 
                        value={elementTypeName}
                        onChange={(e) => {
                            this.onElementTypeQueryInputChange({ name: 'elementTypeName', value: e.target.value })
                        }}
                    />
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <span style={{ fontSize: 14 }}>ElementType: </span>
                    <Button 
                        onClick={() => this.addOrEditElementTypeBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                        icon='plus'
                    />
                    <Button 
                        onClick={() => this.addOrEditElementTypeBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon='edit'
                    />
                    <Button 
                        onClick={() => this.delElementTypeBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                        icon='minus'
                    />

                    <Divider type='vertical' />

                    <span style={{ fontSize: 14 }}>Fields: </span>
                    <Button 
                        onClick={() => this.addOrEditFieldBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                        icon='plus'
                    />
                    <Button 
                        onClick={() => this.addOrEditFieldBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon='edit'
                    />
                    <Button 
                        onClick={() => this.delFieldBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                        icon='minus'
                    />


                </div>

                <div className='ElementTypeMngGrid'><ElementTypeMngGrid {...this.props} /></div>
                <AddOrEditElementTypeDialog {...this.props} />
                <AddOrEditFieldDialog {...this.props} />

            </div>
        );
    }

}


export default ElementTypeMngDashboard;
