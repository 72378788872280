import React, { Component } from 'react';

import { Form, Row, Col, Input, Modal, Select, message, InputNumber, Upload, Button, Icon } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import { QUESTION_TYPE_MAP, QUESTION_TYPE_CODE } from '../../../../../utils/QuestionTypeUtil';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// Question Type
const QuestionTypeOptions = [];  
for (let i = 0; i < QUESTION_TYPE_MAP.length; i++) {
  QuestionTypeOptions.push(<Option key={QUESTION_TYPE_MAP[i].code}>{QUESTION_TYPE_MAP[i].label}</Option>);
}

class QuestionEditDialog extends Component {
  state = {
    
  };

  onInputChange = ({ name, value }) => {
    this.props.questionEditChangeInput({ name, value });
  };

  componentDidUpdate() {
    const { 
      createQuestionActionStatus,
      updateQuestionActionStatus,
    } = this.props;

    // 创建 Question 成功
    if (createQuestionActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Question is created successfully!');
      this.props.loadLicensingForm();
      this.props.initCreateQuestionActionStatus();

      this.props.form.resetFields();
      this.props.resetQuestionEditModalDialog();
    } 

    // 创建 Question 失败
    if (createQuestionActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCreateQuestionActionStatus();
    }

    // 更新 Question 成功
    if (updateQuestionActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Question is updated successfully!');
      this.props.loadLicensingForm();
      this.props.initUpdateQuestionActionStatus();

      this.props.form.resetFields();
      this.props.resetQuestionEditModalDialog();
    } 

    // 更新 Question 失败
    if (updateQuestionActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateQuestionActionStatus();
    }
  }

  // Submit Create/Edit Member
  submitButtonOnClicked = () => {
    const {
      questionEditControlModal,
    } =  this.props;

    const {
      isAddMode,
      fields,
    } =  questionEditControlModal;

    const {
      guid,
      licensingFormGuid,
      questionnaireGuid,
      questionNumber,
      subQuestionNumber,
      questionEnglish,
      questionChinese,
      options,
      questionType,
      parentGuid,
      triggerOption,
      correctAnswer,
      score,
      attachment,
      attachmentOriginName,
    } = fields;

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          console.info('success');

          if (isAddMode) {
            this.props.createQuestion({
              guid,
              licensingFormGuid,
              questionnaireGuid,
              questionNumber,
              subQuestionNumber,
              questionEnglish,
              questionChinese,
              options,
              questionType,
              parentGuid,
              triggerOption,
              correctAnswer,
              score,
              attachment,
              attachmentOriginName,
            });
          } else {
            this.props.updateQuestion({
              guid,
              licensingFormGuid,
              questionnaireGuid,
              questionNumber,
              subQuestionNumber,
              questionEnglish,
              questionChinese,
              options,
              questionType,
              parentGuid,
              triggerOption,
              correctAnswer,
              score,
              attachment,
              attachmentOriginName,
            });
          }
        }
      },
    );
  }

  checkSubQuestionNumber = (rule, value, callback) => {
    let regExp = new RegExp('^[0-9]*[0-9]*$');
    if (value !== '' && value !== null && !regExp.test(value) ) {
      callback('SubQuestion Number has to be a positive integer or zero!');
    }

    callback();
  }

  // Questionnaire 选择
  licensingFormOnChange = (value) => {
    this.onInputChange({ name: 'licensingFormGuid', value });
    this.props.licensingFormOptionChange(value);
  }

  // Parent Question 选择
  parentQuestionOnChange = (value) => {
    this.onInputChange({ name: 'parentGuid', value })
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    this.props.questionEditChangeInput({name: 'fileList', value: fileList});

    let fileNameArray = [];
    let originalFileNameArray = [];
    fileList.forEach(file => {
      if (file.response) {
        const fileName = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        
        fileNameArray.push(fileName);
        originalFileNameArray.push(originalFileName);
      }
    });

    debugger;
    if (fileList.length === fileNameArray.length) {
      const fileNames = fileNameArray.join(',');
      const originalFileNames = originalFileNameArray.join(',');
      this.props.questionEditChangeInput({name: 'attachment', value: fileNames});
      this.props.questionEditChangeInput({name: 'attachmentOriginName', value: originalFileNames});
    }
  }

  // 删除
  attachmentHandleRemove = (file) => {
    const {
      fileList,
    } =  this.props.questionEditControlModal.fields;

    const fileListWithoutRemovedFile = fileList.filter(fileItem => fileItem.uid !== file.uid);
    this.props.questionEditChangeInput({name: 'fileList', value: fileListWithoutRemovedFile});

    let fileNameArray = fileListWithoutRemovedFile.map(fileItem => fileItem.response.data.fileNameWithTimeStamp);
    let originalFileNameArray = fileListWithoutRemovedFile.map(fileItem => fileItem.response.data.originalFileName);
    
    const fileNames = fileNameArray.join(',');
    const originalFileNames = originalFileNameArray.join(',');
    this.props.questionEditChangeInput({name: 'attachment', value: fileNames});
    this.props.questionEditChangeInput({name: 'attachmentOriginName', value: originalFileNames});
  }

  render() {
    const {
      questionEditControlModal,
      createQuestionActionStatus,
      updateQuestionActionStatus,
      licensingFormOptions,
      parentQuestionOptions,

      licensingFormAttachmentUploadUrl,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = questionEditControlModal;

    const { getFieldDecorator } = this.props.form;

    const {
      guid,
      licensingFormGuid,
      questionnaireGuid,
      questionNumber,
      subQuestionNumber,
      questionEnglish,
      questionChinese,
      options,
      questionType,
      parentGuid,
      triggerOption,
      correctAnswer,
      score,
      fileList,
    } = questionEditControlModal.fields;

    let modalTitle = "Edit Question";
    if (isAddMode) {
      modalTitle = "Add Question";
    }

    const isLoading = createQuestionActionStatus === ACTION_STATUS.LOGINING || updateQuestionActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1100}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetQuestionEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Item Number">
                  <Input 
                    value={questionNumber}
                    placeholder="Item Number (eg: A1/B1)" 
                    onChange={(e) => {
                        this.onInputChange({ name: 'questionNumber', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="SubItem Number">
                  {getFieldDecorator('subQuestionNumber', {
                      initialValue: subQuestionNumber,
                      rules: [this.checkSubQuestionNumber],
                    })(
                    <Input placeholder="SubItem Number (eg: 0/1), the default is 0" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'subQuestionNumber', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="LicensingForm">
                  {getFieldDecorator('licensingFormGuid', {
                    initialValue: `${licensingFormGuid}`,
                    rules: [{
                      required: true,
                      message: 'Please select a LicensingForm!',
                    }],
                  })(
                    <Select 
                      allowClear
                      onChange={this.licensingFormOnChange} 
                      placeholder="Please Choose">
                      {licensingFormOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
              {/* <Col span={12}>
                <FormItem {...formItemLayout} label="Parent Item">
                  <Select 
                    allowClear
                    value={parentGuid}
                    onChange={(value) => {
                        this.onInputChange({ name: 'parentGuid', value })
                      }} 
                      placeholder="Please Choose">
                    {parentQuestionOptions}
                  </Select>
                </FormItem>
              </Col> */}
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Item English">
                  {getFieldDecorator('questionEnglish', {
                    initialValue: questionEnglish,
                    rules: [{
                      required: true,
                      message: 'Please input Item english! ',
                    }],
                  })(
                    <Input.TextArea 
                      rows={3}
                      placeholder="Item English" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'questionEnglish', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Item Chinese">
                  <Input.TextArea 
                    rows={3}
                    value={questionChinese}
                    placeholder="Item Chinese" 
                    onChange={(e) => {
                        this.onInputChange({ name: 'questionChinese', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Item Type">
                  {getFieldDecorator('questionType', {
                    initialValue: questionType,
                    rules: [{
                      required: true,
                      message: 'Please input item type! ',
                    }],
                  })(
                    <Select onChange={(value) => {
                          this.onInputChange({ name: 'questionType', value })
                        }} 
                        placeholder="Please Choose">
                      {QuestionTypeOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            {
              (questionType === QUESTION_TYPE_CODE.SINGLE_CHOICE || questionType === QUESTION_TYPE_CODE.MULTIPLE_CHOICE) &&
              <Row>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Item Options">
                    {getFieldDecorator('options', {
                      initialValue: options,
                      rules: [{
                        required: true,
                        message: 'Please input Item options! ',
                      }],
                    })(
                      <TextArea placeholder="Item Options (Use the | split options, eg: A|B|C)" 
                        rows={3}
                        onChange={(e) => {
                            this.onInputChange({ name: 'options', value: e.target.value })
                          }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            }
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Trigger Option">
                  <Input placeholder="Trigger Option" 
                    value={triggerOption}
                    onChange={(e) => {
                        this.onInputChange({ name: 'triggerOption', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Attachment">
                  <Upload
                    name="attachment"
                    withCredentials={true}
                    multiple={true}
                    showUploadList={true}
                    fileList={fileList}
                    action={licensingFormAttachmentUploadUrl}
                    onChange={this.attachmentHandleChange}
                    onRemove={this.attachmentHandleRemove}
                  >
                    <Button>
                      <Icon type="upload" /> Template of Question
                    </Button>
                  </Upload>
                </FormItem>
              </Col>

            </Row>
            <Row gutter={24}>
                            {/* <Col span={12}>
                <FormItem {...formItemLayout} label="Correct Answer">
                  <Input placeholder="Correct Answer" 
                    value={correctAnswer}
                    onChange={(e) => {
                        this.onInputChange({ name: 'correctAnswer', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col> */}
              {/* <Col span={12}>
                <FormItem {...formItemLayout} label="Score">
                  <InputNumber
                    value={score}
                    min={0}
                    defaultValue={0}
                    precision={0}
                    onChange={(val) => {
                        this.onInputChange({ name: 'score', value: val })
                      }}
                  />
                </FormItem>
              </Col> */}

            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

const QuestionEditDialogForm = Form.create({ name: 'question_edit_dialog' })(QuestionEditDialog);

export default QuestionEditDialogForm;