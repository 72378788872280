import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_EXPENSE_SUMMARY_INFO,
    LOAD_EXPENSE_SUMMARY_INFO_SUCCESS,
    LOAD_EXPENSE_SUMMARY_INFO_FAILURE,
    INIT_LOAD_EXPENSE_SUMMARY_INFO_ACTION_STATUS,
    // select
    SELECT_EXPENSE_SUMMARY_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL,
    ADD_OR_EDIT_EXPENSE_SUMMARY_CHANGE_INPUT,
    START_ADD_OR_EDIT_EXPENSE_SUMMARY,
    ADD_OR_EDIT_EXPENSE_SUMMARY_SUCCESS,
    ADD_OR_EDIT_EXPENSE_SUMMARY_FAILURE,
    INIT_ADD_OR_EDIT_EXPENSE_SUMMARY_ACTION_STATUS,
    RESET_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_EXPENSE_SUMMARY_MODAL,
    DEL_EXPENSE_SUMMARY_CHANGE_INPUT,
    START_DEL_EXPENSE_SUMMARY,
    DEL_EXPENSE_SUMMARY_SUCCESS,
    DEL_EXPENSE_SUMMARY_FAILURE,
    INIT_DEL_EXPENSE_SUMMARY_ACTION_STATUS,
    RESET_DEL_EXPENSE_SUMMARY_MODAL,

} from './ExpenseSummaryConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadExpenseSummaryInfo() {
    return {
      type: START_LOAD_EXPENSE_SUMMARY_INFO,
    };
}
export function loadExpenseSummaryInfo(params) {
    return function(dispatch) {
        dispatch(startLoadExpenseSummaryInfo());
        httpClient
          .get(UrlConfig.LOAD_EXPENSE_SUMMARY_INFO, params)
          .then(resp => dispatch(loadExpenseSummaryInfoSuccess(resp)))
          .catch(err => dispatch(loadExpenseSummaryInfoFailure(err)));
    };
}
function loadExpenseSummaryInfoSuccess(resp) {
    return {
      type: LOAD_EXPENSE_SUMMARY_INFO_SUCCESS,
      payload: resp,
    };
}
function loadExpenseSummaryInfoFailure(err) {
    return {
        type: LOAD_EXPENSE_SUMMARY_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadExpenseSummaryInfoActionStatus() {
    return {
      type: INIT_LOAD_EXPENSE_SUMMARY_INFO_ACTION_STATUS,
    };
}

// select
export function selectExpenseSummaryInfo(array) {
    return {
        type: SELECT_EXPENSE_SUMMARY_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditExpenseSummaryModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditExpenseSummaryChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_EXPENSE_SUMMARY_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditExpenseSummary() {
    return {
        type: START_ADD_OR_EDIT_EXPENSE_SUMMARY,
    };
}
export function addOrEditExpenseSummary(params) {
      return function(dispatch) {
        dispatch(startAddOrEditExpenseSummary());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_EXPENSE_SUMMARY, params)
          .then(resp => dispatch(addOrEditExpenseSummarySuccess(resp)))
          .catch(err => dispatch(addOrEditExpenseSummaryFailure(err)));
      };
}
function addOrEditExpenseSummarySuccess(resp) {
    return {
        type: ADD_OR_EDIT_EXPENSE_SUMMARY_SUCCESS,
        payload: resp,
    };
}
function addOrEditExpenseSummaryFailure(err) {
    return {
        type: ADD_OR_EDIT_EXPENSE_SUMMARY_FAILURE,
        payload: err,
    };
}
export function initAddOrEditExpenseSummaryActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_EXPENSE_SUMMARY_ACTION_STATUS,
    };
}
export function resetAddOrEditExpenseSummaryModal() {
    return {
        type: RESET_ADD_OR_EDIT_EXPENSE_SUMMARY_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelExpenseSummaryModal(info) {
    return {
        type: OPEN_DEL_EXPENSE_SUMMARY_MODAL,
        payload: info,
    };  
}
export function delExpenseSummaryChangeInput({name, value}) {
    return {
        type: DEL_EXPENSE_SUMMARY_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelExpenseSummary() {
    return {
        type: START_DEL_EXPENSE_SUMMARY,
    };
}
export function delExpenseSummary(params) {
      return function(dispatch) {
        dispatch(startDelExpenseSummary());
        httpClient
          .post(UrlConfig.DEL_EXPENSE_SUMMARY, params)
          .then(resp => dispatch(delExpenseSummarySuccess(resp)))
          .catch(err => dispatch(delExpenseSummaryFailure(err)));
      };
}
function delExpenseSummarySuccess(resp) {
    return {
        type: DEL_EXPENSE_SUMMARY_SUCCESS,
        payload: resp,
    };
}
function delExpenseSummaryFailure(err) {
    return {
        type: DEL_EXPENSE_SUMMARY_FAILURE,
        payload: err,
    };
}
export function initDelExpenseSummaryActionStatus() {
    return {
        type: INIT_DEL_EXPENSE_SUMMARY_ACTION_STATUS,
    };
}
export function resetDelExpenseSummaryModal() {
    return {
        type: RESET_DEL_EXPENSE_SUMMARY_MODAL,
    };
}


