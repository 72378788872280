import { createReducer } from '../../../../utils/reducerUtil';
import GridColumnMap from '../GridColumnMap';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
  // Load Custodian
  START_LOAD_CUSTODIAN,
  LOAD_CUSTODIAN_SUCCESS,
  LOAD_CUSTODIAN_FAILURE,
  INIT_LOAD_CUSTODIAN_ACTION_STATUS,
  
  // Load Custodian Account
  START_LOAD_CUSTODIAN_ACCOUNT,
  LOAD_CUSTODIAN_ACCOUNT_SUCCESS,
  LOAD_CUSTODIAN_ACCOUNT_FAILURE,
  INIT_LOAD_CUSTODIAN_ACCOUNT_ACTION_STATUS,
} from './custodianConstants';

const initialState = {
  custodianGridColumns: GridColumnMap.custodianGridColumns,
  custodianAccountGridColumns: GridColumnMap.custodianAccountGridColumns,

  // Custodian
  custodianArray: [],

  // Custodian Account
  custodianAccountArray: [],

  // Load Custodian Action Status
  loadCustodianActionStatus: ACTION_STATUS.READY,

  // Load Custodian Account Action Status
  loadCustodianAccountActionStatus: ACTION_STATUS.READY,
}

// Load Custodian
function startLoadCustodian(state) {
  return {
    ...state,
    loadCustodianActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadCustodianSuccess(state, resp) {
  return {
    ...state,
    loadCustodianActionStatus: ACTION_STATUS.SUCCESS,
    custodianArray: resp.data,
  };
}

function loadCustodianFailure(state, err) {
  return {
    ...state,
    loadCustodianActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadCustodianActionStatus(state) {
  return {
    ...state,
    loadCustodianActionStatus: ACTION_STATUS.READY,
  };
}


// Load Custodian Account
function startLoadCustodianAccount(state) {
  return {
    ...state,
    loadCustodianAccountActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadCustodianAccountSuccess(state, resp) {
  return {
    ...state,
    loadCustodianAccountActionStatus: ACTION_STATUS.SUCCESS,
    custodianAccountArray: resp.data,
  };
}

function loadCustodianAccountFailure(state, err) {
  return {
    ...state,
    loadCustodianAccountActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadCustodianAccountActionStatus(state) {
  return {
    ...state,
    loadCustodianAccountActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Custodian
  [START_LOAD_CUSTODIAN]: startLoadCustodian,
  [LOAD_CUSTODIAN_SUCCESS]: loadCustodianSuccess,
  [LOAD_CUSTODIAN_FAILURE]: loadCustodianFailure,
  [INIT_LOAD_CUSTODIAN_ACTION_STATUS]: initLoadCustodianActionStatus,
  
  // Load Custodian Account
  [START_LOAD_CUSTODIAN_ACCOUNT]: startLoadCustodianAccount,
  [LOAD_CUSTODIAN_ACCOUNT_SUCCESS]: loadCustodianAccountSuccess,
  [LOAD_CUSTODIAN_ACCOUNT_FAILURE]: loadCustodianAccountFailure,
  [INIT_LOAD_CUSTODIAN_ACCOUNT_ACTION_STATUS]: initLoadCustodianAccountActionStatus,
});