import {connect} from 'react-redux';
import CounterPartyDashboard from '../components/CounterPartyDashboard';

import {
  // Load CounterParty
  loadCounterParty,
  initLoadCounterPartyActionStatus,
  

} from '../counterPartyActions';

const mapStateToProps = state => {
  return {
    counterpartyListGridColumns: state.counterParty.counterpartyListGridColumns,

    // CounterParty
    counterPartyArray: state.counterParty.counterPartyArray,

    // Load CounterParty Action Status
    loadCounterPartyActionStatus: state.counterParty.loadCounterPartyActionStatus,

  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load CounterParty
    loadCounterParty: () => dispatch(loadCounterParty()),
    initLoadCounterPartyActionStatus: () => dispatch(initLoadCounterPartyActionStatus()),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounterPartyDashboard);