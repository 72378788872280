import {connect} from 'react-redux';
import WorkManagementDashboard from '../components/WorkManagementDashboard';


import {
    // query
    onQueryInputChange,
    loadWorkManagementInfo,
    initLoadWorkManagementInfoActionStatus,
    // select
    selectWorkManagement,
    // add or edit
    openAddOrEditWorkManagementModal,
    addOrEditWorkManagementChangeInput,
    resetSubmitSaveWorkManagementModal,
    submitSaveWorkManagement,
    submitSaveWorkManagementFailure,
    initSubmitSaveWorkManagementActionStatus,

    //Sub add or edit
    openAddOrEditWorkManagementSubModal,
    addOrEditWorkManagementSubChangeInput,
    resetSubmitSaveWorkManagementSubModal,
    submitSaveWorkManagementSub,
    submitSaveWorkManagementSubFailure,
    initSubmitSaveWorkManagementSubActionStatus,



    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForWorkManagement,
    initBatchChangeStatusForWorkManagementActionStatus,
    // finish
    selectWorkManagementTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishWorkManagementTask,
    initFinishWorkManagementTaskActionStatus,

} from '../WorkManagementActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';

import {
    queryPendingTasksNum,
} from '../../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
    return {
        // basic options
        locationOptions: state.workManagement.locationOptions,
        fundCompanyOptions: state.workManagement.fundCompanyOptions,
        frequencyOptions: state.workManagement.frequencyOptions,
        weekdayOptions: state.workManagement.weekdayOptions,
        monthOptions: state.workManagement.monthOptions,
        responsibleDeptOptions: state.workManagement.responsibleDeptOptions,

        categoryOptions: state.workManagement.categoryOptions,
        fundCoOptions: state.workManagement.fundCoOptions,
        statusOptions: state.workManagement.statusOptions,
        importanceOptions: state.workManagement.importanceOptions,
        userAccountOptions: state.userAccess.userAccountOptions,

        // query
        workManagementQueryFields: state.workManagement.workManagementQueryFields,
        loadWorkManagementInfoActionStatus: state.workManagement.loadWorkManagementInfoActionStatus,
        loadWorkManagementInfoResp: state.workManagement.loadWorkManagementInfoResp,
        // select
        selectedWorkManagementArray: state.workManagement.selectedWorkManagementArray,
        // add or edit
        addOrEditWorkManagementModal: state.workManagement.addOrEditWorkManagementModal,
        addOrEditWorkManagementStatus: state.workManagement.addOrEditWorkManagementStatus,

        // sub add or edit
        addOrEditWorkManagementSubModal: state.workManagement.addOrEditWorkManagementSubModal,
        addOrEditWorkManagementSubStatus: state.workManagement.addOrEditWorkManagementSubStatus,



        // batch change status
        batchChangeStatusModal: state.workManagement.batchChangeStatusModal,
        batchChangeStatusForWorkManagementStatus: state.workManagement.batchChangeStatusForWorkManagementStatus,
        // finish
        selectedWorkManagementTaskArray: state.workManagement.selectedWorkManagementTaskArray,
        finishTaskModal: state.workManagement.finishTaskModal,
        finishWorkManagementTaskStatus: state.workManagement.finishWorkManagementTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.workManagement.userAccountEmailOptions,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadWorkManagementInfo: (params) => dispatch(loadWorkManagementInfo(params)),
        initLoadWorkManagementInfoActionStatus: () => dispatch(initLoadWorkManagementInfoActionStatus()),
        // select
        selectWorkManagement: (array) => dispatch(selectWorkManagement(array)),
        // add or edit
        openAddOrEditWorkManagementModal: (mode, data) => dispatch(openAddOrEditWorkManagementModal(mode, data)),
        addOrEditWorkManagementChangeInput: (data) => dispatch(addOrEditWorkManagementChangeInput(data)),
        resetSubmitSaveWorkManagementModal: () => dispatch(resetSubmitSaveWorkManagementModal()),
        submitSaveWorkManagement: (bean) => dispatch(submitSaveWorkManagement(bean)),
        submitSaveWorkManagementFailure: (err) => dispatch(submitSaveWorkManagementFailure(err)),
        initSubmitSaveWorkManagementActionStatus: () => dispatch(initSubmitSaveWorkManagementActionStatus()),


        //sub add or edit
        openAddOrEditWorkManagementSubModal: (mode, data,idList) => dispatch(openAddOrEditWorkManagementSubModal(mode, data,idList)),
        addOrEditWorkManagementSubChangeInput: (data) => dispatch(addOrEditWorkManagementSubChangeInput(data)),
        resetSubmitSaveWorkManagementSubModal: () => dispatch(resetSubmitSaveWorkManagementSubModal()),
        submitSaveWorkManagementSub: (bean) => dispatch(submitSaveWorkManagementSub(bean)),
        submitSaveWorkManagementSubFailure: (err) => dispatch(submitSaveWorkManagementSubFailure(err)),
        initSubmitSaveWorkManagementSubActionStatus: () => dispatch(initSubmitSaveWorkManagementSubActionStatus()),



        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForWorkManagement: (params) => dispatch(batchChangeStatusForWorkManagement(params)),
        initBatchChangeStatusForWorkManagementActionStatus: () => dispatch(initBatchChangeStatusForWorkManagementActionStatus()),
        // finish
        selectWorkManagementTask: (array) => dispatch(selectWorkManagementTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishWorkManagementTask: (task) => dispatch(finishWorkManagementTask(task)),
        initFinishWorkManagementTaskActionStatus: () => dispatch(initFinishWorkManagementTaskActionStatus()),
        queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(WorkManagementDashboard);
